import moment from 'moment';
import { Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const PersonalCart = ({ cartList, currentPage }) => {
  const currentData = cartList?.filter((cart, index) => {
    if (
        index >= 10 * (currentPage - 1) &&
        index <= 9 + 10 * (currentPage - 1)
    ) {
      return cart;
    }
  });
  return (
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>加入購物車日期</th>
            <th>商品名稱</th>
            <th>顏色</th>
            <th>尺寸</th>
            <th>數量</th>
            <th>金額</th>
          </tr>
          </thead>
          {!currentData && <TableLoading />}
          {currentData && (
              <tbody>
              {currentData?.length > 0 ? (
                  currentData.map((cart, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          {/* 加入購物車日期 */}
                          {cart.createTime
                              ? moment(cart.createTime).format('YYYY/MM/DD HH:mm:ss')
                              : '--'}
                        </td>
                        <td className="text-center">
                          {/* 商品名稱 */}
                          {cart.productname || '--'}
                        </td>
                        <td className="text-center">
                          {/* 顏色 */}
                          {cart.color || '--'}
                        </td>
                        <td className="text-center">
                          {/* 尺寸 */}
                          {cart.size || '--'}
                        </td>
                        <td className="text-center">
                          {/* 數量 */}
                          {cart.quantity}
                        </td>
                        <td className="text-center price text-black fw-normal">
                          {/* 金額 */}
                          {cart.finalPrice.toLocaleString('en-US')}
                        </td>
                      </tr>
                  ))
              ) : (
                  <TableHasNoData />
              )}
              </tbody>
          )}
        </Table>
      </div>
  );
};

export default PersonalCart;
