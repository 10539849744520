import {
  ADMIN_ADD_FAIL,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_RESET,
  ADMIN_ADD_SUCCESS,
  ADMIN_DELETE_FAIL,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_RESET,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_PERMISSIONS_FAIL,
  ADMIN_PERMISSIONS_REQUEST,
  ADMIN_PERMISSIONS_SUCCESS,
  ADMIN_SEND_VERIFY_FAIL,
  ADMIN_SEND_VERIFY_REQUEST,
  ADMIN_SEND_VERIFY_RESET,
  ADMIN_SEND_VERIFY_SUCCESS,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_RESET,
  ADMIN_UPDATE_SUCCESS,
} from '../../constants/data/adminConstants';

//get admin list
export const adminListReducer = (
  state = { adminListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_LIST_REQUEST:
      return { loading: true };
    case ADMIN_LIST_SUCCESS:
      return { adminListInfo: payload, loading: false };
    case ADMIN_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get permission options
export const permissionOptionsReducer = (
  state = { loading: true, options: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_PERMISSIONS_REQUEST:
      return { loading: true };
    case ADMIN_PERMISSIONS_SUCCESS:
      return { loading: false, options: payload };
    case ADMIN_PERMISSIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add admin
export const adminAddReducer = (state = { success: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_ADD_REQUEST:
      return { loading: true };
    case ADMIN_ADD_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_ADD_FAIL:
      return { error: payload, loading: false };
    case ADMIN_ADD_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//get admin details
export const adminDetailsReducer = (
  state = { adminDetailsInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_DETAILS_SUCCESS:
      return { adminDetailsInfo: payload, loading: false };
    case ADMIN_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update admin
export const adminUpdateReducer = (
  state = { error: null, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_UPDATE_REQUEST:
      return { ...state, loading: true };
    case ADMIN_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADMIN_UPDATE_FAIL:
      return { error: payload, loading: false };
    case ADMIN_UPDATE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//delete admin
export const adminDeleteReducer = (state = { success: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_DELETE_REQUEST:
      return { loading: true };
    case ADMIN_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_DELETE_FAIL:
      return { error: payload, loading: false };
    case ADMIN_DELETE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//send verify letter
export const adminSendVerifyReducer = (state = { success: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_SEND_VERIFY_REQUEST:
      return { loading: true };
    case ADMIN_SEND_VERIFY_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_SEND_VERIFY_FAIL:
      return { error: payload, loading: false };
    case ADMIN_SEND_VERIFY_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
