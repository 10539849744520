import ShipItemV2 from 'components/ShipItemV2';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap';

import Layout from '../components/Layout';
import Loader from '../components/Loader';
import { userLogout } from '../redux/actions/data/userActions';
import {
  getShippingDealerList,
  submitWarrantyAndProductNumber,
} from '../redux/actions/data/warrantyActions';
import { WARRANTY_PRODUCT_CHECK_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const ShipV2 = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();

  const initialProductData = {
    barcode: '',
    product: '',
    productName: '',
    warranty: '',
  };

  const [dealer, setDealer] = useState({ label: '請選擇經銷商', value: 0 });
  const [amount, setAmount] = useState('');
  const [remark, setRemark] = useState('');
  const [type, setType] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [productList, setProductList] = useState([initialProductData]);
  const { userInfo } = useSelector(state => state.userLogin);
  const { shippingDealerList } = useSelector(state => state.shippingDealerList);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { success: checkSuccess, error: checkError } = useSelector(
    state => state.warrantyProductCheck,
  );

  const handleSubmit = values => {
    const shipperList = [];
    values.productList.forEach((set, index) => {
      shipperList.push({
        barcode: set.barcode,
        codeType: +type,
        id: 0,
        serialnumber: set.product,
        warrantySerialNum: set.warranty,
      });
    });
    setErrorMessage('');
    MessageUtil.submitConfirm({
      cancelFun: () => {},
      doneFun: async () => {
        const obj = {
          codeType: +type,
          id: 0,
          remark: remark,
          shipperDts: shipperList,
          shippingCount: +amount,
          suppliercode: dealer.value,
        };
        setSubmitLoading(true);

        try {
          const res = await submitWarrantyAndProductNumber(obj);
          if (res?.result?.msg) {
            setErrorMessage(res.result.msg);
            setFieldError(
              'productList',
              res.result.msgs.map((m, index) => {
                return {
                  barcode: m.barcodeMsg || '',
                  product: m.serialnumberMsg || '',
                  result: m.message || '',
                  warranty: m.warrantyMsg || '',
                };
              }),
            );

            //提交但是有錯誤
            MessageUtil.alertWanring('綁定錯誤');
          } else {
            MessageUtil.toastSuccess('儲存成功!!');

            //重新設定
            handleCancel();
          }
          setSubmitLoading(false);
        } catch (e) {
          console.log('儲存失敗', e);
          setErrorMessage('儲存失敗');
          MessageUtil.alertWanring('儲存失敗');
          setSubmitLoading(false);
        }
      },
    });
  };

  const { values, setFieldValue, errors, setFieldError } = useFormik({
    initialErrors: {
      productList: [],
    },
    initialValues: {
      productList: [
        {
          barcode: '',
          isBarcodeVaildate: false,
          isFocused: true,
          isProductVaildate: false,
          isWarrantyVaildate: false,
          product: '',
          productName: '',
          warranty: '',
        },
      ],
    },
  });

  useEffect(() => {
    if (checkError) {
      if (checkError && checkError.status === 401) {
        dispatch(userLogout());
        history.push({ pathname: '/Login', state: '/Ship' });
        MessageUtil.alertWanring('請重新登入取得授權');
      } else if (userInfo) {
        //比對token時間
        if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
          dispatch(userLogout());
          history.push({ pathname: '/Login', state: '/Ship' });
          MessageUtil.alertWanring('請重新登入取得授權');
        }
      }
    }

    return () => {
      dispatch({ type: WARRANTY_PRODUCT_CHECK_RESET });
    };
  }, [checkSuccess, checkError]);

  useEffect(() => {
    //取得經銷商資料
    dispatch(getShippingDealerList());
  }, []);

  const handleCancel = () => {
    setErrorMessage('');
    setFieldError('productList', []);
    setFieldValue('productList', [
      {
        barcode: '',
        isBarcodeVaildate: false,
        isFocused: true,
        isProductVaildate: false,
        isWarrantyVaildate: false,
        product: '',
        productName: '',
        warranty: '',
      },
    ]);
    setProductList([initialProductData]);
    setDealer({ label: '請選擇經銷商', value: 0 });
    setAmount('');
    setType('');
    setRemark('');
    setConfirm(false);
  };

  const handleConfirm = () => {
    setErrorMessage('');
    if (dealer.value !== 0 && amount && type) {
      setConfirm(true);
    } else {
      MessageUtil.alertWanring(
        dealer.value === 0
          ? '請選擇經銷商'
          : amount === ''
          ? '請輸入數量'
          : '請選擇條碼類型',
        '',
      );
    }
  };

  return (
    <Layout
      pageTitle="出貨"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: true, page_name: '出貨' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >          序號綁定設定
        </CardHeader>
        <CardBody className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 requird">
                  選擇經銷商
                </label>
                <Select
                  className={style['form-select']}
                  options={shippingDealerList}
                  onChange={setDealer}
                  defaultValue={dealer}
                  isDisabled={confirm}
                  value={dealer}
                  style={{ margin: 0 }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 requird" htmlFor="amount">
                  出貨數量
                </label>
                <input
                  id="amount"
                  type="number"
                  className="form-control"
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  min={1}
                  disabled={confirm}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 requird" htmlFor="remark">
                  備註
                </label>
                <input
                  id="remark"
                  type="text"
                  className="form-control"
                  onChange={e => setRemark(e.target.value)}
                  value={remark}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 requird">
                  使用條碼類型
                </label>
                <div className="d-flex">
                  <label className="mr-2" htmlFor="1">
                    <input
                      id="1"
                      className="me-1"
                      name="barcodeType"
                      type="radio"
                      value="1"
                      onChange={e => setType(e.target.value)}
                      checked={type === '1' ? true : false}
                      disabled={confirm}
                      style={{ cursor: 'pointer' }}
                    />
                    產品序號
                  </label>
                  <label className="mr-2" htmlFor="2">
                    <input
                      id="2"
                      className="me-1"
                      name="barcodeType"
                      type="radio"
                      value="2"
                      onChange={e => setType(e.target.value)}
                      checked={type === '2' ? true : false}
                      disabled={confirm}
                      style={{ cursor: 'pointer' }}
                    />
                    國際條碼
                  </label>
                </div>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="text-end">
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={handleCancel}
            >
              取消
            </Button>
            <Button
              className={style.modal_button_submit}
              disabled={confirm}
              onClick={handleConfirm}
            >
              確認
            </Button>
        </CardFooter>
      </Card>
      {confirm && (
        <Card className={style.card}>
          <div className="d-flex justify-content-end">
            <span className="mx-1">{`共${amount}筆`}</span>
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>序號</th>
                  <th scope="col">保固序號</th>
                  {type == 2 && <th scope="col">國際條碼</th>}
                  <th scope="col">產品序號</th>
                  <th scope="col">產品名稱</th>
                  {errorMessage && <th scope="col">檢核結果</th>}
                </tr>
              </thead>
              {submitLoading && (
                <tbody>
                  <tr>
                    <td colSpan="10">
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              )}
              {!submitLoading && (
                <tbody ref={inputRef}>
                  {values.productList.map((product, index) => {
                    if (index < amount) {
                      return (
                        <ShipItemV2
                          limit={amount}
                          key={index}
                          id={index}
                          values={values}
                          errors={errors}
                          isBarcode={type == 2}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          onCreate={() => {
                            if (
                              index === values.productList.length - 1 &&
                              +amount > values.productList.length
                            ) {
                              setFieldValue('productList', [
                                ...(values?.productList || []),
                                {
                                  barcode: '',
                                  isBarcodeVaildate: false,
                                  isFocused: true,
                                  isProductVaildate: false,
                                  isWarrantyVaildate: false,
                                  product: '',
                                  productName: '',
                                  warranty: '',
                                },
                              ]);
                            }
                          }}
                          isFocused={product.isFocused}
                        />
                      );
                    }
                  })}
                </tbody>
              )}
            </Table>
          </div>
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
          <hr />
          <div className="d-flex justify-content-center">
            <Button
              className={style.modal_button_submit}
              style={{ height: '40px', width: '272px' }}
              disabled={submitLoading}
              onClick={() => {
                handleSubmit(values);
              }}
            >
              {submitLoading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: '1rem' }}
                ></span>
              )}
              <span className={'btn-wrapper--label'}>儲存</span>
            </Button>
          </div>
        </Card>
      )}
    </Layout>
  );
};

export default ShipV2;
