

import {
  ADD_BINDING_PRODUCT_FAIL,
  ADD_BINDING_PRODUCT_IDLE,
  ADD_BINDING_PRODUCT_REQUEST,
  ADD_BINDING_PRODUCT_SUCCESS,
  CREATE_GIFT_FAIL,
  CREATE_GIFT_IDLE,
  CREATE_GIFT_REQUEST,
  CREATE_GIFT_SUCCESS,
  GET_BINDING_PRODUCT_LIST_FAIL,
  GET_BINDING_PRODUCT_LIST_REQUEST,
  GET_BINDING_PRODUCT_LIST_SUCCESS,
  GET_GIFTS_LIST_FAIL,
  GET_GIFTS_LIST_REQUEST,
  GET_GIFTS_LIST_SUCCESS,
  GET_GIFT_FAIL,
  GET_GIFT_REQUEST,
  GET_GIFT_SUCCESS,
  UPDATE_GIFT_FAIL,
  UPDATE_GIFT_IDLE,
  UPDATE_GIFT_REQUEST,
  UPDATE_GIFT_SUCCESS,
} from 'redux/constants/data/GiftsConstants';

export const getGiftsListReducer = (
  state = { error: null, gifts: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GIFTS_LIST_REQUEST:
      return { error: null, gifts: {}, loading: true, success: false };
    case GET_GIFTS_LIST_SUCCESS:
      return { ...state, gifts: payload, loading: false };
    case GET_GIFTS_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getGiftReducer = (
  state = { error: null, gift: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GIFT_REQUEST:
      return { error: null, gift: {}, loading: true, success: false };
    case GET_GIFT_SUCCESS:
      if (payload?.freebieImg) {
        try {
          let baseUrl = '';
          if (payload?.freebieImgInfo?.imageUrl) {
            const strs = payload.freebieImgInfo.imageUrl.split('/');
            strs.pop();
            baseUrl = strs.join('/');
          }
          payload.freebieImg = JSON.parse(payload.freebieImg).map(img => {
            return {
              ...img,
              id: img.id,
              url: `${baseUrl}/${img.image || img.imageUrl || ''}`,
            };
          });
        } catch (e) {
          payload.freebieImg = [
            {
              ...payload.freebieImgInfo,
              url: payload.freebieImgInfo.imageUrl,
            },
          ];
        }
      }
      return { ...state, gift: payload, loading: false };
    case GET_GIFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const createGiftReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_GIFT_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_GIFT_REQUEST:
      return { ...state, loading: true };
    case CREATE_GIFT_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_GIFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateGiftReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_GIFT_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_GIFT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_GIFT_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_GIFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getBindingProductListReducer = (
  state = { error: null, loading: false, productList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BINDING_PRODUCT_LIST_REQUEST:
      return { error: null, loading: true, productList: {}, success: false };
    case GET_BINDING_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, productList: payload };
    case GET_BINDING_PRODUCT_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const addBindingProductReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_BINDING_PRODUCT_IDLE:
      return { error: null, loading: false, success: false };
    case ADD_BINDING_PRODUCT_REQUEST:
      return { ...state, loading: true };
    case ADD_BINDING_PRODUCT_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_BINDING_PRODUCT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
