import Layout from 'components/Layout';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { DELIVERY_LIST_SAVE_RESET } from 'redux/constants/data/deliveryConstants';
import style from 'styles/layout.module.css';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import {
  getDeliveryList,
  saveDeliveryList,
} from '../redux/actions/data/deliveryActions';

const CheckoutDelivery = () => {
  const dispatch = useDispatch();

  const { loading, deliveryList } = useSelector(state => state.deliveryList);
  const handleGetDeliveryList = () => {
    dispatch(getDeliveryList());
  };
  useEffect(() => {
    handleGetDeliveryList();
  }, []);

  const { values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      deliveryList,
    },
    async onSubmit(formModel) {
      const params = formModel.deliveryList.map(i => ({
        basicDeliveryCharge: i.basicDeliveryCharge,
        basicDeliveryCharge_Remote: i.basicDeliveryCharge_Remote,
        freeofChargeAmount: i.freeofChargeAmount,
        freeofChargeAmount_Remote: i.freeofChargeAmount_Remote,
        id: i.id,
        isDisabled: i.isDisabled,
      }));
      dispatch(saveDeliveryList(params));
    },
  });
  const { success } = useSelector(state => state.deliveryListSave);
  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功!');
    success && handleGetDeliveryList();
    return () => {
      dispatch({ type: DELIVERY_LIST_SAVE_RESET });
    };
  }, [success]);

  useEffect(() => {
    if (deliveryList?.length > 0) {
      CommonUtil.consoleLog({ anno: 'response', data: deliveryList });
    }
  }, [deliveryList]);
  return (
    <Layout
      pageTitle="配送設定"
      items={[
        { isActive: false, page_name: '購物相關設定' },
        { isActive: true, page_name: '配送設定' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}> 運費為NT$0，前台顯示免運費。</span>
        </div>
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            {' '}
            配送方式套用全部商品，若各別商品需排除配送方式，請至【商品管理&gt;商品編輯&gt;付款配送方式】設定。
          </span>
        </div>
      </Alert>
      <Card className={style.card}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>配送方式</th>
                <th>運費設定</th>
                <th>可搭配的付款方式</th>
                <th>停用</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody className={style.table_body}>
                {deliveryList?.length > 0 ? (
                  deliveryList.map((i, index) => (
                    <tr key={i.id}>
                      <td>{i.logisticsWayName}</td>
                      <td>
                        <FormGroup>
                          <span>【本島】基本運費為 NT$</span>{' '}
                          <Input
                            type="number"
                            className="w100 d-inline-block"
                            name={`deliveryList[${index}].basicDeliveryCharge`}
                            onChange={handleChange}
                            value={
                              values.deliveryList[index]?.basicDeliveryCharge
                            }
                          />
                          <span style={{ fontWeight: 'bold' }}>，</span>消費滿
                          NT${' '}
                          <Input
                            type="number"
                            className="w100 d-inline-block"
                            name={`deliveryList[${index}].freeofChargeAmount`}
                            onChange={handleChange}
                            value={
                              values.deliveryList[index]?.freeofChargeAmount
                            }
                          />
                          <div
                            style={{
                              display: 'inline-block',
                              marginLeft: '0.3rem',
                            }}
                          >
                            (含以上)免運費
                          </div>
                        </FormGroup>
                        {(i.logisticsWay === 1 || i.logisticsWay === 3) && (
                          <div>
                            【離島】基本運費為 NT${' '}
                            <Input
                              type="number"
                              className="w100 d-inline-block"
                              name={`deliveryList[${index}].basicDeliveryCharge_Remote`}
                              onChange={handleChange}
                              value={
                                values.deliveryList[index]
                                  ?.basicDeliveryCharge_Remote
                              }
                            />
                            <span style={{ fontWeight: 'bold' }}>，</span>
                            消費滿NT${' '}
                            <Input
                              type="number"
                              className="w100 d-inline-block"
                              name={`deliveryList[${index}].freeofChargeAmount_Remote`}
                              onChange={handleChange}
                              value={
                                values.deliveryList[index]
                                  ?.freeofChargeAmount_Remote
                              }
                            />
                            <div
                              style={{
                                display: 'inline-block',
                                marginLeft: '0.3rem',
                              }}
                            >
                              (含以上)免運費
                            </div>
                          </div>
                        )}
                        {i.logisticsWay === 2 && (
                          <div>
                            <AiOutlineInfoCircle />{' '}
                            <span>全家不支援離島配送服務</span>
                          </div>
                        )}
                      </td>
                      <td>
                        {i.payWayNames.split(',').map((way, index) => (
                          <div key={index}>{way}</div>
                        ))}
                      </td>
                      <td>
                        <Label check>
                          <div
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                              margin: '0.1rem',
                            }}
                          >
                            <input
                              type="checkbox"
                              name={`deliveryList[${index}].isDisabled`}
                              onChange={handleChange}
                              checked={values.deliveryList[index]?.isDisabled}
                              style={{
                                height: '18px',
                                margin: '0.3rem',
                                width: '18px',
                              }}
                            />
                            <div style={{ whiteSpace: 'nowrap' }}>停用</div>
                          </div>
                        </Label>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
          <p className={style.fs_14}>
            異動時間：
            {deliveryList.length > 0
              ? `${deliveryList[0].updateDate} ${deliveryList[0].updateTime} ${deliveryList[0].updater}`
              : ''}
          </p>
          <hr />
          <div className="text-center">
            <Button
              color="primary"
              className={style.modal_button_submit}
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              儲存
            </Button>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default CheckoutDelivery;
