import Layout from 'components/Layout';
import RcPagination from 'rc-pagination';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Table,
} from 'reactstrap';


import Loader from '../components/Loader';
import {
  deleteCafeNewsDetail,
  getCafeNewsList,
} from '../redux/actions/data/uuCafeNewsAction';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const CafeNews = () => {
  const dispatch = useDispatch();
  const { loading, newsList } = useSelector(state => state.cafeNewSList);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);

  const handlePageChange = useCallback(
    (value, keyword) => {
      dispatch(
        getCafeNewsList({
          keyword,
          page: value,
          page_size: newsList.page_size || 10,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, newsList],
  );

  const handleDeleteNews = useCallback(
    async news => {
      try {
        await deleteCafeNewsDetail(news);
        handlePageChange(1, keyword);
        MessageUtil.toastSuccess('刪除成功!');
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [handlePageChange, keyword],
  );

  useEffect(() => {
    handlePageChange(1, keyword);
  }, []);

  return (
    <Layout
      pageTitle="最新消息"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '最新消息' },
      ]}
    >
      <Card className={style.search_card} style={{ overflow: 'hidden' }}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <FormGroup className="d-flex align-items-center">
            <label className="mx-3">關鍵字:</label>
            <Input
              style={{ maxWidth: '200px' }}
              placeholder="輸入關鍵字"
              value={keyword}
              onChange={e => {
                setKeyword(e.target.value);
              }}
            />
          </FormGroup>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  history.push('/UUCafe/News/add');
                }}
              >
                新增
              </Button>
            </div>
            <p className="small text-end mb-0">共 {newsList.total_count} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th nowrap="nowrap">標題</th>
                    <th nowrap="nowrap">上架區間</th>
                    <th nowrap="nowrap">圖片</th>
                    <th style={{ minWidth: '120px' }}>異動日期</th>
                    <th>功能</th>
                  </tr>
                </thead>
                <tbody>
                  {newsList.datas.map((data, index) => (
                    <tr key={index}>
                      <td>{data.title}</td>
                      <td>
                        <div className="text-nowrap">
                          起: {data.startDateTime}
                        </div>
                        <div className="text-nowrap">
                          迄: {data.endDateTime}
                        </div>
                      </td>
                      <td className="text-center" width="150">
                        <img
                          className={style.w200}
                          src={data.imgUrl}
                          alt={data.imgFile}
                        />
                      </td>
                      <td>
                        <div className="text-nowrap">{data.updateDateTime}</div>
                        <div>{data.updater}</div>
                      </td>
                      <td nowrap="nowrap" className="text-center">
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="primary"
                          onClick={() => {
                            history.push(`/UUCafe/News/${data.id}`);
                          }}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="danger"
                          onClick={() => {
                            MessageUtil.submitConfirm({
                              doneFun: () => {
                                handleDeleteNews(data);
                              },
                              title: '確認是否刪除',
                            });
                          }}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={newsList.page_size || 10}
                total={newsList.total_count || 1}
                onChange={value => {
                  handlePageChange(value, keyword);
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </Layout>
  );
};

export default CafeNews;
