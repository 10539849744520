import { useRequest } from 'utils/useFetch';

import {
  UUCAFE_MENU_SETTINGS_FAIL,
  UUCAFE_MENU_SETTINGS_REQUEST,
  UUCAFE_MENU_SETTINGS_SUCCESS,
  UUCAFE_MENU_SETTINGS_UPDATE_FAIL,
  UUCAFE_MENU_SETTINGS_UPDATE_REQUEST,
  UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeMenuSettingsConstants';

export const getCafeMenuSettings = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MENU_SETTINGS_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MENU_SETTINGS_REQUEST,
      successConstant: UUCAFE_MENU_SETTINGS_SUCCESS,
      url: '/Backend/Cafe_SysSetting/cafe_menuSetting',
    });
  };
};

export const updateCafeMenuSettings = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MENU_SETTINGS_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MENU_SETTINGS_UPDATE_REQUEST,
      successConstant: UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS,
      url: '/Backend/Cafe_SysSetting/cafe_menuSetting/update',
    });
  };
};
