export const VOUCHER_SETTINGS_DATA = 'VOUCHER_SETTINGS_DATA';

export const GET_VOUCHER_LIST_REQUEST = 'GET_VOUCHER_LIST_REQUEST';
export const GET_VOUCHER_LIST_SUCCESS = 'GET_VOUCHER_LIST_SUCCESS';
export const GET_VOUCHER_LIST_FAIL = 'GET_VOUCHER_LIST_FAIL';

export const CREATE_VOUCHER_IDLE = 'CREATE_VOUCHER_IDLE';

export const GET_VOUCHER_DETAIL_REQUEST = 'GET_VOUCHER_DETAIL_REQUEST';
export const GET_VOUCHER_DETAIL_SUCCESS = 'GET_VOUCHER_DETAIL_SUCCESS';
export const GET_VOUCHER_DETAIL_FAIL = 'GET_VOUCHER_DETAIL_FAIL';
export const UPDATE_VOUCHER_DETAIL_IDLE = 'UPDATE_VOUCHER_DETAIL_IDLE';