import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { OrderItemFreebieInfo } from 'redux/actions/data/orderActions';

import style from '../../styles/layout.module.css';

import { ActivityGifts, ProductGifts, ActivityFreebies } from './DetailTable';

const DetailModal = ({ type, id, toggle, isOpen }) => {
  const [list, setList] = useState();

  useEffect(() => {
    setList(undefined);

    if (isOpen) {
      OrderItemFreebieInfo(id)
          .then(res => {
            console.log(res);
            if (type === '商品贈品') {
              setList(res.productFreebies);
            }
            if (type === '活動贈品') {
              setList(res.activityFreebies);
            }
            if (type === '贈品券') {
              setList(res.couponFreebies);
            }
          })
          .catch(e => {
            console.log(e);
            setList([]);
          });
    }
  }, [id, type, isOpen]);

  return (
      <Modal isOpen={isOpen} centered zIndex={2000} size="xl">
        <ModalHeader toggle={toggle} className={style.modal_head}>
          贈品明細
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          <div className="table-responsive">
            <Table hover bordered striped>
              {type === '商品贈品' && <ProductGifts list={list} />}

              {type === '贈品券' && <ActivityFreebies list={list} />}

              {type === '活動贈品' && <ActivityGifts list={list} />}
            </Table>
          </div>
        </ModalBody>
      </Modal>
  );
};

export default DetailModal;
