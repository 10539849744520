import Layout from 'components/Layout';
import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


import ShopEditor from '../components/uuCafeCommon/ShopEditor';
import {
  addCafeShopDetail,
  getCafeShopDetail,
  updateCafeShopDetail,
} from '../redux/actions/data/uuCafeShopAction';
import { UUCAFE_SHOP_DETAIL_SUCCESS } from '../redux/constants/data/uuCafeShopConstants';
import history from '../utils/createHistory';

const CafeShopDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isSubmit = useRef(false);
  const { error, loading, success, detail } = useSelector(
    state => state.cafeShopDetail,
  );

  const handleSubmit = useCallback(
    props => {
      if (props.isValid) {
        isSubmit.current = true;
        const formData = new FormData();
        formData.append('name', props.values.name);
        formData.append('name_en', props.values.name_en);
        formData.append('phone', props.values.phone);
        formData.append('countryId', Number(props.values.countryId));
        formData.append('cityId', Number(props.values.cityId));
        formData.append('townId', Number(props.values.townId));
        formData.append('address', props.values.address);
        formData.append('address_en', props.values.address_en);
        formData.append('longitude', Number(props.values.longitude));
        formData.append('latitude', Number(props.values.latitude));
        formData.append('businessHours', props.values.businessHours);
        formData.append('businessHours_en', props.values.businessHours_en);
        formData.append('otherDesc', props.values.otherDesc);
        formData.append('otherDesc_en', props.values.otherDesc_en);
        props.values.services.forEach(s => {
          formData.append('serviceIds[]', Number(s.value));
        });

        if (props.values.img_files) {
          formData.append('img_files', props.values.img_files || null);
        } else {
          if (detail.imgFile && detail.imgUrl) {
            formData.append('img_files', []);
          }
        }
        if (detail.id) {
          formData.append('id', detail.id);
          dispatch(updateCafeShopDetail(formData));
        } else {
          dispatch(addCafeShopDetail(formData));
        }
      }
    },
    [dispatch, detail],
  );

  const goBack = useCallback(() => {
    history.push('/UUCafe/CafeShop');
  }, []);

  useEffect(() => {
    if (params) {
      if (params.id === 'add') {
        dispatch({
          payload: {
            address: '',
            address_en: '',
            businessHours: '',
            businessHours_en: '',
            cityId: '',
            countryId: '',
            imgFile: '',
            imgUrl: '',
            latitude: '',
            longitude: '',
            name: '',
            name_en: '',
            otherDesc: '',
            otherDesc_en: '',
            phone: '',
            serviceIds: [],
            townId: '',
          },
          type: UUCAFE_SHOP_DETAIL_SUCCESS,
        });
      } else {
        dispatch(getCafeShopDetail(params));
      }
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        goBack();
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail, goBack]);

  return (
    <Layout
      pageTitle={`門市設定-${detail.id ? '編輯' : '新增'}`}
      items={[
        { isActive: true, page_name: 'UUCafe' },
        {
          isActive: false,
          page_name: `門市設定 -${detail.id ? '編輯' : '新增'}`,
          to: '/UUCafe/CafeShop',
        },
      ]}
    >
      <ShopEditor
        detail={detail}
        loading={loading}
        handleSubmit={handleSubmit}
        goBack={goBack}
      />
    </Layout>
  );
};

export default CafeShopDetail;
