import Layout from 'components/Layout';
import CategorySettingsModal from 'components/messageCategory/CategorySettingsModal';
import CategoryTable from 'components/messageCategory/CategoryTable';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import { getMessageCategoryList } from 'redux/actions/data/messageActions';
import {
  CREATE_MESSAGE_CATEGORY_IDLE,
  DELETE_MESSAGE_CATEGORY_IDLE,
  UPDATE_MESSAGE_CATEGORY_IDLE,
} from 'redux/constants/data/MessageConstant';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';


import style from '../styles/layout.module.css';

const MessageCategory = () => {
  const dispatch = useDispatch();
  const [isCategorySettingsModalOpen, setIsCategorySettingsModalOpen] =
    useToggle();
  const [isEdit, setIsEdit] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const query = useQuery();
  const { list } = useSelector(state => state.getMessageCategoryList);
  const { success: createSuccess, error: createError } = useSelector(
    state => state.createMessageCategory,
  );
  const { success: updateSuccess, error: updateError } = useSelector(
    state => state.updateMessageCategory,
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.deleteMessageCategory,
  );
  const cateOptions = useMemo(() => {
    return list.map(cate => ({
      label: cate.parent_name,
      label_en: cate.parent_name_en,
      value: cate.parentId,
    }));
  }, [list]);

  const identity = useMemo(() => {
    return query.value.type === 'services' ? '客服' : '業務';
  }, []);

  useEffect(() => {
    // call list api
    dispatch(
      getMessageCategoryList({
        catType: identity,
        parentId: null,
      }),
    );
  }, [identity]);

  useEffect(() => {
    if (createSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: CREATE_MESSAGE_CATEGORY_IDLE });
      setIsCategorySettingsModalOpen();
      dispatch(
        getMessageCategoryList({
          catType: identity,
          parentId: null,
        }),
      );
    }

    if (createError) {
      MessageUtil.alertWanring('儲存失敗', createError);
      dispatch({ type: CREATE_MESSAGE_CATEGORY_IDLE });
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      setIsCategorySettingsModalOpen();
      dispatch({ type: UPDATE_MESSAGE_CATEGORY_IDLE });
      dispatch(
        getMessageCategoryList({
          catType: identity,
          parentId: null,
        }),
      );
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: UPDATE_MESSAGE_CATEGORY_IDLE });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      dispatch({ type: DELETE_MESSAGE_CATEGORY_IDLE });
      dispatch(
        getMessageCategoryList({
          catType: identity,
          parentId: null,
        }),
      );
    }

    if (deleteError) {
      MessageUtil.alertWanring('刪除失敗', deleteError);
      dispatch({ type: DELETE_MESSAGE_CATEGORY_IDLE });
    }
  }, [deleteSuccess, deleteError]);

  return (
    <Layout
      pageTitle={'分類選單管理'}
      items={[
        { isActive: true, page_name: '訊息管理' },
        { isActive: true, page_name: '訊息紀錄' },
        { isActive: false, page_name: '分類選單管理' },
      ]}
    >
      <Card className={style.card}>
        <div className={style.show_flex}>
          <Button
            color="success"
            className="mb-3"
            onClick={() => {
              setIsEdit(false);
              setIsCategorySettingsModalOpen();
            }}
          >
            新增
          </Button>
          <div style={{ margin: 'auto' }} />
          <Button
            className={`${style.sort_button} mb-3`}
            onClick={() => {
              history.push(`/MessageCategorySorting?identity=${identity}`);
            }}
          >
            排序
          </Button>
        </div>
        <CategoryTable
          setIsEdit={setIsEdit}
          setIsCategorySettingsModalOpen={setIsCategorySettingsModalOpen}
          setCurrentData={setCurrentData}
        />
      </Card>
      {isCategorySettingsModalOpen && (
        <CategorySettingsModal
          isOpen={isCategorySettingsModalOpen}
          toggle={setIsCategorySettingsModalOpen}
          isEdit={isEdit}
          options={cateOptions}
          data={currentData}
          type={identity}
        />
      )}
    </Layout>
  );
};

export default MessageCategory;
