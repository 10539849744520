import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';

const TableList = ({
  datas = [],
  setIsContentSettingsModalOpen,
  setCurrentData,
  setIsEdit,
  onDelete,
}) => {
  return (
    <div className="table-responsive">
      <Table bordered hover striped>
        <TableHead>
          <tr>
            <th>標題</th>
            <th>內容</th>
            <th>功能</th>
          </tr>
        </TableHead>
        <tbody>
          {datas?.length > 0 ? (
            datas.map((data, index) => (
              <tr key={index}>
                <td>{data.title}</td>
                <td className={style['newline-max-2']}>{data.desc}</td>
                <td className="text-center">
                  <Button
                    color="primary"
                    outline
                    className="btn-sm me-2"
                    onClick={() => {
                      setCurrentData({
                        ...data,
                        index,
                      });
                      setIsContentSettingsModalOpen();
                      setIsEdit(true);
                    }}
                  >
                    編輯
                  </Button>
                  <Button
                    color="danger"
                    outline
                    className="btn-sm me-2"
                    onClick={() => {
                      onDelete({
                        ...data,
                        index,
                      });
                    }}
                  >
                    刪除
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <TableHasNoData colNumber={3} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

const ContentSettings = ({
  setIsContentSettingsModalOpen,
  setCurrentData,
  setIsEdit,
  onDelete,
}) => {
  const { settings } = useSelector(state => state.getRewardsPointsSettings);
  return (
    <Card className={style.card}>
      <div className={style.formGroup}>
        <label className="w100 required">啟用狀態</label>
        <span>{settings?.enableSetting?.status === 1 ? '開啟' : '關閉'}</span>
      </div>
      <div className={style.formGroup}>
        <label className="w100 required">給點計算方式</label>
        <span>單筆訂單金額計算</span>
      </div>
      <div className={style.formGroup}>
        <label className="required">點數說明</label>
      </div>
      <div>
        <Button
          color="success w100 mb-3"
          onClick={() => {
            setCurrentData({});
            setIsContentSettingsModalOpen();
            setIsEdit(false);
          }}
        >
          新增
        </Button>
        <TableList
          datas={settings?.enableSetting?.descs}
          setIsContentSettingsModalOpen={setIsContentSettingsModalOpen}
          setCurrentData={setCurrentData}
          setIsEdit={setIsEdit}
          onDelete={onDelete}
        />
      </div>
    </Card>
  );
};

export default ContentSettings;
