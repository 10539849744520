import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  ADD_MEMBER_LEVEL_SETTINGS_FAIL,
  ADD_MEMBER_LEVEL_SETTINGS_REQUEST,
  ADD_MEMBER_LEVEL_SETTINGS_SUCCESS,
  COPY_MEMBER_LEVEL_LIST_FAIL,
  COPY_MEMBER_LEVEL_LIST_REQUEST,
  COPY_MEMBER_LEVEL_LIST_SUCCESS,
  DELETE_MEMBER_LEVEL_DRAFT_FAIL,
  DELETE_MEMBER_LEVEL_DRAFT_REQUEST,
  DELETE_MEMBER_LEVEL_DRAFT_SUCCESS,
  DELETE_ONE_DRAFT_FAIL,
  DELETE_ONE_DRAFT_REQUEST,
  DELETE_ONE_DRAFT_SUCCESS,
  EXPORT_MEMBER_INFO_FAIL,
  EXPORT_MEMBER_INFO_REQUEST,
  EXPORT_MEMBER_INFO_SUCCESS,
  GET_MEMBER_LEVEL_OPTIONS_FAIL,
  GET_MEMBER_LEVEL_OPTIONS_REQUEST,
  GET_MEMBER_LEVEL_OPTIONS_SUCCESS,
  GET_MEMBER_LEVEL_SETTINGS_FAIL,
  GET_MEMBER_LEVEL_SETTINGS_REQUEST,
  GET_MEMBER_LEVEL_SETTINGS_SUCCESS,
  GET_MEMBER_SHIP_GIFT_FAIL,
  GET_MEMBER_SHIP_GIFT_REQUEST,
  GET_MEMBER_SHIP_GIFT_SUCCESS,
  GET_PUBLISH_DATE_FAIL,
  GET_PUBLISH_DATE_REQUEST,
  GET_PUBLISH_DATE_SUCCESS,
  IMPORT_MEMBER_INFO_FAIL,
  IMPORT_MEMBER_INFO_REQUEST,
  IMPORT_MEMBER_INFO_SUCCESS,
  MEMBER_INFO_GET_FAIL,
  MEMBER_INFO_GET_REQUEST,
  MEMBER_INFO_GET_SUCCESS,
  MEMBER_INFO_UPDATE_FAIL,
  MEMBER_INFO_UPDATE_REQUEST,
  MEMBER_INFO_UPDATE_SUCCESS,
  MEMBER_LIST_FAIL,
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  UPDATE_MEMBER_LEVEL_DRAFT_FAIL,
  UPDATE_MEMBER_LEVEL_DRAFT_REQUEST,
  UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS,
  UPDATE_MEMBER_LEVEL_FAIL,
  UPDATE_MEMBER_LEVEL_REQUEST,
  UPDATE_MEMBER_LEVEL_SETTINGS_FAIL,
  UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST,
  UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS,
  UPDATE_MEMBER_LEVEL_SUCCESS,
  UPDATE_PUBLISH_DATE_FAIL,
  UPDATE_PUBLISH_DATE_REQUEST,
  UPDATE_PUBLISH_DATE_SUCCESS,
} from '../../constants/data/memberConstants';

//get member list
export const getMemberList = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Member/listV2' });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MEMBER_LIST_FAIL,
      method: 'post',
      requestConstant: MEMBER_LIST_REQUEST,
      successConstant: MEMBER_LIST_SUCCESS,
      url: '/Backend/Member/listV2',
    });
  };
};

export const getMemberListCount = async memberLevelSettingId => {
  return await request({
    data: {
      memberLevelSettingId,
      page: 1,
      page_size: 1,
    },
    method: 'post',
    url: '/Backend/Member/listV2',
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

//update member info
export const updateMemberInfo = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Member/Update' });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MEMBER_INFO_UPDATE_FAIL,
      method: 'post',
      requestConstant: MEMBER_INFO_UPDATE_REQUEST,
      successConstant: MEMBER_INFO_UPDATE_SUCCESS,
      url: '/Backend/Member/Update',
    });
  };
};

//get member info
export const getMemberInfo = id => {
  const url = `/Backend/Member/GetInfo/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MEMBER_INFO_GET_FAIL,
      method: 'get',
      requestConstant: MEMBER_INFO_GET_REQUEST,
      successConstant: MEMBER_INFO_GET_SUCCESS,
      url,
    });
  };
};

export const getMemberLevelList = data => {
  const url = '/Backend/MemberLevelSetting/listSetting_0204';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_MEMBER_LEVEL_SETTINGS_FAIL,
      method: 'post',
      requestConstant: GET_MEMBER_LEVEL_SETTINGS_REQUEST,
      successConstant: GET_MEMBER_LEVEL_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const addMemberLevelSettings = data => {
  const url = 'Backend/MemberLevelSetting/createSetting_v2';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ADD_MEMBER_LEVEL_SETTINGS_FAIL,
      method: 'post',
      requestConstant: ADD_MEMBER_LEVEL_SETTINGS_REQUEST,
      successConstant: ADD_MEMBER_LEVEL_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const updateMemberLevelSettings = data => {
  const url = '/Backend/MemberLevelSetting/updateData';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_MEMBER_LEVEL_SETTINGS_FAIL,
      method: 'post',
      requestConstant: UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST,
      successConstant: UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const updateMemberLevel = data => {
  const url = '/Backend/MemberLevelSetting/updateMemberLevel';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_MEMBER_LEVEL_FAIL,
      method: 'post',
      requestConstant: UPDATE_MEMBER_LEVEL_REQUEST,
      successConstant: UPDATE_MEMBER_LEVEL_SUCCESS,
      url,
    });
  };
};

export const getMemberLevelOptions = () => {
  const url = '/Backend/MemberLevelSetting/GetSettingOption';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MEMBER_LEVEL_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_MEMBER_LEVEL_OPTIONS_REQUEST,
      successConstant: GET_MEMBER_LEVEL_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const exportMemberInfo = data => {
  const url = '/Backend/Member/Export';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: EXPORT_MEMBER_INFO_FAIL,
      method: 'post',
      requestConstant: EXPORT_MEMBER_INFO_REQUEST,
      successConstant: EXPORT_MEMBER_INFO_SUCCESS,
      url,
    });
  };
};

export const importMemberInfo = data => {
  const url = '/Backend/Member/Import';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: IMPORT_MEMBER_INFO_FAIL,
      method: 'post',
      requestConstant: IMPORT_MEMBER_INFO_REQUEST,
      successConstant: IMPORT_MEMBER_INFO_SUCCESS,
      url,
    });
  };
};

export const copyMemberLevelList = () => {
  const url = '/Backend/MemberLevelSetting/copy';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: COPY_MEMBER_LEVEL_LIST_FAIL,
      method: 'get',
      requestConstant: COPY_MEMBER_LEVEL_LIST_REQUEST,
      successConstant: COPY_MEMBER_LEVEL_LIST_SUCCESS,
      url,
    });
  };
};

export const deleteMemberLevelDraft = () => {
  const url = '/Backend/MemberLevelSetting/deleteDraft';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DELETE_MEMBER_LEVEL_DRAFT_FAIL,
      method: 'get',
      requestConstant: DELETE_MEMBER_LEVEL_DRAFT_REQUEST,
      successConstant: DELETE_MEMBER_LEVEL_DRAFT_SUCCESS,
      url,
    });
  };
};

export const updateMemberLevelDraft = data => {
  const url = '/Backend/MemberLevelSetting/updateSetting_v2';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_MEMBER_LEVEL_DRAFT_FAIL,
      method: 'post',
      requestConstant: UPDATE_MEMBER_LEVEL_DRAFT_REQUEST,
      successConstant: UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS,
      url,
    });
  };
};

export const getPublishDate = () => {
  const url = '/Backend/MemberLevelSetting/getDraftDate';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_PUBLISH_DATE_FAIL,
      method: 'get',
      requestConstant: GET_PUBLISH_DATE_REQUEST,
      successConstant: GET_PUBLISH_DATE_SUCCESS,
      url,
    });
  };
};

export const updatePublishDate = data => {
  const url = '/Backend/MemberLevelSetting/updateDraftDate';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_PUBLISH_DATE_FAIL,
      method: 'post',
      requestConstant: UPDATE_PUBLISH_DATE_REQUEST,
      successConstant: UPDATE_PUBLISH_DATE_SUCCESS,
      url,
    });
  };
};

export const deleteOneDraft = id => {
  const url = `/Backend/MemberLevelSetting/deleteDraft/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DELETE_ONE_DRAFT_FAIL,
      method: 'get',
      requestConstant: DELETE_ONE_DRAFT_REQUEST,
      successConstant: DELETE_ONE_DRAFT_SUCCESS,
      url,
    });
  };
};

/** 補發會員優惠券
 */
export const reissueMemberCoupon = async (
  data = {
    code: '',
    id: 0,
  },
) => {
  CommonUtil.consoleLog(data);
  return await request({
    data,
    method: 'post',
    url: '/Backend/Member/ReissueMemberCoupon',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const getMembershipList = (
  data = {
    activityType: '',
    availableWay: '',
    endTime: '',
    keyword: '',
    memberLevelId: 0,
    numberQuery: '',
    numberQueryType: '',
    order: '',
    order_by: '',
    page: 0,
    page_size: 10,
    startTime: '',
    status: [0],
    timeType: '',
    type: 0,
  },
) => {
  return request({
    data: {
      ...data,
      page_size: 10,
    },
    method: 'post',
    url: '/Backend/MembershipGift/List',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const getMembershipGiftById = id => {
  const url = `/Backend/MembershipGift/Get/${id}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MEMBER_SHIP_GIFT_FAIL,
      method: 'get',
      requestConstant: GET_MEMBER_SHIP_GIFT_REQUEST,
      successConstant: GET_MEMBER_SHIP_GIFT_SUCCESS,
      url,
    });
  };
};

/**
 * id integer($int32) ID
 * activityCode string 活動序號
 * activityType string 活動類型 生日禮/升等禮/開卡禮
 * couponType integer($int32) 活動卷型 固定為 1折價券
 * memberLevelId string 會員等級
 * rangeStartTime string 目標條件區間起 (生日月分/升等時間)
 * rangeEndTime string 目標條件區間迄 (生日月分/升等時間)
 * sendTimeType string 發送時間類型 生日當月/生日當月前/升等當日發送/升等後隔日/升等後隔月
 * sendTimeNumber  string 發卷時間 N號/N天
 * discountType integer($int32) 折扣類型 1商品折抵 2商品打折
 * usableType integer($int32) 何時可使用 1領取後即可取用 2領取後第N日可使用
 * dueType integer($int32) 使用期限 1自訂區間 2依日期推算
 * isGetTime  boolean 與領取時間相同
 * redeemStartTime string 使用期限-起
 * redeemEndTime string 使用期限-迄
 * usableDays integer($int32) 可使用天數
 * couponQtyEach integer($int32) 每種面額提供張數 (僅生日禮、升等禮有)
 * useMin integer($int32) 使用門檻
 * useMinAmount integer($int32) 使用門檻數字
 * discountMax integer($int32) 折抵上限
 * discountMaxAmount  integer($int32) 折抵上限數字
 * remiderNum integer($int32) 剩餘張數提醒
 * availableWay string 適用通路
 * isUsedwithDiscount  boolean  是否與折扣活動合併使用
 * storeRedeemShowWay  string 門市核銷券號顯示 qrcode/barcode
 * productRange string  適用商品-活動範圍 all/picked
 * name  string  名稱
 * name_en string 名稱(英)
 * desc string 說明
 * desc_en string  說明(英)
 * couponWording string 券面文字
 * couponWording_en string 券面文字(英)
 * couponImg string 圖片
 * couponImgFiles  array 圖片檔案
 * products string 一般商品
 * groupProducts  string 組合商品
 * couponSettings string
 **/

export const createMembershipGift = (data = new FormData()) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/MembershipGift/Create',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};


export const ClearMemberInfo = (phone ='') => {
  return request({
    data:{
      phone
    },
    method: 'post',
    url: '/Backend/Member/ClearMemberInfo',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const updateMembershipGift = (data = new FormData()) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/MembershipGift/Update',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const deleteMembershipGift = id => {
  return request({
    method: 'get',
    params: {
      id,
    },
    url: '/Backend/MembershipGift/Delete',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};


export const clearMemberInfo = (memberId) => {
  return request({
    method: 'get',
    url: `/Backend/Member/ClearMemberInfo/${memberId}`,
  }).then(res => {
    return res;

  });
};
