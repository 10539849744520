import React from 'react';
import { NavItem, NavLink } from 'reactstrap';

import style from '../../styles/layout.module.css';

export const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        } ${tab === 4 ? style.modal_button_submit_extend : ''}`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};
