import Select from 'react-select';
import { emptyOption } from 'utils';

const SearchSelector = ({
  options,
  emptyVal,
  emptyLabel = '全部',
  name,
  setFieldValue,
  values,
  className,
  isMulti = false,
}) => {
  return (
    <Select
      isMulti={isMulti}
      className={`w200 ${className}`}
      options={[emptyOption(emptyLabel, emptyVal), ...(options ? options : [])]}
      name={name}
      defaultValue={emptyOption(emptyLabel, emptyVal)}
      onChange={({ value }) => setFieldValue(name, value)}
      value={
        options?.find(i => i.value === values[name]) ||
        emptyOption(emptyLabel, emptyVal)
      }
    />
  );
};
export const SearchSelectorMuti = ({
  options,
  name,
  setFieldValue,
  values,
  className,
}) => {
  return (
    <Select
      isMulti
      className={`${className}`}
      options={options || []}
      name={name}
      onChange={val => {
        setFieldValue(name, val);
      }}
      value={values[name]}
    />
  );
};

export default SearchSelector;
