import {
  LOG_RECORD_REQUEST,
  LOG_RECORD_SUCCESS,
  LOG_RECORD_FAIL,
  LOG_RECORD_IDLE,
  EXPORT_LOG_RECORD_REQUEST,
  EXPORT_LOG_RECORD_SUCCESS,
  EXPORT_LOG_RECORD_FAIL,
  EXPORT_LOG_RECORD_IDLE,
} from 'redux/constants/data/logRecordConstant';

export const logRecordReducer = (
  state = { data: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case LOG_RECORD_IDLE:
      return { ...state, loading: false, success: false };
    case LOG_RECORD_REQUEST:
      return { ...state, loading: true };
    case LOG_RECORD_SUCCESS:
      return { data: payload, error: null, loading: false, success: true };
    case LOG_RECORD_FAIL:
      return { data: [], error: payload, loading: false, success: false };
    default:
      return state;
  }
};

export const exportLogRecordReducer = (
  state = { error: null, loading: false, result: null, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case EXPORT_LOG_RECORD_IDLE:
      return { ...state, loading: false, success: false };
    case EXPORT_LOG_RECORD_REQUEST:
      return { ...state, loading: true };
    case EXPORT_LOG_RECORD_SUCCESS:
      return { ...state, loading: false, result: payload, success: true };
    case EXPORT_LOG_RECORD_FAIL:
      return { error: payload, loading: false, result: null, success: false };
    default:
      return state;
  }
};
