import Layout from 'components/Layout';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import { getModelList } from 'redux/actions/data/modelActions';
import { updateModelSellingState } from 'redux/actions/data/modelActions';
import { getModelOptions } from 'redux/actions/data/productActions';
import { MODEL_SELLING_STATE_UPDATE_IDLE } from 'redux/constants/data/modelConstants';
import MessageUtil from 'utils/MessageUtil';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({ onChangePage }) => {
  const { userInfo } = useSelector(state => state.userLogin);
  const dispatch = useDispatch();
  const { error: updateError, success: updateSuccess } = useSelector(
    state => state.modelSellingState,
  );
  const { values, setFieldValue, setValues } = useFormik({
    enableReinitialize: true,
    initialValues: {},
  });
  const { loading: modelListLoading, modelListInfo } = useSelector(
    state => state.modelList,
  );

  useEffect(() => {
    if (modelListInfo) {
      setValues(modelListInfo);
    }
  }, [modelListInfo]);

  useEffect(() => {
    if (updateError) {
      MessageUtil.alertWanring('狀態更新失敗', updateError);
      dispatch({ type: MODEL_SELLING_STATE_UPDATE_IDLE });
    }
    if (updateSuccess) {
      dispatch({ type: MODEL_SELLING_STATE_UPDATE_IDLE });
    }
  }, [updateError, updateSuccess]);

  const handleChangePage = page => {
    onChangePage(page);
  };

  const updateModelOptionsState = (mId, data) => {
    dispatch(updateModelSellingState({ data, mId }));
  };

  return (
    <Card className={style.card}>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>商品</th>
              <th>品牌</th>
              <th>型號</th>
              <th style={{ minWidth: '100px' }}>官網</th>
              <th>商品操作說明</th>
              <th>商品保固</th>
              <th>商品維修</th>
            </tr>
          </thead>
          <tbody>
            {values && values?.datas?.length > 0
              ? values.datas.map((model, index) => (
                  <tr key={JSON.stringify(model)}>
                    <td>{model.name}</td>
                    <td>{model.brandName}</td>
                    <td>{model.model}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={model.isSell}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isSell`, true);
                            updateModelOptionsState(model.id, {
                              result: true,
                              type: 'isSell',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 30,
                            )
                          }
                        />{' '}
                        上架
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={!model.isSell}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isSell`, false);
                            updateModelOptionsState(model.id, {
                              result: false,
                              type: 'isSell',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 30,
                            )
                          }
                        />{' '}
                        下架
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={model.isInstruction}
                          onChange={() => {
                            setFieldValue(
                              `datas[${index}].isInstruction`,
                              true,
                            );
                            updateModelOptionsState(model.id, {
                              result: true,
                              type: 'isInstruction',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 31,
                            )
                          }
                        />{' '}
                        上架
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={!model.isInstruction}
                          onChange={() => {
                            setFieldValue(
                              `datas[${index}].isInstruction`,
                              false,
                            );
                            updateModelOptionsState(model.id, {
                              result: false,
                              type: 'isInstruction',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 31,
                            )
                          }
                        />{' '}
                        下架
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={model.isWarranty}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isWarranty`, true);
                            updateModelOptionsState(model.id, {
                              result: true,
                              type: 'isWarranty',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 32,
                            )
                          }
                        />{' '}
                        上架
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={!model.isWarranty}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isWarranty`, false);
                            updateModelOptionsState(model.id, {
                              result: false,
                              type: 'isWarranty',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 32,
                            )
                          }
                        />{' '}
                        下架
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={model.isRepair}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isRepair`, true);
                            updateModelOptionsState(model.id, {
                              result: true,
                              type: 'isRepair',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 33,
                            )
                          }
                        />{' '}
                        上架
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className={style.maintain_list_checkbox}
                          checked={!model.isRepair}
                          onChange={() => {
                            setFieldValue(`datas[${index}].isRepair`, false);
                            updateModelOptionsState(model.id, {
                              result: false,
                              type: 'isRepair',
                            });
                          }}
                          disabled={
                            !userInfo?.permissions.find(
                              permission => permission.id === 33,
                            )
                          }
                        />{' '}
                        下架
                      </div>
                    </td>
                  </tr>
                ))
              : !modelListLoading && <TableHasNoData />}
          </tbody>
          {modelListLoading && <TableLoading />}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          defaultCurrent={modelListInfo?.current_page ?? 1}
          defaultPageSize={modelListInfo?.page_size ?? 10}
          total={modelListInfo?.total_count ?? 0}
          onChange={handleChangePage}
        />
      </div>
    </Card>
  );
};

const ProductSellingSettings = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const { values, handleSubmit, handleReset, handleChange, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        brandId: 0,
        isInstruction: null,
        isRepair: null,
        isSell: null,
        isWarranty: null,
        keyword: '',
        model: '',
        page: 1,
        page_size: 10,
        type: 'isSell',
      },
      onSubmit: values => {
        const body = { ...values, page: currentPage };
        delete body.type;
        dispatch(getModelList(body));
      },
    });

  useEffect(() => {
    handleSubmit();
    dispatch(getBrandOptions());
    dispatch(getModelOptions(''));
  }, []);

  useEffect(() => {
    const brand =
      brandOptions?.find(i => Number(i.value) === Number(values.brandId))
        ?.label || '';
    dispatch(getModelOptions(brand));
    setFieldValue('model', '');
  }, [values.brandId]);

  const handleChangePage = page => {
    setCurrentPage(page);
    handleSubmit();
  };

  return (
    <Layout
      pageTitle={'商品上架設定'}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: false, page_name: '商品上架設定' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader className="bg-white">搜尋條件</CardHeader>
        <CardBody className={style.card_body}>
          <Row className="gap-3">
            <FormGroup
              className="col-sm-12 d-flex gap-1"
              style={{ maxWidth: '250px' }}
            >
              <label className="col-form-label text-nowrap">關鍵字</label>
              <div>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  name="keyword"
                  placeholder="商品料號、商品名稱"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
            </FormGroup>
            <FormGroup
              className="col-sm-12 d-flex gap-1"
              style={{ maxWidth: '250px' }}
            >
              <label className="col-form-label text-nowrap">品牌</label>
              <div>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className={style['form-select']}
                  options={[
                    { label: '請選擇品牌', value: 0 },
                    ...(brandOptions ? [...brandOptions] : []),
                  ]}
                  id="brandId"
                  name="brandId"
                  defaultValue={{ label: '請選擇品牌', value: 0 }}
                  onChange={({ label, value }) => {
                    setFieldValue('brandId', Number(value));
                  }}
                  value={
                    brandOptions?.find(
                      i => Number(i.value) === values.brandId,
                    ) || { label: '請選擇品牌', value: 0 }
                  }
                />
              </div>
            </FormGroup>
            <FormGroup
              className="col-sm-12 d-flex gap-1"
              style={{ maxWidth: '250px' }}
            >
              <label className="col-form-label text-nowrap">型號</label>
              <div>
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  className={style['form-select']}
                  options={
                    modelOptions && [
                      { label: '請選擇型號', value: '' },
                      ...modelOptions,
                    ]
                  }
                  id="model"
                  name="model"
                  defaultValue={{ label: '請選擇型號', value: '' }}
                  onChange={({ value }) => setFieldValue('model', value)}
                  value={
                    modelOptions?.find(i => i.value === values.model) || {
                      label: '請選擇型號',
                      value: '',
                    }
                  }
                />
              </div>
            </FormGroup>
            <FormGroup
              className="col-sm-12 d-flex gap-1"
              style={{ maxWidth: '350px' }}
            >
              <label className="col-form-label text-nowrap">上下架</label>
              <div className="d-flex gap-1">
                <Input
                  value={values.type}
                  type="select"
                  name="type"
                  onChange={e => {
                    setFieldValue('isSell', null);
                    setFieldValue('isInstruction', null);
                    setFieldValue('isWarranty', null);
                    setFieldValue('isRepair', null);
                    setFieldValue('type', e.target.value);
                  }}
                >
                  <option value="isSell">官網</option>
                  <option value="isInstruction">商品操作說明</option>

                  <option value="isWarranty">商品保固</option>
                  <option value="isRepair">商品維修</option>
                </Input>

                <Input
                  value={String(values[values.type] ?? '-')}
                  type="select"
                  name="typeValue"
                  onChange={e => {
                    let value = null;
                    switch (e.target.value) {
                      case 'true':
                        value = true;
                        break;
                      case 'false':
                        value = false;
                        break;
                    }
                    setFieldValue(values.type, value);
                  }}
                >
                  <option value="-">全部</option>
                  <option value="true">上架</option>
                  <option value="false">下架</option>
                </Input>
              </div>
            </FormGroup>
          </Row>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                handleChangePage(1);
              }}
            >
              查詢
            </Button>
          </div>
        </CardBody>
      </Card>
      <TableList onChangePage={handleChangePage} />
    </Layout>
  );
};

export default ProductSellingSettings;
