import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col } from 'reactstrap';
import { getActivityCateOptions } from 'redux/actions/data/discountCompaignActions';
import CommonUtil from 'utils/CommonUtil';
import history from 'utils/createHistory';

import Layout from '../components/Layout';
import style from '../styles/layout.module.css';

const DiscountCompaignCate = () => {
  // const [activityName, setActivityName] = useState('');
  const [cateList, setCateList] = useState([]);
  const { cateOptions } = useSelector(state => state.getActivityCateOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActivityCateOptions());
  }, []);

  useEffect(() => {
    if (cateOptions?.length > 0) {
      let temp = [];

      // 過濾同類型活動
      cateOptions.forEach(option => {
        if (!temp.find(data => data.type === option.type)) {
          temp.push(option);
        }
      });
      CommonUtil.consoleLog({ anno: 'response', data: cateOptions });
      setCateList(temp);
    }
  }, [cateOptions]);

  return (
    <Layout
      pageTitle={'新增折扣活動'}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: true, page_name: '折扣活動' },
        { isActive: false, page_name: '新增折扣活動' },
      ]}
    >
      <span className="ml-2">請選擇折扣活動</span>
      <div style={{ height: '17px' }} />
      {cateList?.length > 0 &&
        cateList.map((cate, index) => (
          <Col key={index}>
            <Card className={style.search_card}>
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#1DB4B9' }}
              >
                {cate.type}
              </div>
              <div
                className={style.card_body}
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'left',
                  minHeight: '120px',
                }}
              >
                {cate.codes.map((code, cIndex) => (
                  <Button
                    key={index + cIndex}
                    outline
                    className={style['discount-btn']}
                    onClick={() => {
                      history.push(
                        `/CompaignSettingPage?cateCode=${code.code}&type=${code.name}`,
                      );
                    }}
                  >
                    {code.name}
                  </Button>
                ))}
              </div>
            </Card>
          </Col>
        ))}
    </Layout>
  );
};

export default DiscountCompaignCate;
