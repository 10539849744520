import axios from 'axios';

import {
  ABOUT_INFO_EDIT_FAIL,
  ABOUT_INFO_EDIT_REQUEST,
  ABOUT_INFO_EDIT_SUCCESS,
  ABOUT_INFO_GET_FAIL,
  ABOUT_INFO_GET_REQUEST,
  ABOUT_INFO_GET_SUCCESS,
  SETTING_EDIT_FAIL,
  SETTING_EDIT_REQUEST,
  SETTING_EDIT_SUCCESS,
  SETTING_GET_FAIL,
  SETTING_GET_REQUEST,
  SETTING_GET_SUCCESS,
} from '../../constants/data/settingConstants';

import { baseURL, prefix_api_path } from './api_config';

//get setting
export const getSetting = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_GET_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/PageSetting/list`,
        config,
      );

      dispatch({
        payload: data,
        type: SETTING_GET_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: SETTING_GET_FAIL,
      });
    }
  };
};

//edit setting
export const editSetting = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SETTING_EDIT_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/PageSetting/edit`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: SETTING_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: SETTING_EDIT_FAIL,
      });
    }
  };
};

//get about info
export const getAboutInfo = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ABOUT_INFO_GET_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/PageSetting/list?pagenames=about`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: ABOUT_INFO_GET_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response,
        type: ABOUT_INFO_GET_FAIL,
      });
    }
  };
};

//edit about info
export const editAboutInfo = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ABOUT_INFO_EDIT_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/PageSetting/editAbout`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: ABOUT_INFO_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: ABOUT_INFO_EDIT_FAIL,
      });
    }
  };
};
