import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from 'reactstrap';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import {
  getProductProfile,
  getProductSpecList,
} from '../redux/actions/data/productActions';
import history from '../utils/createHistory';

const slickSettings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
};

const ProductPreview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, profile: info } = useSelector(state => state.productProfile);

  const { specListInfo } = useSelector(state => state.productSpecList);

  useEffect(() => {
    dispatch(getProductProfile(id));
    dispatch(getProductSpecList(id));
  }, []);

  let specList = specListInfo && specListInfo.result?.datas;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      {!loading && info && (
        <div className="preview">
          <Button
            className="preview_button_cancel btn btn-light"
            onClick={handleGoBack}
          >
            取消預覽
          </Button>
          <section
            className="py-0 text-black mb-64 overflow-hidden"
            style={{ marginTop: '7.5rem' }}
          >
            <div className="wrap">
              <div className="row gx-0 gx-md-5">
                <div className="col-md-6 mb-4 slicker_dot">
                  <Slider {...slickSettings}>
                    {info &&
                      info.setting?.images?.map((url, index) => (
                        <img src={url.imageUrl} alt={url.image} key={index} />
                      ))}
                  </Slider>
                </div>
                <div className="col-md-6">
                  <div className="prdDt-name">
                    <div className="prd-model fs-md">{info.model}</div>
                    <h1
                      className="prd-name fs-lg"
                      style={{ marginBottom: '0' }}
                    >
                      {info.modelName}
                    </h1>
                    <div className="price fs-md text-black">
                      {info.highestSellPrice?.toLocaleString('en-US')}
                    </div>
                  </div>
                  <div className="gary-light fs-md mb-2">購買此產品</div>
                  <div className="mb-4 prd-buy-group">
                    {info.isSoldOut ? (
                      <button className="btn btn-outline-dark btn-buy disabled">
                        售完
                      </button>
                    ) : (
                      <a
                        target="_blank"
                        className="btn btn-outline-dark btn-buy "
                      >
                        <span>前往商城</span>
                        <img src="/images/logo_uu.svg" />
                      </a>
                    )}
                    <div className="d-flex mt-2 btn-invt">
                      <button className="btn btn-outline-dark flex-1 hover_clear">
                        實體門市庫存
                      </button>
                      <button className="btn btn-outline-dark flex-1 ms-3 hover_clear">
                        授權通路庫存
                      </button>
                    </div>

                    <div className="invt d-none">
                      {/*點擊實體門市庫存、授權通路庫存，加class="invt show"*/}
                      <button className="btn btn-close"></button>
                      <div className="invt-list container overflow-hidden">
                        <div className="invt-item row text-center mb-4">
                          <div className="col">
                            <span className="underline fs-md"> 門市名稱 </span>
                          </div>
                          <div className="col">
                            <span className="underline fs-md">
                              {' '}
                              庫存狀狀態{' '}
                            </span>
                          </div>
                        </div>
                        <div className="invt-item row text-center">
                          <a href="#" className="col btn-link-black py-2">
                            中壢大江中壢大江
                          </a>
                          <div className="col py-2">有庫存</div>
                        </div>
                        <div className="invt-item row text-center">
                          <a href="#" className="col btn-link-black py-2">
                            中壢大江
                          </a>
                          <div className="col gary-light py-2">無庫存</div>
                          {/*無庫存的class要加gary-light*/}
                        </div>
                        <div className="text-center ssmall mt-4">
                          點擊門市查詢地址
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="gary-light fs-md mb-2">商品特色</div>
                    <ul
                      className="ul-list fs-sm"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {info &&
                        info.setting?.features &&
                        info.setting.features[0]?.content
                          .split('\n')
                          ?.map((text, index) => <li key={index}>{text}</li>)}
                    </ul>
                  </div>
                  <div className="prd-social-group">
                    <button className="btn btn-link-black">
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path d="M10,0A10,10,0,0,0,8.44,19.88v-7H5.9V10H8.44V7.8a3.52,3.52,0,0,1,3.77-3.89,15.72,15.72,0,0,1,2.24.19V6.56H13.19a1.45,1.45,0,0,0-1.63,1.56V10h2.78l-.45,2.89H11.56v7A10,10,0,0,0,10,0Z" />
                        </g>
                      </svg>
                      <span className="small">SHARE</span>
                    </button>
                    <button className="btn btn-link-black">
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm5.57,6.58a.28.28,0,0,1-.06.18,4.13,4.13,0,0,1-.87.9c-.05,0-.13.08-.13.15a6.68,6.68,0,0,1-1.78,4.9,6.56,6.56,0,0,1-6.4,1.5,6.27,6.27,0,0,1-1.24-.49l-.39-.23s0-.05,0-.07h.15a4.35,4.35,0,0,0,1.86-.24,2.87,2.87,0,0,0,1.22-.64v0l-.24,0A2.38,2.38,0,0,1,5.88,11c0-.06,0-.09.06-.08a2.54,2.54,0,0,0,.85,0L6.5,10.8A2.32,2.32,0,0,1,5.08,9a2.18,2.18,0,0,1,0-.25c0-.06,0-.08.08-.05A2.33,2.33,0,0,0,6,8.9a2.1,2.1,0,0,1-.27-.24A2.26,2.26,0,0,1,5.36,6c0-.07.07-.07.12,0A7,7,0,0,0,10,8.25s.06,0,0-.05a2.47,2.47,0,0,1,0-.94,2.34,2.34,0,0,1,.45-.89,2.33,2.33,0,0,1,1.66-.87,2.27,2.27,0,0,1,1.61.55,1.14,1.14,0,0,1,.11.11.08.08,0,0,0,.09,0,6.11,6.11,0,0,0,1.31-.5.06.06,0,0,1,.06,0s0,0,0,.06a2,2,0,0,1-.37.67c-.1.13-.36.45-.54.48a4.1,4.1,0,0,0,1.1-.32c.05,0,.05,0,.05,0Z" />
                        </g>
                      </svg>
                      <span className="small">TWITTER</span>
                    </button>
                    <button className="btn btn-link-black">
                      <svg xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm3.61,11.72c-1.24,1.54-3.25,1.64-4.18.7-.07-.07-.13-.16-.2-.24l0,.09a9.91,9.91,0,0,1-.95,2.94,8.14,8.14,0,0,1-.74,1.1s-.06.1-.12.08-.07-.07-.08-.13a9.43,9.43,0,0,1-.09-1.59c0-.7.11-.94,1-4.73a.27.27,0,0,0,0-.15A2.75,2.75,0,0,1,8.13,8c.41-1.29,1.88-1.39,2.13-.33.16.66-.26,1.52-.58,2.8-.26,1,1,1.8,2,1A4.41,4.41,0,0,0,13,7.91C12.85,5.52,10.22,5,8.56,5.77,6.65,6.66,6.21,9,7.07,10.11c.11.13.2.22.16.36s-.1.43-.16.65a.23.23,0,0,1-.34.15,1.91,1.91,0,0,1-.8-.6A3.8,3.8,0,0,1,6,6.45a5.1,5.1,0,0,1,4.9-2.16,4.16,4.16,0,0,1,3.81,3.43A5.68,5.68,0,0,1,13.61,11.72Z" />
                        </g>
                      </svg>
                      <span className="small">PINTEREST</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            style={{
              background: `${info.bgColor}`,
              color: `${info.fontColor}`,
            }}
          >
            <section className="py-5 prdDt-btm px-0">
              <div className="prdDt-intro mb-120 overflow-hidden">
                {info &&
                  info.introdutions?.map(intro => (
                    <div
                      key={intro.id}
                      className="prdDt-intro-item row"
                      style={{ justifyContent: 'center' }}
                    >
                      {intro.type === 'imageRight' ? (
                        <>
                          <div className="col-md-6 prdDt-intro-txt">
                            <h3 className="mb-4">{intro.title}</h3>
                            <p
                              className="fs-sm"
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {intro?.content}
                            </p>
                          </div>
                          <div className="col-md-6 prdDt-intro-img">
                            <img className="w-100" src={intro.imageUrl} />
                          </div>
                        </>
                      ) : intro.type === 'imageLeft' ? (
                        <>
                          <div className="col-md-6 prdDt-intro-img">
                            <img className="w-100" src={intro.imageUrl} />
                          </div>
                          <div className="col-md-6 prdDt-intro-txt left_img">
                            <h3 className="mb-4">{intro.title}</h3>
                            <p
                              className="fs-sm"
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {intro?.content}
                            </p>
                          </div>
                        </>
                      ) : intro.type === 'contentOnly' ? (
                        <div
                          className="prdDt-intro-item fs-lg text-center px-10vw"
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          {intro?.content}
                        </div>
                      ) : intro.type === 'imageOnly' ? (
                        <div className="prdDt-intro-item text-center">
                          <img className="img-fluid " src={intro.imageUrl} />
                        </div>
                      ) : intro.type === 'youtubeLink' ? (
                        <div className="col-12 justify-content-center px-10vw">
                          <div className="prdDt-intro-item ratio ratio-16x9 h-100">
                            <div className="wrapper">
                              <div className="frame-container">
                                <iframe
                                  src={`${intro.youtubeLink}?loop=1&autoplay=1&controls=1&showinfo=0&mute=1`}
                                  title="YouTube video player"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="prdDt-intro-item"
                          style={{ height: `${intro.height}px` }}
                        ></div>
                      )}
                    </div>
                  ))}
              </div>
            </section>
            {specList && specList.length > 0 && (
              <section>
                <div className="wrap">
                  <h3 className="fs-lg mb-3">商品規格</h3>
                  <table className="w-100 table-spec fs-sm">
                    <tbody>
                      {specList?.map(spec => (
                        <tr key={spec?.id}>
                          <th>{spec?.title}</th>
                          <td>{spec?.content}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPreview;
