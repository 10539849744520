import Layout from 'components/Layout';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import PresentSettings, {
  initValues,
} from '../components/memberPresentSettings/PresentSettings';
import {
  createMembershipGift,
  getMemberLevelOptions,
  getMembershipGiftById,
  updateMembershipGift,
} from '../redux/actions/data/memberActions';
import { GET_MEMBER_SHIP_GIFT_SUCCESS } from '../redux/constants/data/memberConstants';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import { getImageDataFromSetting } from '../utils/getImageDataFromSetting';

const MemberBirthPresentSettings = () => {
  const dispatch = useDispatch();
  const { detail } = useSelector(state => state.getMembershipGift);
  const location = useLocation();
  const param = useParams();
  const type = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    switch (searchParams.get('type')) {
      case 'edit':
        return '編輯';
      case 'view':
        return '檢視';
      case 'copy':
        return '複製';
      default:
        return '新增';
    }
  }, [location?.search]);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleSubmit = useCallback(
    async props => {
      try {
        const formData = new FormData();
        Object.keys(props.values).forEach(key => {
          if (key !== 'couponImgFiles') {
            if (typeof props.values[key] === 'object') {
              formData.append(key, JSON.stringify(props.values[key]));
            } else {
              if (props.values[key] === null) {
                formData.append(key, 0);
              } else {
                formData.append(key, String(props.values[key]));
              }
            }
          }
        });

        formData.set('id', props.values.id || 0);
        formData.set('couponQtyEach', 0);
        formData.set('remiderNum', 0);

        formData.set(
          'rangeStartTime',
          moment(props.values.rangeStartTime).format('YYYY/MM'),
        );
        formData.set(
          'rangeEndTime',
          moment(props.values.rangeEndTime).format('YYYY/MM'),
        );

        if (props.values?.couponImgFiles?.length) {
          if (props.values.couponImgFiles[0] instanceof File) {
            formData.append('couponImgFiles', props.values.couponImgFiles[0]);
          } else {
            formData.delete('couponImgFiles');
            formData.delete('couponImg');
          }
        }

        formData.set(
          'groupProducts',
          JSON.stringify(
            (props?.values?.models || [])
              .filter(m => m.modelType === '組合商品')
              .reduce((arr, model) => {
                return [...arr, ...model.products];
              }, [])
              .map(product => ({
                activityProductId: product.activityProductId,
                area: product.area,
                discountPrice: Number(product.discountPrice || 0),
                mid: product.mid,
                productid: product.productid,
              })),
          ),
        );
        formData.set(
          'products',
          JSON.stringify(
            (props?.values?.models || [])
              .filter(m => m.modelType === '一般商品')
              .reduce((arr, model) => {
                return [...arr, ...model.products];
              }, [])
              .map(product => ({
                activityProductId: product.activityProductId,
                area: product.area,
                discountPrice: Number(product.discountPrice || 0),
                mid: product.mid,
                productid: product.productid,
              })),
          ),
        );

        formData.set(
          'availableWay',
          JSON.stringify(
            (props.values.availableWay || '').split(',').filter(s => s),
          ),
        );

        formData.set(
          'memberLevelId',
          JSON.stringify([props.values?.memberLevelId].filter(s => String(s))),
        );

        formData.set('activityType', '生日禮');

        if (detail?.id) {
          await updateMembershipGift(formData);
          MessageUtil.alertSuccess('更新成功');
        } else {
          await createMembershipGift(formData);
          MessageUtil.alertSuccess('新增成功');
          goBack();
        }
      } catch (e) {
        if (detail?.id) {
          MessageUtil.alertSuccess('更新失敗', e);
        } else {
          MessageUtil.alertSuccess('新增失敗', e);
        }
      }
    },
    [detail, goBack],
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      availableWay: Yup.string().test('in-valid', '請選擇指定通路', val => {
        return val;
      }),
      couponSettings: Yup.array()
        .when('discountType', {
          is: discountType => Number(discountType) === 1,
          then: Yup.array().of(
            Yup.object().shape({
              discountAmount: Yup.number()
                .required('請輸入大於0得數字')
                .min(1, '請輸入大於0得數字'),
            }),
          ),
        })
        .when('discountType', {
          is: discountType => Number(discountType) === 2,
          then: Yup.array().of(
            Yup.object().shape({
              discountAmount: Yup.number()
                .required('請輸入大於0得數字')
                .min(1, '請輸入大於0得數字')
                .max(9.9, '請輸入小於9.9得數字'),
            }),
          ),
        })
        .min(1, '請輸入大於0得數字'),
      couponWording: Yup.string().required('請輸入券面顯示文字(英文)'),
      couponWording_en: Yup.string().required('請輸入券面顯示文字'),
      desc: Yup.string().required('請輸入活動說明'),
      desc_en: Yup.string().required('請輸入活動說明(英文)'),
      discountMaxAmount: Yup.number()
        .when('discountType', {
          is: discountType => Number(discountType) === 1,
          then: Yup.number()
            .required('請輸入大於0得數字')
            .min(1, '請輸入大於0的數字'),
        })
        .when(['discountType', 'discountMax'], {
          is: (discountType, discountMax) =>
            Number(discountType) === 2 && Number(discountMax) === 2,
          then: Yup.number()
            .required('請輸入大於0得數字')
            .min(1, '請輸入大於0的數字'),
        }),
      models: Yup.array().when('productRange', {
        is: productRange => 'productRange' === 'picked',
        then: Yup.array().required('請輸入大於0得數字').min(1, '請選擇商品'),
      }),
      name: Yup.string().required('請輸入名稱'),
      name_en: Yup.string().required('請輸入名稱(英文)'),
      sendTimeNumber: Yup.number().min(1, '請輸入大於0的數字'),
      usableNdaysLater: Yup.number().when('usableType', {
        is: usableType => Number(usableType) === 2,
        then: Yup.number().min(2, '請輸入大於2的數字'),
      }),
      useMinAmount: Yup.number().when('useMin', {
        is: useMin => Number(useMin) === 2,
        then: Yup.number().min(1, '請輸入大於0的數字'),
      }),
    });
    return schema;
  };

  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    dispatch({
      payload: {},
      type: GET_MEMBER_SHIP_GIFT_SUCCESS,
    });
    if (searchParams.get('type') === 'edit') {
      dispatch(getMembershipGiftById(param.id));
    } else if (searchParams.get('type') === 'copy') {
      dispatch(getMembershipGiftById(param.id));
    } else if (searchParams.get('type') === 'view') {
      dispatch(getMembershipGiftById(param.id));
    } else {
      dispatch({
        payload: initValues,
        type: GET_MEMBER_SHIP_GIFT_SUCCESS,
      });
    }

    return () => {
      dispatch({
        payload: initValues,
        type: GET_MEMBER_SHIP_GIFT_SUCCESS,
      });
    };
  }, [location]);

  return (
    <Layout
      pageTitle="生日禮設定"
      items={[
        { isActive: true, page_name: '會員管理' },
        {
          isActive: false,
          page_name: '會員禮設定',
          to: '/MemberPresentSettings',
        },
        { isActive: false, page_name: '生日禮設定' },
      ]}
    >
      <PresentSettings
        type="生日禮"
        goBack={goBack}
        getValidationSchema={getValidationSchema}
        handleSubmit={handleSubmit}
        disabled={type === '檢視'}
        initialValues={{
          ...detail,
          activityCode: detail?.activityCode ?? '',
          activityType: '生日禮',
          availableWay: detail?.availableWay
            ? JSON.parse(detail?.availableWay).join(',')
            : '',
          couponImg: detail?.couponImg ?? '',
          couponImgFiles: detail?.couponImg
            ? JSON.parse(detail?.couponImg)
            : [],
          couponQtyEach: detail?.couponQtyEach || 0,
          couponSettings: detail?.couponSettings ?? [],
          couponType: 1,
          couponWording: detail?.couponWording ?? '',
          couponWording_en: detail?.couponWording_en || '',
          customedActivityCode: detail?.customedActivityCode ?? '',
          desc: detail?.desc ?? '',
          desc_en: detail?.desc_en ?? '',
          discountMax: detail?.discountMax || 0,
          discountMaxAmount: detail?.discountMaxAmount || 0,
          discountType: detail?.discountType || 1,
          dueType: detail?.dueType || 1,
          groupProducts: detail?.groupProducts ?? [],
          id: detail?.id || 0,
          initialCouponImg: detail?.couponImg
            ? getImageDataFromSetting(detail?.couponImg, detail?.couponImgLink)
            : getImageDataFromSetting(null, null),
          isGetTime: detail?.isGetTime ?? true,
          isUsedwithDiscount: detail?.isUsedwithDiscount ?? true,
          memberLevelId: detail?.memberLevelId
            ? JSON.parse(detail?.memberLevelId).join(',')
            : '0',
          models: [
            ...(detail?.products ?? []).map(m => {
              return {
                ...m,
                modelType: '一般商品',
              };
            }),
            ...(detail?.groupProducts ?? []).map(m => {
              return {
                ...m,
                modelType: '組合商品',
              };
            }),
          ],
          name: detail?.name ?? '',
          name_en: detail?.name_en ?? '',
          productRange: detail?.productRange ?? 'all',
          products: detail?.products ?? [],
          rangeEndTime:
            detail?.rangeEndTime ??
            moment().add(30, 'minutes').format('YYYY/MM/DD HH:mm:ss'),
          rangeStartTime:
            detail?.rangeStartTime ?? moment().format('YYYY/MM/DD HH:mm:ss'),
          redeemEndTime:
            detail?.redeemEndTime ??
            moment().add(30, 'minutes').format('YYYY/MM/DD HH:mm:ss'),
          redeemStartTime:
            detail?.redeemStartTime ?? moment().format('YYYY/MM/DD HH:mm:ss'),
          remiderNum: detail?.remiderNum || 0,
          sendTimeNumber: detail?.sendTimeNumber || 0,
          sendTimeType: detail?.sendTimeType || '生日當月',
          storeRedeemShowWay: detail?.storeRedeemShowWay || 'qrcode',
          usableDays: detail?.usableDays || 0,
          usableNdaysLater: detail?.usableNdaysLater || 0,
          usableType: detail?.usableType || 1,

          useMin: detail?.useMin || 1,
          useMinAmount: detail?.useMinAmount || 0,
        }}
      />
    </Layout>
  );
};

export default MemberBirthPresentSettings;
