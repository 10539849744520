import RcPagination from 'rc-pagination';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Card,
  Button,
  Table,
  CardHeader,
  CardFooter,
  CardBody,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Loader from '../../components/Loader';
import {
  deleteCafeRecruitmentDetail,
  getCafeRecruitmentList,
} from '../../redux/actions/data/uuCafeRecruitmentAction';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';



import RecruitmentModel from './RecruitmentModel';
import SortRecruitmentModel from './SortRecruitmentModel';

const RecruitmentSetting = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(state => state.cafeRecruitmentList);
  const { loading: detailLoading } = useSelector(
    state => state.cafeRecruitment,
  );
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState({
    label: '全部',
    value: 0,
  });

  const [page, setPage] = useState(1);
  const handlePageChange = useCallback(
    (value, keyword, status) => {
      dispatch(
        getCafeRecruitmentList({
          keyword,
          order: '',
          order_by: '',
          page: value,
          page_size: list.page_size || 10,
          status: status.value,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, list],
  );

  const handleDeleteShop = useCallback(
    async detail => {
      try {
        await deleteCafeRecruitmentDetail({
          id: detail.id,
        });
        handlePageChange(1, keyword, status);
        MessageUtil.toastSuccess('刪除成功!');
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [handlePageChange, keyword, status],
  );

  useEffect(() => {
    handlePageChange(1, keyword, status);
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [sortModalShow, setSortModalShow] = useState(false);

  const detail = useRef({
    content: '',
    content_en: '',
    id: 0,
    name: '',
    name_en: '',
  });

  return (
    <>
      <RecruitmentModel
        detail={detail.current}
        openModal={modalShow}
        setOpenModel={(show, isReload) => {
          if (isReload) {
            handlePageChange(1, keyword, status);
          }
          detail.current = {};
          setModalShow(show);
        }}
      />
      <SortRecruitmentModel
        setOpenModel={show => {
          handlePageChange(1, keyword, status);
          setSortModalShow(show);
        }}
        openModal={sortModalShow}
      />
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">關鍵字:</label>
              <Input
                className="flex-1"
                placeholder="輸入關鍵字"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">狀態:</label>
              <Select
                className="flex-1"
                placeholder="請選擇狀態"
                options={[
                  {
                    label: '全部',
                    value: 0,
                  },
                  {
                    label: '上架',
                    value: 1,
                  },
                  {
                    label: '下架',
                    value: 2,
                  },
                ]}
                value={status}
                onChange={selectedOption => {
                  setStatus(selectedOption);
                }}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
              setStatus({
                label: '全部',
                value: 0,
              });
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword, status);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  detail.current = {
                    content: '',
                    content_en: '',
                    id: 0,
                    name: '',
                    name_en: '',
                  };
                  setModalShow(true);
                }}
              >
                新增
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  setSortModalShow(true);
                }}
              >
                職缺排序
              </Button>
            </div>
            <p className="small text-end mb-0">共 {list.total_count} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              {!detailLoading && (
                <Table hover bordered striped>
                  <thead className={`thead-dark ${style.table_head}`}>
                    <tr>
                      <th nowrap="nowrap">商品名稱</th>
                      <th nowrap="nowrap">內容</th>
                      <th nowrap="nowrap">狀態</th>
                      <th style={{ minWidth: '120px' }}>異動日期</th>
                      <th>功能</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.datas.map((data, index) => (
                      <tr key={index}>
                        <td nowrap="nowrap">{data.name}</td>
                        <td>
                          <pre>{data.content}</pre>
                        </td>
                        <td nowrap="nowrap" className="text-center">
                          {data.status === 1 && '上架'}
                          {data.status === 2 && '下架'}
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {data.updateDateTime}
                          </div>
                          <div>{data.updater}</div>
                        </td>
                        <td nowrap="nowrap" className="text-center">
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              detail.current = data;
                              setModalShow(true);
                            }}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => {
                              MessageUtil.submitConfirm({
                                doneFun: () => {
                                  handleDeleteShop(data);
                                },
                                title: '確認是否刪除',
                              });
                            }}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={list.page_size || 10}
                total={list.total_count || 1}
                onChange={value => {
                  handlePageChange(value, keyword, status);
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </>
  );
};

export default memo(RecruitmentSetting);
