import {
  GET_AREA_LIST_FAIL,
  GET_AREA_LIST_REQUEST,
  GET_AREA_LIST_SUCCESS,
  GET_CITY_LIST_FAIL,
  GET_CITY_LIST_REQUEST,
  GET_CITY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNYRT_LIST_SUCCESS,
} from 'redux/constants/data/locationContants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getCountryList = () => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Website/City/countryList' });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_COUNTRY_LIST_FAIL,
      method: 'get',
      requestConstant: GET_COUNTRY_LIST_REQUEST,
      successConstant: GET_COUNYRT_LIST_SUCCESS,
      url: '/Website/City/countryList',
    });
  };
};

export const getCityList = countryId => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Website/City/cityList/${countryId || 1}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_CITY_LIST_FAIL,
      method: 'get',
      requestConstant: GET_CITY_LIST_REQUEST,
      successConstant: GET_CITY_LIST_SUCCESS,
      url: `/Website/City/cityList/${countryId || 1}`,
    });
  };
};

export const getAreaList = cityId => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Website/City/townList/${cityId || 1}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_AREA_LIST_FAIL,
      method: 'get',
      requestConstant: GET_AREA_LIST_REQUEST,
      successConstant: GET_AREA_LIST_SUCCESS,
      url: `/Website/City/townList/${cityId || 1}`,
    });
  };
};
