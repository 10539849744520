import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  addCafeRecruitmentDetail,
  getCafeRecruitmentDetail,
  updateCafeRecruitmentDetail,
} from '../../redux/actions/data/uuCafeRecruitmentAction';
import { UUCAFE_RECRUITMENT_SUCCESS } from '../../redux/constants/data/uuCafeRecruitmentConstants';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';

const RecruitmentModel = ({
  detail = {
    content: '',
    content_en: '',
    id: 0,
    name: '',
    name_en: '',
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const {
    loading,
    success,
    error,
    detail: initData,
  } = useSelector(state => state.cafeRecruitment);
  const dispatch = useDispatch();
  const isSubmit = useRef(false);
  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const params = {
        content: props.values.content,
        content_en: props.values.content_en,
        id: 0,
        name: props.values.name,
        name_en: props.values.name_en,
        status: props.values.status,
      };
      if (detail.id) {
        params.id = detail.id;
        dispatch(updateCafeRecruitmentDetail(params));
      } else {
        params.id = 0;
        dispatch(addCafeRecruitmentDetail(params));
      }
    },
    [detail, initData],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    if (detail.id) {
      dispatch(getCafeRecruitmentDetail(detail));
    } else {
      dispatch({
        payload: detail,
        type: UUCAFE_RECRUITMENT_SUCCESS,
      });
    }
  }, [detail.id]);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
    });
    return schema;
  };
  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>商品編輯</ModalHeader>
        {!(!loading || isSubmit.current) && <Loader />}
        {(!loading || isSubmit.current) && (
          <Formik
            initialValues={{
              content: initData.content,
              content_en: initData.content_en,
              name: initData.name,
              name_en: initData.name_en,
              status: initData.status || 1,
            }}
            validationSchema={getValidationSchema()}
          >
            {props => (
              <Form className="form-list form-label-140">
                <ModalBody className={style.modal_body}>
                  <div className="form-item">
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*職缺名稱(中文)</label>
                      <div className="form-item">
                        <Field name="name" className="form-control" />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*職缺名稱(英文)</label>
                      <div className="form-item">
                        <Field name="name_en" className="form-control" />
                        <ErrorMessage
                          name="name_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*內容(中文)</label>
                      <div className="form-item">
                        <Field
                          name="content"
                          className="form-control"
                          component="textarea"
                          row="4"
                        />
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*內容(英文)</label>
                      <div className="form-item">
                        <Field
                          name="content_en"
                          className="form-control"
                          component="textarea"
                          row="4"
                        />
                        <ErrorMessage
                          name="content_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-3 mb-3">
                      <label className="w-100">*狀態</label>
                      <div className="w-100 row">
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="status-1"
                        >
                          <Field
                            type="radio"
                            name="status"
                            value={1}
                            id="status-1"
                            onChange={() => {
                              props.setFieldValue('status', 1);
                            }}
                          />
                          上架
                        </label>
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="status-2"
                        >
                          <Field
                            type="radio"
                            name="status"
                            value={2}
                            id="status-2"
                            onChange={() => {
                              props.setFieldValue('status', 2);
                            }}
                          />
                          下架
                        </label>
                      </div>
                    </FormGroup>
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  <Button
                    color="light"
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={() => {
                      setOpenModel(false, false);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    disabled={loading}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span>儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default memo(RecruitmentModel);
