import {
  UUCAFE_BOTTOM_HOME_SETTING_FAIL,
  UUCAFE_BOTTOM_HOME_SETTING_REQUEST,
  UUCAFE_BOTTOM_HOME_SETTING_SUCCESS,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeBottomHomeSettingConstants';

export const cafeBottomHomeSettingReducer = (
  state = {
    detail: {},
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_BOTTOM_HOME_SETTING_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_BOTTOM_HOME_SETTING_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_BOTTOM_HOME_SETTING_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
