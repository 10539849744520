import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  DISTRIBUTOR_GET_FAIL,
  DISTRIBUTOR_GET_REQUEST,
  DISTRIBUTOR_GET_SUCCESS,
  DISTRIBUTOR_MILESTONE_LIST_GET_FAIL,
  DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST,
  DISTRIBUTOR_MILESTONE_LIST_GET_SUCCESS,
} from '../../constants/data/distributorConstants';
import { initMilestone } from '../../reducers/data/distributorReducers';

export const getDistributor = () => {
  const url = '/Backend/Distributor/Get';
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DISTRIBUTOR_GET_REQUEST,
      method: 'get',
      requestConstant: DISTRIBUTOR_GET_FAIL,
      successConstant: DISTRIBUTOR_GET_SUCCESS,
      url,
    });
  };
};

export const updateDistributor = (data = new FormData()) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/Distributor/Update',
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const getMilestoneList = (
  data = {
    keyword: '',
    order: '',
    order_by: '',
    page: 0,
    page_size: 10,
    type: 0,
  },
) => {
  const url = '/Backend/Distributor/GetMilestoneList';
  return async dispatch => {
    await useRequest({
      data: {
        ...data,
        order: 'asc',
        order_by: 'year',
      },
      dispatch,
      failConstant: DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST,
      method: 'post',
      requestConstant: DISTRIBUTOR_MILESTONE_LIST_GET_FAIL,
      successConstant: DISTRIBUTOR_MILESTONE_LIST_GET_SUCCESS,
      url,
    });
  };
};

export const createMilestone = (data = initMilestone) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/Distributor/CreateMilestone',
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const updateMilestone = (data = initMilestone) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/Distributor/UpdateMilestone',
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const deleteMilestoneById = (id = 0) => {
  return request({
    method: 'get',
    url: `/Backend/Distributor/DeleteMilestone/${id}`,
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res.message.split('，')[0];
    }
  });
};
