import {
  UUCAFE_BANNER_SETTINGS_ADD_FAIL,
  UUCAFE_BANNER_SETTINGS_ADD_REQUEST,
  UUCAFE_BANNER_SETTINGS_ADD_SUCCESS,
  UUCAFE_BANNER_SETTINGS_FAIL,
  UUCAFE_BANNER_SETTINGS_LIST_FAIL,
  UUCAFE_BANNER_SETTINGS_LIST_REQUEST,
  UUCAFE_BANNER_SETTINGS_LIST_SUCCESS,
  UUCAFE_BANNER_SETTINGS_REQUEST,
  UUCAFE_BANNER_SETTINGS_SUCCESS,
  UUCAFE_BANNER_SETTINGS_UPDATE_FAIL,
  UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST,
  UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeBannerSettingsConstants';

export const cafeBannerSettingsListReducer = (
  state = {
    bannerList: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_BANNER_SETTINGS_LIST_REQUEST:
      return {
        bannerList: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 0,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_BANNER_SETTINGS_LIST_SUCCESS:
      return { ...state, bannerList: payload, loading: false, success: true };
    case UUCAFE_BANNER_SETTINGS_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeBannerSettingReducder = (
  state = { banner: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_BANNER_SETTINGS_REQUEST:
      return { banner: {}, error: null, loading: true, success: false };
    case UUCAFE_BANNER_SETTINGS_SUCCESS:
      return {
        ...state,
        banner: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_BANNER_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_BANNER_SETTINGS_ADD_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_BANNER_SETTINGS_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_BANNER_SETTINGS_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_BANNER_SETTINGS_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
