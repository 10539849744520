export const UUCAFE_BOTTOM_HOME_SETTING_REQUEST =
  'UUCAFE_BOTTOM_HOME_SETTING_REQUEST';
export const UUCAFE_BOTTOM_HOME_SETTING_SUCCESS =
  'UUCAFE_BOTTOM_HOME_SETTING_SUCCESS';
export const UUCAFE_BOTTOM_HOME_SETTING_FAIL =
  'UUCAFE_BOTTOM_HOME_SETTING_FAIL';

export const UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST =
  'UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST';
export const UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS =
  'UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS';
export const UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL =
  'UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL';
