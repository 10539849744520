import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  getBrandList,
  updateBrandSort,
} from '../redux/actions/data/brandActions';
import { getUpdateTime, updateData } from '../redux/actions/data/userActions';
import { BRAND_UPDATESORT_RESET } from '../redux/constants/data/brandConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const statusOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '上架',
    value: '上架',
  },
  {
    label: '下架',
    value: '下架',
  },
];

const BrandControl = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { location } = history;

  const [current_page, setCurrentPage] = useState(1);
  const [search_values, setSerachValues] = useState({
    name: '',
    status: '',
  });

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, brandList, error } = useSelector(state => state.brandList);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateData);
  const {
    loading: sortLoading,
    success: sortSuccess,
    error: sortError,
  } = useSelector(state => state.brandSort);

  useEffect(() => {
    //如果沒有更新時間就要發動
    if (localStorage.getItem('updateTime') === null) {
      dispatch(getUpdateTime());
    }
  }, []);

  useEffect(() => {
    if (brandList && brandList.datas?.length > 10) {
      setEditBrand(brandList.datas);
    } else if (brandList && brandList.datas?.length < 10) {
      setEditBrand(brandList.datas);
    }
  }, [brandList]);

  useEffect(() => {
    if (sortSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      toggleModal();
      dispatch(
        getBrandList(search_values.name, search_values.status, current_page),
      );
    }

    if (sortError) {
      //*
    }
    dispatch({ type: BRAND_UPDATESORT_RESET });
  }, [sortSuccess, sortError]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;

      setSerachValues({ name: queryKeyword, status: queryStatus });
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        //儲存搜尋及頁數
        dispatch(getBrandList(queryKeyword, queryStatus, queryPage));
      } else {
        //單純記錄頁數
        dispatch(getBrandList('', '', queryPage));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setSerachValues({ name: '', status: '' });

      //初始搜尋
      dispatch(getBrandList('', '', 1));
    }
  }, [location.search]);

  const handleSearchChanged = (value, key) => {
    setSerachValues({
      ...search_values,
      [key]: value,
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getBrandList(search_values.name ?? '', search_values.status ?? '', 1),
    );
    history.push(
      `${location.pathname}?keyword=${search_values.name ?? ''}&status=${
        search_values.status ?? ''
      }&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setSerachValues({ name: '', status: '' });
    dispatch(getBrandList('', '', 1));
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    const _name = search_values.name ? search_values.name : '';
    const _status = search_values.status ? search_values.status : '';
    dispatch(getBrandList(_name, _status, page));
    if (_name || _status) {
      history.push(
        `${location.pathname}?keyword=${_name}&status=${_status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const updateTime = () => {
    dispatch(getUpdateTime());
    dispatch(updateData());
  };

  /**** drag n drop  ****/
  const [isOpenModal, setModal] = useState(false);
  const [editBrand, setEditBrand] = useState([]);

  const toggleModal = () => {
    dispatch(getBrandList('', '', current_page));
    setModal(false);
  };
  const handleOnDragEnd = result => {
    const items = Array.from(editBrand);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setEditBrand(items);
  };

  const updateSort = () => {
    const sort_objects = editBrand.map((brand, index) => {
      return {
        id: brand.id,
        sort: index + 1,
      };
    });
    dispatch(updateBrandSort(sort_objects));
  };

  const handleModalOpen = () => {
    setModal(true);
    dispatch(getBrandList('', '', 0));
  };

  const handlePreview = id => {
    history.push(`/BrandPreview/${id}`);
  };

  useEffect(() => {
    if (brandList) {
      if (JSON.stringify(brandList) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: brandList });
      }
    }
  }, [brandList]);

  return (
    <Layout
      pageTitle="品牌管理"
      items={[{ isActive: true, page_name: '品牌管理' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label htmlFor="name mr-2" style={{ lineHeight: '48px' }}>
                  品牌名稱
                </label>
                <Input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder=""
                  data-id="name"
                  value={search_values.name}
                  onChange={e => {
                    handleSearchChanged(e.target.value, 'name');
                  }}
                />
              </div>
              <div className={style.formGroup}>
                <label className="mr-2" htmlFor="status">
                  狀態
                </label>
                <Select
                  className={style['form-select-short']}
                  options={statusOptions}
                  value={
                    statusOptions.filter(
                      o => o.value === search_values.status,
                    )[0]
                  }
                  onChange={option => {
                    handleSearchChanged(option.value, 'status');
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                color="light"
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
                style={{ marginRight: '1rem' }}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card
        className={style.card}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div className="text-end mb-2">
          <Button
            type="button"
            className={style.sort_button}
            onClick={() => handleModalOpen()}
            style={{ textAlign: 'center' }}
          >
            排序
          </Button>
        </div>

        <div className="d-flex justify-content-end">
          共<span className="mx-1">{brandList?.total_count ?? '-'}</span>筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>排序</th>
                <th>品牌名稱</th>
                <th style={{ minWidth: '180px' }}>品牌logo</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {!(loading || updateLoading) && (
              <tbody>
                {brandList ? (
                  brandList.datas.map((data, index) => (
                    <tr key={index}>
                      <td>{data.sort}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.name}</td>
                      <td>
                        {data.logoUrl && (
                          <img
                            src={data.logoUrl}
                            alt={data.name}
                            style={{
                              backgroundColor: 'black',
                              width: '200px',
                            }}
                          />
                        )}
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                        </div>
                        {data.updateDate.length > 0 && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/BrandSetting/${data.id}`}
                        >
                          網頁設定
                        </Link>
                        <Link
                          className="m-2 btn btn-outline-success btn-sm"
                          to={`/BrandCategory/${data.id}`}
                        >
                          類別設定
                        </Link>
                        <Button
                          className="m-2 btn btn-sm"
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: '#f4772e',
                          }}
                          onClick={() => handlePreview(data.id)}
                        >
                          <span style={{ color: '#f4772e' }}>預覽</span>
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
            {(loading || updateLoading) && <TableLoading />}
          </Table>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={brandList?.current_page ?? 1}
            pageSize={brandList?.page_size ?? 10}
            total={brandList?.total_count ?? 0}
            onChange={handlePageChange}
          />
        </div>
      </Card>
      <div className={style.modal}>
        <Modal
          zIndex={2000}
          centered
          size="lg"
          isOpen={isOpenModal}
          toggle={toggleModal}
          backdrop="static"
        >
          <ModalHeader toggle={toggleModal} className={style.modal_head}>
            品牌排序
          </ModalHeader>
          <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="brands">
                {provided => (
                  <ul
                    className="drag_list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {editBrand.map(({ id, name, status }, index) => (
                      <Draggable key={id} draggableId={`${id}`} index={index}>
                        {provided => (
                          <li
                            className="drag_data"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <span className="title">{index + 1}</span>
                            <span className="brand_name">
                              {name}
                              {status === '下架' && (
                                <span className="badge bg-danger ms-4">
                                  下架
                                </span>
                              )}
                            </span>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <ModalFooter className={style.modal_footer}>
            <Button
              color="light"
              className={style.modal_button_cancel}
              onClick={toggleModal}
            >
              取消
            </Button>
            <Button
              color="primary"
              type="submit"
              className={style.modal_button_submit}
              onClick={updateSort}
              disabled={updateLoading}
            >
              {updateLoading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              儲存
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Layout>
  );
};

export default BrandControl;
