export const UUCAFE_BANNER_SETTINGS_LIST_REQUEST =
  'UUCAFE_BANNER_SETTINGS_LIST_REQUEST';
export const UUCAFE_BANNER_SETTINGS_LIST_SUCCESS =
  'UUCAFE_BANNER_SETTINGS_LIST_SUCCESS';
export const UUCAFE_BANNER_SETTINGS_LIST_FAIL =
  'UUCAFE_BANNER_SETTINGS_LIST_FAIL';

export const UUCAFE_BANNER_SETTINGS_REQUEST = 'UUCAFE_BANNER_SETTINGS_REQUEST';
export const UUCAFE_BANNER_SETTINGS_SUCCESS = 'UUCAFE_BANNER_SETTINGS_SUCCESS';
export const UUCAFE_BANNER_SETTINGS_FAIL = 'UUCAFE_BANNER_SETTINGS_FAIL';

export const UUCAFE_BANNER_SETTINGS_ADD_REQUEST =
  'UUCAFE_BANNER_SETTINGS_ADD_REQUEST';
export const UUCAFE_BANNER_SETTINGS_ADD_SUCCESS =
  'UUCAFE_BANNER_SETTINGS_ADD_SUCCESS';
export const UUCAFE_BANNER_SETTINGS_ADD_FAIL =
  'UUCAFE_BANNER_SETTINGS_ADD_FAIL';

export const UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST =
  'UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST';
export const UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS =
  'UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS';
export const UUCAFE_BANNER_SETTINGS_UPDATE_FAIL =
  'UUCAFE_BANNER_SETTINGS_UPDATE_FAIL';
