import {
  GET_REWARDS_POINTS_SETTINGS_FAIL,
  GET_REWARDS_POINTS_SETTINGS_REQUEST,
  GET_REWARDS_POINTS_SETTINGS_SUCCESS,
  UPDATE_REDEEM_REWARDS_POINTS_FAIL,
  UPDATE_REDEEM_REWARDS_POINTS_REQUEST,
  UPDATE_REDEEM_REWARDS_POINTS_SUCCESS,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS,
  UPDATE_REWARDS_POINTS_RULES_FAIL,
  UPDATE_REWARDS_POINTS_RULES_REQUEST,
  UPDATE_REWARDS_POINTS_RULES_SUCCESS,
} from 'redux/constants/data/rewardsPointsConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getRewardsPointsSettings = () => {
  const url = '/Backend/PointSetting/GetSetting';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_REWARDS_POINTS_SETTINGS_FAIL,
      method: 'get',
      requestConstant: GET_REWARDS_POINTS_SETTINGS_REQUEST,
      successConstant: GET_REWARDS_POINTS_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const updateRewardsPointsEnableSettings = data => {
  const url = '/Backend/PointSetting/EnableSetting';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL,
      method: 'post',
      requestConstant: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST,
      successConstant: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const updateRewardsPointsRules = data => {
  const url = '/Backend/PointSetting/GiveSetting';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_REWARDS_POINTS_RULES_FAIL,
      method: 'post',
      requestConstant: UPDATE_REWARDS_POINTS_RULES_REQUEST,
      successConstant: UPDATE_REWARDS_POINTS_RULES_SUCCESS,
      url,
    });
  };
};

export const updateRedeemRewardsPoints = data => {
  const url = '/Backend/PointSetting/RedeemSetting';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_REDEEM_REWARDS_POINTS_FAIL,
      method: 'post',
      requestConstant: UPDATE_REDEEM_REWARDS_POINTS_REQUEST,
      successConstant: UPDATE_REDEEM_REWARDS_POINTS_SUCCESS,
      url,
    });
  };
};
