import {
  OVERALL_REQUEST,
  OVERALL_SUCCESS,
  OVERALL_FAIL,
} from 'redux/constants/data/overallConstants';

export const overallReducer = (
  state = { loading: false, overallInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case OVERALL_REQUEST:
      return { ...state, loading: true };
    case OVERALL_SUCCESS:
      return { loading: false, overallInfo: payload || {} };
    case OVERALL_FAIL:
      return { error: payload, loading: false, overallInfo: {} };
    default:
      return state;
  }
};
