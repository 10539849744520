import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

class MessageUtil {
  /*****************  basic ****************/
  static mSwal = null;

  static Instance = () => {
    // console.log('get SwalDialog')

    if (this.mSwal === null) {
      // console.log('create SwalDialog')
      this.mSwal = withReactContent(Swal);
    }

    // console.log("mSqal" + this.mSwal);
    return this.mSwal;
  };

  /*****************  alert ****************/

  static alertWanring = (title, msg) => {
    this.Instance().fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: 'warning',
      text: typeof msg === 'object' ? JSON.stringify(msg) : msg,
      title: typeof title === 'object' ? JSON.stringify(title) : title,
    });
  };

  static alertSuccess = (title, msg) => {
    this.Instance().fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: 'success',
      text: typeof msg === 'object' ? JSON.stringify(msg) : msg,
      title: typeof title === 'object' ? JSON.stringify(title) : title,
    });
  };

  static alertWanringWithHTML = (title, code) => {
    this.Instance().fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      html: code,
      icon: 'success',
      title: typeof title === 'object' ? JSON.stringify(title) : title,
    });
  };

  static alertErrorsWithHTML = (title, code) => {
    this.Instance().fire({
      html: code,
      icon: 'warning',
      title: typeof title === 'object' ? JSON.stringify(title) : title,
    });
  };

  /***************** toast(1.5秒後會自動消失) ****************/

  static toast_ms = 1500; // 顯示時間

  static toastSuccess = (title, msg) => {
    this.Instance().fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: 'success',
      showConfirmButton: false,
      text: msg,
      timer: this.toast_ms,
      title: title,
    });
  };

  static toastWarning = (title, msg) => {
    this.Instance().fire({
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      icon: 'warning',
      showConfirmButton: false,
      text: typeof msg === 'object' ? JSON.stringify(msg) : msg,
      timer: this.toast_ms,
      title: typeof title === 'object' ? JSON.stringify(title) : title,
    });
  };

  /***************** confirm ****************/
  static confirm = (title, msg, doneFun) => {
    this.Instance()
      .fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        text: msg,
        title: title,
      })
      .then(result => {
        if (result.isConfirmed && doneFun) {
          doneFun();
        }
      });
  };

  static delConfirm = doneFun => {
    this.Instance()
      .fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        text: '',
        title: '確定刪除嗎?',
      })
      .then(result => {
        if (result.isConfirmed && doneFun) {
          doneFun();
        }
      });
  };
  static logOutConfirm = doneFun => {
    this.Instance()
      .fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        text: '',
        title: '確定登出嗎?',
      })
      .then(result => {
        if (result.isConfirmed && doneFun) {
          doneFun();
        }
      });
  };

  static submitConfirm = ({ doneFun, cancelFun, title = '', text = '' }) => {
    this.Instance()
      .fire({
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        icon: 'question',
        showCancelButton: true,
        text,
        title: title || '資料已輸入完，確定儲存?',
      })
      .then(result => {
        if (result.isConfirmed && doneFun) {
          doneFun && doneFun();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          cancelFun && cancelFun();
        }
      });
  };
}

export default MessageUtil;
