import {
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_RESET,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_RESET,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_SORT_FAIL,
  CATEGORY_SORT_REQUEST,
  CATEGORY_SORT_RESET,
  CATEGORY_SORT_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_RESET,
  CATEGORY_UPDATE_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAIL,
  CREATE_PRODUCT_CATEGORY_IDLE,
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
} from '../../constants/data/categoryConstants';

export const categoryListReducer = (
  state = { categoryList: [], loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true };
    case CATEGORY_LIST_SUCCESS:
      return { categoryList: payload, loading: false };
    case CATEGORY_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const categoryAddReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_ADD_REQUEST:
      return { loading: true };
    case CATEGORY_ADD_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_ADD_FAIL:
      return { error: payload, loading: false };
    case CATEGORY_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
export const categoryUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true };
    case CATEGORY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_UPDATE_FAIL:
      return { error: payload, loading: false };
    case CATEGORY_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
export const categoryDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_DELETE_FAIL:
      return { error: payload, loading: false };
    case CATEGORY_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
export const categorySortReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_SORT_REQUEST:
      return { loading: true };
    case CATEGORY_SORT_SUCCESS:
      return { loading: false, success: true };
    case CATEGORY_SORT_FAIL:
      return { error: payload, loading: false };
    case CATEGORY_SORT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const createProductCategoryReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PRODUCT_CATEGORY_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_PRODUCT_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case CREATE_PRODUCT_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_PRODUCT_CATEGORY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
