import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import UploadFileBox from '../components/UploadFileBox';
import {
  editAboutInfo,
  getAboutInfo,
} from '../redux/actions/data/settingAction';
import { ABOUT_INFO_EDIT_RESET } from '../redux/constants/data/settingConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

const About = () => {
  const swal = withReactContent(Swal);
  const dispatch = useDispatch();

  /**************  useState **************/
  const [state_page_setting, setPageSetting] = useState({
    block_1: {},
    block_2: {},
    block_3: {},
    block_banner: {},
  });

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, aboutInfo, error } = useSelector(state => state.about);
  const {
    loading: editLoading,
    success,
    error: editError,
  } = useSelector(state => state.aboutEdit);

  console.log(aboutInfo);

  // console.log(success)

  // const getFileDataFromSetting = (page_setting) => {
  //   // console.log('getFileDataFromSetting')
  //   if (
  //     page_setting &&
  //     page_setting.fileUrl &&
  //     page_setting.fileUrl.length > 0
  //   ) {
  //     return [
  //       {
  //         id: CommonUtil.getNewID(),
  //         name: page_setting.fileName,
  //         url: page_setting.fileUrl,
  //       },
  //     ]
  //   }

  //   return []
  // }

  const getFileDataFromSetting = (pic, url) => {
    if (pic && url) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: pic,
          url: url,
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    console.log('call api');
    dispatch(getAboutInfo());
  }, []);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     // dispatch(userLogout())
  //     // history.push({ pathname: '/Login', state: '/About' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/About' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (success) {
      window.scrollTo(0, 0);
      dispatch(getAboutInfo());
      MessageUtil.toastSuccess('儲存成功!!');
    }

    if (editError) {
      if (editError.response && editError.response.data) {
        swal.fire({
          icon: 'warning',
          text: editError.response.data.message,
          title: `儲存失敗(${  editError.response.data.code  })`,
        });
      } else {
        swal.fire({
          icon: 'warning',
          text: editError,
          title: '儲存失敗',
        });
      }
    }

    return () => {
      dispatch({ type: ABOUT_INFO_EDIT_RESET });
    };
  }, [success, editError]);

  useEffect(() => {
    if (aboutInfo && aboutInfo.result && aboutInfo.result.length !== 0) {
      let pageSetting = {
        block_1: {},
        block_2: {},
        block_3: {},
        block_banner: {},
      };

      aboutInfo.result.forEach(setting => {
        switch (setting.block) {
          case 'banner':
            pageSetting.block_banner = setting;
            break;
          case 'block1':
            pageSetting.block_1 = setting;
            break;
          case 'block2':
            pageSetting.block_2 = setting;
            break;
          case 'block3':
            pageSetting.block_3 = setting;
            break;
        }
      });

      setPageSetting(pageSetting);
    }
  }, [aboutInfo]);

  /**************  form init **************/

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      banner_current_files: Yup.array().min(1, '需上傳圖片'),
      current_banner_files_mobile: Yup.array().min(1, '需上傳圖片'),
      navColor: Yup.string().required('必填!'),
    });

    return schema;
  };

  console.log(state_page_setting);

  const handleSubmit = values => {
    // console.log('handleSubmit')
    // console.log(JSON.stringify(values))
    // console.log(values.block2_banner_files[0])
    console.log(values);
    console.log(values.current_banner_files_mobile);
    console.log(values.block2_banner_files);
    console.log(values.block3_banner_files);

    let formData = new FormData();

    //---- banner
    formData.append('banner_title', values.banner_title);
    formData.append('banner_title_en', values.banner_title_en);
    formData.append('banner_introduction', values.banner_introduction);
    formData.append('banner_introduction_en', values.banner_introduction_en);
    formData.append('navColor', values.navColor);

    // 確認是否刪除原有的檔案
    let isRemoveBannerFile = false;
    if (
      state_page_setting.block_banner.fileName &&
      state_page_setting.block_banner.fileName.length > 0
    ) {
      if (
        values.banner_current_files &&
        values.banner_current_files.length > 0
      ) {
        isRemoveBannerFile =
          values.banner_current_files.filter(function (item) {
            return item.name === state_page_setting.block_banner.fileName;
          }).length == 0;
      } else {
        isRemoveBannerFile = true;
      }
    }
    formData.append('isRemoveBannerFile', isRemoveBannerFile);

    if (values.banner_files && values.banner_files.length > 0) {
      formData.append('banner_files', values.banner_files[0]);
    }

    // 確認是否刪除原有的檔案
    let isRemoveOrginalFile_mobile = false;
    if (state_page_setting.block_banner.fileName_mobile) {
      if (
        values.current_banner_files_mobile &&
        values.current_banner_files_mobile.length > 0
      ) {
        isRemoveOrginalFile_mobile =
          values.current_banner_files_mobile.filter(function (item) {
            return item.name === state_page_setting.block_banner.fileName;
          }).length == 0;
      } else {
        isRemoveOrginalFile_mobile = true;
      }
    }
    formData.append('isRemoveOrginalFile_mobile', false);

    if (values.banner_files_mobile && values.banner_files_mobile.length > 0) {
      formData.append('banner_files_mobile', values.banner_files_mobile[0]);
    }

    //---- block1
    formData.append('block1_title', values.block1_title);
    formData.append('block1_title_en', values.block1_title_en);
    formData.append('block1_introduction', values.block1_introduction);
    formData.append('block1_introduction_en', values.block1_introduction_en);

    let isRemoveBlock1File = false;
    if (
      state_page_setting.block_1.fileName &&
      state_page_setting.block_1.fileName.length > 0
    ) {
      if (
        values.block1_current_files &&
        values.block1_current_files.length > 0
      ) {
        isRemoveBlock1File =
          values.block1_current_files.filter(function (item) {
            return item.name === state_page_setting.block_1.fileName;
          }).length == 0;
      } else {
        isRemoveBlock1File = true;
      }
    }
    formData.append('isRemoveBlock1File', isRemoveBlock1File);

    if (values.block1_banner_files && values.block1_banner_files.length > 0) {
      formData.append('block1_banner_files', values.block1_banner_files[0]);
    }

    console.log(state_page_setting);

    //---- block2
    let isRemoveBlock2File = false;
    if (
      state_page_setting.block_2.fileName &&
      state_page_setting.block_2.fileName.length > 0
    ) {
      if (
        values.block2_current_files &&
        values.block2_current_files.length > 0
      ) {
        isRemoveBlock2File =
          values.block2_current_files.filter(function (item) {
            return item.name === state_page_setting.block_2.fileName;
          }).length == 0;
      } else {
        isRemoveBlock2File = true;
      }
    }
    formData.append('isRemoveBlock2File', isRemoveBlock2File);

    if (values.block2_banner_files && values.block2_banner_files.length > 0) {
      formData.append('block2_banner_files', values.block2_banner_files[0]);
    }

    //---- block3
    formData.append('block3_title', values.block3_title);
    formData.append('block3_title_en', values.block3_title_en);
    formData.append('block3_introduction', values.block3_introduction);
    formData.append('block3_introduction_en', values.block3_introduction_en);

    let isRemoveBlock3File = false;
    if (
      state_page_setting.block_3.fileName &&
      state_page_setting.block_3.fileName.length > 0
    ) {
      if (
        values.block3_current_files &&
        values.block3_current_files.length > 0
      ) {
        isRemoveBlock3File =
          values.block3_current_files.filter(function (item) {
            return item.name === state_page_setting.block_3.fileName;
          }).length == 0;
      } else {
        isRemoveBlock3File = true;
      }
    }
    formData.append('isRemoveBlock3File', isRemoveBlock3File);

    if (values.block3_banner_files && values.block3_banner_files.length > 0) {
      formData.append('block3_banner_files', values.block3_banner_files[0]);
    }

    for (var pair of formData.entries()) {
      console.log(`${pair[0]  }, ${  pair[1]}`);
    }

    dispatch(editAboutInfo(formData));
  };

  return (
    <Layout
      pageTitle="關於我們"
      items={[{ isActive: true, page_name: '關於我們' }]}
    >
      <Card className={style.card}>
        <Formik
          enableReinitialize
          initialValues={{
            banner_current_files:
              state_page_setting &&
              state_page_setting.block_banner.fileName &&
              getFileDataFromSetting(
                state_page_setting.block_banner.fileName,
                state_page_setting.block_banner.fileUrl,
              ),
            banner_files: [],
            banner_files_mobile: [],
            banner_introduction:
              state_page_setting.block_banner.introduction ?? '',
            banner_introduction_en:
              state_page_setting.block_banner.introduction_en ?? '',

            banner_title: state_page_setting.block_banner.title ?? '',
            banner_title_en: state_page_setting.block_banner.title_en ?? '',
            block1_current_files:
              state_page_setting &&
              state_page_setting.block_1.fileName &&
              getFileDataFromSetting(
                state_page_setting.block_1.fileName,
                state_page_setting.block_1.fileUrl,
              ),
            block1_files: [],

            block1_introduction: state_page_setting.block_1.introduction ?? '',
            block1_introduction_en:
              state_page_setting.block_1.introduction_en ?? '',
            block1_title: state_page_setting.block_1.title ?? '',
            block1_title_en: state_page_setting.block_1.title_en ?? '',

            block2_current_files:
              state_page_setting &&
              state_page_setting.block_2.fileName &&
              getFileDataFromSetting(
                state_page_setting.block_2.fileName,
                state_page_setting.block_2.fileUrl,
              ),
            block2_files: [],
            block3_current_files:
              state_page_setting &&
              state_page_setting.block_3.fileName &&
              getFileDataFromSetting(
                state_page_setting.block_3.fileName,
                state_page_setting.block_3.fileUrl,
              ),
            block3_files: [],

            block3_introduction: state_page_setting.block_3.introduction ?? '',
            block3_introduction_en:
              state_page_setting.block_3.introduction_en ?? '',
            block3_title: state_page_setting.block_3.title ?? '',
            block3_title_en: state_page_setting.block_3.title_en ?? '',

            current_banner_files_mobile: [],
            init_banner_files_mobile:
              state_page_setting &&
              state_page_setting.block_banner.fileName_mobile
                ? getFileDataFromSetting(
                    state_page_setting.block_banner.fileName_mobile,
                    state_page_setting.block_banner.fileUrl_mobile,
                  )
                : [],
            navColor: state_page_setting.block_banner.navColor ?? '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <h4 className="title">形像圖</h4>
              <div className="form-group mb-3 mb-3">
                <label>標題</label>
                <div className="form-item">
                  <Field name="banner_title" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3 mb-3">
                <label>標題(英)</label>
                <div className="form-item">
                  <Field name="banner_title_en" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3 mb-3">
                <label className="">說明</label>
                <div className="form-item">
                  <Field
                    name="banner_introduction"
                    className="form-control"
                    as="textarea"
                  />
                </div>
              </div>

              <div className="form-group mb-3 mb-3">
                <label className="">說明(英)</label>
                <div className="form-item">
                  <Field
                    as="textarea"
                    name="banner_introduction_en"
                    className="form-control"
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required" style={{ marginRight: '5.8rem' }}>
                  形象圖
                </label>
                <div className="form-item">
                  <div className={style.formGroup}>
                    <label style={{ width: '120px' }}>電腦版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        setFormFieldValue={props.setFieldValue}
                        formFieldName="banner_files"
                        currentFileFieldName="banner_current_files"
                        uplodModel="images"
                        displayFileWidth="1920"
                        displayFileHeight="1080"
                        displayFileSize="1MB"
                        initFiles={props.values.banner_current_files}
                      />
                      <ErrorMessage
                        name="banner_current_files"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>

                  <div className={style.formGroup}>
                    <label style={{ width: '120px' }}>手機版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        setFormFieldValue={props.setFieldValue}
                        formFieldName="banner_files_mobile"
                        currentFileFieldName="current_banner_files_mobile"
                        uplodModel="images"
                        displayFileWidth="800"
                        displayFileHeight="1200"
                        displayFileSize="1MB"
                        initFiles={props.values.init_banner_files_mobile}
                      />
                      <ErrorMessage
                        name="current_banner_files_mobile"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h4 className="title mt-8">主選單圖標顏色</h4>

              <div className={style.formGroup}>
                <label className="required" style={{ marginRight: '6.5rem' }}>
                  顏色
                </label>
                <div className="form-item">
                  <div className="d-flex">
                    <Label className="mr-3" style={{ marginRight: '1rem' }}>
                      <Field name="navColor" type="radio" value="white" /> 白色
                    </Label>

                    <Label className="mr-3" style={{ marginRight: '1rem' }}>
                      <Field name="navColor" type="radio" value="black" /> 黑色
                    </Label>

                    <ErrorMessage
                      name="navColor"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
              </div>

              <h4 className="title mt-8">區塊一</h4>

              <div className="form-group mb-3">
                <label>標題</label>
                <div className="form-item">
                  <Field name="block1_title" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>標題(英)</label>
                <div className="form-item">
                  <Field name="block1_title_en" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>說明</label>
                <div className="form-item">
                  <Field name="block1_introduction" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>說明(英)</label>
                <div className="form-item">
                  <Field
                    name="block1_introduction_en"
                    className="form-control"
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label style={{ marginRight: '5.8rem' }}>背景圖</label>
                <div className="form-item">
                  <UploadFileBox
                    setFormFieldValue={props.setFieldValue}
                    formFieldName="block1_banner_files"
                    currentFileFieldName="block1_current_files"
                    uplodModel="images"
                    displayFileWidth="1920"
                    displayFileHeight="1080"
                    displayFileSize="1MB"
                    initFiles={props.values.block1_current_files}
                  />
                </div>
              </div>

              <h4 className="title mt-8">區塊二</h4>
              <div className={style.formGroup}>
                <label style={{ marginRight: '5.8rem' }}>背景圖</label>
                <div className="form-item">
                  <UploadFileBox
                    setFormFieldValue={props.setFieldValue}
                    formFieldName="block2_banner_files"
                    currentFileFieldName="block2_current_files"
                    uplodModel="images"
                    displayFileWidth="1920"
                    displayFileHeight="1080"
                    displayFileSize="1MB"
                    initFiles={props.values.block2_current_files}
                  />
                </div>
              </div>
              <h4 className="title mt-8">區塊三</h4>
              <div className="form-group mb-3">
                <label>標題</label>
                <div className="form-item">
                  <Field name="block3_title" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>標題(英)</label>
                <div className="form-item">
                  <Field name="block3_title_en" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>說明</label>
                <div className="form-item">
                  <Field name="block3_introduction" className="form-control" />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>說明(英)</label>
                <div className="form-item">
                  <Field
                    name="block3_introduction_en"
                    className="form-control"
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label style={{ marginRight: '5.8rem' }}>背景圖</label>
                <div className="form-item">
                  <UploadFileBox
                    setFormFieldValue={props.setFieldValue}
                    uplodModel="images"
                    formFieldName="block3_banner_files"
                    currentFileFieldName="block3_current_files"
                    displayFileWidth="1920"
                    displayFileHeight="1080"
                    displayFileSize="1MB"
                    initFiles={props.values.block3_current_files}
                  />
                </div>
              </div>
              <hr />
              <div className="text-center">
                <Button
                  type="submit"
                  className={style.modal_button_submit}
                  disabled={editLoading}
                >
                  {editLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout>
  );
};

export default About;
