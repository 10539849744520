import moment from 'moment';
import { useState, useEffect } from 'react';
import { userLogout } from 'redux/actions/data/userActions';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import request from 'utils/useAxios';

const useFetch = url => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(url)
      .then(res => {
        if (!res.ok) {
          throw Error('Could not fetch data...');
        }
        return res.json();
      })
      .then(data => {
        setData(data.result);
        setIsLoading(false);
        setError(null);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err.message);
      });
  }, [url]);

  return { data, error, isLoading };
};

export const useRequest = async ({
  dispatch,
  requestConstant,
  successConstant,
  failConstant,
  url,
  method,
  params,
  data,
}) => {
  requestConstant && dispatch({ type: requestConstant });
  const response = await request({
    data,
    method,
    params,
    url,
  });

  const test = localStorage.getItem('backend_auth');

  // 授權逾時 or 主動登出
  if (moment(Date.now()).isAfter(test?.authValidTime) || !test) {
    dispatch(userLogout());
    MessageUtil.alertWanring('請重新登入取得授權');
    failConstant && dispatch({ type: failConstant });
    history.push({ pathname: '/Login' });

    // 無權限
  } else if (response.code === 401 && test) {
    MessageUtil.alertWanring('您無此功能權限，如需開啟此權限，請洽網站管理員');
    history.push('/');
  }

  response.code !== 200 &&
    response.code !== 500 &&
    response.code !== 401 &&
    failConstant &&
    dispatch({ payload: response.message, type: failConstant });

  response.code === 500 &&
    failConstant &&
    dispatch({ payload: '系統錯誤，請連繫開發人員', type: failConstant });

  response.code === 200 &&
    successConstant &&
    dispatch({
      payload: response.result,
      type: successConstant,
    });

  response.code === undefined &&
    dispatch({
      payload: response,
      type: successConstant,
    });
};
export default useFetch;
