import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input } from 'reactstrap';
import { createMessageReply } from 'redux/actions/data/messageActions';

import style from '../../styles/layout.module.css';

const ReplyArea = ({ id }) => {
  const dispatch = useDispatch();
  const { loading: replyLoading, success: replySuccess } = useSelector(
    state => state.createMessageReply,
  );
  const { values, setFieldValue, handleSubmit, handleChange, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        context: '',
        messageId: id,
        reply_files: [],
      },
      onSubmit: () => {
        const formData = new FormData();

        formData.append('messageId', values.messageId);
        formData.append('context', values.context);
        formData.append('reply_files', JSON.stringify(values.reply_files));

        dispatch(createMessageReply(formData));
      },
    });

  useEffect(() => {
    if (replySuccess) {
      handleReset();
    }
  }, [replySuccess]);

  return (
    <Card className={style.search_card}>
      <div className={style.card_header}>後台回覆</div>
      <div className={style.card_body}>
        <Input
          type="textarea"
          className="mb-3"
          style={{ height: '230px' }}
          value={values.context}
          id="context"
          onChange={handleChange}
        />
        <Button
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={replyLoading}
        >
          送出回覆
        </Button>
      </div>
    </Card>
  );
};

export default ReplyArea;
