import {
  HIDE_MODEL_LIST_REQUEST,
  HIDE_MODEL_LIST_SUCCESS,
  HIDE_MODEL_LIST_FAIL,
  HIDE_MODEL_GET_REQUEST,
  HIDE_MODEL_GET_SUCCESS,
  HIDE_MODEL_GET_FAIL,
} from 'redux/constants/data/hideModelConstants';

export const hideModelListReducer = (
  state = { hideModelListInfo: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case HIDE_MODEL_LIST_REQUEST:
      return { ...state, loading: true };
    case HIDE_MODEL_LIST_SUCCESS:
      return { hideModelListInfo: payload || {}, loading: false };
    case HIDE_MODEL_LIST_FAIL:
      return {
        error: payload,
        hideModelListInfo: {},
        loading: false,
      };
    default:
      return state;
  }
};
export const hideModelDetailReducer = (
  state = { hideModelDetailInfo: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case HIDE_MODEL_GET_REQUEST:
      return { ...state, loading: true };
    case HIDE_MODEL_GET_SUCCESS:
      return { hideModelDetailInfo: payload || {}, loading: false };
    case HIDE_MODEL_GET_FAIL:
      return {
        error: payload,
        hideModelDetailInfo: {},
        loading: false,
      };
    default:
      return state;
  }
};
