import { Alert } from 'reactstrap';

import style from '../../styles/layout.module.css';

const Message = ({
  type,
  name,
  time,
  content,
  messageImages = [],
  setCurrentImgSrc,
  setIsOriginImageModalOpen,
}) => {
  const labelStyle =
    type === 'staff'
      ? style['message-record-font-style-staff']
      : style['message-record-font-style-customer'];
  const alertColor =
    type === 'staff'
      ? style['message-record-background-color-staff']
      : style['message-record-background-color-customer'];
  return (
    <div>
      <div className={'mb-1'}>
        <label className={labelStyle}>{name || '消費者回覆'}</label>
        {/* <div style={{ margin: 'auto' }}/> */}
        <div>
          <small>{time}</small>
        </div>
      </div>
      <Alert color="" className={alertColor}>
        <div className="mb-3" style={{ whiteSpace: 'pre-line' }}>
          {content}
        </div>
        <div className={`${style.show_flex}`}>
          {messageImages?.length > 0 &&
            messageImages.map(img => (
              <img
                style={{ cursor: 'pointer', height: '100px', width: '100px' }}
                alt={`${img}`}
                src={img}
                onClick={() => {
                  setCurrentImgSrc(img);
                  setIsOriginImageModalOpen();
                }}
              />
            ))}
        </div>
      </Alert>
    </div>
  );
};

export default Message;
