import Moment from 'moment';
import { extendMoment } from 'moment-range';
import RcPagination from 'rc-pagination';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { deleteNews, getNewsList } from '../redux/actions/data/newsActions';
import { NEWS_DELETE_CLEAR } from '../redux/constants/data/newsConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const NewsList = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const moment = extendMoment(Moment);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { loading, newsListInfo } = useSelector(state => state.newsList);

  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.newsDelete,
  );

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        setKeyword(queryKeyword);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(getNewsList(queryKeyword, queryStatus, queryPage));
      } else {
        //單純記錄頁數
        dispatch(getNewsList('', '', queryPage));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      dispatch(getNewsList('', '', 1));
    }
  }, [location.search]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (newsListInfo.datas.length !== 1) {
        dispatch(getNewsList(keyword, status, currentPage));
      } else {
        dispatch(getNewsList(keyword, status, currentPage - 1));

        history.push(
          `${location.pathname}?keyword=${keyword}&status=${status}&page=${
            currentPage - 1
          }`,
        );

        setCurrentPage(currentPage - 1);
      }
    }

    if (deleteError && deleteError.code === APIUtil.Code_Not_Found) {
      MessageUtil.alertWanring('資料錯誤', '');
    }

    return () => {
      dispatch({ type: NEWS_DELETE_CLEAR });
    };
  }, [deleteSuccess, deleteError]);

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getNewsList(keyword, status, page));
    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const clearSearchForm = () => {
    setKeyword('');
    setStatus('');
    dispatch(getNewsList('', '', 1));
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    dispatch(getNewsList(keyword, status, 1));
    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };

  const callDel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteNews(id));
    });
  };

  const handlePreview = id => {
    history.push(`/NewsPreview/${id}`);
  };

  useEffect(() => {
    if (newsListInfo) {
      if (JSON.stringify(newsListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: newsListInfo });
      }
    }
  }, [newsListInfo]);

  return (
    <Layout
      pageTitle="新聞管理"
      items={[{ isActive: false, page_name: '新聞管理' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label htmlFor="name" className="mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="請輸入關鍵字"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>

              <div className={style.formGroup}>
                <label htmlFor="status" className="mr-2">
                  狀態
                </label>
                <Select
                  className={style['form-select']}
                  options={[
                    { label: '全部', value: '' },
                    { label: '上架', value: '上架' },
                    { label: '下架', value: '下架' },
                  ]}
                  value={
                    [
                      { label: '全部', value: '' },
                      { label: '上架', value: '上架' },
                      { label: '下架', value: '下架' },
                    ].filter(o => o.value === status)[0]
                  }
                  onChange={option => {
                    setStatus(option.value);
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                color="light"
                type="button"
                className={style.modal_button_cancel}
                style={{ marginRight: '1rem' }}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card className={style.card}>
        <button
          className="btn btn-success w100"
          onClick={() => history.push('/NewsDetail')}
        >
          新增
        </button>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && newsListInfo && newsListInfo.total_count
              ? newsListInfo.total_count
              : 0}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th style={{ minWidth: '120px' }}>標題</th>
                <th style={{ minWidth: '200px' }}>摘要</th>
                <th>日期區間</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {newsListInfo?.datas?.length ? (
                  newsListInfo?.datas?.map((data, index) => (
                    <tr key={data.id}>
                      <td>
                        {index + 1 + (currentPage - 1) * newsListInfo.page_size}
                      </td>
                      <td>{data.title}</td>
                      <td style={{ whiteSpace: 'pre-wrap' }}>
                        {data.introdution}
                      </td>
                      <td>
                        <span style={{ wordBreak: 'keep-all' }}>
                          {data.startDate
                            ? moment(new Date(data.startDate)).format(
                                'yyyy/MM/DD',
                              )
                            : ''}
                        </span>
                        <br />
                        <span>
                          {data.endDate
                            ? moment(new Date(data.endDate)).format(
                                'yyyy/MM/DD',
                              )
                            : ''}
                        </span>
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/NewsDetail/${data.id}`}
                        >
                          編輯
                        </Link>
                        <Button
                          className="m-2 btn btn-sm"
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: '#f4772e',
                          }}
                          onClick={() => handlePreview(data.id)}
                        >
                          <span style={{ color: '#f4772e' }}>預覽</span>
                        </Button>
                        <Button
                          className="m-2"
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && newsListInfo && newsListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={newsListInfo.current_page}
              defaultPageSize={10}
              total={newsListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
    </Layout>
  );
};

export default NewsList;
