import SortingTableHead from 'components/SortingTableHead';
import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React from 'react';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { GrClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { getProductSalesDataRanking } from 'redux/actions/data/operatingReportActions';

import style from '../../styles/layout.module.css';
import { useStore } from '../../utils/useStore';

const ProductType = ['' , '一般商品', '隱形賣場','組合商品']
const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

const ModelCard = ({
  setIsChooseModalOpen,
  brandModelOptions,
  setBrandModelOptions,
  currentTab,
}) => {
  const { loading, rankingList } = useSelector(
    state => state.getProductSalesDataRanking,
  );
  const dispatch = useDispatch();
  const { values, setFieldValue, handleSubmit, handleReset, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        activeId: 0,
        dtEnd: '',
        dtStart: '',
        keyword: '',
        options: brandModelOptions,
        order: 'desc',
        order_by: 'rankingIndex',
        page: 1,
        page_size: 10,
        qMode: 'model',
        rankingNum: 0,
        salesChannel: [{ label: 'online', value: 'online' }],
      },
      onReset: () => {
        handleSubmit();
      },
      onSubmit: values => {
        let modelIds = [];
        values.options.forEach(o => {
          if (o.model.value === '') {
            o.models.forEach(m => modelIds.push(m.value));
          } else {
            modelIds.push(o.model.value);
          }
        });
        const body = {
          brandIds: values.options.map(option => option.brand.value) || [],
          dtEnd: values.dtEnd
            ? moment(values.dtEnd).format('YYYY/MM/DD HH:mm:ss')
            : '',
          dtStart: values.dtStart
            ? moment(values.dtStart).format('YYYY/MM/DD HH:mm:ss')
            : '',
          keyword: values.keyword,
          models: modelIds || [],
          order: values.order,
          order_by: values.order_by,
          page: values.page,
          page_size: values.page_size,
          qMode: values.qMode,
          rankingNum: values.rankingNum,
          salesChannel: values?.salesChannel.map(s => s.value).filter(s => s),
        };
        dispatch(getProductSalesDataRanking(body));
      },
    });

  const handleChangePage = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    if (currentTab == 1) {
      handleSubmit();
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab == 1) {
      handleSubmit();
    }
  }, [values.activeId]);
  const { options: StoreOptions, loading: storeLoading } = useStore();

  return (
    <React.Fragment>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={style.show_flex}>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>關鍵字</label>
              <Input
                id="keyword"
                type="text"
                placeholder="商品名稱、商品編號"
                value={values.keyword}
                onChange={e => setFieldValue('keyword', e.target.value)}
              />
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>門市</label>
              <Select
                isMulti
                className="w-100"
                options={StoreOptions}
                isLoading={storeLoading}
                value={values.salesChannel}
                onChange={e => {
                  setFieldValue('salesChannel', e);
                }}
              />
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>日期區間</label>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  showTimeSelect
                  selected={
                    values.dtStart ? moment(values.dtStart).toDate() : ''
                  }
                  onChange={date => setFieldValue('dtStart', date)}
                />
              </div>
              <span style={{ margin: '1rem' }}>~</span>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  showTimeSelect
                  selected={values.dtEnd ? moment(values.dtEnd).toDate() : ''}
                  onChange={date => setFieldValue('dtEnd', date)}
                  minDate={
                    values.dtStart ? moment(values.dtStart).toDate() : ''
                  }
                />
              </div>
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>排名</label>
              <Input
                type="select"
                className={style['form-select-short']}
                style={{ margin: 0 }}
                id="rankingNum"
                onChange={handleChange}
                value={values.rankingNum}
              >
                <option value={0}>全部</option>
                <option value={50}>前50</option>
                <option value={100}>前100</option>
                <option value={150}>前150</option>
              </Input>
            </div>
          </div>
          <div className={`${style.formGroup} ${style['mr-1p5']}`}>
            <div>
              <div className={style.show_flex}>
                <label>品牌 / 型號</label>
                <Button
                  color="primary w80"
                  className="btn-sm"
                  outline
                  onClick={setIsChooseModalOpen}
                >
                  選擇
                </Button>
              </div>
              <div className={style.show_flex}>
                {values.options.map((option, i) => (
                  <div
                    key={i}
                    className={style.show_flex}
                    style={{
                      backgroundColor: '#E8E8E8',
                      margin: '0.5rem',
                      padding: '0.3rem',
                    }}
                  >
                    {`${option.brand.label} / ${option.model.label}`}
                    <div style={{ width: '20px' }} />
                    <GrClose
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setBrandModelOptions(
                          values.options.filter(o => o !== option),
                        );

                        // setFieldValue('options', values.options.filter(o => o !== option));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                setBrandModelOptions([]);
                handleReset();
                handleSubmit();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <TableHead>
              <tr>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 0,
                      item: 'rankingIndex',
                      up: 1,
                    }}
                    title="銷售排行"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 2,
                      item: 'brandName',
                      up: 3,
                    }}
                    title="品牌"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 4,
                      item: 'model',
                      up: 5,
                    }}
                    title="型號"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                      activeId={{
                        down: 14,
                        item: 'productType',
                        up: 15,
                      }}
                      title="產品類型"
                      setFieldValue={setFieldValue}
                      currentActiveId={values.activeId}
                      handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 6,
                      item: 'quantity_sum',
                      up: 7,
                    }}
                    title="商品銷售量"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 8,
                      item: 'orderItemId_count',
                      up: 9,
                    }}
                    title="訂單數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 10,
                      item: 'finalPrice_sum',
                      up: 11,
                    }}
                    title="商品總金額"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 12,
                      item: 'finalPrice_sell_sum',
                      up: 13,
                    }}
                    title="訂單總金額"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                    handleSubmit={handleSubmit}
                  />
                </th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {rankingList.datas?.length > 0 ? (
                  rankingList.datas.map((data, i) => (
                    <tr key={i}>
                      <td>{data.rankingIndex ?? '--'}</td>
                      <td>{data.brandName ?? '--'}</td>
                      <td>{data.model ?? '--'}</td>
                      <td title={data.productType}>{ProductType[data.productType]}</td>
                      <td>{data.quantity_sum ?? '--'}</td>
                      <td>{data.orderItemId_count ?? '--'}</td>
                      <td className="price fw-normal text-black">
                        {(data.finalPrice_sell_sum || 0).toLocaleString(
                          'en-US',
                        )}
                      </td>
                      <td className="price fw-normal text-black">
                        {(data.finalPrice_sum || 0).toLocaleString('en-US')}
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData colNumber={7} />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={rankingList.current_page || 1}
            defaultPageSize={rankingList.page_size || 10}
            total={rankingList.total_count}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

const ProductCard = ({
  brandModelOptions,
  setIsChooseModalOpen,
  setBrandModelOptions,
  currentTab,
}) => {
  const { loading, rankingList } = useSelector(
    state => state.getProductSalesDataRanking,
  );
  const dispatch = useDispatch();
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleReset,
    setValues,
    handleChange,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeId: 0,
      dtEnd: '',
      dtStart: '',
      keyword: '',
      options: brandModelOptions,
      order: 'desc',
      order_by: 'rankingIndex',
      page: 1,
      page_size: 10,
      qMode: 'product',
      rankingNum: 0,
      salesChannel: [{ label: 'online', value: 'online' }],
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      let modelIds = [];

      values.options.forEach(o => {
        if (o.model.value === '') {
          o.models.forEach(m => modelIds.push(m.value));
        } else {
          modelIds.push(o.model.value);
        }
      });
      const body = {
        brandIds: values.options.map(option => option.brand.value) || [],
        dtEnd: values.dtEnd
          ? moment(values.dtEnd).format('YYYY/MM/DD HH:mm:ss')
          : '',
        dtStart: values.dtStart
          ? moment(values.dtStart).format('YYYY/MM/DD HH:mm:ss')
          : '',
        keyword: values.keyword,
        models: modelIds || [],
        order: values.order,
        order_by: values.order_by,
        page: values.page,
        page_size: values.page_size,
        qMode: values.qMode,
        rankingNum: values.rankingNum,
        salesChannel: values?.salesChannel.map(s => s.value).filter(s => s),
      };
      dispatch(getProductSalesDataRanking(body));
    },
  });

  const handleChangePage = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    if (currentTab == 2) {
      handleSubmit();
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab == 2) {
      handleSubmit();
    }
  }, [values.activeId]);
  const { options: StoreOptions, loading: storeLoading } = useStore();

  return (
    <React.Fragment>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={style.show_flex}>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>關鍵字</label>
              <Input
                id="keyword"
                type="text"
                placeholder="商品名稱、商品編號"
                value={values.keyword}
                onChange={e => setFieldValue('keyword', e.target.value)}
              />
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>門市</label>
              <Select
                isMulti
                className="w-100"
                options={StoreOptions}
                isLoading={storeLoading}
                value={values.salesChannel}
                onChange={e => {
                  setFieldValue('salesChannel', e);
                }}
              />
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>日期區間</label>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  selected={
                    values.dtStart ? moment(values.dtStart).toDate() : ''
                  }
                  onChange={date => setFieldValue('dtStart', date)}
                />
              </div>
              <span style={{ margin: '1rem' }}>~</span>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  selected={values.dtEnd ? moment(values.dtEnd).toDate() : ''}
                  onChange={date => setFieldValue('dtEnd', date)}
                  minDate={
                    values.dtStart ? moment(values.dtStart).toDate() : ''
                  }
                />
              </div>
            </div>
            <div className={`${style.formGroup} ${style['mr-1p5']}`}>
              <label>排名</label>
              <Input
                type="select"
                className={style['form-select-short']}
                style={{ margin: 0 }}
                id="rankingNum"
                onChange={handleChange}
                value={values.rankingNum}
              >
                <option value={0}>全部</option>
                <option value={50}>前50</option>
                <option value={100}>前100</option>
                <option value={150}>前150</option>
              </Input>
            </div>
          </div>
          <div className={`${style.formGroup} ${style['mr-1p5']}`}>
            <div>
              <div className={style.show_flex}>
                <label>品牌 / 型號</label>
                <Button
                  color="primary w80"
                  className="btn-sm"
                  outline
                  onClick={setIsChooseModalOpen}
                >
                  選擇
                </Button>
              </div>
              <div className={style.show_flex}>
                {values.options.map((option, i) => (
                  <div
                    key={i}
                    className={style.show_flex}
                    style={{
                      backgroundColor: '#E8E8E8',
                      margin: '0.5rem',
                      padding: '0.3rem',
                    }}
                  >
                    {`${option.brand.label} / ${option.model.label}`}
                    <div style={{ width: '20px' }} />
                    <GrClose
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setBrandModelOptions(
                          values.options.filter(o => o !== option),
                        );

                        // setFieldValue('options', values.options.filter(o => o !== option));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                setBrandModelOptions([]);
                handleReset();
                handleSubmit();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <TableHead>
              <tr>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 0,
                      item: 'rankingIndex',
                      up: 1,
                    }}
                    title="銷售排行"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 2,
                      item: 'brandName',
                      up: 3,
                    }}
                    title="品牌"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 4,
                      item: 'productcode',
                      up: 5,
                    }}
                    title="商品編號"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 6,
                      item: 'productName',
                      up: 7,
                    }}
                    title="商品名稱"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                      activeId={{
                        down: 16,
                        item: 'productType',
                        up: 17,
                      }}
                      title="商品類型"
                      setFieldValue={setFieldValue}
                      currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 8,
                      item: 'quantity_sum',
                      up: 9,
                    }}
                    title="商品銷售量"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 10,
                      item: 'orderItemId_count',
                      up: 11,
                    }}
                    title="訂單數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 12,
                      item: 'finalPrice_sum',
                      up: 13,
                    }}
                    title="商品總金額"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 14,
                      item: 'finalPrice_sell_sum',
                      up: 15,
                    }}
                    title="訂單總金額"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {rankingList.datas?.length > 0 ? (
                  rankingList.datas.map((data, i) => (
                    <tr key={i}>
                      <td>{data.rankingIndex || '--'}</td>
                      <td>{data.brandName || '--'}</td>
                      <td>{data.productcode || '--'}</td>
                      <td>{data.productName || '--'}</td>
                      <td title={data.productType}>{ProductType[data.productType]}</td>
                      <td>{data.quantity_sum ?? '--'}</td>
                      <td>{data.orderItemId_count ?? '--'}</td>
                      <td className="price text-black fw-normal">
                        {(data.finalPrice_sell_sum || 0).toLocaleString(
                          'en-US',
                        )}
                      </td>
                      <td className="price text-black fw-normal">
                        {(data.finalPrice_sum || 0).toLocaleString('en-US')}
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData colNumber={8} />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={rankingList.current_page || 1}
            defaultPageSize={rankingList.page_size || 10}
            total={rankingList.total_count}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

const SearchCard = ({
  currentTab,
  setCurrentTab,
  setIsChooseModalOpen,
  brandModelOptions,
  setBrandModelOptions,
}) => {
  return (
    <React.Fragment>
      <Nav pills>
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="型號"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="產品"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <ModelCard
            setIsChooseModalOpen={setIsChooseModalOpen}
            brandModelOptions={brandModelOptions}
            setBrandModelOptions={setBrandModelOptions}
            currentTab={currentTab}
          />
        </TabPane>
        <TabPane tabId={2}>
          <ProductCard
            setIsChooseModalOpen={setIsChooseModalOpen}
            brandModelOptions={brandModelOptions}
            setBrandModelOptions={setBrandModelOptions}
            currentTab={currentTab}
          />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

export default SearchCard;
