import Layout from 'components/Layout';
import EditDraftPublishedDateModal from 'components/memberLevelSettings/EditDraftPublishedDateModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import {
  addMemberLevelSettings,
  copyMemberLevelList,
  deleteMemberLevelDraft,
  deleteOneDraft,
  getMemberLevelList,
  getPublishDate,
  updateMemberLevelDraft,
  updateMemberLevelSettings,
} from 'redux/actions/data/memberActions';
import {
  ADD_MEMBER_LEVEL_SETTINGS_IDLE,
  COPY_MEMBER_LEVEL_LIST_IDLE,
  DELETE_MEMBER_LEVEL_DRAFT_IDLE,
  DELETE_ONE_DRAFT_IDLE,
  UPDATE_MEMBER_LEVEL_DRAFT_IDLE,
  UPDATE_MEMBER_LEVEL_SETTINGS_IDLE,
  UPDATE_PUBLISH_DATE_IDLE,
} from 'redux/constants/data/memberConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const ChoosingPage = ({ onSetIsDraft, onSetIsCreateEmptyDraft }) => {
  const dispatch = useDispatch();

  return (
    <Card className={style.card} style={{ height: '400px' }}>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Button
          outline
          color="success"
          style={{ fontSize: '24px', height: '80px', width: '290px' }}
          onClick={() => {
            // onChoose('create');
            onSetIsCreateEmptyDraft();
          }}
        >
          新增會員等級
        </Button>
        <div className={style['w200']} />
        <Button
          outline
          color="success"
          style={{ fontSize: '24px', height: '80px', width: '290px' }}
          onClick={() => {
            // onChoose('copy');
            onSetIsDraft();
            dispatch(copyMemberLevelList());
          }}
        >
          複製目前設定
        </Button>
      </div>
    </Card>
  );
};

const TabItem = ({ currentTab, tab, onClick, text, setIsDraft }) => {
  const dispatch = useDispatch();
  return (
    <NavItem>
      <NavLink
        onClick={() => {
          onClick(tab);
          let status = 1;
          if (tab !== 1) {
            status = 3; // 草稿
            setIsDraft();
          }
          dispatch(
            getMemberLevelList({
              page: 1,
              page_size: 10,
              status: status,
            }),
          );

          // setIsDraft && setIsDraft();
        }}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

const SmallHint = ({ content }) => {
  return (
    <div className={`mb-4 ${style.show_flex}`}>
      <div style={{ width: '210px' }} />
      <div style={{ maxWidth: '470px' }}>
        {content.map((ctx, index) => (
          <small key={index}>
            <li>{ctx}</li>
          </small>
        ))}
      </div>
    </div>
  );
};

const ExpiredOptions = ({
  type,
  values,
  setFieldValue,
  limit,
  shoppingExpired,
  errors,
  touched,
  isCreate,
  isDraft,
}) => {
  return (
    <div className={style.show_flex}>
      <div
        style={{ alignItems: 'center', display: 'flex', marginRight: '2rem' }}
      >
        <input
          type="radio"
          style={{ cursor: 'pointer', height: '15px', width: '15px' }}
          value={182}
          onChange={event => {
            setFieldValue(`${type}`, event.target.value);
            if (type === 'levelExpired') {
              setFieldValue('shoppingExpired', event.target.value);
            }
          }}
          checked={values == 182}
          disabled={!isCreate && !isDraft}
        />
        <span className="ml-2">半年(182天)</span>
      </div>
      <div
        style={{ alignItems: 'center', display: 'flex', marginRight: '2rem' }}
      >
        <input
          type="radio"
          style={{ cursor: 'pointer', height: '15px', width: '15px' }}
          value={365}
          onChange={event => {
            setFieldValue(`${type}`, event.target.value);
            if (type === 'levelExpired' && shoppingExpired > 365) {
              setFieldValue('shoppingExpired', event.target.value);
            }
          }}
          checked={values == 365}
          disabled={limit !== -1 ? limit < 364 : !isCreate}
        />
        <span className="ml-2">一年(365天)</span>
      </div>
      <div
        style={{ alignItems: 'center', display: 'flex', marginRight: '2rem' }}
      >
        <input
          type="radio"
          style={{ cursor: 'pointer', height: '15px', width: '15px' }}
          value={730}
          onChange={event => {
            setFieldValue(`${type}`, event.target.value);
            if (type === 'levelExpired' && shoppingExpired == 1095) {
              setFieldValue('shoppingExpired', event.target.value);
            }
          }}
          checked={values == 730}
          disabled={limit !== -1 ? limit < 729 : !isCreate}
        />
        <span className="ml-2">兩年(730天)</span>
      </div>
      <div
        style={{ alignItems: 'center', display: 'flex', marginRight: '2rem' }}
      >
        <input
          type="radio"
          style={{ cursor: 'pointer', height: '15px', width: '15px' }}
          value={1095}
          onChange={event => {
            setFieldValue(`${type}`, event.target.value);
          }}
          checked={values == 1095}
          disabled={limit !== -1 ? limit < 1094 : !isCreate}
        />
        <span className="ml-2">三年(1095天)</span>
      </div>
      {errors && touched && <span className="text-danger">{errors}</span>}
    </div>
  );
};

const EditMemberLevelModal = ({
  isOpen,
  toggle,
  info,
  onRefresh,
  isCreate,
  nextDegree,
  isDraft,
}) => {
  const dispatch = useDispatch();
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.addMemberLevelSettings);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateMemberLevelSettings);
  const {
    loading: updateDraftLoading,
    success: updateDraftSuccess,
    error: updateDraftError,
  } = useSelector(state => state.updateMemberLevelDraft);
  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...info,
      id: info?.id,

      // enabledDate: info?.activeTime || null,
      isUpdating: info?.isUpdating,
      level: isCreate ? nextDegree || 0 : info?.degree || 0,
      levelExpired: info?.memberlevelDays || -1,
      levelKeepExpense: info?.renewAmount || '',
      levelName: info?.name || '',
      levelNameEn: info?.name_en || '',
      levelUpExpense: info?.upgradeAmount || '',
      remark: info?.remark,
      remarkEn: info?.remark_en,
      shoppingExpired: info?.accumulateDays || -1,
    },
    onSubmit: () => {
      const body = {
        accumulateDays: values.shoppingExpired,
        degree: values.level || 0,
        id: values.id || 0,
        level: values.level || 0,
        memberlevelDays: values.levelExpired,
        name: values.levelName,
        name_en: values.levelNameEn,

        // activeTime: moment(values.enabledDate).format('YYYY-MM-DD HH:mm'),
        remark: values.remark,
        remark_en: values.remarkEn,
        renewAmount: values.levelKeepExpense || 0,
        upgradeAmount: values.levelUpExpense || 0,
      };

      if (isCreate) {
        dispatch(addMemberLevelSettings(body));
      } else {
        // 更新目前資料
        if (!isDraft) {
          delete body.degree;
          delete body.memberlevelDays;
          delete body.accumulateDays;
          delete body.upgradeAmount;
          delete body.renewAmount;

          // delete body.activeTime;

          dispatch(updateMemberLevelSettings(body));
        } else {
          console.log(body);

          // console.log(info);
          dispatch(updateMemberLevelDraft(body));
        }
      }
    },
    validationSchema: Yup.object().shape({
      level: Yup.number(),
      levelExpired: Yup.number().when('level', {
        is: val => val != 0,
        then: Yup.number()
          .typeError('請填入數字')
          .nullable()
          .test('val', '必填', val => val !== -1)
          .required('必填'),
      }),
      levelKeepExpense: Yup.number().when('level', {
        is: val => val != 0,
        then: Yup.number().typeError('請填入數字').nullable().required('必填'),
      }),
      levelName: Yup.string().nullable().required('必填'),
      levelNameEn: Yup.string().nullable().required('必填'),
      levelUpExpense: Yup.number().when('level', {
        is: val => val != 0,
        then: Yup.number().typeError('請填入數字').nullable().required('必填'),
      }),

      // enabledDate: Yup.date().nullable().required('必填'),
      remark: Yup.string().nullable().required('必填'),
      remarkEn: Yup.string().nullable().required('必填'),
      shoppingExpired: Yup.number().when('level', {
        is: val => val != 0,
        then: Yup.number()
          .typeError('請填入數字')
          .nullable()
          .test('val', '必填', val => val !== -1)
          .required('必填'),
      }),
    }),
  });

  const shoppingExpiredHints = [
    '累積消費過期則重新歸零計算',
    '累積消費有效期限不得大於會員等級有效期限',
    '將期限延長至該月後一天',
  ];

  const levelUpExpenseHints = [
    '當滿足條件，會員將被升等至此等級。',
    '請注意：當該筆線上訂單達升等門檻後，會員將於完成訂單之隔日升等。(需該筆線上訂單內所有商品皆出貨完成)',
  ];

  const levelKeepExpenseHints = [
    '在會員等級有效期限內達成續等門檻，會員等級有效期限將會被延長；反之，會員等級將在有效期限過後被降等。',
  ];

  const enabledDateHints = [
    '已過啟用日期後，更動任何欄位，在隔日的八點前台才會顯示資料',
  ];

  useEffect(() => {
    if (addSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      toggle();
      onRefresh && onRefresh();
      dispatch({ type: ADD_MEMBER_LEVEL_SETTINGS_IDLE });
    }

    if (addError) {
      MessageUtil.alertWanring(addError);
      dispatch({ type: ADD_MEMBER_LEVEL_SETTINGS_IDLE });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      toggle();
      onRefresh && onRefresh();
      dispatch({ type: UPDATE_MEMBER_LEVEL_SETTINGS_IDLE });
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: UPDATE_MEMBER_LEVEL_SETTINGS_IDLE });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (updateDraftSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      toggle();
      onRefresh && onRefresh();
      dispatch({ type: UPDATE_MEMBER_LEVEL_DRAFT_IDLE });
    }

    if (updateDraftError) {
      MessageUtil.alertWanring(updateDraftError);
      dispatch({ type: UPDATE_MEMBER_LEVEL_DRAFT_IDLE });
    }
  }, [updateDraftSuccess, updateDraftError]);

  // set time range
  // const [startDate, setStartDate] = useState(null);

  // const filterPassedTime = (time) => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(time);

  //   return currentDate.getTime() < selectedDate.getTime();
  // };

  // console.log(info);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        會員等級編輯
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '480px' }}>
        {/* {
          values.isUpdating &&
          <div style={{ backgroundColor: '#e55757', padding: '0.6rem', marginBottom: '1rem', color: 'white' }}>
            <AiOutlineInfoCircle />
            {' '}
            <span>{`已異動資料，將於${values.updatingTime}更新`}</span>
          </div>
        } */}
        <div className={`mb-4 ${style.show_flex}`}>
          <div className="w200">會員等級</div>
          <div>等級{values.level}</div>
        </div>
        <div className={`mb-4 ${style.show_flex}`}>
          <div className="w200 required">會員等級名稱(中)</div>
          <Input
            className={style['form-input']}
            value={values.levelName}
            onChange={event => {
              setFieldValue('levelName', event.target.value);
            }}
            invalid={errors?.levelName && touched?.levelName}
          />
          {errors?.levelName && touched?.levelName && (
            <span className="text-danger ml-2">{errors.levelName}</span>
          )}
        </div>
        <div className={`mb-4 ${style.show_flex}`}>
          <div className="w200 required">會員等級名稱(英)</div>
          <Input
            className={style['form-input']}
            value={values.levelNameEn}
            onChange={event => {
              setFieldValue('levelNameEn', event.target.value);
            }}
            invalid={errors?.levelNameEn && touched?.levelNameEn}
          />
          {errors?.levelNameEn && touched?.levelNameEn && (
            <span className="text-danger ml-2">{errors.levelNameEn}</span>
          )}
        </div>
        <div className={`mb-4 ${style.show_flex}`}>
          <div className="required w150">會員等級有效期限</div>
          {values.levelExpired == -1 && values.level == 0 ? (
            <div>永久效期</div>
          ) : (
            <ExpiredOptions
              type="levelExpired"
              values={values.levelExpired}
              setFieldValue={setFieldValue}
              shoppingExpired={values.shoppingExpired}
              errors={errors.levelExpired}
              touched={touched.levelExpired}
              isCreate={isCreate}
              isDraft={isDraft}
            />
          )}
        </div>
        {values.level != 0 && (
          <React.Fragment>
            <div className={`${style.show_flex} mb-2`}>
              <div className="required w150">累積消費有效期限</div>
              <ExpiredOptions
                type="shoppingExpired"
                values={values.shoppingExpired}
                setFieldValue={setFieldValue}
                limit={values.levelExpired}
                errors={errors.shoppingExpired}
                touched={touched.shoppingExpired}
                isCreate={isCreate}
                isDraft={isDraft}
              />
            </div>
            <SmallHint content={shoppingExpiredHints} />
          </React.Fragment>
        )}
        {values.level != 0 && (
          <React.Fragment>
            <div className={`mb-2 ${style.show_flex}`}>
              <div className="required w200">升等門檻</div>
              <span style={{ marginRight: '0.2rem' }}>累積消費金額 NT$</span>
              <Input
                className={style['form-input']}
                value={values.levelUpExpense}
                onChange={event => {
                  setFieldValue('levelUpExpense', event.target.value);
                }}
                invalid={errors?.levelUpExpense && touched?.levelUpExpense}
                disabled={!isCreate && !isDraft}
              />
              {errors?.levelUpExpense && touched?.levelUpExpense && (
                <span className="text-danger ml-2">
                  {errors.levelUpExpense}
                </span>
              )}
            </div>
            <SmallHint content={levelUpExpenseHints} />
          </React.Fragment>
        )}
        {values.level != 0 && (
          <React.Fragment>
            <div className={`mb-2 ${style.show_flex}`}>
              <div className="required w200">續等門檻</div>
              <span style={{ marginRight: '0.2rem' }}>累積消費金額 NT$</span>
              <Input
                className={style['form-input']}
                value={values.levelKeepExpense}
                onChange={event => {
                  setFieldValue('levelKeepExpense', event.target.value);
                }}
                invalid={errors?.levelKeepExpense && touched?.levelKeepExpense}
                disabled={!isCreate && !isDraft}
              />
              {errors?.levelKeepExpense && touched?.levelKeepExpense && (
                <span className="text-danger ml-2">
                  {errors.levelKeepExpense}
                </span>
              )}
            </div>
            <SmallHint content={levelKeepExpenseHints} />
          </React.Fragment>
        )}
        {/* {
          isCreate || isDraft
          ?
          <React.Fragment>
            <div className={`mb-2 ${style.show_flex}`} style={{ marginBottom: '2rem' }}>
              <div className='required w200'>啟用日期</div>
              <div className={style["from-input"]}>
                <DatePicker
                  className={`form-control ${errors?.enabledDate && touched?.enabledDate ? style["invalid-date-border"] : ''}`}
                  dateFormat={"yyyy/MM/dd HH:mm"}
                  timeFormat={"HH:mm"}
                  showTimeSelect
                  selected={values.enabledDate ? moment(values.enabledDate).toDate() : ""}
                  onChange={date => {
                    setFieldValue("enabledDate", date);
                  }}
                  minDate={moment().add(24, 'hours').toDate()}
                  // filterTime={filterPassedTime}
                />
              </div>
            </div>
            {
              errors?.enabledDate &&
              touched?.enabledDate &&
              <span className='text-danger ml-2'>{errors.enabledDate}</span>
            }
            <SmallHint content={enabledDateHints}/>
          </React.Fragment>
          : <></>
        } */}
        <hr />
        <div className="required">會員中心顯示的文字(中)</div>
        {errors?.remark && touched?.remark && (
          <span className="text-danger">{errors.remark}</span>
        )}
        <Input
          type="textarea"
          style={{ minHeight: '100px' }}
          value={values.remark}
          onChange={event => {
            setFieldValue('remark', event.target.value);
          }}
          invalid={errors?.remark && touched?.remark}
        />

        <div className="required">會員中心顯示的文字(英)</div>
        {errors?.remarkEn && touched?.remarkEn && (
          <span className="text-danger">{errors.remarkEn}</span>
        )}
        <Input
          type="textarea"
          style={{ minHeight: '100px' }}
          value={values.remarkEn}
          onChange={event => {
            setFieldValue('remarkEn', event.target.value);
          }}
          invalid={errors?.remarkEn && touched?.remarkEn}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={updateLoading || updateDraftLoading}
        >
          {updateLoading || updateDraftLoading ? (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <></>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const AlertBlock = ({ items }) => {
  return (
    <Alert color="secondary">
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div>
            <AiOutlineInfoCircle /> <span> {item}</span>
          </div>
        </React.Fragment>
      ))}
    </Alert>
  );
};

const TableList = ({
  items,
  onEdit,
  getInfo,
  setIsCreate,
  onChangePage,
  isDraft,
  onDelete,
}) => {
  return (
    <div className="table-responsive">
      <Table hover bordered striped className="mb-5">
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>會員等級</th>
            <th>
              <div>會員等級有效期限</div>
              <div>累積消費有限期限</div>
            </th>
            <th>升等門檻</th>
            <th>續等門檻</th>
            <th>異動日期</th>
            <th>功能</th>
          </tr>
        </thead>
        {!items?.datas && <TableLoading />}
        {items?.datas && (
          <tbody>
            {items?.datas?.length > 0 ? (
              items.datas.map((item, index) => (
                <tr key={index} className="text-center">
                  {/* 會員等級 */}
                  <td>
                    <div>{item.name}</div>
                    <div>等級{item.degree}</div>
                  </td>
                  {/* 會員等級有效期限 累積消費有效期限 */}
                  <td>
                    <div>
                      {item.memberlevelDays == 182
                        ? '半年(182天)'
                        : item.memberlevelDays == 365
                        ? '一年(365天)'
                        : item.memberlevelDays == 730
                        ? '兩年(730天)'
                        : item.memberlevelDays == 1095
                        ? '三年(1095天)'
                        : '永久效期'}
                    </div>
                    <div>
                      {item.accumulateDays == 182
                        ? '半年(182天)'
                        : item.accumulateDays == 365
                        ? '一年(365天)'
                        : item.accumulateDays == 730
                        ? '兩年(730天)'
                        : item.accumulateDays == 1095
                        ? '三年(1095天)'
                        : '永久效期'}
                    </div>
                  </td>
                  {/* 升等門檻 */}
                  <td>
                    {item.upgradeAmount == 0 ? (
                      '--'
                    ) : (
                      <div>
                        累積消費金額滿
                        <span className="price text-black fw-normal">
                          {item.upgradeAmount.toLocaleString('en-US')}
                        </span>
                      </div>
                    )}
                  </td>
                  {/* 續等門檻 */}
                  <td>
                    {item.renewAmount == 0 ? (
                      '--'
                    ) : (
                      <div>
                        效期內累積消費金額
                        <span className="price text-black fw-normal">
                          {item.renewAmount.toLocaleString('en-US')}
                        </span>
                      </div>
                    )}
                  </td>
                  {/* 異動日期 */}
                  <td>
                    <div>{moment(item.updateTime).format('YYYY/MM/DD')} </div>
                    <div>
                      {`${moment(item.updateTime).format('HH:mm:ss')} ${
                        item.updater
                      }`}
                    </div>
                  </td>
                  {/* 功能 */}
                  <td>
                    <div>
                      <Button
                        className="btn-sm mb-2"
                        color="primary"
                        outline
                        onClick={() => {
                          getInfo(item);
                          onEdit();
                          setIsCreate(false);
                        }}
                      >
                        編輯
                      </Button>
                    </div>
                    {isDraft && items?.datas?.length > 0 && index === 0 && (
                      <div>
                        <Button
                          className="btn-sm"
                          color="danger"
                          outline
                          onClick={() => {
                            onDelete(item.id);
                          }}
                        >
                          刪除
                        </Button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
      {items && items.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={items.current_page}
            defaultPageSize={10}
            total={items.total_count}
            onChange={onChangePage}
          />
        </div>
      )}
    </div>
  );
};

const MemberLevelSettings = () => {
  const dispatch = useDispatch();
  const { loading, error, memberLevelList } = useSelector(
    state => state.getMemberLevelList,
  );
  const { success: deleteSuccess } = useSelector(
    state => state.deleteMemberListDraft,
  );
  const { publishedDate } = useSelector(state => state.getPublishDate);
  const { values } = useFormik({
    initialValues: {
      page: 1,
      page_size: 10,
    },
  });

  // 會員等級 modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };
  const [currentInfo, setCurrentInfo] = useState(null);
  const [isCreateEmptyDraft, setIsCreateEmptyDraft] = useState(false);

  const [draftPublishedDate, setDraftPublishedDate] = useState('');

  const [
    isEditDraftPublishedDateModalOpen,
    setIsEditDraftPublishedDateModalOpen,
  ] = useState(false);
  const toggleEditDraftPublishedDateModal = () => {
    setIsEditDraftPublishedDateModalOpen(!isEditDraftPublishedDateModalOpen);
  };

  const alertContent = [
    '會員等級，最高至10級',
    '當日消費、累積消費 — 都以逐級升等方式，不可跨級',
    '累積金額以主單為單位計算(不含運費)，以會期為單位計算金額，升等之後，累積金額歸零',
    '會員退貨將扣除效期內累積消費，以符合會員實際消費後的等級(可能會降級)，退貨為單筆訂單金額計算',
  ];

  useEffect(() => {
    const body = {
      page: 1,
      page_size: 10,
      status: 1, // 目前設定
    };
    dispatch(getMemberLevelList(body));
    dispatch(getPublishDate());
  }, []);

  useEffect(() => {
    if (memberLevelList) {
      if (JSON.stringify(memberLevelList) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: memberLevelList });
      }
    }
  }, [memberLevelList]);

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(
        getMemberLevelList({
          page: 1,
          page_size: 10,
          status: 3,
        }),
      );
      dispatch(getPublishDate());
      dispatch({ type: DELETE_MEMBER_LEVEL_DRAFT_IDLE });
    }
  }, [deleteSuccess]);

  useEffect(() => {
    setDraftPublishedDate(
      publishedDate?.length > 0 ? publishedDate[0].content : '',
    );
  }, [publishedDate]);

  const changePage = page => {
    const body = {
      page,
      page_size: 10,
      status: 1, // 目前設定
    };
    dispatch(getMemberLevelList(body));
  };

  const [currentTab, setCurrentTab] = useState(1);

  const { success: updateDraftDateSuccess, error: updateDraftDateError } =
    useSelector(state => state.updatePublishDate);
  const { success: deleteDraftSuccess, error: deleteDraftError } = useSelector(
    state => state.deleteOneDraft,
  );

  useEffect(() => {
    if (updateDraftDateSuccess) {
      MessageUtil.toastSuccess('儲存成功');
      dispatch({ type: UPDATE_PUBLISH_DATE_IDLE });
      dispatch(getPublishDate());
      toggleEditDraftPublishedDateModal();
    }

    if (updateDraftDateError) {
      MessageUtil.alertWanring('儲存失敗', updateDraftDateError);
      dispatch({ type: UPDATE_PUBLISH_DATE_IDLE });
    }
  }, [updateDraftDateSuccess, updateDraftDateError]);

  useEffect(() => {
    if (deleteDraftSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      dispatch({ type: DELETE_ONE_DRAFT_IDLE });
      dispatch(
        getMemberLevelList({
          page: 1,
          page_size: 10,
          status: 3,
        }),
      );
    }

    if (deleteDraftError) {
      MessageUtil.alertWanring('刪除失敗', deleteDraftError);
      dispatch({ type: DELETE_ONE_DRAFT_IDLE });
    }
  }, [deleteDraftSuccess, deleteDraftError]);

  const deleteDraftHandler = id => {
    dispatch(deleteOneDraft(id));
  };

  const { success: copySuccess } = useSelector(
    state => state.copyMemberLevelList,
  );

  useEffect(() => {
    if (copySuccess) {
      dispatch(
        getMemberLevelList({
          page: 1,
          page_size: 10,
          status: 3,
        }),
      );
      dispatch({ type: COPY_MEMBER_LEVEL_LIST_IDLE });
    }
  }, [copySuccess]);

  return (
    <Layout
      pageTitle="會員等級設定"
      items={[
        { isActive: true, page_name: '會員管理' },
        { isActive: false, page_name: '會員等級設定' },
      ]}
    >
      <AlertBlock items={alertContent} />
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          currentTab={currentTab}
          tab={1}
          text="目前設定"
        />
        <TabItem
          onClick={setCurrentTab}
          setIsDraft={() => {
            setIsDraft(true);
          }}
          // setIsDraft={() => {
          //   setIsDraft(true);
          // }}
          currentTab={currentTab}
          tab={2}
          text="草稿"
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            <TableList
              items={memberLevelList}
              onEdit={toggleEditModal}
              getInfo={setCurrentInfo}
              setIsCreate={() => {
                setIsCreate(false);
                setIsDraft(false);
              }}
              onChangePage={changePage}
              onDelete={deleteDraftHandler}
            />
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          {memberLevelList?.datas?.length > 0 || isCreateEmptyDraft ? (
            <Card className={style.card}>
              {/* 新增會員層級 */}
              <div className={style.formGroup}>
                <Button
                  className="w100"
                  color="success"
                  onClick={() => {
                    setCurrentInfo(null);
                    toggleEditModal();
                    setIsCreate(true);
                    setIsDraft(true);
                  }}
                  style={{ marginRight: '1rem' }}
                >
                  新增
                </Button>
                <Button
                  className="w100"
                  color="danger"
                  onClick={() => {
                    // setScriptType('');
                    dispatch(deleteMemberLevelDraft());
                    setIsDraft(false);
                    setIsCreateEmptyDraft(false);
                  }}
                >
                  刪除草稿
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: '#ffe7e7',
                  marginBottom: '1rem',
                  padding: '1.5rem',
                }}
              >
                <div className={style.formGroup}>
                  <label style={{ fontSize: '20px' }}>草稿生效日期</label>
                  {/* <DatePicker
                        className="form-control"
                        dateFormat={"yyyy/MM/dd"}
                        selected={draftPublishedDate ? moment(draftPublishedDate).toDate() : ''}
                        minDate={moment().add(1, 'day').toDate()}
                        onChange={date => {
                          // setDraftPublishedDate(date);
                          dispatch(updatePublishDate({
                            type: '草稿生效日',
                            content: date ? moment(date).format("YYYY/MM/DD") : null,
                            content_en: date ? moment(date).format("YYYY/MM/DD") : null,
                          }));
                        }}
                      /> */}
                  <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {draftPublishedDate
                      ? moment(draftPublishedDate).format('yyyy/MM/DD')
                      : '--'}
                  </label>
                  <Button
                    color="success"
                    className="w100 ml-2"
                    onClick={toggleEditDraftPublishedDateModal}
                  >
                    設定生效日
                  </Button>
                </div>
                <div className={style.formGroup}>
                  <span>
                    日期生效後，草稿的"設定"，會覆蓋"目前設定"，會員的等級將會重新計算，且無法再次復原資料
                  </span>
                </div>
              </div>
              <TableList
                items={memberLevelList}
                onEdit={toggleEditModal}
                getInfo={setCurrentInfo}
                setIsCreate={() => {
                  setIsCreate(false);
                  setIsDraft(true);
                }}
                onChangePage={changePage}
                isDraft={isDraft}
                onDelete={deleteDraftHandler}
              />
            </Card>
          ) : (
            <ChoosingPage
              onSetIsDraft={() => {
                setIsDraft(true);
              }}
              onSetIsCreateEmptyDraft={() => {
                setIsCreateEmptyDraft(true);
              }}
            />
          )}
        </TabPane>
      </TabContent>
      {isEditModalOpen && (
        <EditMemberLevelModal
          isOpen={isEditModalOpen}
          toggle={toggleEditModal}
          info={currentInfo}
          onRefresh={() => {
            dispatch(
              getMemberLevelList({
                page: 1,
                page_size: 10,
                status: isDraft ? 3 : 1,
              }),
            );
          }}
          isCreate={isCreate}
          nextDegree={memberLevelList?.datas?.length}
          isDraft={isDraft}
        />
      )}
      {isEditDraftPublishedDateModalOpen && (
        <EditDraftPublishedDateModal
          isOpen={isEditDraftPublishedDateModalOpen}
          toggle={toggleEditDraftPublishedDateModal}
          defaultDate={draftPublishedDate}
        />
      )}
    </Layout>
  );
};

export default MemberLevelSettings;
