import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  SET_SETTINGS_FAIL,
  SET_SETTINGS_REQUEST,
  SET_SETTINGS_SUCCESS,
} from 'redux/constants/data/yourFavoriteConstant';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getSettings = () => {
  const url = '/Backend/YouLikeDiscount/youlike';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_SETTINGS_FAIL,
      method: 'get',
      requestConstant: GET_SETTINGS_REQUEST,
      successConstant: GET_SETTINGS_SUCCESS,
      url,
    });
  };
};

export const setSettings = data => {
  const url = '/Backend/YouLikeDiscount/save';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SET_SETTINGS_FAIL,
      method: 'post',
      requestConstant: SET_SETTINGS_REQUEST,
      successConstant: SET_SETTINGS_SUCCESS,
      url,
    });
  };
};
