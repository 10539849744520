import { useFormik } from 'formik';
import moment from 'moment';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { addBindingProduct } from 'redux/actions/data/giftsActions';
import useQuery from 'utils/useQuery';

import style from '../../styles/layout.module.css';
import { TableHasNoData, TableLoading } from '../TableHasNoData';
const ProductTypeMap = ['一般商品', '隱形賣場' , '組合商品']

const SelectedList = ({ isAdd = false, productType = '一般商品' }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const id = query.value.id;
  const { loading: getLoading, productList } = useSelector(
    state => state.getBindingProductList,
  );
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: [],
    },
    onSubmit: () => {
      const body = {
        freebieId: id,
        isforAdding: false,
        productType,
        updateIds: values.selectedProducts.map(prd => ({
          mId: prd.mid,
          productType:ProductTypeMap.indexOf(productType) + 1,
          productid: prd.productid,
        })),
      };
      dispatch(addBindingProduct(body));
      setFieldValue('selectedProducts', []);
    },
  });

  useEffect(() => {
    !isAdd && setFieldValue('selectedProducts', []);
  }, [isAdd]);

  const allChecked = useMemo(() => {
    let allPrds = [];
    productList?.datas?.forEach(data => {
      data.products.forEach(prd => {
        allPrds.push(prd);
      });
    });
    return allPrds.length === values.selectedProducts.length;
  }, [values.selectedProducts, productList.datas]);

  return (
    <>
      <Button
        color="success"
        className="w100 mb-3"
        onClick={handleSubmit}
        disabled={values.selectedProducts.length === 0}
      >
        移除商品
      </Button>
      <div className="table-responsive mb-5">
        <Table hover striped bordered>
          <thead
            className={`${style.table_head} thead-dark`}
            style={{ marginBottom: 0 }}
          >
            <tr>
              <th colSpan={9}>
                <div className={style.formGroup}>
                  <input
                    type="checkbox"
                    className={style['radio-btn']}
                    onChange={event => {
                      const checked = event.target.checked;
                      setFieldValue('selectedProducts', []);
                      let allProducts = [];
                      productList.datas.forEach(data => {
                        data.products.forEach(prd => {
                          allProducts.push(prd);
                        });
                      });
                      checked && setFieldValue('selectedProducts', allProducts);
                    }}
                    checked={allChecked && productList?.datas?.length > 0}
                  />
                  <span className="ml-2">全選</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>選擇</th>
              <th>商品名稱</th>
              <th>分類</th>
              <th>商品頁銷售時間</th>
              <th>選擇料號</th>
              <th>商品料號</th>
              <th>規格</th>
              <th>
                <div>加入此贈品日期</div>
                <div>加入人員</div>
              </th>
              <th>商品是否上架</th>
            </tr>
          </thead>
          {getLoading && <TableLoading />}
          {!getLoading && (
            <tbody>
              {productList?.datas?.length > 0 ? (
                productList.datas.map((data, index) =>
                  data.products.map((product, pIndex) => (
                    <tr key={index + pIndex}>
                      {/* 選擇 model */}
                      {pIndex === 0 && (
                        <td
                          className="text-center"
                          rowSpan={data.products.length}
                        >
                          <input
                            type="checkbox"
                            className={style['radio-btn']}
                            checked={
                              data.products.length ===
                              values.selectedProducts.filter(
                                prd => prd.mid === data.mid,
                              ).length
                            }
                            onChange={event => {
                              const checked = event.target.checked;
                              checked
                                ? setFieldValue('selectedProducts', [
                                    ...values.selectedProducts,
                                    ...data.products.filter(
                                      prd =>
                                        !values.selectedProducts.find(
                                          sp =>
                                            sp.productcode === prd.productcode,
                                        ),
                                    ),
                                  ])
                                : setFieldValue(
                                    'selectedProducts',
                                    values.selectedProducts.filter(
                                      prd => prd.mid !== data.mid,
                                    ),
                                  );
                            }}
                          />
                        </td>
                      )}
                      {

                        /* 商品名稱 */
                        pIndex === 0 && (
                          <td
                            className="text-center"
                            rowSpan={data.products.length}
                          >
                            {data.modelName}
                            <div> {productType === '隱形賣場' ? `(${data.modelNumber})` : ''}</div>
                          </td>
                        )
                      }
                      {

                        /* 商品分類 */
                        pIndex === 0 && (
                          <td
                            className="text-center"
                            rowSpan={data.products.length}
                          >
                            {data.globalCategoies.map(category => (
                              <div className="text-nowrap">{`${
                                category.name
                              } / ${category.cat2Name || '全部'}`}</div>
                            ))}
                          </td>
                        )
                      }
                      {

                        /* 銷售時間 */
                        pIndex === 0 && (
                          <td
                            className="text-center"
                            rowSpan={data.products.length}
                          >
                            {/* 銷售時間 */}
                            <div className="text-nowrap">
                              {data.availableTimeStart &&
                                moment(data.availableTimeStart).format(
                                  'YYYY/MM/DD HH:mm',
                                )}
                            </div>
                            <div className="text-nowrap">
                              {data.availableTimeEnd &&
                                moment(data.availableTimeEnd).format(
                                  'YYYY/MM/DD HH:mm',
                                )}
                            </div>
                          </td>
                        )
                      }
                      <td className="text-center">
                        {/* 選擇料號 */}
                        <input
                          type={'checkbox'}
                          className={style['radio-btn']}
                          checked={values.selectedProducts.find(
                            prd => prd.productcode === product.productcode,
                          )}
                          onChange={event => {
                            const checked = event.target.checked;
                            checked
                              ? setFieldValue('selectedProducts', [
                                  ...values.selectedProducts,
                                  product,
                                ])
                              : setFieldValue(
                                  'selectedProducts',
                                  values.selectedProducts.filter(
                                    prd =>
                                      prd.productcode !== product.productcode,
                                  ),
                                );
                          }}
                        />
                      </td>
                      <td className="text-center">
                        {/* 商品料號 */}
                        {product.productcode}
                      </td>
                      <td className="text-center">
                        {/* 商品規格 */}
                        {`${product.color || '--'} / ${product.size || '--'}`}
                      </td>
                      <td className="text-center">
                        {/* 加入日期 & 人員 */}
                        <div className="text-nowrap">
                          {(product.addTime &&
                            moment(product.addTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )) ||
                            '--'}
                        </div>
                        <div>{product.addAdmin}</div>
                      </td>
                      <td className="text-center">
                        {/* 上架 */}
                        {product.isSell ? '是' : '否'}
                      </td>
                    </tr>
                  )),
                )
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
    </>
  );
};

export default SelectedList;
