import React, { useState } from 'react';
import { BsFillImageFill } from 'react-icons/bs';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import style from 'styles/components/productImgPicker.module.css';
import layoutStyle from 'styles/layout.module.css';

const ProductImgPicker = ({ onSelect, thumbnail, imgs = [] }) => {
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`${style.pip_preview}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        setIsOpen(true);
        setIsHover(false);
      }}
    >
      <BsFillImageFill />
      {thumbnail && (
        <img

          // src="https://www.ikea.com.tw/dairyfarm/tw/images/162/0916210_PH166416_S5.jpg"
          src={thumbnail}
          alt=""
        />
      )}
      {isHover && <div className={style.overlay}>請選擇圖片</div>}
      <div className={layoutStyle.modal}>
        <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
          <ModalHeader
            className={layoutStyle.modal_head}
            toggle={() => setIsOpen(!isOpen)}
          >
            選擇圖片
          </ModalHeader>
          <ModalBody className={layoutStyle.modal_body}>
            <Row>
              {imgs.map(i => (
                <Col xs={6} sm={4} key={i.id}>
                  <img
                    src={i.url}
                    alt=""
                    className={style.pick_img}
                    onClick={() => {
                      onSelect(i);
                      setIsOpen(false);
                    }}
                  />
                </Col>
              ))}
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default ProductImgPicker;
