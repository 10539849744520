import { useEffect, useMemo } from 'react';
import { useRef } from 'react';
import {
  checkBarcodeNumber,
  checkProductNumber,
  checkWarrantyNumber,
  getProductName,
} from 'redux/actions/data/warrantyActions';

import classes from '../styles/layout.module.css';

const ShipItemV2 = ({
  id,
  values,
  setFieldValue,
  setFieldError,
  errors,
  isBarcode,
  onCreate,
  isFocused,
  limit,
}) => {
  const warrantyInputRef = useRef(null);
  const productInputRef = useRef(null);
  const barcodeInputRef = useRef(null);
  const errorsObj = useMemo(() => {
    if (errors && errors.productList && errors.productList[id]) {
      return errors.productList[id];
    }
    return {};
  }, [id, errors]);

  useEffect(() => {
    isFocused && warrantyInputRef.current.focus();
    isFocused && setFieldValue(`productList[${id}].isFocused`, false, false);
  }, [isFocused]);

  const checkValueIsEmpty = data => {
    let result = false;
    if (data.value === '') {
      setFieldError(`productList[${data.id}].${data.type}`, '未填寫資料');
      setFieldValue(`productList[${data.id}].productName`, '', false);
      result = true;
    } else {
      setFieldError(`productList[${data.id}].${data.type}`, '');
      result = false;
    }
    return result;
  };
  const checkValueIsReapted = data => {
    let result = false;
    if (data.value !== '') {
      if (
        values.productList.find(
          (prd, index) => prd[data.type] === data.value && +data.id !== index,
        )
      ) {
        if (data.type !== 'barcode') {
          switch (data.type) {
            case 'warranty':
              setFieldError(`productList[${data.id}].warranty`, '保固序號重複');
              setFieldValue(`productList[${data.id}].productName`, '', false);
              break;
            case 'product':
              setFieldError(`productList[${data.id}].product`, '產品序號重複');
              setFieldValue(`productList[${data.id}].productName`, '', false);
              break;
            default:
              break;
          }
          result = true;
        }
      }
    }
    return result;
  };

  const checkWarrantyNumberHandler = async data => {
    const response = await checkWarrantyNumber({
      serialNumber: data.value,
      type: isBarcode ? '2' : '1',
    });
    if (response) {
      if (!errors.productList) {
        errors.productList = [];
      }
      errors.productList[id] = { ...errorsObj, warranty: response };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isWarrantyVaildate`, false, false);
    } else {
      errors.productList[id] = { ...errorsObj, warranty: '' };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isWarrantyVaildate`, true, false);
    }
  };

  const checkBarcodeNumberHandler = async data => {
    const response = await checkBarcodeNumber({
      serialNumber: data.value,
      type: isBarcode ? '2' : '1',
    });
    if (response) {
      if (!errors.productList) {
        errors.productList = [];
      }
      errors.productList[id] = { ...errorsObj, barcode: response };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isBarcodeVaildate`, false, false);
    } else {
      errors.productList[id] = { ...errorsObj, barcode: null };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isBarcodeVaildate`, true, false);
    }
  };

  const checkProductNumberHandler = async data => {
    const response = await checkProductNumber({
      serialNumber: data.value,
      type: isBarcode ? '2' : '1',
    });

    if (response) {
      if (!errors.productList) {
        errors.productList = [];
      }
      errors.productList[id] = { ...errorsObj, product: response };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isProductVaildate`, false, false);
    } else {
      errors.productList[id] = { ...errorsObj, product: null };
      setFieldError('productList', errors.productList);
      setFieldValue(`productList[${id}].isProductVaildate`, true, false);
    }
  };

  const getProductNameHandler = async () => {
    const info = {
      barcode: values.productList[id].barcode,
      codeType: isBarcode ? 2 : 1,
      id: 0,
      serialnumber: values.productList[id].product,
      warrantySerialNum: values.productList[id].warranty,
    };

    const { msgs } = await getProductName(info);

    if (msgs.length > 0) {
      setFieldValue(
        `productList[${id}].productName`,
        msgs[0].produuctName,
        false,
      );
      return true;
    }

    return false;
  };

  const inputKeyDownHandler = event => {
    const key = event.keyCode;

    // enter = 13
    if (key === 13) {
      if (document.activeElement === warrantyInputRef.current && isBarcode) {
        warrantyInputRef.current.blur();
        barcodeInputRef.current.focus();
      } else if (
        document.activeElement === warrantyInputRef.current &&
        !isBarcode
      ) {
        warrantyInputRef.current.blur();
        productInputRef.current.focus();
      } else if (document.activeElement === barcodeInputRef.current) {
        barcodeInputRef.current.blur();
        productInputRef.current.focus();
      } else if (document.activeElement === productInputRef.current) {
        productInputRef.current.blur();
        values.productList.length >= id + 2 &&
          setFieldValue(`productList[${id + 1}].isFocused`, true, false);

        if (
          values.productList.length - 1 === +id &&
          values.productList[id].productName
        ) {
          if (values.productList.length < limit) {
            onCreate();
            limit - 1 < id &&
              setFieldValue(`productList[${id + 1}].isFocused`, true, false);
          }
        }
      }
    } else if (key === 32) {
      event.preventDefault();
    }
  };
  const inputBlurHandler = data => {
    if (checkValueIsEmpty(data)) {
      return false;
    } else if (checkValueIsReapted(data)) {
      return false;
    } else {
      if (values.productList[id].warranty && values.productList[id].product) {
        if (isBarcode && values.productList[id].barcode) {
          getProductNameHandler();
        }
        if (!isBarcode) {
          getProductNameHandler();
        }
      }

      switch (data.type) {
        case 'warranty':
          !values.productList[id].isWarrantyVaildate &&
            checkWarrantyNumberHandler(data);
          break;
        case 'barcode':
          !values.productList[id].isBarcodeVaildate &&
            checkBarcodeNumberHandler(data);
          break;
        case 'product':
          !values.productList[id].isProductVaildate &&
            checkProductNumberHandler(data);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!values.productList[id].isWarrantyVaildate) {
      setFieldValue(`productList[${id}].productName`, '', false);
    }
  }, [values.productList[id].isWarrantyVaildate]);

  useEffect(() => {
    if (!values.productList[id].isBarcodeVaildate && isBarcode) {
      setFieldValue(`productList[${id}].productName`, '', false);
    }
  }, [values.productList[id].isBarcodeVaildate]);

  useEffect(() => {
    if (!values.productList[id].isProductVaildate) {
      setFieldValue(`productList[${id}].productName`, '', false);
    }
  }, [values.productList[id].isProductVaildate]);

  useEffect(() => {
    if (values.productList[id].productName !== '' && id < limit - 1) {
      onCreate();
    }
  }, [values.productList[id].productName]);

  const invalidBackgroundColor = {
    backgroundColor: '#ffe9e9',
  };

  return (
    <tr>
      <td>
        <div>{id + 1}</div>
      </td>
      <td style={errorsObj?.warranty ? invalidBackgroundColor : {}}>
        <input
          className="targetInput form-control"
          onChange={e => {
            setFieldValue(`productList[${id}].warranty`, e.target.value, false);
            setFieldValue(
              `productList[${id}].isWarrantyVaildate`,
              false,
              false,
            );
          }}
          onKeyDown={inputKeyDownHandler}
          onBlur={() => {
            inputBlurHandler({
              id,
              type: 'warranty',
              value: values.productList[id].warranty,
            });
          }}
          ref={warrantyInputRef}
          value={values.productList[id].warranty}
        />
        {errorsObj?.warranty && (
          <p className={classes.invalid}>{errorsObj?.warranty}</p>
        )}
      </td>
      {isBarcode && (
        <td style={errorsObj?.barcode ? invalidBackgroundColor : {}}>
          <input
            className="targetInput form-control"
            onChange={e => {
              setFieldValue(
                `productList[${id}].barcode`,
                e.target.value,
                false,
              );
              setFieldValue(
                `productList[${id}].isBarcodeVaildate`,
                false,
                false,
              );
            }}
            onKeyDown={inputKeyDownHandler}
            onBlur={() => {
              inputBlurHandler({
                id,
                type: 'barcode',
                value: values.productList[id].barcode,
              });
            }}
            ref={barcodeInputRef}
            value={values.productList[id].barcode}
          />
          {errorsObj?.barcode && (
            <p className={classes.invalid}>{errorsObj?.barcode}</p>
          )}
        </td>
      )}
      <td style={errorsObj?.product ? invalidBackgroundColor : {}}>
        <input
          className="targetInput form-control"
          onChange={e => {
            setFieldValue(`productList[${id}].product`, e.target.value, false);
            setFieldValue(`productList[${id}].isProductVaildate`, false, false);
          }}
          onKeyDown={inputKeyDownHandler}
          onBlur={() => {
            inputBlurHandler({
              id,
              type: 'product',
              value: values.productList[id].product,
            });
          }}
          ref={productInputRef}
          value={values.productList[id].product}
        />
        {errorsObj?.product && (
          <p className={classes.invalid}>{errorsObj?.product}</p>
        )}
      </td>
      <td>{values.productList[id].productName || '-'}</td>
      {errorsObj?.result && (
        <td className="text-nowrap text-danger">{errorsObj?.result}</td>
      )}
    </tr>
  );
};

export default ShipItemV2;
