import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Label, Row } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import {
  getStoreDetails,
  getStoreServiceOptions,
  updateStoreDetail,
} from '../redux/actions/data/storeActions';
import { SERVICE_STORE_UPDATE_RESET } from '../redux/constants/data/storeConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

export default function StoreDetail(props) {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [serviceId, setServiceId] = useState([]);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, detailInfo, error } = useSelector(
      state => state.storeDetail,
  );
  const { options } = useSelector(state => state.storeServiceOption);
  const { loading: postLoading, success: postSuccess } = useSelector(
      state => state.storeDetailUpdate,
  );

  useEffect(() => {
    dispatch(getStoreDetails(id));
    dispatch(getStoreServiceOptions());
  }, []);

  useEffect(() => {
    if (
        detailInfo &&
        detailInfo.result &&
        detailInfo.result.serviceItems.length > 0
    ) {
      const targetIds = detailInfo.result.serviceItems.map(item => {
        return item.serviceItemId;
      });

      setServiceId(targetIds);
    }
  }, [detailInfo?.result?.serviceItems]);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      history.push('/StoreList');
    }

    return () => {
      dispatch({ type: SERVICE_STORE_UPDATE_RESET });
    };
  }, [postSuccess]);

  const handleCheck = (id, checked) => {
    if (checked) {
      setServiceId([...serviceId, id]);
    } else {
      setServiceId(serviceId.filter(targetId => targetId != id));
    }
  };

  const handleSubmit = values => {
    console.log(values);

    let formData = new FormData();

    formData.append('id', id);
    formData.append('longitude', values.longitude);
    formData.append('latitude', values.latitude);
    formData.append('serviceItems', JSON.stringify(serviceId));
    formData.append('remark', values.remark);
    formData.append('status', values.status);

    dispatch(updateStoreDetail(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      latitude: Yup.string().required('必填!'),
      longitude: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
      <Layout
          pageTitle="據點編輯"
          items={[
            {
              isActive: false,
              page_name: '據點管理',
              to: '/StoreList',
            },
            { isActive: true, page_name: `${detailInfo?.result?.companyname}編輯` },
          ]}
      >
        {!loading && detailInfo && detailInfo.result && (
            <Card className={style.card}>
              <Formik
                  enableReinitialize
                  initialValues={{
                    latitude:
                        detailInfo && detailInfo.result
                            ? detailInfo.result.latitude ?? 0
                            : 0,
                    longitude:
                        detailInfo && detailInfo.result
                            ? detailInfo.result.longitude ?? 0
                            : 0,
                    remark:
                        detailInfo && detailInfo.result
                            ? detailInfo.result.remark ?? ''
                            : '',
                    serviceItems:
                        detailInfo && detailInfo.result
                            ? detailInfo.result.serviceItems ?? []
                            : [],
                    status:
                        detailInfo && detailInfo.result
                            ? detailInfo.result.status ?? ''
                            : '',
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={getValidationSchema}
              >
                {props => (
                    <Form className="form-list form-label-140">
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>據點名稱</label>
                        <div className="form-item">
                          {detailInfo.result.companyname}
                        </div>
                      </div>
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>據點名稱(英)</label>
                        <div className="form-item">
                          {detailInfo.result.englishname}
                        </div>
                      </div>

                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>據點類型</label>
                        <div className="d-flex form-item">
                          {detailInfo.result.companyname.split('-')[0] ===
                          'Urban Units'
                              ? 'UU門市'
                              : '經銷'}
                        </div>
                      </div>

                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>地址</label>
                        <div className="form-item">{detailInfo.result.address}</div>
                      </div>
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>地址(英)</label>
                        <div className="form-item">
                          {detailInfo.result.englishaddress}
                        </div>
                      </div>
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>電話</label>
                        <div className="form-item">{detailInfo.result.phone}</div>
                      </div>
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>品牌</label>
                        <div className="form-item">
                          {detailInfo.result.brands.map((brand, index) => (
                              <label
                                  key={index}
                                  className="m-1 py-1 px-2"
                                  style={{
                                    backgroundColor: '#eceef7',
                                    fontWeight: '500',
                                  }}
                              >
                                {brand.brand}
                              </label>
                          ))}
                        </div>
                      </div>
                      <div className={style.formGroup}>
                        <label className="required" style={{ width: '150px' }}>
                          經緯度
                          <ErrorMessage
                              name="longitude"
                              component="span"
                              className="form-error"
                          />
                          <ErrorMessage
                              name="latitude"
                              component="span"
                              className="form-error"
                          />
                        </label>
                        <div className="form-item">
                          <ul>
                            <li className="form-error">
                              <small className="form-error">
                                若從Google Map取得經緯度，請依Google
                                Map顯示有左到右依序輸入即可。
                              </small>
                            </li>
                          </ul>
                          <Row>
                            <div className="d-flex align-items-center col-6">
                              <label className="mr-0">緯度</label>
                              <Field
                                  name="latitude"
                                  type="text"
                                  className="form-control"
                              />
                            </div>
                            <div className="d-flex align-items-center col-6">
                              <label className="mr-0">經度</label>
                              <Field
                                  name="longitude"
                                  type="text"
                                  className="form-control"
                              />
                            </div>
                          </Row>
                        </div>
                      </div>
                      <div className={style.formGroup}>
                        <label style={{ width: '150px' }}>
                          營業時間
                        </label>
                        <div className="form-item">
                          <Field
                              name="remark"
                              as="textarea"
                              className="form-control"
                          />
                        </div>
                      </div>
                      <div className={style.formGroup}>
                        <label className="required" style={{ width: '150px' }}>
                          服務項目
                          <ErrorMessage
                              name="serviceItems"
                              component="span"
                              className="form-error"
                          />
                        </label>
                        <div className="form-item d-flex">
                          {options &&
                              options.length > 0 &&
                              options.map(option => {
                                return (
                                    <div key={option.value}>
                                      <input
                                          type="checkbox"
                                          id={option.value}
                                          value={option.value || ''}
                                          onChange={e =>
                                              handleCheck(option.value, e.target.checked)
                                          }
                                          checked={
                                            !!serviceId.find(id => id == option.value)
                                          }
                                      />
                                      <Label
                                          check
                                          htmlFor={option.value}
                                          style={{ marginRight: '1rem' }}
                                      >
                                        {option.label}
                                      </Label>
                                    </div>
                                );
                              })}
                        </div>
                      </div>

                      <div className={style.formGroup}>
                        <label className="required" style={{ width: '150px' }}>
                          狀態
                          <ErrorMessage
                              name="status"
                              component="span"
                              className="form-error"
                          />
                        </label>
                        <div className="d-flex form-item">
                          <Field
                              className="my-auto me-2"
                              id="up"
                              name="status"
                              type="radio"
                              value="上架"
                          />
                          <label htmlFor="up" className="me-5">
                            上架
                          </label>
                          <Field
                              className="my-auto me-2"
                              id="down"
                              name="status"
                              type="radio"
                              value="下架"
                          />
                          <label htmlFor="down">下架</label>
                        </div>
                      </div>

                      <hr />
                      <div className="d-flex justify-content-center">
                        <button
                            type="button"
                            className="layout_modal_button_cancel__cgP-b btn btn-light"
                            onClick={() => history.goBack()}
                        >
                          {' '}
                          取消
                        </button>
                        <Button
                            type="submit"
                            className="layout_modal_button_submit__qiiyD btn btn-secondary"
                            disabled={postLoading}
                        >
                          {postLoading && (
                              <span
                                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                              ></span>
                          )}
                          <span className="btn-wrapper--label">儲存</span>
                        </Button>
                      </div>
                    </Form>
                )}
              </Formik>
            </Card>
        )}
      </Layout>
  );
}
