import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';

import Layout from '../../components/Layout';
import { TableHasNoData, TableLoading } from '../../components/TableHasNoData';
import TableHead from '../../components/TableHead';
import {
  deleteSpecialPointEventSetting,
  getSpecialPointEventSettings,
} from '../../redux/actions/data/pointSettingsActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import { usePointOptions } from '../../utils/usePointOptions';
import useQuery from '../../utils/useQuery';
import { useStatus } from '../../utils/useStatus';

const SpecialEventPointSystem = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const query = useQuery();
  const { options: PointOptions } = usePointOptions();
  const { options: StatusOptions } = useStatus();
  const { list, loading } = useSelector(
    state => state.specialPointEventSetting,
  );
  const { values, handleReset, handleSubmit, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endTime: null,
        keyword: '',
        page: 1,
        page_size: 10,
        startTime: null,
        statusList: [],
        timeType: '',
        type: 0,
      },
      onReset: () => {
        handleSubmit();
      },
      onSubmit: values => {
        const body = {
          ...values,
          endTime: values?.endTime
            ? moment(values?.endTime).format('YYYY/MM/DD')
            : null,
          startTime: values?.startTime
            ? moment(values?.startTime).format('YYYY/MM/DD')
            : null,
          statusList: JSON.stringify(values?.statusList),
        };
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime || null),
          startTime: JSON.stringify(body.startTime || null),
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      },
    });

  const handlePageChange = (page = 1) => {
    setFieldValue('page', page);
    handleSubmit();
  };
  useEffect(() => {
    if (location.search) {
      const body = {
        ...query.value,
        endTime: query.value.endTime
          ? JSON.parse(query.value.endTime) === null
            ? ''
            : moment(query.value.endTime).toDate()
          : '',
        startTime: query.value.startTime
          ? JSON.parse(query.value.startTime) === null
            ? ''
            : moment(query.value.startTime).toDate()
          : '',
        statusList: query.value.statusList
          ? JSON.parse(query.value.statusList)
          : [],
        type: ~~query.value.type,
      };
      setValues({ ...body });
      dispatch(getSpecialPointEventSettings(body));
    } else {
      handleReset();
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle={'特殊活動給點設定'}
      items={[
        { isActive: true, page_name: '紅利點數' },
        { isActive: false, page_name: '特殊活動給點設定' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader className="bg-white">搜尋條件</CardHeader>
        <CardBody className={style.card_body}>
          <Row className="flex-wrap">
            <FormGroup
              className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3 gap-2"
              style={{ minWidth: '300px' }}
            >
              <label className="col-form-label text-nowrap mr-2">關鍵字</label>
              <Input
                type="text"
                className={`form-control ${style['form-keyword']}`}
                name="keyword"
                placeholder="活動序號、活動名稱"
                onChange={e => {
                  setFieldValue('keyword', e.target.value);
                }}
                value={values.keyword}
              />
            </FormGroup>

            <FormGroup
              className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3 gap-2"
              style={{ minWidth: '300px' }}
            >
              <label className="col-form-label mr-2">特殊活動給點設定</label>
              <Select
                style={{ margin: 0 }}
                className="flex-grow-1"
                value={
                  PointOptions.filter(
                    o => String(o.value) === String(values.type || 0),
                  )[0]
                }
                name="type"
                onChange={option => {
                  setFieldValue('type', option.value);
                }}
                options={PointOptions}
              />
            </FormGroup>

            <FormGroup
              className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3 gap-2"
              style={{ minWidth: '600px' }}
            >
              <label className="col-form-label mr-2 text-nowrap">
                日期區間
              </label>
              <div className="d-flex align-items-center">
                <DatePicker
                  className="form-control"
                  popperClassName={style['form-control-date-picker']}
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.startTime
                      ? moment(values.startTime).toDate()
                      : null
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  popperClassName={style['form-control-date-picker']}
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.endTime
                      ? moment(values.endTime).toDate()
                      : null
                  }
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  minDate={values.startTime}
                />
              </div>
            </FormGroup>

            <FormGroup
              className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3 gap-2"
              style={{ minWidth: '400px' }}
            >
              <label className="col-form-label mr-2 text-nowrap">
                活動狀態
              </label>
              <div>
                {StatusOptions.map(status => {
                  return (
                    <div
                      className="d-inline-flex align-items-center mx-2"
                      key={JSON.stringify(status)}
                    >
                      <input
                        type="checkbox"
                        className={style['radio-btn']}
                        checked={values?.statusList?.some(
                          ele => String(ele) == String(status.value),
                        )}
                        onChange={() => {
                          const checked = values?.statusList?.some(
                            ele => String(ele) === String(status.value),
                          );
                          checked
                            ? setFieldValue('statusList', [
                                ...values.statusList.filter(
                                  ele => String(ele) != String(status.value),
                                ),
                              ])
                            : setFieldValue('statusList', [
                                ...values.statusList,
                                status.value,
                              ]);
                        }}
                      />
                      <span>{status.label}</span>
                    </div>
                  );
                })}
              </div>
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              handleReset();
            }}
          >
            取消
          </Button>
          <Button
            type="button"
            className={style.modal_button_submit}
            onClick={() => {
              handlePageChange(1);
            }}
          >
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <Row className="mb-2">
          <div className="col-md-6 d-flex justify-content-start  align-items-center">
            <Button
              color="success"
              onClick={() => history.push('/SpecialEventPointSystemAdd')}
            >
              新增給點活動
            </Button>
          </div>
          <div className="d-flex justify-content-end  align-items-center col-md-6">
            每頁
            <Input
              type="select"
              name="page_size"
              style={{ margin: '0.3rem', width: '80px' }}
              onChange={event => {
                const value = event.target.value;
                setFieldValue('page_size', value);
                handlePageChange(1);
              }}
              value={values.page_size}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </Input>
            {`筆，共${list?.total_count ?? 0}筆`}
          </div>
        </Row>
        <div className="table-responsive mb-5">
          <Table hover striped bordered>
            <TableHead>
              <tr>
                <th>
                  <div>活動序號</div>
                </th>
                <th>
                  <div>活動名稱</div>
                </th>
                <th>
                  <div>活動時間</div>
                </th>
                <th>
                  <div>活動類型</div>
                </th>
                <th>
                  <div>活動對象</div>
                </th>
                <th>
                  <div>給點通路</div>
                </th>
                <th>
                  <div>更新日期</div>
                </th>
                <th>
                  <div>活動狀態</div>
                </th>
                <th>功能</th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {list?.datas?.length > 0 ? (
                  list.datas?.map(data => {
                    return (
                      <tr key={JSON.stringify(data)}>
                        <td>
                          {/* 活動序號 */}
                          <div>{data?.id || '--'}</div>
                        </td>
                        <td>
                          {/* 活動名稱 */}
                          <div>{data?.name || '--'}</div>
                        </td>
                        <td>
                          {/* 活動時間 */}
                          <div className="text-nowrap">
                            {data?.startTime
                              ? moment(data?.startTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                          </div>

                          <div className="text-nowrap">
                            {data?.endTime
                              ? moment(data?.endTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                          </div>
                        </td>
                        <td>
                          {/* 活動類型 */}
                          <div>{data?.typeLabel || '--'}</div>
                        </td>
                        <td>
                          {/* 活動對象 */}
                          <div>{data?.eventObjectLabel || '--'}</div>
                        </td>
                        <td>
                          {/* 給點通路 */}
                          <div>{data?.availableWay === '[]' && '--'}</div>
                          <div>
                            {String(data?.availableWay).includes('web') &&
                              '網頁版'}
                          </div>
                          <div>
                            {String(data?.availableWay).includes('store') &&
                              '門市'}
                          </div>
                        </td>
                        <td>
                          <div>{data?.updateTime || '--'}</div>
                        </td>
                        <td>
                          {/* 狀態 */}
                          <div>{data?.statusLabel || '--'}</div>
                        </td>
                        <td style={{ minWidth: '200px' }} nowrap="nowrap" align="center">
                          {data?.status !== 0 && (
                              <Button
                                  className="m-2"
                                  size="sm"
                                  color="primary"
                                  outline
                                  onClick={() =>
                                      history.push(
                                        `/SpecialEventPointSystemDetail/view/${data.id}?type=${data.type}`,
                                      )
                                  }
                              >
                                查看
                              </Button>
                          )}
                          {data.status === 0 && (
                            <Button
                              className="m-2"
                              size="sm"
                              color="primary"
                              outline
                              onClick={() =>
                                history.push(
                                  `/SpecialEventPointSystemDetail/update/${data.id}?type=${data.type}`,
                                )
                              }
                            >
                              編輯
                            </Button>
                          )}
                          <Button
                            className="m-2"
                            size="sm"
                            color="primary"
                            outline
                            onClick={() =>
                              history.push(
                                `/SpecialEventPointSystemDetail/copy/${data.id}?type=${data.type}`,
                              )
                            }
                          >
                            複製
                          </Button>
                          {data.status === 0 && (
                            <Button
                              className="m-2"
                              size="sm"
                              color="danger"
                              outline
                              onClick={() => {
                                MessageUtil.confirm(
                                  '',
                                  `確定刪除${data.name}?`,
                                  () => {
                                    deleteSpecialPointEventSetting(data.id)
                                      .then(() => {
                                        MessageUtil.alertSuccess('刪除成功');
                                        handlePageChange(1);
                                      })
                                      .catch(e => {
                                        MessageUtil.alertWanring('刪除失敗', e);
                                      });
                                  },
                                );
                              }}
                            >
                              刪除
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={list?.current_page || 1}
            pageSize={list?.page_size || 10}
            style={{ borderColor: '#446' }}
            total={list?.total_count || 0}
            onChange={handlePageChange}
          />
        </div>
      </Card>
    </Layout>
  );
};

export default SpecialEventPointSystem;
