import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateEmployeeId } from 'redux/actions/data/repairActions';
import { UPDATE_EMPLOYEE_ID_IDLE } from 'redux/constants/data/repairConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import style from '../styles/layout.module.css';

const EditStaffCodeModal = ({ isOpen, toggle, onRefresh, info }) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    state => state.updateEmployeeId,
  );
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      employeeid: info?.employeeid,
      id: info?.id,
    },
    onSubmit: () => {
      CommonUtil.consoleLog({ anno: 'request body', data: values });
      dispatch(updateEmployeeId(info.type, values));
    },
  });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      onRefresh && onRefresh();
      toggle();
      dispatch({ type: UPDATE_EMPLOYEE_ID_IDLE });
    }
    if (error) {
      MessageUtil.alertWanring('更新失敗', error);
      dispatch({ type: UPDATE_EMPLOYEE_ID_IDLE });
    }
  }, [success, error]);
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        修改服務人員代碼
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.show_flex} style={{ marginBottom: '5rem' }}>
          <label className="w150">服務人員代碼</label>
          <Input
            className={style['form-input']}
            value={values.employeeid}
            onChange={event => {
              setFieldValue('employeeid', event.target.value);
            }}
          />
        </div>
        <div>
          <label>服務人員更換歷程：</label>
          {info.records.map((record, index) => (
            <div key={index} style={{ margin: '1rem' }}>
              <div>
                <small>
                  {record.createtime} {record.admin}
                </small>
              </div>
              <label className="fw-bold">{record.employeeid}</label>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className={`me-3 ${style.modal_button_cancel}`}
          onClick={toggle}
        >
          取消
        </Button>

        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditStaffCodeModal;
