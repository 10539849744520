import Loader from 'components/Loader';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { addBindingProduct } from 'redux/actions/data/giftsActions';
import CommonUtil from 'utils/CommonUtil';
import useQuery from 'utils/useQuery';

import style from '../../styles/layout.module.css';
import TableHasNoData from '../TableHasNoData';

const ProductTypeMap = ['一般商品', '隱形賣場' , '組合商品']

const UnselectedList = ({ isAdd = false, productType = '一般商品' }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const id = query.value.id;
  const { loading: getLoading, productList } = useSelector(
    state => state.getBindingProductList,
  );
  const { loading: addLoading } = useSelector(state => state.addBindingProduct);
  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: [],
    },
    onSubmit: () => {
      const body = {
        freebieId: id,
        isforAdding: true,
        productType,
        updateIds: values.selectedProducts.map(prd => ({
          mId: prd.mid,
          productType:ProductTypeMap.indexOf(productType) + 1,
          productid: prd.productid,
        })),
      };
      dispatch(addBindingProduct(body));
      setFieldValue('selectedProducts', []);
    },
  });

  useEffect(() => {
    isAdd && setFieldValue('selectedProducts', []);
  }, [isAdd]);

  useEffect(() => {
    if (JSON.stringify(productList) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: productList });
    }
  }, [productList]);

  const allChecked = useMemo(() => {
    let allPrds = [];
    productList?.datas?.forEach(data => {
      data.products.forEach(prd => {
        allPrds.push(prd);
      });
    });
    return allPrds.length === values.selectedProducts.length;
  }, [values.selectedProducts, productList.datas]);

  return (
    <>
      <Button
        color="success"
        className="w100 mb-3"
        onClick={handleSubmit}
        disabled={values.selectedProducts.length === 0 || addLoading}
      >
        {addLoading && (
          <span
            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        加入商品
      </Button>
      {!getLoading && (
        <div className="table-responsive">
          <Table hover striped bordered>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th colSpan={8}>
                  <div
                    className={`${style.formGroup}`}
                    style={{ marginBottom: 0 }}
                  >
                    <input
                      type="checkbox"
                      className={style['radio-btn']}
                      onChange={event => {
                        const checked = event.target.checked;
                        setFieldValue('selectedProducts', []);
                        let allProducts = [];
                        productList.datas.forEach(data => {
                          data.products.forEach(prd => {
                            allProducts.push(prd);
                          });
                        });
                        checked &&
                          setFieldValue('selectedProducts', allProducts);
                      }}
                      checked={allChecked && productList?.datas?.length > 0}
                      disabled={productList?.datas?.length === 0}
                    />
                    <span className="ml-2">全選</span>
                  </div>
                </th>
              </tr>
              <tr>
                <th>選擇</th>
                <th>商品名稱</th>
                <th>分類</th>
                <th>商品頁銷售時間</th>
                <th>選擇料號</th>
                <th>商品料號</th>
                <th>規格</th>
                <th>商品是否上架</th>
              </tr>
            </thead>
            <tbody>
              {productList?.datas?.length > 0 ? (
                productList.datas.map((data, index) =>
                  data.products.map((product, pIndex) => (
                    <tr key={index + pIndex}>
                      {pIndex === 0 && (
                        <td
                          className="text-center"
                          rowSpan={data.products.length}
                        >
                          {/* model 選擇 */}
                          <input
                            type="checkbox"
                            className={style['radio-btn']}
                            checked={
                              values.selectedProducts.filter(
                                prd => prd.mid === data.mid,
                              )?.length === data.products.length
                            }
                            onChange={event => {
                              const checked = event.target.checked;
                              checked
                                ? values.selectedProducts.find(
                                    product => product.mid === data.mid,
                                  )
                                  ? setFieldValue('selectedProducts', [
                                      ...values.selectedProducts,
                                      ...data.products.filter(
                                        prd =>
                                          !values.selectedProducts.find(
                                            sp =>
                                              sp.productcode ===
                                              prd.productcode,
                                          ),
                                      ),
                                    ])
                                  : setFieldValue('selectedProducts', [
                                      ...values.selectedProducts,
                                      ...data.products,
                                    ])
                                : setFieldValue(
                                    'selectedProducts',
                                    values.selectedProducts.filter(
                                      prd => prd.mid !== data.mid,
                                    ),
                                  );
                            }}
                          />
                        </td>
                      )}
                      {pIndex === 0 && (
                        <td
                          className="text-center"
                          rowSpan={data.products.length}
                        >
                          {/* 商品名稱 */}
                          {data.modelName}
                         <div> {productType === '隱形賣場' ? `(${data.modelNumber})` : ''}</div>
                        </td>
                      )}
                      {pIndex === 0 && (
                        <td
                          className="text-center"
                          rowSpan={data.products.length}
                        >
                          {/* 分類 */}
                          {data.globalCategoies.map(category => (
                            <div className="text-nowrap">{`${category.name} / ${
                              category.cat2Name || '全部'
                            }`}</div>
                          ))}
                        </td>
                      )}
                      {pIndex === 0 && (
                        <td
                          className="text-center"
                          rowSpan={data.products.length}
                        >
                          {/* 銷售時間 */}
                          <div className="text-nowrap">
                            {(data.availableTimeStart &&
                              moment(data.availableTimeStart).format(
                                'YYYY/MM/DD HH:mm',
                              )) ||
                              '--'}
                          </div>
                          <div className="text-nowrap">
                            {(data.availableTimeEnd &&
                              moment(data.availableTimeEnd).format(
                                'YYYY/MM/DD HH:mm',
                              )) ||
                              '--'}
                          </div>
                        </td>
                      )}
                      <td className="text-center">
                        {/* 選擇 product */}
                        <input
                          type="checkbox"
                          className={style['radio-btn']}
                          checked={
                            values.selectedProducts?.length > 0 &&
                            values.selectedProducts.find(
                              prd => prd.productcode === product.productcode,
                            )
                          }
                          onChange={event => {
                            const checked = event.target.checked;
                            checked
                              ? setFieldValue('selectedProducts', [
                                  ...values.selectedProducts,
                                  product,
                                ])
                              : setFieldValue(
                                  'selectedProducts',
                                  values.selectedProducts.filter(
                                    prd =>
                                      prd.productcode !== product.productcode,
                                  ),
                                );
                          }}
                        />
                      </td>
                      <td className="text-center">
                        {/* 商品料號 */}
                        {product.productcode}
                      </td>
                      <td className="text-center">
                        {/* 規格 */}
                        {`${product.color || '--'} / ${product.size || '--'}`}
                      </td>
                      <td className="text-center">
                        {/* 上架 */}
                        {product.isSell ? '是' : '否'}
                      </td>
                    </tr>
                  )),
                )
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          </Table>
        </div>
      )}
      {getLoading && <Loader />}
    </>
  );
};

export default UnselectedList;
