import React, { memo, useState } from 'react';
import { Button, FormGroup, Table } from 'reactstrap';

import style from '../../../styles/layout.module.css';
import TableHasNoData from '../../TableHasNoData';
import AddProductModal from '../../discountCompaign/AddProductModal';

const ProductRange = ({
  type = 'all',
  models = [],
  onChange = (products, type) => {},
  disabled = false,
  errors = {},
  touched = {},
}) => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <AddProductModal
        isOpen={toggle}
        toggle={() => {
          setToggle(false);
        }}
        type={type === 'all' ? '新增排除商品' : '新增指定商品'}
        onSubmit={(data, type) => {
          onChange(data, type);
        }}
        existList={models}
        area={0}
      />
      <FormGroup className={style.formGroup}>
        <label className="w150" />
        {!disabled && (
          <Button
            color="primary"
            outline
            onClick={() => {
              setToggle(true);
            }}
          >
            {type === 'all' && '新增排除商品'}
            {type === 'picked' && '新增指定商品'}
          </Button>
        )}
      </FormGroup>
      <FormGroup className={style.formGroup}>
        <label className="w150" />
        <div className="w-100">
          <h5>
            {type === 'all' && '排除商品'}
            {type === 'picked' && '指定商品'}
          </h5>
          {errors?.models && touched?.models && (
            <div className="form-error">{errors?.models ?? errors?.models}</div>
          )}
          <div className="table-responsive">
            <Table bordered striped hover>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th style={{ minWidth: '150px' }}>商品名稱</th>
                  <th style={{ minWidth: '150px' }}>商品類型</th>
                  <th style={{ minWidth: '150px' }}>商品料號</th>
                  <th>規格</th>
                  <th>商品是否上架/啟用</th>
                  {!disabled && <th>刪除</th>}
                </tr>
              </thead>
              <tbody>
                {models?.length ? (
                  models.map(product => {
                    return (product?.products || []).map((p, index) => {
                      return (
                        <tr key={JSON.stringify(p)}>
                          {index === 0 && (
                            <td rowSpan={product?.products?.length}>
                              {product.modelName}
                            </td>
                          )}
                          {index === 0 && (
                            <td rowSpan={product?.products?.length}>
                              <div>
                                {product.productType === 1 ? '一般商品' : ''}
                                {product.productType === 2 ? '隱形賣場' : ''}
                                {product.productType === 3 ? '組合商品' : ''}
                              </div>
                              {product.productType === 2 && (
                                <div>({product.modelNumber || '-'})</div>
                              )}
                            </td>
                          )}
                          <td className="text-center">{p.productcode}</td>
                          <td className="text-center text-nowrap">
                            {p.color || '-'}/{p.size || '-'}
                          </td>
                          <td className="text-center">
                            {product.productType === 2 ? (
                              <div> {Number(product.status) === 1 ? '是' : '否'}</div>
                            ) : (
                              <div>{p.isSell ? '是' : '否'}</div>
                            )}
                          </td>
                          {!disabled && (
                            <td>
                              <Button
                                className="btn-sm"
                                color="danger"
                                outline
                                onClick={() => {
                                  product.products.splice(index, 1);
                                  onChange(
                                    models.filter(p => p.products?.length > 0),
                                  );
                                }}
                              >
                                刪除
                              </Button>
                            </td>
                          )}
                        </tr>
                      );
                    });
                  })
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default memo(ProductRange);
