import {
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_FALI,
} from 'redux/constants/data/backCodeConstant';

export const getBankList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_BANK_LIST_REQUEST });
      fetch(
        'https://raw.githubusercontent.com/nczz/taiwan-banks-list/main/bank_with_branchs_all.json',
      )
        .then(response => {
          return response.json();
        })
        .then(result => {
          dispatch({ payload: result, type: GET_BANK_LIST_SUCCESS });
        });
    } catch (ex) {
      dispatch({ payload: ex, type: GET_BANK_LIST_FALI });
    }
  };
};
