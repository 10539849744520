import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import {
  deleteServiceContent,
  getServiceList,
  postServiceContent,
  postServiceTitle,
} from '../redux/actions/data/serviceActions';
import {
  SERVICE_CONTENT_DELETE_RESET,
  SERVICE_CONTENT_POST_RESET,
  SERVICE_TITLE_POST_RESET,
} from '../redux/constants/data/serviceConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

export default function MembersInterests(props) {
  const dispatch = useDispatch();
  const [openTitle, setOpenTitle] = useState(false);
  const [openUpperContent, setOpenUpperContent] = useState(false);
  const [openLowerContent, setOpenLowerContent] = useState(false);

  const [titleInput, setTitleInput] = useState('');
  const [titleInputEn, setTitleInputEn] = useState('');
  const [blockId, setblockId] = useState(0);
  const [contentId, setContentId] = useState(0);
  const [editData, setEditData] = useState({});

  const { loading, serviceListInfo, error } = useSelector(
    state => state.serviceList,
  );
  const {
    loading: postLoading,
    success: postSuccess,
    error: postError,
  } = useSelector(state => state.serviceTitlePost);
  const {
    loading: contentLoading,
    success: contentSuccess,
    error: contentError,
  } = useSelector(state => state.serviceContentPost);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.serviceContentDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  useEffect(() => {
    dispatch(getServiceList());
  }, []);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getServiceList());
      setOpenTitle(false);
    }

    if (postError) {
      MessageUtil.alertWanring(postError);
    }

    return () => {
      dispatch({ type: SERVICE_TITLE_POST_RESET });
    };
  }, [postSuccess, postError]);

  useEffect(() => {
    if (contentSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getServiceList());
      setOpenUpperContent(false);
      setOpenLowerContent(false);
      dispatch({ type: SERVICE_CONTENT_POST_RESET });
    }

    if (contentError) {
      MessageUtil.alertWanring(contentError);
      dispatch({ type: SERVICE_CONTENT_POST_RESET });
    }
  }, [contentSuccess, contentError, contentLoading]);

  console.log(contentSuccess, contentError, contentLoading);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getServiceList());
    }

    return () => {
      dispatch({ type: SERVICE_CONTENT_DELETE_RESET });
    };
  }, [deleteSuccess]);

  const toggleTitle = position => {
    setOpenTitle(!openTitle);
    if (position === 'upper') {
      setblockId(serviceListInfo[0].blockId);
      setTitleInput(serviceListInfo[0].blockName);
      setTitleInputEn(serviceListInfo[0].blockName_en);
    } else {
      setblockId(serviceListInfo[1].blockId);
      setTitleInput(serviceListInfo[1].blockName);
      setTitleInputEn(serviceListInfo[1].blockName_en);
    }
  };
  const toggleContent = () => {
    setOpenUpperContent(!openUpperContent);
    setContentId(0);
    setblockId(serviceListInfo[0].blockId);
    setEditData({});
  };

  const toggleEditContent = data => {
    setOpenUpperContent(!openUpperContent);
    setEditData(data);
    setblockId(data.blockId);
    setContentId(data.id);
  };

  const toggleLowerContent = () => {
    setOpenLowerContent(!openLowerContent);
    setContentId(0);
    setblockId(serviceListInfo[1].blockId);
    setEditData({});
  };

  const toggleEditLowerContent = data => {
    setOpenLowerContent(!openLowerContent);
    setEditData(data);
    setblockId(data.blockId);
    setContentId(data.id);
  };

  const handleTitleSubmit = values => {
    let formData = new FormData();

    formData.append('id', blockId);
    formData.append('name', values.title);
    formData.append('name_en', values.title_en);

    dispatch(postServiceTitle(formData));
  };

  const handleContentSubmit = (values, position) => {
    let formData = new FormData();

    if (position === 'upper') {
      formData.append('id', contentId);
      formData.append('blockId', blockId);

      // formData.append('content', values.content)
      // formData.append('content_en', values.content)
      formData.append('status', '上架');
      formData.append('content_desktop_files', values.content_desktop_files[0]);
      formData.append(
        'content_desktop_en_files',
        values.content_desktop_en_files[0],
      );
      formData.append('content_mobile_files', values.content_mobile_files[0]);
      formData.append(
        'content_mobile_en_files',
        values.content_mobile_en_files[0],
      );
    } else {
      formData.append('id', contentId);
      formData.append('blockId', blockId);
      formData.append('status', '上架');
      formData.append('content_desktop_files', values.content_desktop_files[0]);
      formData.append(
        'content_desktop_en_files',
        values.content_desktop_en_files[0],
      );
      formData.append('content_mobile_files', values.content_mobile_files[0]);
      formData.append(
        'content_mobile_en_files',
        values.content_mobile_en_files[0],
      );
    }
    dispatch(postServiceContent(formData));
  };

  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteServiceContent(id));
    });
  };

  const getImageDataFromSetting = (pic, url) => {
    return [
      {
        id: CommonUtil.getNewID(),
        name: pic,
        url: url,
      },
    ];
  };

  const getTitleValidationSchema = () => {
    const schema = Yup.object().shape({
      title: Yup.string().required('必填!'),
    });
    return schema;
  };
  const getContentValidationSchema = () => {
    const schema = Yup.object().shape({
      current_content_desktop_en_files: Yup.array().min(1, '需上傳圖片'),

      // content: Yup.string().required('必填!'),
      current_content_desktop_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_en_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_files: Yup.array().min(1, '需上傳圖片'),
    });
    return schema;
  };
  const getLowerContentValidationSchema = () => {
    const schema = Yup.object().shape({
      current_content_desktop_en_files: Yup.array().min(1, '需上傳圖片'),
      current_content_desktop_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_en_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_files: Yup.array().min(1, '需上傳圖片'),
    });
    return schema;
  };

  useEffect(() => {
    if (serviceListInfo) {
      if (JSON.stringify(serviceListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: serviceListInfo });
      }
    }
  }, [serviceListInfo]);

  return (
    <Layout
      pageTitle="會員權益"
      items={[
        { isActive: false, page_name: '客服中心' },
        { isActive: true, page_name: '會員權益' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>
          <div>{serviceListInfo && serviceListInfo[0]?.blockName}</div>
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => toggleTitle('upper')}
          >
            編輯名稱
          </Button>
        </div>
        <div className={style.card_body}>
          <Button className="btn btn-success w100" onClick={toggleContent}>
            新增
          </Button>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
              {serviceListInfo ? serviceListInfo[0]?.contents.length : 0}
            </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>#</th>
                  <th style={{ minWidth: '150px' }}>電腦版</th>
                  <th style={{ minWidth: '150px' }}>手機版</th>
                  <th style={{ minWidth: '150px' }}>電腦版(英)</th>
                  <th style={{ minWidth: '150px' }}>手機板(英)</th>
                  <th style={{ minWidth: '120px' }}>異動日期</th>
                  <th>功能</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {serviceListInfo &&
                  serviceListInfo[0]?.contents.length > 0 ? (
                    serviceListInfo[0]?.contents.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          {item.contentImg_desktopUrl && (
                            <img
                              className="w150"
                              src={item.contentImg_desktopUrl}
                              alt={item.content}
                            />
                          )}
                        </td>
                        <td>
                          {item.contentImg_mobileUrl && (
                            <img
                              className="w150"
                              src={item.contentImg_mobileUrl}
                              alt={item.content}
                            />
                          )}
                        </td>
                        <td>
                          {item.contentImg_desktopUrl_en && (
                            <img
                              className="w150"
                              src={item.contentImg_desktopUrl_en}
                              alt={item.content_en}
                            />
                          )}
                        </td>
                        <td>
                          {item.contentImg_mobileUrl_en && (
                            <img
                              className="w150"
                              src={item.contentImg_mobileUrl_en}
                              alt={item.content_en}
                            />
                          )}
                        </td>
                        <td>
                          <div>
                            <span>新增：</span>
                            <span className="d-inline-block me-2">
                              {item.createDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.createTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.creator}
                            </span>
                          </div>
                          {item.updateDate && (
                            <div>
                              <span>修改：</span>
                              <span className="d-inline-block me-2">
                                {item.updateDate}
                              </span>
                              <span className="d-inline-block me-2">
                                {item.updateTime}
                              </span>
                              <span className="d-inline-block me-2">
                                {item.updater}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <Button
                            className="m-2"
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => toggleEditContent(item, 'upper')}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-2"
                            color="danger"
                            outline
                            size="sm"
                            onClick={() => handleDelete(item.id)}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </Card>

      <Card className={style.search_card}>
        <div className={style.card_header}>
          <div>{serviceListInfo && serviceListInfo[1]?.blockName}</div>
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => toggleTitle('lower')}
          >
            編輯名稱
          </Button>
        </div>
        <div className={style.card_body} style={{ minWidth: '120px' }}>
          <Button className="btn btn-success w100" onClick={toggleLowerContent}>
            新增
          </Button>
          <div className="d-flex justify-content-end">
            共{' '}
            <span className="mx-1">
              {serviceListInfo ? serviceListInfo[1]?.contents.length : 0}
            </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>#</th>
                  <th style={{ minWidth: '150px' }}>電腦版</th>
                  <th style={{ minWidth: '150px' }}>手機版</th>
                  <th style={{ minWidth: '150px' }}>電腦版(英)</th>
                  <th style={{ minWidth: '150px' }}>手機版(英)</th>
                  <th style={{ minWidth: '120px' }}>異動日期</th>
                  <th>功能</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {serviceListInfo &&
                  serviceListInfo[1]?.contents.length > 0 ? (
                    serviceListInfo[1].contents.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            className="w150"
                            src={item.contentImg_desktopUrl}
                            alt={item.content}
                          />
                        </td>
                        <td>
                          <img
                            className="w150"
                            src={item.contentImg_mobileUrl}
                            alt={item.content}
                          />
                        </td>
                        <td>
                          <img
                            className="w150"
                            src={item.contentImg_desktopUrl_en}
                            alt={item.content_en}
                          />
                        </td>
                        <td>
                          <img
                            className="w150"
                            src={item.contentImg_mobileUrl_en}
                            alt={item.content_en}
                          />
                        </td>
                        <td>
                          <div>
                            <span>新增：</span>
                            <span className="d-inline-block me-2">
                              {item.createDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.createTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.creator}
                            </span>
                          </div>
                          {item.updateDate && (
                            <div>
                              <span>修改：</span>
                              <span className="d-inline-block me-2">
                                {item.updateDate}
                              </span>
                              <span className="d-inline-block me-2">
                                {item.updateTime}
                              </span>
                              <span className="d-inline-block me-2">
                                {item.updater}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <Button
                            className="m-2"
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => toggleEditLowerContent(item)}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-2"
                            color="danger"
                            outline
                            size="sm"
                            onClick={() => handleDelete(item.id)}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
        </div>
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={openTitle}
        toggle={toggleTitle}
        backdrop="static"
      >
        <ModalHeader
          className={style.modal_head}
          toggle={() => toggleTitle('lower')}
        >
          品牌保固升級
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            title: titleInput,
            title_en: titleInputEn,
          }}
          onSubmit={handleTitleSubmit}
          validationSchema={getTitleValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '80px' }}>
                    標題
                  </label>
                  <div className="form-item">
                    <div>
                      中文
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="title"
                        component="span"
                        className="form-error"
                      />
                    </div>
                    <div>
                      英文
                      <Field
                        name="title_en"
                        type="text"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="title_en"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleTitle}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={openUpperContent}
        toggle={toggleContent}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleContent}>
          編輯名稱
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            content_desktop_en_files: [],

            // content: editData ? editData.content : '',
            content_desktop_files: [],
            content_mobile_en_files: [],

            content_mobile_files: [],
            current_content_desktop_en_files: [],
            current_content_desktop_files: [],

            current_content_mobile_en_files: [],
            current_content_mobile_files: [],
            init_content_desktop_en_files:
              editData && editData.contentImg_desktop_en
                ? getImageDataFromSetting(
                    editData.contentImg_desktop_en,
                    editData.contentImg_desktopUrl_en,
                  )
                : [],

            init_content_desktop_files:
              editData && editData.contentImg_desktop
                ? getImageDataFromSetting(
                    editData.contentImg_desktop,
                    editData.contentImg_desktopUrl,
                  )
                : [],
            init_content_mobile_en_files:
              editData && editData.contentImg_mobile_en
                ? getImageDataFromSetting(
                    editData.contentImg_mobile_en,
                    editData.contentImg_mobileUrl_en,
                  )
                : [],
            init_content_mobile_files:
              editData && editData.contentImg_mobile
                ? getImageDataFromSetting(
                    editData.contentImg_mobile,
                    editData.contentImg_mobileUrl,
                  )
                : [],
          }}
          onSubmit={values => handleContentSubmit(values, 'upper')}
          validationSchema={getContentValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_files"
                      currentFileFieldName="current_content_desktop_files"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_files}
                    />
                    <ErrorMessage
                      name="current_content_desktop_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_files"
                      currentFileFieldName="current_content_mobile_files"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_files}
                    />
                    <ErrorMessage
                      name="current_content_mobile_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_en_files"
                      currentFileFieldName="current_content_desktop_en_files"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_en_files}
                    />
                    <ErrorMessage
                      name="current_content_desktop_en_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_en_files"
                      currentFileFieldName="current_content_mobile_en_files"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_en_files}
                    />
                    <ErrorMessage
                      name="current_content_mobile_en_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleContent}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                >
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={openLowerContent}
        toggle={toggleLowerContent}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleLowerContent}>
          品牌保固升級
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            content_desktop_en_files: [],
            content_desktop_files: [],
            content_mobile_en_files: [],

            content_mobile_files: [],
            current_content_desktop_en_files: [],
            current_content_desktop_files: [],

            current_content_mobile_en_files: [],
            current_content_mobile_files: [],
            init_content_desktop_en_files:
              editData && editData.contentImg_desktop_en
                ? getImageDataFromSetting(
                    editData.contentImg_desktop_en,
                    editData.contentImg_desktopUrl_en,
                  )
                : [],

            init_content_desktop_files:
              editData && editData.contentImg_desktop
                ? getImageDataFromSetting(
                    editData.contentImg_desktop,
                    editData.contentImg_desktopUrl,
                  )
                : [],
            init_content_mobile_en_files:
              editData && editData.contentImg_mobile_en
                ? getImageDataFromSetting(
                    editData.contentImg_mobile_en,
                    editData.contentImg_mobileUrl_en,
                  )
                : [],
            init_content_mobile_files:
              editData && editData.contentImg_mobile
                ? getImageDataFromSetting(
                    editData.contentImg_mobile,
                    editData.contentImg_mobileUrl,
                  )
                : [],
          }}
          onSubmit={values => handleContentSubmit(values, 'lower')}
          validationSchema={getLowerContentValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_files"
                      currentFileFieldName="current_content_desktop_files"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_files}
                    />
                    <ErrorMessage
                      name="current_content_desktop_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_files"
                      currentFileFieldName="current_content_mobile_files"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_files}
                    />
                    <ErrorMessage
                      name="current_content_mobile_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_en_files"
                      currentFileFieldName="current_content_desktop_en_files"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_en_files}
                    />
                    <ErrorMessage
                      name="current_content_desktop_en_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_en_files"
                      currentFileFieldName="current_content_mobile_en_files"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_en_files}
                    />
                    <ErrorMessage
                      name="current_content_mobile_en_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleLowerContent}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                >
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
