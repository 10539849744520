import {
  GET_ORDERITEMS_RETURN_INFO_FAIL,
  GET_ORDERITEMS_RETURN_INFO_REQUEST,
  GET_ORDERITEMS_RETURN_INFO_SUCCESS,
} from 'redux/constants/data/orderReturnConstants';
import {
  ORDER_RETURN_REASON_LIST_REQUEST,
  ORDER_RETURN_REASON_LIST_SUCCESS,
  ORDER_RETURN_REASON_LIST_FAIL,
} from 'redux/constants/data/orderReturnReasonConstants';

export const orderReturnReasonListReducer = (
  state = { loading: false, orderReturnReasonListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_REASON_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_REASON_LIST_SUCCESS:
      return { loading: false, orderReturnReasonListInfo: payload || {} };
    case ORDER_RETURN_REASON_LIST_FAIL:
      return {
        error: payload,
        loading: false,
        orderReturnReasonListInfo: {},
      };
    default:
      return state;
  }
};
export const orderReturnReasonSelectionsReducer = (
  state = { orderReturnReasonSelections: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_REASON_LIST_SUCCESS:
      return { orderReturnReasonSelections: payload || [] };
    default:
      return state;
  }
};

export const getOrderitemReturnInfoReducer = (
  state = { error: false, info: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDERITEMS_RETURN_INFO_REQUEST:
      return { error: false, info: {}, loading: true, success: false };
    case GET_ORDERITEMS_RETURN_INFO_SUCCESS:
      return { ...state, info: payload, loading: false, success: true };
    case GET_ORDERITEMS_RETURN_INFO_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
