import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';
import { addGifts } from 'redux/actions/data/productActions';
import useQuery from 'utils/useQuery';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const UnselectedList = ({ isAdded }) => {
  const dispatch = useDispatch();
  const { gifts, loading: getLoading } = useSelector(
      state => state.getBindingGiftsList,
  );
  const { loading: addLoading } = useSelector(state => state.addGifts);
  const query = useQuery();
  const productType = useMemo(() => {
    return Number(query.value?.productType || 1);
  }, [query]);
  const productId = query.value.id;
  const mId = query.value.mid;
  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: [],
    },
    onSubmit: () => {
      const body = {
        freebieIds: values.selectedProducts.map(gift => gift.id),
        isforAdding: true,
        productType,
        mId,
        productId,
      };
      setFieldValue('selectedProducts', []);
      dispatch(addGifts(body));
    },
  });

  useEffect(() => {
    setFieldValue('selectedProducts', []);
  }, [isAdded]);

  return (
      <Card className={style.card}>
        <Button
            color="success"
            className="w100 mb-3"
            onClick={handleSubmit}
            disabled={values.selectedProducts.length === 0 || addLoading}
        >
          {addLoading && (
              <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
              ></span>
          )}
          加入贈品
        </Button>
        <div className="table-responsive">
          <Table hover striped bordered className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th colSpan={8}>
                <div
                    className={`${style.formGroup}`}
                    style={{ marginBottom: 0 }}
                >
                  <input
                      type="checkbox"
                      className={style['radio-btn']}
                      checked={
                          gifts?.datas?.length > 0 &&
                          values.selectedProducts.length === gifts?.datas?.length
                      }
                      onChange={e => {
                        const checked = e.target.checked;
                        setFieldValue('selectedProducts', []);
                        checked &&
                        setFieldValue('selectedProducts', gifts?.datas);
                      }}
                  />
                  <span className="ml-2">全選</span>
                </div>
              </th>
            </tr>
            <tr>
              <th>選擇</th>
              <th>贈品序號</th>
              <th>贈品主圖</th>
              <th>贈品名稱</th>
              <th>贈品料號</th>
              <th>
                <div>已發送數量</div>
                <div>贈品庫存</div>
              </th>
              <th>
                <div>贈品上架時間</div>
                <div>贈品下架時間</div>
              </th>
              <th>贈品狀態</th>
            </tr>
            </thead>
            {getLoading && <TableLoading />}
            {!getLoading && (
                <tbody>
                {gifts?.datas?.length > 0 ? (
                    gifts.datas.map((data, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {/* 選擇 */}
                            <input
                                type="checkbox"
                                className={style['radio-btn']}
                                checked={values.selectedProducts.find(
                                    gift => data.id === gift.id,
                                )}
                                onChange={e => {
                                  const checked = e.target.checked;
                                  checked
                                      ? setFieldValue('selectedProducts', [
                                        ...values.selectedProducts,
                                        data,
                                      ])
                                      : setFieldValue(
                                          'selectedProducts',
                                          values.selectedProducts.filter(
                                              g => g.id !== data.id,
                                          ),
                                      );
                                }}
                            />
                          </td>
                          <td className="text-center">
                            {/* 贈品序號 */}
                            {data.freebieCode}
                          </td>
                          <td className="text-center">
                            {/* 贈品主圖 */}
                            <img
                                className={style.w200}
                                src={data?.freebieImgInfo[0]?.imageUrl || ''}
                                alt={data?.freebieImgInfo[0]?.imageUrl || ''}
                            />
                          </td>
                          <td className="text-center">
                            {/* 贈品名稱 */}
                            {data.name}
                          </td>
                          <td className="text-center">
                            {/* 贈品料號 */}
                            {data.productcode}
                          </td>
                          <td className="text-center">
                            {/* 已發送數量/贈品庫存 */}
                            <div>{0}</div>
                            <div>{data.stock}</div>
                          </td>
                          <td className="text-center">
                            {/* 上下架時間 */}
                            <div>
                              {data.startTime
                                  ? moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')
                                  : '--'}
                            </div>
                            <div>
                              {data.endTime
                                  ? moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')
                                  : '--'}
                            </div>
                          </td>
                          <td className="text-center">
                            {/* 贈品狀態 */}
                            <div>{data.isSell ? '開啟' : '關閉'}</div>
                          </td>
                        </tr>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
      </Card>
  );
};

export default UnselectedList;
