import {
  GET_CAPTCHA_CODE_FAIL,
  GET_CAPTCHA_CODE_REQUEST,
  GET_CAPTCHA_CODE_SUCCESS,
  GET_CONTACT_US_FAIL,
  GET_CONTACT_US_REQUEST,
  GET_CONTACT_US_SUCCESS,
  UPDATE_CAPTCHA_CODE_FAIL,
  UPDATE_CAPTCHA_CODE_IDLE,
  UPDATE_CAPTCHA_CODE_REQUEST,
  UPDATE_CAPTCHA_CODE_SUCCESS,
  UPDATE_CONTACT_US_FAIL,
  UPDATE_CONTACT_US_IDLE,
  UPDATE_CONTACT_US_REQUEST,
  UPDATE_CONTACT_US_SUCCESS,
} from 'redux/constants/data/websiteSettingsContants';

export const getCaptchaCodeReducer = (
  state = { data: null, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CAPTCHA_CODE_REQUEST:
      return { ...state, data: null, error: null, loading: true };
    case GET_CAPTCHA_CODE_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case GET_CAPTCHA_CODE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateCaptchaCodeReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CAPTCHA_CODE_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_CAPTCHA_CODE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CAPTCHA_CODE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_CAPTCHA_CODE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getContactUsContextReducer = (
  state = { error: null, loading: false, result: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONTACT_US_REQUEST:
      return { error: null, loading: true, result: {}, success: false };
    case GET_CONTACT_US_SUCCESS:
      return { ...state, loading: false, result: payload, success: true };
    case GET_CONTACT_US_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateContactUsContextReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CONTACT_US_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_CONTACT_US_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CONTACT_US_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_CONTACT_US_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
