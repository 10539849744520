export const DEALER_CHANNEL_LIST_REQUEST = 'DEALER_CHANNEL_LIST_REQUEST';
export const DEALER_CHANNEL_LIST_SUCCESS = 'DEALER_CHANNEL_LIST_SUCCESS';
export const DEALER_CHANNEL_LIST_FAIL = 'DEALER_CHANNEL_LIST_FAIL';

export const DEALER_CHANNEL_DETAILS_IDLE = 'DEALER_CHANNEL_DETAILS_IDLE';
export const DEALER_CHANNEL_DETAILS_REQUEST = 'DEALER_CHANNEL_DETAILS_REQUEST';
export const DEALER_CHANNEL_DETAILS_SUCCESS = 'DEALER_CHANNEL_DETAILS_SUCCESS';
export const DEALER_CHANNEL_DETAILS_FAIL = 'DEALER_CHANNEL_DETAILS_FAIL';

export const DEALER_CHANNEL_POST_REQUEST = 'DEALER_CHANNEL_POST_REQUEST';
export const DEALER_CHANNEL_POST_SUCCESS = 'DEALER_CHANNEL_POST_SUCCESS';
export const DEALER_CHANNEL_POST_FAIL = 'DEALER_CHANNEL_POST_FAIL';
export const DEALER_CHANNEL_POST_RESET = 'DEALER_CHANNEL_POST_RESET';

export const DEALER_DEFAULT_SET_REQUEST = 'DEALER_DEFAULT_SET_REQUEST';
export const DEALER_DEFAULT_SET_SUCCESS = 'DEALER_DEFAULT_SET_SUCCESS';
export const DEALER_DEFAULT_SET_FAIL = 'DEALER_DEFAULT_SET_FAIL';
export const DEALER_DEFAULT_SET_RESET = 'DEALER_DEFAULT_SET_RESET';
