import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_RECRUITMENT_ADD_FAIL,
  UUCAFE_RECRUITMENT_ADD_REQUEST,
  UUCAFE_RECRUITMENT_ADD_SUCCESS,
  UUCAFE_RECRUITMENT_FAIL,
  UUCAFE_RECRUITMENT_REQUEST,
  UUCAFE_RECRUITMENT_SUCCESS,
  UUCAFE_RECRUITMENT_UPDATE_FAIL,
  UUCAFE_RECRUITMENT_UPDATE_REQUEST,
  UUCAFE_RECRUITMENT_UPDATE_SUCCESS,
  UUCAFE_RECRUITMENT_LIST_FAIL,
  UUCAFE_RECRUITMENT_LIST_REQUEST,
  UUCAFE_RECRUITMENT_LIST_SUCCESS,
} from '../../constants/data/uuCafeRecruitmentConstants';

/*
* @Params
* {
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string",
  "keyword": "string",
  "menuId": 0,
  "status": 0
  }
*
*/
export const getCafeRecruitmentList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_RECRUITMENT_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_RECRUITMENT_LIST_REQUEST,
      successConstant: UUCAFE_RECRUITMENT_LIST_SUCCESS,
      url: '/Backend/Cafe_Recruitment/list',
    });
  };
};

export const getCafeRecruitmentDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_RECRUITMENT_FAIL,
      method: 'get',
      requestConstant: UUCAFE_RECRUITMENT_REQUEST,
      successConstant: UUCAFE_RECRUITMENT_SUCCESS,
      url: `/Backend/Cafe_Recruitment/recruitment/${params.id}`,
    });
  };
};

/*
* @Params
  "id": 0,
  "name": "string",
  "name_en": "string",
  "content": "string",
  "content_en": "string",
  "status": 0
*/

export const addCafeRecruitmentDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_RECRUITMENT_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_RECRUITMENT_ADD_REQUEST,
      successConstant: UUCAFE_RECRUITMENT_ADD_SUCCESS,
      url: '/Backend/Cafe_Recruitment/recruitment/add',
    });
  };
};

/*
* @Params
  "id": 0,
  "name": "string",
  "name_en": "string",
  "content": "string",
  "content_en": "string",
  "status": 0
*/

export const updateCafeRecruitmentDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_RECRUITMENT_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_RECRUITMENT_UPDATE_REQUEST,
      successConstant: UUCAFE_RECRUITMENT_UPDATE_SUCCESS,
      url: '/Backend/Cafe_Recruitment/recruitment/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeRecruitmentDetail = async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_Recruitment/recruitment/delete',
  });
};
