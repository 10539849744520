import moment from 'moment';
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { FormGroup, Input, Row } from 'reactstrap';

const labelStyle = {
  minWidth: '80px',
};

const productRangeOptions = [
  { label: '全部', value: '' },
  { label: '全店商品', value: 'all' },
  { label: '指定商品', value: 'picked' },
];

const timeTypeRangeOptions = [
  { label: '全部', value: '全部' },
  { label: '活動開始日', value: '活動開始日' },
  { label: '活動結束日', value: '活動結束日' },
  { label: '活動建檔日', value: '活動建檔日' },
];

const SearchPromoListForm = ({ values, setFieldValue }) => {
  return (
    <Row>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-6"
        style={{ minWidth: '250px' }}
      >
        <label className="mx-3 text-nowrap" style={labelStyle}>
          關鍵字:
        </label>
        <Input
          className="flex-grow-1"
          style={{ minWidth: '200px' }}
          placeholder="活動序號、活動名稱"
          value={values.keyword}
          onChange={e => {
            setFieldValue('keyword', e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-6"
        style={{ minWidth: '250px' }}
      >
        <label className="mx-3 text-nowrap" style={labelStyle}>
          活動範圍:
        </label>
        <Select
          type="select"
          className="flex-grow-1"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              minWidth: '200px',
              width: '100%',
            }),
          }}
          defaultValue={
            productRangeOptions.filter(
              o => String(o.value) == String(values.productRange),
            )[0] || null
          }
          value={
            productRangeOptions.filter(
              o => String(o.value) == String(values.productRange),
            )[0] || null
          }
          options={productRangeOptions}
          onChange={op => {
            setFieldValue('productRange', op.value);
          }}
        ></Select>
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-lg-7"
        style={{ minWidth: '350px' }}
      >
        <label className="mx-3 text-nowrap" style={labelStyle}>
          日期區間:
        </label>
        <div className="d-inline-flex flex-wrap align-items-center flex-grow-1">
          <Select
            type="select"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                marginRight: '5px',
                minWidth: '150px',
              }),
            }}
            defaultValue={
              timeTypeRangeOptions.filter(
                o => String(o.value) === String(values.timeType),
              )[0] || null
            }
            value={
              timeTypeRangeOptions.filter(
                o => String(o.value) === String(values.timeType),
              )[0] || null
            }
            options={timeTypeRangeOptions}
            onChange={op => {
              setFieldValue('timeType', op.value);
            }}
          ></Select>
          <div style={{ minWidth: '150px' }}>
            <DatePicker
              wrapperClassName="flex-grow-1"
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={
                values.startTime ? moment(values.startTime).toDate() : ''
              }
              onChange={date => {
                setFieldValue('startTime', moment(date).format('YYYY/MM/DD'));
              }}
            />
          </div>
          <span className="mx-3">~</span>
          <div style={{ minWidth: '150px' }}>
            <DatePicker
              wrapperClassName="flex-grow-1"
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={values.endTime ? moment(values.endTime).toDate() : ''}
              onChange={date => {
                setFieldValue('endTime', moment(date).format('YYYY/MM/DD'));
              }}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-lg-5"
        style={{ minWidth: '250px' }}
      >
        <label className="mx-3 text-nowrap">活動狀態:</label>
        <div className="d-inline-flex flex-wrap align-items-center">
          {[
            {
              text: '尚未開始',
              value: 1,
            },
            {
              text: '進行中',
              value: 2,
            },
            {
              text: '已結束',
              value: 3,
            },
          ].map(option => {
            return (
              <label
                htmlFor={`${option.text}_status`}
                className="d-inline-flex align-items-center mx-2"
                key={`${option.text}_status`}
              >
                <input
                  id={`${option.text}_status`}
                  type="checkbox"
                  checked={values?.status?.some(s => s === option.value)}
                  onChange={e => {
                    if (values?.status?.some(s => s === option.value)) {
                      const index = values?.status?.indexOf(option.value);
                      values?.status?.splice(index, 1);
                    } else {
                      values?.status?.push(option.value);
                    }
                    setFieldValue('status', [...values.status]);
                  }}
                />
                <span>{option.text}</span>
              </label>
            );
          })}
        </div>
      </FormGroup>
    </Row>
  );
};

export default memo(SearchPromoListForm);
