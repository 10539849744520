import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';

import Layout from '../components/Layout';
import Loader from '../components/Loader';
import MiddleHomeModal from '../components/uuCafeMiddleHomeSetting/MiddleHomeModal';
import { getCafeMiddleHomeList } from '../redux/actions/data/uuCafeMiddleHomeSettingsAction';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import { copyText } from '../utils/copyText';

const UUCafeMiddleHomeSettings = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(
    state => state.cafeMiddleHomeSettingsList,
  );
  const [openModal, setOpenModel] = useState(false);

  const middleHome = useRef({
    bannerImg: '',
    bannerImg_mobile: '',
    bannerUrl: '',
    bannerUrl_mobile: '',
    linkUrl: '',
    title: '',
    title_en: '',
  });

  const handleInit = useCallback(() => {
    dispatch(getCafeMiddleHomeList());
  }, [dispatch]);

  useEffect(() => {
    handleInit();
  }, [handleInit]);

  return (
    <Layout
      pageTitle="首頁-最新動態"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '首頁-最新動態' },
      ]}
    >
      <MiddleHomeModal
        openModal={openModal}
        setOpenModel={show => {
          if (!show) {
            handleInit();
          }
          setOpenModel(show);
        }}
        middleHome={middleHome.current}
      />
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <p className="small text-end mb-0">共 {list.length} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th nowrap="nowrap">桌機版區塊</th>
                    <th nowrap="nowrap">標題</th>
                    <th style={{ minWidth: '120px' }}>連結</th>
                    <th nowrap="nowrap">電腦版圖片</th>
                    <th nowrap="nowrap">手機版圖片</th>
                    <th style={{ minWidth: '120px' }}>異動日期</th>
                    <th>功能</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((data, index) => (
                    <tr key={index}>
                      <td>{data.location}</td>
                      <td>{data.title}</td>
                      <td>
                        <Button
                          size="sm"
                          outline
                          color="primary"
                          disabled={!data.linkUrl}
                          onClick={() => {
                            copyText(data.linkUrl);
                            MessageUtil.toastSuccess('已複製', data.linkUrl);
                          }}
                        >
                          複製連結
                        </Button>
                      </td>
                      <td width="150">
                        <img
                          className={style.w200}
                          src={data.bannerUrl}
                          alt={data.bannerImg}
                        />
                      </td>
                      <td width="150">
                        <img
                          className={style.w200}
                          src={data.bannerUrl_mobile}
                          alt={data.bannerImg_mobile}
                        />
                      </td>

                      <td>
                        <div className="text-nowrap">{data.updateDateTime}</div>
                        <div>{data.updater}</div>
                      </td>
                      <td nowrap="nowrap">
                        <Button
                          size="sm"
                          outline
                          color="primary"
                          onClick={() => {
                            middleHome.current = data;
                            setOpenModel(true);
                          }}
                        >
                          編輯
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </Layout>
  );
};

export default UUCafeMiddleHomeSettings;
