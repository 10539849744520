import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_MEALINTRO_DETAIL_REQUEST,
  UUCAFE_MEALINTRO_DETAIL_SUCCESS,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_DETAIL_FAIL,
  UUCAFE_MEALINTRO_SEO_REQUEST,
  UUCAFE_MEALINTRO_SEO_SUCCESS,
  UUCAFE_MEALINTRO_SEO_FAIL,
  UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_SEO_UPDATE_FAIL,
  UUCAFE_MEALINTRO_MENU_LIST_REQUEST,
  UUCAFE_MEALINTRO_MENU_LIST_SUCCESS,
  UUCAFE_MEALINTRO_MENU_LIST_FAIL,
  UUCAFE_MEALINTRO_MENU_REQUEST,
  UUCAFE_MEALINTRO_MENU_SUCCESS,
  UUCAFE_MEALINTRO_MENU_FAIL,
  UUCAFE_MEALINTRO_MENU_ADD_REQUEST,
  UUCAFE_MEALINTRO_MENU_ADD_SUCCESS,
  UUCAFE_MEALINTRO_MENU_ADD_FAIL,
  UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_MENU_UPDATE_FAIL,
  UUCAFE_MEALINTRO_ZONE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_FAIL,
  UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SAVE_FAIL,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL,
  UUCAFE_MEALINTRO_ZONE_SEO_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SEO_FAIL,
} from '../../constants/data/uuCafeMealIntroConstants';

// ============ MEALINTRO ==================

export const getCafeMealIntroDetail = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_DETAIL_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_DETAIL_REQUEST,
      successConstant: UUCAFE_MEALINTRO_DETAIL_SUCCESS,
      url: '/Backend/Cafe_MealIntro/mealIntro',
    });
  };
};

/*
@Params : data : {
  "title": "string",
  "title_en": "string",
  "content": "string",
  "content_en": "string"
}
*/
export const updateCafeMealIntroDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealIntro/mealIntro/save',
    });
  };
};

// ============ MEALINTRO_SEO ==================

export const getCafeMealIntroSeo = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_SEO_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_SEO_REQUEST,
      successConstant: UUCAFE_MEALINTRO_SEO_SUCCESS,
      url: '/Backend/Cafe_MealIntro/mealIntroSeo',
    });
  };
};

/*
@Params : data : {
{
  "title": "string",
  "title_en": "string",
  "keyword": "string",
  "keyword_en": "string",
  "desc": "string",
  "desc_en": "string"
}
}
*/
export const updateCafeMealIntroSeo = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_SEO_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealIntro/mealIntroSeo/save',
    });
  };
};

// ============ MEALINTRO_Menu ==================
/*
* "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string"
*/
export const getCafeMealIntroMenuList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_MENU_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_MENU_LIST_REQUEST,
      successConstant: UUCAFE_MEALINTRO_MENU_LIST_SUCCESS,
      url: '/Backend/Cafe_MealCoffeeMenu/list',
    });
  };
};

export const getCafeMealIntroMenu = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_MENU_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_MENU_REQUEST,
      successConstant: UUCAFE_MEALINTRO_MENU_SUCCESS,
      url: `/Backend/Cafe_MealCoffeeMenu/menu/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
title string
title_en string
content string
content_en string
startTime string($date-time)
endTime string($date-time)
img_files array
*/
export const addCafeMealIntroMenu = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_MENU_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_MENU_ADD_REQUEST,
      successConstant: UUCAFE_MEALINTRO_MENU_ADD_SUCCESS,
      url: '/Backend/Cafe_MealCoffeeMenu/menu/add',
    });
  };
};

/*
* id integer($int32)
  name string
  name_en string
  templateType integer($int32)
  status integer($int32)
  img_files array
* */
export const updateCafeMealIntroMenu = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_MENU_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealCoffeeMenu/menu/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeMealIntroMenu = async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_MealCoffeeMenu/menu/delete',
  });
};

// ============ MEALINTRO_Cafe_Zone ==================
export const getCafeMealIntroZone = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_ZONE_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_ZONE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_ZONE_SUCCESS,
      url: '/Backend/Cafe_MealIntro/coffeeZone',
    });
  };
};

/**
 * title  string
 * title_en  string
 * content  string
 * content_en string
 * introBanner_files  array
 * introBanner_files_mobile  array
 * zoneBanner_files  array
 * zoneBanner_files_mobile array
 */

export const saveCafeMealIntroZone = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_ZONE_SAVE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS,
      url: '/Backend/Cafe_MealIntro/coffeeZone/save',
    });
  };
};

// ============ MEALINTRO_Cafe_Zone ==================
export const getCafeMealIntroZoneSeo = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_ZONE_SEO_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_ZONE_SEO_REQUEST,
      successConstant: UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS,
      url: '/Backend/Cafe_MealIntro/coffeeZoneSeo',
    });
  };
};

/**
 * title  string
 * title_en  string
 * content  string
 * content_en string
 */

export const saveCafeMealIntroZoneSeo = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS,
      url: '/Backend/Cafe_MealIntro/coffeeZoneSeo/save',
    });
  };
};
