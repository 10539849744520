export const SEOSETTINGS_LIST_REQUEST = 'SEOSETTINGS_LIST_REQUEST';
export const SEOSETTINGS_LIST_SUCCESS = 'SEOSETTINGS_LIST_SUCCESS';
export const SEOSETTINGS_LIST_FAIL = 'SEOSETTINGS_LIST_FAIL';

export const SEOSETTINGS_DETAIL_REQUEST = 'SEOSETTINGS_DETAIL_REQUEST';
export const SEOSETTINGS_DETAIL_SUCCESS = 'SEOSETTINGS_DETAIL_SUCCESS';
export const SEOSETTINGS_DETAIL_FAIL = 'SEOSETTINGS_DETAIL_FAIL';

export const SEOSETTINGS_UPDATE_IDLE = 'SEOSETTINGS_UPDATE_IDLE';
export const SEOSETTINGS_UPDATE_REQUEST = 'SEOSETTINGS_UPDATE_REQUEST';
export const SEOSETTINGS_UPDATE_SUCCESS = 'SEOSETTINGS_UPDATE_SUCCESS';
export const SEOSETTINGS_UPDATE_FAIL = 'SEOSETTINGS_UPDATE_FAIL';

export const SEOSETTINGS_DETAIL_UPDATE_IDLE = 'SEOSETTINGS_DETAIL_UPDATE_IDLE';
export const SEOSETTINGS_DETAIL_UPDATE_REQUEST =
  'SEOSETTINGS_DETAIL_UPDATE_REQUEST';
export const SEOSETTINGS_DETAIL_UPDATE_SUCCESS =
  'SEOSETTINGS_DETAIL_UPDATE_SUCCESS';
export const SEOSETTINGS_DETAIL_UPDATE_FAIL = 'SEOSETTINGS_DETAIL_UPDATE_FAIL';
