import Layout from 'components/Layout';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaArrowsAltV, FaPlay } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Card } from 'reactstrap';
import {
  getMessageCategoryList,
  updateMessageCategorySort,
} from 'redux/actions/data/messageActions';
import { UPDATE_MESSAGE_CATEGORY_SORT_IDLE } from 'redux/constants/data/MessageConstant';
import MessageUtil from 'utils/MessageUtil';
import useQuery from 'utils/useQuery';


import style from '../styles/layout.module.css';

const MessageCategorySorting = () => {
  const dispatch = useDispatch();
  const [sortList, setSortList] = useState([]);
  const [sortSubList, setSortSubList] = useState([]);
  const { success, error } = useSelector(
    state => state.updateMessageCategorySort,
  );
  const query = useQuery();
  const identity = useMemo(() => {
    return query.value.identity;
  }, []);

  const handleOnDragEnd = result => {
    const items = Array.from(sortList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortList(items);

    const body = items.map((item, index) => ({
      id: item.parentId,
      sort: index + 1,
    }));
    dispatch(updateMessageCategorySort(body));
  };

  useEffect(() => {
    // call list api
    dispatch(
      getMessageCategoryList({
        catType: identity,
        parentId: null,
      }),
    );
  }, []);

  const handleOnDragEndSub = result => {
    const items = Array.from(sortSubList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortSubList(items);
    const body = items.map((item, index) => ({
      id: item.id,
      sort: index + 1,
    }));
    dispatch(updateMessageCategorySort(body));
  };

  const { list } = useSelector(state => state.getMessageCategoryList);

  useEffect(() => {
    let main = [];

    list.forEach(data => {
      if (!data.id) {
        main.push(data);
      }
    });
    setSortList(main);
  }, []);

  const toggleSubItems = parentId => {
    let sub = [];
    list.forEach(data => {
      if (data.parentId === parentId) {
        sub = data.cats;
      }
    });
    setSortSubList(sub);
  };

  useEffect(() => {
    if (error) {
      MessageUtil.alertWanring('發生錯誤', error);
      dispatch({ type: UPDATE_MESSAGE_CATEGORY_SORT_IDLE });
    }

    if (success) {
      dispatch({ type: UPDATE_MESSAGE_CATEGORY_SORT_IDLE });
    }
  }, [error, success]);

  return (
    <Layout
      pageTitle={'分類選單排序'}
      items={[
        { isActive: true, page_name: '訊息紀錄' },
        { isActive: true, page_name: '分類選單管理' },
        { isActive: false, page_name: '分類選單排序' },
      ]}
    >
      <div className={style.show_flex} style={{ alignItems: 'flex-start' }}>
        <Card
          className={`${style.card} ${style['message-cat-card']}`}
          style={{ maxHeight: window.innerHeight * 0.7, overflowY: 'scroll' }}
        >
          <div className={style.formGroup}>
            <label className={style['message-cat-label']}>第一層選單</label>
            <div style={{ margin: 'auto' }} />
            <small>
              <FaArrowsAltV />
              直接拖拉排序
            </small>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ margin: 0, padding: 0 }}
                >
                  {sortList?.map(
                    ({ parentId, id, name, parent_name }, index) => (
                      <Draggable
                        key={id || parentId}
                        draggableId={`${id || parentId}`}
                        index={index}
                      >
                        {provided => (
                          <li
                            className={`${style.formGroup}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Alert
                              color="secondary"
                              style={{ width: '100%' }}
                              className={style.show_flex}
                            >
                              {name || parent_name}
                              <div style={{ margin: 'auto' }} />
                              <FaPlay
                                style={{ cursor: 'pointer' }}
                                size="20"
                                onClick={() => {
                                  toggleSubItems(parentId);
                                }}
                              />
                            </Alert>
                          </li>
                        )}
                      </Draggable>
                    ),
                  )}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <div style={{ margin: '0.5rem' }} />
        {sortSubList?.length > 0 && (
          <Card
            className={`${style.card} ${style['message-cat-card']}`}
            style={{ maxHeight: window.innerHeight * 0.7, overflowY: 'scroll' }}
          >
            <div className={style.formGroup}>
              <label className={style['message-cat-label']}>第二層</label>
              <div style={{ margin: 'auto' }} />
              <small>
                <FaArrowsAltV />
                直接拖拉排序
              </small>
            </div>
            <DragDropContext onDragEnd={handleOnDragEndSub}>
              <Droppable droppableId="brands">
                {provided => (
                  <ul
                    className="drag_list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {sortSubList?.map(({ id, name }, index) => (
                      <Draggable key={id} draggableId={`${id}`} index={index}>
                        {provided => (
                          <li
                            className={`${style.formGroup}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Alert
                              color="secondary"
                              style={{ width: '100%' }}
                              className={style.show_flex}
                            >
                              {name}
                              <div style={{ margin: 'auto' }} />
                            </Alert>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
        )}
      </div>
    </Layout>
  );
};

export default MessageCategorySorting;
