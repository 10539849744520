import TableHasNoData from 'components/TableHasNoData';
import React from 'react';
import { Button, Input, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';

const DiscountSettings = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
  setIsProductModalOpen,
  setDiscountType,
}) => {
  return (
    <React.Fragment>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="w150">活動商品</label>
        <div>
          <small>
            <li>商品清單更新後即刻生效</li>
          </small>
          <small>
            <li>
              若不設定這個活動時，請選 "指定商品"，不要加入任何商品，即無此活動
            </li>
          </small>
          <small>
            <li>商品不能跟【打折商品】重複</li>
          </small>
          <div style={{ margin: '1.5rem' }} />
          <div className={style.formGroup}>
            <div
              className={style['radio-btn-layout']}
              style={{ marginRight: '2rem' }}
            >
              <input
                type="radio"
                className={style['radio-btn']}
                checked={values.productRange_discount === 'all'}
                onChange={() => {
                  setFieldValue('productRange_discount', 'all');
                  setFieldValue('productRange_percent', 'picked');
                }}
                disabled={values.productRange_percent === 'all'}
              />
              <span className="ml-2">全店商品</span>
            </div>
            <div className={style['radio-btn-layout']}>
              <input
                type="radio"
                className={style['radio-btn']}
                checked={values.productRange_discount === 'picked'}
                onChange={() => {
                  setFieldValue('productRange_discount', 'picked');
                }}
              />
              <span className="ml-2">指定商品</span>
            </div>
          </div>
          {values.productRange_discount !== '' && (
            <React.Fragment>
              <Button
                className="btn-sm mb-3"
                color="primary"
                outline
                onClick={() => {
                  setIsProductModalOpen();
                  setDiscountType('折價');
                }}
                disabled={
                  values.percent_products.length === 0 &&
                  values.productRange_percent === 'all'
                }
              >
                {`新增${
                  values.productRange_discount === 'all' ? '排除' : '指定'
                }商品`}
              </Button>
              <div>
                <h5>{`${
                  values.productRange_discount === 'all' ? '排除' : '指定'
                }商品`}</h5>
                <div className="table-responsive">
                  <Table bordered striped hover>
                    <thead className={`thead-dark ${style.table_head}`}>
                      <tr>
                        <th style={{ minWidth: '150px' }}>商品名稱</th>
                        <th style={{ minWidth: '150px' }}>商品類型</th>
                        <th style={{ minWidth: '150px' }}>商品料號</th>
                        <th>規格</th>
                        <th>商品是否上架</th>
                        <th>刪除</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values?.discount_products?.length > 0 ? (
                        values.discount_products.map((temp, index) =>
                          temp?.products?.map((product, pIndex) => (
                            <tr key={index + pIndex}>
                              {pIndex === 0 && (
                                <td rowSpan={temp.products.length}>
                                  {temp.modelName}
                                </td>
                              )}
                              {pIndex === 0 && (
                                <td rowSpan={temp?.products?.length}>
                                  <div>
                                    {temp.productType === 1 ? '一般商品' : ''}
                                    {temp.productType === 2 ? '隱形賣場' : ''}
                                    {temp.productType === 3 ? '組合商品' : ''}
                                  </div>
                                  {temp.productType === 2 && (
                                    <div>({temp.modelNumber || '-'})</div>
                                  )}
                                </td>
                              )}
                              <td className="text-center">
                                <label>{product.productcode}</label>
                              </td>
                              <td nowrap="nowrap">
                                {product.color || '--'} / {product.size || '--'}
                              </td>
                              <td>{product.isSell ? '是' : '否'}</td>
                              <td>
                                <Button
                                  className="btn-sm"
                                  color="danger"
                                  outline
                                  onClick={() => {
                                    temp?.products.splice(index, 1);
                                    setFieldValue(
                                      'discount_products',
                                      values.discount_products.filter(
                                        p => p.products?.length > 0,
                                      ),
                                    );
                                  }}
                                >
                                  刪除
                                </Button>
                              </td>
                            </tr>
                          )),
                        )
                      ) : (
                        <TableHasNoData colNumber={7} />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="w150">折抵條件</label>
        <div className={style.formGroup}>
          <label>折價NT$</label>
          <Input
            className={style['form-input']}
            type="number"
            placeholder="請輸入金額"
            id="discountAmount"
            value={values.discountAmount}
            onChange={handleChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DiscountSettings;
