import React, { useMemo, useRef } from 'react';
import { Input } from 'reactstrap';

import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

export const ConditionsValue1 = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();

  return (
    <div>
      <Input
        type="number"
        className={style['form-input']}
        invalid={
          errors?.conditions?.length > 0 && errors?.conditions[index]?.value1
        }
        value={values?.conditions[index]?.value1 || 0}
        onBlur={e => {
          let value = e.target.value || '0';

          if (isNaN(Number(value))) {
            MessageUtil.alertWanring('請輸入數字');
            return;
          }

          if (Number(value) < 0) {
            MessageUtil.alertWanring('金額必須大於0');
            return;
          }

          if (placeholder.includes('折') && Number(value) <= 0.1) {
            MessageUtil.alertWanring('數字必須大於0.1');
            return;
          }

          if (placeholder.includes('折') && value > 9.9) {
            MessageUtil.alertWanring('數字必須小於9.9');
            return;
          }
          setFieldValue(`conditions[${index}].value1`, Number(e.target.value));
        }}
        onChange={e => {
          isTouched.current = true;
          let value = e.target.value;
          setFieldValue(
            `conditions[${index}].value1`,
            Number(value).toString(),
          );
        }}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors?.conditions?.length > 0 && errors?.conditions[index]?.value1 && (
        <div className="form-error">
          {placeholder.includes('折')
            ? placeholder
            : errors?.conditions[index]?.value1}
        </div>
      )}
    </div>
  );
};
export const ConditionsValue2 = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();
  return (
    <div>
      <Input
        type="number"
        className={style['form-input']}
        invalid={
          errors?.conditions?.length > 0 && errors?.conditions[index]?.value2
        }
        value={values?.conditions[index]?.value2 || 0}
        onBlur={e => {
          let value = e.target.value || '0';

          if (isNaN(Number(value))) {
            MessageUtil.alertWanring('請輸入數字');
            return;
          }

          if (Number(value) < 0) {
            MessageUtil.alertWanring('金額必須大於0');
            return;
          }

          if (placeholder.includes('折') && Number(value) <= 0.1) {
            MessageUtil.alertWanring('數字必須大於0.1');
            return;
          }

          if (placeholder.includes('折') && value > 9.9) {
            MessageUtil.alertWanring('數字必須小於9.9');
            return;
          }
          setFieldValue(`conditions[${index}].value2`, Number(e.target.value));
        }}
        onChange={e => {
          isTouched.current = true;
          let value = e.target.value;
          setFieldValue(
            `conditions[${index}].value2`,
            Number(value).toString(),
          );
        }}
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors?.conditions?.length > 0 && errors?.conditions[index]?.value2 && (
        <div className="form-error">
          {placeholder.includes('折')
            ? placeholder
            : errors?.conditions[index]?.value2}
        </div>
      )}
    </div>
  );
};

export const ConditionsAreaValue1 = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();

  const className = useMemo(() => {
    if (
      errors?.conditionsArea?.length > 0 &&
      errors?.conditionsArea[index]?.value1 &&
      isTouched.current
    ) {
      return `${style['form-input']} is-invalid`;
    }
    return style['form-input'];
  }, [errors?.conditionsArea, isTouched]);

  return (
    <div>
      <Input
        type="number"
        className={className}
        placeholder={placeholder}
        value={
          (values.conditionsArea?.length > 0 &&
            values.conditionsArea[index]?.value1) ||
          0
        }
        onBlur={e => {
          let value = e.target.value || '0';
          if (isNaN(Number(value))) {
            MessageUtil.alertWanring('請輸入數字');
            return;
          }
          if (Number(value) < 0) {
            MessageUtil.alertWanring('金額必須大於0');
            return;
          }
          if (placeholder.includes('折') && Number(value) <= 0.1) {
            MessageUtil.alertWanring('數字必須大於0.1');
            return;
          }
          if (placeholder.includes('折') && value > 9.9) {
            MessageUtil.alertWanring('數字必須小於9.9');
            return;
          }
          setFieldValue(
            `conditionsArea[${index}].value1`,
            Number(e.target.value),
          );
        }}
        onChange={e => {
          isTouched.current = true;
          let value = e.target.value;
          setFieldValue(
            `conditionsArea[${index}].value1`,
            Number(value).toString(),
          );
          setFieldValue(
            `conditionsArea[${index}].value2`,
            values.conditionsArea[index]?.value2 ?? 0,
          );
        }}
        disabled={disabled}
      />
      {errors?.conditionsArea?.length > 0 &&
        errors?.conditionsArea[index]?.value1 && (
          <div className="form-error">
            {placeholder.includes('折')
              ? placeholder
              : errors?.conditionsArea[index]?.value1}
          </div>
        )}
    </div>
  );
};

export const ConditionsAreaValue2 = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();
  const className = useMemo(() => {
    if (
      errors?.conditionsArea?.length > 0 &&
      errors?.conditionsArea[index]?.value2 &&
      isTouched.current
    ) {
      return `${style['form-input']} is-invalid`;
    }
    return style['form-input'];
  }, [errors?.conditionsArea, isTouched]);

  return (
    <div>
      <Input
        className={className}
        type="number"
        placeholder={placeholder}
        value={
          (values.conditionsArea?.length > 0 &&
            values.conditionsArea[index]?.value2) ||
          0
        }
        onBlur={e => {
          let value = e.target.value || '0';

          if (isNaN(Number(value))) {
            MessageUtil.alertWanring('請輸入數字');
            return;
          }

          if (Number(value) < 0) {
            MessageUtil.alertWanring('金額必須大於0');
            return;
          }

          if (placeholder.includes('折') && Number(value) <= 0.1) {
            MessageUtil.alertWanring('數字必須大於0.1');
            return;
          }
          if (placeholder.includes('折') && value > 9.9) {
            MessageUtil.alertWanring('數字必須小於9.9');
            return;
          }
          if (placeholder.includes('折') && value > 9.9) {
            return;
          }
          setFieldValue(
            `conditionsArea[${index}].value2`,
            Number(e.target.value),
          );
        }}
        onChange={e => {
          isTouched.current = true;
          let value = e.target.value;
          setFieldValue(
            `conditionsArea[${index}].value2`,
            Number(value).toString(),
          );
        }}
        disabled={disabled}
      />
      {errors?.conditionsArea?.length > 0 &&
        errors?.conditionsArea[index]?.value2 && (
          <div className="form-error">
            {placeholder.includes('折')
              ? placeholder
              : errors?.conditionsArea[index]?.value2}
          </div>
        )}
    </div>
  );
};

export const ConditionsAreaName = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();

  const className = useMemo(() => {
    if (
      errors.area?.length > 0 &&
      errors.area[index]?.areaName &&
      isTouched.current
    ) {
      return `${style['form-input']} is-invalid`;
    }
    return style['form-input'];
  }, [errors?.area, isTouched]);

  return (
    <div>
      <Input
        className={className}
        value={values.area[index]?.areaName || ''}
        onChange={event => {
          isTouched.current = true;
          setFieldValue(`area[${index}].areaName`, event.target.value);
        }}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors.area?.length > 0 && errors.area[index]?.areaName && (
        <div className="form-error">{errors.area[index]?.areaName}</div>
      )}
    </div>
  );
};

export const ConditionsAreaNameEN = ({
  values,
  index = 0,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();

  const className = useMemo(() => {
    if (
      errors.area?.length > 0 &&
      errors.area[index]?.areaName_en &&
      isTouched.current
    ) {
      return `${style['form-input']} is-invalid`;
    }
    return style['form-input'];
  }, [errors?.area, isTouched]);

  return (
    <div>
      <Input
        className={className}
        value={values.area[index]?.areaName_en || ''}
        onChange={event => {
          isTouched.current = true;
          setFieldValue(`area[${index}].areaName_en`, event.target.value);
        }}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
      />
      {errors.area?.length > 0 && errors.area[index]?.areaName_en && (
        <div className="form-error">{errors.area[index]?.areaName_en}</div>
      )}
    </div>
  );
};

export const ConditionFixedTPrice = ({
  values,
  setFieldValue,
  errors = {},
  touched = {},
  placeholder = '請輸入金額',
  disabled = false,
}) => {
  const isTouched = useRef();

  return (
    <div>
      <Input
        className={style['form-input']}
        type="number"
        placeholder={placeholder}
        value={values.condition_fixedTPrice}
        onBlur={e => {
          let value = e.target.value;
          if (isNaN(Number(value))) {
            MessageUtil.alertWanring('請輸入數字');
            return;
          }
          if (Number(value) < 0) {
            MessageUtil.alertWanring('金額必須大於0');
            return;
          }
          if (placeholder.includes('折') && Number(value) <= 0.1) {
            MessageUtil.alertWanring('數字必須大於0.1');
            return;
          }

          if (placeholder.includes('折') && value > 9.9) {
            MessageUtil.alertWanring('數字必須小於9.9');
            return;
          }

          setFieldValue('condition_fixedTPrice', Number(e.target.value));
        }}
        onChange={e => {
          isTouched.current = true;
          let value = e.target.value;
          setFieldValue('condition_fixedTPrice', Number(value).toString());
        }}
        disabled={disabled}
        invalid={errors.condition_fixedTPrice && isTouched.current}
      />
      {errors.condition_fixedTPrice && (
        <div className="form-error">
          {placeholder.includes('折')
            ? placeholder
            : errors.condition_fixedTPrice}
        </div>
      )}
    </div>
  );
};
