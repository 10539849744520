import { useFormik } from 'formik';
import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState, memo, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TabPane,
} from 'reactstrap';
import * as Yup from 'yup';

import { getBrandOptions } from '../../redux/actions/data/brandActions';
import {
  copyGroupModel,
  getGroupModelDetail,
  setGroupModelSetting,
} from '../../redux/actions/data/groupModelActions';
import {
  getGroupModelBrandOptions,
  getModelList,
} from '../../redux/actions/data/modelActions';
import {
  getModelOptions,
  getProductList,
} from '../../redux/actions/data/productActions';
import { getSeoSettingsDetail } from '../../redux/actions/data/seoSettingsAction';
import {
  GROUP_MODEL_COPY_RESET,
  GROUP_MODEL_SET_SETTING_RESET,
} from '../../redux/constants/data/groupModelConstants';
import style from '../../styles/layout.module.css';
import { checkOverNow, emptyOption, hourArr, minArr } from '../../utils';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import CustomColorPicker from '../CustomColorPicker';
import ProductImgPicker from '../ProductImgPicker';
import SearchSelect from '../SearchSelector';
import { BlockSpinner } from '../Spinner';
import TableHasNoData, { TableLoading } from '../TableHasNoData';
import UploadFiles from '../UploadFiles';
import ProductCategorySelection from '../productDetail/ProductCategorySelection';

import LabelC from './LabelC';
const AddModalTableList = ({ isShowTable, onChange, selecteds }) => {
  const { loading, productListInfo } = useSelector(state => state.productList);

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
  });
  useEffect(() => {
    onChange && onChange(values.list);
  }, [values]);

  const selected = id =>
    selecteds?.some(j => j.rels?.some(k => k.productId === id));

  return (
    <div className="table-responsive">
      {isShowTable && (
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th style={{ width: 90 }}>
                <input
                  className={style.maintain_list_checkbox}
                  type="checkbox"
                  name="all"
                  onChange={e => {
                    const val = e.target.checked;
                    setFieldValue('list', val ? productListInfo?.datas : []);
                  }}
                  checked={
                    values.list.length > 0 &&
                    values.list.length === productListInfo?.datas?.length
                  }
                />
                全選
              </th>
              <th>商品名稱</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {productListInfo?.datas?.length > 0 ? (
                productListInfo?.datas?.map(i => (
                  <tr className="text-nowrap" key={i.id}>
                    <td>
                      <input
                        className={style.maintain_list_checkbox}
                        type="checkbox"
                        onChange={e => {
                          const val = e.target.checked;
                          setFieldValue(
                            'list',
                            val
                              ? [...values.list, i]
                              : values.list.filter(j => j.id !== i.id),
                          );
                        }}
                        checked={values.list.indexOf(i) > -1 || selected(i.id)}
                      />
                    </td>
                    <td>{i.name}</td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      )}
    </div>
  );
};
export const AddModal = ({ open, toggle, onSuccess, selecteds }) => {
  const dispatch = useDispatch();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { loading, productListInfo } = useSelector(state => state.productList);

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  const [isShowTable, setIsShowTable] = useState(false);
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: 10,
    },
    async onSubmit(formModel) {
      setIsShowTable(true);
      dispatch(getProductList(formModel));
    },
  });

  useEffect(() => {
    setFieldValue('model', '');
    setFieldValue('brandCateName', '請選擇品牌類別');
    setFieldValue('brandCateId', 0);
    dispatch(
      getModelOptions(
        brandOptions?.find(i => i.value === values.brandId)?.label || '',
      ),
    );
  }, [values.brandId]);
  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const [list, setList] = useState([]);
  const handleListChange = formModel => {
    setList(formModel);
  };
  const handleAddProducts = () => {
    list.length <= 0 && MessageUtil.toastWarning('請選擇商品！');
    list.length > 0 && onSuccess && onSuccess(list);
  };

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        新增分類商品
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        {loading && <BlockSpinner />}
        <form className="form-inline">
          <div className="d-flex flex-column flex-sm-row flex-wrap w-100">
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2 text-nowrap">關鍵字</label>
              <input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="商品名稱"
                onChange={handleChange}
                value={values.keyword}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2">品牌</label>
              <SearchSelect
                options={brandOptions}
                emptyVal={0}
                name="brandId"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label me-2">型號</label>
              <SearchSelect
                options={modelOptions}
                emptyVal={''}
                name="model"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup}>
              <Button
                type="button"
                className={style.modal_button_submit}
                onClick={() => {
                  handleChangePage(1);
                }}
              >
                查詢
              </Button>
            </div>
          </div>
        </form>
        <AddModalTableList
          isShowTable={isShowTable}
          onChange={handleListChange}
          selecteds={selecteds}
        />
        {productListInfo?.total_count > 0 && isShowTable && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={productListInfo?.current_page}
              defaultPageSize={productListInfo?.page_size}
              total={productListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
        >
          <span className="btn-wrapper--label" onClick={handleAddProducts}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const CopyModalTableList = ({ isShowTable, onChange }) => {
  const { loading, modelListInfo } = useSelector(state => state.modelList);

  const { values, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: 0,
    },
  });
  useEffect(() => {
    onChange && onChange(values.id);
  }, [values]);

  return (
    <div className="table-responsive">
      {isShowTable && (
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th></th>
              <th>商品名稱</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {modelListInfo?.datas?.length > 0 ? (
                modelListInfo?.datas?.map((i, index) => (
                  <tr className="text-nowrap" key={i.id}>
                    <td>
                      <input
                        className={style.maintain_list_checkbox}
                        type="radio"
                        name="id"
                        onChange={handleChange}
                        value={i.id}
                        checked={+values.id === i.id}
                      />
                    </td>
                    <td>{i.name}</td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      )}
    </div>
  );
};
const CopyModal = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { modelListInfo } = useSelector(state => state.modelList);

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  const [isShowTable, setIsShowTable] = useState(false);
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: 10,
    },
    async onSubmit(formModel) {
      setIsShowTable(true);
      dispatch(getModelList(formModel));
    },
  });

  useEffect(() => {
    setFieldValue('model', '');
    dispatch(
      getModelOptions(
        brandOptions?.find(i => i.value === values.brandId)?.label || '',
      ),
    );
  }, [values.brandId]);
  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const [modelId, setModelId] = useState(null);
  const handleListChange = id => {
    setModelId(+id);
  };
  const { loading, modelInfo, success, error } = useSelector(
    state => state.copyGroupModel,
  );
  const handleCopyGroupModel = () => {
    dispatch(copyGroupModel(modelId));
  };
  useEffect(() => {
    success && MessageUtil.toastSuccess('複製成功！');
    success && toggle();
    success &&
      modelInfo?.id &&
      dispatch(getSeoSettingsDetail({ sourceId: modelInfo.id, type: 14 }));
    success && history.push(`/GroupModelDetail/${modelInfo.id}`);
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_COPY_RESET });
    };
  }, [success, error]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        複製商品資訊
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        {loading && <BlockSpinner />}
        <form className="form-inline">
          <div className="d-flex flex-column flex-sm-row flex-wrap w-100">
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2 text-nowrap">關鍵字</label>
              <input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="商品名稱"
                onChange={handleChange}
                value={values.keyword}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2">品牌</label>
              <SearchSelect
                options={brandOptions}
                emptyVal={0}
                name="brandId"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label me-2">型號</label>
              <SearchSelect
                options={modelOptions}
                emptyVal={''}
                name="model"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup}>
              <Button
                type="button"
                className={style.modal_button_submit}
                onClick={() => handleChangePage(1)}
              >
                查詢
              </Button>
            </div>
          </div>
        </form>
        <CopyModalTableList
          isShowTable={isShowTable}
          onChange={handleListChange}
        />
        {modelListInfo?.total_count > 0 && isShowTable && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={modelListInfo?.current_page}
              defaultPageSize={modelListInfo?.page_size}
              total={modelListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
        >
          <span className="btn-wrapper--label" onClick={handleCopyGroupModel}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const TabSetting = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [copyModalOpen, setCopyModalOpen] = useState(false);
  const handleToggleCopyModal = () => {
    setCopyModalOpen(!copyModalOpen);
  };

  const { modelBrandOptionsInfo, loading: modelBrandOptionsInfoLoading } =
    useSelector(state => state.modelBrandOptions);

  const { loading, groupModelInfo } = useSelector(
    state => state.groupModelDetail,
  );

  const checkCanDelete = useMemo(() => {
    return (
      groupModelInfo.setting?.availableEndTimeType === 2 ||
      (groupModelInfo.setting?.availableEndTimeType !== 2 &&
        !checkOverNow(groupModelInfo.setting?.availableTimeStart))
    );
  }, [
    groupModelInfo?.setting?.availableEndTimeType,
    groupModelInfo?.setting?.availableTimeStart,
  ]);

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {},
    onSubmit(formModel) {
      const params = {
        availableEndTimeType: formModel.availableEndTimeType,
        availableTimeStart: (formModel.availableTimeStart
          ? moment(formModel.availableTimeStart)
          : moment()
        )
          .hour(+formModel.availableTimeStartHour)
          .minute(+formModel.availableTimeStartMin)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss'),
        brand: formModel?.brand || '',
        brandCatId: formModel.brandCateId,
        brandCateId: formModel.brandCateId,
        brandCateName: formModel.brandCateName,
        features: JSON.stringify([formModel.features]),
        globalCategoyIds: JSON.stringify(
          formModel.categories.map(i => {
            if (i.cat1.hasPrd === false && String(i.cat2.value) === '-1') {
              return i.cat1.value;
            } else if (i.cat1.hasPrd) {
              return i.cat1.value;
            } else {
              return i.cat2.value;
            }
          }),
        ),
        model: formModel.model,
        modelName: formModel.modelName,
        modelName_en: formModel.modelName_en,
        modelimgs: JSON.stringify(formModel.modelimgs),
        products: JSON.stringify(formModel.products),
        ...(formModel.availableEndTimeType === 1 && {
          availableTimeEnd: (formModel.availableTimeEnd
            ? moment(formModel.availableTimeEnd)
            : moment()
          )
            .hour(+formModel.availableTimeEndHour)
            .minute(+formModel.availableTimeEndMin)
            .second(59)
            .format('YYYY-MM-DDTHH:mm:ss'),
        }),
        isReturnable: formModel.isReturnable,
        isSell: formModel.isSell,
      };
      const formData = new FormData();
      Object.keys(params).forEach(i => {
        formData.append(i, params[i]);
      });
      formModel.modelImgFiles.forEach(i => {
        formData.append('modelImgFiles', i);
      });

      dispatch(setGroupModelSetting({ data: formData, id: id || 0 }));
    },
    validationSchema: Yup.object().shape({
      availableTimeStart: Yup.string().required('請選擇銷售區間'),
      brand: Yup.string().required('請選擇品牌'),
      brandCateId: Yup.number()
        .required('品牌類別必填')
        .test('is-positive', '品牌類別必填', value => {
          return value && value > 0;
        }),
      categories: Yup.array()
        .of(
          Yup.object().shape({
            cat1: Yup.object().shape({
              value: Yup.number()
                .min(1, '商品類別1必填')
                .required('商品類別1必填'),
            }),
            cat2: Yup.object().when('cat1', {
              is: cat1 => cat1.hasCat2 === true,
              then: Yup.object().shape({
                value: Yup.number()
                  .min(1, '商品類別2必填')
                  .required('商品類別2必填'),
              }),
            }),
          }),
        )
        .min(1, '請選擇商品類別'),
      features: Yup.object().shape({
        content: Yup.string().required('特色說明必填'),
        content_en: Yup.string().required('特色說明必填'),
      }),
      model: Yup.string().required('型號必填'),
      modelName: Yup.string().nullable().required('商品名稱(中文)必填'),
      modelName_en: Yup.string().nullable().required('商品名稱(英文)必填'),
      modelimgs: Yup.array().min(1, '請選擇圖片'),
      products: Yup.array().of(
        Yup.object().shape({
          colorCode: Yup.string().test('is-positive', '請輸入色碼', value => {
            return value !== '#' && value?.length > 1;
          }),
          colorName: Yup.string().test(
            'is-positive',
            '請輸入顏色名稱(中文)',
            value => {
              return value;
            },
          ),
          colorName_en: Yup.string().test(
            'is-positive',
            '請輸入顏色名稱(英文)',
            value => {
              return value;
            },
          ),
          maxSellNum: Yup.string()
            .nullable()
            .test('is-positive', '數值必須大於0', value => {
              // 將字串轉換為數值並進行比較
              const numericValue = parseFloat(value);
              return !isNaN(numericValue) && numericValue > 0;
            }),
          rels: Yup.array().of(
            Yup.object().shape({
              lowestStock: Yup.string()
                .test('is-positive', '數值必須大於0', value => {
                  // 將字串轉換為數值並進行比較
                  const numericValue = parseFloat(value);
                  return !isNaN(numericValue) && numericValue > 0;
                })
                .required(),
              sellprice: Yup.string()
                .test('is-positive', '數值必須大於0', value => {
                  // 將字串轉換為數值並進行比較
                  const numericValue = parseFloat(value);
                  return !isNaN(numericValue) && numericValue > 0;
                })
                .required(),
              sellquantity: Yup.string()
                .test('is-positive', '數值必須大於0', value => {
                  // 將字串轉換為數值並進行比較
                  const numericValue = parseFloat(value);
                  return !isNaN(numericValue) && numericValue > 0;
                })
                .required(),
              stock: Yup.string()
                .test('is-positive', '數值必須大於0', value => {
                  // 將字串轉換為數值並進行比較
                  const numericValue = parseFloat(value);
                  return !isNaN(numericValue) && numericValue > 0;
                })
                .required(),
            }),
          ),
        }),
      ),
    }),
  });

  useEffect(() => {
    if (!loading && groupModelInfo) {
      setValues({
        availableEndTimeType: groupModelInfo.setting?.availableEndTimeType ?? 0,
        availableTimeEnd: groupModelInfo.setting?.availableTimeEnd,
        availableTimeEndHour: groupModelInfo.setting?.availableTimeEnd
          ? moment(groupModelInfo.setting?.availableTimeEnd).hour()
          : 0,
        availableTimeEndMin: groupModelInfo.setting?.availableTimeEnd
          ? moment(groupModelInfo.setting?.availableTimeEnd).minute()
          : 0,
        availableTimeStart:
          groupModelInfo.setting?.availableTimeStart ||
          moment().add(1, 'day').format('YYYY/MM/DD HH:mm:ss'),
        availableTimeStartHour: groupModelInfo.setting?.availableTimeStart
          ? moment(groupModelInfo.setting?.availableTimeStart).hour()
          : 0,
        availableTimeStartMin: groupModelInfo.setting?.availableTimeStart
          ? moment(groupModelInfo.setting?.availableTimeStart).minute()
          : 0,
        brand: groupModelInfo?.setting?.brand || '',
        brandCateId: groupModelInfo.setting?.brandCateId ?? 0,
        brandCateName: groupModelInfo.setting?.brandCateName ?? 0,
        categories: (groupModelInfo.setting?.globalCategoies || []).map(
          category => {
            const obj = {
              cat1: {
                hasPrd: Number(category?.cat2Id) <= 0 ? true : false,
                label: category.name.toString(),
                value: category.id.toString(),
              },
              cat2: {
                label: category?.cat2Name?.toString() ?? '',
                value: category?.cat2Id?.toString() ?? '',
              },
            };
            return obj;
          },
        ),
        features: {
          content: groupModelInfo?.setting?.features?.[0]?.content || '',
          content_en: groupModelInfo?.setting?.features?.[0]?.content_en || '',
          sort: 1,
        },
        isReturnable: groupModelInfo.setting?.isReturnable ?? false,
        isSell: groupModelInfo.setting?.isSell ?? false,
        model: groupModelInfo.model,
        modelImgFiles: [],
        modelName: groupModelInfo.modelName,
        modelName_en: groupModelInfo.modelName_en,
        modelimgs: groupModelInfo.setting?.images?.map(i => ({
          ...i,
          imgFormFileId: -1,
          isRemoveFile: false,
          name: i.image,
          url: i.imageUrl,
        })),
        products:
          groupModelInfo.setting?.products?.map((i, index) => ({
            index: index,
            ...i,
            rels: i.relProducts.map(p => {
              return {
                ...p,
              };
            }),
          })) || [],
      });
      dispatch(getGroupModelBrandOptions(groupModelInfo?.brandId || 99));
    }
  }, [loading, groupModelInfo]);

  const handleChangeImages = images => {
    const modelImgFiles = images.filter(i => Boolean(i.file)).map(i => i.file);
    const modelimgs = images.map((i, index) => ({
      id: i.id,
      image: i.name,
      imgFormFileId: modelImgFiles.indexOf(i.file),
      isRemoveFile: !i.file,
      sort: index + 1,
      ...i,
    }));
    setFieldValue('modelImgFiles', modelImgFiles);
    setFieldValue('modelimgs', modelimgs);
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null);
  const handleToggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };
  const handleAddModalSubmit = list => {
    if (currentGroupIndex !== null) {
      const params = list.map((i, index) => ({
        ...i,
        color: i.color,
        index: values.products[currentGroupIndex].length + index,
        lowestStock: i.lowestStock ?? 0,
        productId: i.id,
        productcode: i.code || '',
        productname: i.name || '',
        sellprice: i.sellprice ?? 0,
        sellquantity: (i.h_sellQuantity || i.sellquantity) ?? 0,
        size: i.size,
        stock: i.stock ?? 0,
      }));
      setFieldValue(`products[${currentGroupIndex}].rels`, [
        ...values.products[currentGroupIndex].rels,
        ...params,
      ]);
      handleToggleAddModal();
      return;
    }
    const params = {
      colorCode: '',
      colorName: '',
      colorName_en: '',
      defaultImgId: '',
      groupModelProductId: 0,
      isSell: false,
      outofStockWords: '已售完',
      productname: '',
      rels: list.map((i, index) => ({
        ...i,
        color: i.color,
        index,
        lowestStock: i.lowestStock ?? 0,
        productId: i.id,
        productcode: i.code,
        productname: i.name,
        sellprice: i.sellprice ?? 0,
        sellquantity: (i.h_sellQuantity || i.sellquantity) ?? 0,
        size: i.size,
        stock: i.stock ?? 0,
      })),
      showImg: '',
      size: '',
    };
    setFieldValue('products', [...values.products, params]);
    handleToggleAddModal();
  };

  const handleDeleteProduct = i => {
    MessageUtil.submitConfirm({
      doneFun() {
        setFieldValue(
          'products',
          values.products.filter((_, index) => i !== index),
        );
      },
      title: '確認刪除？',
    });
  };

  const handleDeleteRelProduct = (iIndex, jIndex) => {
    MessageUtil.submitConfirm({
      doneFun() {
        setFieldValue(
          `products[${iIndex}].rels`,
          values.products[iIndex].rels.filter((_, index) => jIndex !== index),
        );
      },
      title: '確認刪除？',
    });
  };

  const {
    loading: setLoading,
    success,
    groupModelId,
    error,
  } = useSelector(state => state.setGroupModelSetting);

  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );

  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功！');
    success && !id && history.push(`/GroupModelDetail/${groupModelId}`);
    success && id && dispatch(getGroupModelDetail(id));
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_SET_SETTING_RESET });
    };
  }, [success, error]);

  return (
    <TabPane tabId={1}>
      {loading && <TableLoading />}
      {!loading && (
        <Card className={style.card} style={{ overflow: 'inherit' }}>
          {!id && (
            <div className="mb-5">
              <Button
                color="warning"
                className="text-light"
                onClick={() => setCopyModalOpen(true)}
              >
                複製商品內容
              </Button>
              <CopyModal open={copyModalOpen} toggle={handleToggleCopyModal} />
            </div>
          )}
          {id && groupModelInfo && (
            <>
              <Form className="position-relative">
                {(loading || setLoading) && <BlockSpinner />}
                <FormGroup row>
                  <LabelC text="品牌" required />
                  <Col>
                    <Select
                      isLoading={brandOptionsLoading}
                      className={{
                        control: () => 'w-100',
                      }}
                      options={[
                        { label: '請選擇品牌', value: 0 },
                        ...(brandOptions ? [...brandOptions] : []),
                      ]}
                      name="brand"
                      onChange={({ value, label }) => {
                        if (label === 'label') {
                          setFieldValue('brand', '');
                        } else {
                          setFieldValue('brand', label);
                        }
                        setFieldValue('brandCateName', '請選擇品牌類別');
                        setFieldValue('brandCateId', 0);
                        dispatch(getGroupModelBrandOptions(value));
                      }}
                      value={
                        brandOptions
                          ?.filter(o => o.label)
                          ?.find(i => i.label === values?.brand) || {
                          label: '請選擇品牌',
                          value: 0,
                        }
                      }
                    />
                    {errors?.brand && (
                      <FormText color="danger" className="d-block">
                        {errors.brand}
                      </FormText>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="型號" required />
                  <Col>
                    <Input
                      name="model"
                      onChange={handleChange}
                      value={values.model}
                    />
                    {errors?.model && (
                      <FormText color="danger" className="d-block">
                        {errors.model}
                      </FormText>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="商品名稱" required />
                  <Col>
                    <Input
                      name="modelName"
                      onChange={handleChange}
                      value={values.modelName}
                    />
                    {errors?.modelName && (
                      <FormText color="danger" className="d-block">
                        {errors.modelName}
                      </FormText>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="商品名稱(英)" required />
                  <Col>
                    <Input
                      name="modelName_en"
                      onChange={handleChange}
                      value={values.modelName_en}
                    />
                    {errors?.modelName_en && (
                      <FormText color="danger" className="d-block">
                        {errors.modelName_en}
                      </FormText>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="商品圖片" required />
                  <Col className="mb-4">
                    <UploadFiles
                      defaultFiles={values.modelimgs}
                      onChange={handleChangeImages}
                    />
                    {errors?.modelimgs && (
                      <FormText color="danger" className="d-block">
                        {errors.modelimgs}
                      </FormText>
                    )}
                  </Col>
                </FormGroup>

                {values.products?.length === 0 && (
                  <div className={`${style.bg_gray} p-5 text-center mb-5`}>
                    <h4 className="fw-normal mb-3">還沒有組合商品</h4>
                    <Button
                      color="success"
                      className="w300"
                      onClick={() => {
                        setAddModalOpen(true);
                        setCurrentGroupIndex(null);
                      }}
                    >
                      新增商品組合
                    </Button>
                  </div>
                )}
                <AddModal
                  open={addModalOpen}
                  toggle={handleToggleAddModal}
                  onSuccess={handleAddModalSubmit}
                  selecteds={
                    currentGroupIndex !== null
                      ? [values.products[currentGroupIndex]]
                      : []
                  }
                />
                {values.products?.length > 0 && (
                  <Button
                    color="success"
                    className="mb-3"
                    disabled={!checkCanDelete}
                    onClick={() => {
                      setAddModalOpen(true);
                      setCurrentGroupIndex(null);
                    }}
                  >
                    新增商品組合
                  </Button>
                )}
                <div className="table-responsive mb-3">
                  {(values.products || []).map((i, index) => (
                    <Table hover bordered key={`${i.groupModelProductId}`}>
                      <thead className={`thead-dark ${style.table_head}`}>
                        <tr>
                          <th style={{ minWidth: 80 }}>排序</th>
                          <th>圖片</th>
                          <th style={{ minWidth: 250 }}>*productcode</th>
                          <th style={{ minWidth: 180 }}>*顏色</th>
                          <th style={{ minWidth: 180 }}>*顏色名稱</th>
                          <th style={{ minWidth: 180 }}>尺吋</th>
                          <th style={{ minWidth: 180 }}>
                            <div>建議售價</div>
                            <div>售價</div>
                          </th>
                          <th style={{ minWidth: 150 }}>商品交期</th>
                          <th style={{ minWidth: 100 }}>上架</th>
                          <th>缺貨顯示</th>
                          <th nowrap="nowrap">*一次最高購買量</th>
                          <th style={{ minWidth: 100 }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan="100">
                            <input
                              value={i.sort}
                              min={0}
                              type="number"
                              className="form-control"
                              onBlur={e => {
                                setFieldValue(
                                  `products[${index}].sort`,
                                  Number(e.target.value),
                                );
                              }}
                              onChange={e => {
                                setFieldValue(
                                  `products[${index}].sort`,
                                  Number(e.target.value).toString(),
                                );
                              }}
                            />
                          </td>
                          <td>
                            <ProductImgPicker
                              thumbnail={i.showImg}
                              imgs={values.modelimgs}
                              onSelect={imgItem => {
                                setFieldValue(
                                  `products[${index}].defaultImgId`,
                                  imgItem.id,
                                );
                                setFieldValue(
                                  `products[${index}].showImg`,
                                  imgItem.url,
                                );
                              }}
                            />
                          </td>
                          <td>
                            {/* productcode */}
                            {values.products[index].productcode ||
                              values.products[index].productname ||
                              '-'}
                          </td>
                          <td>
                            {/* 顏色 */}
                            <CustomColorPicker
                              initColor={i.colorCode}
                              formFieldName={`products[${index}].colorCode`}
                              setFormFieldValue={setFieldValue}
                            />
                            <FormText color="danger" className="d-block">
                              {errors.products?.[index]?.colorCode}
                            </FormText>
                          </td>
                          <td>
                            <div className="d-flex align-items-center mb-1 flex-column">
                              <div className="d-flex">
                                中：
                                <Input
                                  type="text"
                                  name={`products[${index}].colorName`}
                                  onChange={handleChange}
                                  value={values.products[index]?.colorName}
                                />
                              </div>
                              <FormText color="danger" className="d-block">
                                {errors.products?.[index]?.colorName}
                              </FormText>
                            </div>
                            <div className="d-flex align-items-center mb-1 flex-column">
                              <div className="d-flex">
                                英：
                                <Input
                                  type="text"
                                  name={`products[${index}].colorName_en`}
                                  onChange={handleChange}
                                  value={values.products[index]?.colorName_en}
                                />
                              </div>
                              <FormText color="danger" className="d-block">
                                {errors.products?.[index]?.colorName_en}
                              </FormText>
                            </div>
                          </td>
                          <td>
                            <Input
                              name={`products[${index}].size`}
                              onChange={handleChange}
                              value={values.products[index].size}
                            />
                          </td>
                          <td className="text-nowrap">
                            {/* 建議售價 / 售價 */}
                            <div className="price text-dark fw-normal">
                              {values.products?.[index]?.rels
                                ?.reduce(
                                  (a, b) => a + b.websiteprice * b.sellquantity,
                                  0,
                                )
                                ?.toLocaleString('en-US')}
                            </div>
                            <div className="price text-dark fw-normal">
                              {values.products?.[index]?.rels
                                ?.reduce(
                                  (a, b) => a + b.sellprice * b.sellquantity,
                                  0,
                                )
                                ?.toLocaleString('en-US')}
                            </div>
                          </td>
                          <td className="text-nowrap">
                            {/*商品交期*/}
                            <Label
                              className="d-flex gap-1 align-items-center"
                              htmlFor={`products[${index}].deliveryType.true`}
                            >
                              <input
                                id={`products[${index}].deliveryType.true`}
                                className={style.maintain_list_checkbox}
                                onClick={e => {
                                  setFieldValue(
                                    `products[${index}].deliveryType`,
                                    1,
                                  );
                                  setFieldValue(
                                    `products[${index}].preorderDeliveryDate`,
                                    null,
                                  );
                                }}
                                type="radio"
                                checked={
                                  values.products[index]
                                    .preorderDeliveryDate === null
                                }
                                value="null"
                              />
                              一般
                            </Label>
                            <Label
                              className="d-flex gap-1 align-items-center"
                              for={`products[${index}].deliveryType.false`}
                            >
                              <input
                                id={`products[${index}].deliveryType.false`}
                                className={style.maintain_list_checkbox}
                                onClick={() => {
                                  setFieldValue(
                                    `products[${index}].deliveryType`,
                                    2,
                                  );
                                  setFieldValue(
                                    `products[${index}].preorderDeliveryDate`,
                                    moment().format('YYYY-MM-DD'),
                                  );
                                }}
                                type="radio"
                                checked={
                                  values.products[index]
                                    .preorderDeliveryDate !== null
                                }
                                value={moment().format('YYYY-MM-DD')}
                              />
                              預購，預定出貨日
                            </Label>
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              selected={
                                values.products[index].preorderDeliveryDate
                                  ? moment(
                                      values.products[index]
                                        .preorderDeliveryDate,
                                    ).toDate()
                                  : null
                              }
                              name="preorderDeliveryDate"
                              onChange={date => {
                                setFieldValue(
                                  `products[${index}].deliveryType`,
                                  2,
                                );
                                setFieldValue(
                                  `products[${index}].preorderDeliveryDate`,
                                  moment(date).format('YYYY-MM-DD'),
                                );
                              }}
                            />
                          </td>
                          <td>
                            {/*上架*/}
                            <Label
                              className="d-flex gap-1 align-items-center"
                              htmlFor={`products[${index}].isSell.true`}
                            >
                              <input
                                id={`products[${index}].isSell.true`}
                                className={style.maintain_list_checkbox}
                                name={`products[${index}].isSell`}
                                onClick={e => {
                                  setFieldValue(
                                    `products[${index}].isSell`,
                                    true,
                                  );
                                }}
                                type="radio"
                                checked={values.products[index].isSell}
                                value={true}
                              />
                              是
                            </Label>
                            <Label
                              className="d-flex gap-1 align-items-center"
                              htmlFor={`products[${index}].isSell.false`}
                            >
                              <input
                                id={`products[${index}].isSell.false`}
                                className={style.maintain_list_checkbox}
                                onClick={e => {
                                  setFieldValue(
                                    `products[${index}].isSell`,
                                    false,
                                  );
                                }}
                                type="radio"
                                checked={!values.products[index].isSell}
                                value={false}
                              />
                              否
                            </Label>
                          </td>
                          <td className="text-nowrap">
                            <Label className="d-flex gap-1 align-items-center">
                              <input
                                className={style.maintain_list_checkbox}
                                name={`products[${index}].outofStockWords`}
                                onChange={handleChange}
                                type="radio"
                                value="已售完"
                                checked={
                                  values.products[index].outofStockWords ===
                                  '已售完'
                                }
                              />
                              已售完
                            </Label>
                            <Label className="d-flex gap-1 align-items-center">
                              <input
                                className={style.maintain_list_checkbox}
                                name={`products[${index}].outofStockWords`}
                                onChange={handleChange}
                                type="radio"
                                value="售完不補貨"
                                checked={
                                  values.products[index].outofStockWords ===
                                  '售完不補貨'
                                }
                              />
                              售完不補貨
                            </Label>
                            <Label className="d-flex gap-1 align-items-center">
                              <input
                                className={style.maintain_list_checkbox}
                                name={`products[${index}].outofStockWords`}
                                onChange={handleChange}
                                type="radio"
                                value="貨到通知"
                                checked={
                                  values.products[index].outofStockWords ===
                                  '貨到通知'
                                }
                              />
                              貨到通知
                            </Label>
                          </td>
                          <td>
                            {/* *一次最高購買量*/}
                            <Input
                              type="number"
                              name={`products[${index}].maxSellNum`}
                              onChange={e => {
                                setFieldValue(
                                  `products[${index}].maxSellNum`,
                                  Number(e.target.value).toString(),
                                );
                              }}
                              onBlur={e => {
                                setFieldValue(
                                  `products[${index}].maxSellNum`,
                                  Number(e.target.value),
                                );
                              }}
                              value={values.products[index]?.maxSellNum}
                            />
                            <FormText color="danger" className="d-block">
                              {errors.products?.[index]?.maxSellNum}
                            </FormText>
                          </td>
                          <td>
                            <div className="d-flex gap-2">
                              <Button
                                color="primary"
                                outline
                                disabled={!checkCanDelete}
                                onClick={() => {
                                  setAddModalOpen(true);
                                  setCurrentGroupIndex(index);
                                }}
                              >
                                新增商品
                              </Button>
                              <Button
                                color="danger"
                                outline
                                disabled={!checkCanDelete}
                                onClick={() => handleDeleteProduct(index)}
                              >
                                刪除
                              </Button>
                            </div>
                          </td>
                        </tr>
                        <tr
                          className={`${style.bg_gray}`}
                          style={{ borderBottom: '2px solid #999' }}
                        >
                          <td colSpan="2"></td>
                          <td>顏色</td>
                          <td>尺寸</td>
                          <td>建議售價</td>
                          <td>*售價</td>
                          <td>*銷售數量</td>
                          <td>*庫存量</td>
                          <td>*安全庫存</td>
                          <td></td>
                        </tr>
                        {i.rels?.map((j, jIndex) => {
                          return (
                            <tr
                              className={style.bg_gray}
                              key={`${i.groupModelProductId}_${j.id}_${index}`}
                            >
                              <td>{j.productcode}</td>
                              <td>{j.productname}</td>
                              <td>{j.color}</td>
                              <td>{j.size || '--'}</td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <span style={{ wordBreak: 'keep-all' }}>
                                    NT$
                                  </span>
                                  {values.products[index]?.rels?.[
                                    jIndex
                                  ]?.websiteprice?.toLocaleString() ?? '0'}
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-1">
                                  <span style={{ wordBreak: 'keep-all' }}>
                                    NT$
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name={`products[${index}].rels[${jIndex}].sellprice`}
                                    onChange={e => {
                                      setFieldValue(
                                        `products[${index}].rels[${jIndex}].sellprice`,
                                        Number(e.target.value).toString(),
                                      );
                                    }}
                                    onBlur={e => {
                                      setFieldValue(
                                        `products[${index}].rels[${jIndex}].sellprice`,
                                        Number(e.target.value),
                                      );
                                    }}
                                    value={
                                      values.products[index]?.rels?.[jIndex]
                                        ?.sellprice
                                    }
                                  />
                                </div>
                                <FormText color="danger" className="d-block">
                                  {
                                    errors.products?.[index]?.rels?.[jIndex]
                                      ?.sellprice
                                  }
                                </FormText>
                              </td>
                              <td>
                                {/*銷售數量*/}
                                <Input
                                  type="number"
                                  name={`products[${index}].rels[${jIndex}].sellquantity`}
                                  onChange={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].sellquantity`,
                                      Number(e.target.value).toString(),
                                    );
                                  }}
                                  onBlur={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].sellquantity`,
                                      Number(e.target.value),
                                    );
                                  }}
                                  value={
                                    values.products[index]?.rels?.[jIndex]
                                      ?.sellquantity
                                  }
                                />
                                <FormText color="danger" className="d-block">
                                  {
                                    errors.products?.[index]?.rels?.[jIndex]
                                      ?.sellquantity
                                  }
                                </FormText>
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name={`products[${index}].rels[${jIndex}].stock`}
                                  onChange={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].stock`,
                                      Number(e.target.value).toString(),
                                    );
                                  }}
                                  onBlur={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].stock`,
                                      Number(e.target.value),
                                    );
                                  }}
                                  value={
                                    values.products[index]?.rels?.[jIndex]
                                      ?.stock
                                  }
                                />
                                <FormText color="danger" className="d-block">
                                  {
                                    errors.products?.[index]?.rels?.[jIndex]
                                      ?.stock
                                  }
                                </FormText>
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  name={`products[${index}].rels[${jIndex}].lowestStock`}
                                  onChange={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].lowestStock`,
                                      Number(e.target.value).toString(),
                                    );
                                  }}
                                  onBlur={e => {
                                    setFieldValue(
                                      `products[${index}].rels[${jIndex}].lowestStock`,
                                      Number(e.target.value),
                                    );
                                  }}
                                  value={
                                    values.products[index]?.rels?.[jIndex]
                                      ?.lowestStock
                                  }
                                />
                                <FormText color="danger" className="d-block">
                                  {
                                    errors.products?.[index]?.rels?.[jIndex]
                                      ?.lowestStock
                                  }
                                </FormText>
                              </td>
                              <td></td>
                              <td className="text-center">
                                <Button
                                  color="danger"
                                  outline
                                  disabled={!checkCanDelete}
                                  onClick={() =>
                                    handleDeleteRelProduct(index, jIndex)
                                  }
                                >
                                  刪除
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ))}
                </div>
                <div
                  className={style.formGroup}
                  style={{ alignItems: 'flex-start', marginBottom: '2rem' }}
                >
                  <label className="required" style={{ width: '140px' }}>
                    品牌類別
                    {errors.brandCateId && (
                      <span className="form-error"> 必填！</span>
                    )}
                  </label>
                  <div className="form-item" style={{ minWidth: '120px' }}>
                    <Select
                      isLoading={modelBrandOptionsInfoLoading}
                      isDisabled={modelBrandOptionsInfoLoading}
                      options={
                        modelBrandOptionsInfo !== undefined && [
                          emptyOption('請選擇品牌類別', 0),
                          ...modelBrandOptionsInfo,
                        ]
                      }
                      id="brandCateId"
                      name="brandCateId"
                      defaultValue={emptyOption('請選擇品牌類別', 0)}
                      onChange={({ label, value }) => {
                        if (value === 0) {
                          setFieldValue('brandCateName', '請選擇品牌類別');
                        } else {
                          setFieldValue('brandCateName', label);
                        }
                        setFieldValue('brandCateId', value);
                      }}
                      value={{
                        label: values.brandCateName,
                        value: values.brandCateId,
                      }}
                      styles={{
                        control: baseStyles => ({
                          ...baseStyles,
                          minWidth: '120px',
                        }),
                      }}
                    />
                  </div>
                </div>
                <div
                  className={style.formGroup}
                  style={{ alignItems: 'flex-start', marginBottom: '2rem' }}
                >
                  <label className="required" style={{ width: '140px' }}>
                    商品類別
                  </label>
                  <div>
                    {errors?.categories &&
                      typeof errors.categories === 'string' && (
                        <div className="form-error">{errors.categories}</div>
                      )}
                    {values?.categories?.length > 0 &&
                      values.categories.map((category, i) => (
                        <ProductCategorySelection
                          key={i}
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          index={i}
                        />
                      ))}

                    <Button
                      color="primary"
                      outline
                      onClick={() => {
                        const conditions = [
                          ...values.categories,
                          {
                            cat1: { label: '請選擇', value: -1 },
                            cat2: { label: '請選擇', value: -1 },
                            id: values.categories.length + 1,
                          },
                        ];
                        setFieldValue('categories', conditions);
                      }}
                    >
                      新增商品類別
                    </Button>
                  </div>
                </div>
                <FormGroup row>
                  <LabelC text="特色說明" required />
                  <Col>
                    <FormGroup>
                      中
                      <Input
                        type="textarea"
                        rows={10}
                        name="features.content"
                        onChange={e => {
                          setFieldValue('features.content', e.target.value);
                        }}
                        value={values.features?.content}
                      />
                    </FormGroup>
                    <div className="form-error">
                      {errors?.features?.content}
                    </div>
                    <FormGroup>
                      英
                      <Input
                        type="textarea"
                        rows={10}
                        name="features.content_en"
                        onChange={e => {
                          setFieldValue('features.content_en', e.target.value);
                        }}
                        value={values.features?.content_en}
                      />
                      <div className="form-error">
                        {errors?.features?.content_en}
                      </div>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="可銷售區間" required />
                  <Col>
                    <FormGroup>
                      <Label>銷售開始時間</Label>
                      <div className="form-item flex-wrap d-flex mb-3">
                        <div className="w200">
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd"
                            selected={moment(
                              values?.availableTimeStart || new Date(),
                            )
                              .startOf('day')
                              .toDate()}
                            name="availableTimeStart"
                            onChange={date => {
                              setFieldValue(
                                'availableTimeStart',
                                moment(date).startOf('day').toDate(),
                              );
                            }}
                            selectsStart
                          />
                        </div>
                        <Input
                          type="select"
                          className="w80 ms-1"
                          name="availableTimeStartHour"
                          onChange={handleChange}
                          value={values.availableTimeStartHour}
                        >
                          {hourArr().map(i => (
                            <option key={i.value} value={i.value}>
                              {i.label}
                            </option>
                          ))}
                        </Input>
                        <span className="px-2 pt-2">:</span>
                        <Input
                          type="select"
                          className="w80"
                          name="availableTimeStartMin"
                          onChange={handleChange}
                          value={values.availableTimeStartMin}
                        >
                          {minArr().map(i => (
                            <option key={i.value} value={i.value}>
                              {i.label}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
                        <div>
                          <SearchSelect
                            options={[
                              { label: '自訂時間', value: 1 },
                              { label: '無期限', value: 2 },
                            ]}
                            emptyVal={0}
                            emptyLabel="請選擇"
                            name="availableEndTimeType"
                            setFieldValue={setFieldValue}
                            values={values}
                          />
                          <div className="form-error">
                            {errors?.availableEndTimeType}
                          </div>
                        </div>
                        {values.availableEndTimeType === 2 && (
                          <span>結束日期預計為 2099/12/12 12:12:12</span>
                        )}
                      </div>
                      {errors?.availableTimeStart && (
                        <div className="form-error">
                          {errors?.availableTimeStart}
                        </div>
                      )}
                      {values.availableEndTimeType === 1 && (
                        <div className="form-item flex-wrap d-flex mb-3">
                          <div className="w200">
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              selected={moment(
                                values.availableTimeEnd || undefined,
                              )
                                .startOf('day')
                                .valueOf()}
                              name="availableTimeEnd"
                              onChange={date => {
                                setFieldValue(
                                  'availableTimeEnd',
                                  moment(date).startOf('day').toDate(),
                                );
                              }}
                              selectsEnd
                              minDate={moment(
                                values.availableTimeStart || undefined,
                              )
                                .startOf('day')
                                .valueOf()}
                            />
                          </div>
                          <Input
                            type="select"
                            className="w80 ms-1"
                            name="availableTimeEndHour"
                            onChange={handleChange}
                            value={values.availableTimeEndHour}
                          >
                            {hourArr().map(i => (
                              <option key={i.value} value={i.value}>
                                {i.label}
                              </option>
                            ))}
                          </Input>
                          <span className="px-2 pt-2">:</span>
                          <Input
                            type="select"
                            className="w80"
                            name="availableTimeEndMin"
                            onChange={handleChange}
                            value={values.availableTimeEndMin}
                          >
                            {minArr().map(i => (
                              <option key={i.value} value={i.value}>
                                {i.label}
                              </option>
                            ))}
                          </Input>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="是否可退貨" />
                  <Col className="d-flex gap-2">
                    <Label className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        className={style.maintain_list_checkbox}
                        name="是"
                        value={true}
                        onChange={() => {
                          setFieldValue('isReturnable', true);
                        }}
                        checked={values.isReturnable}
                      />
                      是
                    </Label>
                    <Label className="d-flex align-items-center gap-2">
                      <input
                        type="radio"
                        className={style.maintain_list_checkbox}
                        name="否"
                        value={false}
                        onChange={() => {
                          setFieldValue('isReturnable', false);
                        }}
                        checked={!values.isReturnable}
                      />
                      否
                    </Label>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <LabelC text="上架" />
                  <Col>
                    <Label className="d-flex align-items-center gap-2">
                      <input
                        type="checkbox"
                        className={style.maintain_list_checkbox}
                        name="isSell"
                        onChange={handleChange}
                        checked={values.isSell}
                      />
                      官網商品
                    </Label>
                  </Col>
                </FormGroup>
              </Form>
              <p className={style.fs_14}>
                異動時間：
                {groupModelInfo.setting?.updateDate}{' '}
                {groupModelInfo.setting?.updateTime}{' '}
                {groupModelInfo.setting?.updater}
              </p>
              <hr />
              <div className="text-center">
                <Button
                  color="primary"
                  disabled={
                    loading || setLoading || Object.keys(errors).length > 0
                  }
                  className={style.modal_button_submit}
                  onClick={handleSubmit}
                >
                  {(loading || setLoading) && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </div>
            </>
          )}
        </Card>
      )}
    </TabPane>
  );
};

export default memo(TabSetting);
