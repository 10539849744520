import moment from 'moment';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  BRAND_OPTIONS_FAIL,
  BRAND_OPTIONS_REQUEST,
  BRAND_OPTIONS_SUCCESS,
  COLOR_OPTIONS_FAIL,
  COLOR_OPTIONS_REQUEST,
  COLOR_OPTIONS_SUCCESS,
  EXPORT_MAINTAINLIST_WORD_FAIL,
  EXPORT_MAINTAINLIST_WORD_REQUEST,
  EXPORT_MAINTAINLIST_WORD_SUCCESS,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS,
  GET_RELATED_REGISTER_LIST_FAIL,
  GET_RELATED_REGISTER_LIST_REQUEST,
  GET_RELATED_REGISTER_LIST_SUCCESS,
  MAINTAIN_ADD_FAIL,
  MAINTAIN_ADD_REQUEST,
  MAINTAIN_ADD_SUCCESS,
  MAINTAIN_BATCH_UPDATE_FAIL,
  MAINTAIN_BATCH_UPDATE_REQUEST,
  MAINTAIN_BATCH_UPDATE_SUCCESS,
  MAINTAIN_DEALING_UPDATE_FAIL,
  MAINTAIN_DEALING_UPDATE_REQUEST,
  MAINTAIN_DEALING_UPDATE_SUCCESS,
  MAINTAIN_DETAILS_FAIL,
  MAINTAIN_DETAILS_REQUEST,
  MAINTAIN_DETAILS_SUCCESS,
  MAINTAIN_DETAILS_UPDATE_FAIL,
  MAINTAIN_DETAILS_UPDATE_REQUEST,
  MAINTAIN_DETAILS_UPDATE_SUCCESS,
  MAINTAIN_EXPORT_FAIL,
  MAINTAIN_EXPORT_REQUEST,
  MAINTAIN_EXPORT_SUCCESS,
  MAINTAIN_GET_INFO_FAIL,
  MAINTAIN_GET_INFO_REQUEST,
  MAINTAIN_GET_INFO_SUCCESS,
  MAINTAIN_HANDLERS_FAIL,
  MAINTAIN_HANDLERS_REQUEST,
  MAINTAIN_HANDLERS_SUCCESS,
  MAINTAIN_IMPORT_FAIL,
  MAINTAIN_IMPORT_REQUEST,
  MAINTAIN_IMPORT_SUCCESS,
  MAINTAIN_LIST_FAIL,
  MAINTAIN_LIST_REQUEST,
  MAINTAIN_LIST_SUCCESS,
  MAINTAIN_OPTIONS_FAIL,
  MAINTAIN_OPTIONS_REQUEST,
  MAINTAIN_OPTIONS_SUCCESS,
  MAINTAIN_STATUS_OPTIONS_FAIL,
  MAINTAIN_STATUS_OPTIONS_REQUEST,
  MAINTAIN_STATUS_OPTIONS_SUCCESS,
  MODEL_OPTIONS_FAIL,
  MODEL_OPTIONS_REQUEST,
  MODEL_OPTIONS_SUCCESS,
  REPAIR_SETTING_GET_FAIL,
  REPAIR_SETTING_GET_REQUEST,
  REPAIR_SETTING_GET_SUCCESS,
  REPAIR_SETTING_POST_FAIL,
  REPAIR_SETTING_POST_REQUEST,
  REPAIR_SETTING_POST_SUCCESS,
  UPDATE_CREDITS_FAIL,
  UPDATE_CREDITS_REQUEST,
  UPDATE_CREDITS_SUCCESS,
  UPDATE_EMPLOYEE_ID_FAIL,
  UPDATE_EMPLOYEE_ID_REQUEST,
  UPDATE_EMPLOYEE_ID_SUCCESS,
  UPDATE_REPAIR_REGISTER_FAIL,
  UPDATE_REPAIR_REGISTER_REQUEST,
  UPDATE_REPAIR_REGISTER_SUCCESS,
  UPDATE_SEND_BACK_DATE_FAIL,
  UPDATE_SEND_BACK_DATE_SUCCESS,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS,
  UPDUATE_SEND_BACK_DATE_REQUEST,
} from '../../constants/data/repairConstants';
import {
  GET_WARRANTY_NOTIFY_LIST_FAIL, GET_WARRANTY_NOTIFY_LIST_REQUEST, GET_WARRANTY_NOTIFY_LIST_SUCCESS,
  UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
  UPDATE_WARRANTY_NOTIFY_LIST_REQUEST, UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS
} from '../../constants/data/warrantyConstants';

export const getRepairSetting = () => {
  const url = '/Backend/RepairSetting/getRepairInfo';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: REPAIR_SETTING_GET_FAIL,
      method: 'get',
      requestConstant: REPAIR_SETTING_GET_REQUEST,
      successConstant: REPAIR_SETTING_GET_SUCCESS,
      url,
    });
  };
};

//post repair setting
export const postRepairSetting = object => {
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: REPAIR_SETTING_POST_FAIL,
      method: 'post',
      requestConstant: REPAIR_SETTING_POST_REQUEST,
      successConstant: REPAIR_SETTING_POST_SUCCESS,
      url: '/Backend/RepairSetting/updateRepairInfo',
    });
  };
};

//get maintain list
export const getMaintainList = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/RepairRegister/list' });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MAINTAIN_LIST_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_LIST_REQUEST,
      successConstant: MAINTAIN_LIST_SUCCESS,
      url: '/Backend/RepairRegister/list',
    });
  };
};

//get maintain status options
export const getMaintainStatusOptions = () => {
  const url = '/Backend/RepairRegister/statusOptions';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MAINTAIN_STATUS_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MAINTAIN_STATUS_OPTIONS_REQUEST,
      successConstant: MAINTAIN_STATUS_OPTIONS_SUCCESS,
      url,
    });
  };
};

//get maintain details
export const getMaintainDetails = id => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MAINTAIN_DETAILS_FAIL,
      method: 'get',
      requestConstant: MAINTAIN_DETAILS_REQUEST,
      successConstant: MAINTAIN_DETAILS_SUCCESS,
      url: `/Backend/RepairRegister/GetRepairDetail/${id}`,
    });
  };
};

//get repair options
export const getRepairOptions = () => {
  const url = '/Backend/RepairRegister/GetRepairOptions';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MAINTAIN_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MAINTAIN_OPTIONS_REQUEST,
      successConstant: MAINTAIN_OPTIONS_SUCCESS,
      url,
    });
  };
};

//update maintain details
export const updateMaintainDetails = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/RepairRegister/RepairUpdate',
  });

  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MAINTAIN_DETAILS_UPDATE_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_DETAILS_UPDATE_REQUEST,
      successConstant: MAINTAIN_DETAILS_UPDATE_SUCCESS,
      url: '/Backend/RepairRegister/RepairUpdate',
    });
  };
};

//get repair handler options
export const getRepairHandlerOptions = () => {
  const url = '/Backend/RepairRegister/handlerOptions';
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MAINTAIN_HANDLERS_FAIL,
      method: 'get',
      requestConstant: MAINTAIN_HANDLERS_REQUEST,
      successConstant: MAINTAIN_HANDLERS_SUCCESS,
      url,
    });
  };
};

//update repair dealing
export const updateRepairDealing = data => {
  if (data.sendBackDate) {
    data.sendBackDate = moment(data.sendBackDate).format('YYYY-MM-DD');
  }
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: MAINTAIN_DEALING_UPDATE_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_DEALING_UPDATE_REQUEST,
      successConstant: MAINTAIN_DEALING_UPDATE_SUCCESS,
      url: '/Backend/RepairRegister/RepairRegisterReview',
    });
  };
};

//add repair
export const addRepairRequest = (source, object) => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MAINTAIN_ADD_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_ADD_REQUEST,
      successConstant: MAINTAIN_ADD_SUCCESS,
      url: `/Backend/RepairRegister/Create/${source}`,
    });
  };
};

//batch update repair
export const batchUpdateRepairRequest = object => {
  const url = '/Backend/RepairRegister/BatchUpdate';
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MAINTAIN_BATCH_UPDATE_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_BATCH_UPDATE_REQUEST,
      successConstant: MAINTAIN_BATCH_UPDATE_SUCCESS,
      url,
    });
  };
};

//repair get info by product number
export const getRepairInfo = productNumber => {
  const url = `/Website/WarrantyRegister/getWarrantyRegisterDefaultInfo/${productNumber}/1`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MAINTAIN_GET_INFO_FAIL,
      method: 'get',
      requestConstant: MAINTAIN_GET_INFO_REQUEST,
      successConstant: MAINTAIN_GET_INFO_SUCCESS,
      url,
    });
  };
};

//repair export
export const exportRepairList = object => {
  const url = '/Backend/RepairRegister/Export';
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: MAINTAIN_EXPORT_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_EXPORT_REQUEST,
      successConstant: MAINTAIN_EXPORT_SUCCESS,
      url,
    });
  };
};

//repair import
export const importRepairList = formData => {
  const url = '/Backend/RepairRegister/RepairImport';
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: MAINTAIN_IMPORT_FAIL,
      method: 'post',
      requestConstant: MAINTAIN_IMPORT_REQUEST,
      successConstant: MAINTAIN_IMPORT_SUCCESS,
      url,
    });
  };
};

//get repair brand option
export const getRepairBrandOption = (isWarranty, isRepair) => {
  const url = `/Website/WarrantyRegister/brandoptions/${isWarranty}/${isRepair}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_OPTIONS_FAIL,
      method: 'get',
      requestConstant: BRAND_OPTIONS_REQUEST,
      successConstant: BRAND_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getProductBrandOptions = (isWarranty, isRepair) => {
  const url = `/Website/WarrantyRegister/brandoptions/${isWarranty}/${isRepair}`;
  return request({
    method: 'get',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

//get repair model option
export const getRepairModelOption = (isWarranty, isRepair, brandId) => {
  const url = `/Website/WarrantyRegister/modeloptions/${isWarranty}/${isRepair}?brandId=${
    brandId || 0
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MODEL_OPTIONS_REQUEST,
      successConstant: MODEL_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const loadRepairModelOption = (isWarranty, isRepair, brandId) => {
  const url = `/Website/WarrantyRegister/modeloptions/${isWarranty}/${isRepair}?brandId=${
    brandId || 0
  }`;
  return request({
    method: 'get',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const loadModelOptions = keyword => {
  const url = `/Backend/Product/modelOptions?brandName=${keyword}`;
  return request({
    method: 'get',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

//get repair model option
export const getColorModelOption = (isWarranty, isRepair, model) => {
  const url = `/Website/WarrantyRegister/coloroptions/${isWarranty}/${isRepair}?modelId=${model}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: COLOR_OPTIONS_FAIL,
      method: 'get',
      requestConstant: COLOR_OPTIONS_REQUEST,
      successConstant: COLOR_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const deleteRepairDetail = async id => {
  return await request({
    method: 'get',
    url: `/Backend/RepairRegister/DeleteRepairDetail/${id}`,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const updateCredits = data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/RepairRegister/BatchUpdateIsCredit',
  });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_CREDITS_FAIL,
      method: 'post',
      requestConstant: UPDATE_CREDITS_REQUEST,
      successConstant: UPDATE_CREDITS_SUCCESS,
      url: '/Backend/RepairRegister/BatchUpdateIsCredit',
    });
  };
};

export const updateSendBackDate = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_SEND_BACK_DATE_FAIL,
      method: 'post',
      requestConstant: UPDUATE_SEND_BACK_DATE_REQUEST,
      successConstant: UPDATE_SEND_BACK_DATE_SUCCESS,
      url: '/Backend/RepairRegister/BatchUpdateSendBackDate',
    });
  };
};

export const updateRepairRegisterStock = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_SEND_BACK_DATE_FAIL,
      method: 'post',
      requestConstant: UPDUATE_SEND_BACK_DATE_REQUEST,
      successConstant: UPDATE_SEND_BACK_DATE_SUCCESS,
      url: '/Backend/RepairRegister/BatchUpdateRepairRegisterStock',
    });
  };
};

export const updateSendBackCompanyDate = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_SEND_BACK_DATE_FAIL,
      method: 'post',
      requestConstant: UPDUATE_SEND_BACK_DATE_REQUEST,
      successConstant: UPDATE_SEND_BACK_DATE_SUCCESS,
      url: '/Backend/RepairRegister/BatchUpdateBackToCompanyDate',
    });
  };
};

export const getRelatedRegisterList = warrantySerialNumber => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_RELATED_REGISTER_LIST_FAIL,
      method: 'get',
      requestConstant: GET_RELATED_REGISTER_LIST_REQUEST,
      successConstant: GET_RELATED_REGISTER_LIST_SUCCESS,
      url: `/Backend/RepairRegister/GetRelatedRegisterList/${warrantySerialNumber}`,
    });
  };
};

export const updateWarrantyProductSerialNumber = (data, id) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL,
      method: 'post',
      requestConstant: UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST,
      successConstant: UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS,
      url: `/Backend/RepairRegister/UpdateWarrantyProductSerailNum/${id}`,
    });
  };
};

export const updateRepairRegister = (data, memberId) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_REPAIR_REGISTER_FAIL,
      method: 'post',
      requestConstant: UPDATE_REPAIR_REGISTER_REQUEST,
      successConstant: UPDATE_REPAIR_REGISTER_SUCCESS,
      url: `/Backend/RepairRegister/RegisterBackend?memberId=${memberId}`,
    });
  };
};

export const getProductInfoViaWarrantySerialNumber = (
  code,
  type = 'warrantSerial',
) => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL,
      method: 'get',
      requestConstant: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST,
      successConstant: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS,
      url:
        type === 'warrantSerial'
          ? `/Website/WarrantyRegister/getWarrantyRegisterDefaultInfo/${code}`
          : `/Website/WarrantyRegister/getProductDefaultInfo/${code}`,
    });
  };
};

export const getMemberInfoViaPhoneNumber = (code, number) => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Website/RepairRegister/GetMemberByPhone?countryCode=${code}&phone=${number}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL,
      method: 'get',
      requestConstant: GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST,
      successConstant: GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS,
      url: `/Website/RepairRegister/GetMemberByPhone?countryCode=${code}&phone=${number}`,
    });
  };
};

export const exportMaintainListWord = ids => {
  return async dispatch => {
    await useRequest({
      data: ids,
      dispatch,
      failConstant: EXPORT_MAINTAINLIST_WORD_FAIL,
      method: 'post',
      requestConstant: EXPORT_MAINTAINLIST_WORD_REQUEST,
      successConstant: EXPORT_MAINTAINLIST_WORD_SUCCESS,
      url: '/Backend/RepairRegister/ExportWord',
    });
  };
};

export const updateEmployeeId = (type, data) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_EMPLOYEE_ID_FAIL,
      method: 'post',
      requestConstant: UPDATE_EMPLOYEE_ID_REQUEST,
      successConstant: UPDATE_EMPLOYEE_ID_SUCCESS,
      url: `/Backend/RepairRegister/CreateEmployeeIDRecord/${type}`,
    });
  };
};

export const sendRepairRegisterSMS = (
  data = {
    content: 'string',
    countryCode: 'string',
    phoneNumber: 'string',
    repairId: 0,
  },
) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/RepairRegister/SendRepairRegisterSMS',
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const getSendRepairRegisterRecord = repairId => {
  return request({
    method: 'get',
    url: `/Backend/RepairRegister/GetSendRepairRegisterRecord/${repairId}`,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};



export const createRepairRegisterNotify = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:19:52.506Z',
  'startTime': '2024-09-18T17:19:52.506Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/RepairRegister/CreateRepairRegisterNotify',
    });
  };
};


export const updateRepairRegisterNotify = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:20:27.701Z',
  'id': 0,
  'startTime': '2024-09-18T17:20:27.701Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/RepairRegister/UpdateRepairRegisterNotify',
    });
  };
};

export const deleteRepairRegisterNotify = (data={
  'content': 'string',
  'content_en': 'string',
  'endTime': '2024-09-18T17:20:27.701Z',
  'id': 0,
  'startTime': '2024-09-18T17:20:27.701Z',
  'state': 'string',
  'title': 'string',
  'title_en': 'string'
}) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      params:data,
      requestConstant: UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/RepairRegister/DeleteRepairRegisterNotify',
    });
  };
};

export const getRepairRegisterNotifyList = () => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_WARRANTY_NOTIFY_LIST_FAIL,
      method: 'post',
      requestConstant: GET_WARRANTY_NOTIFY_LIST_REQUEST,
      successConstant: GET_WARRANTY_NOTIFY_LIST_SUCCESS,
      url: '/Backend/RepairRegister/RepairRegisterNotifyList',
    });
  };
};
