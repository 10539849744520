import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { memo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import { updateMemberLevel } from '../../redux/actions/data/memberActions';
import { UPDATE_MEMBER_LEVEL_IDLE } from '../../redux/constants/data/memberConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const EditMemberLevelModal = ({
  isOpen,
  toggle,
  onRefresh,
  info,
  lastName,
  firstName,
  phone,
  id,
}) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    state => state.updateMemberLevel,
  );
  const { memberLevelOptions } = useSelector(
    state => state.getMemberLevelOptions,
  );

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      accumulateAmount: info?.accumulateAmount || 0,
      accumulateDateEnd: info?.accumulateDateEnd,
      accumulateDateStart: info?.accumulateDateStart,
      action: '新增',
      initializeExpired: false,
      memberDateEnd: info?.memberDateEnd,
      memberDateStart: info?.memberDateStart,
      memberLevel: memberLevelOptions?.find(
        level => Number(level.value) === info?.memberLevelSettingId,
      ) || { label: '請選擇', value: '' },
      name: `${lastName || '--'} ${firstName || '--'}`,
      phone: phone,
      point: 0,
    },
    onSubmit: () => {
      const body = {
        accumulateAmount: Number(values.accumulateAmount),
        accumulateDateEnd: values.accumulateDateEnd
          ? moment(values.accumulateDateEnd)
              .endOf('month')
              .format('YYYY/MM/DD HH:mm:ss')
          : null,
        accumulateDateStart: values.accumulateDateStart
          ? moment(values.accumulateDateStart).format('YYYY/MM/DD 00:00:00')
          : null,
        firstName: firstName,
        isReset: values.initializeExpired,
        lastName: lastName,
        memberDateEnd: values.memberDateStart
          ? moment(values.memberDateEnd)
              .endOf('month')
              .format('YYYY/MM/DD HH:mm:ss')
          : null,
        memberDateStart: values.memberDateStart
          ? moment(values.memberDateStart).format('YYYY/MM/DD 00:00:00')
          : null,
        memberId: id,
        memberLevelSettingId: Number(values.memberLevel.value),
        memberPhone: phone,
        pointChangeInfo: {
          point: values.point,
          remark: '',
        },
      };

      if (values.action === '減少') {
        body.pointChangeInfo.point = ~body.pointChangeInfo.point + 1;
      }

      if (body.isReset) {
        body.memberDateStart = null;
        body.memberDateEnd = null;
        body.accumulateDateStart = null;
        body.accumulateDateEnd = null;
      }

      if (Number(body.memberLevelSettingId) === 1) {
        body.memberDateEnd = null;
        body.accumulateDateEnd = null;
      }

      dispatch(updateMemberLevel(body));
    },
    validationSchema: Yup.object().shape({
      accumulateAmount: Yup.number().typeError('請填入數字').required('必填'),
      accumulateDateEnd: Yup.date()
        .nullable()
        .when(['initializeExpired', 'memberLevel'], {
          is: (initializeExpired, memberLevel) =>
            !initializeExpired && Number(memberLevel?.value) !== 1,
          then: Yup.date().nullable().required('必填'),
        }),
      accumulateDateStart: Yup.date()
        .nullable()
        .when('initializeExpired', {
          is: val => !val,
          then: Yup.date().nullable().required('必填'),
        }),
      initializeExpired: Yup.boolean(),
      memberDateEnd: Yup.date()
        .nullable()
        .when(['initializeExpired', 'memberLevel'], {
          is: (initializeExpired, memberLevel) => {
            return !initializeExpired && Number(memberLevel?.value) !== 1;
          },
          then: Yup.date().nullable().required('必填'),
        }),
      memberDateStart: Yup.date()
        .nullable()
        .when('initializeExpired', {
          is: val => !val,
          then: Yup.date().nullable().required('必填'),
        }),
      memberLevel: Yup.object().shape({
        value: Yup.number().typeError('必填').required('必填'),
      }),
    }),
  });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      onRefresh && onRefresh();
      toggle();
      dispatch({ type: UPDATE_MEMBER_LEVEL_IDLE });
    }

    if (error) {
      MessageUtil.alertWanring(error);
      dispatch({ type: UPDATE_MEMBER_LEVEL_IDLE });
    }
  }, [success, error]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} zIndex={2000} size="lg" centered>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        更改會員等級
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <Alert color="secondary" style={{ marginBottom: '1rem' }}>
          <li>等級異動後當天即可生效，會員升等禮則隔日生效。</li>
          <li>
            勾選「效期重新計算」，會員等級效期、累積消費效期將自動隱藏，會籍、累積消費效期開始日設定為異動當天，會籍、累積消費效期結束日則依照會員機制設定重新計算。
          </li>
        </Alert>
        <div style={{ padding: '1.5rem' }}>
          <div className={style.formGroup}>
            <label style={{ width: '120px' }}>會員姓名</label>
            <span>{values.name}</span>
          </div>
          <div className={style.formGroup}>
            <label style={{ width: '120px' }}>手機號碼</label>
            <span>{`0${values.phone}`}</span>
          </div>
          <div className={style.formGroup}>
            <label style={{ width: '120px' }} className="required">
              更改會員等級
            </label>
            <Select
              className={style['form-select']}
              options={memberLevelOptions}
              onChange={option => {
                setFieldValue('memberLevel', option);
              }}
              value={values.memberLevel}
            />
            {errors?.memberLevel && touched?.memberLevel && (
              <span className="text-danger ml-2">
                {errors.memberLevel.value}
              </span>
            )}
          </div>
          <div className={style.formGroup}>
            <label style={{ width: '120px' }} />
            <input
              type="checkbox"
              checked={values.initializeExpired === true}
              onChange={event => {
                event.target.checked
                  ? setFieldValue('initializeExpired', true)
                  : setFieldValue('initializeExpired', false);
              }}
              style={{ height: '18px', width: '18px' }}
            />
            <span className="ml-2">重新計算效期</span>
          </div>
          {!values.initializeExpired && (
            <React.Fragment>
              <div className={style.formGroup}>
                <label style={{ width: '120px' }} className="required">
                  會員等級效期
                </label>
                <div className={style['form-select']}>
                  <DatePicker
                    className="form-control"
                    dateFormat={'yyyy/MM/dd'}
                    selected={
                      values.memberDateStart
                        ? moment(values.memberDateStart).toDate()
                        : ''
                    }
                    onChange={date => {
                      setFieldValue('memberDateStart', date);
                    }}

                    // minDate={moment().toDate()}
                  />
                </div>
                {errors?.memberDateStart && touched?.memberDateStart && (
                  <span className="text-danger ml-2">
                    {errors.memberDateStart}
                  </span>
                )}
                <span style={{ margin: '0.5rem' }}>~</span>
                {Number(values.memberLevel.value) === 1 ? (
                  <div>永久效期</div>
                ) : (
                  <React.Fragment>
                    <div className={style['form-select']}>
                      <DatePicker
                        className="form-control"
                        dateFormat={'yyyy/MM'}
                        showMonthYearPicker
                        selected={
                          values.memberDateEnd
                            ? moment(values.memberDateEnd).toDate()
                            : ''
                        }
                        onChange={date => {
                          setFieldValue('memberDateEnd', date);
                        }}
                        minDate={
                          values.memberDateStart
                            ? moment(values.memberDateStart).toDate()
                            : ''
                        }
                      />
                    </div>
                    {errors?.memberDateEnd && touched?.memberDateEnd && (
                      <span className="text-danger ml-2">
                        {errors.memberDateEnd}
                      </span>
                    )}
                    <span className="ml-2">月底</span>
                  </React.Fragment>
                )}
              </div>
              <div className={style.formGroup}>
                <label style={{ width: '120px' }} className="required">
                  累積消費效期
                </label>
                <div className={style['form-select']}>
                  <DatePicker
                    className="form-control"
                    dateFormat={'yyyy/MM/dd'}
                    selected={
                      values.accumulateDateStart
                        ? moment(values.accumulateDateStart).toDate()
                        : ''
                    }
                    onChange={date => {
                      setFieldValue('accumulateDateStart', date);
                    }}

                    // minDate={values.memberDateStart ? moment(values.memberDateStart).toDate() : ''}
                  />
                </div>
                {errors?.accumulateDateStart &&
                  touched?.accumulateDateStart && (
                    <span className="text-danger ml-2">
                      {errors.accumulateDateStart}
                    </span>
                  )}
                <span style={{ margin: '0.5rem' }}>~</span>
                {Number(values.memberLevel.value) === 1 ? (
                  <div>永久效期</div>
                ) : (
                  <React.Fragment>
                    <div className={style['form-select']}>
                      <DatePicker
                        className="form-control"
                        dateFormat={'yyyy/MM'}
                        showMonthYearPicker
                        selected={
                          values.accumulateDateEnd
                            ? moment(values.accumulateDateEnd).toDate()
                            : ''
                        }
                        onChange={date => {
                          setFieldValue('accumulateDateEnd', date);
                        }}
                        maxDate={
                          values.memberDateEnd
                            ? moment(values.memberDateEnd)
                                .add(1, 'month')
                                .toDate()
                            : ''
                        }
                      />
                    </div>
                    {errors?.accumulateDateEnd &&
                      touched?.accumulateDateEnd && (
                        <span className="text-danger ml-2">
                          {errors?.accumulateDateEnd}
                        </span>
                      )}
                    <span className="ml-2">月底</span>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          <div className={style.formGroup}>
            <label className="required" style={{ width: '120px' }}>
              等級內消費金額
            </label>
            <span style={{ width: '40px' }}>NT$</span>
            <Input
              className={style['form-input']}
              value={values.accumulateAmount}
              onChange={event => {
                setFieldValue('accumulateAmount', event.target.value);
              }}
              invalid={errors?.accumulateAmount && touched?.accumulateAmount}
            />
            {errors?.accumulateAmount && touched?.accumulateAmount && (
              <span className="text-danger ml-2">
                {errors?.accumulateAmount}
              </span>
            )}
          </div>
          <div className={style.formGroup}>
            <label className="required" style={{ width: '120px' }}>
              點數異動
            </label>
            <Input
              type="select"
              className={style['form-select-short']}
              style={{ margin: 0 }}
              value={values.action}
              id="action"
              onChange={e => {
                setFieldValue('action', e.target.value);
              }}
            >
              <option value="新增">新增</option>
              <option value="減少">減少</option>
            </Input>
            <Input
              className={style['form-input']}
              value={values.point}
              onChange={event => {
                setFieldValue('point', event.target.value);
              }}
            />
            <span className="ml-2">點</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(EditMemberLevelModal);
