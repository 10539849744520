import * as XLSX from 'xlsx';

export const ExcelToJSON = function () {
  this.parseExcel = function (file) {
    return new Promise((resolve , reject)=>{
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary',
        });
        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
          );
          const json_object = JSON.parse(JSON.stringify(XL_row_object));
          resolve(json_object);
        });
      };
      reader.onerror = function (ex) {
        reject();
      };
      reader.readAsBinaryString(file);
    })
  };
};
