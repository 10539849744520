import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Input } from 'reactstrap';

import {
  getAreaList,
  getCityList,
} from '../../redux/actions/data/locationActions';
import { getMemberInfoViaPhoneNumber } from '../../redux/actions/data/repairActions';
import { GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE } from '../../redux/constants/data/repairConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const PersonalInfo = ({
  setFieldValue,
  type,
  errors,
  values,
  touched,
  isNonmember,
  isStore,
}) => {
  const dispatch = useDispatch();
  const { countryList } = useSelector(state => state.getCountryList);
  const { cityList } = useSelector(state => state.getCityList);
  const { areaList } = useSelector(state => state.getAreaList);

  const { success, error, info } = useSelector(
    state => state.getMemberInfoViaPhoneNumber,
  );

  const countryOptions = countryList?.map(country => {
    return { label: country.name, value: country.id };
  });
  const cityListOptions = cityList?.map(city => {
    return { label: city.name, value: city.id };
  });
  const areaListOptions = areaList?.map(area => {
    return { label: area.name, value: area.id };
  });

  useEffect(() => {
    dispatch(getCityList(values?.country?.value));
  }, [values.country]);

  useEffect(() => {
    dispatch(getAreaList(values?.city?.value));
  }, [values.city]);

  const getMemberInfo = number => {
    number && dispatch(getMemberInfoViaPhoneNumber('886', number));
  };

  const [currentType, setCurrentType] = useState('');

  useEffect(() => {
    if (success && info && type === currentType) {
      setFieldValue(`${type}.firstName_${type}`, info?.firstName);
      setFieldValue(`${type}.lastName_${type}`, info?.lastName);
      setFieldValue(`${type}.email_${type}`, info?.email);
      setFieldValue(
        `${type}.country`,
        countryOptions.find(country => country.value == info?.countryId) || {
          label: '請選擇',
          value: 0,
        },
      );
      setFieldValue(
        `${type}.city`,
        cityListOptions.find(city => city.value == info?.cityId) || {
          label: '請選擇',
          value: 0,
        },
      );
      setFieldValue(
        `${type}.town`,
        areaListOptions.find(area => area.value == info?.townId) || {
          label: '請選擇',
          value: 0,
        },
      );
      setFieldValue(`${type}.address_${type}`, info?.address);

      dispatch({ type: GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE });
    }
    if (success && !info) {
      MessageUtil.alertWanring('無會員資料');
      dispatch({ type: GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE });
    }
  }, [success, error]);

  return (
    <div style={{ width: '100%' }}>
      <div className={`mb-3 ${style.show_flex}`}>
        <label
          className={`w50 ${
            !isNonmember || type === 'sender'
              ? !isStore
                ? 'required'
                : ''
              : ''
          }`}
        >
          姓氏
        </label>
        <Input
          className={style['first-name-input']}
          onChange={event => {
            setFieldValue(`${type}.lastName_${type}`, event.target.value);
          }}
          value={
            type === 'sender'
              ? values.lastName_sender ?? ''
              : values.lastName_receiver ?? ''
          }
          invalid={
            type === 'sender'
              ? errors?.lastName_sender && touched?.lastName_sender
              : errors?.lastName_receiver && touched?.lastName_receiver
          }
        />
        {type === 'sender'
          ? errors?.lastName_sender &&
            touched?.lastName_sender && (
              <span className="text-danger ml-2">{errors.lastName_sender}</span>
            )
          : errors?.lastName_receiver &&
            touched?.lastName_receiver && (
              <span className="text-danger ml-2">
                {errors.lastName_receiver}
              </span>
            )}
        <div style={{ width: '50px' }} />
        <label
          className={`w50 ${
            !isNonmember || type === 'sender'
              ? !isStore
                ? 'required'
                : ''
              : ''
          }`}
        >
          名字
        </label>
        <Input
          className={style['last-name-input']}
          onChange={event => {
            setFieldValue(`${type}.firstName_${type}`, event.target.value);
          }}
          value={
            type === 'sender'
              ? values.firstName_sender ?? ''
              : values.firstName_receiver ?? ''
          }
          invalid={
            type === 'sender'
              ? errors?.firstName_sender && touched?.firstName_sender
              : errors?.firstName_receiver && touched?.firstName_receiver
          }
        />
        {type === 'sender'
          ? errors?.firstName_sender &&
            touched?.firstName_sender && (
              <span className="text-danger ml-2">
                {errors.firstName_sender}
              </span>
            )
          : errors?.firstName_receiver &&
            touched?.firstName_receiver && (
              <span className="text-danger ml-2">
                {errors.firstName_receiver}
              </span>
            )}
      </div>
      <div className={`mb-3 ${style.show_flex}`}>
        <label
          className={`w80 ${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          手機號碼
        </label>
        <Input
          className={style['large-input']}
          onChange={event => {
            setFieldValue(`${type}.phone_${type}`, event.target.value);
          }}
          onBlur={event => {
            getMemberInfo(event.target.value);
            setCurrentType(type);
          }}
          value={
            type === 'sender' ? values.phone_sender : values.phone_receiver
          }
          invalid={
            type === 'sender'
              ? errors?.phone_sender && touched?.phone_sender
              : errors?.phone_receiver && touched?.phone_receiver
          }
        />
        {type === 'sender'
          ? errors?.phone_sender &&
            touched?.phone_sender && (
              <span className="text-danger ml-2">{errors.phone_sender}</span>
            )
          : errors?.phone_receiver &&
            touched?.phone_receiver && (
              <span className="text-danger ml-2">{errors.phone_receiver}</span>
            )}
      </div>
      <div className={`mb-3 ${style.show_flex}`}>
        <label
          className={`w80 ${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          電子信箱
        </label>
        <Input
          className={style['large-input']}
          onChange={event => {
            setFieldValue(`${type}.email_${type}`, event.target.value);
          }}
          value={
            type === 'sender'
              ? values.email_sender ?? ''
              : values.email_receiver ?? ''
          }
          invalid={
            type === 'sender'
              ? errors?.email_sender && touched?.email_sender
              : errors?.email_receiver && touched?.email_receiver
          }
        />
        {type === 'sender'
          ? errors?.email_sender &&
            touched?.email_sender && (
              <span className="text-danger">{errors.email_sender}</span>
            )
          : errors?.email_receiver &&
            touched?.email_receiver && (
              <span className="text-danger">{errors.email_receiver}</span>
            )}
      </div>
      <div className={`mb-3 ${style.show_flex}`}>
        <label
          className={`w80 ${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          國家/地區
        </label>
        <Select
          className={style['form-select-short']}
          style={{ margin: 0 }}
          options={[{ label: '請選擇', value: 0 }, ...countryOptions]}
          defaultValue={{ label: '請選擇', value: 0 }}
          onChange={({ label, value }) => {
            setFieldValue(`${type}.country`, { label, value });
            setFieldValue(`${type}.city`, { label: '請選擇', value: 0 });
          }}
          value={values.country}
        />
        {errors?.country && touched?.country && (
          <span className="text-danger ml-2">{errors.country.label}</span>
        )}
        <div className="w50" />
        <label
          style={{ marginRight: '0.5rem' }}
          className={`${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          城市
        </label>
        <Select
          className={style['form-select-short']}
          style={{ margin: 0 }}
          options={[{ label: '請選擇', value: 0 }, ...cityListOptions]}
          defaultValue={{ label: '請選擇', value: 0 }}
          onChange={({ label, value }) => {
            setFieldValue(`${type}.city`, { label, value });
            setFieldValue(`${type}.town`, { label: '請選擇', value: 0 });
          }}
          value={values.city}
        />
        {errors?.city && touched?.city && (
          <span className="text-danger ml-2">{errors.city.label}</span>
        )}
        <div className="w100" />
        <label
          style={{ marginRight: '0.5rem' }}
          className={`${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          區域
        </label>
        <Select
          className={style['form-select-short']}
          style={{ margin: 0 }}
          options={[{ label: '請選擇', value: 0 }, ...areaListOptions]}
          defaultValue={{ label: '請選擇', value: 0 }}
          onChange={({ label, value }) => {
            setFieldValue(`${type}.town`, { label, value });
          }}
          value={values.town}
        />
        {errors?.town && touched?.town && (
          <span className="text-danger ml-2">{errors.town.label}</span>
        )}
      </div>
      <div className={`mb-3 ${style.show_flex}`}>
        <label
          className={`w80 ${!isNonmember ? (!isStore ? 'required' : '') : ''}`}
        >
          詳細地址
        </label>
        <Input
          className={style['large-input']}
          onChange={event => {
            setFieldValue(`${type}.address_${type}`, event.target.value);
          }}
          value={
            type === 'sender'
              ? values.address_sender ?? ''
              : values.address_receiver ?? ''
          }
          invalid={
            type === 'sender'
              ? errors?.address_sender && touched?.address_sender
              : errors?.address_receiver && touched?.address_receiver
          }
        />
        {type === 'sender'
          ? errors?.address_sender &&
            touched?.address_sender && (
              <span className="text-danger">{errors.address_sender}</span>
            )
          : errors?.address_receiver &&
            touched?.address_receiver && (
              <span className="text-danger">{errors.address_receiver}</span>
            )}
      </div>
    </div>
  );
};

export default memo(PersonalInfo);
