import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  deleteActivity,
  getActivityCateOptions,
  getActivityList,
  sortActivity,
} from 'redux/actions/data/discountCompaignActions';
import {
  DELETE_ACTIVITY_IDLE,
  SORT_ACTIVITY_IDLE,
} from 'redux/constants/data/discountCompaignContants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import useQuery from 'utils/useQuery';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const SortingModal = ({ isOpen, toggle, onCancel }) => {
  const [sortList, setSortList] = useState([]);
  const dispatch = useDispatch();

  const { activityList } = useSelector(state => state.getActivityList);

  useEffect(() => {
    dispatch(
        getActivityList({
          isShow: true,
          isforSort: true,
          page: 0,
          page_size: 0,
          statusList: [0, 1],
        }),
    );
  }, []);

  useEffect(() => {
    setSortList(activityList.datas);
  }, [activityList]);

  const handleOnDragEnd = result => {
    const items = Array.from(sortList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortList(items);
  };

  const handleUpdateSort = () => {
    const body = sortList.map((item, index) => ({
      id: item.id,
      sort: index + 1,
    }));
    dispatch(sortActivity(body));
    toggle();
  };

  return (
      <Modal
          zIndex={2000}
          centered
          size="lg"
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <ModalHeader
            toggle={() => {
              toggle();
              onCancel && onCancel();
            }}
            className={style.modal_head}
        >
          商城折扣活動排序
        </ModalHeader>
        <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                  <ul
                      className="drag_list"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                  >
                    {sortList?.map(
                        ({ id, name, startTime, endTime, status }, index) => (
                            <Draggable key={id} draggableId={`${id}`} index={index}>
                              {provided => (
                                  <li
                                      className={`drag_data ${style.formGroup}`}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                  >
                          <span
                              className="title"
                              style={{
                                backgroundColor: '#434343',
                                borderRadius: '8px 0 0 8px',
                                color: 'white',
                                padding: '1rem 2rem',
                                whiteSpace: 'nowrap',
                              }}
                          >
                            {index + 1}
                          </span>
                                    <span
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginLeft: '1rem',
                                          width: '100%',
                                        }}
                                    >
                            <div>
                              <div>{name}</div>
                              <small>{`${startTime} - ${endTime}`}</small>
                            </div>

                            <div>
                              <div
                                  style={{
                                    backgroundColor:
                                        status == 0
                                            ? '#0077FF'
                                            : status == 1
                                                ? '#1BC943'
                                                : 'transparent',
                                    borderRadius: '12px',
                                    color: 'white',
                                    fontSize: '14px',
                                    marginRight: '1rem',
                                    minWidth: '80px',
                                    padding: '0.1rem 0.5rem 0.1rem 0.5rem',
                                    textAlign: 'center',
                                  }}
                              >
                                {status == 0 ? '尚未開始' : '進行中'}
                              </div>
                            </div>
                          </span>
                                  </li>
                              )}
                            </Draggable>
                        ),
                    )}
                    {provided.placeholder}
                  </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
              color="light"
              className={style.modal_button_cancel}
              onClick={() => {
                toggle();
                onCancel && onCancel();
              }}
          >
            取消
          </Button>
          <Button
              color="primary"
              type="submit"
              className={style.modal_button_submit}
              onClick={handleUpdateSort}

              // disabled={sortLoading}
          >
            {/* {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
  );
};

const TableList = ({ datas, onDelete }) => {
  const { loading } = useSelector(state => state.getActivityList);
  const deleteActivityHandler = id => {
    MessageUtil.submitConfirm({
      doneFun: () => {
        onDelete && onDelete(id);
      },
      title: '確定刪除?',
    });
  };

  return (
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>活動序號</th>
            <th>活動名稱</th>
            <th>活動時間</th>
            <th>
              <div>活動對象</div>
              <div>會員等級</div>
              <div>常態/短期活動</div>
            </th>
            <th>活動類型</th>
            <th>活動範圍</th>
            <th>排除商品</th>
            <th>是否可使用折價券</th>
            <th>活動狀態</th>
            <th>功能</th>
          </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
              <tbody>
              {datas?.length > 0 ? (
                  datas?.map((data, index) => (
                      <tr key={index} className="text-center">
                        {/* 活動序號 */}
                        <td>{data.activityCode}</td>
                        {/* 活動名稱 */}
                        <td>{data.name}</td>
                        {/* 活動時間 */}
                        <td>
                          <div>{`${
                              data.startTime
                                  ? moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')
                                  : '--'
                          } 起`}</div>
                          <div>{`${
                              data.endTime
                                  ? moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')
                                  : '--'
                          } 止`}</div>
                        </td>
                        <td>
                          {/* 活動對象 */}
                          <div>
                            {data._object === 'all' ? '全體會員' : '指定會員'}
                          </div>
                          {/* 會員等級 */}
                          <div>{data.objectMLevelName || '--'}</div>
                          {/* 常態/短期活動 */}
                          <div>
                            {data.objectType
                                ? data.objectType === 'regular'
                                    ? '常態活動'
                                    : data.objectType === 'special'
                                        ? '短期活動'
                                        : '--'
                                : '--'}
                          </div>
                        </td>
                        {/* 活動類型 */}
                        <td>{data.catName}</td>
                        {/* 活動範圍 */}
                        <td>
                          <div>
                            {data.productRange === 'all' ? '全店活動' : '指定商品'}
                          </div>
                          <div>
                            {data.pickedNum !== 0 && `活動商品共${data.pickedNum}件`}
                          </div>
                        </td>
                        {/* 排除商品 */}
                        <td>
                          {data.removedbyAllNum !== 0 &&
                              `排除商品共${data.removedbyAllNum}件`}
                        </td>
                        {/* 是否可使用折價券 */}
                        <td>{data.isDiscountAvailable ? '是' : '否'}</td>
                        {/* 活動狀態 */}
                        <td>
                          {data.status === 0
                              ? '尚未開始'
                              : data.status === 1
                                  ? '進行中'
                                  : '已結束'}
                        </td>
                        <td>
                          {data.status !== 2 ? (
                              <div className="mb-2">
                                <Button
                                    color="primary"
                                    outline
                                    className="btn-sm"
                                    onClick={() => {
                                      history.push(
                                          `/UpdateCompaignSettingPage?id=${data.id}`,
                                      );
                                    }}
                                >
                                  編輯
                                </Button>
                              </div>
                          ) : (
                              <div className="mb-2">
                                <Button
                                    color="primary"
                                    outline
                                    className="btn-sm"
                                    onClick={() => {
                                      history.push(
                                          `/ViewCompaignSettingPage?id=${data.id}`,
                                      );
                                    }}
                                >
                                  查看
                                </Button>
                              </div>
                          )}
                          {data.status !== 2 && (
                              <div className="mb-2">
                                <Button
                                    color="primary"
                                    outline
                                    className="btn-sm"
                                    onClick={() => {
                                      history.push(
                                          `/SortingActivityProducts?id=${data.id}`,
                                      );
                                    }}
                                >
                                  商品排序
                                </Button>
                              </div>
                          )}
                          <div className="mb-2">
                            <Button
                                color="primary"
                                outline
                                className="btn-sm"
                                onClick={() => {
                                  history.push(
                                      `/CopyCompaignSettingPage?id=${data.id}`,
                                  );
                                }}
                            >
                              複製
                            </Button>
                          </div>
                          {data.status === 0 && (
                              <div className="mb-2">
                                <Button
                                    color="danger"
                                    outline
                                    className="btn-sm"
                                    onClick={() => {
                                      deleteActivityHandler(data.id);
                                    }}
                                >
                                  刪除
                                </Button>
                              </div>
                          )}
                        </td>
                      </tr>
                  ))
              ) : (
                  <TableHasNoData />
              )}
              </tbody>
          )}
        </Table>
      </div>
  );
};

const DiscountCompaign = () => {
  const dispatch = useDispatch();
  const { activityList } = useSelector(state => state.getActivityList);
  const { cateOptions, loading, error } = useSelector(
      state => state.getActivityCateOptions,
  );
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = useSelector(state => state.deleteActivity);
  const [activityCateOptions, setActivityCateOptions] = useState([]);
  const { success: sortSuccess, error: sortError } = useSelector(
      state => state.sortActivity,
  );
  const { location } = history;
  const query = useQuery();
  const [currentValues, setCurrentValues] = useState(null);

  const { values, handleSubmit, resetForm, setFieldValue, setValues } =
      useFormik({
        enableReinitialize: true,
        initialValues: {
          _object: '',
          activityCode: '',
          catCode: 0,
          endTime: null,
          isDiscountAvailable: null,
          keyword: '',
          page: 1,
          page_size: 10,
          productRange: '',
          startTime: null,
          statusList: [],
          timeType: '',
        },
        onReset: () => {
          handleSubmit();
        },
        onSubmit: () => {
          const body = {
            _object: values?._object || '',
            activityCode: values.activityCode.toString(),
            catCode: Number(values?.catCode) || 0,
            endTime: values?.endTime
                ? moment(values?.endTime).format('YYYY/MM/DD')
                : null,
            isDiscountAvailable:
                values?.isDiscountAvailable === ''
                    ? null
                    : JSON.parse(values?.isDiscountAvailable || null),
            keyword: values?.keyword || '',
            page: values?.page,
            page_size: 10,
            productRange: values?.productRange || '',
            startTime: values?.startTime
                ? moment(values?.startTime).format('YYYY/MM/DD')
                : null,
            statusList: values?.statusList,
            timeType: values?.timeType || '',
          };

          const params = new URLSearchParams({
            ...body,
            endTime: JSON.stringify(body.endTime || null),
            startTime: JSON.stringify(body.startTime || null),
            statusList: JSON.stringify(body.statusList || []),
          });

          // compare params
          if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
            history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
          } else {
            // just refreshing the data
            dispatch(getActivityList(body));
          }
        },
      });

  useEffect(() => {
    if (!location.search) {
      handleSubmit();
    }
    dispatch(getActivityCateOptions());
  }, []);

  useEffect(() => {
    if (location.search) {
      const value = {
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime) === null
                ? ''
                : moment(query.value.endTime).toDate()
            : '',
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime) === null
                ? ''
                : moment(query.value.startTime).toDate()
            : '',
        statusList: query.value.statusList
            ? JSON.parse(query.value.statusList)
            : [],
      };

      const body = {
        ...query.value,
        activityCode: query.value.activityCode
            ? query.value.activityCode.toString()
            : '',
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
                ? moment(values.endTime).format('YYYY/MM/DD')
                : null
            : null,
        isDiscountAvailable: query.value.isDiscountAvailable
            ? JSON.parse(query.value.isDiscountAvailable)
            : null,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
                ? moment(values.startTime).format('YYYY/MM/DD')
                : null
            : null,
        statusList: query.value.statusList
            ? JSON.parse(query.value.statusList)
            : [],
      };

      setValues({
        ...values,
        ...value,
        activityCode: '',
      });

      dispatch(getActivityList(body));
    } else {
      resetForm();
    }
  }, [location.search]);

  useEffect(() => {
    if (cateOptions?.length > 0) {
      let temp = [];

      // 過濾同類型活動
      cateOptions.forEach(option => {
        if (!temp.find(data => data.type === option.type)) {
          temp.push(option);
        }
      });
      let options = [];
      temp.forEach(data => {
        options.push(...data.codes);
      });

      setActivityCateOptions(options);
    }
  }, [cateOptions]);

  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      dispatch({ type: DELETE_ACTIVITY_IDLE });
      handleSubmit();
    }

    if (deleteError) {
      MessageUtil.alertWanring('刪除失敗', deleteError);
      dispatch({ type: DELETE_ACTIVITY_IDLE });
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (sortSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: SORT_ACTIVITY_IDLE });
      handleSubmit();
    }

    if (sortError) {
      MessageUtil.alertWanring('更新失敗', sortError);
      dispatch({ type: SORT_ACTIVITY_IDLE });
    }
  }, [sortSuccess, sortError]);

  const handlePageChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const deleteActivityHandler = id => {
    dispatch(deleteActivity(id));
  };

  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const toggleSortModal = () => {
    setIsSortModalOpen(!isSortModalOpen);
  };

  const searchHandler = () => {
    setFieldValue('page', 1);
    handleSubmit();
  };

  return (
      <Layout
          pageTitle="折扣活動"
          items={[
            { isActive: true, page_name: '行銷活動' },
            { isActive: false, page_name: '折扣活動' },
          ]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>關鍵字</label>
                <Input
                    placeholder="活動名稱"
                    className={style['form-input']}
                    value={values.keyword}
                    onChange={event => {
                      setFieldValue('keyword', event.target.value);
                    }}
                />
              </div>
              {/* <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>活動狀態</label>
              <Input
                type="select"
                className={style["form-select-short"]}
                style={{ margin: 0 }}
                value={values.status}
                onChange={event => {
                  setFieldValue('status', event.target.value);
                }}
              >
                <option value="">全部</option>
                <option value="尚未開始">尚未開始</option>
                <option value="進行中">進行中</option>
                <option value="已結束">已結束</option>
              </Input>
            </div> */}
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>活動對象</label>
                <Input
                    type="select"
                    className={style['form-select-medium']}
                    style={{ margin: 0 }}
                    value={values._object}
                    onChange={event => {
                      setFieldValue('_object', event.target.value);
                    }}
                >
                  <option value="">全部</option>
                  <option value="all">全體會員</option>
                  <option value="member">指定會員</option>
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>活動類型</label>
                <Input
                    type={'select'}
                    className={style['form-select-medium']}
                    style={{ margin: 0 }}
                    value={values.catCode}
                    onChange={event => {
                      setFieldValue('catCode', event.target.value);
                    }}
                >
                  <option value={0}>全部</option>
                  {activityCateOptions?.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.name}
                      </option>
                  ))}
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>時間區間</label>
                <Input
                    type="select"
                    className={style['form-select-medium']}
                    style={{ margin: 0 }}
                    value={values.timeType}
                    onChange={event => {
                      setFieldValue('timeType', event.target.value);
                    }}
                >
                  <option value="">全部</option>
                  <option value="活動開始日">活動開始日</option>
                  <option value="活動結束日">活動結束日</option>
                </Input>
                <div className={style['form-select']} style={{ margin: 0 }}>
                  <DatePicker
                      className="form-control"
                      dateFormat={'yyyy/MM/dd'}
                      selected={
                        values.startTime ? moment(values.startTime).toDate() : ''
                      }
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                  />
                </div>
                <span style={{ margin: '1rem' }}>~</span>
                <div className={style['form-select']} style={{ margin: 0 }}>
                  <DatePicker
                      className="form-control"
                      dateFormat={'yyyy/MM/dd'}
                      selected={
                        values.endTime ? moment(values.endTime).toDate() : ''
                      }
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      minDate={
                        values.startTime ? moment(values.startTime).toDate() : ''
                      }
                  />
                </div>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>活動範圍</label>
                <Input
                    type="select"
                    className={style['form-select-short']}
                    value={values.productRange}
                    style={{ margin: 0 }}
                    onChange={event => {
                      setFieldValue('productRange', event.target.value);
                    }}
                >
                  <option value="">全部</option>
                  <option value="all">全店活動</option>
                  <option value="picked">指定商品</option>
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
                <label>是否可使用折價券</label>
                <Input
                    className={style['form-select-short']}
                    style={{ margin: 0 }}
                    type="select"
                    value={values.isDiscountAvailable}
                    onChange={event => {
                      setFieldValue('isDiscountAvailable', event.target.value);
                    }}
                >
                  <option value={''}>全部</option>
                  <option value="true">是</option>
                  <option value="false">否</option>
                </Input>
              </div>
              <div className={style.formGroup}>
                <label>活動狀態</label>
                <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'left',
                      marginRight: '1.5rem',
                    }}
                >
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      checked={values.statusList?.includes(0)}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked
                            ? !values.statusList.includes(0) &&
                            setFieldValue('statusList', [...values.statusList, 0])
                            : setFieldValue(
                                'statusList',
                                values.statusList.filter(val => val !== 0),
                            );
                      }}
                  />
                  <span className="ml-2">尚未開始</span>
                </div>
                <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'left',
                      marginRight: '1.5rem',
                    }}
                >
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      checked={values.statusList?.includes(1)}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked
                            ? !values.statusList.includes(1) &&
                            setFieldValue('statusList', [...values.statusList, 1])
                            : setFieldValue(
                                'statusList',
                                values.statusList.filter(val => val !== 1),
                            );
                      }}
                  />
                  <span className="ml-2">進行中</span>
                </div>
                <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'left',
                      marginRight: '1.5rem',
                    }}
                >
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      checked={values.statusList?.includes(2)}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked
                            ? !values.statusList.includes(2) &&
                            setFieldValue('statusList', [...values.statusList, 2])
                            : setFieldValue(
                                'statusList',
                                values.statusList.filter(val => val !== 2),
                            );
                      }}
                  />
                  <span className="ml-2">已結束</span>
                </div>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={resetForm}
              >
                取消
              </Button>
              <Button
                  type="submit"
                  className={style.modal_button_submit}
                  onClick={searchHandler}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <Card className={style.card}>
          <div className={style.formGroup}>
            <Button
                className="w100 mb-3"
                color="success"
                onClick={() => {
                  history.push('/DiscountCompaignCate');
                }}
            >
              新增
            </Button>
            <Button
                type="button"
                className={style.sort_button}
                onClick={() => {
                  toggleSortModal();
                }}
            >
              商品折扣活動排序
            </Button>
          </div>
          <TableList
              datas={activityList?.datas}
              onDelete={deleteActivityHandler}
          />
          {activityList?.current_page && (
              <div className="d-flex align-items-center justify-content-center">
                <RcPagination
                    defaultCurrent={activityList.current_page}
                    defaultPageSize={10}
                    total={activityList.total_count}
                    onChange={handlePageChange}
                />
              </div>
          )}
        </Card>
        {isSortModalOpen && (
            <SortingModal
                isOpen={isSortModalOpen}
                toggle={toggleSortModal}
                currentPage={values.page}
                onCancel={handleSubmit}
            />
        )}
      </Layout>
  );
};

export default DiscountCompaign;
