import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Alert, Button, Card, CardBody, FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import {
  getPointEventSetting,
  updatePointEventSetting,
} from '../../redux/actions/data/pointSettingsActions';
import {
  GET_POINT_EVENT_SETTINGS_SUCCESS,
  GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST,
} from '../../redux/constants/data/pointSettingConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import { EnableOptions } from '../../utils/options';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '180px',
};
const lableStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };

const VirtualTryOn = () => {
  const history = useHistory();
  const { detail, loading } = useSelector(state => state.pointEventSetting);
  const dispatch = useDispatch();
  const { values, setValues, handleSubmit, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: '',
      content_en: '',
      month: 0,
      quantity: 0,
      status: '啟用',
      year: 0,
    },
    onSubmit: async values => {
      dispatch({
        type: GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST,
      });
      try {
        await updatePointEventSetting(values);
        MessageUtil.alertSuccess('更新成功');
        dispatch(getPointEventSetting('虛擬試穿戴'));
      } catch (e) {
        MessageUtil.alertWanring(e.message);
        dispatch({
          payload: values,
          type: GET_POINT_EVENT_SETTINGS_SUCCESS,
        });
      }
    },
    validationSchema: Yup.object().shape({
      quantity: Yup.number()
        .required('請輸入大於0的數字')
        .min(1, '請輸入大於0的數字'),
    }),
  });

  useEffect(() => {
    dispatch(getPointEventSetting('虛擬試穿戴'));
  }, []);

  useEffect(() => {
    if (!loading && Object.keys(detail).length > 0) {
      setValues(detail);
    }
  }, [detail, loading]);

  return (
    <Layout
      pageTitle={'指定活動給點設定-虛擬試穿戴'}
      items={[
        { isActive: true, page_name: '紅利點數' },
        {
          isActive: false,
          page_name: '指定活動給點設定',
          to: 'Specifyquantityettings',
        },

        { isActive: false, page_name: '指定活動給點設定-虛擬試穿戴' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span>完成任一款虛擬試戴即贈點，點數僅贈送一次</span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span>
            消費者使用點數折抵後，若發生取消訂單或退貨，系統會自動歸還點數給消費者
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span>儲存後即刻更新</span>
        </div>
      </Alert>
      <Card className={style.card}>
        <CardBody className="px-2">
          <h4 className="title" style={titleStyle}>
            基本設定
          </h4>
          <div className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                是否啟用
              </label>
              <div className="form-item">
                <Select
                  classNames={{
                    control: state => 'w-100',
                  }}
                  options={EnableOptions}
                  value={
                    EnableOptions.filter(o => o.value === values.status)[0]
                  }
                  onChange={e => {
                    setFieldValue('status', e.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                給點設定
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Input
                    type="number"
                    min="0"
                    className="form-control mx-2"
                    value={values.quantity}
                    onBlur={e => {
                      setFieldValue('quantity', Number(e.target.value));
                    }}
                    onChange={e => {
                      setFieldValue(
                        'quantity',
                        Number(e.target.value).toString(),
                      );
                    }}
                  />
                </div>
                <div className="form-error px-2">{errors.quantity}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                給點效期
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <select
                    className="form-control mx-2"
                    value={String(values.year)}
                    onChange={e => {
                      setFieldValue('year', Number(e.target.value));
                    }}
                  >
                    <option value={0}>當年</option>
                    <option value={1}>明年</option>
                    <option value={2}>後年</option>
                  </select>
                  <select
                    className="form-control mx-2"
                    value={String(values.month)}
                    onChange={e => {
                      setFieldValue('month', Number(e.target.value));
                    }}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(v => {
                      return <option value={v} key={v}>{v}</option>;
                    })}
                  </select>
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="mt-4 text-nowrap row flex-wrap">
            <div className="d-inline-block col-4" style={{ minWidth: '300px' }}>
              異動時間: {detail?.updateTime} {detail?.updater}
            </div>
          </div>
          <hr />
          <div className="text-center">
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                history?.goBack();
              }}
            >
              <span className="btn-wrapper--label">取消</span>
            </Button>
            <Button
              type="button"
              disabled={Object.keys(errors).length || loading}
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              {loading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: '1rem' }}
                ></span>
              )}
              <span className="btn-wrapper--label">儲存</span>
            </Button>
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
};

export default VirtualTryOn;
