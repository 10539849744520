import {
  CREATE_LOGISTICS_SHIPPING_REQUEST,
  CREATE_LOGISTICS_SHIPPING_SUCCESS,
  CREATE_LOGISTICS_SHIPPING_FAIL,
  CREATE_LOGISTICS_PRINT_TICKET_REQUEST,
  CREATE_LOGISTICS_PRINT_TICKET_SUCCESS,
  CREATE_LOGISTICS_PRINT_TICKET_FAIL,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL,
  CREATE_LOGISTICS_CANCEL_ORDER_REQUEST,
  CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS,
  CREATE_LOGISTICS_CANCEL_ORDER_FAIL,
} from 'redux/constants/data/createLogisticsConstants';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const createLogistics = async data => {
  return await request({
    data,
    method: 'post',
    url: '/Website/CreateLogistics/CreateLogistics',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};
export const createLogisticsShipping = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_LOGISTICS_SHIPPING_FAIL,
      method: 'post',
      requestConstant: CREATE_LOGISTICS_SHIPPING_REQUEST,
      successConstant: CREATE_LOGISTICS_SHIPPING_SUCCESS,
      url: '/Website/CreateLogistics/Shipping',
    });
  };
};

export const createLogisticsPrintTicket = ({ params, logisticsWay }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: CREATE_LOGISTICS_PRINT_TICKET_FAIL,
      method: 'post',
      requestConstant: CREATE_LOGISTICS_PRINT_TICKET_REQUEST,
      successConstant: CREATE_LOGISTICS_PRINT_TICKET_SUCCESS,
      url: `/Website/CreateLogistics/${
        logisticsWay === 1
          ? 'PrintFAMIC2COrderInfo'
          : 'PrintUniMartC2COrderInfo'
      }`,
    });
  };
};
export const createLogisticsCancelLogistic = ({
  params,
  shippingStatus,
  logisticsWay,
}) => {
  // console.log(params);
  return async (dispatch, getState) => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL,
      method: 'post',
      requestConstant: CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST,
      successConstant: CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS,
      url: `/Website/CreateLogistics/CancelLogistic/${shippingStatus}/${logisticsWay}`,
    });
  };
};
export const createLogisticsCancelOrder = ({ params, logisticsWay }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: CREATE_LOGISTICS_CANCEL_ORDER_FAIL,
      method: 'post',
      requestConstant: CREATE_LOGISTICS_CANCEL_ORDER_REQUEST,
      successConstant: CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS,
      url: `/Website/CreateLogistics/CancelOrder/${logisticsWay}`,
    });
  };
};
