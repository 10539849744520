import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';

import Layout from '../components/Layout';
import Loader from '../components/Loader';
import BannerModel from '../components/uuCafeBannerSetting/BannerModel';
import SortBannerModel from '../components/uuCafeBannerSetting/SortBannerModel';
import {
  deleteCafeHomeBanner,
  getCafeHomeBannerList,
} from '../redux/actions/data/uuCafeBannerSettingsAction';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import { copyText } from '../utils/copyText';

const UUCafeBannerSettings = () => {
  const dispatch = useDispatch();
  const { loading, bannerList } = useSelector(
    state => state.cafeBannerSettingsList,
  );
  const [page, setPage] = useState(1);
  const [openModal, setOpenModel] = useState(false);
  const [openSortModal, setOpenSortModel] = useState(false);

  const banner = useRef({
    bannerImg: '',
    bannerImg_mobile: '',
    bannerUrl: '',
    bannerUrl_mobile: '',
    linkUrl: '',
  });

  const handlePageChange = useCallback(
    value => {
      dispatch(
        getCafeHomeBannerList({
          page: value,
          page_size: bannerList.page_size || 10,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, bannerList],
  );

  const handleDeleteBanner = useCallback(
    async banner => {
      try {
        await deleteCafeHomeBanner(banner);
        MessageUtil.toastSuccess('刪除成功!');
        handlePageChange(1);
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [handlePageChange],
  );

  useEffect(() => {
    handlePageChange(1);
  }, []);

  return (
    <Layout
      pageTitle="首頁-形象圖片設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '首頁-形象圖片設定' },
      ]}
    >
      <BannerModel
        openModal={openModal}
        setOpenModel={(show, isReload) => {
          if (isReload) {
            handlePageChange(1);
          }
          setOpenModel(show);
        }}
        banner={banner.current}
      />

      <SortBannerModel
        openModal={openSortModal}
        setOpenModel={show => {
          if (!show) {
            handlePageChange(page);
          }
          setOpenSortModel(show);
        }}
      />
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  banner.current = {
                    bannerImg: '',
                    bannerImg_mobile: '',
                    bannerUrl: '',
                    bannerUrl_mobile: '',
                    linkUrl: '',
                  };
                  setOpenModel(true);
                }}
              >
                新增
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  setOpenSortModel(true);
                }}
              >
                排序
              </Button>
            </div>
            <p className="small text-end mb-0">
              共 {bannerList.total_count} 筆
            </p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th nowrap="nowrap">電腦版圖片</th>
                    <th nowrap="nowrap">手機版圖片</th>
                    <th style={{ minWidth: '120px' }}>連結</th>
                    <th style={{ minWidth: '120px' }}>異動日期</th>
                    <th>功能</th>
                  </tr>
                </thead>
                <tbody>
                  {bannerList.datas.map((data, index) => (
                    <tr key={index}>
                      <td width="150">
                        <img
                          className={style.w200}
                          src={data.bannerUrl}
                          alt={data.bannerImg}
                        />
                      </td>
                      <td width="150">
                        <img
                          className={style.w200}
                          src={data.bannerUrl_mobile}
                          alt={data.bannerImg_mobile}
                        />
                      </td>
                      <td className="text-center">
                        <Button
                          size="sm"
                          outline
                          color="primary"
                          disabled={!data.linkUrl}
                          onClick={() => {
                            copyText(data.linkUrl);
                            MessageUtil.toastSuccess('已複製', data.linkUrl);
                          }}
                        >
                          複製連結
                        </Button>
                      </td>
                      <td>
                        <div className="text-nowrap">{data.updateDateTime}</div>
                        <div>{data.updater}</div>
                      </td>
                      <td nowrap="nowrap" className="text-center">
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="primary"
                          onClick={() => {
                            banner.current = data;
                            setOpenModel(true);
                          }}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="danger"
                          onClick={() => {
                            MessageUtil.submitConfirm({
                              doneFun: () => {
                                handleDeleteBanner(data);
                              },
                              title: '確認是否刪除',
                            });
                          }}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={bannerList.page_size || 10}
                total={bannerList.total_count || 1}
                onChange={handlePageChange}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </Layout>
  );
};
export default UUCafeBannerSettings;
