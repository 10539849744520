export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';

export const USER_LOGOUT = 'USER_LOGOUT';

export const UPDATE_DATA_REQUEST = 'UPDATE_DATA_REQUEST';
export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_FAIL = 'UPDATE_DATA_FAIL';

export const UPDATE_TIME_REQUEST = 'UPDATE_TIME_REQUEST';
export const UPDATE_TIME_SUCCESS = 'UPDATE_TIME_SUCCESS';
export const UPDATE_TIME_FAIL = 'UPDATE_TIME_FAIL';

export const JOB_LIST_REQUEST = 'JOB_LIST_REQUEST';
export const JOB_LIST_SUCCESS = 'JOB_LIST_SUCCESS';
export const JOB_LIST_FAIL = 'JOB_LIST_FAIL';

export const JOB_DETAILS_REQUEST = 'JOB_DETAILS_REQUEST';
export const JOB_DETAILS_SUCCESS = 'JOB_DETAILS_SUCCESS';
export const JOB_DETAILS_FAIL = 'JOB_DETAILS_FAIL';

export const JOB_POST_REQUEST = 'JOB_POST_REQUEST';
export const JOB_POST_SUCCESS = 'JOB_POST_SUCCESS';
export const JOB_POST_FAIL = 'JOB_POST_FAIL';
export const JOB_POST_RESET = 'JOB_POST_RESET';

export const JOB_DELETE_REQUEST = 'JOB_DELETE_REQUEST';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAIL = 'JOB_DELETE_FAIL';
export const JOB_DELETE_RESET = 'JOB_DELETE_RESET';

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST';
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS';
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL';
export const USER_VERIFY_RESET = 'USER_VERIFY_RESET';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET';

export const USER_SEND_FORGOT_REQUEST = 'USER_SEND_FORGOT_REQUEST';
export const USER_SEND_FORGOT_SUCCESS = 'USER_SEND_FORGOT_SUCCESS';
export const USER_SEND_FORGOT_FAIL = 'USER_SEND_FORGOT_FAIL';
export const USER_SEND_FORGOT_RESET = 'USER_SEND_FORGOT_RESET';
