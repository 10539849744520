import React from 'react';
import { Input } from 'reactstrap';

import style from '../../styles/layout.module.css';

const BasicSettings = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
}) => {
  return (
    <React.Fragment>
      <div className={style.formGroup}>
        <label className="w150 required">活動名稱(中)</label>
        <Input
          className={style['form-select-shop-banner']}
          id="name"
          onChange={handleChange}
          value={values.name}
          maxLength={20}
          invalid={errors.name && touched.name}
        />
        <small className="ml-2">{`(${values.name.length}/20)`}</small>
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">活動名稱(英)</label>
        <Input
          className={style['form-select-shop-banner']}
          id="name_en"
          onChange={handleChange}
          value={values.name_en}
          maxLength={20}
          invalid={errors.name_en && touched.name_en}
        />
        <small className="ml-2">{`(${values.name_en.length}/20)`}</small>
      </div>
      <div className={style.formGroup}>
        <label className="required w150">折扣持續時間</label>
        <Input
          className={style['form-input-short']}
          type="number"
          id="days"
          value={values.days}
          onChange={handleChange}
          min={0}
          invalid={errors.days && touched.days}
        />
        <label className="ml-2">天</label>
        <Input
          className={style['form-input-short']}
          type="number"
          id="hours"
          value={values.hours}
          onChange={handleChange}
          min={0}
          max={23}
          invalid={errors.hours && touched.hours}
        />
        <label className="ml-2">時</label>
        <Input
          className={style['form-input-short']}
          type="number"
          id="minutes"
          value={values.minutes}
          onChange={handleChange}
          min={0}
          max={59}
          invalid={errors.minutes && touched.minutes}
        />
        <label className="ml-2">分</label>
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">第一次優惠條件</label>
        <Input
          className={style['form-input-short']}
          type="number"
          id="firstDays"
          value={values.firstDays}
          onChange={handleChange}
          invalid={errors.firstDays && touched.firstDays}
          min={0}
        />
        <label className="ml-2">天內，瀏覽商品頁</label>
        <Input
          className={style['form-input-short']}
          type="number"
          id="firstCount"
          value={values.firstCount}
          onChange={handleChange}
          invalid={errors.firstCount && touched.firstCount}
        />
        <span className="ml-2">次</span>
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">第二次優惠條件</label>
        <div>
          <div className={style.formGroup}>
            <Input
              className={style['form-input-short']}
              type="number"
              id="secondDays"
              value={values.secondDays}
              onChange={handleChange}
              invalid={errors.secondDays && touched.secondDays}
              min={0}
            />
            <label className="ml-2">天內，瀏覽商品頁</label>
            <Input
              className={style['form-input-short']}
              type="number"
              id="secondCount"
              value={values.secondCount}
              onChange={handleChange}
              invalid={errors.secondCount && touched.secondCount}
              min={0}
            />
            <span className="ml-2">次</span>
          </div>
          <div>
            <small>
              <li>優惠條件的 "瀏覽次數" 必須小於 "天數"</li>
              <li>
                ex. 2天內瀏覽一次，第1天瀏覽1次達標，第二天瀏覽後出現優惠價
              </li>
            </small>
          </div>
        </div>
      </div>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w150">重新計算</label>
        <div>
          <div className={style.formGroup}>
            <Input
              className={style['form-input-short']}
              type="number"
              id="resetDays"
              value={values.resetDays}
              onChange={handleChange}
              invalid={errors.resetDays && touched.resetDays}
              min={0}
            />
            <span className="ml-2">天後重新計算瀏覽次數</span>
          </div>
          <small>
            <li>在限時優惠期間購買商品後，多久後才會再出現優惠</li>
          </small>
          <small>
            <li>在第二次優惠條件結束後，多久後才會再出現優惠</li>
          </small>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasicSettings;
