import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  deleteProductSpec,
  getProductSpecList,
  postProductSpec,
} from '../redux/actions/data/productActions';
import {
  PRODUCT_SPEC_DELETE_RESET,
  PRODUCT_SPEC_POST_RESET,
} from '../redux/constants/data/productConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

export default function PrdSpecList(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [modal, setModal] = useState(false);
  const [specId, setSpecId] = useState('');
  const [title, setTitle] = useState('');
  const [title_en, setTitle_en] = useState('');
  const [content, setContent] = useState('');
  const [content_en, setContent_en] = useState('');

  const { loading, specListInfo, error } = useSelector(
    state => state.productSpecList,
  );
  const { success: addSuccess } = useSelector(state => state.productSpecPost);
  const { success: deleteSuccess } = useSelector(
    state => state.productSpecDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: `/PrdSpecList/${id}` })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: `/PrdSpecList/${id}` })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }

  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getProductSpecList(id));
  }, []);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(getProductSpecList(id));
      toggleModal();
    }

    return () => {
      dispatch({ type: PRODUCT_SPEC_POST_RESET });
    };
  }, [addSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getProductSpecList(id));
    }

    return () => {
      dispatch({ type: PRODUCT_SPEC_DELETE_RESET });
    };
  }, [deleteSuccess]);

  const handleSubmit = values => {
    console.log(values);
    let formData = new FormData();

    formData.append('productId', id);
    formData.append('id', specId);
    formData.append('sort', 1);
    formData.append('title', values.title);
    formData.append('title_en', values.title_en);
    formData.append('content', values.content);
    formData.append('content_en', values.content_en);

    dispatch(postProductSpec(formData));
  };

  const toggleModal = () => {
    setModal(!modal);

    setSpecId(CommonUtil.getNewID());
    setTitle('');
    setTitle_en('');
    setContent('');
    setContent_en('');
  };

  const handleOpenEditModal = data => {
    console.log(data);

    setModal(!modal);
    setSpecId(data.id);
    setTitle(data.title);
    setTitle_en(data.title_en);
    setContent(data.content);
    setContent_en(data.content_en);
  };

  const callDel = specId => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteProductSpec(id, specId));
    });
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });

    return schema;
  };

  return (
    <Layout
      pageTitle={`${specListInfo && specListInfo.result?.productName}-規格`}
      items={[
        { isActive: false, page_name: '商品管理', to: '/ProductControl' },
        { isActive: true, page_name: '商品規格' },
      ]}
    >
      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {specListInfo &&
            specListInfo.result &&
            specListInfo.result.datas !== null
              ? specListInfo.result.datas.length
              : 0}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>標題</th>
                <th>說明</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {specListInfo &&
                specListInfo.result &&
                specListInfo.result.datas !== null &&
                specListInfo.result.datas.length > 0 ? (
                  specListInfo.result.datas.map((data, index) => (
                    <tr key={data.id}>
                      <td>{index + 1}</td>
                      <td>{data.title}</td>
                      <td>{data.content}</td>
                      <td>
                        <Button
                          className="m-2"
                          type="button"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          編輯分類
                        </Button>
                        <Button
                          className="m-2"
                          type="button"
                          color="danger"
                          size="sm"
                          outline
                          onClick={() => callDel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          商品規格
        </ModalHeader>
        <Formik
          initialValues={{
            content: content,
            content_en: content_en,
            title: title,
            title_en: title_en,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form>
              <ModalBody className={style.modal_body}>
                <div className="form-list form-label-140">
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      標題{' '}
                      <ErrorMessage
                        name="title"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field name="title" className="form-control" />
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      標題(英){' '}
                      <ErrorMessage
                        name="title_en"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field name="title_en" className="form-control" />
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      內容{' '}
                      <ErrorMessage
                        name="content"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field
                        name="content"
                        as="textarea"
                        className="form-control"
                        rows="3"
                      ></Field>
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      內容(英)
                      <ErrorMessage
                        name="content_en"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field
                        name="content_en"
                        as="textarea"
                        className="form-control"
                        rows="3"
                      ></Field>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>{' '}
                <Button
                  color="primary"
                  className={style.modal_button_submit}
                  type="submit"
                >
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
