import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, {memo, useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table } from 'reactstrap';

import { getMaintainList } from '../../redux/actions/data/repairActions';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const RepairRegistersRecords = ({ memberId }) => {
  const [page, setPage] = useState(1);
  const { loading, maintainListInfo, error } = useSelector(
    state => state.maintainList,
  );
  const dispatch = useDispatch();

  const handlePageChange = useCallback(
    (page = 1) => {
      setPage(page);
      dispatch(
        getMaintainList({
          memberId: Number(memberId),
          page,
          page_size: 10,
        }),
      );
    },
    [memberId],
  );

  useEffect(() => {
    handlePageChange(1);
  }, [memberId]);

  return (
    <Card className={style.card}>
      <div className="d-flex justify-content-end">
        共
        <span className="mx-1">
          {maintainListInfo?.total_count ? maintainListInfo.total_count : 0}
        </span>
        筆
      </div>
      {!loading && (
        <div className="table-responsive mb-5">
          <Table hover bordered striped>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>
                  <div>狀態</div>
                  <div>狀態日期</div>
                </th>
                <th>
                  <div>維修單編號</div>
                  <div>申請日期</div>
                </th>
                <th>
                  <div>來源</div>
                  <div>寄送門市</div>
                </th>
                <th style={{ maxWidth: '100px' }}>
                  <div>寄件人的</div>
                  <div>顧客姓名</div>
                </th>
                <th>寄件人的電話</th>
                <th style={{ maxWidth: '100px' }}>
                  <div>商品品牌</div>
                  <div>商品型號</div>
                  <div>商品顏色</div>
                </th>
                <th style={{ minWidth: '300px' }}>商品問題</th>
                <th>經手人</th>
                <th style={{ minWidth: '300px' }}>百滋處理詳情</th>
                <th>報廢倉庫</th>
                <th>購買日期</th>
                <th>回公司日期</th>
                <th>寄回日期</th>
                <th>庫存</th>
                <th>credit</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {maintainListInfo
                  ? maintainListInfo.datas.map((data, index) => (
                      <tr key={index}>
                        <td className="text-nowrap">
                          <div>{data.status}</div>
                          <div>
                            {moment(data.statusCodeTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )}
                          </div>
                        </td>
                        <td className="text-nowrap">
                          <div>{data.repairRegisterNum}</div>
                          <div>
                            {moment(new Date(data.createTime)).format(
                              'yyyy/MM/DD',
                            )}
                          </div>
                        </td>
                        <td className="text-center">
                          <div>{data.sourceName}</div>
                          <div>{data.storeName_sender}</div>
                        </td>
                        <td>{data.senderName || data.supplierName_sender}</td>
                        <td>
                          {data.countryCode_sender == 886 && data.phone_sender
                            ? `0${data.phone_sender}`
                            : data.phone_sender}
                        </td>
                        <td>
                          {/*
                            商品品牌
                            商品型號
                            商品顏色
                            */}
                          <div className="text-nowrap">{data.brandName}</div>
                          <div className="text-nowrap">{data.model}</div>
                          <div className="text-nowrap">{data.color}</div>
                        </td>
                        <td>
                          {data.productProblem
                            ?.split('\n')
                            .map((string, index) => {
                              return <div key={index}>{string}</div>;
                            })}
                        </td>
                        <td className="text-center">{data.handlerNamer}</td>
                        <td>
                          {data.handlingDetail
                            ?.split('\n')
                            .map((string, index) => {
                              return <div key={index}>{string}</div>;
                            })}
                        </td>
                        <td>
                          {/*報廢倉庫*/}
                          {data.scrapWarehouseName ||
                            data.scrapWarehouseCode ||
                            '--'}
                        </td>
                        <td className="text-nowrap">
                          <div>
                            {data.purchaseDate &&
                              moment(data.purchaseDate).format('YYYY/MM/DD')}
                          </div>
                          {/* <div>{data.supplierChannelName}</div> */}
                        </td>
                        <td className="text-nowrap">
                          {data.backtoCompanyDate}
                        </td>
                        <td className="text-nowrap">
                          {/*寄回日期*/}
                          {data.sendBackDate
                            ? moment(data.sendBackDate).format('YYYY/MM/DD')
                            : '-'}
                        </td>
                        <td className="text-center">{data.stock}</td>
                        <td className="text-center">
                          {data.isCredit ? '是' : '否'}
                        </td>
                      </tr>
                    ))
                  : !loading && <TableHasNoData />}
              </tbody>
            )}
          </Table>
        </div>
      )}
      {loading && <Loader />}
      {!loading && maintainListInfo && maintainListInfo.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={page}
            defaultPageSize={10}
            total={maintainListInfo.total_count}
            onChange={page => handlePageChange(page)}
          />
        </div>
      )}
    </Card>
  );
};

export default memo(RepairRegistersRecords);
