import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import Tabs from 'components/Tabs';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, Input, TabPane } from 'reactstrap';
import { PURCHASE_NOTE_UPDATE_RESET } from 'redux/constants/data/cartConstants';
import style from 'styles/layout.module.css';
import MessageUtil from 'utils/MessageUtil';


import {
  getPurchaseNote,
  updatePurchaseNote,
} from '../redux/actions/data/cartActions';

const TabPaneItem = ({ tabId, loading, label, onSuccess, ...rest }) => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: rest.content,
      content_en: rest.content_en,
      type: label,
    },
    onSubmit(formModel) {
      dispatch(updatePurchaseNote(formModel));
    },
  });
  const {
    loading: updateLoading,
    success,
    error,
  } = useSelector(state => state.updatePurchaseNote);
  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功！');
    success && onSuccess && onSuccess();
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: PURCHASE_NOTE_UPDATE_RESET });
    };
  }, [success, error]);
  return (
    <TabPane tabId={tabId}>
      <Card className={style.card}>
        <Form className="position-relative mb-5">
          {(loading || updateLoading) && <BlockSpinner />}
          <div style={{ marginBottom: '1rem' }}>
            <span>中文</span>
            <Input
              type="textarea"
              rows={10}
              name="content"
              onChange={handleChange}
              value={values.content}
            />
          </div>
          <div style={{ marginBottom: '1rem' }}>
            <span>英文</span>
            <Input
              type="textarea"
              rows={10}
              name="content_en"
              onChange={handleChange}
              value={values.content_en}
            />
          </div>
        </Form>
        <p className={style.fs_14}>
          異動時間：
          {rest.updateDate} {rest.updateTime} {rest.updater}
        </p>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            disabled={loading || updateLoading}
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            {(loading || updateLoading) && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </TabPane>
  );
};
const PurchaseNote = () => {
  const dispatch = useDispatch();
  const handleInit = () => {
    dispatch(getPurchaseNote());
  };
  useEffect(() => {
    handleInit();
  }, []);
  const { loading, purchaseNote } = useSelector(state => state.purchaseNote);

  // console.log(purchaseNote);

  return (
    <Layout
      pageTitle="購物需知"
      items={[
        { isActive: false, page_name: '購物相關設定' },
        { isActive: true, page_name: '購物需知' },
      ]}
    >
      <Tabs
        tabs={purchaseNote.map((i, index) => ({
          name: i.type,
          tab: index + 1,
        }))}
      >
        {purchaseNote?.map((i, index) => (
          <TabPaneItem
            key={index}
            tabId={index + 1}
            loading={loading}
            label={i.type}
            onSuccess={handleInit}
            {...i}
          />
        ))}
      </Tabs>
    </Layout>
  );
};

export default PurchaseNote;
