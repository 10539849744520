import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import { TabItem } from '../../components/tabItem';
import {
  GetFormDescription,
  UpdateFormDescription,
} from '../../redux/actions/data/warrantyActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const FormDescriptionText = () => {
  const { detail, loading, error } = useSelector(
    state => state.getFormDescription,
  );
  const dispatch = useDispatch();
  const isUpdate = useRef(false);

  const { values, handleSubmit, setValues, setFieldValue, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_Content: '',
      new_Content_en: '',
      old_Content: '',
      old_Content_en: '',
      tab: 1,
    },
    onSubmit: values => {
      isUpdate.current = true;
      dispatch(UpdateFormDescription(values));
    },
    validationSchema: Yup.object().shape({
      new_Content: Yup.string().when('tab', {
        is: tab => {
          return tab === 1;
        },
        then: Yup.string().required('請輸入說明文字'),
      }),
      new_Content_en: Yup.string().when('tab', {
        is: tab => {
          return tab === 1;
        },
        then: Yup.string().required('請輸入說明文字(英文)'),
      }),
      old_Content: Yup.string().when('tab', {
        is: tab => {
          return tab === 2;
        },
        then: Yup.string().required('請輸入說明文字'),
      }),
      old_Content_en: Yup.string().when('tab', {
        is: tab => {
          return tab === 2;
        },
        then: Yup.string().required('請輸入說明文字(英文)'),
      }),
    }),
  });

  useEffect(() => {
    dispatch(GetFormDescription());
  }, []);

  useEffect(() => {
    if (detail) {
      setValues({
        tab: 1,
        ...detail,
      });
    }
  }, [detail]);

  useEffect(() => {
    if (isUpdate.current && !loading) {
      if (error) {
        MessageUtil.alertWanring(error);
      } else {
        MessageUtil.alertSuccess('更新成功');
      }
      isUpdate.current = false;
    }
  }, [loading, error]);

  return (
    <Layout
      pageTitle="表單說明文字"
      items={[
        { isActive: false, page_name: '產品保固註冊' },
        { isActive: true, page_name: '表單說明文字' },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={() => {
            setFieldValue('tab', 1);
          }}
          tab={1}
          text="新版保固卡"
          currentTab={values.tab}
        />
        <TabItem
          onClick={() => {
            setFieldValue('tab', 2);
          }}
          tab={2}
          text="舊版保固卡"
          currentTab={values.tab}
        />
      </Nav>
      <TabContent activeTab={values.tab}>
        <TabPane tabId={1}>
          <Card>
            <CardBody className="p-5 pb-2 mt-3">
              <FormGroup>
                <FormLabel>說明文字:</FormLabel>
                <Input
                  tag="textarea"
                  rows={8}
                  value={values.new_Content}
                  onChange={e => {
                    setFieldValue('new_Content', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.new_Content}</div>
              </FormGroup>
              <FormGroup>
                <FormLabel>說明文字(英文):</FormLabel>
                <Input
                  tag="textarea"
                  rows={8}
                  value={values.new_Content_en}
                  onChange={e => {
                    setFieldValue('new_Content_en', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.new_Content_en}</div>
              </FormGroup>
              <p className="text-secondary fs-14">
                說明文字會出現在前台的保固註冊表單的下方
              </p>
            </CardBody>
            <CardFooter className="pb-5 text-center">
              <Button
                color="primary"
                disabled={Object.keys(errors).length > 0 || loading}
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </CardFooter>
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <Card>
            <CardBody className="p-5 pb-2 mt-3">
              <FormGroup>
                <FormLabel>說明文字:</FormLabel>
                <Input
                  tag="textarea"
                  rows={8}
                  value={values.old_Content}
                  onChange={e => {
                    setFieldValue('old_Content', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.old_Content}</div>
              </FormGroup>
              <FormGroup>
                <FormLabel>說明文字(英文):</FormLabel>
                <Input
                  tag="textarea"
                  rows={8}
                  value={values.old_Content_en}
                  onChange={e => {
                    setFieldValue('old_Content_en', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.old_Content_en}</div>
              </FormGroup>
              <p className="text-secondary fs-14">
                說明文字會出現在前台的保固註冊表單的下方
              </p>
            </CardBody>
            <CardFooter className="pb-5 text-center">
              <Button
                color="primary"
                disabled={Object.keys(errors).length > 0 || loading}
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </CardFooter>
          </Card>
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default FormDescriptionText;
