import {
  CREATE_NEW_SHOP_BANNER_FAIL,
  CREATE_NEW_SHOP_BANNER_IDLE,
  CREATE_NEW_SHOP_BANNER_REQUEST,
  CREATE_NEW_SHOP_BANNER_SUCCESS,
  CREATE_SHOP_BANNER_SCHEDULE_FAIL,
  CREATE_SHOP_BANNER_SCHEDULE_IDLE,
  CREATE_SHOP_BANNER_SCHEDULE_REQUEST,
  CREATE_SHOP_BANNER_SCHEDULE_SUCCESS,
  DELETE_SHOP_BANNER_FAIL,
  DELETE_SHOP_BANNER_IDLE,
  DELETE_SHOP_BANNER_REQUEST,
  DELETE_SHOP_BANNER_SUCCESS,
  GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL,
  GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST,
  GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS,
  GET_ONE_SHOP_BANNER_FAIL,
  GET_ONE_SHOP_BANNER_REQUEST,
  GET_ONE_SHOP_BANNER_SUCCESS,
  GET_SHOP_BANNER_COLOR_FAIL,
  GET_SHOP_BANNER_COLOR_REQUEST,
  GET_SHOP_BANNER_COLOR_SUCCESS,
  GET_SHOP_BANNER_LIST_FAIL,
  GET_SHOP_BANNER_LIST_REQUEST,
  GET_SHOP_BANNER_LIST_SUCCESS,
  SORTING_SHOP_BANNER_FAIL,
  SORTING_SHOP_BANNER_IDLE,
  SORTING_SHOP_BANNER_REQUEST,
  SORTING_SHOP_BANNER_SUCCESS,
  UPDATE_SHOP_BANNER_COLOR_FAIL,
  UPDATE_SHOP_BANNER_COLOR_IDLE,
  UPDATE_SHOP_BANNER_COLOR_REQUEST,
  UPDATE_SHOP_BANNER_COLOR_SUCCESS,
  UPDATE_SHOP_BANNER_FAIL,
  UPDATE_SHOP_BANNER_IDLE,
  UPDATE_SHOP_BANNER_REQUEST,
  UPDATE_SHOP_BANNER_SUCCESS,
} from 'redux/constants/data/shopBannerConstants';

export const getDiscountCompaignOptionsReducer = (
  state = { error: null, loading: false, options: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST:
      return { error: null, loading: true, options: [], success: false };
    case GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS:
      return { ...state, loading: false, options: payload };
    case GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getShopBannerListReducer = (
  state = { error: null, list: [], loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SHOP_BANNER_LIST_REQUEST:
      return { error: null, list: [], loading: true, success: false };
    case GET_SHOP_BANNER_LIST_SUCCESS:
      return { ...state, list: payload, loading: false };
    case GET_SHOP_BANNER_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const createNewShopBannerReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_NEW_SHOP_BANNER_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_NEW_SHOP_BANNER_REQUEST:
      return { ...state, loading: true };
    case CREATE_NEW_SHOP_BANNER_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_NEW_SHOP_BANNER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const deleteShopBannerReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_SHOP_BANNER_IDLE:
      return { error: null, loading: false, success: false };
    case DELETE_SHOP_BANNER_REQUEST:
      return { ...state, loading: true };
    case DELETE_SHOP_BANNER_SUCCESS:
      return { ...state, loading: false, success: true };
    case DELETE_SHOP_BANNER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOneShopBannerReducer = (
  state = { data: null, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_SHOP_BANNER_REQUEST:
      return { data: null, error: null, loading: true, success: false };
    case GET_ONE_SHOP_BANNER_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case GET_ONE_SHOP_BANNER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateShopBannerReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SHOP_BANNER_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_SHOP_BANNER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SHOP_BANNER_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_SHOP_BANNER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const createShopBannerScheduleReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_SHOP_BANNER_SCHEDULE_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_SHOP_BANNER_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case CREATE_SHOP_BANNER_SCHEDULE_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_SHOP_BANNER_SCHEDULE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getShopBannerColorReducer = (
  state = { data: null, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SHOP_BANNER_COLOR_REQUEST:
      return { data: null, error: null, loading: true, success: false };
    case GET_SHOP_BANNER_COLOR_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case GET_SHOP_BANNER_COLOR_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateShopBannerColorReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SHOP_BANNER_COLOR_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_SHOP_BANNER_COLOR_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SHOP_BANNER_COLOR_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_SHOP_BANNER_COLOR_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const sortingShopBannerReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SORTING_SHOP_BANNER_IDLE:
      return { error: null, loading: false, success: false };
    case SORTING_SHOP_BANNER_REQUEST:
      return { ...state, loading: true };
    case SORTING_SHOP_BANNER_SUCCESS:
      return { ...state, loading: false, success: true };
    case SORTING_SHOP_BANNER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
