import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import style from '../../styles/layout.module.css';

import ContentSettings from './ContentSettings';
import DiscountSettings from './DiscountSettings';
import RulesSettings from './RulesSettings';

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

const TabContainer = ({
  setIsContentSettingsModalOpen,
  setCurrentData,
  setIsEdit,
  onDelete,
}) => {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <React.Fragment>
      <Nav pills>
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="點數啟用設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="給點設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={3}
          text="折抵設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <ContentSettings
            setIsContentSettingsModalOpen={setIsContentSettingsModalOpen}
            setCurrentData={setCurrentData}
            setIsEdit={setIsEdit}
            onDelete={onDelete}
          />
        </TabPane>
        <TabPane tabId={2}>
          <RulesSettings />
        </TabPane>
        <TabPane tabId={3}>
          <DiscountSettings />
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

export default TabContainer;
