import Layout from 'components/Layout';
import ContactUsEmailModal from 'components/websiteSettings/ContactUsEmailModal';
import React from 'react';
import { useEffect } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col } from 'reactstrap';
import {
  getCaptchaCode,
  getContactUsContext,
  updateCaptchaCode,
} from 'redux/actions/data/websiteSettingsActions';
import {
  UPDATE_CAPTCHA_CODE_IDLE,
  UPDATE_CONTACT_US_IDLE,
} from 'redux/constants/data/websiteSettingsContants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import { useToggle } from 'utils/customHooks';

import style from '../styles/layout.module.css';

const WebsiteSettings = () => {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.getCaptchaCode);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateCaptchaCode);
  const { success: updateContextSuccess, error: updateContextError } =
    useSelector(state => state.updateContactUsContext);
  const { result } = useSelector(state => state.getContactUsContext);

  const { userInfo } = useSelector(state => state.userLogin);

  const [isContactUsEmailModalOpen, setIsContactUsEmailModalOpen] = useToggle();

  useEffect(() => {
    dispatch(getCaptchaCode());
    dispatch(getContactUsContext());
  }, []);

  useEffect(() => {
    CommonUtil.consoleLog({ anno: 'response', data });
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(result) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: result });
    }
  }, [result]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_CAPTCHA_CODE_IDLE });
      dispatch(getCaptchaCode());
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: UPDATE_CAPTCHA_CODE_IDLE });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (updateContextSuccess) {
      MessageUtil.toastSuccess('儲存成功');
      dispatch({ type: UPDATE_CONTACT_US_IDLE });
      dispatch(getContactUsContext());
      setIsContactUsEmailModalOpen();
    }

    if (updateContextError) {
      MessageUtil.alertWanring('儲存失敗', updateContextError);
      dispatch({ type: UPDATE_CONTACT_US_IDLE });
    }
  }, [updateContextSuccess, updateContextError]);

  const updatingCaptchaCode = () => {
    dispatch(updateCaptchaCode());
  };

  return (
    <Layout
      pageTitle="網站設定"
      items={[{ isActive: false, page_name: '網站設定' }]}
    >
      <Col>
        <Card className={style.search_card}>
          <div
            className={`${style.card_header} ${style.card_header_dark}`}
            style={{
              alignItems: 'center',
              backgroundColor: '#656565',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>會員註冊-手機驗證萬用碼</div>
            <div style={{ fontSize: '10px' }}>每三個月自動更新密碼</div>
          </div>
          <div className={style.card_body}>
            <div
              className={'mb-3'}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: '170px',
              }}
            >
              <div className="fw-bold" style={{ fontSize: '24px' }}>
                {data?.content}
              </div>
              <div
                style={{
                  color: '#0077ff',
                  cursor: 'pointer',
                  fontSize: '14px',
                  textDecoration: 'underline',
                }}
                onClick={updatingCaptchaCode}
              >
                <span className="me-1">
                  <FaRedoAlt />
                </span>
                更新密碼
              </div>
            </div>
            <div className="fs-14">
              更新日期：{' '}
              {`${data?.updateDate}  ${data?.updateTime}  ${data?.updater}`}
            </div>
          </div>
        </Card>
        <Card className={style.search_card}>
          <div
            className={`${style.card_header} ${style.card_header_dark}`}
            style={{
              alignItems: 'center',
              backgroundColor: '#656565',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>聯絡我們</div>
            <div style={{ backgroundColor: 'white', borderRadius: '5px' }}>
              <Button
                color="primary"
                outline

                // className="btn-sm"
                onClick={setIsContactUsEmailModalOpen}
              >
                編輯
              </Button>
            </div>
          </div>
          <div className={style.card_body}>
            <div className={style.formGroup}>
              <label>消費者：</label>
              <div>{result.content?.consumer || '--'}</div>
            </div>
            <div className={style.formGroup}>
              <label>商業合作：</label>
              <div>{result.content?.business || '--'}</div>
            </div>
            <div>{'更新日期：2023/03/12 16:39:57'}</div>
          </div>
        </Card>
      </Col>
      {isContactUsEmailModalOpen && (
        <ContactUsEmailModal
          isOpen={isContactUsEmailModalOpen}
          toggle={setIsContactUsEmailModalOpen}
          datas={result?.content}
        />
      )}
    </Layout>
  );
};

export default WebsiteSettings;
