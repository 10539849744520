import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateContactUsContext } from 'redux/actions/data/websiteSettingsActions';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const ContactUsEmailModal = ({ isOpen, toggle, datas }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.updateContactUsContext);
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      business: datas.business,
      consumer: datas.consumer,
    },
    onSubmit: () => {
      dispatch(updateContactUsContext(values));
    },
    validationSchema: Yup.object().shape({
      business: Yup.string().required('必填'),
      consumer: Yup.string().required('必填'),
    }),
  });

  return (
    <Modal zIndex={2000} isOpen={isOpen} centered size="xl">
      <ModalHeader className={style.modal_head} toggle={toggle}>
        聯絡我們收件者
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup} style={{ alignItems: 'start' }}>
          <label className="required w150">消費者</label>
          <div style={{ width: '100%' }}>
            <small>
              <li>可同時輸入多組，請以半形逗號 , 區隔</li>
            </small>
            <div style={{ margin: '1rem' }} />
            <Input
              type="textarea"
              style={{ height: '100px' }}
              placeholder="請輸入電子信箱"
              id="consumer"
              value={values.consumer}
              onChange={handleChange}
              invalid={errors.consumer && touched.consumer}
            />
          </div>
        </div>
        <div className={style.formGroup} style={{ alignItems: 'start' }}>
          <label className="required w150">商業合作</label>
          <div style={{ width: '100%' }}>
            <small>
              <li>可同時輸入多組，請以半形逗號 , 區隔</li>
            </small>
            <div style={{ margin: '1rem' }} />
            <Input
              type="textarea"
              style={{ height: '100px' }}
              placeholder="請輸入電子信箱"
              value={values.business}
              onChange={handleChange}
              id="business"
              invalid={errors.business && touched.business}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"

          // type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactUsEmailModal;
