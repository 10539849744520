import Layout from 'components/Layout';
import SelectedList from 'components/productBinding/SelectedList';
import UnselectedList from 'components/productBinding/UnselectedList';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Row,
} from 'reactstrap';
import {
  getBindingProductList,
  getGift,
} from 'redux/actions/data/giftsActions';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import { ADD_BINDING_PRODUCT_IDLE } from 'redux/constants/data/GiftsConstants';
import MessageUtil from 'utils/MessageUtil';
import useQuery from 'utils/useQuery';

import SearchSelect from '../components/OrderSearchSelector';
import style from '../styles/layout.module.css';
const productMap = ['一般商品', '隱形賣場', '組合商品'];
const ProductBinding = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const id = useMemo(() => {
    return query.value.id;
  }, []);

  const [isAdd, setIsAdd] = useState(null);
  const [productType, setProductType] = useState('');

  const { success: addSuccess, error: addError } = useSelector(
    state => state.addBindingProduct,
  );

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: { label: '請選擇', value: 0 },
      freebieId: id,
      globalCatId: 0,
      isAdded: true,
      keyword: '',
      page: 1,
      page_size: 10,
      productType: '一般商品',
      searchType: '',
    },
    onSubmit: values => {
      setIsAdd(values.isAdded);
      setProductType(values.productType);
      const body = {
        ...values,
        globalCatId: values.category.value,
        productType: productMap.indexOf(values.productType) + 1,
      };
      delete body.category;
      dispatch(getBindingProductList(body));
    },
  });

  const { gift } = useSelector(state => state.getGift);
  const { loading: getLoading, productList } = useSelector(
    state => state.getBindingProductList,
  );
  const { options, loading: optionsLoading } = useSelector(
    state => state.getProductCategoryOptions,
  );

  const pageChangeHandler = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    dispatch(getGift(id));
    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (addSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: ADD_BINDING_PRODUCT_IDLE });
      handleSubmit();
    }

    if (addError) {
      MessageUtil.alertWanring(addError);
      dispatch({ type: ADD_BINDING_PRODUCT_IDLE });
    }
  }, [addSuccess, addError]);

  return (
    <Layout
      pageTitle={'贈品加入商品頁'}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: false, page_name: '贈品管理', to: '/GiftManagement' },
        { isActive: false, page_name: '贈品加入商品頁' },
      ]}
    >
      <Card className="mb-3">
        <Row className="justify-content-start align-items-center p-2">
          <Col
            className="text-center"
            style={{
              maxWidth: '350px',
              minWidth: '350px',
            }}
          >
            <img
              className={style.w200}
              src={
                gift?.freebieImgInfo?.length
                  ? gift?.freebieImgInfo[0].imageUrl
                  : ''
              }
              alt={
                gift?.freebieImgInfo?.length
                  ? gift?.freebieImgInfo[0].imageUrl
                  : ''
              }
            />
          </Col>
          <Col
            style={{
              minWidth: '350px',
            }}
          >
            <div>{`贈品序號：${gift.productcode ?? '-'}`}</div>
            <div>{`贈品名稱：${gift.name ?? '-'}`}</div>
          </Col>
        </Row>
      </Card>
      <Card className="mb-3">
        <CardHeader className={style.card_header}>搜尋條件</CardHeader>
        <CardBody className={style.card_body}>
          <div
            className={style.formGroup}
            style={{ gap: '0.5rem', marginRight: '1.5rem' }}
          >
            <label className="col-form-label mr-2">商品類型設定</label>
            <SearchSelect
              className={style['form-select']}
              options={[
                {
                  code: true,
                  name: '已加入',
                },
                {
                  code: false,
                  name: '未加入',
                },
              ]}
              emptyVal={true}
              name="isAdded"
              setFieldValue={setFieldValue}
              values={values}
              noAll={true}
            />
            <SearchSelect
              className={style['form-select']}
              options={[
                {
                  code: '一般商品',
                  name: '一般商品',
                },
                {
                  code: '組合商品',
                  name: '組合商品',
                },
                {
                  code: '隱形賣場',
                  name: '隱形賣場',
                },
              ]}
              name="productType"
              setFieldValue={(key, value) => {
                setFieldValue('searchType', '');
                setFieldValue('keyword', '');
                setFieldValue('globalCatId', 0);
                setFieldValue(key, value);
              }}
              values={values}
              noAll={true}
            />
          </div>
          <div
            className={style.formGroup}
            style={{ gap: '0.5rem', marginRight: '1.5rem' }}
          >
            <label className="col-form-label mr-2">選擇商品</label>
            <SearchSelect
              className={style['form-select']}
              options={[
                {
                  code: '',
                  name: '所有商品',
                },
                {
                  code: '商品料號',
                  name: '商品料號',
                },
                {
                  code: '商品名稱',
                  name: '商品名稱',
                },
                {
                  code:
                    values.productType === '隱形賣場' ? '隱賣編號' : '商品分類',
                  name:
                    values.productType === '隱形賣場' ? '隱賣編號' : '商品分類',
                },
              ]}
              name="searchType"
              setFieldValue={(key, value) => {
                if (value === '商品分類' || value === '') {
                  setFieldValue('keyword', '');
                }
                if (value !== '商品分類') {
                  setFieldValue('globalCatId', 0);
                }
                setFieldValue(key, value);
              }}
              values={values}
              noAll={true}
            />

            {(values.searchType === '商品名稱' ||
              values.searchType === '隱賣編號' ||
              values.searchType === '商品料號') && (
              <Input
                id="keyword"
                className={style['form-select-shop-banner']}
                placeholder={`請輸入商品名稱、請輸入商品料號${
                  values.searchType === '隱賣編號' ? '、隱賣編號' : ''
                }`}
                onChange={handleChange}
              />
            )}
            {values.searchType === '商品分類' && (
              <Select
                isLoading={optionsLoading}
                isDisabled={optionsLoading}
                className={style['form-select-shop-banner']}
                options={options}
                value={values.category}
                onChange={option => {
                  setFieldValue('category', option);
                }}
              />
            )}
          </div>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            className={`${style.modal_button_submit}`}
            onClick={() => {
              setFieldValue('page', 1);
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </CardFooter>
      </Card>
      {isAdd !== null && (
        <Card className="mb-3">
          <CardBody>
            {isAdd && <SelectedList isAdd={isAdd} productType={productType} />}
            {!isAdd && (
              <UnselectedList isAdd={isAdd} productType={productType} />
            )}
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={productList?.current_page || 1}
                pageSize={productList?.page_size || 10}
                total={productList?.total_count || 0}
                onChange={pageChangeHandler}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </Layout>
  );
};

export default ProductBinding;
