export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST';
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS';
export const NEWS_LIST_FAIL = 'NEWS_LIST_FAIL';

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL';
export const NEWS_DELETE_CLEAR = 'NEWS_DELETE_CLEAR';

export const NEWS_DETAILS_REQUEST = 'NEWS_DETAILS_REQUEST';
export const NEWS_DETAILS_SUCCESS = 'NEWS_DETAILS_SUCCESS';
export const NEWS_DETAILS_FAIL = 'NEWS_DETAILS_FAIL';
export const NEWS_CLEAR_OUT = 'NEWS_CLEAR_OUT';
