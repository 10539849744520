import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  addBrandCategory,
  getBrandCategory,
  removeBrandCategory,
  updateBrandCategory,
  updateBrandCategorySort,
} from '../redux/actions/data/brandActions';
import {
  BRAND_CATEGORY_ADD_RESET,
  BRAND_CATEGORY_REMOVE_RESET,
  BRAND_CATEGORY_SORT_RESET,
  BRAND_CATEGORY_SUCCESS,
  BRAND_CATEGORY_UPDATE_RESET,
} from '../redux/constants/data/brandConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil.js';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function BrandCategory(props) {
  const dispatch = useDispatch();
  const { brandId } = useParams();
  const query = useQuery();
  const { location } = history;

  const [current_page, setCurrentPage] = useState(1);
  const [search_values, setSerachValues] = useState({ name: '', status: '' });

  const { loading, categoryListInfo, error } = useSelector(
    state => state.brandCategory,
  );

  // 將商品列表及排序列表分開
  const [currentPageItemList, setCurrentPageItemList] = useState([]);

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.brandCategoryAdd);

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.brandCategoryUpdate);

  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.brandCategoryRemove,
  );

  const {
    loading: sortLoading,
    success: sortSuccess,
    error: sortError,
  } = useSelector(state => state.brandCategorySort);

  useEffect(() => {
    // 排序就不更新目前頁面的商品
    if (categoryListInfo && sortModal) {
      setEditCategory(categoryListInfo.datas);
      return;
    }
    if (categoryListInfo && categoryListInfo.datas?.length > 0) {
      setCurrentPageItemList(categoryListInfo);
    } else if (categoryListInfo && categoryListInfo.datas?.length < 0) {
      setCurrentPageItemList(categoryListInfo);
    }
  }, [categoryListInfo]);

  useEffect(() => {
    if (!sortLoading) {
      if (sortSuccess) {
        MessageUtil.toastSuccess('更新成功!');
        toggleSortModal();
        dispatch(
          getBrandCategory(
            current_page,
            brandId,
            search_values.name,
            search_values.status,
          ),
        );
      }

      if (sortError) {
        MessageUtil.toastSuccess('更新失敗!');
        toggleSortModal();
      }
    }
    dispatch({ type: BRAND_CATEGORY_SORT_RESET });
  }, [sortLoading, sortSuccess, sortError]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);
      setSerachValues({ name: queryKeyword, status: queryStatus });
      dispatch(getBrandCategory(queryPage, brandId, queryKeyword, queryStatus));
    } else {
      setCurrentPage(1);
      setSerachValues({ name: '', status: '' });
      dispatch(getBrandCategory(1, brandId));
    }
    return () => {
      setCurrentPage(1);
      setSerachValues({ name: '', status: '' });
      dispatch({ payload: {}, type: BRAND_CATEGORY_SUCCESS });
    };
  }, [brandId, location.search]);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(getBrandCategory(current_page, brandId));
      toggleModal();
    }

    if (addError && addError.code === APIUtil.Code_Brand_Not_Exists) {
      MessageUtil.alertWanring('品牌不存在', '');
    } else if (
      addError &&
      addError.code === APIUtil.Code_BrandCategory_Name_Duplicate
    ) {
      MessageUtil.alertWanring('名稱重複', '');
    }

    return () => {
      dispatch({ type: BRAND_CATEGORY_ADD_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (updateSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('更新成功!');
      dispatch(getBrandCategory(current_page, brandId));
      toggleModal();
    }

    if (updateError && updateError.code === APIUtil.Code_Brand_Not_Exists) {
      MessageUtil.alertWanring('品牌不存在', '');
    } else if (
      updateError &&
      updateError.code === APIUtil.Code_BrandCategory_Name_Duplicate
    ) {
      MessageUtil.alertWanring('名稱重複', '');
    } else if (updateError && updateError.code === APIUtil.Code_Not_Found) {
      MessageUtil.alertWanring('資料錯誤', '');
    }
    return () => {
      dispatch({ type: BRAND_CATEGORY_UPDATE_RESET });
    };
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      if (categoryListInfo.datas.length !== 1) {
        dispatch(
          getBrandCategory(
            current_page,
            brandId,
            search_values.name,
            search_values.status,
          ),
        );
      } else {
        dispatch(
          getBrandCategory(
            current_page - 1,
            brandId,
            search_values.name,
            search_values.status,
          ),
        );
        if (search_values.name || search_values.status) {
          history.push(
            `${location.pathname}?keyword=${search_values.name}&status=${
              search_values.status
            }&page=${current_page - 1}`,
          );
        } else {
          history.push(`${location.pathname}?page=${current_page - 1}`);
        }
        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError && deleteError.code === APIUtil.Code_Not_Found) {
      MessageUtil.alertWanring('資料錯誤', '');
    }
    return () => {
      dispatch({ type: BRAND_CATEGORY_REMOVE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleSearchChanged = event => {
    setSerachValues({
      ...search_values,
      [event.target.dataset.id]: event.target.value,
    });
  };

  const handleSearch = () => {
    history.push(
      `${location.pathname}?keyword=${search_values.name}&status=${
        search_values.status
      }&page=${1}`,
    );
  };
  const clearSearchForm = () => {
    setSerachValues({ name: '', status: '' });
    history.push(`${location.pathname}`);
  };

  /**** modal  ****/
  const toggleModal = () => setModal(prev => !prev);
  const [isOpenModal, setModal] = useState(false);
  const [editData, setEditData] = useState({});

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });

    return schema;
  };

  const handleOpenEditModal = data => {
    setEditData(data);
    toggleModal();
  };

  const handleOpenAddModal = () => {
    setEditData({
      brandCatId: 0,
      brandId: props.match.params.brandId,
      name: '',
      status: '',
    });
    toggleModal();
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    history.push(
      `${location.pathname}?keyword=${search_values.name}&status=${search_values.status}&page=${page}`,
    );
  };
  const callAdd = editInfo => {
    dispatch(addBrandCategory(editInfo));
  };
  const callUpd = editInfo => {
    dispatch(updateBrandCategory(editInfo));
  };
  const callDel = cateId => {
    MessageUtil.delConfirm(() => {
      dispatch(removeBrandCategory(cateId));
    });
  };

  const handleModalSubmit = values => {
    let editParam = {
      brandCatId: editData.id ?? 0,
      brandId: props.match.params.brandId,
      name: values.name,
      name_en: values.name_en,
      status: values.status,
    };

    if (editParam.brandCatId === 0) {
      callAdd(editParam);
    } else {
      callUpd(editParam);
    }
  };

  /**** drag n drop  ****/
  const toggleSortModal = () => setSortModal(prev => !prev);
  const [sortModal, setSortModal] = useState(false);
  const [editCategory, setEditCategory] = useState([]);

  const handleOnDragEnd = result => {
    const items = Array.from(editCategory);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);

    setEditCategory(items);
  };

  const updateSort = () => {
    const sort_objects = editCategory.map((brand, index) => {
      return {
        id: brand.id,
        sort: index + 1,
      };
    });
    dispatch(updateBrandCategorySort(sort_objects));
  };

  const handleSortModalOpen = () => {
    toggleSortModal();
    dispatch(getBrandCategory(0, brandId));
  };

  return (
    <Layout
      pageTitle={`${categoryListInfo?.brandName || '-'}類別設定`}
      items={[
        { isActive: false, page_name: '品牌管理', to: '/BrandControl' },
        { isActive: true, page_name: '類別管理' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <div className={style.show_flex}>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label style={{ lineHeight: '48px', width: '100px' }}>
                分類名稱
              </label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                data-id="name"
                value={search_values.name}
                onChange={handleSearchChanged}
              />
            </div>

            <div className={style.formGroup}>
              <label style={{ lineHeight: '48px', width: '80px' }}>狀態</label>
              <select
                className="form-control"
                data-id="status"
                value={search_values.status}
                onChange={handleSearchChanged}
              >
                <option value="">全部</option>
                <option value="上架">上架</option>
                <option value="下架">下架</option>
              </select>
            </div>
          </div>
        </CardBody>
        <CardFooter className="text-end border-0 pb-3">
          <Button
            type="button"
            color="light"
            className={style.modal_button_cancel}
            onClick={clearSearchForm}
            style={{ marginRight: '1rem' }}
          >
            取消
          </Button>
          <Button className={style.modal_button_submit} onClick={handleSearch}>
            搜尋
          </Button>
        </CardFooter>
      </Card>

      <Card className={style.card}>
        <div className={style.show_flex}>
          <button
            type="button"
            className="btn btn-success w100"
            onClick={handleOpenAddModal}
          >
            新增
          </button>
          <Button
            type="button"
            className={style.sort_button}
            onClick={handleSortModalOpen}
          >
            排序
          </Button>
        </div>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && categoryListInfo && categoryListInfo.total_count > 0
              ? categoryListInfo.total_count
              : 0}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>排序</th>
                <th>分類名稱</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {currentPageItemList !== [] ? (
                  currentPageItemList?.datas
                    ?.sort((a, b) => {
                      return a.sort - b.sort;
                    })
                    .map(data => (
                      <tr key={data.id}>
                        <td>{data.sort}</td>
                        <td>{data.name}</td>
                        <td>{data.status}</td>
                        <td>
                          <div>
                            <span>新增：</span>
                            <span className="d-inline-block me-2">
                              {data.createDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.createTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.creator}
                            </span>
                          </div>
                          {data.updateDate.length > 0 && (
                            <div>
                              <span>修改：</span>
                              <span className="d-inline-block me-2">
                                {data.updateDate}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.updateTime}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.updater}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <Button
                            className="m-2"
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => handleOpenEditModal(data)}
                          >
                            編輯分類
                          </Button>
                          <Link
                            className="m-2 btn btn-outline-success btn-sm"
                            to={
                              +brandId === 18
                                ? `/BrandCategoryUUPrds?uubrandCatId=${data.id}`
                                : `/BrandCategoryPrds/${data.brandId}/${data.id}`
                            }
                          >
                            商品設定
                          </Link>
                          <Link
                              className="m-2 btn btn-outline-success btn-sm"
                              to={`/BrandCategoryGroupPrds/${data.brandId}/${data.id}`}
                          >
                            組合商品設定
                          </Link>
                          <Button
                            className="m-2"
                            type="button"
                            color="danger"
                            outline
                            size="sm"
                            onClick={() => callDel(data.id)}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {categoryListInfo && categoryListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={categoryListInfo.current_page}
              defaultPageSize={categoryListInfo.page_size}
              total={categoryListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={1000}
        centered
        size="lg"
        isOpen={isOpenModal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal} className={style.modal_head}>
          編輯分類名稱
        </ModalHeader>
        <Formik
          initialValues={{
            name: editData.name,
            name_en: editData.name_en,
            status: editData.status,
          }}
          onSubmit={handleModalSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form>
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required w100">分類名稱</label>
                  <div className="form-item">
                    <Field className="form-control" name="name" />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">分類名稱(英)</label>
                  <div className="form-item">
                    <Field className="form-control" name="name_en" />
                    <ErrorMessage
                      name="name_en"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">狀態</label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Label className="me-3">
                        {' '}
                        <Field
                          name="status"
                          type="radio"
                          value="上架"
                        /> 上架{' '}
                      </Label>
                      <Label className="me-3">
                        {' '}
                        <Field
                          name="status"
                          type="radio"
                          value="下架"
                        /> 下架{' '}
                      </Label>

                      <ErrorMessage
                        name="status"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="light"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading || updateLoading}
                >
                  {addLoading ||
                    (updateLoading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ))}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={sortModal}
        toggle={toggleSortModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleSortModal} className={style.modal_head}>
          分類排序
        </ModalHeader>
        <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {editCategory.map(({ id, name, status }, index) => (
                    <Draggable key={id} draggableId={`${id}`} index={index}>
                      {provided => (
                        <li
                          className="drag_data"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <span className="title">{index + 1}</span>
                          <span className="brand_name">
                            {name}
                            {status === '下架' && (
                              <span className="badge bg-danger ms-4">下架</span>
                            )}
                          </span>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleSortModal}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={updateSort}
            disabled={sortLoading}
          >
            {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
}
