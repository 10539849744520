import Layout from 'components/Layout';
import React, { useState } from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';


import { TabItem } from '../components/tabItem';
import MailManage from '../components/uuCafeContactUs/MailManage';
import SubjectManage from '../components/uuCafeContactUs/SubjectManage';

const UUCafeContactUs = () => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <Layout
      pageTitle="聯絡我們"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '聯絡我們' },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="主旨管理"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="Mail設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <SubjectManage />
        </TabPane>
        <TabPane tabId={2}>
          <MailManage />
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default UUCafeContactUs;
