import {
  SEOSETTINGS_LIST_REQUEST,
  SEOSETTINGS_LIST_SUCCESS,
  SEOSETTINGS_LIST_FAIL,
  SEOSETTINGS_DETAIL_REQUEST,
  SEOSETTINGS_DETAIL_SUCCESS,
  SEOSETTINGS_DETAIL_FAIL,
  SEOSETTINGS_UPDATE_REQUEST,
  SEOSETTINGS_UPDATE_SUCCESS,
  SEOSETTINGS_UPDATE_FAIL,
  SEOSETTINGS_DETAIL_UPDATE_REQUEST,
  SEOSETTINGS_DETAIL_UPDATE_SUCCESS,
  SEOSETTINGS_DETAIL_UPDATE_FAIL,
  SEOSETTINGS_UPDATE_IDLE,
  SEOSETTINGS_DETAIL_UPDATE_IDLE,
} from '../../constants/data/seoSettingsConstants';

export const seoSettingsListReducer = (
  state = { error: null, list: [], loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SEOSETTINGS_LIST_REQUEST:
      return { error: null, list: [], loading: true, success: false };
    case SEOSETTINGS_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case SEOSETTINGS_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const seoSettingDetailReducder = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SEOSETTINGS_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case SEOSETTINGS_DETAIL_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case SEOSETTINGS_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const seoSettingsUpdateReducer = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SEOSETTINGS_UPDATE_IDLE:
      return { detail: {}, error: null, loading: false, success: false };
    case SEOSETTINGS_UPDATE_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case SEOSETTINGS_UPDATE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case SEOSETTINGS_UPDATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const seoSettingsDetailUpdateReducer = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case SEOSETTINGS_DETAIL_UPDATE_IDLE:
      return { detail: {}, error: null, loading: false, success: false };
    case SEOSETTINGS_DETAIL_UPDATE_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case SEOSETTINGS_DETAIL_UPDATE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case SEOSETTINGS_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
