export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';

export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST';
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS';
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL';
export const CATEGORY_ADD_RESET = 'CATEGORY_ADD_RESET';

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET';

export const CATEGORY_SORT_REQUEST = 'CATEGORY_SORT_REQUEST';
export const CATEGORY_SORT_SUCCESS = 'CATEGORY_SORT_SUCCESS';
export const CATEGORY_SORT_FAIL = 'CATEGORY_SORT_FAIL';
export const CATEGORY_SORT_RESET = 'CATEGORY_SORT_RESET';

export const CREATE_PRODUCT_CATEGORY_IDLE = 'CREATE_PRODUCT_CATEGORY_IDLE';
export const CREATE_PRODUCT_CATEGORY_REQUEST =
  'CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAIL = 'CREATE_PRODUCT_CATEGORY_FAIL';
