import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_JOURNEY_ADD_FAIL,
  UUCAFE_JOURNEY_ADD_REQUEST,
  UUCAFE_JOURNEY_ADD_SUCCESS,
  UUCAFE_JOURNEY_FAIL,
  UUCAFE_JOURNEY_REQUEST,
  UUCAFE_JOURNEY_SUCCESS,
  UUCAFE_JOURNEY_UPDATE_FAIL,
  UUCAFE_JOURNEY_UPDATE_REQUEST,
  UUCAFE_JOURNEY_UPDATE_SUCCESS,
  UUCAFE_JOURNEY_LIST_FAIL,
  UUCAFE_JOURNEY_LIST_REQUEST,
  UUCAFE_JOURNEY_LIST_SUCCESS,
  UUCAFE_JOURNEY_PAGE_REQUEST,
  UUCAFE_JOURNEY_PAGE_SUCCESS,
  UUCAFE_JOURNEY_PAGE_FAIL,
  UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST,
  UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS,
  UUCAFE_JOURNEY_PAGE_UPDATE_FAIL,
} from '../../constants/data/uuCafeJourneyConstants';

/*
* @Params
* {
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string",
  "keyword": "string"
  }
*
*/
export const getCafeJourneList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_JOURNEY_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_JOURNEY_LIST_REQUEST,
      successConstant: UUCAFE_JOURNEY_LIST_SUCCESS,
      url: '/Backend/Cafe_Journey/list',
    });
  };
};

export const getCafeJourneDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_JOURNEY_FAIL,
      method: 'get',
      requestConstant: UUCAFE_JOURNEY_REQUEST,
      successConstant: UUCAFE_JOURNEY_SUCCESS,
      url: `/Backend/Cafe_Journey/journey/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
name string
name_en string
*/
export const addCafeJourneDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_JOURNEY_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_JOURNEY_ADD_REQUEST,
      successConstant: UUCAFE_JOURNEY_ADD_SUCCESS,
      url: '/Backend/Cafe_Journey/journey/add',
    });
  };
};

export const updateCafeJourneDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_JOURNEY_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_JOURNEY_UPDATE_REQUEST,
      successConstant: UUCAFE_JOURNEY_UPDATE_SUCCESS,
      url: '/Backend/Cafe_Journey/journey/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeJourneDetail =  async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_Journey/journey/delete',
  });
};

export const getJourneyPage = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_JOURNEY_PAGE_FAIL,
      method: 'get',
      requestConstant: UUCAFE_JOURNEY_PAGE_REQUEST,
      successConstant: UUCAFE_JOURNEY_PAGE_SUCCESS,
      url: '/Backend/Cafe_PageSetting/aboutUs',
    });
  };
};

/*
@Params : data : {
  "title": "string",
  "title_en": "string",
  "content": "string",
  "content_en": "string"
}
*/
export const updateJourneyPage = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_JOURNEY_PAGE_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST,
      successConstant: UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS,
      url: '/Backend/Cafe_PageSetting/aboutUs/save',
    });
  };
};
