import Layout from 'components/Layout';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { FaRedoAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Card, Input, Table } from 'reactstrap';
import {
  getStoreList,
  getStoreServiceOptions,
} from 'redux/actions/data/storeActions';
import style from 'styles/layout.module.css';
import CommonUtil from 'utils/CommonUtil';
import history from 'utils/createHistory';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';

export default function StoreList(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const { id, model } = useParams();
  const query = useQuery();

  const [current_page, setCurrentPage] = useState(1);

  const [keyword, setKeyword] = useState('');
  const [mapType, setMapType] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [status, setStatus] = useState('上架');

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, storeListInfo, error } = useSelector(
    state => state.storeList,
  );
  const { options } = useSelector(state => state.storeServiceOption);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({ pathname: '/Login', state: '/StoreList' });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({ pathname: '/Login', state: '/StoreList' });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  useEffect(() => {
    dispatch(getStoreServiceOptions());
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryMapType = query.value.mapType;
      const queryService = query.value.serviceId;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryMapType || queryService || queryStatus) {
        setKeyword(queryKeyword);
        setMapType(queryMapType);
        setServiceId(queryService);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(
          getStoreList({
            keyword: queryKeyword,
            mapType: queryMapType,
            page: queryPage,
            serviceId: queryService,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getStoreList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setMapType('');
      setServiceId('');
      setStatus('上架');

      //初始搜尋
      dispatch(getStoreList({ page: 1 }));
    }
  }, [location.search]);

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setStatus('');
    setMapType('');
    setServiceId('');
    dispatch(
      getStoreList({
        page: 1,
      }),
    );
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e?.preventDefault();
    setCurrentPage(1);
    dispatch(
      getStoreList({
        keyword,
        mapType,
        page: 1,
        serviceId,
        status,
      }),
    );
    history.push(
      `${
        location.pathname
      }?keyword=${keyword}&mapType=${mapType}&serviceId=${serviceId}&status=${status}&page=${1}`,
    );
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getStoreList({
        keyword,
        page,
        status,
      }),
    );
    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const [syncLoading, setSyncLoading] = useState();
  const handleSyncLocation = async () => {
    setSyncLoading(true);
    await request({
      method: 'get',
      url: '/Backend/SyncERP/SyncLocation',
    });
    setSyncLoading(false);
    handleSearch();
  };

  useEffect(() => {
    if (storeListInfo) {
      if (JSON.stringify(storeListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: storeListInfo });
      }
    }
  }, [storeListInfo]);

  return (
    <Layout
      pageTitle="據點管理"
      items={[{ isActive: true, page_name: '據點管理' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  id="keyword"
                  placeholder="請輸入關鍵字"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="type">
                  類型
                </label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  id="type"
                  value={mapType}
                  onChange={e => setMapType(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">選擇門市</option>
                  <option value="3">UU門市</option>
                  <option value="1">經銷</option>
                </Input>
              </div>

              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="item">
                  服務項目
                </label>
                <Input
                  type="select"
                  className={style['form-select-medium']}
                  id="item"
                  value={serviceId}
                  onChange={e => setServiceId(e.target.value)}
                  style={{ margin: 0 }}
                >
                  {options && options.length > 0 ? (
                    [{ label: '選擇服務項目', value: '' }, ...options].map(
                      (option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ),
                    )
                  ) : (
                    <option>無選項</option>
                  )}
                </Input>
              </div>

              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  id="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option>上架</option>
                  <option>下架</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className={style.renew_panel}>
          <span style={{ wordBreak: 'keep-all' }}>
            更新時間：{storeListInfo?.updateTime || '--'}
          </span>
          <button
            className="btn btn-warning btn-sm px-3 py-2 text-white ms-3"
            onClick={handleSyncLocation}
            disabled={syncLoading}
          >
            <span className="me-2">
              {syncLoading ? (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <FaRedoAlt />
              )}
            </span>
            更新
          </button>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && storeListInfo && storeListInfo.total_count > 0
              ? storeListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>據點名稱</th>
                <th>類型</th>
                <th style={{ minWidth: '180px' }}>地址</th>
                <th>電話</th>
                <th style={{ minWidth: '180px' }}>品牌</th>
                <th>服務項目</th>
                <th>營業時間</th>
                <th>狀態</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {storeListInfo ? (
                  storeListInfo.datas?.map((data, index) => (
                    <tr key={data.id}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index +
                          1 +
                          (current_page - 1) * storeListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.companyname}
                      </td>
                      <td className="text-nowrap">
                        {data.companyname.split(' ')[0] === 'Urban'
                          ? 'UU門市'
                          : '經銷'}
                      </td>
                      <td>{data.address}</td>
                      <td className="text-nowrap">{data.phone}</td>
                      <td style={{ wordBreak: 'normal' }}>
                        {data.brands.map(item => item.brand).join('、')}
                      </td>
                      <td>
                        {data.serviceItems.map((item, index) => (
                          <img
                            src={item.contentImgUrl}
                            alt={item.name}
                            key={index}
                            width="24px"
                            className="m-2"
                          />
                        ))}
                      </td>
                      <td style={{ whiteSpace: 'pre-wrap' }}>{data.remark}</td>
                      <td>{data.status}</td>
                      <td>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/StoreDetail/${data.id}`}
                        >
                          編輯
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && storeListInfo && storeListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={storeListInfo.current_page}
              defaultPageSize={10}
              total={storeListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
    </Layout>
  );
}
