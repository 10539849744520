import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { editOrderRemark } from 'redux/actions/data/orderActions';
import { ORDER_REMARK_EDIT_IDLE } from 'redux/constants/data/orderConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const OrderRemarkModal = ({ isOpen, toggle, info, callBack }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.editOrderRemark);
  const { location } = history;
  const { values, handleSubmit, handleChange, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderItemId: info.requestBody.orderItemId,
      orderRemark: info.requestBody.orderRemark,
    },
    onReset() {
      dispatch({ type: ORDER_REMARK_EDIT_IDLE });
    },
    onSubmit(formModel) {
      CommonUtil.consoleLog({ anno: 'request body', data: formModel });
      dispatch(editOrderRemark(formModel));
    },
  });

  useEffect(() => {
    if (!success) return;
    MessageUtil.toastSuccess('儲存成功!');
    history.push(`${location.pathname}${location.search}&reload=true`);
    handleReset();
    toggle();
    callBack();
  }, [success]);

  useEffect(() => {
    if (!error) return;
    MessageUtil.alertWanring('儲存失敗', error);
    handleReset();
  }, [error]);

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        商店訂單備註
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <small>您可在此備註訂單相關資訊，作為訂單處理時的小提醒</small>
        </div>
        {Object.keys(info).map((key, index) => {
          if (key !== 'requestBody') {
            return (
              <div key={index} className={`fs-14 mb-2 ${style.show_flex}`}>
                <div className="w80">{key}</div>
                <div>{info[key]}</div>
              </div>
            );
          }
        })}
        <div className={`fs-14 mb-2 ${style.show_flex}`}>
          <div className="w80 mb-2">訂單備註</div>
          <Input
            type="textarea"
            value={values.orderRemark || ''}
            style={{ height: '150px', width: '100%' }}
            id="orderRemark"
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
        >
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderRemarkModal;
