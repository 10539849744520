import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import { deleteJob, getJobList } from '../redux/actions/data/userActions';
import { JOB_DELETE_RESET } from '../redux/constants/data/userConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';

export default function JoinUs(props) {
  const dispatch = useDispatch();

  const [current_page, setCurrentPage] = useState(1);

  const { loading, jobListInfo, error } = useSelector(state => state.jobList);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.jobDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/JoinUs' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/JoinUs' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getJobList({ page: 1 }));
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getJobList({ page: 1 }));
    }

    return () => {
      dispatch({ type: JOB_DELETE_RESET });
    };
  }, [deleteSuccess]);

  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteJob(id));
    });
  };

  useEffect(() => {
    if (JSON.stringify(jobListInfo) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: jobListInfo });
    }
  }, [jobListInfo]);

  return (
    <Layout
      pageTitle="加入我們"
      items={[{ isActive: false, page_name: '加入我們' }]}
    >
      <Card className={style.card}>
        <Link className="btn btn-success w100" to="/JoinUsDetail/0">
          新增
        </Link>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && jobListInfo && jobListInfo.total_count > 0
              ? jobListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>職務名稱</th>
                <th>工作地點</th>
                <th>工作職務</th>
                <th>工作類型</th>
                <th>刊登區間</th>
                <th>連結</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動時間</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {jobListInfo && jobListInfo.datas?.length > 0 ? (
                  jobListInfo.datas.map((data, index) => (
                    <tr key={data.id}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index + 1 + (current_page - 1) * jobListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.jobName}</td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.workPlace}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.workDuty}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.jobType}</td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.startDate}-
                        {data.endDate
                          ? moment(new Date(data.endDate))
                              .add(24, 'hours')
                              .format('yyyy/MM/DD')
                              .toString()
                          : ''}
                      </td>
                      <td>
                        <a
                          href={data.jobLink}
                          className="m-2 btn btn-outline-success btn-sm"
                          target="_blank"
                        >
                          連結
                        </a>
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate.length > 0 && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/JoinUsDetail/${data.id}`}
                          className="m-2 btn btn-outline-primary btn-sm"
                        >
                          編輯
                        </Link>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleDelete(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </Card>
    </Layout>
  );
}
