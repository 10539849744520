import {
  UUCAFE_MENU_SETTINGS_FAIL,
  UUCAFE_MENU_SETTINGS_REQUEST,
  UUCAFE_MENU_SETTINGS_SUCCESS,
  UUCAFE_MENU_SETTINGS_UPDATE_FAIL,
  UUCAFE_MENU_SETTINGS_UPDATE_REQUEST,
  UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeMenuSettingsConstants';

export const cafeMenuSettingsReducer = (
  state = {
    detail: {
      bookingUrl: '',
      shopUrl: '',
      updateDateTime: '',
      updater: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MENU_SETTINGS_REQUEST:
      return {
        ...state,
        detail: {
          bookingUrl: '',
          shopUrl: '',
          updateDateTime: '',
          updater: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MENU_SETTINGS_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MENU_SETTINGS_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MENU_SETTINGS_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MENU_SETTINGS_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
