import {
  DOWNLOAD_TEMPLATE_FILE_FAIL,
  DOWNLOAD_TEMPLATE_FILE_REQUEST,
  DOWNLOAD_TEMPLATE_FILE_SUCCESS,
  GET_BLOCK_LIST_FAIL,
  GET_BLOCK_LIST_REQUEST,
  GET_BLOCK_LIST_SUCCESS,
  SAVE_BLOCK_LIST_FAIL,
  SAVE_BLOCK_LIST_REQUEST,
  SAVE_BLOCK_LIST_SUCCESS,
  UPLOAD_BLOCK_LIST_FAIL,
  UPLOAD_BLOCK_LIST_REQUEST,
  UPLOAD_BLOCK_LIST_SUCCESS,
} from 'redux/constants/data/blockListConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getBlockList = data => {
  const url = '/Backend/MemberTransBlackList/list';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_BLOCK_LIST_FAIL,
      method: 'post',
      requestConstant: GET_BLOCK_LIST_REQUEST,
      successConstant: GET_BLOCK_LIST_SUCCESS,
      url,
    });
  };
};

export const saveBlockList = data => {
  const url = '/Backend/MemberTransBlackList/save';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SAVE_BLOCK_LIST_FAIL,
      method: 'post',
      requestConstant: SAVE_BLOCK_LIST_REQUEST,
      successConstant: SAVE_BLOCK_LIST_SUCCESS,
      url,
    });
  };
};

export const downloadTemplateFile = () => {
  const url = '/Backend/MemberTransBlackList/exportTemplate';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DOWNLOAD_TEMPLATE_FILE_FAIL,
      method: 'get',
      requestConstant: DOWNLOAD_TEMPLATE_FILE_REQUEST,
      successConstant: DOWNLOAD_TEMPLATE_FILE_SUCCESS,
      url,
    });
  };
};

export const uploadBlockList = data => {
  const url = '/Backend/MemberTransBlackList/import';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPLOAD_BLOCK_LIST_FAIL,
      method: 'post',
      requestConstant: UPLOAD_BLOCK_LIST_REQUEST,
      successConstant: UPLOAD_BLOCK_LIST_SUCCESS,
      url,
    });
  };
};
