import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  addFAQCategory,
  deleteFAQCategory, getAllFAQCategoryList,
  getFAQCategory, updateFAQCategoryListSort,
} from '../redux/actions/data/FAQActions';
import {
  FAQ_CATEGORY_ADD_RESET,
  FAQ_CATEGORY_DELETE_RESET,
} from '../redux/constants/data/FAQConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const FAQCategoryListSortModal = ({ isOpen = false, toggle }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateSort = useCallback(async (list = []) => {
    setLoading(true);
    try {
      await updateFAQCategoryListSort(
        list.map((l, index) => {
          return {
            ...l,
            sort: index,
          };
        }),
      );
      dispatch(getFAQCategory({ page: 1 }));
      toggle();
      MessageUtil.alertSuccess('更新成功');
    } catch (e) {
      MessageUtil.alertWanring('更新失敗', e);
    }
    setLoading(false);
  }, []);

  const handleOnDragEnd = result => {
    const items = Array.from(list);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setList(items);
  };

  useEffect(() => {
    if (isOpen) {
      getAllFAQCategoryList().then(res => {
        setList(res?.result?.datas || []);
      });
    }
  }, [isOpen]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        問題管理排序
      </ModalHeader>
      <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="brands">
            {provided => (
              <ul
                className="drag_list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map((l, index) => (
                  <Draggable key={l.id} draggableId={`${l.id}`} index={index}>
                    {provided => (
                      <li
                        className="drag_data"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <span className="title">{index + 1}</span>
                        <span className="brand_name">
                          {l.name}
                        </span>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="light"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={() => {
            updateSort(list);
          }}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FAQCategory = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [modal, setModal] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');

  const [modal_cateName, setModalCateName] = useState('');
  const [modal_cateName_en, setModalCateNameEn] = useState('');
  const [modal_status, setModalStatus] = useState('');
  const [cateId, setCateId] = useState(0);

  const { loading, FAQCategoryInfo, error } = useSelector(
    state => state.FAQCategory,
  );
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.FAQCategoryAdd);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.FAQCategoryDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/FAQCategory' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/FAQCategory' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        setKeyword(queryKeyword);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(
          getFAQCategory({
            keyword: queryKeyword,
            page: queryPage,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getFAQCategory({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      dispatch(getFAQCategory({ page: 1 }));
    }
  }, [location.search]);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(getFAQCategory({ page: current_page }));
      handleOpenAddModal();
    }

    // if (addError && addError.code === APIUtil.Code_Brand_Not_Exists) {
    //   MessageUtil.alertWanring('品牌不存在', '')
    // } else if (
    //   addError &&
    //   addError.code === APIUtil.Code_BrandCategory_Name_Duplicate
    // ) {
    //   MessageUtil.alertWanring('名稱重複', '')
    // }

    if (addError) {
      MessageUtil.alertWanring(addError);
    }

    return () => {
      dispatch({ type: FAQ_CATEGORY_ADD_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (FAQCategoryInfo.datas?.length !== 1) {
        dispatch(getFAQCategory({ page: current_page }));
      } else {
        dispatch(getFAQCategory({ page: current_page - 1 }));

        history.push(
          `${location.pathname}?keyword=${keyword}&status=${status}&page=${
            current_page - 1
          }`,
        );

        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: FAQ_CATEGORY_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (FAQCategoryInfo && FAQCategoryInfo?.datas?.length > 0) {
      setEditCategory(FAQCategoryInfo.datas);
    }
  }, [FAQCategoryInfo]);

  const handleOpenAddModal = () => {
    setModal(!modal);
    setModalCateName('');
    setModalCateNameEn('');
    setModalStatus('');
    setCateId(0);
  };

  const handleSearch = e => {
    e.preventDefault();
    dispatch(getFAQCategory({ keyword: keyword, page: 1, status: status }));
    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setKeyword('');
    setStatus('');
    setCurrentPage(1);
    dispatch(getFAQCategory({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handleOpenEditModal = data => {
    handleOpenAddModal();
    setModalCateName(data.name);
    setModalCateNameEn(data.name_en);
    setModalStatus(data.status);
    setCateId(data.id);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getFAQCategory({
        keyword,
        page,
        status,
      }),
    );

    if (keyword) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSubmit = values => {
    let formData = new FormData();

    formData.append('id', cateId);
    formData.append('name', values.name);
    formData.append('name_en', values.name_en);
    formData.append('status', values.status);

    dispatch(addFAQCategory(formData));
  };

  const callDel = cateId => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteFAQCategory(cateId));
    });
  };

  /**** drag n drop  ****/
  const [sortModal, setSortModal] = useState(false);
  const [editCategory, setEditCategory] = useState([]);

  const toggleSortModal = () => {
    setSortModal(false);
    dispatch(getFAQCategory({ page: 1 }));
  };
  const handleSortModalOpen = () => {
    setSortModal(true);
  };
  const handleOnDragEnd = result => {
    const items = Array.from(editCategory);

    const [newOrderItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, newOrderItem);

    setEditCategory(items);
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });

    return schema;
  };

  useEffect(() => {
    if (FAQCategoryInfo) {
      if (JSON.stringify(FAQCategoryInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: FAQCategoryInfo });
      }
    }
  }, [FAQCategoryInfo]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout
      pageTitle="常見問題"
      items={[
        { isActive: false, page_name: '常見問題' },
        { isActive: true, page_name: '類別管理' },
      ]}
    >
      <FAQCategoryListSortModal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
      />
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="keyword">
                  分類名稱
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="請輸入分類名稱"
                  id="keyword"
                  value={keyword}
                  onChange={e => {
                    setKeyword(e.target.value);
                  }}
                />
              </div>
              <div className={style.formGroup}>
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  id="status"
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value);
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="上架">上架</option>
                  <option value="下架">下架</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <div className={style.card}>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-success w100"
            onClick={handleOpenAddModal}
          >
            新增
          </button>
          <span className="flex-grow-1" />
          <div>
            <Button
              type="button"
              className={style.sort_button}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              排序
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && FAQCategoryInfo && FAQCategoryInfo.total_count > 0
              ? FAQCategoryInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>分類名稱</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {FAQCategoryInfo ? (
                  FAQCategoryInfo.datas.map((data, index) => (
                    <tr key={data.id}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index +
                          1 +
                          (current_page - 1) * FAQCategoryInfo.page_size}
                      </td>
                      <td>{data.name}</td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate.length > 0 && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          編輯分類
                        </Button>
                        <Button
                          className="m-2"
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {FAQCategoryInfo && FAQCategoryInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={FAQCategoryInfo.current_page}
              defaultPageSize={FAQCategoryInfo.page_size}
              total={FAQCategoryInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={handleOpenAddModal}
        backdrop="static"
      >
        <ModalHeader toggle={handleOpenAddModal} className={style.modal_head}>
          編輯分類名稱
        </ModalHeader>
        <Formik
          initialValues={{
            name: modal_cateName,
            name_en: modal_cateName_en,
            status: modal_status,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form>
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required w100">分類名稱</label>
                  <div className="form-item">
                    <Field name="name" className="form-control" />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">分類名稱(英)</label>
                  <div className="form-item">
                    <Field name="name_en" className="form-control" />
                    <ErrorMessage
                      name="name_en"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">狀態</label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Field
                        className="my-auto me-2"
                        id="up"
                        name="status"
                        type="radio"
                        value="上架"
                      />
                      <label htmlFor="up" className="me-5">
                        上架
                      </label>
                      <Field
                        className="my-auto me-2"
                        id="down"
                        name="status"
                        type="radio"
                        value="下架"
                      />
                      <label htmlFor="down">下架</label>
                      <ErrorMessage
                        name="status"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={handleOpenAddModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading}
                >
                  {addLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={sortModal}
        toggle={toggleSortModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleSortModal} className={style.modal_head}>
          分類排序
        </ModalHeader>
        <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {editCategory.map(({ id, name, status }, index) => (
                    <Draggable key={id} draggableId={`${id}`} index={index}>
                      {provided => (
                        <li
                          className="drag_data"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <span className="title">{index + 1}</span>
                          <span className="brand_name">
                            {name}
                            {status === '下架' && (
                              <span className="badge bg-danger ms-4">下架</span>
                            )}
                          </span>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleSortModal}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}

            // onClick={updateSort}
            // disabled={sortLoading}
          >
            {/* {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
};

export default FAQCategory;
