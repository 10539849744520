export const GET_BLOCK_LIST_REQUEST = 'GET_BLOCK_LIST_REQUEST';
export const GET_BLOCK_LIST_SUCCESS = 'GET_BLOCK_LIST_SUCCESS';
export const GET_BLOCK_LIST_FAIL = 'GET_BLOCK_LIST_FAIL';

export const SAVE_BLOCK_LIST_IDLE = 'SAVE_BLOCK_LIST_IDLE';
export const SAVE_BLOCK_LIST_REQUEST = 'SAVE_BLOCK_LIST_REQUEST';
export const SAVE_BLOCK_LIST_SUCCESS = 'SAVE_BLOCK_LIST_SUCCESS';
export const SAVE_BLOCK_LIST_FAIL = 'SAVE_BLOCK_LIST_FAIL';

export const DOWNLOAD_TEMPLATE_FILE_IDLE = 'DOWNLOAD_TEMPLATE_FILE_IDLE';
export const DOWNLOAD_TEMPLATE_FILE_REQUEST = 'DOWNLOAD_TEMPLATE_FILE_REQUEST';
export const DOWNLOAD_TEMPLATE_FILE_SUCCESS = 'DOWNLOAD_TEMPLATE_FILE_SUCCESS';
export const DOWNLOAD_TEMPLATE_FILE_FAIL = 'DOWNLOAD_TEMPLATE_FILE_FAIL';

export const UPLOAD_BLOCK_LIST_IDLE = 'UPLOAD_BLOCK_LIST_IDLE';
export const UPLOAD_BLOCK_LIST_REQUEST = 'UPLOAD_BLOCK_LIST_REQUEST';
export const UPLOAD_BLOCK_LIST_SUCCESS = 'UPLOAD_BLOCK_LIST_SUCCESS';
export const UPLOAD_BLOCK_LIST_FAIL = 'UPLOAD_BLOCK_LIST_FAIL';
