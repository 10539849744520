import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';

import { deletePromo } from '../../redux/actions/data/promoActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import { TableHasNoData, TableLoading } from '../TableHasNoData';
import TableHead from '../TableHead';

const PromoListTable = () => {
  const { list, loading } = useSelector(state => state.promoList);
  const { location } = history;

  const handleDeleteItems = useCallback(async ({ id }) => {
    try {
      await deletePromo({ id });
      setTimeout(() => {
        history.push(
          `${location.pathname}${
            (location.search && `${location.search}&`) || '?'
          }page=1&reload=${new Date().getTime()}`,
        );
      }, 500);
      MessageUtil.toastSuccess('刪除成功');
    } catch (e) {
      MessageUtil.toastWarning('刪除失敗', e);
    }
  }, []);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th nowrap="nowrap">活動序號</th>
            <th nowrap="nowrap">活動名稱</th>
            <th nowrap="nowrap">活動時間</th>
            <th nowrap="nowrap">優惠碼</th>
            <th>
              <div>折抵類型</div>
              <div>面額</div>
            </th>
            <th>
              <div>活動範圍</div>
              <div>數量</div>
            </th>
            <th className={style['date-column']}>更新日期</th>
            <th>活動狀態</th>
            <th>功能</th>
          </tr>
        </TableHead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {list.datas?.length ? (
              list.datas.map(data => (
                <tr key={JSON.stringify(data)}>
                  <td className="text-start text-nowrap">
                    {/* 活動序號 */}
                    <div>{data.promoNo ?? '-'}</div>
                  </td>
                  <td className="text-start">
                    {/* 活動名稱 */}
                    <div>{data.name}</div>
                  </td>
                  <td>
                    {/* 領取期限 */}
                    <div className="text-nowrap">
                      {data.redeemStartTime || '-'} 起
                    </div>
                    <div className="text-nowrap">
                      {data.redeemEndTime || '-'} 止
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 優惠券代碼 */}
                    {data.promoCode || '-'}
                  </td>
                  <td className="text-center">
                    {/* 折抵類型面額 */}
                    <div>{data.discountType === 1 && '消費現折'}</div>
                    <div>{data.discountType === 2 && '消費打折'}</div>
                    <div>
                      {data.discountType === 1 &&
                        `NT$${Number(data.discountAmount).toLocaleString()}`}
                    </div>
                    <div>
                      {data.discountType === 2 &&
                        `${data.discountAmount}折`}
                    </div>
                    <div></div>
                  </td>
                  <td>
                    {/* 適用商品 */}
                    <div>
                      {data.productRange === 'all' && '全店商品'}
                      {data.productRange === 'picked' && '指定商品'}
                    </div>
                    {/* 數量 */}
                    <div>
                      {data.productRange === 'picked' &&
                        `${Number(~~data.productCount).toLocaleString()} 件`}
                    </div>
                  </td>
                  <td>
                    {/* 更新日期 */}
                    <div className="text-nowrap">
                      {data.updateDate} {data.updateTime}
                    </div>
                    <div className="text-nowrap">{data.updater}</div>
                  </td>
                  <td>
                    {/* 活動狀態 */}
                    {data?.statusNameList?.map(s => {
                      return <div key={s}>{s}</div>;
                    })}
                  </td>
                  <td style={{ minWidth: '200px' }}>
                    {!(data?.statusList || []).includes(3) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="primary"
                        outline
                        onClick={() => history.push(`/PromoSetting/${data.id}`)}
                      >
                        編輯
                      </Button>
                    )}
                    {(data?.statusList || []).includes(3) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="primary"
                        outline
                        onClick={() =>
                          history.push(`/PromoSetting/${data.id}?type=view`)
                        }
                      >
                        查看
                      </Button>
                    )}
                    <Button
                      className="m-2"
                      size="sm"
                      color="primary"
                      outline
                      onClick={() =>
                        history.push(`/PromoSetting/${data.id}?type=copy`)
                      }
                    >
                      複製
                    </Button>
                    {data?.statusList?.includes(1) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="danger"
                        outline
                        onClick={() => {
                          MessageUtil.submitConfirm({
                            doneFun: () => {
                              handleDeleteItems(data);
                            },
                            title: '確認是否刪除',
                          });
                        }}
                      >
                        刪除
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default memo(PromoListTable);
