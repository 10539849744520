import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Input, Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { BindingSettingsModal } from '../components/WarrantyNumSearch/BindingSettingsModal';
import {
  getShippingDealerList,
  getShippingExcel,
  getWarrantyMasterList,
} from '../redux/actions/data/warrantyActions';
import { SHIPPING_EXCEL_PRODUCE_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function WarrantySearch() {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const page_size = 10;
  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [bindingStatus, setBindingStatus] = useState({
    label: '全部',
    value: '',
  });
  const [dealer, setDealer] = useState({ label: '請選擇經銷商', value: '' });
  const [warrantyStartDate, setWarrantyStartDate] = useState(null);
  const [warrantyEndDate, setWarrantyEndDate] = useState(null);
  const [shipperStartDate, setShipperStartDate] = useState(null);
  const [shipperEndDate, setShipperEndDate] = useState(null);
  const [isRegistered, setIsRegistered] = useState(null);

  const [modal, setModal] = useState(false);
  const [rangeType, setRangeType] = useState({ label: '全部', value: '' });

  const { loading, warrantyMasterListInfo } = useSelector(
      state => state.warrantyMasterList,
  );
  const { shippingDealerList, loading: shippingDealerListLoading } =
      useSelector(state => state.shippingDealerList);
  const { error: warrantyInfoIsError, warrantyInfo } = useSelector(
      state => state.warrantyInfo,
  );

  const { result } = useSelector(state => state.shippingExcelCreate);
  const [preSearch, setPreSearch] = useState('');

  useEffect(() => {
    if (decodeURI(location.search) === preSearch) {
      return;
    }
    if (location.search) {
      const queryPage = query.value.page;
      const queryKeyword = query.value.keyword;
      const queryDealer = query.value.dealer;
      const queryStatus = query.value.status;
      const queryRangeType = query.value.rangeType;
      const queryWarrantyStart = query.value.warrantyStartDate;
      const queryWarrantyEnd = query.value.warrantyEndDate;
      const queryShipperStart = query.value.shipperStartDate;
      const queryShipperEnd = query.value.shipperEndDate;
      const queryIsRegistered = query.value.isRegistered;

      setCurrentPage(queryPage);

      if (
          queryKeyword ||
          queryDealer ||
          queryStatus ||
          queryRangeType ||
          queryWarrantyStart ||
          queryWarrantyEnd ||
          queryShipperStart ||
          queryShipperEnd
      ) {
        setKeyword(queryKeyword ? JSON.parse(queryKeyword) : '');
        setDealer({
          ...dealer,
          value: queryDealer ? JSON.parse(queryDealer) : '',
        });

        // setRangeType(queryRangeType);
        setWarrantyStartDate(
            queryWarrantyStart !== 'null'
                ? new Date(JSON.parse(queryWarrantyStart))
                : null,
        );
        setWarrantyEndDate(
            queryWarrantyEnd !== 'null'
                ? new Date(JSON.parse(queryWarrantyEnd))
                : null,
        );
        setShipperStartDate(
            queryShipperStart !== 'null'
                ? new Date(JSON.parse(queryShipperStart))
                : null,
        );
        setShipperEndDate(
            queryShipperEnd !== 'null'
                ? new Date(JSON.parse(queryShipperEnd))
                : null,
        );
        setIsRegistered(JSON.parse(queryIsRegistered));

        //儲存搜尋及頁數
        dispatch(
            getWarrantyMasterList({
              isRegistered: JSON.parse(queryIsRegistered),
              keyword: queryKeyword ? JSON.parse(queryKeyword) : '',
              page: queryPage,
              page_size,
              serailEndDate:
                  queryWarrantyEnd !== 'null' ? JSON.parse(queryWarrantyEnd) : null,
              serailPeriod: queryRangeType,
              serailStartDate:
                  queryWarrantyStart !== 'null'
                      ? JSON.parse(queryWarrantyStart)
                      : null,
              shipperEndDate:
                  queryShipperEnd !== 'null' ? JSON.parse(queryShipperEnd) : null,
              shipperStartDate:
                  queryShipperStart !== 'null'
                      ? JSON.parse(queryShipperStart)
                      : null,
              status: queryStatus,
              suppliercode: queryDealer ? JSON.parse(queryDealer) : '',
            }),
        );
      } else {
        //單純記錄頁數
        dispatch(
            getWarrantyMasterList({
              page: queryPage,
              page_size,
            }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setDealer({ label: '請選擇經銷商', value: '' });
      setBindingStatus({ label: '全部', value: '' });
      setRangeType({ label: '全部', value: '' });
      setWarrantyStartDate(null);
      setWarrantyEndDate(null);
      setShipperStartDate(null);
      setShipperEndDate(null);
      setIsRegistered(null);

      //初始搜尋
      dispatch(getShippingDealerList());
      dispatch(
          getWarrantyMasterList({
            page: 1,
            page_size,
          }),
      );
    }
    setPreSearch(decodeURI(location.search));
  }, [location.search]);

  useEffect(() => {
    if (result) {
      window.open(result);
    }

    return () => {
      dispatch({ type: SHIPPING_EXCEL_PRODUCE_RESET });
    };
  }, [result]);

  useEffect(() => {
    if (warrantyInfoIsError) {
      MessageUtil.alertWanring(warrantyInfoIsError?.data?.message);
      setModal(false);
    }
  }, [warrantyInfoIsError, modal]);

  useEffect(() => {
    handleSearch();
    dispatch(getShippingDealerList());
  }, []);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleSearch = e => {
    e?.preventDefault();
    setCurrentPage(1);

    history.push(
        `${location.pathname}?keyword=${JSON.stringify(
            keyword,
        )}&dealer=${JSON.stringify(dealer.value)}&status=${
            bindingStatus.value
        }&rangeType=${rangeType.value}&warrantyStartDate=${
            warrantyStartDate
                ? JSON.stringify(moment(warrantyStartDate).toISOString())
                : null
        }&warrantyEndDate=${
            warrantyEndDate
                ? JSON.stringify(moment(warrantyEndDate).toISOString())
                : null
        }&shipperStartDate=${
            shipperStartDate
                ? JSON.stringify(moment(shipperStartDate).toISOString())
                : null
        }&shipperEndDate=${
            shipperEndDate
                ? JSON.stringify(moment(shipperEndDate).toISOString())
                : null
        }&page=${1}&isRegistered=${isRegistered}&v=${new Date().getTime()}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setDealer({ label: '請選擇經銷商', value: '' });
    setBindingStatus({ label: '全部', value: '' });
    setRangeType({ label: '全部', value: '' });
    setWarrantyStartDate(null);
    setWarrantyEndDate(null);
    setShipperStartDate(null);
    setShipperEndDate(null);
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    if (
        keyword ||
        dealer.value ||
        rangeType.value ||
        bindingStatus.value ||
        warrantyStartDate ||
        warrantyEndDate ||
        shipperStartDate ||
        shipperEndDate ||
        isRegistered
    ) {
      history.push(
          `${location.pathname}?keyword=${JSON.stringify(
              keyword,
          )}&dealer=${JSON.stringify(dealer.value)}&status=${
              bindingStatus.value
          }&rangeType=${rangeType.value}&warrantyStartDate=${
              warrantyStartDate
                  ? JSON.stringify(moment(warrantyStartDate).toISOString())
                  : null
          }&warrantyEndDate=${
              warrantyEndDate
                  ? JSON.stringify(moment(warrantyEndDate).toISOString())
                  : null
          }&shipperStartDate=${
              shipperStartDate
                  ? JSON.stringify(moment(shipperStartDate).toISOString())
                  : null
          }&shipperEndDate=${
              shipperEndDate
                  ? JSON.stringify(moment(shipperEndDate).toISOString())
                  : null
          }&page=${page}&isRegistered=${isRegistered}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleExport = () => {
    const exportObject = {
      isRegistered,
      keyword,
      page: current_page,
      page_size,
      serailEndDate: warrantyEndDate,
      serailPeriod: rangeType.value,
      serailStartDate: warrantyStartDate,
      shipperEndDate,
      shipperStartDate,
      status: bindingStatus.value,
      suppliercode: dealer.value,
    };
    dispatch(getShippingExcel(exportObject));
  };

  const serialNumberBindingStatusList = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '無',
      value: '無',
    },
    {
      label: '正常',
      value: '正常',
    },
    {
      label: '取消',
      value: '取消',
    },
  ];

  const rangeTypeList = [
    {
      label: '全部',
      value: '',
    },
    {
      label: '保固序號',
      value: '保固序號',
    },
    {
      label: '產品序號',
      value: '產品序號',
    },
  ];

  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (warrantyMasterListInfo) {
      if (JSON.stringify(warrantyMasterListInfo) !== '{}') {
        CommonUtil.consoleLog({
          anno: 'response',
          data: warrantyMasterListInfo,
        });
      }
    }
  }, [warrantyMasterListInfo]);

  return (
      <Layout
          pageTitle="序號/出貨查詢"
          items={[
            { isActive: false, page_name: '序號/綁定及出貨' },
            { isActive: true, page_name: '序號/出貨查詢' },
          ]}
      >
        <Card className={style.search_card}>
          <CardHeader className="bg-white">搜尋條件</CardHeader>
          <CardBody className={style.card_body}>
            <form onSubmit={handleSearch}>
              <div className={style.show_flex}>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2" htmlFor="qkeyword">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      placeholder="保固序號、保固網址、產品名稱、出貨備註"
                      onChange={e => setKeyword(e.target.value.trim())}
                      value={keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className={'col-form-label mr-2'} htmlFor="dealer">
                    經銷商
                  </label>
                  <Select
                      isLoading={shippingDealerListLoading}
                      isDisabled={shippingDealerListLoading}
                      name="dealer"
                      className={style['form-select']}
                      options={shippingDealerList}
                      onChange={option => setDealer(option)}
                      value={dealer}
                      style={{ margin: 0 }}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2" htmlFor="selqStatus">
                    產品序號綁定
                  </label>
                  <Select
                      name="selqStatus"
                      className={style['form-select-short']}
                      options={serialNumberBindingStatusList}
                      onChange={option => setBindingStatus(option)}
                      value={bindingStatus}
                      style={{ margin: 0 }}
                  />
                </div>
              </div>
              <div className={style.show_flex}>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2" htmlFor="rangeType">
                    產生序號區間
                  </label>
                  <div>
                    <Select
                        name="rangeType"
                        className={style['form-select-medium']}
                        options={rangeTypeList}
                        onChange={option => setRangeType(option)}
                        value={rangeType}
                        style={{ margin: 0 }}
                    />
                  </div>
                  <DatePicker
                      className="form-control"
                      selected={
                        warrantyStartDate ? new Date(warrantyStartDate) : ''
                      }
                      onChange={date => setWarrantyStartDate(date)}
                      dateFormat="yyyy/MM/dd"
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      selected={warrantyEndDate ? new Date(warrantyEndDate) : ''}
                      onChange={date => setWarrantyEndDate(date)}
                      minDate={warrantyStartDate}
                      dateFormat="yyyy/MM/dd"
                  />
                </div>
              </div>
              <div className={style.show_flex}>
                <div className={style.formGroup}>
                  <label className="col-form-label mr-2">出貨區間</label>
                  <DatePicker
                      className="form-control"
                      selected={shipperStartDate ? new Date(shipperStartDate) : ''}
                      onChange={date => setShipperStartDate(date)}
                      dateFormat="yyyy/MM/dd"
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      selected={shipperEndDate ? new Date(shipperEndDate) : ''}
                      onChange={date => setShipperEndDate(date)}
                      minDate={shipperStartDate}
                      dateFormat="yyyy/MM/dd"
                  />
                </div>
              </div>
              <div className={style.show_flex}>
                <label style={{ marginRight: '1rem' }}>已註冊</label>
                <Input
                    type="select"
                    className={style['form-select-short']}
                    onChange={event => {
                      setIsRegistered(
                          event.target.value === '全部' ? null : event.target.value,
                      );
                    }}
                    value={isRegistered}
                >
                  <option value={null}>全部</option>
                  <option value={true}>是</option>
                  <option value={false}>否</option>
                </Input>
              </div>
              <div className={style.button_position}>
                <Button
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={clearSearchForm}
                >
                  取消
                </Button>
                <Button type="submit" className={style.modal_button_submit}>
                  查詢
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>

        <Card className={style.card}>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex gap-2 g-3">
                <Button
                    className="btn btn-success"
                    onClick={() => {
                      history.push('/BatchUnbind');
                    }}
                >
                  批次解除綁定
                </Button>
                <Button className="btn btn-dark" onClick={handleExport}>
                  匯出Excel
                </Button>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                共
                <span className="mx-1">
                {!loading && (warrantyMasterListInfo?.total_count || '0')}
              </span>
                筆
              </div>
            </div>
            <div className="table-responsive mb-5">
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>
                    <div>保固序號</div>
                    <div>產生時間</div>
                  </th>
                  <th>保固網址</th>
                  <th>
                    <div>產品序號</div>
                    <div>產生時間</div>
                  </th>
                  <th>國際條碼</th>
                  <th>經銷商</th>
                  <th>產品名稱</th>
                  <th>產品序號綁定</th>
                  <th>
                    <div>註冊會員</div>
                    <div>註冊時間</div>
                  </th>
                  <th>
                    <div>出貨備註</div>
                    <div>出貨時間</div>
                  </th>
                  <th>功能</th>
                </tr>
                </thead>
                {loading && <TableLoading />}
                {!loading && (
                    <tbody>
                    {warrantyMasterListInfo ? (
                        warrantyMasterListInfo.datas?.map(data => (
                            <tr key={JSON.stringify(data)}>
                              <td style={{ wordBreak: 'keep-all' }}>
                                <div>{data.warrantySerialNum ?? '--'}</div>
                                <div>
                                  <small className="text-nowrap">
                                    {data.warrantyCreateDate
                                        ? moment(data.warrantyCreateDate).format(
                                            'YYYY/MM/DD',
                                        )
                                        : '--'}
                                  </small>
                                </div>
                                <div>
                                  <small className="text-nowrap">
                                    {data.warrantyCreateTime ?? '--'}
                                  </small>
                                </div>
                              </td>
                              <td>{data.warrantyUrl || '--'}</td>
                              <td>
                                <div>
                                  {data.serialnumber ? data.serialnumber : '--'}
                                </div>
                                <div>
                                  <small className="text-nowrap">
                                    {data.serialnumberCreateDate
                                        ? moment(data.serialnumberCreateDate).format(
                                            'YYYY/MM/DD',
                                        )
                                        : '--'}
                                  </small>
                                </div>
                                <div>
                                  <small>{data.serialnumberCreateTime ?? '--'}</small>
                                </div>
                              </td>
                              <td>{data?.barcode ?? '--'}</td>
                              <td>{data.suppliername ?? '--'}</td>
                              <td>{data.productname ?? '--'}</td>
                              <td>{data.status}</td>
                              <td>
                                {data?.memberPhone ? (
                                    <a
                                        className="d-block text-nowrap"
                                        href={`/#/MemberDetails/${data.memberId}`}
                                        target="_blank"
                                    >
                                      {data.memberPhone?.replace('886', '0')}
                                    </a>
                                ) : (
                                    <div>--</div>
                                )}

                                <div>
                                  <small className="text-nowrap">
                                    {data.registerDate
                                        ? moment(data.registerDate).format(
                                            'YYYY/MM/DD HH:mm:ss',
                                        )
                                        : '--'}
                                  </small>
                                </div>
                              </td>
                              <td>
                                <div> {data.remark ?? '--'}</div>
                                <div>
                                  <small className="text-nowrap">
                                    {data.shipperCreateDate
                                        ? moment(data.shipperCreateDate).format(
                                            'YYYY/MM/DD',
                                        )
                                        : '--'}
                                  </small>
                                </div>
                                <div>
                                  <small className="text-nowrap">
                                    {data.shipperCreateTime || '--'}
                                  </small>
                                </div>
                              </td>
                              <td>
                                {data.status !== '無' && !data.isRegistered && (
                                    <Button
                                        className="m-2"
                                        color="primary"
                                        outline
                                        size="sm"
                                        onClick={() => {
                                          toggleModal();
                                          setInfo(data.shipperDtId);
                                        }}
                                    >
                                      綁定設定
                                    </Button>
                                )}
                              </td>
                            </tr>
                        ))
                    ) : (
                        <TableHasNoData />
                    )}
                    </tbody>
                )}
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                  current={warrantyMasterListInfo?.current_page || 1}
                  defaultPageSize={10}
                  total={warrantyMasterListInfo?.total_count || 0}
                  onChange={handlePageChange}
              />
            </div>
          </CardBody>
        </Card>
        {modal && (
            <BindingSettingsModal
                isOpen={modal}
                toggle={toggleModal}
                shipperDtId={info}
                onRefresh={handleSearch}
            />
        )}
      </Layout>
  );
}
