import UploadFileBox from 'components/UploadFileBox';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  getRepairBrandOption,
  getRepairModelOption,
} from 'redux/actions/data/repairActions';
import {
  createShopBannerSchedule,
  updateShopBanner,
} from 'redux/actions/data/shopBannerActions';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const EditScheduleSettingModal = ({ isOpen, toggle, type, isEdit }) => {
  const dispatch = useDispatch();
  const { data: shopBannerData } = useSelector(state => state.getOneShopBanner);
  const { repairBrandOptions } = useSelector(state => state.repairBrandOptions);
  const { repairModelOptions } = useSelector(state => state.repairModelOptions);
  const { loading: updateLoading } = useSelector(
    state => state.updateShopBanner,
  );
  const { loading: createLoading } = useSelector(
    state => state.createShopBannerSchedule,
  );

  const { values, setFieldValue, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      activityCode: isEdit
        ? shopBannerData?.innerType == 3
          ? shopBannerData?.innerValue
          : ''
        : '',
      brand:
        shopBannerData?.innerType == 1
          ? repairBrandOptions?.find(
            option => option?.value == shopBannerData?.innerValue,
          )
          : shopBannerData?.innerType == 2
            ? repairBrandOptions?.find(
              option => option?.value == shopBannerData?.brandId,
            )
            : { label: '請選擇', value: 0 },
      currentShopBanner_d: [],
      currentShopBanner_m: [],
      endTime: isEdit ? shopBannerData?.endTime : null,
      initBanner_d: isEdit
        ? shopBannerData?.fileUrl
          ? [{ url: shopBannerData?.fileUrl }]
          : null
        : [],
      initBanner_m: isEdit
        ? shopBannerData?.fileUrl
          ? [{ url: shopBannerData?.fileUrl_mobile }]
          : null
        : [],
      innerType: isEdit ? shopBannerData?.innerType : 0,
      innerValue: '',
      linkType: isEdit ? shopBannerData?.linkType : '',
      linkUrl: isEdit ? shopBannerData?.linkUrl || '' : '',
      model: isEdit
        ? shopBannerData?.innerType == 2
          ? repairModelOptions?.find(
            option => option?.value === shopBannerData?.innerValue,
          )
          : { label: '請選擇', value: '' }
        : { label: '請選擇', value: '' },
      shopBanner_d: [],
      shopBanner_m: [],
      startTime: isEdit ? shopBannerData?.startTime : null,
    },

    // validationSchema: ,
    onSubmit: () => {
      const body = {
        endTime: values.endTime
          ? moment(values.endTime).format('YYYY/MM/DD HH:mm')
          : null,
        files: values.shopBanner_d,
        files_mobile: values.shopBanner_m,
        id: shopBannerData.id,
        innerType: values.linkType === 'inner' ? values.innerType : 0,
        innerValue:
          values.linkType === 'inner'
            ? Number(values.innerType) === 1
              ? values.brand.value
              : Number(values.innerType) === 2
                ? values.model.value
                : Number(values.innerType) === 3
                  ? values.activityCode
                  : ''
            : '',
        isRemoveOrginalFile_mobile: false,
        isRemoveOriginalFile: false,
        isSchedule: type === '排程' ? true : false,
        linkType: values.linkType,
        linkUrl: values.linkUrl,

        // 排程帶列表 id, 非排程帶 0
        pid:
          type === '排程'
            ? isEdit
              ? shopBannerData.pid
              : shopBannerData.id
            : 0,
        startTime: values.startTime
          ? moment(values.startTime).format('YYYY/MM/DD HH:mm')
          : null,
      };

      const formData = new FormData();

      Object.keys(body)
        .filter(i => i !== 'files' && i !== 'files_mobile')
        .forEach(i => {
          if (body[i] !== null) {
            formData.append(i, body[i]);
          }
        });
      if (values.shopBanner_d.length > 0) {
        formData.append('files', values.shopBanner_d[0]);
      }
      if (values.shopBanner_m.length > 0) {
        formData.append('files_mobile', values.shopBanner_m[0]);
      }

      // dispatch(updateShopBanner(formData));
      if (!isEdit) {
        dispatch(createShopBannerSchedule(formData));
      } else {
        dispatch(updateShopBanner(formData));
      }
    },
    validationSchema: Yup.object().shape({
      activityCode: Yup.string()
        .nullable()
        .when(['innerType', 'linkType'], {
          is: (innerType, linkType) => {
            if (linkType === 'inner') {
              if (innerType == 3) {
                return true;
              }
            }
            return false;
          },
          then: Yup.string().nullable().required('必填'),
        }),
      brand: Yup.object().when(['innerType', 'linkType'], {
        is: (innerType, linkType) => {
          if (linkType === 'inner') {
            if (innerType == 1 || innerType == 2) {
              return true;
            }
          }
          return false;
        },
        then: Yup.object().shape({
          value: Yup.number().test('test-1', '必填', val => val !== 0),
        }),
      }),
      currentShopBanner_d: Yup.array().nullable(),
      currentShopBanner_m: Yup.array().nullable(),
      endTime: Yup.date().nullable().required('必填'),
      initBanner_d: Yup.array().nullable(),
      initBanner_m: Yup.array().nullable(),
      innerType: Yup.number()
        .nullable()
        .when('linkType', {
          is: val => val === 'inner',
          then: Yup.number().nullable().min(1, '必填').required('必填'),
        }),
      linkType: Yup.string().nullable().required('必填'),
      linkUrl: Yup.string()
        .nullable()
        .when('linkType', {
          is: val => val === 'outer',
          then: Yup.string().nullable().required('必填'),
        }),
      model: Yup.object().when(['innerType', 'linkType'], {
        is: (innerType, linkType) => {
          if (linkType === 'inner') {
            if (innerType == 2) {
              return true;
            }
          }
          return false;
        },
        then: Yup.object().shape({
          value: Yup.string().required('必填'),
        }),
      }),
      shopBanner_d: Yup.array()
        .nullable()
        .when(['initBanner_d', 'currentShopBanner_d'], {
          is: (initBanner_d, currentShopBanner_d) =>
            initBanner_d === null || currentShopBanner_d.length === 0,
          then: Yup.array().nullable().min(1, '必填').required('必填'),
        }),
      shopBanner_m: Yup.array()
        .nullable()
        .when(['initBanner_m', 'currentShopBanner_m'], {
          is: (initBanner_m, currentShopBanner_m) =>
            initBanner_m === null || currentShopBanner_m.length === 0,
          then: Yup.array().nullable().min(1, '必填').required('必填'),
        }),
      startTime: Yup.date().nullable().required('必填'),
    }),
  });

  const { options: activityOptions } = useSelector(
    state => state.getDiscountCompaignOptions,
  );

  useEffect(() => {
    dispatch(getRepairBrandOption(0, 0));

    // dispatch(getDiscountCompaignOptions());
  }, []);

  useEffect(() => {
    values.brand?.value &&
    dispatch(getRepairModelOption(0, 0, values.brand.value));
  }, [values.brand?.value]);

  useEffect(() => {
    if (shopBannerData) {
      CommonUtil.consoleLog({ anno: 'response', data: shopBannerData });
    }
  }, [shopBannerData]);

  return (
    <Modal zIndex={2000} size="xl" centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        {`${type}設定`}
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '1100px' }}>
        <div className={style.formGroup}>
          <label className="required w150">
            電腦版圖片
            {errors.shopBanner_d && touched.shopBanner_d && (
              <span className="ml-2 text-danger">{errors.shopBanner_d}</span>
            )}
          </label>
          <div>
            <UploadFileBox
              setFormFieldValue={setFieldValue}
              formFieldName="shopBanner_d"
              currentFileFieldName="currentShopBanner_d"
              uplodModel="images"
              displayFileWidth="1920"
              displayFileHeight="不限"
              displayFileSize="3MB"
              initFiles={values.initBanner_d || values.shopBanner_d}
            />
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="required w150">
            手機版圖片
            {errors.shopBanner_m && touched.shopBanner_m && (
              <span className="text-danger ml-2">{errors.shopBanner_m}</span>
            )}
          </label>
          <div>
            <UploadFileBox
              setFormFieldValue={setFieldValue}
              formFieldName="shopBanner_m"
              currentFileFieldName="currentShopBanner_m"
              uplodModel="images"
              displayFileWidth="800"
              displayFileHeight="不限"
              displayFileSize="3MB"
              initFiles={values.initBanner_m || values.shopBanner_m}
            />
          </div>
        </div>
        <div className={style.formGroup} style={{ marginTop: '3rem' }}>
          <label className="required w150 mb-2">
            連結網址
            {errors.linkType && touched.linkType && (
              <span className="text-danger ml-2">{errors.linkType}</span>
            )}
          </label>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
              marginRight: '2rem',
            }}
          >
            <input
              type="radio"
              style={{ height: '20px', width: '20px' }}
              checked={values.linkType === 'inner'}
              onChange={() => {
                setFieldValue('linkType', 'inner');
              }}
            />
            <span className="ml-2">選擇頁面</span>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
            }}
          >
            <input
              type="radio"
              style={{ height: '20px', width: '20px' }}
              checked={values.linkType === 'outer'}
              onChange={() => {
                setFieldValue('linkType', 'outer');
              }}
            />
            <span className="ml-2">自訂網址</span>
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="w150" />
          {values.linkType === 'inner' && (
            <React.Fragment>
              <Input
                type="select"
                style={{ margin: 0 }}
                className={style['form-select-shop-banner']}
                value={values.innerType}
                onChange={event => {
                  setFieldValue('innerType', event.target.value);
                }}
              >
                <option value="0">請選擇</option>
                <option value="1">品牌頁</option>
                <option value="2">商品頁</option>
                <option value="3">折扣活動頁</option>
              </Input>
              {errors.innerType && touched.innerType && (
                <span className="text-danger ml-2">{errors.innerType}</span>
              )}
            </React.Fragment>
          )}
          {values.linkType === 'outer' && (
            <React.Fragment>
              <Input
                className={style['form-select-shop-banner']}
                value={values.linkUrl}
                onChange={event => {
                  setFieldValue('linkUrl', event.target.value);
                }}
              />
              {errors.linkUrl && touched.linkUrl && (
                <span className="text-danger ml-2">{errors.linkUrl}</span>
              )}
            </React.Fragment>
          )}
        </div>
        <div className={style.formGroup}>
          <label className="w150" />
          {values.linkType === 'inner' && values.innerType == 1 && (
            <React.Fragment>
              <Select
                className={style['form-select-shop-banner']}
                value={values.brand}
                options={repairBrandOptions ? repairBrandOptions : []}
                onChange={option => {
                  setFieldValue('brand', option);
                  setFieldValue('model', { label: '請選擇', value: '' });
                }}
                style={{ margin: 0 }}
              />
              {errors.brand && touched.brand && (
                <span className="text-danger ml-2">{errors.brand.value}</span>
              )}
            </React.Fragment>
          )}
          {values.linkType === 'inner' && values.innerType == 2 && (
            <React.Fragment>
              <Select
                className={style['form-select-brand-model']}
                style={{ margin: 0 }}
                value={values.brand}
                options={repairBrandOptions ? repairBrandOptions : []}
                onChange={option => {
                  setFieldValue('brand', option);
                  setFieldValue('model', { label: '請選擇', value: '' });
                }}
              />
              {errors.brand && touched.brand && (
                <label className="text-danger ml-2">{errors.brand.value}</label>
              )}
              <Select
                className={style['form-select-brand-model']}
                style={{ margin: 0 }}
                value={values.model}
                options={repairModelOptions ? repairModelOptions : []}
                onChange={option => {
                  setFieldValue('model', option);
                }}
              />
              {errors.model && touched.model && (
                <span className="text-danger ml-2">{errors.model.value}</span>
              )}
            </React.Fragment>
          )}
          {/* {
            values.linkType === 'inner' &&
            values.innerType == 3 &&
            <Select 
              className={style["form-select-shop-banner"]}
              style={{ margin: 0 }}
              options={activityOptions ? activityOptions : []}
              value={values.activityCode}
              onChange={option => {
                setFieldValue('activityCode', option);
              }}
            />
          } */}
          {values.linkType === 'inner' && values.innerType == 3 && (
            <React.Fragment>
              <Input
                className={style['form-select-shop-banner']}
                placeholder="請輸入活動序號"
                value={values.activityCode}
                onChange={event => {
                  setFieldValue('activityCode', event.target.value);
                }}
              />
              {errors.activityCode && touched.activityCode && (
                <span className="text-danger ml-2">{errors.activityCode}</span>
              )}
            </React.Fragment>
          )}
        </div>
        {type === '排程' && (
          <React.Fragment>
            <div className={style.formGroup} style={{ marginTop: '3rem' }}>
              <label className="w150 required">排程</label>
              <div className={style.show_flex}>
                <label>開始時間</label>
                <div className={style['form-select']}>
                  <DatePicker
                    className="form-control"
                    dateFormat={'yyyy/MM/dd HH:mm'}
                    showTimeSelect
                    selected={
                      values.startTime ? moment(values.startTime).toDate() : ''
                    }
                    onChange={date => {
                      setFieldValue('startTime', date);
                    }}
                  />
                  {errors.startTime && touched.endTime && (
                    <span className="text-danger ml-2">{errors.startTime}</span>
                  )}
                </div>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="w150" />
              <div className={style.show_flex}>
                <label>結束時間</label>
                <div className={style['form-select']}>
                  <DatePicker
                    className="form-control"
                    dateFormat={'yyyy/MM/dd HH:mm'}
                    showTimeSelect
                    selected={
                      values.endTime ? moment(values.endTime).toDate() : ''
                    }
                    onChange={date => {
                      setFieldValue('endTime', date);
                    }}
                    minDate={
                      values.startTime
                        ? moment(values.startTime).toDate()
                        : moment().toDate()
                    }
                  />
                  {errors.endTime && touched.endTime && (
                    <span className="text-danger ml-2">{errors.endTime}</span>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={updateLoading || createLoading}
        >
          {updateLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          {createLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditScheduleSettingModal;
