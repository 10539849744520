import CategorySettingsModal from 'components/categoryControl/CategorySettingsModal';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  deleteCategory,
  getCategoryList,
  updateCategory,
  updateCategorySort,
} from '../redux/actions/data/categoryActions';
import {
  CATEGORY_DELETE_RESET,
  CATEGORY_UPDATE_RESET,
  CREATE_PRODUCT_CATEGORY_IDLE,
} from '../redux/constants/data/categoryConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const CategoryControl = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const [current_page, setCurrentPage] = useState(1);
  const [cateName, setCateName] = useState('');
  const [status, setStatus] = useState('');
  const [currentData, setCurrentData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  // const { userInfo } = useSelector(state => state.userLogin);
  const { loading, categoryList, error } = useSelector(
    state => state.categoryList,
  );
  const { success: addSuccess, error: addError } = useSelector(
    state => state.createProductCategory,
  );

  const [flatList, setFlatList] = useState([]);

  // const {
  //   loading: addLoading,
  //   success: addSuccess,
  //   error: addError,
  // } = useSelector(state => state.categoryAdd);

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.categoryUpdate);

  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = useSelector(state => state.categoryDelete);

  const {
    loading: sortLoading,
    success: sortSuccess,
    error: sortError,
  } = useSelector(state => state.categorySort);

  useEffect(() => {
    if (categoryList && categoryList?.datas?.length > 10) {
      setEditCategory(categoryList.datas);
    } else if (categoryList && categoryList?.datas?.length < 10) {
      setEditCategory(categoryList.datas);
    }
  }, [categoryList]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;

      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        setCateName(queryKeyword);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(getCategoryList(queryKeyword, queryStatus, queryPage));
      } else {
        //單純記錄頁數
        dispatch(getCategoryList('', '', queryPage));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setCateName('');
      setStatus('');

      //初始搜尋
      dispatch(getCategoryList('', '', 1));
    }
  }, [location.search]);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(getCategoryList(cateName, status, current_page));
      toggleModal();
      dispatch({ type: CREATE_PRODUCT_CATEGORY_IDLE });
    }

    if (addError && addError.code === APIUtil.GlobalCategory_Not_Exists) {
      MessageUtil.alertWanring('品牌不存在', '');
      dispatch({ type: CREATE_PRODUCT_CATEGORY_IDLE });
    } else if (
      addError &&
      addError.code === APIUtil.GlobalCategory_Name_Duplicate
    ) {
      MessageUtil.alertWanring('名稱重複', '');
      dispatch({ type: CREATE_PRODUCT_CATEGORY_IDLE });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (updateSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('更新成功!');
      dispatch(getCategoryList(cateName, status, current_page));
      dispatch({ type: CATEGORY_UPDATE_RESET });
      if (isOpenModal) {
        toggleModal();
      }
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: CATEGORY_UPDATE_RESET });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (categoryList.datas.length !== 1) {
        dispatch(getCategoryList(cateName, status, current_page));
      } else {
        dispatch(getCategoryList(cateName, status, current_page - 1));

        history.push(
          `${location.pathname}?keyword=${cateName}&status=${status}&page=${
            current_page - 1
          }`,
        );

        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError && deleteError.code === APIUtil.Code_Not_Found) {
      MessageUtil.alertWanring('資料錯誤', '');
    }

    return () => {
      dispatch({ type: CATEGORY_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError, categoryList]);

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getCategoryList(cateName, status, 1));
    history.push(
      `${location.pathname}?keyword=${cateName}&status=${status}&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setCateName('');
    setStatus('');
    dispatch(getCategoryList('', '', 1));
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getCategoryList(cateName, status, page));

    if (cateName) {
      history.push(
        `${location.pathname}?keyword=${cateName}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  /**** modal  ****/
  const toggleModal = () => setModal(prev => !prev);
  const [isOpenModal, setModal] = useState(false);

  // const [isSumbitLoading, setSumbitLoading] = useState(false)
  // const [editData, setEditData] = useState({});

  // const getValidationSchema = () => {
  //   const schema = Yup.object().shape({
  //     name: Yup.string().required('必填!'),
  //     name_en: Yup.string().required('必填!'),
  //     status: Yup.string().required('必填!'),
  //   });

  //   return schema;
  // };

  // const handleSubmit = values => {

  //   let info = {
  //     cateId: editData.id ?? 0,
  //     name: values.name,
  //     name_en: values.name_en,
  //     status: values.status,
  //   };

  //   if (info.cateId === 0) {
  //     callAdd(info);
  //   } else {
  //     callUpd(info);
  //   }
  // };

  // const handleOpenAddModal = () => {
  //   setEditData({
  //     cateId: 0,
  //     name: '',
  //     status: '',
  //   });
  //   toggleModal();
  // };

  // const handleOpenEditModal = info => {
  //   setEditData(info);
  //   toggleModal();
  // };

  // const callAdd = info => {
  //   dispatch(addCategory(info));
  // };

  // const callUpd = info => {
  //   dispatch(updateCategory(info));
  // };

  const callDel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteCategory(id));
    });
  };

  /**** drag n drop  ****/
  const [sortModal, setSortModal] = useState(false);
  const [editCategory, setEditCategory] = useState([]);

  const toggleSortModal = () => {
    dispatch(getCategoryList('', '', current_page));
    setSortModal(false);
  };
  const handleOnDragEnd = result => {
    const items = Array.from(editCategory);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setEditCategory(items);
  };

  const updateSort = () => {
    const sort_objects = editCategory.map((brand, index) => {
      return {
        id: brand.id,
        sort: index + 1,
      };
    });
    dispatch(updateCategorySort(sort_objects));
  };

  const handleSortModalOpen = () => {
    setSortModal(true);
    dispatch(getCategoryList('', '', 0));
  };

  useEffect(() => {
    if (categoryList) {
      if (JSON.stringify(categoryList) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: categoryList });
        let temp = [];
        categoryList?.datas?.forEach(data => {
          temp.push({
            ...data,
            isFlatten: false,
            isShow: true,
          });
          if (data.cat2s.length > 0) {
            data.cat2s.forEach(cat => {
              temp.push({
                ...cat,
                displaySort: `${data.sort}.${cat.sort}`,
                isShow: false,
              });
            });
          }
        });
        setFlatList(temp);
      }
    }
  }, [categoryList]);

  const toggleSubItems = (id, isFlatten) => {
    const newList = flatList?.map((item, index) => {
      if (id === item.id && typeof item.cat2s === 'object') {
        return {
          ...item,
          isFlatten: !isFlatten,
        };
      }
      if (id === item.parentId) {
        return {
          ...item,
          isShow: !isFlatten,
        };
      } else {
        return item;
      }
    });
    setFlatList(newList);
  };

  return (
    <Layout
      pageTitle="類別管理"
      items={[
        { isActive: false, page_name: '商品管理' },
        { isActive: true, page_name: '類別管理' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="mr-2">分類名稱</label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  data-id="cateName"
                  value={cateName}
                  onChange={e => setCateName(e.target.value)}
                />
              </div>
              <div className={style.formGroup}>
                <label className="mr-2">狀態</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  data-id="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="上架">上架</option>
                  <option value="下架">下架</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <div className={style.card}>
        <button
          className="btn btn-success w100"
          onClick={() => {
            setCurrentData(null);
            setIsEdit(false);
            toggleModal();
          }}
        >
          新增
        </button>
        <Button
          type="button"
          className={style.sort_button}
          onClick={() => {
            history.push('/ProductCategorySorting');
          }}
        >
          排序
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && categoryList && categoryList.total_count > 0
              ? categoryList.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>排序</th>
                <th>分類名稱</th>
                <th>上/下架</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {categoryList ? (
                  flatList.map(
                    (data, index) =>
                      data.isShow && (
                        <tr key={data.id}>
                          <td>
                            {data.cat2s?.length > 0 ? (
                              typeof data.cat2s === 'object' ? (
                                !data.isFlatten ? (
                                  <FaCaretDown
                                    size="30"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      toggleSubItems(data.id, data.isFlatten)
                                    }
                                  />
                                ) : (
                                  <FaCaretUp
                                    size="30"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      toggleSubItems(data.id, data.isFlatten)
                                    }
                                  />
                                )
                              ) : (
                                <></>
                              )
                            ) : (
                              <></>
                            )}{' '}
                            {data.displaySort || data.sort}
                          </td>
                          <td>{data.name}</td>
                          <td>
                            <div className={style['radio-btn-layout']}>
                              <input
                                type="radio"
                                className={style['radio-btn']}
                                checked={data.status === '上架'}
                                onChange={() => {
                                  dispatch(
                                    updateCategory({
                                      cateId: data.id,
                                      name: data.name,
                                      name_en: data.name_en,
                                      status: '上架',
                                    }),
                                  );
                                }}
                              />
                              <span className="ml-2">上架</span>
                            </div>
                            <div className={style['radio-btn-layout']}>
                              <input
                                type="radio"
                                className={style['radio-btn']}
                                checked={data.status === '下架'}
                                onChange={() => {
                                  dispatch(
                                    updateCategory({
                                      cateId: data.id,
                                      name: data.name,
                                      name_en: data.name_en,
                                      status: '下架',
                                    }),
                                  );
                                }}
                              />
                              <span className="ml-2">下架</span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <span>新增：</span>
                              <span className="d-inline-block me-2">
                                {data.createDate}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.createTime}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.creator}
                              </span>
                            </div>
                            {data.updateDate?.length > 0 && (
                              <div>
                                <span>修改：</span>
                                <span className="d-inline-block me-2">
                                  {data.updateDate}
                                </span>
                                <span className="d-inline-block me-2">
                                  {data.updateTime}
                                </span>
                                <span className="d-inline-block me-2">
                                  {data.updater}
                                </span>
                              </div>
                            )}
                          </td>
                          <td>
                            <Button
                              className="m-2"
                              type="button"
                              color="primary"
                              outline
                              size="sm"
                              onClick={() => {
                                setCurrentData(data);
                                setIsEdit(true);
                                toggleModal();
                              }}
                            >
                              編輯分類
                            </Button>
                            {data.cat2s?.length === 0 ? (
                              <Link
                                className="m-2 btn btn-outline-success btn-sm"
                                to={`/PrdCategoryPrds/${data.id}`}
                              >
                                商品設定
                              </Link>
                            ) : data.parentId ? (
                              <Link
                                className="m-2 btn btn-outline-success btn-sm"
                                to={`/PrdCategoryPrds/${data.id}`}
                              >
                                商品設定
                              </Link>
                            ) : (
                              <></>
                            )}
                            <Button
                              className="m-2"
                              type="button"
                              color="danger"
                              size="sm"
                              outline
                              onClick={() => callDel(data.id)}
                            >
                              刪除
                            </Button>
                          </td>
                        </tr>
                      ),
                  )
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
            {loading && <TableLoading />}
          </Table>
        </div>
        {categoryList && categoryList.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={categoryList.current_page}
              defaultPageSize={categoryList.page_size}
              total={categoryList.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>

      {/* <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={isOpenModal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          編輯分類名稱
        </ModalHeader>
        <Formik
          initialValues={{
            name: editData.name,
            name_en: editData.name_en,
            status: editData.status,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form>
              <ModalBody className={style.modal_body}>
                <div
                  className={style.formGroup}
                  style={{ marginBottom: '1rem' }}
                >
                  <label className="required" style={{ marginRight: '3rem' }}>
                    分類名稱
                  </label>
                  <div className="form-item">
                    <Field className="form-control" name="name" />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div
                  className={style.formGroup}
                  style={{ marginBottom: '1rem' }}
                >
                  <label className="required" style={{ marginRight: '3rem' }}>
                    分類名稱(英)
                  </label>
                  <div className="form-item">
                    <Field className="form-control" name="name_en" />
                    <ErrorMessage
                      name="name_en"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ marginRight: '5rem' }}>
                    狀態
                  </label>
                  <div className="form-item">
                    <div
                      className={style.formGroup}
                      style={{ marginBottom: '0' }}
                    >
                      <Label style={{ marginRight: '1rem' }} className="mb-0">
                        {' '}
                        <Field
                          name="status"
                          type="radio"
                          value="上架"
                        /> 上架{' '}
                      </Label>
                      <Label className="mr-3 mb-0">
                        {' '}
                        <Field
                          name="status"
                          type="radio"
                          value="下架"
                        /> 下架{' '}
                      </Label>

                      <ErrorMessage
                        name="status"
                        component="span"
                        className="form-error"
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="light"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading || updateLoading}
                >
                  {addLoading ||
                    (updateLoading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ))}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal> */}

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={sortModal}
        toggle={toggleSortModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleSortModal} className={style.modal_head}>
          分類排序
        </ModalHeader>
        <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {editCategory.map(({ id, name, status }, index) => (
                    <Draggable key={id} draggableId={`${id}`} index={index}>
                      {provided => (
                        <li
                          className="drag_data"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <span className="title">{index + 1}</span>
                          <span className="brand_name">
                            {name}
                            {status === '下架' && (
                              <span className="badge bg-danger ms-4">下架</span>
                            )}
                          </span>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleSortModal}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={updateSort}
            disabled={sortLoading}
          >
            {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
      {isOpenModal && (
        <CategorySettingsModal
          isOpen={isOpenModal}
          toggle={toggleModal}
          isEdit={isEdit}
          data={currentData}
        />
      )}
    </Layout>
  );
};

export default CategoryControl;
