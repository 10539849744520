import CommonUtil from './CommonUtil';

export const getImageDataFromSetting = (pic, url) => {
  if (pic && url) {
    return [
      {
        id: CommonUtil.getNewID(),
        name: pic,
        url: url,
      },
    ];
  } else {
    return [];
  }
};

export function loadImageAsFile(imageUrl, filename) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // 设置跨域属性
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(
          blob => {
            resolve(new File([blob], filename, { type: blob.type }));
          },
          img.src.includes('data:')
              ? img.src.split(';')[0].split(':')[1]
              : 'image/png',
      );
    };
    img.onerror = reject;
    img.src = imageUrl;
  });
}
