import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Card, FormGroup } from 'reactstrap';
import SunEditor from 'suneditor-react';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import Loader from '../Loader';
import UploadFileBox from '../UploadFileBox';

import 'suneditor/dist/css/suneditor.min.css';
import './index.css';
const DetailEditor = ({ detail, loading, handleSubmit, goBack }) => {
  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };
  if (!detail) {
    return <Loader />;
  }

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Formik
        enableReinitialize
        initialValues={{
          content: detail.content || '',
          content_en: detail.content_en || '',
          endDateTime: detail.endDateTime || new Date().toLocaleDateString(),
          img_files: null,
          init_img_files: getImageDataFromSetting(
            detail.imgFile,
            detail.imgUrl,
          ),
          startDateTime:
            detail.startDateTime || new Date().toLocaleDateString(),
          title: detail.title || '',
          title_en: detail.title_en || '',
        }}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(中文)</label>
              <div className="form-item">
                <Field name="title" className="form-control" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(英文)</label>
              <div className="form-item">
                <Field name="title_en" className="form-control" />
                <ErrorMessage
                  name="title_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(中文)</label>
              <div className="form-item fix-z-index">
                <SunEditor
                  plugin
                  placeholder="請輸入內容(中文)"
                  setContents={props.values.content}
                  onChange={html => {
                    props.values.content = html;
                    props.setFieldValue('content', html);
                  }}
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],['link'],
                        ['fontColor'],
                      ['align', 'list', 'lineHeight'],
                    ],
                    defaultTag: 'div',
                    formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                  }}
                />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(英文)</label>
              <div className="form-item fix-z-index">
                <SunEditor
                  plugin
                  placeholder="請輸入內容(英文)"
                  setContents={props.values.content_en}
                  setOptions={{
                    buttonList: [
                      ['undo', 'redo'],
                      ['font', 'fontSize', 'formatBlock'],
                      [
                        'bold',
                        'underline',
                        'italic',
                        'strike',
                        'subscript',
                        'superscript',
                      ],
                      ['link'],
                      ['fontColor'],
                      ['align', 'list', 'lineHeight'],
                    ],
                    defaultTag: 'div',
                    formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                  }}
                  onChange={html => {
                    props.values.content_en = html;
                    props.setFieldValue('content_en', html);
                  }}
                />
                <ErrorMessage
                  name="content_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*上架日期</label>
              <div className="form-item">
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={moment(
                    props.values.startDateTime || undefined,
                  ).toDate()}
                  name="startDateTime"
                  onChange={date => {
                    props.setFieldValue(
                      'startDateTime',
                      date.toLocaleDateString(),
                    );
                  }}
                  selectsEnd
                  maxDate={moment(
                    props.values.endDateTime || undefined,
                  ).toDate()}
                />
                <ErrorMessage
                  name="startDateTime"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*下架日期</label>
              <div className="form-item">
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={moment(
                    props.values.endDateTime || undefined,
                  ).toDate()}
                  name="endDateTime"
                  onChange={date => {
                    props.setFieldValue(
                      'endDateTime',
                      date.toLocaleDateString(),
                    );
                  }}
                  selectsEnd
                  minDate={moment(
                    props.values.startDateTime || undefined,
                  ).toDate()}
                />
                <ErrorMessage
                  name="endDateTime"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="img_files"
                  currentFileFieldName="img_files"
                  setFormFieldValue={(field, files) => {
                    if (files && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue('img_files', files[0]);
                      }
                    }
                  }}
                  displayFileWidth="1080"
                  displayFileHeight="700"
                  displayFileSize="5MB"
                  initFiles={props.values.init_img_files}
                />
              </div>
            </FormGroup>
            <hr className="mt-5" />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={goBack}
              >
                <span className="btn-wrapper--label">取消</span>
              </Button>
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!(props.isValid && props.dirty)}
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '1rem' }}
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(DetailEditor);
