import {
  GET_MAIL_CONTENT_FAIL,
  GET_MAIL_CONTENT_REQUEST,
  GET_MAIL_CONTENT_SUCCESS,
  GET_MAIL_RECORDS_LIST_FAIL,
  GET_MAIL_RECORDS_LIST_REQUEST,
  GET_MAIL_RECORDS_LIST_SUCCESS,
  GET_MAIL_TYPE_OPTIONS_FAIL,
  GET_MAIL_TYPE_OPTIONS_REQUEST,
  GET_MAIL_TYPE_OPTIONS_SUCCESS,
} from 'redux/constants/data/mailRecordsContants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getMailRecordsList = data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/MailLog/list' });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_MAIL_RECORDS_LIST_FAIL,
      method: 'post',
      requestConstant: GET_MAIL_RECORDS_LIST_REQUEST,
      successConstant: GET_MAIL_RECORDS_LIST_SUCCESS,
      url: '/Backend/MailLog/list',
    });
  };
};

export const getMailContent = id => {
  const url = `/Backend/MailLog/Get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MAIL_CONTENT_FAIL,
      method: 'get',
      requestConstant: GET_MAIL_CONTENT_REQUEST,
      successConstant: GET_MAIL_CONTENT_SUCCESS,
      url,
    });
  };
};

export const getMailTypeOptions = () => {
  const url = '/Backend/MailLog/GetSelection';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MAIL_TYPE_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_MAIL_TYPE_OPTIONS_REQUEST,
      successConstant: GET_MAIL_TYPE_OPTIONS_SUCCESS,
      url,
    });
  };
};
