import RcPagination from 'rc-pagination';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table } from 'reactstrap';

import { getWarrantyRegisterList } from '../../redux/actions/data/warrantyActions';
import style from '../../styles/layout.module.css';
import { modal as modalUtils } from '../../utils/modal';
import TableHasNoData, { TableLoading } from '../TableHasNoData';
import TableHead from '../TableHead';

const WarrantyRegistersRecords = ({ memberId }) => {
  const [page, setPage] = useState(1);
  const { loading, warrantyRegisterListInfo } = useSelector(
    state => state.warrantyRegisterList,
  );
  const dispatch = useDispatch();

  const handlePageChange = useCallback(
    (page = 1) => {
      setPage(page);
      dispatch(
        getWarrantyRegisterList({
          memberId: Number(memberId),
          page,
          page_size: 10,
        }),
      );
    },
    [memberId],
  );

  useEffect(() => {
    handlePageChange(1);
  }, [memberId]);

  return (
    <Card className={style.card}>
      <div className="d-flex justify-content-end">
        共
        <span className="mx-1">
          {!loading &&
          warrantyRegisterListInfo &&
          warrantyRegisterListInfo.total_count > 0
            ? warrantyRegisterListInfo.total_count
            : '0'}
        </span>
        筆
      </div>
      <div className="table-responsive mb-5">
        <Table hover bordered striped>
          <TableHead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>電話</th>
              <th>品牌</th>
              <th>型號/顏色/尺寸</th>
              <th>購買通路</th>
              <th>保卡序號</th>
              <th>產品序號</th>
              <th>購買日期</th>
              <th>保固日期</th>
              <th>審核狀態</th>
              <th>發票/保卡圖片</th>
            </tr>
          </TableHead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {warrantyRegisterListInfo?.datas?.length > 0 ? (
                warrantyRegisterListInfo?.datas.map(data => (
                  <tr key={JSON.stringify(data)}>
                    <td className="text-nowrap">
                      {data.countryCode === '886' ? '0' : data.countryCode}
                      {data.phone}
                    </td>
                    <td className="text-nowrap">{data.brandName}</td>
                    <td className="text-nowrap">
                      {data.model}/{data.color}/{data.size}
                    </td>
                    <td>
                      {data.isNew
                        ? data.purchaseChannelName
                        : data.supplierChanneName}
                    </td>
                    <td className="text-nowrap">{data.warrantySerialNum}</td>
                    <td>{data.productSerialNum}</td>
                    <td className="text-nowrap">{data.purchaseDate || '--'}</td>
                    <td className="text-nowrap">
                      {data.warrantyDays ? (
                        <>
                          <p>起：{data.warrantyStartDate || '--'}</p>
                          <p>訖：{data.warrantyEndDate || '--'}</p>
                        </>
                      ) : (
                        '無'
                      )}
                    </td>
                    <td align="center">{data.status}</td>
                    <td>
                      {data?.warrantyimgList?.map(img => {
                        return (
                          <img
                            role="button"
                            src={img.imageUrl}
                            alt={img.imageUrl}
                            width="50"
                            key={JSON.stringify(img)}
                            onClick={() => {
                              modalUtils.showFillImage(img.imageUrl);
                            }}
                          />
                        );
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={page}
          pageSize={10}
          total={warrantyRegisterListInfo?.total_count || 0}
          onChange={(page) => handlePageChange(page)}
        />
      </div>
    </Card>
  );
};

export default memo(WarrantyRegistersRecords);
