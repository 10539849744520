import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

const Breadcrumbs = props => {
  const history = useHistory();
  return (
    <div className="app-page-title">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ marginBottom: '0rem' }}>
          <li className="breadcrumb-item">
            <NavLink to="/">首頁</NavLink>
          </li>
          {props.items.map((item, index) => {
            if (item.isActive) {
              return (
                <li
                  key={index}
                  className="breadcrumb-item active"
                  aria-current="page"
                >
                  {item.page_name}
                </li>
              );
            } else if (item.to && item.to.length > 0) {
              if (history.length > 0) {
                return (
                  <li key={index} className="breadcrumb-item">
                    <a
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {item.page_name}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li key={index} className="breadcrumb-item">
                    <NavLink to={item.to}>{item.page_name}</NavLink>
                  </li>
                );
              }
            } else {
              return (
                <li key={index} className="breadcrumb-item">
                  {item.page_name}
                </li>
              );
            }
          })}
        </ol>
      </nav>
    </div>
  );
};

Breadcrumbs.defaultProps = {
  items: [], // {to:"/path...",name:"page_name" ,isActive:false} without home page
  pageTitle: '首頁',
};

export default Breadcrumbs;
