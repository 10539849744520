import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { emptyOption } from 'utils';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  assignGroupModelToGlobalCat,
  assignModelToGlobalCat,
  getModelGlobalCatListV2,
  getModelList,
  removeGlobalCatGroupModel,
  removeGlobalCatModel,
} from '../redux/actions/data/modelActions';
import { getModelOptions } from '../redux/actions/data/productActions';
import {
  GLOBAL_CAT_MODEL_ASSIGN_RESET,
  GLOBAL_CAT_MODEL_REMOVE_RESET,
} from '../redux/constants/data/modelConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil.js';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

export default function PrdCategoryPrds() {
  const dispatch = useDispatch();
  const { location } = history;
  const { cateId } = useParams();

  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );

  const productOptions = [
    {
      label: '全選',
      value: 0,
    },
    {
      label: '一般商品',
      value: 1,
    },
    {
      label: '組合商品',
      value: 3,
    },
  ];

  const [current_page, setCurrentPage] = useState(1);

  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      initialValues: {
        brandId: emptyOption('請選擇品牌', 0),
        keyword: '',
        model: emptyOption('請選擇型號', ''),
        productType: emptyOption('全選', 0),
      },
      onSubmit: values => {
        const params = {
          ...values,
          brandId: values.brandId.value,
          globalCateId: +cateId,
          model: values.model.value,
          page: 1,
          productType: values.productType.value,
        };
        dispatch(getModelGlobalCatListV2(params));
      },
    });

  const { loading, modelGlobalCatListInfo, error } = useSelector(
    state => state.modelGlobalCatList,
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.globalCatRemoveModel,
  );
  const { modelListInfo } = useSelector(state => state.modelList);
  const handleSearch = () => {
    dispatch(
      getModelGlobalCatListV2({
        page: current_page,
        ...values,
        brandId: values.brandId.value,
        globalCateId: +cateId,
        model: values.model.value,
        productType: values.productType.value,
      }),
    );
  };

  useEffect(() => {
    dispatch(getModelOptions(values.brandId.value ? values.brandId.label : ''));
  }, [values.brandId]);

  useEffect(() => {
    handleSearch();
    dispatch(getBrandOptions());
  }, []);
  useEffect(() => {
    handleSearch();
  }, [current_page]);

  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      handleReset();
      handleSearch();
    }

    if (deleteError) {
      MessageUtil.alertWanring('資料錯誤', '');
    }

    return () => {
      dispatch({ type: GLOBAL_CAT_MODEL_REMOVE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleRemoveGlobalCatModel = (id, productType = '1') => {
    MessageUtil.delConfirm(() => {
      dispatch(
        String(productType) === '1'
          ? removeGlobalCatModel({
              globalCateId: +cateId,
              modelIds: [id],
            })
          : removeGlobalCatGroupModel({
              globalCateId: +cateId,
              modelIds: [id],
            }),
      );
    });
  };

  const [isOpenModal, setOpenModal] = useState(false);
  const [modal_current_page, setModalCurrentPage] = useState(1);
  const [modal_keyword, setModalKeyword] = useState('');
  const [modal_brandId, setModalBrandId] = useState({
    label: '請選擇品牌',
    value: 0,
  });
  const [modal_model, setModalModel] = useState({
    label: '請選擇型號',
    value: '',
  });

  const productTypeRef = useRef('1');
  const toggleModal = type => {
    productTypeRef.current = type || '1';
    setOpenModal(prev => !prev);
  };

  const [existProductIds, setExistProductIds] = useState([]);
  useEffect(() => {
    if (modelGlobalCatListInfo && modelGlobalCatListInfo?.datas?.length > 0) {
      const existProductIds = modelGlobalCatListInfo?.datas.map(
        item => item.id,
      );
      setExistProductIds(existProductIds);
    }
  }, [modelGlobalCatListInfo]);

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.globalCatAssignModel);

  useEffect(() => {
    isOpenModal && handleModalSearch();
    isOpenModal || clearModalSearchForm();
  }, [isOpenModal]);

  const handleModalSearch = () => {
    dispatch(
      getModelList({
        brandId: modal_brandId.value,
        keyword: modal_keyword,
        model: modal_model.value,
        page: modal_current_page,
        page_size: 10,
      }),
    );
  };
  const clearModalSearchForm = () => {
    setModalCurrentPage(1);
    setModalKeyword('');
    setModalModel({ label: '請選擇型號', value: '' });
  };
  useEffect(() => {
    handleModalSearch();
  }, [modal_current_page]);

  const [selProductIds, setSelProductIds] = useState([]);
  const handleCheck = (id, checked) => {
    if (checked) {
      setSelProductIds([...selProductIds, id]);
    } else {
      setSelProductIds(selProductIds.filter(productId => productId !== id));
    }
  };

  const callAssignToGlobalCategory = () => {
    if (selProductIds.length === 0) {
      MessageUtil.alertWanring('請新增商品', '');
    } else {
      if (productTypeRef.current === '1') {
        dispatch(
          assignModelToGlobalCat({
            globalCateId: +cateId,
            modelIds: selProductIds,
          }),
        );
      } else {
        dispatch(
          assignGroupModelToGlobalCat({
            globalCateId: +cateId,
            modelIds: selProductIds,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      MessageUtil.toastSuccess('新增成功!!');
      handleSubmit(values);
    }

    if (addError) {
      if (addError.code === APIUtil.Code_Product_Extensions_Not_Exists) {
        MessageUtil.alertWanring('商品不存在', '');
      }
    }

    return () => {
      dispatch({ type: GLOBAL_CAT_MODEL_ASSIGN_RESET });
    };
  }, [addSuccess, addError]);

  const goToExportPage = useCallback(() => {
    history.push(`/ProductCategoryPrdsExport?type=product&cateId=${cateId}`);
  }, []);

  return (
    <Layout
      pageTitle={!loading && `${modelGlobalCatListInfo?.globalCateName} 設定`}
      items={[
        { isActive: false, page_name: '商品管理', to: '' },
        {
          isActive: false,
          page_name: '商品分類管理',
          to: '/CategoryControl',
        },
        { isActive: true, page_name: '商品類別管理' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="keyword"
                  style={{ lineHeight: '48px', width: '60px' }}
                >
                  關鍵字
                </label>
                <input
                  type="text"
                  className="form-control w200"
                  id="keyword"
                  value={values.keyword}
                  onChange={handleChange}
                  placeholder="商品名稱"
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="brand"
                  style={{ lineHeight: '48px', width: '45px' }}
                >
                  品牌
                </label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className="w200"
                  name="brandId"
                  options={
                    brandOptions && [
                      emptyOption('請選擇品牌', 0),
                      ...brandOptions,
                    ]
                  }
                  value={values.brandId}
                  onChange={val => {
                    setFieldValue('brandId', val);
                  }}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="number"
                  style={{ lineHeight: '48px', width: '45px' }}
                >
                  型號
                </label>
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  className="w200"
                  name="model"
                  options={
                    modelOptions && [
                      emptyOption('請選擇型號', ''),
                      ...modelOptions,
                    ]
                  }
                  value={values.model}
                  onChange={val => {
                    setFieldValue('model', val);
                  }}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="number"
                  style={{ lineHeight: '48px', width: '60px' }}
                >
                  商品類別
                </label>
                <Select
                  className="w200"
                  name="productType"
                  options={productOptions}
                  value={values.productType}
                  onChange={val => {
                    setFieldValue('productType', val);
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                color="light"
                className={style.modal_button_cancel}
                onClick={handleReset}
                style={{ marginRight: '1rem' }}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="d-flex gap-2">
          <button
            className="btn btn-success"
            onClick={() => {
              toggleModal('1');
            }}
          >
            新增一般商品
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              toggleModal('2');
            }}
          >
            新增組合商品
          </button>
          <button className="btn btn-success" onClick={goToExportPage}>
            匯出/匯入
          </button>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && modelGlobalCatListInfo?.total_count > 0
              ? modelGlobalCatListInfo?.total_count
              : '0'}
          </span>
          筆
        </div>

        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>排序</th>
                <th>型號</th>
                <th>商品</th>
                <th>商品類型</th>
                <th>品牌</th>
                <th>品牌類別</th>
                <th>官網上架</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {modelGlobalCatListInfo?.datas?.length > 0 ? (
                  modelGlobalCatListInfo?.datas?.map((data, index) => (
                    <tr key={index}>
                      <td nowrap="nowrap" align="center">{data.sort ?? '--'}</td>
                      <td nowrap="nowrap">{data.model}</td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.modelName}
                      </td>
                      <td nowrap="nowrap">
                        {!data.productType
                          ? '--'
                          : String(data.productType) === '1'
                          ? '一般商品'
                          : '組合商品'}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.brandName}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.brandCateName}
                      </td>
                      <td>{data.isSell ? '是' : '否'}</td>
                      <td>
                        <Button
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() =>
                            handleRemoveGlobalCatModel(
                              data.id,
                              data.productType || '1',
                            )
                          }
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && modelGlobalCatListInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={modelGlobalCatListInfo?.current_page}
              defaultPageSize={modelGlobalCatListInfo?.page_size}
              total={modelGlobalCatListInfo?.total_count}
              onChange={setCurrentPage}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={1000}
        centered
        size="xl"
        isOpen={isOpenModal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          新增類別商品
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          <div className="form-inline">
            <form className="d-flex flex-wrap">
              <div className="form-group me-2 mb-2">
                <input
                  className="form-control w200"
                  placeholder="關鍵字"
                  value={modal_keyword}
                  onChange={e => setModalKeyword(e.target.value)}
                />
              </div>
              <div className="form-group me-2 mb-2">
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className="w200"
                  options={brandOptions}
                  value={modal_brandId}
                  onChange={option => setModalBrandId(option)}
                />
              </div>
              <div className="form-group me-2 mb-2">
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  className="w200"
                  options={modelOptions}
                  value={modal_model}
                  onChange={option => setModalModel(option)}
                />
              </div>
              <div>
                <Button
                  className={style.modal_button_submit}
                  onClick={handleModalSearch}
                >
                  搜尋
                </Button>
              </div>
            </form>
          </div>

          <div className="text-danger mr-auto">
            ※同商品編碼可以指定多個商品類別
          </div>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
              {modelListInfo?.total_count > 0
                ? modelListInfo?.total_count
                : '0'}
            </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>#</th>
                  <th>型號</th>
                  <th>商品</th>
                  <th>品牌</th>
                  <th>品牌類別</th>
                  <th>品牌上架</th>
                  <th>官網上架</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {modelListInfo?.datas ? (
                    modelListInfo?.datas?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={existProductIds.find(id => id === item.id)}
                            onChange={e =>
                              handleCheck(item.id, e.target.checked)
                            }
                          />
                        </td>
                        <td style={{ wordBreak: 'keep-all' }}>{item.model}</td>
                        <td style={{ wordBreak: 'keep-all' }}>{item.name}</td>
                        <td style={{ wordBreak: 'keep-all' }}>
                          {item.brandName}
                        </td>
                        <td style={{ wordBreak: 'keep-all' }}>
                          {item.brandCatName}
                        </td>
                        <td>{item.brandStatus}</td>
                        <td>{item.isSell ? '是' : '否'}</td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          {modelListInfo?.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={modelListInfo?.current_page}
                defaultPageSize={modelListInfo?.page_size}
                total={modelListInfo?.total_count}
                onChange={setModalCurrentPage}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleModal}
          >
            取消
          </Button>{' '}
          <Button
            type="button"
            color="primary"
            className={style.modal_button_submit}
            onClick={callAssignToGlobalCategory}
            disabled={addLoading}
          >
            {addLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="btn-wrapper--label">儲存</span>
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
}
