import AddNewProduct from 'pages/AddNewProduct';
import AutoReply from 'pages/AutoReply';
import BlockList from 'pages/BlockList';
import BlockListBatchFile from 'pages/BlockListBatchFile';
import CompaignSettingPage from 'pages/CompaignSettingPage';
import CopyCompaignSettingPage from 'pages/CopyCompaignSettingPage';
import DiscountCompaign from 'pages/DiscountCompaign';
import DiscountCompaignCate from 'pages/DiscountCompaignCate';
import GiftDetail from 'pages/GiftDetail';
import GiftManagement from 'pages/GiftManagement';
import GiftSettings from 'pages/GiftSettings';
import GiftsBinding from 'pages/GiftsBinding';
import HomeDeliveryOrderFailedList from 'pages/HomeDeliveryOrderFailedList';
import LogRecord from 'pages/LogRecord';
import LogRecordGuideLink from 'pages/LogRecordGuideLink';
import LogRecordWearableProductDownload from 'pages/LogRecordWearableProductDownload';
import LogRecordWearableProductExperience from 'pages/LogRecordWearableProductExperience';
import LogRecordWearableProductShare from 'pages/LogRecordWearableProductShare';
import LogRecordWearableProduct from 'pages/LogrecordWearableProduct';
import MailRecords from 'pages/MailRecords';
import MemberLevelSettings from 'pages/MemberLevelSettings';
import MemberPresentSettings from 'pages/MemberPresentSettings';
import MessageCategory from 'pages/MessageCategory';
import MessageCategorySorting from 'pages/MessageCategorySorting';
import MessageList from 'pages/MessageList';
import OrderCancelStatisticalTable from 'pages/OrderCancelStatisticalTable';
import OrderSourceData from 'pages/OrderSourceData';
import OrderStatisticalTable from 'pages/OrderStatisticalTable';
import ProductBinding from 'pages/ProductBinding';
import ProductCategorySorting from 'pages/ProductCategorySorting';
import ProductSalesDataRanking from 'pages/ProductSalesDataRanking';
import ProductSellingSettings from 'pages/ProductSellingSettings';
import RewardsPointsSettings from 'pages/RewardsPointsSettings';
import RewardsPointsStatisticalTable from 'pages/RewardsPointsStatisticalTable';
import SEOSettings from 'pages/SEOSettings';
import ServiceForm from 'pages/ServiceForm';
import ShopBannerSetting from 'pages/ShopBannerSetting';
import SortingActivityProducts from 'pages/SortingActivityProducts';
import StaffReply from 'pages/StaffReply';
import StoreSalesDataList from 'pages/StroeSalesDataList';
import UpdateCompaignSettingPage from 'pages/UpdateCompaignSettingPage';
import ViewCompaignPage from 'pages/ViewCompaignPage';
import VoucherList from 'pages/VoucherList';
import VoucherSettings from 'pages/VoucherSettings';
import WebsiteSettings from 'pages/WebsiteSettings';
import YourFavorite from 'pages/YourFavorite';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import SideBar from './components/SideBar';
import About from './pages/About';
import AdminControl from './pages/AdminControl';
import BannerSetting from './pages/BannerSetting';
import BestSeller from './pages/BestSeller';
import BestSellerSetting from './pages/BestSellerSetting';
import PersonalInformation from './pages/BonusPoints/PersonalInformation';
import SpecialEventPointSystem from './pages/BonusPoints/SpecialEventPointSystem';
import SpecialEventPointSystemAdd from './pages/BonusPoints/SpecialEventPointSystemAdd';
import SpecialEventPointSystemDetail from './pages/BonusPoints/SpecialEventPointSystemDetail';
import SpecifyPointSettings from './pages/BonusPoints/SpecifyPointSettings';
import VirtualTryOn from './pages/BonusPoints/VirtualTryOn';
import BrandCategory from './pages/BrandCategory';
import BrandCategoryGroupPrds from './pages/BrandCategoryGroupPrds';
import BrandCategoryPrds from './pages/BrandCategoryPrds';
import BrandCategoryUUPrds from './pages/BrandCategoryUUPrds';
import BrandControl from './pages/BrandControl';
import BrandPreview from './pages/BrandPreview';
import BrandSetting from './pages/BrandSetting';
import CancelOrderCreate from './pages/CancelOrderCreate';
import CancelOrderList from './pages/CancelOrderList';
import CategoryControl from './pages/CategoryControl';
import ChannelControl from './pages/ChannelControl';
import CheckoutDelivery from './pages/CheckoutDelivery';
import CheckoutPayment from './pages/CheckoutPayment';
import CheckoutReturnContact from './pages/CheckoutReturnContact';
import ClearMemberList from './pages/ClearMemberList';
import CouponBackground from './pages/CouponBackground';
import CouponList from './pages/CouponList';
import CreateLogistics from './pages/CreateLogistics';
import CreateLogisticsCancelLogistic from './pages/CreateLogisticsCancelLogistic';
import CreateLogisticsCancelLogisticSeven from './pages/CreateLogisticsCancelLogisticSeven';
import CreateLogisticsCancelOrder from './pages/CreateLogisticsCancelOrder';
import CreateLogisticsCancelOrderSeven from './pages/CreateLogisticsCancelOrderSeven';
import CreateLogisticsFail from './pages/CreateLogisticsFail';
import CreateLogisticsFailSeven from './pages/CreateLogisticsFailSeven';
import CreateLogisticsSeven from './pages/CreateLogisticsSeven';
import CreateLogisticsShipping from './pages/CreateLogisticsShipping';
import CreateLogisticsShippingSeven from './pages/CreateLogisticsShippingSeven';
import DistributionServicesSettings from './pages/DistributionServicesSettings';
import FAQCategory from './pages/FAQCategory';
import FAQList from './pages/FAQList';
import Forgetpwd from './pages/Forgetpwd';
import GroupModel from './pages/GroupModel';
import GroupModelDetail from './pages/GroupModelDetail';
import GroupPrdCategoryPrds from './pages/GroupPrdCategoryPrds';
import HideModel from './pages/HideModel';
import HideModelDetail from './pages/HideModelDetail';
import Home from './pages/Home';
import HomeDeliveryOrderConfirmList from './pages/HomeDeliveryOrderConfirmList';
import HomeDeliveryOrderShippingList from './pages/HomeDeliveryOrderShippingList';
import JoinUs from './pages/JoinUs';
import JoinUsDetail from './pages/JoinUsDetail';
import Login from './pages/Login';
import LowestNotify from './pages/LowestNotify';
import MaintainanceAdd from './pages/MaintainanceAdd';
import MaintainanceContent from './pages/MaintainanceContent';
import MaintainanceList from './pages/MaintainanceList';
import MaintainanceListSetting from './pages/MaintainanceListSetting';
import Maintenance from './pages/Maintenance';
import MaintenanceAnnouncementNotice from './pages/MaintenanceAnnouncementNotice';
import MemberBirthPresentSettings from './pages/MemberBirthPresentSettings';
import MemberDetails from './pages/MemberDetails';
import MemberList from './pages/MemberList';
import MemberOpenPresentSettings from './pages/MemberOpenPresentSettings';
import MemberPromotionPresentSettings from './pages/MemberPromotionPresentSettings';
import MembersInterests from './pages/MembersInterests';
import NewsDetail from './pages/NewsDetail';
import NewsList from './pages/NewsList';
import NewsPreview from './pages/NewsPreview';
import OrderChangeCreate from './pages/OrderChangeCreate';
import OrderChangeList from './pages/OrderChangeList';
import OrderChangeStep1 from './pages/OrderChangeStep1';
import OrderChangeStep2 from './pages/OrderChangeStep2';
import OrderChangeStep3 from './pages/OrderChangeStep3';
import OrderDetail from './pages/OrderDetail';
import OrderInvoiceList from './pages/OrderInvoiceList';
import OrderList from './pages/OrderList';
import OrderPrintDetail from './pages/OrderPrintDetail';
import OrderReturnCreate from './pages/OrderReturnCreate';
import OrderReturnDeliveryList from './pages/OrderReturnDeliveryList';
import OrderReturnList from './pages/OrderReturnList';
import OrderReturnPayATMYet from './pages/OrderReturnPayATMYet';
import OrderReturnPayFail from './pages/OrderReturnPayFail';
import OrderReturnPayList from './pages/OrderReturnPayList';
import OrderReturnReason from './pages/OrderReturnReason';
import OrderReturnRepayList from './pages/OrderReturnRepayList';
import OrderReturnStep1 from './pages/OrderReturnStep1';
import OrderReturnStep2 from './pages/OrderReturnStep2';
import OrderReturnStep3 from './pages/OrderReturnStep3';
import Overall from './pages/Overall';
import ProductBrandBannerSettings from './pages/PageSettings/ProductBrandBannerSettings';
import PersonalData from './pages/PersonalData';
import PrdCategoryPrds from './pages/PrdCategoryPrds';
import PrdInstructionsFAQ from './pages/PrdInstructionsFAQ';
import PrdSpecList from './pages/PrdSpecList';
import ProductCategoryPrdsExport from './pages/ProductCategoryPrdsExport';
import ProductControl from './pages/ProductControl';
import ProductDetail from './pages/ProductDetail';
import ProductInstructions from './pages/ProductInstructions';
import ProductLinkControl from './pages/ProductLinkControl';
import ProductLinkDetail from './pages/ProductLinkDetail';
import ProductNoneApplySettings from './pages/ProductNoneApplySettings';
import ProductNum from './pages/ProductNum';
import ProductNumDetail from './pages/ProductNumDetail';
import ProductPreview from './pages/ProductPreview';
import FormDescriptionText from './pages/ProductWarrantyRegistration/FormDescriptionText';
import PromoList from './pages/PromoList';
import PromoSetting from './pages/PromoSetting';
import PurchaseNote from './pages/PurchaseNote';
import PromoPerformanceReport from './pages/Report/PromoPerformanceReport';
import PromoRealTimeReport from './pages/Report/PromoRealTimeReport';
import ReconciliationCheck from './pages/Report/ReconciliationCheck';
import ReconciliationReport from './pages/Report/ReconciliationReport';
import VoucherPerformanceReport from './pages/Report/VoucherPerformanceReport';
import VoucherRealTimeReport from './pages/Report/VoucherRealTimeReport';
import ResetPwd from './pages/ResetPwd';
import ShipV2 from './pages/ShipV2';
import ShipV3 from './pages/ShipV3';
import ShippingPrintInfo from './pages/ShippingPrintInfo';
import StoreDetail from './pages/StoreDetail';
import StoreList from './pages/StoreList';
import StoreOrders from './pages/StoreOrders';
import StoreOrdersArrived from './pages/StoreOrdersArrived';
import StoreOrdersCancel from './pages/StoreOrdersCancel';
import StoreOrdersPickedUp from './pages/StoreOrdersPickedUp';
import StoreOrdersShipping from './pages/StoreOrdersShipping';
import StoreType from './pages/StoreType';
import UUCafeBannerSettings from './pages/UUCafeBannerSettings';
import UUCafeBottomHomeSettings from './pages/UUCafeBottomHomeSettings';
import UUCafeContactUs from './pages/UUCafeContactUs';
import UUCafeJourney from './pages/UUCafeJourney';
import UUCafeMealIntroCafeProduct from './pages/UUCafeMealIntroCafeProduct';
import UUCafeMealIntroHome from './pages/UUCafeMealIntroHome';
import UUCafeMealIntroMenu from './pages/UUCafeMealIntroMenu';
import UUCafeMealIntroOther from './pages/UUCafeMealIntroOther';
import UUCafeMealIntroOtherProduct from './pages/UUCafeMealIntroOtherProduct';
import UUCafeMenuSettings from './pages/UUCafeMenuSettings';
import UUCafeMiddleHomeSettings from './pages/UUCafeMiddleHomeSettings';
import UUCafeNews from './pages/UUCafeNews';
import UUCafeNewsDetail from './pages/UUCafeNewsDetail';
import UUCafeRecruitment from './pages/UUCafeRecruitment';
import UUCafeSEOSettings from './pages/UUCafeSEOSettings';
import UUCafeSEOSettingsDetail from './pages/UUCafeSEOSettingsDetail';
import UUCafeServices from './pages/UUCafeServices';
import UUCafeShop from './pages/UUCafeShop';
import UUCafeShopDetail from './pages/UUCafeShopDetail';
import UUCafeWorld from './pages/UUCafeWorld';
import UUCafeWorldDetail from './pages/UUCafeWorldDetail';
import Warranty from './pages/Warranty';
import WarrantyAnnouncementNotice from './pages/WarrantyAnnouncementNotice';
import WarrantyBrand from './pages/WarrantyBrand';
import WarrantyExpiration from './pages/WarrantyExpiration';
import WarrantyNum from './pages/WarrantyNum';
import WarrantyNumDetail from './pages/WarrantyNumDetail';
import WarrantyNumSearch from './pages/WarrantyNumSearch';
import WarrantyRegister from './pages/WarrantyRegister';
import WarrantyRegisterDetails from './pages/WarrantyRegisterDetails';
import WarrantyRegisterUnverified from './pages/WarrantyRegisterUnverified';
import WarrantyRegisterVerifying from './pages/WarrantyRegisterVerifying';
import BatchUnbind from './pages/WarrantyUnbind/BatchUnbind';
import YouMayAlsoLike from './pages/YouMayAlsoLike';
import YouMayAlsoLikeSetting from './pages/YouMayAlsoLikeSetting';
import history from './utils/createHistory';

function App() {
  return (
    <Router history={history}>
      <ToastContainer position="top-right" autoClose={5000} />
      <ScrollToTop />
      <SideBar />
      <Header />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/Overall" component={Overall} exact />
        <Route path="/BannerSetting" component={BannerSetting} />
        <Route path="/About" component={About} />
        <Route path="/SEOSettings" component={SEOSettings} />
        <Route path="/BrandControl" component={BrandControl} />
        <Route path="/BrandPreview/:id" component={BrandPreview} />
        <Route path="/BrandSetting/:brandId" component={BrandSetting} />
        <Route path="/BrandCategory/:brandId" component={BrandCategory} />
        <Route
          path="/BrandCategoryPrds/:brandId/:cateId"
          component={BrandCategoryPrds}
        />
        <Route
          path="/BrandCategoryGroupPrds/:brandId/:cateId"
          component={BrandCategoryGroupPrds}
        />
        <Route
          path="/DistributionServicesSettings"
          component={DistributionServicesSettings}
        />
        <Route path="/BrandCategoryUUPrds" component={BrandCategoryUUPrds} />
        <Route path="/CategoryControl" component={CategoryControl} />
        <Route path="/ProductControl" component={ProductControl} />
        <Route path="/ProductDetail/:id" component={ProductDetail} />
        <Route path="/AddNewProduct" component={AddNewProduct} />
        <Route
          path="/ProductSellingSettings"
          component={ProductSellingSettings}
        />
        <Route path="/LowestNotify" component={LowestNotify} />
        <Route path="/GroupModel" component={GroupModel} />
        <Route path="/GroupModelAdd" component={GroupModelDetail} />
        <Route path="/GroupModelDetail/:id" component={GroupModelDetail} />
        <Route path="/HideModel" component={HideModel} />
        <Route path="/HideModelDetail/:id" component={HideModelDetail} />
        <Route path="/ProductPreview/:id" component={ProductPreview} />
        <Route path="/PrdSpecList/:id" component={PrdSpecList} />
        <Route path="/ProductLinkControl" component={ProductLinkControl} />
        <Route path="/ProductLinkDetail/:id" component={ProductLinkDetail} />
        <Route path="/PrdCategoryPrds/:cateId" component={PrdCategoryPrds} />
        <Route
          path="/GroupPrdCategoryPrds/:cateId"
          component={GroupPrdCategoryPrds}
        />

        <Route path="/YouMayAlsoLike" component={YouMayAlsoLike} />
        <Route path="/FormDescriptionText" component={FormDescriptionText} />
        <Route
          path="/YouMayAlsoLikeSetting/:id"
          component={YouMayAlsoLikeSetting}
        />
        <Route path="/BestSeller" component={BestSeller} />
        <Route path="/BestSellerSetting/:id" component={BestSellerSetting} />
        <Route path="/CouponList" component={CouponList} />
        <Route path="/CouponBackground" component={CouponBackground} />
        <Route path="/NewsList" component={NewsList} />
        <Route path="/NewsDetail/:id?" component={NewsDetail} />
        <Route path="/NewsPreview/:id?" component={NewsPreview} />
        <Route path="/MembersInterests" component={MembersInterests} />
        <Route path="/ProductInstructions" component={ProductInstructions} />
        <Route
          path="/PrdInstructionsFAQ/:id/:model"
          component={PrdInstructionsFAQ}
        />
        <Route path="/Warranty" component={Warranty} />
        <Route path="/Maintenance" component={Maintenance} />
        <Route path="/FAQCategory" component={FAQCategory} />
        <Route path="/FAQList" component={FAQList} />
        <Route path="/AdminControl" component={AdminControl} />
        <Route path="/ResetPwd" component={ResetPwd} />
        <Route path="/Forgetpwd" component={Forgetpwd} />
        <Route path="/PersonalData" component={PersonalData} />
        <Route path="/Login" component={Login} />
        <Route path="/JoinUs" component={JoinUs} />
        <Route path="/JoinUsDetail/:id" component={JoinUsDetail} />
        <Route path="/CheckoutPayment" component={CheckoutPayment} />
        <Route path="/OrderReturnReason" component={OrderReturnReason} />
        <Route path="/CheckoutDelivery" component={CheckoutDelivery} />
        <Route
          path="/CheckoutReturnContact"
          component={CheckoutReturnContact}
        />
        <Route path="/PurchaseNote" component={PurchaseNote} />
        <Route
          path="/HomeDeliveryOrderConfirmList"
          component={HomeDeliveryOrderConfirmList}
        />
        <Route
          path="/HomeDeliveryOrderShippingList"
          component={HomeDeliveryOrderShippingList}
        />
        <Route
          path="/HomeDeliveryOrderFailedList"
          component={HomeDeliveryOrderFailedList}
        />
        <Route path="/CreateLogistics" component={CreateLogistics} />
        <Route
          path="/CreateLogisticsShipping"
          component={CreateLogisticsShipping}
        />
        <Route path="/ShippingPrintInfo" component={ShippingPrintInfo} />
        <Route
          path="/CreateLogisticsCancelLogistic"
          component={CreateLogisticsCancelLogistic}
        />
        <Route
          path="/CreateLogisticsCancelOrder"
          component={CreateLogisticsCancelOrder}
        />
        <Route path="/CreateLogisticsFail" component={CreateLogisticsFail} />
        <Route path="/CreateLogisticsSeven" component={CreateLogisticsSeven} />
        <Route
          path="/CreateLogisticsShippingSeven"
          component={CreateLogisticsShippingSeven}
        />
        <Route path="/VirtualTryOn" component={VirtualTryOn} />
        <Route
          path="/CreateLogisticsCancelLogisticSeven"
          component={CreateLogisticsCancelLogisticSeven}
        />
        <Route
          path="/CreateLogisticsCancelOrderSeven"
          component={CreateLogisticsCancelOrderSeven}
        />
        <Route
          path="/CreateLogisticsFailSeven"
          component={CreateLogisticsFailSeven}
        />
        <Route path="/StoreOrders" component={StoreOrders} />
        <Route path="/StoreOrdersShipping" component={StoreOrdersShipping} />
        <Route path="/StoreOrdersArrived" component={StoreOrdersArrived} />
        <Route path="/StoreOrdersPickedUp" component={StoreOrdersPickedUp} />
        <Route path="/StoreOrdersCancel" component={StoreOrdersCancel} />
        <Route path="/PersonalInformation" component={PersonalInformation} />
        <Route path="/OrderList" component={OrderList} />
        <Route path="/OrderDetail/:id" component={OrderDetail} />
        <Route path="/CancelOrderList" component={CancelOrderList} />
        <Route path="/CancelOrderCreate" component={CancelOrderCreate} />
        <Route path="/OrderReturnCreate" component={OrderReturnCreate} />
        <Route path="/OrderReturnStep1" component={OrderReturnStep1} />
        <Route path="/OrderReturnStep2" component={OrderReturnStep2} />
        <Route path="/OrderReturnStep3" component={OrderReturnStep3} />
        <Route
          path="/OrderReturnDeliveryList"
          component={OrderReturnDeliveryList}
        />
        <Route path="/OrderReturnList" component={OrderReturnList} />
        <Route path="/OrderReturnRepayList" component={OrderReturnRepayList} />
        <Route path="/OrderChangeCreate" component={OrderChangeCreate} />
        <Route path="/OrderChangeStep1" component={OrderChangeStep1} />
        <Route path="/OrderChangeStep2" component={OrderChangeStep2} />
        <Route path="/OrderChangeStep3" component={OrderChangeStep3} />
        <Route path="/OrderChangeList" component={OrderChangeList} />
        <Route path="/OrderReturnPayList" component={OrderReturnPayList} />
        <Route path="/OrderReturnPayATMYet" component={OrderReturnPayATMYet} />
        <Route path="/OrderReturnPayFail" component={OrderReturnPayFail} />
        <Route path="/OrderInvoiceList" component={OrderInvoiceList} />
        <Route path="/StoreList" component={StoreList} />
        <Route path="/StoreType" component={StoreType} />
        <Route path="/StoreDetail/:id" component={StoreDetail} />
        <Route path="/WarrantyBrand" component={WarrantyBrand} />
        <Route path="/WarrantyNum" component={WarrantyNum} />
        <Route path="/WarrantyNumDetail/:id" component={WarrantyNumDetail} />
        <Route path="/Ship" component={ShipV2} />
        <Route path="/Ship_New" component={ShipV3} />
        <Route path="/WarrantyNumSearch" component={WarrantyNumSearch} />
        <Route path="/ProductNum" component={ProductNum} />
        <Route path="/ProductNumDetail/:id" component={ProductNumDetail} />
        <Route path="/WarrantyExpiration" component={WarrantyExpiration} />
        <Route path="/WarrantyRegister" component={WarrantyRegister} />
        <Route
          path="/WarrantyRegisterDetails/:id"
          component={WarrantyRegisterDetails}
        />
        <Route
          path="/WarrantyRegisterUnverified"
          component={WarrantyRegisterUnverified}
        />
        <Route
          path="/WarrantyRegisterVerifying"
          component={WarrantyRegisterVerifying}
        />
        <Route path="/MemberList" component={MemberList} />
        <Route path="/ClearMemberList" component={ClearMemberList} />
        <Route path="/MemberDetails/:id" component={MemberDetails} />
        <Route path="/MemberLevelSettings" component={MemberLevelSettings} />
        <Route
          path="/MemberPresentSettings"
          component={MemberPresentSettings}
        />
        <Route
          path="/MemberBirthPresentSettings/:id"
          component={MemberBirthPresentSettings}
        />
        <Route
          path="/MemberPromotionPresentSettings/:id"
          component={MemberPromotionPresentSettings}
        />
        <Route
          path="/MemberOpenPresentSettings/:id"
          component={MemberOpenPresentSettings}
        />
        <Route path="/ChannelControl" component={ChannelControl} />
        <Route path="/MaintainanceList" component={MaintainanceList} />
        <Route path="/MaintainanceAdd" component={MaintainanceAdd} />
        <Route path="/ServiceForm" component={ServiceForm} />
        <Route
          path="/MaintainanceListSetting"
          component={MaintainanceListSetting}
        />
        <Route
          path="/MaintainanceContent/:id"
          component={MaintainanceContent}
        />
        <Route path="/OrderPrintDetail" component={OrderPrintDetail} />
        <Route path="/LogRecord" component={LogRecord} />
        <Route path="/LogRecordGuideLink" component={LogRecordGuideLink} />
        <Route
          path="/LogRecordWearableProduct"
          component={LogRecordWearableProduct}
        />
        <Route
          path="/LogRecordWearableProductDownload"
          component={LogRecordWearableProductDownload}
        />
        <Route
          path="/LogRecordWearableProductShare"
          component={LogRecordWearableProductShare}
        />
        <Route
          path="/LogRecordWearableProductExperience"
          component={LogRecordWearableProductExperience}
        />
        <Route path="/WebsiteSettings" component={WebsiteSettings} />
        <Route path="/MailRecords" component={MailRecords} />
        <Route path="/DiscountCompaign" component={DiscountCompaign} />
        <Route path="/DiscountCompaignCate" component={DiscountCompaignCate} />
        <Route path="/CompaignSettingPage" component={CompaignSettingPage} />
        <Route
          path="/UpdateCompaignSettingPage"
          component={UpdateCompaignSettingPage}
        />
        <Route path="/ViewCompaignSettingPage" component={ViewCompaignPage} />
        <Route
          path="/CopyCompaignSettingPage"
          component={CopyCompaignSettingPage}
        />
        <Route path={'/ShopBannerSetting'} component={ShopBannerSetting} />
        <Route
          path="/SortingActivityProducts"
          component={SortingActivityProducts}
        />
        <Route path="/GiftManagement" component={GiftManagement} />
        <Route path="/GiftSettings" component={GiftSettings} />
        <Route path="/ProductBinding" component={ProductBinding} />
        <Route path="/GiftDetail" component={GiftDetail} />
        <Route path="/GiftsBinding" component={GiftsBinding} />
        <Route path="/VoucherList" component={VoucherList} />
        <Route
          path="/ProductNoneApplySettings"
          component={ProductNoneApplySettings}
        />
        <Route path="/VoucherSettings" component={VoucherSettings} />
        <Route path="/MessageList" component={MessageList} />
        <Route path="/MessageCategory" component={MessageCategory} />
        <Route
          path="/MessageCategorySorting"
          component={MessageCategorySorting}
        />
        <Route path="/AutoReply" component={AutoReply} />
        <Route path="/StaffReply" component={StaffReply} />
        <Route
          path="/ProductCategorySorting"
          component={ProductCategorySorting}
        />
        <Route
          path="/ProductSalesDataRanking"
          component={ProductSalesDataRanking}
        />
        <Route path="/StoreSalesDataList" component={StoreSalesDataList} />
        <Route
          path="/OrderStatisticalTable"
          component={OrderStatisticalTable}
        />
        <Route path="/OrderSourceData" component={OrderSourceData} />
        <Route
          path="/OrderCancelStatisticalTable"
          component={OrderCancelStatisticalTable}
        />
        <Route path="/YourFavorite" component={YourFavorite} />
        <Route
          path="/RewardsPointsSettings"
          component={RewardsPointsSettings}
        />
        <Route path="/BlockList" component={BlockList} />
        <Route path="/BlockListBatchFile" component={BlockListBatchFile} />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route
          path="/RewardsPointsStatisticalTable"
          component={RewardsPointsStatisticalTable}
        />
        <Route exact path="/UUCafe/SEOSettings" component={UUCafeSEOSettings} />
        <Route
          exact
          path="/UUCafe/SEOSettings/:id"
          component={UUCafeSEOSettingsDetail}
        />
        <Route
          exact
          path="/UUCafe/MenuSettings"
          component={UUCafeMenuSettings}
        />
        <Route
          exact
          path="/UUCafe/BannerSettings"
          component={UUCafeBannerSettings}
        />
        <Route
          exact
          path="/UUCafe/MiddleHomeSettings"
          component={UUCafeMiddleHomeSettings}
        />
        <Route
          exact
          path="/UUCafe/BottomHomeSettings"
          component={UUCafeBottomHomeSettings}
        />
        <Route exact path="/UUCafe/News" component={UUCafeNews} />
        <Route exact path="/UUCafe/News/:id" component={UUCafeNewsDetail} />
        <Route exact path="/UUCafe/CafeWorld" component={UUCafeWorld} />
        <Route
          exact
          path="/UUCafe/CafeWorld/:id"
          component={UUCafeWorldDetail}
        />
        <Route exact path="/UUCafe/CafeService" component={UUCafeServices} />
        <Route exact path="/UUCafe/CafeShop" component={UUCafeShop} />
        <Route exact path="/UUCafe/CafeShop/:id" component={UUCafeShopDetail} />
        <Route
          exact
          path="/UUCafe/MealIntroHome"
          component={UUCafeMealIntroHome}
        />
        <Route
          exact
          path="/UUCafe/MealIntroMenu"
          component={UUCafeMealIntroMenu}
        />
        <Route
          exact
          path="/UUCafe/MealIntroOther"
          component={UUCafeMealIntroOther}
        />
        <Route
          exact
          path="/UUCafe/MealIntroCafeProduct/:id"
          component={UUCafeMealIntroCafeProduct}
        />
        <Route
          exact
          path="/UUCafe/MealIntroOtherProduct/:id"
          component={UUCafeMealIntroOtherProduct}
        />
        <Route exact path="/UUCafe/ContactUs" component={UUCafeContactUs} />
        <Route exact path="/UUCafe/Recruitment" component={UUCafeRecruitment} />
        <Route exact path="/UUCafe/Journey" component={UUCafeJourney} />
        <Route exact path="/PromoList" component={PromoList} />
        <Route exact path="/PromoSetting/:id" component={PromoSetting} />
        <Route
          exact
          path="/VoucherRealTimeReport"
          component={VoucherRealTimeReport}
        />
        <Route
          exact
          path="/VoucherPerformanceReport"
          component={VoucherPerformanceReport}
        />
        <Route
          exact
          path="/PromoRealTimeReport"
          component={PromoRealTimeReport}
        />
        <Route
          exact
          path="/PromoPerformanceReport"
          component={PromoPerformanceReport}
        />
        <Route
          exact
          path="/SpecifyPointSettings"
          component={SpecifyPointSettings}
        />
        <Route
          path="/SpecialEventPointSystem"
          component={SpecialEventPointSystem}
        />
        <Route
          path="/SpecialEventPointSystemAdd"
          component={SpecialEventPointSystemAdd}
        />
        <Route
          path="/SpecialEventPointSystemDetail/:action/:id"
          component={SpecialEventPointSystemDetail}
        />
        <Route exact path="/BatchUnbind" component={BatchUnbind} />
        <Route
          exact
          path="/ReconciliationReport"
          component={ReconciliationReport}
        />
        <Route
          exact
          path="/ReconciliationCheck"
          component={ReconciliationCheck}
        />
        <Route
          exact
          path="/ProductBrandBannerSettings"
          component={ProductBrandBannerSettings}
        />
        <Route
          path="/WarrantyAnnouncementNotice"
          component={WarrantyAnnouncementNotice}
          exact
        />
        <Route
            path="/MaintenanceAnnouncementNotice"
            component={MaintenanceAnnouncementNotice}
            exact
        />
        <Route
          path="/ProductCategoryPrdsExport"
          component={ProductCategoryPrdsExport}
          exact
        />
        <Redirect from="/UUCafe" to="/UUCafe/SEOSettings" />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
