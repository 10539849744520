import { useState, useEffect } from 'react';
import request from 'utils/useAxios';

const useScrapWarehouseOptions = () => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    request({
      method: 'get',
      url: '/Backend/RepairRegister/GetScrapWarehouseOption'
    })
      .then(res => {
        setLoading(false);
        setOptions((res.result?.result || []).map(o=>({
          ...o,
          value: String(o.supplierCode),
          label: o.name
        })));
      })
      .catch(() => {
        setOptions([]);
        setLoading(false);
      });
  }, []);

  return { loading, options };
};

export default useScrapWarehouseOptions;
