import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Card } from 'reactstrap';

import {
  getModelWarrantyDateSetting,
  saveModelWarrantyDateSetting,
} from '../../redux/actions/data/productActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const WarrantyPeriodSetting = ({ mid }) => {

  const [data, setData] = useState({
    extendDays: 0,
    mid: 0,
    warrantyDays: 0,
  });

  const onGetModelWarrantyDateSetting = useCallback(async (mid = 0) => {
    try {
      const data = await getModelWarrantyDateSetting(mid);
      setData(data.result);
    } catch (e) {
      console.log(e);
    }
  },[setData]);

  const handleSubmit = useCallback(async data => {
    try {
      await saveModelWarrantyDateSetting(data);
      MessageUtil.alertSuccess('更新成功')
    } catch (e) {
      console.log(e);
      MessageUtil.alertWanring('更新失敗')
    }
  }, [])

  useEffect(() => {
    if (mid) {
      setData({
        ...data,
        mid
      })
      onGetModelWarrantyDateSetting(mid);
    }
  }, [mid]);

  return (
    <Card className={style.card}>
      <div
        className="d-inline-flex flex-column p-4"
        style={{
          gap: '1rem',
        }}
      >
        <div className="d-inline-flex">
          <label style={{ width: '140px' }} className="required">
            保固期限
          </label>
          <div className="flex-1">
            <div className="d-inline-flex w-100 flex-wrap">
              {[
                {
                  label: '無',
                  value: 0,
                },
                {
                  label: '一年(365)',
                  value: 365,
                },
                {
                  label: '兩年(730)',
                  value: 730,
                },
                {
                  label: '三年(1095)',
                  value: 1095,
                },
              ].map(o => {
                return (
                  <div
                    key={JSON.stringify(o)}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      marginRight: '2rem',
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        cursor: 'pointer',
                        height: '15px',
                        width: '15px',
                      }}
                      value={o.value}
                      onChange={() => {
                        setData({
                          ...data,
                          warrantyDays: o.value,
                        });
                      }}
                      checked={data.warrantyDays === o.value}
                    />
                    <span className="ml-2">{o.label}</span>
                  </div>
                );
              })}
            </div>
            <p className="text-secondary fs-14">
              <samll> 設定【無】，前台維修單申請，將不會出現此品牌</samll>
            </p>
          </div>
        </div>

        <div className="d-inline-flex">
          <label style={{ width: '140px' }} className="required">
            延長日期
          </label>
          <div className="flex-1">
            <div className="d-inline-flex w-100 flex-wrap">
              {[
                {
                  label: '無',
                  value: 0,
                },
                {
                  label: '半年(183)',
                  value: 183,
                },
                {
                  label: '一年(365)',
                  value: 365,
                },
                {
                  label: '一年半(547)',
                  value: 547,
                },
                {
                  label: '兩年(730)',
                  value: 730,
                },
                {
                  label: '三年(1095)',
                  value: 1095,
                },
              ].map(o => {
                return (
                  <div
                    key={JSON.stringify(o)}
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      marginRight: '2rem',
                    }}
                  >
                    <input
                      type="radio"
                      style={{
                        cursor: 'pointer',
                        height: '15px',
                        width: '15px',
                      }}
                      value={o.value}
                      onChange={() => {
                        setData({
                          ...data,
                          extendDays: o.value,
                        });
                      }}
                      checked={data.extendDays === o.value}
                    />
                    <span className="ml-2">{o.label}</span>
                  </div>
                );
              })}
            </div>
            <p className="text-secondary fs-14">
              <samll>購買七日內，會員資料有填寫完整，可再延長保固</samll>
            </p>
          </div>
        </div>
        <hr />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={()=>{
              handleSubmit(data)
            }}
          >
            儲存
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default memo(WarrantyPeriodSetting);
