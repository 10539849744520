import RcPagination from 'rc-pagination';
import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Card } from 'reactstrap';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';

import {
  deleteCafeMealIntroOther,
  getCafeMealIntroOtherList,
} from '../../redux/actions/data/uuCafeMealOtherAction';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';

import OtherModel from './OtherModel';
import SortOtherModel from './SortOtherModel';

const OtherSettings = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(state => state.cafeMealInfoOtherList);
  const [page, setPage] = useState(1);
  const [menuModalShow, setOtherModalShow] = useState(false);
  const [sortOtherModalShow, setSortOtherModalShow] = useState(false);

  const detail = useRef({
    id: 0,
    name: '',
    prdCount: 0,
    sort: 0,
    status: 0,
    templateType: 1,
  });

  const handlePageChange = useCallback(
    value => {
      dispatch(
        getCafeMealIntroOtherList({
          page: value,
          page_size: list.page_size || 10,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, list],
  );

  const handleDeleteItem = useCallback(
    async detail => {
      try {
        await deleteCafeMealIntroOther({
          id: detail.id,
        });
        MessageUtil.toastSuccess('刪除成功!');
        handlePageChange(1);
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [handlePageChange],
  );

  useEffect(() => {
    handlePageChange(1);
  }, []);

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      {!loading ? (
        <>
          <OtherModel
            openModal={menuModalShow}
            setOpenModel={(show, isReload) => {
              if (isReload) {
                handlePageChange(1);
              }
              setOtherModalShow(show);
            }}
            detail={detail.current}
          />
          <SortOtherModel
            setOpenModel={show => {
              handlePageChange(1);

              setSortOtherModalShow(show);
            }}
            openModal={sortOtherModalShow}
          />
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Button
              color="success"
              onClick={() => {
                detail.current = {
                  imgFile: '',
                  imgUrl: '',
                  name: '',
                  prdCount: 0,
                  sort: 0,
                  status: 0,
                  templateType: 1,
                };
                setOtherModalShow(true);
              }}
            >
              新增
            </Button>
            <Button
              color="warning"
              onClick={() => {
                setSortOtherModalShow(true);
              }}
            >
              排序
            </Button>
          </div>
          <p className="small text-end mb-0">共 {list.total_count} 筆</p>
          <div className={style.table_overflow} style={{ minHeight: '200px' }}>
            <Table hover bordered striped>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th nowrap="nowrap">選單名稱</th>
                  <th nowrap="nowrap">樣版</th>
                  <th nowrap="nowrap">商品數量</th>
                  <th nowrap="nowrap">狀態</th>
                  <th nowrap="nowrap">功能</th>
                </tr>
              </thead>
              <tbody>
                {list.datas.map((data, index) => (
                  <tr key={index}>
                    <td nowrap="nowrap">{data.name}</td>
                    <td nowrap="nowrap">版型{data.templateType}</td>
                    <td>{data.prdCount}</td>
                    <td>{data.status ? '上架' : '下架'}</td>
                    <td nowrap="nowrap" className="text-center">
                      <Button
                        className="m-1"
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => {
                          detail.current = data;
                          setOtherModalShow(true);
                        }}
                      >
                        頁面設定
                      </Button>
                      <Button
                        className="m-1"
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => {
                          detail.current = data;
                          history.push(
                            `/UUCafe/MealIntroOtherProduct/${data.id}`,
                          );
                        }}
                      >
                        商品設定
                      </Button>
                      <Button
                        className="m-1"
                        size="sm"
                        outline
                        color="danger"
                        onClick={() => {
                          MessageUtil.submitConfirm({
                            doneFun: () => {
                              handleDeleteItem(data);
                            },
                            title: '確認是否刪除',
                          });
                        }}
                      >
                        刪除
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={page}
              pageSize={list.page_size || 10}
              total={list.total_count || 1}
              onChange={handlePageChange}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Card>
  );
};
export default memo(OtherSettings);
