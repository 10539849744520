import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSidebarToggleMobile } from '../redux/actions/style/sideBarActions';
import style from '../styles/sideBar.module.css';

import SideBarHeader from './SideBarHeader';
import SideBarMenu from './SideBarMenu';

const SideBar = () => {
  const dispatch = useDispatch();

  const {
    // sidebarStyle,
    // sidebarShadow,
    sidebarToggleMobile,
  } = useSelector(state => state.sideBar);

  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };
  return (
    <>
      <div className={style.sideBar}>
        <SideBarHeader />
        <SideBarMenu />
      </div>
      <div
        onClick={toggleSidebarMobile}
        className={clsx('app-sidebar-overlay', {
          'is-active': sidebarToggleMobile,
        })}
      />
    </>
  );
};

export default SideBar;
