export const GET_MESSAGE_CATEGORY_LIST_REQUEST =
  'GET_MESSAGE_CATEGORY_LIST_REQUEST';
export const GET_MESSAGE_CATEGORY_LIST_SUCCESS =
  'GET_MESSAGE_CATEGORY_LIST_SUCCESS';
export const GET_MESSAGE_CATEGORY_LIST_FAIL = 'GET_MESSAGE_CATEGORY_LIST_FAIL';

export const CREATE_MESSAGE_CATEGORY_IDLE = 'CREATE_MESSAGE_CATEGORY_IDLE';
export const CREATE_MESSAGE_CATEGORY_REQUEST =
  'CREATE_MESSAGE_CATEGORY_REQUEST';
export const CREATE_MESSAGE_CATEGORY_SUCCESS =
  'CREATE_MESSAGE_CATEGORY_SUCCESS';
export const CREATE_MESSAGE_CATEGORY_FAIL = 'CREATE_MESSAGE_CATEGORY_FAIL';

export const UPDATE_MESSAGE_CATEGORY_IDLE = 'UPDATE_MESSAGE_CATEGORY_IDLE';
export const UPDATE_MESSAGE_CATEGORY_REQUEST =
  'UPDATE_MESSAGE_CATEGORY_REQUEST';
export const UPDATE_MESSAGE_CATEGORY_SUCCESS =
  'UPDATE_MESSAGE_CATEGORY_SUCCESS';
export const UPDATE_MESSAGE_CATEGORY_FAIL = 'UPDATE_MESSAGE_CATEGORY_FAIL';

export const DELETE_MESSAGE_CATEGORY_IDLE = 'DELETE_MESSAGE_CATEGORY_IDLE';
export const DELETE_MESSAGE_CATEGORY_REQUEST =
  'DELETE_MESSAGE_CATEGORY_REQUEST';
export const DELETE_MESSAGE_CATEGORY_SUCCESS =
  'DELETE_MESSAGE_CATEGORY_SUCCESS';
export const DELETE_MESSAGE_CATEGORY_FAIL = 'DELETE_MESSAGE_CATEGORY_FAIL';

export const UPDATE_MESSAGE_CATEGORY_SORT_IDLE =
  'UPDATE_MESSAGE_CATEGORY_SORT_IDLE';
export const UPDATE_MESSAGE_CATEGORY_SORT_REQUEST =
  'UPDATE_MESSAGE_CATEGORY_SORT_REQUEST';
export const UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS =
  'UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS';
export const UPDATE_MESSAGE_CATEGORY_SORT_FAIL =
  'UPDATE_MESSAGE_CATEGORY_SORT_FAIL';

export const GET_AUTO_REPLY_MESSAGE_REQUEST = 'GET_AUTO_REPLY_MESSAGE_REQUEST';
export const GET_AUTO_REPLY_MESSAGE_SUCCESS = 'GET_AUTO_REPLY_MESSAGE_SUCCESS';
export const GET_AUTO_REPLY_MESSAGE_FAIL = 'GET_AUTO_REPLY_MESSAGE_FAIL';

export const SET_AUTO_REPLY_MESSAGE_IDLE = 'SET_AUTO_REPLY_MESSAGE_IDLE';
export const SET_AUTO_REPLY_MESSAGE_REQUEST = 'SET_AUTO_REPLY_MESSAGE_REQUEST';
export const SET_AUTO_REPLY_MESSAGE_SUCCESS = 'SET_AUTO_REPLY_MESSAGE_SUCCESS';
export const SET_AUTO_REPLY_MESSAGE_FAIL = 'SET_AUTO_REPLY_MESSAGE_FAIL';

export const GET_MESSAGE_LSIT_REQUEST = 'GET_MESSAGE_LSIT_REQUEST';
export const GET_MESSAGE_LIST_SUCCESS = 'GET_MESSAGE_LIST_SUCCESS';
export const GET_MESSAGE_LIST_FAIL = 'GET_MESSAGE_LIST_FAIL';

export const GET_MESSAGE_CATEGORY_OPTIONS_REQUEST =
  'GET_MESSAGE_CATEGORY_OPTIONS_REQUEST';
export const GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS =
  'GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS';
export const GET_MESSAGE_CATEGORY_OPTIONS_FAIL =
  'GET_MESSAGE_CATEGORY_OPTIONS_FAIL';

export const GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST =
  'GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST';
export const GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS =
  'GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS';
export const GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL =
  'GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL';

export const GET_SINGLE_MESSAGE_REQUEST = 'GET_SINGLE_MESSAGE_REQUEST';
export const GET_SINGLE_MESSAGE_SUCCESS = 'GET_SINGLE_MESSAGE_SUCCESS';
export const GET_SINGLE_MESSAGE_FAIL = 'GET_SINGLE_MESSAGE_FAIL';

export const CREATE_MESSAGE_REPLY_IDLE = 'CREATE_MESSAGE_REPLY_IDLE';
export const CREATE_MESSAGE_REPLY_REQUEST = 'CREATE_MESSAGE_REPLY_REQUEST';
export const CREATE_MESSAGE_REPLY_SUCCESS = 'CREATE_MESSAGE_REPLY_SUCCESS';
export const CREATE_MESSAGE_REPLY_FAIL = 'CREATE_MESSAGE_REPLY_FAIL';

export const SET_MESSAGE_CLOSED_IDLE = 'SET_MESSAGE_CLOSED_IDLE';
export const SET_MESSAGE_CLOSED_REQUEST = 'SET_MESSAGE_CLOSED_REQUEST';
export const SET_MESSAGE_CLOSED_SUCCESS = 'SET_MESSAGE_CLOSED_SUCCESS';
export const SET_MESSAGE_CLOSED_FAIL = 'SET_MESSAGE_CLOSED_FAIL';

export  const  UNREAD_MESSAGE_COUNT_REQUEST = 'UNREAD_MESSAGE_COUNT_REQUEST';
export  const  UNREAD_MESSAGE_COUNT_SUCCESS = 'UNREAD_MESSAGE_COUNT_SUCCESS';
export  const  UNREAD_MESSAGE_COUNT_FAIL = 'UNREAD_MESSAGE_COUNT_FAIL';
