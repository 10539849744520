import Layout from 'components/Layout';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Card, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import Loader from '../components/Loader';
import ProductRange from '../components/voucherSettings/discount/ProductRange';
import {
  createPromo,
  getPromoDetail,
  updatePromo,
} from '../redux/actions/data/promoActions';
import { GET_PROMO_DETAIL_SUCCESS } from '../redux/constants/data/promoConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const lableStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '150px',
};

const PromoSetting = () => {
  const initDate = moment().add(5, 'minute').toDate();

  const params = useParams();
  const dispatch = useDispatch();
  const { detail, loading } = useSelector(state => state.promoDetail);
  const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false);

  const goBack = useCallback(() => {
    history.push('/PromoList');
  }, []);

  const type = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('type') || '';
  }, [location?.search]);

  const isEditor = useMemo(() => {
    if (!type && detail.id) {
      if (detail.redeemStartTime && moment(detail.redeemStartTime).isValid()) {
        return moment(detail.redeemStartTime).isBefore(moment());
      }
      return true;
    }
    return false;
  }, [type, detail]);

  const handleSubmit = useCallback(
    async props => {
      const formData = new FormData();
      Object.keys(props.values).forEach(key => {
        if (typeof props.values[key] === 'object') {
          formData.append(key, JSON.stringify(props.values[key]));
        } else {
          if (props.values[key] === null) {
            formData.append(key, 0);
          } else {
            formData.append(key, String(props.values[key]));
          }
        }
      });

      formData.set(
        'groupProducts',
        JSON.stringify(
          (props.values?.models || [])
            .filter(m => m.modelType === '組合商品')
            .reduce((arr, model) => {
              return [
                ...arr,
                ...model.products.map(p => {
                  return {
                    ...p,
                    activityProductId: p.activityProductId,
                    area: p.area,
                    discountPrice: p.discountPrice,
                    mid: model.productType === 2 ? model.id : model.mid,
                    productId: p.productid || p.hProductId || p.productId,
                    productType: model.productType,
                    productid: p.productid || p.hProductId || p.productId,
                  };
                }),
              ];
            }, []),
        ),
      );
      formData.set(
        'products',
        JSON.stringify(
          (props.values?.models || [])?.reduce((arr, model) => {
            return [
              ...arr,
              ...model.products.map(p => {
                return {
                  ...p,
                  activityProductId: p.activityProductId,
                  area: p.area,
                  discountPrice: p.discountPrice,
                  mid: model.productType === 2 ? model.id : model.mid,
                  productType: model.productType,
                  productid: p.productid,
                };
              }),
            ];
          }, []),
        ),
      );

      if (type) {
        formData.set('id', 0);
      }

      try {
        if (type === 'copy' || params.id === 'add') {
          if (
            !props.values.redeemStartTime ||
            (props.values.redeemStartTime &&
              moment(props.values.redeemStartTime).isBefore(moment()))
          ) {
            MessageUtil.alertWanring('活動期限開始時間不可小於現在時間');
            return true;
          }
          if (
            !props.values.redeemEndTime ||
            (props.values.redeemEndTime &&
              moment(props.values.redeemEndTime).isBefore(moment()))
          ) {
            MessageUtil.alertWanring('活動期限結束時間不可小於現在時間');
            return true;
          }
          setIsSubmit(true);
          await createPromo(formData);
          MessageUtil.toastSuccess('新增成功');
          setIsSubmit(false);
          goBack();
        } else {
          if (
            !props.values.redeemEndTime ||
            (props.values.redeemEndTime &&
              moment(props.values.redeemEndTime).isBefore(moment()))
          ) {
            MessageUtil.alertWanring('活動期限結束時間不可小於現在時間');
            return true;
          }
          setIsSubmit(true);
          await updatePromo(formData);
          MessageUtil.toastSuccess('修改成功');
          dispatch(
            getPromoDetail({
              id: params.id,
            }),
          );
          setIsSubmit(false);
        }
      } catch (e) {
        if (type === 'copy' || params.id === 'add') {
          MessageUtil.toastWarning('新增失敗', e);
        } else {
          MessageUtil.toastWarning('修改失敗', e);
        }
        dispatch({
          payload: props.values,
          type: GET_PROMO_DETAIL_SUCCESS,
        });
        setIsSubmit(false);
      }
    },
    [detail, location, params],
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      discountAmount: Yup.number()
        .min(0.1, '請輸入大於0的數字')
        .required('必填!')
        .when(['discountType'], {
          is: discountType => {
            return discountType === 2;
          },
          then: Yup.number().max(9.9, '不可大於9.9'),
        }),
      models: Yup.array().test('invalid', '請選擇商品', (value, { parent }) => {
        return (
          parent.productRange !== 'picked' ||
          (parent.productRange === 'picked' && value?.length > 0)
        );
      }),
      name: Yup.string().max(50, '超過字數').required('必填!'),
      promoCode: Yup.string()
        .max(20, '超過字數')
        .matches(/^[a-zA-Z0-9]+$/, '只允許英文字母和數字')
        .required('必填!'),
      redeemEndTime: Yup.string().required('請選擇日期'),
      redeemStartTime: Yup.string().required('請選擇日期'),
      useMinAmount: Yup.number().when(['useMin'], {
        is: useMin => {
          return ~~useMin === 2;
        },
        then: Yup.number()
          .min(1, '請輸入大於0的數字')
          .required('請輸入大於0的數字'),
      }),
    });
    return schema;
  };

  useEffect(() => {
    dispatch({
      payload: {},
      type: GET_PROMO_DETAIL_SUCCESS,
    });
    if (params.id !== 'add' && params.id) {
      dispatch(
        getPromoDetail({
          id: params.id,
        }),
      );
    } else {
      dispatch({
        payload: {
          availableWay: ['web'],
          createTime: moment().format('YYYY/MM/DD HH:mm'),
          creator: '',
          discountAmount: 0,
          discountType: 1,
          groupProducts: [],
          id: 0,
          isUsedwithCoupon: true,
          isUsedwithDiscount: true,
          isUsedwithYouLike: true,
          name: '',
          name_en: '',
          productRange: 'all',
          products: [],
          promoCode: '',
          redeemEndTime: moment().add(30, 'minute').format('YYYY/MM/DD HH:mm'),
          redeemStartTime: moment(initDate).format('YYYY/MM/DD HH:mm'),
          status: 1,
          updateTime: moment().format('YYYY/MM/DD HH:mm'),
          updater: '',
          useMin: 1,
          useMinAmount: 0,
        },
        type: GET_PROMO_DETAIL_SUCCESS,
      });
    }
  }, [params]);

  return (
    <Layout
      pageTitle="優惠碼"
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: false, page_name: '優惠碼', to: '/PromoList' },
        {
          isActive: true,
          page_name: `優惠碼-${params.id === 'add' ? '新增' : '編輯'}`,
        },
      ]}
    >
      {loading && <Loader />}
      {Object.keys(detail).length > 0 && !loading && (
        <Card className={style.card}>
          <Formik
            enableReinitialize
            initialValues={{
              ...detail,
              groupProducts:
                  (detail?.groupProducts || []).map(p => {
                    return {
                      ...p,
                      modelType:
                          p.productType === 1
                              ? '一般商品'
                              : p.productType === 2
                                  ? '隱形賣場'
                                  : '組合商品',
                    };
                  }),
              models: [
                ...(detail?.products || []).map(p => {
                  return {
                    ...p,
                    modelType:
                        p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                                ? '隱形賣場'
                                : '組合商品',
                  };
                }),
                ...(detail?.groupProducts|| []).map(p => {
                  return {
                    ...p,
                    modelType:
                        p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                                ? '隱形賣場'
                                : '組合商品',
                  };
                })
              ],
              products:
                  (detail?.products||[]).map(p => {
                    return {
                      ...p,
                      modelType:
                          p.productType === 1
                              ? '一般商品'
                              : p.productType === 2
                                  ? '隱形賣場'
                                  : '組合商品',
                    };
                  })  ,
            }}
            validationSchema={getValidationSchema}
          >
            {props => (
              <Form className="form-list form-label-140">
                <h4 className="title" style={titleStyle}>
                  基本設定
                </h4>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    活動名稱(中文)
                  </label>
                  <div className="form-item">
                    <ul>
                      <li>
                        <small className="text-secondary">
                          活動名稱僅供活動辨識，不會顯示於前台。
                        </small>
                      </li>
                    </ul>
                    <div className="d-flex">
                      <Field
                        name="name"
                        className="form-control mx-2"
                        disabled={type === 'view'}
                      />
                      <span
                        style={{ minWidth: '80px' }}
                        className={`text-nowrap ${
                          props.values?.name?.length > 50 ? 'text-danger' : ''
                        }`}
                      >
                        {props.values?.name?.length || 0}/50
                      </span>
                    </div>
                    {props.errors?.name && (
                      <div className="form-error px-2">
                        {props.errors?.name}
                      </div>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3 d-none">
                  <label className="required" style={lableStyle}>
                    活動名稱(英文)
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Field
                        name="name_en"
                        className="form-control mx-2"
                        disabled={type === 'view'}
                      />
                      <span
                        style={{ minWidth: '80px' }}
                        className={`text-nowrap ${
                          props.values?.name_en?.length > 50
                            ? 'text-danger'
                            : ''
                        }`}
                      >
                        {props.values?.name_en?.length || 0}/50
                      </span>
                    </div>
                    {props.errors?.name_en && (
                      <div className="form-error px-2">
                        {props.errors?.name_en}
                      </div>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    活動期限
                  </label>
                  <div className="form-item">
                    <div className="d-flex align-items-center">
                      <DatePicker
                        dateFormat="yyyy/MM/dd HH:mm"
                        className="form-control mx-2"
                        showTimeSelect
                        selected={moment(
                          props.values.redeemStartTime || initDate,
                        ).toDate()}
                        disabled={type === 'view' || isEditor}
                        name="redeemStartTime"
                        onChange={date => {
                          props.setFieldValue(
                            'redeemStartTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );
                          if (
                            moment(date).isAfter(
                              moment(props.values.redeemEndTime),
                            )
                          ) {
                            props.setFieldValue(
                              'redeemEndTime',
                              moment(date)
                                .add(30, 'minutes')
                                .format('yyyy/MM/DD HH:mm'),
                            );
                          }
                        }}
                        minDate={initDate}
                      />
                      <span className="mx-2">~</span>
                      <DatePicker
                        disabled={type === 'view'}
                        className="form-control mx-2"
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        selected={moment(
                          props.values.redeemEndTime || initDate,
                        ).toDate()}
                        name="redeemEndTime"
                        onChange={date => {
                          props.setFieldValue(
                            'redeemEndTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );
                        }}
                        minDate={moment(
                          props.values.redeemStartTime || initDate,
                        ).toDate()}
                      />
                    </div>
                  </div>
                </FormGroup>
                <div className="my-2" />
                <h4 className="title" style={titleStyle}>
                  會員回饋條件
                </h4>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    活動對象
                  </label>
                  <div className="form-item">全體會員</div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    回饋次數
                  </label>
                  <div className="form-item">
                    會員不限消費次數(均可使用優惠碼)
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    消費門檻
                  </label>
                  <div className="form-item">
                    <div className="d-flex align-items-center">
                      <label
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor="radio-useMin-1"
                      >
                        <div style={{ height: '25px', width: '25px' }}>
                          <Field
                            type="radio"
                            name="useMin"
                            value={1}
                            disabled={type === 'view' || isEditor}
                            id="radio-useMin-1"
                            onChange={() => {
                              props.setFieldValue('useMin', 1);
                              props.setFieldValue('useMinAmount', 0);
                            }}
                          />
                        </div>
                        <span className="fw-medium px-1"> 無門檻</span>
                      </label>
                      <label
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor="radio-useMin-2"
                      >
                        <div style={{ height: '25px', width: '25px' }}>
                          <Field
                            type="radio"
                            name="useMin"
                            disabled={type === 'view' || isEditor}
                            value={2}
                            id="radio-useMin-2"
                            onChange={() => {
                              props.setFieldValue('useMin', 2);
                              props.setFieldValue('useMinAmount', 0);
                            }}
                          />
                        </div>
                        <span className="fw-medium px-1">
                          滿額門檻，金額需滿NT$
                        </span>
                        <Field
                          type="number"
                          name="useMinAmount"
                          disabled={
                            type === 'view' ||
                            isEditor ||
                            props.values.useMin !== 2
                          }
                          className="form-control mx-2"
                          style={{ maxWidth: '80px' }}
                          min="0"
                        />
                        {props.errors?.useMinAmount && (
                          <div className="form-error px-2 fw-light">
                            {props.errors?.useMinAmount}
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    活動範圍
                  </label>
                  <div className="form-item">
                    <div className="d-flex align-items-center">
                      {[
                        { name: '全店商品', value: 'all' },
                        { name: '指定商品', value: 'picked' },
                      ].map((op, index) => {
                        return (
                          <label
                            key={`radio-range-${op.value}`}
                            className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                            htmlFor={`radio-range-${op.value}`}
                          >
                            <Field
                              type="radio"
                              name="productRange"
                              disabled={type === 'view'}
                              value={op.value}
                              id={`radio-range-${op.value}`}
                              onChange={() => {
                                props.setFieldValue('productRange', op.value);
                                props.setFieldTouched('products', true);
                              }}
                            />
                            <span className="fw-medium px-1"> {op.name}</span>
                          </label>
                        );
                      })}
                    </div>

                    <ul>
                      <li>
                        <small className="text-secondary">
                          商品清單更新後，系統將於30分鐘後生效。
                        </small>
                      </li>
                    </ul>
                  </div>
                </FormGroup>
                <ProductRange
                  errors={props.errors}
                  touched={props.touched}
                  disabled={type === 'view'}
                  type={props.values.productRange}
                  models={props.values.models}
                  onChange={(products, type) => {

                    props.setFieldTouched('models', true);
                    props.setFieldValue('models', products);
                  }}
                />
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    <span className="align-top text-danger">*</span>折抵類型
                  </label>
                  <div className="form-item">
                    <div className="d-flex align-items-center">
                      {[
                        { name: '消費現折', value: 1 },
                        { name: '消費打折', value: 2 },
                      ].map((op, index) => {
                        return (
                          <label
                            key={`radio-discountType-${op.value}`}
                            className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                            htmlFor={`radio-discountType-${op.value}`}
                          >
                            <Field
                              type="radio"
                              name="discountType"
                              disabled={type === 'view' || isEditor}
                              value={op.value}
                              id={`radio-discountType-${op.value}`}
                              onChange={() => {
                                props.setFieldValue('discountType', op.value);
                                props.setFieldValue('discountAmount', 0);
                              }}
                            />
                            <span className="fw-medium px-1"> {op.name}</span>
                          </label>
                        );
                      })}
                    </div>
                    {props.values.discountType === 2 && (
                      <ul>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            折扣請輸入1.0 ~ 9.9，若打85折，請輸入「8.8」折
                          </small>
                        </li>
                      </ul>
                    )}
                    <div className="d-flex align-items-center my-2">
                      {props.values.discountType === 1 && '輸入優惠碼可折抵NT$'}
                      {props.values.discountType === 2 &&
                        '輸入優惠碼享打折優惠'}
                      <Field
                        disabled={type === 'view' || isEditor}
                        placeholder="請輸入數字"
                        name="discountAmount"
                        className="form-control mx-2"
                        style={{ maxWidth: '150px' }}
                        type="number"
                        step={props.values.discountType === 2 ? '0.1' : '1'}
                        min="0"
                      />
                      {props.errors?.discountAmount && (
                        <div className="form-error px-2">
                          {props.errors?.discountAmount}
                        </div>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    是否與折價券合併使用
                  </label>
                  <div className="form-item">
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithCoupon-1"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithCoupon"
                        value={true}
                        id="radio-isUsedwithCoupon-1"
                        onChange={() => {
                          props.setFieldValue('isUsedwithCoupon', true);
                        }}
                      />
                      <span className="fw-medium px-1">
                        <span className="mx-2">是</span>
                        <span className="text-secondary">
                          (參與此檔活動時，可同時使用優惠碼)
                        </span>
                      </span>
                    </label>
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithCoupon-2"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithCoupon"
                        value={false}
                        id="radio-isUsedwithCoupon-2"
                        onChange={() => {
                          props.setFieldValue('isUsedwithCoupon', false);
                        }}
                      />
                      <span className="fw-medium px-1">
                        {' '}
                        <span className="mx-2">否</span>
                        <span className="text-secondary">(無法使用優惠碼)</span>
                      </span>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    是否與折價活動合併使用
                  </label>
                  <div className="form-item">
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithDiscount-1"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithDiscount"
                        value={true}
                        id="radio-isUsedwithDiscount-1"
                        onChange={() => {
                          props.setFieldValue('isUsedwithDiscount', true);
                        }}
                      />

                      <span className="fw-medium px-1">
                        {' '}
                        <span className="mx-2">是</span>
                        <span className="text-secondary">
                          (參與此檔活動時，可同時使用優惠碼)
                        </span>
                      </span>
                    </label>
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithDiscount-2"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithDiscount"
                        value={false}
                        id="radio-isUsedwithDiscount-2"
                        onChange={() => {
                          props.setFieldValue('isUsedwithDiscount', false);
                        }}
                      />

                      <span className="fw-medium px-1">
                        {' '}
                        <span className="mx-2">否</span>
                        <span className="text-secondary">(無法使用優惠碼)</span>
                      </span>
                    </label>
                  </div>
                </FormGroup>

                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    是否與你真的喜歡合併使用
                  </label>
                  <div className="form-item">
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithYouLike-1"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithYouLike"
                        value={true}
                        id="radio-isUsedwithYouLike-1"
                        onChange={() => {
                          props.setFieldValue('isUsedwithYouLike', true);
                        }}
                      />
                      <span className="fw-medium px-1">
                        <span className="mx-2">是</span>
                        <span className="text-secondary">
                          (參與此檔活動時，可同時使用優惠碼)
                        </span>
                      </span>
                    </label>
                    <label
                      className="d-inline-flex w-100 align-items-center"
                      htmlFor="radio-isUsedwithYouLike-2"
                    >
                      <Field
                        type="radio"
                        disabled={type === 'view' || isEditor}
                        name="isUsedwithYouLike"
                        value={false}
                        id="radio-isUsedwithYouLike-2"
                        onChange={() => {
                          props.setFieldValue('isUsedwithYouLike', false);
                        }}
                      />
                      <span className="fw-medium px-1">
                        <span className="mx-2">否</span>
                        <span className="text-secondary">(無法使用優惠碼)</span>
                      </span>
                    </label>
                  </div>
                </FormGroup>
                <div className="my-2" />
                <h4 className="title" style={titleStyle}>
                  優惠碼設定
                </h4>
                <FormGroup className="form-group mb-3 mb-3">
                  <div className="form-item">
                    <ul>
                      <li>
                        <small className="text-secondary">
                          注意:此優惠碼活動開始後，將無法編輯。
                        </small>
                      </li>
                      <li>
                        <small className="text-secondary">
                          優惠碼僅限輸入數字與英文字母。
                        </small>
                      </li>
                      <li>
                        <small className="text-secondary">
                          優惠碼相同活動期間不得重複。
                        </small>
                      </li>
                    </ul>
                    <div className="d-flex">
                      <Field
                        name="promoCode"
                        className="form-control mx-2"
                        disabled={type === 'view' || isEditor}
                      />
                      <span
                        style={{ minWidth: '80px' }}
                        className={`text-nowrap ${
                          props.values?.promoCode?.length > 20
                            ? 'text-danger'
                            : ''
                        }`}
                      >
                        {props.values?.promoCode?.length || 0}/20
                      </span>
                    </div>
                    {props.errors?.promoCode && (
                      <div className="form-error px-2">
                        {props.errors?.promoCode}
                      </div>
                    )}
                  </div>
                </FormGroup>
                <div className="mt-4 text-nowrap text">
                  <div
                    className="d-inline-block col-4"
                    style={{ minWidth: '300px' }}
                  >
                    新增時間: {props.values.createTime} {props.values.creator}
                  </div>
                  {!!detail.id && (
                    <div
                      className="d-inline-block col-4"
                      style={{ minWidth: '300px' }}
                    >
                      異動時間: {props.values.updateTime} {props.values.updater}
                    </div>
                  )}
                </div>
                <hr />
                <div className="text-center">
                  <Button
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={goBack}
                  >
                    <span className="btn-wrapper--label">取消</span>
                  </Button>
                  {type !== 'view' && (
                    <Button
                      type="button"
                      className={style.modal_button_submit}
                      disabled={
                        Object.keys(props.errors).length ||
                        !props.dirty ||
                        isSubmit
                      }
                      onClick={() => {
                        handleSubmit(props);
                      }}
                    >
                      {isSubmit && (
                        <span
                          className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '1rem' }}
                        ></span>
                      )}
                      <span className="btn-wrapper--label">儲存</span>
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </Layout>
  );
};

export default PromoSetting;
