import React from 'react';
import { FormGroup } from 'reactstrap';

import style from '../../styles/layout.module.css';

export const DisplaySettings = ({ values, setFieldValue }) => {
  return (
    <FormGroup className={style.formGroup}>
      <label className="w150">
        前台商城是否
        <br />
        要出現折扣活動頁
      </label>
      <div className={style.show_flex}>
        <div
          className={style.show_flex}
          style={{ marginRight: '1.5rem', width: '90px' }}
        >
          <input
            type="radio"
            style={{ height: '20px', width: '20px' }}
            checked={values.isShow}
            onChange={() => {
              setFieldValue('isShow', true);
            }}
          />
          <span className="ml-2">是</span>
        </div>
        <div
          className={style.show_flex}
          style={{ marginRight: '1.5rem', width: '90px' }}
        >
          <input
            type="radio"
            style={{ height: '20px', width: '20px' }}
            checked={!values.isShow}
            onChange={() => {
              setFieldValue('isShow', false);
            }}
          />
          <span className="ml-2">否</span>
        </div>
      </div>
    </FormGroup>
  );
};
