import Layout from 'components/Layout';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { CONSTANT } from '../components/memberPresentSettings/PresentSettings';
import {
  deleteMembershipGift,
  getMemberLevelOptions,
  getMembershipList,
} from '../redux/actions/data/memberActions';
import { exportSingleMembershipGift } from '../redux/actions/data/voucherActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const DateTypeOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '發送日期/活動期間',
    value: '發送日期/活動期間',
  },
  {
    label: '使用期間',
    value: '使用期間',
  },
  {
    label: '使用期間',
    value: '使用期間',
  },
];

const ActivityTypeOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '生日禮',
    value: '生日禮',
  },
  {
    label: '升等禮',
    value: '升等禮',
  },
  {
    label: '開卡禮',
    value: '開卡禮',
  },
];

const NumberQueryTypeOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '折價券代碼',
    value: '折價券代碼',
  },
  {
    label: '活動序號',
    value: '活動序號',
  },
];

const MemberPresentSettings = () => {
  const { location } = history;

  const [datas, setDatas] = useState({});

  const { values, handleSubmit, handleReset, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        activityType: '',
        availableWay: [],
        endTime: '',
        keyword: '',
        memberLevelId: '',
        numberQuery: '',
        numberQueryType: '',
        page: 1,
        startTime: '',
        status: [],
        timeType: '',
      },
      onReset: () => {
        history.push(`${location.pathname}?page=1&v=${new Date().getTime()}`);
      },
      onSubmit: async values => {
        try {
          let o = {};
          Object.keys(values).forEach(key => {
            if (values[key]) {
              o[key] = values[key];
            }
          });
          const params = new URLSearchParams({
            ...o,
            availableWay: values?.availableWay
              ? JSON.stringify(values?.availableWay)
              : '',
            status: values?.status ? JSON.stringify(values?.status) : '',
          });

          history.push(
            `${
              location.pathname
            }?${params.toString()}&v=${new Date().getTime()}`,
          );
        } catch (e) {}
      },
    });

  const { memberLevelOptions, loading: memberLevelOptionsLoading } =
    useSelector(state => state.getMemberLevelOptions);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  useEffect(() => {
    setDatas(undefined);

    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams);
      const body = {
        activityType: params?.activityType ?? '',
        availableWay: params?.availableWay
          ? JSON.parse(params?.availableWay)
          : [],
        endTime: params?.endTime ?? '',
        keyword: params?.keyword ?? '',
        memberLevelId: Number(params?.memberLevelId ?? 0),
        numberQuery: params?.numberQuery ?? '',
        numberQueryType: params?.numberQueryType ?? '',
        page: params?.page ?? 1,
        startTime: params?.startTime ?? '',
        status: params?.status ? JSON.parse(params?.status) : [],
        timeType: params?.timeType ?? '',
      };

      setDatas(null);
      getMembershipList(body)
        .then(res => {
          console.log(res.result);
          setDatas(res.result);
        })
        .catch(e => {
          setDatas({
            current_page: 1,
            datas: [],
            page_size: 10,
            total_count: 0,
          });
        });
      setValues(body);
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle={'會員禮設定'}
      items={[
        { isActive: true, page_name: '會員管理' },
        { isActive: false, page_name: '會員禮設定' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <div className="d-flex flex-wrap">
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">關鍵字</label>
              <Input
                className="d-inline-block w-auto"
                style={{ minWidth: '200px' }}
                placeholder="輸入關鍵字"
                value={values.keyword}
                onChange={e => {
                  setFieldValue('keyword', e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">區間查詢</label>
              <div className="d-inline-flex flex-nowrap align-items-center">
                <Select
                  className={style['form-select']}
                  options={DateTypeOptions}
                  value={
                    DateTypeOptions?.filter(m => m.value === values.timeType)[0]
                  }
                  onChange={option => setFieldValue('timeType', option.value)}
                />

                <div
                  className="d-flex flex-nowrap mx-2"
                  style={{ minWidth: '250px' }}
                >
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      values?.startTime
                        ? moment(values?.startTime).toDate()
                        : ''
                    }
                    onChange={date => {
                      setFieldValue(
                        'startTime',
                        moment(date).format('YYYY/MM/DD'),
                      );
                    }}
                  />
                  <span className="mx-3">~</span>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      values?.endTime ? moment(values?.endTime).toDate() : ''
                    }
                    onChange={date => {
                      setFieldValue(
                        'endTime',
                        moment(date).format('YYYY/MM/DD'),
                      );
                    }}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">會員等級</label>
              <Select
                isLoading={memberLevelOptionsLoading}
                className={style['form-select']}
                options={
                  memberLevelOptions && [
                    { label: '全部', value: '' },
                    ...memberLevelOptions,
                  ]
                }
                value={
                  memberLevelOptions?.find(
                    m => m.value === values.memberLevelId,
                  )
                    ? memberLevelOptions?.filter(
                        m => m.value === values.memberLevelId,
                      )[0]
                    : { label: '全部', value: '' }
                }
                onChange={option =>
                  setFieldValue('memberLevelId', option.value)
                }
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">會員禮別</label>
              <Select
                className={style['form-select']}
                options={ActivityTypeOptions}
                value={
                  ActivityTypeOptions?.filter(
                    m => m.value === values.activityType,
                  )[0]
                }
                onChange={option => setFieldValue('activityType', option.value)}
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">序號查詢</label>
              <div className="d-flex">
                <Select
                  className={style['form-select']}
                  options={NumberQueryTypeOptions}
                  value={
                    NumberQueryTypeOptions?.filter(
                      m => m.value === values.numberQueryType,
                    )[0]
                  }
                  onChange={option =>
                    setFieldValue('numberQueryType', option.value)
                  }
                />
                <Input
                  className="w-auto mx-2"
                  style={{ borderRadius: '0px' }}
                  value={values.numberQuery}
                  onChange={e => {
                    setFieldValue('numberQuery', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">活動狀態</label>
              <div className="d-inline-flex flex-wrap align-items-center">
                {[
                  {
                    text: '尚未開始',
                    value: 1,
                  },
                  {
                    text: '發送中',
                    value: 2,
                  },
                  {
                    text: '待使用',
                    value: 3,
                  },
                  {
                    text: '進行中',
                    value: 4,
                  },
                  {
                    text: '已結束',
                    value: 5,
                  },
                ].map((option, index) => {
                  return (
                    <div
                      key={JSON.stringify(option)}
                      className={style['radio-btn-layout']}
                      style={{ marginRight: '1.5rem' }}
                    >
                      <input
                        id={JSON.stringify(option)}
                        type="checkbox"
                        className={style['radio-btn']}
                        checked={values?.status?.includes(option.value)}
                        onChange={() => {
                          if (values?.status?.includes(option.value)) {
                            setFieldValue(
                              'status',
                              values?.status?.filter(s => s !== option.value),
                            );
                          } else {
                            setFieldValue('status', [
                              ...(values?.status || []).filter(
                                s => s !== option.value,
                              ),
                              option.value,
                            ]);
                          }
                        }}
                      />
                      <span>{option.text}</span>
                    </div>
                  );
                })}
              </div>
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center flex-nowrap flex-grow-0">
              <label className="mx-3 text-nowrap">使用通路</label>
              <div className="d-inline-flex flex-wrap align-items-center">
                {[
                  {
                    text: '網頁版',
                    value: 'web',
                  },
                  {
                    text: '門市',
                    value: 'store',
                  },
                ].map((option, index) => {
                  return (
                    <div
                      key={JSON.stringify(option)}
                      className={style['radio-btn-layout']}
                      style={{ marginRight: '1.5rem' }}
                    >
                      <input
                        id={JSON.stringify(option)}
                        type="checkbox"
                        className={style['radio-btn']}
                        checked={values?.availableWay?.some(
                          s => s === option.value,
                        )}
                        onChange={() => {
                          if (values?.availableWay?.includes(option.value)) {
                            setFieldValue(
                              'availableWay',
                              values?.availableWay?.filter(
                                s => s !== option.value,
                              ),
                            );
                          } else {
                            setFieldValue('availableWay', [
                              ...(values?.availableWay || []).filter(
                                s => s !== option.value,
                              ),
                              option.value,
                            ]);
                          }
                        }}
                      />
                      <span>{option.text}</span>
                    </div>
                  );
                })}
              </div>
            </FormGroup>
          </div>
        </CardBody>

        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={handleReset}
          >
            取消
          </Button>
          <Button className={style.modal_button_submit} onClick={handleSubmit}>
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <CardBody>
          <Row className="mb-3">
            <Col sm={12} md={8}>
              <Button
                color="success"
                className="me-3 mb-1"
                onClick={() => {
                  history.push('/MemberBirthPresentSettings/0?type=add');
                }}
              >
                新增生日禮
              </Button>
              <Button
                color="success"
                className="me-3  mb-1"
                onClick={() => {
                  history.push('/MemberPromotionPresentSettings/0?type=add');
                }}
              >
                新增升等禮
              </Button>
              <Button
                color="success"
                className="me-3  mb-1"
                onClick={() => {
                  history.push('/MemberOpenPresentSettings/0?type=add');
                }}
              >
                新增開卡禮
              </Button>
            </Col>
            <Col sm={12} md={4} className="text-end">
              <Button
                color="success"
                className="me-3"
                onClick={() => history.push('/ProductNoneApplySettings')}
              >
                設定不適用任何折價券商品
              </Button>
            </Col>
          </Row>
          <div className="table-responsive mb-5">
            <Table hover striped bordered>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>
                    <div>活動序號</div>
                    <div>活動名稱</div>
                  </th>
                  <th>會員禮別</th>
                  <th>會員等級</th>
                  <th>生日月份</th>
                  <th>
                    <div>活動卷型</div>
                    <div>面額</div>
                  </th>
                  <th>折抵類型</th>
                  <th>折價券代碼</th>
                  <th
                    style={{
                      minWidth: '250px',
                    }}
                  >
                    折抵上限
                  </th>
                  <th>可使用商品</th>
                  <th>排除商品</th>
                  <th>使用通路</th>
                  <th>
                    <div>總券數</div>
                    <div>已發送</div>
                  </th>
                  <th>發送日期</th>
                  <th>活動期間</th>
                  <th>使用區間</th>
                  <th>使用活動狀態</th>
                  <th>異動日期</th>
                  <th>功能</th>
                </tr>
              </thead>
              {!datas && <TableLoading />}
              {datas && (
                <tbody>
                  {datas.datas?.length ? (
                    datas.datas.map(d => {
                      return (
                        <tr key={JSON.stringify(d)}>
                          <td>
                            <div>{d.activityCode ?? '--'}</div>
                            <div>{d.name ?? '--'}</div>
                          </td>
                          <td>
                            <div>{d.activityType ?? '--'}</div>
                          </td>
                          <td>
                            {d.memberLevelIds?.length > 0 &&
                            memberLevelOptions?.length > 0
                              ? memberLevelOptions
                                  .filter(m =>
                                    d.memberLevelIds.some(
                                      dm => String(dm) === String(m.value),
                                    ),
                                  )
                                  .map(m => {
                                    return (
                                      <div key={JSON.stringify(m)}>
                                        {m.label}
                                      </div>
                                    );
                                  })
                              : '--'}
                          </td>
                          <td align="center">
                            {d.activityType === CONSTANT.BIRTH && (
                              <>
                                <div>
                                  {d.rangeStartTime
                                    ? moment(d.rangeStartTime).format('YYYY/MM')
                                    : '--'}
                                </div>
                                <div>{d.rangeEndTime ? '至' : ''}</div>
                                <div>
                                  {d.rangeEndTime
                                    ? moment(d.rangeEndTime).format('YYYY/MM')
                                    : ''}
                                </div>
                              </>
                            )}
                          </td>
                          <td align="center">
                            {d.couponSettings
                              ? d.couponSettings.map(c => {
                                  return (
                                    <div
                                      key={JSON.stringify(c)}
                                      className="text-nowrap"
                                    >
                                      折價券
                                      {d.discountType === 1 &&
                                        `NT$${Number(
                                          c.discountAmount,
                                        ).toLocaleString()}`}
                                      {d.discountType === 2 &&
                                        `${Number(c.discountAmount)}折`}
                                    </div>
                                  );
                                })
                              : '--'}
                          </td>
                          <td align="center">
                            {d.discountType === 1 && '折現'}
                            {d.discountType === 2 && '打折'}
                          </td>
                          <td align="center">
                            {d.couponSettings
                              ? d.couponSettings.map(c => {
                                  return (
                                    <div
                                      key={`${JSON.stringify(c)}code`}
                                      className="text-nowrap"
                                    >
                                      {c.customedActivityCode}
                                    </div>
                                  );
                                })
                              : '--'}
                          </td>
                          <td align="center">
                            {/* 折抵上限 */}

                            {d.discountType === 1 && (
                              <div>
                                最高可折抵購買金額為 {d.discountMaxAmount} %
                              </div>
                            )}
                            {d.discountType === 2 && d.discountMax === 2 && (
                              <div>
                                最高可折抵購買金額的NT${' '}
                                {d.discountMaxAmount?.toLocaleString()}
                              </div>
                            )}
                          </td>
                          <td align="center">
                            <div>
                              {d.productRange === 'all' && '全店商品'}
                              {d.productRange === 'picked' && '指定商品'}
                            </div>
                            {d.productRange === 'picked' && (
                              <div>
                                共
                                {(
                                  d.groupProducts?.length + d.products?.length
                                ).toLocaleString()}
                                件
                              </div>
                            )}
                          </td>
                          <td align="center">
                            {d.productRange === 'all' && (
                              <div>
                                共
                                {(
                                  d.groupProducts?.length + d.products?.length
                                ).toLocaleString()}
                                件
                              </div>
                            )}
                          </td>
                          <td align="center">
                            {d.availableWays?.length
                              ? d.availableWays.map(aw => {
                                  return (
                                    <div key={`AW-${aw}`}>
                                      {aw === 'web' && '網頁版'}
                                      {aw === 'store' && '門市'}
                                    </div>
                                  );
                                })
                              : '--'}
                          </td>
                          <td align="center">
                            <div> {d.couponQtyEach ?? '0'}</div>
                            <div> {d.sentQty ?? '0'}</div>
                          </td>
                          <td>
                            {d.activityType === '生日禮' && (
                              <>
                                {' '}
                                {d.sendTimeType || '--'}
                                {d.sendTimeType.includes('生日當月') &&
                                  (Number(d?.sendTimeNumber) || 1)}
                                {d.sendTimeType.includes('生日當月') && '號'}
                              </>
                            )}
                            {d.activityType === '升等禮' && (
                              <>{d.sendTimeType || '--'}</>
                            )}
                            {d.activityType === '開卡禮' && (
                              <>
                                {Number(d.usableType) === 1 && ' 領取即可使用'}
                                {Number(d.usableType) === 2 &&
                                  ` 領取第${d.usableDays || 0}天可使用`}
                              </>
                            )}
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {d.rangeStartTimeString ?? '--'}起
                            </div>
                            <div className="text-nowrap">
                              {d.rangeEndTimeString ?? '--'}迄
                            </div>
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {d.redeemStartTimeString ?? '--'}起
                            </div>
                            <div className="text-nowrap">
                              {d.redeemEndTimeString ?? '--'}迄
                            </div>
                          </td>
                          <td>
                            {d.statusNameList?.length
                              ? d.statusNameList.map(s => {
                                  return (
                                    <div className="text-nowrap" key={s}>
                                      {s}
                                    </div>
                                  );
                                })
                              : '--'}
                          </td>
                          <td>
                            <div className="text-nowrap">
                              {d.updateTimeString ?? '--'}迄
                            </div>
                          </td>
                          <td>
                            {!(d?.statusList || []).includes(5) && (
                              <Button
                                className="m-2"
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => {
                                  if (d.activityType === '生日禮') {
                                    history.push(
                                      `/MemberBirthPresentSettings/${d.id}?type=edit&id=${d.id}`,
                                    );
                                  } else if (d.activityType === '開卡禮') {
                                    history.push(
                                      `/MemberOpenPresentSettings/${d.id}?type=edit&id=${d.id}`,
                                    );
                                  } else {
                                    history.push(
                                      `/MemberPromotionPresentSettings/${d.id}?type=edit&id=${d.id}`,
                                    );
                                  }
                                }}
                              >
                                編輯
                              </Button>
                            )}
                            {(d?.statusList || []).includes(5) && (
                              <Button
                                className="m-2"
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => {
                                  if (d.activityType === '生日禮') {
                                    history.push(
                                      `/MemberBirthPresentSettings/${d.id}?type=view&id=${d.id}`,
                                    );
                                  } else if (d.activityType === '開卡禮') {
                                    history.push(
                                      `/MemberOpenPresentSettings/${d.id}?type=view&id=${d.id}`,
                                    );
                                  } else {
                                    history.push(
                                      `/MemberPromotionPresentSettings/${d.id}?type=view&id=${d.id}`,
                                    );
                                  }
                                }}
                              >
                                查看
                              </Button>
                            )}

                            {!(d?.statusList || []).includes(1) && (
                              <Button
                                className="m-2"
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => {
                                  exportSingleMembershipGift(d.id)
                                    .then(res => {
                                      if (res.result) {
                                        window.open(res.result, '_blank');
                                      } else {
                                        MessageUtil.toastWarning('查無明細');
                                      }
                                    })
                                    .catch(r => {
                                      MessageUtil.toastWarning(r);
                                    });
                                }}
                              >
                                匯出明細
                              </Button>
                            )}
                            {(d?.statusList || []).includes(1) && (
                              <Button
                                className="m-2"
                                size="sm"
                                color="danger"
                                outline
                                onClick={() => {
                                  MessageUtil.submitConfirm({
                                    doneFun: () => {
                                      deleteMembershipGift(d.id)
                                        .then(() => {
                                          setFieldValue('page', 1);
                                          handleSubmit();
                                        })
                                        .catch(e => {
                                          MessageUtil.toastWarning(e);
                                        });
                                    },
                                    title: '確認是否刪除',
                                  });
                                }}
                              >
                                刪除
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          {datas && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={datas.current_page}
                defaultPageSize={datas.page_size}
                total={datas.total_count}
                onChange={page => {
                  setFieldValue('page', page);
                  handleSubmit();
                }}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </Layout>
  );
};

export default MemberPresentSettings;
