import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Table } from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import {
  exportLogRecordList,
  getLogRecordList,
} from 'redux/actions/data/logRecordActions';
import { EXPORT_LOG_RECORD_IDLE } from 'redux/constants/data/logRecordConstant';
import { LOG_RECORD_IDLE } from 'redux/constants/data/logRecordConstant';
import MessageUtil from 'utils/MessageUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getModelOptions } from '../redux/actions/data/productActions';
import style from '../styles/layout.module.css';

const LogRecordWearableProduct = () => {
  const dispatch = useDispatch();
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const { loading, success, error, data } = useSelector(
    state => state.logRecord,
  );
  const {
    loading: exportLoading,
    success: exportSuccess,
    error: errorMsg,
    result,
  } = useSelector(state => state.exportLogRecord);
  const [isTouched, setIsTouched] = useState(false);
  const deviceOptions = [
    { label: '全部', value: '' },
    { label: '桌機', value: 'desktop' },
    { label: '手機', value: 'phone' },
  ];
  const mobileOSOptions = [
    { label: '全部', value: '' },
    { label: 'IOS', value: 'IOS' },
    { label: 'Android', value: 'Android' },
  ];
  const memberTypeOptions = [
    { label: '全部', value: null },
    { label: '是', value: true },
    { label: '否', value: false },
  ];
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand: { label: '全部', value: '' }, // 取 label (brand name)
      datatype: 'VTR-P', // 商品頁瀏覽 PBR 商品頁導購外連 PBR-LP 試穿戴商品 VTR-P 試穿戴結果下載 VTR-D 試穿戴結果分享 VTR-S 試穿戴結果現場體驗 VTR-LP
      dateEnd: '',
      dateStart: '',
      device: { label: '全部', value: '' },
      landingpage: '',
      landingpagetype: '',
      membertype: { label: '全部', value: null },
      model: { label: '全部', value: '' },
      order: '',
      order_by: '',
      page: 1,
      page_size: 10,
      phoneSystem: { label: '全部', value: '' },
    },
    async onSubmit(formModel) {
      const params = {
        ...formModel,
        brand: formModel.brand.label === '全部' ? '' : formModel.brand.label,
        dateEnd: formModel.dateEnd ?? moment().toDate(),
        dateStart: formModel.dateStart ?? moment().subtract(7, 'day').toDate(),
        device: formModel.device.value,
        membertype: formModel.membertype.value,
        model: formModel.model.value,
        phoneSystem: formModel.phoneSystem.value,
      };
      dispatch(getLogRecordList(params));
      console.log(params);
    },
  });

  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    dispatch(getBrandOptions());
    dispatch(
      getModelOptions(values.brand.label === '全部' ? '' : values.brand.label),
    );
  }, [values.brand]);

  useEffect(() => {
    if (exportLoading) {
      return;
    }
    exportSuccess && MessageUtil.alertSuccess('匯出成功');
    exportSuccess && result && window.open(result);
    errorMsg && MessageUtil.alertWanring('匯出失敗', errorMsg);
    dispatch({ type: EXPORT_LOG_RECORD_IDLE });
    setIsTouched(false);
  }, [exportLoading, exportSuccess, result, errorMsg]);

  useEffect(() => {
    success && dispatch({ type: LOG_RECORD_IDLE });
  }, [success]);

  useEffect(() => {
    exportSuccess && dispatch({ type: EXPORT_LOG_RECORD_IDLE });
  }, [exportSuccess]);

  const handleExport = () => {
    const condition = {
      brand: values.brand.label === '全部' ? '' : values.brand.label,
      datatype: values.datatype,
      dateEnd: values.dateEnd,
      dateStart: values.dateStart,
      device: values.device.value,
      landingpage: values.landingpage,
      landingpagetype: values.landingpagetype,
      membertype: values.membertype.value,
      model: values.model.label === '全部' ? '' : values.model.label,
      order: values.order,
      order_by: values['order_by'],
      page: values.page,
      page_size: 10,
      phoneSystem: values.phoneSystem.value,
    };
    dispatch(exportLogRecordList(condition));
    setIsTouched(true);
  };

  const handlePageChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const isSticky = data?.datas?.length > 0 ? { height: '80vh' } : {};
  return (
    <Layout
      pageTitle="試穿戴商品"
      items={[
        { isActive: false, page_name: 'log記錄' },
        { isActive: true, page_name: '試穿戴商品' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="brand">
                  品牌
                </label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  name="brand"
                  className={style['form-select-medium']}
                  options={
                    brandOptions && [
                      { label: '全部', value: '' },
                      ...brandOptions,
                    ]
                  }
                  onChange={option => {
                    setFieldValue('brand', option);
                    setFieldValue('model', { label: '全部', value: '' });
                  }}
                  value={values.brand}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="model">
                  型號
                </label>
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  name="model"
                  className={style['form-select']}
                  options={
                    modelOptions && [
                      { label: '全部', value: '' },
                      ...modelOptions,
                    ]
                  }
                  onChange={option => setFieldValue('model', option)}
                  value={values.model}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="date">
                  日期區間
                </label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.dateStart ?? moment().subtract(7, 'day').toDate()
                  }
                  name="dateStart"
                  onChange={date => {
                    setFieldValue('dateStart', date);
                  }}
                  selectsStart
                />
                <span style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                  ~
                </span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={values.dateEnd ?? new Date()}
                  name="dateEnd"
                  onChange={date => {
                    setFieldValue('dateEnd', date);
                  }}
                  selectsEnd
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="device">
                  使用載具
                </label>
                <Select
                  name="device"
                  className={style['form-select-medium']}
                  options={deviceOptions}
                  onChange={option => {
                    setFieldValue('device', option);
                    if (option.value === 'phone') {
                      return;
                    }
                    setFieldValue('phoneSystem', { label: '全部', value: '' });
                  }}
                  value={values.device}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="phoneSystem">
                  手機系統
                </label>
                <Select
                  name="phoneSystem"
                  className={style['form-select-short']}
                  options={mobileOSOptions}
                  onChange={option => setFieldValue('phoneSystem', option)}
                  value={values.phoneSystem}
                  isDisabled={values.device.value === 'desktop'}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="membertype">
                  是否會員
                </label>
                <Select
                  name="membertype"
                  className={style['form-select-short']}
                  options={memberTypeOptions}
                  onChange={option => setFieldValue('membertype', option)}
                  value={values.membertype}
                />
              </div>
            </div>

            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={resetForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card className={style.card}>
        <Button
          className="btn btn-success w100"
          onClick={handleExport}
          disabled={isTouched}
        >
          匯出Excel
        </Button>
        <div className="d-flex justify-content-end">
          共{' '}
          <span className="mx-1">
            {data['total_count'] ? data['total_count'] : 0}
          </span>
          筆
        </div>
        <div className="table-responsive" style={isSticky}>
          <Table hover bordered striped className="mb-5">
            <thead
              className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
              <tr>
                <th>ID</th>
                <th>
                  <div>是否為會員</div>
                  <div>會員等級</div>
                  <div>會員ID/CookieID</div>
                </th>
                <th>
                  <div>姓名</div>
                  <div>電話</div>
                  <div>性別</div>
                  <div>年齡</div>
                </th>
                <th>
                  <div>偵測性別</div>
                  <div>偵測年齡</div>
                </th>
                <th>
                  <div>區域</div>
                  <div>地址</div>
                  <div>EMAIL</div>
                </th>
                <th>
                  <div>品牌</div>
                  <div>商品名稱/顏色/尺寸</div>
                  <div>價格</div>
                </th>
                <th>
                  <div>使用載具</div>
                  <div>手機系統</div>
                </th>
                <th>紀錄時間</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {data && data.datas ? (
                  data.datas.map((data, index) => (
                    <tr key={index}>
                      <td nowrap="nowrap">{data.id}</td>
                      <td>
                        <div>{data.membertype ? '是' : '否'}</div>
                        <div>{data.membershiplevel || '--'}</div>
                        <div>{data.memberid || '--'}</div>
                      </td>
                      <td>
                        <div>{data.name || '--'}</div>
                        <div>{data.phone || '--'}</div>
                        <div>
                          {' '}
                          {data.gender
                            ? data.gender === 1
                              ? '男'
                              : '女'
                            : '不透漏'}
                        </div>
                        <div>{data.age || '--'}</div>
                      </td>
                      <td>
                        <div>{data.vtr_gender || '--'}</div>
                        <div>{data.vtr_age || '--'}</div>
                      </td>
                      <td>
                        <div>
                          {data.area
                            ? data.area === 'N'
                              ? '北部'
                              : data.area === 'S'
                              ? '南部'
                              : data.area === 'C'
                              ? '中部'
                              : data.area === 'E'
                              ? '東部'
                              : '西部'
                            : '--'}
                        </div>
                        <div>{data.address || '--'}</div>
                        <div>{data.email || '--'}</div>
                      </td>
                      <td>
                        <div>{data.brand || '--'}</div>
                        <div>
                          {`${data.productname || '--'}/${data.color || '--'}/${
                            data.size || '--'
                          }`}
                        </div>
                        <div className="price text-black fw-normal">
                          {data.websiteprice?.toLocaleString('en-US') || 0}
                        </div>
                      </td>
                      <td>
                        <div>{data.device === 'phone' ? '手機' : '桌機'}</div>
                        <div>{data.phonesystem || '--'}</div>
                      </td>
                      <td>
                        {data.createTime
                          ? moment(data.createTime)
                              .format('YYYY/MM/DD HH:mm:ss')
                              .split(' ')
                              .map(s => {
                                return <div key={s}>{s}</div>;
                              })
                          : '--'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && data && data.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={data.current_page}
              defaultPageSize={10}
              total={data.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
    </Layout>
  );
};

export default LogRecordWearableProduct;
