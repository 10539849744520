export const SERVICE_LIST_REQUEST = 'SERVICE_LIST_REQUEST';
export const SERVICE_LIST_SUCCESS = 'SERVICE_LIST_SUCCESS';
export const SERVICE_LIST_FAIL = 'SERVICE_LIST_FAIL';

export const SERVICE_TITLE_POST_REQUEST = 'SERVICE_TITLE_POST_REQUEST';
export const SERVICE_TITLE_POST_SUCCESS = 'SERVICE_TITLE_POST_SUCCESS';
export const SERVICE_TITLE_POST_FAIL = 'SERVICE_TITLE_POST_FAIL';
export const SERVICE_TITLE_POST_RESET = 'SERVICE_TITLE_POST_RESET';

export const SERVICE_CONTENT_POST_REQUEST = 'SERVICE_CONTENT_POST_REQUEST';
export const SERVICE_CONTENT_POST_SUCCESS = 'SERVICE_CONTENT_POST_SUCCESS';
export const SERVICE_CONTENT_POST_FAIL = 'SERVICE_CONTENT_POST_FAIL';
export const SERVICE_CONTENT_POST_RESET = 'SERVICE_CONTENT_POST_RESET';

export const SERVICE_CONTENT_DELETE_REQUEST = 'SERVICE_CONTENT_DELETE_REQUEST';
export const SERVICE_CONTENT_DELETE_SUCCESS = 'SERVICE_CONTENT_DELETE_SUCCESS';
export const SERVICE_CONTENT_DELETE_FAIL = 'SERVICE_CONTENT_DELETE_FAIL';
export const SERVICE_CONTENT_DELETE_RESET = 'SERVICE_CONTENT_DELETE_RESET';

export const WARRANTY_LIST_REQUEST = 'WARRANTY_LIST_REQUEST';
export const WARRANTY_LIST_SUCCESS = 'WARRANTY_LIST_SUCCESS';
export const WARRANTY_LIST_FAIL = 'WARRANTY_LIST_FAIL';

export const WARRANTY_POST_REQUEST = 'WARRANTY_POST_REQUEST';
export const WARRANTY_POST_SUCCESS = 'WARRANTY_POST_SUCCESS';
export const WARRANTY_POST_FAIL = 'WARRANTY_POST_FAIL';
export const WARRANTY_POST_RESET = 'WARRANTY_POST_RESET';

export const WARRANTY_DELETE_REQUEST = 'WARRANTY_DELETE_REQUEST';
export const WARRANTY_DELETE_SUCCESS = 'WARRANTY_DELETE_SUCCESS';
export const WARRANTY_DELETE_FAIL = 'WARRANTY_DELETE_FAIL';
export const WARRANTY_DELETE_RESET = 'WARRANTY_DELETE_RESET';

export const REPAIR_LIST_REQUEST = 'REPAIR_LIST_REQUEST';
export const REPAIR_LIST_SUCCESS = 'REPAIR_LIST_SUCCESS';
export const REPAIR_LIST_FAIL = 'REPAIR_LIST_FAIL';

export const REPAIR_POST_REQUEST = 'REPAIR_POST_REQUEST';
export const REPAIR_POST_SUCCESS = 'REPAIR_POST_SUCCESS';
export const REPAIR_POST_FAIL = 'REPAIR_POST_FAIL';
export const REPAIR_POST_RESET = 'REPAIR_POST_RESET';

export const REPAIR_DELETE_REQUEST = 'REPAIR_DELETE_REQUEST';
export const REPAIR_DELETE_SUCCESS = 'REPAIR_DELETE_SUCCESS';
export const REPAIR_DELETE_FAIL = 'REPAIR_DELETE_FAIL';
export const REPAIR_DELETE_RESET = 'REPAIR_DELETE_RESET';

export const INSTRUCTION_LIST_REQUEST = 'INSTRUCTION_LIST_REQUEST';
export const INSTRUCTION_LIST_SUCCESS = 'INSTRUCTION_LIST_SUCCESS';
export const INSTRUCTION_LIST_FAIL = 'INSTRUCTION_LIST_FAIL';

export const INSTRUCTION_POST_REQUEST = 'INSTRUCTION_POST_REQUEST';
export const INSTRUCTION_POST_SUCCESS = 'INSTRUCTION_POST_SUCCESS';
export const INSTRUCTION_POST_FAIL = 'INSTRUCTION_POST_FAIL';
export const INSTRUCTION_POST_RESET = 'INSTRUCTION_POST_RESET';

export const INSTRUCTION_DELETE_REQUEST = 'INSTRUCTION_DELETE_REQUEST';
export const INSTRUCTION_DELETE_SUCCESS = 'INSTRUCTION_DELETE_SUCCESS';
export const INSTRUCTION_DELETE_FAIL = 'INSTRUCTION_DELETE_FAIL';
export const INSTRUCTION_DELETE_RESET = 'INSTRUCTION_DELETE_RESET';

export const INSTRUCTION_FAQ_LIST_REQUEST = 'INSTRUCTION_FAQ_LIST_REQUEST';
export const INSTRUCTION_FAQ_LIST_SUCCESS = 'INSTRUCTION_FAQ_LIST_SUCCESS';
export const INSTRUCTION_FAQ_LIST_FAIL = 'INSTRUCTION_FAQ_LIST_FAIL';

export const INSTRUCTION_FAQ_POST_REQUEST = 'INSTRUCTION_FAQ_POST_REQUEST';
export const INSTRUCTION_FAQ_POST_SUCCESS = 'INSTRUCTION_FAQ_POST_SUCCESS';
export const INSTRUCTION_FAQ_POST_FAIL = 'INSTRUCTION_FAQ_POST_FAIL';
export const INSTRUCTION_FAQ_POST_RESET = 'INSTRUCTION_FAQ_POST_RESET';

export const INSTRUCTION_FAQ_DELETE_REQUEST = 'INSTRUCTION_FAQ_DELETE_REQUEST';
export const INSTRUCTION_FAQ_DELETE_SUCCESS = 'INSTRUCTION_FAQ_DELETE_SUCCESS';
export const INSTRUCTION_FAQ_DELETE_FAIL = 'INSTRUCTION_FAQ_DELETE_FAIL';
export const INSTRUCTION_FAQ_DELETE_RESET = 'INSTRUCTION_FAQ_DELETE_RESET';

export const UPDATE_SERVICE_REMARK_IDLE = 'UPDATE_SERVICE_REMARK_IDLE';
export const UPDATE_SERVICE_REMARK_REQUEST = 'UPDATE_SERVICE_REMARK_REQUEST';
export const UPDATE_SERVICE_REMARK_SUCCESS = 'UPDATE_SERVICE_REMARK_SUCCESS';
export const UPDATE_SERVICE_REMARK_FAIL = 'UPDATE_SERVICE_REMARK_FAIL';
