import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Tooltip,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import request from 'utils/useAxios';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  getWarrantyBrandList,
  updateWarrantyBrand,
} from '../redux/actions/data/warrantyActions';
import { WARRANTY_BRAND_SET_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const TooltipItem = ({ target }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen}
      target={target}
      toggle={toggleTooltip}
    >
      購買7日內，會員資料有填寫完整， 可再延長保固
    </Tooltip>
  );
};
const WarrantyExpiration = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const page_size = 10;

  const [current_page, setCurrentPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [itemId, setItemId] = useState(0);
  const [brand, setBrand] = useState({ label: '請選擇品牌', value: 0 });
  const [modal, setModal] = useState(false);

  const { userInfo } = useSelector(state => state.userLogin);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { loading, warrantyBrandListInfo, error } = useSelector(
    state => state.warrantyBrandList,
  );
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.warrantyBrandSet);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({ pathname: '/Login', state: '/WarrantyNum' });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({ pathname: '/Login', state: '/WarrantyNum' });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  useEffect(() => {
    if (location.search) {
      const queryPage = query.value.page;
      const queryBrand = query.value.brand;

      setCurrentPage(queryPage);

      if (queryBrand) {
        setBrand({ ...brand, value: queryBrand });

        //儲存搜尋及頁數
        dispatch(
          getWarrantyBrandList({
            brandId: brand.value,
            page: queryPage,
            page_size,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getWarrantyBrandList({
            page: queryPage,
            page_size,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setBrand({ label: '請選擇品牌', value: 0 });

      //初始搜尋
      dispatch(getBrandOptions());
      dispatch(
        getWarrantyBrandList({
          page: 1,
          page_size,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (updateSuccess) {
      setModal(false);
      MessageUtil.toastSuccess('新增成功!!');
      dispatch(
        getWarrantyBrandList({
          page: current_page,
        }),
      );
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
    }

    return () => {
      dispatch({ type: WARRANTY_BRAND_SET_RESET });
    };
  }, [updateSuccess, updateError]);

  const handleOpenEditModal = data => {
    setModal(!modal);
    setEditData(data);
    setItemId(data.brandId);
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);

    dispatch(
      getWarrantyBrandList({
        brandId: brand.value,
        page: 1,
        page_size,
      }),
    );

    history.push(`${location.pathname}?brand=${brand.value}&page=${1}`);
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setBrand({ label: '請選擇品牌', value: 0 });
    dispatch(
      getWarrantyBrandList({
        page: 1,
        page_size,
      }),
    );
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);

    dispatch(
      getWarrantyBrandList({
        brandId: brand.value,
        page,
        page_size,
      }),
    );
    if (brand.value) {
      history.push(`${location.pathname}?brand=${brand.value}&page=${page}`);
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const [warrantyDaysOptions, setWarrantyDaysOptions] = useState([]);
  const [extendDaysOptions, setExtendDaysOptions] = useState([]);

  const handleGetWarrantyDaysOptions = async () => {
    const result = await request({
      method: 'get',
      url: '/Backend/BrandWarrantyDateSetting/WarrantyDaysOptions',
    });
    setWarrantyDaysOptions(result);
  };
  const handleGetExtendDaysOptions = async () => {
    const result = await request({
      method: 'get',
      url: '/Backend/BrandWarrantyDateSetting/ExtendDaysOptions',
    });
    setExtendDaysOptions(result);
  };
  useEffect(() => {
    handleGetWarrantyDaysOptions();
    handleGetExtendDaysOptions();
  }, []);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      expiration: Yup.string().required('必填!'),
      extention: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = values => {
    const object = {
      brandId: itemId,
      extendDays: values.extention,
      warrantyDays: values.expiration,
    };
    dispatch(updateWarrantyBrand(object));
  };

  useEffect(() => {
    if (warrantyBrandListInfo) {
      if (JSON.stringify(warrantyBrandListInfo) !== '{}') {
        CommonUtil.consoleLog({
          anno: 'response',
          data: warrantyBrandListInfo,
        });
      }
    }
  }, [warrantyBrandListInfo]);

  return (
    <Layout
      pageTitle="品牌保固期限設定"
      items={[
        { isActive: false, page_name: '產品保固註冊' },
        { isActive: true, page_name: '品牌保固期限設定' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className="d-flex flex-wrap">
              <div className={style.formGroup}>
                <label className="col-form-label mr-2" htmlFor="keyword">
                  品牌
                </label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  name="brandName"
                  className={style['form-select']}
                  options={brandOptions}
                  onChange={setBrand}
                  value={brand}
                  style={{ margin: 0 }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading &&
            warrantyBrandListInfo &&
            warrantyBrandListInfo.total_count > 0
              ? warrantyBrandListInfo.total_count
              : 0}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>品牌</th>
                <th>保固期限</th>
                <th className="position-relative">
                  延長保固 <AiOutlineInfoCircle id="TooltipWarrant" />
                  <TooltipItem target="TooltipWarrant" />
                </th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {warrantyBrandListInfo &&
                warrantyBrandListInfo.datas?.length > 0 ? (
                  warrantyBrandListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td>{data.brandName}</td>
                      <td>{data.warrantyDaysSt}</td>
                      <td>{data.extendDaysSt}</td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          期限設定
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          warrantyBrandListInfo &&
          warrantyBrandListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={warrantyBrandListInfo.current_page}
                defaultPageSize={10}
                total={warrantyBrandListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={handleOpenEditModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={handleOpenEditModal}>
          品牌保固序號編碼設定
        </ModalHeader>

        <Formik
          enableReinitialize
          initialValues={{
            expiration: editData?.warrantyDays?.toString(),
            extention: editData?.extendDays?.toString(),
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={`${style.formGroup} align-items-start`}>
                  <label className="required" style={{ width: '130px' }}>
                    保固期限
                    <ErrorMessage
                      name="expiration"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      {warrantyDaysOptions?.map((i, index) => (
                        <label className="me-4">
                          <Field
                            className="my-auto me-1"
                            name="expiration"
                            type="radio"
                            value={i.value}
                          />

                          {i.label}
                          {index > 0 && `(${i.value})`}
                        </label>
                      ))}
                    </div>
                    <p className={`${style.fs_14} text-muted mt-2`}>
                      設定【無】，前台維修單申請，將不會出現此品牌
                    </p>
                  </div>
                </div>
                <div
                  className={`${style.formGroup} align-items-start`}
                  style={{ marginBottom: '20vh' }}
                >
                  <label className="required" style={{ width: '130px' }}>
                    延長日期
                    <ErrorMessage
                      name="extention"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex flex-wrap gap-2">
                      {extendDaysOptions?.map((i, index) => (
                        <label className="me-4">
                          <Field
                            className="my-auto me-1"
                            name="extention"
                            type="radio"
                            value={i.value}
                          />

                          {i.label}
                          {index > 0 && `(${i.value})`}
                        </label>
                      ))}
                    </div>
                    <p className={`${style.fs_14} text-muted mt-2`}>
                      購買7日內，會員資料有填寫完整，可再延長保固
                    </p>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={handleOpenEditModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={updateLoading}
                >
                  {updateLoading && (
                    <span
                      className="me-1 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
};

export default WarrantyExpiration;
