import {
  CREATE_LOGISTICS_SHIPPING_REQUEST,
  CREATE_LOGISTICS_SHIPPING_SUCCESS,
  CREATE_LOGISTICS_SHIPPING_FAIL,
  CREATE_LOGISTICS_SHIPPING_RESET,
  CREATE_LOGISTICS_PRINT_TICKET_REQUEST,
  CREATE_LOGISTICS_PRINT_TICKET_SUCCESS,
  CREATE_LOGISTICS_PRINT_TICKET_FAIL,
  CREATE_LOGISTICS_PRINT_TICKET_RESET,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL,
  CREATE_LOGISTICS_CANCEL_LOGISTIC_RESET,
  CREATE_LOGISTICS_CANCEL_ORDER_REQUEST,
  CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS,
  CREATE_LOGISTICS_CANCEL_ORDER_FAIL,
  CREATE_LOGISTICS_CANCEL_ORDER_RESET,
} from 'redux/constants/data/createLogisticsConstants';

export const createLogisticsShippingReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LOGISTICS_SHIPPING_REQUEST:
      return { loading: true };
    case CREATE_LOGISTICS_SHIPPING_SUCCESS:
      return { loading: false, success: true };
    case CREATE_LOGISTICS_SHIPPING_FAIL:
      return { error: payload, loading: false };
    case CREATE_LOGISTICS_SHIPPING_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const createLogisticsPrintTicketReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LOGISTICS_PRINT_TICKET_REQUEST:
      return { loading: true };
    case CREATE_LOGISTICS_PRINT_TICKET_SUCCESS:
      return { loading: false, success: true };
    case CREATE_LOGISTICS_PRINT_TICKET_FAIL:
      return { error: payload, loading: false };
    case CREATE_LOGISTICS_PRINT_TICKET_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const createLogisticsCancelLogisticReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST:
      return { loading: true };
    case CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS:
      return { loading: false, success: true };
    case CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL:
      return { error: payload, loading: false };
    case CREATE_LOGISTICS_CANCEL_LOGISTIC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const createLogisticsCancelOrderReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LOGISTICS_CANCEL_ORDER_REQUEST:
      return { loading: true };
    case CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS:
      return { loading: false, success: true };
    case CREATE_LOGISTICS_CANCEL_ORDER_FAIL:
      return { error: payload, loading: false };
    case CREATE_LOGISTICS_CANCEL_ORDER_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
