import Layout from 'components/Layout';
import ShowOriginImageModal from 'components/ShowOriginImageModal';
import MessageTable from 'components/messageList/MessageTable';
import RecordsModal from 'components/messageList/RecordsModal';
import SearchCard from 'components/messageList/SearchCard';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import {
  getMessageCategoryOptions,
  getMessageList,
  getMessageSubCategoryOptions,
  getUnreadMessageCount,
  setMessageClosed,
} from 'redux/actions/data/messageActions';
import { SET_MESSAGE_CLOSED_IDLE } from 'redux/constants/data/MessageConstant';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import style from '../styles/layout.module.css';

const MessageList = () => {
  const dispatch = useDispatch();
  const [isRecordsModalOpen, setIsRecordsModalOpen] = useToggle();
  const [currentId, setCurrentId] = useState(null);
  const { userInfo } = useSelector(state => state.userLogin);
  const { options: catOptions } = useSelector(
    state => state.getMessageCategoryOptions,
  );
  const { options: subCatLOptions } = useSelector(
    state => state.getMessageSubCategoryOptions,
  );
  const {
    loading: closeLoading,
    success: closeSuccess,
    error: closeError,
  } = useSelector(state => state.setMessageClosed);
  const { list } = useSelector(state => state.getMessageList);
  const [permissions, setPermissions] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [ids, setIds] = useState([]);
  const [isOriginImageModalOpen, setIsOriginImageModalOpen] = useToggle();
  const [currentImgSrc, setCurrentImgSrc] = useState('');
  const query = useQuery();
  const { location } = history;

  const { loading: messageCloseLoading } = useSelector(
    state => state.setMessageClosed,
  );

  useEffect(() => {
    dispatch(getUnreadMessageCount());
  }, [messageCloseLoading]);

  const {
    values,
    handleSubmit,
    handleChange,
    handleReset,
    setFieldValue,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      adminId: 0,
      cat1: { label: '全部', value: 0 },
      cat1Id: 0,
      cat2: { label: '全部', value: 0 },
      cat2Id: 0,
      catType: '',
      dtEnd: null,
      dtStart: null,
      keyword: '',
      messageStatus: ['待處理', '處理中'],
      page: 1,
      page_size: 10,
      serialNum: '',
      serialNumType: '',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      setIds([]);
      const body = {
        ...values,
        cat1Id: values.cat1?.value || 0,
        cat2Id: values.cat2?.value || 0,
        dtEnd: values.dtEnd
          ? moment(values.dtEnd).format('YYYY/MM/DD HH:mm:ss')
          : null,
        dtStart: values.dtStart
          ? moment(values.dtStart).format('YYYY/MM/DD HH:mm:ss')
          : null,
      };

      delete body.cat1;
      delete body.cat2;

      if (CommonUtil.isValueChanged(currentData, body, setCurrentData)) {
        const params = new URLSearchParams({
          ...body,
          dtEnd: JSON.stringify(body.dtEnd),
          dtStart: JSON.stringify(body.dtStart),
          messageStatus: JSON.stringify(body.messageStatus),
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      } else {
        dispatch(getMessageList(body));
      }
    },
  });

  useEffect(() => {
    const messagePermissions = userInfo.permissions.filter(
      permission => permission.id === 39 || permission.id === 40,
    );
    setPermissions(messagePermissions);
  }, [userInfo]);

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        adminId: query.value.adminId,
        catType:
          query.value.catType === 'null'
            ? JSON.parse(query.value.catType)
            : query.value.catType || null,
        dtEnd: query.value.dtEnd ? JSON.parse(query.value.dtEnd) : null,
        dtStart: query.value.dtStart ? JSON.parse(query.value.dtStart) : null,
        keyword: query.value.keyword ? query.value.keyword.toString() : '',
        messageStatus: query.value.messageStatus
          ? JSON.parse(query.value.messageStatus)
          : [],
        serialNum: query.value.serialNum
          ? query.value.serialNum.toString()
          : '',
      };
      const value = {
        ...body,
        cat1: catOptions?.find(
          option => option.value == query.value.cat1Id,
        ) || { label: '全部', value: 0 },
        cat2: subCatLOptions?.find(
          option => option.value == query.value.cat2Id,
        ) || { label: '全部', value: 0 },
      };

      delete body.cat1;
      delete body.cat2;

      setValues(value);
      dispatch(getMessageList(body));
    } else {
      handleReset();
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getMessageCategoryOptions());
  }, []);

  useEffect(() => {
    setFieldValue(
      'cat1',
      catOptions?.find(option => option.value == query.value.cat1Id) || {
        label: '全部',
        value: 0,
      },
    );
  }, [catOptions]);

  useEffect(() => {
    setFieldValue(
      'cat2',
      subCatLOptions?.find(option => option.value == query.value.cat2Id) || {
        label: '全部',
        value: 0,
      },
    );
  }, [subCatLOptions]);

  useEffect(() => {
    if (JSON.stringify(list) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: list });
    }
  }, [list]);

  useEffect(() => {
    dispatch(getMessageSubCategoryOptions(values.cat1.value));
  }, [values.cat1.value]);

  useEffect(() => {
    if (closeSuccess) {
      MessageUtil.toastSuccess('更新成功');
      dispatch({ type: SET_MESSAGE_CLOSED_IDLE });
      const body = {
        ...values,
        cat1Id: values.cat1?.value || 0,
        cat2Id: values.cat2?.value || 0,
        dtEnd: values.dtEnd
          ? moment(values.dtEnd).format('YYYY/MM/DD HH:mm:ss')
          : null,
        dtStart: values.dtStart
          ? moment(values.dtStart).format('YYYY/MM/DD HH:mm:ss')
          : null,
      };

      delete body.cat1;
      delete body.cat2;
      dispatch(getMessageList(body));
      setIds([]);
    }

    if (closeError) {
      MessageUtil.alertWanring('更新失敗', closeError);
      dispatch({ type: SET_MESSAGE_CLOSED_IDLE });
    }
  }, [closeSuccess, closeError]);

  const handlePageChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const setMessageClosedHandler = () => {
    dispatch(setMessageClosed(ids));
  };

  return (
    <Layout
      pageTitle={'訊息紀錄'}
      items={[
        { isActive: true, page_name: '訊息管理' },
        { isActive: false, page_name: '訊息紀錄' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <SearchCard
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            values={values}
            permissions={permissions}
          />
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button className={style.modal_button_cancel} onClick={handleReset}>
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            onClick={() => {
              setFieldValue('page', 1);
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <div className={`${style.show_flex}`}>
          <Button
            color="success"
            className="mb-3"
            onClick={setMessageClosedHandler}
            disabled={ids?.length === 0 || closeLoading}
          >
            批次結案
          </Button>
          <div style={{ margin: 'auto' }} />
          {permissions.find(permission => permission.id === 40) && (
            <Button
              color="warning"
              className="mb-3"
              style={{ color: 'white' }}
              onClick={() => {
                history.push('/MessageCategory?type=sales');
              }}
            >
              業務分類選單管理
            </Button>
          )}
          {permissions.find(permission => permission.id === 39) && (
            <Button
              className="ml-2 mb-3"
              color="warning"
              style={{ color: 'white' }}
              onClick={() => {
                history.push('/MessageCategory?type=services');
              }}
            >
              客服分類選單管理
            </Button>
          )}
        </div>
        <MessageTable
          onToggleRecordsModal={setIsRecordsModalOpen}
          onSetCurrentId={setCurrentId}
          onSetIds={setIds}
          onReload={handleSubmit}
          ids={ids}
        />
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={list?.current_page}
            defaultPageSize={list?.page_size}
            style={{ borderColor: '#446' }}
            total={list?.total_count || 0}
            onChange={handlePageChange}
          />
        </div>
      </Card>
      {isRecordsModalOpen && (
        <RecordsModal
          isOpen={isRecordsModalOpen}
          toggle={setIsRecordsModalOpen}
          id={currentId}
          setCurrentImgSrc={setCurrentImgSrc}
          setIsOriginImageModalOpen={setIsOriginImageModalOpen}
        />
      )}
      {isOriginImageModalOpen && (
        <ShowOriginImageModal
          toggle={setIsOriginImageModalOpen}
          isOpen={isOriginImageModalOpen}
          source={currentImgSrc}
        />
      )}
    </Layout>
  );
};

export default MessageList;
