import {
  ORDER_RETURN_REASON_GET_SELECTION_SUCCESS,
  ORDER_RETURN_REASON_LIST_REQUEST,
  ORDER_RETURN_REASON_LIST_SUCCESS,
  ORDER_RETURN_REASON_LIST_FAIL,
} from 'redux/constants/data/orderReturnReasonConstants';
import { useRequest } from 'utils/useFetch';

export const orderReturnReasonselections = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      method: 'get',
      successConstant: ORDER_RETURN_REASON_GET_SELECTION_SUCCESS,
      url: '/Backend/OrderReturnReason/GetSelection?type=' + '退貨/換貨',
    });
  };
};
export const orderReturnReasonList = (data, type) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_REASON_LIST_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_REASON_LIST_REQUEST,
      successConstant: ORDER_RETURN_REASON_LIST_SUCCESS,
      url: `/Backend/OrderReturnReason/list?type=${type}`,
    });
  };
};
