import React, { memo } from 'react';

import style from '../../styles/layout.module.css';

export const OthersSettings = ({ values, setFieldValue, errors, touched }) => {
  return (
    <div>
      <div className={style.formGroup}>
        <label className="w150 required">是否可使用折價券</label>
        <div>
          <small>
            <li>
              若活動商品已設定為「不適用任何折價券商品」則此商品亦無法使用折價券
            </li>
          </small>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          >
            <input
              type="radio"
              checked={values.couponEnabled}
              style={{ height: '20px', width: '20px' }}
              onChange={() => {
                setFieldValue('couponEnabled', true);
              }}
              disabled={values.status === 1}
            />
            <span className="ml-2">是(此檔活動結帳可使用折價券)</span>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
              marginBottom: '1rem',
              marginTop: '1rem',
            }}
          >
            <input
              type="radio"
              checked={!values.couponEnabled}
              style={{ height: '20px', width: '20px' }}
              onChange={() => {
                setFieldValue('couponEnabled', false);
              }}
              disabled={values.status === 1}
            />
            <span className="ml-2">否(不可使用折價券)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(OthersSettings);
