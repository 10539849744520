import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, FormGroup, Input } from 'reactstrap';

import { getMemberLevelOptions } from '../../redux/actions/data/memberActions';
import style from '../../styles/layout.module.css';
import numberToChinese from '../../utils/numberToChinese';
import Loader from '../Loader';
import UploadFileBox from '../UploadFileBox';
import ProductRange from '../voucherSettings/discount/ProductRange';

export const initValues = {
  activityCode: '',
  activityType: '生日禮',
  availableWay: '',
  couponImg: '',
  couponImgFiles: [],
  couponQtyEach: 0,
  couponSettings: [],
  couponType: 1,
  couponWording: '',
  couponWording_en: '',
  customedActivityCode: '',
  desc: '',
  desc_en: '',
  discountMax: 1,
  discountMaxAmount: 0,
  discountType: 1,
  dueType: 1,
  groupProducts: [],
  id: 0,
  isGetTime: true,
  isUsedwithDiscount: true,
  memberLevelId: '[]',
  name: '',
  name_en: '',
  productRange: 'all',
  products: [],
  rangeEndTime: moment().add(30, 'minutes').format('YYYY/MM/DD HH:mm:ss'),
  rangeStartTime: moment().format('YYYY/MM/DD HH:mm:ss'),
  redeemEndTime: moment().add(30, 'minutes').format('YYYY/MM/DD HH:mm:ss'),
  redeemStartTime: moment().format('YYYY/MM/DD HH:mm:ss'),
  remiderNum: 0,
  sendTimeNumber: 0,
  sendTimeType: '',
  storeRedeemShowWay: 'qrcode',
  usableDays: 0,
  usableNdaysLater: 0,
  usableType: 1,
  useMin: 1,
  useMinAmount: 0
};

export const CONSTANT = {
  BIRTH: '生日禮',
  OPEN: '開卡禮',
  PROMOTION: '升等禮',
};

const lableStyle = {
  fontWeight: '400',
  minWidth: '100px',
  padding: '0px',
  paddingTop: '5px',
};

/**
 * @Params
 * type: string
 * 升等禮 生日禮 開卡禮
 *
 */
const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '150px',
};

const PresentSettings = ({
  type = '',
  disabled = false,
  goBack = () => {},
  getValidationSchema = () => {},
  handleSubmit = () => {},
  initialValues = initValues,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.getMembershipGift);
  const { memberLevelOptions } = useSelector(
    state => state.getMemberLevelOptions,
  );
  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Card className={style.card}>
      <Formik
        enableReinitialize={false}
        validateOnMount
        initialValues={initialValues}
        validationSchema={disabled ? null : getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <h4 className="title" style={titleStyle}>
              基本設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動類型
              </label>
              <div className="form-item p-2">{type}</div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動券型
              </label>
              <div className="form-item p-2">折價券</div>
            </FormGroup>
            {type !== CONSTANT.OPEN && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  會員等級
                </label>
                <div className="form-item">
                  <div className="d-flex flex-wrap align-items-center">
                    {type === CONSTANT.PROMOTION && (
                      <span className="mx-2">升等至</span>
                    )}
                    <Select
                      isDisabled={disabled}
                      styles={{
                        control: styles => ({
                          ...styles,
                          width: '150px',
                        }),
                      }}
                      options={
                        memberLevelOptions && [
                          { label: '全部', value: '' },
                          ...memberLevelOptions,
                        ]
                      }
                      value={
                        memberLevelOptions?.find(
                          m => m.value === props.values.memberLevelId,
                        )
                          ? memberLevelOptions?.filter(
                              m => m.value === props.values.memberLevelId,
                            )[0]
                          : { label: '全部', value: '' }
                      }
                      onChange={option => {
                        props.setFieldValue(
                          'memberLevelId',
                          String(option.value),
                        );
                      }}
                    />
                  </div>
                  <div className="form-error">
                    {props.errors?.memberLevelId}
                  </div>
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                {type === CONSTANT.BIRTH && '生日年月'}
                {type === CONSTANT.OPEN && '活動期間'}
                {type === CONSTANT.PROMOTION && '升等時間'}
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center">
                  <div style={{ maxWidth: '250px' }}>
                    <DatePicker
                      className="form-control"
                      dateFormat={
                        type === CONSTANT.BIRTH ? 'yyyy/MM' : 'yyyy/MM/dd'
                      }
                      disabled={disabled}
                      showMonthYearPicker={type === CONSTANT.BIRTH}
                      selected={moment(
                        props.values.rangeStartTime || new Date(),
                      ).toDate()}
                      onChange={date => {
                        props.setFieldValue(
                          'rangeStartTime',
                          moment(date).format(
                            type === CONSTANT.BIRTH ? 'YYYY/MM' : 'YYYY/MM/DD',
                          ),
                        );
                      }}
                    />
                  </div>
                  <span className="px-2">~</span>
                  <div style={{ maxWidth: '250px' }}>
                    <DatePicker
                      className="form-control"
                      dateFormat={
                        type === CONSTANT.BIRTH ? 'yyyy/MM' : 'yyyy/MM/dd'
                      }
                      disabled={disabled}
                      showMonthYearPicker={type === CONSTANT.BIRTH}
                      selected={moment(
                        props.values.rangeEndTime || new Date(),
                      ).toDate()}
                      onChange={date => {
                        props.setFieldValue(
                          'rangeEndTime',
                          moment(date).format(
                            type === CONSTANT.BIRTH ? 'YYYY/MM' : 'YYYY/MM/DD',
                          ),
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </FormGroup>
            {type !== CONSTANT.OPEN && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  發券時間
                </label>
                {type === CONSTANT.BIRTH && (
                  <div className="form-item">
                    <ul>
                      <li className="text-secondary">
                        <small className="text-secondary">
                          假設預計發送5月份生日禮，並設定於生日「當月5號」發送，5月份生日禮將於5/5發送
                        </small>
                      </li>
                      <li className="text-secondary">
                        <small className="text-secondary">
                          假設預計發送5月份生日禮，並設定於生日當月「前3天」發送，5月份生日禮將統一於4/28發送
                        </small>
                      </li>
                    </ul>
                    <div>
                      <label
                        className="d-inline-flex mb-2 w-100 align-items-center"
                        htmlFor="radio-birth-type-1"
                      >
                        <Field
                          type="radio"
                          disabled={disabled}
                          value="生日當月"
                          name="sendTimeType"
                          id="radio-birth-type-1"
                          onChange={() => {
                            props.setFieldValue('sendTimeType', '生日當月');
                            props.setFieldValue('sendTimeNumber', 0);
                          }}
                        />
                        <span className="fw-medium px-1">生日當月</span>
                        <Input
                          size="sm"
                          type="number"
                          value={
                            props.values.sendTimeType === '生日當月'
                              ? props.values.sendTimeNumber
                              : ''
                          }
                          disabled={props.values.sendTimeType !== '生日當月'}
                          style={{ maxWidth: '100px' }}
                          onBlur={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value),
                            );
                          }}
                          onChange={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value).toString(),
                            );
                          }}
                        />
                        <span className="fw-medium px-1">號</span>
                        {props.values.sendTimeType === '生日當月' && (
                          <div className="form-error fw-normal">
                            {props.errors.sendTimeNumber}
                          </div>
                        )}
                      </label>
                      <label
                        className="d-inline-flex mb-2 w-100 align-items-center"
                        htmlFor="radio-birth-type-2"
                      >
                        <Field
                          type="radio"
                          disabled={disabled}
                          value="生日當月前"
                          name="sendTimeType"
                          id="radio-birth-type-2"
                          onChange={() => {
                            props.setFieldValue('sendTimeType', '生日當月前');
                            props.setFieldValue('sendTimeNumber', 0);
                          }}
                        />
                        <span className="fw-medium px-1">生日當月前</span>
                        <Input
                          size="sm"
                          type="number"
                          value={
                            props.values.sendTimeType === '生日當月前'
                              ? props.values.sendTimeNumber
                              : ''
                          }
                          disabled={props.values.sendTimeType !== '生日當月前'}
                          style={{ maxWidth: '100px' }}
                          onBlur={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value),
                            );
                          }}
                          onChange={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value).toString(),
                            );
                          }}
                        />
                        <span className="fw-medium px-1">天</span>
                        {props.values.sendTimeType === '生日當月前' && (
                          <div className="form-error fw-normal">
                            {props.errors.sendTimeNumber}
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                )}
                {type === CONSTANT.PROMOTION && (
                  <div className="form-item">
                    <div className="d-flex flex-wrap align-items-center">
                      <Field
                        as="select"
                        className="form-select d-inline-block"
                        style={{ maxWidth: '200px' }}
                        disabled={disabled}
                        name="sendTimeType"
                        value={props.values.sendTimeType}
                        onChange={e => {
                          props.setFieldValue('sendTimeType', e.target.value);
                          props.setFieldValue('sendTimeNumber', 0);
                        }}
                      >
                        <option value="升等當日發送">升等當日發送</option>
                        <option value="升等後隔日發送">升等後隔日發送</option>
                        <option value="升等後隔月發送">升等後隔月發送</option>
                      </Field>
                      {(props.values.sendTimeType === '升等後隔日發送' ||
                        props.values.sendTimeType === '升等後隔月發送') && (
                        <Input
                          style={{ maxWidth: '100px' }}
                          className="mx-2 form-control"
                          type="number"
                          min="0"
                          value={props.values.sendTimeNumber}
                          name="sendTimeNumber"
                          onBlur={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value),
                            );
                          }}
                          onChange={e => {
                            props.setFieldValue(
                              'sendTimeNumber',
                              Number(e.target.value).toString(),
                            );
                          }}
                        />
                      )}
                      {props.values.sendTimeType === '升等後隔日發送' && '天'}
                      {props.values.sendTimeType === '升等後隔月發送' && '號'}
                      <div className="form-error fw-normal">
                        {props.errors.sendTimeNumber}
                      </div>
                    </div>
                  </div>
                )}
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                使用區間
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center mb-2">
                  {[
                    { text: '固定期間', value: 1 },
                    { text: '依日期推算期限', value: 2 },
                  ].map(op => {
                    return (
                      <label
                        key={`radio-dueType-${op.value}`}
                        className="d-inline-fle mb-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-dueType-${op.value}`}
                      >
                        <Field
                          type="radio"
                          name="dueType"
                          value={op.value}
                          disabled={disabled}
                          checked={props.values.dueType === op.value}
                          id={`radio-dueType-${op.value}`}
                          onChange={() => {
                            props.setFieldValue('dueType', op.value);
                          }}
                        />
                        <span className="fw-medium px-1">{op.text}</span>
                      </label>
                    );
                  })}
                </div>
                {props?.values?.dueType === 1 && (
                  <div className="d-flex align-items-center flex-wrap">
                    {(type === CONSTANT.PROMOTION ||
                      type === CONSTANT.BIRTH) && (
                      <DatePicker
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        name="redeemEndTime"
                        disabled={disabled}
                        selected={moment(
                          props.values.redeemEndTime || new Date(),
                        ).toDate()}
                        minDate={new Date()}
                        onChange={date => {
                          props.setFieldValue(
                            'redeemStartTime',
                            moment(date).format('YYYY/MM/DD'),
                          );
                          props.setFieldValue(
                            'redeemEndTime',
                            moment(date).format('YYYY/MM/DD'),
                          );
                        }}
                      />
                    )}
                    {type === CONSTANT.OPEN && (
                      <>
                        <label
                          className="d-block w-100 mb-2"
                          htmlFor="radio-isGetTime"
                        >
                          <Field
                            type="checkbox"
                            name="isGetTime"
                            value="isGetTime"
                            disabled={disabled}
                            checked={props.values.isGetTime}
                            id="radio-isGetTime"
                            onChange={() => {
                              props.setFieldValue(
                                'isGetTime',
                                !props.values.isGetTime,
                              );
                              if (!props.values.isGetTime) {
                                props.setFieldValue(
                                  'redeemStartTime',
                                  props.values.rangeStartTime,
                                );
                                props.setFieldValue(
                                  'redeemEndTime',
                                  props.values.rangeEndTime,
                                );
                              }
                            }}
                          />
                          <span className="fw-medium px-1">與活動期間相同</span>
                        </label>
                        {props.values.isGetTime && (
                          <div>
                            {moment(props.values?.redeemStartTime).format(
                              'YYYY/MM/DD HH:mm',
                            )}
                            ~
                            {moment(props.values?.redeemEndTime).format(
                              'YYYY/MM/DD HH:mm',
                            )}
                          </div>
                        )}
                        {!props.values.isGetTime && (
                          <>
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd HH:mm"
                              disabled={disabled}
                              showTimeInput
                              selected={moment(
                                props.values.redeemStartTime || new Date(),
                              ).toDate()}
                              onChange={date => {
                                props.setFieldValue(
                                  'redeemStartTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                );
                              }}
                            />
                            <span className="mx-2">~</span>
                            <DatePicker
                              className="form-control"
                              showTimeInput
                              dateFormat="yyyy/MM/dd HH:mm"
                              disabled={disabled}
                              selected={moment(
                                props.values.redeemEndTime || new Date(),
                              ).toDate()}
                              onChange={date => {
                                props.setFieldValue(
                                  'redeemEndTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                );
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
                {props?.values?.dueType === 2 && (
                  <>
                    <div className="w-100 px-2 mb-2">何時可開始使用:</div>
                    <div className="d-flex align-items-start">
                      <label
                        className="d-inline-flex flex-column"
                        htmlFor="radio-usableType-1"
                        style={{ minHeight: '30px' }}
                      >
                        <span className="d-inline-flex align-items-center flex-nowrap w-100">
                          <Field
                            type="radio"
                            name="usableType"
                            value={1}
                            checked={props.values.usableType === 1}
                            disabled={disabled}
                            id="radio-usableType-1"
                            onChange={() => {
                              props.setFieldValue('usableType', 1);
                              props.setFieldValue('usableNdaysLater', 0);
                            }}
                          />
                          <span className="fw-medium px-1 py-2">
                            領取即可使用
                          </span>
                        </span>
                      </label>
                      <label
                        className="d-inline-flex flex-column"
                        style={{ minHeight: '30px' }}
                        htmlFor="radio-usableType-2"
                      >
                        <span className="d-inline-flex align-items-center flex-nowrap w-100">
                          <Field
                            type="radio"
                            name="usableType"
                            value={2}
                            checked={props.values.usableType === 2}
                            disabled={disabled}
                            id="radio-usableType-2"
                            onChange={() => {
                              props.setFieldValue('usableType', 2);
                            }}
                          />
                          <span className="fw-medium px-1 py-2">領取第</span>
                          <Input
                            size="sm"
                            className="mx-2"
                            type="number"
                            name="usableNdaysLater"
                            placeholder="輸入2-31"
                            disabled={props.values.usableType !== 2}
                            value={
                              props.values.usableType === 2
                                ? props.values.usableNdaysLater
                                : ''
                            }
                            onBlur={e => {
                              props.setFieldValue(
                                'usableNdaysLater',
                                Number(e.target.value),
                              );
                            }}
                            onChange={e => {
                              props.setFieldValue(
                                'usableNdaysLater',
                                Number(e.target.value).toString(),
                              );
                            }}
                            min="2"
                            style={{ maxWidth: '100px' }}
                          />
                          <span className="fw-medium px-1">天可開始使用</span>
                        </span>
                        <div className="form-error fw-normal">
                          {props.errors.usableNdaysLater}
                        </div>
                        <ul>
                          <li className="text-secondary">
                            <small className="text-secondary fw-normal">
                              若設定為領取第2天，將於領取隔日的00:00可開始使用
                            </small>
                          </li>
                        </ul>
                      </label>
                    </div>
                    <div className="px-2">可使用天數:</div>
                    <div className="d-flex align-items-center flex-nowrap">
                      <Input
                        size="sm"
                        type="number"
                        name="useDay"
                        min="0"
                        placeholder="輸入1-365"
                        className="mx-2"
                        style={{ maxWidth: '150px' }}
                        value={props.values.usableDays}
                        onBlur={e => {
                          props.setFieldValue(
                            'usableDays',
                            Number(e.target.value),
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'usableDays',
                            Number(e.target.value).toString(),
                          );
                        }}
                      />
                      天內有效(含開始使用當日)
                    </div>
                    <div className="form-error fw-normal">
                      {props.errors.usableDays}
                    </div>
                    <ul>
                      <li className="text-secondary">
                        <small className="text-secondary">
                          若設定1天內有效，將於開始使用當日的23:59到期
                        </small>
                      </li>
                    </ul>
                  </>
                )}

                {props?.values?.dueType === 1 && (
                  <ul className="text-secondary">
                    <li className="text-secondary">
                      <small className="text-secondary">
                        假設設定
                        {moment(
                          props.values.redeemEndTime || new Date(),
                        ).format('YYYY/MM/DD')}
                        ，代表不論何時領券使用迄日都是
                        {moment(
                          props.values.redeemEndTime || new Date(),
                        ).format('YYYY/MM/DD')}
                      </small>
                    </li>
                  </ul>
                )}
              </div>
            </FormGroup>
            <div className="my-5" />
            <h4 className="title" style={titleStyle}>
              折價條件設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                折扣類型
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center">
                  {[
                    { text: '折現', value: 1 },
                    { text: '打折', value: 2 },
                  ].map(op => {
                    return (
                      <label
                        key={`radio-discountType-${op.value}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-discountType-${op.value}`}
                      >
                        <Field
                          type="radio"
                          name="discountType"
                          value={op.value}
                          disabled={disabled}
                          id={`radio-discountType-${op.value}`}
                          onChange={() => {
                            props.setFieldValue('discountType', op.value);
                          }}
                        />
                        <span className="fw-medium px-1">{op.text}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </FormGroup>
            {type === CONSTANT.OPEN && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  面額與張數
                </label>
                <div className="form-item">
                  {props.values.discountType === 1 && (
                    <div className="d-flex align-items-center flex-wrap">
                      每張面額NT$
                      <Input
                        size="sm"
                        name="discountQuantity"
                        value={
                          props?.values?.couponSettings?.length > 0
                            ? props?.values?.couponSettings[0].discountAmount ??
                              0
                            : 0
                        }
                        className="form-control mx-2  w-auto"
                        style={{ maxWidth: '100px' }}
                        type="number"
                        min="0"
                        disabled={disabled}
                        onBlur={e => {
                          if (props?.values?.couponSettings.length > 0) {
                            props.setFieldValue(
                              'couponSettings',
                              props.values.couponSettings.map(couponSetting => {
                                return {
                                  ...couponSetting,
                                  discountAmount: Number(e.target.value),
                                };
                              }),
                            );
                          } else {
                            props.setFieldValue('couponSettings', [
                              {
                                customedActivityCode: '',
                                discountAmount: Number(e.target.value),
                                id: 0,
                              },
                            ]);
                          }
                        }}
                        onChange={e => {
                          if (props?.values?.couponSettings.length > 0) {
                            props.setFieldValue(
                              'couponSettings',
                              props.values.couponSettings.map(couponSetting => {
                                return {
                                  ...couponSetting,
                                  discountAmount: Number(
                                    e.target.value,
                                  ).toString(),
                                };
                              }),
                            );
                          } else {
                            props.setFieldValue('couponSettings', [
                              {
                                customedActivityCode: '',
                                discountAmount: Number(
                                  e.target.value,
                                ).toString(),
                                id: 0,
                              },
                            ]);
                          }
                        }}
                      />
                      ， 提供
                      <Input
                        size="sm"
                        value={props.values?.couponQtyEach ?? 0}
                        name="couponQtyEach"
                        className="form-control mx-2  w-auto"
                        style={{ maxWidth: '100px' }}
                        type="number"
                        min="0"
                        disabled={disabled}
                        onBlur={e => {
                          props.setFieldValue(
                            'couponQtyEach',
                            Number(e.target.value),
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'couponQtyEach',
                            Number(e.target.value).toString(),
                          );
                        }}
                      />
                      張，共計NT$
                      <Input
                        readOnly="readOnly"
                        size="sm"
                        value={Number(
                          Number(
                            props.values?.couponSettings?.length
                              ? props.values?.couponSettings[0].discountAmount
                              : 0,
                          ) * Number(props.values?.couponQtyEach),
                        ).toLocaleString()}
                        className="mx-2"
                        style={{ maxWidth: '100px' }}
                        onChange={() => {}}
                      />
                    </div>
                  )}
                  {props.values.discountType === 2 && (
                    <div className="d-flex align-items-center flex-wrap">
                      打
                      <Input
                        size="sm"
                        name="discountQuantity"
                        values={
                          props?.values?.couponSettings.length > 0
                            ? props?.values?.couponSettings[0].discountAmount
                            : ''
                        }
                        className="form-control mx-2  w-auto"
                        style={{ maxWidth: '100px' }}
                        type="number"
                        min="0"
                        disabled={disabled}
                        onBlur={e => {
                          if (props?.values?.couponSettings.length > 0) {
                            props.setFieldValue(
                              'couponSettings',
                              props.values.couponSettings.map(couponSetting => {
                                return {
                                  ...couponSetting,
                                  discountAmount: Number(e.target.value),
                                };
                              }),
                            );
                          } else {
                            props.setFieldValue('couponSettings', [
                              {
                                customedActivityCode: '',
                                discountAmount: Number(e.target.value),
                                id: 0,
                              },
                            ]);
                          }
                        }}
                        onChange={e => {
                          if (props?.values?.couponSettings.length > 0) {
                            props.setFieldValue(
                              'couponSettings',
                              props.values.couponSettings.map(couponSetting => {
                                return {
                                  ...couponSetting,
                                  discountAmount: Number(
                                    e.target.value,
                                  ).toString(),
                                };
                              }),
                            );
                          } else {
                            props.setFieldValue('couponSettings', [
                              {
                                customedActivityCode: '',
                                discountAmount: Number(
                                  e.target.value,
                                ).toString(),
                                id: 0,
                              },
                            ]);
                          }
                        }}
                      />
                      折 ， 發送
                      <Input
                        size="sm"
                        name="couponQtyEach"
                        value={props.values?.couponQtyEach ?? 0}
                        className="mx-2  w-auto"
                        style={{ maxWidth: '200px' }}
                        type="number"
                        min="0"
                        disabled={disabled}
                        onBlur={e => {
                          props.setFieldValue(
                            'couponQtyEach',
                            Number(e.target.value),
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'couponQtyEach',
                            Number(e.target.value).toString(),
                          );
                        }}
                      />
                      張
                    </div>
                  )}
                  <div className="form-error">
                    {typeof props?.errors?.couponSettings === 'object' &&
                      props?.errors?.couponSettings?.length > 0 &&
                      (props?.errors?.couponSettings[0]?.customedActivityCode ||
                        props?.errors?.couponSettings[0]?.discountAmount)}
                    {typeof props?.errors?.couponSettings === 'string' &&
                      props?.errors?.couponSettings}
                  </div>
                </div>
              </FormGroup>
            )}
            {!(
              type === CONSTANT.PROMOTION ||
              type === CONSTANT.BIRTH ||
              type === CONSTANT.OPEN
            ) && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  折價券張數
                </label>
                <div className="form-item">
                  <div className="d-flex align-items-center">
                    每張面額的折價券皆提供
                    <Input
                      size="sm"
                      name="couponQtyEach"
                      value={props.values.couponQtyEach}
                      className="mx-2  w-auto"
                      style={{ maxWidth: '200px' }}
                      type="number"
                      min="0"
                      disabled={disabled}
                      onBlur={e => {
                        props.setFieldValue(
                          'couponQtyEach',
                          Number(e.target.value),
                        );
                      }}
                      onChange={e => {
                        props.setFieldValue(
                          'couponQtyEach',
                          Number(e.target.value).toString(),
                        );
                      }}
                    />
                    張
                  </div>
                  <div className="form-error">{props.errors.couponQtyEach}</div>
                </div>
              </FormGroup>
            )}
            {type !== CONSTANT.OPEN && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  折價券面額
                </label>
                <div className="form-item">
                  <ul>
                    {props.values.discountType === 1 && (
                      <>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            新增折價券上限為5筆
                          </small>
                        </li>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            假設發給某等級會員升等禮2張NT$500的折價券，則需要新增NT$500兩次。
                          </small>
                        </li>
                      </>
                    )}
                    {props.values.discountType === 2 && (
                      <>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            折扣請輸入1.0~9.9之間的數字，例:8.5代表85折;7.0代表7折。
                          </small>
                        </li>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            新增折價券上限為5筆。
                          </small>
                        </li>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            假設發給某等級會員升等禮2張折扣9折的折價券，則需要新增折扣9折兩次。
                          </small>
                        </li>
                      </>
                    )}
                  </ul>

                  {props.values?.couponSettings?.map((couponSetting, index) => {
                    return (
                      <>
                        <div
                          className="d-flex align-items-center mb-1"
                          key={`couponSetting-${index}`}
                        >
                          第{numberToChinese(index + 1)}張，面額為
                          {props.values.discountType === 1 && 'NT$'}
                          <Input
                            size="sm"
                            className="mx-2  w-auto"
                            style={{ maxWidth: '200px' }}
                            type="number"
                            min="0"
                            step={props.values.discountType === 1 ? 1 : 0.1}
                            value={couponSetting.discountAmount}
                            disabled={disabled}
                            onBlur={e => {
                              props.setFieldValue(
                                `couponSettings[${index}].discountAmount`,
                                Number(e.target.value),
                              );
                            }}
                            onChange={e => {
                              props.setFieldValue(
                                `couponSettings[${index}].discountAmount`,
                                Number(e.target.value).toString(),
                              );
                            }}
                          />
                          {props.values.discountType === 2 && '折'}
                          {index >= 1 && (
                            <Button
                              size="sm"
                              outline="outline"
                              color="danger"
                              className="mx-2"
                              onClick={() => {
                                props.values.couponSettings.splice(index, 1);
                                props.setFieldValue(
                                  'couponSettings',
                                  props.values.couponSettings,
                                );
                              }}
                            >
                              刪除
                            </Button>
                          )}
                        </div>
                        <div
                          className="form-error"
                          key={`errors-couponSetting-${index}`}
                        >
                          {typeof props.errors?.couponSettings !== 'string' &&
                            props.errors?.couponSettings?.length > 0 &&
                            props.errors?.couponSettings[index] &&
                            props.errors?.couponSettings[index]?.discountAmount}
                        </div>
                      </>
                    );
                  })}
                  <Button
                    size="sm"
                    outline="outline"
                    color="primary"
                    disabled={
                      disabled || props.values.couponSettings?.length >= 5
                    }
                    onClick={() => {
                      if (props.values.couponSettings.length < 5) {
                        props.setFieldValue('couponSettings', [
                          ...props.values.couponSettings,
                          {
                            customedActivityCode: '',
                            discountAmount: 0,
                            id: 0,
                          },
                        ]);
                      }
                    }}
                  >
                    新增折價券
                  </Button>
                  <div className="form-error">
                    {typeof props.errors.couponSettings === 'string' &&
                      props.errors.couponSettings}
                  </div>
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                折價門檻
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      消費者在購物車內結帳時，若商品不可使用此折價券，不會列入門檻金額計算，也不會被折扣。
                    </small>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-useMin-1"
                  >
                    <Field
                      type="radio"
                      name="useMin"
                      value={1}
                      checked={props.values.useMin === 1}
                      disabled={disabled}
                      id="radio-useMin-1"
                      onChange={() => {
                        props.setFieldValue('useMin', 1);
                      }}
                    />
                    <span className="fw-medium px-1">無門檻</span>
                  </label>
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-useMin-2"
                  >
                    <Field
                      type="radio"
                      name="useMin"
                      checked={props.values.useMin === 2}
                      disabled={disabled}
                      value={2}
                      id="radio-useMin-2"
                      onChange={() => {
                        props.setFieldValue('useMin', 2);
                        if (props.values.discountType === 1) {
                          props.setFieldValue('discountMaxAmount', 100);
                        }
                      }}
                    />
                    <span className="fw-medium px-1">滿額門檻</span>
                  </label>
                </div>
                {props.values.useMin === 2 && (
                  <>
                    <div className="d-flex align-items-center">
                      單筆金額需滿NT$
                      <Input
                        size="sm"
                        type="number"
                        value={props.values.useMinAmount}
                        name="useMinAmount"
                        disabled={disabled}
                        min="0"
                        className="form-control mx-2"
                        style={{ maxWidth: '80px' }}
                        onBlur={e => {
                          props.setFieldValue(
                            'useMinAmount',
                            Number(e.target.value),
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'useMinAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                      />
                      <span className="fw-medium px-1">，方可折抵。</span>
                    </div>
                    <div className="form-error">
                      {props.errors?.useMinAmount}
                    </div>
                  </>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                折抵上限
              </label>
              {props.values.discountType === 1 && (
                <div className="form-item">
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        提醒您，當有設定滿額門檻時，折抵上限建議設定為100%
                      </small>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center">
                    最高可折抵購買金額為
                    <Input
                      size="sm"
                      type="number"
                      name="discountMaxAmount"
                      value={props.values.discountMaxAmount}
                      className="form-control mx-2"
                      disabled={props.values.useMin === 2 || disabled}
                      style={{ maxWidth: '80px' }}
                      onBlur={e => {
                        props.setFieldValue(
                          'discountMaxAmount',
                          Number(e.target.value),
                        );
                      }}
                      onChange={e => {
                        props.setFieldValue(
                          'discountMaxAmount',
                          Number(e.target.value).toString(),
                        );
                      }}
                    />
                    %
                  </div>
                  <div className="form-error">
                    {props.errors.discountMaxAmount}
                  </div>
                </div>
              )}
              {props.values.discountType === 2 && (
                <div className="form-item">
                  <div className="d-flex align-items-center">
                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-discountMax-1"
                    >
                      <div style={{ width: '20px' }}>
                        <Field
                          type="radio"
                          disabled={disabled}
                          name="discountMax"
                          value={1}
                          id="radio-discountMax-1"
                          onChange={() => {
                            props.setFieldValue('discountMax', 1);
                          }}
                        />
                      </div>
                      <span className="fw-medium px-1">不限定</span>
                    </label>

                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-discountMax-2"
                    >
                      <div style={{ width: '20px' }}>
                        <Field
                          type="radio"
                          name="discountMax"
                          value={2}
                          disabled={disabled}
                          id="radio-discountMax-2"
                          onChange={() => {
                            props.setFieldValue('discountMax', 2);
                          }}
                        />
                      </div>
                      <span className="fw-medium px-1">
                        最高可折抵購買金額的NT$
                      </span>
                      <Input
                        size="sm"
                        type="number"
                        name="discountMaxAmount"
                        disabled={disabled || props.values.discountMax === 1}
                        value={props.values.discountMaxAmount}
                        className="form-control mx-2"
                        style={{ maxWidth: '80px' }}
                        onBlur={e => {
                          props.setFieldValue(
                            'discountMaxAmount',
                            Number(e.target.value),
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'discountMaxAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                      />
                    </label>
                    <div className="form-error">
                      {props.errors.discountMaxAmount}
                    </div>
                  </div>
                </div>
              )}
            </FormGroup>
            {type === CONSTANT.OPEN && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  剩餘張數提醒
                </label>
                <div className="form-item">
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-remiderNum-0"
                  >
                    <input
                      type="radio"
                      name="remiderNum"
                      value={1}
                      disabled={disabled}
                      checked={props.values?.remiderNum > 0}
                      id="radio-remiderNum-0"
                      onChange={() => {
                        props.setFieldValue('remiderNum', 1);
                      }}
                    />
                    <span className="fw-medium px-1">剩</span>
                    {props.values?.remiderNum > 0 && (
                      <Input
                        size="sm"
                        className="mx-2"
                        name="remiderNum"
                        value={props.values.remiderNum}
                        type="number"
                        min="1"
                        disabled={disabled}
                        style={{ maxWidth: '100px' }}
                        onBlur={e => {
                          props.setFieldValue(
                            'remiderNum',
                            Number(e.target.value) > 0
                              ? Number(e.target.value)
                              : 1,
                          );
                        }}
                        onChange={e => {
                          props.setFieldValue(
                            'remiderNum',
                            Number(e.target.value) > 0
                              ? Number(e.target.value).toString()
                              : 1,
                          );
                        }}
                      />
                    )}
                    {props.values?.remiderNum === 0 && (
                      <input
                        disabled
                        className="mx-2"
                        style={{ maxWidth: '100px' }}
                      />
                    )}
                    <span className="fw-medium px-1">張數時，系統發信提醒</span>
                  </label>
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-remiderNum-1"
                  >
                    <Field
                      type="radio"
                      name="remiderNum"
                      value={0}
                      disabled={disabled}
                      checked={props.values?.remiderNum === 0}
                      id="radio-remiderNum-1"
                      onChange={() => {
                        props.setFieldValue('remiderNum', 0);
                      }}
                    />
                    <span className="fw-medium px-1">不需要提醒</span>
                  </label>
                </div>
              </FormGroup>
            )}
            <div className="my-5" />
            <h4 className="title" style={titleStyle}>
              折價券使用設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                可使用通路
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center">
                  {['web', 'store'].map(op => {
                    return (
                      <label
                        key={`radio-availableWay-${op}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-availableWay-${op}`}
                      >
                        <input
                          type="checkbox"
                          name="availableWay"
                          value={op}
                          disabled={disabled}
                          checked={props.values?.availableWay?.includes(op)}
                          id={`radio-availableWay-${op}`}
                          onChange={() => {
                            if (
                              props.values?.availableWay.split(',').includes(op)
                            ) {
                              props.setFieldValue(
                                'availableWay',
                                props.values?.availableWay
                                  .replace(op, '')
                                  .split(',')
                                  .filter(s => s)
                                  .join(','),
                              );
                            } else {
                              props.setFieldValue(
                                'availableWay',
                                [
                                  ...(props.values?.availableWay
                                    ?.split(',')
                                    .filter(s => s) || []),
                                  op,
                                ].join(','),
                              );
                            }
                          }}
                        />
                        <span className="fw-medium px-1">
                          {op === 'web' && '網頁版'}
                          {op === 'store' && '門市'}
                        </span>
                      </label>
                    );
                  })}
                </div>
                <div className="form-error">{props.errors.availableWay}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                是否與折扣活動
                <br />
                合併使用
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      「是」於購物車結帳時，適用商品可同時使用折價券與折扣活動優惠
                    </small>
                  </li>

                  <li className="text-secondary">
                    <small className="text-secondary">
                      「否」於購物車結帳時，適用商品於折價券與折扣活動，只可擇一折抵
                    </small>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-isUsedwithDiscount-1"
                  >
                    <Field
                      type="radio"
                      name="isUsedwithDiscount"
                      value={true}
                      disabled={disabled}
                      id="radio-isUsedwithDiscount-1"
                      onChange={() => {
                        props.setFieldValue('isUsedwithDiscount', true);
                      }}
                    />
                    <span className="fw-medium px-1">是</span>
                  </label>
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-isUsedwithDiscount-2"
                  >
                    <Field
                      type="radio"
                      name="isUsedwithDiscount"
                      value={false}
                      disabled={disabled}
                      id="radio-isUsedwithDiscount-2"
                      onChange={() => {
                        props.setFieldValue('isUsedwithDiscount', false);
                      }}
                    />
                    <span className="fw-medium px-1">否</span>
                  </label>
                </div>
              </div>
            </FormGroup>

            {props.values?.availableWay?.includes('store') && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  自訂活動代碼
                </label>
                <div className="form-item">
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        輸入自訂的活動代碼或pos產出的活動代碼，以辨識該活動的訂單，限20字元以內英文字母、數字、半形底線「_」，或半形連字號「-」
                      </small>
                    </li>
                  </ul>
                  {(type === CONSTANT.BIRTH || type === CONSTANT.OPEN) && (
                    <div className="d-flex">
                      <Input
                        size="sm"
                        name="customedActivityCode"
                        className="mx-2"
                        value={
                          props.values?.couponSettings?.length
                            ? props.values?.couponSettings[0]
                                ?.customedActivityCode
                            : ''
                        }
                        disabled={disabled}
                        onChange={e => {
                          if (props.values?.couponSettings?.length) {
                            props.setFieldValue(
                              'couponSettings',
                              props.values.couponSettings.map(couponSetting => {
                                return {
                                  ...couponSetting,
                                  customedActivityCode: e.target.value,
                                };
                              }),
                            );
                          } else {
                            props.setFieldValue('couponSettings', [
                              {
                                customedActivityCode: e.target.value,
                                discountAmount: 0,
                              },
                            ]);
                          }
                        }}
                      />
                      <span
                        style={{ minWidth: '80px' }}
                        className={`text-nowrap ${
                          props.values?.customedActivityCode?.length > 20
                            ? 'text-danger'
                            : ''
                        }`}
                      >
                        ({props.values?.customedActivityCode?.length || 0}/20)
                      </span>
                    </div>
                  )}
                  {type === CONSTANT.PROMOTION &&
                    (props.values?.couponSettings || []).map(
                      (couponSetting, index) => {
                        return (
                          <>
                            <div
                              className="d-flex align-items-center mb-1"
                              key={`customedActivityCode-${index}`}
                            >
                              第{numberToChinese(index + 1)}張，
                              {props.values.discountType === 1 &&
                                `NT$${props.values?.couponSettings[
                                  index
                                ]?.discountAmount?.toLocaleString()}`}
                              {props.values.discountType === 2 &&
                                `${props.values?.couponSettings[index]?.discountAmount}折`}
                              <Input
                                size="sm"
                                className="mx-2  w-auto"
                                type="text"
                                style={{ maxWidth: '200px' }}
                                value={
                                  props.values?.couponSettings[index]
                                    ?.customedActivityCode
                                }
                                disabled={disabled}
                                onChange={e => {
                                  props.setFieldValue(
                                    `couponSettings[${index}].customedActivityCode`,
                                    e.target.value,
                                  );
                                }}
                              />
                            </div>

                            <div
                              className="form-error"
                              key={`error-customedActivityCode-${index}`}
                            >
                              {typeof props.errors?.couponSettings !==
                                'string' &&
                                props.errors?.couponSettings?.length > 0 &&
                                props.errors?.couponSettings[index] &&
                                props.errors?.couponSettings[index]
                                  ?.customedActivityCode}
                            </div>
                          </>
                        );
                      },
                    )}
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                適用商品
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      此折價券指定商品中若有「不適用任何折價券商品」，該商品亦無法使用本折價券折抵
                    </small>
                  </li>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      商品清單更新後，系統將於30分鐘後生效
                    </small>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  {[
                    { name: '全店商品', value: 'all' },
                    { name: '指定商品', value: 'picked' },
                  ].map((op, index) => {
                    return (
                      <label
                        key={`radio-range-${op.value}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-range-${op.value}`}
                      >
                        <Field
                          type="radio"
                          name="productRange"
                          disabled={disabled}
                          value={op.value}
                          id={`radio-range-${op.value}`}
                          onChange={() => {
                            props.setFieldValue('productRange', op.value);
                          }}
                        />
                        <span className="fw-medium px-1">{op.name}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <div className="form-item">
                <ProductRange
                  disabled={disabled}
                  type={props.values.productRange}
                  models={props.values.models}
                  onChange={products => {
                    props.setFieldTouched('models', true);
                    props.setFieldValue('models', [...products]);
                  }}
                />
                <div className="form-error">{props.errors?.models}</div>
              </div>
            </FormGroup>
            <div className="my-5" />
            <h4 className="title" style={titleStyle}>
              優惠券顯示內容
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動名稱(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="name"
                    className="form-control mx-2"
                    disabled={disabled}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.name?.length > 40 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.name?.length || 0}/40)
                  </span>
                </div>
                <div className="form-error">{props.errors.name}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動名稱(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="name_en"
                    className="form-control mx-2"
                    disabled={disabled}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.name_en?.length > 100 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.name_en?.length || 0}/100)
                  </span>
                </div>
                <div className="form-error">{props.errors.name_en}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動說明(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    as="textarea"
                    row="5"
                    style={{ height: '200px' }}
                    name="desc"
                    className="form-control mx-2"
                    disabled={disabled}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.desc?.length > 800 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.desc?.length || 0}/800)
                  </span>
                </div>
                <div className="form-error">{props.errors.desc}</div>
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動說明(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    as="textarea"
                    row="5"
                    style={{ height: '200px' }}
                    name="desc_en"
                    className="form-control mx-2"
                    disabled={disabled}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.desc_en?.length > 800 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.desc_en?.length || 0}/800)
                  </span>
                </div>
                <div className="form-error">{props.errors.desc_en}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>
                券面圖片
              </label>
              <div className="form-item">
                <UploadFileBox
                  disabled={disabled}
                  uplodModel="images"
                  formFieldName="couponImgFiles"
                  currentFileFieldName="initialCouponImg"
                  setFormFieldValue={(field, files) => {
                    if (files)
                      if (files && files instanceof Array && files.length) {
                        if (files[0] instanceof File) {
                          props.setFieldValue(
                            'couponImg',
                            JSON.stringify(files),
                          );
                          props.setFieldValue('couponImgLink', files[0].name);
                          props.setFieldValue('couponImgFiles', files);
                        }
                      } else if (
                        typeof files === 'string' &&
                        files === 'isDelete'
                      ) {
                        props.setFieldValue('couponImg', '');
                        props.setFieldValue('couponImgLink', '');
                        props.setFieldValue('couponImgFiles', []);
                      }
                  }}
                  displayFileWidth="160"
                  displayFileHeight="160"
                  displayFileSize="3MB"
                  initFiles={props.values.initialCouponImg}
                />
                <div className="form-error">{props.errors.couponImgFiles}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                券面顯示文字(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="couponWording"
                    className="form-control mx-2"
                    disabled={disabled}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.couponWording?.length > 20
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    ({props.values?.couponWording?.length || 0}/20)
                  </span>
                </div>
                <div className="form-error">{props.errors.couponWording}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                券面顯示文字(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    disabled={disabled}
                    name="couponWording_en"
                    className="form-control mx-2"
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.couponWording_en?.length > 80
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    ({props.values?.couponWording_en?.length || 0}/80)
                  </span>
                </div>
                <div className="form-error">
                  {props.errors.couponWording_en}
                </div>
              </div>
            </FormGroup>
            <div className="my-4" />
            <div className="mt-2 text-nowrap text">
              {!initialValues.id && (
                <div className="d-inline-block ">
                  新增時間: {props.values?.createTime || '--'}
                  <span className="mx-2"></span>
                  {props.values.creator ? props.values.creator : ''}
                </div>
              )}
              {!!initialValues.id && (
                <div className="d-inline-block">
                  更新時間: {props.values?.updateTime || '--'}
                  <span className="mx-2"></span>
                  {props.values.updater ? props.values.updater : ''}
                </div>
              )}
            </div>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={goBack}
              >
                <span className="btn-wrapper--label">取消</span>
              </Button>
              {!disabled && (
                <Button
                  type="button"
                  disabled={Object.keys(props.errors).length > 0}
                  className={style.modal_button_submit}
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(PresentSettings);
