const {
  VOUCHER_SETTINGS_DATA,
  GET_VOUCHER_LIST_REQUEST,
  GET_VOUCHER_LIST_SUCCESS,
  GET_VOUCHER_LIST_FAIL,
  CREATE_VOUCHER_IDLE,
  GET_VOUCHER_DETAIL_REQUEST,
  GET_VOUCHER_DETAIL_SUCCESS,
  GET_VOUCHER_DETAIL_FAIL,
  UPDATE_VOUCHER_DETAIL_IDLE,
} = require('redux/constants/data/voucherConstants');

export const setVoucherSettingsDataReducer = (
  state = {
    datas: {},
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case VOUCHER_SETTINGS_DATA:
      return { datas: payload };
    default:
      return state;
  }
};

export const getVoucherListReducer = (
  state = { error: null, loading: false, success: false, voucherList: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VOUCHER_LIST_REQUEST:
      return { error: null, loading: true, success: false, voucherList: {} };
    case GET_VOUCHER_LIST_SUCCESS:
      return { ...state, loading: false, success: true, voucherList: payload };
    case GET_VOUCHER_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getVoucherDetailReducer = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VOUCHER_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case GET_VOUCHER_DETAIL_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case GET_VOUCHER_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

