import {
  CREATE_NEW_SHOP_BANNER_FAIL,
  CREATE_NEW_SHOP_BANNER_REQUEST,
  CREATE_NEW_SHOP_BANNER_SUCCESS,
  CREATE_SHOP_BANNER_SCHEDULE_FAIL,
  CREATE_SHOP_BANNER_SCHEDULE_REQUEST,
  CREATE_SHOP_BANNER_SCHEDULE_SUCCESS,
  DELETE_SHOP_BANNER_FAIL,
  DELETE_SHOP_BANNER_REQUEST,
  DELETE_SHOP_BANNER_SUCCESS,
  GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL,
  GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST,
  GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS,
  GET_ONE_SHOP_BANNER_FAIL,
  GET_ONE_SHOP_BANNER_REQUEST,
  GET_ONE_SHOP_BANNER_SUCCESS,
  GET_SHOP_BANNER_COLOR_FAIL,
  GET_SHOP_BANNER_COLOR_REQUEST,
  GET_SHOP_BANNER_COLOR_SUCCESS,
  GET_SHOP_BANNER_LIST_FAIL,
  GET_SHOP_BANNER_LIST_REQUEST,
  GET_SHOP_BANNER_LIST_SUCCESS,
  SORTING_SHOP_BANNER_FAIL,
  SORTING_SHOP_BANNER_REQUEST,
  SORTING_SHOP_BANNER_SUCCESS,
  UPDATE_SHOP_BANNER_COLOR_FAIL,
  UPDATE_SHOP_BANNER_COLOR_REQUEST,
  UPDATE_SHOP_BANNER_COLOR_SUCCESS,
  UPDATE_SHOP_BANNER_FAIL,
  UPDATE_SHOP_BANNER_REQUEST,
  UPDATE_SHOP_BANNER_SUCCESS,
} from 'redux/constants/data/shopBannerConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getDiscountCompaignOptions = () => {
  const url = '/Backend/DiscountActivity/GetSelection';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST,
      successConstant: GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getShopBannerList = data => {
  const url = '/Backend/ProductBannerSetting/List';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_SHOP_BANNER_LIST_FAIL,
      method: 'post',
      requestConstant: GET_SHOP_BANNER_LIST_REQUEST,
      successConstant: GET_SHOP_BANNER_LIST_SUCCESS,
      url,
    });
  };
};

export const createNewShopBanner = () => {
  const url = '/Backend/ProductBannerSetting/CreateEmpty';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: CREATE_NEW_SHOP_BANNER_FAIL,
      method: 'get',
      requestConstant: CREATE_NEW_SHOP_BANNER_REQUEST,
      successConstant: CREATE_NEW_SHOP_BANNER_SUCCESS,
      url,
    });
  };
};

export const deleteShopBanner = id => {
  const url = `/Backend/ProductBannerSetting/Delete/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DELETE_SHOP_BANNER_FAIL,
      method: 'get',
      requestConstant: DELETE_SHOP_BANNER_REQUEST,
      successConstant: DELETE_SHOP_BANNER_SUCCESS,
      url,
    });
  };
};

export const getOneShopBanner = id => {
  const url = `/Backend/ProductBannerSetting/Get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ONE_SHOP_BANNER_FAIL,
      method: 'get',
      requestConstant: GET_ONE_SHOP_BANNER_REQUEST,
      successConstant: GET_ONE_SHOP_BANNER_SUCCESS,
      url,
    });
  };
};

export const updateShopBanner = data => {
  const url = '/Backend/ProductBannerSetting/Update';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_SHOP_BANNER_FAIL,
      method: 'post',
      requestConstant: UPDATE_SHOP_BANNER_REQUEST,
      successConstant: UPDATE_SHOP_BANNER_SUCCESS,
      url,
    });
  };
};

export const createShopBannerSchedule = data => {
  const url = '/Backend/ProductBannerSetting/Create';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_SHOP_BANNER_SCHEDULE_FAIL,
      method: 'post',
      requestConstant: CREATE_SHOP_BANNER_SCHEDULE_REQUEST,
      successConstant: CREATE_SHOP_BANNER_SCHEDULE_SUCCESS,
      url,
    });
  };
};

export const getShopBannerColor = () => {
  const url = '/Backend/ProductBannerSetting/GetColor';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_SHOP_BANNER_COLOR_FAIL,
      method: 'get',
      requestConstant: GET_SHOP_BANNER_COLOR_REQUEST,
      successConstant: GET_SHOP_BANNER_COLOR_SUCCESS,
      url,
    });
  };
};

export const updateShopBannerColor = color => {
  const url = `/Backend/ProductBannerSetting/UpdateColor/${color}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: UPDATE_SHOP_BANNER_COLOR_FAIL,
      method: 'get',
      requestConstant: UPDATE_SHOP_BANNER_COLOR_REQUEST,
      successConstant: UPDATE_SHOP_BANNER_COLOR_SUCCESS,
      url,
    });
  };
};

export const sortingShopBanner = data => {
  const url = '/Backend/ProductBannerSetting/UpdateSort';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SORTING_SHOP_BANNER_FAIL,
      method: 'post',
      requestConstant: SORTING_SHOP_BANNER_REQUEST,
      successConstant: SORTING_SHOP_BANNER_SUCCESS,
      url,
    });
  };
};
