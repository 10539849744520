import Message from 'components/messageList/Message';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card } from 'reactstrap';

import style from '../../styles/layout.module.css';

const ProblemRecords = ({
  datas,
  setCurrentImgSrc,
  setIsOriginImageModalOpen,
}) => {
  const [content, setContent] = useState([]);
  useEffect(() => {
    let replies = [];
    if (JSON.stringify(datas) !== '{}') {
      replies = datas.replies?.map(reply => ({
        content: reply.context,
        messageImages: reply.messageImgs,
        name: reply.adminName,
        time: reply.createTime,
        type: reply.adminName ? 'staff' : 'customer',
      }));

      if (replies?.length > 0) {
        // setContent([{
        //   // 第一筆資料固定是消費者提問
        //   type: 'customer',
        //   content: datas.context,
        //   time: datas.createTime,
        //   messageImages: datas.messageImgs,
        // }, ...replies]);
        setContent(replies);
      }
    }
  }, [datas]);
  return (
    <Card className={style.search_card}>
      <div className={style.card_header}>歷程記錄</div>
      <div className={style.card_body}>
        {content.length > 0 &&
          content.map((data, index) => (
            <Message
              key={index}
              type={data.type}
              name={data.name}
              time={data.time}
              content={data.content}
              messageImages={data.messageImages}
              setCurrentImgSrc={setCurrentImgSrc}
              setIsOriginImageModalOpen={setIsOriginImageModalOpen}
            />
          ))}
      </div>
    </Card>
  );
};

export default ProblemRecords;
