import { useRequest } from 'utils/useFetch';

import {
  SEOSETTINGS_LIST_REQUEST,
  SEOSETTINGS_LIST_SUCCESS,
  SEOSETTINGS_LIST_FAIL,
  SEOSETTINGS_DETAIL_REQUEST,
  SEOSETTINGS_DETAIL_SUCCESS,
  SEOSETTINGS_DETAIL_FAIL,
  SEOSETTINGS_UPDATE_REQUEST,
  SEOSETTINGS_UPDATE_SUCCESS,
  SEOSETTINGS_UPDATE_FAIL,
  SEOSETTINGS_DETAIL_UPDATE_REQUEST,
  SEOSETTINGS_DETAIL_UPDATE_SUCCESS,
  SEOSETTINGS_DETAIL_UPDATE_FAIL,
} from '../../constants/data/seoSettingsConstants';

export const getSeoSettingsList = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: SEOSETTINGS_LIST_FAIL,
      method: 'get',
      requestConstant: SEOSETTINGS_LIST_REQUEST,
      successConstant: SEOSETTINGS_LIST_SUCCESS,
      url: '/Backend/SEOSetting/list',
    });
  };
};

export const updateSeoSettings = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: SEOSETTINGS_UPDATE_FAIL,
      method: 'post',
      requestConstant: SEOSETTINGS_UPDATE_REQUEST,
      successConstant: SEOSETTINGS_UPDATE_SUCCESS,
      url: '/Backend/SEOSetting/Update',
    });
  };
};

export const getSeoSettingsDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: SEOSETTINGS_DETAIL_FAIL,
      method: 'get',
      requestConstant: SEOSETTINGS_DETAIL_REQUEST,
      successConstant: SEOSETTINGS_DETAIL_SUCCESS,
      url: `/Backend/SEOSetting/GetDetail/${params.type}/${params.sourceId}`,
    });
  };
};

export const updateSeoSettingsDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: SEOSETTINGS_DETAIL_UPDATE_FAIL,
      method: 'post',
      requestConstant: SEOSETTINGS_DETAIL_UPDATE_REQUEST,
      successConstant: SEOSETTINGS_DETAIL_UPDATE_SUCCESS,
      url: '/Backend/SEOSetting/UpdateDetail',
    });
  };
};
