import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateWarrantyProductSerialNumber } from 'redux/actions/data/repairActions';
import { UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE } from 'redux/constants/data/repairConstants';
import MessageUtil from 'utils/MessageUtil';

import style from '../styles/layout.module.css';

const ExchangeSerialNumberModal = ({ isOpen, toggle, onRefresh, info }) => {
  const dispatch = useDispatch();
  const { success, error, loading } = useSelector(
    state => state.updateWarrantyProductSerialNumber,
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      productSerialNum: info.productSerailNum,
      shippingId: 0,
      warrantyId: 0,
      warrantySerialNum: info.warrantySerailNum,
    },
    onSubmit: () => {
      dispatch(updateWarrantyProductSerialNumber(values, info.id));
    },
  });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      toggle();
      onRefresh && onRefresh();
      dispatch({ type: UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE });
    }

    if (error) {
      MessageUtil.alertWanring('更新失敗', error);
      dispatch({ type: UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE });
    }
  }, [success, error]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        更換產品序號
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ margin: '1rem' }}>
        <div className={`${style.show_flex} mb-3`}>
          <div className="w150">保固序號：</div>
          <div> {values.warrantySerialNum}</div>
        </div>
        <div className={`${style.show_flex} mb-3`}>
          <span className="w150">更換的產品序號</span>
          <Input
            className={style['repair-modal-input']}
            onChange={event => {
              setFieldValue('productSerialNum', event.target.value);
            }}
            value={values.productSerialNum}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExchangeSerialNumberModal;
