export const UUCAFE_SEO_SETTINGS_LIST_REQUEST =
  'UUCAFE_SEO_SETTINGS_LIST_REQUEST';
export const UUCAFE_SEO_SETTINGS_LIST_SUCCESS =
  'UUCAFE_SEO_SETTINGS_LIST_SUCCESS';
export const UUCAFE_SEO_SETTINGS_LIST_FAIL = 'UUCAFE_SEO_SETTINGS_LIST_FAIL';

export const UUCAFE_SEO_SETTINGS_DETAIL_REQUEST =
  'UUCAFE_SEO_SETTINGS_DETAIL_REQUEST';
export const UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS =
  'UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS';
export const UUCAFE_SEO_SETTINGS_DETAIL_FAIL =
  'UUCAFE_SEO_SETTINGS_DETAIL_FAIL';

export const UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST =
  'UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST';
export const UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS =
  'UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS';
export const UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL =
  'UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL';
