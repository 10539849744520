import axios from 'axios';
import { useRequest } from 'utils/useFetch';

import CommonUtil from '../../../utils/CommonUtil';
import request from '../../../utils/useAxios';
import {
  GET_PRODUCTBG,
  GET_PRODUCTPIC,
  GET_PRODUCT_FEATURES,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS,
  PRODUCT_BRAND_CATEGORY_FAIL,
  PRODUCT_BRAND_CATEGORY_REQUEST,
  PRODUCT_BRAND_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_PRODUCTS_FAIL,
  PRODUCT_CATEGORY_PRODUCTS_REQUEST,
  PRODUCT_CATEGORY_PRODUCTS_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_EDIT_FAIL,
  PRODUCT_EDIT_REQUEST,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_GLOBAL_CATEGORY_FAIL,
  PRODUCT_GLOBAL_CATEGORY_REQUEST,
  PRODUCT_GLOBAL_CATEGORY_SUCCESS,
  PRODUCT_LINKLIST_FAIL,
  PRODUCT_LINKLIST_REQUEST,
  PRODUCT_LINKLIST_SUCCESS,
  PRODUCT_LINK_DETAILS_FAIL,
  PRODUCT_LINK_DETAILS_REQUEST,
  PRODUCT_LINK_DETAILS_SUCCESS,
  PRODUCT_LINK_POST_FAIL,
  PRODUCT_LINK_POST_REQUEST,
  PRODUCT_LINK_POST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_MODEL_OPTIONS_FAIL,
  PRODUCT_MODEL_OPTIONS_REQUEST,
  PRODUCT_MODEL_OPTIONS_SUCCESS,
  PRODUCT_OPTIONS_FAIL,
  PRODUCT_OPTIONS_REQUEST,
  PRODUCT_OPTIONS_SUCCESS,
  PRODUCT_PROFILE_FAIL,
  PRODUCT_PROFILE_REQUEST,
  PRODUCT_PROFILE_SUCCESS,
  PRODUCT_OPTIONS_STATE_UPDATE_FAIL,
  PRODUCT_OPTIONS_STATE_UPDATE_REQUEST,
  PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS,
  PRODUCT_SPEC_DELETE_FAIL,
  PRODUCT_SPEC_DELETE_REQUEST,
  PRODUCT_SPEC_DELETE_SUCCESS,
  PRODUCT_SPEC_LIST_FAIL,
  PRODUCT_SPEC_LIST_REQUEST,
  PRODUCT_SPEC_LIST_SUCCESS,
  PRODUCT_SPEC_POST_FAIL,
  PRODUCT_SPEC_POST_REQUEST,
  PRODUCT_SPEC_POST_SUCCESS,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  IMPORT_PRODUCT_STOCK_FILE_REQUEST,
  IMPORT_PRODUCT_STOCK_FILE_SUCCESS,
  IMPORT_PRODUCT_STOCK_FILE_FAIL,
  GET_COLOR_OPTIONS_REQUEST,
  GET_COLOR_OPTIONS_SUCCESS,
  GET_COLOR_OPTIONS_FAIL,
  SET_PRODUCT_STOCK_STATUS_REQUEST,
  SET_PRODUCT_STOCK_STATUS_SUCCESS,
  SET_PRODUCT_STOCK_STATUS_FAIL,
  GET_BINDING_GIFTS_LIST_REQUEST,
  GET_BINDING_GIFTS_LIST_SUCCESS,
  GET_BINDING_GIFTS_LIST_FAIL,
  ADD_GIFTS_REQUEST,
  ADD_GIFTS_SUCCESS,
  ADD_GIFTS_FAIL,
} from '../../constants/data/productConstants';

import { baseURL, prefix_api_path } from './api_config';

export const getProductList = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_LIST_FAIL,
      method: 'get',
      params,
      requestConstant: PRODUCT_LIST_REQUEST,
      successConstant: PRODUCT_LIST_SUCCESS,
      url: '/Backend/Product/list',
    });
  };
};

export const getProductListV2 = params => {
  if (params.productType === 1) {
    params.brandId = params.brandId || null;
  } else {
    params.brandId = params.brandId || null;
  }
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: PRODUCT_LIST_FAIL,
      method: 'post',
      params,
      requestConstant: PRODUCT_LIST_REQUEST,
      successConstant: PRODUCT_LIST_SUCCESS,
      url:
        params.productType === 1
          ? '/Backend/DiscountActivity/AddList_V2'
          : '/Backend/DiscountActivity/AddGroupModelListQuery',
    });
  };
};

//get model options
export const getModelOptions = keyword => {
  const url = `/Backend/Product/modelOptions?brandName=${keyword}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_MODEL_OPTIONS_FAIL,
      method: 'get',
      requestConstant: PRODUCT_MODEL_OPTIONS_REQUEST,
      successConstant: PRODUCT_MODEL_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getProductOptions = col => {
  const url = `/Backend/Product/options?col=${col}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_OPTIONS_FAIL,
      method: 'get',
      requestConstant: PRODUCT_OPTIONS_REQUEST,
      successConstant: PRODUCT_OPTIONS_SUCCESS,
      url,
    });
  };
};

//edit product profile
export const editProductProfile = editInfo => {
  const url = '/Backend/Product/profile';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: editInfo,
      dispatch,
      failConstant: PRODUCT_EDIT_FAIL,
      method: 'post',
      requestConstant: PRODUCT_EDIT_REQUEST,
      successConstant: PRODUCT_EDIT_SUCCESS,
      url,
    });
  };
};

//get product profile
export const getProductProfile = productID => {
  const url = `/Website/Model/GetReview/${productID}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_PROFILE_FAIL,
      method: 'get',
      requestConstant: PRODUCT_PROFILE_REQUEST,
      successConstant: PRODUCT_PROFILE_SUCCESS,
      url,
    });
  };
};

//get product brand category
export const getProductBrandCategory = brandId => {
  const url = `/Backend/Brand/category/options?brandId=${brandId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_BRAND_CATEGORY_FAIL,
      method: 'get',
      requestConstant: PRODUCT_BRAND_CATEGORY_REQUEST,
      successConstant: PRODUCT_BRAND_CATEGORY_SUCCESS,
      url,
    });
  };
};

//get product global category
export const getProductCategoryOptions = () => {
  const url = '/Backend/Product/category/options';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_GLOBAL_CATEGORY_FAIL,
      method: 'get',
      requestConstant: PRODUCT_GLOBAL_CATEGORY_REQUEST,
      successConstant: PRODUCT_GLOBAL_CATEGORY_SUCCESS,
      url,
    });
  };
};

//get product category product
export const getProductCategoryProducts = info => {
  const url = `/Backend/Product/listFromGlobalCate?${
    info.globalCateId ? `globalCateId=${info.globalCateId}` : ''
  }&${info.keyword ? `keyword=${info.keyword}` : ''}&${
    info.brandId ? `brandId=${info.brandId}` : ''
  }&${info.model ? `model=${info.model}` : ''}&page=${info.page}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_CATEGORY_PRODUCTS_FAIL,
      method: 'get',
      requestConstant: PRODUCT_CATEGORY_PRODUCTS_REQUEST,
      successConstant: PRODUCT_CATEGORY_PRODUCTS_SUCCESS,
      url,
    });
  };
};

//delete product
export const deleteProduct = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Product/removeGlobalCategory`,
        info,
        config,
      );

      dispatch({
        payload: data,
        type: PRODUCT_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: PRODUCT_DELETE_FAIL,
      });
    }
  };
};

//add product to global category
export const addProductToGlobalCategory = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Product/assignToGlobalCategory`,
        info,
        config,
      );

      dispatch({
        payload: data,
        type: PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL,
      });
    }
  };
};

//get product link list
export const getProductLinkList = (name, page, pageSize) => {
  const url = `/Backend/ProductECLink/ecPlatformList?${
    name ? `name=${name}` : ''
  }&${page ? `page=${page}` : ''}&${pageSize ? `page_size=${pageSize}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_LINKLIST_FAIL,
      method: 'get',
      requestConstant: PRODUCT_LINKLIST_REQUEST,
      successConstant: PRODUCT_LINKLIST_SUCCESS,
      url,
    });
  };
};

export const getAllProductListList = async () => {
  const url = '/Backend/ProductECLink/ecPlatformList?page=1&page_size=-1';
  return await request({
    method: 'get',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

//get product link detaiks
export const getProductLinkDetails = (
  id,
  keyword,
  brandId,
  model,
  page,
  page_size,
) => {
  const url = `/Backend/ProductECLink/ecProductList?ecId=${id}&page=${page}&page_size=${10}${
    keyword ? `&keyword=${keyword}` : ''
  }${brandId ? `&brandId=${brandId}` : ''}${model ? `&model=${model}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_LINK_DETAILS_FAIL,
      method: 'get',
      requestConstant: PRODUCT_LINK_DETAILS_REQUEST,
      successConstant: PRODUCT_LINK_DETAILS_SUCCESS,
      url,
    });
  };
};

//post product link
export const postProductLink = linkInfo => {
  const url = '/Backend/ProductECLink/productLink';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: linkInfo,
      dispatch,
      failConstant: PRODUCT_LINK_POST_FAIL,
      method: 'post',
      requestConstant: PRODUCT_LINK_POST_REQUEST,
      successConstant: PRODUCT_LINK_POST_SUCCESS,
      url,
    });
  };
};

//get product spec list
export const getProductSpecList = id => {
  const url = `/Backend/Product/spec/list?productId=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_SPEC_LIST_FAIL,
      method: 'get',
      requestConstant: PRODUCT_SPEC_LIST_REQUEST,
      successConstant: PRODUCT_SPEC_LIST_SUCCESS,
      url,
    });
  };
};

//post product spec
export const postProductSpec = formData => {
  const url = '/Backend/Product/spec';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: PRODUCT_SPEC_POST_FAIL,
      method: 'post',
      requestConstant: PRODUCT_SPEC_POST_REQUEST,
      successConstant: PRODUCT_SPEC_POST_SUCCESS,
      url,
    });
  };
};

//delete product spec
export const deleteProductSpec = (productId, id) => {
  const url = `/Backend/Product/spec?productId=${productId}&id=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_SPEC_DELETE_FAIL,
      method: 'delete',
      requestConstant: PRODUCT_SPEC_DELETE_REQUEST,
      successConstant: PRODUCT_SPEC_DELETE_SUCCESS,
      url,
    });
  };
};

export const getProductPics = pictures => {
  return { payload: pictures, type: GET_PRODUCTPIC };
};

export const getProductBg = color => {
  return { payload: color, type: GET_PRODUCTBG };
};
export const getProductFeatures = features => {
  return { payload: features, type: GET_PRODUCT_FEATURES };
};

export const updateProductOptionsState = ({ pId, data }) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: PRODUCT_OPTIONS_STATE_UPDATE_FAIL,
      method: 'post',
      requestConstant: PRODUCT_OPTIONS_STATE_UPDATE_REQUEST,
      successConstant: PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS,
      url: `/Backend/Model/UpdateProductOption/${pId}`,
    });
  };
};

export const getNewProductList = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: NEW_PRODUCT_LIST_FAIL,
      method: 'post',
      requestConstant: NEW_PRODUCT_LIST_REQUEST,
      successConstant: NEW_PRODUCT_LIST_SUCCESS,
      url: '/Backend/Model/AddList',
    });
  };
};

export const importProductStockFile = data => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Model/ImportProduct' });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: IMPORT_PRODUCT_STOCK_FILE_FAIL,
      method: 'post',
      requestConstant: IMPORT_PRODUCT_STOCK_FILE_REQUEST,
      successConstant: IMPORT_PRODUCT_STOCK_FILE_SUCCESS,
      url: '/Backend/Model/ImportProduct',
    });
  };
};

export const getColorOptions = params => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_COLOR_OPTIONS_FAIL,
      method: 'get',
      params: { modelId: params.modelId },
      requestConstant: GET_COLOR_OPTIONS_REQUEST,
      successConstant: GET_COLOR_OPTIONS_SUCCESS,
      url: `/Website/WarrantyRegister/coloroptions/${params.isWarranty}/${params.isRepair}`,
    });
  };
};

export const setProductStockStatus = data => {
  const url = '/Backend/ProductECLink/productStockStatus';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SET_PRODUCT_STOCK_STATUS_FAIL,
      method: 'post',
      requestConstant: SET_PRODUCT_STOCK_STATUS_REQUEST,
      successConstant: SET_PRODUCT_STOCK_STATUS_SUCCESS,
      url,
    });
  };
};

export const getBindingGiftsList = data => {
  const url = '/Backend/Freebie/AddFreebieList';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_BINDING_GIFTS_LIST_FAIL,
      method: 'post',
      requestConstant: GET_BINDING_GIFTS_LIST_REQUEST,
      successConstant: GET_BINDING_GIFTS_LIST_SUCCESS,
      url,
    });
  };
};

export const addGifts = data => {
  const url = '/Backend/Freebie/AddFreebieRel';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ADD_GIFTS_FAIL,
      method: 'post',
      requestConstant: ADD_GIFTS_REQUEST,
      successConstant: ADD_GIFTS_SUCCESS,
      url,
    });
  };
};

export const getModelWarrantyDateSetting = async (mid = 0) => {
  const url = `/Backend/BrandWarrantyDateSetting/GetModelWarrantyDateSetting/${mid}`;
  return await request({
    method: 'get',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const saveModelWarrantyDateSetting = async (
  data = {
    extendDays: 0,
    mid: 0,
    warrantyDays: 0,
  },
) => {
  const url = '/Backend/BrandWarrantyDateSetting/SaveModelWarrantyDateSetting';
  return await request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};
