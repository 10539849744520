import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Alert, Button, Card, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import {
  getCafeMealIntroZoneSeo,
  saveCafeMealIntroZoneSeo,
} from '../../redux/actions/data/uuCafeMealIntroAction';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';



const ZoneSEOSetting = () => {
  const { detail, loading, success, error } = useSelector(
    state => state.cafeMealInfoZoneSEO,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      desc: Yup.string().required('必填!'),
      desc_en: Yup.string().required('必填!'),
      keyword: Yup.string().required('必填!'),
      keyword_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        const params = {
          desc: props.values.desc,
          desc_en: props.values.desc_en,
          id: detail.id || 0,
          keyword: props.values.keyword,
          keyword_en: props.values.keyword_en,
          title: props.values.title,
          title_en: props.values.title_en,
        };
        dispatch(saveCafeMealIntroZoneSeo(params));
      }
    },
    [dispatch, detail],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        dispatch(getCafeMealIntroZoneSeo());
      }
      if (error) {
        toast.warning('修改失敗');
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    dispatch(getCafeMealIntroZoneSeo());
  }, []);

  if (!detail) {
    return <Loader />;
  }

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>語系設定說明</span>
        </div>
        <ul>
          <li>
            中文 (繁體)為前台的第一優先顯示語系，必填欄位之資訊不得為空值。
          </li>
          <li>其他非預設的語系若無填寫，前台將顯示中文 (繁體)的文案。</li>
        </ul>
      </Alert>
      <Formik
        enableReinitialize
        initialValues={{
          desc: detail.desc,
          desc_en: detail.desc_en,
          keyword: detail.keyword,
          keyword_en: detail.keyword_en,
          title: detail.title || '',
          title_en: detail.title_en || '',
          updateDateTime: detail.updateDateTime || '',
          updater: detail.updater || '',
        }}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <h4 className="title">商品標題(Title)</h4>
            <ul>
              <li>顯示於搜尋結果上的名稱，建議使用商品名稱</li>
              <li>建議輸入30個中文字以內</li>
            </ul>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*中文</label>
              <div className="form-item">
                <Field name="title" className="form-control" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*英文</label>
              <div className="form-item">
                <Field name="title_en" className="form-control" />
                <ErrorMessage
                  name="title_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <div style={{ minHeight: '30px' }}></div>
            <h4 className="title">關鍵字(Keyword)</h4>
            <ul>
              <li>
                建議輸入3-5組關鍵字，每組關鍵字之間請以,區隔，如：關鍵字1,關鍵字2,關鍵字3
              </li>
            </ul>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*中文</label>
              <div className="form-item fix-z-index">
                <Field name="keyword" className="form-control" />
                <ErrorMessage
                  name="keyword"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*英文</label>
              <div className="form-item fix-z-index">
                <Field name="keyword_en" className="form-control" />
                <ErrorMessage
                  name="keyword_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <div style={{ minHeight: '30px' }}></div>
            <h4 className="title">商品描述(Description)</h4>
            <ul>
              <li>顯示於搜尋結果的說明，建議輸入100個中文字以內</li>
            </ul>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*中文</label>
              <div className="form-item fix-z-index">
                <Field name="desc" className="form-control" />
                <ErrorMessage
                  name="desc"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*英文</label>
              <div className="form-item fix-z-index">
                <Field name="desc_en" className="form-control" />
                <ErrorMessage
                  name="desc_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <div style={{ minHeight: '150px' }}></div>
            <p className="fs-14">
              {props.values.updateDateTime} {props.values.updater}
            </p>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!(props.isValid && props.dirty)}
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '1rem' }}
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(ZoneSEOSetting);
