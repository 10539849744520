import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Input } from 'reactstrap';

import style from '../../styles/layout.module.css';


const Configurations = ({
  values,
  setFieldValue,
  errors,
  touched,
  onChange,
}) => {
  return (
    <div className="mb-5">
      <div className={style.formGroup}>
        <label className="required w150">
          贈品庫存
          {errors.stock && touched.stock && (
            <span className="ml-2 text-danger">{errors.stock}</span>
          )}
        </label>
        <Input
          type="number"
          className={style['form-select-shop-banner']}
          id="stock"
          value={values.stock}
          onChange={onChange}
          min={0}
          invalid={errors.stock && touched.stock}
        />
      </div>
      <div className={style.formGroup}>
        <label className="required w150">
          安全庫存量
          {errors.lowestStock && touched.lowestStock && (
            <span className="ml-2 text-danger">{errors.lowestStock}</span>
          )}
        </label>
        <Input
          type="number"
          className={style['form-select-shop-banner']}
          id="lowestStock"
          value={values.lowestStock}
          onChange={onChange}
          min={0}
          invalid={errors.lowestStock && touched.lowestStock}
        />
      </div>
      <div className={style.formGroup}>
        <label className="required w150">
          贈品狀態
          {errors.isSell && touched.isSell && (
            <span className="ml-2 text-danger">{errors.isSell}</span>
          )}
        </label>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            marginRight: '2rem',
          }}
        >
          <input
            type="radio"
            className={style['radio-btn']}
            id="isSell"
            value={true}
            checked={values.isSell === true || values.isSell === 'true'}
            onChange={onChange}
          />
          <span className="ml-2">開啟</span>
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          <input
            type="radio"
            className={style['radio-btn']}
            id="isSell"
            value={false}
            checked={values.isSell === false || values.isSell === 'false'}
            onChange={onChange}
          />
          <span className="ml-2">關閉</span>
        </div>
      </div>
      <div className={style.formGroup}>
        <label className="required w150">
          贈品上架時間
          {errors.startTime && touched.startTime && (
            <span className="ml-2 text-danger">{errors.startTime}</span>
          )}
        </label>
        <div className={style['form-select']}>
          <DatePicker
            className="form-control"
            dateFormat="yyyy/MM/dd HH:mm"
            showTimeSelect
            selected={values.startTime ? moment(values.startTime).toDate() : ''}
            onChange={date => {
              setFieldValue('startTime', date);
            }}
          />
        </div>
      </div>
      <div className={style.formGroup}>
        <label className="required w150">
          贈品下架時間
          {errors.endTime && touched.endTime && (
            <span className="ml-2 text-danger">{errors.endTime}</span>
          )}
        </label>
        <div className={style['form-select']}>
          <DatePicker
            className="form-control"
            dateFormat="yyyy/MM/dd HH:mm"
            showTimeSelect
            selected={values.endTime ? moment(values.endTime).toDate() : ''}
            onChange={date => {
              setFieldValue('endTime', date);
            }}
            minDate={values.startTime ? moment(values.startTime).toDate() : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Configurations;
