import {
  LOWEST_NOTIFY_INFO_REQUEST,
  LOWEST_NOTIFY_INFO_SUCCESS,
  LOWEST_NOTIFY_INFO_FAIL,
  LOWEST_NOTIFY_UPDATE_REQUEST,
  LOWEST_NOTIFY_UPDATE_SUCCESS,
  LOWEST_NOTIFY_UPDATE_FAIL,
} from 'redux/constants/data/lowestNotifyConstants';
import { useRequest } from 'utils/useFetch';
export const getLowestNotifyInfo = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: LOWEST_NOTIFY_INFO_FAIL,
      method: 'get',
      requestConstant: LOWEST_NOTIFY_INFO_REQUEST,
      successConstant: LOWEST_NOTIFY_INFO_SUCCESS,
      url: '/Backend/LowestNotify/getLowestNotifyInfo',
    });
  };
};
export const updateLowestNotify = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: LOWEST_NOTIFY_UPDATE_FAIL,
      method: 'post',
      requestConstant: LOWEST_NOTIFY_UPDATE_REQUEST,
      successConstant: LOWEST_NOTIFY_UPDATE_SUCCESS,
      url: '/Backend/LowestNotify/updateRepairInfo',
    });
  };
};
