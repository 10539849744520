import {
  GET_CAPTCHA_CODE_FAIL,
  GET_CAPTCHA_CODE_REQUEST,
  GET_CAPTCHA_CODE_SUCCESS,
  GET_CONTACT_US_FAIL,
  GET_CONTACT_US_REQUEST,
  GET_CONTACT_US_SUCCESS,
  UPDATE_CAPTCHA_CODE_FAIL,
  UPDATE_CAPTCHA_CODE_REQUEST,
  UPDATE_CAPTCHA_CODE_SUCCESS,
  UPDATE_CONTACT_US_FAIL,
  UPDATE_CONTACT_US_REQUEST,
  UPDATE_CONTACT_US_SUCCESS,
} from 'redux/constants/data/websiteSettingsContants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getCaptchaCode = () => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WebsiteSetting/getMemberCodeInfo',
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_CAPTCHA_CODE_FAIL,
      method: 'get',
      requestConstant: GET_CAPTCHA_CODE_REQUEST,
      successConstant: GET_CAPTCHA_CODE_SUCCESS,
      url: '/Backend/WebsiteSetting/getMemberCodeInfo',
    });
  };
};

export const updateCaptchaCode = () => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/WebsiteSetting/updateMemberCodeInfo',
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: UPDATE_CAPTCHA_CODE_FAIL,
      method: 'get',
      requestConstant: UPDATE_CAPTCHA_CODE_REQUEST,
      successConstant: UPDATE_CAPTCHA_CODE_SUCCESS,
      url: '/Backend/WebsiteSetting/updateMemberCodeInfo',
    });
  };
};

export const getContactUsContext = () => {
  const url = '/Backend/WebsiteSetting/getContactUs';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_CONTACT_US_FAIL,
      method: 'get',
      requestConstant: GET_CONTACT_US_REQUEST,
      successConstant: GET_CONTACT_US_SUCCESS,
      url,
    });
  };
};

export const updateContactUsContext = data => {
  const url = '/Backend/WebsiteSetting/updateContactUs';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_CONTACT_US_FAIL,
      method: 'post',
      requestConstant: UPDATE_CONTACT_US_REQUEST,
      successConstant: UPDATE_CONTACT_US_SUCCESS,
      url,
    });
  };
};
