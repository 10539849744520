import Layout from 'components/Layout';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Nav, TabContent, TabPane } from 'reactstrap';


import { TabItem } from '../components/tabItem';
import OtherProductPageEditor from '../components/uuCafeMealIntroProduct/OtherProductPageEditor';
import OtherProductSEOSetting from '../components/uuCafeMealIntroProduct/OtherProductSEOSetting';
import OtherProducts from '../components/uuCafeMealIntroProduct/OtherProducts';

const CafeMealIntroOtherProduct = () => {
  const params = useParams();
  const [currentTab, setCurrentTab] = useState(1);
  const { detail } = useSelector(state => state.cafeMealInfoOther);

  return (
    <Layout
      pageTitle={`${detail.name}-商品設定`}
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: `${detail.name}-商品設定` },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="商品設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="頁面設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={3}
          text="SEO設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <OtherProducts menuId={params.id} />
        </TabPane>
        <TabPane tabId={2}>
          <OtherProductPageEditor menuId={params.id} />
        </TabPane>
        <TabPane tabId={3}>
          <OtherProductSEOSetting menuId={params.id} />
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default CafeMealIntroOtherProduct;
