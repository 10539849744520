import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderReturnDescModal from 'components/OrderReturnDescModal';
import { BlockSpinner } from 'components/Spinner';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import { orderReturnFinished } from 'redux/actions/data/orderReturnActions';
import { ORDER_RETURN_FINISHED_IDLE } from 'redux/constants/data/orderReturnConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const RejectModal = ({ open, toggle, ids, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit, handleReset, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        returnStatusReason: '',
      },
      async onSubmit(formModel) {
        setLoading(true);
        const { code, message } = await request({
          data: { ...formModel, orderItemIds: ids },
          method: 'post',
          url: '/Backend/OrderReturn/LogisticsRejectFinish_v2',
        });
        setLoading(false);
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('修改成功！');
        handleReset();
        toggle();
        onSubmit && onSubmit();
      },
      validationSchema: Yup.object().shape({
        returnStatusReason: Yup.string().required('必填'),
      }),
    });
  return (
    <div className={style.modal}>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={open}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className={style.modal_head}>
          不予退貨
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          {loading && <BlockSpinner />}
          <Form>
            <FormGroup row>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                原因
              </Label>
              <Col>
                <Input
                  type="textarea"
                  rows={5}
                  name="returnStatusReason"
                  onChange={handleChange}
                  value={values.returnStatusReason}
                />
                {touched.returnStatusReason && (
                  <FormText color="danger" className="d-block">
                    {errors.returnStatusReason}
                  </FormText>
                )}
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggle}
            disabled={loading}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const RepayModal = ({ open, toggle, currentOrder }) => {
  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit, handleReset, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        amount: 0,
        items: '',
        returnId: currentOrder.returnId,
      },
      async onSubmit(formModel) {
        if (isNaN(Number(formModel.amount)) || !formModel.amount) {
          MessageUtil.alertWanring('補收金額請輸入正確數值');
          return;
        }
        setLoading(true);
        const { code, message } = await request({
          data: formModel,
          method: 'post',
          url: '/Backend/OrderReturn/AddOrderRecharge',
        });
        setLoading(false);
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('建立成功！');
        handleReset();
        toggle();
      },
      validationSchema: Yup.object().shape({
        amount: Yup.number()
          .min(0, '請輸入大於0的數字')
          .max(currentOrder.actualAmount, '補收金額不能大於退貨金額'),
        items: Yup.string().required('必填'),
      }),
    });
  return (
    <div className={style.modal}>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={open}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className={style.modal_head}>
          補收單
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          {loading && <BlockSpinner />}
          <Form>
            <FormGroup row>
              <Label style={{ width: 150 }}>退貨單號</Label>
              <Col>{currentOrder.returnNumber || '--'}</Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>訂單編號</Label>
              <Col>{currentOrder.orderItemNumber}</Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>退貨商品名稱</Label>
              <Col>{currentOrder.productname}</Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>退貨金額</Label>
              <Col>
                <span className="price fw-normal text-dark">
                  {currentOrder.finalPrice?.toLocaleString('en-US')}
                </span>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                補收項目
              </Label>
              <Col>
                <Input
                  type="select"
                  name="items"
                  onChange={handleChange}
                  value={values.items}
                >
                  <option value="" disabled>
                    請選擇
                  </option>
                  <option value="運費">運費</option>
                  <option value="贈品">贈品</option>
                  <option value="包裝/整新費">包裝/整新費</option>
                  <option value="活動/折扣差價">活動/折扣差價</option>
                </Input>
                <div className="form-error">{errors.items}</div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                補收金額
              </Label>
              <Col>
                <Input
                  type="number"
                  name="amount"
                  onChange={handleChange}
                  value={values.amount}
                />
                <div className="form-error">{errors.amount}</div>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggle}
            disabled={loading}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            disabled={loading || Object.keys(errors).length}
            onClick={handleSubmit}
          >
            {loading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const TableList = ({
  onAction,
  onReturnDelivery,
  onReject,
  onChangePage,
  setIsDiscountActivityModalOpen,
  setIsDiscountModalOpen,
  setCurrentDiscountInfo,
}) => {
  const { loading, orderListInfo } = useSelector(state => state.orderList);

  const [modalOpen, setModalOpen] = useState(false);
  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const { values, handleSubmit, setFieldValue, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
    async onSubmit(formModel, { resetForm }) {
      onAction && onAction(formModel, resetForm);
    },
  });

  const [repayModalOpen, setRepayModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({});
  const handleToggleRepayModal = () => {
    setRepayModalOpen(!repayModalOpen);
  };

  const [descOpen, setDescOpen] = useState(false);
  const [currentDescInfo, setCurrentDescInfo] = useState(null);
  const handleToggleDesc = () => {
    setDescOpen(!descOpen);
  };

  const { loading: returnLoading } = useSelector(
    state => state.orderReturnFinished,
  );

  const flatList = orderListInfo?.datas?.map(i => i.orderItems).flat();

  /*
  const handleCheckEvent = useCallback(
    async (orderItems = [], isChecked) => {
      try {
        const res = await checkReturnOrderItemPair({
          items: [
            ...values.list.filter(
              i =>
                orderItems.every(orderItem => orderItem.id !== i.id) &&
                i.orderMasterId === orderItems[0].orderMasterId,
            ),
            ...orderItems.map(orderItem => {
              return {
                ...orderItem,
                freebieRelatedNumbers: orderItem.freebieRelatedNumbers,
                isSelected: isChecked,
                orderItemId: orderItem.id,
                productId: orderItem.productId,
                productType: orderItem.productType,
                quantity: orderItem.quantity,
                returnReason: '',
                unitPrice: orderItem.unitPrice,
              };
            }),
          ].map(o => {
            return {
              id: o.orderItemId || o.id,
              isSelected: o.isSelected,
              returnQty: o.quantity,
            };
          }),
          orderMasterId: orderItems[0].orderMasterId,
          orderNumber: orderItems[0].orderItemNumber,
        });

        if (res?.result?.outputItems) {
          const itemMap = {};
          res.result.outputItems.forEach(i => {
            itemMap[i.id] = {
              ...values?.list?.find(o => o.orderItemId === i.id),
              ...i,
            };
            if (
              orderItems.every(orderItem => orderItem.id !== i.orderItemId) &&
              !values.list.find(o => o.id === i.id)
            ) {
              itemMap[i.id].isSelected = i.isSelected;
              itemMap[i.id].quantity = i.returnQty;
              itemMap[i.id].appendId = orderItems[0].id;
              itemMap[
                i.id
              ].message = `與${orderItems[0].orderItemNumber}同折扣活動，須整組退貨`;
              itemMap[i.id].disabled = true;
            }
          });
          const checkItems = flatList
            .filter(l => {
              return itemMap[l.id] && itemMap[l.id].isSelected;
            })
            .map(l => {
              return {
                orderItemId: l.id,
                ...l,
                ...itemMap[l.id],
              };
            });

          setFieldValue('list', [
            ...checkItems,
            ...values.list.filter(
              l => l.orderMasterId !== orderItems[0].orderMasterId,
            ),
          ]);
        }
      } catch (e) {
        MessageUtil.alertWanring(e);
      }
    },
    [values?.list, flatList],
  );
*/

  const handleCheckEvent = useCallback(
    async (orderItems = [], isChecked) => {
      if (isChecked) {
        const checkItems = flatList
          .filter(l => {
            return orderItems.some(o => o.id === l.id);
          })
          .map(l => {
            return {
              orderItemId: l.id,
              ...l,
            };
          });
        setFieldValue('list', [
          ...checkItems,
          ...values.list.filter(
            l => l.orderMasterId !== orderItems[0].orderMasterId,
          ),
        ]);
      } else {
        setFieldValue('list', [
          ...values.list.filter(
            l => l.orderMasterId !== orderItems[0].orderMasterId,
          ),
        ]);
      }
    },
    [values?.list, flatList],
  );

  return (
    <Card className={style.card}>
      <div className="mb-3">
        <Button
          className="me-2"
          color="success"
          onClick={handleSubmit}
          disabled={values.list.length <= 0 || returnLoading}
        >
          {returnLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          完成退貨
        </Button>
        <Button
          color="success"
          disabled={values.list.length <= 0}
          onClick={() => setModalOpen(true)}
        >
          不予退貨
        </Button>
      </div>
      <div
        className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
      >
        <div className="d-flex align-items-center gap-1">
          <input
            className={style.maintain_list_checkbox}
            type="checkbox"
            checked={
              values.list.length > 0 && values.list.length === flatList.length
            }
            name="all"
            onChange={e => {
              const val = e.target.checked;
              setFieldValue('list', val ? flatList : []);
            }}
          />
          本頁全選
        </div>
      </div>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th> 選擇</th>
              <th>
                <div>訂單編號</div>
                <div>退貨單號</div>
              </th>
              <th>
                <div>配送商</div>
                <div>配送編號</div>
              </th>

              <th>
                <div>退貨單狀態</div>
                <div>退貨成立時間</div>
                <div>退貨狀態時間</div>
              </th>
              <th>
                <div>退貨原因</div>
                <div>退貨描述</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>總折扣金額</div>
                <div>總銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>退貨聯絡人</div>
                <div>退貨聯絡人電話</div>
              </th>
              <th>
                <div>退貨國家</div>
                <div>退貨郵遞區號</div>
                <div>退貨城市</div>
                <div>退貨鄉鎮市區</div>
                <div>退貨地址</div>
              </th>
              <th>功能</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {orderListInfo?.datas?.length > 0 ? (
                orderListInfo?.datas?.map(i => (
                  <React.Fragment key={JSON.stringify(i)}>
                    <tr className="text-nowrap bg">
                      <td>
                        <input
                          className={style.maintain_list_checkbox}
                          type="checkbox"
                          onChange={e => {
                            const val = e.target.checked;
                            handleCheckEvent(i.orderItems, val);
                          }}
                          checked={i.orderItems.every(j =>
                            values.list.some(k => k.id === j.id),
                          )}
                        />
                      </td>

                      <td>
                        總金額：
                        <span className="price text-black">
                          {(i.totalAmount || '0')?.toLocaleString('en-US')}
                        </span>
                      </td>
                      <td colSpan="2">主單編號：{i.orderNumber}</td>
                      <td colSpan="2">
                        <div>
                          運費：
                          <span className="price text-black">
                            {i.deliveryCharge?.toLocaleString('en-US')}
                          </span>
                          {i.deliveryChargeReturnNumber && (
                            <span>
                              （退運費申請 -
                              <span className={style.text_dark_info}>
                                {i.deliveryChargeReturnNumber}
                              </span>
                              )
                            </span>
                          )}
                        </div>
                      </td>
                      <td colSpan={5}>
                        <div className="d-flex align-items-center">
                          {i.deliveryCharge > 0 &&
                            !i.deliveryChargeReturnNumber && (
                              <Button
                                color="success"
                                outline
                                className="bg-white text-success"
                                onClick={() => onReturnDelivery(i)}
                              >
                                運費退款
                              </Button>
                            )}
                          <FaChevronDown
                            id={`orderId${i.id}`}
                            className="ms-auto"
                          />
                        </div>
                      </td>
                    </tr>
                    {i.orderItems.map((j, jIndex) => (
                      <tr key={jIndex}>
                        <td>
                          <input
                            className={style.maintain_list_checkbox}
                            type="checkbox"
                            disabled={true}
                            checked={values.list.some(k => k.id === j.id)}
                            onChange={e => {
                              const val = e.target.checked;
                              handleCheckEvent([j], val);
                            }}
                          />
                        </td>
                        <td>
                          <div className={style.text_dark_info}>
                            {j.orderItemNumber}
                          </div>
                          <div>{j.returnNumber || '--'}</div>
                        </td>
                        <td>
                          <div>{j.returnHomeDeliveryCName || '--'}</div>
                          <div>{j.returnHomeDeliveryNumber || '--'}</div>
                        </td>
                        <td>
                          <div>{j.returnStatusName}</div>
                          <div>{j.returnCreateTime}</div>
                          <div>{j.returnStatusTime}</div>
                        </td>
                        <td>
                          <div>{j.returnReason || '--'}</div>
                          <div className="text-clamp clamp3">
                            {j.returnDesc || '--'}
                          </div>
                          {j.returnDesc && (
                            <Button
                              color="link"
                              className="text-decoration-none"
                              size="sm"
                              onClick={() => {
                                setCurrentDescInfo({
                                  returnDesc: j.returnDesc,
                                  returnReason: j.returnReason,
                                });
                                handleToggleDesc();
                              }}
                            >
                              查看完整描述
                            </Button>
                          )}
                        </td>
                        <td>
                          {/* 商品名稱 */}
                          <div>{j.productname}</div>
                          {/* 商品選項 */}
                          <div>{`${j.color || '--'}/${j.size || '--'}`}</div>
                          {/* 商品料號 */}
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '16px',
                            }}
                            onClick={() => {
                              window.open(
                                `/#/ProductControl?page=1&keyword=${j.productname}`,
                                '_blank',
                              );
                            }}
                          >
                            {j.productcode || '--'}
                          </div>
                          {/* 商品屬性 */}
                          <div>
                            {Number(j.producrType) === 4 ? '贈品' : '商品'}
                          </div>
                        </td>
                        <td className="text-end">
                          {/* 數量 */}
                          <div>{j.quantity}</div>
                          {/* 商品單價 */}
                          <div className="price text-black fw-normal">
                            {j.unitPrice?.toLocaleString('en-US')}
                          </div>
                          {/* 折扣金額 */}
                          <div
                            className="d-flex justify-content-end text-primary"
                            role="button"
                            onClick={() => {
                              modal.showBillInfo({
                                ...j,
                                orderNumber: i.orderNumber,
                              });
                            }}
                          >
                            -
                            <div className="price text-primary fw-normal discountAmount">
                              {j?.discountAmount?.toLocaleString('en-US') ||
                                0}
                            </div>
                          </div>
                          {/* 銷售金額(折扣後) */}
                          <div className="price text-black fw-normal discountPrice">
                            {(j.discountPrice || '0')?.toLocaleString('en-US')}
                          </div>
                        </td>
                        <td className="text-end">
                          {/* 有無商品贈品 */}
                          <div>--</div>
                          {/* 有無活動贈品 */}
                          <div>--</div>
                          {/* 折扣活動總折扣金額 */}
                          <div
                            className="d-flex justify-content-end"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setCurrentDiscountInfo(j.id);
                              setIsDiscountActivityModalOpen();
                            }}
                          >
                            -
                            <div
                              className={`price fw-normal ${style.text_dark_info}`}
                            >
                              {(
                                j.activityDiscountAmountUnit || '0'
                              ).toLocaleString('en-US')}
                            </div>
                          </div>
                          {/* 折價券總折扣金額 */}
                          <div
                            className="d-flex justify-content-end"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setCurrentDiscountInfo(j.id);
                              setIsDiscountModalOpen();
                            }}
                          >
                            -
                            <div
                              className={`price fw-normal ${style.text_dark_info}`}
                            >
                              {(
                                j.couponDiscountAmountUnit || '0'
                              ).toLocaleString('en-US')}
                            </div>
                          </div>
                          {/* 贈品券活動序號 */}
                          <div>--</div>
                        </td>
                        <td>
                          <div className="text-nowrap">{j.memberPhone}</div>
                          <div>{j.memberLevel}</div>
                          <div>{j.returnName}</div>
                          <div>{j.returnPhone}</div>
                        </td>
                        <td>
                          <div>{j.returnCountryName || '--'}</div>
                          <div>{j.returnZipcode || '--'}</div>
                          <div>{j.returnCityName || '--'}</div>
                          <div>{j.returnTownName || '--'}</div>
                          <div>{j.returnAddress || '--'}</div>
                        </td>
                        <td>
                          <Button
                            color="success"
                            outline
                            onClick={() => {
                              setRepayModalOpen(true);
                              setCurrentOrder(j);
                            }}
                          >
                            建立補收單
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={orderListInfo?.current_page || 1}
          pageSize={orderListInfo?.page_size || 10}
          total={orderListInfo?.total_count || 0}
          onChange={onChangePage}
        />
      </div>
      <RejectModal
        open={modalOpen}
        toggle={handleToggleModal}
        ids={values.list.map(o => o.id)}
        onSubmit={() => {
          onReject();
          handleReset();
        }}
      />
      <RepayModal
        open={repayModalOpen}
        toggle={handleToggleRepayModal}
        currentOrder={currentOrder}
      />
      <OrderReturnDescModal
        open={descOpen}
        toggle={handleToggleDesc}
        info={currentDescInfo}
      />
    </Card>
  );
};
const OrderReturnStep3 = () => {
  const dispatch = useDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
    useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      isReturnOrder: true,
      keyword: '',
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      returnLogisticStatus: 2,
      returnStatus: 2,
      startTime: null,
      timeType: '退貨單成立日期',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
          ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
          ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
          ? JSON.parse(query.value.endTime)
          : moment().format('yyyy/MM/DD HH:mm:ss'),
        isReturnOrder: true,
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
          ? JSON.parse(query.value.startTime)
          : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const handleAction = async (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        dispatch(
          orderReturnFinished(
            formModel.list
              .map(o => o.orderMasterId)
              .filter((id, index, arr) => {
                return arr.indexOf(id) === index;
              }),
          ),
        );
        cb();
      },
      title: '確認完成退貨？',
    });
  };

  const { success: returnSuccess, error: returnError } = useSelector(
    state => state.orderReturnFinished,
  );

  useEffect(() => {
    if (returnSuccess) {
      MessageUtil.toastSuccess('完成退貨！');
      handleSubmit();
      dispatch({ type: ORDER_RETURN_FINISHED_IDLE });
    }

    if (returnError) {
      MessageUtil.alertWanring(returnError);
      handleSubmit();
      dispatch({ type: ORDER_RETURN_FINISHED_IDLE });
    }
  }, [returnSuccess, returnError]);

  const handleReturnDelivery = item => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          method: 'get',
          url: `/Backend/OrderReturn/AddOrderDeliveryChargeReturn?returnId=${item.orderItems[0].returnId}`,
        });
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('送出成功！');
        handleSubmit();
      },
      text: '確認後即不能再修改',
      title: '確認要退運費？',
    });
  };
  return (
    <Layout
      pageTitle="退貨處理中-setp3"
      items={[
        { isActive: false, page_name: '退貨訂單管理' },
        { isActive: true, page_name: '退貨處理中-setp3' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            本頁僅出現物流狀態為【取件成功】的訂單
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            【完成退貨】後，即進入退款流程，以【主單】為單位，退款金額-補收單金額(補收單)+運費(運費退款申請單)=退款總金額
          </span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  name="keyword"
                  placeholder="會員電話、收件人、收件人電話"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-medium']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                  <option value="退貨單編號">退貨單編號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  日期區間
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  name="timeType"
                  onChange={handleChange}
                  value={values.timeType}
                  style={{ margin: 0 }}
                >
                  <option value="退貨單成立日期">退貨單成立日期</option>
                  <option value="退貨單狀態日期">退貨單狀態日期</option>
                </Input>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.startTime
                      ? moment(values.startTime).toDate()
                      : moment().subtract(7, 'day').toDate()
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                  selectsStart
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.endTime
                      ? moment(values.endTime).toDate()
                      : moment().toDate()
                  }
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  selectsEnd
                  minDate={values.startTime}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <TableList
        onAction={handleAction}
        onReject={handleSubmit}
        onReturnDelivery={handleReturnDelivery}
        onChangePage={handleChangePage}
        setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
        setIsDiscountModalOpen={setIsDiscountModalOpen}
        setCurrentDiscountInfo={setCurrentDiscountInfo}
      />
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={setIsDiscountModalOpen}
          id={currentDiscountInfo}
        />
      )}
      {isDiscountActivityModalOpen && (
        <DiscountActivityDetailModal
          isOpen={isDiscountActivityModalOpen}
          toggle={setIsDiscountActivityModalOpen}
          id={currentDiscountInfo}
        />
      )}
    </Layout>
  );
};
export default OrderReturnStep3;
