export const UUMODELREL_LIST_REQUEST = 'UUMODELREL_LIST_REQUEST';
export const UUMODELREL_LIST_SUCCESS = 'UUMODELREL_LIST_SUCCESS';
export const UUMODELREL_LIST_FAIL = 'UUMODELREL_LIST_FAIL';

export const UUMODELREL_SELECT_MODELS_REQUEST =
  'UUMODELREL_SELECT_MODELS_REQUEST';
export const UUMODELREL_SELECT_MODELS_SUCCESS =
  'UUMODELREL_SELECT_MODELS_SUCCESS';
export const UUMODELREL_SELECT_MODELS_FAIL = 'UUMODELREL_SELECT_MODELS_FAIL';

export const UUMODELREL_EDIT_UUMODEL_REQUEST =
  'UUMODELREL_EDIT_UUMODEL_REQUEST';
export const UUMODELREL_EDIT_UUMODEL_SUCCESS =
  'UUMODELREL_EDIT_UUMODEL_SUCCESS';
export const UUMODELREL_EDIT_UUMODEL_FAIL = 'UUMODELREL_EDIT_UUMODEL_FAIL';
export const UUMODELREL_EDIT_UUMODEL_RESET = 'UUMODELREL_EDIT_UUMODEL_RESET';

export const UUMODELREL_SAVE_MODELS_REQUEST = 'UUMODELREL_SAVE_MODELS_REQUEST';
export const UUMODELREL_SAVE_MODELS_SUCCESS = 'UUMODELREL_SAVE_MODELS_SUCCESS';
export const UUMODELREL_SAVE_MODELS_FAIL = 'UUMODELREL_SAVE_MODELS_FAIL';
export const UUMODELREL_SAVE_MODELS_RESET = 'UUMODELREL_SAVE_MODELS_RESET';

export const UUMODELREL_DELETE_UUMODEL_REQUEST =
  'UUMODELREL_DELETE_UUMODEL_REQUEST';
export const UUMODELREL_DELETE_UUMODEL_SUCCESS =
  'UUMODELREL_DELETE_UUMODEL_SUCCESS';
export const UUMODELREL_DELETE_UUMODEL_FAIL = 'UUMODELREL_DELETE_UUMODEL_FAIL';
export const UUMODELREL_DELETE_UUMODEL_RESET =
  'UUMODELREL_DELETE_UUMODEL_RESET';
