import SEOSettingsConfig from 'components/SEOSettingsConfig';
import ProductCategorySelection from 'components/productDetail/ProductCategorySelection';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import {
  deleteModelSpec,
  editModelDesc,
  editModelOrder,
  editModelSetting,
  editModelSpec, editModelSpecRequest,
  getModelBrandOptions,
  getModelProductOptions,
  getModelProfile,
} from 'redux/actions/data/modelActions';
import { getSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';
import { emptyOption, formatImagesArr } from 'utils';
import * as Yup from 'yup';

import CustomColorPicker from '../components/CustomColorPicker';
import Layout from '../components/Layout';
import ProductImgPicker from '../components/ProductImgPicker';
import ProductIntro from '../components/ProductIntro';
import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import WarrantyPeriodSetting from '../components/productDetail/WarrantyPeriodSetting';
import {
  MODEL_DELETE_SPEC_RESET,
  MODEL_EDIT_DESC_RESET,
  MODEL_EDIT_ORDER_RESET,
  MODEL_EDIT_SETTING_RESET,
  MODEL_EDIT_SPEC_RESET,
} from '../redux/constants/data/modelConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        } ${tab === 4 ? style.modal_button_submit_extend : ''}`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};
const TabDesc = ({ profile, id }) => {
  const dispatch = useDispatch();
  const {
    loading: submitLoading,
    success,
    error,
  } = useSelector(state => state.modelEditDesc);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功');
      dispatch(getModelProfile(id));
    }
    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
    }
    return () => {
      dispatch({ type: MODEL_EDIT_DESC_RESET });
    };
  }, [success, error]);

  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        bgColor: profile?.desc?.bgColor || '#161616',
        fontColor: profile?.desc?.fontColor || 'white',
        introImgFiles: [],
        introImgFiles_en: [],
        intros: profile?.desc?.introdutions,
      },
      onSubmit: values => {
        if (Object.keys(errors).length > 0) {
          const message = CommonUtil.getFormVaildationErrorMessages(errors);
          MessageUtil.alertErrorsWithHTML('請填寫完整資料', message);
        } else {
          const params = {
            ...values,
            intros: JSON.stringify(
              values.intros
                .map((i, index) => {
                  const obj = {
                    content: i.get('content'),
                    content_en: i.get('content_en'),
                    height: i.get('height') || 0,
                    id: i.get('id'),
                    image: i.get('imageUrl'),
                    image_en: i.get('imageUrl_en'),
                    isChangeFile: i.get('isUpload'),
                    isRemoveFile: false,
                    isRemoveFile_en: false,
                    sort: i.get('sort') ? i.get('sort') : index + 1, //沒有拖拉排序就要有此默認值
                    title: i.get('title'),
                    title_en: i.get('title_en'),
                    type: i.get('type'),
                    youtubeLink: i.get('youtubeLink'),
                  };
                  obj.imgFormFileId = values.introImgFiles
                    ? values.introImgFiles.indexOf(
                        values.introImgFiles.find(i => i.id === obj.id),
                      )
                    : -1;
                  obj.imgFormFileId_en = values.introImgFiles_en
                    ? values.introImgFiles_en.indexOf(
                        values.introImgFiles_en.find(i => i.id === obj.id),
                      )
                    : -1;
                  return obj;
                })
                .sort((a, b) => {
                  return a.sort - b.sort;
                }),
            ),
          };
          const formData = new FormData();
          Object.keys(params).forEach(i => {
            i !== 'introImgFiles' &&
              i !== 'introImgFiles_en' &&
              formData.append(i, params[i]);
          });
          values.introImgFiles?.length > 0 &&
            values.introImgFiles.forEach(i => {
              formData.append('introImgFiles', i);
            });
          values.introImgFiles_en?.length > 0 &&
            values.introImgFiles_en.forEach(i => {
              formData.append('introImgFiles_en', i);
            });

          dispatch(editModelDesc({ id, params: formData }));
        }
      },
      validateOnChange: true,
      validateOnMount: true,
      validationSchema: Yup.object().shape({
        bgColor: Yup.string()
          .test('len', '必填', val => val.replace('#', '').length > 0)
          .required('必填'),
        fontColor: Yup.string().required('必填'),
      }),
    });

  return (
    <Card className={style.card}>
      <form>
        <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
          <label className="required" style={{ width: '140px' }}>
            商品介紹背景顏色
            {errors.bgColor && <div className="form-error"> 必填！</div>}
          </label>
          <div className="form-item">
            <CustomColorPicker
              initColor={profile?.desc?.bgColor || '161616'}
              formFieldName="bgColor"
              setFormFieldValue={setFieldValue}
            />
          </div>
        </div>
        <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
          <label
            className="required"
            style={{ whiteSpace: 'normal', width: '140px' }}
          >
            商品介紹 / 銷售標題文字顏色
            {errors.fontColor && <span className="form-error"> 必填！</span>}
          </label>
          <div className="d-flex form-item align-items-center ">
            <div className="d-flex gap-3">
              <Label className="d-flex gap-1">
                <input
                  name="fontColor"
                  type="radio"
                  value="white"
                  onChange={handleChange}
                  checked={values.fontColor === 'white'}
                />
                白色
              </Label>
              <Label className="d-flex gap-1">
                <input
                  name="fontColor"
                  type="radio"
                  value="black"
                  onChange={handleChange}
                  checked={values.fontColor === 'black'}
                />
                黑色
              </Label>
            </div>
          </div>
        </div>
        <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
          <label style={{ width: '140px' }}>商品介紹</label>
          <div className="d-flex form-item align-items-center ">
            <ProductIntro
              setFormFieldValue={setFieldValue}
              initContents={profile?.desc?.introdutions}
              onChange={value => setFieldValue('intros', value)}
              name="intros"
              formFieldName="introImgFiles"
              formFieldNameEn="introImgFiles_en"
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-center">
          <Button
            className={style.modal_button_submit}
            disabled={submitLoading}
            onClick={handleSubmit}
          >
            {submitLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="btn-wrapper--label">儲存</span>
          </Button>
        </div>
      </form>
    </Card>
  );
};
const TabSpec = ({ profile, id, handleRefresh }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
    setSpecId(CommonUtil.getNewID());
    setTitle('');
    setTitle_en('');
    setContent('');
    setContent_en('');
    setSort(null);
  };

  const [specId, setSpecId] = useState('');
  const [title, setTitle] = useState('');
  const [title_en, setTitle_en] = useState('');
  const [content, setContent] = useState('');
  const [content_en, setContent_en] = useState('');
  const [sort, setSort] = useState(null);

  const { success: addSuccess, error: addError } = useSelector(
    state => state.modelEditSpec,
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.modelDeleteSpec,
  );

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      handleRefresh();
      toggleModal();
    }

    if (addError) {
      MessageUtil.alertWanring('新增失敗', addError);
    }

    return () => {
      dispatch({ type: MODEL_EDIT_SPEC_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      handleRefresh();
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: MODEL_DELETE_SPEC_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleSubmit = values => {
    const formData = new FormData();
    formData.append('productId', id);
    // formData.append('id', specId === 'null' || specId === null ? CommonUtil.getNewID() : specId === 'undefined' ? CommonUtil.getNewID());
    let guid = null;
    switch (specId) {
      case undefined:
      case null:
      case 'undefined':
      case 'null':
        guid = CommonUtil.getNewID();
        break;
      default:
        guid = specId;
        break;
    }

    // formData.append('sort', values.title === );
    formData.append('id', guid);
    formData.append('title', values.title);
    formData.append('title_en', values.title_en);
    formData.append('content', values.content);
    formData.append('content_en', values.content_en);
    dispatch(editModelSpec({ id, params: formData }));
  };
  const handleOpenEditModal = data => {
    setIsOpen(!isOpen);
    setSpecId(data.id);
    setTitle(data.title);
    setTitle_en(data.title_en);
    setContent(data.content);
    setContent_en(data.content_en);
    setSort(data['sort']);
  };

  const callDel = specId => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteModelSpec({ id: specId, mid: id }));
    });
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });

    return schema;
  };

  const updateSort = async (values) => {
    const formData = new FormData();
    formData.append('id', values.id || null);
    formData.append('title', values.title);
    formData.append('title_en', values.title_en);
    formData.append('content', values.content);
    formData.append('content_en', values.content_en);
    formData.append('sort', values.sort);
    try{
      await editModelSpecRequest({ id, params: formData });
    }catch (e){

    }
  };
  return (
    <Card className={style.card}>
      <Button className="btn btn-success w100" onClick={toggleModal}>
        新增
      </Button>
      <div className="d-flex justify-content-end">
        共
        <span className="mx-1">
          {profile?.spec?.list !== null ? profile?.spec?.list.length : 0}
        </span>
        筆
      </div>
      <div className="table-responsive">
        <Table hover bordered striped>
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>#</th>
              <th>標題</th>
              <th>說明</th>
              <th>功能</th>
            </tr>
          </thead>
          <tbody>
            {profile?.spec?.list?.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="number"
                    defaultValue={data.sort}
                    onChange={e => {
                      updateSort({
                        ...data,
                        sort: e.target.value,
                      });
                    }}
                    className="form-control"
                    style={{
                      maxWidth: '100px',
                    }}
                  />
                </td>
                <td>{data.title}</td>
                <td>{data.content}</td>
                <td>
                  <Button
                    className="m-2"
                    type="button"
                    color="primary"
                    outline
                    size="sm"
                    onClick={() => handleOpenEditModal(data)}
                  >
                    編輯
                  </Button>
                  {data.title !== '商品重量' &&
                    data.title !== '商品材積(長X寬X高)' && (
                      <Button
                        className="m-2"
                        type="button"
                        color="danger"
                        size="sm"
                        outline
                        onClick={() => callDel(data.id)}
                      >
                        刪除
                      </Button>
                    )}
                </td>
              </tr>
            ))}
            {
              // 預設商品材積
              !profile?.spec?.list?.find(
                data => data.title === '商品材積(長X寬X高)',
              ) && (
                <tr>
                  <td>
                    <input
                        type="number"
                        defaultValue={1}
                        onChange={e => {
                          updateSort({
                            content: '',
                            content_en: '',
                            sort: e.target.value,
                            title: '商品材積(長X寬X高)',
                            title_en: 'Dimensions (Length x Width x Depth)',
                          });
                        }}
                        className="form-control"
                        style={{
                          maxWidth: '100px',
                        }}
                    />
                  </td>
                  <td>商品材積(長X寬X高)</td>
                  <td></td>
                  <td>
                    <Button
                      className="m-2"
                      type="button"
                      color="primary"
                      outline
                      size="sm"
                      onClick={() =>
                        handleOpenEditModal({
                          content: '',
                          content_en: '',
                          sort: 1,
                          title: '商品材積(長X寬X高)',
                          title_en: 'Dimensions (Length x Width x Depth)',
                        })
                      }
                    >
                      編輯
                    </Button>
                  </td>
                </tr>
              )
            }
            {
              // 預設商品重量
              !profile?.spec?.list?.find(data => data.title === '商品重量') && (
                <tr>
                  <td>

                    <input
                        type="number"
                        defaultValue={2}
                        onChange={e => {
                          updateSort({
                            content: '',
                            content_en: '',
                            sort: e.target.value,
                            title: '商品重量',
                            title_en: 'Weight',

                          });
                        }}
                        className="form-control"
                        style={{
                          maxWidth: '100px',
                        }}
                    />
                  </td>
                  <td>商品重量</td>
                  <td></td>
                  <td>
                    <Button
                      className="m-2"
                      type="button"
                      color="primary"
                      outline
                      size="sm"
                      onClick={() =>
                        handleOpenEditModal({
                          content: '',
                          content_en: '',
                          sort: 2,
                          title: '商品重量',
                          title_en: 'Weight',
                        })
                      }
                    >
                      編輯
                    </Button>
                  </td>
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={isOpen}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          商品規格
        </ModalHeader>
        <Formik
          initialValues={{
            content: content,
            content_en: content_en,
            sort: sort,
            title: title,
            title_en: title_en,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form>
              <ModalBody className={style.modal_body}>
                <div className="form-list form-label-140">
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      標題
                      <ErrorMessage
                        name="title"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field
                        name="title"
                        className="form-control"
                        disabled={
                          title === '商品重量' || title === '商品材積(長X寬X高)'
                        }
                      />
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="required" style={{ width: '120px' }}>
                      標題(英)
                      <ErrorMessage
                        name="title_en"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <div className="form-item">
                      <Field
                        name="title_en"
                        className="form-control"
                        disabled={
                          title === '商品重量' || title === '商品材積(長X寬X高)'
                        }
                      />
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label style={{ width: '120px' }}>內容</label>
                    <div className="form-item">
                      <Field
                        name="content"
                        as="textarea"
                        className="form-control"
                        rows="3"
                      ></Field>
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label style={{ width: '120px' }}>
                      內容(英)
                      {/* <ErrorMessage
                        name="content_en"
                        component="span"
                        className="form-error"
                      /> */}
                    </label>
                    <div className="form-item">
                      <Field
                        name="content_en"
                        as="textarea"
                        className="form-control"
                        rows="3"
                      ></Field>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  className={style.modal_button_submit}
                  type="submit"
                >
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Card>
  );
};
const TabOrder = ({ profile, id }) => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        logisticsWays: (profile?.orderSetting?.logisticsWays || []).map(l => {
          if (l.code === 3) {
            l.isEabled = true;
          }
          return l;
        }),
        payWays: (profile?.orderSetting?.payWays || []).map(p => {
          if (p.code === 1) {
            p.isEabled = true;
          }
          return p;
        }),
      },
      onSubmit(formModel) {
        if (Object.keys(errors).length > 0) {
          let message = CommonUtil.getFormVaildationErrorMessages(errors);
          MessageUtil.alertErrorsWithHTML('請填寫完整資料', message);
        } else {
          dispatch(editModelOrder({ id, params: formModel }));
        }
      },
      validateOnMount: true,
    });

  const { loading, success, error } = useSelector(
    state => state.modelEditOrder,
  );

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功', '');
      dispatch(getModelProfile(id));
    }
    if (error) {
      alert(error);
    }
    return () => {
      dispatch({ type: MODEL_EDIT_ORDER_RESET });
    };
  }, [success]);

  return (
    <Card className={style.card}>
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            付款方式、配送方式的設定在
            <Link to="/CheckoutPayment">【購物設定&gt;付款方式】</Link>
            <Link to="/CheckoutDelivery">【購物設定&gt;配送方式】</Link>
            ，只要未停用的項目，就會在這頁出現
          </span>
        </div>
        <div className="d-flex gap-1">
          <AiOutlineInfoCircle />
          <span className={style.fs_14}>
            停用【付款/取配送】方式，將會連動相關功能：
            <br />
            例：停用【便利商店取貨付款】，配送方式的【7-11取貨、全家取貨】也會停用
          </span>
        </div>
      </Alert>

      <FormGroup className="d-flex">
        <p style={{ width: 150 }}>付款方式</p>
        <div>
          {values?.payWays?.map((i, index) => (
            <FormGroup key={index}>
              <label className="d-flex gap-1 mb-1">
                <input
                  type="checkbox"
                  name={`payWays[${index}].isEabled`}
                  checked={values.payWays[index].isEabled}
                  disabled={values.payWays[index].code === 1}
                  onChange={handleChange}
                />
                {i.name}
              </label>
            </FormGroup>
          ))}
        </div>
      </FormGroup>
      <FormGroup className="d-flex">
        <p style={{ width: 150 }}>配送方式</p>
        <div>
          {values?.logisticsWays?.map((i, index) => (
            <FormGroup key={index}>
              <label className="d-flex gap-1 mb-1">
                <input
                  type="checkbox"
                  name={`logisticsWays[${index}].isEabled`}
                  checked={values.logisticsWays[index].isEabled}
                  disabled={values.logisticsWays[index].code === 3}
                  onChange={handleChange}
                />
                {i.name}
              </label>
            </FormGroup>
          ))}
        </div>
      </FormGroup>
      <p className={`${style.fs_14} mb-0`}>
        異動時間：
        {`${profile?.orderSetting?.updateDate} ${profile?.orderSetting?.updateTime} ${profile?.orderSetting?.updater}`}
      </p>
      <hr />
      <div className="text-center">
        <Button
          color="primary"
          className={style.modal_button_submit}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          儲存
        </Button>
      </div>
    </Card>
  );
};
const ProductDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(1);
  const [permissions, setPermissions] = useState({});
  const { loading, profile } = useSelector(state => state.modelProfile);

  const {
    loading: submitLoading,
    success,
    error,
  } = useSelector(state => state.modelEditSetting);
  const { modelBrandOptionsInfo, loading: modelBrandOptionsInfoLoading } =
    useSelector(state => state.modelBrandOptions);
  const { userInfo } = useSelector(state => state.userLogin);
  const models = () => {
    let arr = [...(profile?.setting?.products || [])];
    arr.sort((a, b) => (a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0));
    arr = arr.map(i => ({
      ...i,
      count: arr.filter(j => j.color === i.color).length,
    }));
    arr = arr.map(i => ({
      ...i,
      rowSpan:
        arr.filter(j => j.color === i.color).indexOf(i) === 0
          ? arr.find(j => j.color === i.color).count
          : 0,
    }));
    const modelArr = arr.filter(
      (i, index) => i?.color !== arr[index - 1]?.color,
    );
    return { modelArr, productArr: arr };
  };

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableEndTimeType: { label: '自訂時間', value: 1 },
      availableTimeEnd: '',
      availableTimeStart: '',
      brandCateId: 0,
      categories: [
        {
          cat1: { label: '請選擇', value: -1 },
          cat2: { label: '請選擇', value: -1 },
          id: 1,
        },
      ],
      features: {
        content: '',
        content_en: '',
        sort: 1,
      },
      globalCategoyIds: [],
      isInstruction: false,
      isRepair: false,
      isReturnable: false,
      isSell: false,
      isWarranty: false,
      modelArr: [],
      modelImgFiles: [],
      modelName: '',
      modelName_en: '',
      modelimgs: [],
      product_current_files: [],
      product_init_files: [],
      products: [],
      updateDate: '',
      updateTime: '',
      updater: '',
    },
    onSubmit: formModel => {
      if (Object.keys(errors).length > 0) {
        const message = CommonUtil.getFormVaildationErrorMessages(errors);
        MessageUtil.alertErrorsWithHTML('請填寫完整資料', message);
      } else {
        const params = {
          ...formModel,
          availableEndTimeType: formModel.availableEndTimeType.value,
          availableTimeEnd: new Date(formModel.availableTimeEnd).toLocaleString(
            'zh-TW',
            {
              dateStyle: 'medium',
              hourCycle: 'h23',
              timeStyle: 'medium',
            },
          ),
          availableTimeStart: new Date(
            formModel.availableTimeStart,
          ).toLocaleString('zh-TW', {
            dateStyle: 'medium',
            hourCycle: 'h23',
            timeStyle: 'medium',
          }),
          brandCateId: formModel.brandCateId,
          features: JSON.stringify([formModel.features]),
          globalCategoyIds: JSON.stringify(
            // formModel.globalCategoyIds.map(i => i.value),
            formModel.categories.map(i => {
              if (i.cat1.hasPrd === false && i.cat2.value == -1) {
                return i.cat1.value;
              } else if (i.cat1.hasPrd) {
                return i.cat1.value;
              } else {
                return i.cat2.value;
              }
            }),
          ),
          products: JSON.stringify(
            formModel.products
              .map(
                ({
                  productId,
                  colorCode,
                  colorName,
                  colorName_en,
                  websiteprice,
                  sizeSort,
                  sellprice,
                  isSoldOut,
                  defaultImgId,
                  stock,
                  lowestStock,
                  outofStockWords,
                  deliveryType,
                  preorderDeliveryDate,
                  isSell,
                  maxSellNum,
                  ...rest
                }) => ({
                  colorCode:
                    formModel.products.find(i => i.color === rest.color)
                      .colorCode || colorCode,
                  colorName:
                    formModel.products.find(i => i.color === rest.color)
                      .colorName || colorName,
                  colorName_en:
                    formModel.products.find(i => i.color === rest.color)
                      .colorName_en || colorName_en,
                  defaultImgId,
                  deliveryType,
                  isSell,
                  lowestStock,
                  maxSellNum,
                  outofStockWords,
                  preorderDeliveryDate,
                  productId,
                  sellprice,
                  sizeSort,
                  sort:
                    formModel.modelArr.find(i => i.color === rest.color)
                      ?.sort || 0,
                  stock,
                  websiteprice,
                }),
              )
              .sort((a, b) => {
                if (a.sort > b.sort) {
                  return 1;
                }
                if (a.sort < b.sort) {
                  return -1;
                }
                return 0;
              })
              .sort((a, b) => {
                if (a.color > b.color) {
                  return 1;
                }
                if (a.color < b.color) {
                  return -1;
                }
                return 0;
              }),
          ),
        };
        if (params.availableEndTimeType !== 1) {
          delete params.availableTimeEnd;
        }
        delete params.modelArr;
        delete params.categories;
        const formData = new FormData();
        handleFormatFiles(formData);
        Object.keys(params)
          .filter(i => i !== 'modelimgs' && i !== 'modelImgFiles')
          .forEach(i => {
            formData.append(i, params[i]);
          });
        dispatch(editModelSetting({ id, params: formData }));
      }
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      availableEndTimeType: Yup.object().shape({
        value: Yup.number().required('必填'),
      }),
      availableTimeEnd: Yup.string()
        .nullable()
        .when('availableEndTimeType', {
          is: value => Number(value.value) === 1,
          then: Yup.string().nullable().required('銷售結束時間必填'),
        }),
      availableTimeStart: Yup.string().nullable().required('銷售開始時間必填'),
      brandCateId: Yup.number().required().positive('品牌類別必填'),
      categories: Yup.array()
        .of(
          Yup.object().shape({
            cat1: Yup.object().shape({
              value: Yup.number()
                .min(1, '商品類別1必填')
                .required('商品類別1必填'),
            }),
            cat2: Yup.object().when('cat1', {
              is: cat1 => cat1.hasCat2 === true,
              then: Yup.object().shape({
                value: Yup.number()
                  .min(1, '商品類別2必填')
                  .required('商品類別2必填'),
              }),
            }),
          }),
        )
        .min(1, '請選擇商品類別'),
      modelName: Yup.string().required('商品名稱必填'),
      modelName_en: Yup.string().required('商品名稱(英文)必填'),
      products: Yup.array()
        .of(
          Yup.object().shape({
            colorCode: Yup.string()
              .test(
                'len',
                '顏色必填',
                len => (len || '').replace('#', '').length > 0,
              )
              .required('顏色必填'),
            deliveryType: Yup.string().required('必填'),
            lowestStock: Yup.string().required('安全庫存量必填'),
            maxSellNum: Yup.string().required('一次最高購買量必填'),
            preorderDeliveryDate: Yup.string()
              .nullable()
              .when('deliveryType', {
                is: val => val === '2',
                then: Yup.string().nullable().required('預定出貨日必填'),
              }),
            sellprice: Yup.string().required('售價必填'),
            stock: Yup.string().required('庫存量必填'),
            websiteprice: Yup.string().required('建議售價必填'),
          }),
        )
        .required('必填'),
    }),
  });
  // 過濾同顏色 products
  const [initImg, setInitImg] = useState([]);
  const [pickerImgs, setPickerImgs] = useState([]);

  useEffect(() => {
    dispatch(getModelProductOptions());
    dispatch(getModelProfile(id));
    dispatch(getSeoSettingsDetail({ sourceId: id, type: 12 }));
  }, []);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功');
      dispatch(getModelProfile(id));
      // window?.opener?.location?.reload();
      dispatch({ type: MODEL_EDIT_SETTING_RESET });
    }
    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
      dispatch({ type: MODEL_EDIT_SETTING_RESET });
    }
  }, [success, error]);

  //收集刪除圖片的id
  const { productPicID } = useSelector(state => state.productDetails);
  const [removeImages, setRemoveImages] = useState([]);

  useEffect(() => {
    if (productPicID) {
      setRemoveImages([...removeImages, productPicID]);
    }
  }, [productPicID]);

  const handleFormatFiles = formData => {
    const modelimgsJson = JSON.stringify(
      values.product_current_files.map((i, index) => ({
        ...i,
        image: i.url,
        imgFormFileId: values.product_files
          ? values.product_files.indexOf(
              values.product_files.find(j => j.id === i.id),
            )
          : -1,
        isRemoveFile: !!values.product_files?.some(j => j.id !== i.id),
        sort: i.sort || index + 1,
      })),
    );
    formData.append('modelimgs', modelimgsJson);
    values.product_files?.length > 0 &&
      values.product_files.forEach(file => {
        formData.append('modelImgFiles', file);
      });
  };
  const handlePreview = () => {
    window.open(
      `${process.env.REACT_APP_WEBSITE_URL}/products/${profile.id}?productType=1&preview=1&previewToken=${profile.previewToken}`,
    );
  };

  useEffect(() => {
    const officialProduct = userInfo?.permissions?.find(
      permission => Number(permission.id) === 30,
    )
      ? true
      : false;
    const operatingManual = userInfo?.permissions?.find(
      permission => Number(permission.id) === 31,
    )
      ? true
      : false;
    const productWarranty = userInfo?.permissions?.find(
      permission => Number(permission.id) === 32,
    )
      ? true
      : false;
    const productRepair = userInfo?.permissions?.find(
      permission => Number(permission.id) === 33,
    )
      ? true
      : false;

    setPermissions({
      officialProduct,
      operatingManual,
      productRepair,
      productWarranty,
    });
  }, []);

  useEffect(() => {
    profile && profile.id && dispatch(getModelBrandOptions(profile.id));
    profile &&
      profile.id &&
      setInitImg(formatImagesArr(profile.setting.images));

    if (profile && profile?.id) {
      setTimeout(() => {
        setValues({
          availableEndTimeType:
            profile?.setting?.availableEndTimeType === 1
              ? { label: '自訂時間', value: 1 }
              : { label: '無期限', value: 2 },
          availableTimeEnd: profile?.setting?.availableTimeEnd || '',
          availableTimeStart: profile?.setting?.availableTimeStart || '',
          brandCateId: profile?.setting?.brandCateId || 0,
          categories: profile?.setting?.globalCategoies?.map((cate, i) => ({
            cat1: {
              hasPrd: cate.cat2Id > 0 ? false : true,
              label: cate.name,
              value: cate.id,
            },
            cat2: {
              label: cate.cat2Name || '請選擇',
              value: cate.cat2Id || -1,
            },
            id: i + 1,
          })) || [
            {
              cat1: { label: '請選擇', value: -1 },
              cat2: { label: '請選擇', value: -1 },
              id: 1,
            },
          ],
          features: {
            content: profile?.setting?.features?.[0]?.content || '',
            content_en: profile?.setting?.features?.[0]?.content_en || '',
            sort: 1,
          },
          globalCategoyIds:
            profile?.setting?.globalCategoies.map(i => ({
              label: i.name,
              value: i.id,
            })) || [],
          isInstruction: profile?.setting?.isInstruction,
          isRepair: profile?.setting?.isRepair,
          isReturnable: profile?.setting?.isReturnable,
          isSell: profile?.setting?.isSell,
          isWarranty: profile?.setting?.isWarranty,
          modelArr: models().modelArr,
          modelImgFiles: [],
          modelName: profile?.setting?.modelName || '',
          modelName_en: profile?.setting?.modelName_en || '',
          modelimgs: profile?.setting?.images || [],
          product_current_files: profile?.setting?.images || [],
          product_init_files: profile?.setting?.images || [],
          products:
            models().productArr.map(i => {
              return {
                ...i,
                colorCode: i.colorCode || '#964C4C',
                colorName: i.colorName || i.color,
                colorName_en: i.colorName_en || i.color,
                deliveryType: i.deliveryType,
                isSell: i.isSell,
                maxSellNum: i.maxSellNum,
                outofStockWords: i.outofStockWords || '已售完',
                sellprice: i.sellprice,
                websiteprice: i.websiteprice,
              };
            }) || [],
          updateDate: profile?.setting?.updateDate,
          updateTime: profile?.setting?.updateTime,
          updater: profile?.setting?.updater,
        });
      }, 500);
    }
  }, [profile]);

  return (
    <Layout
      pageTitle={`商品編輯 - ${profile?.modelName}`}
      items={[
        { isActive: false, page_name: '商品管理', to: '/ProductControl' },
        { isActive: true, page_name: '商品編輯' },
      ]}
    >
      <div className="text-end">
        <Button
          className="m-2 btn btn-sm"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          }}
          onClick={handlePreview}
        >
          <FaEye style={{ color: '#f4772e' }} />
          <span className="ms-2" style={{ color: '#f4772e' }}>
            預覽
          </span>
        </Button>
      </div>
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="商品設定"
          currentTab={currentTab}
        />

        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="商品介紹"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={3}
          text="商品規格"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={4}
          text="付款配送方式"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={5}
          text="seo設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={6}
          text="保固期限設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            <form className="form-list form-label-140">
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label className="required" style={{ width: '140px' }}>
                  商品名稱
                  {errors.modelName && (
                    <span className="form-error"> 必填！</span>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="modelName"
                  name="modelName"
                  onChange={handleChange}
                  value={values.modelName}
                />
              </div>
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label className="required" style={{ width: '140px' }}>
                  商品名稱(英)
                  {errors.modelName_en && (
                    <span className="form-error"> 必填！</span>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="modelName_en"
                  name="modelName_en"
                  onChange={handleChange}
                  value={values.modelName_en}
                />
              </div>
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label className="required" style={{ width: '140px' }}>
                  商品圖片
                </label>
                <div className="form-item">
                  <UploadFileBox
                    thumbSize="100px"
                    isMultipleUpload
                    setFormFieldValue={setFieldValue}
                    formFieldName="product_files"
                    currentFileFieldName="product_current_files"
                    isDelete="isDelete"
                    isUpload="isUpload"
                    isDragNDrop="isDragNDrop"
                    product_total_upload="product_total_upload"
                    uplodModel="images"
                    displayFileWidth="不限"
                    displayFileHeight="不限"
                    displayFileSize="不限"
                    initFiles={initImg}
                    onThumbsChange={setPickerImgs}
                  />
                  {values?.product_current_files?.length === 0 && (
                    <div className="form-error">請上傳圖片</div>
                  )}
                </div>
              </div>
              <div className={`${style.table_overflow} mb-5`}>
                <Table hover bordered striped>
                  <thead className={`thead-dark ${style.table_head}`}>
                    <tr>
                      <th style={{ minWidth: 80 }}>排序</th>
                      <th>圖片</th>
                      <th>
                        <div>ProductCode</div>
                        <div>BarCode</div>
                      </th>
                      <th style={{ minWidth: 150 }}>*顏色</th>
                      <th style={{ minWidth: 150 }}>顏色名稱</th>
                      <th>尺吋</th>
                      <th>
                        <div>*建議售價</div>
                        <div>*售價</div>
                      </th>
                      <th>商品交期</th>
                      <th>上架</th>
                      <th>
                        <div>*庫存量</div>
                        <div>*安全庫存量</div>
                      </th>
                      <th>*一次最高購買量</th>
                      <th>缺貨顯示</th>
                    </tr>
                  </thead>
                  {loading && <TableLoading />}
                  {!loading && (
                    <tbody>
                      {models()?.productArr.length > 0 ? (
                        models()?.productArr.map((data, index) => (
                          <tr key={JSON.stringify(data)}>
                            {data.rowSpan > 0 && (
                              <td rowSpan={data.rowSpan}>
                                <input
                                  value={
                                    values.modelArr[
                                      values.modelArr
                                        .map(i => i.productId)
                                        .indexOf(data.productId)
                                    ]?.sort
                                  }
                                  min={0}
                                  type="number"
                                  className="form-control"
                                  onChange={handleChange}
                                  name={`modelArr[${models()
                                    .modelArr.map(i => i.productId)
                                    .indexOf(data.productId)}].sort`}
                                />
                              </td>
                            )}
                            <td style={{ wordBreak: 'keep-all' }}>
                              <ProductImgPicker
                                thumbnail={
                                  values?.products?.[index]?.defaultImgUrl ||
                                  data.showImg
                                }
                                imgs={pickerImgs}
                                onSelect={imgItem => {
                                  setFieldValue(
                                    `products[${index}].defaultImgUrl`,
                                    imgItem.url,
                                  );
                                  setFieldValue(
                                    `products[${index}].defaultImgId`,
                                    imgItem.id,
                                  );
                                }}
                              />
                            </td>
                            <td style={{ wordBreak: 'keep-all' }}>
                              <div>{data.productcode}</div>
                              <div>{data.barcode}</div>
                            </td>
                            {data.rowSpan > 0 && (
                              <td
                                style={{ wordBreak: 'keep-all' }}
                                rowSpan={data.rowSpan}
                              >
                                <div>{data.color}</div>
                                <div className="form-item">
                                  <CustomColorPicker
                                    initColor={data.colorCode}
                                    formFieldName={`products[${index}].colorCode`}
                                    setFormFieldValue={setFieldValue}
                                  />
                                  {errors.products &&
                                    errors.products[index]?.colorCode && (
                                      <span className="form-error">
                                        {errors.products[index]?.colorCode}
                                      </span>
                                    )}
                                </div>
                              </td>
                            )}
                            {data.rowSpan > 0 && (
                              <td
                                style={{ wordBreak: 'keep-all' }}
                                rowSpan={data.rowSpan}
                              >
                                <div className="d-flex align-items-center mb-1">
                                  中：
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`products[${index}].colorName`}
                                    onChange={handleChange}
                                    value={values?.products[index]?.colorName}
                                  />
                                </div>
                                <div className="d-flex align-items-center">
                                  英：
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`products[${index}].colorName_en`}
                                    onChange={handleChange}
                                    value={
                                      values?.products[index]?.colorName_en
                                    }
                                  />
                                </div>
                              </td>
                            )}
                            <td align="center" nowrap="nowrap">
                              <input
                                type="number"
                                className="form-control"
                                name={`products[${index}].sizeSort`}
                                onChange={handleChange}
                                style={{
                                  minWidth: '100px',
                                }}
                                value={values?.products[index]?.sizeSort}
                              />
                              {data.size || '--'}
                            </td>
                            <td align="left">
                              <div className="d-flex align-items-center">
                                <div className="text-nowrap">NT$</div>
                                <Input
                                  type="number"
                                  className="form-control"
                                  name={`products[${index}].websiteprice`}
                                  onChange={handleChange}
                                  value={values.products[index]?.websiteprice}
                                  style={{ width: '95px' }}
                                />
                              </div>
                              {errors.products &&
                                errors.products[index]?.websiteprice && (
                                  <div className="form-error">
                                    {errors.products[index]?.websiteprice}
                                  </div>
                                )}
                              <div className="d-flex align-items-center mt-2">
                                <div className="text-nowrap">NT$</div>
                                <Input
                                  type="number"
                                  className="form-control"
                                  name={`products[${index}].sellprice`}
                                  onChange={handleChange}
                                  value={values.products[index]?.sellprice}
                                  style={{ width: '95px' }}
                                />
                              </div>
                              {errors.products &&
                                errors.products[index]?.sellprice && (
                                  <div className="form-error">
                                    {errors.products[index]?.sellprice}
                                  </div>
                                )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  name={`products[${index}].deliveryType`}
                                  checked={
                                    Number(
                                      values.products[index]?.deliveryType,
                                    ) === 1
                                  }
                                  onChange={handleChange}
                                  value={1}
                                />
                                一般
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  name={`products[${index}].deliveryType`}
                                  checked={
                                    Number(
                                      values.products[index]?.deliveryType,
                                    ) === 2
                                  }
                                  onChange={handleChange}
                                  value={2}
                                />
                                預購，預定出貨日
                              </div>
                              <div
                                className="w200"
                                style={{ marginTop: '0.3rem' }}
                              >
                                <DatePicker
                                  id="preorderDeliveryDate"
                                  className="form-control"
                                  dateFormat="yyyy/MM/dd"
                                  disabled={
                                    Number(
                                      values.products[index]?.deliveryType,
                                    ) !== 2
                                  }
                                  selected={
                                    values.products[index]?.preorderDeliveryDate
                                      ? moment(
                                          values.products[index]
                                            ?.preorderDeliveryDate,
                                        )
                                          .hour(8)
                                          .toDate()
                                      : null
                                  }
                                  name="preorderDeliveryDate"
                                  onChange={date => {
                                    setFieldValue(
                                      `products[${index}].preorderDeliveryDate`,
                                      moment(date).toISOString(),
                                    );
                                  }}
                                  selectsStart
                                />
                                {errors?.products?.length > 0 &&
                                  errors?.products[index]
                                    ?.preorderDeliveryDate && (
                                    <span className="form-error">
                                      {
                                        errors?.products[index]
                                          ?.preorderDeliveryDate
                                      }
                                    </span>
                                  )}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  name={`products[${index}].isSell`}
                                  checked={values.products[index]?.isSell}
                                  onChange={() => {
                                    setFieldValue(
                                      `products[${index}].isSell`,
                                      true,
                                    );
                                  }}
                                />
                                是
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  name={`products[${index}].isSell`}
                                  checked={!values.products[index]?.isSell}
                                  onChange={() => {
                                    setFieldValue(
                                      `products[${index}].isSell`,
                                      false,
                                    );
                                  }}
                                />
                                否
                              </div>
                            </td>
                            <td>
                              <Input
                                type="number"
                                min={0}
                                className="form-control text-center"
                                name={`products[${index}].stock`}
                                onChange={handleChange}
                                value={values.products[index]?.stock}
                              />
                              {errors?.products?.length > 0 &&
                                errors?.products[index]?.stock && (
                                  <div className="form-error text-nowrap">
                                    {errors?.products[index]?.stock}
                                  </div>
                                )}
                              <input
                                type="number"
                                min={0}
                                className="form-control text-center"
                                name={`products[${index}].lowestStock`}
                                onChange={handleChange}
                                value={values.products[index]?.lowestStock}
                              />
                              {errors?.products?.length > 0 &&
                                errors?.products[index]?.lowestStock && (
                                  <div className="form-error text-nowrap">
                                    {errors?.products[index]?.lowestStock}
                                  </div>
                                )}
                            </td>
                            <td>
                              <input
                                type="number"
                                className="form-control text-center"
                                name={`products[${index}].maxSellNum`}
                                onChange={handleChange}
                                value={values.products[index]?.maxSellNum}
                              />
                              {errors?.products?.length > 0 &&
                                errors?.products[index]?.maxSellNum && (
                                  <div className="form-error text-nowrap">
                                    {errors?.products[index]?.maxSellNum}
                                  </div>
                                )}
                            </td>
                            <td>
                              <FormGroup check>
                                <Label className="d-flex gap-1 text-nowrap">
                                  <input
                                    type="radio"
                                    name={`products[${index}].outofStockWords`}
                                    value="已售完"
                                    checked={
                                      values.products[index]
                                        ?.outofStockWords === '已售完'
                                    }
                                    onChange={handleChange}
                                  />
                                  已售完
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label className="d-flex gap-1 text-nowrap">
                                  <input
                                    name={`products[${index}].outofStockWords`}
                                    type="radio"
                                    onChange={handleChange}
                                    value="售完不補貨"
                                    checked={
                                      values.products[index]
                                        ?.outofStockWords === '售完不補貨'
                                    }
                                  />
                                  售完不補貨
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label className="d-flex gap-1 text-nowrap">
                                  <input
                                    name={`products[${index}].outofStockWords`}
                                    type="radio"
                                    onChange={handleChange}
                                    value="貨到通知"
                                    checked={
                                      values.products[index]
                                        ?.outofStockWords === '貨到通知'
                                    }
                                  />
                                  貨到通知
                                </Label>
                              </FormGroup>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableHasNoData />
                      )}
                    </tbody>
                  )}
                </Table>
              </div>
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label className="required" style={{ width: '140px' }}>
                  品牌類別
                  {errors.brandCateId && (
                    <span className="form-error"> 必填！</span>
                  )}
                </label>
                <div className="form-item">
                  <Select
                    isLoading={modelBrandOptionsInfoLoading}
                    isDisabled={modelBrandOptionsInfoLoading}
                    options={
                      modelBrandOptionsInfo !== undefined && [
                        emptyOption('請選擇品牌類別', 0),
                        ...modelBrandOptionsInfo,
                      ]
                    }
                    id="brandCateId"
                    name="brandCateId"
                    defaultValue={emptyOption('請選擇品牌類別', 0)}
                    onChange={({ value }) =>
                      setFieldValue('brandCateId', value)
                    }
                    value={
                      modelBrandOptionsInfo?.find(
                        i => i.value === values.brandCateId.toString(),
                      ) || emptyOption('請選擇品牌類別', 0)
                    }
                  />
                </div>
              </div>
              <div
                className={style.formGroup}
                style={{ alignItems: 'flex-start', marginBottom: '2rem' }}
              >
                <label className="required" style={{ width: '140px' }}>
                  商品類別
                </label>
                <div>
                  {errors?.categories &&
                    typeof errors.categories === 'string' && (
                      <div className="form-error">{errors.categories}</div>
                    )}
                  {values.categories.length > 0 &&
                    values.categories.map((category, i) => (
                      <ProductCategorySelection
                        key={i}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        index={i}
                      />
                    ))}
                  <Button
                    color="primary"
                    outline
                    onClick={() => {
                      const conditions = [
                        ...values.categories,
                        {
                          cat1: { label: '請選擇', value: -1 },
                          cat2: { label: '請選擇', value: -1 },
                          id: values.categories.length + 1,
                        },
                      ];
                      setFieldValue('categories', conditions);
                    }}
                  >
                    新增商品類別
                  </Button>
                </div>
              </div>

              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label style={{ width: '140px' }}>特色說明</label>
                <div className="form-item">
                  <small>中文</small>
                  <Input
                    type="textarea"
                    rows="10"
                    id="features.content"
                    name="features.content"
                    onChange={handleChange}
                    value={values?.features?.content}
                  />

                  <small>英文</small>
                  <Input
                    type="textarea"
                    rows="10"
                    id="features.content_en"
                    name="features.content_en"
                    onChange={handleChange}
                    value={values?.features?.content_en}
                  />
                </div>
                <div className="form-item"></div>
              </div>
              <FormGroup row>
                <label className="required" style={{ width: '140px' }}>
                  可銷售區間
                </label>
                <Col>
                  <FormGroup>
                    <Label>銷售開始時間</Label>
                    <div className="form-item flex-wrap d-flex mb-3">
                      <div style={{ width: '2100px' }}>
                        <DatePicker
                          className="form-control"
                          dateFormat="yyyy/MM/dd hh:mm:ss aa"
                          timeFormat="HH:mm:ss"
                          timeIntervals={1}
                          showTimeSelect
                          selected={
                            values?.availableTimeStart
                              ? moment(values?.availableTimeStart).toDate()
                              : ''
                          }
                          name="availableTimeStart"
                          onChange={date => {
                            setFieldValue('availableTimeStart', date);
                          }}
                          selectsStart
                        />
                      </div>
                      {errors && errors.availableTimeStart && (
                        <span className="form-error ml-2">
                          {errors.availableTimeStart}
                        </span>
                      )}
                    </div>
                    <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
                      <Select
                        options={[
                          { label: '自訂時間', value: 1 },
                          { label: '無期限', value: 2 },
                        ]}
                        name="availableEndTimeType"
                        onChange={option => {
                          setFieldValue('availableEndTimeType', option);
                        }}
                        value={values.availableEndTimeType}
                      />
                      {values?.availableEndTimeType?.value === 2 && (
                        <span>結束日期預計為 2099/12/12 12:12:12</span>
                      )}
                    </div>
                    {values?.availableEndTimeType?.value === 1 && (
                      <div className="form-item flex-wrap d-flex mb-3">
                        <div style={{ width: '2100px' }}>
                          <DatePicker
                            className="form-control"
                            dateFormat="yyyy/MM/dd hh:mm:ss aa"
                            timeFormat="HH:mm:ss"
                            timeIntervals={1}
                            showTimeSelect
                            selected={
                              values.availableTimeEnd
                                ? moment(values.availableTimeEnd).toDate()
                                : ''
                            }
                            name="availableTimeEnd"
                            onChange={date => {
                              setFieldValue('availableTimeEnd', date);
                            }}
                            selectsEnd
                            minDate={values.availableTimeStart}
                          />
                        </div>
                        {errors && errors.availableTimeEnd && (
                          <span className="form-error">
                            {errors.availableTimeEnd}
                          </span>
                        )}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </FormGroup>
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <div className="d-flex align-items-center gap-2">
                  <label
                    className="required"
                    style={{ margin: 0, width: '140px' }}
                  >
                    是否可退貨
                  </label>
                  <input
                    type="radio"
                    className={`${style.maintain_list_checkbox}`}
                    name="isReturnable"
                    checked={values.isReturnable}
                    onChange={() => {
                      setFieldValue('isReturnable', true);
                    }}
                  />
                  是
                  <input
                    type="radio"
                    className={style.maintain_list_checkbox}
                    style={{ marginLeft: '1rem' }}
                    name="isReturnable"
                    checked={!values.isReturnable}
                    onChange={() => {
                      setFieldValue('isReturnable', false);
                    }}
                  />
                  否
                </div>
                <div></div>
              </div>
              <div className={style.formGroup} style={{ marginBottom: '2rem' }}>
                <label className="" style={{ width: '140px' }}>
                  上架
                </label>
                <div className="form-item align-items-center">
                  <Label
                    htmlFor="isSell"
                    className="me-3"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="checkbox"
                      id="isSell"
                      name="isSell"
                      checked={values.isSell}
                      onChange={e => {
                        handleChange(e);
                        setFieldValue(
                          'products',
                          values.products.map(i => ({
                            ...i,
                            isSell: e.target.checked,
                          })),
                        );
                      }}
                      value={values.isSell}
                      disabled={!permissions.officialProduct}
                    />
                    官網商品
                  </Label>
                  <Label
                    htmlFor="isInstruction"
                    className="me-3"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="checkbox"
                      id="isInstruction"
                      name="isInstruction"
                      checked={values.isInstruction}
                      onChange={handleChange}
                      value={values.isInstruction}
                      disabled={!permissions.operatingManual}
                    />
                    商品操作說明
                  </Label>
                  <Label
                    htmlFor="isWarranty"
                    className="me-3"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="checkbox"
                      id="isWarranty"
                      name="isWarranty"
                      checked={values.isWarranty}
                      onChange={handleChange}
                      value={values.isWarranty}
                      disabled={!permissions.productWarranty}
                    />
                    商品保固
                  </Label>
                  <Label
                    htmlFor="isRepair"
                    className="me-3"
                    style={{ width: '100%' }}
                  >
                    <input
                      type="checkbox"
                      id="isRepair"
                      name="isRepair"
                      checked={values.isRepair}
                      onChange={handleChange}
                      value={values.isRepair}
                      disabled={!permissions.productRepair}
                    />
                    商品維修
                  </Label>
                </div>
              </div>
              <div>
                異動時間： {values.updateDate} {values.updateTime}{' '}
                {values.updater}
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <Button
                  className={style.modal_button_submit}
                  disabled={
                    submitLoading ||
                    Object.keys(errors).length > 0 ||
                    values?.product_current_files?.length === 0
                  }
                  onClick={handleSubmit}
                >
                  {submitLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </div>
            </form>
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <TabDesc id={id} profile={profile} />
        </TabPane>
        <TabPane tabId={3}>
          <TabSpec
            profile={profile}
            id={id}
            handleRefresh={() => {
              dispatch(getModelProfile(id));
            }}
          />
        </TabPane>
        <TabPane tabId={4}>
          <TabOrder profile={profile} id={id} />
        </TabPane>
        <TabPane tabId={5}>
          <SEOSettingsConfig
            info={{ sourceId: Number(id), type: 12, typeName: '商品編輯頁' }}
          />
        </TabPane>
        <TabPane tabId={6}>
          <WarrantyPeriodSetting mid={profile?.id} />
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default ProductDetail;
