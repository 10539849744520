import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  deleteDiscountCode,
  getDiscountCodeList,
  postDiscountCode,
} from '../redux/actions/data/salesActions';
import {
  DISCOUNT_CODE_DELETE_RESET,
  DISCOUNT_CODE_POST_RESET,
} from '../redux/constants/data/salesConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

export default function CouponList(props) {
  const dispatch = useDispatch();
  const { location } = history;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState(''); //日期都要用字串
  const [endDate, setEndDate] = useState('');
  const [codeId, setCodeId] = useState(0);

  const [codeData, setCodeData] = useState(null);

  const { loading, codeInfo, error } = useSelector(
    state => state.discountCodeList,
  );
  const {
    loading: postLoading,
    success: postSuccess,
    error: postError,
  } = useSelector(state => state.discountCodePost);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = useSelector(state => state.discountCodeDelete);

  useEffect(() => {
    dispatch(
      getDiscountCodeList({
        page: 1,
      }),
    );
  }, []);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      setModal(false);
      dispatch(getDiscountCodeList({ page: currentPage }));
    }

    //會有日期區間重複的錯誤
    if (postError) {
      MessageUtil.alertWanring(postError);
    }

    return () => {
      dispatch({ type: DISCOUNT_CODE_POST_RESET });
    };
  }, [postSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (codeInfo.datas.length !== 1) {
        dispatch(getDiscountCodeList({ keyword, page: currentPage, status }));
      } else {
        dispatch(
          getDiscountCodeList({ keyword, page: currentPage - 1, status }),
        );

        history.push(
          `${location.pathname}?keyword=${keyword}&status=${status}&page=${
            currentPage - 1
          }`,
        );

        setCurrentPage(currentPage - 1);
      }
    }

    // if (deleteError && deleteError.code === APIUtil.Code_Not_Found) {
    //   MessageUtil.alertWanring('資料錯誤', '')
    // }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: DISCOUNT_CODE_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setStatus('');
    dispatch(getDiscountCodeList({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getDiscountCodeList({ keyword, page: 1, status }));
    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };

  const toggleAdd = () => {
    setModal(!modal);
    setCodeData(null);
    setCodeId(0);
    setStartDate('');
    setEndDate('');
  };
  const toggleEditModal = data => {
    setModal(!modal);
    setCodeData(data);
    setCodeId(data.id);
    setStartDate('');
    setEndDate('');
  };

  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteDiscountCode(id));
    });
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getDiscountCodeList({ keyword, page, status }));
    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSubmit = values => {
    // console.log(codeData.startTime)
    // console.log(codeData.endTime)

    let formData = new FormData();

    formData.append('id', codeId);
    formData.append('code', values.code);
    formData.append('desc', values.desc);
    formData.append('desc_en', values.desc);
    formData.append('discountDesc', values.discountDesc);
    formData.append('discountDesc_en', values.discountDesc);
    formData.append('status', values.status);

    //有選時間就要toISOstring
    formData.append(
      'startTime',
      startDate ? startDate.toISOString() : codeData.startTime,
    );

    formData.append(
      'endTime',
      endDate ? endDate.toISOString() : codeData.endTime,
    );

    dispatch(postDiscountCode(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      code: Yup.string().required('必填!'),
      desc: Yup.string().required('必填!'),
      discountDesc: Yup.string().required('必填!'),

      // startTime: Yup.string().required('必填!'),
      // endTime: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    if (codeInfo) {
      if (JSON.stringify(codeInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: codeInfo });
      }
    }
  }, [codeInfo]);

  return (
    <Layout
      pageTitle="折扣碼設定"
      items={[
        { isActive: false, page_name: '折扣碼管理' },
        { isActive: true, page_name: '折扣碼設定' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2" htmlFor="keyword">
                  折扣碼
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="折扣碼"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>

              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  id="status"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option>上架</option>
                  <option>下架</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleAdd}>
          新增
        </Button>
        <div className="text-danger">折扣碼顯示日期區間不可重複</div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && codeInfo && codeInfo.total_count
              ? codeInfo.total_count
              : 0}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>折扣碼</th>
                <th>說明文字</th>
                <th>優惠文字</th>
                <th>顯示區間</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {codeInfo && codeInfo.datas.length > 0 ? (
                  codeInfo.datas.map((data, index) => (
                    <tr key={data.id}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index + 1 + (currentPage - 1) * 10}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.code}</td>
                      <td>{data.desc}</td>
                      <td>{data.discountDesc}</td>
                      <td>
                        <span style={{ wordBreak: 'keep-all' }}>
                          {moment(new Date(data.startTime))
                            .add(8, 'hours')
                            .format('yyyy/MM/DD')
                            .toString()}
                          {''} ~
                        </span>
                        <br />
                        <span style={{ wordBreak: 'keep-all' }}>
                          {data.endTime
                            ? moment(new Date(data.endTime))
                                .add(8, 'hours')
                                .format('yyyy/MM/DD')
                                .toString()
                            : ''}
                        </span>
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => toggleEditModal(data)}
                        >
                          編輯分類
                        </Button>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleDelete(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && codeInfo && codeInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={codeInfo.current_page}
              defaultPageSize={10}
              total={codeInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={toggleEditModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleEditModal}>
          折扣碼編輯
        </ModalHeader>

        <Formik
          enableReinitialize
          initialValues={{
            code: codeData && codeData.code ? codeData.code : '',
            desc: codeData && codeData.desc ? codeData.desc : '',
            discountDesc:
              codeData && codeData.discountDesc ? codeData.discountDesc : '',
            endTime: codeData && codeData.endTime ? codeData.endTime : '',
            startTime: codeData && codeData.startTime ? codeData.startTime : '',
            status: codeData && codeData.status ? codeData.status : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    折扣碼
                    <ErrorMessage
                      name="code"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="code" className="form-control" />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    說明文字{' '}
                    <ErrorMessage
                      name="desc"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="desc"
                      as="textarea"
                      rows="3"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    優惠文字{' '}
                    <ErrorMessage
                      name="discountDesc"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="discountDesc"
                      as="textarea"
                      rows="3"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    顯示區間{' '}
                    {!startDate && (
                      <ErrorMessage
                        name="startTime"
                        component="span"
                        className="form-error"
                      />
                    )}
                    {!endDate && startDate && (
                      <ErrorMessage
                        name="endTime"
                        component="span"
                        className="form-error"
                      />
                    )}
                  </label>
                  <div className="form-item d-flex flex-wrap d-flex">
                    <div className="w200">
                      <DatePicker
                        className="form-control"
                        name="startDate"
                        selected={
                          startDate === '' && codeData && codeData.startTime
                            ? new Date(codeData.startTime)
                            : startDate
                        }
                        onChange={date => setStartDate(date)}
                        selectsStart
                        dateFormat="yyyy/MM/dd"
                      />
                    </div>
                    <span className="px-3 pt-2">~</span>
                    <div className="w200">
                      <DatePicker
                        className="form-control w200"
                        name="endDate"
                        selected={
                          endDate === '' && codeData && codeData.endTime
                            ? new Date(codeData.endTime)
                            : endDate
                        }
                        defaultValue="undefined"
                        onChange={date => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        selectsEnd
                        minDate={endDate || new Date(codeData?.startTime)}
                      />
                    </div>
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required w150">
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="d-flex form-item">
                    <Field
                      className="my-auto me-2"
                      id="up"
                      name="status"
                      type="radio"
                      value="上架"
                    />
                    <label htmlFor="up" className="me-5">
                      上架
                    </label>
                    <Field
                      className="my-auto me-2"
                      id="down"
                      name="status"
                      type="radio"
                      value="下架"
                    />
                    <label htmlFor="down">下架</label>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className={style.modal_footer}>
                <Button
                  color="link"
                  className={`${style.modal_button_cancel} ms-auto`}
                  onClick={toggleEditModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
