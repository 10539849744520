import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import { updateCafeMiddleHome } from '../../redux/actions/data/uuCafeMiddleHomeSettingsAction';
import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import UploadFileBox from '../UploadFileBox';

const MiddleHomeModal = ({
  middleHome = {
    bannerImg: '',
    bannerImg_mobile: '',
    bannerUrl: '',
    bannerUrl_mobile: '',
    linkUrl: '',
    title: '',
    title_en: '',
  },
  openModal = false,
  setOpenModel = () => {},
}) => {
  const { loading, success, error } = useSelector(
    state => state.cafeMiddleHomeSetting,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        const formData = new FormData();
        formData.append('linkUrl', props.values.linkUrl);
        formData.append('title', props.values.title);
        formData.append('title_en', props.values.title_en);

        if (props.values.banner_files) {
          formData.append('banner_files', props.values.banner_files);
        } else {
          if (middleHome.bannerUrl && middleHome.bannerImg) {
            formData.append('banner_files', []);
          }
        }

        if (props.values.banner_mobile_files) {
          formData.append(
            'banner_files_mobile',
            props.values.banner_mobile_files,
          );
        } else {
          if (middleHome.bannerImg_mobile && middleHome.bannerUrl_mobile) {
            formData.append('banner_files_mobile', []);
          }
        }

        if (middleHome.id) {
          formData.append('id', middleHome.id);
          dispatch(updateCafeMiddleHome(formData));
        }
      }
    },
    [middleHome, dispatch],
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        setOpenModel(false);
      }
      if (error) {
        toast.warning('修改失敗');
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, middleHome, setOpenModel]);

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>編輯</ModalHeader>
        <Formik
          validationSchema={getValidationSchema}
          initialValues={{
            banner_files: null,
            banner_mobile_files: null,
            init_bannerFile: getImageDataFromSetting(
              middleHome.bannerImg,
              middleHome.bannerUrl,
            ),
            init_bannerUrl_mobile: getImageDataFromSetting(
              middleHome.bannerImg_mobile,
              middleHome.bannerUrl_mobile,
            ),
            linkUrl: middleHome.linkUrl,
            title: middleHome.title,
            title_en: middleHome.title_en,
          }}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className="form-item">
                  <FormGroup className={style.formGroup}>
                    <label style={{ width: '120px' }}>*電腦版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        uplodModel="images"
                        formFieldName="banner_files"
                        currentFileFieldName="banner_files"
                        setFormFieldValue={(field, files) => {
                          if (files && files.length) {
                            if (files[0] instanceof File) {
                              props.setFieldValue('banner_files', files[0]);
                            }
                          }
                        }}
                        displayFileWidth="350"
                        displayFileHeight="550"
                        displayFileSize="3MB"
                        initFiles={props.values.init_bannerFile}
                      />
                    </div>
                    <ErrorMessage
                      name="bannerUrl"
                      component="div"
                      className="form-error"
                    />
                  </FormGroup>

                  <FormGroup className={style.formGroup}>
                    <label style={{ width: '120px' }}>*手機版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        uplodModel="images"
                        formFieldName="banner_mobile_files"
                        currentFileFieldName="banner_mobile_files"
                        setFormFieldValue={(field, files) => {
                          if (files && files.length) {
                            if (files[0] instanceof File) {
                              props.setFieldValue(
                                'banner_mobile_files',
                                files[0],
                              );
                            }
                          }
                        }}
                        displayFileWidth="350"
                        displayFileHeight="350"
                        displayFileSize="1MB"
                        initFiles={props.values.init_bannerUrl_mobile}
                      />
                      <ErrorMessage
                        name="bannerUrl_mobile"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>*標題(中)</label>
                    <div className="form-item">
                      <Field name="title" className="form-control" />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>*標題(英文)</label>
                    <div className="form-item">
                      <Field name="title_en" className="form-control" />
                      <ErrorMessage
                        name="title_en"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>*連結</label>
                    <div className="form-item">
                      <Field name="linkUrl" className="form-control" />
                      <ErrorMessage
                        name="linkUrl"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <Button
                  color="light"
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    setOpenModel(false);
                  }}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  type="button"
                  className={style.modal_button_submit}
                  disabled={loading || !(props.isValid && props.dirty)}
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span>儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default memo(MiddleHomeModal);
