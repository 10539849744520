import {
  GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL,
  GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST,
  GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS,
  GET_COUPON_REALTIME_REPORT_LIST_FAIL,
  GET_COUPON_REALTIME_REPORT_LIST_REQUEST,
  GET_COUPON_REALTIME_REPORT_LIST_SUCCESS,
  GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL,
  GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST,
  GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS,
  GET_ORDER_CANCEL_REPORT_FAIL,
  GET_ORDER_CANCEL_REPORT_REQUEST,
  GET_ORDER_CANCEL_REPORT_SUCCESS,
  GET_ORDER_INSTANT_REPORT_FAIL,
  GET_ORDER_INSTANT_REPORT_REQUEST,
  GET_ORDER_INSTANT_REPORT_SUCCESS,
  GET_ORDER_SOURCE_LIST_FAIL,
  GET_ORDER_SOURCE_LIST_REQUEST,
  GET_ORDER_SOURCE_LIST_SUCCESS,
  GET_PRODUCT_SALES_DATA_RANKING_FAIL,
  GET_PRODUCT_SALES_DATA_RANKING_REQUEST,
  GET_PRODUCT_SALES_DATA_RANKING_SUCCESS,
  GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL,
  GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST,
  GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS,
  GET_STORE_SALES_DATA_LIST_FAIL,
  GET_STORE_SALES_DATA_LIST_REQUEST,
  GET_STORE_SALES_DATA_LIST_SUCCESS,
} from 'redux/constants/data/operatingReportContants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getProductSalesDataRanking = data => {
  const url = '/Backend/OperatingReports/SalesAmountRankingReport';
  CommonUtil.consoleLog({ anno: 'request', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_PRODUCT_SALES_DATA_RANKING_FAIL,
      method: 'post',
      requestConstant: GET_PRODUCT_SALES_DATA_RANKING_REQUEST,
      successConstant: GET_PRODUCT_SALES_DATA_RANKING_SUCCESS,
      url,
    });
  };
};

export const getStoreSalesDataList = data => {
  const url = '/Backend/OperatingReports/SalesStatisticsReport';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_STORE_SALES_DATA_LIST_FAIL,
      method: 'post',
      requestConstant: GET_STORE_SALES_DATA_LIST_REQUEST,
      successConstant: GET_STORE_SALES_DATA_LIST_SUCCESS,
      url,
    });
  };
};

export const getOrderInstantReport = data => {
  const url = '/Backend/OperatingReports/InstantOrderReport';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ORDER_INSTANT_REPORT_FAIL,
      method: 'post',
      requestConstant: GET_ORDER_INSTANT_REPORT_REQUEST,
      successConstant: GET_ORDER_INSTANT_REPORT_SUCCESS,
      url,
    });
  };
};

export const getOrderCancelReport = data => {
  const url = '/Backend/OperatingReports/OrderCancelReport';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ORDER_CANCEL_REPORT_FAIL,
      method: 'post',
      requestConstant: GET_ORDER_CANCEL_REPORT_REQUEST,
      successConstant: GET_ORDER_CANCEL_REPORT_SUCCESS,
      url,
    });
  };
};

export const getOrderSourceList = data => {
  const url = '/Backend/OperatingReports/OrderSourceReport';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ORDER_SOURCE_LIST_FAIL,
      method: 'post',
      requestConstant: GET_ORDER_SOURCE_LIST_REQUEST,
      successConstant: GET_ORDER_SOURCE_LIST_SUCCESS,
      url,
    });
  };
};

export const getRewardsPointsStatisticalList = data => {
  const url = '/Backend/OperatingReports/BonusPointStatisticsReport';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL,
      method: 'post',
      requestConstant: GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST,
      successConstant: GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS,
      url,
    });
  };
};

export const getCouponPerformanceReportList = (
  data = {
    couponType: 0,
    endTime: '',
    getWay: 0,
    keywords: '',
    page: 0,
    page_size: 0,
    queryDateType: '',
    salesChannel: '',
    startTime: '',
  },
) => {
  const url = '/Backend/OperatingReports/GetCouponPerformanceReport';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST,
      successConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS,
      url,
    });
  };
};

export const getCouponRealtimeReportList = (
  data = {
    couponType: 0,
    endTime: '',
    getWay: 0,
    keywords: '',
    page: 0,
    page_size: 0,
    queryDateType: '',
    salesChannel: '',
    startTime: '',
  },
) => {
  const url = '/Backend/OperatingReports/GetCouponRealTimeReport';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_COUPON_REALTIME_REPORT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_COUPON_REALTIME_REPORT_LIST_REQUEST,
      successConstant: GET_COUPON_REALTIME_REPORT_LIST_SUCCESS,
      url,
    });
  };
};

export const getPromoPerformanceReport = (
  data = {
    couponType: 0,
    endTime: '',
    getWay: 0,
    keywords: '',
    order: '',
    order_by: '',
    page: 0,
    page_size: 0,
    queryDateType: '',
    salesChannel: '',
    startTime: '',
    type: 0,
  },
) => {
  const url = '/Backend/OperatingReports/GetPromoPerformanceReport';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST,
      successConstant: GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS,
      url,
    });
  };
};

export const getPromoRealTimeReport = (
  data = {
    couponType: 0,
    endTime: '',
    getWay: 0,
    keywords: '',
    order: '',
    order_by: '',
    page: 0,
    page_size: 0,
    queryDateType: '',
    salesChannel: '',
    startTime: '',
    type: 0,
  },
) => {
  const url = '/Backend/OperatingReports/GetPromoRealTimeReport';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_COUPON_REALTIME_REPORT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_COUPON_REALTIME_REPORT_LIST_REQUEST,
      successConstant: GET_COUPON_REALTIME_REPORT_LIST_SUCCESS,
      url,
    });
  };
};

export const getFinanceReportDashboard = (
  data = {
    endDate: '',
    startDate: '',
  },
) => {
  const url = '/Backend/FinanceReport/Dashboard';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL,
      method: 'post',
      requestConstant: GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST,
      successConstant: GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS,
      url,
    });
  };
};

export const getFinancialReconciliationQuery = (
  data = {
    endDate: 'string',
    order: 'string',
    order_by: 'string',
    page: 0,
    page_size: 0,
    startDate: 'string',
    type: 0,
  },
) => {
  const url = '/Backend/FinanceReport/FinancialReconciliationQuery';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL,
      method: 'post',
      requestConstant: GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST,
      successConstant: GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS,
      url,
    });
  };
};

export const getFinancialReconciliationQueryExport = (
  data = {
    endDate: '',
    order: '',
    order_by: '',
    page: 0,
    page_size: 0,
    startDate: '',
    type: 0,
  },
) => {
  const url = '/Backend/FinanceReport/FinancialReconciliationExport';

  return request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};
