import {
  ABOUT_INFO_EDIT_FAIL,
  ABOUT_INFO_EDIT_REQUEST,
  ABOUT_INFO_EDIT_RESET,
  ABOUT_INFO_EDIT_SUCCESS,
  ABOUT_INFO_GET_FAIL,
  ABOUT_INFO_GET_REQUEST,
  ABOUT_INFO_GET_SUCCESS,
  SETTING_EDIT_FAIL,
  SETTING_EDIT_REQUEST,
  SETTING_EDIT_RESET,
  SETTING_EDIT_SUCCESS,
  SETTING_GET_FAIL,
  SETTING_GET_REQUEST,
  SETTING_GET_SUCCESS,
} from '../../constants/data/settingConstants';

export const settingReducer = (
  state = { loading: true, settingInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SETTING_GET_REQUEST:
      return { loading: true };
    case SETTING_GET_SUCCESS:
      return { loading: false, settingInfo: payload };
    case SETTING_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const settingEditReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SETTING_EDIT_REQUEST:
      return { loading: true };
    case SETTING_EDIT_SUCCESS:
      return { loading: false, success: true };
    case SETTING_EDIT_FAIL:
      return { error: payload, loading: false };
    case SETTING_EDIT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const aboutReducer = (
  state = { aboutInfo: [], loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ABOUT_INFO_GET_REQUEST:
      return { loading: true };
    case ABOUT_INFO_GET_SUCCESS:
      return { aboutInfo: payload, loading: false };
    case ABOUT_INFO_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const aboutEditReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ABOUT_INFO_EDIT_REQUEST:
      return { loading: true };
    case ABOUT_INFO_EDIT_SUCCESS:
      return { loading: false, success: true };
    case ABOUT_INFO_EDIT_FAIL:
      return { error: payload, loading: false };
    case ABOUT_INFO_EDIT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
