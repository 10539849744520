import Select from 'react-select';

const SearchSelect = ({
  options,
  name,
  setFieldValue,
  values,
  className,
  noAll = false,
}) => {
  const handleMapToOption = arr => {
    if (!arr) {
      if (!noAll) {
        return [{ label: '全部', value: null }];
      } else {
        return [];
      }
    } else {
      if (!noAll) {
        return [
          { label: '全部', value: null },
          ...arr.map(i => ({ label: i.name, value: i.code })),
        ];
      } else {
        return arr.map(i => ({ label: i.name, value: i.code }));
      }
    }
  };
  return (
    <Select
      isLoading={!options}
      className={className}
      options={handleMapToOption(options)}
      name={name}
      onChange={({ value }) => setFieldValue(name, value)}
      value={
        handleMapToOption(options)?.find(i => i.value === values[name]) || {
          label: '全部',
          value: null,
        }
      }
    />
  );
};

export default SearchSelect;
