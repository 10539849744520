import { useFormik } from 'formik';
import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Row,
  Table,
} from 'reactstrap';

import Layout from '../../components/Layout';
import { TableHasNoData, TableLoading } from '../../components/TableHasNoData';
import TableHead from '../../components/TableHead';
import SortButton from '../../components/report/SortButtin';
import {
  getFinancialReconciliationQuery,
  getFinancialReconciliationQueryExport,
} from '../../redux/actions/data/operatingReportActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
const labelStyle = {
  minWidth: '80px',
  paddingRight: '5px',
  whiteSpace: 'nowrap',
};
const ReconciliationCheck = () => {
  const location = useLocation();
  const { loading, list } = useSelector(
    state => state.getFinanceReportReconciliationList,
  );
  const dispatch = useDispatch();

  const { values, setFieldValue, handleSubmit, handleReset, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endDate: moment().startOf('month').format('YYYY/MM/DD'),
        order: '',
        order_by: '',
        page: 1,
        page_size: 10,
        startDate: moment().format('YYYY/MM/DD'),
      },
      onReset: () => {},
      onSubmit: values => {
        const body = {
          ...values,
          endDate: values.endDate
            ? moment(values.endDate).format('YYYY/MM/DD')
            : '',
          startDate: values.startDate
            ? moment(values.startDate).format('YYYY/MM/DD')
            : '',
        };
        const params = new URLSearchParams({
          ...body,
          endDate: JSON.stringify(body.endDate),
          startDate: JSON.stringify(body.startDate),
        });

        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      },
    });

  const handlePageChange = (page = 1) => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);

      const body = {
        endDate: urlParams.get('endDate')
          ? JSON.parse(urlParams.get('endDate'))
          : moment().format('YYYY/MM/DD'),
        order: urlParams.get('order') || '',
        order_by: urlParams.get('order_by') || '',
        page: urlParams.get('page'),
        page_size: urlParams.get('page_size') || 10,
        startDate: urlParams.get('startDate')
          ? JSON.parse(urlParams.get('startDate'))
          : moment().startOf('month').format('YYYY/MM/DD'),
      };
      setValues(body);
      dispatch(getFinancialReconciliationQuery(body));
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle="對帳-帳務核對"
      items={[
        { isActive: false, page_name: '營運報表' },
        { isActive: true, page_name: '帳務核對' },
      ]}
    >
      <Card className="mb-3">
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <Row className="flex-wrap">
            <FormGroup className="col-sm-12 d-inline-flex align-items-center flex-nowrap">
              <label style={labelStyle}>日期區間</label>
              <div className="flex-wrap  d-inline-flex align-items-center flex-grow-1 gap-1">
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  selected={
                    values.startDate ? moment(values.startDate).toDate() : ''
                  }
                  onChange={date => setFieldValue('startDate', date)}
                />
                <span className="mx-3">~</span>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  style={{ minWidth: '100px' }}
                  selected={
                    values.endDate ? moment(values.endDate).toDate() : ''
                  }
                  onChange={date => setFieldValue('endDate', date)}
                  minDate={
                    values.startDate ? moment(values.startDate).toDate() : ''
                  }
                />
              </div>
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button className={style.modal_button_cancel} onClick={handleReset}>
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            onClick={() => {
              handlePageChange(1);
            }}
          >
            搜尋
          </Button>
        </CardFooter>
      </Card>
      <div className="pt-4 pb-2">
        <Button
          color="secondary"
          onClick={() => {
            const urlParams = new URLSearchParams(location.search);

            const body = {
              endDate: urlParams.get('endDate')
                ? JSON.parse(urlParams.get('endDate'))
                : moment().format('YYYY/MM/DD'),
              order: urlParams.get('order') || '',
              order_by: urlParams.get('order_by') || '',
              page: urlParams.get('page'),
              page_size: urlParams.get('page_size') || 10,
              startDate: urlParams.get('startDate')
                ? JSON.parse(urlParams.get('startDate'))
                : moment().startOf('month').format('YYYY/MM/DD'),
            };
            getFinancialReconciliationQueryExport(body)
              .then(res => {
                window.open(res.result, '_blank');
              })
              .catch(e => {
                MessageUtil.alertWanring(e);
              });
          }}
        >
          匯出帳務
        </Button>
      </div>
      <Card>
        <CardBody className={style.card_body}>
          <div className="table-responsive">
            <Table hover striped bordered>
              <TableHead>
                <tr>
                  <th>
                    <SortButton currentOrder="createTime" onSearch={() => {}}>
                      下訂日期
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="updatedate" onSearch={() => {}}>
                      帳務日期
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="orderNumber" onSearch={() => {}}>
                      主單編號
                    </SortButton>
                  </th>
                  <th>
                    <SortButton
                      currentOrder="orderItemNumber"
                      onSearch={() => {}}
                    >
                      訂單編號
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="productcode" onSearch={() => {}}>
                      商品編號
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="productname" onSearch={() => {}}>
                      商品名稱
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="quantity" onSearch={() => {}}>
                      數量
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="ActualAmount" onSearch={() => {}}>
                      帳款
                    </SortButton>
                  </th>
                  <th>
                    <SortButton
                      currentOrder="deliveryCharge"
                      onSearch={() => {}}
                    >
                      運費
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="payWay" onSearch={() => {}}>
                      付款方式
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="logisticsWay" onSearch={() => {}}>
                      配送方式
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="amountfrom" onSearch={() => {}}>
                      帳務來源
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="orderStatus" onSearch={() => {}}>
                      訂單狀態
                    </SortButton>
                  </th>
                  <th>
                    <SortButton currentOrder="payStatus" onSearch={() => {}}>
                      付款狀態
                    </SortButton>
                  </th>
                </tr>
              </TableHead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {list?.datas?.length > 0 ? (
                    list?.datas?.map(data => {
                      return (
                        <tr key={JSON.stringify(data)}>
                          <td nowrap>
                            {/*下訂日期*/}
                            {data?.createTime || '--'}
                          </td>
                          <td nowrap>
                            {/*帳務日期*/}
                            {data?.updatedate || '--'}
                          </td>
                          <td nowrap>
                            {/*主單編號*/}
                            {data?.orderNumber || '--'}
                          </td>
                          <td nowrap>
                            {/*訂單編號*/}
                            {data?.orderItemNumber || '--'}
                          </td>
                          <td nowrap>
                            {/*商品編號*/}
                            {data?.productcode || '--'}
                          </td>
                          <td>
                            {/*商品名稱*/}
                            {data?.productname || '--'}
                          </td>
                          <td>
                            {/*數量*/}
                            {data?.quantity || '--'}
                          </td>
                          <td>
                            {/*帳款*/}
                            NT${' '}
                            {Number(
                              (data?.actualAmount || 0) +
                                (data?.rechargeamount || 0),
                            ).toLocaleString()}
                          </td>
                          <td>
                            {/*運費*/}
                            NT${' '}
                            {Number(data?.deliveryCharge || 0).toLocaleString()}
                          </td>
                          <td>
                            {/*付款方式*/}
                            {data?.payWay || '--'}
                          </td>
                          <td>
                            {/*配送方式*/}
                            {data?.logisticsWay || '--'}
                          </td>
                          <td>
                            {/*帳務來源*/}
                            {data?.amountfrom || '--'}
                          </td>
                          <td>
                            {/*訂單狀態*/}
                            {data?.orderStatus || '--'}
                          </td>
                          <td>
                            {/*付款狀態*/}
                            {data?.payStatus || '--'}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          <p className="text-end fs-14">
            小計: NT$ {Number(list?.actualAmount || 0).toLocaleString()}
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={list?.current_page || 1}
              pageSize={list?.page_size || 10}
              total={list?.total_count || 0}
              onChange={handlePageChange}
            />
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
};

export default ReconciliationCheck;
