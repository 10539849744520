import Loader from 'components/Loader';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  getMailContent,
  getMailRecordsList,
  getMailTypeOptions,
} from 'redux/actions/data/mailRecordsActions';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import style from '../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';

const MailContentModal = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch();
  const { mailContent } = useSelector(state => state.getMailContent);

  useEffect(() => {
    dispatch(getMailContent(id));
  }, []);

  useEffect(() => {
    if (mailContent) {
      CommonUtil.consoleLog({ anno: 'response', data: mailContent });
    }
  }, [mailContent]);

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        信件內容
      </ModalHeader>
      <ModalBody>
        {mailContent?.content ? (
          <div dangerouslySetInnerHTML={{ __html: mailContent.content }}></div>
        ) : (
          ''
        )}
      </ModalBody>
    </Modal>
  );
};

const TableList = ({ onToggleContentModal, onSetMailId }) => {
  const { loading, mailRecordsList } = useSelector(
    state => state.getMailRecordsList,
  );
  return (
    <div className="table-responsive">
      {!loading ? (
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>發信時間</th>
              <th>類型</th>
              <th>信件主旨</th>
              <th>
                <div>收件人姓名</div>
                <div>收件人電話</div>
                <div>收件人mail</div>
              </th>
              <th>是否會員</th>
              <th>寄送成功</th>
              <th>信件內容</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {mailRecordsList?.datas?.length > 0 ? (
                mailRecordsList.datas.map(data => (
                  <tr key={data.id}>
                    <td className="text-center" style={{ minWidth: '177px' }}>
                      {/* 發信日期 */}
                      {data.sendTime
                        ? moment(data.sendTime).format('yyyy/MM/DD HH:mm:ss')
                        : '--'}
                    </td>
                    <td className="text-center">
                      {/* 類型 */}
                      {data.type}
                    </td>
                    <td>
                      {/* 信件主旨 */}
                      {data.subject}
                    </td>
                    <td className="text-center">
                      {/* 收件人姓名 */}
                      <div>{data.name}</div>
                      {/* 收件人電話 */}
                      <div>
                        {data.countryCode === '886'
                          ? `0${data.phone}`
                          : data.phone}
                      </div>
                      {/* 收件人mail */}
                      <div>{data.email}</div>
                    </td>
                    <td className="text-center">
                      {/* 是否會員 */}
                      {data.memberid !== 0 ? '是' : '否'}
                    </td>
                    <td className="text-center">
                      {/* 寄送成功 */}
                      {data.isSuccessful ? '是' : '否'}
                    </td>
                    <td className="text-center">
                      <Button
                        outline
                        color="primary"
                        onClick={() => {
                          onSetMailId(data.id);
                          onToggleContentModal();
                        }}
                      >
                        查看
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const MailRecords = () => {
  const dispatch = useDispatch();
  const [isMailContentModalOpen, setIsMailContentModalOpen] = useState(false);
  const [currentMailId, setCurrentMailId] = useState(null);
  const toggleMailContentModal = () => {
    setIsMailContentModalOpen(!isMailContentModalOpen);
  };
  const { options } = useSelector(state => state.getMailTypeOptions);

  const { values, handleSubmit, handleReset, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endDate: null,
      isMember: '',
      isSuccessful: '',
      keyword: '',
      page: 1,
      page_size: 10,
      startDate: null,
      type: '',
    },
    onSubmit: () => {
      const body = {
        ...values,
        endDate: values.endDate
          ? moment(values.endDate).format('yyyy/MM/DD')
          : moment().format('yyyy/MM/DD'),
        isMember: values.isMember ? JSON.parse(values.isMember) : null,
        isSuccessful: values.isSuccessful
          ? JSON.parse(values.isSuccessful)
          : null,
        page: 1,
        startDate: values.startDate
          ? moment(values.startDate).format('yyyy/MM/DD')
          : moment().add(-2, 'day').format('yyyy/MM/DD'),
      };
      dispatch(getMailRecordsList(body));
    },
  });

  const { loading, mailRecordsList } = useSelector(
    state => state.getMailRecordsList,
  );

  useEffect(() => {
    handleSubmit();
    dispatch(getMailTypeOptions());
  }, []);

  useEffect(() => {
    if (JSON.stringify(mailRecordsList) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: mailRecordsList });
    }
  }, [mailRecordsList]);

  const handlePageChange = page => {
    setFieldValue('page', page);
    const body = {
      ...values,
      endDate: values.endDate
        ? moment(values.endDate).format('yyyy/MM/DD')
        : moment().format('yyyy/MM/DD'),
      isMember: values.isMember ? JSON.parse(values.isMember) : null,
      isSuccessful: values.isSuccessful
        ? JSON.parse(values.isSuccessful)
        : null,
      page: page,
      startDate: values.startDate
        ? moment(values.startDate).format('yyyy/MM/DD')
        : moment().add(-2, 'day').format('yyyy/MM/DD'),
    };
    dispatch(getMailRecordsList(body));
  };

  return (
    <Layout
      pageTitle={'信件寄送記錄'}
      items={[{ isActive: true, page_name: '信件寄送記錄' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={style.show_flex}>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>關鍵字</label>
              <Input
                className={style['form-keyword']}
                placeholder="主旨、收件人姓名/電話/mail"
                value={values.keyword}
                onChange={event => setFieldValue('keyword', event.target.value)}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>類型</label>
              <Input
                className={style['form-select-short']}
                type="select"
                style={{ margin: 0 }}
                value={values.type}
                onChange={event => setFieldValue('type', event.target.value)}
              >
                <option value={''}>全部</option>
                {options &&
                  options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </Input>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>是否會員</label>
              <Input
                className={style['form-select-short']}
                type="select"
                style={{ margin: 0 }}
                value={values.isMember}
                onChange={event =>
                  setFieldValue('isMember', event.target.value)
                }
              >
                <option value={''}>全部</option>
                <option value={true}>是</option>
                <option value={false}>否</option>
              </Input>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>發信區間</label>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  maxDate={moment().toDate()}
                  selected={
                    values.startDate
                      ? moment(values.startDate).toDate()
                      : moment().add(-2, 'day').toDate()
                  }
                  onChange={date => {
                    setFieldValue('startDate', date);
                    setFieldValue(
                      'endDate',
                      date ? moment(date).add(2, 'day').toDate() : '',
                    );
                  }}
                />
              </div>
              <span style={{ margin: '1rem' }}>~</span>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  maxDate={
                    values.startDate
                      ? moment(values.startDate).add(2, 'day').toDate()
                      : moment().toDate()
                  }
                  minDate={
                    values.startDate
                      ? moment(values.startDate).toDate()
                      : moment().add(-2, 'day').toDate()
                  }
                  selected={
                    values.endDate
                      ? moment(values.endDate).toDate()
                      : moment().toDate()
                  }
                  onChange={date => setFieldValue('endDate', date)}
                />
              </div>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>是否發送成功</label>
              <Input
                className={style['form-select-short']}
                type="select"
                style={{ margin: 0 }}
                value={values.isSuccessful}
                onChange={event =>
                  setFieldValue('isSuccessful', event.target.value)
                }
              >
                <option value={''}>全部</option>
                <option value={true}>是</option>
                <option value={false}>否</option>
              </Input>
            </div>
          </div>
          <div className={style.button_position}>
            <Button className={style.modal_button_cancel} onClick={handleReset}>
              取消
            </Button>
            <Button
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="d-flex justify-content-end">
          共<span className="mx-1">{mailRecordsList?.total_count || 0}</span>筆
        </div>
        <TableList
          isContentModalOpen={isMailContentModalOpen}
          onToggleContentModal={toggleMailContentModal}
          onSetMailId={setCurrentMailId}
        />
        {!loading && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={values?.page}
              defaultPageSize={values?.page_size}
              total={mailRecordsList?.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
      {isMailContentModalOpen && (
        <MailContentModal
          isOpen={isMailContentModalOpen}
          toggle={toggleMailContentModal}
          id={currentMailId}
        />
      )}
    </Layout>
  );
};

export default MailRecords;
