export const CART_REQUEST = 'CART_REQUEST';
export const CART_SUCCESS = 'CART_SUCCESS';
export const CART_UPDATE_SUCCESS = 'CART_UPDATE_SUCCESS';
export const CART_FAIL = 'CART_FAIL';
export const CART_RESET = 'CART_RESET';

export const PURCHASE_NOTE_REQUEST = 'PURCHASE_NOTE_REQUEST';
export const PURCHASE_NOTE_SUCCESS = 'PURCHASE_NOTE_SUCCESS';
export const PURCHASE_NOTE_FAIL = 'PURCHASE_NOTE_FAIL';

export const PURCHASE_NOTE_UPDATE_REQUEST = 'PURCHASE_NOTE_UPDATE_REQUEST';
export const PURCHASE_NOTE_UPDATE_SUCCESS = 'PURCHASE_NOTE_UPDATE_SUCCESS';
export const PURCHASE_NOTE_UPDATE_FAIL = 'PURCHASE_NOTE_UPDATE_FAIL';
export const PURCHASE_NOTE_UPDATE_RESET = 'PURCHASE_NOTE_UPDATE_RESET';
