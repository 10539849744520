import {
  UUCAFE_PRODUCT_ADD_FAIL,
  UUCAFE_PRODUCT_ADD_REQUEST,
  UUCAFE_PRODUCT_ADD_SUCCESS,
  UUCAFE_PRODUCT_FAIL,
  UUCAFE_PRODUCT_LIST_FAIL,
  UUCAFE_PRODUCT_LIST_REQUEST,
  UUCAFE_PRODUCT_LIST_SUCCESS,
  UUCAFE_PRODUCT_REQUEST,
  UUCAFE_PRODUCT_SUCCESS,
  UUCAFE_PRODUCT_UPDATE_FAIL,
  UUCAFE_PRODUCT_UPDATE_REQUEST,
  UUCAFE_PRODUCT_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeProductConstants';

// ============ MEALINTRO_Menu ==================

export const cafeMealProductListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUCAFE_PRODUCT_LIST_REQUEST:
      return {
        error: null,
        list: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        loading: true,
        success: false,
      };
    case UUCAFE_PRODUCT_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_PRODUCT_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeMealProductReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      id: 0,
      imgFile: '',
      imgUrl: '',
      menuId: 0,
      name: '',
      name_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_PRODUCT_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          id: 0,
          imgFile: '',
          imgUrl: '',
          menuId: 0,
          name: '',
          name_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_PRODUCT_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_PRODUCT_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_PRODUCT_ADD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_PRODUCT_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_PRODUCT_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_PRODUCT_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_PRODUCT_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
