export const REPAIR_SETTING_GET_REQUEST = 'REPAIR_SETTING_GET_REQUEST';
export const REPAIR_SETTING_GET_SUCCESS = 'REPAIR_SETTING_GET_SUCCESS';
export const REPAIR_SETTING_GET_FAIL = 'REPAIR_SETTING_GET_FAIL';

export const REPAIR_SETTING_POST_REQUEST = 'REPAIR_SETTING_POST_REQUEST';
export const REPAIR_SETTING_POST_SUCCESS = 'REPAIR_SETTING_POST_SUCCESS';
export const REPAIR_SETTING_POST_FAIL = 'REPAIR_SETTING_POST_FAIL';
export const REPAIR_SETTING_POST_RESET = 'REPAIR_SETTING_POST_RESET';

export const MAINTAIN_LIST_REQUEST = 'MAINTAIN_LIST_REQUEST';
export const MAINTAIN_LIST_SUCCESS = 'MAINTAIN_LIST_SUCCESS';
export const MAINTAIN_LIST_FAIL = 'MAINTAIN_LIST_FAIL';

export const MAINTAIN_STATUS_OPTIONS_REQUEST =
  'MAINTAIN_STATUS_OPTIONS_REQUEST';
export const MAINTAIN_STATUS_OPTIONS_SUCCESS =
  'MAINTAIN_STATUS_OPTIONS_SUCCESS';
export const MAINTAIN_STATUS_OPTIONS_FAIL = 'MAINTAIN_STATUS_OPTIONS_FAIL';

export const MAINTAIN_DETAILS_REQUEST = 'MAINTAIN_DETAILS_REQUEST';
export const MAINTAIN_DETAILS_SUCCESS = 'MAINTAIN_DETAILS_SUCCESS';
export const MAINTAIN_DETAILS_FAIL = 'MAINTAIN_DETAILS_FAIL';

export const MAINTAIN_OPTIONS_REQUEST = 'MAINTAIN_OPTIONS_REQUEST';
export const MAINTAIN_OPTIONS_SUCCESS = 'MAINTAIN_OPTIONS_SUCCESS';
export const MAINTAIN_OPTIONS_FAIL = 'MAINTAIN_OPTIONS_FAIL';

export const MAINTAIN_DETAILS_UPDATE_REQUEST =
  'MAINTAIN_DETAILS_UPDATE_REQUEST';
export const MAINTAIN_DETAILS_UPDATE_SUCCESS =
  'MAINTAIN_DETAILS_UPDATE_SUCCESS';
export const MAINTAIN_DETAILS_UPDATE_FAIL = 'MAINTAIN_DETAILS_UPDATE_FAIL';
export const MAINTAIN_DETAILS_UPDATE_RESET = 'MAINTAIN_DETAILS_UPDATE_RESET';

export const MAINTAIN_HANDLERS_REQUEST = 'MAINTAIN_HANDLERS_REQUEST';
export const MAINTAIN_HANDLERS_SUCCESS = 'MAINTAIN_HANDLERS_SUCCESS';
export const MAINTAIN_HANDLERS_FAIL = 'MAINTAIN_HANDLERS_FAIL';

export const MAINTAIN_DEALING_UPDATE_REQUEST =
  'MAINTAIN_DEALING_UPDATE_REQUEST';
export const MAINTAIN_DEALING_UPDATE_SUCCESS =
  'MAINTAIN_DEALING_UPDATE_SUCCESS';
export const MAINTAIN_DEALING_UPDATE_FAIL = 'MAINTAIN_DEALING_UPDATE_FAIL';
export const MAINTAIN_DEALING_UPDATE_RESET = 'MAINTAIN_DEALING_UPDATE_RESET';

export const MAINTAIN_ADD_REQUEST = 'MAINTAIN_ADD_REQUEST';
export const MAINTAIN_ADD_SUCCESS = 'MAINTAIN_ADD_SUCCESS';
export const MAINTAIN_ADD_FAIL = 'MAINTAIN_ADD_FAIL';
export const MAINTAIN_ADD_RESET = 'MAINTAIN_ADD_RESET';

export const MAINTAIN_BATCH_UPDATE_REQUEST = 'MAINTAIN_BATCH_UPDATE_REQUEST';
export const MAINTAIN_BATCH_UPDATE_SUCCESS = 'MAINTAIN_BATCH_UPDATE_SUCCESS';
export const MAINTAIN_BATCH_UPDATE_FAIL = 'MAINTAIN_BATCH_UPDATE_FAIL';
export const MAINTAIN_BATCH_UPDATE_RESET = 'MAINTAIN_BATCH_UPDATE_RESET';

export const MAINTAIN_GET_INFO_REQUEST = 'MAINTAIN_GET_INFO_REQUEST';
export const MAINTAIN_GET_INFO_SUCCESS = 'MAINTAIN_GET_INFO_SUCCESS';
export const MAINTAIN_GET_INFO_FAIL = 'MAINTAIN_GET_INFO_FAIL';
export const MAINTAIN_GET_INFO_RESET = 'MAINTAIN_GET_INFO_RESET';

export const MAINTAIN_EXPORT_REQUEST = 'MAINTAIN_EXPORT_REQUEST';
export const MAINTAIN_EXPORT_SUCCESS = 'MAINTAIN_EXPORT_SUCCESS';
export const MAINTAIN_EXPORT_FAIL = 'MAINTAIN_EXPORT_FAIL';
export const MAINTAIN_EXPORT_RESET = 'MAINTAIN_EXPORT_RESET';

export const MAINTAIN_IMPORT_REQUEST = 'MAINTAIN_IMPORT_REQUEST';
export const MAINTAIN_IMPORT_SUCCESS = 'MAINTAIN_IMPORT_SUCCESS';
export const MAINTAIN_IMPORT_FAIL = 'MAINTAIN_IMPORT_FAIL';
export const MAINTAIN_IMPORT_RESET = 'MAINTAIN_IMPORT_RESET';

export const BRAND_OPTIONS_REQUEST = 'BRAND_OPTSION_REQUEST';
export const BRAND_OPTIONS_SUCCESS = 'BRAND_OPTISON_SUCCESS';
export const BRAND_OPTIONS_FAIL = 'BRAND_OPTION_FSAIL';

export const MODEL_OPTIONS_REQUEST = 'MODEL_OPTSION_REQUEST';
export const MODEL_OPTIONS_SUCCESS = 'MODEL_OPTISON_SUCCESS';
export const MODEL_OPTIONS_FAIL = 'MODEL_OPTION_FSAIL';

export const COLOR_OPTIONS_REQUEST = 'COLOR_OPTSION_REQUEST';
export const COLOR_OPTIONS_SUCCESS = 'COLOR_OPTISON_SUCCESS';
export const COLOR_OPTIONS_FAIL = 'COLOR_OPTION_FSAIL';

export const UPDATE_CREDITS_IDLE = 'UPDATE_CREDITS_IDLE';
export const UPDATE_CREDITS_REQUEST = 'UPDATE_CREDITS_REQUEST';
export const UPDATE_CREDITS_SUCCESS = 'UPDATE_CREDITS_SUCCESS';
export const UPDATE_CREDITS_FAIL = 'UPDATE_CREDUTS_FAIL';

export const UPDATE_SEND_BACK_DATE_IDLE = 'UPDATE_SEND_BACK_DATE_IDLE';
export const UPDUATE_SEND_BACK_DATE_REQUEST = 'UPDATE_SEND_BACK_DATE_REQUEST';
export const UPDATE_SEND_BACK_DATE_SUCCESS = 'UPDATE_SEND_BACK_DATE_SUCCESS';
export const UPDATE_SEND_BACK_DATE_FAIL = 'UPDATE_SEND_BACK_DATE_FAIL';

export const GET_RELATED_REGISTER_LIST_IDLE = 'GET_RELATED_REGISTER_LIST_IDLE';
export const GET_RELATED_REGISTER_LIST_REQUEST =
  'GET_RELATED_REGISTER_LIST_REQUEST';
export const GET_RELATED_REGISTER_LIST_SUCCESS =
  'GET_RELATED_REGISTER_LIST_SUCCESS';
export const GET_RELATED_REGISTER_LIST_FAIL = 'GET_RELATED_REGISTER_LIST_FAIL';

export const UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE =
  'UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE';
export const UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST =
  'UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST';
export const UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS =
  'UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS';
export const UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL =
  'UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL';

export const UPDATE_REPAIR_REGISTER_IDLE = 'UPDATE_REPAIR_REGISTER_IDLE';
export const UPDATE_REPAIR_REGISTER_REQUEST = 'UPDATE_REPAIR_REGISTER_REQUEST';
export const UPDATE_REPAIR_REGISTER_SUCCESS = 'UPDATE_REPAIR_REGISTER_SUCCESS';
export const UPDATE_REPAIR_REGISTER_FAIL = 'UPDATE_REPAIR_REGISTER_FAIL';

export const GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE =
  'GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE';
export const GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST =
  'GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST';
export const GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS =
  'GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS';
export const GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL =
  'GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL';

export const GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE =
  'GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE';
export const GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST =
  'GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST';
export const GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS =
  'GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS';
export const GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL =
  'GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL';

export const EXPORT_MAINTAINLIST_WORD_IDLE = 'EXPORT_MAINTAINLIST_WORD_IDLE';
export const EXPORT_MAINTAINLIST_WORD_REQUEST =
  'EXPORT_MAINTAINLIST_WORD_REQUEST';
export const EXPORT_MAINTAINLIST_WORD_SUCCESS =
  'EXPORT_MAINTAINLIST_WORD_SUCCESS';
export const EXPORT_MAINTAINLIST_WORD_FAIL = 'EXPORT_MAINTAINLIST_WORD_FAIL';

export const UPDATE_EMPLOYEE_ID_IDLE = 'UPDATE_EMPLOYEE_ID_IDLE';
export const UPDATE_EMPLOYEE_ID_REQUEST = 'UPDATE_EMPLOYEE_ID_REQUEST';
export const UPDATE_EMPLOYEE_ID_SUCCESS = 'UPDATE_EMPLOYEE_ID_SUCCESS';
export const UPDATE_EMPLOYEE_ID_FAIL = 'UPDATE_EMPLOYEE_ID_FAIL';
