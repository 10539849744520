import {
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  GET_ACTIVITY_CATE_OPTIONS_FAIL,
  GET_ACTIVITY_CATE_OPTIONS_REQUEST,
  GET_ACTIVITY_CATE_OPTIONS_SUCCESS,
  GET_ACTIVITY_LIST_FAIL,
  GET_ACTIVITY_LIST_REQUEST,
  GET_ACTIVITY_LIST_SUCCESS,
  GET_ACTIVITY_PRODUCTLIST_FAIL,
  GET_ACTIVITY_PRODUCTLIST_REQUEST,
  GET_ACTIVITY_PRODUCTLIST_SUCCESS,
  GET_ACTIVITY_REAPTED_PRODUCTS_FAIL,
  GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST,
  GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS,
  GET_ONE_ACTIVITY_FAIL,
  GET_ONE_ACTIVITY_REQUEST,
  GET_ONE_ACTIVITY_SUCCESS,
  SORT_ACTIVITY_FAIL,
  SORT_ACTIVITY_REQUEST,
  SORT_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from 'redux/constants/data/discountCompaignContants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getActivityList = data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  const url = '/Backend/DiscountActivity/List';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ACTIVITY_LIST_FAIL,
      method: 'post',
      requestConstant: GET_ACTIVITY_LIST_REQUEST,
      successConstant: GET_ACTIVITY_LIST_SUCCESS,
      url,
    });
  };
};

export const getActivityCateOptions = () => {
  const url = '/Backend/DiscountActivity/Catlist';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ACTIVITY_CATE_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_ACTIVITY_CATE_OPTIONS_REQUEST,
      successConstant: GET_ACTIVITY_CATE_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getActivityProductList = data => {
  const url = '/Backend/DiscountActivity/AddList_V2';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ACTIVITY_PRODUCTLIST_FAIL,
      method: 'post',
      requestConstant: GET_ACTIVITY_PRODUCTLIST_REQUEST,
      successConstant: GET_ACTIVITY_PRODUCTLIST_SUCCESS,
      url,
    });
  };
};

export const AddGroupModelList = data => {
  const url = '/Backend/DiscountActivity/AddGroupModelListQuery';
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ACTIVITY_PRODUCTLIST_FAIL,
      method: 'post',
      requestConstant: GET_ACTIVITY_PRODUCTLIST_REQUEST,
      successConstant: GET_ACTIVITY_PRODUCTLIST_SUCCESS,
      url,
    });
  };
};

export const AddHideModelList = data => {
  const url = '/Backend/HideModel/list';

  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ACTIVITY_PRODUCTLIST_FAIL,
      method: 'post',
      requestConstant: GET_ACTIVITY_PRODUCTLIST_REQUEST,
      successConstant: GET_ACTIVITY_PRODUCTLIST_SUCCESS,
      url,
    });
  };
};
export const addActivity = data => {
  const url = '/Backend/DiscountActivity/Create';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ADD_ACTIVITY_FAIL,
      method: 'post',
      requestConstant: ADD_ACTIVITY_REQUEST,
      successConstant: ADD_ACTIVITY_SUCCESS,
      url,
    });
  };
};

export const updateActivity = data => {
  const url = '/Backend/DiscountActivity/Update';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_ACTIVITY_FAIL,
      method: 'post',
      requestConstant: UPDATE_ACTIVITY_REQUEST,
      successConstant: UPDATE_ACTIVITY_SUCCESS,
      url,
    });
  };
};

export const getOneActivity = id => {
  const url = `/Backend/DiscountActivity/Get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ONE_ACTIVITY_FAIL,
      method: 'get',
      requestConstant: GET_ONE_ACTIVITY_REQUEST,
      successConstant: GET_ONE_ACTIVITY_SUCCESS,
      url,
    });
  };
};

export const deleteActivity = id => {
  const url = `/Backend/DiscountActivity/Delete/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DELETE_ACTIVITY_FAIL,
      method: 'get',
      requestConstant: DELETE_ACTIVITY_REQUEST,
      successConstant: DELETE_ACTIVITY_SUCCESS,
      url,
    });
  };
};

export const getActivityReaptedProduct = data => {
  const url = '/Backend/DiscountActivity/GetRepeatPId';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ACTIVITY_REAPTED_PRODUCTS_FAIL,
      method: 'post',
      requestConstant: GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST,
      successConstant: GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS,
      url,
    });
  };
};

export const sortActivity = data => {
  const url = '/Backend/DiscountActivity/UpdateSort';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SORT_ACTIVITY_FAIL,
      method: 'post',
      requestConstant: SORT_ACTIVITY_REQUEST,
      successConstant: SORT_ACTIVITY_SUCCESS,
      url,
    });
  };
};

export const getCouponExcludeProductList = async data => {
  const url = '/Backend/CouponSetting/CouponExcludeProductList';
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res;
    }
  });
};



export const deleteCouponExcludeProduct = async (data = []) => {
  const url = '/Backend/CouponSetting/CouponExcludeProductDelete';
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res;
    }
  });
};




export const addCouponExcludeProduct = async (data = []) => {
  const url = '/Backend/CouponSetting/CouponExcludeProductAdd';
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res;
    }
  });
};
