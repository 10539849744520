import Layout from 'components/Layout';
import ShowOriginImageModal from 'components/ShowOriginImageModal';
import ProblemDetail from 'components/staffReply/ProblemDetail';
import ProblemRecords from 'components/staffReply/ProblemRecords';
import ReplyArea from 'components/staffReply/ReplyArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getSingleMessage, getUnreadMessageCount} from 'redux/actions/data/messageActions';
import { CREATE_MESSAGE_REPLY_IDLE } from 'redux/constants/data/MessageConstant';
import MessageUtil from 'utils/MessageUtil';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';
const StaffReply = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [isOriginImageModalOpen, setIsOriginImageModalOpen] = useToggle();
  const [currentImgSrc, setCurrentImgSrc] = useState('');
  const messageId = useMemo(() => {
    return query.value.id;
  }, []);
  const { success: replySuccess, error: replyError , loading: messageReplyLoading } = useSelector(
    state => state.createMessageReply,
  );

  const { datas } = useSelector(state => state.getSingleMessage);

  useEffect(() => {
    dispatch(getSingleMessage(messageId));
  }, [messageId]);

  useEffect(() => {
    dispatch(getUnreadMessageCount());
  }, [messageReplyLoading]);

  useEffect(() => {
    if (replySuccess) {
      MessageUtil.toastSuccess('發送成功');
      dispatch({ type: CREATE_MESSAGE_REPLY_IDLE });
      dispatch(getSingleMessage(messageId));
    }
    if (replyError) {
      MessageUtil.alertWanring('發送失敗', replyError);
      dispatch({ type: CREATE_MESSAGE_REPLY_IDLE });
    }
  }, [replySuccess, replyError]);
  return (
    <Layout
      pageTitle={'問題回覆'}
      items={[
        { isActive: true, page_name: '訊息管理' },
        { isActive: true, page_name: '訊息紀錄' },
        { isActive: false, page_name: '問題回覆' },
      ]}
    >
      <ProblemDetail
        datas={datas}
        setCurrentImgSrc={setCurrentImgSrc}
        setIsOriginImageModalOpen={setIsOriginImageModalOpen}
      />
      <ProblemRecords
        datas={datas}
        setCurrentImgSrc={setCurrentImgSrc}
        setIsOriginImageModalOpen={setIsOriginImageModalOpen}
      />
      <ReplyArea id={messageId} />
      {isOriginImageModalOpen && (
        <ShowOriginImageModal
          toggle={setIsOriginImageModalOpen}
          isOpen={isOriginImageModalOpen}
          source={currentImgSrc}
        />
      )}
    </Layout>
  );
};

export default StaffReply;
