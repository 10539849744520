import Layout from 'components/Layout';
import SortingTableHead from 'components/SortingTableHead';
import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Table, Tooltip } from 'reactstrap';
import { getRewardsPointsStatisticalList } from 'redux/actions/data/operatingReportActions';

import style from '../styles/layout.module.css';
import { useStore } from '../utils/useStore';

const RewardsPointsStatisticalTable = () => {
  const dispatch = useDispatch();
  const { list } = useSelector(state => state.getRewardsPointsStatisticalList);
  const { options: StoreOptions, loading: storeLoading } = useStore();
  const { values, handleChange, handleReset, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        activeId: 0,
        dtEnd: '',
        dtStart: '',
        order: 'desc',
        order_by: 'createDate',
        page: 1,
        page_size: 10,
        salesChannel: [{ label: 'online', value: 'online' }],
      },
      onReset: () => {
        handleSubmit();
      },
      onSubmit: () => {
        const body = {
          dtEnd: values.dtEnd
            ? moment(values.dtEnd).format('yyyy/MM/DD HH:mm:ss')
            : '',
          dtStart: values.dtStart
            ? moment(values.dtStart).format('yyyy/MM/DD HH:mm:ss')
            : '',
          order: values.order,
          order_by: values.order_by,
          page: values.page,
          page_size: values.page_size,
          salesChannel: values?.salesChannel.map(s => s.value).filter(s => s),
        };
        dispatch(getRewardsPointsStatisticalList(body));
      },
    });

  const handlePaginationChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    handleSubmit();
  }, [values.activeId]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Layout
      pageTitle={'紅利點數統計表'}
      items={[
        {
          isActive: true,
          page_name: '營運報表',
        },
        {
          isActive: false,
          page_name: '紅利點數統計表',
        },
      ]}
    >
      <Card className={style.search_card}>
        <div
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1B99A2', padding: '1.5rem' }}
        >
          即時可用點數
        </div>
        <div
          className={`${style.card_body}`}
          style={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '28px',
            fontWeight: '900',
            justifyContent: 'center',
          }}
        >
          {`${list?.instantlyAvailablePoints?.toLocaleString('en-US') || 0} 點`}
        </div>
      </Card>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={style.show_flex}>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  showTimeSelect
                  selected={values.dtStart}
                  onChange={date => setFieldValue('dtStart', date)}
                />
              </div>
              <span style={{ margin: '1rem' }}>~</span>
              <div className={style['form-select']}>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  showTimeSelect
                  selected={values.dtEnd}
                  onChange={date => setFieldValue('dtEnd', date)}
                  minDate={values.dtStart}
                />
              </div>
            </div>
            <div className={style.formGroup}>
              <label>門市</label>
              <Select
                isMulti
                className="w-100"
                options={StoreOptions}
                isLoading={storeLoading}
                value={values.salesChannel}
                onChange={e => {
                  setFieldValue('salesChannel', e);
                }}
              />
            </div>
          </div>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="table-responsive">
          <Table hover striped bordered className="mb-5">
            <TableHead>
              <tr>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 0,
                      item: 'createDate',
                      up: 1,
                    }}
                    title="日期"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 2,
                      item: 'validPoints',
                      up: 3,
                    }}
                    title="當日生效點數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 4,
                      item: 'usedPoints',
                      up: 5,
                    }}
                    title="當日使用點數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 6,
                      item: 'returPoints',
                      up: 7,
                    }}
                    title="當日歸還點數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 8,
                      item: 'overduePoints',
                      up: 9,
                    }}
                    title="當日逾期點數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 10,
                      item: 'backPoints',
                      up: 11,
                    }}
                    title="當日回收點數"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 12,
                      item: 'redeemAmount_sum',
                      up: 13,
                    }}
                    title="點數折抵總金額"
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
                <th>
                  <SortingTableHead
                    activeId={{
                      down: 14,
                      item: 'finalPrice_sum',
                      up: 15,
                    }}
                    title={[
                      <span>
                        訂單實付總金額
                        <FaInfoCircle
                          className="align-text-bottom"
                          size="18"
                          title="有使用點數折抵的訂單總金額"
                          id="Tooltip"
                        />
                        <Tooltip
                          target="Tooltip"
                          isOpen={tooltipOpen}
                          toggle={toggle}
                        >
                          有使用點數折抵的訂單總金額
                        </Tooltip>
                      </span>,
                    ]}
                    setFieldValue={setFieldValue}
                    currentActiveId={values.activeId}
                  />
                </th>
              </tr>
            </TableHead>
            <tbody>
              {list.datas?.length > 0 ? (
                list.datas.map((data, i) => (
                  <tr key={i}>
                    <td>{data.createDate}</td>
                    <td>{data.validPoints.toLocaleString('en-US')}</td>
                    <td>{data.usedPoints.toLocaleString('en-US')}</td>
                    <td>{data.returPoints.toLocaleString('en-US')}</td>
                    <td>{data.overduePoints.toLocaleString('en-US')}</td>
                    <td>{data.backPoints.toLocaleString('en-US')}</td>
                    <td className="price text-black fw-normal">
                      {data.redeemAmount_sum.toLocaleString('en-US')}
                    </td>
                    <td className="price text-black fw-normal">
                      {data.finalPrice_sum.toLocaleString('en-US')}
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData colNumber={8} />
              )}
            </tbody>
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={list.current_page || 1}
            defaultPageSize={list.page_size || 10}
            total={list.total_count}
            onChange={handlePaginationChange}
          />
        </div>
      </Card>
    </Layout>
  );
};

export default RewardsPointsStatisticalTable;
