import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  FAQ_ADD_FAIL,
  FAQ_ADD_REQUEST,
  FAQ_ADD_SUCCESS,
  FAQ_CATEGORY_ADD_FAIL,
  FAQ_CATEGORY_ADD_REQUEST,
  FAQ_CATEGORY_ADD_SUCCESS,
  FAQ_CATEGORY_DELETE_FAIL,
  FAQ_CATEGORY_DELETE_REQUEST,
  FAQ_CATEGORY_DELETE_SUCCESS,
  FAQ_CATEGORY_DETAILS_FAIL,
  FAQ_CATEGORY_DETAILS_REQUEST,
  FAQ_CATEGORY_DETAILS_SUCCESS,
  FAQ_CATEGORY_FAIL,
  FAQ_CATEGORY_REQUEST,
  FAQ_CATEGORY_SUCCESS,
  FAQ_DELETE_FAIL,
  FAQ_DELETE_REQUEST,
  FAQ_DELETE_SUCCESS,
  FAQ_LIST_FAIL,
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_OPTIONS_FAIL,
  FAQ_OPTIONS_REQUEST,
  FAQ_OPTIONS_SUCCESS,
} from '../../constants/data/FAQConstants';

import { baseURL, prefix_api_path } from './api_config';

//get FAQ category
export const getFAQCategory = ({ page, keyword, status }) => {
  const url = `/Backend/FAQCategory/list?page=${page}&page_size=10${
    keyword ? `&keyword=${keyword}` : ''
  }${status ? `&status=${status}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: FAQ_CATEGORY_FAIL,
      method: 'get',
      requestConstant: FAQ_CATEGORY_REQUEST,
      successConstant: FAQ_CATEGORY_SUCCESS,
      url,
    });
  };
};

//get FAQ category details
export const getFAQCategoryDetails = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: FAQ_CATEGORY_DETAILS_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/FAQCategory/faqcategory?id=${id}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: FAQ_CATEGORY_DETAILS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response,
        type: FAQ_CATEGORY_DETAILS_FAIL,
      });
    }
  };
};

//add FAQ
export const addFAQCategory = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/FAQCategory/faqcategory',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: FAQ_CATEGORY_ADD_FAIL,
      method: 'post',
      requestConstant: FAQ_CATEGORY_ADD_REQUEST,
      successConstant: FAQ_CATEGORY_ADD_SUCCESS,
      url: '/Backend/FAQCategory/faqcategory',
    });
  };
};

//delete FAQ
export const deleteFAQCategory = cateId => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/FAQCategory/faqcategory?faqCateId=${cateId}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: FAQ_CATEGORY_DELETE_FAIL,
      method: 'delete',
      requestConstant: FAQ_CATEGORY_DELETE_REQUEST,
      successConstant: FAQ_CATEGORY_DELETE_SUCCESS,
      url: `/Backend/FAQCategory/faqcategory?faqCateId=${cateId}`,
    });
  };
};

//get FAQ List
export const getFAQList = ({ page, catId, keyword, status }) => {
  const url = `/Backend/FAQContent/list?page=${page}&page_size=10${
    catId ? `&catId=${catId}` : ''
  }${keyword ? `&keyword=${keyword}` : ''}${status ? `&status=${status}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: FAQ_LIST_FAIL,
      method: 'get',
      requestConstant: FAQ_LIST_REQUEST,
      successConstant: FAQ_LIST_SUCCESS,
      url,
    });
  };
};
export const getAllFAQList = async () => {
  const url = '/Backend/FAQContent/list?page=1&page_size=9999';

  return await request({
    method: 'get',
    url
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};


export const getAllFAQCategoryList = async () => {
  const url = '/Backend/FAQCategory/list?page=1&page_size=9999';

  return await request({
    method: 'get',
    url
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};

export const updateFAQCategoryListSort = async (data=[{
  id: 0,
  sort: 0
}]) => {
  const url = '/Backend/FAQCategory/sort';

  return await request({
    data,
    method: 'post',
    url
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};


export const updateFAQListSort = async (data=[{
  id: 0,
  sort: 0
}]) => {
  const url = '/Backend/FAQContent/sort';

  return await request({
    data,
    method: 'post',
    url
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};



//get FAQ options
export const getFAQOptions = () => {
  const url = '/Backend/FAQContent/category/options';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: FAQ_OPTIONS_FAIL,
      method: 'get',
      requestConstant: FAQ_OPTIONS_REQUEST,
      successConstant: FAQ_OPTIONS_SUCCESS,
      url,
    });
  };
};

//add FAQ
export const addFAQ = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/FAQContent/faqcontent',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: FAQ_ADD_FAIL,
      method: 'post',
      requestConstant: FAQ_ADD_REQUEST,
      successConstant: FAQ_ADD_SUCCESS,
      url: '/Backend/FAQContent/faqcontent',
    });
  };
};

//delete FAQ
export const deleteFAQ = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/FAQContent/faqcontent?faqId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: FAQ_DELETE_FAIL,
      method: 'delete',
      requestConstant: FAQ_DELETE_REQUEST,
      successConstant: FAQ_DELETE_SUCCESS,
      url: `/Backend/FAQContent/faqcontent?faqId=${id}`,
    });
  };
};
