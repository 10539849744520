import RcPagination from 'rc-pagination';
import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  FormGroup,
  Input,
  CardFooter,
} from 'reactstrap';
import MessageUtil from 'utils/MessageUtil';

import {
  deleteCafeJourneDetail,
  getCafeJourneList,
} from '../../redux/actions/data/uuCafeJourneAction';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';

import OtherModel from './JourneyModel';


const JourneySettings = () => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(state => state.cafeJourneyList);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);

  const detail = useRef({
    id: 0,
    name: '',
    prdCount: 0,
    sort: 0,
    status: 0,
    templateType: 1,
  });

  const handlePageChange = useCallback(
    (value, keyword) => {
      dispatch(
        getCafeJourneList({
          keyword,
          page: value,
          page_size: list.page_size || 10,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, list, keyword],
  );

  const handleDeleteItem = useCallback(
    async detail => {
      try {
        await deleteCafeJourneDetail({
          id: detail.id,
        });
        MessageUtil.toastSuccess('刪除成功!');
        handlePageChange(1, keyword);
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [handlePageChange, keyword],
  );

  useEffect(() => {
    handlePageChange(1, keyword);
  }, []);

  return (
    <>
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">關鍵字:</label>
              <Input
                className="flex-1"
                placeholder="輸入關鍵字"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            {modalShow && (
              <OtherModel
                openModal={modalShow}
                setOpenModel={(show, isReload) => {
                  if (isReload) {
                    handlePageChange(1, keyword);
                  }
                  setModalShow(show);
                }}
                detail={detail.current}
              />
            )}
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  detail.current = {
                    imgFile: '',
                    imgUrl: '',
                    name: '',
                    prdCount: 0,
                    sort: 0,
                    status: 0,
                    templateType: 1,
                  };
                  setModalShow(true);
                }}
              >
                新增
              </Button>
            </div>
            <p className="small text-end mb-0">共 {list.total_count} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th nowrap="nowrap">年/月</th>
                    <th nowrap="nowrap">內容</th>
                    <th nowrap="nowrap">異動時間</th>
                    <th nowrap="nowrap">功能</th>
                  </tr>
                </thead>
                <tbody>
                  {list.datas
                    .sort((a, b) => {
                      if (new Date(a.yyyymm) > new Date(b.yyyymm)) {
                        return 1;
                      }
                      if (new Date(a.yyyymm) < new Date(b.yyyymm)) {
                        return -1;
                      }
                      return 0;
                    })
                    .map((data, index) => (
                      <tr key={index}>
                        <td nowrap="nowrap">{data.yyyymm}</td>
                        <td>{data.content}</td>
                        <td>
                          <div className="text-nowrap">
                            {data.updateDateTime}
                          </div>
                          <div>{data.updater}</div>
                        </td>

                        <td nowrap="nowrap" className="text-center">
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              detail.current = data;
                              setModalShow(true);
                            }}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => {
                              MessageUtil.submitConfirm({
                                doneFun: () => {
                                  handleDeleteItem(data);
                                },
                                title: '確認是否刪除',
                              });
                            }}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={list.page_size || 10}
                total={list.total_count || 1}
                onChange={value => {
                  handlePageChange(value, keyword);
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </>
  );
};
export default memo(JourneySettings);
