import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Card, CardBody, FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import Loader from '../../components/Loader';
import {
  createSpecialPointEventSetting,
  getSpecialPointEventSettingsDetail,
  updateSpecialPointEventSetting,
} from '../../redux/actions/data/pointSettingsActions';
import {
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS,
} from '../../redux/constants/data/pointSettingConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';

const labelStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };
const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '150px',
};

const SpecialEventPointSystemDetail = () => {
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { detail, loading } = useSelector(
    state => state.specialPointEventSettingDetail,
  );

  const dispatch = useDispatch();
  const { values, errors, handleSubmit, setFieldValue, setValues } = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableWays: [],
      description: '',
      endTime: '',
      eventObject: 'all',
      month: 1,
      name: '',

      orderAmount: 0,
      quantity: 0,
      startTime: '',
      times: 0,
      type: '',
      year: 0,
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: async values => {
      if (params.action === 'view') {
        return;
      }

      const body = {
        ...values,
        availableWay: JSON.stringify(values.availableWays),
        endTime: moment(values.endTime).toDate(),
        id: params.id,
        startTime: moment(values.startTime).toDate(),
      };

      try {
        if (params.action === 'update') {
          await updateSpecialPointEventSetting(body);
          MessageUtil.alertSuccess('更新成功');
        } else {
          await createSpecialPointEventSetting(body);
          MessageUtil.alertSuccess('新增成功');
          history.push('/SpecialEventPointSystem');
        }
      } catch (e) {
        if (params.action === 'update') {
          MessageUtil.alertWanring('更新失敗', e);
        } else {
          MessageUtil.alertWanring('新增失敗', e);
        }
      }
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      availableWays: Yup.array().min(1, '請選擇指定通路'),
      description: Yup.string().required('請輸入活動名稱').max(100, '字數超過'),
      name: Yup.string().required('請輸入活動名稱').max(40, '字數超過'),
      orderAmount: Yup.number()
        .required('請輸入大於0的數字')
        .min(1, '請輸入大於0的數字'),
      quantity: Yup.number()
        .required('請輸入大於0的數字')
        .min(1, '請輸入大於0的數字'),
    }),
  });

  useEffect(() => {
    dispatch({
      type: GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST,
    });
    if (params.action === 'add') {
      dispatch({
        payload: {
          availableWays: [],
          description: '',
          endTime: moment().add(1, 'day').format('YYYY/MM/DD HH:mm:ss'),
          eventObject: 'all',
          month: 1,
          name: '',
          orderAmount: 0,
          quantity: 0,
          startTime: moment().format('YYYY/MM/DD HH:mm:ss'),
          times: 0,
          type: Number(searchParams.get('type')),
          year: 0,
        },
        type: GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS,
      });
    } else {
      dispatch(getSpecialPointEventSettingsDetail(params.id));
    }
  }, [location.search]);

  useEffect(() => {
    if (detail) {
      if (detail.availableWay) {
        detail.availableWays = JSON.parse(detail.availableWay);
      } else {
        detail.availableWays = detail.availableWays || [];
      }

      detail.quantity = Number(detail.quantity);
      detail.orderAmount = Number(detail.orderAmount);

      detail.eventObject = 'all';
      detail.times = 0;
      setValues(detail);
    }
  }, [detail]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout
      pageTitle={`特殊活動給點設定-${
        values.type === 1 ? '單筆滿額比例(%)給點' : '單筆滿額固定給點'
      }`}
      items={[
        { isActive: true, page_name: '紅利點數' },
        {
          isActive: false,
          page_name: '特殊活動給點設定',
          to: 'SpecialEventPointSystem',
        },
        {
          isActive: true,
          page_name:
            values.type === 1 ? '單筆滿額比例(%)給點' : '單筆滿額固定給點',
        },
      ]}
    >
      <Card className={style.card}>
        <CardBody>
          <div className="form-list form-label-140">
            <h4 className="title" style={titleStyle}>
              基本設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                活動名稱(中文)
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      活動名稱僅供活動辨識，不會顯示於前台
                    </small>
                  </li>
                </ul>
                <div className="d-flex">
                  <Input
                      readOnly={params.action === 'view'}
                      name="name"
                    className="form-control mx-2"
                    max="40"
                    value={values.name}
                    onChange={e => {
                      setFieldValue('name', e.target.value);
                    }}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      values?.name?.length > 40 ? 'text-danger' : ''
                    }`}
                  >
                    ({values?.name?.length || 0}/40)
                  </span>
                </div>
                {errors?.name && (
                  <div className="form-error px-2">{errors.name}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                活動時間
              </label>
              <div className="form-item">
                <div className="d-flex gap-1">
                  <DatePicker
                    disabled={params.action === 'view'}
                    className="form-control mx-2"
                    dateFormat="yyyy/MM/dd"
                    selected={moment(values.startTime || new Date()).toDate()}
                    name="startTime"
                    onChange={date => {
                      setFieldValue(
                        'startTime',
                        moment(date || new Date()).format('yyyy/MM/DD'),
                      );
                      if (
                        values.endTime &&
                        moment(date).isAfter(moment(values.endTime))
                      )
                        setFieldValue(
                          'endTime',
                          moment(date || new Date()).format('yyyy/MM/DD'),
                        );
                    }}
                    minDate={moment().toDate()}
                  />
                  <span>~</span>
                  <DatePicker
                    className="form-control mx-2"
                    dateFormat="yyyy/MM/dd"
                    disabled={params.action === 'view'}
                    selected={moment(values.endTime || new Date()).toDate()}
                    name="endTime"
                    onChange={date => {
                      setFieldValue(
                        'endTime',
                        moment(date || new Date()).format('yyyy/MM/DD'),
                      );
                    }}
                    minDate={moment(values.startTime).toDate()}
                  />
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                活動說明
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Input
                    tag="textarea"
                    rows={5}
                    name="description"
                    className="form-control mx-2"
                    max="400"
                    readOnly={params.action === 'view'}
                    value={values.description}
                    onChange={e => {
                      setFieldValue('description', e.target.value);
                    }}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      values?.description?.length > 400 ? 'text-danger' : ''
                    }`}
                  >
                    ({values?.description?.length || 0}/400)
                  </span>
                </div>
                {errors?.description && (
                  <div className="form-error px-2">{errors.description}</div>
                )}
              </div>
            </FormGroup>
            <h4 className="title" style={titleStyle}>
              折抵條件設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                活動對象
              </label>
              <div className="form-item">
                <div>
                  <label
                    className="d-inline-block m-2"
                    htmlFor="radio-object-0"
                  >
                    <input
                      disabled={params.action === 'view'}
                      type="radio"
                      checked={values.eventObject === 'all'}
                      value="all"
                      onChange={() => {
                        setFieldValue('eventObject', 'all');
                      }}
                    />
                    <span className="fw-medium px-1">全體會員</span>
                  </label>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                可使用通路
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center">
                  {['web', 'store'].map(op => {
                    return (
                      <label
                        key={`radio-availableWays-${op}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-availableWays-${op}`}
                      >
                        <input
                          disabled={params.action === 'view'}
                          type="checkbox"
                          name="availableWays"
                          value={op}
                          checked={values?.availableWays.includes(op)}
                          id={`radio-availableWays-${op}`}
                          onChange={() => {
                            const availableWays = values?.availableWays || [];
                            const index = availableWays.indexOf(op);
                            if (index > -1) {
                              availableWays.splice(index, 1);
                            } else {
                              availableWays.push(op);
                            }
                            setFieldValue('availableWays', availableWays);
                          }}
                        />
                        <span className="d-inline-block px-1 fw-medium">
                          {op === 'web' && '網頁版'}
                          {op === 'store' && '門市'}
                        </span>
                      </label>
                    );
                  })}
                </div>
                {errors?.availableWays && (
                  <div className="form-error">{errors?.availableWays}</div>
                )}
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                給點條件
              </label>
              <div className="form-item">
                <div className="d-flex gap-1 align-items-center">
                  <span>滿NT$</span>
                  <Input
                    readOnly={params.action === 'view'}
                    style={{ maxWidth: '100px' }}
                    className="w-auto"
                    name="orderAmount"
                    value={values.orderAmount}
                    type="number"
                    onChange={e => {
                      setFieldValue(
                        'orderAmount',
                        Number(e.target.value).toString(),
                      );
                    }}
                    onBlur={e => {
                      setFieldValue('orderAmount', Number(e.target.value));
                    }}
                  />
                  {values.type === 1 ? (
                    <>
                      <span>，享</span>
                      <Input
                        readOnly={params.action === 'view'}
                        style={{ maxWidth: '100px' }}
                        className="w-auto"
                        name="quantity"
                        value={values.quantity}
                        type="number"
                        onChange={e => {
                          setFieldValue(
                            'quantity',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          setFieldValue('quantity', Number(e.target.value));
                        }}
                      />
                      %回饋
                    </>
                  ) : (
                    <>
                      <span>，固定給點</span>
                      <Input
                        className="w-auto"
                        name="quantity"
                        readOnly={params.action === 'view'}
                        value={values.quantity}
                        type="number"
                        onChange={e => {
                          setFieldValue(
                            'quantity',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          setFieldValue('quantity', Number(e.target.value));
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="form-error">
                  {errors.orderAmount || errors.quantity}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                給點次數
              </label>
              <div className="form-item">
                <div>
                  <label
                    className="d-inline-block m-2"
                    htmlFor="radio-object-0"
                  >
                    <input
                      disabled={params.action === 'view'}
                      type="radio"
                      checked={values.times === 0}
                      value="all"
                      onChange={() => {
                        setFieldValue('times', 0);
                      }}
                    />
                    <span className="fw-medium px-1">
                      (單一會員) 不限回饋次數
                    </span>
                  </label>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={labelStyle}>
                給點效期
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      點數到期日固定為月底的23:59到期，若選擇當年12月即2021/12/31
                      23:59到期
                    </small>
                  </li>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      若點數生效月份大於給點效期，將自動延展至明年。例如:點數到期日設定為當年3月，若消費者於2021/6/6獲得點數，則自動延展至2022/3/31
                      23:59到期
                    </small>
                  </li>
                </ul>
                <div className="d-flex align-items-center gap-1">
                  <span className="text-nowrap">給點到期日設定為</span>
                  <select
                    className="form-control mx-2"
                    value={String(values.year)}
                    onChange={e => {
                      setFieldValue('year', Number(e.target.value));
                    }}
                    disabled={params.action === 'view'}
                  >
                    <option value={0}>當年</option>
                    <option value={1}>明年</option>
                    <option value={2}>後年</option>
                  </select>
                  <select
                    className="form-control mx-2"
                    value={String(values.month)}
                    onChange={e => {
                      setFieldValue('month', Number(e.target.value));
                    }}
                    disabled={params.action === 'view'}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(v => {
                      return (
                        <option value={v} key={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </FormGroup>
            <div className="mt-4 text-nowrap row flex-wrap">
              <div
                className="d-inline-block col-4"
                style={{ minWidth: '300px' }}
              >
                新增時間: {detail?.createTime || '--'} {detail?.creator}
              </div>
              {detail?.id && (
                <div
                  className="d-inline-block col-4"
                  style={{ minWidth: '300px' }}
                >
                  異動時間: {detail?.updateTime || '--'} {detail?.updater}
                </div>
              )}
            </div>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={() => {
                  history.goBack();
                }}
              >
                <span className="btn-wrapper--label">取消</span>
              </Button>
              {params.action !== 'view' && (
                <Button
                  type="button"
                  className={style.modal_button_submit}
                  disabled={Object.keys(errors).length > 0}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
};

export default SpecialEventPointSystemDetail;
