import React from 'react';

import style from '../styles/footer.module.css';

const Footer = () => {
  return (
    <div className={style.footer}>© 2021 PEZCROWN. All Rights Reserved</div>
  );
};

export default Footer;
