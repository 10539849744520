import { useFormik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  updatePublishDate,
} from 'redux/actions/data/memberActions';

import style from '../../styles/layout.module.css';

const EditDraftPublishedDateModal = ({ isOpen, toggle, defaultDate }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.updatePublishDate);
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: defaultDate,
      type: '草稿生效日',
    },
    onSubmit: () => {
      const body = {
        ...values,
        content: values.content
          ? moment(values.content).format('YYYY/MM/DD')
          : null,
        content_en: values.content
          ? moment(values.content).format('YYYY/MM/DD')
          : null,
      };
      dispatch(updatePublishDate(body));
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" zIndex={2000} centered>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        生效日設定
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '320px' }}>
        <div className={style.formGroup}>
          <label>生效日設定</label>
          <div className={style['form-select']}>
            <DatePicker
              className="form-control"
              dateFormat={'yyyy/MM/dd'}
              selected={values.content ? moment(values.content).toDate() : ''}
              minDate={moment().add(1, 'day').toDate()}
              onChange={date => {
                setFieldValue('content', date);
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className={`me-3 ${style.modal_button_cancel}`}
          onClick={toggle}
        >
          取消
        </Button>

        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditDraftPublishedDateModal;
