import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import ShippingTable from '../components/ship/ShippingTable';
import {
  createShipperMt,
  getShippingDealerList,
} from '../redux/actions/data/warrantyActions';
import style from '../styles/layout.module.css';
const ShipV3 = () => {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const [productList, setProductList] = useState([]);

  const { shippingDealerList, loading: shippingDealerListLoading } =
    useSelector(state => state.shippingDealerList);
  const {
    values,
    touched,
    setFieldTouched,
    setFieldValue,
    errors,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      amount: 999,
      dealer: 0,
      id: 0,
      remark: '',
      status: '',
      type: 1,
    },
    onReset: () => {
      setIsDisabled(false);
    },
    onSubmit: async values => {
      try {
        const res = await createShipperMt(
          values.dealer,
          ~~values.amount,
          values.remark,
          values.type,
        );
        setFieldValue('id', res.id);
        setFieldValue('dealer', res.suppliercode);
        setFieldValue('type', res.codeType);
        setFieldValue('status', res.status);

        setIsDisabled(true);
      } catch (e) {
        setIsDisabled(false);
      }
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(1, '請輸入大於0的數字')
        .test('min', '請輸入大於0的數字', value => {
          return value > 0;
        }),
      dealer: Yup.string().required('請選擇經銷商'),
      type: Yup.number().required('請選擇條碼類型'),
    }),
  });

  useEffect(() => {
    //取得經銷商資料
    dispatch(getShippingDealerList());
  }, []);

  return (
    <Layout
      pageTitle="出貨"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: true, page_name: '出貨' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader className={`bg-white ${style.card_header}`}>
          序號綁定設定
        </CardHeader>
        <CardBody className={style.card_body}>
          <form className="d-flex flex-wrap">
            <div className="form-group d-inline-flex align-items-center mx-3 py-2">
              <label className="required text-nowrap p-0 me-2 ">
                選擇經銷商
              </label>
              <div className="px-2">
                <Select
                  isLoading={shippingDealerListLoading}
                  isDisabled={shippingDealerListLoading}
                  className={style['form-select']}
                  options={shippingDealerList}
                  placeholder="請選擇經銷商"
                  onChange={option => {
                    setFieldTouched('dealer', true);
                    setFieldValue('dealer', option.value);
                  }}
                  value={
                    shippingDealerList?.some(s => s.value === values.dealer)
                      ? shippingDealerList?.filter(
                          s => s.value === values.dealer,
                        )[0]
                      : null
                  }
                  isDisabled={isDisabled}
                />
                {errors.dealer && touched.dealer && (
                  <div className="form-error">{errors.dealer}</div>
                )}
              </div>
            </div>
            <div className="form-group d-inline-flex align-items-center mx-3 py-2">
              <label className="p-0 me-2" htmlFor="remark">
                備註
              </label>
              <div className="px-2">
                <Input
                  type="text"
                  className="form-control"
                  onChange={e => setFieldValue('remark', e.target.value)}
                  value={values.remark}
                />
              </div>
            </div>
            <div className="form-group d-inline-flex align-items-center mx-3 py-2">
              <label className="required text-nowrap p-0 me-2">
                使用條碼類型
              </label>
              <div className="d-flex px-2">
                <label className="me-2" htmlFor="barcodeType-1">
                  <input
                    id="barcodeType-1"
                    className="me-1"
                    name="barcodeType"
                    type="radio"
                    value={1}
                    onChange={e => setFieldValue('type', 1)}
                    checked={values.type === 1}
                    disabled={isDisabled}
                  />
                  產品序號
                </label>
                <label className="me-2" htmlFor="barcodeType-2">
                  <input
                    id="barcodeType-2"
                    className="me-1"
                    name="barcodeType"
                    type="radio"
                    value={2}
                    onChange={e => setFieldValue('type', 2)}
                    checked={values.type === 2}
                    disabled={isDisabled}
                  />
                  國際條碼
                </label>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            type="button"
            disabled={productList.length > 0}
            className={style.modal_button_cancel}
            onClick={handleReset}
          >
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            disabled={Object.keys(errors).length > 0 || isDisabled}
            onClick={handleSubmit}
          >
            確認
          </Button>
        </CardFooter>
      </Card>
      {isDisabled && values.id && (
        <ShippingTable
          setInitial={() => {
            setIsDisabled(false);
            handleReset();
          }}
          productList={productList}
          setProductList={setProductList}
          shipperMtId={values.id}
          suppliercode={values.dealer}
          codeType={values.type}
          amount={values.amount}
        />
      )}
    </Layout>
  );
};

export default ShipV3;
