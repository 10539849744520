export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL';

export const ORDER_OPTIONS_REQUEST = 'ORDER_OPTIONS_REQUEST';
export const ORDER_OPTIONS_SUCCESS = 'ORDER_OPTIONS_SUCCESS';
export const ORDER_OPTIONS_FAIL = 'ORDER_OPTIONS_FAIL';

export const ORDER_OPTIONS_BY_TYPE_REQUEST = 'ORDER_OPTIONS_BY_TYPE_REQUEST';
export const ORDER_OPTIONS_BY_TYPE_SUCCESS = 'ORDER_OPTIONS_BY_TYPE_SUCCESS';
export const ORDER_OPTIONS_BY_TYPE_FAIL = 'ORDER_OPTIONS_BY_TYPE_FAIL';

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS';
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL';
export const ORDER_LIST_EXPORT_ALL_IDLE = 'ORDER_LIST_EXPORT_ALL_IDLE';
export const ORDER_LIST_EXPORT_ALL_REQUEST = 'ORDER_LIST_EXPORT_ALL_REQUEST';
export const ORDER_LIST_EXPORT_ALL_SUCCESS = 'ORDER_LIST_EXPORT_ALL_SUCCESS';
export const ORDER_LIST_EXPORT_ALL_FAIL = 'ORDER_LIST_EXPORT_ALL_FAIL';

export const ORDER_REMARK_EDIT_IDLE = 'ORDER_REMARK_EDIT_IDLE';
export const ORDER_REMARK_EDIT_REQUEST = 'ORDER_REMARK_EDIT_REQUEST';
export const ORDER_REMARK_EDIT_SUCCESS = 'ORDER_REMARK_EDIT_SUCCESS';
export const ORDER_REMARK_EDIT_FAIL = 'ORDER_REMARK_EDIT_FAIL';

export const GET_ORDER_ACTIVITIES_REQUEST = 'GET_ORDER_ACTIVITIES_REQUEST';
export const GET_ORDER_ACTIVITIES_SUCCESS = 'GET_ORDER_ACTIVITIES_SUCCESS';
export const GET_ORDER_ACTIVITIES_FAIL = 'GET_ORDER_ACTIVITIES_FAIL';

export const GET_ORDER_FREEBIE_LIST_REQUEST = 'GET_ORDER_FREEBIE_LIST_REQUEST';
export const GET_ORDER_FREEBIE_LIST_SUCCESS = 'GET_ORDER_FREEBIE_LIST_SUCCESS';
export const GET_ORDER_FREEBIE_LIST_FAIL = 'GET_ORDER_FREEBIE_LIST_FAIL';

export const GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST =
  'GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST';
export const GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS =
  'GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS';
export const GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL =
  'GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL';
