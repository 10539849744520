import React from 'react';
import DatePicker from 'react-datepicker';
import { GrClose } from 'react-icons/gr';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';

import style from '../../styles/layout.module.css';
import { useStore } from '../../utils/useStore';

const SearchCard = ({ values, setFieldValue, setIsChooseModalOpen }) => {
  const { options: StoreOptions, loading: storeLoading } = useStore();
  return (
    <React.Fragment>
      <div className={style.show_flex}>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>關鍵字</label>
          <Input
            id="keyword"
            type="text"
            placeholder="商品名稱、商品編號"
            value={values.keyword}
            onChange={e => setFieldValue('keyword', e.target.value)}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>門市</label>
          <Select
            isMulti
            className="w-100"
            options={StoreOptions}
            isLoading={storeLoading}
            value={values.salesChannel}
            onChange={e => {
              setFieldValue('salesChannel', e);
            }}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>日期區間</label>
          <div className={style['form-select']}>
            <DatePicker
              className="form-control"
              dateFormat={'yyyy/MM/dd HH:mm'}
              showTimeSelect
              selected={values.dtStart}
              onChange={date => setFieldValue('dtStart', date)}
            />
          </div>
          <span style={{ margin: '1rem' }}>~</span>
          <div className={style['form-select']}>
            <DatePicker
              className="form-control"
              dateFormat={'yyyy/MM/dd HH:mm'}
              showTimeSelect
              selected={values.dtEnd}
              onChange={date => setFieldValue('dtEnd', date)}
              minDate={values.dtStart}
            />
          </div>
        </div>
      </div>
      <div className={`${style.formGroup} ${style['mr-1p5']}`}>
        <div>
          <div className={style.show_flex}>
            <label>品牌 / 型號</label>
            <Button
              color="primary w80"
              className="btn-sm"
              outline
              onClick={setIsChooseModalOpen}
            >
              選擇
            </Button>
          </div>
          <div className={style.show_flex}>
            {values.options.map((option, i) => (
              <div
                key={i}
                className={style.show_flex}
                style={{
                  backgroundColor: '#E8E8E8',
                  margin: '0.5rem',
                  padding: '0.3rem',
                }}
              >
                {`${option.brand.label} / ${option.model.label}`}
                <div style={{ width: '20px' }} />
                <GrClose
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    // setBrandModelOptions(values.options.filter(o => o !== option));
                    setFieldValue(
                      'options',
                      values.options.filter(o => o !== option),
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SearchCard;
