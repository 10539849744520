export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';

export const PRODUCT_OPTIONS_REQUEST = 'PRODUCT_OPTIONS_REQUEST';
export const PRODUCT_OPTIONS_SUCCESS = 'PRODUCT_OPTIONS_SUCCESS';
export const PRODUCT_OPTIONS_FAIL = 'PRODUCT_OPTIONS_FAIL';

export const PRODUCT_MODEL_OPTIONS_REQUEST = 'PRODUCT_MODEL_OPTIONS_REQUEST';
export const PRODUCT_MODEL_OPTIONS_SUCCESS = 'PRODUCT_MODEL_OPTIONS_SUCCESS';
export const PRODUCT_MODEL_OPTIONS_FAIL = 'PRODUCT_MODEL_OPTIONS_FAIL';

export const PRODUCT_PROFILE_REQUEST = 'PRODUCT_PROFILE_REQUEST';
export const PRODUCT_PROFILE_SUCCESS = 'PRODUCT_PROFILE_SUCCESS';
export const PRODUCT_PROFILE_FAIL = 'PRODUCT_PROFILE_FAIL';

export const PRODUCT_EDIT_REQUEST = 'PRODUCT_EDIT_REQUEST';
export const PRODUCT_EDIT_SUCCESS = 'PRODUCT_EDIT_SUCCESS';
export const PRODUCT_EDIT_FAIL = 'PRODUCT_EDIT_FAIL';
export const PRODUCT_EDIT_RESET = 'PRODUCT_EDIT_RESET';

export const PRODUCT_BRAND_CATEGORY_REQUEST = 'PRODUCT_BRAND_CATEGORY_REQUEST';
export const PRODUCT_BRAND_CATEGORY_SUCCESS = 'PRODUCT_BRAND_CATEGORY_SUCCESS';
export const PRODUCT_BRAND_CATEGORY_FAIL = 'PRODUCT_BRAND_CATEGORY_FAIL';

export const PRODUCT_GLOBAL_CATEGORY_REQUEST =
  'PRODUCT_GLOBAL_CATEGORY_REQUEST';
export const PRODUCT_GLOBAL_CATEGORY_SUCCESS =
  'PRODUCT_GLOBAL_CATEGORY_SUCCESS';
export const PRODUCT_GLOBAL_CATEGORY_FAIL = 'PRODUCT_GLOBAL_CATEGORY_FAIL';

export const PRODUCT_CATEGORY_PRODUCTS_REQUEST =
  'PRODUCT_CATEGORY_PRODUCTS_REQUEST';
export const PRODUCT_CATEGORY_PRODUCTS_SUCCESS =
  'PRODUCT_CATEGORY_PRODUCTS_SUCCESS';
export const PRODUCT_CATEGORY_PRODUCTS_FAIL = 'PRODUCT_CATEGORY_PRODUCTS_FAIL';

export const PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST =
  'PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST';
export const PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS =
  'PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS';
export const PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL =
  'PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL';
export const PRODUCT_ADDTO_GLOBAL_CATEGORY_RESET =
  'PRODUCT_ADDTO_GLOBAL_CATEGORY_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_LINKLIST_REQUEST = 'PRODUCT_LINKLIST_REQUEST';
export const PRODUCT_LINKLIST_SUCCESS = 'PRODUCT_LINKLIST_SUCCESS';
export const PRODUCT_LINKLIST_FAIL = 'PRODUCT_LINKLIST_FAIL';

export const PRODUCT_LINK_DETAILS_REQUEST = 'PRODUCT_LINK_DETAILS_REQUEST';
export const PRODUCT_LINK_DETAILS_SUCCESS = 'PRODUCT_LINK_DETAILS_SUCCESS';
export const PRODUCT_LINK_DETAILS_FAIL = 'PRODUCT_LINK_DETAILS_FAIL';

export const PRODUCT_LINK_POST_REQUEST = 'PRODUCT_LINK_POST_REQUEST';
export const PRODUCT_LINK_POST_SUCCESS = 'PRODUCT_LINK_POST_SUCCESS';
export const PRODUCT_LINK_POST_FAIL = 'PRODUCT_LINK_POST_FAIL';
export const PRODUCT_LINK_POST_RESET = 'PRODUCT_LINK_POST_RESET';

export const PRODUCT_SPEC_LIST_REQUEST = 'PRODUCT_SPEC_LIST_REQUEST';
export const PRODUCT_SPEC_LIST_SUCCESS = 'PRODUCT_SPEC_LIST_SUCCESS';
export const PRODUCT_SPEC_LIST_FAIL = 'PRODUCT_SPEC_LIST_FAIL';

export const PRODUCT_SPEC_POST_REQUEST = 'PRODUCT_SPEC_POST_REQUEST';
export const PRODUCT_SPEC_POST_SUCCESS = 'PRODUCT_SPEC_POST_SUCCESS';
export const PRODUCT_SPEC_POST_FAIL = 'PRODUCT_SPEC_POST_FAIL';
export const PRODUCT_SPEC_POST_RESET = 'PRODUCT_SPEC_POST_RESET';

export const PRODUCT_SPEC_DELETE_REQUEST = 'PRODUCT_SPEC_DELETE_REQUEST';
export const PRODUCT_SPEC_DELETE_SUCCESS = 'PRODUCT_SPEC_DELETE_SUCCESS';
export const PRODUCT_SPEC_DELETE_FAIL = 'PRODUCT_SPEC_DELETE_FAIL';
export const PRODUCT_SPEC_DELETE_RESET = 'PRODUCT_SPEC_DELETE_RESET';

export const GET_PRODUCTPIC = 'GET_PRODUCTPIC';
export const GET_PRODUCTBG = 'GET_PRODUCTBG';
export const GET_PRODUCT_FEATURES = 'GET_PRODUCT_FEATURES';

export const PRODUCT_OPTIONS_STATE_UPDATE_IDLE =
  'PRODUCT_OPTIONS_STATE_UPDATE_IDLE';
export const PRODUCT_OPTIONS_STATE_UPDATE_REQUEST =
  'PRODUCT_OPTIONS_STATE_UPDATE_REQUEST';
export const PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS =
  'PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS';
export const PRODUCT_OPTIONS_STATE_UPDATE_FAIL =
  'PRODUCT_OPTIONS_STATE_UPDATE_FAIL';

export const NEW_PRODUCT_LIST_IDLE = 'NEW_PRODUCT_LIST_IDLE';
export const NEW_PRODUCT_LIST_REQUEST = 'NEW_PRODUCT_LIST_REQUEST';
export const NEW_PRODUCT_LIST_SUCCESS = 'NEW_PRODUCT_LIST_SUCCESS';
export const NEW_PRODUCT_LIST_FAIL = 'NEW_PRODUCT_LIST_FAIL';

export const IMPORT_PRODUCT_STOCK_FILE_IDLE = 'IMPORT_PRODUCT_STOCK_FILE_IDLE';
export const IMPORT_PRODUCT_STOCK_FILE_REQUEST =
  'IMPORT_PRODUCT_STOCK_FILE_REQUEST';
export const IMPORT_PRODUCT_STOCK_FILE_SUCCESS =
  'IMPORT_PRODUCT_STOCK_FILE_SUCCESS';
export const IMPORT_PRODUCT_STOCK_FILE_FAIL = 'IMPORT_PRODUCT_STOCK_FILE_FAIL';

export const GET_COLOR_OPTIONS_REQUEST = 'GET_COLOR_OPTIONS_REQUEST';
export const GET_COLOR_OPTIONS_SUCCESS = 'GET_COLOR_OPTIONS_SUCCESS';
export const GET_COLOR_OPTIONS_FAIL = 'GET_COLOR_OPTIONS_FAIL';

export const SET_PRODUCT_STOCK_STATUS_IDLE = 'SET_PRODUCT_STOCK_STATUS_IDLE';
export const SET_PRODUCT_STOCK_STATUS_REQUEST =
  'SET_PRODUCT_STOCK_STATUS_REQUEST';
export const SET_PRODUCT_STOCK_STATUS_SUCCESS =
  'SET_PRODUCT_STOCK_STATUS_SUCCESS';
export const SET_PRODUCT_STOCK_STATUS_FAIL = 'SET_PRODUCT_STOCK_STATUS_FAIL';

export const GET_BINDING_GIFTS_LIST_REQUEST = 'GET_BINDING_GIFTS_LIST_REQUEST';
export const GET_BINDING_GIFTS_LIST_SUCCESS = 'GET_BINDING_GIFTS_LIST_SUCCESS';
export const GET_BINDING_GIFTS_LIST_FAIL = 'GET_BINDING_GIFTS_LIST_FAIL';

export const ADD_GIFTS_IDLE = 'ADD_GIFTS_IDLE';
export const ADD_GIFTS_REQUEST = 'ADD_GIFTS_REQUEST';
export const ADD_GIFTS_SUCCESS = 'ADD_GIFTS_SUCCESS';
export const ADD_GIFTS_FAIL = 'ADD_GIFTS_FAIL';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
