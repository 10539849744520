import {
  GROUP_MODEL_LIST_REQUEST,
  GROUP_MODEL_LIST_SUCCESS,
  GROUP_MODEL_LIST_FAIL,
  GROUP_MODEL_GET_REQUEST,
  GROUP_MODEL_GET_SUCCESS,
  GROUP_MODEL_GET_FAIL,
  GROUP_MODEL_SET_SETTING_REQUEST,
  GROUP_MODEL_SET_SETTING_SUCCESS,
  GROUP_MODEL_SET_SETTING_FAIL,
  GROUP_MODEL_SET_DESC_REQUEST,
  GROUP_MODEL_SET_DESC_SUCCESS,
  GROUP_MODEL_SET_DESC_FAIL,
  GROUP_MODEL_DELETE_REQUEST,
  GROUP_MODEL_DELETE_SUCCESS,
  GROUP_MODEL_DELETE_FAIL,
  GROUP_MODEL_COPY_REQUEST,
  GROUP_MODEL_COPY_SUCCESS,
  GROUP_MODEL_COPY_FAIL,
  GROUP_MODEL_SET_SPEC_REQUEST,
  GROUP_MODEL_SET_SPEC_SUCCESS,
  GROUP_MODEL_SET_SPEC_FAIL,
  GROUP_MODEL_DELETE_SPEC_REQUEST,
  GROUP_MODEL_DELETE_SPEC_SUCCESS,
  GROUP_MODEL_DELETE_SPEC_FAIL,
  GROUP_MODEL_SET_ORDER_REQUEST,
  GROUP_MODEL_SET_ORDER_SUCCESS,
  GROUP_MODEL_SET_ORDER_FAIL,
} from 'redux/constants/data/groupModelConstants';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';



export const getGroupModelList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: GROUP_MODEL_LIST_FAIL,
      method: 'post',
      requestConstant: GROUP_MODEL_LIST_REQUEST,
      successConstant: GROUP_MODEL_LIST_SUCCESS,
      url: '/Backend/GroupModel/list',
    });
  };
};

export const UpdateModelOption = (
  mId,
  data = {
    result: true,
    type: 'isSell', // isSell、isRepair、isWarranty、isInstruction
  },
) => {
  const url = `/Backend/GroupModel/UpdateModelOption/${mId}`;
  return request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const UpdateProductOption = (
  pId,
  data = {
    result: true,
    type: 'isSell', // isSell、isRepair、isWarranty、isInstruction
  },
) => {
  const url = `/Backend/GroupModel/UpdateProductOption/${pId}`;
  return request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const getGroupModelOptions = () => {
  const url = '/Backend/GroupModel/list';
  return request({
    data: {
      keyword: '',
      page: 1,
      page_size: 0,
    },
    method: 'post',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const getGroupModelDetail = id => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: GROUP_MODEL_GET_FAIL,
      method: 'get',
      requestConstant: GROUP_MODEL_GET_REQUEST,
      successConstant: GROUP_MODEL_GET_SUCCESS,
      url: `/Backend/GroupModel/Get/${id}`,
    });
  };
};
export const copyGroupModel = id => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: GROUP_MODEL_COPY_FAIL,
      method: 'get',
      requestConstant: GROUP_MODEL_COPY_REQUEST,
      successConstant: GROUP_MODEL_COPY_SUCCESS,
      url: `/Backend/GroupModel/CopytoGroupModel?modelId=${id}`,
    });
  };
};
export const setGroupModelSetting = ({ id, data }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: GROUP_MODEL_SET_SETTING_FAIL,
      method: 'post',
      requestConstant: GROUP_MODEL_SET_SETTING_REQUEST,
      successConstant: GROUP_MODEL_SET_SETTING_SUCCESS,
      url: `/Backend/GroupModel/setSetting/${id}`,
    });
  };
};
export const setGroupModelDesc = ({ id, data }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: GROUP_MODEL_SET_DESC_FAIL,
      method: 'post',
      requestConstant: GROUP_MODEL_SET_DESC_REQUEST,
      successConstant: GROUP_MODEL_SET_DESC_SUCCESS,
      url: `/Backend/GroupModel/setDesc/${id}`,
    });
  };
};

export const setGroupModelSpecRequest = ({ mid, data }) => {
  return request({
    data,
    method: 'post',
    url: `/Backend/GroupModel/setSpec/${mid}`,
  }).then(res => {
    if (res.code === 200) {
      return res.result || [];
    } else {
      throw JSON.stringify(res);
    }
  });
};

export const setGroupModelSpec = ({ mid, data }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: GROUP_MODEL_SET_SPEC_FAIL,
      method: 'post',
      requestConstant: GROUP_MODEL_SET_SPEC_REQUEST,
      successConstant: GROUP_MODEL_SET_SPEC_SUCCESS,
      url: `/Backend/GroupModel/setSpec/${mid}`,
    });
  };
};
export const deleteGroupModelSpec = ({ mid, id }) => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: GROUP_MODEL_DELETE_SPEC_FAIL,
      method: 'get',
      requestConstant: GROUP_MODEL_DELETE_SPEC_REQUEST,
      successConstant: GROUP_MODEL_DELETE_SPEC_SUCCESS,
      url: `/Backend/GroupModel/delSpec/${mid}/${id}`,
    });
  };
};
export const setGroupModelOrder = ({ mid, data }) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: GROUP_MODEL_SET_ORDER_FAIL,
      method: 'post',
      requestConstant: GROUP_MODEL_SET_ORDER_REQUEST,
      successConstant: GROUP_MODEL_SET_ORDER_SUCCESS,
      url: `/Backend/GroupModel/setOrder/${mid}`,
    });
  };
};
export const deleteGroupModel = id => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: GROUP_MODEL_DELETE_FAIL,
      method: 'get',
      requestConstant: GROUP_MODEL_DELETE_REQUEST,
      successConstant: GROUP_MODEL_DELETE_SUCCESS,
      url: `/Backend/GroupModel/Delete/${id}`,
    });
  };
};
