import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  getWarrantyExcel,
  getWarrantySerialNumbers,
} from '../redux/actions/data/warrantyActions';
import { WARRANTY_EXCEL_PRODUCE_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function WarrantyNumDetail(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const { id } = useParams();
  const query = useQuery();
  const page_size = 10;

  const [current_page, setCurrentPage] = useState(1);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, serialNumberListInfo, error } = useSelector(
    state => state.warrantySerialNumbers,
  );
  const { result } = useSelector(state => state.warrantyExcelCreate);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/WarrantyNumDetail' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/WarrantyNumDetail' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryPage = query.value.page;

      setCurrentPage(queryPage);

      if (queryPage) {
        //單純記錄頁數
        dispatch(
          getWarrantySerialNumbers({
            page: queryPage || 1,
            page_size,
            warrantySerialNumMtID: id,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);

      //初始搜尋
      dispatch(
        getWarrantySerialNumbers({
          page: 1,
          page_size,
          warrantySerialNumMtID: id,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (result) {
      window.open(result);
    }

    return () => {
      dispatch({ type: WARRANTY_EXCEL_PRODUCE_RESET });
    };
  }, [result]);

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getWarrantySerialNumbers({
        page,
        page_size,
        warrantySerialNumMtID: id,
      }),
    );

    history.push(`${location.pathname}?page=${page}`);
  };

  const handleExport = () => {
    dispatch(getWarrantyExcel(id));
  };

  useEffect(() => {
    if (serialNumberListInfo) {
      if (JSON.stringify(serialNumberListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: serialNumberListInfo });
      }
    }
  }, [serialNumberListInfo]);

  return (
    <Layout
      pageTitle="批次序號清單"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: false, page_name: '產出保固序號', to: '/WarrantyNum' },
        { isActive: true, page_name: '批次序號清單' },
      ]}
    >
      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={() => handleExport()}>
          匯出Excel
        </Button>
        <div className="d-flex justify-content-between mt-4">
          <div>
            新增時間：{' '}
            {!loading &&
            serialNumberListInfo &&
            serialNumberListInfo.total_count > 0
              ? `${serialNumberListInfo.createDate} ${serialNumberListInfo.createTime}`
              : ' '}
          </div>
          <div>
            共
            <span className="mx-1">
              {!loading &&
              serialNumberListInfo &&
              serialNumberListInfo.total_count > 0
                ? serialNumberListInfo.total_count
                : '0'}
            </span>
            筆
          </div>
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                {/* <th scope="col">二維條碼</th> */}
                <th scope="col">品牌</th>
                <th scope="col">保固序號</th>
                <th scope="col">保固網址</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {/* <tr>
                <td className="text-nowrap">
                  <img className="w60" />
                  這是條碼圖片
                </td>
                <td className="text-nowrap"></td>
                <td>
                  {' '}
                  <span>https://www.pezcrown.com/</span>
                </td>
              </tr> */}
                {serialNumberListInfo &&
                serialNumberListInfo.warrantyDts?.length > 0 ? (
                  serialNumberListInfo.warrantyDts.map((data, index) => (
                    <tr key={index}>
                      <td>{data.brandName}</td>
                      <td>{data.warrantySerialNum}</td>
                      <td>{data.warrantyUrl}</td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          serialNumberListInfo &&
          serialNumberListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={serialNumberListInfo.current_page}
                defaultPageSize={10}
                total={serialNumberListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>
    </Layout>
  );
}
