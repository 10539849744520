import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Alert, Button, Card, CardBody, CardHeader } from 'reactstrap';

import Layout from '../../components/Layout';
import style from '../../styles/layout.module.css';
import history from '../../utils/createHistory';

const SpecialEventPointSystemAdd = () => {
  return (
    <Layout
      pageTitle={'特殊活動給點設定'}
      items={[
        { isActive: true, page_name: '紅利點數' },
        { isActive: false, page_name: '特殊活動給點設定' },
      ]}
    >
      <Alert color="secondary">
        <div className="d-flex">
          <AiOutlineInfoCircle className="my-1" />
          <span>當會員完成指定功能後，即時贈與相對應的點數</span>
        </div>
        <div className="d-flex">
          <AiOutlineInfoCircle className="my-1" />
          <span>
            消費者使用點數折抵後，若發生取消訂單或退貨，系統會自動歸還點數給消費者。
          </span>
        </div>
        <div className="d-flex">
          <AiOutlineInfoCircle className="my-1" />
          <div>
            <div>點數計算邏輯:</div>
            <div>
              - 網頁版：和一般給點一樣是過鑑賞期後才一起給點
              (同一張訂單只會給點一次)。
            </div>
            <div>
              - 門市：每天結算一次。結算時計算前一天 by
              會員的消費總金額，依消費總金額給點 (每筆消費總金額只會給一次點)。
            </div>
          </div>
        </div>
      </Alert>
      <div style={{ height: '17px' }} />
      <Card className={style.search_card}>
        <CardHeader
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1DB4B9' }}
        >
          指定活動
        </CardHeader>

        <CardBody
          className={style.card_body}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            minHeight: '120px',
          }}
        >
          {[
            { name: '單筆滿額比例(%)給點', url: '/SpecialEventPointSystemDetail/add/0' ,value: 1},
            { name: '單筆滿額固定給點', url: '/SpecialEventPointSystemDetail/add/0' ,value: 2},
          ].map((cate, index) => {
            return (
              <Button
                key={JSON.stringify(cate)}
                outline
                className={style['discount-btn']}
                onClick={() => {
                  history.push(`${cate.url}?type=${cate.value}`);
                }}
              >
                {cate.name}
              </Button>
            );
          })}
        </CardBody>
      </Card>
    </Layout>
  );
};

export default SpecialEventPointSystemAdd;
