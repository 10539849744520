import {
  ADD_MEMBER_LEVEL_SETTINGS_FAIL,
  ADD_MEMBER_LEVEL_SETTINGS_IDLE,
  ADD_MEMBER_LEVEL_SETTINGS_REQUEST,
  ADD_MEMBER_LEVEL_SETTINGS_SUCCESS,
  COPY_MEMBER_LEVEL_LIST_FAIL,
  COPY_MEMBER_LEVEL_LIST_IDLE,
  COPY_MEMBER_LEVEL_LIST_REQUEST,
  COPY_MEMBER_LEVEL_LIST_SUCCESS,
  DELETE_MEMBER_LEVEL_DRAFT_FAIL,
  DELETE_MEMBER_LEVEL_DRAFT_IDLE,
  DELETE_MEMBER_LEVEL_DRAFT_REQUEST,
  DELETE_MEMBER_LEVEL_DRAFT_SUCCESS,
  DELETE_ONE_DRAFT_FAIL,
  DELETE_ONE_DRAFT_IDLE,
  DELETE_ONE_DRAFT_REQUEST,
  DELETE_ONE_DRAFT_SUCCESS,
  EXPORT_MEMBER_INFO_FAIL,
  EXPORT_MEMBER_INFO_IDLE,
  EXPORT_MEMBER_INFO_REQUEST,
  EXPORT_MEMBER_INFO_SUCCESS,
  GET_MEMBER_LEVEL_OPTIONS_FAIL,
  GET_MEMBER_LEVEL_OPTIONS_REQUEST,
  GET_MEMBER_LEVEL_OPTIONS_SUCCESS,
  GET_MEMBER_LEVEL_SETTINGS_FAIL,
  GET_MEMBER_LEVEL_SETTINGS_REQUEST,
  GET_MEMBER_LEVEL_SETTINGS_SUCCESS,
  GET_MEMBER_SHIP_GIFT_FAIL,
  GET_MEMBER_SHIP_GIFT_REQUEST,
  GET_MEMBER_SHIP_GIFT_SUCCESS,
  GET_PUBLISH_DATE_FAIL,
  GET_PUBLISH_DATE_REQUEST,
  GET_PUBLISH_DATE_SUCCESS,
  IMPORT_MEMBER_INFO_FAIL,
  IMPORT_MEMBER_INFO_IDLE,
  IMPORT_MEMBER_INFO_REQUEST,
  IMPORT_MEMBER_INFO_SUCCESS,
  MEMBER_INFO_GET_FAIL,
  MEMBER_INFO_GET_REQUEST,
  MEMBER_INFO_GET_SUCCESS,
  MEMBER_INFO_UPDATE_FAIL,
  MEMBER_INFO_UPDATE_REQUEST,
  MEMBER_INFO_UPDATE_RESET,
  MEMBER_INFO_UPDATE_SUCCESS,
  MEMBER_LIST_FAIL,
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  UPDATE_MEMBER_LEVEL_DRAFT_FAIL,
  UPDATE_MEMBER_LEVEL_DRAFT_IDLE,
  UPDATE_MEMBER_LEVEL_DRAFT_REQUEST,
  UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS,
  UPDATE_MEMBER_LEVEL_FAIL,
  UPDATE_MEMBER_LEVEL_IDLE,
  UPDATE_MEMBER_LEVEL_REQUEST,
  UPDATE_MEMBER_LEVEL_SETTINGS_FAIL,
  UPDATE_MEMBER_LEVEL_SETTINGS_IDLE,
  UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST,
  UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS,
  UPDATE_MEMBER_LEVEL_SUCCESS,
  UPDATE_PUBLISH_DATE_FAIL,
  UPDATE_PUBLISH_DATE_IDLE,
  UPDATE_PUBLISH_DATE_REQUEST,
  UPDATE_PUBLISH_DATE_SUCCESS,
} from '../../constants/data/memberConstants';

//get member list
export const memberListReducer = (state = { memberListInfo: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case MEMBER_LIST_REQUEST:
      return { loading: true };
    case MEMBER_LIST_SUCCESS:
      return { loading: false, memberListInfo: payload };
    case MEMBER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

// member info update
export const memberInfoUpdateReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MEMBER_INFO_UPDATE_REQUEST:
      return { ...state, loading: true };
    case MEMBER_INFO_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case MEMBER_INFO_UPDATE_FAIL:
      return { ...state, error: payload, loading: false };
    case MEMBER_INFO_UPDATE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//get member list
export const memberInfoReducer = (state = { memberInfo: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case MEMBER_INFO_GET_REQUEST:
      return { loading: true };
    case MEMBER_INFO_GET_SUCCESS:
      return { loading: false, memberInfo: payload };
    case MEMBER_INFO_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const getMemberLevelSettingsReducer = (
    state = { error: null, loading: false, memberLevelList: {}, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBER_LEVEL_SETTINGS_REQUEST:
      return {
        error: null,
        loading: true,
        memberLevelList: {},
        success: false,
      };
    case GET_MEMBER_LEVEL_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        memberLevelList: payload,
        success: true,
      };
    case GET_MEMBER_LEVEL_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const addMemberLevelSettingsReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_MEMBER_LEVEL_SETTINGS_IDLE:
      return { error: null, loading: false, success: false };
    case ADD_MEMBER_LEVEL_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case ADD_MEMBER_LEVEL_SETTINGS_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_MEMBER_LEVEL_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateMemberlevleSettingsReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MEMBER_LEVEL_SETTINGS_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST:
      return { ...state, loading: false };
    case UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_MEMBER_LEVEL_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateMemberLevelRecuder = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MEMBER_LEVEL_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_MEMBER_LEVEL_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MEMBER_LEVEL_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_MEMBER_LEVEL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMemberLevelOptionsReducer = (
    state = {
      error: null,
      loading: false,
      memberLevelOptions: [],
      success: true,
    },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBER_LEVEL_OPTIONS_REQUEST:
      return {
        error: null,
        loading: true,
        memberLevelOptions: [],
        success: false,
      };
    case GET_MEMBER_LEVEL_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        memberLevelOptions: payload,
        success: true,
      };
    case GET_MEMBER_LEVEL_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const exportMemberInfoReducer = (
    state = { error: null, loading: false, result: null, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case EXPORT_MEMBER_INFO_IDLE:
      return { error: null, loading: false, result: null, success: false };
    case EXPORT_MEMBER_INFO_REQUEST:
      return { ...state, loading: true };
    case EXPORT_MEMBER_INFO_SUCCESS:
      return { ...state, loading: false, result: payload, success: true };
    case EXPORT_MEMBER_INFO_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const importMemberInfoReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case IMPORT_MEMBER_INFO_IDLE:
      return { error: null, loading: false, success: false };
    case IMPORT_MEMBER_INFO_REQUEST:
      return { ...state, loading: true };
    case IMPORT_MEMBER_INFO_SUCCESS:
      return { ...state, loading: false, success: true };
    case IMPORT_MEMBER_INFO_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const copyMemberLevelListReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case COPY_MEMBER_LEVEL_LIST_IDLE:
      return { error: null, loading: false, success: false };
    case COPY_MEMBER_LEVEL_LIST_REQUEST:
      return { ...state, loading: true };
    case COPY_MEMBER_LEVEL_LIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case COPY_MEMBER_LEVEL_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const deleteMemberLevelDraftReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_MEMBER_LEVEL_DRAFT_IDLE:
      return { error: null, loading: false, success: false };
    case DELETE_MEMBER_LEVEL_DRAFT_REQUEST:
      return { ...state, loading: true };
    case DELETE_MEMBER_LEVEL_DRAFT_SUCCESS:
      return { ...state, loading: false, success: true };
    case DELETE_MEMBER_LEVEL_DRAFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateMemberLevelDraftReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MEMBER_LEVEL_DRAFT_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_MEMBER_LEVEL_DRAFT_REQUEST:
      return { ...state, loading: false };
    case UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_MEMBER_LEVEL_DRAFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getPublishDateReducer = (
    state = { error: null, loading: false, publishedDate: null, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PUBLISH_DATE_REQUEST:
      return {
        error: null,
        loading: true,
        publishedDate: null,
        success: false,
      };
    case GET_PUBLISH_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedDate: payload,
        success: true,
      };
    case GET_PUBLISH_DATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updatePublishDateReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PUBLISH_DATE_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_PUBLISH_DATE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PUBLISH_DATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_PUBLISH_DATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const deleteOneDraftReducer = (
    state = { error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_ONE_DRAFT_IDLE:
      return { error: null, loading: false, success: false };
    case DELETE_ONE_DRAFT_REQUEST:
      return { ...state, loading: true };
    case DELETE_ONE_DRAFT_SUCCESS:
      return { ...state, loading: false, success: true };
    case DELETE_ONE_DRAFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMembershipGiftReducer = (
    state = { detail: null, error: null, loading: false, success: false },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBER_SHIP_GIFT_REQUEST:
      return {
        detail: null,
        error: null,
        loading: true,
        success: false,
      };
    case GET_MEMBER_SHIP_GIFT_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case GET_MEMBER_SHIP_GIFT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
