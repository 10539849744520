import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Card, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';
import request from '../../utils/useAxios';
import Loader from '../Loader';


const MailManage = () => {
  const [data, setData] = useState(null);

  const getContactUs = useCallback(async () => {
    const res = await request({
      method: 'get',
      url: '/Backend/Cafe_SysSetting/cafe_contactUs',
    });
    if (res.code === 200) {
      return res.result || {};
    } else {
      return {};
    }
  }, []);

  const saveContactUs = useCallback(
    async (
      data = {
        recipients: '',
        subject: '',
        subject_en: '',
      },
    ) => {
      setData(null);
      const res = await request({
        data,
        method: 'post',
        url: '/Backend/Cafe_SysSetting/cafe_contactUs/save',
      });
      if (res.code === 200) {
        getContactUs().then(data => {
          setData(data);
        });
      } else {
        setData(data);
      }
    },
    [],
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      recipients: Yup.string().required('必填!'),
      subject: Yup.string().required('必填!'),
      subject_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    getContactUs().then(data => {
      setData(data);
    });
  }, []);

  if (!data) {
    return <Loader />;
  }

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Formik
        enableReinitialize
        initialValues={{
          recipients: data.recipients || '',
          subject: data.subject || '',
          subject_en: data.subject_en || '',
          updateDateTime: data.updateDateTime || '',
          updater: data.updater || '',
        }}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label>*寄件主旨(中文)</label>
              <div className="form-item">
                <Field name="subject" className="form-control" />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*寄件主旨(英文)</label>
              <div className="form-item">
                <Field name="subject_en" className="form-control" />
                <ErrorMessage
                  name="subject_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*收件者</label>
              <div className="form-item fix-z-index">
                <Field
                  name="recipients"
                  className="form-control"
                  component="textarea"
                  row="5"
                />
                <ErrorMessage name="recipients" className="form-error" />
                <p className="text-secondary fs-14">
                  • 可同時輸入多組，請以半形逗號 , 區隔
                </p>
              </div>
            </FormGroup>
            <div style={{ minHeight: '150px' }}></div>
            <p className="fs-14">
              {props.values.updateDateTime} {props.values.updater}
            </p>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!(props.isValid && props.dirty)}
                onClick={() => {
                  saveContactUs(props.values);
                }}
              >
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(MailManage);
