export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST';
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS';
export const BRAND_LIST_FAIL = 'BRAND_LIST_FAIL';

export const BRAND_OPTIONS_REQUEST = 'BRAND_OPTIONS_REQUEST';
export const BRAND_OPTIONS_SUCCESS = 'BRAND_OPTIONS_SUCCESS';
export const BRAND_OPTIONS_FAIL = 'BRAND_OPTIONS_FAIL';

export const BRAND_UPDATESORT_REQUEST = 'BRAND_UPDATESORT_REQUEST';
export const BRAND_UPDATESORT_SUCCESS = 'BRAND_UPDATESORT_SUCCESS';
export const BRAND_UPDATESORT_FAIL = 'BRAND_UPDATESORT_FAIL';
export const BRAND_UPDATESORT_RESET = 'BRAND_UPDATESORT_RESET';

export const BRAND_DETAILS_REQUEST = 'BRAND_DETAILS_REQUEST';
export const BRAND_DETAILS_SUCCESS = 'BRAND_DETAILS_SUCCESS';
export const BRAND_DETAILS_FAIL = 'BRAND_DETAILS_FAIL';

export const BRAND_UPDATE_REQUEST = 'BRAND_UPDATE_REQUEST';
export const BRAND_UPDATE_SUCCESS = 'BRAND_UPDATE_SUCCESS';
export const BRAND_UPDATE_FAIL = 'BRAND_UPDATE_FAIL';
export const BRAND_UPDATE_RESET = 'BRAND_UPDATE_RESET';

export const BRAND_CATEGORY_REQUEST = 'BRAND_CATEGORY_REQUEST';
export const BRAND_CATEGORY_SUCCESS = 'BRAND_CATEGORY_SUCCESS';
export const BRAND_CATEGORY_FAIL = 'BRAND_CATEGORY_FAIL';

export const BRAND_CATEGORY_OPTIONS_REQUEST = 'BRAND_CATEGORY_OPTIONS_REQUEST';
export const BRAND_CATEGORY_OPTIONS_SUCCESS = 'BRAND_CATEGORY_OPTIONS_SUCCESS';
export const BRAND_CATEGORY_OPTIONS_FAIL = 'BRAND_CATEGORY_OPTIONS_FAIL';

export const BRAND_CATEGORY_ADD_REQUEST = 'BRAND_CATEGORY_ADD_REQUEST';
export const BRAND_CATEGORY_ADD_SUCCESS = 'BRAND_CATEGORY_ADD_SUCCESS';
export const BRAND_CATEGORY_ADD_FAIL = 'BRAND_CATEGORY_ADD_FAIL';
export const BRAND_CATEGORY_ADD_RESET = 'BRAND_CATEGORY_ADD_RESET';

export const BRAND_CATEGORY_UPDATE_REQUEST = 'BRAND_CATEGORY_UPDATE_REQUEST';
export const BRAND_CATEGORY_UPDATE_SUCCESS = 'BRAND_CATEGORY_UPDATE_SUCCESS';
export const BRAND_CATEGORY_UPDATE_FAIL = 'BRAND_CATEGORY_UPDATE_FAIL';
export const BRAND_CATEGORY_UPDATE_RESET = 'BRAND_CATEGORY_UPDATE_RESET';

export const BRAND_CATEGORY_REMOVE_REQUEST = 'BRAND_CATEGORY_REMOVE_REQUEST';
export const BRAND_CATEGORY_REMOVE_SUCCESS = 'BRAND_CATEGORY_REMOVE_SUCCESS';
export const BRAND_CATEGORY_REMOVE_FAIL = 'BRAND_CATEGORY_REMOVE_FAIL';
export const BRAND_CATEGORY_REMOVE_RESET = 'BRAND_CATEGORY_REMOVE_RESET';

export const BRAND_CATEGORY_SORT_REQUEST = 'BRAND_CATEGORY_SORT_REQUEST';
export const BRAND_CATEGORY_SORT_SUCCESS = 'BRAND_CATEGORY_SORT_SUCCESS';
export const BRAND_CATEGORY_SORT_FAIL = 'BRAND_CATEGORY_SORT_FAIL';
export const BRAND_CATEGORY_SORT_RESET = 'BRAND_CATEGORY_SORT_RESET';

export const BRAND_CATEGORY_PRODUCT_REQUEST = 'BRAND_CATEGORY_PRODUCT_REQUEST';
export const BRAND_CATEGORY_PRODUCT_SUCCESS = 'BRAND_CATEGORY_PRODUCT_SUCCESS';
export const BRAND_CATEGORY_PRODUCT_FAIL = 'BRAND_CATEGORY_PRODUCT_FAIL';

export const BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST =
  'BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST';
export const BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS =
  'BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS';
export const BRAND_CATEGORY_PRODUCT_REMOVE_FAIL =
  'BRAND_CATEGORY_PRODUCT_REMOVE_FAIL';
export const BRAND_CATEGORY_PRODUCT_REMOVE_RESET =
  'BRAND_CATEGORY_PRODUCT_REMOVE_RESET';

export const BRAND_CATEGORY_PRODUCT_LIST_REQUEST =
  'BRAND_CATEGORY_PRODUCT_LIST_REQUEST';
export const BRAND_CATEGORY_PRODUCT_LIST_SUCCESS =
  'BRAND_CATEGORY_PRODUCT_LIST_SUCCESS';
export const BRAND_CATEGORY_PRODUCT_LIST_FAIL =
  'BRAND_CATEGORY_PRODUCT_LIST_FAIL';

export const PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST =
  'PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST';
export const PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS =
  'PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS';
export const PRODUCT_ADDTO_BRAND_CATEGORY_FAIL =
  'PRODUCT_ADDTO_BRAND_CATEGORY_FAIL';
export const PRODUCT_ADDTO_BRAND_CATEGORY_RESET =
  'PRODUCT_ADDTO_BRAND_CATEGORY_RESET';

export const BRAND_CATEGORY_MODEL_LIST_REQUEST =
  'BRAND_CATEGORY_MODEL_LIST_REQUEST';
export const BRAND_CATEGORY_MODEL_LIST_SUCCESS =
  'BRAND_CATEGORY_MODEL_LIST_SUCCESS';
export const BRAND_CATEGORY_MODEL_LIST_FAIL = 'BRAND_CATEGORY_MODEL_LIST_FAIL';

export const BRAND_CATEGORY_MODEL_REMOVE_REQUEST =
  'BRAND_CATEGORY_MODEL_REMOVE_REQUEST';
export const BRAND_CATEGORY_MODEL_REMOVE_SUCCESS =
  'BRAND_CATEGORY_MODEL_REMOVE_SUCCESS';
export const BRAND_CATEGORY_MODEL_REMOVE_FAIL =
  'BRAND_CATEGORY_MODEL_REMOVE_FAIL';

export const BRAND_CATEGORY_MODEL_ASSIGN_REQUEST =
  'BRAND_CATEGORY_MODEL_ASSIGN_REQUEST';
export const BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS =
  'BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS';
export const BRAND_CATEGORY_MODEL_ASSIGN_FAIL =
  'BRAND_CATEGORY_MODEL_ASSIGN_FAIL';
export const BRAND_CATEGORY_MODEL_ASSIGN_RESET =
  'BRAND_CATEGORY_MODEL_ASSIGN_RESET';
export const PRODUCT_BRAND_BANNER_SETTING_SUCCESS =
  'PRODUCT_BRAND_BANNER_SETTING_SUCCESS';
export const PRODUCT_BRAND_BANNER_SETTING_REQUEST =
  'PRODUCT_BRAND_BANNER_SETTING_REQUEST';
export const PRODUCT_BRAND_BANNER_SETTING_FAIL =
  'PRODUCT_BRAND_BANNER_SETTING_FAIL';
