import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_OPTIONS_REQUEST,
  ORDER_OPTIONS_SUCCESS,
  ORDER_OPTIONS_FAIL,
  ORDER_OPTIONS_BY_TYPE_REQUEST,
  ORDER_OPTIONS_BY_TYPE_SUCCESS,
  ORDER_OPTIONS_BY_TYPE_FAIL,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_LIST_EXPORT_ALL_REQUEST,
  ORDER_LIST_EXPORT_ALL_SUCCESS,
  ORDER_LIST_EXPORT_ALL_FAIL,
  ORDER_REMARK_EDIT_REQUEST,
  ORDER_REMARK_EDIT_SUCCESS,
  ORDER_REMARK_EDIT_FAIL,
  GET_ORDER_ACTIVITIES_REQUEST,
  GET_ORDER_ACTIVITIES_SUCCESS,
  GET_ORDER_ACTIVITIES_FAIL,
  GET_ORDER_FREEBIE_LIST_REQUEST,
  GET_ORDER_FREEBIE_LIST_SUCCESS,
  GET_ORDER_FREEBIE_LIST_FAIL,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL,
} from 'redux/constants/data/orderConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
export const getOrderList = data => {
  data.suppliercode = data.suppliercode ? `${data.suppliercode}` : '';
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_LIST_FAIL,
      method: 'post',
      requestConstant: ORDER_LIST_REQUEST,
      successConstant: ORDER_LIST_SUCCESS,
      url: '/Backend/Order/list_V2',
    });
  };
};
export const getOrderOptions = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: ORDER_OPTIONS_FAIL,
      method: 'get',
      requestConstant: ORDER_OPTIONS_REQUEST,
      successConstant: ORDER_OPTIONS_SUCCESS,
      url: '/Website/General/GetAllOrderValueOption',
    });
  };
};
export const getOrderOptionsByType = type => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: ORDER_OPTIONS_BY_TYPE_FAIL,
      method: 'get',
      requestConstant: ORDER_OPTIONS_BY_TYPE_REQUEST,
      successConstant: ORDER_OPTIONS_BY_TYPE_SUCCESS,
      url: `/Website/General/GetOrderValueOption/${type}`,
    });
  };
};

export const getOrderDetail = id => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: ORDER_DETAIL_FAIL,
      method: 'get',
      requestConstant: ORDER_DETAIL_REQUEST,
      successConstant: ORDER_DETAIL_SUCCESS,
      url: `/Backend/Order/Get/${id}`,
    });
  };
};

// 宅配, 超商, 門市
export const exportOrdersInfo = data => {
  data.suppliercode = data.suppliercode ? `${data.suppliercode}` : '';

  return request({
    data,
    method: 'post',
    url: '/Backend/Order/Export',
  }).then(res => {
    if (~~res.code === 200) {
      if (res.result) {
        return res.result;
      }
      throw '依搜尋條件查無可匯出的訂單';
    } else {
      throw '依搜尋條件查無可匯出的訂單';
    }
  });
};

// 所有訂單
export const exportALLOrdersInfo = data => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Order/ExportALL' });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_LIST_EXPORT_ALL_FAIL,
      method: 'post',
      requestConstant: ORDER_LIST_EXPORT_ALL_REQUEST,
      successConstant: ORDER_LIST_EXPORT_ALL_SUCCESS,
      url: '/Backend/Order/ExportALL',
    });
  };
};

export const editOrderRemark = data => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/Order/OrderRemarkEdit',
  });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_REMARK_EDIT_FAIL,
      method: 'post',
      requestConstant: ORDER_REMARK_EDIT_REQUEST,
      successConstant: ORDER_REMARK_EDIT_SUCCESS,
      url: '/Backend/Order/OrderRemarkEdit',
    });
  };
};

export const getOrderActivities = orderItemId => {
  console.log('TODO', `/Backend/Order/GetActivities_V2/${orderItemId}`);
  const url = `/Backend/Order/GetActivities_V2/${orderItemId}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ORDER_ACTIVITIES_FAIL,
      method: 'get',
      requestConstant: GET_ORDER_ACTIVITIES_REQUEST,
      successConstant: GET_ORDER_ACTIVITIES_SUCCESS,
      url,
    });
  };
};

export const getCouponDetails = async orderItemId => {
  const url = `/Backend/Order/GetCoupon/${orderItemId}`;
  return await request({
    method: 'get',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res.message;
    }
  });
};

export const getOrderFreebieList = orderItemId => {
  const url = `/Backend/Order/freebielist_V2/${orderItemId}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ORDER_FREEBIE_LIST_FAIL,
      method: 'get',
      requestConstant: GET_ORDER_FREEBIE_LIST_REQUEST,
      successConstant: GET_ORDER_FREEBIE_LIST_SUCCESS,
      url,
    });
  };
};

export const getOrderFreebieListV2 = async orderItemId => {
  const url = `/Backend/Order/freebielist_V2/${orderItemId}`;
  return await request({
    method: 'get',
    params: { orderItemId },
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res.message;
    }
  });
};

export const OrderItemFreebieInfo = async orderItemId => {
  const url = `/Backend/Order/OrderItemFreebieInfo/${orderItemId}`;
  return await request({
    method: 'get',
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res.message;
    }
  });
};

export const getOrderActivityFreebieList = orderItemId => {
  const url = `/Backend/Order/freebielist_Activity_V2/${orderItemId}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL,
      method: 'get',
      requestConstant: GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST,
      successConstant: GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS,
      url,
    });
  };
};

export const getOrderActivityFreebieListV2 = async orderItemId => {
  const url = `/Backend/Order/freebielist_Activity_V2/${orderItemId}`;
  return await request({
    method: 'get',
    params: { orderItemId },
    url,
  }).then(res => {
    if (res.code === 200) {
      return res.result;
    } else {
      throw res.message;
    }
  });
};
