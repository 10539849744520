import {
  OVERALL_REQUEST,
  OVERALL_SUCCESS,
  OVERALL_FAIL,
  GET_OVERALL_MESSAGE_REQUEST,
  GET_OVERALL_MESSAGE_SUCCESS,
  GET_OVERALL_MESSAGE_FAIL,
} from 'redux/constants/data/overallConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getOverall = () => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Overall/Get' });
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: OVERALL_FAIL,
      method: 'get',
      requestConstant: OVERALL_REQUEST,
      successConstant: OVERALL_SUCCESS,
      url: '/Backend/Overall/Get',
    });
  };
};

export const getOverallMessage = () => {
  const url = '/Backend/Message/tackover';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_OVERALL_MESSAGE_FAIL,
      method: 'get',
      requestConstant: GET_OVERALL_MESSAGE_REQUEST,
      successConstant: GET_OVERALL_MESSAGE_SUCCESS,
      url,
    });
  };
};
