import Layout from 'components/Layout';
import ContentSettingsModal from 'components/rewardsPoints/ContentSettingsModal';
import TabContainer from 'components/rewardsPoints/TabContainer';
import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import {
  getRewardsPointsSettings,
  updateRewardsPointsEnableSettings,
} from 'redux/actions/data/rewardsPointsActions';
import { UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE } from 'redux/constants/data/rewardsPointsConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import { useToggle } from 'utils/customHooks';
const RewardsPointsSettings = () => {
  const dispatch = useDispatch();
  const [isContentSettingsModalOpen, setIsContentSettingsModalOpen] =
    useToggle();

  const { settings } = useSelector(state => state.getRewardsPointsSettings);
  const { success: updateSuccess, error: updateError } = useSelector(
    state => state.updateRewardsPointsEnableSettings,
  );
  const [currentData, setCurrentData] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    dispatch(getRewardsPointsSettings());
  }, []);

  useEffect(() => {
    if (JSON.stringify(settings) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: settings });
      settings.enableSetting.descs = settings?.enableSetting?.descs?.map(
        (desc, index) => ({
          ...desc,
          index: index,
        }),
      );
    }
  }, [settings]);

  const combineDataHandler = result => {
    const newDatas = settings?.enableSetting?.descs || [];

    if (isEdit) {
      newDatas[result.index] = {
        desc: result.content,
        desc_en: result.content_en,
        title: result.title,
        title_en: result.title_en,
      };
    } else {
      newDatas.push({
        desc: result.content,
        desc_en: result.content_en,
        title: result.title,
        title_en: result.title_en,
      });
    }

    // update
    dispatch(
      updateRewardsPointsEnableSettings({
        descs: newDatas.map(data => ({
          desc: data.desc,
          desc_en: data.desc_en,
          title: data.title,
          title_en: data.title_en,
        })),
        status: 1,
      }),
    );
  };

  const deleteItemHandler = item => {
    setIsDelete(true);
    const newDatas = settings.enableSetting.descs.filter(
      desc => desc.index != item.index,
    );
    dispatch(
      updateRewardsPointsEnableSettings({
        descs: newDatas.map(data => ({
          desc: data.desc,
          desc_en: data.desc_en,
          title: data.title,
          title_en: data.title_en,
        })),
        status: 1,
      }),
    );
  };

  useEffect(() => {
    if (isDelete) {
      if (updateSuccess) {
        MessageUtil.toastSuccess('刪除成功!');
        dispatch({ type: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE });
        setIsDelete(false);
        dispatch(getRewardsPointsSettings());
      }

      if (updateError) {
        MessageUtil.alertWanring(updateError);
        dispatch({ type: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE });
        setIsDelete(false);
      }
    }
  }, [updateSuccess, updateError, isDelete]);

  return (
    <Layout
      pageTitle={'紅利點數設定'}
      items={[
        { isActive: true, page_name: '紅利點數' },
        { isActive: false, page_name: '紅利點數設定' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span>訂單出貨完成且鑑賞期結束後，依訂單金額計算發送點數。</span>
        </div>
        <div>
          <AiOutlineInfoCircle /> <span>給點計算方式：單筆訂單金額計算。</span>
        </div>
        <div>
          <AiOutlineInfoCircle /> <span>點數計算為四捨五入。</span>
        </div>
        <div>
          <AiOutlineInfoCircle />{' '}
          <span>
            消費者使用點數折抵後，若發生取消訂單或退貨，系統會自動歸還點數給消費者。
          </span>
        </div>
        <div>
          <AiOutlineInfoCircle /> <span>儲存後即刻更新。</span>
        </div>
      </Alert>
      <TabContainer
        setIsContentSettingsModalOpen={setIsContentSettingsModalOpen}
        setCurrentData={setCurrentData}
        setIsEdit={setIsEdit}
        onDelete={deleteItemHandler}
      />
      {isContentSettingsModalOpen && (
        <ContentSettingsModal
          isOpen={isContentSettingsModalOpen}
          toggle={setIsContentSettingsModalOpen}
          data={currentData}
          onCombineData={combineDataHandler}
        />
      )}
    </Layout>
  );
};

export default RewardsPointsSettings;
