import { useFormik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getNewProductList } from 'redux/actions/data/productActions';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const TableList = ({ values, setFieldValue }) => {
  const { productList } = useSelector(state => state.newProductList);

  const datas = useMemo(() => {
    let products = [];
    productList?.datas?.forEach(data => {
      data.products.forEach(product => {
        products.push(product);
      });
    });
    return products;
  }, [productList]);

  return (
    <div className="table-responsive">
      <Table striped hover bordered className="mb-5">
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>選擇</th>
            <th>商品名稱</th>
            <th>商品料號</th>
            <th>規格</th>
          </tr>
        </thead>
        <tbody>
          {datas?.length > 0 &&
            datas.map((data, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center', width: '40px' }}>
                  <input
                    type={'radio'}
                    className={style['radio-btn']}
                    checked={data.productcode === values.productcode}
                    onChange={() => {
                      setFieldValue('productcode', data.productcode);
                    }}
                    disabled={data.hasAdded}
                  />
                </td>
                <td className="text-center">{data.productname}</td>
                <td className="text-center">{data.productcode}</td>
                <td className="text-center">
                  {`${data.size || '--'} / ${data.color || '--'}`}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const SelectGiftsModal = ({ isOpen, toggle, productcode, onSubmit }) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const { values, setFieldValue, handleSubmit, handleChange, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        keyword: '',
        page: 1,
        page_size: 10,
        productcode: productcode,

        // brand: { label: '選擇品牌', value: 0 },
        // model: { label: '選擇型號', value: '' },
        // color: { label: '選擇顏色', value: '' },
      },
      onSubmit: () => {
        setIsSubmit(true);
        dispatch(
          getNewProductList({
            keyword: values.keyword,
            page: 1,
            page_size: 10,
          }),
        );
      },
      validationSchema: Yup.object().shape({
        keyword: Yup.string().nullable().required('必填'),
      }),
    });

  const pageChangeHandler = page => {
    setFieldValue('page', page);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="lg" zIndex={2000}>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        選擇贈品
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup}>
          <Input
            id="keyword"
            className={style['form-select-shop-banner']}
            placeholder="商品名稱、料號"
            value={values.keyword}
            onChange={handleChange}
            invalid={errors.keyword && touched.keyword}
          />
          {/* <Select 
            className={`${style["form-select"]} ml-2`}
            value={values.brand}
            onChange={option => {
              setFieldValue('brand', option);
              setFieldValue('model', { label: '選擇型號', value: '' });
              setFieldValue('color', { label: '選擇顏色', value: '' });
            }}
          />
          <Select 
            className={`${style["form-select"]} ml-2`}
            value={values.model}
            onChange={option => {
              setFieldValue('model', option);
              setFieldValue('color', { label: '選擇顏色', value: '' });
            }}
          />
          <Select 
            className={`${style["form-select"]} ml-2`}
            value={values.color}
            onChange={option => {
              setFieldValue('color', option);
            }}
          /> */}
          <Button
            className={`${style.modal_button_submit} ml-2`}
            onClick={handleSubmit}
          >
            查詢
          </Button>
        </div>
        {isSubmit && (
          <React.Fragment>
            <TableList setFieldValue={setFieldValue} values={values} />
            {/* <div className="d-flex align-items-center justify-content-center">
              <RcPagination 
                // defaultCurrent={pageSetting.current_page}
                // defaultPageSize={pageSetting.page_size}
                // total={pageSetting.total_count}
                onChange={pageChangeHandler}
              />
            </div> */}
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <div className={style.button_position}>
          <Button
            className={`${style.modal_button_submit}`}
            onClick={() => {
              onSubmit(values.productcode);
              toggle();
            }}
          >
            儲存
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SelectGiftsModal;
