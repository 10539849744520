import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';

const RedeemRewardPointsDetailModal = ({ isOpen, toggle, id , data }) => {
  return (
    <Modal zIndex={2000} size="xl" centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        點數折扣明細
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <Table hover bordered striped>
          <TableHead>
            <tr>
              <th>活動名稱</th>
              <th>折抵點數</th>
              <th>折扣金額</th>
            </tr>
          </TableHead>
          <tbody>
            {data.points > 0 ? (
              <tr>
                <td className="text-center">點數折抵消費</td>
                <td className="text-center">
                  {data.points.toLocaleString('en-US')}
                </td>
                <td
                  className="text-end"
                  style={{ whiteSpace: 'nowrap' }}
                >{`-NT$ ${data.amount.toLocaleString('en-US')}`}</td>
              </tr>
            ) : (
              <TableHasNoData/>
            )}
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default RedeemRewardPointsDetailModal;
