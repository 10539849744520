import {
  ADD_BINDING_PRODUCT_FAIL,
  ADD_BINDING_PRODUCT_REQUEST,
  ADD_BINDING_PRODUCT_SUCCESS,
  CREATE_GIFT_FAIL,
  CREATE_GIFT_REQUEST,
  CREATE_GIFT_SUCCESS,
  GET_BINDING_PRODUCT_LIST_FAIL,
  GET_BINDING_PRODUCT_LIST_REQUEST,
  GET_BINDING_PRODUCT_LIST_SUCCESS,
  GET_GIFTS_LIST_FAIL,
  GET_GIFTS_LIST_REQUEST,
  GET_GIFTS_LIST_SUCCESS,
  GET_GIFT_FAIL,
  GET_GIFT_REQUEST,
  GET_GIFT_SUCCESS,
  UPDATE_GIFT_FAIL,
  UPDATE_GIFT_REQUEST,
  UPDATE_GIFT_SUCCESS,
} from 'redux/constants/data/GiftsConstants';
import {
  GET_PRODUCT_FAIL,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
} from 'redux/constants/data/productConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getGiftsList = data => {
  const url = '/Backend/Freebie/List';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_GIFTS_LIST_FAIL,
      method: 'post',
      requestConstant: GET_GIFTS_LIST_REQUEST,
      successConstant: GET_GIFTS_LIST_SUCCESS,
      url,
    });
  };
};
export const checkFreebieCode = data => {
  const url = '/Backend/Freebie/List';
  return request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res;
    }
  });
};

export const getGift = id => {
  const url = `/Backend/Freebie/Get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_GIFT_FAIL,
      method: 'get',
      requestConstant: GET_GIFT_REQUEST,
      successConstant: GET_GIFT_SUCCESS,
      url,
    });
  };
};

export const createGift = data => {
  const url = '/Backend/Freebie/Create';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_GIFT_FAIL,
      method: 'post',
      requestConstant: CREATE_GIFT_REQUEST,
      successConstant: CREATE_GIFT_SUCCESS,
      url,
    });
  };
};

export const updateGift = data => {
  const url = '/Backend/Freebie/Update';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_GIFT_FAIL,
      method: 'post',
      requestConstant: UPDATE_GIFT_REQUEST,
      successConstant: UPDATE_GIFT_SUCCESS,
      url,
    });
  };
};

export const BuyAndGetFreeList = (
  data = {
    freebieId: 0,
    globalCatId: 0,
    isAdded: true,
    keyword: 'string',
    order: 'string',
    order_by: 'string',
    page: 0,
    page_size: 0,
    productType: '一般商品',
    searchType: 'string',
    type: 0,
  },
) => {
  let url = '/Backend/Freebie/BuyAndGetFreeList';

  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_BINDING_PRODUCT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_BINDING_PRODUCT_LIST_REQUEST,
      successConstant: GET_BINDING_PRODUCT_LIST_SUCCESS,
      url,
    });
  };
};

export const getBindingProductList = data => {
  let url = '/Backend/Freebie/AddProductList';
  if(data.productType === 2){
    url = '/Backend/Freebie/AddHideProductList';
  }
  if(data.productType === 3){
    url = '/Backend/Freebie/AddGroupProductList';
  }

  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_BINDING_PRODUCT_LIST_FAIL,
      method: 'post',
      requestConstant: GET_BINDING_PRODUCT_LIST_REQUEST,
      successConstant: GET_BINDING_PRODUCT_LIST_SUCCESS,
      url,
    });
  };
};

export const addBindingProduct = data => {
  let url = '';

  switch (data.productType) {
    case '一般商品':
      url = '/Backend/Freebie/AddProductRel';
      break;
    case '組合商品':
      url = '/Backend/Freebie/AddProductRel';
      break;
    case '隱形賣場':
      url = '/Backend/Freebie/AddProductRel';
      break;
    default:
      url = '/Backend/Freebie/AddProductRel';
      break;
  }

  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ADD_BINDING_PRODUCT_FAIL,
      method: 'post',
      requestConstant: ADD_BINDING_PRODUCT_REQUEST,
      successConstant: ADD_BINDING_PRODUCT_SUCCESS,
      url,
    });
  };
};

export const getProduct = (pid, productType) => {
  let url = `/Backend/Model/getProduct/${pid}`;
  if (productType === 2) {
    url = `/Backend/HideModel/getProduct/${pid}`;
  }
  if (productType === 3) {
    url = `/Backend/GroupModel/getProduct/${pid}`;
  }
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_PRODUCT_FAIL,
      method: 'get',
      requestConstant: GET_PRODUCT_REQUEST,
      successConstant: GET_PRODUCT_SUCCESS,
      url,
    });
  };
};

export const getCouponSettingList = (freebiecode = '', page = 1) => {
  return request({
    data: {
      freebiecode,
      page,
      page_size: 10,
      type: [2],
    },
    method: 'post',
    url: '/Backend/CouponSetting/List',
  }).then(res => {
    if (~~res.code === 200) {
      return res.result;
    } else {
      throw res;
    }
  });
};
