import axios from 'axios';
import moment from 'moment/moment';
import { baseURL, prefix_api_path } from 'redux/actions/data/api_config';

import MessageUtil from './MessageUtil';

axios.interceptors.request.use(
  config => {
    const token =
      JSON.parse(localStorage.getItem('backend_auth') || 'null')?.token || null;
    config = {
      ...config,
      baseURL: baseURL + prefix_api_path,
      headers: {
        ...config.headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  response => {
    response = {
      ...response,
    };
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

/**
 *
 * @param {url, method, data, params} config
 * data   --> post {}
 */
const request = async (config, callBack) => {
  const params = {
    data: config.data,
    method: config.method,
    params: config.params,
    url: config.url,
  };

  if (params.data && Object.keys(params.data).length > 0) {
    Object.keys(params.data).forEach(key => {
      if (key.includes('Time') || key.includes('Date')) {
        if (params.data[key] && typeof params.data[key] === 'string') {
          if (params.data[key].includes('/')) {
            params.data[key] = params.data[key].split('/').join('-');
          }
          if (params.data[key].includes('T')) {
            params.data[key] = moment(params.data[key]).format(
              'YYYY-MM-DDTHH:mm:ss',
            );
          }
          if (params.data[key].includes(' ')) {
            params.data[key] = params.data[key].split(' ').join('T');
          }
        } else if (params.data[key] && params.data[key] instanceof Date) {
          params.data[key] = moment(params.data[key]).format(
            'YYYY-MM-DDTHH:mm:ss',
          );
        }
      }
    });
  }

  try {
    const { data } = await axios(params);
    return data;
  } catch (error) {
    if (error.response) {
      const res = error.response;
      return {
        code: res.status,
        message: res.data?.message ? res.data.message : res.data,
        result: null,
      };
    } else if (error.request) {
      const res = error.request;
      if (error.toJSON().config.url.includes('/Model/setSetting')) {
        MessageUtil.alertWanring('上傳檔案過大');
        callBack && callBack();
      } else {
        // MessageUtil.alertWanring(error.message);
      }
      return {
        code: res.status,
        message: res.data?.message ? res.data.message : res.data,
        result: null,
      };
    } else {
      MessageUtil.alertWanring(error.message);
      console.log('Error message', error.message);
    }
    const res = error?.response;
    const req = error?.request;

    if (req) {
      return {
        code: req.status,
        message: null,
        result: null,
      };
    }

    if (res) {
      return res?.status === 401
        ? { code: res.status, message: res.data, result: null }
        : res?.data;
    }
  }
};

export default request;
