import ExchangeSerialNumberModal from 'components/ExchangeSerialNumberModal';
import ExchangeWarrantyProductModal from 'components/ExchangeWarrantyProductModal';
import Loader from 'components/Loader';
import { Form, Formik, useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getUUOptions } from 'redux/actions/data/orderShippingActions';
import { getShippingDealerList } from 'redux/actions/data/warrantyActions';
import CommonUtil from 'utils/CommonUtil';
import { modal as modalUtils } from 'utils/modal';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  batchUpdateRepairRequest,
  deleteRepairDetail,
  exportMaintainListWord,
  exportRepairList,
  getColorModelOption,
  getMaintainList,
  getMaintainStatusOptions,
  getRepairBrandOption,
  getRepairHandlerOptions,
  getRepairModelOption,
  getRepairOptions,
  importRepairList,
  updateCredits,
  updateRepairRegisterStock,
  updateSendBackCompanyDate,
  updateSendBackDate,
} from '../redux/actions/data/repairActions';
import {
  EXPORT_MAINTAINLIST_WORD_IDLE,
  MAINTAIN_BATCH_UPDATE_RESET,
  MAINTAIN_EXPORT_RESET,
  MAINTAIN_IMPORT_RESET,
  UPDATE_CREDITS_IDLE,
  UPDATE_SEND_BACK_DATE_IDLE,
} from '../redux/constants/data/repairConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import request from '../utils/useAxios';
import useQuery from '../utils/useQuery';
import useScrapWarehouseOptions from '../utils/useScrapWarehouseOptions';

const BatchUpdateStockModal = ({ isOpen, toggle, ids, onRefresh }) => {
  const dispatch = useDispatch();
  const [stock, setStock] = useState('');
  const {
    loading: updateSendBackDateLoading,
    success: updateSendBackDateSuccess,
    error: updateSendBackDateError,
  } = useSelector(state => state.updateSendBackDate);

  const onConfirm = () => {
    const body = {
      repairIds: ids,
      stock,
    };
    dispatch(updateRepairRegisterStock(body));
  };

  useEffect(() => {
    if (updateSendBackDateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
      onRefresh();
      toggle();
    }

    if (updateSendBackDateError) {
      MessageUtil.alertWanring('更新失敗', updateSendBackDateError);
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
    }
  }, [updateSendBackDateSuccess, updateSendBackDateError]);

  const options = [
    {
      label: '請選擇庫存狀態',
      value: '',
    },
    {
      label: 'no',
      value: 'no',
    },
    {
      label: 'ok',
      value: 'ok',
    },
  ];
  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        批次更改庫存
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ height: '315px' }}>
        <div className={style.formGroup}>
          <label className={style.maintain_item_label}>庫存</label>
          <Select
            name="scrapWareHouse"
            className={style['form-select']}
            options={options}
            onChange={option => {
              setStock(option.value);
            }}
            value={options.find(o => o.value === (stock || ''))}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={!stock || updateSendBackDateLoading}
          onClick={onConfirm}
        >
          {updateSendBackDateLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const UpdateSendBackDateModal = ({ isOpen, toggle, ids, onRefresh }) => {
  const dispatch = useDispatch();
  const [sendBackDate, setSendBackDate] = useState(null);
  const {
    loading: updateSendBackDateLoading,
    success: updateSendBackDateSuccess,
    error: updateSendBackDateError,
  } = useSelector(state => state.updateSendBackDate);

  const updateDetailSendBackDate = () => {
    const body = {
      repairIds: ids,
      sendBackDate: new Date(sendBackDate).toISOString(),
    };
    dispatch(updateSendBackDate(body));
  };

  useEffect(() => {
    if (updateSendBackDateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
      onRefresh();
      toggle();
    }

    if (updateSendBackDateError) {
      MessageUtil.alertWanring('更新失敗', updateSendBackDateError);
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
    }
  }, [updateSendBackDateSuccess, updateSendBackDateError]);

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        批次更改寄回日期
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ height: '315px' }}>
        <DatePicker
          className="form-control"
          selected={sendBackDate}
          onChange={date => setSendBackDate(date)}
          dateFormat="yyyy/MM/dd"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={updateSendBackDateLoading}
          onClick={updateDetailSendBackDate}
        >
          {updateSendBackDateLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const UpdateSendBackCompanyDateModal = ({ isOpen, toggle, ids, onRefresh }) => {
  const dispatch = useDispatch();
  const [sendBackDate, setSendBackDate] = useState(null);
  const {
    loading: updateSendBackDateLoading,
    success: updateSendBackDateSuccess,
    error: updateSendBackDateError,
  } = useSelector(state => state.updateSendBackDate);

  const updateDetailSendBackDate = () => {
    const body = {
      backtoCompanyDate: new Date(sendBackDate).toISOString(),
      repairIds: ids,
    };
    dispatch(updateSendBackCompanyDate(body));
  };

  useEffect(() => {
    if (updateSendBackDateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
      onRefresh();
      toggle();
    }

    if (updateSendBackDateError) {
      MessageUtil.alertWanring('更新失敗', updateSendBackDateError);
      dispatch({ type: UPDATE_SEND_BACK_DATE_IDLE });
    }
  }, [updateSendBackDateSuccess, updateSendBackDateError]);

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        批次更改送回公司日期
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ height: '315px' }}>
        <DatePicker
          className="form-control"
          selected={sendBackDate}
          onChange={date => setSendBackDate(date)}
          dateFormat="yyyy/MM/dd"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={updateSendBackDateLoading || !sendBackDate}
          onClick={updateDetailSendBackDate}
        >
          {updateSendBackDateLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const UpdateScrapWarehouseModal = ({ isOpen, toggle, ids, onRefresh }) => {
  const { options: scrapWarehouseOptions } = useScrapWarehouseOptions();
  const [loading, setLoading] = useState(false);
  const [scrapWareHouse, setScrapWareHouse] = useState({
    label: '無',
    value: '',
  });

  const batchUpdateScrapWarehouse = useCallback(async () => {
    setLoading(true);
    try {
      await request({
        data: {
          repairRegisterIds: ids,
          scrapWarehouseCode: scrapWareHouse.value,
        },
        method: 'post',
        url: '/Backend/RepairRegister/BatchUpdateScrapWarehouse',
      });
      setLoading(false);
      onRefresh();
      toggle();
    } catch (e) {
      MessageUtil.alertWanring('更新失敗', e);
      setLoading(false);
    }
  }, [onRefresh, ids, scrapWareHouse]);

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        批次更改報廢倉庫
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <Select
          styles={{
            menuPortal: (provided, state) => ({
              ...provided,
              zIndex: 99999,
            }),
          }}
          name="scrapWareHouse"
          className={style['form-select']}
          options={
            scrapWarehouseOptions && [
              { label: '無', value: '' },
              ...scrapWarehouseOptions,
            ]
          }
          onChange={option => {
            setScrapWareHouse(option);
          }}
          menuPortalTarget={document.body}
          value={scrapWareHouse}
          style={{ margin: 0 }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={loading}
          onClick={batchUpdateScrapWarehouse}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const UpdateCreditsModal = ({ isOpen, toggle, ids, onRefresh }) => {
  const dispatch = useDispatch();
  const [isCredit, setIsCredit] = useState(true);
  const {
    success: updateCreditSuccess,
    error: updateCreditError,
    loading: updateCreditLoading,
  } = useSelector(state => state.updateCredits);

  useEffect(() => {
    if (updateCreditSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_CREDITS_IDLE });
      onRefresh();
      toggle();
    }

    if (updateCreditError) {
      MessageUtil.alertWanring('更新失敗', updateCreditError);
      dispatch({ type: UPDATE_CREDITS_IDLE });
    }
  }, [updateCreditSuccess, updateCreditError]);

  const updateDetailCredits = () => {
    const body = {
      isCredit,
      repairIds: ids,
    };
    dispatch(updateCredits(body));
  };

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        批次更改credit
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.show_flex}>
          <div className="me-5">
            <input
              type="radio"
              onChange={() => {
                setIsCredit(true);
              }}
              checked={isCredit}
            />
            <span style={{ marginLeft: '0.2rem' }}>是</span>
          </div>
          <div className="me-5">
            <input
              type="radio"
              onChange={() => {
                setIsCredit(false);
              }}
              checked={!isCredit}
            />
            <span style={{ marginLeft: '0.2rem' }}>否</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={updateCreditLoading}
          onClick={updateDetailCredits}
        >
          {updateCreditLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const MaintainanceList = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const { options: scrapWarehouseOptions } = useScrapWarehouseOptions();
  const [showScrapWarehouseModal, toggleScrapWarehouseModal] = useState(false);
  const [prevParams, setPrevParams] = useState(null);
  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand: { label: '請選擇品牌', value: 0 },
      color: { label: '請選擇顏色', value: '' },
      endDate: '',
      handler: { label: '請選擇', value: 0 },
      hasFinishedProcessing: null,
      isCredit: '',
      keyword: '',
      model: { label: '請選擇型號', value: '' },
      number: '',
      numberType: '',
      page: 1,
      page_size: 50,
      scrapWareHouse: { label: '全部', value: '' },
      sendBackEndDate: '',
      sendBackStartDate: '',
      senderType: '',
      sourceList: [],
      startDate: '',
      statusCode: { label: '全部', value: 0 },
      stock: '',
      storeList: [],
      supplierList: [],
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      const body = {
        brandId: values.brand.value,
        color: values.color.value,
        endDate: values.endDate
          ? moment(values.endDate).format('YYYY/MM/DD')
          : null,
        handler: values.handler.value,
        hasFinishedProcessing:
          values.hasFinishedProcessing === ''
            ? null
            : JSON.parse(values.hasFinishedProcessing),
        isCredit: values.isCredit === '' ? null : JSON.parse(values.isCredit),
        keyword: `${values.keyword || ''}`,
        model: values.model.value,
        number: `${values.number || ''}`,
        numberType: values.numberType,
        page: values.page,
        page_size: values.page_size,
        scrapWareHouseCode: String(values.scrapWareHouse.value),
        sendBackEndDate: values.sendBackEndDate
          ? moment(values.sendBackEndDate).format('YYYY/MM/DD')
          : null,
        sendBackStartDate: values.sendBackStartDate
          ? moment(values.sendBackStartDate).format('YYYY/MM/DD')
          : null,
        senderType: values.senderType,
        sourceList: values.sourceList,
        startDate: values.startDate
          ? moment(values.startDate).format('YYYY/MM/DD')
          : null,
        statusCode: values?.statusCode?.value || 0,
        stock: values.stock,
        storeList: values.storeList.map(store => store.value),
        supplierList: values.supplierList.map(supplier => supplier.value),
      };
      const params = new URLSearchParams({
        ...body,
        endDate: JSON.stringify(body.endDate),
        keyword: body.keyword ? JSON.stringify(body.keyword) : '',
        number: body.number ? JSON.stringify(body.number) : '',
        sendBackEndDate: JSON.stringify(body.sendBackEndDate),
        sendBackStartDate: JSON.stringify(body.sendBackStartDate),
        sourceList: JSON.stringify(body.sourceList),
        startDate: JSON.stringify(body.startDate),
        statusCode: String(values?.statusCode?.value || 0),
        storeList: JSON.stringify(body.storeList),
        supplierList: JSON.stringify(body.supplierList),
      });
      history.push(
        `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
      );
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        brandId: query.value.brandId || values.brand.value,
        color: query.value.color || values.color.value,
        endDate: query.value.endDate ? JSON.parse(query.value.endDate) : null,
        handler: query.value.handler || values.handler.value,
        hasFinishedProcessing: query.value.hasFinishedProcessing
          ? JSON.parse(query.value.hasFinishedProcessing)
          : null,
        isCredit: query.value.isCredit
          ? JSON.parse(query.value.isCredit)
          : null,
        keyword: query.value.keyword ? JSON.parse(query.value.keyword) : '',
        model: query.value.model || values.model.value,
        number: query.value.number ? JSON.parse(query.value.number) : '',
        scrapWareHouseCode: String(
          query?.value?.scrapWareHouseCode || values.scrapWareHouse.value || '',
        ),
        sendBackEndDate: query.value.sendBackEndDate
          ? JSON.parse(query.value.sendBackEndDate)
          : null,
        sendBackStartDate: query.value.sendBackStartDate
          ? JSON.parse(query.value.sendBackStartDate)
          : null,
        sourceList: query.value.sourceList
          ? JSON.parse(query.value.sourceList)
          : [],
        startDate: query.value.startDate
          ? JSON.parse(query.value.startDate)
          : null,
        statusCode: Number(query.value.statusCode || 0),
        storeList: query.value.storeList
          ? JSON.parse(query.value.storeList)
          : [],
        supplierList: query.value.supplierList
          ? JSON.parse(query.value.supplierList)
          : [],
      };
      const value = {
        ...body,
        brand: repairBrandOptions?.find(
          option => option.value == query.value.brandId,
        ) || { label: '請選擇品牌', value: 0 },
        color: repairColorOptions?.find(
          option => option.value == query.value.color,
        ) || { label: '請選擇顏色', value: '' },
        handler: maintainHandlerOptions?.find(
          option => option.value == query.value.handler,
        ) || { label: '請選擇', value: 0 },
        model: repairModelOptions?.find(
          option => option.value == query.value.model,
        ) || { label: '請選擇型號', value: '' },
        scrapWareHouse: scrapWarehouseOptions?.find(
          option => option.value == query.value.scrapWareHouseCode,
        ) || { label: '全部', value: '' },
        statusCode: maintainStatusOptions?.find(
          option => String(option.value) === String(query.value.statusCode),
        ),
        storeList: body.storeList.map(store =>
          uuStoreOptions?.find(uu => uu.value == store),
        ),
        supplierList: body.supplierList.map(supplier =>
          shippingDealerList?.find(dealer => dealer.value == supplier),
        ),
      };

      delete body.brand;
      setValues(value);
      dispatch(getMaintainList(body));
    } else {
      resetForm();
    }
  }, [location.search]);

  const [modal, setModal] = useState(false);
  const [renewStatus, setRenewStatus] = useState({
    label: '請選擇狀態',
    value: '',
  });

  const [renewIds, setRenewIds] = useState([]);

  const { repairBrandOptions, loading: repairBrandOptionsLoading } =
    useSelector(state => state.repairBrandOptions);
  const { repairModelOptions, loading: repairModelOptionsLoading } =
    useSelector(state => state.repairModelOptions);
  const { repairColorOptions, loading: repairColorOptionsLoading } =
    useSelector(state => state.repairColorOptions);

  const { maintainStatusOptions, loading: maintainStatusOptionsLoading } =
    useSelector(state => state.maintainStatusOptions);

  const { loading, maintainListInfo, error } = useSelector(
    state => state.maintainList,
  );
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.maintainBatchUpdate);
  const { result, loading: maintainExportLoading } = useSelector(
    state => state.maintainExport,
  );
  const { result: mailResults } = useSelector(state => state.maintainImport);

  useEffect(() => {
    dispatch(getRepairBrandOption(0, 0));
    dispatch(getRepairHandlerOptions());
    dispatch(getShippingDealerList());
    dispatch(getUUOptions());
    dispatch(getRepairOptions());
  }, []);

  useEffect(() => {
    if (!maintainStatusOptions?.length) {
      dispatch(getMaintainStatusOptions());
    }
  }, [maintainStatusOptions]);

  useEffect(() => {
    dispatch(getRepairModelOption(0, 0, values.brand.value));
  }, [values.brand.value]);

  useEffect(() => {
    dispatch(getColorModelOption(0, 0, values.model.value));
  }, [values.model.value]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!!');
      setRenewIds([]);
      setRenewStatus({ label: '請選擇狀態', value: 0 });
      handleSubmit();
      setModal(!modal);
      dispatch({ type: MAINTAIN_BATCH_UPDATE_RESET });
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: MAINTAIN_BATCH_UPDATE_RESET });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (result) {
      window.open(result);
      handleSubmit();
    }

    return () => {
      dispatch({ type: MAINTAIN_EXPORT_RESET });
    };
  }, [result]);

  useEffect(() => {
    if (mailResults && mailResults.allSnedResult === false) {
      //寄信失敗
      //列出saveResult為false的單號
      const text =
        mailResults &&
        mailResults.mailResults
          .map(
            data =>
              data.snedResult === false && `<h4>${data.repairRegisterNum}</h4>`,
          )
          .join('');
      MessageUtil.alertWanring(text);
    } else if (mailResults && mailResults.allSaveResult === false) {
      //儲存失敗
      //列出saveResult為false的單號
      const text =
        mailResults &&
        mailResults.mailResults
          .map(
            data =>
              data.saveResult === false && `<h4>${data.repairRegisterNum}</h4>`,
          )
          .join('');
      MessageUtil.alertWanring(text);
    } else if (
      (mailResults && mailResults.allSnedResult) ||
      (mailResults && mailResults.allSaveResult)
    ) {
      //成功
      MessageUtil.toastSuccess('儲存成功!!');
      handleSubmit();
    }

    return () => {
      dispatch({ type: MAINTAIN_IMPORT_RESET });
    };
  }, [mailResults]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleChangePageSize = page_size => {
    setFieldValue('page_size', page_size);
    handleSubmit();
  };

  const handlePageChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  const handleSearch = () => {
    setFieldValue('page', 1);
    handleSubmit();
  };

  const handleCheck = (id, checked) => {
    if (checked) {
      setRenewIds([...renewIds, id]);
    } else {
      setRenewIds(renewIds.filter(renewId => renewId !== id));
    }
  };

  const handleExport = () => {
    if (renewIds.length > 0) {
      MessageUtil.confirm('確定執行匯出?', '', () => {
        dispatch(exportRepairList(renewIds));
      });
    } else {
      MessageUtil.alertWanring('請勾選要匯出的維修單');
    }
  };

  const handleUpload = e => {
    let formData = new FormData();
    formData.append('importFile', e.target.files[0]);
    dispatch(importRepairList(formData));
  };

  const deleteDetail = id => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        try {
          await deleteRepairDetail(id);
          MessageUtil.toastSuccess('刪除成功!');
          history.push(`${location.pathname}${location.search}&reload=true`);
          handleSubmit();
        } catch (e) {
          MessageUtil.alertWanring('刪除失敗', e);
        }
      },
      title: '確定刪除?',
    });
  };

  const [isUpdateCreditsModalOpen, setIsUpdateCreditsModalOpen] =
    useState(false);
  const toggleUpdateCreditsModal = () => {
    setIsUpdateCreditsModalOpen(!isUpdateCreditsModalOpen);
  };

  const updateList = () => {
    handleSubmit();
  };

  const [isUpdateSendBackDateModalOpen, setisUpdateSendBackDateModalOpen] =
    useState(false);
  const toggleUpdateSendBackDateModal = () => {
    setisUpdateSendBackDateModalOpen(!isUpdateSendBackDateModalOpen);
  };

  const [
    isUpdateSendBackCompanyDateModalOpen,
    setisUpdateSendBackCompanyDateModalOpen,
  ] = useState(false);
  const toggleUpdateSendBackCompanyDateModal = () => {
    setisUpdateSendBackCompanyDateModalOpen(
      !isUpdateSendBackCompanyDateModalOpen,
    );
  };

  const setCopyFlag = () => {
    localStorage.setItem('isCopy', '0');
  };

  const removeCopyFlag = () => {
    localStorage.removeItem('isCopy');
  };

  // 保固註冊 - 折舊換新 modal
  const [
    isExchangeWarrantyProductModalOpen,
    setIsExchangeWarrantyProductModalOpen,
  ] = useState(false);
  const toggleExchangeWarrantyProductModal = () => {
    setIsExchangeWarrantyProductModalOpen(!isExchangeWarrantyProductModalOpen);
  };

  // 更換產品序號 modal
  const [isExchangeSerialNumberModalOpen, setIsExchangeSerialNumberModalOpen] =
    useState(false);
  const toggleExchangeSerialNumberModal = () => {
    setIsExchangeSerialNumberModalOpen(!isExchangeSerialNumberModalOpen);
  };

  const [isUpdateStockModalOpen, setIsUpdateStockModalOpen] = useState(false);
  const toggleUpdateStockModal = () => {
    setIsUpdateStockModalOpen(!isUpdateStockModalOpen);
  };

  const [currentDetailInfo, setCurrentDetailInfo] = useState(null);

  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const { uuoptionsInfo } = useSelector(state => state.uuoptions);

  useEffect(() => {
    if (maintainListInfo) {
      if (JSON.stringify(maintainListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: maintainListInfo });
      }
    }
  }, [maintainListInfo]);

  const {
    loading: exportWordLoading,
    success: exportWordSuccess,
    error: exportWordError,
    result: wordFile,
  } = useSelector(state => state.exportMaintainListWord);

  useEffect(() => {
    if (exportWordSuccess && wordFile) {
      MessageUtil.toastSuccess('匯出成功!');
      window.open(wordFile);
      setRenewIds([]);
      dispatch({ type: EXPORT_MAINTAINLIST_WORD_IDLE });
    }

    if (exportWordError) {
      MessageUtil.alertWanring('匯出失敗', exportWordError);
      dispatch({ type: EXPORT_MAINTAINLIST_WORD_IDLE });
    }
  }, [exportWordSuccess, exportWordError]);

  const exportMaintainList = () => {
    dispatch(exportMaintainListWord(renewIds));
  };

  const { maintainHandlerOptions } = useSelector(
    state => state.maintainHandlerOptions,
  );

  const hasFinishedProcessingOptions = useMemo(() => {
    return [
      { label: '全部', value: 0 },
      ...(maintainStatusOptions || []).filter(
        m =>
          m.label.includes('已取消') ||
          m.label.includes('已寄回') ||
          m.label.includes('已結案') ||
          m.label.includes('產品由顧客領回'),
      ),
    ];
  }, [maintainStatusOptions]);

  const unFinishedProcessingOptions = useMemo(() => {
    return [
      { label: '全部', value: 0 },
      ...(maintainStatusOptions || []).filter(
        m =>
          !(
            m.label.includes('已取消') ||
            m.label.includes('已寄回') ||
            m.label.includes('已結案') ||
            m.label.includes('產品由顧客領回')
          ),
      ),
    ];
  }, [maintainStatusOptions]);

  const { shippingDealerList } = useSelector(state => state.shippingDealerList);

  const uuStoreOptions = uuoptionsInfo?.map(data => ({
    label: data.companyname,
    value: data.suppliercode,
  }));

  const handleUpdateStatus = () => {
    const params = {
      repairIds: renewIds,
      statusCode: renewStatus.value,
    };
    dispatch(batchUpdateRepairRequest(params));
  };

  return (
    <Layout
      pageTitle="維修清單"
      items={[{ isActive: true, page_name: '維修清單' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={style.show_flex}>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="keyword">
                關鍵字
              </label>
              <input
                id="keyword"
                type="text"
                className={`form-control ${style['form-keyword']}`}
                placeholder="客人姓名、客人電話、檢修項目"
                onChange={handleChange}
                value={values.keyword}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="brandName">
                品牌
              </label>
              <Select
                isLoading={repairBrandOptionsLoading}
                isDisabled={repairBrandOptionsLoading}
                name="brandName"
                className={style['form-select']}
                options={
                  repairBrandOptions && [
                    { label: '全部', value: 0 },
                    ...repairBrandOptions,
                  ]
                }
                onChange={option => {
                  setFieldValue('brand', option);
                  setFieldValue('model', { label: '請選擇品牌', value: '' });
                  setFieldValue('color', { label: '請選擇顏色', value: '' });
                }}
                value={values.brand}
                style={{ margin: 0 }}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label
                className="col-form-label mr-2"
                htmlFor="model"
                style={{ marginRight: '1rem' }}
              >
                型號
              </label>
              <Select
                isLoading={repairModelOptionsLoading}
                isDisabled={repairModelOptionsLoading}
                name="model"
                className={style['form-select']}
                options={
                  repairModelOptions && [
                    { label: '全部', value: '' },
                    ...repairModelOptions,
                  ]
                }
                onChange={option => {
                  setFieldValue('model', option);
                  setFieldValue('color', { label: '請選擇顏色', value: '' });
                }}
                value={values.model}
                style={{ margin: 0 }}
              />
            </div>

            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="color">
                顏色
              </label>
              <Select
                name="color"
                className={style['form-select']}
                options={
                  repairColorOptions && [
                    { label: '全部', value: '' },
                    ...repairColorOptions,
                  ]
                }
                isLoading={repairColorOptionsLoading}
                isDisabled={repairColorOptionsLoading}
                onChange={option => setFieldValue('color', option)}
                value={values.color}
                style={{ margin: 0 }}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">編號</label>
              <Input
                type="select"
                style={{ margin: 0 }}
                id="numberType"
                onChange={handleChange}
                value={values.numberType}
              >
                <option value="">請選擇</option>
                <option value="repairRegisterNum">維修單號</option>
                <option value="supplierRepairNum">經銷商維修單號</option>
                <option value="productSerailNum">商品序號</option>
                <option value="warrantySerailNum">保固序號</option>
              </Input>
              <Input
                id="number"
                className={style['form-input-order-number']}
                onChange={handleChange}
                value={values.number}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2">經手人</label>
              <Select
                name="color"
                className={style['form-select']}
                options={
                  maintainHandlerOptions
                    ? [{ label: '請選擇', value: 0 }, ...maintainHandlerOptions]
                    : []
                }
                isLoading={maintainStatusOptionsLoading}
                isDisabled={maintainStatusOptionsLoading}
                onChange={option => setFieldValue('handler', option)}
                value={values.handler}
                style={{ margin: 0 }}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="status">
                狀態
              </label>
              <Input
                type="select"
                id="hasFinishedProcessing"
                className={style['form-select-short']}
                onChange={e => {
                  setFieldValue('hasFinishedProcessing', e.target.value);
                  setFieldValue('statusCode', { label: '全部', value: 0 });
                }}
                value={values.hasFinishedProcessing}
                style={{ margin: 0 }}
              >
                <option value={''}>全部</option>
                <option value="false">處理中</option>
                <option value="true">處理完畢</option>
              </Input>
              <Select
                isLoading={maintainStatusOptionsLoading}
                isDisabled={maintainStatusOptionsLoading}
                className={style['form-select']}
                options={
                  !values.hasFinishedProcessing
                    ? [
                        { label: '全部', value: 0 },
                        ...(maintainStatusOptions || []).map(m => ({
                          ...m,
                          isDisabled: false,
                        })),
                      ]
                    : values.hasFinishedProcessing === 'true' ||
                      values.hasFinishedProcessing === true
                    ? hasFinishedProcessingOptions.map(m => ({
                        ...m,
                        isDisabled: false,
                      }))
                    : unFinishedProcessingOptions.map(m => ({
                        ...m,
                        isDisabled: false,
                      }))
                }
                onChange={option => {
                  setFieldValue('statusCode', option);
                }}
                value={values.statusCode}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label text-nowrap mr-2">
                維修單成立區間
              </label>
              <DatePicker
                className="form-control"
                selected={
                  values.startDate ? moment(values.startDate).toDate() : ''
                }
                onChange={date => setFieldValue('startDate', date)}
                dateFormat="yyyy/MM/dd"
              />
              <span style={{ margin: '1rem' }}>~</span>
              <DatePicker
                className="form-control"
                selected={values.endDate ? moment(values.endDate).toDate() : ''}
                onChange={date => setFieldValue('endDate', date)}
                minDate={values.startDate}
                dateFormat="yyyy/MM/dd"
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label text-nowrap mr-2">
                寄回日期區間
              </label>
              <DatePicker
                className="form-control"
                selected={
                  values.sendBackStartDate
                    ? moment(values.sendBackStartDate).toDate()
                    : ''
                }
                onChange={date => setFieldValue('sendBackStartDate', date)}
                dateFormat="yyyy/MM/dd"
              />
              <span style={{ margin: '1rem' }}>~</span>
              <DatePicker
                className="form-control"
                selected={
                  values.sendBackEndDate
                    ? moment(values.sendBackEndDate).toDate()
                    : ''
                }
                onChange={date => setFieldValue('sendBackEndDate', date)}
                minDate={values.sendBackEndDate}
                dateFormat="yyyy/MM/dd"
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="status">
                來源
              </label>
              <div className={style['checkbox-with-label']}>
                <input
                  type="checkBox"
                  style={{ height: '20px', width: '20px' }}
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                      ? setFieldValue('sourceList', [...values.sourceList, 1])
                      : setFieldValue(
                          'sourceList',
                          values.sourceList.filter(source => source !== 1),
                        );
                  }}
                  checked={values.sourceList.includes(1)}
                />
                <span style={{ marginLeft: '0.5rem' }}>會員</span>
              </div>
              <div className={style['checkbox-with-label']}>
                <input
                  type="checkBox"
                  style={{ height: '20px', width: '20px' }}
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                      ? setFieldValue('sourceList', [...values.sourceList, 2])
                      : setFieldValue(
                          'sourceList',
                          values.sourceList.filter(source => source !== 2),
                        );
                  }}
                  checked={values.sourceList.includes(2)}
                />
                <span style={{ marginLeft: '0.5rem' }}>客服</span>
              </div>
              <div className={style['checkbox-with-label']}>
                <input
                  type="checkBox"
                  style={{ height: '20px', width: '20px' }}
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                      ? setFieldValue('sourceList', [...values.sourceList, 3])
                      : setFieldValue(
                          'sourceList',
                          values.sourceList.filter(source => source !== 3),
                        );
                  }}
                  checked={values.sourceList.includes(3)}
                />
                <span style={{ marginLeft: '0.5rem' }}>門市</span>
              </div>
              <div className={style['checkbox-with-label']}>
                <input
                  type="checkBox"
                  style={{ height: '20px', width: '20px' }}
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                      ? setFieldValue('sourceList', [...values.sourceList, 4])
                      : setFieldValue(
                          'sourceList',
                          values.sourceList.filter(source => source !== 4),
                        );
                  }}
                  checked={values.sourceList.includes(4)}
                />
                <span style={{ marginLeft: '0.5rem' }}>經銷商</span>
              </div>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>庫存</label>
              <Input
                type={'select'}
                id="stock"
                className={style['form-select-short']}
                style={{ margin: 0 }}
                value={values.stock}
                onChange={handleChange}
              >
                <option value={''}>全部</option>
                <option value={'ok'}>ok</option>
                <option value={'no'}>no</option>
              </Input>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label>credit</label>
              <Input
                type="select"
                id="isCredit"
                className={style['form-select-short']}
                style={{ margin: 0 }}
                value={values.isCredit}
                onChange={handleChange}
              >
                <option value={''}>全部</option>
                <option value={'true'}>是</option>
                <option value={'false'}>否</option>
              </Input>
            </div>
            <div className={style.formGroup}>
              <label>寄件人</label>
              <Input
                type="select"
                className={style['form-select-medium']}
                style={{ margin: 0 }}
                onChange={event => {
                  setFieldValue('senderType', event.target.value);
                  setFieldValue('storeList', []);
                  setFieldValue('supplierList', []);
                }}
                value={values.senderType}
              >
                <option value={''}>全部</option>
                <option value={'門市維修單'}>門市維修單</option>
                <option value={'經銷商維修單'}>經銷商維修單</option>
              </Input>
              {values.senderType === '經銷商維修單' && (
                <Select
                  options={shippingDealerList}
                  isMulti
                  style={{ margin: 0 }}
                  className={style['form-select-multi-large']}
                  onChange={options => {
                    if (values.senderType === '經銷商維修單') {
                      setFieldValue('supplierList', options);
                      setFieldValue('storeList', []);
                    } else if (values.senderType === '門市維修單') {
                      setFieldValue('storeList', options);
                      setFieldValue('supplierList', []);
                    } else {
                      setFieldValue('supplierList', []);
                      setFieldValue('storeList', []);
                    }
                  }}
                  values={values.supplierList}
                  defaultValue={values.supplierList}
                />
              )}
              {values.senderType === '門市維修單' && (
                <Select
                  options={uuStoreOptions}
                  isMulti
                  style={{ margin: 0 }}
                  className={style['form-select-multi-large']}
                  onChange={options => {
                    if (values.senderType === '經銷商維修單') {
                      setFieldValue('supplierList', options);
                      setFieldValue('storeList', []);
                    } else if (values.senderType === '門市維修單') {
                      setFieldValue('storeList', options);
                      setFieldValue('supplierList', []);
                    } else {
                      setFieldValue('supplierList', []);
                      setFieldValue('storeList', []);
                    }
                  }}
                  values={values.storeList}
                  defaultValue={values.storeList}
                />
              )}
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label
                className="col-form-label mr-2"
                htmlFor="model"
                style={{ marginRight: '1rem' }}
              >
                報廢倉庫
              </label>
              <Select
                name="scrapWareHouse"
                className={style['form-select']}
                options={
                  scrapWarehouseOptions && [
                    { label: '全部', value: '' },
                    ...scrapWarehouseOptions,
                  ]
                }
                onChange={option => {
                  setFieldValue('scrapWareHouse', option);
                }}
                value={values.scrapWareHouse}
                style={{ margin: 0 }}
              />
            </div>
          </div>

          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={resetForm}
            >
              取消
            </Button>
            <Button
              type="submit"
              className={style.modal_button_submit}
              onClick={()=>{
                handlePageChange(1)
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="d-flex justify-content-between">
          <div className={style.show_flex} style={{ margin: '0.5rem' }}>
            <Link className="btn btn-success me-3 mb-2" to="/ServiceForm">
              新增維修單
            </Link>
            <div>
              <Link to="/MaintainanceAdd" className="btn btn-success me-3 mb-2">
                新增經銷商維修單
              </Link>
            </div>
            <div>
              <label
                className="btn btn-success me-3 mb-2"
                htmlFor="import"
                style={{ cursor: 'pointer' }}
              >
                匯入檢測排程單
              </label>
              <Input
                id="import"
                type="file"
                className="d-none"
                onChange={handleUpload}
              />
            </div>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={toggleModal}
              disabled={renewIds.length === 0}
            >
              批次更新狀態
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              disabled={maintainExportLoading || renewIds.length === 0}
              onClick={handleExport}
            >
              批次匯出黑貓收件單
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={toggleUpdateCreditsModal}
              disabled={renewIds.length === 0}
            >
              批次更改credit
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={toggleUpdateSendBackDateModal}
              disabled={renewIds.length === 0}
            >
              批次更改寄回日期
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={exportMaintainList}
              disabled={renewIds.length === 0 || exportWordLoading}
            >
              批次下載維修單
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={() => {
                toggleScrapWarehouseModal(true);
              }}
              disabled={renewIds.length === 0}
            >
              批次更改報廢倉庫
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={() => {
                toggleUpdateSendBackCompanyDateModal(true);
              }}
              disabled={renewIds.length === 0}
            >
              批次更新送回公司日期
            </Button>
            <Button
              className="btn btn-success me-3 mb-2"
              onClick={() => {
                setIsUpdateStockModalOpen(true);
              }}
              disabled={renewIds.length === 0}
            >
              批次更新庫存
            </Button>
          </div>
          <div className={style['rwd-width-100']} style={{ width: '150px' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              每頁
              <Input
                type="select"
                name="page_size"
                style={{ margin: '0.3rem', width: '80px' }}
                onChange={event => {
                  const value = event.target.value;
                  handleChangePageSize(value);
                }}
                value={values.page_size}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Input>
              筆
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {maintainListInfo?.total_count ? maintainListInfo.total_count : 0}
          </span>
          筆
        </div>
        {!loading && (
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>
                    <input
                      className={style.maintain_list_checkbox}
                      type="checkbox"
                      onChange={e => {
                        const checked = e.target.checked;
                        setRenewIds(
                          checked
                            ? maintainListInfo?.datas?.map(i => i.id)
                            : [],
                        );
                      }}
                      checked={
                        renewIds.length > 0 &&
                        renewIds.length === maintainListInfo?.datas?.length
                      }
                    />
                  </th>
                  <th>功能</th>
                  <th>
                    <div>狀態</div>
                    <div>狀態日期</div>
                  </th>
                  <th>
                    <div>維修單編號</div>
                    <div>申請日期</div>
                  </th>
                  <th>
                    <div>來源</div>
                    <div>寄送門市</div>
                  </th>
                  <th style={{ maxWidth: '100px' }}>
                    <div>寄件人的</div>
                    <div>顧客姓名</div>
                  </th>
                  <th>寄件人的電話</th>
                  <th style={{ maxWidth: '100px' }}>
                    <div>商品品牌</div>
                    <div>商品型號</div>
                    <div>商品顏色</div>
                  </th>
                  <th style={{ minWidth: '300px' }}>商品問題</th>
                  <th>經手人</th>
                  <th style={{ minWidth: '300px' }}>百滋處理詳情</th>
                  <th>報廢倉庫</th>
                  <th>購買日期</th>
                  <th>回公司日期</th>
                  <th>寄回日期</th>
                  <th>庫存</th>
                  <th>credit</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {maintainListInfo
                    ? maintainListInfo.datas.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              className={style.maintain_list_checkbox}
                              type="checkbox"
                              onChange={e =>
                                handleCheck(data.id, e.target.checked)
                              }
                              checked={renewIds.indexOf(data.id) > -1}
                            />
                          </td>
                          <td>
                            <span onClick={removeCopyFlag}>
                              <Link
                                className="m-2 btn btn-outline-primary btn-sm"
                                to={`/MaintainanceContent/${data.id}`}
                              >
                                內容
                              </Link>
                            </span>
                            {data.sourceName === '客服' ||
                            data.sourceName === '經銷商' ? (
                              <span onClick={setCopyFlag}>
                                <Link
                                  className="m-2 btn btn-outline-primary btn-sm"
                                  to={`/MaintainanceContent/${data.id}`}
                                >
                                  複製
                                </Link>
                              </span>
                            ) : (
                              <></>
                            )}
                            {data.sourceName !== '客服' &&
                            data.sourceName !== '經銷商' &&
                            data?.stType !== '內部庫存報廢' ? (
                              <Button
                                className="m-2 btn-sm"
                                outline
                                color="primary"
                                onClick={() => {
                                  toggleExchangeWarrantyProductModal();
                                  setCurrentDetailInfo({ ...data });
                                }}
                              >
                                保固註冊
                              </Button>
                            ) : (
                              <></>
                            )}

                            <Button
                              className="m-2 btn-sm"
                              outline
                              color="primary"
                              onClick={() => {
                                toggleExchangeSerialNumberModal();
                                setCurrentDetailInfo({ ...data });
                              }}
                            >
                              更換產品序號
                            </Button>
                            {data.sourceName !== '會員' && (
                              <Button
                                className="m-2 btn-sm"
                                // style={{ backgroundColor: 'transparent' }}
                                // to={`/MaintainanceContent/${data.id}`}
                                outline
                                color="danger"
                                onClick={() => {
                                  deleteDetail(data.id);
                                }}
                              >
                                刪除
                              </Button>
                            )}

                            {data.id > 0 && data.phone_sender && <Button
                                className="m-2 btn-sm"
                                outline
                                color="primary"
                                onClick={() => {
                                  modalUtils.showSMSNotification(data);
                                }}
                            >
                              簡訊通知
                            </Button>}
                          </td>
                          <td className="text-nowrap">
                            <div>{data.status}</div>
                            <div>
                              {moment(data.statusCodeTime).format(
                                'YYYY/MM/DD HH:mm:ss',
                              )}
                            </div>
                          </td>
                          <td className="text-nowrap">
                            <div>{data.repairRegisterNum}</div>
                            <div>
                              {moment(new Date(data.createTime)).format(
                                'yyyy/MM/DD',
                              )}
                            </div>
                          </td>
                          <td className="text-center">
                            <div>{data.sourceName}</div>
                            <div>{data.storeName_sender}</div>
                          </td>
                          <td>{data.senderName || data.supplierName_sender}</td>
                          <td>
                            {data.countryCode_sender == 886 && data.phone_sender
                              ? `0${data.phone_sender}`
                              : data.phone_sender}
                          </td>
                          <td>
                            {/*
                            商品品牌
                            商品型號
                            商品顏色
                            */}
                            <div className="text-nowrap">{data.brandName}</div>
                            <div className="text-nowrap">{data.model}</div>
                            <div className="text-nowrap">{data.color}</div>
                          </td>
                          <td>
                            {data.productProblem
                              ?.split('\n')
                              .map((string, index) => {
                                return <div key={index}>{string}</div>;
                              })}
                          </td>
                          <td className="text-center">{data.handlerNamer}</td>
                          <td>
                            {data.handlingDetail
                              ?.split('\n')
                              .map((string, index) => {
                                return <div key={index}>{string}</div>;
                              })}
                          </td>
                          <td>
                            {/*報廢倉庫*/}
                            {data.scrapWarehouseName ||
                              data.scrapWarehouseCode ||
                              '--'}
                          </td>
                          <td className="text-nowrap">
                            <div>
                              {data.purchaseDate &&
                                moment(data.purchaseDate).format('YYYY/MM/DD')}
                            </div>
                            {/* <div>{data.supplierChannelName}</div> */}
                          </td>
                          <td className="text-nowrap">
                            {data.backtoCompanyDate}
                          </td>
                          <td className="text-nowrap">
                            {/*寄回日期*/}
                            {data.sendBackDate
                              ? moment(data.sendBackDate).format('YYYY/MM/DD')
                              : '-'}
                          </td>
                          <td className="text-center">{data.stock}</td>
                          <td className="text-center">
                            {data.isCredit ? '是' : '否'}
                          </td>
                        </tr>
                      ))
                    : !loading && <TableHasNoData />}
                </tbody>
              )}
            </Table>
          </div>
        )}
        {loading && <Loader />}
        {!loading && maintainListInfo && maintainListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={maintainListInfo.current_page}
              defaultPageSize={values.page_size}
              total={maintainListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          選擇狀態
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{}}
          onSubmit={handleUpdateStatus}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody
                className={style.modal_body}
                style={{ height: '40vh' }}
              >
                <Select
                  name="status"
                  className="w400"
                  options={
                    maintainStatusOptions &&
                    maintainStatusOptions.filter(
                      option => option.value != 10 && option.value != 11,
                    )
                  }
                  onChange={option => setRenewStatus(option)}
                  value={renewStatus}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={`${style.modal_button_cancel} ms-auto`}
                  onClick={toggleModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={updateLoading}
                >
                  {updateLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      {isUpdateCreditsModalOpen && (
        <UpdateCreditsModal
          isOpen={isUpdateCreditsModalOpen}
          toggle={toggleUpdateCreditsModal}
          ids={renewIds}
          onRefresh={updateList}
        />
      )}
      {showScrapWarehouseModal && (
        <UpdateScrapWarehouseModal
          isOpen={showScrapWarehouseModal}
          toggle={() => {
            toggleScrapWarehouseModal(false);
          }}
          ids={renewIds}
          onRefresh={updateList}
        />
      )}
      {isUpdateSendBackDateModalOpen && (
        <UpdateSendBackDateModal
          isOpen={isUpdateSendBackDateModalOpen}
          toggle={toggleUpdateSendBackDateModal}
          ids={renewIds}
          onRefresh={updateList}
        />
      )}
      {isUpdateSendBackCompanyDateModalOpen && (
        <UpdateSendBackCompanyDateModal
          isOpen={isUpdateSendBackCompanyDateModalOpen}
          toggle={toggleUpdateSendBackCompanyDateModal}
          ids={renewIds}
          onRefresh={updateList}
        />
      )}
      {isUpdateStockModalOpen && (
        <BatchUpdateStockModal
          isOpen={isUpdateStockModalOpen}
          toggle={toggleUpdateStockModal}
          ids={renewIds}
          onRefresh={updateList}
        />
      )}
      {isExchangeWarrantyProductModalOpen &&
        currentDetailInfo &&
        maintainOptions && (
          <ExchangeWarrantyProductModal
            isOpen={isExchangeWarrantyProductModalOpen}
            toggle={toggleExchangeWarrantyProductModal}
            info={{
              email: currentDetailInfo.memberEmail,
              id: currentDetailInfo.memberId,
              phone: currentDetailInfo.memberPhone,
              repairRegisterNum: currentDetailInfo?.repairRegisterNum,
              supplier: maintainOptions.allChannels,
            }}
          />
        )}
      {isExchangeSerialNumberModalOpen && currentDetailInfo && (
        <ExchangeSerialNumberModal
          isOpen={isExchangeSerialNumberModalOpen}
          toggle={toggleExchangeSerialNumberModal}
          info={currentDetailInfo}
        />
      )}
    </Layout>
  );
};

export default MaintainanceList;
