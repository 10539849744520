import ExchangeWarrantyProductModal from 'components/ExchangeWarrantyProductModal';
import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Input, Table } from 'reactstrap';
import {
  getColorModelOption,
  getRepairBrandOption,
  getRepairModelOption,
  getRepairOptions,
} from 'redux/actions/data/repairActions';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import TableHead from '../components/TableHead';
import WarrantyDateSettingModal from '../components/warrantyRegisterDetails/WarrantyDateSettingModal';
import {
  exportWarrantyRegister,
  getWarrantyRegisterDetails,
  getWarrantyRegisterList,
  reviewWarrantyRegister,
} from '../redux/actions/data/warrantyActions';
import {
  WARRANTY_REGISTER_DETAILS_RESET,
  WARRANTY_REGISTER_EXPORT_RESET,
  WARRANTY_REGISTER_REVIEW_RESET,
} from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import { modal as modalUtils } from '../utils/modal';
import useQuery from '../utils/useQuery';

const WarrantyRegister = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [brand, setBrand] = useState({ label: '請選擇品牌', value: 0 });
  const [model, setModel] = useState({ label: '請選擇型號', value: '' });
  const [color, setColor] = useState({ label: '請選擇顏色', value: '' });
  const [status, setStatus] = useState('');
  const infoRef = useRef('');
  const [otherReason, setOtherReason] = useState('');

  const { loading: addLoading } = useSelector(state => state.warrantyAddRemark);
  const {
    loading: submitLoading,
    success: submitSuccess,
    error: submitError,
  } = useSelector(state => state.warrantyRegisterReview);

  const handleSubmit = values => {
    if (infoRef.current) {
      const object = {
        ...values,
        productDate_admin: values.shippingDate
          ? moment(values.shippingDate).format('YYYY/MM/DD HH:mm')
          : infoRef.current.productDate_admin
          ? moment(infoRef.current.productDate_admin).format('YYYY/MM/DD HH:mm')
          : '',
        purchaseDate_admin: values.purchaseDate
          ? moment(values.purchaseDate).format('YYYY/MM/DD HH:mm')
          : infoRef.current.purchaseDate_admin
          ? moment(infoRef.current.purchaseDate_admin).format(
              'YYYY/MM/DD HH:mm',
            )
          : '',
        reason: values.reason,
        startDateType_admin: Number(values.startDateType_admin),
        status: values.status,
        warrantyEndDate: values.warrantyEndDate
          ? moment(values.warrantyEndDate).format('YYYY/MM/DD HH:mm')
          : infoRef.current.warrantyEndDate
          ? moment(infoRef.current.warrantyEndDate).format('YYYY/MM/DD HH:mm')
          : '',
        warrantyRegisterId: infoRef.current.id,
      };
      dispatch(reviewWarrantyRegister(object));
    }
  };

  const { repairBrandOptions, loading: repairBrandOptionsLoading } =
    useSelector(state => state.repairBrandOptions);
  const { repairModelOptions, loading: repairModelOptionsLoading } =
    useSelector(state => state.repairModelOptions);
  const { repairColorOptions, loading: repairColorOptionsLoading } =
    useSelector(state => state.repairColorOptions);

  const { loading, warrantyRegisterListInfo } = useSelector(
    state => state.warrantyRegisterList,
  );
  const { result } = useSelector(state => state.warrantyRegisterExport);

  const { maintainOptions, loading: maintainOptionsLoading } = useSelector(
    state => state.maintainOptions,
  );

  const [supplier, setSupplier] = useState({
    label: '請選擇經銷商',
    value: '',
  });

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape(
      {
        purchaseDate: Yup.date().when('startDateType_admin', {
          is: startDateType_admin => startDateType_admin == 1,
          then: Yup.date().required('請填入購買日期').nullable(),
        }),
        reason: Yup.string().when('status', {
          is: status => status == '未通過',
          then: Yup.string().required('請填入原因'),
        }),
        shippingDate: Yup.date().when('startDateType_admin', {
          is: startDateType_admin => startDateType_admin == 2,
          then: Yup.date().required('請填入出貨日期').nullable(),
        }),
        startDateType_admin: infoRef?.current?.warrantyDays
          ? Yup.string().required('必填')
          : Yup.string(),
        status: Yup.string().required('必填'),
        warrantyEndDate: infoRef?.current?.warrantyDays
          ? Yup.date().required('必填').nullable()
          : Yup.date().nullable(),
      },
      ['startDateType_admin', 'purchaseDate', 'shippingDate'],
    );
    return schema;
  };

  useEffect(() => {
    if (location.search) {
      const queryPage = query.value.page;
      const queryKeyword = query.value.keyword
        ? JSON.parse(query.value.keyword)
        : '';
      const queryBrand = query.value.brand;
      const queryModel = query.value.model ? JSON.parse(query.value.model) : '';
      const queryColor = query.value.color ? JSON.parse(query.value.color) : '';
      const queryStatus = query.value.status;
      const queryChannel = query.value.supplierChannelId;
      const queryPageSize = query.value.page_size || 50;

      // 0開頭的數字string會被轉換
      let urlParams = new URLSearchParams(location.search);

      const querySupplier = urlParams.get('supplier');

      setCurrentPage(queryPage);
      setPageSize(queryPageSize);

      if (
        queryKeyword ||
        queryBrand ||
        queryModel ||
        queryColor ||
        queryStatus ||
        queryChannel ||
        queryPageSize ||
        querySupplier
      ) {
        setKeyword(queryKeyword);
        setBrand(
          repairBrandOptions &&
            repairBrandOptions.find(option => option.value == queryBrand)
            ? repairBrandOptions.find(option => option.value == queryBrand)
            : brand,
        );
        setModel(
          repairModelOptions &&
            repairModelOptions.find(option => option.value == queryModel)
            ? repairModelOptions.find(option => option.value == queryModel)
            : model,
        );
        setColor(
          repairColorOptions &&
            repairColorOptions.find(option => option.value == queryColor)
            ? repairColorOptions.find(option => option.value == queryColor)
            : color,
        );
        setStatus(queryStatus);
        setChannel(
          maintainOptions?.allChannels &&
            maintainOptions.allChannels.find(
              option => option.value == queryChannel,
            )
            ? maintainOptions.allChannels.find(
                option => option.value == queryChannel,
              )
            : { label: '請選擇通路', value: 0 },
        );
        setSupplier(
          (maintainOptions?.suppliers &&
            maintainOptions.suppliers.find(
              option => option.value == querySupplier,
            )) || { label: '請選擇經銷商', value: '' },
        );

        //儲存搜尋及頁數
        dispatch(
          getWarrantyRegisterList({
            brandId: queryBrand,
            color: queryColor,
            keyword: queryKeyword.toString(),
            model: queryModel,
            page: queryPage,
            page_size: queryPageSize,
            status: queryStatus,
            supplierChannelId: queryChannel,
            suppliercode: querySupplier,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getWarrantyRegisterList({
            page: queryPage,
            page_size: queryPageSize,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setBrand({ label: '請選擇品牌', value: 0 });
      setModel({ label: '請選擇型號', value: '' });
      setColor({ label: '請選擇顏色', value: '' });
      setStatus('');
      setChannel({ label: '請選擇通路', value: 0 });
      setPageSize(50);
      setSupplier({ label: '請選擇經銷商', value: '' });

      //初始搜尋
      dispatch(
        getWarrantyRegisterList({
          page: 1,
          page_size: pageSize,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getRepairBrandOption(0, 0));
    dispatch(getRepairOptions());
  }, []);

  useEffect(() => {
    brand.value && dispatch(getRepairModelOption(0, 0, brand.value));
  }, [brand]);

  useEffect(() => {
    model.value && dispatch(getColorModelOption(0, 0, model.value));
  }, [model]);

  useEffect(() => {
    if (result) {
      window.open(result);
    }
    return () => {
      dispatch({ type: WARRANTY_REGISTER_EXPORT_RESET });
    };
  }, [result]);

  useEffect(() => {
    if (warrantyRegisterListInfo) {
      if (JSON.stringify(warrantyRegisterListInfo) !== '{}') {
        CommonUtil.consoleLog({
          anno: 'response',
          data: warrantyRegisterListInfo,
        });
      }
    }
  }, [warrantyRegisterListInfo]);

  const handleSearch = e => {
    e?.preventDefault();
    setCurrentPage(1);
    history.push(
      `${location.pathname}?keyword=${JSON.stringify(keyword)}&brand=${
        brand.value
      }&model=${JSON.stringify(model.value)}&color=${JSON.stringify(
        color.value,
      )}&status=${status}&page=${1}&supplierChannelId=${
        channel.value
      }&page_size=${pageSize}&supplier=${
        supplier.value
      }&v=${new Date().getTime()}`,
    );
  };

  const handleChangePageSize = page_size => {
    setCurrentPage(1);
    history.push(
      `${location.pathname}?keyword=${JSON.stringify(keyword)}&brand=${
        brand.value
      }&model=${JSON.stringify(model.value)}&color=${JSON.stringify(
        color.value,
      )}&status=${status}&page=${1}&supplierChannelId=${
        channel.value
      }&page_size=${page_size}&supplier=${supplier.value}`,
    );
  };

  const updateList = () => {
    history.push(
      `${location.pathname}?keyword=${JSON.stringify(keyword)}&brand=${
        brand.value
      }&model=${JSON.stringify(model.value)}&color=${JSON.stringify(
        color.value,
      )}&status=${status}&page=${current_page}&supplierChannelId=${
        channel.value
      }&page_size=${pageSize}&supplier=${supplier.value}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setBrand({ label: '請選擇品牌', value: 0 });
    setModel({ label: '請選擇型號', value: '' });
    setColor({ label: '請選擇顏色', value: '' });
    setStatus('');
    setChannel({ label: '請選擇通路', value: 0 });
    setPageSize(50);
    setSupplier({ label: '請選擇經銷商', value: '' });
    history.push(`${location.pathname}`);
  };

  const handleExport = () => {
    const exportObject = {
      brandId: brand.value,
      color: color.value,
      keyword,
      model: model.value,
      page: current_page,
      page_size: pageSize,
      status,
      supplierChannelId: channel.value,
      suppliercode: supplier.value,
    };
    dispatch(exportWarrantyRegister(exportObject));
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    if (
      keyword ||
      model.value ||
      brand.value ||
      color.value ||
      status ||
      channel.value ||
      supplier.value
    ) {
      history.push(
        `${location.pathname}?keyword=${keyword}&brand=${
          brand.value
        }&model=${JSON.stringify(model.value)}&color=${JSON.stringify(
          color.value,
        )}&status=${status}&page=${page}&supplierChannelId=${
          channel.value
        }&page_size=${pageSize}&supplier=${supplier.value}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}&page_size=${pageSize}`);
    }
  };

  const [channel, setChannel] = useState({ label: '請選擇通路', value: 0 });

  const [
    isExchangeWarrantyProductModalOpen,
    setIsExchangeWarrantyProductModalOpen,
  ] = useState(false);
  const toggleExchangeWarrantyProductModal = () => {
    setIsExchangeWarrantyProductModalOpen(!isExchangeWarrantyProductModalOpen);
  };

  const [pageSize, setPageSize] = useState(50);
  const { loading: detailLoading, warrantyRegisterDetailsInfo } = useSelector(
    state => state.warrantyRegisterDetails,
  );
  useEffect(() => {
    if (infoRef.current && modal && !submitLoading) {
      if (submitSuccess) {
        MessageUtil.alertSuccess('更新成功');
        isClickRef.current = false;
        setModal(false);
        handleSearch();
      }
      if (submitError) {
        MessageUtil.alertWanring(submitError);
      }
    }
  }, [submitLoading, submitSuccess, submitError]);

  const isClickRef = useRef(false);
  useEffect(() => {
    if (
      !modal &&
      isClickRef.current &&
      !infoRef.current &&
      warrantyRegisterDetailsInfo &&
      !detailLoading
    ) {
      infoRef.current = warrantyRegisterDetailsInfo;
      setModal(true);
      dispatch({ type: WARRANTY_REGISTER_DETAILS_RESET });
      dispatch({ type: WARRANTY_REGISTER_REVIEW_RESET });
    }
    return () => {
      dispatch({ type: WARRANTY_REGISTER_DETAILS_RESET });
      dispatch({ type: WARRANTY_REGISTER_REVIEW_RESET });
    };
  }, [warrantyRegisterDetailsInfo]);

  return (
    <Layout
      pageTitle="保固註冊清單"
      items={[
        { isActive: false, page_name: '產品保固註冊' },
        { isActive: true, page_name: '保固註冊清單' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader className="bg-white">搜尋條件</CardHeader>
        <CardBody>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  id="keyword"
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  placeholder="電話、購買通路、保卡序號、產品序號"
                  onChange={e => {
                    setKeyword(e.target.value.trim());
                  }}
                  value={keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="brandName">
                  品牌
                </label>
                <Select
                  isLoading={repairBrandOptionsLoading}
                  isDisabled={repairBrandOptionsLoading}
                  name="brandName"
                  className={style['form-select']}
                  options={
                    repairBrandOptions
                      ? [{ label: '全部', value: 0 }, ...repairBrandOptions]
                      : []
                  }
                  onChange={option => {
                    setBrand(option);
                    setModel({ label: '請選擇品牌', value: '' });
                    setColor({ label: '請選擇顏色', value: '' });
                  }}
                  value={brand}
                  style={{ margin: 0 }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="model">
                  型號
                </label>
                <Select
                  isLoading={repairModelOptionsLoading}
                  isDisabled={repairModelOptionsLoading}
                  name="model"
                  className={style['form-select']}
                  options={
                    repairModelOptions
                      ? [{ label: '全部', value: '' }, ...repairModelOptions]
                      : []
                  }
                  onChange={option => {
                    setModel(option);
                    setColor({ label: '請選擇顏色', value: '' });
                  }}
                  value={model}
                  style={{ margin: 0 }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="color">
                  顏色
                </label>
                <Select
                  isLoading={repairColorOptionsLoading}
                  isDisabled={repairColorOptionsLoading}
                  name="color"
                  className={style['form-select']}
                  options={
                    repairColorOptions
                      ? [{ label: '全部', value: '' }, ...repairColorOptions]
                      : []
                  }
                  onChange={option => setColor(option)}
                  value={color}
                  style={{ margin: 0 }}
                />
              </div>

              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="status">
                  審核狀態
                </label>
                <Input
                  type="select"
                  id="status"
                  className={style['form-select-short']}
                  onChange={e => setStatus(e.target.value)}
                  value={status}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="未審核">未審核</option>
                  <option value="已通過">已通過</option>
                  <option value="未通過">未通過</option>
                  <option value="審核中">審核中</option>
                  <option value="已過期">已過期</option>
                  <option value="已作廢">已作廢</option>
                  <option value="自動通過">自動通過</option>
                </Input>
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label style={{ marginRight: '1.5rem' }}>購買通路</label>
                <Select
                  isLoading={maintainOptionsLoading}
                  isDisabled={maintainOptionsLoading}
                  options={maintainOptions?.allChannels}
                  value={channel}
                  onChange={option => {
                    setChannel(option);
                  }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label style={{ marginRight: '1.5rem' }}>經銷商</label>
                <Select
                  isLoading={maintainOptionsLoading}
                  isDisabled={maintainOptionsLoading}
                  className={style['form-select']}
                  options={maintainOptions?.suppliers}
                  value={supplier}
                  onChange={option => {
                    setSupplier(option);
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
      <Card className={style.card}>
        <div className="d-flex justify-content-between">
          <div className={style.show_flex}>
            <Button
              className="btn btn-success w100 me-3"
              onClick={handleExport}
            >
              匯出Excel
            </Button>
            <Button
              className="btn btn-success me-3"
              onClick={toggleExchangeWarrantyProductModal}
            >
              新增保固註冊
            </Button>
          </div>
          <div className={style['rwd-width-100']} style={{ width: '150px' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              每頁
              <Input
                type="select"
                name="page_size"
                style={{ margin: '0.3rem', width: '80px' }}
                onChange={event => {
                  setPageSize(event.target.value);
                  handleChangePageSize(event.target.value);
                }}
                value={pageSize}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Input>
              筆
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading &&
            warrantyRegisterListInfo &&
            warrantyRegisterListInfo.total_count > 0
              ? warrantyRegisterListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive mb-5">
          <Table hover bordered striped>
            <TableHead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>功能</th>
                <th>電話</th>
                <th>品牌</th>
                <th>型號/顏色/尺寸</th>
                <th>購買通路</th>
                <th>保卡序號</th>
                <th>產品序號</th>
                <th>購買日期</th>
                <th>保固日期</th>
                <th>審核狀態</th>
                <th>發票/保卡圖片</th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {warrantyRegisterListInfo?.datas?.length > 0 ? (
                  warrantyRegisterListInfo?.datas.map(data => (
                    <tr key={JSON.stringify(data)}>
                      <td align="center" className="text-nowrap">
                        <Button
                          outline
                          color="primary"
                          size="sm"
                          onClick={() => {
                            infoRef.current = null;
                            isClickRef.current = true;
                            dispatch(getWarrantyRegisterDetails(data.id));
                          }}
                        >
                          編輯
                        </Button>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/WarrantyRegisterDetails/${data.id}`}
                        >
                          詳細資料
                        </Link>
                      </td>
                      <td className="text-nowrap">{data.countryCode === '886' ? '0': data.countryCode}{data.phone}</td>
                      <td className="text-nowrap">{data.brandName}</td>
                      <td className="text-nowrap">
                        {data.model}/{data.color}/{data.size}
                      </td>
                      <td>
                        {data.isNew
                          ? data.purchaseChannelName
                          : data.supplierChanneName}
                      </td>
                      <td className="text-nowrap">{data.warrantySerialNum}</td>
                      <td>{data.productSerialNum}</td>
                      <td className="text-nowrap">
                        {data.purchaseDate || '--'}
                      </td>
                      <td className="text-nowrap">
                        {data.warrantyDays ? (
                          <>
                            <p>起：{data.warrantyStartDate || '--'}</p>
                            <p>訖：{data.warrantyEndDate || '--'}</p>
                          </>
                        ) : (
                          '無'
                        )}
                      </td>
                      <td align="center">{data.status}</td>
                      <td>
                        {data?.warrantyimgList?.map(img => {
                          return (
                            <img
                              role="button"
                              src={img.imageUrl}
                              alt={img.imageUrl}
                              width="50"
                              key={JSON.stringify(img)}
                              onClick={() => {
                                modalUtils.showFillImage(img.imageUrl);
                              }}
                            />
                          );
                        })}
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={warrantyRegisterListInfo?.current_page || 1}
            pageSize={pageSize} // // todo 等候端修正要替換成 pageSize
            total={warrantyRegisterListInfo?.total_count || 0}
            onChange={handlePageChange}
          />
        </div>
      </Card>
      {isExchangeWarrantyProductModalOpen && (
        <ExchangeWarrantyProductModal
          isOpen={isExchangeWarrantyProductModalOpen}
          toggle={toggleExchangeWarrantyProductModal}
          onRefresh={updateList}
          type="保固"
        />
      )}
      {modal && (
        <WarrantyDateSettingModal
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
          getValidationSchema={getValidationSchema}
          modal={modal}
          info={infoRef.current}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
          addLoading={addLoading}
          submitLoading={submitLoading}
        />
      )}
    </Layout>
  );
};

export default WarrantyRegister;
