import Layout from 'components/Layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import {
  getOverall,
  getOverallMessage,
} from 'redux/actions/data/overallActions';
import style from 'styles/layout.module.css';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';


const OverAll = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.userLogin);
  const { overallInfo } = useSelector(state => state.overall);
  const { data: msgData } = useSelector(state => state.getOverallMessage);
  const [tab, setTab] = useState(0);
  const handleChangeTab = n => {
    setTab(n);
  };

  useEffect(() => {
    dispatch(getOverall());
    dispatch(getOverallMessage());
  }, []);

  useEffect(() => {
    if (overallInfo) {
      if (JSON.stringify(overallInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: overallInfo });
      }
    }
  }, [overallInfo]);

  const ordersURL = {
    '出貨處理中(物流未驗收)': {
      '超商取貨：7-11': '/OrderList',
      '超商取貨：全家': '/OrderList',
    },
    待出貨: {
      'URBAN UNITS門市自取': '/StoreOrders',
      宅配: '/HomeDeliveryOrderConfirmList',
      '超商取貨：7-11': '/CreateLogisticsSeven',
      '超商取貨：全家': '/CreateLogistics',
    },
    換貨待處理: '/OrderChangeStep1',
    '超商取貨/退貨相關': {
      店到店出貨失敗: {
        '7-11超取': '/CreateLogisticsFailSeven',
        全家超取: '/CreateLogisticsFail',
      },
      驗退處理: {
        '7-11超取': '/CreateLogisticsCancelOrderSeven',
        全家超取: '/CreateLogisticsCancelOrder',
      },
    },
    退貨待處理: '/OrderReturnStep1',
    門市自取已出貨: {
      已出貨未到門市: '/StoreOrdersArrived',
      已到門市未取且過領取期限: '/StoreOrdersCancel',
      已到門市未取貨: '/StoreOrdersPickedUp',
    },
  };

  const setCondition = (pervTitle = '', title) => {
    const period = [7, 30, 90];
    const startTime = JSON.stringify(
      moment().subtract(period[tab], 'day').format('yyyy/MM/DD HH:mm:ss'),
    );
    const endTime = JSON.stringify(moment().format('yyyy/MM/DD HH:mm:ss'));
    let condition = {
      endTime,
      page: 1,
      page_size: 10,
      startTime,
    };

    switch (title) {
      case '超商取貨：7-11':
        if (pervTitle !== '待出貨') {
          condition = {
            ...condition,
            logisticsWay: 1,
            orderStatus: 2,
            shippingStatus: 2,
          };
        }
        break;
      case '超商取貨：全家':
        if (pervTitle !== '待出貨') {
          condition = {
            ...condition,
            logisticsWay: 2,
            orderStatus: 2,
            shippingStatus: 2,
          };
        }
        break;
      default:
        break;
    }
    return condition;
  };

  const [cardIsShowed, setCardIsShowed] = useState([]);

  return (
    <Layout pageTitle="總覽" items={[{ isActive: true, page_name: '總覽' }]}>
      <Card style={{ borderRadius: '10px', marginBottom: '3rem' }}>
        <div className={style.show_flex}>
          <div className={style['member-calculation']}>
            <div
              style={{
                fontSize: '36px',
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
              }}
            >
              {overallInfo?.memberInfo?.totalNum?.toLocaleString('en-US')}
            </div>
            <div>會員人數</div>
          </div>
          <div style={{ padding: '1.5rem' }}>
            <div>{`資料填寫完整： ${overallInfo?.memberInfo?.completedNum?.toLocaleString(
              'en-US',
            )}`}</div>
            <div>{`線上會員：${overallInfo?.memberInfo?.onlineNum?.toLocaleString(
              'en-US',
            )}`}</div>
            <div>{`線下會員：${overallInfo?.memberInfo?.offlineNum?.toLocaleString(
              'en-US',
            )}`}</div>
          </div>
        </div>
      </Card>
      <Row xs="1" sm="2">
        <Col>
          <Card className={style.search_card}>
            <div
              className={`${style.card_header} ${style.card_header_dark}`}
              style={{ backgroundColor: '#9862AE' }}
            >
              訂單相關
            </div>
            <div className={style.card_body}>
              <ButtonGroup className="my-3 w-100">
                <Button outline={tab !== 0} onClick={() => handleChangeTab(0)}>
                  近七天
                </Button>
                <Button outline={tab !== 1} onClick={() => handleChangeTab(1)}>
                  近一個月
                </Button>
                <Button outline={tab !== 2} onClick={() => handleChangeTab(2)}>
                  近三個月
                </Button>
              </ButtonGroup>
              {overallInfo?.orderInfo?.periods?.[tab]?.details?.map(
                (i, index) => (
                  <div key={index} className="mb-4">
                    <Button
                      id={`prepare${index}`}
                      color="secondary"
                      outline
                      className={`p-0 border-0 w-100 mb-1 d-flex align-items-center justify-content-between ${style.collapse_btn}`}
                      onClick={() => {
                        if (!cardIsShowed.includes(index)) {
                          setCardIsShowed([...cardIsShowed, index]);
                        } else {
                          setCardIsShowed(
                            cardIsShowed.filter(item => item !== index),
                          );
                        }
                      }}
                    >
                      {i.title}
                      <div>
                        <Link
                          target="_blank"
                          to={`${
                            ordersURL[i.title.trim()]
                          }?${new URLSearchParams(
                            setCondition(i.title.trim()),
                          ).toString()}`}
                        >
                          <span>{i.quantity}</span>
                        </Link>
                        <FaChevronDown
                          className={`ms-2 ${i.details ? '' : 'opacity-0'} ${
                            cardIsShowed.includes(index)
                              ? style['upsideDown']
                              : style['upsideDown-reverse']
                          }`}
                        />
                      </div>
                    </Button>
                    {i.details && (
                      <UncontrolledCollapse toggler={`#prepare${index}`}>
                        <Card>
                          <CardBody style={{ backgroundColor: '#efefef' }}>
                            {i.details?.map((j, jIndex) => {
                              const url_main =
                                !j.details &&
                                ordersURL[i.title.trim()][j.title.trim()];
                              return (
                                <div
                                  style={{ marginBottom: '2rem' }}
                                  key={jIndex}
                                >
                                  <div className="d-flex align-items-center justify-content-between gray mb-2">
                                    <span>{j.title}</span>
                                    <span
                                      className={`${style.text_dark_info} d-inline-block me-2`}
                                    >
                                      {!j.details && (
                                        <Link
                                          target={'_blank'}
                                          to={`${url_main}?${new URLSearchParams(
                                            setCondition(
                                              i.title,
                                              j.title.trim(),
                                            ),
                                          )?.toString()}`}
                                        >
                                          <span>{j.quantity}</span>
                                        </Link>
                                      )}
                                      {j.details && <span>{j.quantity}</span>}
                                    </span>
                                  </div>
                                  {j.details && <hr />}
                                  {j.details &&
                                    j.details.map((k, kIndex) => {
                                      const url_sub =
                                        ordersURL[i.title.trim()][
                                          j.title.trim()
                                        ][k.title.trim()];
                                      return (
                                        <div
                                          key={kIndex}
                                          className="d-flex align-items-center justify-content-between gray mb-2"
                                        >
                                          <span>{k.title}</span>
                                          <span
                                            className={`${style.text_dark_info} d-inline-block me-2`}
                                          >
                                            <Link
                                              target={'_blank'}
                                              to={`${url_sub}?${new URLSearchParams(
                                                setCondition(k.title.trim()),
                                              )?.toString()}`}
                                            >
                                              {k.quantity}
                                            </Link>
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    )}
                  </div>
                ),
              )}
            </div>
          </Card>
        </Col>
        <Col>
          <Card className={style.search_card}>
            <div
              className={`${style.card_header} ${style.card_header_dark}`}
              style={{ backgroundColor: '#406DB2' }}
            >
              訊息紀錄
            </div>
            <div className={style.card_body}>
              <div className="d-flex align-items-center justify-content-between gray mb-2">
                <span>客服待處理</span>
                <span
                  className={style['link-text']}
                  onClick={() => {
                    if (
                      !userInfo.permissions.find(
                        permission => permission.id === 39,
                      )
                    ) {
                      // history.push("/");
                      MessageUtil.alertWanring(
                        '您無此功能權限，如需開啟此權限，請洽網站管理員',
                      );
                      return;
                    }
                    const condition = {
                      catType: '客服',
                      messageStatus: JSON.stringify(['待處理']),
                      page: 1,
                      page_size: 10,
                    };
                    const params = new URLSearchParams(condition);

                    // history.push(`/MessageList?${params.toString()}`);
                    window.open(
                      `/#/MessageList?${params.toString()}`,
                      '_blank',
                    );
                  }}
                >
                  {msgData.custPendingCount || 0}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between gray mb-2">
                <span>客服處理中</span>
                <span
                  className={style['link-text']}
                  onClick={() => {
                    if (
                      !userInfo.permissions.find(
                        permission => permission.id === 39,
                      )
                    ) {
                      // history.push("/");
                      MessageUtil.alertWanring(
                        '您無此功能權限，如需開啟此權限，請洽網站管理員',
                      );
                      return;
                    }
                    const condition = {
                      catType: '客服',
                      messageStatus: JSON.stringify(['待處理', '處理中']),
                      page: 1,
                      page_size: 10,
                    };
                    const params = new URLSearchParams(condition);

                    // history.push(`/MessageList?${params.toString()}`);
                    window.open(
                      `/#/MessageList?${params.toString()}`,
                      '_blank',
                    );
                  }}
                >
                  {msgData.custProcessingCount || 0}
                </span>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between gray mb-2">
                <span>業務待處理</span>
                <span
                  className={style['link-text']}
                  onClick={() => {
                    if (
                      !userInfo.permissions.find(
                        permission => permission.id === 40,
                      )
                    ) {
                      // history.push("/");
                      MessageUtil.alertWanring(
                        '您無此功能權限，如需開啟此權限，請洽網站管理員',
                      );
                      return;
                    }
                    const condition = {
                      catType: '業務',
                      messageStatus: JSON.stringify(['待處理']),
                      page: 1,
                      page_size: 10,
                    };
                    const params = new URLSearchParams(condition);

                    // history.push(`/MessageList?${params.toString()}`);
                    window.open(
                      `/#/MessageList?${params.toString()}`,
                      '_blank',
                    );
                  }}
                >
                  {msgData.salesPendingCount || 0}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between gray mb-2">
                <span>業務處理中</span>
                <span
                  className={style['link-text']}
                  onClick={() => {
                    if (
                      !userInfo.permissions.find(
                        permission => permission.id === 40,
                      )
                    ) {
                      // history.push("/");
                      MessageUtil.alertWanring(
                        '您無此功能權限，如需開啟此權限，請洽網站管理員',
                      );
                      return;
                    }
                    const condition = {
                      catType: '業務',
                      messageStatus: JSON.stringify(['待處理', '處理中']),
                      page: 1,
                      page_size: 10,
                    };
                    const params = new URLSearchParams(condition);

                    // history.push(`/MessageList?${params.toString()}`);
                    window.open(
                      `/#/MessageList?${params.toString()}`,
                      '_blank',
                    );
                  }}
                >
                  {msgData.salesProcessingCount || 0}
                </span>
              </div>
            </div>
          </Card>
          <Card className={style.search_card}>
            <div
              className={`${style.card_header} ${style.card_header_dark}`}
              style={{ backgroundColor: '#1DB4B9' }}
            >
              帳務相關
            </div>
            <div className={style.card_body}>
              <p>
                提醒您！目前尚有待核對處理之帳務，請前往帳務核對(
                <a href="#">連結</a>)
              </p>
            </div>
          </Card>
          <Card className={style.search_card}>
            <div
              className={`${style.card_header} ${style.card_header_dark}`}
              style={{ backgroundColor: '#BCA668' }}
            >
              發票異常
            </div>
            <div className={style.card_body}>
              <div className="d-flex align-items-center justify-content-between gray mb-2">
                <span>發票異常</span>
                <span>0</span>
              </div>
            </div>
          </Card>
          <Card className={style.search_card}>
            <div
              className={`${style.card_header} ${style.card_header_dark}`}
              style={{ backgroundColor: '#BCA668' }}
            >
              低於庫存量商品
            </div>
            <div className={style.card_body}>
              <Table>
                <thead>
                  <tr>
                    <th>商品名稱</th>
                    <th>ProductCode</th>
                    <th>現在庫存量</th>
                    <th>安全庫存量</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <th scope="row">
                      <Button color="link" className="p-0 text-decoration-none">
                        bal bla
                      </Button>
                    </th>
                    <td>1222222</td>
                    <td>2</td>
                    <td>3</td>
                </tr> */}
                </tbody>
              </Table>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
export default OverAll;
