import {
  NEWS_CLEAR_OUT,
  NEWS_DELETE_CLEAR,
  NEWS_DELETE_FAIL,
  NEWS_DELETE_REQUEST,
  NEWS_DELETE_SUCCESS,
  NEWS_DETAILS_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
} from '../../constants/data/newsConstants';

export const newsListReducer = (
  state = { loading: true, newsListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case NEWS_LIST_REQUEST:
      return { loading: true };
    case NEWS_LIST_SUCCESS:
      return { loading: false, newsListInfo: payload };
    case NEWS_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};
export const newsDeleteReducer = (state = { success: false }, action) => {
  const { type, payload } = action;
  switch (type) {
    case NEWS_DELETE_REQUEST:
      return { loading: true };
    case NEWS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case NEWS_DELETE_FAIL:
      return { error: payload, loading: false };
    case NEWS_DELETE_CLEAR:
      return { ...state, success: false };
    default:
      return state;
  }
};

export const newsDetailsReducer = (
  state = { loadingDetail: true, newsData: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case NEWS_DETAILS_REQUEST:
      return { loadingDetail: true };
    case NEWS_DETAILS_SUCCESS:
      return { loadingDetail: false, newsData: payload };
    case NEWS_DETAILS_FAIL:
      return { error: payload, loadingDetail: false };
    case NEWS_CLEAR_OUT:
      return { loadingDetail: true, newsData: {} };
    default:
      return state;
  }
};
