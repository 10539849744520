import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  ADMIN_ADD_FAIL,
  ADMIN_ADD_REQUEST,
  ADMIN_ADD_SUCCESS,
  ADMIN_DELETE_FAIL,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_PERMISSIONS_FAIL,
  ADMIN_PERMISSIONS_REQUEST,
  ADMIN_PERMISSIONS_SUCCESS,
  ADMIN_SEND_VERIFY_FAIL,
  ADMIN_SEND_VERIFY_REQUEST,
  ADMIN_SEND_VERIFY_SUCCESS,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_SUCCESS,
} from '../../constants/data/adminConstants';

//get admin list
export const getAdminList = ({ keyword, status, page }) => {
  const url = `/Backend/Admins/list?page=${page}&page_size=10&${
    keyword ? `keyword=${keyword}` : ''
  }&${status ? `status=${status}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ADMIN_LIST_FAIL,
      method: 'get',
      requestConstant: ADMIN_LIST_REQUEST,
      successConstant: ADMIN_LIST_SUCCESS,
      url,
    });
  };
};

//get permission options
export const getPermissionOptions = () => {
  const url = '/Backend/Admins/permissions';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ADMIN_PERMISSIONS_FAIL,
      method: 'get',
      requestConstant: ADMIN_PERMISSIONS_REQUEST,
      successConstant: ADMIN_PERMISSIONS_SUCCESS,
      url,
    });
  };
};

//add admin
export const addAdmin = object => {
  const url = '/Backend/Admins/account/add';
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: ADMIN_ADD_FAIL,
      method: 'post',
      requestConstant: ADMIN_ADD_REQUEST,
      successConstant: ADMIN_ADD_SUCCESS,
      url,
    });
  };
};

//get admin details
export const getAdminDetails = id => {
  const url = `/Backend/Admins/account?id=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ADMIN_DETAILS_FAIL,
      method: 'get',
      requestConstant: ADMIN_DETAILS_REQUEST,
      successConstant: ADMIN_DETAILS_SUCCESS,
      url,
    });
  };
};

//update admin
export const updateAdmin = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/Admins/account/update',
  });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: ADMIN_UPDATE_FAIL,
      method: 'post',
      requestConstant: ADMIN_UPDATE_REQUEST,
      successConstant: ADMIN_UPDATE_SUCCESS,
      url: '/Backend/Admins/account/update',
    });
  };
};

//delete admin
export const deleteAdmin = id => {
  const url = `/Backend/Admins/account?adminId=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ADMIN_DELETE_FAIL,
      method: 'delete',
      requestConstant: ADMIN_DELETE_REQUEST,
      successConstant: ADMIN_DELETE_SUCCESS,
      url,
    });
  };
};

//send verify letter
export const sendVerifyLetter = id => {
  const url = `/Backend/Admins/sendVerificationLetter?id=${id}`;
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ADMIN_SEND_VERIFY_FAIL,
      method: 'get',
      requestConstant: ADMIN_SEND_VERIFY_REQUEST,
      successConstant: ADMIN_SEND_VERIFY_SUCCESS,
      url,
    });
  };
};


export const getAdminListOptions = async ()=>{

  return await request({
    method: 'get',
    url: '/Backend/Admins/GetAdminList',
  }).then(res => {
    return res;
  });
};
