import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_MEALINTRO_OTHER_LIST_REQUEST,
  UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_LIST_FAIL,
  UUCAFE_MEALINTRO_OTHER_REQUEST,
  UUCAFE_MEALINTRO_OTHER_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_FAIL,
  UUCAFE_MEALINTRO_OTHER_ADD_REQUEST,
  UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_ADD_FAIL,
  UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL,
} from '../../constants/data/uuCafeMealOtherConstants';

// ============ MEALINTRO_Other ==================
/*
* "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string"
*/
export const getCafeMealIntroOtherList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_OTHER_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_OTHER_LIST_REQUEST,
      successConstant: UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS,
      url: '/Backend/Cafe_MealOthMenu/list',
    });
  };
};

export const getCafeMealIntroOther = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MEALINTRO_OTHER_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MEALINTRO_OTHER_REQUEST,
      successConstant: UUCAFE_MEALINTRO_OTHER_SUCCESS,
      url: `/Backend/Cafe_MealOthMenu/menu/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
title string
title_en string
content string
content_en string
startTime string($date-time)
endTime string($date-time)
img_files array
*/
export const addCafeMealIntroOther = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_OTHER_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_OTHER_ADD_REQUEST,
      successConstant: UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS,
      url: '/Backend/Cafe_MealOthMenu/menu/add',
    });
  };
};

/*
* id integer($int32)
  name string
  name_en string
  templateType integer($int32)
  status integer($int32)
  img_files array
* */
export const updateCafeMealIntroOther = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST,
      successConstant: UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealOthMenu/menu/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeMealIntroOther = async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_MealOthMenu/menu/delete',
  });
};
