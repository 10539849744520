import {
  LOWEST_NOTIFY_INFO_REQUEST,
  LOWEST_NOTIFY_INFO_SUCCESS,
  LOWEST_NOTIFY_INFO_FAIL,
  LOWEST_NOTIFY_UPDATE_REQUEST,
  LOWEST_NOTIFY_UPDATE_SUCCESS,
  LOWEST_NOTIFY_UPDATE_FAIL,
  LOWEST_NOTIFY_UPDATE_RESET,
} from 'redux/constants/data/lowestNotifyConstants';

export const lowestNotifyInfoReducer = (
  state = { loading: false, lowestNotifyInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case LOWEST_NOTIFY_INFO_REQUEST:
      return { ...state, loading: true };
    case LOWEST_NOTIFY_INFO_SUCCESS:
      return { loading: false, lowestNotifyInfo: payload || [] };
    case LOWEST_NOTIFY_INFO_FAIL:
      return { error: payload, loading: false, lowestNotifyInfo: [] };
    default:
      return state;
  }
};
export const updateLowestNotifyReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOWEST_NOTIFY_UPDATE_REQUEST:
      return { loading: true };
    case LOWEST_NOTIFY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case LOWEST_NOTIFY_UPDATE_FAIL:
      return { error: payload, loading: false };
    case LOWEST_NOTIFY_UPDATE_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
