import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import { resetPassword, verifyUser } from '../redux/actions/data/userActions';
import {
  USER_RESET_PASSWORD_RESET,
  USER_VERIFY_RESET,
} from '../redux/constants/data/userConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const ResetPassword = () => {
  const swal = withReactContent(Swal);
  const { location } = history;
  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const [adminId, setAdminId] = useState(0);

  const { loading, userData, error } = useSelector(state => state.userVerify);
  const {
    loading: resetLoading,
    success: resetSuccess,
    error: resetError,
  } = useSelector(state => state.userResetPassword);

  useEffect(() => {
    if (location.search) {
      const codeString = location.search.split('=')[1];
      setCode(codeString);
    }
  }, [location.search]);

  useEffect(() => {
    console.log(code);
    if (code) {
      dispatch(verifyUser({ code }));
    }
  }, [code]);

  useEffect(() => {
    console.log(userData);
    if (userData && userData.id) {
      setAdminId(userData.id);
    }

    if (error && error.status === 403) {
      swal.fire({
        icon: 'error',
        title: '驗證已失效',
      });
    }

    return () => {
      dispatch({ type: USER_VERIFY_RESET });
    };
  }, [userData, error]);

  useEffect(() => {
    console.log(resetSuccess);
    if (resetSuccess) {
      MessageUtil.toastSuccess('設定成功');

      setTimeout(() => {
        history.push('/Login');
      }, 1500);
    }

    if (resetError && resetError.status == 404) {
      console.log('nnnnnn');
      MessageUtil.alertWanring('無此帳號', '');
    }

    return () => {
      dispatch({ type: USER_RESET_PASSWORD_RESET });
    };
  }, [resetSuccess, resetError]);

  const handleSubmit = values => {
    console.log(values);
    console.log(adminId);
    const object = {
      adminId: adminId,
      newPwd: values.password,
    };

    if (values.password === values.confirmPassword) {
      dispatch(resetPassword(object));
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], '密碼不相符!')
        .required('必填!'),
      password: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <div className="panel">
      <div className="login">
        <img src="/images/logo.svg" className="w200 mb-3" />
        <Card className={style.card}>
          <Formik
            enableReinitialize
            initialValues={{
              confirmPassword: '',
              password: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema}
          >
            {props => (
              <Form className="form-list form-label-140">
                <div className="text-center">
                  <h1 className={style.password_panel_title}>重設密碼</h1>
                </div>
                {/* <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="請輸入驗證碼"
                  value={code}
                  readOnly
                  onChange={(e) => setCode(e.target.value)}
                />
              </div> */}
                <div className="form-group mb-3" style={{ display: 'block' }}>
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="請輸入新密碼"
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="form-error"
                  />
                </div>
                <div className="form-group mb-3" style={{ display: 'block' }}>
                  <Field
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                    placeholder="確認密碼"
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    component="span"
                    className="form-error"
                  />
                </div>
                <div className="text-center py-4">
                  <Button
                    type="submit"
                    color="second"
                    className={style.password_panel_button}
                    disabled={resetLoading}
                  >
                    {resetLoading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    <span className="btn-wrapper--label">重設密碼</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
