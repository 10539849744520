import Layout from 'components/Layout';
import { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaArrowsAltV, FaPlay } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Card } from 'reactstrap';
import {
  getCategoryList,
  updateCategorySort,
} from 'redux/actions/data/categoryActions';
import { CATEGORY_SORT_RESET } from 'redux/constants/data/categoryConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';


import style from '../styles/layout.module.css';

const ProductCategorySorting = () => {
  const dispatch = useDispatch();
  const [sortList, setSortList] = useState([]);
  const [sortSubList, setSortSubList] = useState([]);

  const handleOnDragEnd = result => {
    const items = Array.from(sortList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortList(items);

    const body = items.map((item, index) => ({
      id: item.id,
      sort: index + 1,
    }));
    CommonUtil.consoleLog({ anno: 'request body', data: body });
    dispatch(updateCategorySort(body));
  };

  useEffect(() => {
    // call list api
    dispatch(getCategoryList('', '', 0));
  }, []);

  const handleOnDragEndSub = result => {
    const items = Array.from(sortSubList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortSubList(items);
    const body = items.map((item, index) => ({
      id: item.id,
      sort: index + 1,
    }));
    CommonUtil.consoleLog({ anno: 'request body', data: body });
    dispatch(updateCategorySort(body));
  };

  const {
    loading,
    categoryList: list,
    error,
    success,
  } = useSelector(state => state.categoryList);

  useEffect(() => {
    let main = [];
    list?.datas?.forEach(data => {
      main.push(data);
    });
    setSortList(main);
  }, [list]);

  const toggleSubItems = parentId => {
    let sub = [];
    list.datas?.forEach(data => {
      if (data.cat2s.length > 0) {
        window.scrollTo(0, 0);
        if (data.id === parentId) {
          sub.push(...data.cat2s);
        }
      }
    });
    setSortSubList(sub);
  };

  useEffect(() => {
    if (error) {
      MessageUtil.alertWanring('發生錯誤', error);
      dispatch({ type: CATEGORY_SORT_RESET });
    }

    if (success) {
      dispatch({ type: CATEGORY_SORT_RESET });
    }
  }, [error, success]);

  return (
    <Layout
      pageTitle={'分類選單排序'}
      items={[
        { isActive: true, page_name: '訊息紀錄' },
        { isActive: true, page_name: '分類選單管理' },
        { isActive: false, page_name: '分類選單排序' },
      ]}
    >
      <div className={style.show_flex} style={{ alignItems: 'flex-start' }}>
        <Card
          className={`${style.card} ${style['message-cat-card']}`}
          style={{ maxHeight: window.innerHeight * 0.7, overflowY: 'scroll' }}
        >
          <div className={style.formGroup}>
            <label className={style['message-cat-label']}>第一層選單</label>
            <div style={{ margin: 'auto' }} />
            <small>
              <FaArrowsAltV />
              直接拖拉排序
            </small>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sortList?.map(({ id, name, parent_name, cat2s }, index) => (
                    <Draggable key={id} draggableId={`${id}`} index={index}>
                      {provided => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Alert
                            color="secondary"
                            style={{ width: '100%' }}
                            className={style.show_flex}
                          >
                            {name || parent_name}
                            <div style={{ margin: 'auto' }} />
                            {cat2s.length > 0 && (
                              <FaPlay
                                style={{ cursor: 'pointer' }}
                                size="20"
                                onClick={() => {
                                  toggleSubItems(id);
                                }}
                              />
                            )}
                          </Alert>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
        <div style={{ margin: '0.5rem' }} />
        {sortSubList?.length > 0 && (
          <Card
            className={`${style.card} ${style['message-cat-card']}`}
            style={{ maxHeight: window.innerHeight * 0.7, overflowY: 'scroll' }}
          >
            <div className={style.formGroup}>
              <label className={style['message-cat-label']}>第二層</label>
              <div style={{ margin: 'auto' }} />
              <small>
                <FaArrowsAltV />
                直接拖拉排序
              </small>
            </div>
            <DragDropContext onDragEnd={handleOnDragEndSub}>
              <Droppable droppableId="brands">
                {provided => (
                  <ul
                    className="drag_list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {sortSubList?.map(({ id, name }, index) => (
                      <Draggable key={id} draggableId={`${id}`} index={index}>
                        {provided => (
                          <li
                            className={`${style.formGroup}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Alert
                              color="secondary"
                              style={{ width: '100%' }}
                              className={style.show_flex}
                            >
                              {name}
                              <div style={{ margin: 'auto' }} />
                            </Alert>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
        )}
      </div>
    </Layout>
  );
};

export default ProductCategorySorting;
