export const GET_REWARDS_POINTS_SETTINGS_REQUEST =
  'GET_REWARDS_POINTS_SETTINGS_REQUEST';
export const GET_REWARDS_POINTS_SETTINGS_SUCCESS =
  'GET_REWARDS_POINTS_SETTINGS_SUCCESS';
export const GET_REWARDS_POINTS_SETTINGS_FAIL =
  'GET_REWARDS_POINTS_SETTINGS_FAIL';

export const UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE =
  'UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE';
export const UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST =
  'UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST';
export const UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS =
  'UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS';
export const UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL =
  'UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL';

export const UPDATE_REWARDS_POINTS_RULES_IDLE =
  'UPDATE_REWARDS_POINTS_RULES_IDLE';
export const UPDATE_REWARDS_POINTS_RULES_REQUEST =
  'UPDATE_REWARDS_POINTS_RULES_REQUEST';
export const UPDATE_REWARDS_POINTS_RULES_SUCCESS =
  'UPDATE_REWARDS_POINTS_RULES_SUCCESS';
export const UPDATE_REWARDS_POINTS_RULES_FAIL =
  'UPDATE_REWARDS_POINTS_RULES_FAIL';

export const UPDATE_REDEEM_REWARDS_POINTS_IDLE =
  'UPDATE_REDEEM_REWARDS_POINTS_IDLE';
export const UPDATE_REDEEM_REWARDS_POINTS_REQUEST =
  'UPDATE_REDEEM_REWARDS_POINTS_REQUEST';
export const UPDATE_REDEEM_REWARDS_POINTS_SUCCESS =
  'UPDATE_REDEEM_REWARDS_POINTS_SUCCESS';
export const UPDATE_REDEEM_REWARDS_POINTS_FAIL =
  'UPDATE_REDEEM_REWARDS_POINTS_FAIL';
