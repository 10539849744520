import React, { memo } from 'react';
import { Card } from 'reactstrap';

import style from '../../styles/layout.module.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '1rem',
};

const MemberPresentRecords = ({
  datas = {
    birthday: [],
    openCard: [],
    upgrade: [],
  },
}) => {
  return (
    <Card className={style.card}>
      <div className="mb-3">
        <h4 className="title" style={titleStyle}>
          開卡禮－領取記錄 (會員資訊有填寫完整時贈送開卡禮)
        </h4>
        <div className="d-inline-flex w-100 g-1 flex-column mb-2">
          {datas?.openCard?.map(item => (
            <div className="w-100 d-inline-flex g-1" key={JSON.stringify(item)}>
              <div className="d-inline-block">
                {moment(item.getDate).format('YYYY/MM/DD')}
              </div>
              <div className="d-inline-block mx-2">活動序號:</div>
              <Link
                className="d-inline-block"
                to={`/MemberPresentSettings?numberQuery=${item.couponCode}&numberQueryType=活動序號&page=1&status=%5B%5D&v=1722219596119`}
              >
                {item.couponCode}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-3">
        <h4 className="title" style={titleStyle}>
          生日禮－領取記錄
        </h4>
          <div className="d-inline-flex w-100 g-1 flex-column">
              {datas?.birthday?.map(item => (
                  <div className="w-100 d-inline-flex g-1" key={JSON.stringify(item)}>
                      <div className="d-inline-block">
                          {moment(item.getDate).format('YYYY/MM/DD')}
                      </div>
                      <div className="d-inline-block mx-2">活動序號 :</div>
                      <Link
                          className="d-inline-block"
                          to={`/MemberPresentSettings?numberQuery=${item.couponCode}&numberQueryType=活動序號&page=1&status=%5B%5D&v=1722219596119`}
                      >
                          {item.couponCode}
                      </Link>
                  </div>
              ))}
          </div>
      </div>
      <div className="mb-3">
        <h4 className="title" style={titleStyle}>
          升等禮－領取記錄
        </h4>
          <div className="d-inline-flex w-100 g-1 flex-column">
              {datas?.upgrade?.map(item => (
                  <div className="w-100 d-inline-flex g-1" key={JSON.stringify(item)}>
                      <div className="d-inline-block">
                          {moment(item.getDate).format('YYYY/MM/DD')}
                      </div>
                      <div className="d-inline-block mx-2">活動序號:</div>
                      <Link
                          className="d-inline-block"
                          to={`/MemberPresentSettings?numberQuery=${item.couponCode}&numberQueryType=活動序號&page=1&status=%5B%5D&v=1722219596119`}
                      >
                          {item.couponCode}
                      </Link>
                  </div>
              ))}
          </div>
      </div>
    </Card>
  );
};

export default memo(MemberPresentRecords);
