import Layout from 'components/Layout';
import { useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  getSeoSettingsList,
  updateSeoSettings,
} from 'redux/actions/data/seoSettingsAction';
import { SEOSETTINGS_UPDATE_IDLE } from 'redux/constants/data/seoSettingsConstants';
import MessageUtil from 'utils/MessageUtil';


import style from '../styles/layout.module.css';

const EditModal = ({ isOpen, toggle, page }) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    state => state.seoSettingsUpdate,
  );
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      desc: page?.desc,
      desc_en: page?.desc_en,
      id: page?.id,
      keyword: page?.keyword,
      keyword_en: page?.keyword_en,
      title: page?.title,
      title_en: page?.title_en,
    },
    onSubmit: values => {
      dispatch(updateSeoSettings(values));

      // console.log(values);
    },
  });

  useEffect(() => {
    if (!loading) {
      success && !error && MessageUtil.toastSuccess('儲存成功!');
      success && !error && toggle();
      !success && error && MessageUtil.alertWanring('儲存失敗', error);
    }
  }, [loading, success, error]);

  const seoTitleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
  };

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        <span style={{ color: '#3c44b1' }}>{page?.typeName}</span>
      </ModalHeader>
      <ModalBody>
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>語系設定說明</span>
            <ul style={{ fontSize: '14px' }}>
              <li>
                中文(繁體)為前台的第一優先顯示語系，必填欄位之資訊不得為空值。
              </li>
              <li>其他非預設的語系若無填寫，前台將顯示中文(繁體)的文案。</li>
            </ul>
          </div>
        </Alert>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品標題(Title)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>顯示於搜尋結果上的名稱，建議使用商品名稱。</li>
                <li>建議輸入30個中文字以內。</li>
              </small>
            </ul>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                value={values.title}
                onChange={e => setFieldValue('title', e.target.value)}
              />
            </div>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                value={values.title_en}
                onChange={e => setFieldValue('title_en', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品關鍵字(Keywords)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>
                  建議輸入3-5組關鍵字，每組關鍵字之間請以,區隔，如關鍵字1,關鍵字2,關鍵字3
                </li>
              </small>
            </ul>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                value={values.keyword}
                onChange={e => setFieldValue('keyword', e.target.value)}
              />
            </div>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                value={values.keyword_en}
                onChange={e => setFieldValue('keyword_en', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品描述(Description)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>顯示於搜尋結果的說明，建議輸入100個中文字以內</li>
              </small>
            </ul>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                type="textarea"
                style={{ height: '106px' }}
                value={values.desc}
                onChange={e => {
                  setFieldValue('desc', e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className={style.show_flex}
            style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                type="textarea"
                style={{ height: '106px' }}
                value={values.desc_en}
                onChange={e => {
                  setFieldValue('desc_en', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {page.updater && (
          <div style={{ margin: '1rem' }}>
            {`異動時間： ${page.updateDate} ${page.updateTime}`}
            <span style={{ marginLeft: '1rem' }}>{page.updater}</span>
          </div>
        )}
        <div>
          <Button className={style.modal_button_cancel} onClick={toggle}>
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            儲存
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const SEOSettings = () => {
  const dispatch = useDispatch();
  const { loading, success, list } = useSelector(
    state => state.seoSettingsList,
  );
  const { success: updateSuccess } = useSelector(
    state => state.seoSettingsUpdate,
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleModalHandler = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    dispatch(getSeoSettingsList());
  }, []);

  useEffect(() => {
    if (!modalIsOpen && updateSuccess) {
      dispatch({ type: SEOSETTINGS_UPDATE_IDLE });
      dispatch(getSeoSettingsList());
    }
  }, [modalIsOpen]);

  return (
    <Layout
      pageTitle="SEO設定"
      items={[
        { isActive: false, page_name: '網頁設定' },
        { isActive: true, page_name: 'SEO設定' },
      ]}
    >
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>頁面</th>
                <th>商品標題(Title)</th>
                <th>商品關鍵字(Keywords)</th>
                <th>商品描述(Description)</th>
                <th>異動時間</th>
                <th>功能</th>
              </tr>
            </thead>
            <tbody className={style['padding']}>
              {!loading &&
                success &&
                list.map((page, index) => (
                  <tr key={index}>
                    <td>{page.typeName}</td>
                    <td>{page.title || ''}</td>
                    <td>{page.keyword || ''}</td>
                    <td>{page.desc || ''}</td>
                    <td>
                      <div>{`${page.updateDate} ${page.updateTime}`}</div>
                      <div>{page.updater || ''}</div>
                    </td>
                    <td>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          key={index}
                          className="m-2 btn btn-outline-primary btn-sm text-primary"
                          style={{
                            backgroundColor: 'transparent',
                            maxWidth: '80px',
                            width: '90%',
                          }}
                          onClick={() => {
                            setSelectedItem(page);
                            toggleModalHandler();
                          }}
                        >
                          編輯
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              {!loading && !success}
            </tbody>
          </Table>
        </div>
      </Card>
      {modalIsOpen && (
        <EditModal
          isOpen={modalIsOpen}
          toggle={toggleModalHandler}
          page={selectedItem}
        />
      )}
    </Layout>
  );
};

export default SEOSettings;
