import moment from 'moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  getSendRepairRegisterRecord,
  sendRepairRegisterSMS,
} from '../../redux/actions/data/repairActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const SMSNotificationModal = ({ isOpen = false, maintaince = {}, toggle }) => {
  const [message, setMessage] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);

  const [replyLoading, setReplyLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setReplyLoading(true);
    try {
      await sendRepairRegisterSMS({
        content: message,
        countryCode: maintaince.countryCode_sender,
        phoneNumber: maintaince.phone_sender,
        repairId: maintaince.id,
      });
      MessageUtil.alertWanring('發送成功');
      getSendRepairRegisterRecord(maintaince.id).then(res => {
        setMessageHistory(res);
      });
      setMessage('')
    } catch (e) {
      MessageUtil.alertWanring('發送失敗', e);
    }
    setReplyLoading(false);
  }, [maintaince, message]);

  useEffect(() => {
    getSendRepairRegisterRecord(maintaince.id).then(res => {
      setMessageHistory(res);
    });
  }, [maintaince]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      style={{
        minWidth: '80%',
      }}
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        簡訊通知
      </ModalHeader>
      <ModalBody
        className={`form-list form-label-140 mb-5 ${style.modal_body}`}
      >
        <div className="d-flex flex-column flex-sm-row-reverse">
          <div
            style={{ flex: '0 0 auto', maxWidth: '400px' , minWidth: '300px' }}
            className="px-2 overflow-auto"
          >
            {messageHistory.length === 0
              ? '無歷史紀錄'
              : messageHistory.map(h => {
                  return (
                    <div className="mb-3" key={JSON.stringify(h)}>
                      <small className="text-secondary">
                        {moment(h.sentTime).format('YYYY/MM/DD HH:mm:ss')}{' '}
                        {h.adminName}
                      </small>
                      <h6>{h.content} </h6>
                    </div>
                  );
                })}
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <Input
              type="textarea"
              value={message}
              rows="10"
              onChange={e => {
                setMessage(e.target.value);
              }}
            />
            <p className="mb-3 text-secondary text-end">
              <small>({message.length}/70) 發送封數: { Math.ceil(message.length/70)}</small>
            </p>
            <Button
              className={style.modal_button_submit}
              onClick={()=>{
                MessageUtil.confirm('是否確認發送訊息' , '' , handleSubmit)
              }}
              disabled={replyLoading || !message}
            >
              送出回覆
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(SMSNotificationModal);
