import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Table } from 'reactstrap';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  getProductExcel,
  getProductSerialNumbers,
} from '../redux/actions/data/warrantyActions';
import { PRODUCT_EXCEL_PRODUCE_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function ProductNumDetail(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const { id } = useParams();
  const query = useQuery();
  const page_size = 10;

  const [current_page, setCurrentPage] = useState(1);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, productSerialNumberInfo, error } = useSelector(
    state => state.productSerialNumber,
  );
  const { result } = useSelector(state => state.productExcelCreate);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/WarrantyNumDetail' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/WarrantyNumDetail' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryPage = query.value.page;

      setCurrentPage(queryPage);

      if (queryPage) {
        //單純記錄頁數
        dispatch(
          getProductSerialNumbers({
            page: queryPage || 1,
            page_size,
            productSerialNumMtId: id,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);

      //初始搜尋
      dispatch(
        getProductSerialNumbers({
          page: 1,
          page_size,
          productSerialNumMtId: id,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (result) {
      window.open(result);
    }

    return () => {
      dispatch({ type: PRODUCT_EXCEL_PRODUCE_RESET });
    };
  }, [result]);

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getProductSerialNumbers({
        page,
        page_size,
        productSerialNumMtId: id,
      }),
    );

    history.push(`${location.pathname}?page=${page}`);
  };

  const handleConvert = () => {
    dispatch(getProductExcel(id));
  };

  return (
    <Layout
      pageTitle="批次序號清單"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: false, page_name: '產出產品序號', to: '/ProductNum' },
        { isActive: true, page_name: '批次序號清單' },
      ]}
    >
      <Card className={style.card}>
        <Button
          className="btn btn-success w100"
          onClick={() => handleConvert()}
        >
          匯出Excel
        </Button>
        <div className="d-flex justify-content-between mt-4">
          <div>
            新增時間：{' '}
            {!loading &&
            productSerialNumberInfo &&
            productSerialNumberInfo.total_count > 0
              ? `${productSerialNumberInfo.createDate} ${productSerialNumberInfo.createTime}`
              : ' '}
          </div>
          <div>
            共
            <span className="mx-1">
              {!loading &&
              productSerialNumberInfo &&
              productSerialNumberInfo.total_count > 0
                ? productSerialNumberInfo.total_count
                : '0'}
            </span>
            筆
          </div>
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">品牌</th>
                <th scope="col">產品序號</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {productSerialNumberInfo &&
                productSerialNumberInfo.productSerialNumDts?.length > 0 ? (
                  productSerialNumberInfo.productSerialNumDts.map(
                    (data, index) => (
                      <tr key={index}>
                        <td>{data.brandName}</td>
                        <td>{data.productSerialNum}</td>
                      </tr>
                    ),
                  )
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          productSerialNumberInfo &&
          productSerialNumberInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={productSerialNumberInfo.current_page}
                defaultPageSize={10}
                total={productSerialNumberInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>
    </Layout>
  );
}
