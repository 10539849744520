import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shippingPrintInfo } from 'redux/actions/data/orderShippingActions';
import 'styles/shipmentDetail.css';
import history from 'utils/createHistory';

import { getPurchaseNote } from '../redux/actions/data/cartActions';

const OrderPrintDetail = () => {
  const dispatch = useDispatch();
  const { shippingPrintInfo: info } = useSelector(
    state => state.shippingPrintInfo,
  );

  const { purchaseNote } = useSelector(state => state.purchaseNote);

  const handleGetPrintInfo = () => {
    let list = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })?.orderNumber;
    const params = typeof list === 'string' ? [list] : list?.map(i => i);
    dispatch(shippingPrintInfo(params));
    dispatch(getPurchaseNote());
  };
  useEffect(() => {
    handleGetPrintInfo();
  }, []);
  const handlePrint = () => {
    document.title = '出貨明細表';
    window.print();
  };

  return (
    <div
      style={{
        backgroundColor: '#fafafa',
        bottom: 0,
        color: '#000',
        fontSize: 'inherit',
        left: 0,
        margin: 0,
        overflowY: 'auto',
        padding: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 9999,
      }}
      className="printWrap"
    >
      <div className="doPrint" onClick={handlePrint}>
        列印
      </div>

      {info
        ? info?.map(i => (
            <div className="page" key={i.id}>
              <h2
                style={{
                  fontFamily: 'Microsoft JhengHei',
                  fontSize: '24px',
                  marginBottom: '10px',
                  marginTop: '5px',
                  textAlign: 'center',
                }}
              >
                百滋國際 出貨明細表
              </h2>

              <div>
                <h3
                  style={{
                    fontFamily: 'Microsoft JhengHei',
                    fontSize: '11pt',
                    lineHeight: 'inherit',
                    marginBottom: '5px',
                    marginTop: '5px',
                    textAlign: 'left',
                  }}
                >
                  收件人： {i.name}
                </h3>
                <h3
                  style={{
                    fontFamily: 'Microsoft JhengHei',
                    fontSize: '11pt',
                    lineHeight: 'inherit',
                    marginBottom: '5px',
                    marginTop: '5px',
                    textAlign: 'left',
                  }}
                >
                  主單編號： {i.orderNumber}
                </h3>
                <table
                  className="printTable"
                  style={{
                    fontFamily: 'Microsoft JhengHei',
                    fontSize: '11pt',
                    width: '100%',
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '7%' }}
                        colSpan="3"
                      >
                        項次
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '25%' }}
                        colSpan="10"
                      >
                        訂單編號
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '12%' }}
                        colSpan="6"
                      >
                        品項編碼
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '30%' }}
                        colSpan="14"
                      >
                        商品名稱
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '18%' }}
                        colSpan="10"
                      >
                        顏色、尺寸
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '6%' }}
                        colSpan="3"
                      >
                        數量
                      </td>
                    </tr>
                    {i.details.map((j, jIndex) => (
                      <tr>
                        <td
                          className="ColAlignLeft"
                          style={{ width: '7%' }}
                          colSpan="3"
                          nowrap="nowrap"
                        >
                          {jIndex + 1}
                        </td>
                        <td
                          className="ColAlignLeft"
                          style={{ width: '25%' }}
                          colSpan="10"
                          nowrap="nowrap"
                        >
                          {j.orderItemNumber}
                        </td>
                        <td
                          className="ColAlignLeft"
                          style={{ whiteSpace: 'break-spaces', width: '12%' }}
                          colSpan="6"
                          nowrap="nowrap"
                        >
                          {j.productcode}
                        </td>
                        <td
                          className="ColAlignLeft"
                          style={{ width: '30%' }}
                          colSpan="14"
                        >
                          {j.productname}
                        </td>
                        <td
                          className="ColAlignLeft"
                          style={{ width: '18%' }}
                          colSpan="10"
                        >
                          {j.color || '-'}/{j.size || '-'}
                        </td>
                        <td
                          className="ColAlignLeft"
                          style={{ width: '6%' }}
                          colSpan="3"
                          nowrap="nowrap"
                        >
                          {j.quantity}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '7%' }}
                        colSpan="3"
                      >
                        總計
                      </td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '87%' }}
                        colSpan="40"
                      ></td>
                      <td
                        className="ColAlignLeft FontBold"
                        style={{ width: '6%' }}
                        colSpan="3"
                        nowrap="nowrap"
                      >
                        {i.details.reduce((a, b) => a + b.quantity, 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <br />
              <br />
              <table
                style={{
                  fontFamily: 'Microsoft JhengHei',
                  fontSize: '11pt',
                  width: '100%',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className="ColAlignLeft"
                      style={{ width: '100%' }}
                      colSpan="2"
                    >
                      {i.remark}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ColAlignLeft FontBold fs-md pb-3"
                      style={{ width: '100%' }}
                      colSpan="2"
                    >
                      【退換貨政策】
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ColAlignLeft"
                      style={{ whiteSpace: 'pre-line', width: '100%' }}
                      colSpan="2"
                    >
                      {purchaseNote[2]?.content}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))
        : null}
    </div>
  );
};

export default OrderPrintDetail;
