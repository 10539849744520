export const UUCAFE_SERVICES_LIST_REQUEST = 'UUCAFE_SERVICES_LIST_REQUEST';
export const UUCAFE_SERVICES_LIST_SUCCESS = 'UUCAFE_SERVICES_LIST_SUCCESS';
export const UUCAFE_SERVICES_LIST_FAIL = 'UUCAFE_SERVICES_LIST_FAIL';
export const UUCAFE_SERVICES_DETAIL_REQUEST = 'UUCAFE_SERVICES_DETAIL_REQUEST';
export const UUCAFE_SERVICES_DETAIL_SUCCESS = 'UUCAFE_SERVICES_DETAIL_SUCCESS';
export const UUCAFE_SERVICES_DETAIL_FAIL = 'UUCAFE_SERVICES_DETAIL_FAIL';
export const UUCAFE_SERVICES_DETAIL_ADD_REQUEST =
  'UUCAFE_SERVICES_DETAIL_ADD_REQUEST';
export const UUCAFE_SERVICES_DETAIL_ADD_SUCCESS =
  'UUCAFE_SERVICES_DETAIL_ADD_SUCCESS';
export const UUCAFE_SERVICES_DETAIL_ADD_FAIL =
  'UUCAFE_SERVICES_DETAIL_ADD_FAIL';
export const UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST =
  'UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST';
export const UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS =
  'UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS';
export const UUCAFE_SERVICES_DETAIL_UPDATE_FAIL =
  'UUCAFE_SERVICES_DETAIL_UPDATE_FAIL';
