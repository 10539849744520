import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { Button, Card, Col, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';

import { deleteCafeShopImage } from '../../redux/actions/data/uuCafeShopAction';
import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import request from '../../utils/useAxios';
import Loader from '../Loader';
import UploadFileBox from '../UploadFileBox';

const ShopEditor = ({ detail, loading, handleSubmit, goBack }) => {
  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      address: Yup.string().required('必填!'),
      address_en: Yup.string().required('必填!'),
      cityId: Yup.string().required('必填!'),
      countryId: Yup.string().required('必填!'),
      latitude: Yup.string().required('必填!'),
      longitude: Yup.string().required('必填!'),
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
      phone: Yup.string().required('必填!'),
      services: Yup.array().required('必填!'),
      townId: Yup.string().required('必填!'),
    });
    return schema;
  };

  const [serviceOptions, setServiceOptions] = useState([]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [townOptions, setTownOptions] = useState([]);

  const loadServiceList = useCallback(async () => {
    const res = await request({
      method: 'get',
      url: '/Backend/Cafe_Shop/services',
    });
    setServiceOptions(res || []);
    return res || [];
  }, [setServiceOptions]);

  const loadCountryList = useCallback(async () => {
    const res = await request({
      method: 'get',
      url: '/Website/City/countryList',
    });
    setCountryOptions(res.result || []);
    return res;
  }, [setCountryOptions]);

  const loadCityList = useCallback(
    async countryId => {
      const res = await request({
        method: 'get',
        url: `/Website/City/cityList/${countryId}`,
      });
      setCityOptions(res.result || []);
      return res;
    },
    [setCityOptions],
  );

  const loadTownList = useCallback(
    async cityId => {
      const res = await request({
        method: 'get',
        url: `/Website/City/townList/${cityId}`,
      });
      setTownOptions(res.result || []);
      return res;
    },
    [setTownOptions],
  );
  const initState = useRef({});

  useEffect(() => {
    loadServiceList();
    loadCountryList();
  }, []);

  useEffect(() => {
    initState.current = {
      address: detail.address || '',
      address_en: detail.address_en || '',
      businessHours: detail.businessHours || '',
      businessHours_en: detail.businessHours_en || '',
      cityId: detail.cityId,
      countryId: detail.countryId,
      img_files: null,
      init_img_files: getImageDataFromSetting(detail.imgFile, detail.imgUrl),
      latitude: detail.latitude,
      longitude: detail.longitude,
      name: detail.name,
      name_en: detail.name_en,
      otherDesc: detail.otherDesc || '',
      otherDesc_en: detail.otherDesc_en || '',
      phone: detail.phone,
      services: serviceOptions.filter(s =>
        (detail.serviceIds || []).join(',').includes(s.value),
      ),
      townId: detail.townId,
    };
    if (detail.cityId) {
      loadTownList(detail.cityId);
    }
    if (detail.countryId) {
      loadCityList(detail.countryId);
    }
  }, [detail]);

  if (!detail) {
    return <Loader />;
  }
  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Formik
        enableReinitialize
        initialValues={initState.current}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label>*門市名稱(中文)</label>
              <div className="form-item">
                <Field name="name" className="form-control" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*門市名稱(英文)</label>
              <div className="form-item">
                <Field name="name_en" className="form-control" />
                <ErrorMessage
                  name="name_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*電話</label>
              <div className="form-item">
                <Field name="phone" className="form-control" />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*地址</label>
              <div className="form-item">
                <Row className="w-100">
                  <Col sm={12} md={4}>
                    <Field
                      name="countryId"
                      className="form-control"
                      as="select"
                      onChange={e => {
                        props.setFieldValue('countryId', e.target.value);
                        props.setFieldValue('cityId', '');
                        props.setFieldValue('townId', '');
                        initState.current = props.values;
                        initState.current.countryId = e.target.value;
                        initState.current.cityId = '';
                        initState.current.townId = '';

                        if (e.target.value) {
                          loadCityList(e.target.value);
                        } else {
                          setTownOptions([]);
                          setCityOptions([]);
                        }
                      }}
                    >
                      <option value="">-</option>
                      {countryOptions.map(c => {
                        return (
                          <option key={`country${c.id}`} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="countryId"
                      component="div"
                      className="form-error"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <Field
                      name="cityId"
                      as="select"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue('cityId', e.target.value);
                        props.setFieldValue('townId', '');
                        initState.current = props.values;
                        initState.current.cityId = e.target.value;
                        initState.current.townId = '';

                        if (e.target.value) {
                          loadTownList(e.target.value);
                        } else {
                          setTownOptions([]);
                        }
                      }}
                    >
                      <option value="">-</option>
                      {cityOptions.map(c => {
                        return (
                          <option key={`city${c.id}`} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="cityId"
                      component="div"
                      className="form-error"
                    />
                  </Col>
                  <Col sm={12} md={4}>
                    <Field
                      name="townId"
                      as="select"
                      className="form-control"
                      onChange={e => {
                        props.setFieldValue('townId', e.target.value);
                        initState.current = props.values;

                        const country = countryOptions.filter(
                          c => c.id == props.values.countryId,
                        )[0];
                        const city = cityOptions.filter(
                          c => c.id == props.values.cityId,
                        )[0];
                        const town = townOptions.filter(
                          c => c.id == e.target.value,
                        )[0];
                        props.setFieldValue(
                          'address',
                          `${country?.name} ${city?.name} ${town?.name}`,
                        );
                        props.setFieldValue(
                          'address_en',
                          `${town?.name_en} ${city?.name_en},${country?.name_en}`,
                        );
                      }}
                    >
                      <option value="">-</option>
                      {townOptions.map(c => {
                        return (
                          <option key={`town${c.id}`} value={c.id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="townId"
                      component="div"
                      className="form-error"
                    />
                  </Col>
                </Row>
                <FormGroup className="form-group mb-3 mt-3">
                  <label>中文</label>
                  <div className="form-item">
                    <Field name="address" className="form-control" />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label>英文</label>
                  <div className="form-item">
                    <Field name="address_en" className="form-control" />
                  </div>
                </FormGroup>
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*經緯度</label>
              <div className="form-item">
                <Row className="w-100">
                  <Col sm={12} md={6}>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>經度</label>
                      <div className="form-item">
                        <Field
                          name="longitude"
                          className="form-control"
                          type="number"
                        />
                        <ErrorMessage
                          name="longitude"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>緯度</label>
                      <div className="form-item">
                        <Field
                          name="latitude"
                          className="form-control"
                          type="number"
                        />
                        <ErrorMessage
                          name="latitude"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>營業時間(中文)</label>
              <div className="form-item">
                <Field
                  name="businessHours"
                  className="form-control"
                  as="textarea"
                  row="5"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>營業時間(英文)</label>
              <div className="form-item">
                <Field
                  name="businessHours_en"
                  className="form-control"
                  as="textarea"
                  row="5"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>其他說明(中文)</label>
              <div className="form-item">
                <Field
                  name="otherDesc"
                  className="form-control"
                  as="textarea"
                  row="5"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>其他說明(英文)</label>
              <div className="form-item">
                <Field
                  name="otherDesc_en"
                  className="form-control"
                  as="textarea"
                  row="5"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>服務項目</label>
              <div className="form-item">
                <Select
                  isMulti="isMulti"
                  value={props.values.services}
                  options={serviceOptions}
                  onChange={selectOptions => {
                    props.setFieldValue('services', selectOptions);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="img_files"
                  currentFileFieldName="img_files"
                  setFormFieldValue={(field, files) => {
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue('img_files', files[0]);
                      }
                    }

                    if (typeof files === 'string' && files === 'isDelete') {
                      deleteCafeShopImage({
                        id: detail.id,
                      }).then(() => {
                        props.setFieldValue('img_files', 'undefined');
                      });
                    }
                  }}
                  displayFileWidth="700"
                  displayFileSize="5MB"
                  initFiles={props.values.init_img_files}
                />
              </div>
            </FormGroup>
            <hr className="mt-5" />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={goBack}
              >
                <span className="btn-wrapper--label">取消</span>
              </Button>
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!props.isValid}
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '1rem' }}
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(ShopEditor);
