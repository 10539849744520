import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import RealTimeList from '../../components/report/PromoRealTimeList';
import SearchCondition from '../../components/report/PromoSearchCondition';
import { getPromoRealTimeReport } from '../../redux/actions/data/operatingReportActions';

const PromoRealTimeReport = () => {
  const { loading, list } = useSelector(
    state => state.getCouponRealtimeReportList,
  );

  const dispatch = useDispatch();

  const onSearch = useCallback(data => {
    dispatch(getPromoRealTimeReport(data));
  }, []);

  return (
    <Layout
      pageTitle="優惠碼-即時報表"
      items={[
        { isActive: false, page_name: '營運報表' },
        { isActive: true, page_name: '優惠碼-即時報表' },
      ]}
    >
      <SearchCondition onSearch={onSearch} />
      <RealTimeList list={list} loading={loading} onSearch={onSearch} />
    </Layout>
  );
};

export default PromoRealTimeReport;
