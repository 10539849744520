import { useRequest } from 'utils/useFetch';

import {
  UUCAFE_MIDDLE_HOME_SETTINGS_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS,
  UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeMiddleHomeSettingsConstants';

/*
* @Response
"result": [
    {
      "id": 0,
      "bannerImg": "string",
      "bannerUrl": "string",
      "bannerImg_mobile": "string",
      "bannerUrl_mobile": "string",
      "linkUrl": "string",
      "linkTarget": "string",
      "updater": "string",
      "updateDateTime": "string",
      "location": "string",
      "title": "string",
      "title_en": "string"
    }
  ]
*/
export const getCafeMiddleHomeList = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST,
      successConstant: UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/middleHome/list',
    });
  };
};

export const getCafeMiddleHome = data => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_MIDDLE_HOME_SETTINGS_FAIL,
      method: 'get',
      requestConstant: UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST,
      successConstant: UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS,
      url: `/Backend/Cafe_HomeBanner/middleHome/{id}/${data.id}`,
    });
  };
};

export const updateCafeMiddleHome = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST,
      successConstant: UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/middleHome/update',
    });
  };
};
