import style from '../styles/layout.module.css';

import Loader from './Loader';

export const TableLoading = () => {
  return (
    <tbody>
    <tr>
      <td colSpan="100%">
        <Loader />
      </td>
    </tr>
    </tbody>
  );
};

export const TableHasNoData = () => {
  return (
    <tr className={style.table_no_data}>
      <td colSpan="100%">無資料</td>
    </tr>
  );
};

export default TableHasNoData;
