import AddProductModal from 'components/discountCompaign/AddProductModal';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, FormGroup } from 'reactstrap';
import {
  addActivity,
  getActivityReaptedProduct,
} from 'redux/actions/data/discountCompaignActions';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import { ADD_ACTIVITY_IDLE } from 'redux/constants/data/discountCompaignContants';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import BasicSettings from '../components/compaignSettingPage/BasicSettings';
import DiscountConditionSettings from '../components/compaignSettingPage/DiscountConditionSettings';
import OthersSettings from '../components/compaignSettingPage/OthersSettings';
import style from '../styles/layout.module.css';

export const asyncValidation = async value => {
  return new Promise(resolve => {
    setTimeout(() => {
      if (value > 10) {
        resolve(true);
      } else {
        resolve(false);
      }
    }, 500); // 等待 1 秒
  });
};

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
};
const CompaignSettingPage = () => {
  const initData = useMemo(() => {
    const initDate = moment().add(5, 'minute').toDate();
    return {
      activityPic: [],
      activityPicMoblie: [],
      area: [
        {
          area: 1,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 2,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 3,
          areaName: '',
          areaName_en: '',
        },
      ],
      catCode: 0,
      category1: { label: '請選擇', value: 0 },
      category2: { label: '全部', value: 0 },
      condition: null,
      condition_fixedTPrice: 0,
      conditions: [{ freebieCode: '', value1: '', value2: '' }],
      conditionsArea: [
        {
          area: 1,
          value1: '',
          value2: '',
        },
        {
          area: 2,
          value1: '',
          value2: '',
        },
        {
          area: 3,
          value1: '',
          value2: '',
        },
      ],
      couponEnabled: true,
      currentTab: 1,
      desc: '',
      desc_en: '',
      endDate: moment(initDate).add(30, 'minute').toDate(),
      gateway: ['web'],
      globalCat: [],
      hasFreebieMustBuy: false,
      isExtraProductRange: false,
      isShow: true,
      memberLevel: 0,
      menuColor: 'white',
      modelType: '一般商品',
      models: [],
      name: '',
      name_en: '',
      objectType: '',
      originPriceA: false,
      originPriceB: false,
      originPriceC: false,
      range: 'all',
      startDate: initDate,
      storePic: [],
      target: 'all',
      type: '',
    };
  }, []);
  const query = useQuery();
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productType, setProductType] = useState(null);
  const toggleProductModal = () => {
    setIsProductModalOpen(!isProductModalOpen);
  };
  // const [tempProductList, setTempProductList] = useState([]);
  const dispatch = useDispatch();
  const catCode = query.value.cateCode || query.value.catCode;

  const {
    setFieldTouched,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setFieldError,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: values => {
      const body = {
        ...values,
        _object: values.catCode == 11 ? 'all' : values.target,
        availableWay: ['web'],
        condition_fixedTPrice: values.condition_fixedTPrice,
        conditions:
          values.condition === null ? values.conditions : values.conditionsArea,
        conditionsArea: [...values.conditionsArea],
        coverImg: values.storePic,
        desc: values.desc,
        desc_en: values.desc_en,
        endTime: moment(values.endDate).format('YYYY/MM/DD HH:mm:ss'),
        globleCatIds: values.globalCat.map(cat => {
          if (cat.cat2.value != 0) {
            return cat.cat2.value;
          } else {
            return cat.cat1.value;
          }
        }),
        groupProducts: values.models
          .filter(m => m.modelType === '組合商品')
          .reduce((arr, model) => {
            return [...arr, ...model.products];
          }, [])
          .map(product => ({
            activityProductId: product.activityProductId,
            area: product.area,
            discountPrice: Number(product.discountPrice || 0),
            mid: product.mid,
            productid: product.productid,
          })),
        id: 0,
        isDiscountAvailable: values.catCode == 11 ? true : values.couponEnabled,
        isShow: values.catCode == 11 ? false : values.isShow,
        menuColor: values.menuColor,
        objectMemberLevelId: values.memberLevel,
        pageImg_d: values.activityPic,
        pageImg_m: values.activityPicMoblie,
        productRange: values.catCode === 1 ? 'picked' : values.range,
        products: values.models
          .filter(m => m.modelType === '一般商品')
          .reduce((arr, model) => {
            return [...arr, ...model.products];
          }, [])
          .map(product => ({
            activityProductId: product.activityProductId,
            area: product.area,
            discountPrice: Number(product.discountPrice || 0),
            mid: product.mid,
            productid: product.productid,
          })),
        startTime: moment(values.startDate).format('YYYY/MM/DD HH:mm:ss'),
      };

      if (body.hasFreebieMustBuy === false && body.catCode == 11) {
        body.products = [];
      }
      if (body.catCode == 11) {
        delete body.menuColor;
      }
      let breakPoint = false;
      if (body.products.find(product => product.discountPrice == 0)) {
        breakPoint = true;
      }
      if (breakPoint && body.catCode == 1) {
        MessageUtil.alertWanring('商品價格不能為0');
        return;
      }
      const catIds = JSON.stringify(
        body.productRange === 'pickedbyCat' ? body.globleCatIds : [],
      );
      let formData = new FormData();
      formData.append('id', body.id);
      formData.append('catCode', body.catCode);
      formData.append('name', body.name);
      formData.append('name_en', body.name_en);
      formData.append('startTime', body.startTime);
      formData.append('endTime', body.endTime);
      formData.append('_object', body._object);
      body.objectMemberLevelId &&
        formData.append('objectMLevelId', body.objectMemberLevelId);
      formData.append('objectType', body.objectType);
      formData.append('availableWay', '["web"]');
      formData.append('productRange', body.productRange);
      formData.append('isDiscountAvailable', body.isDiscountAvailable);
      formData.set('products', JSON.stringify(body.products));
      formData.set('groupProducts', JSON.stringify(body.groupProducts));
      formData.append('globleCatIds', catIds);
      formData.append('hasFreebieMustBuy', values.hasFreebieMustBuy);

      if (body.coverImg.length > 0) {
        const coverImgJsonString = JSON.stringify(
          body.coverImg.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.coverImg
              ? body.coverImg.indexOf(body.coverImg.find(j => j.id === img.id))
              : -1,
            isRemoveFile: !!body.coverImg?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('coverImg', coverImgJsonString);
        formData.append('coverImgFiles', body.coverImg[0]);
      }

      if (body.pageImg_d.length > 0) {
        const pageImg_dJsonString = JSON.stringify(
          body.pageImg_d.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.pageImg_d
              ? body.pageImg_d.indexOf(
                  body.pageImg_d.find(j => j.id === img.id),
                )
              : -1,
            isRemoveFile: !!body.pageImg_d?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('pageImg_d', pageImg_dJsonString);
        formData.append('pageImg_dFiles', body.pageImg_d[0]);
      }

      if (body.pageImg_m.length > 0) {
        const pageImg_mJsonString = JSON.stringify(
          body.pageImg_m.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.pageImg_m
              ? body.pageImg_m.indexOf(
                  body.pageImg_m.find(j => j.id === img.id),
                )
              : -1,
            isRemoveFile: !!body.pageImg_m?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('pageImg_m', pageImg_mJsonString);
        formData.append('pageImg_mFiles', body.pageImg_m[0]);
      }
      body.desc && formData.append('desc', body.desc);
      body.desc_en && formData.append('desc_en', body.desc_en);
      body.menuColor && formData.append('menuColor', body.menuColor);
      body.isShow && formData.append('isShow', body.isShow);

      // 紅配綠的新增項目
      if (body.catCode === 7) {
        if (!body.isExtraProductRange) {
          body.conditions = body.conditions?.slice(0, 2);
        }

        if (values?.originPriceA) {
          body.conditions[0].value2 = null;
        }
        if (values?.originPriceB) {
          body.conditions[1].value2 = null;
        }
        if (values?.originPriceC && body.isExtraProductRange) {
          body.conditions[2].value2 = null;
        }

        formData.append('areas', JSON.stringify(body?.area || body?.areas));
        formData.append('condition', body.condition);
        formData.append('isExtraProductRange', body.isExtraProductRange);

        if (body.condition === 'all') {
          formData.append('condition_fixedTPrice', body.condition_fixedTPrice);
        }
        formData.append(
          'conditions',
          JSON.stringify(
            body.conditions.map(c => {
              if (c.value2 === null) {
                delete c.value2;
              }
              return c;
            }),
          ),
        );
      } else {
        body.catCode !== 1 &&
          formData.append(
            'conditions',
            JSON.stringify(
              body.conditions.map(c => {
                return c;
              }),
            ),
          );
      }

      if (
        values.catCode === 7 &&
        (values?.conditionsArea
          ?.slice(0, values?.isExtraProductRange ? 3 : 2)
          .some(a => {
            return Number(a?.value1) === 0;
          }) ||
          values?.conditionsArea?.length === 0)
      ) {
        return MessageUtil.alertWanring('商品任選件數不可為0');
      }

      if (
        values.catCode === 7 &&
        values.condition === 'separate' &&
        (!values.originPriceA ||
          !values.originPriceB ||
          !values.originPriceC) &&
        values?.conditionsArea?.some(a => {
          return Number(a?.value1) > 0 && Number(a?.value2) === 0;
        })
      ) {
        let msg = '';
        if (
          values?.conditionsArea[0] &&
          !values.originPriceA &&
          Number(values?.conditionsArea[0].value2) === 0
        ) {
          msg = 'A區每件固定價為NT$0確定儲存?';
        }
        if (
          values?.conditionsArea[1] &&
          !values.originPriceB &&
          Number(values?.conditionsArea[1].value2) === 0
        ) {
          msg = 'B區每件固定價為NT$0確定儲存?';
        }
        if (
          values.isExtraProductRange &&
          values?.conditionsArea[2] &&
          !values.originPriceC &&
          Number(values?.conditionsArea[2].value2) === 0
        ) {
          msg = 'C區每件固定價為NT$0確定儲存?';
        }
        if (msg) {
          MessageUtil.confirm(msg, '', () => {
            dispatch(addActivity(formData));
          });
        } else {
          dispatch(addActivity(formData));
        }
      } else {
        dispatch(addActivity(formData));
      }
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      activityPic: Yup.array().when(['catCode', 'isShow'], {
        is: (catCode, isShow) => catCode !== 1 && catCode !== 11 && isShow,
        then: Yup.array().min(1, '必填').required('必填'),
      }),
      activityPicMoblie: Yup.array().when(['catCode', 'isShow'], {
        is: (catCode, isShow) => catCode !== 1 && catCode !== 11 && isShow,
        then: Yup.array().min(1, '必填').required('必填'),
      }),
      area: Yup.array().when('catCode', {
        is: val => val === 7,
        then: Yup.array().of(
          Yup.object().shape({
            area: Yup.number(),
            areaName: Yup.string()
              .nullable()
              .when('area', {
                is: val => val !== 3,
                then: Yup.string().nullable().required('必填'),
              }),
            areaName_en: Yup.string()
              .nullable()
              .when('area', {
                is: val => val !== 3,
                then: Yup.string().nullable().required('必填'),
              }),
          }),
        ),
      }),
      catCode: Yup.number(),
      condition: Yup.string()
        .nullable()
        .when('catCode', {
          is: val => val === 7,
          then: Yup.string().nullable().required('必填'),
        }),
      condition_fixedTPrice: Yup.number().when('condition', {
        is: val => val === 'all',
        then: Yup.number()
          .min(1, '請輸入大於0的數字')
          .nullable()
          .required('請輸入大於0的數字'),
      }),
      conditions: Yup.array().when('catCode', {
        is: catCode => {
          return catCode !== 1 && catCode !== 7;
        },
        then: Yup.array().of(
          Yup.object().shape({
            freebieCode: Yup.string()
              .nullable()
              .when('catCode', {
                is: () => catCode == 11,
                then: Yup.string().nullable().required('必填'),
              }),
            value1: Yup.number()
              .nullable()
              .min(0.1, '請輸入大於0的數字')
              .required('必填'),
            value2: Yup.number().when('catCode', {
              is: () => catCode != 11,
              then: Yup.number()
                .nullable()
                .min(0.1, '請輸入大於0的數字')
                .required('必填'),
            }),
          }),
        ),
      }),
      conditionsArea: Yup.array().when(['catCode'], {
        is: catCode => catCode === 7,
        then: Yup.array().test('invalid', '必填', async function (val) {
          await asyncValidation(10);
          if (val?.length > 0) {
            if (Number(val[0]?.value1) <= 0) {
              return this.createError({
                message: 'A區商品任選至少大於一件',
                path: `${this.path}[0].value1`, // 设置错误路径
              });
            }
            if (Number(val[1]?.value1) <= 0) {
              return this.createError({
                message: 'B區商品任選至少大於一件',
                path: `${this.path}[1].value1`, // 设置错误路径
              });
            }
            if (Number(val[2]?.value1) <= 0 && values.isExtraProductRange) {
              return this.createError({
                message: 'C區商品任選至少大於一件',
                path: `${this.path}[2].value1`, // 设置错误路径
              });
            }

            if (values.condition === 'separate') {
              if (
                Number(val[0]?.value1) > 0 &&
                Number(val[0]?.value2) <= 0 &&
                !values.originPriceA
              ) {
                return this.createError({
                  message: '金額至少大於0',
                  path: `${this.path}[0].value2`, // 设置错误路径
                });
              }
            }
            return true;
          } else {
            return this.createError({
              message: '請設定折抵條件',
            });
          }
        }),
      }),
      desc: Yup.string()
        .nullable()
        .when('catCode', {
          is: val => val !== 1,
          then: Yup.string().nullable().required('必填'),
        }),
      desc_en: Yup.string()
        .nullable()
        .when('catCode', {
          is: val => val !== 1,
          then: Yup.string().nullable().required('必填'),
        }),
      endDate: Yup.date().typeError('必填').required('必填'),
      globalCat: Yup.array().when('range', {
        is: val => val === 'pickedbyCat',
        then: Yup.array().min(1, '請選擇商品'),
      }),
      hasFreebieMustBuy: Yup.boolean().nullable(),
      isExtraProductRange: Yup.boolean(),
      memberLevel: Yup.number().when(['target'], {
        is: target => target !== 'all',
        then: Yup.number().min(1, '請選擇活動對象').required('請選擇活動對象'),
      }),
      models: Yup.array()
        .when('catCode', {
          is: val => val == 1,
          then: Yup.array().of(
            Yup.object().shape({
              products: Yup.array().of(
                Yup.object().shape({
                  discountPrice: Yup.number()
                    .required('請輸入大於0的數字')
                    .min(1, '請輸入大於0的數字')
                    .max(Yup.ref('sellprice'), '限時折扣價不可大於售價'),
                }),
              ),
            }),
          ),
        })
        .when(['range', 'hasFreebieMustBuy'], {
          is: (range, hasFreebieMustBuy) =>
            range === 'picked' || hasFreebieMustBuy === true,
          then: Yup.array().min(1, '請選擇產品').required('請選擇產品'),
        }),
      name: Yup.string().nullable().required('必填'),
      name_en: Yup.string().nullable().required('必填'),
      objectType: Yup.string().when(['target', 'catCode'], {
        is: (target, catCode) => {
          return (
            target === 'member' &&
            catCode != 1 &&
            catCode != 7 &&
            catCode != 6 &&
            catCode != 8 &&
            catCode != 9 &&
            catCode != 10
          );
        },
        then: Yup.string().required('活動類型'),
      }),
      range: Yup.string().nullable(),
      startDate: Yup.date().typeError('必填').required('必填'),
      storePic: Yup.array().when(['catCode', 'isShow'], {
        is: (catCode, isShow) => catCode !== 1 && catCode !== 11 && isShow,
        then: Yup.array().min(1, '必填').required('必填'),
      }),
      target: Yup.string().nullable().required('必填'),
    }),
  });

  const { success, loading, error } = useSelector(state => state.addActivity);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  useEffect(() => {
    const name = query.value.type;
    const catCode = query.value.catCode;
    setFieldValue('type', name);
    setFieldValue('catCode', catCode);

    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: ADD_ACTIVITY_IDLE });
      history.push('/DiscountCompaign');
    }
    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
      dispatch({ type: ADD_ACTIVITY_IDLE });
    }
  }, [success, error]);

  useEffect(() => {
    dispatch(
      getActivityReaptedProduct({
        _object: values.target,
        catCode: catCode,
        endTime: values.endDate
          ? moment(values.endDate).format('YYYY/MM/DD HH:mm')
          : null,
        id: 0,
        objectMLevelId: values.memberLevel,
        startTime: values.startDate
          ? moment(values.startDate).format('YYYY/MM/DD HH:mm')
          : null,
      }),
    );
  }, [
    values.target,
    values.memberLevel,
    values.startDate,
    values.endDate,
    catCode,
  ]);
  const limit = Number(catCode) >= 5 ? 5 : 10;
  const valueRef = useRef();
  const isCompare = useRef(false);

  useEffect(() => {
    if (!isCompare.current) {
      if (!valueRef.current) {
        valueRef.current = { ...values };
      } else {
        try {
          Object.keys(valueRef.current).forEach(key => {
            if (
              JSON.stringify(valueRef.current[key]) !==
              JSON.stringify(values[key])
            ) {
              setFieldTouched(key, true);
              isCompare.current = true;
            }
          });
        } catch (e) {}
      }
    }
  }, [values]);

  useEffect(() => {
    setFieldValue('condition', catCode === 7 ? 'all' : null);
    setFieldValue(
      'range',
      catCode === 1
        ? 'picked'
        : catCode === 7
        ? 'set'
        : catCode === 6
        ? 'picked'
        : 'all',
    );
    setFieldValue('catCode', catCode);
  }, [catCode]);

  return (
    <Layout
      pageTitle={`新增折扣活動-${values.type}`}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: false, page_name: '折扣活動', to: '/DiscountCompaign' },
        { isActive: false, page_name: `新增折扣活動-${values.type}` },
      ]}
    >
      <Card className={style.card}>
        <h4 className="title" style={titleStyle}>
          活動基本設定
        </h4>
        <BasicSettings
          activityType={values.type}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onToggleModal={toggleProductModal}
        />
        {catCode != 11 && (
          <DiscountConditionSettings
            activityType={values.type}
            values={values}
            setErrors={setFieldError}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            onToggleModal={toggleProductModal}
            onSetProductType={setProductType}
            limit={limit}
          />
        )}
        {catCode != 11 && (
          <OthersSettings
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        )}
        <FormGroup className={style.formGroup}>
          <div>新增時間： {moment().format('YYY-MM-DD HH:mm')} </div>
          <div className="mx-2"></div>
        </FormGroup>
        <hr className="mb-5 mt-0" />
        <div className="d-flex align-items-center justify-content-center">
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={() => {
              history.goBack();
            }}
          >
            取消
          </Button>
          <Button
            color="primary"
            className={style.modal_button_submit}
            disabled={loading || Object.keys(errors).length > 0}
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '1rem' }}
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
      <AddProductModal
        disableHideModel
        isOpen={isProductModalOpen}
        toggle={toggleProductModal}
        type={productType}
        existList={
          catCode == 7
            ? values.models.filter(
                m => String(m.area) === String(values.currentTab),
              )
            : values.models
        }
        disableList={
          catCode == 7
            ? values.models.filter(
                m => String(m.area) !== String(values.currentTab),
              )
            : []
        }
        area={catCode == 7 ? values.currentTab || 1 : 0}
        onSubmit={(models, type) => {
          setFieldValue('modelType', type);
          if (catCode == 7) {
            setFieldValue('models', [
              ...values.models.filter(
                m => String(m.area) !== String(values.currentTab),
              ),
              ...models,
            ]);
          } else {
            setFieldValue('models', [...models]);
          }
        }}
      />
    </Layout>
  );
};

export default CompaignSettingPage;
