import React from 'react';

import style from '../../styles/layout.module.css';

const OtherSettings = ({
  values,
  setFieldValue,
  handleChange,
  errors,
  touched,
}) => {
  return (
    <React.Fragment>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w150">
          是否可使用折價券
          {errors.isCouponAvailable && touched.isCouponAvailable && (
            <div className="text-danger">{errors.isCouponAvailable}</div>
          )}
        </label>
        <div>
          <small>
            <li>
              若活動商品已設定為「不適用任何折價券商品」則此商品亦無法使用折價券
            </li>
          </small>
          <div
            className={style['radio-btn-layout']}
            style={{ marginTop: '0.5rem' }}
          >
            <input
              className={style['radio-btn']}
              type="radio"
              checked={values.isCouponAvailable === true}
              onChange={() => {
                setFieldValue('isCouponAvailable', true);
              }}
            />
            <span className="ml-2">是 (此檔活動結帳可以使用折價券)</span>
          </div>
          <div
            className={style['radio-btn-layout']}
            style={{ marginTop: '0.5rem' }}
          >
            <input
              className={style['radio-btn']}
              type="radio"
              checked={values.isCouponAvailable === false}
              onChange={() => {
                setFieldValue('isCouponAvailable', false);
              }}
            />
            <span className="ml-2">否 (不可使用折價券)</span>
          </div>
        </div>
      </div>
      <div style={{ margin: '1.5rem' }} />
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w150">
          是否與折扣活動並用
          {errors.withDiscountActivity && touched.withDiscountActivity && (
            <div className="text-danger">{errors.withDiscountActivity}</div>
          )}
        </label>
        <div>
          <div className={style['radio-btn-layout']}>
            <input
              className={style['radio-btn']}
              type="radio"
              checked={values.withDiscountActivity === true}
              onChange={() => {
                setFieldValue('withDiscountActivity', true);
              }}
            />
            <span className="ml-2">
              是 (可以跟折扣活動【滿額打折、滿額折現】做累計折抵)
            </span>
          </div>
          <div
            className={style['radio-btn-layout']}
            style={{ marginTop: '0.5rem' }}
          >
            <input
              className={style['radio-btn']}
              type="radio"
              checked={values.withDiscountActivity === false}
              onChange={() => {
                setFieldValue('withDiscountActivity', false);
              }}
            />
            <span className="ml-2">否</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OtherSettings;
