import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  getWarrantyinfo,
  getWarrantyMasterList,
  updateWarrantyInfo,
} from '../../redux/actions/data/warrantyActions';
import { WARRANTY_INFO_UPDATE_RESET } from '../../redux/constants/data/warrantyConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import useQuery from '../../utils/useQuery';

export const BindingSettingsModal = ({ isOpen, toggle, shipperDtId }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { error, warrantyInfo } = useSelector(state => state.warrantyInfo);
  const { shippingDealerList, loading: shippingDealerListLoading } =
    useSelector(state => state.shippingDealerList);
  const {
    success: updateSuccess,
    error: updateError,
    loading,
  } = useSelector(state => state.warrantyInfoUpdate);
  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      barcode: warrantyInfo?.barcode,
      codeType: warrantyInfo?.codeType,
      id: warrantyInfo?.id,
      reason: '',
      serialnumber: warrantyInfo?.serialnumber,
      status: '正常',
      suppliercode: warrantyInfo?.suppliercode || '',
      type: '重新綁定',
      warrantySerialNum: warrantyInfo?.warrantySerialNum,
    },
    onSubmit: () => {
      const params = {
        ...values,
        status: values.type === '重新綁定' ? '正常' : '取消',
      };
      dispatch(updateWarrantyInfo(params));
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().min(0).required('必填'),
      serialnumber: Yup.string().when('type', {
        is: val => val === '重新綁定',
        then: Yup.string().min(0).required('必填'),
      }),
      suppliercode: Yup.string().when('type', {
        is: val => val === '重新綁定',
        then: Yup.string().min(0).required('必填'),
      }),
      type: Yup.string().required('必填'),
      warrantySerialNum: Yup.string().when('type', {
        is: val => val === '重新綁定',
        then: Yup.string().min(0).required('必填'),
      }),
    }),
  });

  useEffect(() => {
    dispatch(getWarrantyinfo(shipperDtId));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功');
      dispatch({ type: WARRANTY_INFO_UPDATE_RESET });
      toggle();
      const queryPage = query.value.page;
      const queryKeyword = query.value.keyword;
      const queryDealer = query.value.dealer;
      const queryStatus = query.value.status;
      const queryRangeType = query.value.rangeType;
      const queryWarrantyStart = query.value.warrantyStartDate;
      const queryWarrantyEnd = query.value.warrantyEndDate;
      const queryShipperStart = query.value.shipperStartDate;
      const queryShipperEnd = query.value.shipperEndDate;

      if (
        queryKeyword ||
        queryDealer ||
        queryStatus ||
        queryRangeType ||
        queryWarrantyStart ||
        queryWarrantyEnd ||
        queryShipperStart ||
        queryShipperEnd
      ) {
        //儲存搜尋及頁數
        dispatch(
          getWarrantyMasterList({
            keyword: queryKeyword ? JSON.parse(queryKeyword) : '',
            page: queryPage,
            page_size: 10,
            serailEndDate:
              queryWarrantyEnd !== 'null' ? JSON.parse(queryWarrantyEnd) : null,
            serailPeriod: queryRangeType,
            serailStartDate:
              queryWarrantyStart !== 'null'
                ? JSON.parse(queryWarrantyStart)
                : null,
            shipperEndDate:
              queryShipperEnd !== 'null' ? JSON.parse(queryShipperEnd) : null,
            shipperStartDate:
              queryShipperStart !== 'null'
                ? JSON.parse(queryShipperStart)
                : null,
            status: queryStatus,
            suppliercode: queryDealer ? JSON.parse(queryDealer) : '',
          }),
        );
      }
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: WARRANTY_INFO_UPDATE_RESET });
    }
  }, [updateSuccess, updateError]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        綁定設定{' '}
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup}>
          <label className="required w150">設定 </label>
          <div className="form-item">
            <div className="d-flex">
              <label className="form-label me-4" htmlFor="redo">
                <input
                  className="me-1"
                  type="radio"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                  }}
                  value="重新綁定"
                  checked={values.type === '重新綁定'}
                />
                重新綁定
              </label>
              <label className="form-label" htmlFor="cancel">
                <input
                  className="me-1"
                  type="radio"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                  }}
                  value="取消綁定"
                  checked={values.type === '取消綁定'}
                />
                取消綁定
              </label>
            </div>
          </div>
        </div>
        {values.type === '重新綁定' && (
          <div className={style.formGroup} style={{ width: '100%' }}>
            <label className="required w150">
              出貨經銷商{' '}
              {errors.suppliercode && touched.suppliercode && (
                <span className="text-danger">{errors.suppliercode}</span>
              )}
            </label>
            <Select
              isLoading={shippingDealerListLoading}
              isDisabled={shippingDealerListLoading}
              name="supplier"
              options={shippingDealerList}
              className={style['large-select']}
              value={shippingDealerList.find(
                dealer => dealer.value === values.suppliercode,
              )}
              onChange={({ value }) => {
                setFieldValue('suppliercode', value);
              }}
            />
          </div>
        )}
        <div className={style.formGroup}>
          <label className="required w150">
            保固序號{' '}
            {errors.warrantySerialNum && touched.warrantySerialNum && (
              <span className="text-danger">{errors.warrantySerialNum}</span>
            )}
          </label>
          <Input
            disabled={values.type === '取消綁定'}
            value={
              values.type !== '取消綁定'
                ? values.warrantySerialNum
                : warrantyInfo.warrantySerialNum
            }
            onChange={event => {
              setFieldValue('warrantySerialNum', event.target.value);
            }}
          />
        </div>
        <div className={style.formGroup}>
          <label className="required w150">
            產品序號{' '}
            {errors.serialnumber && touched.serialnumber && (
              <span className="text-danger">{errors.serialnumber}</span>
            )}
          </label>
          <Input
            disabled={values.type === '取消綁定'}
            value={
              values.type !== '取消綁定'
                ? values.serialnumber
                : warrantyInfo.serialnumber
            }
            onChange={event => {
              setFieldValue('serialnumber', event.target.value);
            }}
          />
        </div>
        {values.barcode && (
          <div className={style.formGroup}>
            <label className="w150">國際條碼 </label>
            <Input disabled={true} value={values.barcode} />
          </div>
        )}
        <div className={style.formGroup}>
          <label className="required w150">
            原因{' '}
            {errors.reason && touched.reason && (
              <span className="text-danger">{errors.reason}</span>
            )}
          </label>
          <Input
            type="textarea"
            style={{ height: '150px' }}
            value={values.reason}
            onChange={event => {
              setFieldValue('reason', event.target.value);
            }}
          />
        </div>
        <div className={style.formGroup}>
          <label className="w150">原因紀錄</label>
          <div className="form-item">
            {warrantyInfo?.reasons.map((reason, index) => (
              <div className="mb-2" key={index}>
                <small className="opacity-50">
                  {reason.createDate} {reason.createTime} {reason.creator}
                </small>
                {reason.type === '取消綁定' ? (
                  <div>【取消綁定】{reason.reason}</div>
                ) : (
                  <div>【重新綁定】{reason.reason}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className={`${style.modal_button_cancel} ms-auto`}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
