import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  NEWS_DELETE_FAIL,
  NEWS_DELETE_REQUEST,
  NEWS_DELETE_SUCCESS,
  NEWS_DETAILS_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
} from '../../constants/data/newsConstants';

//get news list
export const getNewsList = (keyword, status, pageNumber) => {
  const url = `/Backend/News/list?keyword=${keyword}&status=${status}&page=${pageNumber}&page_size=10`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: NEWS_LIST_FAIL,
      method: 'get',
      requestConstant: NEWS_LIST_REQUEST,
      successConstant: NEWS_LIST_SUCCESS,
      url,
    });
  };
};

//post news
export const postNews = async (formData = new FormData()) => {
  return await request({
    data: formData,
    method: 'post',
    url: '/Backend/News/news',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

//delete news
export const deleteNews = id => {
  const url = `/Backend/News/news?newsId=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: NEWS_DELETE_FAIL,
      method: 'delete',
      requestConstant: NEWS_DELETE_REQUEST,
      successConstant: NEWS_DELETE_SUCCESS,
      url,
    });
  };
};

//get news details
export const getNewsDetails = id => {
  CommonUtil.consoleLog({ anno: 'API', data: `/Backend/News/news?id=${id}` });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: NEWS_DETAILS_FAIL,
      method: 'get',
      requestConstant: NEWS_DETAILS_REQUEST,
      successConstant: NEWS_DETAILS_SUCCESS,
      url: `/Backend/News/news?id=${id}`,
    });
  };
};
