import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
  addNewModelReducer,
  editModelAvailableTimeReducer,
  editModelShippingDateReducer,
  globalCatAssignModelReducer,
  globalCatRemoveModelReducer,
  modelBrandOptionsReducer,
  modelDeleteSpecReducer,
  modelEditDescReducer,
  modelEditOrderReducer,
  modelEditSettingReducer,
  modelEditSpecReducer,
  modelExportReducer,
  modelGlobalCatListReducer,
  modelImportReducer,
  modelListReducer,
  modelProductOptionsReducer,
  modelProfileReducer,
  modelSellingStateReducer,
} from 'redux/reducers/data/modelReduers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import {
  FAQAddReducer,
  FAQCategoryAddReducer,
  FAQCategoryDeleteReducer,
  FAQCategoryDetailsReducer,
  FAQCategoryReducer,
  FAQDeleteReducer,
  FAQListReducer,
  FAQOptionsReducer,
} from './reducers/data/FAQReducers';
import {
  adminAddReducer,
  adminDeleteReducer,
  adminDetailsReducer,
  adminListReducer,
  adminSendVerifyReducer,
  adminUpdateReducer,
  permissionOptionsReducer,
} from './reducers/data/adminReducers';
import { getBankListReducer } from './reducers/data/bankCodeReducer';
import {
  downloadTemplateFileReducer,
  getBlockListReducer,
  saveBlockListReducer,
  uploadBlockListReducer,
} from './reducers/data/blockListReducers';
import {
  brandCategoryAddProductReducer,
  brandCategoryAddReducer,
  brandCategoryAssignModelReducer,
  brandCategoryModelListReducer,
  brandCategoryOptionsReducer,
  brandCategoryProductListReducer,
  brandCategoryProductReducer,
  brandCategoryProductRemoveReducer,
  brandCategoryReducer,
  brandCategoryRemoveModelReducer,
  brandCategoryRemoveReducer,
  brandCategorySortReducer,
  brandCategoryUpdateReducer,
  brandDetailsReducer,
  brandListReducer,
  brandOptionsReducer,
  brandSortReducer,
  brandUpdateReducer,
  getProductBrandBannerSettingReducer,
} from './reducers/data/brandReducers';
import {
  cartReducer,
  purchaseNoteReducer,
  updatePurchaseNoteReducer,
} from './reducers/data/cartReducers';
import {
  categoryAddReducer,
  categoryDeleteReducer,
  categoryListReducer,
  categorySortReducer,
  categoryUpdateReducer,
  createProductCategoryReducer,
} from './reducers/data/categoryReducers';
import {
  channelDetailsReducer,
  channelListReducer,
  channelPostReducer,
  channelSetReducer,
} from './reducers/data/channelReducers';
import {
  createLogisticsCancelLogisticReducer,
  createLogisticsCancelOrderReducer,
  createLogisticsPrintTicketReducer,
  createLogisticsShippingReducer,
} from './reducers/data/createLogisticsReducers';
import {
  deliveryListReducer,
  deliveryListSaveReducer,
} from './reducers/data/deliveryReducers';
import {
  addActivityReducer,
  deleteActivityReducer,
  getActivityCateOptionsReducer,
  getActivityListReducer,
  getActivityProductListReducer,
  getActivityReaptedProductReducer,
  getOneActivityReducer,
  sortActivityReducer,
  updateActivityReducer,
} from './reducers/data/discountCompaignReducers';
import {
  distributorMileStoneListReducer,
  distributorReducer,
} from './reducers/data/distributorReducers';
import {
  addBindingProductReducer,
  createGiftReducer,
  getBindingProductListReducer,
  getGiftReducer,
  getGiftsListReducer,
  updateGiftReducer,
} from './reducers/data/giftsReducer';
import {
  copyGroupModelReducer,
  deleteGroupModelReducer,
  deleteGroupModelSpecReducer,
  groupModelDetailReducer,
  groupModelListReducer,
  setGroupModelDescReducer,
  setGroupModelOrderReducer,
  setGroupModelSettingReducer,
  setGroupModelSpecReducer,
} from './reducers/data/groupModelReducers';
import {
  hideModelDetailReducer,
  hideModelListReducer,
} from './reducers/data/hideModelReducers';
import {
  getAreaListReducer,
  getCityListReducer,
  getCountryListReducer,
} from './reducers/data/locationReducers';
import {
  exportLogRecordReducer,
  logRecordReducer,
} from './reducers/data/logRecordReducers';
import {
  lowestNotifyInfoReducer,
  updateLowestNotifyReducer,
} from './reducers/data/lowestNotifyReducers';
import {
  getMailContentReducer,
  getMailRecordsListReducer,
  getMailTypeOptionsReducer,
} from './reducers/data/mailRecordsReducers';
import {
  addMemberLevelSettingsReducer,
  copyMemberLevelListReducer,
  deleteMemberLevelDraftReducer,
  deleteOneDraftReducer,
  exportMemberInfoReducer,
  getMemberLevelOptionsReducer,
  getMemberLevelSettingsReducer,
  getMembershipGiftReducer,
  getPublishDateReducer,
  importMemberInfoReducer,
  memberInfoReducer,
  memberInfoUpdateReducer,
  memberListReducer,
  updateMemberLevelDraftReducer,
  updateMemberLevelRecuder,
  updateMemberlevleSettingsReducer,
  updatePublishDateReducer,
} from './reducers/data/memberReducers';
import {
  createMessageCategoryReducer,
  createMessageReplyReducer,
  deleteMessageCategoryReducer,
  getAutoReplyMessageReducer,
  getMessageCategoryListReducer,
  getMessageCategoryOptionsReducer,
  getMessageListReducer,
  getMessageSubCategoryOptionsReducer,
  getOverallMessageReducer,
  getSingleMessageReducer,
  setAutoReplyMessageReducer,
  setMessageClosedReducer,
  unReadMessageCountReducer,
  updateMessageCategoryReducer,
  updateMessageCategorySortReducer,
} from './reducers/data/messageReducers';
import {
  newsDeleteReducer,
  newsDetailsReducer,
  newsListReducer,
} from './reducers/data/newsReducers';
import {
  getCouponPerformanceReportListReducer,
  getCouponRealtimeReportListReducer,
  getFinanceReportDashboardListReducer,
  getFinanceReportReconciliationListReducer,
  getOrderCancelReportReducer,
  getOrderInstantReportReducer,
  getOrderSourceListReducer,
  getProductSalesDataRankingReducer,
  getRewardsPointsStatisticalListReducer,
  getStoreSalesDataListReducer,
} from './reducers/data/operatingReportReducers';
import {
  orderInvoiceDetailReducer,
  orderInvoiceListReducer,
} from './reducers/data/orderInvoiceReducers';
import {
  editOrderRemarkReducer,
  exportAllOrdersInfoReducer,
  getOrderActivitiesReducer,
  getOrderActivityFreebieListReducer,
  getOrderFreebieListReducer,
  orderDetailReducer,
  orderListReducer,
  orderOptionReducer,
  orderOptionsReducer,
} from './reducers/data/orderReducers';
import {
  getOrderitemReturnInfoReducer,
  orderReturnReasonListReducer,
  orderReturnReasonSelectionsReducer,
} from './reducers/data/orderReturnReasonReducers';
import {
  deliveryRefundListReducer,
  editATMAccReducer,
  editReturnFailATMAccReducer,
  orderReturnFinishedReducer,
  rechargeListReducer,
} from './reducers/data/orderReturnReducers';
import {
  chkHomeDeliveryReducer,
  chkStoreReducer,
  homeDeliveryCancelReducer,
  homeDeliveryShippingReducer,
  shippingPrintInfoReducer,
  storeArrivedReducer,
  storeCancelReducer,
  storeLogisticReducer,
  storePickedUpReducer,
  storeShippingReducer,
  uuoptionsReducer,
} from './reducers/data/orderShippingReducers';
import { overallReducer } from './reducers/data/overallReducers';
import {
  paymentListReducer,
  paymentListSaveReducer,
} from './reducers/data/paymentReducers';
import {
  pointEventSettingReducer,
  specialPointEventSettingDetailReducer,
  specialPointEventSettingReducer,
} from './reducers/data/pointSettingReducers';
import {
  addGiftsReducer,
  getBindingGiftsListReducer,
  getColorOptionsReducer,
  getProductReducer,
  globalCategoryAddProductReducer,
  newProductListReducer,
  productBrandCategoryReducer,
  productCategoryProductListReducer,
  productDeleteReducer,
  productDetailsReducer,
  productEditReducer,
  productGlobalCategoryReducer,
  productLinkDetailReducer,
  productLinkListReducer,
  productLinkPostReducer,
  productListReducer,
  productOptionsReducer,
  productOptionsUpdateReducer,
  productProfileReducer,
  productSpecDeleteReducer,
  productSpecListReducer,
  productSpecPostReducer,
  productStockFileImportReducer,
  productmodelOptionsReducer,
  setProductStockStatusReducer,
} from './reducers/data/productReduers';
import {
  promoDetailReducer,
  promoListReducer,
} from './reducers/data/promoReducers';
import {
  exportMaintainListWordReducer,
  getMemberInfoViaPhoneNumberReducer,
  getProductInfoViaWarrantySerialNumberReducer,
  getRelatedRegisterListReducer,
  maintainAddReducer,
  maintainBatchUpdateReducer,
  maintainDealingUpdateReducer,
  maintainDetailUpdateReducer,
  maintainDetailsReducer,
  maintainExportReducer,
  maintainGetInfoReducer,
  maintainHandlerOptionsReducer,
  maintainImporteReducer,
  maintainListReducer,
  maintainOptionsReducer,
  maintainStatusOptionsReducer,
  repairBrandOptionsReducer,
  repairColorOptionsReducer,
  repairModelOptionsReducer,
  repairSettingInfoReducer,
  repairSettingPostReducer,
  updateCreditsReducer,
  updateEmployeeIdReducer,
  updateRepairRegisterReducer,
  updateSendBackDateReducer,
  updateWarrantyProductSerialNumberReducer,
} from './reducers/data/repairReducers';
import {
  getRewardsPointsSettingsReducer,
  updateRedeemRewardsPointsReducer,
  updateRewardsPointsEnableSettingsReducer,
  updateRewardsPointsRulesReducer,
} from './reducers/data/rewardsPointsReducers';
import {
  alsoLikeAddProductReducer,
  alsoLikeBrandListReducer,
  alsoLikeBrandProductListReducer,
  alsoLikeProductDeleteReducer,
  alsoLikeProductListReducer,
  alsoLikeSetTopReducer,
  bestSellerAddProductReducer,
  bestSellerBrandListReducer,
  bestSellerBrandProductListReducer,
  bestSellerProductDeleteReducer,
  bestSellerProductListReducer,
  bestSellerSetTopReducer,
  discountCodeDeleteReducer,
  discountCodeListReducer,
  discountCodePostReducer,
  discountImageDeleteReducer,
  discountImageListReducer,
  discountImagePostReducer,
} from './reducers/data/salesReducers';
import {
  seoSettingDetailReducder,
  seoSettingsDetailUpdateReducer,
  seoSettingsListReducer,
  seoSettingsUpdateReducer,
} from './reducers/data/seoSettingsReducer';
import {
  instructionDeleteReducer,
  instructionFAQDeleteReducer,
  instructionFAQListReducer,
  instructionFAQPostReducer,
  instructionListReducer,
  instructionPostReducer,
  repairDeleteReducer,
  repairListReducer,
  repairPostReducer,
  serviceContentDeleteReducer,
  serviceContentPostReducer,
  serviceListReducer,
  serviceTitlePostReducer,
  updateServiceRemarkReducer,
  warrantyDeleteReducer,
  warrantyListReducer,
  warrantyPostReducer,
} from './reducers/data/serviceReducers';
import {
  aboutEditReducer,
  aboutReducer,
  settingEditReducer,
  settingReducer,
} from './reducers/data/settingReducers';
import {
  createNewShopBannerReducer,
  createShopBannerScheduleReducer,
  deleteShopBannerReducer,
  getDiscountCompaignOptionsReducer,
  getOneShopBannerReducer,
  getShopBannerColorReducer,
  getShopBannerListReducer,
  sortingShopBannerReducer,
  updateShopBannerColorReducer,
  updateShopBannerReducer,
} from './reducers/data/shopBannerReducers';
import {
  serviceTypeDeleteReducer,
  serviceTypeListReducer,
  serviceTypePostReducer,
  storeDetailReducer,
  storeDetailUpdateReducer,
  storeListReducer,
  storeServiceOptionReducer,
} from './reducers/data/storeReducers';
import {
  jobDeleteReducer,
  jobDetailsReducer,
  jobListReducer,
  jobPostReducer,
  updateDataReducer,
  updateTimeReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userResetPasswordReducer,
  userVerifyReducer,
} from './reducers/data/userReducers';
import {
  cafeBannerSettingReducder,
  cafeBannerSettingsListReducer,
} from './reducers/data/uuCafeBannerSettingsReducer';
import { cafeBottomHomeSettingReducer } from './reducers/data/uuCafeBottomHomeSettingReducer';
import {
  cafeJourneyDetailReducer,
  cafeJourneyListReducer,
  cafeJourneyPageReducer,
} from './reducers/data/uuCafeJourneyReducer';
import {
  cafeMealInfoMenuListReducer,
  cafeMealInfoMenuReducer,
  cafeMealInfoReducer,
  cafeMealInfoSEOReducer,
  cafeMealInfoZoneReducer,
  cafeMealInfoZoneSEOReducer,
} from './reducers/data/uuCafeMealIntroReducer';
import {
  cafeMealOtherProductListReducer,
  cafeMealOtherProductPageReducer,
  cafeMealOtherProductReducer,
  cafeMealOtherProductSEOReducer,
} from './reducers/data/uuCafeMealOtherProdocutReducer';
import {
  cafeMealInfoOtherListReducer,
  cafeMealInfoOtherReducer,
} from './reducers/data/uuCafeMealOtherReducer';
import {
  cafeMealProductListReducer,
  cafeMealProductReducer,
} from './reducers/data/uuCafeMealProdocutReducer';
import { cafeMenuSettingsReducer } from './reducers/data/uuCafeMenuSettingsReducer';
import {
  cafeMiddleHomeSettingReducer,
  cafeMiddleHomeSettingsListReducer,
} from './reducers/data/uuCafeMiddleHomeSettingsReducer';
import {
  cafeNewSListReducer,
  cafeNewsDetailReducer,
} from './reducers/data/uuCafeNewsReducer';
import {
  cafeRecruitmentListReducer,
  cafeRecruitmentReducer,
} from './reducers/data/uuCafeRecruitmentReducer';
import {
  cafeSEOSettingDetailReducder,
  cafeSEOSettingsListReducer,
} from './reducers/data/uuCafeSEOSettingsReducer';
import {
  cafeServiceDetailReducer,
  cafeServiceListReducer,
} from './reducers/data/uuCafeServicesReducer';
import {
  cafeShopDetailReducer,
  cafeShopListReducer,
} from './reducers/data/uuCafeShopReducer';
import {
  cafeWorldDetailReducer,
  cafeWorldListReducer,
} from './reducers/data/uuCafeWorldReducer';
import {
  deleteUUModelReducer,
  editUUModelReducer,
  saveModelsReducer,
  uuModelRelListReducer,
  uuModelRelSelectModelsReducer,
} from './reducers/data/uuModelRelReducers';
import {
  getVoucherDetailReducer,
  getVoucherListReducer,
  setVoucherSettingsDataReducer,
} from './reducers/data/voucherReducers';
import {
  canUnbindShipperDtListReducer,
  getFormDescriptionReducer,
  getSizeOptionsReducer,
  productExcelCreateReducer,
  productNumberCreateReducer,
  productNumberListReducer,
  productSerialNumberReducer,
  shippingDealerListReducer,
  shippingExcelCreateReducer,
  warrantyAddRemarkReducer,
  warrantyBrandExpireDateReducer,
  warrantyBrandListReducer,
  warrantyBrandSetReducer,
  warrantyCodeListReducer,
  warrantyCodePostReducer,
  warrantyExcelCreateReducer,
  warrantyInfoReducer,
  warrantyInfoUpdateReducer,
  warrantyMasterListReducer,
  warrantyNumberCreateReducer,
  warrantyNumberListReducer,
  warrantyNumberReducer,
  warrantyPrefixListReducer,
  warrantyProductCheckReducer,
  warrantyRecordReducer,
  warrantyRegisterDetailsReducer,
  warrantyRegisterExportReducer,
  warrantyRegisterListReducer,
  warrantyRegisterNotifyListReducer,
  warrantyRegisterNotifyReducer,
  warrantyRegisterReviewReducer,
  warrantySerialNumbersReducer,
} from './reducers/data/warrantyReducers';
import {
  getCaptchaCodeReducer,
  getContactUsContextReducer,
  updateCaptchaCodeReducer,
  updateContactUsContextReducer,
} from './reducers/data/websiteSettingsReducer';
import {
  getSettingsReducer,
  setSettingsReducer,
} from './reducers/data/yourFavoriteReducers';
import { sideBarReducer } from './reducers/style/sideBarReducers';

const allReducer = combineReducers({
  FAQAdd: FAQAddReducer,
  FAQCategory: FAQCategoryReducer,
  FAQCategoryAdd: FAQCategoryAddReducer,
  FAQCategoryDelete: FAQCategoryDeleteReducer,
  FAQCategoryDetails: FAQCategoryDetailsReducer,
  FAQDelete: FAQDeleteReducer,
  FAQList: FAQListReducer,
  FAQOptions: FAQOptionsReducer,
  about: aboutReducer,
  aboutEdit: aboutEditReducer,
  addActivity: addActivityReducer,
  addBindingProduct: addBindingProductReducer,
  addGifts: addGiftsReducer,
  addMemberLevelSettings: addMemberLevelSettingsReducer,
  addNewModel: addNewModelReducer,
  adminAdd: adminAddReducer,
  adminDelete: adminDeleteReducer,
  adminDetails: adminDetailsReducer,
  adminList: adminListReducer,
  adminSendVerify: adminSendVerifyReducer,
  adminUpdate: adminUpdateReducer,
  alsoLikeAddProduct: alsoLikeAddProductReducer,
  alsoLikeBrandList: alsoLikeBrandListReducer,
  alsoLikeBrandProductList: alsoLikeBrandProductListReducer,
  alsoLikeProductDelete: alsoLikeProductDeleteReducer,
  alsoLikeProductList: alsoLikeProductListReducer,
  alsoLikeSetTop: alsoLikeSetTopReducer,
  bestSellerAddProduct: bestSellerAddProductReducer,
  bestSellerBrandList: bestSellerBrandListReducer,
  bestSellerBrandProductList: bestSellerBrandProductListReducer,
  bestSellerProductDelete: bestSellerProductDeleteReducer,
  bestSellerProductList: bestSellerProductListReducer,
  bestSellerSetTop: bestSellerSetTopReducer,
  brandCategory: brandCategoryReducer,
  brandCategoryAdd: brandCategoryAddReducer,
  brandCategoryAddProduct: brandCategoryAddProductReducer,
  brandCategoryAssignModel: brandCategoryAssignModelReducer,
  brandCategoryModelList: brandCategoryModelListReducer,
  brandCategoryModelRemove: brandCategoryRemoveModelReducer,
  brandCategoryOptions: brandCategoryOptionsReducer,
  brandCategoryProduct: brandCategoryProductReducer,
  brandCategoryProductList: brandCategoryProductListReducer,
  brandCategoryProductRemove: brandCategoryProductRemoveReducer,
  brandCategoryRemove: brandCategoryRemoveReducer,
  brandCategorySort: brandCategorySortReducer,
  brandCategoryUpdate: brandCategoryUpdateReducer,
  brandDetails: brandDetailsReducer,
  brandList: brandListReducer,
  brandOptions: brandOptionsReducer,
  brandSort: brandSortReducer,
  brandUpdate: brandUpdateReducer,
  cafeBannerSetting: cafeBannerSettingReducder,
  cafeBannerSettingsList: cafeBannerSettingsListReducer,
  cafeBottomHomeSetting: cafeBottomHomeSettingReducer,
  cafeJourneyDetail: cafeJourneyDetailReducer,
  cafeJourneyList: cafeJourneyListReducer,
  cafeJourneyPage: cafeJourneyPageReducer,
  cafeMealInfo: cafeMealInfoReducer,
  cafeMealInfoMenu: cafeMealInfoMenuReducer,
  cafeMealInfoMenuList: cafeMealInfoMenuListReducer,
  cafeMealInfoOther: cafeMealInfoOtherReducer,
  cafeMealInfoOtherList: cafeMealInfoOtherListReducer,
  cafeMealInfoSEO: cafeMealInfoSEOReducer,
  cafeMealInfoZone: cafeMealInfoZoneReducer,
  cafeMealInfoZoneSEO: cafeMealInfoZoneSEOReducer,
  cafeMealOtherProduct: cafeMealOtherProductReducer,
  cafeMealOtherProductList: cafeMealOtherProductListReducer,
  cafeMealOtherProductPage: cafeMealOtherProductPageReducer,
  cafeMealOtherProductSEO: cafeMealOtherProductSEOReducer,
  cafeMealProduct: cafeMealProductReducer,
  cafeMealProductList: cafeMealProductListReducer,
  cafeMenuSettings: cafeMenuSettingsReducer,
  cafeMiddleHomeSetting: cafeMiddleHomeSettingReducer,
  cafeMiddleHomeSettingsList: cafeMiddleHomeSettingsListReducer,
  cafeNewSDetail: cafeNewsDetailReducer,
  cafeNewSList: cafeNewSListReducer,
  cafeRecruitment: cafeRecruitmentReducer,
  cafeRecruitmentList: cafeRecruitmentListReducer,
  cafeSEOSettingDetail: cafeSEOSettingDetailReducder,
  cafeSEOSettingsList: cafeSEOSettingsListReducer,
  cafeServiceDetail: cafeServiceDetailReducer,
  cafeServiceList: cafeServiceListReducer,
  cafeShopDetail: cafeShopDetailReducer,
  cafeShopList: cafeShopListReducer,
  cafeWorldDetail: cafeWorldDetailReducer,
  cafeWorldList: cafeWorldListReducer,
  canUnbindShipperDtList: canUnbindShipperDtListReducer,
  cart: cartReducer,
  categoryAdd: categoryAddReducer,
  categoryDelete: categoryDeleteReducer,
  categoryList: categoryListReducer,
  categorySort: categorySortReducer,
  categoryUpdate: categoryUpdateReducer,
  channelDetails: channelDetailsReducer,
  channelList: channelListReducer,
  channelPost: channelPostReducer,
  channelSet: channelSetReducer,
  chkHomeDelivery: chkHomeDeliveryReducer,
  chkStore: chkStoreReducer,
  copyGroupModel: copyGroupModelReducer,
  copyMemberLevelList: copyMemberLevelListReducer,
  createGift: createGiftReducer,
  createLogisticsCancelLogistic: createLogisticsCancelLogisticReducer,
  createLogisticsCancelOrder: createLogisticsCancelOrderReducer,
  createLogisticsPrintTicket: createLogisticsPrintTicketReducer,
  createLogisticsShipping: createLogisticsShippingReducer,
  createMessageCategory: createMessageCategoryReducer,
  createMessageReply: createMessageReplyReducer,
  createNewShopBanner: createNewShopBannerReducer,
  createProductCategory: createProductCategoryReducer,
  createShopBannerSchedule: createShopBannerScheduleReducer,
  deleteActivity: deleteActivityReducer,
  deleteGroupModel: deleteGroupModelReducer,
  deleteGroupModelSpec: deleteGroupModelSpecReducer,
  deleteMemberListDraft: deleteMemberLevelDraftReducer,
  deleteMessageCategory: deleteMessageCategoryReducer,
  deleteOneDraft: deleteOneDraftReducer,
  deleteShopBanner: deleteShopBannerReducer,
  deleteUUModel: deleteUUModelReducer,
  deliveryList: deliveryListReducer,
  deliveryListSave: deliveryListSaveReducer,
  deliveryRefundList: deliveryRefundListReducer,
  discountCodeDelete: discountCodeDeleteReducer,
  discountCodeList: discountCodeListReducer,
  discountCodePost: discountCodePostReducer,
  discountImageDelete: discountImageDeleteReducer,
  discountImageList: discountImageListReducer,
  discountImagePost: discountImagePostReducer,
  distributor: distributorReducer,
  distributorMileStoneList: distributorMileStoneListReducer,
  downloadTemplateFile: downloadTemplateFileReducer,
  editATMAccount: editATMAccReducer,
  editModelAvailableTime: editModelAvailableTimeReducer,
  editModelShippingDate: editModelShippingDateReducer,
  editOrderRemark: editOrderRemarkReducer,
  editReturnFailATMAccount: editReturnFailATMAccReducer,
  editUUModel: editUUModelReducer,
  exportAllOrdersInfo: exportAllOrdersInfoReducer,
  exportLogRecord: exportLogRecordReducer,
  exportMaintainListWord: exportMaintainListWordReducer,
  exportMemberInfo: exportMemberInfoReducer,
  getActivityCateOptions: getActivityCateOptionsReducer,
  getActivityList: getActivityListReducer,
  getActivityProductList: getActivityProductListReducer,
  getActivityReaptedProducts: getActivityReaptedProductReducer,
  getAreaList: getAreaListReducer,
  getAutoReplyMessage: getAutoReplyMessageReducer,
  getBankList: getBankListReducer,
  getBindingGiftsList: getBindingGiftsListReducer,
  getBindingProductList: getBindingProductListReducer,
  getBlockList: getBlockListReducer,
  getCaptchaCode: getCaptchaCodeReducer,
  getCityList: getCityListReducer,
  getColorOptions: getColorOptionsReducer,
  getContactUsContext: getContactUsContextReducer,
  getCountryList: getCountryListReducer,
  getCouponPerformanceReportList: getCouponPerformanceReportListReducer,
  getCouponRealtimeReportList: getCouponRealtimeReportListReducer,
  getDiscountCompaignOptions: getDiscountCompaignOptionsReducer,
  getFinanceReportDashboardList: getFinanceReportDashboardListReducer,
  getFinanceReportReconciliationList: getFinanceReportReconciliationListReducer,
  getFormDescription: getFormDescriptionReducer,
  getGift: getGiftReducer,
  getGiftsList: getGiftsListReducer,
  getMailContent: getMailContentReducer,
  getMailRecordsList: getMailRecordsListReducer,
  getMailTypeOptions: getMailTypeOptionsReducer,
  getMemberInfoViaPhoneNumber: getMemberInfoViaPhoneNumberReducer,
  getMemberLevelList: getMemberLevelSettingsReducer,
  getMemberLevelOptions: getMemberLevelOptionsReducer,
  getMembershipGift: getMembershipGiftReducer,
  getMessageCategoryList: getMessageCategoryListReducer,
  getMessageCategoryOptions: getMessageCategoryOptionsReducer,
  getMessageList: getMessageListReducer,
  getMessageSubCategoryOptions: getMessageSubCategoryOptionsReducer,
  getOneActivity: getOneActivityReducer,
  getOneShopBanner: getOneShopBannerReducer,
  // getTempGiftsList: getTempGiftsListReducer,
  getOrderActivities: getOrderActivitiesReducer,
  getOrderActivityFreebieList: getOrderActivityFreebieListReducer,
  getOrderCancelReport: getOrderCancelReportReducer,
  getOrderFreebieList: getOrderFreebieListReducer,
  getOrderInstantReport: getOrderInstantReportReducer,
  getOrderSourceList: getOrderSourceListReducer,
  getOrderitemReturnInfo: getOrderitemReturnInfoReducer,
  getOverallMessage: getOverallMessageReducer,
  getProduct: getProductReducer,
  getProductBrandBannerSetting: getProductBrandBannerSettingReducer,
  getProductCategoryOptions: productGlobalCategoryReducer,
  getProductInfoViaWarrantySerialNumber:
    getProductInfoViaWarrantySerialNumberReducer,
  getProductSalesDataRanking: getProductSalesDataRankingReducer,
  getPublishDate: getPublishDateReducer,
  getRelatedRegisterList: getRelatedRegisterListReducer,
  getRewardsPointsSettings: getRewardsPointsSettingsReducer,
  getRewardsPointsStatisticalList: getRewardsPointsStatisticalListReducer,
  getShopBannerColor: getShopBannerColorReducer,
  getShopBannerList: getShopBannerListReducer,
  getSingleMessage: getSingleMessageReducer,
  getSizeOptions: getSizeOptionsReducer,
  getStoreSalesDataList: getStoreSalesDataListReducer,
  getVoucherDetail: getVoucherDetailReducer,
  getVoucherList: getVoucherListReducer,
  getYourFavoriteSettings: getSettingsReducer,
  globalCatAssignModel: globalCatAssignModelReducer,
  globalCatRemoveModel: globalCatRemoveModelReducer,
  globalCategoryAddProduct: globalCategoryAddProductReducer,
  groupModelDetail: groupModelDetailReducer,
  groupModelList: groupModelListReducer,
  hideModelDetail: hideModelDetailReducer,
  hideModelList: hideModelListReducer,
  homeDeliveryCancel: homeDeliveryCancelReducer,
  homeDeliveryShipping: homeDeliveryShippingReducer,
  importMemberInfo: importMemberInfoReducer,
  instructionDelete: instructionDeleteReducer,
  instructionFAQDelete: instructionFAQDeleteReducer,
  instructionFAQList: instructionFAQListReducer,
  instructionFAQPost: instructionFAQPostReducer,
  instructionList: instructionListReducer,
  instructionPost: instructionPostReducer,
  jobDelete: jobDeleteReducer,
  jobDetails: jobDetailsReducer,
  jobList: jobListReducer,
  jobPost: jobPostReducer,
  logRecord: logRecordReducer,
  lowestNotifyInfo: lowestNotifyInfoReducer,
  maintainAdd: maintainAddReducer,
  maintainBatchUpdate: maintainBatchUpdateReducer,
  maintainDealingUpdate: maintainDealingUpdateReducer,
  maintainDetailUpdate: maintainDetailUpdateReducer,
  maintainDetails: maintainDetailsReducer,
  maintainExport: maintainExportReducer,
  maintainGetInfo: maintainGetInfoReducer,
  maintainHandlerOptions: maintainHandlerOptionsReducer,
  maintainImport: maintainImporteReducer,
  maintainList: maintainListReducer,
  maintainOptions: maintainOptionsReducer,
  maintainStatusOptions: maintainStatusOptionsReducer,
  memberInfo: memberInfoReducer,
  memberInfoUpdate: memberInfoUpdateReducer,
  memberList: memberListReducer,
  modelBrandOptions: modelBrandOptionsReducer,
  modelDeleteSpec: modelDeleteSpecReducer,
  modelEditDesc: modelEditDescReducer,
  modelEditOrder: modelEditOrderReducer,
  modelEditSetting: modelEditSettingReducer,
  modelEditSpec: modelEditSpecReducer,
  modelExport: modelExportReducer,
  modelGlobalCatList: modelGlobalCatListReducer,
  modelImport: modelImportReducer,
  modelList: modelListReducer,
  modelProductOptions: modelProductOptionsReducer,
  modelProfile: modelProfileReducer,
  modelSellingState: modelSellingStateReducer,
  newProductList: newProductListReducer,
  newsDelete: newsDeleteReducer,
  newsDetails: newsDetailsReducer,
  newsList: newsListReducer,
  orderDetail: orderDetailReducer,
  orderInvoiceDetail: orderInvoiceDetailReducer,
  orderInvoiceList: orderInvoiceListReducer,
  orderList: orderListReducer,
  orderOption: orderOptionReducer,
  orderOptions: orderOptionsReducer,
  orderReturnFinished: orderReturnFinishedReducer,
  orderReturnReasonList: orderReturnReasonListReducer,
  orderReturnReasonSelections: orderReturnReasonSelectionsReducer,
  overall: overallReducer,
  paymentList: paymentListReducer,
  paymentListSave: paymentListSaveReducer,
  permissionOptions: permissionOptionsReducer,
  pointEventSetting: pointEventSettingReducer,
  productBrandCategory: productBrandCategoryReducer,
  productCategoryProductList: productCategoryProductListReducer,
  productDelete: productDeleteReducer,
  productDetails: productDetailsReducer,
  productEdit: productEditReducer,
  productExcelCreate: productExcelCreateReducer,
  productLinkDetail: productLinkDetailReducer,
  productLinkList: productLinkListReducer,
  productLinkPost: productLinkPostReducer,
  productList: productListReducer,
  productNumberCreate: productNumberCreateReducer,
  productNumberList: productNumberListReducer,
  productOptions: productOptionsReducer,
  productOptionsUpdate: productOptionsUpdateReducer,
  productProfile: productProfileReducer,
  productSerialNumber: productSerialNumberReducer,
  productSpecDelete: productSpecDeleteReducer,
  productSpecList: productSpecListReducer,
  productSpecPost: productSpecPostReducer,
  productStockFileImport: productStockFileImportReducer,
  productmodelOptions: productmodelOptionsReducer,
  promoDetail: promoDetailReducer,
  promoList: promoListReducer,
  purchaseNote: purchaseNoteReducer,
  rechargeList: rechargeListReducer,
  repairBrandOptions: repairBrandOptionsReducer,
  repairColorOptions: repairColorOptionsReducer,
  repairDelete: repairDeleteReducer,
  repairList: repairListReducer,
  repairModelOptions: repairModelOptionsReducer,
  repairPost: repairPostReducer,
  repairSettingInfo: repairSettingInfoReducer,
  repairSettingPost: repairSettingPostReducer,
  saveBlockList: saveBlockListReducer,
  saveModels: saveModelsReducer,
  seoSettingsDetail: seoSettingDetailReducder,
  seoSettingsDetailUpdate: seoSettingsDetailUpdateReducer,
  seoSettingsList: seoSettingsListReducer,
  seoSettingsUpdate: seoSettingsUpdateReducer,
  serviceContentDelete: serviceContentDeleteReducer,
  serviceContentPost: serviceContentPostReducer,
  serviceList: serviceListReducer,
  serviceTitlePost: serviceTitlePostReducer,
  serviceTypeDelete: serviceTypeDeleteReducer,
  serviceTypeList: serviceTypeListReducer,
  serviceTypePost: serviceTypePostReducer,
  setAutoReplyMessage: setAutoReplyMessageReducer,
  setGroupModelDesc: setGroupModelDescReducer,
  setGroupModelOrder: setGroupModelOrderReducer,
  setGroupModelSetting: setGroupModelSettingReducer,
  setGroupModelSpec: setGroupModelSpecReducer,
  setMessageClosed: setMessageClosedReducer,
  setProductStockStatus: setProductStockStatusReducer,
  setVoucherSettingsData: setVoucherSettingsDataReducer,
  setYourFavoriteSettings: setSettingsReducer,
  setting: settingReducer,
  settingEdit: settingEditReducer,
  shippingDealerList: shippingDealerListReducer,
  shippingExcelCreate: shippingExcelCreateReducer,
  shippingPrintInfo: shippingPrintInfoReducer,
  sideBar: sideBarReducer,
  sortActivity: sortActivityReducer,
  sortingShopBanner: sortingShopBannerReducer,
  specialPointEventSetting: specialPointEventSettingReducer,
  specialPointEventSettingDetail: specialPointEventSettingDetailReducer,
  storeArrived: storeArrivedReducer,
  storeCancel: storeCancelReducer,
  storeDetail: storeDetailReducer,
  storeDetailUpdate: storeDetailUpdateReducer,
  storeList: storeListReducer,
  storeLogistic: storeLogisticReducer,
  storePickedUp: storePickedUpReducer,
  storeServiceOption: storeServiceOptionReducer,
  storeShipping: storeShippingReducer,
  unReadMessageCount: unReadMessageCountReducer,
  updateActivity: updateActivityReducer,
  updateCaptchaCode: updateCaptchaCodeReducer,
  updateContactUsContext: updateContactUsContextReducer,
  updateCredits: updateCreditsReducer,
  updateData: updateDataReducer,
  updateEmployeeId: updateEmployeeIdReducer,
  updateGift: updateGiftReducer,
  updateLowestNotify: updateLowestNotifyReducer,
  updateMemberLevel: updateMemberLevelRecuder,
  updateMemberLevelDraft: updateMemberLevelDraftReducer,
  updateMemberLevelSettings: updateMemberlevleSettingsReducer,
  updateMessageCategory: updateMessageCategoryReducer,
  updateMessageCategorySort: updateMessageCategorySortReducer,
  updatePublishDate: updatePublishDateReducer,
  updatePurchaseNote: updatePurchaseNoteReducer,
  updateRedeemRewardsPoints: updateRedeemRewardsPointsReducer,
  updateRepiarRegister: updateRepairRegisterReducer,
  updateRewardsPointsEnableSettings: updateRewardsPointsEnableSettingsReducer,
  updateRewardsPointsRules: updateRewardsPointsRulesReducer,
  updateSendBackDate: updateSendBackDateReducer,
  updateServiceRemark: updateServiceRemarkReducer,
  updateShopBanner: updateShopBannerReducer,
  updateShopBannerColor: updateShopBannerColorReducer,
  updateTime: updateTimeReducer,
  updateWarrantyProductSerialNumber: updateWarrantyProductSerialNumberReducer,
  uploadBlockList: uploadBlockListReducer,
  userForgotPassword: userForgotPasswordReducer,
  userLogin: userLoginReducer,
  userResetPassword: userResetPasswordReducer,
  userVerify: userVerifyReducer,
  uuModelRelList: uuModelRelListReducer,
  uuModelRelSelectModels: uuModelRelSelectModelsReducer,
  uuoptions: uuoptionsReducer,
  warrantyAddRemark: warrantyAddRemarkReducer,
  warrantyBrandExpireDate: warrantyBrandExpireDateReducer,
  warrantyBrandList: warrantyBrandListReducer,
  warrantyBrandSet: warrantyBrandSetReducer,
  warrantyCodeList: warrantyCodeListReducer,
  warrantyCodePost: warrantyCodePostReducer,
  warrantyDelete: warrantyDeleteReducer,
  warrantyExcelCreate: warrantyExcelCreateReducer,
  warrantyInfo: warrantyInfoReducer,
  warrantyInfoUpdate: warrantyInfoUpdateReducer,
  warrantyList: warrantyListReducer,
  warrantyMasterList: warrantyMasterListReducer,
  warrantyNumber: warrantyNumberReducer,
  warrantyNumberCreate: warrantyNumberCreateReducer,
  warrantyNumberList: warrantyNumberListReducer,
  warrantyPost: warrantyPostReducer,
  warrantyPrefixList: warrantyPrefixListReducer,
  warrantyProductCheck: warrantyProductCheckReducer,
  warrantyRecord: warrantyRecordReducer,
  warrantyRegisterDetails: warrantyRegisterDetailsReducer,
  warrantyRegisterExport: warrantyRegisterExportReducer,
  warrantyRegisterList: warrantyRegisterListReducer,
  warrantyRegisterNotify: warrantyRegisterNotifyReducer,
  warrantyRegisterNotifyList: warrantyRegisterNotifyListReducer,
  warrantyRegisterReview: warrantyRegisterReviewReducer,
  warrantySerialNumbers: warrantySerialNumbersReducer,
});

const userInfoFromStorage = localStorage.getItem('backend_auth')
  ? JSON.parse(localStorage.getItem('backend_auth'))
  : null;

const initialState = { userLogin: { userInfo: userInfoFromStorage } };
const middleware = [thunk];

//讓store執行異步操作需要redux-thunk作為middleware
//而執行middleware需要引入applyMiddleware

const store = createStore(
  allReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);
export default store;
