import Layout from 'components/Layout';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
  downloadTemplateFile,
  uploadBlockList,
} from 'redux/actions/data/blockListActions';
import {
  DOWNLOAD_TEMPLATE_FILE_IDLE,
  UPLOAD_BLOCK_LIST_IDLE,
} from 'redux/constants/data/blockListConstants';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';


import style from '../styles/layout.module.css';

const BlockListBatchFile = () => {
  const importFileRef = useRef();
  const {
    success: downloadSuccess,
    error: downloadError,
    url,
  } = useSelector(state => state.downloadTemplateFile);
  const {
    loading,
    success: uploadSuccess,
    error: uploadError,
  } = useSelector(state => state.uploadBlockList);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const downloadFileHandler = useCallback(() => {
    dispatch(downloadTemplateFile());
  }, []);

  const uploadFileHandler = useCallback(() => {
    // call upload api
    const formData = new FormData();
    formData.append('importFile', file);

    dispatch(uploadBlockList(formData));
  }, [file]);

  useEffect(() => {
    if (downloadSuccess) {
      window.open(url);
      dispatch({ type: DOWNLOAD_TEMPLATE_FILE_IDLE });
    }

    if (downloadError) {
      MessageUtil.alertWanring(downloadError);
      dispatch({ type: DOWNLOAD_TEMPLATE_FILE_IDLE });
    }
  }, [downloadSuccess, downloadError]);

  useEffect(() => {
    if (uploadSuccess) {
      MessageUtil.toastSuccess('上傳成功!');
      dispatch({ type: UPLOAD_BLOCK_LIST_IDLE });
      history.goBack();
    }

    if (uploadError) {
      MessageUtil.alertWanring(uploadError);
      dispatch({ type: UPLOAD_BLOCK_LIST_IDLE });
    }
  }, [uploadSuccess, uploadError]);

  return (
    <Layout
      pageTitle={'批次新增黑名單'}
      items={[
        { isActive: true, page_name: '交易黑名單管理' },
        { isActive: false, page_name: '批次新增黑名單' },
      ]}
    >
      <Card className={style.search_card}>
        <div
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1B99A2', padding: '1.5rem' }}
        >
          操作說明
        </div>
        <div className={style.card_body}>
          檔案格式：xlsx
          <ul>
            <li>
              工作表(sheet)名稱要正確，請不要更改範例中工作表(sheet)的名稱
            </li>
            <li>資料欄位名稱要正確，請不要更改範例中欄位的名稱</li>
          </ul>
        </div>
      </Card>
      <Card className={style.search_card}>
        <div
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1B99A2', padding: '1.5rem' }}
        >
          上傳檔案
        </div>
        <div className={style.card_body}>
          <div className="mb-3">
            請上傳 .xlsx 或{' '}
            <span
              style={{ color: '#0077ff', cursor: 'pointer' }}
              onClick={downloadFileHandler}
            >
              下載範例格式 <FaDownload style={{ color: '#0077ff' }} />
            </span>
          </div>
          <div className={style.show_flex}>
            <Button
              color="primary"
              outline
              onClick={() => {
                importFileRef.current.click();
              }}
            >
              <input
                type="file"
                style={{ display: 'none' }}
                ref={importFileRef}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={e => {
                  const fileProps = e.target.files[0];
                  fileProps && setFile(fileProps);
                }}
              />
              選擇檔案
            </Button>
            <div style={{ marginLeft: '1rem' }}>{file?.name}</div>
          </div>
        </div>
      </Card>
      <Button
        color="primary"
        className={`${style.modal_button_submit} ${style['rwd-w180']}`}
        onClick={uploadFileHandler}
        disabled={loading || file === null}
      >
        {loading && (
          <span
            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        確認上傳
      </Button>
    </Layout>
  );
};

export default BlockListBatchFile;
