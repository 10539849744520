import Layout from 'components/Layout';
import SearchSelect from 'components/OrderSearchSelector';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Table } from 'reactstrap';
import { getOrderOptions } from 'redux/actions/data/orderActions';
import { getDeliveryRefoundList } from 'redux/actions/data/orderReturnActions';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({ onChangePage }) => {
  const { loading, deliveryRefundListInfo } = useSelector(
    state => state.deliveryRefundList,
  );

  return (
    <Card className={style.card}>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>
                <div>運費退款單號</div>
                <div>退款金額</div>
              </th>
              <th>
                <div>運費退款單狀態</div>
                <div>成立時間</div>
                <div>狀態時間</div>
              </th>
              <th>主單編號</th>

              <th>
                <div>會員編號</div>
                <div>會員等級</div>
              </th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {deliveryRefundListInfo?.datas?.length > 0 ? (
                deliveryRefundListInfo?.datas?.map(i => (
                  <tr className="text-nowrap" key={JSON.stringify(i)}>
                    <td>
                      <div>{i.deliveryChargeReturnNumber}</div>
                      <div className="price text-dark">{i.amount}</div>
                    </td>
                    <td>
                      <div>{i.refundStatusName}</div>
                      <div>{i.deliveryChargeReturnCreateTime}</div>
                      <div>{i.deliveryChargeReturnStatusTime}</div>
                    </td>
                    <td>{i.orderNumber}</td>
                    <td>
                      <div className="text-nowrap">
                        +{i.memberPhone || '--'}
                      </div>
                      <div>{i.memberLevel || '--'}</div>
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      {deliveryRefundListInfo?.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={deliveryRefundListInfo?.current_page}
            defaultPageSize={deliveryRefundListInfo?.page_size}
            total={deliveryRefundListInfo?.total_count}
            onChange={onChangePage}
          />
        </div>
      )}
    </Card>
  );
};
const OrderReturnDeliveryList = () => {
  const dispatch = useDispatch();
  const [pageIsChanged, setPageIsChanged] = useState(false);
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);
  useEffect(() => {
    dispatch(getOrderOptions());
    dispatch(
      getDeliveryRefoundList({
        deliveryChargeReturnStatus: 0,
        endTime: moment().toDate(),
        page: 1,
        page_size: 10,
        returnKeyword: '',
        startTime: moment().subtract(7, 'day').toDate(),
        timeType: '運費退款單成立時間',
      }),
    );
  }, []);
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        deliveryChargeReturnStatus: 0,
        endTime: null,
        orderNumber: '',
        orderNumberType: '主單編號',
        page: 1,
        page_size: 10,
        returnKeyword: '',
        startTime: null,
        timeType: '運費退款單成立時間',
      },
      async onSubmit(formModel) {
        const params = {
          ...formModel,
          endTime: formModel.endTime ?? moment().toDate(),
          page: pageIsChanged ? formModel.page : 1,
          startTime:
            formModel.startTime ?? moment().subtract(7, 'day').toDate(),
        };
        await dispatch(getDeliveryRefoundList(params));
        setPageIsChanged(false);
      },
    });

  const handleChangePage = n => {
    setFieldValue('page', n);
    setPageIsChanged(true);
    handleSubmit();
  };

  return (
    <Layout
      pageTitle="運費退款單查詢"
      items={[
        { isActive: false, page_name: '退貨訂單管理' },
        { isActive: true, page_name: '運費退款單查詢' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  name="returnKeyword"
                  placeholder="會員電話、退貨聯絡人、退貨聯絡人電話"
                  onChange={handleChange}
                  value={values.returnKeyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">編號</label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  name="orderNumberType"
                  onChange={handleChange}
                  value={values.orderNumberType}
                  style={{ margin: 0 }}
                >
                  <option value="主單編號">主單編號</option>
                  <option value="訂單編號">訂單編號</option>
                  <option value="退貨單號">退貨單號</option>
                </Input>
                <Input
                  type="text"
                  className={style['form-input-order-number']}
                  name="orderNumber"
                  onChange={handleChange}
                  value={values.orderNumber}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">運費退款單狀態</label>
                <SearchSelect
                  className={style['form-select-short']}
                  options={orderOptionsInfo?.deliveryChargeReturnStatus}
                  emptyVal={0}
                  name="deliveryChargeReturnStatus"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  日期區間
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  name="timeType"
                  onChange={handleChange}
                  value={values.timeType}
                  style={{ margin: 0 }}
                >
                  <option value="運費退款單成立時間">運費退款單成立時間</option>
                  <option value="訂購時間">訂購時間</option>
                </Input>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={
                    values.startTime ?? moment().subtract(7, 'day').toDate()
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                  selectsStart
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  selected={values.endTime ?? new Date()}
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  selectsEnd
                  minDate={values.startTime}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <TableList onChangePage={handleChangePage} />
    </Layout>
  );
};
export default OrderReturnDeliveryList;
