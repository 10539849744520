import moment from 'moment';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import history from '../../utils/createHistory';
import { TableHasNoData, TableLoading } from '../TableHasNoData';

const GiftListTable = () => {
  const { gifts, loading } = useSelector(state => state.getGiftsList);

  return (
    <div className="table-responsive mb-5">
      <Table bordered striped hover>
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>贈品序號</th>
            <th style={{ width: '250px' }}>贈品主圖</th>
            <th>贈品名稱</th>
            {/* <th>贈品料號</th> */}
            <th>
              <div>已發送數量</div>
              <div>贈品庫存</div>
              <div>安全庫存量</div>
            </th>
            <th>贈品狀態</th>
            <th>
              <div>贈品上架時間</div>
              <div>贈品下架時間</div>
            </th>
            <th>異動日期</th>
            <th>功能</th>
          </tr>
        </thead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {gifts?.datas?.length > 0 ? (
              gifts.datas.map(data => (
                <tr key={JSON.stringify(data)}>
                  <td className="text-center text-nowrap">
                    {/* 贈品序號 */}
                    {data.freebieCode}
                  </td>
                  <td className="text-center">
                    {/* 贈品主圖 */}
                    <img
                      className={style.w200}
                      src={data?.freebieImgInfo[0]?.imageUrl || ''}
                      alt={data?.freebieImgInfo[0]?.imageUrl || ''}
                    />
                  </td>
                  <td className="text-center">
                    {/* 贈品名稱 */}
                    {data.name}
                  </td>
                  {/* <td className="text-center text-nowrap">
                  {data.productcode}
                </td>*/}
                  <td className="text-center">
                    {/* 已發送數量/贈品庫存/安全庫存量 */}
                    <div>{data.soldNum || 0}</div>
                    <div>{data.stock}</div>
                    <div>{data.lowestStock}</div>
                  </td>
                  <td className="text-center">
                    {/* 贈品狀態 */}
                    {data.isSell ? '開啟' : '關閉'}
                  </td>
                  <td className="text-left">
                    {/* 贈品上架時間/贈品下架時間 */}
                    <div className="text-nowrap">
                      {moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')}
                    </div>
                    <div className="text-nowrap">
                      {moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')}
                    </div>
                  </td>
                  <td className="text-left">
                    {/* 異動日期 */}
                    <div className="text-nowrap">
                      {(data.updateTime &&
                        moment(data.updateTime).format(
                          'YYYY/MM/DD HH:mm:ss',
                        )) ||
                        '--'}
                    </div>
                    <div>{data.updater || '--'}</div>
                  </td>
                  <td className="text-center">
                    {/* 功能 */}
                    <div>
                      <Button
                        color="primary"
                        outline
                        className="mb-2 w100"
                        onClick={() => {
                          history.push(
                            `/GiftSettings?status=edit&id=${data.id}`,
                          );
                        }}
                      >
                        編輯
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        outline
                        className="mb-2 w100"
                        onClick={() => {
                          history.push(`/ProductBinding?id=${data.id}`);
                        }}
                      >
                        加入商品
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="primary w100"
                        outline
                        onClick={() => {
                          history.push(`/GiftDetail?id=${data.id}`);
                        }}
                      >
                        檢視活動
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default memo(GiftListTable);
