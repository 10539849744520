import {
  ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST,
  ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS,
  ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL,
  ORDER_RETURN_RECHARGE_LIST_REQUEST,
  ORDER_RETURN_RECHARGE_LIST_SUCCESS,
  ORDER_RETURN_RECHARGE_LIST_FAIL,
  ORDER_RETURN_EDIT_ATMACC_IDLE,
  ORDER_RETURN_EDIT_ATMACC_REQUEST,
  ORDER_RETURN_EDIT_ATMACC_SUCCESS,
  ORDER_RETURN_EDIT_ATMACC_FAIL,
  ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL,
  ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE,
  ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST,
  ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS,
  ORDER_RETURN_FINISHED_IDLE,
  ORDER_RETURN_FINISHED_REQUEST,
  ORDER_RETURN_FINISHED_SUCCESS,
  ORDER_RETURN_FINISHED_FAIL,
} from 'redux/constants/data/orderReturnConstants';

export const deliveryRefundListReducer = (
  state = { deliveryRefundListInfo: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS:
      return { deliveryRefundListInfo: payload || {}, loading: false };
    case ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL:
      return {
        deliveryRefundListInfo: {},
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
};
export const rechargeListReducer = (
  state = { loading: false, rechargeListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_RECHARGE_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_RECHARGE_LIST_SUCCESS:
      return { loading: false, rechargeListInfo: payload || {} };
    case ORDER_RETURN_RECHARGE_LIST_FAIL:
      return {
        error: payload,
        loading: false,
        rechargeListInfo: {},
      };
    default:
      return state;
  }
};

export const editATMAccReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_EDIT_ATMACC_IDLE:
      return { error: null, loading: false, success: false };
    case ORDER_RETURN_EDIT_ATMACC_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_EDIT_ATMACC_SUCCESS:
      return { ...state, loading: false, success: true };
    case ORDER_RETURN_EDIT_ATMACC_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const editReturnFailATMAccReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE:
      return { error: null, loading: false, success: false };
    case ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS:
      return { ...state, loading: false, success: true };
    case ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const orderReturnFinishedReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_RETURN_FINISHED_IDLE:
      return { error: null, loading: false, success: false };
    case ORDER_RETURN_FINISHED_REQUEST:
      return { ...state, loading: true };
    case ORDER_RETURN_FINISHED_SUCCESS:
      return { ...state, loading: false, success: true };
    case ORDER_RETURN_FINISHED_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
