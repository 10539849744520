import {
  GET_REWARDS_POINTS_SETTINGS_FAIL,
  GET_REWARDS_POINTS_SETTINGS_REQUEST,
  GET_REWARDS_POINTS_SETTINGS_SUCCESS,
  UPDATE_REDEEM_REWARDS_POINTS_FAIL,
  UPDATE_REDEEM_REWARDS_POINTS_IDLE,
  UPDATE_REDEEM_REWARDS_POINTS_REQUEST,
  UPDATE_REDEEM_REWARDS_POINTS_SUCCESS,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST,
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS,
  UPDATE_REWARDS_POINTS_RULES_FAIL,
  UPDATE_REWARDS_POINTS_RULES_IDLE,
  UPDATE_REWARDS_POINTS_RULES_REQUEST,
  UPDATE_REWARDS_POINTS_RULES_SUCCESS,
} from 'redux/constants/data/rewardsPointsConstants';

export const getRewardsPointsSettingsReducer = (
  state = { error: null, loading: false, settings: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REWARDS_POINTS_SETTINGS_REQUEST:
      return { error: null, loading: true, settings: {}, success: false };
    case GET_REWARDS_POINTS_SETTINGS_SUCCESS:
      return { ...state, loading: false, settings: payload, success: true };
    case GET_REWARDS_POINTS_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateRewardsPointsEnableSettingsReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateRewardsPointsRulesReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_REWARDS_POINTS_RULES_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_REWARDS_POINTS_RULES_REQUEST:
      return { ...state, loading: true };
    case UPDATE_REWARDS_POINTS_RULES_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_REWARDS_POINTS_RULES_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateRedeemRewardsPointsReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_REDEEM_REWARDS_POINTS_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_REDEEM_REWARDS_POINTS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_REDEEM_REWARDS_POINTS_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_REDEEM_REWARDS_POINTS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
