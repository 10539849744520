export const GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST =
  'GET_DISCOUNT_COMPAIGN_OPTIONS_REQUEST';
export const GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS =
  'GET_DISCOUNT_COMPAIGN_OPTIONS_SUCCESS';
export const GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL =
  'GET_DISCOUNT_COMPAIGN_OPTIONS_FAIL';

export const GET_SHOP_BANNER_LIST_REQUEST = 'GET_SHOP_BANNER_LIST_REQUEST';
export const GET_SHOP_BANNER_LIST_SUCCESS = 'GET_SHOP_BANNER_LIST_SUCCESS';
export const GET_SHOP_BANNER_LIST_FAIL = 'GET_SHOP_BANNER_LIST_FAIL';

export const CREATE_NEW_SHOP_BANNER_IDLE = 'CREATE_NEW_SHOP_BANNER_IDLE';
export const CREATE_NEW_SHOP_BANNER_REQUEST = 'CREATE_NEW_SHOP_BANNER_REQUEST';
export const CREATE_NEW_SHOP_BANNER_SUCCESS = 'CREATE_NEW_SHOP_BANNER_SUCCESS';
export const CREATE_NEW_SHOP_BANNER_FAIL = 'CREATE_NEW_SHOP_BANNER_FAIL';

export const DELETE_SHOP_BANNER_IDLE = 'DELETE_SHOP_BANNER_IDLE';
export const DELETE_SHOP_BANNER_REQUEST = 'DELETE_SHOP_BANNER_REQUEST';
export const DELETE_SHOP_BANNER_SUCCESS = 'DELETE_SHOP_BANNER_SUCCESS';
export const DELETE_SHOP_BANNER_FAIL = 'DELETE_SHOP_BANNER_FAIL';

export const GET_ONE_SHOP_BANNER_REQUEST = 'GET_ONE_SHOP_BANNER_REQUEST';
export const GET_ONE_SHOP_BANNER_SUCCESS = 'GET_ONE_SHOP_BANNER_SUCCESS';
export const GET_ONE_SHOP_BANNER_FAIL = 'GET_ONE_SHOP_BANNER_FAIL';

export const UPDATE_SHOP_BANNER_IDLE = 'UPDATE_SHOP_BANNER_IDLE';
export const UPDATE_SHOP_BANNER_REQUEST = 'UPDATE_SHOP_BANNER_REQUEST';
export const UPDATE_SHOP_BANNER_SUCCESS = 'UPDATE_SHOP_BANNER_SUCCESS';
export const UPDATE_SHOP_BANNER_FAIL = 'UPDATE_SHOP_BANNER_FAIL';

export const CREATE_SHOP_BANNER_SCHEDULE_IDLE =
  'CREATE_SHOP_BANNER_SCHEDULE_IDLE';
export const CREATE_SHOP_BANNER_SCHEDULE_REQUEST =
  'CREATE_SHOP_BANNER_SCHEDULE_REQUEST';
export const CREATE_SHOP_BANNER_SCHEDULE_SUCCESS =
  'CREATE_SHOP_BANNER_SCHEDULE_SUCCESS';
export const CREATE_SHOP_BANNER_SCHEDULE_FAIL =
  'CREATE_SHOP_BANNER_SCHEDULE_FAIL';

export const GET_SHOP_BANNER_COLOR_REQUEST = 'GET_SHOP_BANNER_COLOR_REQUEST';
export const GET_SHOP_BANNER_COLOR_SUCCESS = 'GET_SHOP_BANNER_COLOR_SUCCESS';
export const GET_SHOP_BANNER_COLOR_FAIL = 'GET_SHOP_BANNER_COLOR_FAIL';

export const UPDATE_SHOP_BANNER_COLOR_IDLE = 'UPDATE_SHOP_BANNER_COLOR_IDLE';
export const UPDATE_SHOP_BANNER_COLOR_REQUEST =
  'UPDATE_SHOP_BANNER_COLOR_REQUEST';
export const UPDATE_SHOP_BANNER_COLOR_SUCCESS =
  'UPDATE_SHOP_BANNER_COLOR_SUCCESS';
export const UPDATE_SHOP_BANNER_COLOR_FAIL = 'UPDATE_SHOP_BANNER_COLOR_FAIL';

export const SORTING_SHOP_BANNER_IDLE = 'SORTING_SHOP_BANNER_IDLE';
export const SORTING_SHOP_BANNER_REQUEST = 'SORTING_SHOP_BANNER_REQUEST';
export const SORTING_SHOP_BANNER_SUCCESS = 'SORTING_SHOP_BANNER_SUCCESS';
export const SORTING_SHOP_BANNER_FAIL = 'SORTING_SHOP_BANNER_FAIL';
