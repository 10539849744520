import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { getBrandOptions } from '../../redux/actions/data/brandActions';
import {
  getModelOptions,
  getProductListV2,
} from '../../redux/actions/data/productActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import request from '../../utils/useAxios';
import SearchSelect from '../OrderSearchSelector';

import AddModalTableList from './AddModalTableList';

const CreateHideModelModal = ({ open, toggle }) => {
  const dispatch = useDispatch();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { productListInfo } = useSelector(state => state.productList);

  useEffect(() => {
    dispatch(getBrandOptions());
    return () => {
      setSubmitLoading(false);
    };
  }, []);

  const [isShowTable, setIsShowTable] = useState(false);
  const productTypeRef = useRef();

  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: null,
      keyword: '',
      model: '',
      page: 1,
      page_size: 10,
      productType: 1,
    },
    onSubmit(formModel) {
      setIsShowTable(true);
      productTypeRef.current = formModel.productType;
      dispatch(getProductListV2({ ...formModel, modelId: formModel.model }));
    },
  });
  useEffect(() => {
    dispatch(
      getModelOptions(
        brandOptions?.find(i => i.value === values.brandId)?.label || '',
      ),
    );
    setFieldValue('model', '');
  }, [values.brandId]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const [list, setList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleListChange = formModel => {
    setList(formModel);
  };
  const handleAddProducts = async () => {
    if (list.length <= 0) {
      MessageUtil.toastWarning('請選擇商品！');
      return;
    }
    setSubmitLoading(true);

    const { code, result, message } = await request({
      data: {
        productIds: list.map(l => l.productid),
        productType: productTypeRef.current,
      },
      method: 'post',
      url: '/Backend/HideModel/CopyAndCreateHideModel',
    });

    setSubmitLoading(false);

    if (code !== 200) {
      MessageUtil.alertWanring(message);
      return;
    }
    toggle();
    history.push(`/HideModelDetail/${result}`);
  };

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        新增隱形賣場
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        <form className="d-flex flex-wrap w-100 mb-5" style={{ gap: '0.8rem' }}>
          <Input
            className={style['form-input']}
            placeholder="商品名稱"
            value={values.keyword}
            onChange={event => {
              setFieldValue('keyword', event.target.value);
            }}
          />
          <div
            style={{ maxWidth: '200px', minWidth: '200px' }}
            className="flex-grow-1"
          >
            <SearchSelect
              options={[
                {
                  code: 1,
                  name: '一般商品',
                },
                {
                  code: 3,
                  name: '組合商品',
                },
              ]}
              emptyVal={0}
              emptyLabel="選擇商品類型"
              name="productType"
              setFieldValue={setFieldValue}
              values={values}
              noAll={true}
            />
          </div>
          <div
            style={{ maxWidth: '200px', minWidth: '200px' }}
            className="flex-grow-1"
          >
            <SearchSelect
              options={brandOptions?.map(o => {
                return {
                  ...o,
                  code: o.value,
                  name: o.label,
                };
              })}
              emptyVal={null}
              emptyLabel="選擇品牌"
              name="brandId"
              setFieldValue={setFieldValue}
              values={values}
            />
          </div>
          <div
            style={{ maxWidth: '200px', minWidth: '200px' }}
            className="flex-grow-1"
          >
            <SearchSelect
              options={modelOptions?.map(o => {
                return {
                  ...o,
                  code: o.value,
                  name: o.label,
                };
              })}
              emptyVal={''}
              emptyLabel="選擇型號"
              name="model"
              setFieldValue={setFieldValue}
              values={values}
            />
          </div>
          <Button
            type="button"
            className={style.modal_button_submit}
            onClick={() => {
              handleChangePage(1);
            }}
          >
            查詢
          </Button>
        </form>
        <AddModalTableList
          productType={productTypeRef.current}
          isShowTable={isShowTable}
          onChange={handleListChange}
          selecteds={[]}
        />
        {productListInfo?.total_count > 0 && isShowTable && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={productListInfo?.current_page}
              defaultPageSize={productListInfo?.page_size}
              total={productListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={submitLoading}
        >
          {submitLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleAddProducts}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CreateHideModelModal);
