import { ErrorMessage, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  deleteDiscountImage,
  getDiscountImageList,
  postDiscountImage,
} from '../redux/actions/data/salesActions';
import {
  DISCOUNT_IMAGE_DELETE_RESET,
  DISCOUNT_IMAGE_POST_RESET,
} from '../redux/constants/data/salesConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function CouponBg(props) {
  const dispatch = useDispatch();
  const query = useQuery();
  const { location } = history;

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const [brandId, setBrandId] = useState({ label: '請選擇品牌', value: 0 });
  const [current_page, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);

  const { userInfo } = useSelector(state => state.userLogin);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { loading, imageInfo, error } = useSelector(
    state => state.discountImageList,
  );
  const { loading: postLoading, success: postSuccess } = useSelector(
    state => state.discountImagePost,
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.discountImageDelete,
  );

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/CouponBackground' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/CouponBackground' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getBrandOptions());
    dispatch(getDiscountImageList({ page: 1 }));
  }, []);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getDiscountImageList({ page: current_page }));
      setModal(false);
    }

    return () => {
      dispatch({ type: DISCOUNT_IMAGE_POST_RESET });
    };
  }, [postSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getDiscountImageList({ page: current_page }));
    }

    return () => {
      dispatch({ type: DISCOUNT_IMAGE_DELETE_RESET });
    };
  }, [deleteSuccess]);

  useEffect(() => {
    if (location.search) {
      const queryBrand = query.value.brandId;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryBrand) {
        setBrandId({ ...brandId, value: queryBrand });

        //儲存搜尋及頁數
        dispatch(
          getDiscountImageList({ brandId: queryBrand, page: queryPage }),
        );
      } else {
        //單純記錄頁數
        dispatch(getDiscountImageList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setBrandId({ label: '請選擇品牌', value: 0 });

      //初始搜尋
      dispatch(getDiscountImageList({ page: 1 }));
    }
  }, [location.search]);

  const clearSearchForm = () => {
    setBrandId({ label: '請選擇品牌', value: 0 });
    dispatch(getDiscountImageList({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    dispatch(getDiscountImageList({ brandId: brandId.value, page: 1 }));
    history.push(`${location.pathname}?brandId=${brandId.value}&page=${1}`);
  };

  const toggleEditModal = data => {
    setModal(!modal);
    setData(data);
  };

  const handleSubmit = values => {
    let formData = new FormData();

    formData.append('id', data.id);
    formData.append('brandId', data.brandId);
    formData.append(
      'contentImg',
      values.contentImg ? values.contentImg[0] : [],
    );

    dispatch(postDiscountImage(formData));
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getDiscountImageList({ brandId: brandId.value, page }));
    if (brandId.value) {
      history.push(
        `${location.pathname}?brandId=${brandId.value}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteDiscountImage(id));
    });
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      current_contentImg: Yup.array().min(1, '需上傳圖片'),
    });
    return schema;
  };

  const getImageDataFromSetting = (pic, url) => {
    if (pic && url) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: pic,
          url: url,
        },
      ];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (imageInfo) {
      if (JSON.stringify(imageInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: imageInfo });
      }
    }
  }, [imageInfo]);

  return (
    <Layout
      pageTitle="品牌圖片設定"
      items={[
        { isActive: false, page_name: '折扣碼管理' },
        { isActive: true, page_name: '品牌圖片設定' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-3" htmlFor="keyword">
                品牌
              </label>
              <Select
                isLoading={brandOptionsLoading}
                isDisabled={brandOptionsLoading}
                className={style['form-select']}
                options={
                  brandOptions && [
                    { label: '請選擇品牌', value: 0 },
                    ...brandOptions,
                  ]
                }
                value={brandId}
                onChange={option => setBrandId(option)}
              />
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        {/* <Button className="btn btn-success w100" onClick={toggleAdd}>
          新增
        </Button> */}
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && imageInfo && imageInfo.total_count > 0
              ? imageInfo.total_count
              : 0}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>品牌</th>
                <th style={{ minWidth: '250px' }}>圖片</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {imageInfo ? (
                  imageInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index + 1 + (current_page - 1) * imageInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.brandName}
                      </td>
                      <td>
                        {data.contentImgUrl && (
                          <div className="d-flex">
                            <img
                              src={data.contentImgUrl}
                              alt={data.brandName}
                              width="200"
                            />
                            <MdDelete
                              color="red"
                              size={25}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDelete(data.id)}
                            />
                          </div>
                        )}
                      </td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => toggleEditModal(data)}
                        >
                          編輯
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && imageInfo && imageInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={imageInfo.current_page}
              defaultPageSize={imageInfo.page_size}
              total={imageInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          {data && data.brandName} 折扣碼圖片
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            contentImg_files: [],
            current_contentImg: [],
            init_contentImg:
              data &&
              getImageDataFromSetting(data.contentImg, data.contentImgUrl),
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    上傳折扣碼圖片
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="contentImg"
                      currentFileFieldName="current_contentImg"
                      uplodModel="images"
                      displayFileWidth="600"
                      displayFileHeight="600"
                      displayFileSize="1MB"
                      initFiles={props.values.init_contentImg}
                    />{' '}
                    <ErrorMessage
                      name="current_contentImg"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
