import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import {
  addCafeServiceDetail,
  updateCafeServiceDetail,
} from '../../redux/actions/data/uuCafeServicesAction';
import style from '../../styles/layout.module.css';

const ServiceModel = ({
  service = {
    name: '',
    name_en: '',
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const { loading, success, error } = useSelector(
    state => state.cafeServiceDetail,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    async props => {
      if (props.values.name && props.values.name_en) {
        isSubmit.current = true;
        if (service.id) {
          dispatch(
            updateCafeServiceDetail({
              id: service.id,
              name: props.values.name,
              name_en: props.values.name_en,
            }),
          );
        } else {
          dispatch(
            addCafeServiceDetail({
              name: props.values.name,
              name_en: props.values.name_en,
            }),
          );
        }
      }
    },
    [service],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (service.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (service.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, service]);

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>編輯</ModalHeader>
        <Formik
          initialValues={{
            name: service.name,
            name_en: service.name_en,
          }}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className="form-item">
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>*服務後項目(中文)</label>
                    <div className="form-item">
                      <Field name="name" className="form-control" />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>*服務後項目(英文)</label>
                    <div className="form-item">
                      <Field name="name_en" className="form-control" />
                      <ErrorMessage
                        name="name_en"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <Button
                  color="light"
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    setOpenModel(false, false);
                  }}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  type="button"
                  className={style.modal_button_submit}
                  disabled={loading}
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span>儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default memo(ServiceModel);
