import EditStaffCodeModal from 'components/EditStaffCodeModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import FullScreenImageModal from '../components/warrantyRegisterDetails/FullScreenImageModal';
import WarrantyDateSettingModal from '../components/warrantyRegisterDetails/WarrantyDateSettingModal';
import WarrantyHistoryRecordModal from '../components/warrantyRegisterDetails/WarrantyHistoryRecordModal';
import {
  addWarrantyRemark,
  getWarrantyRecord,
  getWarrantyRegisterDetails,
  getWarrantyRegisterNumber,
  reviewWarrantyRegister,
} from '../redux/actions/data/warrantyActions';
import {
  WARRANTY_REGISTER_DETAILS_RESET,
  WARRANTY_REGISTER_RECORD_RESET,
  WARRANTY_REGISTER_REVIEW_RESET,
  WARRANTY_REMARK_ADD_RESET,
} from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const WarrantyRegisterDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [otherReason, setOtherReason] = useState('');
  const [start, setStart] = useState('0');
  const [info, setInfo] = useState(null);
  const [modal, setModal] = useState(false);
  const [historyModal, toggleHistoryModal] = useState(false);
  const [remark, setRemark] = useState('');
  const { loading, warrantyRegisterDetailsInfo, error } = useSelector(
    state => state.warrantyRegisterDetails,
  );
  const { warrantyRecord } = useSelector(state => state.warrantyRecord);
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.warrantyAddRemark);
  const {
    loading: submitLoading,
    success: submitSuccess,
    error: submitError,
  } = useSelector(state => state.warrantyRegisterReview);

  const toggleModal = () => {
    setModal(!modal);
    setOtherReason('');
  };

  useEffect(() => {
    if (error && error.code === 403) {
      MessageUtil.toastWarning(error.message, '');

      setTimeout(() => {
        history.goBack();
      }, 1500);
    }

    error && MessageUtil.toastWarning(error.message);
  }, [error]);

  useEffect(() => {
    if (id) {
      dispatch(getWarrantyRegisterDetails(id));
    }

    //錯誤清除要寫在初始狀態的useEffect
    return () => {
      dispatch({ type: WARRANTY_REGISTER_DETAILS_RESET });
      dispatch({ type: WARRANTY_REGISTER_RECORD_RESET });
    };
  }, [id]);

  useEffect(() => {
    if (warrantyRegisterDetailsInfo) {
      if (JSON.stringify(warrantyRegisterDetailsInfo) !== '{}') {
        CommonUtil.consoleLog({
          anno: 'response',
          data: warrantyRegisterDetailsInfo,
        });
      }
    }

    setInfo(warrantyRegisterDetailsInfo);
  }, [warrantyRegisterDetailsInfo]);

  useEffect(() => {
    if (addSuccess) {
      MessageUtil.toastSuccess('新增成功!!');
      dispatch(getWarrantyRegisterDetails(id));
      setRemark('');
    }

    return () => {
      dispatch({ type: WARRANTY_REMARK_ADD_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (modal) {
      if (submitSuccess) {
        MessageUtil.toastSuccess('儲存成功!!');
        dispatch(getWarrantyRegisterDetails(id));
        dispatch(getWarrantyRegisterNumber());
        setModal(!modal);
        setOtherReason('');
        dispatch({ type: WARRANTY_REGISTER_REVIEW_RESET });
      }
      if (submitError) {
        MessageUtil.alertWanring('儲存失敗', submitError);
        dispatch({ type: WARRANTY_REGISTER_REVIEW_RESET });
      }
    }
  }, [submitSuccess, submitError]);

  const handleAddRemark = e => {
    e.preventDefault();
    dispatch(
      addWarrantyRemark({
        reason: remark,
        warrantyRegisterId: info.id,
      }),
    );
  };

  const handleShowRecord = id => {
    dispatch(getWarrantyRecord(id));
  };

  const handleSubmit = values => {
    const object = {
      ...values,
      productDate_admin: values.shippingDate
        ? moment(values.shippingDate).format('YYYY/MM/DD HH:mm')
        : info.productDate_admin
        ? moment(info.productDate_admin).format('YYYY/MM/DD HH:mm')
        : '',
      purchaseDate_admin: values.purchaseDate
        ? moment(values.purchaseDate).format('YYYY/MM/DD HH:mm')
        : info.purchaseDate_admin
        ? moment(info.purchaseDate_admin).format('YYYY/MM/DD HH:mm')
        : '',
      reason: values.reason,
      startDateType_admin: Number(values.startDateType_admin),
      status: values.status,
      warrantyEndDate: values.warrantyEndDate
        ? moment(values.warrantyEndDate).format('YYYY/MM/DD HH:mm')
        : info.warrantyEndDate
        ? moment(info.warrantyEndDate).format('YYYY/MM/DD HH:mm')
        : '',
      warrantyRegisterId: info.id,
    };
    dispatch(reviewWarrantyRegister(object));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape(
      {
        purchaseDate: Yup.date().when('startDateType_admin', {
          is: startDateType_admin => startDateType_admin == 1,
          then: Yup.date().required('請填入購買日期').nullable(),
        }),
        reason: Yup.string().when('status', {
          is: status => status == '未通過',
          then: Yup.string().required('請填入原因'),
        }),
        shippingDate: Yup.date().when('startDateType_admin', {
          is: startDateType_admin => startDateType_admin == 2,
          then: Yup.date().required('請填入出貨日期').nullable(),
        }),
        startDateType_admin: info?.warrantyDays
          ? Yup.string().required('必填')
          : Yup.string(),
        status: Yup.string().required('必填'),
        warrantyEndDate: info?.warrantyDays
          ? Yup.date().required('必填').nullable()
          : Yup.date().nullable(),
      },
      ['startDateType_admin', 'purchaseDate', 'shippingDate'],
    );
    return schema;
  };

  const [isFullScreenImgAppear, setIsFullScreenImgAppear] = useState(false);
  const [currentImg, setCurrentImg] = useState(null);

  const toggleFullScreenImage = () => {
    setIsFullScreenImgAppear(!isFullScreenImgAppear);
  };

  // 更換服務人員代碼
  const [isEditStaffCodeModalOpen, setIsEditStaffCodeModalOpen] =
    useState(false);
  const toggleEditStaffCodeModal = () => {
    setIsEditStaffCodeModalOpen(!isEditStaffCodeModalOpen);
  };

  return (
    <Layout
      pageTitle="保固註冊詳細資料"
      items={[
        { isActive: false, page_name: '保固註冊清單', to: '/WarrantyRegister' },
        { isActive: true, page_name: '保固註冊詳細資料' },
      ]}
    >
      <div className={style.layout_flex}>
        <div>
          {info?.isExchangedNew && (
            <Card className="bg-danger mb-3"  style={{ marginRight: '2rem' }}>
              <div className="d-flex flex-column gap-1 px-4 py-3 text-white">
                <div>此產品為折價換新來的</div>
                <div>折價換新維修單號: {info?.repairRegisterNum || '-'}</div>
              </div>
            </Card>
          )}
          {info?.changeRecords?.length > 0 &&
            info.changeRecords.map(record => (
              <div
                className={style['repair-change-records']}
                style={{ marginRight: '2rem' }}
              >
                <div>更換產品序號 ({record.createTime})</div>
                <div>原序號： {record.productSerailNumOld}</div>
                <div>替換序號： {record.productSerailNum}</div>
              </div>
            ))}
          {info && (
            <Card
              className={`${style.search_card} ${style['warranty-card']}`}
              style={{ flex: '4', minWidth: '350px' }}
            >
              <CardBody className={style.card_body}>
                <div>服務人員代碼：</div>
                <div
                  className={style.show_flex}
                  style={{ marginBottom: '1.5rem' }}
                >
                  <label style={{ marginRight: '1rem' }}>
                    {info.employeeid}
                  </label>
                  <FaEdit
                    style={{ color: '#0077ff', cursor: 'pointer' }}
                    onClick={toggleEditStaffCodeModal}
                  />
                </div>
                <div>門市：</div>
                <div>{info.employeeStore}</div>
              </CardBody>
            </Card>
          )}
          {!loading && warrantyRegisterDetailsInfo && (
            <Card
              className={`${style.search_card} ${style['warranty-card']}`}
              style={{ flex: '4', minWidth: '350px' }}
            >
              <CardHeader className="bg-white">歷程資料</CardHeader>
              <CardBody className={style.card_body}>
                {info &&
                  info.records.length > 0 &&
                  info.records.map((record, index) => (
                    <span
                      className={style.data_block}
                      key={index}
                      onClick={() => handleShowRecord(record.id)}
                    >
                      {record.date}
                    </span>
                  ))}
              </CardBody>
            </Card>
          )}
        </div>
        {!loading && warrantyRegisterDetailsInfo && (
          <Card className={style.search_card} style={{ flex: '5' }}>
            <CardHeader className="bg-white">商品保固資料</CardHeader>
            <CardBody className={style.card_body}>
              <h6>
                申請時間：
                {info && moment(new Date(info.createTime)).format('yyyy/MM/DD')}
              </h6>
              <h6>姓名：{info && info.memberName}</h6>
              <h6>
                電話：{'+'}
                {info && info.countryCode} {info && info.phone}
              </h6>
              <h6>Email：{info?.email || '--'}</h6>
              <h6>
                地址：
                {`${info?.countryName || '--'}${info?.cityName || '--'}${
                  info?.townName || '--'
                }${info?.address || '--'}`}
              </h6>
              <h6>保卡：{info && info.isNew ? '新保卡' : '舊保卡'}</h6>
              <h6>
                品牌：
                {warrantyRecord
                  ? warrantyRecord.brandName
                  : info && info.brandName}
              </h6>
              <h6>
                產品型號：
                {warrantyRecord ? warrantyRecord.model : info && info.model}
              </h6>
              <h6>
                產品顏色：
                {warrantyRecord ? warrantyRecord.color : info && info.color}
              </h6>
              <h6>
                產品尺寸：
                {warrantyRecord &&
                warrantyRecord.size &&
                warrantyRecord.size === 'null'
                  ? '--'
                  : info && info.size === 'null'
                  ? '--'
                  : info && info.size}
              </h6>
              <h6>
                購買通路：
                {warrantyRecord
                  ? warrantyRecord.supplierChanneName ||
                    warrantyRecord.purchaseChannelName
                  : (info && info.supplierChanneName) ||
                    (info && info.purchaseChannelName)}
              </h6>
              <h6>
                保卡序號：
                {warrantyRecord
                  ? warrantyRecord.warrantySerialNum
                  : info && info.warrantySerialNum}
              </h6>
              <h6>
                產品序號：
                {warrantyRecord
                  ? warrantyRecord.productSerialNum
                  : info && info.productSerialNum}
              </h6>
              <h6>
                購買日期：
                {warrantyRecord
                  ? warrantyRecord.purchaseDate
                  : info && info.purchaseDate}
              </h6>
              <h6>發票/保卡圖片</h6>
              {warrantyRecord ? (
                <div>
                  {warrantyRecord?.warrantyimgList?.length > 0 &&
                    warrantyRecord.warrantyimgList.map((img, index) => (
                      <img
                        key={index}
                        src={img.imageUrl}
                        alt="warranty_pic"
                        className="mb-3"
                        onClick={() => {
                          setCurrentImg(img.imageUrl);
                          toggleFullScreenImage();
                        }}
                      />
                    ))}
                </div>
              ) : (
                <div>
                  {info?.warrantyimgList?.length > 0 &&
                    info.warrantyimgList.map((img, index) => (
                      <img
                        key={index}
                        src={img.imageUrl}
                        alt="warranty_pic"
                        className="mb-3"
                        onClick={() => {
                          setCurrentImg(img.imageUrl);
                          toggleFullScreenImage();
                        }}
                      />
                    ))}
                </div>
              )}
              <h6>
                維修單號：
                {warrantyRecord
                    ? warrantyRecord.repairRegisterNum
                    : info && info.repairRegisterNum}
              </h6>
            </CardBody>
          </Card>
        )}
      </div>
      <div className={style.layout_flex}>
        {!loading && warrantyRegisterDetailsInfo && (
          <div style={{ flex: '5' }} className="pe-1">
            <Card className={`${style.search_card}`}>
              <CardHeader className="bg-white d-flex align-items-center">
                保固審核
                <div className="flex-grow-1 text-end">
                  <Button
                    color="primary"
                    style={{ backgroundColor: '#3c44b1' }}
                    onClick={toggleModal}
                  >
                    <FaEdit className="mx-2" size={24} />
                    編輯
                  </Button>
                </div>
              </CardHeader>
              <CardBody className={style.card_body}>
                {info?.warrantyDays ? (
                  <div className={style.formGroup}>
                    <h6 style={{ width: '130px' }}>保固起始日</h6>
                    <div>
                      <div style={{ minWidth: '10%' }} className="d-flex mb-3">
                        <input
                          name="startDateType_admin"
                          value="1"
                          type="radio"
                          id="purchase_date"
                          className="me-2"
                          checked={info && info.startDateType_admin == 1}
                          onChange={e => {
                            setStart(e.target.value);
                          }}
                        />
                        <label htmlFor="purchase_date">
                          購買日期：{info?.purchaseDate_admin || '-'}
                        </label>
                      </div>
                      <div style={{ minWidth: '10%' }} className="d-flex mb-3">
                        <input
                          name="startDateType_admin"
                          value="2"
                          type="radio"
                          id="shipping_date"
                          className="me-2"
                          checked={info && info.startDateType_admin == 2}
                          onChange={e => {
                            setStart(e.target.value);
                          }}
                        />
                        <label htmlFor="shipping_date">
                          出貨日期：{info && info.productDate_admin}
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null}
                {info?.warrantyDays ? (
                  <div className={style.formGroup}>
                    <h6 style={{ width: '130px' }}>保固結束日</h6>
                    <div>{info && info.warrantyEndDate}</div>
                  </div>
                ) : null}
                <div className={style.formGroup}>
                  <h6 style={{ width: '130px' }}>審核狀態</h6>
                  <div>{info?.status}</div>
                </div>
                <div className={`mt-5 ${style.formGroup}`}>
                  {info && info.reviewDate_admin && (
                    <small>
                      異動時間：{info.reviewDate_admin} {info.reviewAdmin}
                    </small>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        )}

        {!loading && warrantyRegisterDetailsInfo && (
          <div style={{ flex: '4' }} className="ps-1">
            <Card className={`${style.search_card}`}>
              <CardHeader className="bg-white d-flex align-items-center">
                審核歷程
                <div className="flex-grow-1 text-end">
                  <Button
                    className="btn-link text-primary bg-white"
                    onClick={() => {
                      toggleHistoryModal(true);
                    }}
                  >
                    完整歷程記錄
                  </Button>
                </div>
              </CardHeader>
              <CardBody className={style.card_body}>
                <form onSubmit={handleAddRemark}>
                  <textarea
                    rows="5"
                    className="w-100"
                    onChange={e => setRemark(e.target.value)}
                    value={remark}
                  />
                  <Button
                    type="submit"
                    style={{ backgroundColor: '#3c44b1', color: 'white' }}
                    className="d-inline-flex float-end"
                  >
                    新增歷程
                  </Button>
                </form>
                <div className="mt-5">
                  {info &&
                    info.remarks.map((remark, index) => (
                      <div className="mb-3" key={index}>
                        <small className="text-secondary">{remark.date}</small>
                        <h6>{remark.reason}</h6>
                      </div>
                    ))}
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      <WarrantyDateSettingModal
        toggleModal={toggleModal}
        handleSubmit={handleSubmit}
        getValidationSchema={getValidationSchema}
        modal={modal}
        info={info}
        otherReason={otherReason}
        setOtherReason={setOtherReason}
        addLoading={addLoading}
        submitLoading={submitLoading}
      />
      <WarrantyHistoryRecordModal
        toggleModal={() => {
          toggleHistoryModal(false);
        }}
        reviewRecords={info?.reviewRecords}
        modal={historyModal}
      />
      {/* 左下 */}

      {isFullScreenImgAppear && currentImg && (
        <FullScreenImageModal
          isOpen={isFullScreenImgAppear}
          source={currentImg}
          toggle={toggleFullScreenImage}
        />
      )}
      {isEditStaffCodeModalOpen && info && (
        <EditStaffCodeModal
          isOpen={isEditStaffCodeModalOpen}
          toggle={toggleEditStaffCodeModal}
          onRefresh={() => {
            dispatch(getWarrantyRegisterDetails(id));
          }}
          info={{
            employeeid: info.employeeid,
            id: id,
            records: info.employeeIdRecords,
            type: 1,
          }}
        />
      )}
    </Layout>
  );
};

export default WarrantyRegisterDetails;
