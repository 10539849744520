import {
  DELIVERY_LIST_REQUEST,
  DELIVERY_LIST_SUCCESS,
  DELIVERY_LIST_FAIL,
  DELIVERY_LIST_SAVE_REQUEST,
  DELIVERY_LIST_SAVE_SUCCESS,
  DELIVERY_LIST_SAVE_FAIL,
} from 'redux/constants/data/deliveryConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

export const getDeliveryList = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: DELIVERY_LIST_FAIL,
      method: 'get',
      requestConstant: DELIVERY_LIST_REQUEST,
      successConstant: DELIVERY_LIST_SUCCESS,
      url: '/Backend/DeliveryChargeSetting/GetDeliveryChargeSettingList',
    });
  };
};

export const saveDeliveryList = data => {
  CommonUtil.consoleLog({ anno: 'body', data });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/DeliveryChargeSetting/SaveDeliveryChargeSettingList',
  });
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: DELIVERY_LIST_SAVE_FAIL,
      method: 'post',
      requestConstant: DELIVERY_LIST_SAVE_REQUEST,
      successConstant: DELIVERY_LIST_SAVE_SUCCESS,
      url: '/Backend/DeliveryChargeSetting/SaveDeliveryChargeSettingList',
    });
  };
};
