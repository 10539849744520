import {
  GET_POINT_EVENT_SETTINGS_FAIL,
  GET_POINT_EVENT_SETTINGS_REQUEST,
  GET_POINT_EVENT_SETTINGS_SUCCESS,
  GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS,
  GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL,
  GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS,
} from '../../constants/data/pointSettingConstants';

//get admin list
export const pointEventSettingReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      month: 0,
      quantity: 0,
      status: '',
      type: '',
      year: 0,
    },
    error: null,
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_POINT_EVENT_SETTINGS_REQUEST:
      return { detail: {}, error: null, loading: true };
    case GET_POINT_EVENT_SETTINGS_SUCCESS:
      return { detail: payload, error: null, loading: false };
    case GET_POINT_EVENT_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const specialPointEventSettingReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 0,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST:
      return { error: null, list: {}, loading: true };
    case GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS:
      return { error: null, list: payload, loading: false };
    case GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const specialPointEventSettingDetailReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      month: 0,
      quantity: 0,
      status: '',
      type: '',
      updateTime: '',
      updater: '',
      year: 0,
    },
    error: null,
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true };
    case GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS:
      return { detail: payload, error: null, loading: false };
    case GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
