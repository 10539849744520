import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useCallback } from 'react';
import { FaMapPin } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import {
  setMarkMessage,
  setMessageClosed,
  setUnmarkMessage,
} from 'redux/actions/data/messageActions';
import history from 'utils/createHistory';

import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import { modal } from '../../utils/modal';

const MessageTable = ({
  onToggleRecordsModal,
  onSetCurrentId,
  onSetIds,
  onReload,
  ids,
}) => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(state => state.getMessageList);
  const toggleMarkMessage = useCallback(async message => {
    try {
      if (message.isMarked) {
        await setUnmarkMessage(message.messageId);
      } else {
        await setMarkMessage(message.messageId);
      }
      onReload();
    } catch (e) {
      MessageUtil.alertWanring('更新失敗', e);
    }
  }, []);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>
              <div className={style['radio-btn-layout']}>
                <input
                  type="checkbox"
                  className={style['radio-btn']}
                  onClick={e => {
                    const checked = e.target.checked;
                    onSetIds([]);

                    checked
                      ? onSetIds(
                          list?.datas
                            ?.filter(data => data.messageStatus !== '已結案')
                            .map(data => data.messageId),
                        )
                      : onSetIds([]);
                  }}
                  checked={
                    ids.length ===
                      list?.datas?.filter(
                        data => data.messageStatus !== '已結案',
                      )?.length &&
                    list?.datas?.length !== 0 &&
                    ids.length !== 0
                  }
                  disabled={list?.datas?.length === 0}
                />
                <span className="ml-2">全選</span>
              </div>
            </th>
            <th>
              <div>留言編號</div>
              <div>狀態</div>
            </th>
            <th>提問時間</th>
            <th>
              <div>回覆人</div>
              <div>最後回覆時間</div>
            </th>
            <th>提問內容</th>
            <th>問題分類</th>
            <th>
              <div>會員編號</div>
              <div>姓名</div>
              <div>黑名單</div>
            </th>
            <th>訂單/維修單號</th>
            <th>經手人</th>
            <th>備註</th>
            <th>功能</th>
          </tr>
        </TableHead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {list?.datas?.length > 0 ? (
              list?.datas?.map(data => (
                <tr key={data.messageId}>
                  <td className="text-center">
                    {data.messageStatus !== '已結案' && (
                      <input
                        type="checkbox"
                        className={style['radio-btn']}
                        onChange={e => {
                          const checked = e.target.checked;
                          checked
                            ? onSetIds([...ids, data.messageId])
                            : onSetIds(ids.filter(i => i !== data.messageId));
                        }}
                        checked={ids.find(i => i === data.messageId)}
                      />
                    )}
                  </td>
                  <td
                    className="text-center"
                    style={{
                      background:
                        data.messageStatus !== '已結案' &&
                        data.replyName !== '商店客服'
                          ? '#f7dddd'
                          : '',
                    }}
                  >
                    {/* 留言編號/狀態 */}
                    <div className="d-flex gap-2 justify-content-center">
                      {data.isMarked && <FaMapPin color="red" size="25" />}
                      {data.messageNum}
                    </div>
                    <div>{data.messageStatus}</div>
                  </td>
                  <td className="text-center">
                    {/* 提問時間 */}
                    {data.createTime}
                  </td>
                  <td className="text-center">
                    {/* 回覆人/最後回覆時間 */}
                    <div>{data.replyName}</div>
                    <div>{data.replyTime}</div>
                  </td>
                  <td className="text-center">
                    {/* 提問內容 */}
                    <div>{data.context}</div>
                    <div
                      className={style['link-text']}
                      onClick={() => {
                        onSetCurrentId(data.messageId);
                        onToggleRecordsModal();
                      }}
                    >
                      歷程記錄
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 問題分類 */}
                    <div>{data.cat1Name}</div>
                    <div>{data.cat2Name}</div>
                  </td>
                  <td className="text-center">
                    {/* 會員編號/姓名/黑名單 */}
                    <div className="text-nowrap">
                      0{data?.memberNumber || '--'}
                    </div>
                    <div className="text-nowrap">
                      {data?.memberName?.trim() || '--'}
                    </div>
                    <div className="text-nowrap">
                      {data?.isBlocked ? '是' : '否'}
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 單號 */}
                    <span
                      className={style['link-text']}
                      onClick={() => {
                        if (data.orderNumber) {
                          window.open(
                            `/#/OrderList?page=1&orderNumber=${
                              data.orderNumber
                            }&startTime=${JSON.stringify(
                              data.orderCreateTime,
                            )}`,
                            '_blank',
                          );
                        }

                        if (data.repairRegisterNum) {
                          window.open(
                            `/#/MaintainanceList?page=1&numberType=repairRegisterNum&number="${data.repairRegisterNum}"`,
                            '_blank',
                          );
                        }
                      }}
                    >
                      {data.orderNumber || data.repairRegisterNum}
                    </span>
                  </td>
                  <td>
                    {/*經手人*/}
                    {data.adminName || '-'}
                  </td>
                  <td>
                    {/* 備註 */}
                    {data.note || '-'}
                  </td>

                  <td>
                    <Button
                      color="primary"
                      outline
                      className={'me-2 mb-2 btn-sm'}
                      onClick={() => {
                        toggleMarkMessage(data);
                      }}
                    >
                      {data.isMarked ? '取消標記' : '標記'}特殊客／特殊訊息
                    </Button>
                    {data.messageStatus !== '已結案' && (
                      <div className={style.show_flex}>
                        <Button
                          color="primary"
                          outline
                          className={'me-2 mb-2 btn-sm'}
                          onClick={() => {
                            history.push(`/StaffReply?id=${data.messageId}`);
                          }}
                        >
                          回覆
                        </Button>
                        <Button
                          color="primary"
                          outline
                          className={'me-2 mb-2 btn-sm'}
                          onClick={() => {
                            dispatch(setMessageClosed([data.messageId]));
                          }}
                        >
                          結案
                        </Button>
                      </div>
                    )}
                    <Button
                      color="primary"
                      outline
                      className={'me-2 mb-2 btn-sm'}
                      onClick={() => {
                        modal.showUpdateMessageNoteModal(data).then(() => {
                          onReload();
                        });
                      }}
                    >
                      修改備註
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default MessageTable;
