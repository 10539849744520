export const CREATE_LOGISTICS_SHIPPING_REQUEST =
  'CREATE_LOGISTICS_SHIPPING_REQUEST';
export const CREATE_LOGISTICS_SHIPPING_SUCCESS =
  'CREATE_LOGISTICS_SHIPPING_SUCCESS';
export const CREATE_LOGISTICS_SHIPPING_FAIL = 'CREATE_LOGISTICS_SHIPPING_FAIL';
export const CREATE_LOGISTICS_SHIPPING_RESET =
  'CREATE_LOGISTICS_SHIPPING_RESET';
export const CREATE_LOGISTICS_PRINT_TICKET_REQUEST =
  'CREATE_LOGISTICS_PRINT_TICKET_REQUEST';
export const CREATE_LOGISTICS_PRINT_TICKET_SUCCESS =
  'CREATE_LOGISTICS_PRINT_TICKET_SUCCESS';
export const CREATE_LOGISTICS_PRINT_TICKET_FAIL =
  'CREATE_LOGISTICS_PRINT_TICKET_FAIL';
export const CREATE_LOGISTICS_PRINT_TICKET_RESET =
  'CREATE_LOGISTICS_PRINT_TICKET_RESET';

export const CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST =
  'CREATE_LOGISTICS_CANCEL_LOGISTIC_REQUEST';
export const CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS =
  'CREATE_LOGISTICS_CANCEL_LOGISTIC_SUCCESS';
export const CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL =
  'CREATE_LOGISTICS_CANCEL_LOGISTIC_FAIL';
export const CREATE_LOGISTICS_CANCEL_LOGISTIC_RESET =
  'CREATE_LOGISTICS_CANCEL_LOGISTIC_RESET';

export const CREATE_LOGISTICS_CANCEL_ORDER_REQUEST =
  'CREATE_LOGISTICS_CANCEL_ORDER_REQUEST';
export const CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS =
  'CREATE_LOGISTICS_CANCEL_ORDER_SUCCESS';
export const CREATE_LOGISTICS_CANCEL_ORDER_FAIL =
  'CREATE_LOGISTICS_CANCEL_ORDER_FAIL';
export const CREATE_LOGISTICS_CANCEL_ORDER_RESET =
  'CREATE_LOGISTICS_CANCEL_ORDER_RESET';
