import {
  HOME_DELIVERY_SHIPPING_REQUEST,
  HOME_DELIVERY_SHIPPING_SUCCESS,
  HOME_DELIVERY_SHIPPING_FAIL,
  HOME_DELIVERY_SHIPPING_RESET,
  CHK_HOME_DELIVERY_REQUEST,
  CHK_HOME_DELIVERY_SUCCESS,
  CHK_HOME_DELIVERY_FAIL,
  CHK_HOME_DELIVERY_RESET,
  CHK_STORE_REQUEST,
  CHK_STORE_SUCCESS,
  CHK_STORE_FAIL,
  CHK_STORE_RESET,
  STORE_SHIPPING_REQUEST,
  STORE_SHIPPING_SUCCESS,
  STORE_SHIPPING_FAIL,
  STORE_SHIPPING_RESET,
  STORE_LOGISTIC_REQUEST,
  STORE_LOGISTIC_SUCCESS,
  STORE_LOGISTIC_FAIL,
  STORE_LOGISTIC_RESET,
  STORE_ARRIVED_REQUEST,
  STORE_ARRIVED_SUCCESS,
  STORE_ARRIVED_FAIL,
  STORE_ARRIVED_RESET,
  STORE_PICKED_UP_REQUEST,
  STORE_PICKED_UP_SUCCESS,
  STORE_PICKED_UP_FAIL,
  STORE_PICKED_UP_RESET,
  STORE_CANCEL_REQUEST,
  STORE_CANCEL_SUCCESS,
  STORE_CANCEL_FAIL,
  STORE_CANCEL_RESET,
  UUOPTION_SUCCESS,
  SHIPPING_PRINT_INFO_REQUEST,
  SHIPPING_PRINT_INFO_SUCCESS,
  SHIPPING_PRINT_INFO_FAIL,
  SHIPPING_PRINT_INFO_RESET,
  HOME_DELIVERY_CANCEL_IDLE,
  HOME_DELIVERY_CANCEL_REQUEST,
  HOME_DELIVERY_CANCEL_SUCCESS,
  HOME_DELIVERY_CANCEL_FAIL,
} from 'redux/constants/data/orderShippingConstants';
export const homeDeliveryShippingReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case HOME_DELIVERY_SHIPPING_REQUEST:
      return { loading: true };
    case HOME_DELIVERY_SHIPPING_SUCCESS:
      return { loading: false, success: true };
    case HOME_DELIVERY_SHIPPING_FAIL:
      return { error: payload, loading: false };
    case HOME_DELIVERY_SHIPPING_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const chkHomeDeliveryReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHK_HOME_DELIVERY_REQUEST:
      return { loading: true };
    case CHK_HOME_DELIVERY_SUCCESS:
      return { loading: false, success: true };
    case CHK_HOME_DELIVERY_FAIL:
      return { error: payload, loading: false };
    case CHK_HOME_DELIVERY_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const chkStoreReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHK_STORE_REQUEST:
      return { loading: true };
    case CHK_STORE_SUCCESS:
      return { loading: false, success: true };
    case CHK_STORE_FAIL:
      return { error: payload, loading: false };
    case CHK_STORE_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const storeShippingReducer = (state = {
  loading:false
}, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_SHIPPING_REQUEST:
      return { loading: true };
    case STORE_SHIPPING_SUCCESS:
      return { loading: false, success: true };
    case STORE_SHIPPING_FAIL:
      return { error: payload, loading: false };
    case STORE_SHIPPING_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const storeLogisticReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_LOGISTIC_REQUEST:
      return { loading: true };
    case STORE_LOGISTIC_SUCCESS:
      return { loading: false, success: true };
    case STORE_LOGISTIC_FAIL:
      return { error: payload, loading: false };
    case STORE_LOGISTIC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const storeArrivedReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_ARRIVED_REQUEST:
      return { loading: true };
    case STORE_ARRIVED_SUCCESS:
      return { loading: false, success: true };
    case STORE_ARRIVED_FAIL:
      return { error: payload, loading: false };
    case STORE_ARRIVED_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const storePickedUpReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_PICKED_UP_REQUEST:
      return { loading: true };
    case STORE_PICKED_UP_SUCCESS:
      return { loading: false, success: true };
    case STORE_PICKED_UP_FAIL:
      return { error: payload, loading: false };
    case STORE_PICKED_UP_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const shippingPrintInfoReducer = (
  state = { shippingPrintInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SHIPPING_PRINT_INFO_REQUEST:
      return { loading: true };
    case SHIPPING_PRINT_INFO_SUCCESS:
      return {
        loading: false,
        shippingPrintInfo: payload || [],
        success: true,
      };
    case SHIPPING_PRINT_INFO_FAIL:
      return { error: payload, loading: false };
    case SHIPPING_PRINT_INFO_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const uuoptionsReducer = (state = { uuoptionsInfo: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case UUOPTION_SUCCESS:
      return { uuoptionsInfo: payload };
    default:
      return state;
  }
};

export const storeCancelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case STORE_CANCEL_REQUEST:
      return { loading: true };
    case STORE_CANCEL_SUCCESS:
      return { loading: false, success: true };
    case STORE_CANCEL_FAIL:
      return { error: payload, loading: false };
    case STORE_CANCEL_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const homeDeliveryCancelReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case HOME_DELIVERY_CANCEL_IDLE:
      return { error: null, loading: false, success: false };
    case HOME_DELIVERY_CANCEL_REQUEST:
      return { ...state, loading: true };
    case HOME_DELIVERY_CANCEL_SUCCESS:
      return { ...state, loading: false, success: true };
    case HOME_DELIVERY_CANCEL_FAIL:
      return { ...state, error: payload, lodaing: false };
    default:
      return state;
  }
};
