import {
  DOWNLOAD_TEMPLATE_FILE_FAIL,
  DOWNLOAD_TEMPLATE_FILE_IDLE,
  DOWNLOAD_TEMPLATE_FILE_REQUEST,
  DOWNLOAD_TEMPLATE_FILE_SUCCESS,
  GET_BLOCK_LIST_FAIL,
  GET_BLOCK_LIST_REQUEST,
  GET_BLOCK_LIST_SUCCESS,
  SAVE_BLOCK_LIST_FAIL,
  SAVE_BLOCK_LIST_IDLE,
  SAVE_BLOCK_LIST_REQUEST,
  SAVE_BLOCK_LIST_SUCCESS,
  UPLOAD_BLOCK_LIST_FAIL,
  UPLOAD_BLOCK_LIST_IDLE,
  UPLOAD_BLOCK_LIST_REQUEST,
  UPLOAD_BLOCK_LIST_SUCCESS,
} from 'redux/constants/data/blockListConstants';

export const getBlockListReducer = (
  state = { blockList: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BLOCK_LIST_REQUEST:
      return { blockList: {}, error: null, loading: true, success: false };
    case GET_BLOCK_LIST_SUCCESS:
      return { ...state, blockList: payload, loading: false };
    case GET_BLOCK_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const saveBlockListReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SAVE_BLOCK_LIST_IDLE:
      return { error: null, loading: false, success: false };
    case SAVE_BLOCK_LIST_REQUEST:
      return { ...state, loading: true };
    case SAVE_BLOCK_LIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case SAVE_BLOCK_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const downloadTemplateFileReducer = (
  state = { error: null, loading: false, success: false, url: '' },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DOWNLOAD_TEMPLATE_FILE_IDLE:
      return { error: null, loading: false, success: false, url: '' };
    case DOWNLOAD_TEMPLATE_FILE_REQUEST:
      return { ...state, loading: true };
    case DOWNLOAD_TEMPLATE_FILE_SUCCESS:
      return { ...state, loading: false, success: true, url: payload };
    case DOWNLOAD_TEMPLATE_FILE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const uploadBlockListReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_BLOCK_LIST_IDLE:
      return { error: null, loading: false, success: false };
    case UPLOAD_BLOCK_LIST_REQUEST:
      return { ...state, loading: true };
    case UPLOAD_BLOCK_LIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPLOAD_BLOCK_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
