import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { saveBlockList } from 'redux/actions/data/blockListActions';
import MessageUtil from 'utils/MessageUtil';
import request from 'utils/useAxios';
import * as Yup from 'yup';

import { baseURL, prefix_api_path } from '../../redux/actions/data/api_config';
import style from '../../styles/layout.module.css';

const EditModal = ({ isOpen, toggle, data, isEdit }) => {
  const dispatch = useDispatch();
  const [isNumberError, setIsNumberError] = useState(false);
  const { loading } = useSelector(state => state.saveBlockList);
  const { values, setFieldValue, handleChange, handleSubmit, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        availablePayWay: isEdit ? data.payWays : [],
        isEnable: isEdit ? (data.status == 1 ? true : false) : null,
        memberNumber: isEdit ? 0 + data.phone : '',
        reason: isEdit ? data.reason : '',
        remark: isEdit ? data.remark : '',
      },
      onSubmit: () => {
        if (isNumberError) {
          MessageUtil.alertWanring('會員編號不存在');
          return;
        }

        let temp = Array.from(values.memberNumber);
        let phone = '';
        if (temp[0] == 0) {
          temp.shift();
        }
        phone = temp.join('');

        const body = {
          payWays: values.availablePayWay,
          phone: phone,
          reason: values.reason,
          remark: values.remark,
          status: values.isEnable ? 1 : 2,
        };
        dispatch(saveBlockList(body));
      },
      validationSchema: Yup.object().shape({
        isEnable: Yup.boolean().nullable().required('必填'),
        memberNumber: Yup.number()
          .typeError('請輸入數字')
          .nullable()
          .required('必填'),
        reason: Yup.string().nullable().required('必填'),
      }),
    });

  const isMemberPhoneNumberExisted = async number => {
    let temp = Array.from(number);
    let phone = '';
    if (temp[0] == 0) {
      temp.shift();
    }
    phone = temp.join('');
    const { result } = await request({
      method: 'get',
      url: `${baseURL}${prefix_api_path}/Backend/MemberTransBlackList/chkPhoneIsExist?phone=${phone}`,
    });

    if (!result) {
      MessageUtil.alertWanring('會員編號不存在');
      setIsNumberError(true);
    } else {
      setIsNumberError(false);
    }
  };

  return (
    <Modal isOpen={isOpen} zIndex={2000} size="xl" centered>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        {`${isEdit ? '編輯' : '新增'}黑名單`}
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '400px' }}>
        <div className={style.formGroup}>
          <label className="w150 required">會員編號</label>
          <Input
            id="memberNumber"
            onChange={handleChange}
            value={values.memberNumber}
            invalid={errors.memberNumber && touched.memberNumber}
            onBlur={e => {
              isMemberPhoneNumberExisted(e.target.value);
            }}
          />
        </div>
        <div className={style.formGroup}>
          <label className="w150 required">黑名單原因</label>
          <Input
            type="select"
            style={{ margin: 0 }}
            id="reason"
            onChange={handleChange}
            value={values.reason}
            invalid={errors.reason && touched.reason}
          >
            <option value="">請選擇</option>
            <option value="多次未取貨">多次未取貨</option>
            <option value="多次退貨">多次退貨</option>
            <option value="交易爭議">交易爭議</option>
            <option value="其他">其他</option>
          </Input>
        </div>
        <div className={style.formGroup}>
          <label className="w150">可使用付款方式</label>
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availablePayWay.includes(1)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [
                      ...values.availablePayWay,
                      1,
                    ])
                  : setFieldValue(
                      'availablePayWay',
                      values.availablePayWay.filter(way => way != 1),
                    );
              }}
            />
            <span className="ml-2">信用卡</span>
          </div>
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availablePayWay.includes(2)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [
                      ...values.availablePayWay,
                      2,
                    ])
                  : setFieldValue(
                      'availablePayWay',
                      values.availablePayWay.filter(way => way != 2),
                    );
              }}
            />
            <span className="ml-2">ATM轉帳</span>
          </div>
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availablePayWay.includes(3)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [
                      ...values.availablePayWay,
                      3,
                    ])
                  : setFieldValue(
                      'availablePayWay',
                      values.availablePayWay.filter(way => way != 3),
                    );
              }}
            />
            <span className="ml-2">超商取貨付款</span>
          </div>
          {/* <div className={style["radio-btn-layout"]} style={{ marginRight: '1.5rem' }}>
            <input
              type="checkbox"
              className={style["radio-btn"]}
              checked={values.availablePayWay.includes("全家取貨付款")}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [...values.availablePayWay, "全家取貨付款"])
                  : setFieldValue('availablePayWay', values.availablePayWay.filter(way => way !== '全家取貨付款'));
              }}
            />
            <span className='ml-2'>全家取貨付款</span>
          </div>
          <div className={style["radio-btn-layout"]} style={{ marginRight: '1.5rem' }}>
            <input
              type="checkbox"
              className={style["radio-btn"]}
              checked={values.availablePayWay.includes("7-11取貨付款")}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [...values.availablePayWay, "7-11取貨付款"])
                  : setFieldValue('availablePayWay', values.availablePayWay.filter(way => way !== '7-11取貨付款'));
              }}
            />
            <span className='ml-2'>7-11取貨付款</span>
          </div> */}
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availablePayWay.includes(4)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [
                      ...values.availablePayWay,
                      4,
                    ])
                  : setFieldValue(
                      'availablePayWay',
                      values.availablePayWay.filter(way => way != 4),
                    );
              }}
            />
            <span className="ml-2">Apple Pay</span>
          </div>
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availablePayWay.includes(5)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availablePayWay', [
                      ...values.availablePayWay,
                      5,
                    ])
                  : setFieldValue(
                      'availablePayWay',
                      values.availablePayWay.filter(way => way != 5),
                    );
              }}
            />
            <span className="ml-2">Line Pay</span>
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="w150 required">
            黑名單狀態
            {errors.isEnable && touched.isEnable && (
              <span className="text-danger ml-2">{errors.isEnable}</span>
            )}
          </label>
          <div
            className={style['radio-btn-layout']}
            style={{ marginRight: '1.5rem' }}
          >
            <input
              type="radio"
              className={style['radio-btn']}
              checked={values.isEnable === true}
              onChange={() => {
                setFieldValue('isEnable', true);
              }}
            />
            <span className="ml-2">生效</span>
          </div>
          <div className={style['radio-btn-layout']}>
            <input
              type="radio"
              className={style['radio-btn']}
              checked={values.isEnable === false}
              onChange={() => {
                setFieldValue('isEnable', false);
              }}
            />
            <span className="ml-2">已失效</span>
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="w150">黑名單備註</label>
          <Input
            id="remark"
            onChange={handleChange}
            value={values.remark}
            maxLength={100}
          />
          <small
            className="ml-2"
            style={{ whiteSpace: 'nowrap' }}
          >{`(${values.remark.length}/100)`}</small>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className={`me-3 ${style.modal_button_cancel}`}
          onClick={toggle}
        >
          取消
        </Button>

        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditModal;
