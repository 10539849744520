import Layout from 'components/Layout';
import SEOSettingsConfig from 'components/SEOSettingsConfig';
import Tabs from 'components/Tabs';
import React, { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import { Alert, TabPane } from 'reactstrap';
import { getHideModelDetail } from 'redux/actions/data/hideModelActions';
import { getModelProductOptions } from 'redux/actions/data/modelActions';
import { getSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';

import HideModelDesc from '../components/HideModel/HideModelDesc';
import HideModelOrder from '../components/HideModel/HideModelOrder';
import HideModelSetting from '../components/HideModel/HideModelSetting';
import HideModelSpec from '../components/HideModel/HideModelSpec';

const TABS = [
  {
    name: '商品設定',
    tab: 1,
  },
  {
    name: '商品介紹',
    tab: 2,
  },
  {
    name: '商品規格',
    tab: 3,
  },
  {
    name: '付款配送方式',
    tab: 4,
  },
  {
    name: 'seo設定',
    tab: 5,
  },
];

const HideModelDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.hideModelDetail);
  const location = useLocation();

  useEffect(() => {
    dispatch(getModelProductOptions());
    id && dispatch(getHideModelDetail(id));
  }, [id , location?.pathname]);

  useEffect(() => {
    dispatch(getSeoSettingsDetail({ sourceId: id, type: 13 }));
  }, [location?.pathname]);

  return (
    <Layout
      pageTitle="隱形賣場設定"
      items={[
        { isActive: false, page_name: '隱形賣場', to: '/HideModel' },
        { isActive: true, page_name: '隱形賣場設定' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          銷售時間開始後，不得再新增、刪除商品，且不能異動銷售起始日。
        </div>
      </Alert>
      {!loading && (
        <Tabs tabs={TABS}>
          <TabPane tabId={1}>
            <HideModelSetting />
          </TabPane>
          <TabPane tabId={2}>
            <HideModelDesc />
          </TabPane>
          <TabPane tabId={3}>
            <HideModelSpec />
          </TabPane>
          <TabPane tabId={4}>
            <HideModelOrder />
          </TabPane>
          <TabPane tabId={5}>
            <SEOSettingsConfig
              info={{ sourceId: Number(id), type: 13, typeName: '隱型賣場' }}
            />
          </TabPane>
        </Tabs>
      )}
    </Layout>
  );
};
export default HideModelDetail;
