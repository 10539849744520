import Layout from 'components/Layout';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input } from 'reactstrap';
import {
  getAutoReplyMessage,
  setAutoReplyMessage,
} from 'redux/actions/data/messageActions';
import { SET_AUTO_REPLY_MESSAGE_IDLE } from 'redux/constants/data/MessageConstant';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';


import style from '../styles/layout.module.css';

const AutoReply = () => {
  const { message } = useSelector(state => state.getAutoReplyMessage);
  const {
    loading: setLoading,
    success: setSuccess,
    error: setError,
  } = useSelector(state => state.setAutoReplyMessage);
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: message?.content || '',
    },
    onSubmit: () => {
      dispatch(setAutoReplyMessage(values));
    },
  });

  useEffect(() => {
    dispatch(getAutoReplyMessage());
  }, []);

  useEffect(() => {
    if (message) {
      CommonUtil.consoleLog({ anno: 'response', data: message });
    }
  }, [message]);

  useEffect(() => {
    if (setSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getAutoReplyMessage());
      dispatch({ type: SET_AUTO_REPLY_MESSAGE_IDLE });
    }

    if (setError) {
      MessageUtil.alertWanring('儲存失敗', setError);
      dispatch({ type: SET_AUTO_REPLY_MESSAGE_IDLE });
    }
  }, [setSuccess, setLoading]);

  return (
    <Layout
      pageTitle="自動回覆訊息"
      items={[
        { isActive: true, page_name: '訊息管理' },
        { isActive: false, page_name: '自動回覆訊息' },
      ]}
    >
      <Card className={style.card}>
        <label className="mb-2">訊息內容</label>
        <Input
          type="textarea"
          style={{ height: '180px' }}
          className="mb-4"
          value={values.content}
          id="content"
          onChange={handleChange}
        />
        <label>{`異動時間：${message?.updateTime} ${message?.updater}`}</label>
        <hr></hr>
        <div style={{ margin: 'auto' }}>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={handleSubmit}
            value={values.content}
            disabled={setLoading}
          >
            儲存
          </Button>
        </div>
      </Card>
    </Layout>
  );
};

export default AutoReply;
