import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';

import { getBrandDetails } from '../redux/actions/data/brandActions';
import history from '../utils/createHistory';

const BrandPreview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    loading,
    settingData: brandInfo,
    error,
  } = useSelector(state => state.brandDetails);

  useEffect(() => {
    dispatch(getBrandDetails(id));
  }, []);

  const [browser, setBrowser] = useState('PC');

  useEffect(() => {
    if (window.innerWidth < 767) {
      setBrowser('mobile');
    }
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      {!loading && (
        <div className="preview">
          <Button
            className="preview_button_cancel btn btn-light"
            onClick={handleGoBack}
          >
            取消預覽
          </Button>
          <div className="hero brand-hero">
            <img
              src={brandInfo.bannerUrl}
              alt={brandInfo.name}
              className="mi-desktop"
            />
            <img
              src={brandInfo.bannerUrl_mobile}
              alt={brandInfo.name}
              className="mi-mobile"
            />
          </div>

          <main>
            <div
              className={
                brandInfo.introColor === 'white'
                  ? 'brand-intro-wrap text-white'
                  : 'brand-intro-wrap text-black'
              }
              style={{
                background: brandInfo.bgColor_bottom,
              }}
            >
              <svg
                className="brand-bg-front"
                style={{
                  fill: brandInfo.bgColor_top,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1920 1080"
              >
                <polygon points="1920 415.52 1062 1080 0 1080 0 0 1920 0 1920 415.52" />
              </svg>
              <div
                className="brand-intro clearfix"
                style={
                  browser === 'mobile'
                    ? {
                        background: brandInfo.bgColor_top,
                      }
                    : {}
                }
              >
                <div className="brand-content">
                  <div
                    className={
                      brandInfo.logoColor === 'white'
                        ? 'brand-img svg-mask bg-white'
                        : 'brand-img svg-mask bg-black'
                    }
                    style={{
                      WebkitMaskImage: `url("${  brandInfo.logoUrl  }")`,
                      maskImage: `url("${  brandInfo.logoUrl  }")`,
                    }}
                  ></div>
                  <p>{brandInfo.introdution}</p>
                </div>
                <img
                  className="brand-intro-imgB"
                  src={brandInfo.prdImg_left_url}
                  alt=""
                />
                <img
                  className="brand-intro-imgS "
                  src={brandInfo.prdImg_right_url}
                  alt=""
                />

                <div
                  className="brand-bg-m"
                  style={{
                    borderBottomColor: brandInfo.bgColor_bottom,
                  }}
                ></div>
              </div>
            </div>
          </main>
        </div>
      )}
    </>
  );
};

export default BrandPreview;
