import React, { useState } from 'react';
import { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { Check, UploadCloud, X } from 'react-feather';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaTimes } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { getProductPics } from '../redux/actions/data/productActions';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';

const UploadFileBox = props => {
  const dispatch = useDispatch();
  const imageFileTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const svgFileTypes = ['image/svg+xml'];
  const pdfFileTypes = ['application/pdf'];
  const videoFileTypes = ['video/mp4'];

  /***************  props 內容 ***************/
  // console.log(props)
  const {
    setFormFieldValue,
    formFieldName,
    currentFileFieldName,
    isDelete,
    isUpload,
    isDragNDrop,
    product_total_upload,
    svgBackground,
    thumbSize = 'auto',
    disabled = false,
    onThumbsChange,
  } = props;
  const { initFiles } = props;
  const {
    uplodModel,
    displayFileWidth,
    displayFileHeight,
    displayFileSize,
    isMultipleUpload,
  } = props;
  const { limitSetting } = props;

  /*************** 處理 及紀錄 Dropzone套件 相關  ***************/
  const [formFiles, setFormFiles] = useState([]);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [acceptType, setAcceptType] = useState('*/*');
  const [displayAcceptType, setDisplayAcceptTypes] = useState('');
  const [thumbs, setThumbs] = useState([...initFiles]);
  const [acceptedImages, setAcceptedImages] = useState([]);
  const [error, setError] = useState('');

  const fileValidator = file => {
    return null;
  };

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    accept: acceptType,
    getFilesFromEvent: async event => {
      // console.log('getFilesFromEvent')
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];

      if (files[0]?.type !== 'video/mp4') {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          const promise = new Promise((resolve, reject) => {
            file.id = CommonUtil.getNewID();

            if (
              imageFileTypes.filter(data => {
                return data === file.type;
              }).length > 0
            ) {
              const image = new Image();
              let url = '';
              image.onload = function () {
                file.width = image.width;
                file.height = image.height;
                resolve(file);
              };
              url = URL.createObjectURL(file);
              image.src = url;
            }

            if (
              svgFileTypes.filter(data => {
                return data === file.type;
              }).length > 0
            ) {
              const image = new Image();
              let url = '';
              image.onload = function () {
                file.width = image.width;
                file.height = image.height;
                resolve(file);
                console.log('svg accepted');
              };
              url = URL.createObjectURL(file);
              image.src = url;
            }

            if (pdfFileTypes.includes(files[0].type)) {
              console.log('LLLLLLLLLLL');
              let reader = new FileReader();
              let url = '';
              reader.readAsDataURL(files[0]);
              reader.onload = e => {
                console.log(e.target);
                resolve(file);
                console.log('PDF accepted');
              };
              url = URL.createObjectURL(file);
              file.src = url;
            }
          });
          promises.push(promise);
        }
      } else {
        files[0].id = CommonUtil.getNewID();
        promises.push(files[0]);
      }

      return await Promise.all(promises);
    },
    multiple: isMultipleUpload,
    onDrop: acceptedFiles => {
      console.log('onDrop');

      const fileByte = Number(displayFileSize.split('M')[0]) * 1024 * 1024;

      if (acceptedFiles[0]?.size > fileByte) {
        setError('檔案大小不符限制');
        return;
      } else if (
        acceptedFiles[0]?.width < displayFileWidth &&
        acceptedFiles[0]?.height < displayFileHeight
      ) {
        setError('檔案尺寸不符限制');
        return;
      } else {
        setError('');
      }

      let newCurrentFiles = acceptedFiles.map(file =>
        Object.assign(
          {},
          {
            id: file.id ?? CommonUtil.getNewID(),
            name: file.name,
            url: URL.createObjectURL(file),
          },
        ),
      );

      if (setFormFieldValue) {
        setFormFieldValue(formFieldName, acceptedFiles);
        setFormFieldValue(currentFileFieldName, newCurrentFiles);
      }

      if (isMultipleUpload) {
        setAcceptedImages([...acceptedImages, ...acceptedFiles]);
        setFormFieldValue(formFieldName, [...acceptedImages, ...acceptedFiles]);

        setThumbs([...newCurrentFiles, ...thumbs]); //****
        setFormFieldValue('product_total_upload', [
          ...newCurrentFiles,
          ...thumbs,
        ]);
        setEditThumbSort([...newCurrentFiles, ...thumbs]); //*
        setFormFieldValue(currentFileFieldName, [
          ...newCurrentFiles,
          ...thumbs,
        ]);
      } else {
        setThumbs(newCurrentFiles); //****
        setFormFieldValue(currentFileFieldName, newCurrentFiles);
      }
    },
    onDropAccepted: (acceptedFiles, event) => {
      console.log('onDropAccepted');
      if (setFormFieldValue) {
        setFormFieldValue(isUpload, true);
      }
    },
    onDropRejected: (fileRejections, event) => {
      console.log('onDropRejected');
    },
    validator: file => {
      console.log('validator validator validator');

      console.log(acceptType);
      console.log(file);

      props.activityImg && setFormFieldValue(props.activityImg, file);

      //--- 是圖片才需要判斷長寬
      if (imageFileTypes.indexOf(acceptType) > -1) {
        if (limitSetting.width && limitSetting.width !== file.width) {
          return {
            code: 'small-width',
            message: 'Image width must be greater ',
          };
        }

        if (limitSetting.height && limitSetting.height !== file.height) {
          return {
            code: 'small-width',
            message: 'Image width must be greater ',
          };
        }
      }

      return null;
    },
  });

  const removeThumbs = file_id => {
    console.log(`removeThumbs =>${file_id}`);
    // console.log(currentFiles)
    dispatch(getProductPics(file_id));
    let newCurrentFiles = currentFiles.filter(file => file.id !== file_id);
    console.log(newCurrentFiles);
    setThumbs(newCurrentFiles);
    if (setFormFieldValue) {
      setFormFieldValue(
        formFieldName,
        acceptedFiles.filter(file => file.id !== file_id),
      );
      setFormFieldValue(currentFileFieldName, newCurrentFiles);
    }
    let newThumbs = thumbs.filter(file => file.id !== file_id);

    // console.log(newThumbs)
    setFormFieldValue(currentFileFieldName, newThumbs);
    setFormFieldValue(isDelete, 'isDelete');
    setThumbs(newThumbs);
    setEditThumbSort(newThumbs); //*排序專用的刪除
  };

  // init
  useEffect(() => {
    switch (uplodModel) {
      case 'images':
        setAcceptType('image/jpeg,image/png,image/gif');
        setDisplayAcceptTypes('jpg、png、gif');
        break;
      case 'svg':
        setAcceptType('image/svg+xml');
        setDisplayAcceptTypes('svg');
        break;
      case 'video':
        setAcceptType('video/mp4');
        setDisplayAcceptTypes('mp4');
        break;
      case 'all':
        setAcceptType('image/jpeg,image/png,image/gif,video/mp4');
        setDisplayAcceptTypes('jpg、png、gif、mp4');
        break;
      case 'pdf':
        setAcceptType('application/pdf');
        setDisplayAcceptTypes('pdf');
        break;
      default:
        break;
    }

    setThumbs(initFiles); //*******
    if (isMultipleUpload) {
      setEditThumbSort(initFiles);
      setFormFieldValue(currentFileFieldName, editThumbSort);
    }
    if (setFormFieldValue) {
      setFormFieldValue(currentFileFieldName, initFiles);
    }
  }, [initFiles]);

  /**** drag n drop  ****/
  const [editThumbSort, setEditThumbSort] = useState([]);

  const handleOnDragEnd = result => {
    // console.log(result)
    const items = Array.from(editThumbSort);
    console.log(items);

    const [newOrderItem] = items.splice(result.source.index, 1);
    console.log(newOrderItem);

    items.splice(result?.destination?.index ?? 0, 0, newOrderItem);

    setEditThumbSort(items);

    const sortedThumbs = items.map((item, index) => {
      return { ...item, sort: index + 1 };
    });

    setFormFieldValue(currentFileFieldName, sortedThumbs);
    setFormFieldValue(isDragNDrop, true);
  };

  useEffect(() => {
    onThumbsChange && onThumbsChange(thumbs);
  }, [thumbs]);
  return (
    <>
      <div className="font-size-xs">
        檔案類型：{displayAcceptType}
        <br />
        {displayFileWidth && displayFileHeight && (
          <span>
            檔案尺寸：(寬){displayFileWidth}
            {displayFileWidth === '不限' ? '' : 'px'} x (高){displayFileHeight}
            {displayFileHeight === '不限' ? '' : 'px'}
          </span>
        )}
        <br />
        {displayFileSize && <span>檔案大小：{displayFileSize}</span>}
      </div>

      <div className="dropzone-box">
        {!disabled && (
          <div className="dropzone w200">
            <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
              <input {...getInputProps()} />
              <div className="dropzone-inner-wrapper">
                {isDragAccept && (
                  <div>
                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-success shadow-success-sm rounded-circle text-white">
                      <Check className="d-50" />
                    </div>
                    <div className="font-size-sm text-success">
                      All files will be uploaded!
                    </div>
                  </div>
                )}

                {isDragReject && (
                  <div>
                    <div className="d-100 btn-icon mb-3 hover-scale-lg bg-danger shadow-danger-sm rounded-circle text-white">
                      <X className="d-50" />
                    </div>
                    <div className="font-size-sm text-danger">
                      Some files will be rejected!
                    </div>
                  </div>
                )}

                <div className={isDragActive ? 'drag_active' : ''}>
                  <div className="dropzone-icon">
                    <UploadCloud size={50} />
                  </div>
                  <div className="font-size-sm">拖拉檔案到這邊</div>
                </div>

                <small className="py-2 text-black-50">or</small>
                <div>
                  <Button
                    type="button"
                    color="primary"
                    className={style.upload_button}
                  >
                    <span className="px-2">選擇檔案</span>
                  </Button>
                </div>
              </div>
            </div>
            <span style={{ color: 'red' }}>{error && error}</span>
          </div>
        )}
        <div
          className="card-footer p-0"
          style={{ maxWidth: thumbSize === 'auto' ? '250px' : 'none' }}
        >
          {thumbs.map((thumb, index) =>
            uplodModel === 'images' ||
            (thumb?.name || '').indexOf('mp4') === -1 ? (
              !isMultipleUpload && (
                <div
                  key={thumb.id}
                  className="position-relative p-2 thumbs-img"
                  style={
                    thumb.url?.substr(-3, 3) === 'mp4'
                      ? { display: 'none' }
                      : {}
                  }
                >
                  <img
                    className="img-fluid img-fit-container rounded-sm"
                    src={thumb.url}
                    alt={thumb.name}
                    width={thumbSize}
                  />
                  {!disabled && (
                    <button
                      type="button"
                      className="ic-img-delete"
                      onClick={() => {
                        removeThumbs(thumb.id);
                      }}
                    >
                      <FaTimes />
                    </button>
                  )}
                </div>
              )
            ) : uplodModel === 'svg' ? (
              <div key={thumb.id} className="position-relative p-2 thumbs-img">
                <img
                  className="img-fluid img-fit-container rounded-sm"
                  src={thumb.url}
                  alt={thumb.name}
                  width={thumbSize}
                  style={{
                    backgroundColor: `${
                      svgBackground === 'none' ? 'transparent' : 'black'
                    }`,
                    maxWidth: '250px',
                  }}
                />
                <button
                  type="button"
                  className="ic-img-delete"
                  onClick={() => {
                    removeThumbs(thumb.id);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ) : uplodModel === 'pdf' ? (
              <div key={thumb.id} className="position-relative p-2 thumbs-img">
                <div className="d-flex">
                  <AiOutlineFileText color="black" size={25} />{' '}
                  <p className="ms-2">{thumbs[0].name}</p>
                </div>
              </div>
            ) : (
              <div key={thumb.id} className="p-2 thumbs-img">
                <video
                  className="img-fluid img-fit-container rounded-sm"
                  width={thumbSize}
                  controls
                >
                  <source src={thumb.url} type="video/mp4" />
                </video>
                <button
                  type="button"
                  className="ic-img-delete"
                  onClick={() => {
                    console.log(thumb);
                    removeThumbs(thumb.id);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ),
          )}

          {uplodModel === 'images' && isMultipleUpload && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable
                droppableId="brands"
                direction={thumbSize === 'auto' ? 'vertical' : 'horizontal'}
              >
                {provided => (
                  <ul
                    className="drag_list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={
                      thumbSize === 'auto'
                        ? {}
                        : {
                            display: 'inline-flex',
                            flexWrap: 'wrap',
                            gap: '0.8rem',
                          }
                    }
                  >
                    {editThumbSort.map(({ id, name, url }, index) => (
                      <Draggable
                        key={`${name}_${url}`}
                        draggableId={id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            className={
                              snapshot.isDragging
                                ? 'dragging position-relative p-2 thumbs-img'
                                : 'position-relative p-2 thumbs-img'
                            }
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            style={{
                              width: thumbSize,
                              ...provided.draggableProps.style,
                            }}
                          >
                            <img
                              className="img-fluid img-fit-container rounded-sm"
                              src={url}
                              alt={name}
                              width={thumbSize}
                            />
                            <button
                              type="button"
                              className="ic-img-delete"
                              onClick={() => {
                                removeThumbs(id);
                              }}
                            >
                              <FaTimes />
                            </button>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </>
  );
};

UploadFileBox.defaultProps = {
  currentFileFieldName: 'currentFile', //紀錄初始的檔案資訊,
  displayFileHeight: 1080,
  displayFileSize: '11MB',
  displayFileWidth: 1920, // ex: (寬)1920px x (高)1080px...
  formFieldName: 'files',
  initFiles: [], // { id:"{guid}" url:"",name:"" }
  isMultipleUpload: false,
  setFormFieldValue: null, // function for fromilk，讓formilk sumbit時可接到files的內容，預設是抓不到
  thumbSize: 'auto',
  uplodModel: '', // ex: images / video / svg ，empty is all
};

export default UploadFileBox;
