import {
  ORDER_INVOICE_LIST_REQUEST,
  ORDER_INVOICE_LIST_SUCCESS,
  ORDER_INVOICE_LIST_FAIL,
  ORDER_INVOICE_DETAIL_REQUEST,
  ORDER_INVOICE_DETAIL_SUCCESS,
  ORDER_INVOICE_DETAIL_FAIL,
} from 'redux/constants/data/orderInvoiceConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';
export const getOrderInvoiceList = data => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/OrderInvoice/list' });
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_INVOICE_LIST_FAIL,
      method: 'post',
      requestConstant: ORDER_INVOICE_LIST_REQUEST,
      successConstant: ORDER_INVOICE_LIST_SUCCESS,
      url: '/Backend/OrderInvoice/list',
    });
  };
};

export const getOrderInvoiceDetail = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/OrderInvoice/Detail/${id}`,
  });
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: ORDER_INVOICE_DETAIL_FAIL,
      method: 'get',
      requestConstant: ORDER_INVOICE_DETAIL_REQUEST,
      successConstant: ORDER_INVOICE_DETAIL_SUCCESS,
      url: `/Backend/OrderInvoice/Detail/${id}`,
    });
  };
};
