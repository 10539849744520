import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import request from 'utils/useAxios';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { orderReturnReasonList } from '../redux/actions/data/orderReturnReasonActions';
import style from '../styles/layout.module.css';

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'noWrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};
const OrderReturnReason = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const dispatch = useDispatch();

  const { loading, orderReturnReasonListInfo } = useSelector(
    state => state.orderReturnReasonList,
  );
  const handleGetList = async type => {
    await dispatch(
      orderReturnReasonList(
        {
          page: 1,
          page_size: 10,
        },
        type === 1 ? '退貨' : '換貨',
      ),
    );
  };
  useEffect(() => {
    handleGetList(1);
  }, []);

  const handleChangeTab = tab => {
    setCurrentTab(tab);
    handleGetList(tab);
  };

  const [reason, setReason] = useState('');
  const [reasonEn, setReasonEn] = useState('');
  const handleOrderReturnReasonAdd = async () => {
    const { code, message } = await request({
      data: {
        reason,
        reason_en: reasonEn,
        type: currentTab === 1 ? '退貨' : '換貨',
      },
      dispatch,
      method: 'post',
      url: '/Backend/OrderReturnReason/OrderReturnReason/Add',
    });
    if (code !== 200) {
      MessageUtil.alertWanring(message);
      return;
    }
    setReason('');
    setReasonEn('');
    handleGetList(currentTab);
  };

  const handleDelete = async id => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          dispatch,
          method: 'get',
          url: `/Backend/OrderReturnReason/OrderReturnReason/Delete/${id}`,
        });
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('刪除成功!!');
        handleGetList(currentTab);
      },
      title: '確認刪除？',
    });
  };

  useEffect(() => {
    if (orderReturnReasonListInfo) {
      if (JSON.stringify(orderReturnReasonListInfo) !== '{}') {
        CommonUtil.consoleLog({
          anno: 'response',
          data: orderReturnReasonListInfo,
        });
      }
    }
  }, [orderReturnReasonListInfo.datas]);
  return (
    <Layout
      pageTitle="退貨原因設定"
      items={[
        { isActive: false, page_name: '購物相關設定' },
        { isActive: true, page_name: '退貨原因設定' },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={handleChangeTab}
          tab={1}
          text="退貨原因"
          currentTab={currentTab}
        />

        <TabItem
          onClick={handleChangeTab}
          tab={2}
          text="換貨原因"
          currentTab={currentTab}
        />
      </Nav>
      <Card className={style.card}>
        <div className="d-flex justify-content-between align-items-center">
          <div className={`${style.formGroup} gap-2 mb-3`}>
            <input
              type="text"
              className="form-control"
              placeholder="請輸原因"
              onChange={e => setReason(e.target.value)}
              value={reason}
            />
            <Input
              type="text"
              className={style['form-input']}
              placeholder="請輸入原因(英)"
              onChange={event => {
                setReasonEn(event.target.value);
              }}
              value={reasonEn}
            />
            <Button color="success" onClick={handleOrderReturnReasonAdd}>
              新增
            </Button>
          </div>
          {!loading && (
            <div>
              共
              <span className="mx-1">
                {orderReturnReasonListInfo?.total_count ?? 0}
              </span>
              筆
            </div>
          )}
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>原因</th>
                <th>原因(英)</th>
                <th>新增日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {orderReturnReasonListInfo?.datas?.length > 0 ? (
                  orderReturnReasonListInfo?.datas?.map((i, index) => (
                    <tr key={i.id}>
                      <td>{i.reason}</td>
                      <td>{i.reason_en}</td>
                      <td>
                        {i.updateDate} {i.updateTime} {i.updater}
                      </td>
                      <td>
                        <Button
                          color="danger"
                          outline
                          onClick={() => handleDelete(i.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
          {loading && (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default OrderReturnReason;
