import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  SET_SETTINGS_FAIL,
  SET_SETTINGS_IDLE,
  SET_SETTINGS_REQUEST,
  SET_SETTINGS_SUCCESS,
} from 'redux/constants/data/yourFavoriteConstant';

export const getSettingsReducer = (
  state = { error: null, loading: false, settings: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SETTINGS_REQUEST:
      return { error: false, loading: true, settings: {}, success: false };
    case GET_SETTINGS_SUCCESS:
      return { ...state, loading: false, settings: payload, success: true };
    case GET_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const setSettingsReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SETTINGS_IDLE:
      return { error: null, loading: false, success: false };
    case SET_SETTINGS_REQUEST:
      return { ...state, loading: true };
    case SET_SETTINGS_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
