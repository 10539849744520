import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, Nav, TabContent, TabPane } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import { TabItem } from '../components/tabItem';
import {
  getRepairSetting,
  postRepairSetting,
} from '../redux/actions/data/repairActions';
import { REPAIR_SETTING_POST_RESET } from '../redux/constants/data/repairConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const TABS = [
  {
    name: '付款資訊',
    tab: 1,
  },
  {
    name: '檢附品項',
    tab: 2,
  },
  {
    name: '維修須知',
    tab: 3,
  },
];
const MaintainanceListSetting = () => {
  const dispatch = useDispatch();
  const { location } = history;

  const { loading, repairSettingInfo, error } = useSelector(
    state => state.repairSettingInfo,
  );

  const { values, setFieldValue, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      results: [
        {
          content: repairSettingInfo ? repairSettingInfo[0].content : '',
          content_en: repairSettingInfo ? repairSettingInfo[0].content_en : '',
          type: '匯款資訊',
        },
        {
          repairItems: [
            {
              item: '',
              item_en: '',
            },
          ],
          type: '檢附項目',
        },
        {
          repairDetails: [
            {
              details: '',
              details_en: '',
            },
          ],
          type: '維修須知',
        },
      ],
    },
    onSubmit: () => {},
  });

  const [repairItems, setRepairItems] = useState('');
  const [repairItemsEn, setRepairItemsEn] = useState('');
  const [repairDetailTitle, setRepairDetailTitle] = useState('');
  const [repairDetailTitleEn, setRepairDetailTitleEn] = useState('');
  const [repairDetailContent, setRepairDetailContent] = useState('');
  const [repairDetailContentEn, setRepairDetailContentEn] = useState('');

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.repairSettingPost);

  useEffect(() => {
    dispatch(getRepairSetting());
  }, []);

  useEffect(() => {
    if (repairSettingInfo) {
      if (JSON.stringify(repairSettingInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: repairSettingInfo });
      }
    }
  }, [repairSettingInfo]);

  useEffect(() => {
    if (repairSettingInfo) {
      let repairItems = [];
      let repairDetails = [];
      repairSettingInfo[1].repairItems.forEach((data, i) => {
        repairItems.push({
          item: data,
          item_en: repairSettingInfo[1].repairItems_en[i],
        });
      });
      repairSettingInfo[2].repairDetails.forEach((data, i) => {
        repairDetails.push({
          detail: data,
          detail_en: repairSettingInfo[2].repairDetails_en[i],
        });
      });
      setFieldValue('results[1]', repairItems);
      setFieldValue('results[2]', repairDetails);
    }
  }, [repairSettingInfo]);

  useEffect(() => {
    if (updateSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('更新成功!');
      dispatch(getRepairSetting());
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
    }

    return () => {
      dispatch({ type: REPAIR_SETTING_POST_RESET });
    };
  }, [updateSuccess, updateError]);

  const handleAddRepairItem = e => {
    e.preventDefault();

    if (!repairItems) {
      MessageUtil.alertWanring('請輸入檢附品項名稱(中)');
      return;
    }

    if (!repairItemsEn) {
      MessageUtil.alertWanring('請輸入檢附品項名稱(英)');
      return;
    }

    const newItem = { item: repairItems, item_en: repairItemsEn };
    setFieldValue('results[1]', [...values.results[1], newItem]);
    setRepairItems('');
    setRepairItemsEn('');
  };

  useEffect(() => {
    if (repairSettingInfo) {
      if (JSON.stringify(repairSettingInfo) !== '{}') {
        CommonUtil.consoleLog(repairSettingInfo);
      }
    }
  }, [repairSettingInfo]);

  const handleAddRepairDetails = e => {
    e.preventDefault();

    if (!repairDetailTitle || !repairDetailTitleEn) {
      MessageUtil.alertWanring('請輸入標題');
      return;
    } else if (!repairDetailContent || !repairDetailContentEn) {
      MessageUtil.alertWanring('請輸入內容');
      return;
    }

    const newItem = {
      detail: {
        content: repairDetailContent,
        title: repairDetailTitle,
      },
      detail_en: {
        content: repairDetailContentEn,
        title: repairDetailTitleEn,
      },
    };

    setFieldValue('results[2]', [...values.results[2], newItem]);
    setRepairDetailTitle('');
    setRepairDetailTitleEn('');
    setRepairDetailContent('');
    setRepairDetailContentEn('');
  };

  const handleSubmit = e => {
    e.preventDefault();

    const repairItems = {
      repairItems: values.results[1].map(item => item.item),
      repairItems_en: values.results[1].map(item => item.item_en),
      type: '檢附品項',
    };

    const repairDetails = {
      repairDetails: values.results[2].map(item => item.detail),
      repairDetails_en: values.results[2].map(item => item.detail_en),
      type: '維修須知',
    };

    const object = [values.results[0], repairItems, repairDetails];

    CommonUtil.consoleLog({ anno: 'request body', data: object });
    dispatch(postRepairSetting(object));
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(values.results[2]);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // 更新排序後的結果
    setFieldValue('results[2]', items);
  };

  const [currentTab, setCurrentTab] = useState(1);

  return (
    <Layout
      pageTitle="維修表單設定"
      items={[{ isActive: true, page_name: '維修表單設定' }]}
    >
      <Nav pills className="mb-2">
        {TABS.map(t => {
          return (
            <TabItem
              key={JSON.stringify(t)}
              onClick={setCurrentTab}
              tab={t.tab}
              text={t.name}
              currentTab={currentTab}
            />
          );
        })}
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            <h4 className="title">付款資訊</h4>
            <div className={style.formGroup}>
              <span className="w50">(中)</span>
              <textarea
                name="payment_info"
                rows="8"
                className="form-control me-3"
                onChange={e =>
                  setFieldValue('results[0].content', e.target.value)
                }
                value={values.results[0].content}
              />
            </div>
            <div className={style.formGroup}>
              <span className="w50">(英)</span>
              <textarea
                name="payment_info_en"
                rows="8"
                className="form-control me-3"
                onChange={e =>
                  setFieldValue('results[0].content_en', e.target.value)
                }
                value={values.results[0].content_en}
              />
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => history.goBack()}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                disabled={updateLoading}
                onClick={handleSubmit}
              >
                {updateLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <Card className={style.card}>
            <h4 className="title">檢附品項</h4>
            <form onSubmit={handleAddRepairItem} className="d-flex">
              <div style={{ flex: '9' }}>
                <div className={style.formGroup}>
                  <span className="w50">(中)</span>
                  <input
                    name="repair_items"
                    className="form-control"
                    onChange={e => setRepairItems(e.target.value)}
                    value={repairItems}
                  />
                </div>
                <div className={style.formGroup}>
                  <span className="w50">(英)</span>
                  <input
                    name="repair_items_en"
                    className="form-control"
                    onChange={e => setRepairItemsEn(e.target.value)}
                    value={repairItemsEn}
                  />
                </div>
              </div>
              <Button
                className="btn btn-success w100"
                style={{ height: '38px', marginLeft: '1rem' }}
                type="submit"
              >
                新增項目
              </Button>
            </form>
            <div className={style.maintain_item_group}>
              {values.results[1]?.length > 0 &&
                values.results[1].map((item, index) => (
                  <div key={index} className="me-5">
                    {item.item !== '商品本體' && (
                      <FaTimes
                        onClick={() =>
                          setFieldValue(
                            'results[1]',
                            values.results[1].filter(i => i !== item),
                          )
                        }
                        className={style.maintain_item_remove_button}
                      />
                    )}
                    <span className="ms-1">{`${item.item} ${
                      item.item_en ?? ''
                    }`}</span>
                  </div>
                ))}
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => history.goBack()}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                disabled={updateLoading}
                onClick={handleSubmit}
              >
                {updateLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Card>
        </TabPane>
        <TabPane tabId={3}>
          <Card className={style.card}>
            <h4 className="title">維修須知</h4>
            <form className="d-flex" onSubmit={handleAddRepairDetails}>
              <div style={{ flex: '9' }}>
                <div className={style.formGroup}>
                  <span style={{ width: '5rem' }}>標題</span>
                  <input
                    name="repair_details_title"
                    className="form-control"
                    onChange={e => setRepairDetailTitle(e.target.value)}
                    value={repairDetailTitle}
                  />
                </div>
                <div className={style.formGroup}>
                  <span style={{ width: '5rem' }}>說明</span>
                  <textarea
                    name="payment_info"
                    rows="8"
                    className="form-control"
                    onChange={e => setRepairDetailContent(e.target.value)}
                    value={repairDetailContent}
                  />
                </div>
                <div className={style.formGroup}>
                  <span style={{ width: '5rem' }}>標題(英)</span>
                  <input
                    name="repair_details_title_en"
                    className="form-control"
                    onChange={e => setRepairDetailTitleEn(e.target.value)}
                    value={repairDetailTitleEn}
                  />
                </div>
                <div className={style.formGroup}>
                  <span style={{ width: '5rem' }}>說明(英)</span>
                  <textarea
                    name="payment_info_en"
                    rows="8"
                    className="form-control"
                    onChange={e => setRepairDetailContentEn(e.target.value)}
                    value={repairDetailContentEn}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  padding: '1rem',
                }}
              >
                <Button
                  className="btn btn-success w100"
                  style={{ height: '38px' }}
                  type="submit"
                >
                  新增項目
                </Button>
              </div>
            </form>
            <div className="ms-2 p-5">
              {values.results[2]?.length > 0 && (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppable-2">
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {values.results[2].map((item, index) => (
                          <Draggable
                            key={index}
                            draggableId={`draggable-${index}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={
                                  style.maintain_detail_container +
                                  (snapshot.isDragging
                                    ? ' dragging position-relative'
                                    : '')
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  border: '1px solid lightgray',
                                  padding: '10px',
                                  ...provided.draggableProps.style,
                                  left: '0px',
                                  top:'0px'
                                }}
                              >
                                <Input
                                  name="title"
                                  className="form-control"
                                  onChange={e => {
                                    setFieldValue(
                                      `results[2][${index}].detail.title`,
                                      e.target.value,
                                    );
                                  }}
                                  value={item.detail?.title}
                                />
                                <textarea
                                  name="content"
                                  rows="8"
                                  className="form-control"
                                  onChange={e => {
                                    setFieldValue(
                                      `results[2][${index}].detail.content`,
                                      e.target.value,
                                    );
                                  }}
                                  value={item.detail?.content}
                                />
                                <div style={{ margin: '1rem' }}></div>
                                <Input
                                  name="title_en"
                                  className="form-control"
                                  onChange={e => {
                                    setFieldValue(
                                      `results[2][${index}].detail_en.title`,
                                      e.target.value,
                                    );
                                  }}
                                  value={item.detail_en?.title}
                                />
                                <textarea
                                  name="content_en"
                                  rows="8"
                                  className="form-control"
                                  onChange={e => {
                                    setFieldValue(
                                      `results[2][${index}].detail_en.content`,
                                      e.target.value,
                                    );
                                  }}
                                  value={item.detail_en?.content}
                                />
                                <Button
                                  className={
                                    style.maintain_detail_remove_button
                                  }
                                  type="button"
                                  color="danger"
                                  outline
                                  size="sm"
                                  onClick={() =>
                                    setFieldValue(
                                      'results[2]',
                                      values.results[2].filter(
                                        detail => detail !== item,
                                      ),
                                    )
                                  }
                                >
                                  刪除
                                </Button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => history.goBack()}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                disabled={updateLoading}
                onClick={handleSubmit}
              >
                {updateLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Card>
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default MaintainanceListSetting;
