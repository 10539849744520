import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import {
  getLowestNotifyInfo,
  updateLowestNotify,
} from 'redux/actions/data/lowestNotifyActions';
import { LOWEST_NOTIFY_UPDATE_RESET } from 'redux/constants/data/lowestNotifyConstants';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';


import style from '../styles/layout.module.css';

const LowestNotify = () => {
  const dispatch = useDispatch();
  const { loading, lowestNotifyInfo } = useSelector(
    state => state.lowestNotifyInfo,
  );
  useEffect(() => {
    dispatch(getLowestNotifyInfo());
  }, []);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: lowestNotifyInfo,
    },
    async onSubmit(formModel) {
      const params = formModel.list;
      dispatch(updateLowestNotify(params));
    },
    validationSchema: Yup.object().shape({
      list: Yup.array().of(
        Yup.object().shape({
          content: Yup.string().nullable().required('必填'),
        }),
      ),
    }),
  });

  const {
    loading: updateLoading,
    success,
    error,
  } = useSelector(state => state.updateLowestNotify);
  useEffect(() => {
    return () => {
      success && MessageUtil.toastSuccess('儲存成功！');
      success && dispatch(getLowestNotifyInfo());
      error && MessageUtil.alertWanring(error);
      dispatch({ type: LOWEST_NOTIFY_UPDATE_RESET });
    };
  }, [success, error]);
  return (
    <Layout
      pageTitle="安全庫存量通知"
      items={[
        { isActive: false, page_name: '商品管理' },
        { isActive: true, page_name: '安全庫存量通知' },
      ]}
    >
      <Card className={style.card}>
        <Form className="position-relative">
          {(loading || updateLoading) && <BlockSpinner />}
          {lowestNotifyInfo.map((i, index) => (
            <FormGroup row key={index}>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                {i.title.replace('安全庫存量通知', '')}
              </Label>
              <Col>
                <Input
                  type="textarea"
                  rows={10}
                  name={`list[${index}].content`}
                  onChange={handleChange}
                  value={values.list[index]?.content || ''}
                />
                <FormText>
                  mail之間，請加分號
                  <br />
                  ex. aaa@gmail.com;bbb@gmail.com
                </FormText>
                {touched.list?.[index]?.content && (
                  <FormText color="danger" className="d-block">
                    {errors.list?.[index]?.content}
                  </FormText>
                )}
              </Col>
            </FormGroup>
          ))}
        </Form>
        <p className={style.fs_14}>
          異動時間：
          {lowestNotifyInfo.length > 0
            ? `${lowestNotifyInfo[0].updateDate} ${lowestNotifyInfo[0].updateTime} ${lowestNotifyInfo[0].updater}`
            : ''}
        </p>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            disabled={loading || updateLoading}
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            {(loading || updateLoading) && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </Layout>
  );
};

export default LowestNotify;
