import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';

import style from '../styles/layout.module.css';

const TabItem = ({ active, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={active}
        className={`${
          active ? style.modal_button_submit : style.modal_button_cancel
        } me-0`}
        style={text === '退換貨政策' ? { width: '120px' } : {}}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};
const Tabs = ({ tabs, defaultActiveTab = 1, children }) => {
  const [currentTab, setCurrentTab] = useState(defaultActiveTab);
  return (
    <>
      <Nav pills className="mb-2">
        {tabs.map(i => (
          <TabItem
            key={i.tab}
            onClick={setCurrentTab}
            tab={i.tab}
            text={i.name}
            active={currentTab === i.tab}
          />
        ))}
      </Nav>
      <TabContent activeTab={currentTab}>{children}</TabContent>
    </>
  );
};
export default Tabs;
