import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import {
  getJobDetails,
  postJob,
} from '../redux/actions/data/userActions';
import { JOB_POST_RESET } from '../redux/constants/data/userConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

export default function JoinUsDetail(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [startDate, setStartDate] = useState(''); //日期都要用字串
  const [endDate, setEndDate] = useState('');
  const [workInfo, setWorkInfo] = useState(null);

  const { loading, jobDetailsInfo, error } = useSelector(
    state => state.jobDetails,
  );
  const { loading: postLoading, success: postSuccess } = useSelector(
    state => state.jobPost,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // console.log(jobDetailsInfo)
  console.log(startDate);
  console.log(endDate);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: `/JoinUsDetail/${id}` })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: `/JoinUsDetail/${id}` })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (id > 0) {
      dispatch(getJobDetails(id));
    }
  }, []);

  useEffect(() => {
    if (jobDetailsInfo && jobDetailsInfo.result && id > 0) {
      setWorkInfo(jobDetailsInfo.result);
    } else {
      setWorkInfo(null);
    }
  }, [jobDetailsInfo]);

  console.log(workInfo);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      history.goBack();
    }

    return () => {
      dispatch({ type: JOB_POST_RESET });
    };
  }, [postSuccess]);

  const handleSubmit = values => {
    console.log(values);
    console.log(startDate);
    let formData = new FormData();

    formData.append('id', id);
    formData.append('jobName', values.jobName);
    formData.append('jobName_en', values.jobName_en);
    formData.append('workPlace', values.workPlace);
    formData.append('workPlace_en', values.workPlace_en);
    formData.append('workDuty', values.workDuty);
    formData.append('workDuty_en', values.workDuty_en);
    formData.append('jobType', values.jobType);
    formData.append('jobType_en', values.jobType_en);
    formData.append('jobDesc', values.jobDesc);
    formData.append('jobDesc_en', values.jobDesc_en);
    formData.append('jobLink', values.jobLink);
    formData.append('status', values.status);

    //有選時間就要toISOstring
    formData.append(
      'startTime',
      startDate ? startDate.toISOString() : workInfo.startTime,
    );

    formData.append('endTime', endDate ? endDate.toISOString() : '');

    dispatch(postJob(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      jobDesc: Yup.string().required('必填!'),
      jobDesc_en: Yup.string().required('必填!'),

      // startDate: Yup.string().required('必填!'),
      jobLink: Yup.string().required('必填!'),
      jobName: Yup.string().required('必填!'),
      jobName_en: Yup.string().required('必填!'),
      jobType: Yup.string().required('必填!'),
      jobType_en: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
      workDuty: Yup.string().required('必填!'),
      workDuty_en: Yup.string().required('必填!'),
      workPlace: Yup.string().required('必填!'),
      workPlace_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <Layout
      pageTitle="職務編輯"
      items={[
        { isActive: false, page_name: '加入我們', to: '/JoinUs' },
        { isActive: false, page_name: '職務編輯' },
      ]}
    >
      <Card className={style.card}>
        <Formik
          enableReinitialize
          initialValues={{
            endDate: workInfo ? workInfo.endDate : '',
            jobDesc: workInfo ? workInfo.jobDesc : '',
            jobDesc_en: workInfo ? workInfo.jobDesc_en : '',
            jobLink: workInfo ? workInfo.jobLink : '',
            jobName: workInfo ? workInfo.jobName : '',
            jobName_en: workInfo ? workInfo.jobName_en : '',
            jobType: workInfo ? workInfo.jobType : '',
            jobType_en: workInfo ? workInfo.jobType_en : '',
            startDate: workInfo ? workInfo.startDate : '',
            status: workInfo ? workInfo.status : '',
            workDuty: workInfo ? workInfo.workDuty : '',

            workDuty_en: workInfo ? workInfo.workDuty_en : '',
            workPlace: workInfo ? workInfo.workPlace : '',

            workPlace_en: workInfo ? workInfo.workPlace_en : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <div className={style.formGroup}>
                <label className="required w150">
                  職務名稱{' '}
                  <ErrorMessage
                    name="jobName"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="jobName" className="form-control" />
                </div>
              </div>
              <div className={style.formGroup}>
                <label className="required w150">
                  職務名稱(英){' '}
                  <ErrorMessage
                    name="jobName_en"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="jobName_en" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作地點{' '}
                  <ErrorMessage
                    name="workPlace"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="workPlace" className="form-control" />
                </div>
              </div>
              <div className={style.formGroup}>
                <label className="required w150">
                  工作地點(英){' '}
                  <ErrorMessage
                    name="workPlace_en"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="workPlace_en" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作職務{' '}
                  <ErrorMessage
                    name="workDuty"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="workDuty" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作職務(英){' '}
                  <ErrorMessage
                    name="workDuty_en"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="workDuty_en" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作類型{' '}
                  <ErrorMessage
                    name="jobType"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="jobType" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作類型(英){' '}
                  <ErrorMessage
                    name="jobType_en"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="jobType_en" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作說明{' '}
                  <ErrorMessage
                    name="jobDesc"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field
                    name="jobDesc"
                    as="textarea"
                    rows="10"
                    className="form-control"
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  工作說明(英){' '}
                  <ErrorMessage
                    name="jobDesc_en"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field
                    name="jobDesc_en"
                    as="textarea"
                    rows="10"
                    className="form-control"
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">上架日期區間</label>
                <div className="form-item">
                  <div className="mb-3">
                    <label className="required form-label">
                      上架時間{' '}
                      <ErrorMessage
                        name="startDate"
                        component="span"
                        className="form-error"
                      />
                    </label>
                    <DatePicker
                      className="form-control"
                      name="startDate"
                      selected={
                        startDate === '' && workInfo && workInfo.startTime
                          ? new Date(workInfo && workInfo.startTime)
                          : startDate
                      }
                      onChange={date => setStartDate(date)}
                      selectsStart
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">下架時間 </label>
                    <DatePicker
                      className="form-control"
                      name="endDate"
                      selected={
                        endDate === '' && workInfo?.endTime === null
                          ? undefined
                          : endDate === ''
                          ? new Date(workInfo?.endTime).setHours(
                              new Date(workInfo?.endTime).getHours() + 12,
                            )
                          : endDate
                      }
                      defaultValue="undefined"
                      onChange={date => setEndDate(date)}
                      dateFormat="yyyy/MM/dd"
                      selectsEnd
                      minDate={startDate || new Date(workInfo?.startTime)}
                    />
                  </div>
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="required w150">
                  職缺連結{' '}
                  <ErrorMessage
                    name="jobLink"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="jobLink" className="form-control" />
                  <div className="opacity-75">請填寫包含https://的完整網址</div>
                </div>
              </div>
              <div className={style.formGroup}>
                <label className="required w150">
                  狀態{' '}
                  <ErrorMessage
                    name="status"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="d-flex form-item">
                  <Field
                    className="my-auto me-2"
                    id="up"
                    name="status"
                    type="radio"
                    value="上架"
                  />
                  <label htmlFor="up" className="me-5">
                    上架
                  </label>
                  <Field
                    className="my-auto me-2"
                    id="down"
                    name="status"
                    type="radio"
                    value="下架"
                  />
                  <label htmlFor="down">下架</label>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="layout_modal_button_cancel__cgP-b btn btn-light"
                  onClick={() => history.goBack()}
                >
                  取消
                </button>
                <Button
                  type="submit"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout>
  );
}
