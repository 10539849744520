import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  createWarrantyNumber,
  getWarrantyExcel,
  getWarrantyNumberList,
  getWarrantyPrefixList,
} from '../redux/actions/data/warrantyActions';
import {
  WARRANTY_EXCEL_PRODUCE_RESET,
  WARRANTY_NUMBER_CREATE_RESET,
} from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function WarrantyNum(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const page_size = 10;

  const [current_page, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [serialSet, setSerialSet] = useState([]);
  const [isTouched, setIsTouched] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);

    if (modal) {
      setSerialSet([]);
    }
  };

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, numberListInfo, error } = useSelector(
    state => state.warrantyNumberList,
  );
  const { brandPrefixListInfo } = useSelector(
    state => state.warrantyPrefixList,
  );
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
    result: serialNumberId,
  } = useSelector(state => state.warrantyNumberCreate);
  const { result } = useSelector(state => state.warrantyExcelCreate);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/WarrantyNum' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/WarrantyNum' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryStartDate = query.value.startDate;
      const queryEndDate = query.value.endDate;
      const queryPage = query.value.page;

      setCurrentPage(queryPage);

      if (queryStartDate || queryEndDate) {
        setStartDate(
          queryStartDate !== 'null'
            ? new Date(JSON.parse(queryStartDate))
            : null,
        );
        setEndDate(
          queryEndDate !== 'null' ? new Date(JSON.parse(queryEndDate)) : null,
        );

        //儲存搜尋及頁數
        dispatch(
          getWarrantyNumberList({
            endDate: queryEndDate !== 'null' ? JSON.parse(queryEndDate) : null,
            page: queryPage,
            page_size,
            startDate:
              queryStartDate !== 'null' ? JSON.parse(queryStartDate) : null,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getWarrantyNumberList({
            page: queryPage,
            page_size,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setStartDate('');
      setEndDate('');

      //初始搜尋
      dispatch(getWarrantyPrefixList());
      dispatch(
        getWarrantyNumberList({
          page: 1,
          page_size,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      setIsTouched(false);
      MessageUtil.toastSuccess('新增成功!!');
      history.push(`/WarrantyNumDetail/${serialNumberId}`);
    }

    if (addError) {
      MessageUtil.alertWanring(addError);
    }

    return () => {
      dispatch({ type: WARRANTY_NUMBER_CREATE_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (result) {
      window.open(result);
    }

    return () => {
      dispatch({ type: WARRANTY_EXCEL_PRODUCE_RESET });
    };
  }, [result]);

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);

    history.push(
      `${location.pathname}?startDate=${
        startDate
          ? JSON.stringify(moment(startDate).add(8, 'hours').toISOString())
          : null
      }&endDate=${
        endDate
          ? JSON.stringify(moment(endDate).add(8, 'hours').toISOString())
          : null
      }&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setStartDate('');
    setEndDate('');

    history.push(`${location.pathname}`);
  };
  const handlePageChange = page => {
    setCurrentPage(page);

    if (startDate || endDate) {
      history.push(
        `${location.pathname}?startDate=${
          startDate
            ? JSON.stringify(moment(startDate).add(8, 'hours').toISOString())
            : null
        }&endDate=${
          endDate
            ? JSON.stringify(moment(endDate).add(8, 'hours').toISOString())
            : null
        }&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleInput = (value, item) => {
    const existedSet = serialSet.find(set => set.brandId === item.value);

    const newItem = {
      brandId: item.value,
      prefix: item.serialNumPrefix,
      warrantyCount: value,
    };

    if (existedSet) {
      setSerialSet(
        serialSet
          .map(set =>
            set.brandId !== item.value
              ? set
              : {
                  ...set,
                  warrantyCount: value,
                },
          )
          .filter(set => set.warrantyCount),
      );
    } else {
      setSerialSet([...serialSet, newItem]);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createWarrantyNumber(serialSet));
    setIsTouched(true);
  };

  const handleConvert = id => {
    dispatch(getWarrantyExcel(id));
  };

  useEffect(() => {
    if (numberListInfo) {
      if (JSON.stringify(numberListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: numberListInfo });
      }
    }
  }, [numberListInfo]);

  return (
    <Layout
      pageTitle="產出保固序號"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: true, page_name: '產出保固序號' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup}>
                <label className="col-form-label mr-3">新增日期區間</label>
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  dateFormat="yyyy/MM/dd"
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  minDate={startDate}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && numberListInfo && numberListInfo.total_count > 0
              ? numberListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">新增時間</th>
                <th scope="col">品牌</th>
                <th scope="col">保固序號數量</th>
                <th scope="col">功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {numberListInfo ? (
                  numberListInfo.datas?.map((data, index) => (
                    <tr key={data.id}>
                      <td>
                        {data.createDate} {data.createTime}
                      </td>
                      <td>
                        {data.brands.map(brand => {
                          return <div>{brand.brandName}</div>;
                        })}
                      </td>
                      <td>{data.totalCount}</td>
                      <td>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/WarrantyNumDetail/${data.id}`}
                        >
                          編輯
                        </Link>
                        <Button
                          className="m-2"
                          color="success"
                          outline
                          size="sm"
                          onClick={() => handleConvert(data.id)}
                        >
                          匯出Excel
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && numberListInfo && numberListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={numberListInfo.current_page}
              defaultPageSize={10}
              total={numberListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          新增保固序號數量
        </ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody className={style.modal_body}>
            <div className="table-responsive">
              <Table hover bordered striped className="mb-5">
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th scope="col">品牌</th>
                    <th scope="col" className="w150">
                      數量
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {brandPrefixListInfo?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.label}</td>
                      <td>
                        <input
                          className="form-control"
                          placeholder="0"
                          onChange={e => handleInput(e.target.value, item)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="link"
              className={style.modal_button_cancel}
              onClick={toggleModal}
            >
              取消
            </Button>
            <Button
              color="primary"
              className={style.modal_button_submit}
              type="submit"
              disabled={isTouched}
            >
              {addLoading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              新增序號
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Layout>
  );
}
