import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AiFillPicture } from 'react-icons/ai';
import { BsCaretRightFill } from 'react-icons/bs';
import { CgArrowsShrinkV, CgArrowsVAlt } from 'react-icons/cg';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { GrTextAlignCenter } from 'react-icons/gr';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';

import UploadFileBox from './UploadFileBox';

const ProductIntro = props => {
  const defaultFileFileName = 'intro_files';
  const defaultFileCurrentName = 'intro_current_files';
  const defaultImgUrlFileName = 'imageUrl';
  const defaultFileFileNameEn = 'intro_files_en';
  const defaultFileCurrentNameEn = 'intro_current_files_en';
  const defaultImgUrlFileNameEn = 'imageUrl_en';
  const type_img_left = 'imageLeft';
  const type_img_Right = 'imageRight';
  const contentOnly = 'contentOnly';
  const imageOnly = 'imageOnly';
  const youtubeLink = 'youtubeLink';
  const height = 'height';

  //*************genereal props*************//
  const { setFormFieldValue, formDataName, formFieldName, formFieldNameEn } =
    props;
  const { initContents } = props;

  const [displayBlocks, setDisplayBlock] = useState([]);
  const [currentFormDatas, setCurrentFormDatas] = useState([]);
  const [currentFormFiles, setCurrentFormFiles] = useState([]);
  const [currentFormFilesEn, setCurrentFormFilesEn] = useState([]);

  const getValidationSchema = () => {
    // const schema = Yup.object().shape({
    //   title: Yup.string().required('必填!'),
    //   title_en: Yup.string().required('必填!'),
    //   content: Yup.string().required('必填!'),
    //   content_en: Yup.string().required('必填!'),

    //   intro_current_files: Yup.array().min(1, '需上傳圖片'),
    // });

    const schema = Yup.object().shape({
      intro_current_files: Yup.array().when('type', {
        is: val => {
          if (val !== 'contentOnly') {
            return false;
          }
          if (val !== 'height') {
            return false;
          }
          if (val !== 'youtubeLink') {
            return false;
          }
          return true;
        },
        then: Yup.array().min(1, '需上傳圖片'),
      }),
      intro_current_files_en: Yup.array().when('type', {
        is: val => val === 'imageOnly',
        then: Yup.array().min(1, '需上傳圖片'),
      }),
      type: Yup.string(),
    });

    return schema;
  };

  const renderRightImage = formData => {
    let data = {
      content: formData.get('content'),
      content_en: formData.get('content_en'),
      id: formData.get('id'),
      imgUrl: formData.get(defaultImgUrlFileName),
      title: formData.get('title'),
      title_en: formData.get('title_en'),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <div className="ic-prd-dt me-3">
              <div className="ic-prd-dt-txt">
                <span></span> <span></span> <span></span>
              </div>
              <div className="ic-prd-dt-img"></div>
            </div>
            左文右圖
          </h6>
          <Button
            type="button"
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="mx-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="mx-2" />
          </Button>
        </div>

        <div className="row card-body">
          <div className="col-sm-8">
            <div>
              <div className="d-flex">
                <label>標題(中)：</label>
                <span className="flex-1">{data.title}</span>
              </div>
              <div className="d-flex">
                <label>說明(中)：</label>
                <span className="flex-1">{data.content}</span>
              </div>
            </div>

            <div>
              <div className="d-flex">
                <label>標題(英)：</label>
                <span className="flex-1">{data.title_en}</span>
              </div>
              <div className="d-flex">
                <label>說明(英)：</label>
                <span className="flex-1">{data.content_en}</span>
              </div>
            </div>
          </div>{' '}
          <div className="col-sm-4">
            <img src={data.imgUrl} />
          </div>
        </div>
      </div>
    );
  };

  const renderLeftImage = formData => {
    let data = {
      content: formData.get('content'),
      content_en: formData.get('content_en'),
      id: formData.get('id'),
      imgUrl: formData.get(defaultImgUrlFileName),
      title: formData.get('title'),
      title_en: formData.get('title_en'),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <div className="ic-prd-dt me-3">
              <div className="ic-prd-dt-img"></div>
              <div className="ic-prd-dt-txt">
                <span></span> <span></span> <span></span>
              </div>
            </div>
            左圖右文
          </h6>
          <Button
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="mx-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="mx-2" />
          </Button>
        </div>

        <div className="row card-body">
          <div className="col-sm-8">
            <div>
              <div className="d-flex">
                <label>標題(中)：</label>
                <span className="flex-1">{data.title}</span>
              </div>
              <div className="d-flex">
                <label>說明(中)：</label>
                <span className="flex-1">{data.content}</span>
              </div>
            </div>

            <div>
              <div className="d-flex">
                <label>標題(英)：</label>
                <span className="flex-1">{data.title_en}</span>
              </div>
              <div className="d-flex">
                <label>說明(英)：</label>
                <span className="flex-1">{data.content_en}</span>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <img src={data.imgUrl} />
          </div>
        </div>
      </div>
    );
  };

  const renderContentOnly = formData => {
    // console.log('hhhhhhhhhhhh');
    let data = {
      content: formData.get('content'),
      content_en: formData.get('content_en'),
      id: formData.get('id'),
      title: formData.get('title'),
      title_en: formData.get('title_en'),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <GrTextAlignCenter size="24" className="me-2" />
            <span>純文字</span>
          </h6>
          <Button
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="m-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="m-2" />
          </Button>
        </div>

        <div className="row card-body">
          <div className="col-sm-8">
            <div className="d-flex">
              <label>標題(中)：</label>
              <span className="flex-1">{data.title}</span>
            </div>
            <div className="d-flex">
              <label>標題(英)：</label>
              <span className="flex-1">{data.title_en}</span>
            </div>
            <div className="d-flex">
              <label>說明(中)：</label>
              <span className="flex-1">{data.content}</span>
            </div>
            <div className="d-flex">
              <label>說明(英)：</label>
              <span className="flex-1">{data.content_en}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderImageOnly = formData => {
    let data = {
      id: formData.get('id'),
      imgUrl: formData.get(defaultImgUrlFileName),
      imgUrlEn: formData.get(defaultImgUrlFileNameEn),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <AiFillPicture size="24" className="me-2" />
            <span>純圖片</span>
          </h6>
          <Button
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="m-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="m-2" />
          </Button>
        </div>
        <div className="row card-body">
          <div className="col-sm-8">
            <div className="col-sm-4">
              <div className={style.show_flex} style={{ flexWrap: 'nowrap' }}>
                <img src={data.imgUrl} />
                <div style={{ margin: '1rem' }} />
                <img src={data.imgUrlEn} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderYoutubeLink = formData => {
    // console.log(formData)
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }
    let data = {
      id: formData.get('id'),
      youtubeLink: formData.get('youtubeLink'),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <BsCaretRightFill size="24" className="me-2" />
            <span>影片</span>
          </h6>
          <Button
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="m-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="m-2" />
          </Button>
        </div>

        <div className="row card-body">
          <div className="col-sm-8">
            <div className="col-sm-4">
              <iframe
                width="560"
                height="315"
                src={data.youtubeLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeight = formData => {
    // console.log(formData)
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }
    let data = {
      height: formData.get('height'),
      id: formData.get('id'),
    };

    return (
      <div className="card mb-3" style={{ maxWidth: '800px' }} key={data.id}>
        <div className="d-flex card-header">
          <h6 className="me-auto d-flex align-items-center mb-0">
            <CgArrowsShrinkV size="24" className="me-2" />
            <span>高度</span>
          </h6>
          <Button
            color="primary"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              openEditModal(formData);
            }}
          >
            <FaEdit className="m-2" />
          </Button>
          <Button
            type="button"
            onClick={() => {
              removeContent(data.id);
            }}
            color="danger"
            className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          >
            <FaTimes className="m-2" />
          </Button>
        </div>

        <div className="row card-body">
          <div className="col-sm-8">
            <div className="col-sm-4">
              <label>空白高度：</label>
              <span className="flex-1">{data.height} px</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const removeContent = id => {
    let newFormDatas = currentFormDatas.filter(formData => {
      return formData.get('id') !== id;
    });
    setCurrentFormDatas(newFormDatas);
  };

  /**** modal  ****/
  const [isOpenModal, setModal] = useState(false);
  const toggleModal = () => setModal(prev => !prev);
  const [isSumbitLoading, setSumbitLoading] = useState(false);
  const [editFormData, setEditFormData] = useState(new FormData());

  // // console.log(editFormData)
  // // console.log('------formData info------')
  // for (var pair of editFormData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1])
  // }

  const handleModalSubmit = values => {
    // console.log('submit value' ,values);

    let newFormData = new FormData();
    newFormData.append('id', editFormData.get('id'));
    newFormData.append('type', editFormData.get('type'));
    newFormData.append('title', values.title);
    newFormData.append('title_en', values.title_en);
    newFormData.append('content', values.content);
    newFormData.append('content_en', values.content_en);
    newFormData.append('isUpload', values.isUpload);
    newFormData.append('youtubeLink', values.youtubeLink);
    newFormData.append('height', values.height);

    // console.log(values[defaultFileFileName]); //收到的檔案
    // console.log(editFormData.get(defaultFileFileName)); //收到的檔案

    if (values[defaultFileFileName] && values[defaultFileFileName].length > 0) {
      // 有新上傳
      let file = values[defaultFileFileName][0];

      // let fileEn = values[defaultFileFileNameEn][0];
      file.id = newFormData.get('id');
      setCurrentFormFiles([
        ...currentFormFiles.filter(i =>
          currentFormFiles.some(i => i.id === file.id)
            ? i.id !== file.id
            : true,
        ),
        file,
      ]);
      newFormData.append(
        defaultImgUrlFileName, //imgUrl
        values.intro_current_files[0].url,
      );
    } else if (
      //   editFormData.get(defaultFileFileName) &&
      values.intro_current_files.length > 0
    ) {
      // console.log(editFormData.get(defaultFileFileName));
      // console.log(editFormData.get(defaultImgUrlFileName));
      // 原有檔案
      newFormData.append(
        defaultFileFileName,
        editFormData.get(defaultFileFileName),
      );
      newFormData.append(
        defaultImgUrlFileName,
        editFormData.get(defaultImgUrlFileName),
      );
    }

    // 英文圖片
    if (
      values[defaultFileFileNameEn] &&
      values[defaultFileFileNameEn].length > 0
    ) {
      // 有新上傳
      let file = values[defaultFileFileNameEn][0];

      // let fileEn = values[defaultFileFileNameEn][0];
      file.id = newFormData.get('id');
      setCurrentFormFilesEn([
        ...currentFormFilesEn.filter(i =>
          currentFormFilesEn.some(i => i.id === file.id)
            ? i.id !== file.id
            : true,
        ),
        file,
      ]);

      newFormData.append(
        defaultImgUrlFileNameEn, //imgUrl
        values.intro_current_files_en[0].url,
      );
    } else if (
      //   editFormData.get(defaultFileFileName) &&
      values.intro_current_files_en.length > 0
    ) {
      // 原有檔案
      newFormData.append(
        defaultFileFileNameEn,
        editFormData.get(defaultFileFileNameEn),
      );
      newFormData.append(
        defaultImgUrlFileNameEn,
        editFormData.get(defaultImgUrlFileNameEn),
      );
    }

    // for (var pair of newFormData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    if (
      currentFormDatas.filter(form => {
        return form.get('id') === newFormData.get('id');
      }).length > 0
    ) {
      // 更新原本的項目
      let newtFormDatas = currentFormDatas.map(orginalForm => {
        if (orginalForm.get('id') === newFormData.get('id')) {
          return newFormData;
        }

        return orginalForm;
      });
      setCurrentFormDatas(newtFormDatas);
    } else {
      // 加入新的項目
      setCurrentFormDatas([...currentFormDatas, newFormData]);
    }
    toggleModal();
  };

  // console.log(currentFormDatas[2].get('image_en'));

  const [type, setType] = useState('');

  const openAddModal = type => {
    let formData = new FormData();
    formData.append('id', CommonUtil.getNewID());
    formData.append('type', type);

    setEditFormData(formData);
    toggleModal();
    setType(type);
  };

  const openEditModal = formData => {
    setEditFormData(formData);
    toggleModal();
    setType(formData.get('type'));

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
  };

  const getExistFileData = type => {
    let image = editFormData.get('image');
    let imgUrl = editFormData.get(defaultImgUrlFileName);
    let imgUrlEn = editFormData.get(defaultImgUrlFileNameEn);
    if (editFormData && imgUrl !== null) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: image,
          url: type === 'en' ? imgUrlEn : imgUrl,
        },
      ];
    } else {
      return [];
    }
  };

  /**** render view  ****/
  useEffect(() => {
    if (initContents) {
      let newFormDatas = initContents?.map(data => {
        let formData = new FormData();
        formData.append('id', data.id);
        formData.append('type', data.type);
        formData.append('title', data.title);
        formData.append('title_en', data.title_en);
        formData.append('content', data.content);
        formData.append('content_en', data.content_en);
        formData.append('image', data.image);
        formData.append('imageUrl', data.imageUrl);
        formData.append('image_en', data.image_en);
        formData.append('imageUrl_en', data.imageUrl_en);
        formData.append('youtubeLink', data.youtubeLink);
        formData.append('height', data.height);

        return formData;
      });
      setCurrentFormDatas(newFormDatas);
    } else {
      setCurrentFormDatas([]);
    }
  }, [initContents]);

  useEffect(() => {
    // console.log('init displayBlock')

    // console.log(currentFormDatas)
    // console.log(currentFormFiles)

    //將intro的資料組成formdata格式傳出去給formilk
    if (setFormFieldValue) {
      setFormFieldValue(formDataName, currentFormDatas);
      setFormFieldValue(formFieldName, currentFormFiles);
      setFormFieldValue(formFieldNameEn, currentFormFilesEn);
    }

    let blocks = currentFormDatas?.map(formData => {
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1])
      // }

      if (formData.get('type') === type_img_left) {
        return renderLeftImage(formData);
      }
      if (formData.get('type') === type_img_Right) {
        return renderRightImage(formData);
      }
      if (formData.get('type') === contentOnly) {
        return renderContentOnly(formData);
      }
      if (formData.get('type') === imageOnly) {
        return renderImageOnly(formData);
      }
      if (formData.get('type') === youtubeLink) {
        return renderYoutubeLink(formData);
      }
      if (formData.get('type') === height) {
        return renderHeight(formData);
      }
    });

    setDisplayBlock(blocks);
    setEditThumbSort(currentFormDatas);
  }, [currentFormDatas]);

  /**** drag n drop  ****/
  const [editThumbSort, setEditThumbSort] = useState([]);

  const handleOnDragEnd = result => {
    const items = Array.from(editThumbSort);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setEditThumbSort(items);
    items.forEach((item, index) => {
      item.set('sort', index + 1); //此處不能用appand會造成累加
    });
  };

  // console.log(editThumbSort[2]?.get('imageUrl'));

  return (
    <>
      <div className="form-item">
        <div className="btn-intro-group">
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro mr-3"
            onClick={() => {
              openAddModal(type_img_left);
            }}
          >
            <div className="d-flex align-items-center">
              <div className="ic-prd-dt me-2">
                <div className="ic-prd-dt-img"></div>
                <div className="ic-prd-dt-txt">
                  <span></span> <span></span> <span></span>
                </div>
              </div>
              <span>左圖右文</span>
            </div>
          </button>
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro"
            onClick={() => {
              openAddModal(type_img_Right);
            }}
          >
            <div className="d-flex align-items-center">
              <div className="ic-prd-dt me-2">
                <div className="ic-prd-dt-txt">
                  <span></span> <span></span> <span></span>
                </div>
                <div className="ic-prd-dt-img"></div>
              </div>
              <span>左文右圖</span>
            </div>
          </button>
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro"
            onClick={() => {
              openAddModal(contentOnly);
            }}
          >
            <span className="btn-wrapper--icon">
              <GrTextAlignCenter size="22" />
            </span>
            <span>純文字</span>
          </button>
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro"
            onClick={() => {
              openAddModal(imageOnly);
            }}
          >
            <span className="btn-wrapper--icon">
              <AiFillPicture size="24" />
            </span>
            <span>純圖片</span>
          </button>
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro"
            onClick={() => {
              openAddModal(youtubeLink);
            }}
          >
            <span className="btn-wrapper--icon">
              <BsCaretRightFill size="24" />
            </span>
            <span>影片</span>
          </button>
          <button
            type="button"
            className="m-2 btn btn-neutral-warning btn-intro"
            onClick={() => {
              openAddModal(height);
            }}
          >
            <span className="btn-wrapper--icon">
              <CgArrowsShrinkV size="24" />
            </span>
            <span>空白高度</span>
          </button>
        </div>
        <div className="mt-2 opacity-50">
          <span className="btn-wrapper--icon">
            <CgArrowsVAlt size="24" />
          </span>
          拖拉區塊可以調整排序
        </div>
        {/* <div>{displayBlocks}</div> */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="brands">
            {provided => (
              <ul
                className="drag_list"
                style={{ padding: 0 }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {editThumbSort.map((formData, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {provided => (
                      <li
                        className="position-relative p-2 drag_dont_move"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        {formData.get('type') === 'imageLeft' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <div className="ic-prd-dt me-3">
                                  <div className="ic-prd-dt-img"></div>
                                  <div className="ic-prd-dt-txt">
                                    <span></span> <span></span> <span></span>
                                  </div>
                                </div>
                                左圖右文
                              </h6>
                              <Button
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="mx-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="mx-2" />
                              </Button>
                            </div>

                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div>
                                  <div className="d-flex">
                                    <label>標題(中)：</label>
                                    <span className="flex-1">
                                      {formData.get('title')}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <label>標題(英)：</label>
                                    <span className="flex-1">
                                      {formData.get('title_en')}
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <div className="d-flex">
                                    <label>說明(中)：</label>
                                    <span className="flex-1">
                                      {formData.get('content')}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <label>說明(英)：</label>
                                    <span className="flex-1">
                                      {formData.get('content_en')}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <img src={formData.get('imageUrl')} />
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.get('type') === 'imageRight' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <div className="ic-prd-dt me-3">
                                  <div className="ic-prd-dt-txt">
                                    <span></span> <span></span> <span></span>
                                  </div>
                                  <div className="ic-prd-dt-img"></div>
                                </div>
                                左文右圖
                              </h6>
                              <Button
                                type="button"
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="mx-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="mx-2" />
                              </Button>
                            </div>

                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div>
                                  <div className="d-flex">
                                    <label>標題(中)：</label>
                                    <span className="flex-1">
                                      {formData.get('title')}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <label>說明(中)：</label>
                                    <span className="flex-1">
                                      {formData.get('content')}
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <div className="d-flex">
                                    <label>標題(英)：</label>
                                    <span className="flex-1">
                                      {formData.get('title_en')}
                                    </span>
                                  </div>
                                  <div className="d-flex">
                                    <label>說明(英)：</label>
                                    <span className="flex-1">
                                      {formData.get('content_en')}
                                    </span>
                                  </div>
                                </div>
                              </div>{' '}
                              <div className="col-sm-4">
                                <img src={formData.get('imageUrl')} />
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.get('type') === 'contentOnly' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <GrTextAlignCenter size="24" className="me-2" />
                                <span>純文字</span>
                              </h6>
                              <Button
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="m-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="m-2" />
                              </Button>
                            </div>

                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div className="d-flex">
                                  <label>標題(中)：</label>
                                  <span className="flex-1">
                                    {formData.get('title')}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <label>標題(英)：</label>
                                  <span className="flex-1">
                                    {formData.get('title_en')}
                                  </span>
                                </div>
                                <div className="d-flex">
                                  <label>說明(中)：</label>
                                  <span className="flex-1">
                                    {formData.get('content')}
                                  </span>
                                </div>

                                <div className="d-flex">
                                  <label>說明(英)：</label>
                                  <span className="flex-1">
                                    {formData.get('content_en')}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.get('type') === 'imageOnly' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <AiFillPicture size="24" className="me-2" />
                                <span>純圖片</span>
                              </h6>
                              <Button
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="m-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="m-2" />
                              </Button>
                            </div>
                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div className="col-sm-4">
                                  <div
                                    className={style.show_flex}
                                    style={{ flexWrap: 'nowrap' }}
                                  >
                                    <img src={formData.get('imageUrl')} />
                                    <div style={{ margin: '1rem' }} />
                                    <img src={formData.get('imageUrl_en')} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.get('type') === 'youtubeLink' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <BsCaretRightFill size="24" className="me-2" />
                                <span>影片</span>
                              </h6>
                              <Button
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="m-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="m-2" />
                              </Button>
                            </div>

                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div className="col-sm-4">
                                  <iframe
                                    width="560"
                                    height="315"
                                    src={
                                      `${formData.get('youtubeLink') 
                                      }?showinfo=0`
                                    }
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {formData.get('type') === 'height' && (
                          <div
                            className="card mb-3"
                            style={{ maxWidth: '800px' }}
                            key={formData.get('id')}
                          >
                            <div className="d-flex card-header">
                              <h6 className="me-auto d-flex align-items-center mb-0">
                                <CgArrowsShrinkV size="24" className="me-2" />
                                <span>高度</span>
                              </h6>
                              <Button
                                color="primary"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                onClick={() => {
                                  openEditModal(formData);
                                }}
                              >
                                <FaEdit className="m-2" />
                              </Button>
                              <Button
                                type="button"
                                onClick={() => {
                                  removeContent(formData.get('id'));
                                }}
                                color="danger"
                                className="mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              >
                                <FaTimes className="m-2" />
                              </Button>
                            </div>

                            <div className="row card-body">
                              <div className="col-sm-8">
                                <div>
                                  <label>空白高度：</label>
                                  <span className="flex-1">
                                    {formData.get('height')} px
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={style.modal}>
        <Modal
          zIndex={1000}
          centered
          size="xl"
          isOpen={isOpenModal}
          toggle={toggleModal}
          backdrop="static"
        >
          <ModalHeader className={style.modal_head} toggle={toggleModal}>
            {type === 'imageLeft' && '左圖右文 '}
            {type === 'imageRight' && '左文右圖 '}
            {type === 'contentOnly' && '純文字 '}
            {type === 'imageOnly' && '純圖片 '}
            {type === 'youtubeLink' && '影片 '}
            {type === 'height' && '空白高度 '}
            編輯內容
          </ModalHeader>
          <Formik
            initialValues={{
              content:
                editFormData && editFormData.get('content')
                  ? editFormData.get('content')
                  : '',
              content_en:
                editFormData && editFormData.get('content_en')
                  ? editFormData.get('content_en')
                  : '',
              height:
                editFormData && editFormData.get('height')
                  ? editFormData.get('height')
                  : '',
              intro_current_files: getExistFileData('ch-tw'),
              intro_current_files_en: getExistFileData('en'),
              isUpload: false,
              title:
                editFormData && editFormData.get('title')
                  ? editFormData.get('title')
                  : '',
              title_en:
                editFormData && editFormData.get('title_en')
                  ? editFormData.get('title_en')
                  : '',
              youtubeLink:
                editFormData && editFormData.get('youtubeLink')
                  ? editFormData.get('youtubeLink')
                  : '',
            }}
            onSubmit={handleModalSubmit}
            validationSchema={getValidationSchema}
          >
            {props => (
              <Form>
                <ModalBody className={style.modal_body}>
                  {type === 'imageLeft' ||
                  type === 'imageRight' ||
                  type === 'contentOnly' ? (
                    <>
                      <div className="form-group">
                        <label className="mb-1 required">標題</label>
                        <div className="mb-3">
                          <Field
                            type="text"
                            name="title"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="title"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1 required">標題(英)</label>
                        <div className="mb-3">
                          <Field
                            type="text"
                            name="title_en"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="title_en"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {type === 'imageLeft' ||
                  type === 'imageRight' ||
                  type === 'contentOnly' ? (
                    <>
                      <div className="form-group">
                        <label className="mb-1 required">說明文字</label>
                        <div className="mb-3">
                          <Field
                            name="content"
                            rows="5"
                            as="textarea"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="content"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1 required">說明文字(英)</label>
                        <div className="mb-3">
                          <Field
                            name="content_en"
                            rows="5"
                            as="textarea"
                            className="form-control"
                          />
                          <ErrorMessage
                            name="content_en"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {type === 'imageLeft' || type === 'imageRight' ? (
                    <div className="form-group">
                      <label className="mb-1 required">圖片</label>
                      <div className="mb-3">
                        <UploadFileBox
                          setFormFieldValue={props.setFieldValue}
                          formFieldName={defaultFileFileName}
                          currentFileFieldName={defaultFileCurrentName}
                          uplodModel="images"
                          displayFileWidth="960"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          isUpload="isUpload"
                          initFiles={props.values.intro_current_files}
                        />
                        <ErrorMessage
                          name="intro_current_files"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {type === 'imageOnly' ? (
                    <React.Fragment>
                      <div className="form-group">
                        <label className="mb-1 required">圖片(中)</label>
                        <div className="mb-3">
                          <UploadFileBox
                            setFormFieldValue={props.setFieldValue}
                            formFieldName={defaultFileFileName}
                            currentFileFieldName={defaultFileCurrentName}
                            uplodModel="images"
                            displayFileWidth="1920"
                            displayFileHeight="不限"
                            displayFileSize="1MB"
                            isUpload="isUpload"
                            initFiles={props.values.intro_current_files}
                          />
                          <ErrorMessage
                            name="intro_current_files"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1 required">圖片(英)</label>
                        <div className="mb-3">
                          <UploadFileBox
                            setFormFieldValue={props.setFieldValue}
                            formFieldName={defaultFileFileNameEn}
                            currentFileFieldName={defaultFileCurrentNameEn}
                            uplodModel="images"
                            displayFileWidth="1920"
                            displayFileHeight="不限"
                            displayFileSize="1MB"
                            isUpload="isUpload"
                            initFiles={props.values.intro_current_files_en}
                          />
                          <ErrorMessage
                            name="intro_current_files_en"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <></>
                  )}

                  {type === 'youtubeLink' ? (
                    <div className="form-group">
                      <label className="mb-1 required">影片嵌入碼</label>
                      <div className="mb-3">
                        <Field
                          name="youtubeLink"
                          rows="2"
                          as="textarea"
                          className="form-control"
                        />
                      </div>
                      <div>
                        【Youtube】
                        <br />
                        1_點擊【分享】按鈕，出現popup視窗
                        <br />
                        2_點擊【嵌入】按鈕，出現iframe的code
                        <br />
                        3_複製【scr】裡面的網址貼到上面輸入框裝可
                        <br />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {type === 'height' ? (
                    <div className="form-group">
                      <label className="mb-1 required">高度</label>
                      <div className="d-flex">
                        <div className="mb-3">
                          <Field
                            name="height"
                            as="input"
                            className="form-control w200"
                          />
                        </div>
                        <span className="m-2">px</span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </ModalBody>
                <ModalFooter className={style.modal_footer}>
                  <Button
                    color="link"
                    className={style.modal_button_cancel}
                    onClick={toggleModal}
                  >
                    取消
                  </Button>{' '}
                  <Button
                    type="submit"
                    color="primary"
                    className={style.modal_button_submit}
                    disabled={isSumbitLoading}
                  >
                    {isSumbitLoading && (
                      <span
                        className="btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}

                    <span className="btn-wrapper--label">儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

ProductIntro.defaultProps = {
  formDataName: 'intros', //讓form sumbit時，可接到值得欄位名稱
  formFieldName: 'intro_files',
  initContents: [], //[{id:"可辨識的key",type:"imageLeft/imageRight",title:"",title_en:"",content:"",content_en:"",image:"",imageUrl:""}]
  setFormFieldValue: null, // function for fromilk，讓formilk sumbit時可得到目前的內容，預設是抓不到
};

export default ProductIntro;
