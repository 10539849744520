import { useRequest } from 'utils/useFetch';

import {
  UUCAFE_BOTTOM_HOME_SETTING_FAIL,
  UUCAFE_BOTTOM_HOME_SETTING_REQUEST,
  UUCAFE_BOTTOM_HOME_SETTING_SUCCESS,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST,
  UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeBottomHomeSettingConstants';

/*
* @Response
"result": 
    {
      "id": 0,
      "bannerImg": "string",
      "bannerUrl": "string",
      "bannerImg_mobile": "string",
      "bannerUrl_mobile": "string",
      "linkUrl": "string",
      "linkTarget": "string",
      "updater": "string",
      "updateDateTime": "string",
    }
  
*/
export const getCafeBottomHomeSetting = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_BOTTOM_HOME_SETTING_FAIL,
      method: 'get',
      requestConstant: UUCAFE_BOTTOM_HOME_SETTING_REQUEST,
      successConstant: UUCAFE_BOTTOM_HOME_SETTING_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/bottomHome',
    });
  };
};

export const updateCafeBottomHome = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_BOTTOM_HOME_SETTING_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_BOTTOM_HOME_SETTING_UPDATE_REQUEST,
      successConstant: UUCAFE_BOTTOM_HOME_SETTING_UPDATE_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/bottomHome/update',
    });
  };
};
