import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useRef, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import TableHasNoData from '../../components/TableHasNoData';
import TableHead from '../../components/TableHead';
import {
  CheckSerialnumberCanBatchUnbind,
  UnbindShipperDt,
} from '../../redux/actions/data/warrantyActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';

const BatchUnbind = () => {
  const isSubmit = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  // 解綁設定用
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    values,
    setErrors,
    setFieldValue,
    errors,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: {
      reason: '',
      serialnumber: '',
      unbindList: [],
    },
    onReset: () => {
      setIsDisabled(false);
      isSubmit.current = false;
    },
    onSubmit: () => {
      setIsDisabled(true);
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('請輸入原因'),
    }),
  });

  const handleCheckSerialnumberCanBatchUnbind = (serialnumber = '') => {
    return new Promise(resolve => {
      if (!isSubmit.current && serialnumber.trim()) {
        if (values.unbindList.every(l => l.serialnumber !== serialnumber)) {
          isSubmit.current = true;
          CheckSerialnumberCanBatchUnbind(serialnumber)
            .then(res => {
              setFieldValue('serialnumber', '');
              setFieldValue('unbindList', [...values.unbindList, res.result]);
              isSubmit.current = false;
              resolve(true);
            })
            .catch(e => {
              MessageUtil.alertWanring(e.message);
              setErrors({
                serialnumber: e.message,
              });
              isSubmit.current = false;
              resolve(false);
            });
        } else {
          MessageUtil.alertWanring('此序號已存在列表');
          setErrors({
            serialnumber: '此序號已存在列表',
          });
          resolve(false);
        }
      }
    });
  };
  return (
    <Layout
      pageTitle="批次解除綁定"
      items={[{ isActive: false, page_name: '序號/綁定及出貨' }]}
      items={[{ isActive: false, page_name: '序號/出貨查詢' }]}
      items={[{ isActive: true, page_name: '批次解除綁定' }]}
    >
      <Card className={style.search_card}>
        <CardHeader className={`bg-white ${style.card_header}`}>
          批次解除綁定設定
        </CardHeader>
        <CardBody className={style.card_body}>
          <FormGroup className="form-group d-inline-flex align-items-start mx-3 py-2 w-100">
            <label className="required">解綁原因</label>
            <div className="px-2 flex-grow-1">
              <Input
                tag="textarea"
                rows={2}
                type="text"
                className="form-control "
                onChange={e => setFieldValue('reason', e.target.value)}
                value={values.reason}
              />
            </div>
          </FormGroup>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            type="button"
            disabled={isDisabled}
            className={style.modal_button_cancel}
            onClick={handleReset}
          >
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            disabled={Object.keys(errors).length > 0 || isDisabled}
            onClick={handleSubmit}
          >
            確認
          </Button>
        </CardFooter>
      </Card>

      {isDisabled && (
        <Card className={style.search_card}>
          <CardBody>
            <form className="d-flex flex-wrap">
              <div className="mx-3 py-2">
                <Button
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('unbindList', []);
                    setFieldValue('serialnumber', '');
                    isSubmit.current = false;
                  }}
                >
                  清除
                </Button>
              </div>
              <div className="form-group d-inline-flex align-items-center mx-3 py-2">
                <label className="required text-nowrap p-0" htmlFor="amount">
                  產品序號
                </label>
                <div className="px-2">
                  <input
                    className="form-control"
                    value={values.serialnumber}
                    tabIndex="2"
                    onBlur={e => {
                      handleCheckSerialnumberCanBatchUnbind(
                        e.target.value,
                      ).then(res => {
                        if (res) {
                          e.target.focus();
                        }
                      });
                    }}
                    onChange={e => {
                      setFieldValue('serialnumber', e.target.value);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.target.blur();
                      }
                    }}
                  />
                  {errors.serialnumber && (
                    <div className="form-error">
                      {values.serialnumber
                        ? `產品序號[${values.serialnumber}]`
                        : ''}
                      {errors.serialnumber?.replace(
                        '產品序號',
                        values.serialnumber ? '' : '產品序號',
                      )}
                    </div>
                  )}
                </div>
              </div>
            </form>
            <hr className="my-4" />
            <Row>
              <div className="col-sm-12 d-flex justify-content-between mb-3">
                <Button
                  className="btn-warning"
                  onClick={() => {
                    MessageUtil.confirm('確定取消本批次解除綁定', '', () => {
                      handleReset();
                    });
                  }}
                >
                  取消本批次解綁
                </Button>
                <div className="d-flex align-items-end">
                  <Button
                    className={style.modal_button_submit}
                    disable={isLoading}
                    disabled={!values.unbindList?.length}
                    onClick={() => {
                      MessageUtil.confirm('確定執行本批次解除綁定', '', () => {
                        setIsLoading(true);
                        UnbindShipperDt({
                          reason: values.reason,
                          shipperDtIds: (values.unbindList || []).map(
                            b => b.id,
                          ),
                        })
                          .then(res => {
                            setIsLoading(false);
                            MessageUtil.alertSuccess('完成本批次解除綁定');
                            handleReset();
                          })
                          .catch(e => {
                            MessageUtil.alertWanring(e);
                            setIsLoading(false);
                          });
                      });
                    }}
                  >
                    批次解除綁定完成
                  </Button>
                  <div className="ps-4">
                    <strong>共{values.unbindList?.length || '0'} 筆</strong>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="table-responsive mb-5">
                  <Table hover bordered striped>
                    <TableHead className={`thead-dark ${style.table_head}`}>
                      <tr>
                        <th>編號</th>
                        <th>保固序號</th>
                        <th>產品序號</th>
                        <th>國際條碼</th>
                        <th>產品名稱</th>
                        <th>產品序號綁定</th>
                        <th>
                          <div>出貨備註</div>
                          <div>出貨時間</div>
                        </th>
                        <th></th>
                      </tr>
                    </TableHead>
                    <tbody>
                      {values.unbindList?.length > 0 ? (
                        values.unbindList.map((b, index) => {
                          return (
                            <tr key={JSON.stringify(b)}>
                              <td>{index + 1}</td>
                              <td>{b?.warrantySerialNum || '--'}</td>
                              <td>{b?.serialnumber || '--'}</td>
                              <td>{b?.barCode || '--'}</td>
                              <td>{b?.productName || '--'}</td>
                              <td>{b?.statusLabel || '--'}</td>
                              <td>
                                <div> {b?.remark || '--'}</div>
                                <div>
                                  {b?.createTime
                                    ? moment(b.createTime).format(
                                        'YYYY/MM/DD HH:mm:dd',
                                      )
                                    : '--'}
                                </div>
                              </td>
                              <td>
                                <Button
                                  className="btn-outline-warning btn bg-white"
                                  onClick={() => {
                                    values.unbindList.splice(index, 1);
                                    setFieldValue(
                                      'unbindList',
                                      values.unbindList,
                                    );
                                  }}
                                >
                                  取消綁定
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableHasNoData />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>
          </CardBody>
          <CardFooter className="text-center">
            <Button
              color="light"
              className={style.modal_button_cancel}
              onClick={() => {
                history.goBack();
              }}
            >
              返回
            </Button>
          </CardFooter>
        </Card>
      )}
    </Layout>
  );
};

export default BatchUnbind;
