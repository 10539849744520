import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Tooltip,
} from 'reactstrap';
import { getBankList } from 'redux/actions/data/bankCodeAction';
import { getOrderList } from 'redux/actions/data/orderActions';
import { editATMAccount } from 'redux/actions/data/orderReturnActions';
import { editReturnFailATMAccount } from 'redux/actions/data/orderReturnActions';
import {
  ORDER_RETURN_EDIT_ATMACC_IDLE,
  ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE,
} from 'redux/constants/data/orderReturnConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const EditAccountModal = ({ isOpen, toggle, info }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.getBankList);
  const { success, error } = useSelector(state => state.editATMAccount);
  const { success: returnFailSuccess, error: returnFailError } = useSelector(
      state => state.editReturnFailATMAccount,
  );
  const [bankOptions, setBankOptions] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      atmAccBank: info.bankCode,
      atmAccNo: info.account,
      bankAccountName: info.bankAccountName || '',
      bankBranch: info.bankBranch || '',
      orderItemId: info.id,
    },
    onSubmit: values => {
      Number(info.type) === 3
          ? dispatch(editReturnFailATMAccount(values))
          : dispatch(editATMAccount(values));
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      atmAccBank: Yup.string().required('必填'),
      atmAccNo: Yup.string().required('必填'),
      bankAccountName: Yup.string().required('必填'),
      bankBranch: Yup.string().required('必填'),
    }),
  });

  useEffect(() => {
    if (data && !bankOptions) {
      let options = [];
      for (const [key, value] of Object.entries(data)) {
        options.push({
          label: `${key} ${value.name
              .replace('商業', '')
              .replace('股份有限公司', '')
              .replace('臺北代表人辦事處', '')
              .replace('有限責任', '')
              .replace('保證責任', '')}`,
          value: key,
        });
      }
      setBankOptions(options);
    }
  }, [data]);

  useEffect(() => {
    success && toggle();
    success && MessageUtil.alertSuccess('儲存成功!!');
  }, [success]);

  useEffect(() => {
    error && MessageUtil.alertWanring('儲存失敗', error);
    error && dispatch({ type: ORDER_RETURN_EDIT_ATMACC_IDLE });
  }, [error]);

  useEffect(() => {
    returnFailSuccess && toggle();
    returnFailSuccess && MessageUtil.alertSuccess('儲存成功!!');
  }, [returnFailSuccess]);

  useEffect(() => {
    returnFailError && MessageUtil.alertWanring('儲存失敗', returnFailError);
    returnFailError && dispatch({ type: ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE });
  }, [returnFailError]);

  return (
      <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className={style.modal_head}>
          修改帳號
          <Button
              type="button"
              className="position-absolute"
              size="sm"
              style={{ right: '75px', top: '15px' }}
              color={isEdit ? 'secondary' : 'primary'}
              onClick={() => {
                setIsEdit(pre => !pre);
              }}
          >
            修改
          </Button>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="px-2 d-flex">
            <label className="required w80">帳號</label>
            <Row className="flex-grow-1">
              <Col xs={12} md={4} className="mb-2">
                <Select
                    className={errors.atmAccBank && 'is-invalid'}
                    options={bankOptions}
                    isDisabled={!isEdit}
                    value={
                      bankOptions
                          ? bankOptions.filter(
                              bank => bank.value === values.atmAccBank,
                          )
                          : null
                    }
                    onChange={value => {
                      setFieldValue('atmAccBank', value.value);
                    }}
                />
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <Input
                    type="text"
                    placeholder="請填寫分行"
                    className={errors.bankBranch && 'is-invalid'}
                    value={values.bankBranch}
                    disabled={!isEdit}
                    onChange={e => {
                      setFieldValue('bankBranch', e.target.value);
                    }}
                />
              </Col>
              <Col xs={12} md={4} className="mb-2">
                <Input
                    type="text"
                    className={errors.bankAccountName && 'is-invalid'}
                    placeholder="請填寫戶名"
                    value={values.bankAccountName}
                    disabled={!isEdit}
                    onChange={e => {
                      setFieldValue('bankAccountName', e.target.value);
                    }}
                />
              </Col>
              <Col xs={12}>
                <Input
                    type="text"
                    className={errors.atmAccNo && 'is-invalid'}
                    placeholder="請填寫完整帳號"
                    value={values.atmAccNo}
                    disabled={!isEdit}
                    onChange={e => {
                      const value = e.target.value;
                      const space = /\s/g;
                      const num = value.replace(space, '');
                      setFieldValue('atmAccNo', num);
                    }}
                />
              </Col>
            </Row>
          </FormGroup>
          <div style={{ height: '200px' }}></div>
        </ModalBody>
        <ModalFooter>
          <div>
            <Button className={style.modal_button_cancel} onClick={toggle}>
              取消
            </Button>
            <Button
                color="primary"
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0}
            >
              儲存
            </Button>
          </div>
        </ModalFooter>
      </Modal>
  );
};

const TooltipItem = ({ target }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
      <Tooltip
          placement="bottom-start"
          isOpen={tooltipOpen}
          target={target}
          toggle={toggleTooltip}
          trigger="click"
      >
        此主單所有退款金額
        <br />
        (包含運費退款)
      </Tooltip>
  );
};

const TableList = ({
                     onChangePage,
                     onAction,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.getBankList);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );
  const { values, handleSubmit, setFieldValue, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
    async onSubmit(formModel) {
      onAction && onAction(formModel, handleReset);
    },
  });
  const [info, setInfo] = useState(null);

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    dispatch(getBankList());
  }, []);

  return (
      <Card className={style.card}>
        <div className="mb-3">
          <Button
              color="success"
              disabled={values.list.length <= 0}
              onClick={handleSubmit}
          >
            已退款
          </Button>
        </div>
        <div
            className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
        >
          <div className="d-flex align-items-center gap-1">
            <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                onChange={e => {
                  const val = e.target.checked;
                  setFieldValue(
                      'list',
                      val ? orderListInfo?.datas?.map(i => i.id) : [],
                  );
                }}
                checked={
                    values.list.length > 0 &&
                    values.list.length === orderListInfo?.datas?.length
                }
            />{' '}
            本頁全選
          </div>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>選擇</th>
              <th>
                <div>訂單編號</div>
                <div>退/換貨狀態</div>
              </th>
              <th>
                <div>付款狀態</div>
                <div>付款狀態日期</div>
                <div>退款方式</div>
                <div>退款金額</div>
              </th>
              <th>
                <div>補收單編號</div>
                <div>補收單金額</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>總折扣金額</div>
                <div>總銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>收件人</div>
                <div>收件人電話</div>
              </th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              <input
                                  className={style.maintain_list_checkbox}
                                  type="checkbox"
                                  onChange={e => {
                                    const val = e.target.checked;
                                    setFieldValue(
                                        'list',
                                        val
                                            ? [...values.list, i.id]
                                            : values.list.filter(j => j !== i.id),
                                    );
                                  }}
                                  checked={values.list.indexOf(i.id) > -1}
                              />
                            </td>
                            <td>
                              總金額：
                              <span className="price text-black">
                          {(i.totalAmount || '0').toLocaleString('en-US')}
                        </span>
                            </td>
                            <td colSpan="2">主單編號：{i.orderNumber}</td>
                            <td colSpan="2">
                              運費：
                              <span className="price text-black">
                          {i.deliveryCharge.toLocaleString('en-US')}
                        </span>
                              {i.deliveryChargeReturnNumber && (
                                  <span>
                            （退運費申請 -{' '}
                                    <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </span>
                              )}
                            </td>
                            <td colSpan={3}>
                              <div className="d-flex">
                                <div className="me-auto fw-bold pe-5 text-danger">
                                  <TooltipItem target={`Tooltip-${i.id}`} />
                                  <AiOutlineInfoCircle id={`Tooltip-${i.id}`} />{' '}
                                  退款總金額{' '}
                                  <span className="price text-danger">
                              {i.refundAmount.toLocaleString('en-US')}
                            </span>
                                </div>
                                <FaChevronDown id={`orderId${i.id}`} />
                              </div>
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={JSON.stringify(j)}>
                                <td></td>
                                <td className="text-nowrap">
                                  <div>{j.orderItemNumber}</div>
                                  <div>{j.order_RC_Status || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.payStatusName}</div>
                                  <div>{j.payStatusTime}</div>
                                  {i.payWay !== 1 && (
                                      <Button
                                          className={'p-0 text-decoration-none'}
                                          color="link"
                                          onClick={() => {
                                            toggleModal();
                                            setInfo({
                                              ...j,
                                              account: j.atmAccNo,
                                              bankCode: j.atmAccBank,
                                              id: j.id,
                                              type: i.orderStatus,
                                            });
                                          }}
                                      >
                                        {j.returnWay}
                                      </Button>
                                  )}
                                  {i.payWay === 1 && <div>{j.returnWay}</div>}
                                  <div className="price text-dark fw-normal text-nowrap">
                                    {j.finalPrice.toLocaleString('en-US')}
                                  </div>
                                </td>
                                <td>
                                  {j.rechargeList.map(k => (
                                      <div
                                          className={style.text_dark_info}
                                          key={JSON.stringify(k)}
                                      >
                                        {k.rechargeNumber || '--'}
                                      </div>
                                  ))}
                                  <div className="price text-dark fw-normal text-nowrap">
                                    {j.rechargeAmount.toLocaleString('en-US')}
                                  </div>
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>{j.quantity}</div>
                                  <div className="price text-black fw-normal">
                                    {j.unitPrice.toLocaleString('en-US')}
                                  </div>
                                  {/* 折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end text-primary"
                                      role="button"
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    -
                                    <div className="price text-primary fw-normal discountAmount">
                                      {j?.discountAmount?.toLocaleString('en-US') ||
                                          0}
                                    </div>
                                  </div>
                                  <div className="price text-black fw-normal discountPrice">
                                    {j?.discountPrice?.toLocaleString('en-US') || 0}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 有無商品贈品 */}
                                  <div>--</div>
                                  {/* 有無活動贈品 */}
                                  <div>--</div>
                                  {/* 折扣活動總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountActivityModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.activityDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 折價券總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.couponDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 贈品券活動序號 */}
                                  <div>--</div>
                                </td>
                                <td className="text-nowrap">
                                  <div>{j.memberPhone || '--'}</div>
                                  <div>{j.memberLevel || '--'}</div>
                                  <div>{j.name || '--'}</div>
                                  <div>{j.phone || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
        {isModalOpen && (
            <EditAccountModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                info={info}
            />
        )}
      </Card>
  );
};
const OrderReturnPayFail = () => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.editATMAccount);
  const { success: returnFailSuccess, error: returnFailError } = useSelector(
      state => state.editReturnFailATMAccount,
  );
  const [pageIsChanged, setPageIsChanged] = useState(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();

  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      keyword: '',
      orderItemPayStatus: 5,
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      startTime: null,
      timeType: '取消 / 退貨完成日期區間',
    },
    onReset: () => {
      handleSubmit();
    },
    async onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  useEffect(() => {
    success && handleSubmit();
    success && dispatch({ type: ORDER_RETURN_EDIT_ATMACC_IDLE });
  }, [success]);

  useEffect(() => {
    returnFailSuccess && handleSubmit();
    returnFailSuccess && dispatch({ type: ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE });
  }, [returnFailSuccess]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const handleAction = (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          data: formModel.list,
          method: 'post',
          url: '/Backend/Refund/RefundFinish',
        });
        if (code !== 200) {
          MessageUtil.alertWanring('發生錯誤', message);
          return;
        }
        MessageUtil.toastSuccess('送出成功！');
        handleSubmit();
        cb();
      },
      title: '確認已退款？',
    });
  };

  return (
      <Layout
          pageTitle="退款失敗"
          items={[
            { isActive: false, page_name: '退款訂單管理' },
            { isActive: true, page_name: '退款失敗' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            此頁面只出現付款狀態為【退款失敗】的訂單
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem', width: '100%' }}
                >
                  <label
                      className="col-form-label text-nowrap"
                      style={{ marginRight: '1rem' }}
                  >
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label me-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                    <option value="退貨單號">退貨單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label
                      className="col-form-label text-nowrap"
                      style={{ marginRight: '1rem' }}
                  >
                    取消 / 退貨完成日期區間
                  </label>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onChangePage={handleChangePage}
            onAction={handleAction}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderReturnPayFail;
