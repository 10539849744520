import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Button, Card, Input } from 'reactstrap';

import style from '../../styles/layout.module.css';


const SearchCard = ({
  values,
  setFieldValue,
  handleChange,
  handleReset,
  handleSubmit,
}) => {
  return (
    <Card className={style.search_card}>
      <div className={style.card_header}>搜尋條件</div>
      <div className={style.card_body}>
        <div className={style.show_flex}>
          <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
            <label>關鍵字</label>
            <Input
              className={style['form-select-shop-banner']}
              id="keyword"
              onChange={handleChange}
              value={values.keyword}
              placeholder="會員電話、姓名、電子郵件"
            />
          </div>
          <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
            <label>黑名單原因</label>
            <Input
              type="select"
              className={style['form-select-medium']}
              style={{ margin: 0 }}
              id="reason"
              onChange={handleChange}
              value={values.reason}
            >
              <option value="">全部</option>
              <option value="多次未取貨">多次未取貨</option>
              <option value="多次退貨">多次退貨</option>
              <option value="交易爭議">交易爭議</option>
              <option value="其他">其他</option>
            </Input>
          </div>
          <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
            <label>狀態</label>
            <Input
              type="select"
              className={style['form-select-short']}
              style={{ margin: 0 }}
              id="status"
              onChange={handleChange}
              value={values.status}
            >
              <option value={0}>全部</option>
              <option value={1}>生效</option>
              <option value={2}>已失效</option>
            </Input>
          </div>
          <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
            <label>建立日期</label>
            <div className={style['form-select']}>
              <DatePicker
                className="form-control"
                dateFormat={'yyyy/MM/dd'}
                selected={values.dtStart ? moment(values.dtStart).toDate() : ''}
                onChange={date => setFieldValue('dtStart', date)}
              />
            </div>
            <span style={{ margin: '1rem' }}>~</span>
            <div className={style['form-select']}>
              <DatePicker
                className="form-control"
                dateFormat={'yyyy/MM/dd'}
                selected={values.dtEnd ? moment(values.dtEnd).toDate() : ''}
                onChange={date => setFieldValue('dtEnd', date)}
                minDate={values.dtStart ? moment(values.dtStart).toDate() : ''}
              />
            </div>
          </div>
        </div>
        <div className={style.button_position}>
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              handleReset();
            }}
          >
            取消
          </Button>
          <Button
            type="button"
            className={style.modal_button_submit}
            onClick={() => {
              setFieldValue('page', 1);
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SearchCard;
