import {
  ADD_GIFTS_FAIL,
  ADD_GIFTS_IDLE,
  ADD_GIFTS_REQUEST,
  ADD_GIFTS_SUCCESS,
  GET_BINDING_GIFTS_LIST_FAIL,
  GET_BINDING_GIFTS_LIST_REQUEST,
  GET_BINDING_GIFTS_LIST_SUCCESS,
  GET_COLOR_OPTIONS_FAIL,
  GET_COLOR_OPTIONS_REQUEST,
  GET_COLOR_OPTIONS_SUCCESS,
  GET_PRODUCTBG,
  GET_PRODUCTPIC,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_FEATURES,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  IMPORT_PRODUCT_STOCK_FILE_FAIL,
  IMPORT_PRODUCT_STOCK_FILE_IDLE,
  IMPORT_PRODUCT_STOCK_FILE_REQUEST,
  IMPORT_PRODUCT_STOCK_FILE_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  NEW_PRODUCT_LIST_IDLE,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_RESET,
  PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS,
  PRODUCT_BRAND_CATEGORY_FAIL,
  PRODUCT_BRAND_CATEGORY_REQUEST,
  PRODUCT_BRAND_CATEGORY_SUCCESS,
  PRODUCT_CATEGORY_PRODUCTS_FAIL,
  PRODUCT_CATEGORY_PRODUCTS_REQUEST,
  PRODUCT_CATEGORY_PRODUCTS_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_RESET,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_EDIT_FAIL,
  PRODUCT_EDIT_REQUEST,
  PRODUCT_EDIT_RESET,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_GLOBAL_CATEGORY_FAIL,
  PRODUCT_GLOBAL_CATEGORY_REQUEST,
  PRODUCT_GLOBAL_CATEGORY_SUCCESS,
  PRODUCT_LINKLIST_FAIL,
  PRODUCT_LINKLIST_REQUEST,
  PRODUCT_LINKLIST_SUCCESS,
  PRODUCT_LINK_DETAILS_FAIL,
  PRODUCT_LINK_DETAILS_REQUEST,
  PRODUCT_LINK_DETAILS_SUCCESS,
  PRODUCT_LINK_POST_FAIL,
  PRODUCT_LINK_POST_REQUEST,
  PRODUCT_LINK_POST_RESET,
  PRODUCT_LINK_POST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_MODEL_OPTIONS_FAIL,
  PRODUCT_MODEL_OPTIONS_REQUEST,
  PRODUCT_MODEL_OPTIONS_SUCCESS,
  PRODUCT_OPTIONS_FAIL,
  PRODUCT_OPTIONS_REQUEST,
  PRODUCT_OPTIONS_STATE_UPDATE_FAIL,
  PRODUCT_OPTIONS_STATE_UPDATE_IDLE,
  PRODUCT_OPTIONS_STATE_UPDATE_REQUEST,
  PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS,
  PRODUCT_OPTIONS_SUCCESS,
  PRODUCT_PROFILE_FAIL,
  PRODUCT_PROFILE_REQUEST,
  PRODUCT_PROFILE_SUCCESS,
  PRODUCT_SPEC_DELETE_FAIL,
  PRODUCT_SPEC_DELETE_REQUEST,
  PRODUCT_SPEC_DELETE_RESET,
  PRODUCT_SPEC_DELETE_SUCCESS,
  PRODUCT_SPEC_LIST_FAIL,
  PRODUCT_SPEC_LIST_REQUEST,
  PRODUCT_SPEC_LIST_SUCCESS,
  PRODUCT_SPEC_POST_FAIL,
  PRODUCT_SPEC_POST_REQUEST,
  PRODUCT_SPEC_POST_RESET,
  PRODUCT_SPEC_POST_SUCCESS,
  SET_PRODUCT_STOCK_STATUS_FAIL,
  SET_PRODUCT_STOCK_STATUS_IDLE,
  SET_PRODUCT_STOCK_STATUS_REQUEST,
  SET_PRODUCT_STOCK_STATUS_SUCCESS,
} from '../../constants/data/productConstants';

export const productListReducer = (
  state = { loading: false, productListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, productListInfo: payload };
    case PRODUCT_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productOptionsReducer = (
  state = { productOptions: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_OPTIONS_REQUEST:
      return { loading: true };
    case PRODUCT_OPTIONS_SUCCESS:
      return { loading: false, productOptions: payload };
    case PRODUCT_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productmodelOptionsReducer = (
  state = { productModelOptions: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_MODEL_OPTIONS_REQUEST:
      return { loading: true };
    case PRODUCT_MODEL_OPTIONS_SUCCESS:
      return { loading: false, modelOptions: payload };
    case PRODUCT_MODEL_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productProfileReducer = (
  state = { loading: true, profile: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_PROFILE_REQUEST:
      return { loading: true };
    case PRODUCT_PROFILE_SUCCESS:
      return { loading: false, profile: payload };
    case PRODUCT_PROFILE_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productEditReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_EDIT_REQUEST:
      return { loading: true };
    case PRODUCT_EDIT_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_EDIT_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_EDIT_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const productBrandCategoryReducer = (
  state = { brandCategoryInfo: [], loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_BRAND_CATEGORY_REQUEST:
      return { loading: true };
    case PRODUCT_BRAND_CATEGORY_SUCCESS:
      return { brandCategoryInfo: payload, loading: false };
    case PRODUCT_BRAND_CATEGORY_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productGlobalCategoryReducer = (
  state = { loading: true, options: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_GLOBAL_CATEGORY_REQUEST:
      return { loading: true, options: [] };
    case PRODUCT_GLOBAL_CATEGORY_SUCCESS:
      return { loading: false, options: payload };
    case PRODUCT_GLOBAL_CATEGORY_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productCategoryProductListReducer = (
  state = { categoryProductListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_CATEGORY_PRODUCTS_REQUEST:
      return { loading: true };
    case PRODUCT_CATEGORY_PRODUCTS_SUCCESS:
      return { categoryProductListInfo: payload, loading: false };
    case PRODUCT_CATEGORY_PRODUCTS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//add product to gloabal category
export const globalCategoryAddProductReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_ADDTO_GLOBAL_CATEGORY_REQUEST:
      return { loading: true };
    case PRODUCT_ADDTO_GLOBAL_CATEGORY_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_ADDTO_GLOBAL_CATEGORY_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_ADDTO_GLOBAL_CATEGORY_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const productLinkListReducer = (
  state = { linkListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LINKLIST_REQUEST:
      return { loading: true };
    case PRODUCT_LINKLIST_SUCCESS:
      return { linkListInfo: payload, loading: false };
    case PRODUCT_LINKLIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productLinkDetailReducer = (
  state = { linkDetailInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LINK_DETAILS_REQUEST:
      return { loading: true };
    case PRODUCT_LINK_DETAILS_SUCCESS:
      return { linkDetailInfo: payload, loading: false };
    case PRODUCT_LINK_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productLinkPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_LINK_POST_REQUEST:
      return { loading: true };
    case PRODUCT_LINK_POST_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_LINK_POST_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_LINK_POST_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

export const productSpecListReducer = (
  state = { specListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_SPEC_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_SPEC_LIST_SUCCESS:
      return { loading: false, specListInfo: payload };
    case PRODUCT_SPEC_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const productSpecPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_SPEC_POST_REQUEST:
      return { loading: true };
    case PRODUCT_SPEC_POST_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_SPEC_POST_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_SPEC_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const productSpecDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_SPEC_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_SPEC_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_SPEC_DELETE_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_SPEC_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { features: [], productBg: '', productPicID: '' },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCTPIC:
      return { ...state, productPicID: payload };
    case GET_PRODUCTBG:
      return { ...state, productBg: payload };
    case GET_PRODUCT_FEATURES:
      return { ...state, features: payload };
    default:
      return state;
  }
};

export const productOptionsUpdateReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_OPTIONS_STATE_UPDATE_IDLE:
      return { error: null, loading: false, success: false };
    case PRODUCT_OPTIONS_STATE_UPDATE_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_OPTIONS_STATE_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case PRODUCT_OPTIONS_STATE_UPDATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const newProductListReducer = (
  state = { error: null, loading: false, productList: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case NEW_PRODUCT_LIST_IDLE:
      return { error: null, loading: false, productList: [], success: false };
    case NEW_PRODUCT_LIST_REQUEST:
      return { error: null, loading: true, productList: [], success: false };
    case NEW_PRODUCT_LIST_SUCCESS:
      return { ...state, loading: false, productList: payload, success: true };
    case NEW_PRODUCT_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const productStockFileImportReducer = (
  state = { error: null, loading: false, success: false, warning: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case IMPORT_PRODUCT_STOCK_FILE_IDLE:
      return { error: null, loading: false, success: false, warning: null };
    case IMPORT_PRODUCT_STOCK_FILE_REQUEST:
      return { ...state, loading: true };
    case IMPORT_PRODUCT_STOCK_FILE_SUCCESS:
      return { ...state, loading: false, success: true, warning: payload };
    case IMPORT_PRODUCT_STOCK_FILE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getColorOptionsReducer = (
  state = { colorOptions: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COLOR_OPTIONS_REQUEST:
      return { colorOptions: [], error: null, loading: true, success: false };
    case GET_COLOR_OPTIONS_SUCCESS:
      return { ...state, colorOptions: payload, loading: false, success: true };
    case GET_COLOR_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const setProductStockStatusReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PRODUCT_STOCK_STATUS_IDLE:
      return { error: null, loading: false, success: false };
    case SET_PRODUCT_STOCK_STATUS_REQUEST:
      return { ...state, loading: true };
    case SET_PRODUCT_STOCK_STATUS_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_PRODUCT_STOCK_STATUS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getBindingGiftsListReducer = (
  state = { error: null, gifts: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BINDING_GIFTS_LIST_REQUEST:
      return { error: null, gifts: {}, loading: true, success: false };
    case GET_BINDING_GIFTS_LIST_SUCCESS:
      return { ...state, gifts: payload, loading: false, success: true };
    case GET_BINDING_GIFTS_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const addGiftsReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_GIFTS_IDLE:
      return { error: null, loading: false, success: false };
    case ADD_GIFTS_REQUEST:
      return { ...state, loading: true };
    case ADD_GIFTS_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_GIFTS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getProductReducer = (
  state = { error: null, info: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCT_REQUEST:
      return { error: null, info: {}, loading: true, success: false };
    case GET_PRODUCT_SUCCESS:
      return { ...state, info: payload, loading: false };
    case GET_PRODUCT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
