import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useState } from 'react';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, Table } from 'reactstrap';
import { getOrderList } from 'redux/actions/data/orderActions';
import { cancelHomeDeliveryOrder } from 'redux/actions/data/orderShippingActions';
import { HOME_DELIVERY_CANCEL_IDLE } from 'redux/constants/data/orderShippingConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
                     onChangePage,
                     onToggleDiscountModal,
                     onToggleOrderRemarkModal,
                     setOrderInfo,
                     onToggleDiscountActivityModal,
                     setCurrentOrderItemId,
                   }) => {
  const dispatch = useDispatch();
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );
  const {
    loading: canceling,
    error: cancelError,
    success,
  } = useSelector(state => state.homeDeliveryCancel);
  const [orderListId, setOrderListId] = useState([]);

  useEffect(() => {
    success && MessageUtil.alertSuccess('取消成功!!');
  }, [success]);

  useEffect(() => {
    cancelError && MessageUtil.alertWanring('取消失敗', cancelError);
    cancelError && dispatch({ type: HOME_DELIVERY_CANCEL_IDLE });
  }, [cancelError]);

  const handleSubmit = () => {
    MessageUtil.submitConfirm({
      doneFun: () => {
        CommonUtil.consoleLog({ anno: 'request body', data: orderListId });
        dispatch(cancelHomeDeliveryOrder(orderListId));
      },
      title: '確定取消訂單?',
    });
  };

  return (
      <Card className={style.card}>
        <div className="mb-3" style={{ margin: '0.5rem' }}>
          <Button
              color="success"
              disabled={orderListId.length <= 0}
              onClick={handleSubmit}
          >
            聯繫不到/無人簽收
          </Button>
        </div>
        <div className="table-responsive mb-5" style={{ height: '80vh' }}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>
                <input
                    className={style.maintain_list_checkbox}
                    type="checkbox"
                    onChange={e => {
                      const checked = e.target.checked;
                      checked
                          ? setOrderListId(
                              orderListInfo?.datas?.map(order => order.id),
                          )
                          : setOrderListId([]);
                    }}
                    checked={
                        orderListId.length > 0 &&
                        orderListId.length === orderListInfo?.datas?.length
                    }
                />
                全選
              </th>
              <th>訂單編號</th>
              <th>
                <div>配送資訊</div>
              </th>
              <th>
                <div>轉單時間</div>
                <div>訂單確認時間</div>
              </th>
              <th>
                <div>交期</div>
                <div>預計出貨日</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>總銷售金額</div>
              </th>
              <th>促銷/贈品</th>
              <th>
                <div>收件人資訊</div>
              </th>
              <th>
                <div>商店訂單備註</div>
              </th>
              <th>訂購備註</th>
              <th>訂單狀態</th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 && !error ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              <input
                                  className={style.maintain_list_checkbox}
                                  type="checkbox"
                                  onChange={e => {
                                    const checked = e.target.checked;
                                    checked
                                        ? setOrderListId([...orderListId, i.id])
                                        : setOrderListId(
                                            orderListId?.filter(id => id !== i.id),
                                        );
                                  }}
                                  checked={orderListId.includes(i.id)}
                              />
                            </td>
                            <td colSpan="2">
                              <div>主單編號：{i.orderNumber}</div>
                            </td>
                            <td colSpan={10} className="text-end">
                              <FaChevronDown id={`orderId${i.id}`} />
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td></td>
                                <td>
                                  {/* 訂單編號 */}
                                  <div>{j.orderItemNumber}</div>
                                </td>
                                <td>
                                  {/* 配送資訊 */}
                                  <div>{j.logisticsWayName || '--'}</div>
                                  <div>
                                    {j.logisticsWay === 3
                                        ? j.homeDeliveryCName || '--'
                                        : '--'}
                                  </div>
                                  <div>
                                    {j.logisticsWay === 3
                                        ? j.homeDeliveryNumber || '--'
                                        : j.cvsPaymentNo || '--'}
                                  </div>
                                  <div>{'主單運費'}</div>
                                  <div>{`NT$${i.deliveryCharge}`}</div>
                                </td>
                                <td>
                                  {/* 轉單時間 */}
                                  <div>{i.orderTime || '--'}</div>
                                  {/* 訂單確認時間 */}
                                  <div>{i.checkOrderTime}</div>
                                </td>
                                <td>
                                  {/* 交期 */}
                                  <div>{j.deliveryType === 1 ? '一般' : '預購'}</div>
                                  {/* 預計出貨日 */}
                                  <div>{j.expectedDeliveryDate || '--'}</div>
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 數量 */}
                                  <div>{j.quantity}</div>
                                  {/* 總銷售金額 */}
                                  <div className="price text-black fw-normal finalPrice">
                                    {(j.finalPrice || '0').toLocaleString('en-US')}
                                  </div>
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                      }}
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    查看明細
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* 促銷/贈品 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                      }}
                                      onClick={() => {
                                        setCurrentOrderItemId(j.id);
                                        onToggleDiscountActivityModal();
                                      }}
                                  >
                                    折扣活動明細
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 收件人資訊 */}
                                  <div>{j.name || '--'}</div>
                                  <div>{j.phone || '--'}</div>
                                  <div>{`${j.cityName}${j.townName}${j.address}`}</div>
                                </td>
                                <td className="text-center">
                                  {/* 商店訂單備註 */}
                                  <div>{j.orderRemark || '--'}</div>
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        textAlign: 'center',
                                      }}
                                      onClick={() => {
                                        setOrderInfo({
                                          requestBody: {
                                            orderItemId: j.id,
                                            orderRemark: j.orderRemark,
                                          },
                                          主單編號: i.orderNumber,
                                          商品名稱: j.productname,
                                          訂單編號: j.orderItemNumber,
                                        });
                                        onToggleOrderRemarkModal();
                                      }}
                                  >
                                    編輯備註
                                  </div>
                                </td>
                                <td className="text-center">
                                  {/* 訂購備註 */}
                                  <div>{j.remark || '--'}</div>
                                </td>
                                <td>
                                  {/* 訂單狀態 */}
                                  <div>{j.orderItemStatusName || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                </tbody>
            )}
          </Table>
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
                current={orderListInfo?.current_page || 1}
                pageSize={orderListInfo?.page_size || 10}
                total={orderListInfo?.total_count || 0}
                onChange={onChangePage}
            />
          </div>
        </div>
      </Card>
  );
};

const HomeDeliveryOrderFailedList = () => {
  const dispatch = useDispatch();
  const [isSearched, setIsSearched] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [isOrderRemarkModalOpen, setIsOrderRemarkModalOpen] = useToggle();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentId, setCurrentId] = useState(null);
  const {
    loading: canceling,
    error: cancelError,
    success,
  } = useSelector(state => state.homeDeliveryCancel);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      keyword: '',
      logisticsStatus: 2,
      logisticsWay: 3,
      orderNumber: '',
      orderNumberType: '主單編號',
      orderStatus: 2,
      page: 1,
      page_size: 10,
      shippingStatus: 2,
      startTime: null,
    },
    onReset: () => {
      handleSubmit();
    },
    async onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
      !isSearched && setIsSearched(true);
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      setValues(body);
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
    } else {
      handleReset();
    }
  }, [location.search]);

  useEffect(() => {
    success && handleSubmit();
    success && dispatch({ type: HOME_DELIVERY_CANCEL_IDLE });
  }, [success]);

  const handleChangePage = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  return (
      <Layout
          pageTitle="宅配聯繫不到/無人簽收"
          items={[
            { isActive: false, page_name: '宅配訂單管理' },
            { isActive: true, page_name: '宅配聯繫不到/無人簽收' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />
            <span className={style.fs_14}>
            本頁面只出現主單【已確認、已完成】+ 出貨單【已出貨】的訂單
          </span>
          </div>
          <div>
            <AiOutlineInfoCircle />
            <span className={style.fs_14}>
            點擊【聯繫不到/無人簽收】按鈕後，訂單會自動轉成取消，進入退款流程
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className={style.show_flex}>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem', width: '100%' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-short']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="物流單號">物流單號</option>
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="訂購時間">訂購時間</option>
                    <option value="預購出貨日">預購出貨日</option>
                    <option value="確認時間">確認時間</option>
                    <option value="轉單時間">轉單時間</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      popperClassName={style['form-control-date-picker']}
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        {isSearched && (
            <TableList
                onChangePage={handleChangePage}
                onToggleDiscountModal={setIsDiscountModalOpen}
                onToggleOrderRemarkModal={setIsOrderRemarkModalOpen}
                setOrderInfo={setOrderInfo}
                setCurrentOrderItemId={setCurrentId}
                onToggleDiscountActivityModal={setIsDiscountActivityModalOpen}
            />
        )}
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
            />
        )}
        {isOrderRemarkModalOpen && orderInfo && (
            <OrderRemarkModal
                isOpen={isOrderRemarkModalOpen}
                toggle={setIsOrderRemarkModalOpen}
                info={orderInfo}
                callBack={handleSubmit}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentId}
            />
        )}
      </Layout>
  );
};

export default HomeDeliveryOrderFailedList;
