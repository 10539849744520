export const GET_COUNTRY_LIST_REQUEST = 'GET_COUNTRY_LIST_REQUEST';
export const GET_COUNYRT_LIST_SUCCESS = 'GET_COUNYRT_LIST_SUCCESS';
export const GET_COUNTRY_LIST_FAIL = 'GET_COUNTRY_LIST_FAIL';

export const GET_CITY_LIST_REQUEST = 'GET_CITY_LIST_REQUEST';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAIL = 'GET_CITY_LIST_FAIL';

export const GET_AREA_LIST_REQUEST = 'GET_AREA_LIST_REQUEST';
export const GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS';
export const GET_AREA_LIST_FAIL = 'GET_AREA_LIST_FAIL';
