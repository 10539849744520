import { useFormik } from 'formik';
import React from 'react';
import { useEffect, useMemo } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input } from 'reactstrap';
import { updateSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';
import { SEOSETTINGS_DETAIL_UPDATE_IDLE } from 'redux/constants/data/seoSettingsConstants';
import MessageUtil from 'utils/MessageUtil';

import style from '../styles/layout.module.css';

const SEOSettingsConfig = ({ info }) => {
  const { detail } = useSelector(state => state.seoSettingsDetail);
  const { loading, success, error } = useSelector(
      state => state.seoSettingsDetailUpdate,
  );
  const dispatch = useDispatch();

  const isDisable = useMemo(() => {
    return info?.typeName === '隱型賣場';
  }, [info]);

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      desc: detail.desc || '',
      desc_en: detail.desc_en || '',
      keyword: detail.keyword || '',
      keyword_en: detail.keyword_en || '',
      sourceId: info.sourceId,
      title: detail.title || '',
      title_en: detail.title_en || '',
      type: info.type,
      typeName: info.typeName,
    },
    onSubmit: values => {
      dispatch(updateSeoSettingsDetail(values));
    },
  });

  useEffect(() => {
    if (!loading) {
      success && !error && MessageUtil.toastSuccess('儲存成功!');
      !success && error && MessageUtil.alertWanring('儲存失敗', error);
      success && !error && dispatch({ type: SEOSETTINGS_DETAIL_UPDATE_IDLE });
    }
  }, [success, error]);

  const seoTitleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
  };

  return (
      <Card className={style.card}>
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>語系設定說明</span>
            <ul style={{ fontSize: '14px' }}>
              <li>
                中文(繁體)為前台的第一優先顯示語系，必填欄位之資訊不得為空值。
              </li>
              <li>其他非預設的語系若無填寫，前台將顯示中文(繁體)的文案。</li>
            </ul>
          </div>
        </Alert>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品標題(Title)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>顯示於搜尋結果上的名稱，建議使用商品名稱。</li>
                <li>建議輸入30個中文字以內。</li>
              </small>
            </ul>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  value={values.title}
                  onChange={e => setFieldValue('title', e.target.value)}
              />
            </div>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  value={values.title_en}
                  onChange={e => setFieldValue('title_en', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品關鍵字(Keywords)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>
                  建議輸入3-5組關鍵字，每組關鍵字之間請以,區隔，如關鍵字1,關鍵字2,關鍵字3
                </li>
              </small>
            </ul>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  disabled={isDisable}
                  value={values.keyword}
                  onChange={e => setFieldValue('keyword', e.target.value)}
              />
            </div>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  disabled={isDisable}
                  value={values.keyword_en}
                  onChange={e => setFieldValue('keyword_en', e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="title" style={seoTitleStyle}>
            商品描述(Description)
          </h4>
          <div style={{ marginBottom: '1.5rem' }}>
            <ul>
              <small>
                <li>顯示於搜尋結果的說明，建議輸入100個中文字以內</li>
              </small>
            </ul>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              中文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  disabled={isDisable}
                  type="textarea"
                  style={{ height: '106px' }}
                  value={values.desc}
                  onChange={e => {
                    setFieldValue('desc', e.target.value);
                  }}
              />
            </div>
          </div>
          <div
              className={style.show_flex}
              style={{ flexWrap: 'nowrap', marginBottom: '3rem' }}
          >
            <div style={{ marginRight: '1.5rem', whiteSpace: 'nowrap' }}>
              英文
            </div>
            <div style={{ width: '100%' }}>
              <Input
                  disabled={isDisable}
                  type="textarea"
                  style={{ height: '106px' }}
                  value={values.desc_en}
                  onChange={e => {
                    setFieldValue('desc_en', e.target.value);
                  }}
              />
            </div>
          </div>
        </div>
        <React.Fragment>
          {detail?.updater && (
              <div style={{ margin: '1rem' }}>
                {`異動時間： ${detail.updateDate} ${detail.updateTime}`}
                <span style={{ marginLeft: '1rem' }}>{detail.updater}</span>
              </div>
          )}
          <hr />
          <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
          >
            <Button
                color="primary"
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
            >
              儲存
            </Button>
          </div>
        </React.Fragment>
      </Card>
  );
};

export default SEOSettingsConfig;
