import Layout from 'components/Layout';
import SelectedList from 'components/giftBinding/SelectedList';
import UnselectedList from 'components/giftBinding/UnselectedList';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input } from 'reactstrap';
import { getProduct } from 'redux/actions/data/giftsActions';
import { getBindingGiftsList } from 'redux/actions/data/productActions';
import { ADD_GIFTS_IDLE } from 'redux/constants/data/productConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import useQuery from 'utils/useQuery';
import style from '../styles/layout.module.css';

const GiftsBinding = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const id = useMemo(() => {
    return query.value.id;
  }, []);

  const productType = useMemo(() => {
    return Number(query.value?.productType || 1);
  }, [query]);

  const [isSubmit, setIsSubmit] = useState(false);

  const { gifts } = useSelector(
    state => state.getBindingGiftsList,
  );
  const { info } = useSelector(state => state.getProduct);
  const { success: addSuccess, error: addError } = useSelector(
    state => state.addGifts,
  );

  const { values, setFieldValue, handleSubmit, handleChange } = useFormik({
    enableReinitialize: true,
    initialValues: {
      codes: '',
      freebieCodes: [],
      isAdded: true,
      keyword: '',
      page: 1,
      page_size: 10,
      productId: id,
      searchType: '',
    },
    onSubmit: () => {
      setIsSubmit(true);
      const body = {
        ...values,
        productType,
        freebieCodes: values.codes.split(','),
      };
      delete body.codes;
      dispatch(getBindingGiftsList(body));
    },
  });

  useEffect(() => {
    if (JSON.stringify(gifts) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: gifts });
    }
  }, [gifts]);

  useEffect(() => {
    if (addSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: ADD_GIFTS_IDLE });
      handleSubmit();
    }

    if (addError) {
      MessageUtil.alertWanring(addError);
      dispatch({ type: ADD_GIFTS_IDLE });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    // get product
    dispatch(getProduct(id , productType));
  }, []);

  return (
    <Layout
      pageTitle={'商品加入贈品頁'}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: false, page_name: '商品加入贈品頁' },
      ]}
    >
      <Card className={`${style.card} mb-5`}>
        <div className={style.show_flex}>
          <img className={style.w200} src={`${info.showImg}`} />
          <div style={{ marginLeft: '1.5rem' }}>
            <div>{`商品料號：${info?.productcode}`}</div>
            <div>{`商品名稱： ${info?.name} ${info?.color} ${info?.size}`}</div>
          </div>
        </div>
      </Card>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <div className={`${style.formGroup} mb-4`}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
                marginRight: '2rem',
              }}
            >
              <input
                type="radio"
                className={style['radio-btn']}
                checked={!values.isAdded}
                onChange={() => {
                  setFieldValue('isAdded', false);
                  setIsSubmit(false);
                }}
              />
              <span className="ml-2">未加入贈品</span>
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
              }}
            >
              <input
                type="radio"
                className={style['radio-btn']}
                checked={values.isAdded}
                onChange={() => {
                  setFieldValue('isAdded', true);
                  setIsSubmit(false);
                }}
              />
              <span className="ml-2">已加入贈品</span>
            </div>
          </div>
          <div className={style.formGroup}>
            <Input
              type="select"
              className={style['form-input']}
              style={{ margin: 0 }}
              id="searchType"
              onChange={e => {
                setFieldValue('searchType', e.target.value);
                if (e.target.value === '贈品序號') {
                  setFieldValue('keyword', '');
                }
                if (e.target.value === '所有贈品') {
                  setFieldValue('keyword', '');
                  setFieldValue('codes', '');
                }

                if (
                  e.target.value === '贈品名稱' ||
                  e.target.value === '贈品料號'
                ) {
                  setFieldValue('codes', '');
                }
              }}
            >
              <option value="所有贈品">所有贈品</option>
              <option value="贈品序號">贈品序號</option>
              <option value={'贈品名稱'}>贈品名稱</option>
              <option value={'贈品料號'}>贈品料號</option>
            </Input>
          </div>
          {values.searchType === '贈品序號' && (
            <React.Fragment>
              <div>
                <li>可同時輸入多組，請以半形逗號,區隔</li>
              </div>
              <div className={style.formGroup}>
                <Input
                  type="textarea"
                  style={{ height: '150px' }}
                  placeholder="可同時輸入多組，請以半形逗號,區隔"
                  id="codes"
                  onChange={handleChange}
                  value={values.codes}
                />
              </div>
            </React.Fragment>
          )}
          {values.searchType === '贈品料號' ||
          values.searchType === '贈品名稱' ? (
            <div className={style.formGroup}>
              <Input
                className={style['form-select-shop-banner']}
                placeholder="請輸入贈品名稱 or 請輸入贈品料號"
                id="keyword"
                onChange={handleChange}
                value={values.keyword}
              />
            </div>
          ) : (
            <></>
          )}
          <Button
            className={`${style.modal_button_submit}`}
            onClick={() => {
              setFieldValue('page', 1);
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </div>
      </Card>
      {!values.isAdded && isSubmit && (
        <UnselectedList isAdded={values.isAdded} />
      )}
      {values.isAdded && isSubmit && <SelectedList isAdded={values.isAdded} />}
    </Layout>
  );
};

export default GiftsBinding;
