import { useFormik } from 'formik';
import React, { memo, useEffect, useRef } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

import {
  deleteShipperDt,
  UpdateShipperDt,
} from '../../redux/actions/data/warrantyActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const UpdateShipBindingModal = ({
  isOpen,
  toggle,
  shipperDtId,
  codeType,
  productList,
}) => {
  const isLoadingRef = useRef({
    serialnumber: false,
    warrantySerialNum: false,
  });

  const input1Ref = useRef();
  const input2Ref = useRef();
  const timeRef = useRef();
  const isSubmit = useRef(false);

  const {
    values,
    touched,
    handleSubmit,
    setFieldValue,
    errors,
    setFieldTouched,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: {
      barcode: '',
      option: 'update',
      serialnumber: '',
      warrantySerialNum: '',
    },
    onReset: () => {
      setFieldTouched('serialnumber', false);
      setFieldTouched('warrantySerialNum', false);
    },
    onSubmit: async values => {
      if (shipperDtId) {
        if (!isSubmit.current) {
          isSubmit.current = true;

          if (values.option === 'delete') {
            MessageUtil.confirm('確定刪除綁定', '', async () => {
              try {
                await deleteShipperDt([
                  { shipperDtId: shipperDtId?.shipperDtId },
                ]);
                toggle(values.option, {
                  ...shipperDtId,
                  ...values,
                });
                MessageUtil.alertSuccess('刪除成功');
              } catch (e) {
                MessageUtil.alertWanring('刪除失敗', e.message);
              }
            });
          }
          if (values.option === 'update') {
            MessageUtil.confirm('確定更新綁定', '', async () => {
              try {
                await UpdateShipperDt({
                  ...shipperDtId,
                  ...values,
                  codeType,
                });
                toggle(values.option, {
                  ...shipperDtId,
                  barcode: values.barcode,
                  serialnumber: values.serialnumber,
                  warrantySerialNum: values.warrantySerialNum,
                });
              } catch (e) {
                MessageUtil.alertWanring(
                  e.message || '更新失敗',
                  e.warrantySerialNumResult || e.serialnumberResult,
                );
                setErrors({
                  serialnumber: e.serialnumberResult,
                  warrantySerialNum: e.warrantySerialNumResult,
                });
                isLoadingRef.current = {
                  serialnumber: e.serialnumberResult,
                  warrantySerialNum: e.warrantySerialNumResult,
                };
              }
            });
          }
        }
      }
      isSubmit.current = false;
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    validationSchema: Yup.object().shape({
      serialnumber: Yup.string().test('isInvalid', '', async function (value) {
        isSubmit.current = true;

        if (shipperDtId?.serialnumber && value !== shipperDtId?.serialnumber) {
          if (value) {
            if (productList.some(p => p.serialnumber === value)) {
              isSubmit.current = false;
              return this.createError({
                message: '產品序號已存在',
              });
            } else if (isLoadingRef.current.serialnumber) {
              isSubmit.current = false;
              return this.createError({
                message: isLoadingRef.current.serialnumber,
              });
            } else {
              isSubmit.current = false;
              return true;
            }
          } else {
            isSubmit.current = false;
            return this.createError({
              message: '請輸入產品序號',
            });
          }
        } else {
          isSubmit.current = false;
          return true;
        }
      }),
      warrantySerialNum: Yup.string().test(
        'isInvalid',
        '',
        async function (value) {
          isSubmit.current = true;

          if (
            shipperDtId?.warrantySerialNum &&
            value !== shipperDtId?.warrantySerialNum
          ) {
            if (value) {
              if (productList.some(p => p.warrantySerialNum === value)) {
                isSubmit.current = false;
                return this.createError({
                  message: '保固序號已存在',
                });
              } else if (isLoadingRef.current.warrantySerialNum) {
                isSubmit.current = false;
                return this.createError({
                  message: isLoadingRef.current.warrantySerialNum,
                });
              } else {
                isSubmit.current = false;
                return true;
              }
            } else {
              isSubmit.current = false;
              return this.createError({
                message: '請輸入保固序號',
              });
            }
          } else {
            isSubmit.current = false;
            return true;
          }
        },
      ),
    }),
  });

  useEffect(() => {
    if (!shipperDtId) {
      toggle(undefined);
    } else {
      setValues({
        ...shipperDtId,
        option: 'update',
      });
    }
  }, [shipperDtId]);

  useEffect(() => {
    isLoadingRef.current = {};
  }, [values, errors]);

  useEffect(() => {
    if (isOpen) {
      input1Ref?.current?.focus();
      isSubmit.current = false;
    }
  }, [isOpen]);
  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        綁定設定
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup}>
          <label className="required w150">設定 </label>
          <div className="form-item">
            <div className="d-flex">
              <label className="form-label me-4" htmlFor="update">
                <input
                  id="update"
                  className="me-1"
                  type="radio"
                  onChange={e => {
                    setFieldValue('option', 'update');
                  }}
                  value="update"
                  checked={values.option === 'update'}
                />
                更新資料
              </label>
              <label className="form-label" htmlFor="delete">
                <input
                  id="delete"
                  className="me-1"
                  type="radio"
                  onChange={e => {
                    setFieldValue('option', 'delete');
                  }}
                  value="delete"
                  checked={values.option === 'delete'}
                />
                刪除此筆資料
              </label>
            </div>
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="required w150">保固序號</label>
          <div>
            <input
              ref={input1Ref}
              className="form-control"
              value={values.warrantySerialNum}
              onBlur={e => setFieldTouched('warrantySerialNum', true)}
              onChange={e => {
                if (!isSubmit.current) {
                  setFieldTouched('warrantySerialNum', false);
                  setFieldValue('warrantySerialNum', e.target.value);
                }
              }}
              disabled={values.option === 'delete'}
              onKeyDown={e => {
                if (e.keyCode === 32 || e.keyCode === 16) {
                  e.preventDefault();
                }
                if (e.keyCode === 13 || e.key === 'Enter') {
                  if (timeRef.current) {
                    clearTimeout(timeRef.current);
                  }
                  timeRef.current = setTimeout(() => {
                    if (
                      values.warrantySerialNum &&
                      !errors.warrantySerialNum &&
                      !isSubmit.current
                    ) {
                      input2Ref?.current?.focus();
                    }
                    setFieldTouched('warrantySerialNum', true);
                  }, 100);
                }
              }}
            />
            {errors.warrantySerialNum && touched.warrantySerialNum && (
              <span className="text-danger">{errors.warrantySerialNum}</span>
            )}
          </div>
        </div>
        <div className={style.formGroup}>
          <label className="required w150">產品序號</label>
          <div>
            <input
              className="form-control"
              value={values.serialnumber}
              ref={input2Ref}
              disabled={values.option === 'delete'}
              onBlur={e => setFieldTouched('serialnumber', true)}
              onChange={e => {
                if (!isSubmit.current) {
                  setFieldTouched('serialnumber', false);
                  setFieldValue('serialnumber', e.target.value);
                }
              }}
              onKeyDown={e => {
                if (e.keyCode === 32) {
                  e.preventDefault();
                }
                if (e.keyCode === 13 || e.key === 'Enter') {
                  if (timeRef.current) {
                    clearTimeout(timeRef.current);
                  }
                  timeRef.current = setTimeout(() => {
                    if (
                      values.serialnumber &&
                      !errors.serialnumber &&
                      !isSubmit.current
                    ) {
                      input1Ref?.current?.focus();
                    }
                    setFieldTouched('serialnumber', true);
                  }, 100);
                }
              }}
            />
            {errors.serialnumber && touched.serialnumber && (
              <span className="text-danger">{errors.serialnumber}</span>
            )}
          </div>
        </div>
        {shipperDtId?.codeType === 2 && (
          <div className={style.formGroup}>
            <label className="w150">國際條碼 </label>
            <div>
              <input
                className="form-control"
                disabled={true}
                value={values.barcode}
              />
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className={`${style.modal_button_cancel} ms-auto`}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          color="primary"
          className={style.modal_button_submit}
          disabled={Object.keys(errors).length > 0}
          onClick={handleSubmit}
        >
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(UpdateShipBindingModal);
