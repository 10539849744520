import {
  GET_MAIL_CONTENT_FAIL,
  GET_MAIL_CONTENT_REQUEST,
  GET_MAIL_CONTENT_SUCCESS,
  GET_MAIL_RECORDS_LIST_FAIL,
  GET_MAIL_RECORDS_LIST_REQUEST,
  GET_MAIL_RECORDS_LIST_SUCCESS,
  GET_MAIL_TYPE_OPTIONS_FAIL,
  GET_MAIL_TYPE_OPTIONS_REQUEST,
  GET_MAIL_TYPE_OPTIONS_SUCCESS,
} from 'redux/constants/data/mailRecordsContants';

export const getMailRecordsListReducer = (
  state = { error: null, loading: false, mailRecordsList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MAIL_RECORDS_LIST_REQUEST:
      return {
        error: null,
        loading: true,
        mailRecordsList: {},
        success: false,
      };
    case GET_MAIL_RECORDS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        mailRecordsList: payload,
        success: true,
      };
    case GET_MAIL_RECORDS_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMailContentReducer = (
  state = { error: null, loading: false, mailContent: null, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MAIL_CONTENT_REQUEST:
      return { error: null, loading: true, mailContent: null, success: false };
    case GET_MAIL_CONTENT_SUCCESS:
      return { ...state, loading: false, mailContent: payload, success: true };
    case GET_MAIL_CONTENT_FAIL:
      return { ...state, error: null, loading: false };
    default:
      return state;
  }
};

export const getMailTypeOptionsReducer = (
  state = { error: null, loading: false, options: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MAIL_TYPE_OPTIONS_REQUEST:
      return { error: null, loading: true, options: [], success: false };
    case GET_MAIL_TYPE_OPTIONS_SUCCESS:
      return { ...state, loading: false, options: payload, success: true };
    case GET_MAIL_TYPE_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
