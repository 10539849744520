export const GROUP_MODEL_LIST_REQUEST = 'GROUP_MODEL_LIST_REQUEST';
export const GROUP_MODEL_LIST_SUCCESS = 'GROUP_MODEL_LIST_SUCCESS';
export const GROUP_MODEL_LIST_FAIL = 'GROUP_MODEL_LIST_FAIL';

export const GROUP_MODEL_GET_REQUEST = 'GROUP_MODEL_GET_REQUEST';
export const GROUP_MODEL_GET_SUCCESS = 'GROUP_MODEL_GET_SUCCESS';
export const GROUP_MODEL_GET_FAIL = 'GROUP_MODEL_GET_FAIL';

export const GROUP_MODEL_COPY_REQUEST = 'GROUP_MODEL_COPY_REQUEST';
export const GROUP_MODEL_COPY_SUCCESS = 'GROUP_MODEL_COPY_SUCCESS';
export const GROUP_MODEL_COPY_FAIL = 'GROUP_MODEL_COPY_FAIL';
export const GROUP_MODEL_COPY_RESET = 'GROUP_MODEL_COPY_RESET';

export const GROUP_MODEL_SET_SETTING_REQUEST =
  'GROUP_MODEL_SET_SETTING_REQUEST';
export const GROUP_MODEL_SET_SETTING_SUCCESS =
  'GROUP_MODEL_SET_SETTING_SUCCESS';
export const GROUP_MODEL_SET_SETTING_FAIL = 'GROUP_MODEL_SET_SETTING_FAIL';
export const GROUP_MODEL_SET_SETTING_RESET = 'GROUP_MODEL_SET_SETTING_RESET';

export const GROUP_MODEL_SET_DESC_REQUEST = 'GROUP_MODEL_SET_DESC_REQUEST';
export const GROUP_MODEL_SET_DESC_SUCCESS = 'GROUP_MODEL_SET_DESC_SUCCESS';
export const GROUP_MODEL_SET_DESC_FAIL = 'GROUP_MODEL_SET_DESC_FAIL';
export const GROUP_MODEL_SET_DESC_RESET = 'GROUP_MODEL_SET_DESC_RESET';

export const GROUP_MODEL_SET_SPEC_REQUEST = 'GROUP_MODEL_SET_SPEC_REQUEST';
export const GROUP_MODEL_SET_SPEC_SUCCESS = 'GROUP_MODEL_SET_SPEC_SUCCESS';
export const GROUP_MODEL_SET_SPEC_FAIL = 'GROUP_MODEL_SET_SPEC_FAIL';
export const GROUP_MODEL_SET_SPEC_RESET = 'GROUP_MODEL_SET_SPEC_RESET';
export const GROUP_MODEL_DELETE_SPEC_REQUEST =
  'GROUP_MODEL_DELETE_SPEC_REQUEST';
export const GROUP_MODEL_DELETE_SPEC_SUCCESS =
  'GROUP_MODEL_DELETE_SPEC_SUCCESS';
export const GROUP_MODEL_DELETE_SPEC_FAIL = 'GROUP_MODEL_DELETE_SPEC_FAIL';
export const GROUP_MODEL_DELETE_SPEC_RESET = 'GROUP_MODEL_DELETE_SPEC_RESET';

export const GROUP_MODEL_DELETE_REQUEST = 'GROUP_MODEL_DELETE_REQUEST';
export const GROUP_MODEL_DELETE_SUCCESS = 'GROUP_MODEL_DELETE_SUCCESS';
export const GROUP_MODEL_DELETE_FAIL = 'GROUP_MODEL_DELETE_FAIL';
export const GROUP_MODEL_DELETE_RESET = 'GROUP_MODEL_DELETE_RESET';

export const GROUP_MODEL_SET_ORDER_REQUEST = 'GROUP_MODEL_SET_ORDER_REQUEST';
export const GROUP_MODEL_SET_ORDER_SUCCESS = 'GROUP_MODEL_SET_ORDER_SUCCESS';
export const GROUP_MODEL_SET_ORDER_FAIL = 'GROUP_MODEL_SET_ORDER_FAIL';
export const GROUP_MODEL_SET_ORDER_RESET = 'GROUP_MODEL_SET_ORDER_RESET';
