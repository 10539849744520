import Layout from 'components/Layout';
import Tabs from 'components/Tabs';
import React, { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { getGroupModelDetail } from 'redux/actions/data/groupModelActions';
import { getModelProductOptions } from 'redux/actions/data/modelActions';
import { getSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';
import style from 'styles/layout.module.css';

import TabDesc from '../components/groupModal/TabDesc';
import TabOrder from '../components/groupModal/TabOrder';
import TabSEOSettingsConfig from '../components/groupModal/TabSEOSettingsConfig';
import TabSetting from '../components/groupModal/TabSetting';
import TabSpec from '../components/groupModal/TabSpec';

const TABS = [
  {
    name: '商品設定',
    tab: 1,
  },
  {
    name: '商品介紹',
    tab: 2,
  },
  {
    name: '商品規格',
    tab: 3,
  },
  {
    name: '付款配送方式',
    tab: 4,
  },
  {
    name: 'seo設定',
    tab: 5,
  },
];

const GroupModelDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { loading } = useSelector(state => state.groupModelDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getModelProductOptions());
    id && dispatch(getGroupModelDetail(id));
  }, [id, location?.pathname]);

  useEffect(() => {
    dispatch(getSeoSettingsDetail({ sourceId: id, type: 14 }));
  }, [location?.pathname]);

  return (
    <Layout
      pageTitle={id ? '商品編輯' : '新增商品'}
      items={[
        { isActive: false, page_name: '商品組合', to: '/GroupModel' },
        { isActive: true, page_name: '商品編輯' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          銷售時間開始後，不得再新增、刪除商品，且銷售時間的起始日不得再更動
        </div>
        <div className="d-flex gap-1">
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            銷售時間勾選【不限時間】後，不得再新增、刪除商品，且不得再更動銷售時間
          </span>
        </div>
      </Alert>
      {!loading && (
        <Tabs tabs={TABS}>
          <TabSetting />
          <TabDesc />
          <TabSpec />
          <TabOrder />
          <TabSEOSettingsConfig
            info={{ sourceId: Number(id), type: 14, typeName: '組合商品' }}
          />
        </Tabs>
      )}
    </Layout>
  );
};
export default GroupModelDetail;
