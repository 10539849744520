import Layout from 'components/Layout';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Button, Card } from 'reactstrap';
import * as Yup from 'yup';


import {
  getCafeSeoSettingsDetail,
  updateSeoSettingsDetail,
} from '../redux/actions/data/uuCafeSEOSettingsAction';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const SEOSettingsDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isSubmit = useRef(false);
  const { loading, success, error, detail } = useSelector(
    state => state.cafeSEOSettingDetail,
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      desc: Yup.string().required('必填!'),
      keyword: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        dispatch(updateSeoSettingsDetail(props.values));
      }
    },
    [dispatch],
  );

  const goBack = useCallback(() => {
    history.push('/UUCafe/SEOSettings');
  }, []);

  useEffect(() => {
    if (params) {
      dispatch(getCafeSeoSettingsDetail(params));
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        goBack();
      }
      if (error) {
        toast.warn('修改失敗');
      }
    }
  }, [loading, success, error, goBack]);

  return (
    <Layout
      pageTitle="SEO設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: false, page_name: 'SEO設定', to: '/UUCafe/SEOSettings' },
      ]}
    >
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>語系設定說明</span>
          </div>
          <ul className={style.fs_14}>
            <li>
              中文 (繁體)為前台的第一優先顯示語系，必填欄位之資訊不得為空值。
            </li>
            <li>其他非預設的語系若無填寫，前台將顯示中文 (繁體)的文案。</li>
          </ul>
        </Alert>
        {detail && (
          <Formik
            enableReinitialize
            initialValues={{
              desc: detail.desc || '',
              desc_en: detail.desc_en || '',
              id: detail.id,
              keyword: detail.keyword || '',
              keyword_en: detail.keyword_en || '',
              title: detail.title || '',
              title_en: detail.title_en || '',
            }}
            validationSchema={getValidationSchema}
          >
            {props => (
              <Form className="form-list form-label-140">
                <h4 className="title">商品標題(Title)</h4>
                <div className="form-group mb-3 mb-3">
                  <label>*中文</label>
                  <div className="form-item">
                    <Field name="title" className="form-control" />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>

                <div className="form-group mb-3 mb-3">
                  <label>英文</label>
                  <div className="form-item">
                    <Field name="title_en" className="form-control" />
                  </div>
                </div>
                <h4 className="title">商品關鍵字(Keyword)</h4>
                <div className="form-group mb-3 mb-3">
                  <label>*中文</label>
                  <div className="form-item">
                    <Field name="keyword" className="form-control" />
                    <ErrorMessage
                      name="keyword"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>

                <div className="form-group mb-3 mb-3">
                  <label>英文</label>
                  <div className="form-item">
                    <Field name="keyword_en" className="form-control" />
                  </div>
                </div>

                <h4 className="title">商品描述(Description)</h4>
                <div className="form-group mb-3 mb-3">
                  <label>*中文</label>
                  <div className="form-item">
                    <Field as="textarea" name="desc" className="form-control" />
                    <ErrorMessage
                      name="desc"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className="form-group mb-3 mb-3">
                  <label>英文</label>
                  <div className="form-item">
                    <Field
                      as="textarea"
                      name="desc_en"
                      className="form-control"
                    />
                  </div>
                </div>
                <hr />
                <div className="text-center">
                  <Button
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={goBack}
                  >
                    <span className="btn-wrapper--label">取消</span>
                  </Button>
                  <Button
                    type="button"
                    className={style.modal_button_submit}
                    disabled={!(props.isValid && props.dirty)}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span className="btn-wrapper--label">儲存</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Layout>
  );
};

export default SEOSettingsDetail;
