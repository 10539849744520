export const GET_MAIL_RECORDS_LIST_REQUEST = 'GET_MAIL_RECORDS_LIST_REQUEST';
export const GET_MAIL_RECORDS_LIST_SUCCESS = 'GET_MAIL_RECORDS_LIST_SUCCESS';
export const GET_MAIL_RECORDS_LIST_FAIL = 'GET_MAIL_RECORDS_LIST_FAIL';

export const GET_MAIL_CONTENT_REQUEST = 'GET_MAIL_CONTENT_REQUEST';
export const GET_MAIL_CONTENT_SUCCESS = 'GET_MAIL_CONTENT_SUCCESS';
export const GET_MAIL_CONTENT_FAIL = 'GET_MAIL_CONTENT_FAIL';

export const GET_MAIL_TYPE_OPTIONS_REQUEST = 'GET_MAIL_TYPE_OPTIONS_REQUEST';
export const GET_MAIL_TYPE_OPTIONS_SUCCESS = 'GET_MAIL_TYPE_OPTIONS_SUCCESS';
export const GET_MAIL_TYPE_OPTIONS_FAIL = 'GET_MAIL_TYPE_OPTIONS_FAIL';
