import {
  MODEL_LIST_REQUEST,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_FAIL,
  MODEL_PROFILE_REQUEST,
  MODEL_PROFILE_SUCCESS,
  MODEL_PROFILE_FAIL,
  MODEL_EDIT_SETTING_REQUEST,
  MODEL_EDIT_SETTING_SUCCESS,
  MODEL_EDIT_SETTING_FAIL,
  MODEL_EDIT_SETTING_RESET,
  MODEL_EDIT_DESC_REQUEST,
  MODEL_EDIT_DESC_SUCCESS,
  MODEL_EDIT_DESC_FAIL,
  MODEL_EDIT_DESC_RESET,
  MODEL_EDIT_SPEC_REQUEST,
  MODEL_EDIT_SPEC_SUCCESS,
  MODEL_EDIT_SPEC_FAIL,
  MODEL_EDIT_SPEC_RESET,
  MODEL_DELETE_SPEC_REQUEST,
  MODEL_DELETE_SPEC_SUCCESS,
  MODEL_DELETE_SPEC_FAIL,
  MODEL_DELETE_SPEC_RESET,
  MODEL_EDIT_ORDER_REQUEST,
  MODEL_EDIT_ORDER_SUCCESS,
  MODEL_EDIT_ORDER_FAIL,
  MODEL_EDIT_ORDER_RESET,
  MODEL_GLOBAL_CAT_LIST_REQUEST,
  MODEL_GLOBAL_CAT_LIST_SUCCESS,
  MODEL_GLOBAL_CAT_LIST_FAIL,
  GLOBAL_CAT_MODEL_REMOVE_REQUEST,
  GLOBAL_CAT_MODEL_REMOVE_SUCCESS,
  GLOBAL_CAT_MODEL_REMOVE_FAIL,
  GLOBAL_CAT_MODEL_REMOVE_RESET,
  GLOBAL_CAT_MODEL_ASSIGN_REQUEST,
  GLOBAL_CAT_MODEL_ASSIGN_SUCCESS,
  GLOBAL_CAT_MODEL_ASSIGN_FAIL,
  GLOBAL_CAT_MODEL_ASSIGN_RESET,
  MODEL_BRAND_OPTIONS_REQUEST,
  MODEL_BRAND_OPTIONS_SUCCESS,
  MODEL_BRAND_OPTIONS_FAIL,
  MODEL_PRODUCT_OPTIONS_REQUEST,
  MODEL_PRODUCT_OPTIONS_SUCCESS,
  MODEL_PRODUCT_OPTIONS_FAIL,
  MODEL_SELLING_STATE_UPDATE_IDLE,
  MODEL_SELLING_STATE_UPDATE_REQUEST,
  MODEL_SELLING_STATE_UPDATE_SUCCESS,
  MODEL_SELLING_STATE_UPDATE_FAIL,
  ADD_NEW_MODELS_IDLE,
  ADD_NEW_MODEL_REQUEST,
  ADD_NEW_MODEL_SUCCESS,
  ADD_NEW_MODEL_FAIL,
  MODEL_EXPORT_IDLE,
  MODEL_EXPORT_REQUEST,
  MODEL_EXPORT_SUCCESS,
  MODEL_EXPORT_FAIL,
  MODEL_IMPORT_REQUEST,
  MODEL_IMPORT_IDLE,
  MODEL_IMPORT_SUCCESS,
  MODEL_IMPORT_FAIL,
  EDIT_MODEL_AVAILABLETIME_IDLE,
  EDIT_MODEL_AVAILABLETIME_REQUEST,
  EDIT_MODEL_AVAILABLETIME_SUCCESS,
  EDIT_MODEL_AVAILABLETIME_FAIL,
  EDIT_MODEL_SHIPPING_DATE_IDLE,
  EDIT_MODEL_SHIPPING_DATE_REQUEST,
  EDIT_MODEL_SHIPPING_DATE_SUCCESS,
  EDIT_MODEL_SHIPPING_DATE_FAIL,
} from 'redux/constants/data/modelConstants';

export const modelListReducer = (
  state = { loading: false, modelListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_LIST_REQUEST:
      return { error: null, loading: true, modelListInfo: {} };
    case MODEL_LIST_SUCCESS:
      return { loading: false, modelListInfo: payload };
    case MODEL_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const modelProfileReducer = (
  state = { loading: true, profile: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_PROFILE_REQUEST:
      return { loading: true };
    case MODEL_PROFILE_SUCCESS:
      return { loading: false, profile: payload };
    case MODEL_PROFILE_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const modelEditSettingReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_EDIT_SETTING_REQUEST:
      return { ...state, loading: true };
    case MODEL_EDIT_SETTING_SUCCESS:
      return { ...state, loading: false, success: true };
    case MODEL_EDIT_SETTING_FAIL:
      return { ...state, error: payload, loading: false };
    case MODEL_EDIT_SETTING_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};
export const modelEditDescReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_EDIT_DESC_REQUEST:
      return { loading: true };
    case MODEL_EDIT_DESC_SUCCESS:
      return { loading: false, success: true };
    case MODEL_EDIT_DESC_FAIL:
      return { error: payload, loading: false };
    case MODEL_EDIT_DESC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const modelEditSpecReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_EDIT_SPEC_REQUEST:
      return { loading: true };
    case MODEL_EDIT_SPEC_SUCCESS:
      return { loading: false, success: true };
    case MODEL_EDIT_SPEC_FAIL:
      return { error: payload, loading: false };
    case MODEL_EDIT_SPEC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const modelDeleteSpecReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_DELETE_SPEC_REQUEST:
      return { loading: true };
    case MODEL_DELETE_SPEC_SUCCESS:
      return { loading: false, success: true };
    case MODEL_DELETE_SPEC_FAIL:
      return { error: payload, loading: false };
    case MODEL_DELETE_SPEC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};

export const modelEditOrderReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_EDIT_ORDER_REQUEST:
      return { loading: true };
    case MODEL_EDIT_ORDER_SUCCESS:
      return { loading: false, success: true };
    case MODEL_EDIT_ORDER_FAIL:
      return { error: payload, loading: false };
    case MODEL_EDIT_ORDER_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const modelGlobalCatListReducer = (
  state = { loading: true, modelGlobalCatListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_GLOBAL_CAT_LIST_REQUEST:
      return { loading: true };
    case MODEL_GLOBAL_CAT_LIST_SUCCESS:
      return { loading: false, modelGlobalCatListInfo: payload };
    case MODEL_GLOBAL_CAT_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const globalCatRemoveModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GLOBAL_CAT_MODEL_REMOVE_REQUEST:
      return { loading: true };
    case GLOBAL_CAT_MODEL_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case GLOBAL_CAT_MODEL_REMOVE_FAIL:
      return { error: payload, loading: false };
    case GLOBAL_CAT_MODEL_REMOVE_RESET:
      return { error: null, loading: false };
    default:
      return state;
  }
};
export const globalCatAssignModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GLOBAL_CAT_MODEL_ASSIGN_REQUEST:
      return { loading: true };
    case GLOBAL_CAT_MODEL_ASSIGN_SUCCESS:
      return { loading: false, success: true };
    case GLOBAL_CAT_MODEL_ASSIGN_FAIL:
      return { error: payload, loading: false };
    case GLOBAL_CAT_MODEL_ASSIGN_RESET:
      return { error: null, loading: false };
    default:
      return state;
  }
};

export const modelBrandOptionsReducer = (
  state = { loading: true, modelBrandOptionsInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_BRAND_OPTIONS_REQUEST:
      return { loading: true };
    case MODEL_BRAND_OPTIONS_SUCCESS:
      return { loading: false, modelBrandOptionsInfo: payload };
    case MODEL_BRAND_OPTIONS_FAIL:
      return { error: payload, loading: false, modelBrandOptionsInfo: [] };
    default:
      return state;
  }
};

export const modelProductOptionsReducer = (
  state = { loading: true, modelProductOptionsInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_PRODUCT_OPTIONS_REQUEST:
      return { loading: true };
    case MODEL_PRODUCT_OPTIONS_SUCCESS:
      return { loading: false, modelProductOptionsInfo: payload };
    case MODEL_PRODUCT_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const modelSellingStateReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_SELLING_STATE_UPDATE_IDLE:
      return { error: null, loading: false, success: false };
    case MODEL_SELLING_STATE_UPDATE_REQUEST:
      return { ...state, loading: true };
    case MODEL_SELLING_STATE_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case MODEL_SELLING_STATE_UPDATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const addNewModelReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_MODELS_IDLE:
      return { error: null, loading: false, success: false };
    case ADD_NEW_MODEL_REQUEST:
      return { ...state, loading: true };
    case ADD_NEW_MODEL_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_NEW_MODEL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const modelExportReducer = (
  state = { error: null, loading: false, success: false, url: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_EXPORT_IDLE:
      return { error: null, loading: false, success: false, url: null };
    case MODEL_EXPORT_REQUEST:
      return { ...state, loading: true };
    case MODEL_EXPORT_SUCCESS:
      return { ...state, loading: false, success: true, url: payload };
    case MODEL_EXPORT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const modelImportReducer = (
  state = { error: null, loading: false, success: false, warning: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_IMPORT_IDLE:
      return { error: null, loading: false, success: false, warning: null };
    case MODEL_IMPORT_REQUEST:
      return { ...state, loading: true };
    case MODEL_IMPORT_SUCCESS:
      return { ...state, loading: false, success: true, warning: payload };
    case MODEL_IMPORT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const editModelAvailableTimeReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_MODEL_AVAILABLETIME_IDLE:
      return { error: null, loading: false, success: false };
    case EDIT_MODEL_AVAILABLETIME_REQUEST:
      return { ...state, loading: true };
    case EDIT_MODEL_AVAILABLETIME_SUCCESS:
      return { ...state, loading: false, success: true };
    case EDIT_MODEL_AVAILABLETIME_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const editModelShippingDateReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_MODEL_SHIPPING_DATE_IDLE:
      return { error: null, loading: false, success: false };
    case EDIT_MODEL_SHIPPING_DATE_REQUEST:
      return { ...state, loading: true };
    case EDIT_MODEL_SHIPPING_DATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case EDIT_MODEL_SHIPPING_DATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
