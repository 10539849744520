import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ReactstrapInput } from 'reactstrap-formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Loader from '../components/Loader.js';
import { userLogin } from '../redux/actions/data/userActions.js';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const Login = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { location } = history;

  const { loading, userInfo, error } = useSelector(state => state.userLogin);

  const swal = withReactContent(Swal);

  // useEffect(() => {
  //   history.go(0)
  // }, [])

  // history.go(0)

  // window.location.reload()

  useEffect(() => {
    if (error && error.status === 404) {
      console.log('jjjjjj');
      swal.fire({
        // text: '請重新取得驗證信',
        icon: 'warning',
        title: '帳號/密碼錯誤',
      });
    }

    // else {
    //   console.log('bbbbbbb')
    //   swal.fire({
    //     title: '登入失敗',
    //     // text: error,
    //     icon: 'warning',
    //   })
    // }
  }, [error]);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      account: Yup.string().email('email 格式錯誤').required('必填!'),
      password: Yup.string().required('必填!'),
    });

    return schema;
  };
  const handleSubmit = values => {
    CommonUtil.consoleLog({ anno: 'request body', data: values });

    dispatch(userLogin(values));
  };

  //一律導回主頁
  const redirect = '/';

  //登出時 會記錄登出前的url
  //但萬一有切換使用者 而權限不同時 無法導回權限不同的頁面
  // const redirect = location && location.state ? location.state : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect); //*
    }
  }, [userInfo]);

  return (
    <div className={style.login_wrapper}>
      {loading ? (
        <Loader />
      ) : (
        <div className="login">
          <img src="/images/logo.svg" className="w200 mb-4" alt="" />
          <div className={style.card} style={{ padding: '2rem' }}>
            <h1
              className="text-center"
              style={{ fontWeight: '900', marginBottom: '0' }}
            >
              Login
            </h1>
            <Formik
              initialValues={{ account: '', password: '' }}
              onSubmit={handleSubmit}
              validationSchema={getValidationSchema}
              component={() => (
                <Form>
                  <Field
                    name="account"
                    placeholder="帳號"
                    type="email"
                    className="form-control"
                    component={ReactstrapInput}
                  />
                  <Field
                    name="password"
                    placeholder="密碼"
                    type="password"
                    className="form-control"
                    component={ReactstrapInput}
                  />

                  <div className="text-center py-4">
                    <Button
                      type="submit"
                      className={style.login_button}
                      disabled={loading}
                    >
                      {loading && (
                        <span
                          className="btn-wrapper--icon spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '1rem' }}
                        ></span>
                      )}
                      <span className="btn-wrapper--label">登入</span>
                    </Button>
                  </div>

                  <div className="text-center">
                    <Link to="/Forgetpwd" className="text-first">
                      忘記密碼
                    </Link>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
