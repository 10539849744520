import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';

const TableList = ({ setFieldValue, values }) => {
  const { loading, reportList } = useSelector(
    state => state.getOrderCancelReport,
  );
  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>取消原因</th>
            <th>取消筆數</th>
            <th>取消金額</th>
          </tr>
        </TableHead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {reportList.datas?.length > 0 ? (
              reportList.datas.map((data, i) => (
                <tr key={i}>
                  <td>{data.remark}</td>
                  <td>{data.masterCount}</td>
                  <td className="price text-black fw-normal">
                    {data.finalPrice_sum.toLocaleString('en-US')}
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData colNumber={3} />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default TableList;
