import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  addChannel,
  getChannelDetails,
  getChannelList,
  setChannel,
} from '../redux/actions/data/channelActions';
import { getShippingDealerList } from '../redux/actions/data/warrantyActions';
import {
  DEALER_CHANNEL_DETAILS_IDLE,
  DEALER_CHANNEL_POST_RESET,
  DEALER_DEFAULT_SET_RESET,
} from '../redux/constants/data/channelConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const ChannelControl = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const page_size = 10;

  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [dealer, setDealer] = useState([]); //*
  const [current_page, setCurrentPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [itemId, setItemId] = useState(0);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      setDealer([]);
    }
  };
  const handleOpenAddModal = () => {
    setModal(!modal);
    setEditData({ supplierName: '請選擇經銷商', suppliercode: '請選擇經銷商' });
    setItemId(0);
    setDealer([]);
  };

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, channelListInfo, error } = useSelector(
    state => state.channelList,
  );
  const { shippingDealerList, loading: shippingDealerListLoading } =
    useSelector(state => state.shippingDealerList);
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.channelPost);
  const { channelDetails } = useSelector(state => state.channelDetails);
  const { success: setSuccess } = useSelector(state => state.channelSet);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/WarrantyBrand' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/WarrantyBrand' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword || '';
      const queryStatus = query.value.status || '';
      const queryPage = query.value.page || 1;

      setCurrentPage(queryPage);
      setKeyword(queryKeyword);
      setStatus(queryStatus);

      if (queryKeyword || queryStatus) {
        //儲存搜尋及頁數
        dispatch(
          getChannelList({
            keyword: queryKeyword,
            page: queryPage,
            page_size,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getChannelList({
            page: queryPage,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      // dispatch(getShippingDealerList())
      dispatch(
        getChannelList({
          page: 1,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (!shippingDealerList) {
      dispatch(getShippingDealerList());
    }
  }, [shippingDealerList]);

  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      MessageUtil.toastSuccess('儲存成功!!');

      //編輯完要根據搜尋條件再搜尋一次
      dispatch(
        getChannelList({
          keyword: keyword || '',
          page: current_page,
          status: status || '',
        }),
      );
    }

    //代碼重複錯誤
    if (addError) {
      MessageUtil.alertWanring('儲存失敗', addError);
    }

    return () => {
      dispatch({ type: DEALER_CHANNEL_POST_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (setSuccess) {
      dispatch(
        getChannelList({
          keyword: keyword || '',
          page: current_page,
          status: status || '',
        }),
      );
    }

    return () => {
      dispatch({ type: DEALER_DEFAULT_SET_RESET });
    };
  }, [setSuccess]);

  const handleOpenEditModal = data => {
    setModal(!modal);
    setItemId(data.supplierChannelId);
  };

  useEffect(() => {
    if (!modal) {
      dispatch({ type: DEALER_CHANNEL_DETAILS_IDLE });
    }
  }, [modal]);

  useEffect(() => {
    if (channelDetails) {
      setEditData(channelDetails);
    }
  }, [channelDetails]);

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);

    history.push(
      `${location.pathname}?keyword=${keyword || ''}&status=${
        status || ''
      }&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setStatus('');

    // setDealer([])

    history.push(`${location.pathname}`);
  };

  const handleSubmit = values => {
    let initailDealer =
      values.dealer &&
      values.dealer.map((data, index) => {
        return data.value;
      });
    let currentDealer = dealer.map((data, index) => {
      return data.value;
    });

    const object = {
      channelName: values.channel || '',
      channelName_en: values.channel_en || '',
      status: values.status || '',
      supplierChannelId: itemId,
      suppliercodes: initailDealer || [],
    };

    if (typeof object.suppliercodes === 'string') {
      object.suppliercodes = [];
    }

    dispatch(addChannel(object));
  };

  const handlePageChange = page => {
    setCurrentPage(page);

    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword || ''}&status=${
          status || ''
        }&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSet = data => {
    if (!data.isDefault) {
      dispatch(setChannel(data.id));
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      channel: Yup.string().required('必填!'),
      channel_en: Yup.string().nullable().required('必填!'),
      dealer: Yup.array(),
    });
    return schema;
  };

  useEffect(() => {
    if (channelListInfo) {
      if (JSON.stringify(channelListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: channelListInfo });
      }
    }
  }, [channelListInfo]);

  return (
    <Layout
      pageTitle="經銷通路管理"
      items={[{ isActive: true, page_name: '經銷通路管理' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  id="keyword"
                  placeholder="經銷商代碼、名稱、通路"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  value={status}
                  id="status"
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="正常">正常</option>
                  <option value="停用">停用</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={handleOpenAddModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && channelListInfo && channelListInfo.total_count > 0
              ? channelListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>經銷商代碼</th>
                <th>經銷商名稱</th>
                <th>經銷通路</th>
                <th>經銷通路(英)</th>
                <th>可看總報表</th>
                <th>狀態</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {channelListInfo && channelListInfo.datas.length > 0 ? (
                  channelListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td>{data.suppliercode}</td>
                      <td>{data.supplierName}</td>
                      <td>{data.channelName}</td>
                      <td>{data.channelName_en}</td>
                      <td>
                        {data.id != 0 && (
                          <span
                            className={`channel_default ${
                              data.isDefault ? 'active' : ''
                            }`}
                            onClick={() => handleSet(data)}
                          >
                            預設
                          </span>
                        )}
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => {
                            handleOpenEditModal(data);
                            dispatch(getChannelDetails(data.supplierChannelId));
                          }}
                        >
                          編輯
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && channelListInfo && channelListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={channelListInfo.current_page}
              defaultPageSize={10}
              total={channelListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          編輯通路
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            channel:
              channelDetails && channelDetails.channelName
                ? channelDetails.channelName
                : '',
            channel_en:
              channelDetails && channelDetails.channelName_en
                ? channelDetails.channelName_en
                : '',
            dealer:
              channelDetails && channelDetails.suppliercodesWithName.length > 0
                ? channelDetails.suppliercodesWithName
                : '',
            status:
              channelDetails && channelDetails.status
                ? channelDetails.status
                : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label
                    className="required"
                    style={{ width: '150px' }}
                    htmlFor="channel"
                  >
                    通路名稱
                    <ErrorMessage
                      id="channel"
                      name="channel"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="channel" className="form-control" />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label
                    className="required"
                    style={{ width: '150px' }}
                    htmlFor="channel_en"
                  >
                    通路名稱(英)
                    <ErrorMessage
                      id="channel_en"
                      name="channel_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="channel_en" className="form-control" />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label style={{ width: '150px' }}>
                    經銷商
                    <ErrorMessage
                      name="dealer"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Select
                      isLoading={shippingDealerListLoading}
                      isDisabled={shippingDealerListLoading}
                      name="dealer"
                      isMulti
                      options={shippingDealerList}
                      onChange={option => props.setFieldValue('dealer', option)}
                      value={props.values.dealer}
                    />
                  </div>
                </div>
                <div
                  className={style.formGroup}
                  style={{ marginBottom: '50vh' }}
                >
                  <label style={{ width: '150px' }}>
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Field
                        className="my-auto me-2"
                        id="normal"
                        name="status"
                        type="radio"
                        value="正常"
                      />
                      <label htmlFor="normal" className="me-5">
                        正常
                      </label>
                      <Field
                        className="my-auto me-2"
                        id="stop"
                        name="status"
                        type="radio"
                        value="停用"
                      />
                      <label htmlFor="stop" className="me-5">
                        停用
                      </label>
                    </div>
                  </div>
                </div>
                {editData && editData.updateTime && (
                  <small>
                    異動時間：{editData.updateTime} {editData.updater}
                  </small>
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading}
                >
                  {addLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
};

export default ChannelControl;
