import axios from 'axios';
import moment from 'moment';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import {
  UPDATE_DATA_FAIL,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_TIME_FAIL,
  UPDATE_TIME_REQUEST,
  UPDATE_TIME_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  JOB_DELETE_REQUEST,
  JOB_DELETE_SUCCESS,
  JOB_DETAILS_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_LIST_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_POST_FAIL,
  JOB_POST_REQUEST,
  JOB_POST_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_SEND_FORGOT_REQUEST,
  USER_SEND_FORGOT_SUCCESS,
  USER_SEND_FORGOT_FAIL,
} from '../../constants/data/userConstants';

import { baseURL, prefix_api_path } from './api_config';


export const userLogin = userInfo => {
  return async dispatch => {
    try {
      dispatch({ type: USER_LOGIN_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Auth/login`,
        userInfo,
        config,
      );

      console.log(data);

      localStorage.setItem('backend_auth', JSON.stringify(data.result));

      dispatch({
        payload: data.result,
        type: USER_LOGIN_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error.response ? error.response : error,
        type: USER_LOGIN_FAIL,
      });
    }
  };
};

export const userLogout = () => {
  return dispatch => {
    const record = JSON.parse(localStorage.getItem('backend_auth'));
    const last_auth = {
      ...record,
      logoutTime: moment().format('yyyy-MM-DD HH:mm:ss'),
    };

    localStorage.removeItem('backend_auth');
    localStorage.setItem('last_auth', JSON.stringify(last_auth));
    dispatch({ type: USER_LOGOUT });
  };
};

//update data
export const updateData = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_DATA_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/SyncERP/SyncPrd`,
        config,
      );
      await axios.get(
        `${baseURL + prefix_api_path}/Backend/SyncERP/SyncModel`,
        config,
      );
      dispatch({
        payload: data,
        type: UPDATE_DATA_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error.response ? error.response : error,
        type: UPDATE_DATA_FAIL,
      });
    }
  };
};

//get update time
export const getUpdateTime = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_TIME_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/SyncERP/SyncPrdDatetime`,
        config,
      );

      console.log(data);
      localStorage.setItem('updateTime', JSON.stringify(data.result));

      dispatch({
        payload: data,
        type: UPDATE_TIME_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: UPDATE_TIME_FAIL,
      });
    }
  };
};

//get job list
export const getJobList = ({ page }) => {
  const url = `/Backend/JoinUsJob/list?page=${page}&page_size=10`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: JOB_LIST_FAIL,
      method: 'get',
      requestConstant: JOB_LIST_REQUEST,
      successConstant: JOB_LIST_SUCCESS,
      url,
    });
  };
};

//get job details
export const getJobDetails = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_DETAILS_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/JoinUsJob/joinusJob?id=${id}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: JOB_DETAILS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response,
        type: JOB_DETAILS_FAIL,
      });
    }
  };
};

//post job
export const postJob = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_POST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/JoinUsJob/joinusJob`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: JOB_POST_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: JOB_POST_FAIL,
      });
    }
  };
};

//delete job
export const deleteJob = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JOB_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.delete(
        `${baseURL + prefix_api_path}/Backend/JoinUsJob/joinusJob?jobId=${id}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: JOB_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response,
        type: JOB_DETAILS_FAIL,
      });
    }
  };
};

//user verify
export const verifyUser = object => {
  console.log(object);
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_VERIFY_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Auth/verify`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data.result,
        type: USER_VERIFY_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: USER_VERIFY_FAIL,
      });
    }
  };
};

//user reset password
export const resetPassword = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_RESET_PASSWORD_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      console.log(object);

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Auth/resetPwd`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: USER_RESET_PASSWORD_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: USER_RESET_PASSWORD_FAIL,
      });
    }
  };
};

//user reset password
export const forgotPassword = email => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_SEND_FORGOT_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.get(
        `${baseURL + prefix_api_path}/Backend/Auth/forgetPwd?account=${email}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: USER_SEND_FORGOT_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: USER_SEND_FORGOT_FAIL,
      });
    }
  };
};
