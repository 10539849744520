import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  deleteFAQ,
  getFAQList,
  postFAQ,
} from '../redux/actions/data/serviceActions';
import {
  INSTRUCTION_FAQ_DELETE_RESET,
  INSTRUCTION_FAQ_POST_RESET,
} from '../redux/constants/data/serviceConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function PrdInstructionsFAQ(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const { id, model } = useParams();
  const query = useQuery();

  const [modal, setModal] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [FAQId, setFAQId] = useState(1);
  const [editData, setEditData] = useState({});
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');

  const { loading, FAQListInfo, error } = useSelector(
    state => state.instructionFAQList,
  );
  const { loading: postLoading, success: postSuccess } = useSelector(
    state => state.instructionFAQPost,
  );
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.instructionFAQDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({
  //       pathname: '/Login',
  //       state: `/PrdInstructionsFAQ/${id}/${model}`,
  //     })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({
  //         pathname: '/Login',
  //         state: `/PrdInstructionsFAQ/${id}/${model}`,
  //       })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getFAQList({ brandId: id, model: model, page: 1 }));
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        setKeyword(queryKeyword);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(
          getFAQList({
            brandId: id,
            keyword: queryKeyword,
            model: model,
            page: queryPage,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getFAQList({ brandId: id, model: model, page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      dispatch(getFAQList({ brandId: id, model: model, page: 1 }));
    }
  }, [location.search]);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getFAQList({ brandId: id, model: model, page: 1 }));
      setModal(false);
    }

    return () => {
      dispatch({ type: INSTRUCTION_FAQ_POST_RESET });
    };
  }, [postSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (FAQListInfo.result.datas.length !== 1) {
        dispatch(getFAQList({ brandId: id, model: model, page: current_page }));
      } else {
        dispatch(
          getFAQList({ brandId: id, model: model, page: current_page - 1 }),
        );

        history.push(
          `${location.pathname}?keyword=${keyword}&status=${status}&page=${
            current_page - 1
          }`,
        );

        setCurrentPage(current_page - 1);
      }
    }

    return () => {
      dispatch({ type: INSTRUCTION_FAQ_DELETE_RESET });
    };
  }, [deleteSuccess]);

  const toggleModal = () => {
    setModal(!modal);
    setFAQId(0);
    setEditData(null);
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setStatus('');
    dispatch(getFAQList({ brandId: id, model, page: 1 }));
    history.push(`${location.pathname}`);
  };
  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getFAQList({ brandId: id, keyword, model, page: 1, status }));
    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };
  const toggleEditContent = data => {
    console.log(data);
    setModal(!modal);
    setFAQId(data.id);
    setEditData(data);
  };
  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteFAQ(id));
    });
  };
  const handleSubmit = values => {
    console.log(values);
    let formData = new FormData();

    formData.append('id', FAQId);
    formData.append('brandId', id);
    formData.append('model', model);
    formData.append('title', values.title);
    formData.append('title_en', values.title_en);
    formData.append('content', values.content);
    formData.append('content_en', values.content_en);
    formData.append('status', values.status);

    dispatch(postFAQ(formData));
  };
  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getFAQList({ brandId: id, keyword, model, page, status }));
    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <Layout
      pageTitle="問題管理"
      items={[
        { isActive: false, page_name: '客服中心' },
        {
          isActive: false,
          page_name: '產品使用手冊',
          to: '/ProductInstructions',
        },
        { isActive: true, page_name: '問題管理' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className="d-flex flex-wrap">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 w80" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  type="text"
                  id="keyword"
                  className="form-control"
                  placeholder="標題、內文"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>

              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 w80" htmlFor="status">
                  狀態
                </label>
                <select
                  id="status"
                  className="form-control"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option value="">全部</option>
                  <option value="上架">上架</option>
                  <option value="下架">下架</option>
                </select>
              </div>
            </div>
            <div className="float-end">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            <span className="mx-1">
              {FAQListInfo && FAQListInfo.result
                ? FAQListInfo.result.total_count
                : 0}
            </span>
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th style={{ minWidth: '120px' }}>標題</th>
                <th style={{ minWidth: '180px' }}>內容</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {FAQListInfo &&
                FAQListInfo.result &&
                FAQListInfo.result.datas.length > 0 ? (
                  FAQListInfo.result.datas.map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ wordBreak: 'keep-all' }}>{index + 1}</td>
                      <td>{item.title}</td>
                      <td style={{ whiteSpace: 'pre-wrap' }}>
                        <div
                          style={{
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 5,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {item.content}
                        </div>
                      </td>
                      <td>{item.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {item.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {item.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {item.creator}
                          </span>
                        </div>
                        {item.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {item.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => toggleEditContent(item)}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleDelete(item.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          FAQListInfo &&
          FAQListInfo.result &&
          FAQListInfo.result.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={FAQListInfo.result.current_page}
                defaultPageSize={FAQListInfo.result.page_size}
                total={FAQListInfo.result.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          編輯問題
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            content: editData ? editData.content : '',
            content_en: editData ? editData.content_en : '',
            status: editData ? editData.status : '',
            title: editData ? editData.title : '',
            title_en: editData ? editData.title_en : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    標題{' '}
                    <ErrorMessage
                      name="title"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="title" className="form-control" />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    內容{' '}
                    <ErrorMessage
                      name="content"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="content"
                      as="textarea"
                      className="form-control"
                      rows="8"
                    ></Field>
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    標題(英文){' '}
                    <ErrorMessage
                      name="title_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="title_en" className="form-control" />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    內容(英文){' '}
                    <ErrorMessage
                      name="content_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="content_en"
                      as="textarea"
                      className="form-control"
                      rows="8"
                    ></Field>
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      <div className="d-flex form-item">
                        <Field
                          className="my-auto me-2"
                          id="up"
                          name="status"
                          type="radio"
                          value="上架"
                        />
                        <label htmlFor="up" className="me-5">
                          上架
                        </label>
                        <Field
                          className="my-auto me-2"
                          id="down"
                          name="status"
                          type="radio"
                          value="下架"
                        />
                        <label htmlFor="down">下架</label>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                >
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
