import moment from 'moment';
import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { FormGroup, Input, Row } from 'reactstrap';

import style from '../../styles/layout.module.css';

const labelStyle = {
  minWidth: '80px',
  paddingRight: '5px',
  whiteSpace: 'nowrap',
};
const SearchCard = ({ values, setFieldValue, handleChange }) => {
  const goalOptions = [
    { label: '全部', value: '0' },
    { label: '行銷活動', value: '1' },
    { label: '客服補發', value: '2' },
  ];

  const getWayOptions = useMemo(() => {
    if (values.goal == 2) {
      return [
        { label: '全部', value: 0 },
        { label: '輸入券號型', value: 2 },
        { label: '系統發送型', value: 4 },
      ];
    } else {
      return [
        { label: '全部', value: 0 },
        { label: '直接領取型', value: 1 },
        { label: '輸入券號型', value: 2 },
        { label: '紅利點數兌換型', value: 3 },
        { label: '系統發送型', value: 4 },
      ];
    }
  }, [values?.goal]);

  const productRangeOptions = [
    { label: '全部', value: '' },
    { label: '全店商品', value: 'all' },
    { label: '指定商品', value: 'picked' },
  ];
  const useMinOptions = [
    { label: '全部', value: 0 },
    { label: '無門檻', value: 1 },
    { label: '滿額門檻', value: 2 },
  ];

  const timeTypeOptions = [
    { label: '全部', value: '全部' },
    { label: '開始領取日', value: '開始領取日' },
    { label: '結束領取日', value: '結束領取日' },
    { label: '開始使用日', value: '開始使用日' },
    { label: '結束使用日', value: '結束使用日' },
    { label: '活動建檔日', value: '活動建檔日' },
  ];

  return (
    <Row className="flex-wrap">
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>關鍵字</label>
        <Input
          className={style['form-select-shop-banner']}
          placeholder="活動序號、活動名稱"
          onChange={e => {
            setFieldValue('keyword', e.target.value);
          }}
          value={values.keyword}
        />
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>活動目的</label>
        <Select
          id="goal"
          type="select"
          className="flex-grow-1"
          style={{ margin: 0 }}
          defaultValue={
            goalOptions.filter(o => o.value == values.goal)[0] || null
          }
          value={goalOptions.filter(o => o.value == values.goal)[0] || null}
          options={goalOptions}
          onChange={op => {
            setFieldValue('goal', op.value);
            setFieldValue('getWay', '0');
          }}
        ></Select>
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>領取方式</label>
        <Select
          id="getWay"
          type="select"
          className="flex-grow-1"
          style={{ margin: 0 }}
          value={
            getWayOptions.filter(
              o => String(o.value) === String(values.getWay),
            )[0] || null
          }
          options={getWayOptions}
          onChange={op => {
            setFieldValue('getWay', op.value);
          }}
        ></Select>
      </FormGroup>

      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>適用商品</label>
        <Select
          id="productRange"
          type="select"
          style={{ margin: 0 }}
          className="flex-grow-1"
          options={productRangeOptions}
          value={
            productRangeOptions.filter(
              o => o.value === values.productRange,
            )[0] || null
          }
          defaultValue={
            productRangeOptions.filter(
              o => o.value === values.productRange,
            )[0] || null
          }
          onChange={op => {
            setFieldValue('productRange', op.value);
          }}
        ></Select>
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-3"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>使用門檻</label>
        <Select
          id="useMin"
          type="select"
          className="flex-grow-1"
          style={{ margin: 0 }}
          value={
            useMinOptions.filter(
              o => String(o.value) == String(values.useMin),
            )[0] || null
          }
          options={useMinOptions}
          onChange={op => {
            setFieldValue('useMin', op.value);
          }}
        ></Select>
      </FormGroup>
      <FormGroup
        className="col-sm-12 col-md-6 d-inline-flex align-items-center flex-nowrap"
        style={{ minWidth: '300px' }}
      >
        <label style={labelStyle}>日期區間</label>
        <div className="flex-wrap  flex-sm-nowrap d-inline-flex align-items-center flex-grow-1">
          <Select
            id="timeType"
            type="select"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                marginRight: '5px',
                minWidth: '150px',
              }),
            }}
            value={
              timeTypeOptions.filter(
                o => String(o.value) === String(values.timeType),
              )[0] || null
            }
            options={timeTypeOptions}
            onChange={op => {
              setFieldValue('timeType', op.value);
            }}
          ></Select>
          <DatePicker
            wrapperClassName="flex-grow-1 min-width-100px w-100"
            className="form-control"
            dateFormat={'yyyy/MM/dd'}
            selected={values.startTime ? moment(values.startTime).toDate() : ''}
            onChange={date => setFieldValue('startTime', date)}
          />
          <span className="mx-3">~</span>
          <DatePicker
            wrapperClassName="flex-grow-1 min-width-200px w-100"
            className="form-control"
            dateFormat={'yyyy/MM/dd'}
            style={{ minWidth: '100px' }}
            selected={values.endTime ? moment(values.endTime).toDate() : ''}
            onChange={date => setFieldValue('endTime', date)}
            minDate={values.startTime ? moment(values.startTime).toDate() : ''}
          />
        </div>
      </FormGroup>
      <FormGroup className="col-sm-12 d-inline-flex align-items-center">
        <label style={labelStyle}>優惠券類型</label>
        <div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="type"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.type.find(ele => ele == 1)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('type', [...values.type, 1])
                  : setFieldValue(
                      'type',
                      values.type.filter(ele => ele != 1),
                    );
              }}
            />
            <span>折價券</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="type"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.type.find(ele => ele == 2)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('type', [...values.type, 2])
                  : setFieldValue(
                      'type',
                      values.type.filter(ele => ele != 2),
                    );
              }}
            />
            <span>贈品券</span>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="col-sm-12 d-inline-flex align-items-center">
        <label style={labelStyle}>活動狀態</label>
        <div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="status"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.status.find(ele => ele == 1)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('status', [...values.status, 1])
                  : setFieldValue(
                      'status',
                      values.status.filter(ele => ele != 1),
                    );
              }}
            />
            <span>尚未開始</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="status"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.status.find(ele => ele == 2)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('status', [...values.status, 2])
                  : setFieldValue(
                      'status',
                      values.status.filter(ele => ele != 2),
                    );
              }}
            />
            <span>領取中</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="status"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.status.find(ele => ele == 3)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('status', [...values.status, 3])
                  : setFieldValue(
                      'status',
                      values.status.filter(ele => ele != 3),
                    );
              }}
            />
            <span>待使用</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="status"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.status.find(ele => ele == 4)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('status', [...values.status, 4])
                  : setFieldValue(
                      'status',
                      values.status.filter(ele => ele != 4),
                    );
              }}
            />
            <span>使用中</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="status"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.status.find(ele => ele == 5)}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('status', [...values.status, 5])
                  : setFieldValue(
                      'status',
                      values.status.filter(ele => ele != 5),
                    );
              }}
            />
            <span>已結束</span>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="col-sm-12 d-inline-flex align-items-center">
        <label style={labelStyle}>使用通路</label>
        <div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="availableWay"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availableWay.find(ele => ele === 'web')}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availableWay', [
                      ...values.availableWay,
                      'web',
                    ])
                  : setFieldValue(
                      'availableWay',
                      values.availableWay.filter(ele => ele !== 'web'),
                    );
              }}
            />
            <span>網頁版</span>
          </div>
          <div className="d-inline-flex align-items-center mx-2">
            <input
              id="availableWay"
              type="checkbox"
              className={style['radio-btn']}
              checked={values.availableWay.find(ele => ele === 'store')}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('availableWay', [
                      ...values.availableWay,
                      'store',
                    ])
                  : setFieldValue(
                      'availableWay',
                      values.availableWay.filter(ele => ele !== 'store'),
                    );
              }}
            />
            <span>門市</span>
          </div>
        </div>
      </FormGroup>
    </Row>
  );
};

export default SearchCard;
