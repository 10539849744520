import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import { CART_RESET } from 'redux/constants/data/cartConstants';
import style from 'styles/layout.module.css';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';


import { getCart, updateCart } from '../redux/actions/data/cartActions';

const CheckoutReturnContact = () => {
  const dispatch = useDispatch();
  const handleInit = () => {
    dispatch(getCart());
  };
  useEffect(() => {
    handleInit();
  }, []);
  const { loading, cartInfo, updateSuccess, error } = useSelector(
    state => state.cart,
  );

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      cart: cartInfo,
    },
    async onSubmit(formModel) {
      const params = formModel.cart.map(i => ({
        content: i.content,
        type: i.type,
      }));
      dispatch(updateCart(params));
    },
    validationSchema: Yup.object().shape({
      cart: Yup.array().of(
        Yup.object().shape({
          content: Yup.string().required('必填'),
        }),
      ),
    }),
  });
  useEffect(() => {
    updateSuccess && MessageUtil.toastSuccess('儲存成功!');
    return () => {
      dispatch({ type: CART_RESET });
    };
  }, [updateSuccess]);

  return (
    <Layout
      pageTitle="便利商店退貨聯絡人設定"
      items={[
        { isActive: false, page_name: '購物相關設定' },
        { isActive: true, page_name: '便利商店退貨聯絡人設定' },
      ]}
    >
      <Card className={style.card}>
        <Form className="position-relative">
          {loading && <BlockSpinner />}
          {cartInfo.map((i, index) => (
            <FormGroup row key={index}>
              <Label style={{ width: 150 }}>
                <span className="text-danger">* </span>
                {i.type}
              </Label>
              <Col>
                <Input
                  name={`cart[${index}].content`}
                  onChange={handleChange}
                  value={values.cart[index]?.content || ''}
                />
                {i.type === '聯絡人' && (
                  <FormText>
                    請填上身分證姓名，退貨時需核對身分，亦為超取包裹異常通知的聯繫窗口
                  </FormText>
                )}
                {i.type === '聯絡手機' && (
                  <FormText>商品退回寄件門市時通知使用</FormText>
                )}
                {errors.cart?.[index]?.content &&
                  touched.cart?.[index]?.content && (
                    <FormText color="danger" className="d-block">
                      {errors.cart?.[index]?.content}
                    </FormText>
                  )}
              </Col>
            </FormGroup>
          ))}
        </Form>
        <p className={style.fs_14}>
          異動時間：
          {cartInfo.length > 0
            ? `${cartInfo[0].updateDate} ${cartInfo[0].updateTime} ${
                cartInfo[0].updater || ''
              }`
            : ''}
        </p>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            disabled={loading}
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            {loading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </Layout>
  );
};

export default CheckoutReturnContact;
