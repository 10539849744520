import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Input, Table } from 'reactstrap';
import * as Yup from 'yup';

import {
  deleteShipperDt,
  shipperSingelCheck,
} from '../../redux/actions/data/warrantyActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import TableHasNoData from '../TableHasNoData';
import TableHead from '../TableHead';

import UpdateShipBindingModal from './UpdateShipBindingModal';

const ShippingTable = ({
  shipperMtId = 0,
  suppliercode = '',
  codeType = 1,
  amount = 0,
  setInitial,
  productList = [],
  setProductList = () => {},
}) => {
  const isLoadingRef = useRef({
    barcode: false,
    serialnumber: false,
    warrantySerialNum: false,
  });
  const focusTimeRef = useRef();
  const timeRef = useRef();
  const inputRef = useRef();
  const tempRef = useRef();
  const [selectShipperDtId, setShowModal] = useState(undefined);
  const isSubmit = useRef(false);
  const {
    values,
    touched,
    setFieldValue,
    errors,
    handleSubmit,
    setFieldTouched,
    handleReset,
    setErrors,
  } = useFormik({
    initialValues: {
      barcode: '',
      serialnumber: '',
      warrantySerialNum: '',
    },
    onReset: () => {
      setFieldTouched('barcode', false);
      setFieldTouched('serialnumber', false);
      setFieldTouched('warrantySerialNum', false);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 500);
    },
    onSubmit: async values => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      isSubmit.current = true;
      try {
        const response = await shipperSingelCheck({
          barcode: values.barcode || '',
          codeType,
          serialnumber: values.serialnumber || '',
          shipperDtId: 0,
          shipperMtId,
          suppliercode,
          warrantySerialNum: values.warrantySerialNum || '',
          warrantySerialNumDtId: '',
        });

        setProductList([
          {
            ...response.data,
            ...response.productInfo,
            shipperDtId: response.shipperDtId,
          },
          ...productList,
        ]);

        handleReset();
        isLoadingRef.current = {};

        if (inputRef.current) {
          inputRef.current.focus();
        }
      } catch (e) {
        if (e.warrantySerialNumResult) {
          MessageUtil.alertWanring(e.warrantySerialNumResult);
        } else if (e.barcodeResult) {
          MessageUtil.alertWanring(e.barcodeResult);
        } else if (e.serialnumberResult) {
          MessageUtil.alertWanring(e.serialnumberResult);
        }

        setErrors({
          barcode: e.barcodeResult,
          serialnumber: e.serialnumberResult,
          warrantySerialNum: e.warrantySerialNumResult,
        });
        isLoadingRef.current = {
          barcode: e.barcodeResult,
          serialnumber: e.serialnumberResult,
          warrantySerialNum: e.warrantySerialNumResult,
        };
      }
      timeRef.current = setTimeout(() => {
        isSubmit.current = false;
      }, 500);
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      ...(codeType === 2
        ? {
            barcode: Yup.string().test('isInvalid', '', async function (value) {
              if (value) {
                if (isLoadingRef.current.barcode) {
                  if (focusTimeRef.current) {
                    clearTimeout(focusTimeRef.current);
                  }
                  focusTimeRef.current = setTimeout(() => {
                    tempRef.current.focus();
                  }, 100);
                  return this.createError({
                    message: isLoadingRef.current.barcode,
                  });
                } else {
                  return true;
                }
              } else {
                return this.createError({
                  message: '請輸入國際條碼',
                });
              }
            }),
          }
        : {}),
      serialnumber: Yup.string().test('isInvalid', '', async function (value) {
        if (value) {
          if (productList.some(p => p.serialnumber === value)) {
            if (focusTimeRef.current) {
              clearTimeout(focusTimeRef.current);
            }
            focusTimeRef.current = setTimeout(() => {
              tempRef.current.focus();
            }, 100);
            return this.createError({
              message: '產品序號已存在',
            });
          } else if (isLoadingRef.current.serialnumber) {
            if (focusTimeRef.current) {
              clearTimeout(focusTimeRef.current);
            }
            focusTimeRef.current = setTimeout(() => {
              tempRef.current.focus();
            }, 100);
            return this.createError({
              message: isLoadingRef.current.serialnumber,
            });
          } else {
            return true;
          }
        } else {
          return this.createError({
            message: '請輸入產品序號',
          });
        }
      }),
      warrantySerialNum: Yup.string().test(
        'isInvalid',
        '',
        async function (value) {
          if (value) {
            if (productList.some(p => p.warrantySerialNum === value)) {
              if (focusTimeRef.current) {
                clearTimeout(focusTimeRef.current);
              }
              focusTimeRef.current = setTimeout(() => {
                tempRef.current.focus();
              }, 100);
              return this.createError({
                message: '保固序號已存在',
              });
            } else if (isLoadingRef.current.warrantySerialNum) {
              if (focusTimeRef.current) {
                clearTimeout(focusTimeRef.current);
              }
              focusTimeRef.current = setTimeout(() => {
                tempRef.current.focus();
              }, 100);
              return this.createError({
                message: isLoadingRef.current.warrantySerialNum,
              });
            } else {
              return true;
            }
          } else {
            return this.createError({
              message: '請輸入保固序號',
            });
          }
        },
      ),
    }),
  });

  useEffect(() => {
    inputRef?.current.focus();
  }, []);

  useEffect(() => {
    isLoadingRef.current = {};
    if (
      Object.keys(errors).length === 0 &&
      !isSubmit.current &&
      touched.serialnumber &&
      touched.warrantySerialNum
    ) {
      isSubmit.current = true;
      handleSubmit();
    }
  }, [values, touched, isSubmit.current]);

  return (
    <>
      {!!selectShipperDtId && (
        <UpdateShipBindingModal
          productList={productList}
          codeType={codeType}
          isOpen={!!selectShipperDtId}
          toggle={(option, shipper) => {
            if (shipper) {
              const index = productList.findIndex(
                p => p.shipperDtId === shipper.shipperDtId,
              );

              if (option === 'delete') {
                productList.splice(index, 1);
              }

              if (option === 'update') {
                productList.splice(index, 1, shipper);
              }
              setProductList([...productList]);
              inputRef.current?.focus();
            }
            setShowModal(undefined);
          }}
          shipperDtId={selectShipperDtId}
        />
      )}
      <input ref={tempRef} className="visually-hidden" />

      <Card className={style.search_card}>
        <CardBody className={style.card_body}>
          <form className="d-flex flex-wrap">
            <div className="mx-3 py-2">
              <Button
                className={style.modal_button_submit}
                onClick={() => {
                  handleReset();
                  isSubmit.current = false;
                }}
              >
                清除
              </Button>
            </div>

            <div className="form-group d-inline-flex align-items-center mx-3 py-2">
              <label className="required text-nowrap p-0">保固序號</label>
              <div className="px-2">
                <input
                  className="form-control"
                  ref={inputRef}
                  tabIndex="0"
                  disabled={productList.length >= amount}
                  value={values.warrantySerialNum}
                  onBlur={e => setFieldTouched('warrantySerialNum', true)}
                  onChange={e => {
                    if (!isSubmit.current) {
                      setFieldTouched('warrantySerialNum', false);
                      setFieldValue('warrantySerialNum', e.target.value);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 8) {
                      isSubmit.current = false;
                    }
                    if (e.keyCode === 32 || e.keyCode === 16) {
                      e.preventDefault();
                    }
                    if (e.keyCode === 13 || e.key === 'Enter') {
                      if (
                        values.warrantySerialNum &&
                        !errors.warrantySerialNum &&
                        !isSubmit.current
                      ) {
                        const nextElement = document.querySelector(
                          `[tabindex="${codeType === 1 ? 2 : 1}"]`,
                        );
                        if (nextElement) {
                          nextElement.focus();
                        }
                      }
                      setFieldTouched('warrantySerialNum', true);
                    }
                  }}
                />
                {productList.length < amount &&
                  errors.warrantySerialNum &&
                  touched.warrantySerialNum && (
                    <div className="form-error">
                      {values.warrantySerialNum
                        ? `保固序號[${values.warrantySerialNum}]`
                        : ''}
                      {errors.warrantySerialNum?.replace(
                        '保固序號',
                        values.warrantySerialNum ? '' : '保固序號',
                      )}
                    </div>
                  )}
              </div>
            </div>
            {codeType === 2 && (
              <div className="form-group d-inline-flex align-items-center mx-3 py-2">
                <label className="p-0" htmlFor="remark">
                  國際條碼
                </label>
                <div className="px-2">
                  <Input
                    type="text"
                    tabIndex="1"
                    disabled={productList.length >= amount}
                    className="form-control"
                    onBlur={e => setFieldTouched('barcode', true)}
                    onChange={e => {
                      if (!isSubmit.current) {
                        setFieldValue('barcode', e.target.value);
                      }
                    }}
                    value={values.barcode}
                    onKeyDown={e => {
                      if (e.keyCode === 8) {
                        isSubmit.current = false;
                      }
                      if (e.keyCode === 13 || e.key === 'Enter') {
                        if (values.barcode) {
                          const nextElement = document.querySelector(
                            `[tabindex="${2}"]`,
                          );
                          if (nextElement) {
                            nextElement.focus();
                          }
                        }
                      }
                    }}
                  />
                  {productList.length < amount &&
                    errors.barcode &&
                    touched.barcode && (
                      <div className="form-error">
                        {values.barcode ? `國際條碼[${values.barcode}]` : ''}
                        {errors.barcode?.replace(
                          '國際條碼',
                          values.barcode ? '' : '國際條碼',
                        )}
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="form-group d-inline-flex align-items-center mx-3 py-2">
              <label className="required text-nowrap p-0" htmlFor="amount">
                產品序號
              </label>
              <div className="px-2">
                <input
                  className="form-control"
                  disabled={productList.length >= amount}
                  value={values.serialnumber}
                  tabIndex="2"
                  onBlur={e => setFieldTouched('serialnumber', true)}
                  onChange={e => {
                    if (!isSubmit.current) {
                      setFieldTouched('serialnumber', false);
                      setFieldValue('serialnumber', e.target.value);
                    }
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 8) {
                      isSubmit.current = false;
                    }
                    if (e.keyCode === 32) {
                      e.preventDefault();
                    }
                    if (e.keyCode === 13 || e.key === 'Enter') {
                      if (
                        values.serialnumber &&
                        !errors.serialnumber &&
                        !isSubmit.current
                      ) {
                        if (inputRef.current) {
                          inputRef.current.focus();
                        }
                      }
                      setFieldTouched('serialnumber', true);
                    }
                  }}
                />
                {productList.length < amount &&
                  errors.serialnumber &&
                  touched.serialnumber && (
                    <div className="form-error">
                      {values.serialnumber
                        ? `產品序號[${values.serialnumber}]`
                        : ''}
                      {errors.serialnumber?.replace(
                        '產品序號',
                        values.serialnumber ? '' : '產品序號',
                      )}
                    </div>
                  )}
              </div>
            </div>
          </form>
          <hr className="my-4" />
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-between mb-3">
              <Button
                className="btn-warning"
                disabled={productList.length === 0}
                onClick={() => {
                  isSubmit.current = true;
                  MessageUtil.confirm('確定取消本批次綁定', '', async () => {
                    try {
                      await deleteShipperDt(
                        productList.map(p => {
                          return { shipperDtId: p.shipperDtId };
                        }),
                      );
                      setProductList([]);
                      MessageUtil.alertSuccess('已成功解除綁定');
                      handleReset();
                    } catch (e) {
                      MessageUtil.alertWanring(e.message);
                    }
                    setTimeout(() => {
                      isSubmit.current = false;
                    }, 1000);
                  });
                }}
              >
                取消本批次綁定
              </Button>
              <div className="d-flex align-items-end">
                <Button
                  className={style.modal_button_submit}
                  onClick={() => {
                    handleReset();
                    setProductList([]);
                    setInitial();
                    isSubmit.current = false;
                  }}
                >
                  批次綁定完成
                </Button>
                <div className="ps-4">
                  <strong>共{productList?.length || '0'} 筆</strong>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="table-responsive">
                <Table hover striped bordered className="mb-5">
                  <TableHead>
                    <tr>
                      <th>編號</th>
                      <th>保固序號</th>
                      <th>產品序號</th>
                      {codeType === 2 && <th>國際條碼</th>}
                      <th>產品名稱</th>
                      <th>狀態</th>
                      <th></th>
                    </tr>
                  </TableHead>
                  <tbody>
                    {productList?.length > 0 ? (
                      productList.map((p, index) => {
                        return (
                          <tr key={JSON.stringify(p)}>
                            <td data-id={p.shipperDtId} className="text-center">
                              {productList?.length - index}
                            </td>
                            <td>{p.warrantySerialNum}</td>
                            <td>{p.serialnumber}</td>
                            {codeType === 2 && <td>{p.barcode}</td>}
                            <td>{p.productname}</td>
                            <td>{p.status || '已綁定'} </td>
                            <td>
                              <Button
                                className="btn-outline-warning btn bg-white"
                                onClick={() => {
                                  setShowModal(p);
                                }}
                              >
                                更新綁定資料
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <TableHasNoData />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ShippingTable;
