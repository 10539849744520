import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_SORT_FAIL,
  CATEGORY_SORT_REQUEST,
  CATEGORY_SORT_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAIL,
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
} from '../../constants/data/categoryConstants';

//get category list
export const getCategoryList = (cateName, status, pageNumber) => {
  const url = `/Backend/Product/category/list?${
    cateName ? `cateName=${cateName}` : ''
  }&${status ? `status=${status}` : ''}&${
    pageNumber ? `page=${pageNumber}` : ''
  }&order=asc&order_by=sort`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: CATEGORY_LIST_FAIL,
      method: 'get',
      requestConstant: CATEGORY_LIST_REQUEST,
      successConstant: CATEGORY_LIST_SUCCESS,
      url,
    });
  };
};

//add category
export const addCategory = editInfo => {
  const url = '/Backend/Product/category';
  CommonUtil.consoleLog({ anno: 'request body', data: editInfo });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: editInfo,
      dispatch,
      failConstant: CATEGORY_ADD_FAIL,
      method: 'post',
      requestConstant: CATEGORY_ADD_REQUEST,
      successConstant: CATEGORY_ADD_SUCCESS,
      url,
    });
  };
};

//update category
export const updateCategory = editInfo => {
  const url = '/Backend/Product/category';
  CommonUtil.consoleLog({ anno: 'request body', data: editInfo });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: editInfo,
      dispatch,
      failConstant: CATEGORY_UPDATE_FAIL,
      method: 'put',
      requestConstant: CATEGORY_UPDATE_REQUEST,
      successConstant: CATEGORY_UPDATE_SUCCESS,
      url,
    });
  };
};

//delete category
export const deleteCategory = id => {
  const url = `/Backend/Product/category?id=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: CATEGORY_DELETE_FAIL,
      method: 'delete',
      requestConstant: CATEGORY_DELETE_REQUEST,
      successConstant: CATEGORY_DELETE_SUCCESS,
      url,
    });
  };
};

//update category sort
export const updateCategorySort = array => {
  const url = '/Backend/Product/category/updateSort';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: array,
      dispatch,
      failConstant: CATEGORY_SORT_FAIL,
      method: 'post',
      requestConstant: CATEGORY_SORT_REQUEST,
      successConstant: CATEGORY_SORT_SUCCESS,
      url,
    });
  };
};

export const createProductCategory = data => {
  const url = '/Backend/Product/category/add';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_PRODUCT_CATEGORY_FAIL,
      method: 'post',
      requestConstant: CREATE_PRODUCT_CATEGORY_REQUEST,
      successConstant: CREATE_PRODUCT_CATEGORY_SUCCESS,
      url,
    });
  };
};


export const ExportGroupProductCat1Sort = async (cat1Id = '') => {
  return await request({
    method: 'get',
    params: {
      cat1Id,
    },
    url: '/Backend/GlobalCatGroupModelRel/ExportCat1Sort'
  }).then(res => {
    if (String(res.code) === '200') {
      return res;
    } else {
      throw res;
    }
  });
};

export const ImportGroupProductCat1Sort = async (data = new FormData()) => {
  return await request({
    data,
    method: 'port',
    url: '/Backend/GlobalCatGroupModelRel/ImportCat1Sort'
  }).then(res => {
    if (String(res.code) === '200') {
      if(res.result){
        throw res.result;
      }
      return res;
    } else {
      throw res;
    }
  });
};


export const ExportProductCat1Sort = async (cat1Id = '') => {
  return await request({
    method: 'get',
    params: {
      cat1Id,
    },
    url: '/Backend/GlobalCatModelRel/ExportCat1Sort'
  }).then(res => {
    if (String(res.code) === '200') {
      return res;
    } else {
      throw res;
    }
  });
};

export const ExportProductCat1SortV2 = async (cat1Id = '') => {
  return await request({
    method: 'get',
    params: {
      cat1Id,
    },
    url: '/Backend/GlobalCatModelRel/ExportCat1SortV2'
  }).then(res => {
    if (String(res.code) === '200') {
      return res;
    } else {
      throw res;
    }
  });
};


export const ImportProductCat1Sort = async  (data = new FormData()) => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/GlobalCatModelRel/ImportCat1Sort'
  }).then(res => {
    if (String(res.code) === '200') {
      return res;
    } else {
      throw res;
    }
  });
};


export const ImportProductCat1SortV2 = async  (data = new FormData()) => {
  return await request({
    data,
    method: 'post',
    url: '/Backend/GlobalCatModelRel/ImportCat1SortV2'
  }).then(res => {
    if (String(res.code) === '200') {
      if(res.result){
        throw res.result;
      }
      return res;
    } else {
      throw res;
    }
  });
};

