import {
  UUCAFE_OTHER_PRODUCT_ADD_FAIL,
  UUCAFE_OTHER_PRODUCT_ADD_REQUEST,
  UUCAFE_OTHER_PRODUCT_ADD_SUCCESS,

  UUCAFE_OTHER_PRODUCT_FAIL,
  UUCAFE_OTHER_PRODUCT_LIST_FAIL,
  UUCAFE_OTHER_PRODUCT_LIST_REQUEST,
  UUCAFE_OTHER_PRODUCT_LIST_SUCCESS,
  UUCAFE_OTHER_PRODUCT_REQUEST,
  UUCAFE_OTHER_PRODUCT_SEO_FAIL,
  UUCAFE_OTHER_PRODUCT_SEO_REQUEST,
  UUCAFE_OTHER_PRODUCT_SEO_SUCCESS,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_SUCCESS,
  UUCAFE_OTHER_PRODUCT_UPDATE_FAIL,
  UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST,
  UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_PAGE_FAIL,
  UUCAFE_OTHER_PRODUCT_PAGE_REQUEST,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS,
} from '../../constants/data/uuCafeOtherProductConstants';

// ============ MEALINTRO_Menu ==================

export const cafeMealOtherProductListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUCAFE_OTHER_PRODUCT_LIST_REQUEST:
      return {
        error: null,
        list: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeMealOtherProductReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      id: 0,
      imgFile: '',
      imgUrl: '',
      menuId: 0,
      name: '',
      name_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_OTHER_PRODUCT_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          id: 0,
          imgFile: '',
          imgUrl: '',
          menuId: 0,
          name: '',
          name_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_OTHER_PRODUCT_ADD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_Other_Product_PAGE  ==================

export const cafeMealOtherProductPageReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      introImgBanner: '',
      introImgBannerUrl: '',
      introImgBannerUrl_mobile: '',
      introImgBanner_mobile: '',
      title: '',
      title_en: '',
      updateDateTime: '',
      updater: '',
      zoneImgBanner: '',
      zoneImgBannerUrl: '',
      zoneImgBannerUrl_mobile: '',
      zoneImgBanner_mobile: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_OTHER_PRODUCT_PAGE_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          introImgBanner: '',
          introImgBannerUrl: '',
          introImgBannerUrl_mobile: '',
          introImgBanner_mobile: '',
          title: '',
          title_en: '',
          updateDateTime: '',
          updater: '',
          zoneImgBanner: '',
          zoneImgBannerUrl: '',
          zoneImgBannerUrl_mobile: '',
          zoneImgBanner_mobile: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_PAGE_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_Other_Product_SEO ==================

export const cafeMealOtherProductSEOReducer = (
  state = {
    detail: {
      desc: '',
      desc_en: '',
      keyword: '',
      keyword_en: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_OTHER_PRODUCT_SEO_REQUEST:
      return {
        detail: {
          desc: '',
          desc_en: '',
          keyword: '',
          keyword_en: '',
          title: '',
          title_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_SEO_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_SEO_FAIL:
      return { ...state, error: payload || true, loading: false };

    case UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
