import Layout from 'components/Layout';
import EditModal from 'components/blockList/EditModal';
import SearchCard from 'components/blockList/SearchCard';
import TableList from 'components/blockList/TableList';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import { getBlockList } from 'redux/actions/data/blockListActions';
import { SAVE_BLOCK_LIST_IDLE } from 'redux/constants/data/blockListConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import style from '../styles/layout.module.css';

const BlockList = () => {
  const [currentId, setCurrentId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useToggle();
  const { loading, blockList } = useSelector(state => state.getBlockList);
  const [prevDatas, setPrevDatas] = useState(null);
  const { location } = history;
  const dispatch = useDispatch();
  const query = useQuery();
  const {
    loading: saveLoading,
    success: saveSuccess,
    error: saveError,
  } = useSelector(state => state.saveBlockList);
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      dtEnd: '',
      dtStart: '',
      keyword: '',
      page: 1,
      page_size: 10,
      reason: '',
      status: 0,
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      const body = {
        ...values,
        dtEnd: values.dtEnd ? moment(values.dtEnd).format('yyyy/MM/DD') : '',
        dtStart: values.dtStart
          ? moment(values.dtStart).format('yyyy/MM/DD')
          : '',
      };

      if (CommonUtil.isValueChanged(prevDatas, body, setPrevDatas)) {
        const params = new URLSearchParams({
          ...body,
          dtEnd: values.dtEnd ? JSON.stringify(body.dtEnd) : '',
          dtStart: values.dtStart ? JSON.stringify(body.dtStart) : '',
          keyword: values.keyword ? JSON.stringify(body.keyword) : '',
        });

        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        dispatch(getBlockList(body));
      }
    },
  });

  const pageChangedHandler = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  useEffect(() => {
    if (location.search) {
      const body = {
        ...query.value,
        dtEnd: query.value.dtEnd ? JSON.parse(query.value.dtEnd) : '',
        dtStart: query.value.dtStart ? JSON.parse(query.value.dtStart) : '',
        keyword: query.value.keyword ? JSON.parse(query.value.keyword) : '',
      };
      dispatch(getBlockList(body));
      setValues({
        ...values,
        ...body,
      });
    } else {
      handleReset();
    }
  }, [location.search]);

  useEffect(() => {
    if (saveSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: SAVE_BLOCK_LIST_IDLE });
      setIsEditModalOpen();
      handleSubmit();
    }

    if (saveError) {
      MessageUtil.alertWanring(saveError);
      dispatch({ type: SAVE_BLOCK_LIST_IDLE });
    }
  }, [saveSuccess, saveError]);

  return (
    <Layout
      pageTitle="交易黑名單管理"
      items={[{ isActive: false, page_name: '交易黑名單管理' }]}
    >
      <SearchCard
        values={values}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleReset={handleReset}
      />
      <Card className={style.card_body}>
        <div className={style.show_flex}>
          <div className={style.show_flex}>
            <Button
              className="me-2 mb-2"
              color="success"
              onClick={() => {
                setIsEdit(false);
                setCurrentId(0);
                setIsEditModalOpen();
              }}
            >
              新增黑名單
            </Button>
            {/* <Button
              className="me-2 mb-2"
              color="success"
            >
              黑名單條件設定
            </Button> */}
          </div>
          <div style={{ margin: 'auto' }} />
          <Button
            className="me-2 mb-2"
            color="warning"
            style={{ color: 'white' }}
            onClick={() => {
              history.push('/BlockListBatchFile');
            }}
          >
            批次新增黑名單
          </Button>
        </div>
        <TableList
          setCurrentId={setCurrentId}
          setIsEditModalOpen={setIsEditModalOpen}
          setIsEdit={setIsEdit}
        />
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={blockList.current_page || 1}
            defaultPageSize={blockList.page_size || 10}
            total={blockList.total_count}
            onChange={pageChangedHandler}
          />
        </div>
      </Card>
      {isEditModalOpen && (
        <EditModal
          isOpen={isEditModalOpen}
          toggle={setIsEditModalOpen}
          data={currentId}
          isEdit={isEdit}
        />
      )}
    </Layout>
  );
};

export default BlockList;
