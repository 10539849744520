import Layout from 'components/Layout';
import SearchCard from 'components/voucherList/SearchCard';
import VoucherTable from 'components/voucherList/VoucherTable';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Row,
} from 'reactstrap';
import { getVoucherList } from 'redux/actions/data/voucherActions';
import history from 'utils/createHistory';

import { getGiftsList } from '../redux/actions/data/giftsActions';
import style from '../styles/layout.module.css';

const VoucherList = () => {
  const { location } = history;
  const dispatch = useDispatch();
  const { voucherList } = useSelector(state => state.getVoucherList);

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableWay: [], // 使用通路
      endTime: '',
      getWay: 0, // 領取方式
      goal: 0, // 活動目的
      keyword: '',
      page: 1,
      page_size: 10,
      productRange: '', // 適用商品
      startTime: '',
      status: [], // 活動狀態
      timeType: '全部',
      type: [], // 優惠券類型
      useMin: 0, // 使用門檻
    },
    onReset: () => {
      dispatch(
          getGiftsList({
            isSell: null,
            keyword: '',
            page: 1,
            page_size: 10,
            stock: 0,
            stockCompareType: 0,
            timeType: '全部',
          }),
      );
    },
    onSubmit: () => {
      const body = {
        ...values,
        endTime: values.endTime
            ? moment(values.endTime).format('YYYY/MM/DD')
            : moment().format('YYYY/MM/DD HH:mm:ss'),
        startTime: values.startTime
            ? moment(values.startTime).format('YYYY/MM/DD')
            : moment().subtract(7, 'day').format('YYYY/MM/DD HH:mm:ss'),
      };

      const params = new URLSearchParams({
        ...body,
        availableWay: JSON.stringify(body.availableWay),
        endTime: JSON.stringify(body.endTime),
        startTime: JSON.stringify(body.startTime),
        status: JSON.stringify(body.status),
        type: JSON.stringify(body.type),
      });
      history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
      );
    },
  });

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams);

      const body = {
        ...params,
        availableWay: params.availableWay
            ? JSON.parse(params.availableWay)
            : [],
        endTime: params.endTime
            ? JSON.parse(params.endTime)
            : moment().format('YYYY/MM/DD HH:mm:ss'),
        getWay: ~~params.getWay || 0,
        goal: ~~params.goal || 0,
        keyword: params?.keyword || '',
        page: ~~params.page || 1,
        page_size: ~~params.page_size || 10,
        productRange: params?.productRange || '',
        startTime: params.startTime
            ? JSON.parse(params.startTime)
            : moment().subtract(7, 'days').format('YYYY/MM/DD HH:mm:ss'),
        status: params.status ? JSON.parse(params.status) : [],
        timeType: params?.timeType || '全部',
        type: params.type ? JSON.parse(params.type) : [],
        useMin: ~~params.useMin || 0,
      };
      dispatch(getVoucherList(body));
      setValues(body);
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  const searchHandler = useCallback(() => {
    setFieldValue('page', 1);
    handleSubmit();
  }, []);

  const pageChangeHandler = useCallback(page => {
    setFieldValue('page', page);
    handleSubmit();
  }, []);

  return (
      <Layout
          pageTitle={'優惠券'}
          items={[
            { isActive: true, page_name: '行銷活動' },
            { isActive: false, page_name: '優惠券' },
          ]}
      >
        <Card className={style.search_card}>
          <CardHeader
              className={style.card_header}
              style={{ background: 'white' }}
          >
            搜尋條件
          </CardHeader>
          <CardBody className={style.card_body}>
            <SearchCard
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
            />
          </CardBody>
          <CardFooter className="text-end border-0">
            <Button className={style.modal_button_cancel} onClick={handleReset}>
              取消
            </Button>
            <Button className={style.modal_button_submit} onClick={searchHandler}>
              搜尋
            </Button>
          </CardFooter>
        </Card>
        <Card className={style.card}>
          <Row className="mb-2">
            <div className="col-md-6 d-flex justify-content-start  align-items-center">
              <Button
                  color="success"
                  onClick={() => history.push('/VoucherSettings?type=create')}
              >
                新增優惠券
              </Button>
              <div className="mx-1" />
              <Button
                  color="warning"
                  onClick={() => history.push('/ProductNoneApplySettings')}
              >
                設定不適用任何折價券商品
              </Button>
            </div>
            <div className="d-flex justify-content-end  align-items-center col-md-6">
              每頁
              <Input
                  type="select"
                  name="page_size"
                  style={{ margin: '0.3rem', width: '80px' }}
                  onChange={event => {
                    const value = event.target.value;
                    setFieldValue('page', 1);
                    setFieldValue('page_size', value);
                    handleSubmit();
                  }}
                  value={values.page_size}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Input>
              {`筆，共${voucherList.total_count || 0}筆`}
            </div>
          </Row>
          <VoucherTable />
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
                current={voucherList?.current_page || 1}
                pageSize={voucherList?.page_size || 50}
                style={{ borderColor: '#446' }}
                total={voucherList?.total_count || 0}
                onChange={pageChangeHandler}
            />
          </div>
        </Card>
      </Layout>
  );
};

export default VoucherList;
