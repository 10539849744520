import Layout from 'components/Layout';
import { useEffect, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Label, Spinner, Table } from 'reactstrap';
import { PAYMENT_LIST_SAVE_RESET } from 'redux/constants/data/paymentConstants';
import MessageUtil from 'utils/MessageUtil';


import {
  getPaymentList,
  savePaymentList,
} from '../redux/actions/data/paymentActions';
import style from '../styles/layout.module.css';

const CheckoutPayment = () => {
  const dispatch = useDispatch();
  const { loading, paymentList } = useSelector(state => state.paymentList);
  const handleGetPaymentList = async () => {
    await dispatch(getPaymentList());
  };
  useEffect(() => {
    handleGetPaymentList();
  }, []);
  useEffect(() => {
    setChecked(paymentList.map(i => ({ id: i.id, isDisabled: i.isDisabled })));
  }, [paymentList]);

  const [checked, setChecked] = useState([]);
  const handleSelectDisabled = (e, id) => {
    const list = checked.map(i => ({
      id: i.id,
      isDisabled: id === i.id ? e.target.checked : i.isDisabled,
    }));
    setChecked(list);
  };

  const { success } = useSelector(state => state.paymentListSave);
  const handleSubmit = async () => {
    // console.log(paymentList);
    await dispatch(savePaymentList(checked));
    handleGetPaymentList();
  };

  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功!');
    return () => {
      dispatch({ type: PAYMENT_LIST_SAVE_RESET });
    };
  }, [success]);

  return (
    <Layout
      pageTitle="付款設定"
      items={[
        { isActive: false, page_name: '購物相關設定' },
        { isActive: true, page_name: '付款設定' },
      ]}
    >
      <Alert color="secondary">
        <AiOutlineInfoCircle />{' '}
        <span>
          {' '}
          配送方式套用全部商品，若各別商品需排除配送方式，請至【商品管理&gt;商品編輯&gt;付款配送方式】設定
        </span>
      </Alert>
      <Card className={style.card}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th style={{ textAlign: 'left' }}>付款方式</th>
                <th style={{ textAlign: 'left' }}>可搭配的配送方式</th>
                <th>停用</th>
              </tr>
            </thead>
            <tbody className={style.table_body}>
              {paymentList.length > 0 &&
                paymentList.map((i, index) => (
                  <tr key={i.id}>
                    <td>{i.payWayName}</td>
                    <td>{i.logisticsWayNames.split(',').join('、')}</td>
                    <td
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {i.isDisabled}
                      <Label check>
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0.1rem',
                          }}
                        >
                          <input
                            type="checkbox"
                            defaultChecked={i.isDisabled}
                            onChange={e => handleSelectDisabled(e, i.id)}
                          />{' '}
                          <div style={{ marginLeft: '0.3rem' }}>停用</div>
                        </div>
                      </Label>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {loading && (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          )}
          <p className={style.fs_14}>
            異動時間：
            {paymentList.length > 0
              ? `${paymentList[0].updateDate} ${paymentList[0].updateTime} ${paymentList[0].updater}`
              : ''}
          </p>
          <hr />
          <div className="text-center">
            <Button
              color="primary"
              disabled={loading}
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              {loading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              儲存
            </Button>
          </div>
        </div>
      </Card>
    </Layout>
  );
};

export default CheckoutPayment;
