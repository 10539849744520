import {
  SERVICE_DETAILS_FAIL,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_OPTION_FAIL,
  SERVICE_OPTION_REQUEST,
  SERVICE_OPTION_SUCCESS,
  SERVICE_STORE_LIST_FAIL,
  SERVICE_STORE_LIST_REQUEST,
  SERVICE_STORE_LIST_SUCCESS,
  SERVICE_STORE_UPDATE_FAIL,
  SERVICE_STORE_UPDATE_REQUEST,
  SERVICE_STORE_UPDATE_RESET,
  SERVICE_STORE_UPDATE_SUCCESS,
  SERVICE_TYPE_DELETE_FAIL,
  SERVICE_TYPE_DELETE_REQUEST,
  SERVICE_TYPE_DELETE_RESET,
  SERVICE_TYPE_DELETE_SUCCESS,
  SERVICE_TYPE_LIST_FAIL,
  SERVICE_TYPE_LIST_REQUEST,
  SERVICE_TYPE_LIST_SUCCESS,
  SERVICE_TYPE_POST_FAIL,
  SERVICE_TYPE_POST_REQUEST,
  SERVICE_TYPE_POST_RESET,
  SERVICE_TYPE_POST_SUCCESS,
} from '../../constants/data/storeConstants';

//get service type list
export const serviceTypeListReducer = (
  state = { serviceTypeListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_TYPE_LIST_REQUEST:
      return { loading: true };
    case SERVICE_TYPE_LIST_SUCCESS:
      return { loading: false, serviceTypeListInfo: payload };
    case SERVICE_TYPE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post service type
export const serviceTypePostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_TYPE_POST_REQUEST:
      return { loading: true };
    case SERVICE_TYPE_POST_SUCCESS:
      return { loading: false, success: true };
    case SERVICE_TYPE_POST_FAIL:
      return { error: payload, loading: false };
    case SERVICE_TYPE_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete service type
export const serviceTypeDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_TYPE_DELETE_REQUEST:
      return { loading: true };
    case SERVICE_TYPE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case SERVICE_TYPE_DELETE_FAIL:
      return { error: payload, loading: false };
    case SERVICE_TYPE_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get store list
export const storeListReducer = (state = { storeListInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_STORE_LIST_REQUEST:
      return { loading: true };
    case SERVICE_STORE_LIST_SUCCESS:
      return { loading: false, storeListInfo: payload };
    case SERVICE_STORE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update store detail
export const storeDetailUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_STORE_UPDATE_REQUEST:
      return { loading: true };
    case SERVICE_STORE_UPDATE_SUCCESS:
      return { loading: false, success: payload };
    case SERVICE_STORE_UPDATE_FAIL:
      return { error: payload, loading: false };
    case SERVICE_STORE_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get store service option
export const storeServiceOptionReducer = (state = { options: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_OPTION_REQUEST:
      return { loading: true };
    case SERVICE_OPTION_SUCCESS:
      return { loading: false, options: payload };
    case SERVICE_OPTION_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get store detail
export const storeDetailReducer = (state = { detailInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_DETAILS_REQUEST:
      return { loading: true };
    case SERVICE_DETAILS_SUCCESS:
      return { detailInfo: payload, loading: false };
    case SERVICE_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};
