import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import {
  SERVICE_DETAILS_FAIL,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_OPTION_FAIL,
  SERVICE_OPTION_REQUEST,
  SERVICE_OPTION_SUCCESS,
  SERVICE_STORE_LIST_FAIL,
  SERVICE_STORE_LIST_REQUEST,
  SERVICE_STORE_LIST_SUCCESS,
  SERVICE_STORE_UPDATE_FAIL,
  SERVICE_STORE_UPDATE_REQUEST,
  SERVICE_STORE_UPDATE_SUCCESS,
  SERVICE_TYPE_DELETE_FAIL,
  SERVICE_TYPE_DELETE_REQUEST,
  SERVICE_TYPE_DELETE_SUCCESS,
  SERVICE_TYPE_LIST_FAIL,
  SERVICE_TYPE_LIST_REQUEST,
  SERVICE_TYPE_LIST_SUCCESS,
  SERVICE_TYPE_POST_FAIL,
  SERVICE_TYPE_POST_REQUEST,
  SERVICE_TYPE_POST_SUCCESS,
} from '../../constants/data/storeConstants';

import { baseURL, prefix_api_path } from './api_config';

//get service type list
export const getServiceTypeList = ({ page, keyword }) => {
  const url = `/Backend/ServiceItem/list?page=${page}&page_size=10${
    keyword ? `&keyword=${keyword}` : ''
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: SERVICE_TYPE_LIST_FAIL,
      method: 'get',
      requestConstant: SERVICE_TYPE_LIST_REQUEST,
      successConstant: SERVICE_TYPE_LIST_SUCCESS,
      url,
    });
  };
};

//post service title
export const postServiceType = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/ServiceItem/serviceItem',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: SERVICE_TYPE_POST_FAIL,
      method: 'post',
      requestConstant: SERVICE_TYPE_POST_REQUEST,
      successConstant: SERVICE_TYPE_POST_SUCCESS,
      url: '/Backend/ServiceItem/serviceItem',
    });
  };
};

//delete service type
export const deleteServiceType = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/ServiceItem/serviceItemId?serviceItemId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: SERVICE_TYPE_DELETE_FAIL,
      method: 'delete',
      requestConstant: SERVICE_TYPE_DELETE_REQUEST,
      successConstant: SERVICE_TYPE_DELETE_SUCCESS,
      url: `/Backend/ServiceItem/serviceItemId?serviceItemId=${id}`,
    });
  };
};

//get store list
export const getStoreList = ({ page, keyword, mapType, serviceId, status }) => {
  const url = `/Backend/Locations/list?page=${page}&page_size=10${
    keyword ? `&keyword=${keyword}` : ''
  }${mapType ? `&mapType=${mapType}` : ''}${
    serviceId ? `&serviceId=${serviceId}` : ''
  }${status ? `&status=${status}` : ''}`;

  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: SERVICE_STORE_LIST_FAIL,
      method: 'get',
      requestConstant: SERVICE_STORE_LIST_REQUEST,
      successConstant: SERVICE_STORE_LIST_SUCCESS,
      url,
    });
  };
};

//post service title
export const updateStoreDetail = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_STORE_UPDATE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      console.log(formData);

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Locations/location`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: SERVICE_STORE_UPDATE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SERVICE_STORE_UPDATE_FAIL,
      });
    }
  };
};

//get store service options
export const getStoreServiceOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_OPTION_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${baseURL + prefix_api_path}/Backend/ServiceItem/options`;

      const { data } = await axios.get(link, config);

      // console.log(data)

      dispatch({
        payload: data.result,
        type: SERVICE_OPTION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SERVICE_OPTION_FAIL,
      });
    }
  };
};

//get store details
export const getStoreDetails = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_DETAILS_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${
        baseURL + prefix_api_path
      }/Backend/Locations/location?id=${id}`;

      const { data } = await axios.get(link, config);

      // console.log(data)

      dispatch({
        payload: data,
        type: SERVICE_DETAILS_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SERVICE_DETAILS_FAIL,
      });
    }
  };
};
