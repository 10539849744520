import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_SHOP_DETAIL_ADD_FAIL,
  UUCAFE_SHOP_DETAIL_ADD_REQUEST,
  UUCAFE_SHOP_DETAIL_ADD_SUCCESS,
  UUCAFE_SHOP_DETAIL_FAIL,
  UUCAFE_SHOP_DETAIL_REQUEST,
  UUCAFE_SHOP_DETAIL_SUCCESS,
  UUCAFE_SHOP_DETAIL_UPDATE_FAIL,
  UUCAFE_SHOP_DETAIL_UPDATE_REQUEST,
  UUCAFE_SHOP_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SHOP_LIST_FAIL,
  UUCAFE_SHOP_LIST_REQUEST,
  UUCAFE_SHOP_LIST_SUCCESS,
} from '../../constants/data/uuCafeShopConstants';

/*
* @Params
* {
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string",
  "keyword": "string"
  }
*
*/
export const getCafeShopList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SHOP_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SHOP_LIST_REQUEST,
      successConstant: UUCAFE_SHOP_LIST_SUCCESS,
      url: '/Backend/Cafe_Shop/list',
    });
  };
};

export const getCafeShopDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_SHOP_DETAIL_FAIL,
      method: 'get',
      requestConstant: UUCAFE_SHOP_DETAIL_REQUEST,
      successConstant: UUCAFE_SHOP_DETAIL_SUCCESS,
      url: `/Backend/Cafe_Shop/shop/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
name string
name_en string
*/
export const addCafeShopDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SHOP_DETAIL_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SHOP_DETAIL_ADD_REQUEST,
      successConstant: UUCAFE_SHOP_DETAIL_ADD_SUCCESS,
      url: '/Backend/Cafe_Shop/shop/add',
    });
  };
};

export const updateCafeShopDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SHOP_DETAIL_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SHOP_DETAIL_UPDATE_REQUEST,
      successConstant: UUCAFE_SHOP_DETAIL_UPDATE_SUCCESS,
      url: '/Backend/Cafe_Shop/shop/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeShopDetail = async params => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_Shop/shop/delete',
  });
};

export const deleteCafeShopImage = async params => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_Shop/shop/removeImg',
  });
};
