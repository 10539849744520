import Layout from 'components/Layout';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Alert, Button, Card, Table } from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import { addNewModels } from 'redux/actions/data/modelActions';
import {
  getModelOptions,
  getNewProductList,
} from 'redux/actions/data/productActions';
import { ADD_NEW_MODELS_IDLE } from 'redux/constants/data/modelConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import Loader from '../components/Loader';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const TableList = ({ onSubmit, onChangePage }) => {
  const dispatch = useDispatch();
  const { productList, loading } = useSelector(state => state.newProductList);
  const { success, error } = useSelector(state => state.addNewModel);

  // for calculating how many products has been added in a modal (It will be disabled if all products were added)
  const [isAddedAll, setIsAddedAll] = useState([]);

  const [selectedModel, setSelectedModel] = useState([]);

  const [isModelExtended, setIsModelExtended] = useState([]);

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...productList,
    },
    onSubmit: () => {
      const newList = values.datas.filter(value =>
        selectedModel.includes(value.modelId),
      );
      const body = newList.map(data => ({
        ...data,
        products: data.products.filter(
          product =>
            product.hasAdded !== true &&
            selectedProducts.includes(product.productcode),
        ),
      }));
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      body.length !== 0 && dispatch(addNewModels(body));
      setSeleteProducts([]);
      setSelectedModel([]);
    },
  });

  const calculateAddedProducts = () => {
    const result = productList?.datas?.map(data =>
      data.products.filter(product => product.hasAdded !== true),
    );
    setIsAddedAll(
      result?.map(i => {
        if (i.length > 0) {
          return false;
        }
        return true;
      }),
    );
  };

  const setDefaultState = () => {
    let result = [];
    for (let i = 0; i < productList?.datas?.length; i++) {
      result.push(false);
    }
    setIsModelExtended(result);
  };

  const toggleItemsHandler = index => {
    const newState = isModelExtended.map((modelState, i) => {
      if (i === index) {
        return !modelState;
      }
      return modelState;
    });

    setIsModelExtended(newState);
  };

  useEffect(() => {
    if (JSON.stringify(productList) !== '{}' && productList) {
      CommonUtil.consoleLog({ anno: 'response', data: productList });
      calculateAddedProducts();
      setDefaultState();
    }
  }, [productList]);

  useEffect(() => {
    if (error) {
      MessageUtil.alertWanring('新增失敗', error);
      dispatch({ type: ADD_NEW_MODELS_IDLE });
    }

    if (success) {
      dispatch({ type: ADD_NEW_MODELS_IDLE });
      setSelectedModel([]);
      onSubmit();
      MessageUtil.alertSuccess('新增成功!');
    }
  }, [success, error]);

  const isSticky = productList?.datas?.length > 0 ? { height: '80vh' } : {};

  const tableDisabled = { color: 'rgba(0, 0, 0, 0.5)', pointerEvents: 'none' };

  const handlePageChange = page => {
    onChangePage(page);
    setSeleteProducts([]);
    setSelectedModel([]);
  };

  const [selectedProducts, setSeleteProducts] = useState([]);

  const checkedAnyProductWasSelected = products => {
    let result = false;

    products.forEach(product => {
      if (selectedProducts.includes(product.productcode)) {
        result = true;
      }
    });

    return result;
  };
  return (
    <Card className={style.card}>
      <Button
        color="success"
        className="mb-3 w100"
        onClick={() => {
          handleSubmit();
        }}
        style={{ margin: '0.2rem' }}
        disabled={selectedModel.length === 0}
      >
        新增商品
      </Button>
      <div
        className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
      >
        <div className="d-flex align-items-center gap-1">
          <input
            className={style.maintain_list_checkbox}
            type="checkbox"
            disabled={
              productList?.length === 0 ||
              isAddedAll?.filter(val => val === true)?.length === 10
            }
            checked={
              selectedModel?.length > 0 &&
              selectedModel?.length ===
                values?.datas?.length -
                  isAddedAll?.filter(val => val === true)?.length
            }
            onChange={e => {
              const checked = e.target.checked;
              const models = values.datas
                .filter(
                  (data, index) =>
                    isAddedAll[index] !== true &&
                    !selectedModel.includes(data.modelId),
                )
                .map(data => data.modelId);
              checked ? setSelectedModel(models) : setSelectedModel([]);
              let products = [];
              values.datas.find(model =>
                model.products.forEach(prd => {
                  if (
                    !prd.hasAdded &&
                    !selectedProducts.includes(prd.productcode)
                  ) {
                    products.push(prd.productcode);
                  }
                }),
              );
              checked ? setSeleteProducts(products) : setSeleteProducts([]);
            }}
          />{' '}
          本頁全選
        </div>
      </div>
      <div className="table-responsive" style={isSticky}>
        <Table hover bordered striped className="mb-5">
          <thead
            className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
          >
            <tr>
              <th style={{ maxWidth: '30px' }}>選擇</th>
              <th>商品名稱</th>
              <th>官網商品 上/下架</th>
              <th>選擇料號</th>
              <th>商品料號</th>
              <th>規格</th>
              <th>商品 上/下架</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {values.datas &&
                values?.datas?.map((data, index) => (
                  <React.Fragment key={index}>
                    {data.products.map((product, pIndex) => (
                      <tr key={pIndex}>
                        {pIndex === 0 && isAddedAll && (
                          <>
                            <td
                              rowSpan={
                                isModelExtended[index]
                                  ? data.products.length
                                  : 1
                              }
                            >
                              <input
                                className={style.maintain_list_checkbox}
                                type="checkbox"
                                disabled={isAddedAll[index]}
                                onChange={e => {
                                  const check = e.target.checked;
                                  check
                                    ? setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ])
                                    : setSelectedModel(
                                        selectedModel.filter(
                                          target => target !== data.modelId,
                                        ),
                                      );
                                  const products = data.products.map(p => {
                                    if (!p.hasAdded) {
                                      return p.productcode;
                                    }
                                  });
                                  check
                                    ? setSeleteProducts([
                                        ...selectedProducts,
                                        ...products,
                                      ])
                                    : setSeleteProducts(
                                        selectedProducts.filter(
                                          prd => !products.includes(prd),
                                        ),
                                      );
                                }}
                                checked={
                                  selectedModel.includes(data.modelId) &&
                                  checkedAnyProductWasSelected(data.products)
                                }
                              />
                            </td>
                            <td
                              rowSpan={
                                isModelExtended[index]
                                  ? data.products.length
                                  : 1
                              }
                              style={
                                isAddedAll[index]
                                  ? { color: 'rgba(0, 0, 0, 0.5)' }
                                  : {}
                              }
                            >
                              <div className={style.show_flex}>
                                <Button
                                  id={`toggler${index}`}
                                  style={{ height: '35px', width: '60px' }}
                                  className="me-2"
                                  onClick={() => {
                                    toggleItemsHandler(index);
                                  }}
                                  disabled={data.products.length === 1}
                                >
                                  <div
                                    style={{
                                      alignContent: 'center',
                                      display: 'flex',
                                      height: '100%',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div className="fs-14">
                                      {isModelExtended[index] ? '收合' : '展開'}
                                    </div>
                                    <div style={{ marginLeft: '0.2rem' }}>
                                      {isModelExtended[index] ? (
                                        <FaChevronUp />
                                      ) : (
                                        <FaChevronDown />
                                      )}
                                    </div>
                                  </div>
                                </Button>
                                <div>{data.modelName}</div>
                              </div>
                            </td>
                            <td
                              rowSpan={
                                isModelExtended[index]
                                  ? data.products.length
                                  : 1
                              }
                              style={isAddedAll[index] ? tableDisabled : {}}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={data.isSell}
                                  disabled={isAddedAll[index]}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].isSell`,
                                      true,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                上架
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={data.isSell === false}
                                  disabled={isAddedAll[index]}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].isSell`,
                                      false,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                下架
                              </div>
                            </td>
                          </>
                        )}
                        {pIndex === 0 && !isModelExtended[index] && (
                          <>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              <div
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: '100%',
                                  justifyContent: 'center',
                                  width: '100%',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ height: '20px', width: '20px' }}
                                  disabled={product.hasAdded}
                                  checked={selectedProducts.includes(
                                    values.datas[index].products[pIndex]
                                      .productcode,
                                  )}
                                  onChange={event => {
                                    const checked = event.target.checked;
                                    checked
                                      ? setSeleteProducts([
                                          ...selectedProducts,
                                          product.productcode,
                                        ])
                                      : setSeleteProducts(
                                          selectedProducts.filter(
                                            code =>
                                              code !== product.productcode,
                                          ),
                                        );
                                    checked &&
                                      !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              {product.productcode}
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              {`${product.color || '--'} / ${
                                product.size || '--'
                              }`}
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={product.isSell}
                                  disabled={product.hasAdded}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].products[${pIndex}].isSell`,
                                      true,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                上架
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={product.isSell === false}
                                  disabled={product.hasAdded}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].products[${pIndex}].isSell`,
                                      false,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                下架
                              </div>
                            </td>
                          </>
                        )}
                        {isModelExtended[index] && (
                          <>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              <div
                                style={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  height: '100%',
                                  justifyContent: 'center',
                                  width: '100%',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  style={{ height: '20px', width: '20px' }}
                                  disabled={product.hasAdded}
                                  checked={selectedProducts.includes(
                                    values.datas[index].products[pIndex]
                                      .productcode,
                                  )}
                                  onChange={event => {
                                    const checked = event.target.checked;
                                    checked
                                      ? setSeleteProducts([
                                          ...selectedProducts,
                                          product.productcode,
                                        ])
                                      : setSeleteProducts(
                                          selectedProducts.filter(
                                            code =>
                                              code !== product.productcode,
                                          ),
                                        );
                                    checked &&
                                      !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />
                              </div>
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              {product.productcode}
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              {`${product.color || '--'} / ${
                                product.size || '--'
                              }`}
                            </td>
                            <td style={product.hasAdded ? tableDisabled : {}}>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={product.isSell}
                                  disabled={product.hasAdded}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].products[${pIndex}].isSell`,
                                      true,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                上架
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={product.isSell === false}
                                  disabled={product.hasAdded}
                                  onChange={() => {
                                    setFieldValue(
                                      `datas[${index}].products[${pIndex}].isSell`,
                                      false,
                                    );
                                    !selectedModel.includes(data.modelId) &&
                                      setSelectedModel([
                                        ...selectedModel,
                                        data.modelId,
                                      ]);
                                  }}
                                />{' '}
                                下架
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              {productList.length === 0 && <TableHasNoData />}
            </tbody>
          )}
        </Table>
      </div>
      {!loading && productList?.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={productList?.current_page}
            defaultPageSize={productList?.page_size}
            total={productList?.total_count}
            onChange={handlePageChange}
          />
        </div>
      )}
      {loading && <Loader />}
    </Card>
  );
};

const AddNewProduct = () => {
  const dispatch = useDispatch();
  const { modelOptions: productOptions, loading: productOptionsLoading } =
    useSelector(state => state.productmodelOptions);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const [isSearching, setIsSearching] = useState(false);
  const { values, handleSubmit, handleChange, handleReset, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        brand: '',
        isAdded: false,
        keyword: '',
        model: '',
        page: 1,
        page_size: 10,
      },
      onSubmit: async values => {
        const params = {
          ...values,
          page: isSearching ? 1 : values.page,
        };
        isSearching && setIsSearching(false);
        CommonUtil.consoleLog({ anno: 'request body', data: params });
        dispatch(getNewProductList(params));
      },
    });

  useEffect(() => {
    dispatch(getBrandOptions());

    // dispatch(getModelOptions(''));
    handleSubmit();
  }, []);

  useEffect(() => {
    dispatch(getModelOptions(values.brand));
    setFieldValue('model', '');
  }, [values.brand]);

  const changePageHandler = page => {
    setFieldValue('page', page);
    handleSubmit({ ...values, page });
  };

  return (
    <Layout
      pageTitle="新增商品"
      items={[
        { isActive: false, page_name: '商品管理' },
        { isActive: true, page_name: '新增商品' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>以型號為單位新增商品</span>
        </div>
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>已新增過的產品，無法再次新增</span>
        </div>
      </Alert>
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">關鍵字</label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="商品料號、商品名稱"
                  id="keyword"
                  name="keyword"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">品牌</label>
                <Select
                  className={`${style['form-select']} ${style['select-poper-z-index']}`}
                  options={[
                    { label: '請選擇品牌', value: '' },
                    ...(brandOptions ? [...brandOptions] : []),
                  ]}
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  id="brand"
                  name="brand"
                  defaultValue={{ label: '請選擇品牌', value: '' }}
                  onChange={({ label, value }) =>
                    setFieldValue(
                      'brand',
                      label === '請選擇品牌' ? value : label,
                    )
                  }
                  value={
                    brandOptions?.find(i => i.label === values.brand) || {
                      label: '請選擇品牌',
                      value: '',
                    }
                  }
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">型號</label>
                <Select
                  isLoading={productOptionsLoading}
                  isDisabled={productOptionsLoading}
                  className={`${style['form-select']} ${style['select-poper-z-index']}`}
                  options={[
                    { label: '請選擇型號', value: '' },
                    ...(productOptions ? [...productOptions] : []),
                  ]}
                  id="model"
                  name="model"
                  defaultValue={{ label: '請選擇型號', value: '' }}
                  onChange={({ label, value }) =>
                    setFieldValue(
                      'model',
                      label === '請選擇型號' ? value : label,
                    )
                  }
                  value={
                    productOptions?.find(i => i.label === values.model) || {
                      label: '請選擇型號',
                      value: '',
                    }
                  }
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">是否已加入商城</label>
                <Select
                  className={`${style['form-select']} ${style['select-poper-z-index']}`}
                  options={[
                    { label: '全部', value: null },
                    { label: '已加入', value: true },
                    { label: '未加入', value: false },
                  ]}
                  id="isAdded"
                  name="isAdded"
                  defaultValue={{ label: '全部', value: null }}
                  onChange={({ value }) => setFieldValue('isAdded', value)}
                  value={
                    [
                      { label: '全部', value: null },
                      { label: '已加入', value: true },
                      { label: '未加入', value: false },
                    ]?.find(i => i.value === values.isAdded) || {
                      label: '全部',
                      value: null,
                    }
                  }
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={() => {
                  handleReset();
                  handleSubmit();
                }}
              >
                取消
              </Button>
              <Button
                className={style.modal_button_submit}
                onClick={() => {
                  handleSubmit();
                  setIsSearching(true);
                }}
              >
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <TableList onSubmit={handleSubmit} onChangePage={changePageHandler} />
    </Layout>
  );
};

export default AddNewProduct;
