import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  AddGroupModelList,
  AddHideModelList,
  getActivityProductList,
} from 'redux/actions/data/discountCompaignActions';
import {
  getProductBrandOptions,
  loadModelOptions,
  loadRepairModelOption,
} from 'redux/actions/data/repairActions';

import { getGroupModelOptions } from '../../redux/actions/data/groupModelActions';
import style from '../../styles/layout.module.css';
import { TableHasNoData, TableLoading } from '../TableHasNoData';

const productTypeMap = ['一般商品', '隱形賣場', '組合商品'];

const AddProductModal = ({
  isOpen,
  toggle,
  type,
  onSubmit,
  existList,
  disableList = [],
  area,
  disableHideModel = false,
}) => {
  const dispatch = useDispatch();
  const modelTypeOptions = useMemo(() => {
    if (disableHideModel) {
      return [
        {
          label: '一般商品',
          value: '一般商品',
        },
        {
          label: '組合商品',
          value: '組合商品',
        },
      ];
    } else {
      return [
        {
          label: '一般商品',
          value: '一般商品',
        },
        {
          label: '隱形賣場',
          value: '隱形賣場',
        },
        {
          label: '組合商品',
          value: '組合商品',
        },
      ];
    }
  }, [disableHideModel]);
  const isSellOptions = [
    {
      label: '全選',
      value: null,
    },
    {
      label: '上架',
      value: true,
    },
    {
      label: '下架',
      value: false,
    },
  ];
  const [repairBrandOptions, setRepairBrandOptions] = useState([]);
  const [repairModelOptions, setRepairModelOptions] = useState([]);

  const { productList, loading } = useSelector(
    state => state.getActivityProductList,
  );
  const modelTypeRef = useRef({
    label: '一般商品',
    value: '一般商品',
  });

  const { values, handleSubmit, setFieldValue, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brand: { label: '選擇品牌', value: 0 },
      isSell: {
        label: '全選',
        value: null,
      },
      keyword: '',
      model: { label: '選擇型號', value: '' },
      modelType: { label: '一般商品', value: '一般商品' },
      page: 1,
      page_size: 10,
      selectedModels: [],
      selectedProducts: [],
    },
    onSubmit: () => {
      modelTypeRef.current = values.modelType;
      if (values.modelType.value === '組合商品') {
        dispatch(
          AddGroupModelList({
            brandId: values.brand.value || null,
            excludePrdIds: [],
            isSell: values.isSell?.value,
            keyword: values.keyword,
            modelName: values.model.value ? values.model.label : null,
            modelType: values.modelType.value,
            page: values.page,
            page_size: 10,
            type: 0,
          }),
        );
      } else if (values.modelType.value === '隱形賣場') {
        dispatch(
          AddHideModelList({
            brandId: values.brand.value || null,
            excludePrdIds: [],
            isSell: values.isSell?.value,
            keyword: values.keyword,
            modelName: values.model.value ? values.model.label : null,
            modelType: values.modelType.value,
            page: values.page,
            page_size: 10,
            type: 0,
          }),
        );
      } else {
        dispatch(
          getActivityProductList({
            brandId: values.brand.value || null,
            isSell: values.isSell?.value,
            keyword: values.keyword,
            modelId: values.model.value ? values.model.label : null,
            modelType: values.modelType.value,
            page: values.page,
            page_size: 10,
          }),
        );
      }
    },
  });

  const loadBrandOptions = useCallback(
    async (modelType = '') => {
      setRepairBrandOptions(null);
      setRepairModelOptions(null);
      try {
        if (modelType === '組合商品') {
          let res = await getGroupModelOptions(0, 0);
          setRepairModelOptions(
            res.datas.map(d => {
              return {
                label: d.name,
                value: d.id,
              };
            }),
          );
          res = await getProductBrandOptions(0, 0);
          setRepairBrandOptions(res);
        } else {
          const res = await getProductBrandOptions(0, 0);
          setRepairBrandOptions(res);
          setRepairModelOptions([]);
        }
        if (!modelType) {
          setRepairBrandOptions([]);
          setRepairModelOptions([]);
        }
      } catch (e) {
        setRepairBrandOptions([]);
        setRepairModelOptions([]);
      }
    },
    [setRepairBrandOptions],
  );

  const loadModelOrRepairOptions = useCallback(
    brand => {
      setRepairModelOptions(null);
      if (!brand.label) {
        loadRepairModelOption(0, 0, brand.value).then(res => {
          setRepairModelOptions(res);
        });
      } else {
        loadModelOptions(brand.label).then(res => {
          setRepairModelOptions(res);
        });
      }
    },
    [values.modelType.value],
  );

  const pageChangeHandler = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    loadBrandOptions(values.modelType.value);
    pageChangeHandler(1);
  }, []);

  useEffect(() => {
    if (isOpen && existList?.length) {
      setFieldValue(
        'selectedProducts',
        existList.reduce((arr, data) => {
          return [...arr, ...(data?.products || [])];
        }, []),
      );
      setFieldValue('selectedModels', existList);
    } else {
      setFieldValue('selectedProducts', []);
      setFieldValue('selectedModels', []);
    }
  }, [isOpen]);

  console.log('selectedModels', values.selectedModels);
  console.log('selectedProducts', values.selectedProducts);

  return (
    <Modal centered size="xl" zIndex={2000} isOpen={isOpen}>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        {`${type}設定`}
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '400px' }}>
        <div
          className={style.formGroup}
          style={{ flexWrap: 'wrap', gap: '5px' }}
        >
          <Input
            className={style['form-input']}
            placeholder="商品名稱"
            value={values.keyword}
            onChange={event => {
              setFieldValue('keyword', event.target.value);
            }}
          />
          <Select
            className={`ml-2 ${style['form-select']}`}
            options={modelTypeOptions}
            placeholder="選擇商品類型"
            value={values.modelType}
            onChange={option => {
              setFieldValue('modelType', option);
              loadBrandOptions(option?.value);
              setFieldValue('brand', { label: '選擇品牌', value: '' });
              setFieldValue('model', { label: '選擇型號', value: '' });
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                minWidth: '150px',
              }),
            }}
          />
          <Select
            className={`ml-2 ${style['form-select']}`}
            isDisabled={!repairBrandOptions}
            isLoading={!repairBrandOptions}
            options={repairBrandOptions || []}
            placeholder="請選擇品牌"
            value={values.brand}
            onChange={option => {
              setFieldValue('brand', option);
              loadModelOrRepairOptions(option);
              setFieldValue('model', { label: '選擇型號', value: '' });
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                minWidth: '150px',
              }),
            }}
          />
          <Select
            isLoading={!repairModelOptions}
            isDisabled={!repairModelOptions}
            className={`ml-2 ${style['form-select']}`}
            options={repairModelOptions || []}
            placeholder="請選擇型號"
            value={values.model}
            onChange={option => {
              setFieldValue('model', option);
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                minWidth: '150px',
              }),
            }}
          />
          <Select
            className={`ml-2 ${style['form-select']}`}
            placeholder="上架狀態"
            value={values.isSell}
            options={isSellOptions}
            onChange={option => {
              setFieldValue('isSell', option);
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                minWidth: '150px',
              }),
            }}
          />
          <div className="mx-2">
            <Button
              className={style.modal_button_submit}
              onClick={() => {
                pageChangeHandler(1);
              }}
            >
              搜尋
            </Button>
          </div>
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="table-responsive">
              <Table bordered striped hover className="mb-2">
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th colSpan={100}>
                      <div className={style.show_flex}>
                        <input
                          type="checkbox"
                          style={{ height: '20px', width: '20px' }}
                          checked={productList?.datas?.every(data => {
                            return data?.products.every(p => {
                              return values.selectedProducts?.find(
                                s =>
                                  p.productcode === s.productcode &&
                                  (modelTypeRef.current.value === '隱形賣場'
                                    ? p.hModelId === s.hModelId
                                    : p.mid === s.mid),
                              );
                            });
                          })}
                          onChange={event => {
                            const checked = productList?.datas?.every(data => {
                              return data?.products.every(p => {
                                return values.selectedProducts?.find(
                                  s =>
                                    p.productcode === s.productcode &&
                                    (modelTypeRef.current.value === '隱形賣場'
                                      ? p.hModelId === s.hModelId
                                      : p.mid === s.mid),
                                );
                              });
                            });

                            if (checked) {
                              setFieldValue('selectedProducts', []);
                              setFieldValue('selectedModels', []);
                            } else {
                              setFieldValue(
                                'selectedProducts',
                                [
                                  ...values.selectedProducts,
                                  ...(productList?.datas || [])
                                    .reduce((arr, data) => {
                                      return [...arr, ...data.products];
                                    }, [])
                                    .map(_p => ({
                                      ..._p,
                                      area: area,
                                      mid: _p.mid || _p.hModelId,
                                      modelType: modelTypeRef.current.value,
                                      productType:
                                        productTypeMap.indexOf(
                                          modelTypeRef.current.value,
                                        ) + 1,
                                    })),
                                ].filter((product, index, arr) => {
                                  return (
                                    arr.findIndex(
                                      p =>
                                        p.productcode === product.productcode &&
                                        (modelTypeRef.current.value ===
                                        '隱形賣場'
                                          ? p.hModelId === product.hModelId
                                          : p.mid === product.mid),
                                    ) === index
                                  );
                                }),
                              );

                              setFieldValue(
                                'selectedModels',
                                [
                                  ...values.selectedModels,
                                  ...(productList?.datas?.map(m => {
                                    return {
                                      ...m,
                                      modelType: modelTypeRef.current.value,
                                      productType:
                                        productTypeMap.indexOf(
                                          modelTypeRef.current.value,
                                        ) + 1,
                                    };
                                  }) || []),
                                ].filter((model, index, arr) => {
                                  return (
                                    arr.findIndex(m => m.mid === model.mid) ===
                                    index
                                  );
                                }),
                              );
                            }
                          }}
                        />
                        <span className="ml-2">全選</span>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    {modelTypeRef.current.value === '隱形賣場' && (
                      <th>隱賣編號</th>
                    )}
                    <th>商品名稱</th>
                    {modelTypeRef.current.value === '隱形賣場' && (
                      <th>隱賣狀態</th>
                    )}
                    <th>選擇料號</th>
                    <th>商品料號</th>
                    <th>規格</th>
                    <th style={{ maxWidth: '60px' }}>
                      <div>商品</div>
                      <div>是否</div>
                      <div>上架</div>
                    </th>
                    <th>參與的活動(尚未開始/進行中)</th>
                  </tr>
                </thead>
                {loading && <TableLoading />}
                {!loading && (
                  <tbody>
                    {productList?.datas?.length > 0 ? (
                      productList.datas
                        .map(d => {
                          return {
                            ...d,
                            modelType: modelTypeRef.current.value,
                            productType:
                              productTypeMap.indexOf(
                                modelTypeRef.current.value,
                              ) + 1,
                            products: d.products.map(p => {
                              return {
                                ...p,
                                mid: d.mid || p.mid,
                                modelType: modelTypeRef.current.value,
                                productType:
                                  productTypeMap.indexOf(
                                    modelTypeRef.current.value,
                                  ) + 1,
                              };
                            }),
                          };
                        })
                        .map(data =>
                          (data?.products || []).map((product, pIndex) => (
                            <tr
                              key={
                                JSON.stringify(product) + JSON.stringify(data)
                              }
                            >
                              {pIndex === 0 && (
                                <td
                                  rowSpan={data?.products?.length}
                                  className="text-center"
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      display: disableList?.find(model => {
                                        return modelTypeRef.current.value ===
                                          '隱形賣場'
                                          ? String(model.id) === String(data.id)
                                          : String(model.mid) ===
                                              String(data.mid);
                                      })
                                        ? 'none'
                                        : 'inline-block',
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={disableList.find(model => {
                                      return modelTypeRef.current.value ===
                                        '隱形賣場'
                                        ? String(model.id) === String(data.id)
                                        : String(model.mid) ===
                                            String(data.mid);
                                    })}
                                    checked={data?.products.every(p => {
                                      return values.selectedProducts?.find(
                                        s =>
                                          p.productcode === s.productcode &&
                                          (modelTypeRef.current.value ===
                                          '隱形賣場'
                                            ? p.hModelId === s.hModelId
                                            : p.mid === s.mid),
                                      );
                                    })}
                                    onChange={event => {
                                      const checked = data?.products.every(
                                        p => {
                                          return values.selectedProducts?.find(
                                            s =>
                                              p.productcode === s.productcode &&
                                              (modelTypeRef.current.value ===
                                              '隱形賣場'
                                                ? p.hModelId === s.hModelId
                                                : p.mid === s.mid),
                                          );
                                        },
                                      );

                                      if (!checked) {
                                        setFieldValue('selectedModels', [
                                          ...values.selectedModels.filter(
                                            model => {
                                              return modelTypeRef.current
                                                .value === '隱形賣場'
                                                ? String(model.id) !==
                                                    String(data.id)
                                                : String(model.mid) !==
                                                    String(data.mid);
                                            },
                                          ),
                                          data,
                                        ]);

                                        setFieldValue('selectedProducts', [
                                          ...values.selectedProducts.filter(p =>
                                            modelTypeRef.current.value ===
                                            '隱形賣場'
                                              ? p.hModelId !== data.hModelId
                                              : p.mid !== data.mid,
                                          ),
                                          ...(data?.products || []).map(p => {
                                            return {
                                              ...p,
                                              area: area,
                                            };
                                          }),
                                        ]);
                                      } else {
                                        setFieldValue('selectedModels', [
                                          ...values.selectedModels.filter(
                                            model => {
                                              return modelTypeRef.current
                                                .value === '隱形賣場'
                                                ? String(model.id) !==
                                                    String(data.id)
                                                : String(model.mid) !==
                                                    String(data.mid);
                                            },
                                          ),
                                        ]);
                                        setFieldValue(
                                          'selectedProducts',
                                          values.selectedProducts.filter(p =>
                                            modelTypeRef.current.value ===
                                            '隱形賣場'
                                              ? p.hModelId !== data.hModelId
                                              : p.mid !== data.mid,
                                          ),
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              )}
                              {pIndex === 0 &&
                                modelTypeRef.current.value === '隱形賣場' && (
                                  <td rowSpan={data.products.length}>
                                    {data.modelNumber || '-'}
                                  </td>
                                )}
                              {pIndex === 0 && (
                                <td rowSpan={data.products.length}>
                                  {data.modelName}
                                </td>
                              )}
                              {pIndex === 0 &&
                                modelTypeRef.current.value === '隱形賣場' && (
                                  <td rowSpan={data.products.length}>
                                    {Number(data.status) === 1
                                      ? '啟用'
                                      : '失效'}
                                  </td>
                                )}
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  style={{
                                    display: disableList?.find(model => {
                                      return (
                                        (modelTypeRef.current.value ===
                                        '隱形賣場'
                                          ? String(model.id) === String(data.id)
                                          : String(model.mid) ===
                                            String(data.mid)) &&
                                        model?.products?.find(
                                          p =>
                                            p.productcode ===
                                            product.productcode,
                                        )
                                      );
                                    })
                                      ? 'none'
                                      : 'inline-block',
                                    height: '20px',
                                    width: '20px',
                                  }}
                                  disabled={disableList.find(model => {
                                    return (
                                      (modelTypeRef.current.value === '隱形賣場'
                                        ? String(model.id) === String(data.id)
                                        : String(model.mid) ===
                                          String(data.mid)) &&
                                      model?.products?.find(
                                        p =>
                                          p.productcode === product.productcode,
                                      )
                                    );
                                  })}
                                  checked={
                                    values.selectedProducts.find(
                                      p =>
                                        p.productcode === product.productcode &&
                                        (modelTypeRef.current.value ===
                                        '隱形賣場'
                                          ? p.hModelId === product.hModelId
                                          : p.mid === product.mid),
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={event => {
                                    const checked =
                                      values.selectedProducts.find(
                                        p =>
                                          p.productcode ===
                                            product.productcode &&
                                          (modelTypeRef.current.value ===
                                          '隱形賣場'
                                            ? p.hModelId === product.hModelId
                                            : p.mid === product.mid),
                                      );

                                    if (!checked) {
                                      setFieldValue('selectedModels', [
                                        ...values.selectedModels.filter(
                                          model => {
                                            return modelTypeRef.current
                                              .value === '隱形賣場'
                                              ? model.id !== data.id
                                              : model.mid !== data.mid;
                                          },
                                        ),
                                        data,
                                      ]);
                                      setFieldValue('selectedProducts', [
                                        ...values.selectedProducts.filter(
                                          p =>
                                            !(
                                              p.productcode ===
                                                product.productcode &&
                                              (modelTypeRef.current.value ===
                                              '隱形賣場'
                                                ? p.hModelId ===
                                                  product.hModelId
                                                : p.mid === product.mid)
                                            ),
                                        ),
                                        {
                                          ...product,
                                          area: area,
                                        },
                                      ]);
                                    } else {
                                      setFieldValue(
                                        'selectedProducts',
                                        values.selectedProducts.filter(
                                          p =>
                                            !(
                                              p.productcode ===
                                                product.productcode &&
                                              (modelTypeRef.current.value ===
                                              '隱形賣場'
                                                ? p.hModelId ===
                                                  product.hModelId
                                                : p.mid === product.mid)
                                            ),
                                        ),
                                      );

                                      if (
                                        values.selectedProducts
                                          .filter(
                                            p =>
                                              !(
                                                p.productcode ===
                                                  product.productcode &&
                                                (modelTypeRef.current.value ===
                                                '隱形賣場'
                                                  ? p.hModelId ===
                                                    product.hModelId
                                                  : p.mid === product.mid)
                                              ),
                                          )
                                          .find(p =>
                                            modelTypeRef.current.value ===
                                            '隱形賣場'
                                              ? p.hModelId == data.id
                                              : p.mid == data.mid,
                                          )
                                      ) {
                                        setFieldValue('selectedModels', [
                                          ...values.selectedModels.filter(
                                            model => {
                                              return modelTypeRef.current
                                                .value === '隱形賣場'
                                                ? model.id !== data.id
                                                : model.mid !== data.mid;
                                            },
                                          ),
                                          data,
                                        ]);
                                      } else {
                                        setFieldValue(
                                          'selectedModels',
                                          values.selectedModels.filter(
                                            model => {
                                              return modelTypeRef.current
                                                .value === '隱形賣場'
                                                ? model.id !== data.id
                                                : model.mid !== data.mid;
                                            },
                                          ),
                                        );
                                      }
                                    }
                                  }}
                                />
                              </td>
                              <td nowrap="nowrap">
                                {/*商品料號*/}
                                {product.productcode}
                              </td>
                              <td>
                                {product.color || '--'}/{product.size || '--'}
                              </td>
                              <td>{product.isSell ? '是' : '否'}</td>
                              <td>
                                {product?.activities
                                  ?.filter(
                                    activity => activity.status !== '已結束',
                                  )
                                  ?.map(activity => (
                                    <div
                                      key={
                                        JSON.stringify(activity) +
                                        JSON.stringify(product) +
                                        JSON.stringify(data)
                                      }
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            activity.status === '尚未開始'
                                              ? '#0077FF'
                                              : activity.status === '進行中'
                                              ? '#1BC943'
                                              : 'transparent',
                                          borderRadius: '12px',
                                          color: 'white',
                                          fontSize: '14px',
                                          maxWidth: '80px',
                                          padding:
                                            '0.1rem 0.5rem 0.1rem 0.5rem',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {activity.status}
                                      </div>
                                      <div>{activity.name}</div>
                                      <div>{`(${activity.startTime} - ${activity.endTime})`}</div>
                                    </div>
                                  ))}
                              </td>
                            </tr>
                          )),
                        )
                    ) : (
                      <TableHasNoData />
                    )}
                  </tbody>
                )}
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={productList?.current_page || 1}
                pageSize={productList?.page_size || 10}
                total={productList?.total_count || 0}
                onChange={pageChangeHandler}
              />
            </div>
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className={style.modal_button_submit}
          onClick={() => {
            onSubmit &&
              onSubmit(
                values.selectedModels
                  .filter(m => {
                    return disableList.find(model => {
                      return modelTypeRef.current.value === '隱形賣場'
                        ? String(model.id) === String(m.id)
                        : String(model.mid) === String(m.mid);
                    })
                      ? false
                      : true;
                  })
                  .filter(m => {
                    return values.selectedProducts.some(sp =>
                      modelTypeRef.current.value === '隱形賣場'
                        ? sp.hModelId === m.id
                        : sp.mid === m.mid,
                    );
                  })
                  .map(m => {
                    return {
                      ...m,
                      area: existList.some(tm => tm.mid === m.mid)
                        ? existList.filter(tm => tm.mid === m.mid)[0].area ||
                          area
                        : area,
                      products: (m?.products || [])
                        .map(p => {
                          return {
                            ...p,
                            area: existList.some(tm => tm.mid === m.mid)
                              ? existList.filter(tm => tm.mid === m.mid)[0]
                                  .area || area
                              : area,
                            isSell: p.isSell ?? m.isSell,
                          };
                        })
                        .filter(p =>
                          values.selectedProducts.some(
                            sp =>
                              p.productcode === sp.productcode &&
                              (modelTypeRef.current.value === '隱形賣場'
                                ? p.hModelId === sp.hModelId
                                : p.mid === sp.mid),
                          ),
                        ),
                    };
                  }),
                modelTypeRef.current.value,
              );
            toggle();
          }}
        >
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddProductModal;
