
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input } from 'reactstrap';
import {
  getRewardsPointsSettings,
  updateRedeemRewardsPoints,
} from 'redux/actions/data/rewardsPointsActions';
import { UPDATE_REDEEM_REWARDS_POINTS_IDLE } from 'redux/constants/data/rewardsPointsConstants';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const DiscountSettings = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.getRewardsPointsSettings);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateRedeemRewardsPoints);
  const { values, touched, errors, handleSubmit, setFieldValue, handleChange } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        isThereMinAmount: settings?.memberLevelSetting?.isThereMinAmount,
        redeemAmount: settings?.memberLevelSetting?.redeemAmount || 0,
        redeemMaxAmount: settings?.memberLevelSetting?.redeemMaxAmount || 0,
        redeemMinAmount: settings?.memberLevelSetting?.redeemAmount || 0,
      },
      onSubmit: () => {
        const body = {
          ...values,
          redeemMinAmount:
            values.isThereMinAmount === false ? null : values.redeemMinAmount,
        };
        dispatch(updateRedeemRewardsPoints(body));
      },
      validationSchema: Yup.object().shape({
        isThereMinAmount: Yup.boolean().nullable().required('必填'),
        redeemAmount: Yup.number()
          .typeError('請輸入數字')
          .nullable()
          .min(1, '必填')
          .required('必填'),
        redeemMaxAmount: Yup.number()
          .typeError('請輸入數字')
          .nullable()
          .min(1, '必填')
          .required('必填'),
        redeemMinAmount: Yup.number()
          .typeError('請輸入數字')
          .nullable()
          .when('isThereMinAmount', {
            is: val => val === true,
            then: Yup.number()
              .typeError('請輸入數字')
              .nullable()
              .min(1, '必填')
              .required('必填'),
          }),
      }),
    });

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: UPDATE_REDEEM_REWARDS_POINTS_IDLE });
      dispatch(getRewardsPointsSettings());
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: UPDATE_REDEEM_REWARDS_POINTS_IDLE });
    }
  }, [updateSuccess, updateError]);
  return (
    <Card className={style.card}>
      <div className={style.formGroup}>
        <label className="required w100">折抵方式</label>
        <Input
          type="number"
          className={style['form-input']}
          placeholder="請輸入數字"
          id="redeemAmount"
          onChange={handleChange}
          value={values.redeemAmount}
          invalid={errors.redeemAmount && touched.redeemAmount}
        />
        <span className="ml-2">點折抵NT$1</span>
      </div>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w100">
          折抵門檻
          {errors.isThereMinAmount && touched.isThereMinAmount && (
            <span className="text-danger ml-2">{errors.isThereMinAmount}</span>
          )}
        </label>
        <div>
          <div className={style['radio-btn-layout']}>
            <input
              type="radio"
              className={style['radio-btn']}
              checked={values.isThereMinAmount === false}
              onChange={() => {
                setFieldValue('isThereMinAmount', false);
                setFieldValue('redeemMinAmount', 0);
              }}
            />
            <span className="ml-2">無折抵門檻</span>
          </div>
          <div style={{ margin: '0.5rem' }} />
          {/* <div className={style["radio-btn-layout"]}>
            <input 
              type="radio"
              className={style["radio-btn"]}
              checked={values.isThereMinAmount === true}
              onChange={() => {
                setFieldValue('isThereMinAmount', true);
              }}
            />
            <label className='ml-2'>有折抵門檻，最低消費需滿NT$ </label>
            <Input 
              type="number"
              className={style["form-input"]}
              placeholder='請輸入數字'
              disabled={values.isThereMinAmount !== true}
              id="redeemMinAmount"
              onChange={handleChange}
              value={values.redeemMinAmount}
              invalid={errors.redeemMinAmount && touched.redeemMinAmount}
            />
            <span className='ml-2'>才可以使用點數折抵</span>
          </div> */}
        </div>
      </div>
      <div className={style.formGroup}>
        <label className="required w100">折抵上限</label>
        <label>最高可折抵購買金額的</label>
        <Input
          type="number"
          className={style['form-input']}
          placeholder="請輸入數字"
          id="redeemMaxAmount"
          onChange={handleChange}
          value={values.redeemMaxAmount}
          invalid={errors.redeemMaxAmount && touched.redeemMaxAmount}
        />
        <span className="ml-2">%</span>
      </div>
      <div>
        {`異動時間：${settings?.memberLevelSetting?.updateTime || '--'} ${
          settings?.memberLevelSetting?.updater || '--'
        }`}
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            className={style.modal_button_submit}
            disabled={updateLoading}
            onClick={handleSubmit}
          >
            {updateLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default DiscountSettings;
