import Layout from 'components/Layout';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Input } from 'reactstrap';
import {
  getAreaList,
  getCityList,
  getCountryList,
} from 'redux/actions/data/locationActions';
import { getSizeOptions } from 'redux/actions/data/warrantyActions';
import {
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE,
  MAINTAIN_ADD_RESET,
} from 'redux/constants/data/repairConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import {
  addRepairRequest,
  getColorModelOption,
  getProductInfoViaWarrantySerialNumber,
  getRepairBrandOption,
  getRepairModelOption,
  getRepairOptions,
} from '../redux/actions/data/repairActions';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const MaintainanceDetail = ({
                              isNonMember,
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            }) => {
  const dispatch = useDispatch();
  const { repairBrandOptions } = useSelector(state => state.repairBrandOptions);
  const { repairModelOptions } = useSelector(state => state.repairModelOptions);
  const { repairColorOptions } = useSelector(state => state.repairColorOptions);
  const { sizeOptions } = useSelector(state => state.getSizeOptions);
  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const valuesRef = useRef();

  useEffect(() => {
    if (!valuesRef.current) {
      valuesRef.current = values;
    }
  }, [values]);

  const {
    success: getSuccess,
    error: getError,
    info: productInfo,
  } = useSelector(state => state.getProductInfoViaWarrantySerialNumber);

  useEffect(() => {
    dispatch(getRepairModelOption(0, 0, values.brand.value));
  }, [values.brand.value]);

  useEffect(() => {
    dispatch(getColorModelOption(0, 0, values.model.value));
  }, [values.model]);

  useEffect(() => {
    dispatch(
        getSizeOptions({
          color: values.color.value,
          isRepair: 1,
          isWarranty: 0,
          modelId: values.model.value,
        }),
    );
  }, [values.model.value, values.color.value]);

  useEffect(() => {
    typeof repairColorOptions === 'object' &&
    setFieldValue('maintainDetail.colorOptions', repairColorOptions);
  }, [repairColorOptions]);

  useEffect(() => {
    typeof sizeOptions === 'object' &&
    setFieldValue('maintainDetail.sizeOptions', sizeOptions);
  }, [sizeOptions]);

  useEffect(() => {
    if (!isNonMember && maintainOptions) {
      const defaultValue = maintainOptions.allChannels.find(
          item => item.label === '百滋國際股份有限公司',
      );
      setFieldValue(
          'maintainDetail.gateway',
          defaultValue || { label: '請選擇', value: '' },
      );
    }

    if (!isNonMember) {
      setFieldValue('maintainDetail.date', moment().toDate());
    }
  }, [isNonMember]);

  const getProductInfo = (value, type) => {
    value && dispatch(getProductInfoViaWarrantySerialNumber(value, type));
  };

  useEffect(() => {
    if (getSuccess && productInfo) {
      setFieldValue(
          'maintainDetail.brand',
          repairBrandOptions?.find(option => option.value == productInfo.brandId),
      );
      setFieldValue('maintainDetail.model', {
        label: productInfo.model,
        value: productInfo.modelId,
      });
      setFieldValue('maintainDetail.color', {
        label: productInfo.color,
        value: productInfo.color,
      });
      setFieldValue('maintainDetail.size', {
        label: productInfo.size,
        value: productInfo.size,
      });
      setFieldValue(
          'maintainDetail.productSerialNumber',
          productInfo.productSerialNum,
      );

      setFieldValue(
          'maintainDetail.warrantySerialNumber',
          productInfo.warrantySerialNum,
      );

      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
      valuesRef.current = null;
    }
    if (getError) {
      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    }
  }, [getError, getSuccess]);

  return (
      <div>
        {
          <>
            <div className={`mb-3 ${style.show_flex}`}>
              <label className="w80 required">商品品牌</label>
              {repairBrandOptions && (
                  <Select
                      className={style['form-select']}
                      options={[
                        { label: '請選擇', value: '' },
                        ...repairBrandOptions,
                      ]}
                      value={values.brand}
                      onChange={({ label, value }) => {
                        setFieldValue('maintainDetail.brand', { label, value });
                        setFieldValue('maintainDetail.model', {
                          label: '請選擇',
                          value: '',
                        });
                      }}
                  />
              )}
              {errors?.maintainDetail?.brand &&
                  touched?.maintainDetail?.brand && (
                      <span className="text-danger ml-2">
                  {errors.maintainDetail.brand.value}
                </span>
                  )}
            </div>
            {repairModelOptions && (
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w80 required">商品型號</label>
                  <Select
                      className={style['form-select']}
                      options={[
                        { label: '請選擇', value: '' },
                        ...repairModelOptions,
                      ]}
                      value={values.model}
                      onChange={({ label, value }) => {
                        setFieldValue('maintainDetail.model', { label, value });
                        setFieldValue('maintainDetail.color', {
                          label: '請選擇',
                          value: '',
                        });
                        setFieldValue('maintainDetail.size', {
                          label: '請選擇',
                          value: '',
                        });
                      }}
                  />
                  {errors?.maintainDetail?.model &&
                      touched?.maintainDetail?.model && (
                          <span className="text-danger ml-2">
                    {errors.maintainDetail.model.value}
                  </span>
                      )}
                </div>
            )}
            {repairColorOptions?.length > 0 && (
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w80 required">商品顏色</label>
                  <Select
                      className={style['form-select']}
                      options={[
                        { label: '請選擇', value: '' },
                        ...repairColorOptions,
                      ]}
                      value={values.color}
                      onChange={({ label, value }) => {
                        setFieldValue('maintainDetail.color', { label, value });
                        setFieldValue('maintainDetail.size', {
                          label: '請選擇',
                          value: '',
                        });
                      }}
                  />
                  {errors?.maintainDetail?.color &&
                      errors?.maintainDetail?.color?.value && (
                          <span className="text-danger ml-2">
                    {errors.maintainDetail.color.value}
                  </span>
                      )}
                </div>
            )}
            {sizeOptions?.length > 0 && (
                <div className={`${style.show_flex} mb-3`}>
                  <label className="required w80">商品尺寸</label>
                  <Select
                      className={style['form-select']}
                      options={sizeOptions || []}
                      value={values.size}
                      onChange={option => {
                        setFieldValue('maintainDetail.size', option);
                      }}
                  />
                  {errors?.maintainDetail?.size &&
                      errors?.maintainDetail?.size?.value && (
                          <span className="text-danger ml-2">
                    {errors.maintainDetail.size.value}
                  </span>
                      )}
                </div>
            )}
            <div className={`mb-3 ${style.show_flex}`}>
              <label className="w80">商品序號</label>
              <Input
                  className={style['serial-number-input']}
                  value={values.productSerialNumber}
                  onChange={event => {
                    setFieldValue(
                        'maintainDetail.productSerialNumber',
                        event.target.value,
                    );
                  }}
                  onBlur={e => {
                    getProductInfo(e.target.value, 'productSerial');
                  }}
              />
            </div>
            <div className={`mb-3 ${style.show_flex}`}>
              <label className="w80">保固序號</label>
              <Input
                  className={style['serial-number-input']}
                  value={values.warrantySerialNumber}
                  onChange={event => {
                    setFieldValue(
                        'maintainDetail.warrantySerialNumber',
                        event.target.value,
                    );
                  }}
                  onBlur={e => {
                    getProductInfo(e.target.value, 'warrantSerial');
                  }}
              />
            </div>
            <div className={`mb-3 ${style.show_flex}`}>
              <label className="w80 required">購買日期</label>
              <div className={style['form-select']}>
                <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={values.date}
                    onChange={date => {
                      setFieldValue('maintainDetail.date', date);
                    }}
                />
              </div>
              {errors.maintainDetail && touched.maintainDetail && (
                  <span className="text-danger ml-2">
                {errors.maintainDetail.date}
              </span>
              )}
            </div>
            {isNonMember && (
                <div className={`mb-3 ${style.show_flex}`}>
                  <label className="w80 required">水貨</label>
                  <div style={{ marginRight: '1.5rem' }}>
                    <input
                        type="radio"
                        checked={values.isParallel === true}
                        onChange={() => {
                          setFieldValue('maintainDetail.isParallel', true);
                        }}
                    />
                    <span style={{ marginLeft: '0.5rem' }}>是</span>
                  </div>
                  <div style={{ marginRight: '1.5rem' }}>
                    <input
                        type="radio"
                        checked={values.isParallel === false}
                        onChange={() => {
                          setFieldValue('maintainDetail.isParallel', false);
                        }}
                    />
                    <span style={{ marginLeft: '0.5rem' }}>否</span>
                  </div>
                </div>
            )}
            <div className={`mb-3 ${style.show_flex}`}>
              <label className="w80 required">購買通路</label>
              {maintainOptions && (
                  <Select
                      className={style['form-select-long']}
                      isDisabled={values.isParallel && isNonMember}
                      options={[
                        { label: '請選擇', value: '' },
                        ...maintainOptions.allChannels,
                      ]}
                      value={values.gateway}
                      onChange={({ label, value }) => {
                        setFieldValue('maintainDetail.gateway', { label, value });
                      }}
                  />
              )}
              {errors?.maintainDetail?.gateway &&
                  errors?.maintainDetail?.gateway?.value && (
                      <span className="text-danger ml-2">
                  {errors.maintainDetail.gateway.value}
                </span>
                  )}
            </div>
          </>
        }
      </div>
  );
};

const PersonalInfo = ({ setFieldValue, values, type, errors, touched }) => {
  const dispatch = useDispatch();
  const { countryList } = useSelector(state => state.getCountryList);
  const { cityList } = useSelector(state => state.getCityList);
  const { areaList } = useSelector(state => state.getAreaList);
  const countryOptions = countryList.map(country => {
    return { label: country.name, value: country.id };
  });
  const cityListOptions = cityList.map(city => {
    return { label: city.name, value: city.id };
  });
  const areaListOptions = areaList.map(area => {
    return { label: area.name, value: area.id };
  });

  useEffect(() => {
    dispatch(getCityList(values?.country?.value));
  }, [values.country]);

  useEffect(() => {
    dispatch(getAreaList(values?.city?.value));
  }, [values.city]);

  const isRequired = type === 'sender' ? 'required' : '';

  return (
      <div style={{ width: '100%' }}>
        <div className={`mb-3 ${style.show_flex}`}>
          <label className={`${isRequired} w80`}>姓氏</label>
          <Input
              className={style['first-name-input']}
              onChange={event => {
                setFieldValue(`${type}.lastName`, event.target.value);
              }}
              value={values.lastName}
              invalid={errors?.sender?.lastName && touched?.sender?.lastName}
          />
          {errors?.sender?.lastName && touched?.sender?.lastName && (
              <span className="text-danger ml-2">{errors.sender.lastName}</span>
          )}
          <div style={{ width: '100px' }} />
          <label className={`${isRequired} w80`}>名字</label>
          <Input
              className={style['last-name-input']}
              onChange={event => {
                setFieldValue(`${type}.firstName`, event.target.value);
              }}
              value={values.firstName}
              invalid={errors?.sender?.firstName && touched?.sender?.firstName}
          />
          {errors?.sender?.firstName && touched?.sender?.firstName && (
              <span className="text-danger ml-2">{errors.sender.firstName}</span>
          )}
        </div>
        <div className={`mb-3 ${style.show_flex}`}>
          <label className="w80">手機號碼</label>
          <Input
              className={style['large-input']}
              onChange={event => {
                setFieldValue(`${type}.phoneNumber`, event.target.value);
              }}
              value={values.phoneNumber}
          />
        </div>
        <div className={`mb-3 ${style.show_flex}`}>
          <label className="w80">電子信箱</label>
          <Input
              className={style['large-input']}
              onChange={event => {
                setFieldValue(`${type}.email`, event.target.value);
              }}
              value={values.email}
          />
        </div>
        <div className={`mb-3 ${style.show_flex}`}>
          <label className="w80">國家/地區</label>
          <Select
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={[{ label: '請選擇', value: 0 }, ...countryOptions]}
              defaultValue={{ label: '請選擇', value: 0 }}
              onChange={({ label, value }) => {
                setFieldValue(`${type}.country`, { label, value });
                setFieldValue(`${type}.city`, { label: '請選擇', value: 0 });
              }}
              value={values.country}
          />
          <div className="w50" />
          <label style={{ marginRight: '0.5rem' }}>城市</label>
          <Select
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={[{ label: '請選擇', value: 0 }, ...cityListOptions]}
              defaultValue={{ label: '請選擇', value: 0 }}
              onChange={({ label, value }) => {
                setFieldValue(`${type}.city`, { label, value });
                setFieldValue(`${type}.area`, { label: '請選擇', value: 0 });
              }}
              value={values.city}
          />
          <div className="w100" />
          <label style={{ marginRight: '0.5rem' }}>區域</label>
          <Select
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={[{ label: '請選擇', value: 0 }, ...areaListOptions]}
              defaultValue={{ label: '請選擇', value: 0 }}
              onChange={({ label, value }) => {
                setFieldValue(`${type}.area`, { label, value });
              }}
              value={values.area}
          />
        </div>
        <div className={`mb-3 ${style.show_flex}`}>
          <label className="w80">詳細地址</label>
          <Input
              className={style['large-input']}
              onChange={event => {
                setFieldValue(`${type}.address`, event.target.value);
              }}
              value={values.address}
          />
        </div>
      </div>
  );
};

const ServiceForm = () => {
  const dispatch = useDispatch();
  const NON_MEMBER = '非會員';
  const SCRAPPED = '庫存報廢';
  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const { success, error, id } = useSelector(state => state.maintainAdd);
  const schema = Yup.object().shape({
    formType: Yup.string(),
    maintainDetail: Yup.object().shape({
      brand: Yup.object().shape({
        value: Yup.string().min(0).required('商品品牌必填'),
      }),
      color: Yup.object().when('colorOptions', {
        is: val => val.length > 0,
        then: Yup.object().shape({
          value: Yup.string().required('商品顏色必填'),
        }),
      }),
      colorOptions: Yup.array(),
      date: Yup.date().required('購買日期必填'),
      gateway: Yup.object().when('isParallel', {
        is: value => {
          return !value;
        },
        then: Yup.object().shape({
          value: Yup.string().min(0).required('購買通路必填'),
        }),
      }),
      isParallel: Yup.boolean().required('水貨必填'),
      model: Yup.object().shape({
        value: Yup.string().min(0).required('商品型號必填'),
      }),
      size: Yup.object()
          .nullable()
          .when('sizeOptions', {
            is: val => val.length > 0,
            then: Yup.object().shape({
              value: Yup.string().required('請選擇尺寸'),
            }),
          }),
      sizeOptions: Yup.array(),
    }),
    problem: Yup.string().min(0).required('商品問題必填'),
    repairItems: Yup.array().min(1, '檢附項目必填').required('檢附項目必填'),
    sender: Yup.object().when('formType', {
      is: val => val === NON_MEMBER,
      then: Yup.object().shape({
        firstName: Yup.string().min(0).required('寄件人名子必填'),
        lastName: Yup.string().min(0).required('寄件人姓氏必填'),
      }),
    }),
  });

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getCityList());
    dispatch(getAreaList());
    dispatch(getRepairBrandOption(0, 0));
    dispatch(getRepairOptions());
  }, []);

  const { values, handleSubmit, setFieldValue, errors, touched, isSubmitting } =
      useFormik({
        enableReinitialize: true,
        initialValues: {
          formType: NON_MEMBER,
          maintainDetail: {
            brand: { label: '請選擇', value: '' },
            color: { label: '請選擇', value: '' },
            colorOptions: [],
            date: '',
            gateway: { label: '請選擇', value: '' },
            isParallel: false,
            model: { label: '請選擇', value: '' },
            productSerialNumber: '',
            size: { label: '請選擇', value: '' },
            sizeOptions: [],
            warrantySerialNumber: '',
          },
          otherRepiarItem: '',
          otherRepiarItemInputValue: '',
          problem: '',
          receiver: {
            address: '',
            area: { label: '請選擇', value: 0 },
            city: { label: '請選擇', value: 0 },
            country: { label: '請選擇', value: 0 },
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
          },
          repairItems: ['商品本體'],
          sender: {
            address: '',
            area: { label: '請選擇', value: 0 },
            city: { label: '請選擇', value: 0 },
            country: { label: '請選擇', value: 0 },
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
          },
        },
        onSubmit: () => {
          const body = {
            brandId: values.maintainDetail.brand.value,
            color: values.maintainDetail.color.value,
            isParallelGoods: values.maintainDetail.isParallel,
            model: values.maintainDetail.model.label,
            modelId: values.maintainDetail.model.value,
            otherRepiarItem: values.otherRepiarItem,
            productProblem: values.problem,
            productSerailNum: values.maintainDetail.productSerialNumber,
            purchaseDate: values.maintainDetail.date
                ? moment(values.maintainDetail.date).format('YYYY/MM/DD')
                : null,
            receiver: {
              address_receiver: values.receiver.address,
              cityId_receiver: values.receiver.city.value,
              countryCode_receiver: '886',
              countryId_receiver: values.receiver.country.value,
              email_receiver: values.receiver.email,
              firstName_receiver: values.receiver.firstName,
              lastName_receiver: values.receiver.lastName,
              phone_receiver: values.receiver.phoneNumber,
              townId_receiver: values.receiver.area.value,
            },
            repairItems: values.repairItems,
            sender: {
              address_sender: values.sender.address,
              cityId_sender: values.sender.city.value,
              countryCode_sender: '886',
              countryId_sender: values.sender.country.value,
              email_sender: values.sender.email,
              firstName_sender: values.sender.firstName,
              lastName_sender: values.sender.lastName,
              phone_sender: values.sender.phoneNumber,
              townId_sender: values.sender.area.value,
            },
            size: values.maintainDetail.size.value,
            supplier: {
              csType: values.formType,
            },
            supplierChannel: String(values.maintainDetail.gateway.label),
            supplierChannelId: values.maintainDetail.gateway.value,
            warrantySerailNum: values.maintainDetail.warrantySerialNumber,
          };

          if (values.formType !== NON_MEMBER) {
            delete body.sender;
            delete body.receiver;
          }

          if (values.maintainDetail.isParallel) {
            body.supplierChannel = '0';
            body.supplierChannelId = 0;
          }

          dispatch(addRepairRequest(2, body));
        },
        validationSchema: schema,
      });

  const syncSenderInfo = () => {
    const info = JSON.stringify(values.sender);
    setFieldValue('receiver', JSON.parse(info));
  };

  const titleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
  };

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: MAINTAIN_ADD_RESET });
      history.goBack();
    }
    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
      dispatch({ type: MAINTAIN_ADD_RESET });
    }
  }, [success, error]);

  useEffect(() => {
    console.log(errors, values);
    if (
        errors &&
        JSON.stringify(errors) !== '{}' &&
        touched &&
        JSON.stringify(touched) !== '{}' &&
        isSubmitting
    ) {
      let message = CommonUtil.getFormVaildationErrorMessages(errors);
      MessageUtil.alertErrorsWithHTML('資料請填寫完整', message);
    }
  }, [errors, touched, isSubmitting]);

  return (
      <Layout
          pageTitle="新增維修單"
          items={[
            { isActive: true, page_name: '維修清單' },
            { isActive: false, page_name: '新增維修單' },
          ]}
      >
        <Card className={style.card}>
          <div className={style.show_flex}>
            <label className="required" style={{ width: '130px' }}>
              維修單類型
            </label>
            <div className={style.show_flex}>
              <div style={{ marginRight: '1.5rem' }}>
                <input
                    type="radio"
                    checked={values.formType === NON_MEMBER}
                    onChange={() => {
                      setFieldValue('formType', NON_MEMBER);
                    }}
                />
                <span style={{ marginLeft: '0.5rem' }}>非會員</span>
              </div>
              <div style={{ marginRight: '1.5rem' }}>
                <input
                    type="radio"
                    checked={values.formType === SCRAPPED}
                    onChange={() => {
                      setFieldValue('formType', SCRAPPED);
                    }}
                />
                <span style={{ marginLeft: '0.5rem' }}>庫存報廢</span>
              </div>
            </div>
          </div>
          <h4 className="title mt-5" style={titleStyle}>
            寄件人資訊
          </h4>
          {values.formType === NON_MEMBER ? (
              <PersonalInfo
                  setFieldValue={setFieldValue}
                  values={values.sender}
                  type="sender"
                  errors={errors}
                  touched={touched}
              />
          ) : (
              <div>百滋國際股份有限公司</div>
          )}
          <h4 className="title mt-5" style={titleStyle}>
            收件人資訊
          </h4>
          {values.formType === NON_MEMBER ? (
              <div>
                <div className={`mb-3 ${style.show_flex}`}>
                  <div className="w80" />
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked && syncSenderInfo();
                      }}
                  />
                  <span style={{ marginLeft: '1rem' }}>同寄件人</span>
                </div>
                <PersonalInfo
                    setFieldValue={setFieldValue}
                    values={values.receiver}
                    type="receiver"
                />
              </div>
          ) : (
              <div>百滋國際股份有限公司</div>
          )}

          <h4 className="title mt-5" style={titleStyle}>
            維修品資訊
          </h4>
          <MaintainanceDetail
              isNonMember={values.formType === NON_MEMBER}
              setFieldValue={setFieldValue}
              values={values.maintainDetail}
              errors={errors}
              touched={touched}
          />
          <h4 className="title mt-5 required" style={titleStyle}>
            檢附項目
          </h4>
          <div className={style.show_flex}>
            {maintainOptions &&
                maintainOptions.repairItems.map((item, index) => (
                    <div
                        key={index}
                        style={{
                          alignContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                    >
                      <input
                          type="checkbox"
                          style={{ height: '20px', width: '20px' }}
                          // disabled={item.value === '商品本體'}
                          checked={values.repairItems.includes(item.value)}
                          onChange={event => {
                            const checked = event.target.checked;
                            checked
                                ? !values.repairItems.includes(item.value) &&
                                setFieldValue('repairItems', [
                                  ...values.repairItems,
                                  item.value,
                                ])
                                : setFieldValue(
                                    'repairItems',
                                    values.repairItems.filter(i => i !== item.value),
                                );
                          }}
                      />
                      <span style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
                  {item.value}
                </span>
                    </div>
                ))}
            <div
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
            >
              <input
                  type="checkbox"
                  style={{ height: '20px', width: '20px' }}
                  // checked={values.otherRepiarItem?.trim() !== "" && values.otherRepiarItem !== null}
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                        ? setFieldValue(
                            'otherRepiarItem',
                            values.otherRepiarItemInputValue,
                        )
                        : setFieldValue('otherRepiarItem', '');
                  }}
              />
              <span
                  style={{
                    marginLeft: '0.5rem',
                    marginRight: '1rem',
                    whiteSpace: 'nowrap',
                  }}
              >
              其他
            </span>
              <Input
                  className={style['form-input']}
                  onChange={event => {
                    setFieldValue('otherRepiarItemInputValue', event.target.value);
                  }}
                  value={values.otherRepiarItemInputValue}
              />
            </div>
          </div>
          {errors.repairItems && touched.repairItems && (
              <span className="text-danger">{errors.repairItems}</span>
          )}
          <h4 className="title mt-5 required" style={titleStyle}>
            商品問題
          </h4>
          {errors?.problem && touched?.problem && (
              <span className="text-danger">{errors.problem}</span>
          )}
          <Input
              type="textarea"
              style={{ height: '180px' }}
              value={values.problem}
              onChange={event => {
                const htmlTag = new RegExp('&lt;br /&gt', 'g');
                const value = event.target.value.replace(htmlTag, '\r\n');
                setFieldValue('problem', value);
              }}
              invalid={errors?.problem && touched?.problem}
          />
          <div className="d-flex justify-content-center mt-5">
            <Button
                className={`me-3 ${style.modal_button_cancel}`}
                onClick={() => history.goBack()}
            >
              取消
            </Button>

            <Button
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
                disabled={Object.keys(errors).length > 0}
            >
              <span className="btn-wrapper--label">儲存</span>
            </Button>
          </div>
        </Card>
      </Layout>
  );
};

export default ServiceForm;
