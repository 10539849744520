import {
  CREATE_MESSAGE_CATEGORY_FAIL,
  CREATE_MESSAGE_CATEGORY_REQUEST,
  CREATE_MESSAGE_CATEGORY_SUCCESS,
  CREATE_MESSAGE_REPLY_FAIL,
  CREATE_MESSAGE_REPLY_REQUEST,
  CREATE_MESSAGE_REPLY_SUCCESS,
  DELETE_MESSAGE_CATEGORY_FAIL,
  DELETE_MESSAGE_CATEGORY_REQUEST,
  DELETE_MESSAGE_CATEGORY_SUCCESS,
  GET_AUTO_REPLY_MESSAGE_FAIL,
  GET_AUTO_REPLY_MESSAGE_REQUEST,
  GET_AUTO_REPLY_MESSAGE_SUCCESS,
  GET_MESSAGE_CATEGORY_LIST_FAIL,
  GET_MESSAGE_CATEGORY_LIST_REQUEST,
  GET_MESSAGE_CATEGORY_LIST_SUCCESS,
  GET_MESSAGE_CATEGORY_OPTIONS_FAIL,
  GET_MESSAGE_CATEGORY_OPTIONS_REQUEST,
  GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS,
  GET_MESSAGE_LIST_FAIL,
  GET_MESSAGE_LIST_SUCCESS,
  GET_MESSAGE_LSIT_REQUEST,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS,
  GET_SINGLE_MESSAGE_FAIL,
  GET_SINGLE_MESSAGE_REQUEST,
  GET_SINGLE_MESSAGE_SUCCESS,
  SET_AUTO_REPLY_MESSAGE_FAIL,
  SET_AUTO_REPLY_MESSAGE_REQUEST,
  SET_AUTO_REPLY_MESSAGE_SUCCESS,
  SET_MESSAGE_CLOSED_FAIL,
  SET_MESSAGE_CLOSED_REQUEST,
  SET_MESSAGE_CLOSED_SUCCESS,
  UNREAD_MESSAGE_COUNT_FAIL,
  UNREAD_MESSAGE_COUNT_REQUEST,
  UNREAD_MESSAGE_COUNT_SUCCESS,
  UPDATE_MESSAGE_CATEGORY_FAIL,
  UPDATE_MESSAGE_CATEGORY_REQUEST,
  UPDATE_MESSAGE_CATEGORY_SORT_FAIL,
  UPDATE_MESSAGE_CATEGORY_SORT_REQUEST,
  UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS,
  UPDATE_MESSAGE_CATEGORY_SUCCESS,
} from 'redux/constants/data/MessageConstant';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getMessageCategoryList = data => {
  const url = '/Backend/MessageCat/list';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_MESSAGE_CATEGORY_LIST_FAIL,
      method: 'post',
      requestConstant: GET_MESSAGE_CATEGORY_LIST_REQUEST,
      successConstant: GET_MESSAGE_CATEGORY_LIST_SUCCESS,
      url,
    });
  };
};

export const createMessageCategory = data => {
  const url = '/Backend/MessageCat/add';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_MESSAGE_CATEGORY_FAIL,
      method: 'post',
      requestConstant: CREATE_MESSAGE_CATEGORY_REQUEST,
      successConstant: CREATE_MESSAGE_CATEGORY_SUCCESS,
      url,
    });
  };
};

export const updateMessageCategory = data => {
  const url = '/Backend/MessageCat/update';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_MESSAGE_CATEGORY_FAIL,
      method: 'post',
      requestConstant: UPDATE_MESSAGE_CATEGORY_REQUEST,
      successConstant: UPDATE_MESSAGE_CATEGORY_SUCCESS,
      url,
    });
  };
};

export const deleteMessageCategory = catId => {
  const url = `/Backend/MessageCat/delete?catId=${catId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DELETE_MESSAGE_CATEGORY_FAIL,
      method: 'delete',
      requestConstant: DELETE_MESSAGE_CATEGORY_REQUEST,
      successConstant: DELETE_MESSAGE_CATEGORY_SUCCESS,
      url,
    });
  };
};

export const updateMessageCategorySort = data => {
  const url = '/Backend/MessageCat/sort';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_MESSAGE_CATEGORY_SORT_FAIL,
      method: 'post',
      requestConstant: UPDATE_MESSAGE_CATEGORY_SORT_REQUEST,
      successConstant: UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS,
      url,
    });
  };
};

export const getAutoReplyMessage = () => {
  const url = '/Backend/Message/autoReply';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_AUTO_REPLY_MESSAGE_FAIL,
      method: 'get',
      requestConstant: GET_AUTO_REPLY_MESSAGE_REQUEST,
      successConstant: GET_AUTO_REPLY_MESSAGE_SUCCESS,
      url,
    });
  };
};

export const setAutoReplyMessage = data => {
  const url = '/Backend/Message/autoReply/set';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SET_AUTO_REPLY_MESSAGE_FAIL,
      method: 'post',
      requestConstant: SET_AUTO_REPLY_MESSAGE_REQUEST,
      successConstant: SET_AUTO_REPLY_MESSAGE_SUCCESS,
      url,
    });
  };
};

export const getMessageList = data => {
  const url = '/Backend/Message/list';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_MESSAGE_LIST_FAIL,
      method: 'post',
      requestConstant: GET_MESSAGE_LSIT_REQUEST,
      successConstant: GET_MESSAGE_LIST_SUCCESS,
      url,
    });
  };
};

export const getMessageCategoryOptions = () => {
  const url = '/Backend/Message/cat1s';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MESSAGE_CATEGORY_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_MESSAGE_CATEGORY_OPTIONS_REQUEST,
      successConstant: GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getMessageSubCategoryOptions = catId => {
  const url = `/Backend/Message/cat2s?catId=${catId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL,
      method: 'get',
      requestConstant: GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST,
      successConstant: GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getSingleMessage = messageId => {
  const url = `/Backend/Message/message?messageId=${messageId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_SINGLE_MESSAGE_FAIL,
      method: 'get',
      requestConstant: GET_SINGLE_MESSAGE_REQUEST,
      successConstant: GET_SINGLE_MESSAGE_SUCCESS,
      url,
    });
  };
};

export const createMessageReply = data => {
  const url = '/Backend/Message/reply/add';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: CREATE_MESSAGE_REPLY_FAIL,
      method: 'post',
      requestConstant: CREATE_MESSAGE_REPLY_REQUEST,
      successConstant: CREATE_MESSAGE_REPLY_SUCCESS,
      url,
    });
  };
};

export const setMessageClosed = data => {
  const url = '/Backend/Message/message/setClose';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SET_MESSAGE_CLOSED_FAIL,
      method: 'post',
      requestConstant: SET_MESSAGE_CLOSED_REQUEST,
      successConstant: SET_MESSAGE_CLOSED_SUCCESS,
      url,
    });
  };
};

export const getUnreadMessageCount = () => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: UNREAD_MESSAGE_COUNT_FAIL,
      method: 'get',
      requestConstant: UNREAD_MESSAGE_COUNT_REQUEST,
      successConstant: UNREAD_MESSAGE_COUNT_SUCCESS,
      url: '/Backend/Message/GetUnreadMessageCount',
    });
  };
};



export const setMarkMessage = async (messageId = '') => {
  return await request({
    method: 'get',
    url: `/Backend/Message/MarkMessage/${messageId}`,
  }).then(res => {
    return res;
  });
};



export const setUnmarkMessage = async (messageId  = '') => {
  return await request({
    method: 'get',
    url: `/Backend/Message/UnmarkMessage/${messageId}`,
  }).then(res => {
    return res;
  });
};


export const UpdateMessageNote = async (messageId = '' , note = '') => {
  return await request({
    data:{
      messageId,
      note
    },
    method: 'post',
    url: '/Backend/Message/UpdateMessageNote',
  }).then(res => {
    return res;
  });
};
