import {
  GROUP_MODEL_LIST_REQUEST,
  GROUP_MODEL_LIST_SUCCESS,
  GROUP_MODEL_LIST_FAIL,
  GROUP_MODEL_GET_REQUEST,
  GROUP_MODEL_GET_SUCCESS,
  GROUP_MODEL_GET_FAIL,
  GROUP_MODEL_SET_SETTING_REQUEST,
  GROUP_MODEL_SET_SETTING_SUCCESS,
  GROUP_MODEL_SET_SETTING_FAIL,
  GROUP_MODEL_SET_SETTING_RESET,
  GROUP_MODEL_SET_DESC_REQUEST,
  GROUP_MODEL_SET_DESC_SUCCESS,
  GROUP_MODEL_SET_DESC_FAIL,
  GROUP_MODEL_SET_DESC_RESET,
  GROUP_MODEL_DELETE_REQUEST,
  GROUP_MODEL_DELETE_SUCCESS,
  GROUP_MODEL_DELETE_FAIL,
  GROUP_MODEL_DELETE_RESET,
  GROUP_MODEL_COPY_REQUEST,
  GROUP_MODEL_COPY_SUCCESS,
  GROUP_MODEL_COPY_FAIL,
  GROUP_MODEL_COPY_RESET,
  GROUP_MODEL_SET_ORDER_REQUEST,
  GROUP_MODEL_SET_ORDER_SUCCESS,
  GROUP_MODEL_SET_ORDER_FAIL,
  GROUP_MODEL_SET_ORDER_RESET,
  GROUP_MODEL_SET_SPEC_REQUEST,
  GROUP_MODEL_SET_SPEC_SUCCESS,
  GROUP_MODEL_SET_SPEC_FAIL,
  GROUP_MODEL_SET_SPEC_RESET,
  GROUP_MODEL_DELETE_SPEC_REQUEST,
  GROUP_MODEL_DELETE_SPEC_SUCCESS,
  GROUP_MODEL_DELETE_SPEC_FAIL,
  GROUP_MODEL_DELETE_SPEC_RESET,
} from 'redux/constants/data/groupModelConstants';

export const groupModelListReducer = (
  state = { groupModelListInfo: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_LIST_REQUEST:
      return { ...state, loading: true };
    case GROUP_MODEL_LIST_SUCCESS:
      return { groupModelListInfo: payload || {}, loading: false };
    case GROUP_MODEL_LIST_FAIL:
      return {
        error: payload,
        groupModelListInfo: {},
        loading: false,
      };
    default:
      return state;
  }
};
export const groupModelDetailReducer = (
  state = { groupModelInfo: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_GET_REQUEST:
      return { ...state, loading: true };
    case GROUP_MODEL_GET_SUCCESS:
      return { groupModelInfo: payload || {}, loading: false };
    case GROUP_MODEL_GET_FAIL:
      return {
        error: payload,
        groupModelInfo: {},
        loading: false,
      };
    default:
      return state;
  }
};

export const copyGroupModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_COPY_REQUEST:
      return { loading: true };
    case GROUP_MODEL_COPY_SUCCESS:
      return { loading: false, modelInfo: payload, success: true };
    case GROUP_MODEL_COPY_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_COPY_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const setGroupModelSettingReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_SET_SETTING_REQUEST:
      return { loading: true };
    case GROUP_MODEL_SET_SETTING_SUCCESS:
      return { groupModelId: payload, loading: false, success: true };
    case GROUP_MODEL_SET_SETTING_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_SET_SETTING_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const setGroupModelDescReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_SET_DESC_REQUEST:
      return { loading: true };
    case GROUP_MODEL_SET_DESC_SUCCESS:
      return { groupModelId: payload, loading: false, success: true };
    case GROUP_MODEL_SET_DESC_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_SET_DESC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const setGroupModelSpecReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_SET_SPEC_REQUEST:
      return { loading: true };
    case GROUP_MODEL_SET_SPEC_SUCCESS:
      return { groupModelId: payload, loading: false, success: true };
    case GROUP_MODEL_SET_SPEC_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_SET_SPEC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const deleteGroupModelSpecReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_DELETE_SPEC_REQUEST:
      return { loading: true };
    case GROUP_MODEL_DELETE_SPEC_SUCCESS:
      return { loading: false, success: true };
    case GROUP_MODEL_DELETE_SPEC_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_DELETE_SPEC_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const setGroupModelOrderReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_SET_ORDER_REQUEST:
      return { loading: true };
    case GROUP_MODEL_SET_ORDER_SUCCESS:
      return { groupModelId: payload, loading: false, success: true };
    case GROUP_MODEL_SET_ORDER_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_SET_ORDER_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const deleteGroupModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GROUP_MODEL_DELETE_REQUEST:
      return { loading: true };
    case GROUP_MODEL_DELETE_SUCCESS:
      return { loading: false, success: true };
    case GROUP_MODEL_DELETE_FAIL:
      return { error: payload, loading: false };
    case GROUP_MODEL_DELETE_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
