import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import {
  ALSO_LIKE_BRAND_FAIL,
  ALSO_LIKE_BRAND_PRODUCTS_FAIL,
  ALSO_LIKE_BRAND_PRODUCTS_REQUEST,
  ALSO_LIKE_BRAND_PRODUCTS_SUCCESS,
  ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL,
  ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST,
  ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS,
  ALSO_LIKE_BRAND_REQUEST,
  ALSO_LIKE_BRAND_SUCCESS,
  ALSO_LIKE_PRODUCT_ADD_FAIL,
  ALSO_LIKE_PRODUCT_ADD_REQUEST,
  ALSO_LIKE_PRODUCT_ADD_SUCCESS,
  ALSO_LIKE_PRODUCT_DELETE_FAIL,
  ALSO_LIKE_PRODUCT_DELETE_REQUEST,
  ALSO_LIKE_PRODUCT_DELETE_SUCCESS,
  ALSO_LIKE_TOP_PRODUCT_REQUEST,
  ALSO_LIKE_TOP_PRODUCT_SUCCESS,
  ALSO_LIKE_TOP_PRODUCT_FAIL,
  BEST_SELLER_BRAND_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST,
  BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS,
  BEST_SELLER_BRAND_PRODUCTS_REQUEST,
  BEST_SELLER_BRAND_PRODUCTS_SUCCESS,
  BEST_SELLER_BRAND_REQUEST,
  BEST_SELLER_BRAND_SUCCESS,
  BEST_SELLER_PRODUCT_ADD_FAIL,
  BEST_SELLER_PRODUCT_ADD_REQUEST,
  BEST_SELLER_PRODUCT_ADD_SUCCESS,
  BEST_SELLER_PRODUCT_DELETE_FAIL,
  BEST_SELLER_PRODUCT_DELETE_REQUEST,
  BEST_SELLER_PRODUCT_DELETE_SUCCESS,
  BEST_SELLER_TOP_PRODUCTS_FAIL,
  BEST_SELLER_TOP_PRODUCTS_REQUEST,
  BEST_SELLER_TOP_PRODUCTS_SUCCESS,
  DISCOUNT_CODE_DELETE_FAIL,
  DISCOUNT_CODE_DELETE_REQUEST,
  DISCOUNT_CODE_DELETE_SUCCESS,
  DISCOUNT_CODE_LIST_FAIL,
  DISCOUNT_CODE_LIST_REQUEST,
  DISCOUNT_CODE_LIST_SUCCESS,
  DISCOUNT_CODE_POST_FAIL,
  DISCOUNT_CODE_POST_REQUEST,
  DISCOUNT_CODE_POST_SUCCESS,
  DISCOUNT_IMAGE_DELETE_FAIL,
  DISCOUNT_IMAGE_DELETE_REQUEST,
  DISCOUNT_IMAGE_DELETE_SUCCESS,
  DISCOUNT_IMAGE_LIST_FAIL,
  DISCOUNT_IMAGE_LIST_REQUEST,
  DISCOUNT_IMAGE_LIST_SUCCESS,
  DISCOUNT_IMAGE_POST_FAIL,
  DISCOUNT_IMAGE_POST_REQUEST,
  DISCOUNT_IMAGE_POST_SUCCESS,
} from '../../constants/data/salesConstants';

import { baseURL, prefix_api_path } from './api_config';

//get also like brand list
export const getAlsoLikeBrandList = info => {
  const url = `/Backend/B_BrandAlsoLikePrd/list?page=${info.page}&page_size=10${
    info.brandId ? `&brandId=${info.brandId}` : ''
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ALSO_LIKE_BRAND_FAIL,
      method: 'get',
      requestConstant: ALSO_LIKE_BRAND_REQUEST,
      successConstant: ALSO_LIKE_BRAND_SUCCESS,
      url,
    });
  };
};

//get also like brand product list
export const getAlsoLikeBrandProductList = info => {
  const url = `/Backend/B_BrandAlsoLikePrd/listFromBrandAlsoLike?brandId=${
    info.brandId
  }&page=${info.page}&page_size=10${
    info.keyword ? `&keyword=${info.keyword}` : ''
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ALSO_LIKE_BRAND_PRODUCTS_FAIL,
      method: 'get',
      requestConstant: ALSO_LIKE_BRAND_PRODUCTS_REQUEST,
      successConstant: ALSO_LIKE_BRAND_PRODUCTS_SUCCESS,
      url,
    });
  };
};

// set also like product to top
export const setAlsoLikeProductTop = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ALSO_LIKE_TOP_PRODUCT_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };
      console.log(object);

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/B_BrandAlsoLikePrd/setTop`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: ALSO_LIKE_TOP_PRODUCT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: ALSO_LIKE_TOP_PRODUCT_FAIL,
      });
    }
  };
};

//delete also like product
export const deleteAlsoLikeProduct = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ALSO_LIKE_PRODUCT_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };
      console.log(object);

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/B_BrandAlsoLikePrd/removeBrandAlsoLike`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: ALSO_LIKE_PRODUCT_DELETE_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      console.log(error.response.data);
      dispatch({
        payload: error.response.data,
        type: ALSO_LIKE_PRODUCT_DELETE_FAIL,
      });
    }
  };
};

//get also like brand product
export const getBrandAlsoLikeProducts = info => {
  const url = `/Backend/B_BrandAlsoLikePrd/pickBrandPrdlist?brandId=${
    info.brandId
  }&page=${info.page}&page_size=10${
    info.keyword ? `&keyword=${info.keyword}` : ''
  }${info.model ? `&model=${info.model}` : ''}${
    info.brandCateId ? `&brandCateId=${info.brandCateId}` : ''
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });

  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL,
      method: 'get',
      requestConstant: ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST,
      successConstant: ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS,
      url,
    });
  };

  // return async (dispatch, getState) => {
  //   try {
  //     dispatch({ type: ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST })

  //     const { token } = getState().userLogin.userInfo

  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + token,
  //       },
  //     }

  //     let link = `${
  //       baseURL + prefix_api_path
  //     }/Backend/B_BrandAlsoLikePrd/pickBrandPrdlist?brandId=${
  //       info.brandId
  //     }&page=${info.page}&page_size=10`

  //     if (info.keyword) {
  //       link = link.concat(`&keyword=${info.keyword}`)
  //     }
  //     if (info.model) {
  //       link = link.concat(`&model=${info.model}`)
  //     }
  //     if (info.brandCateId) {
  //       link = link.concat(`&brandCateId=${info.brandCateId}`)
  //     }

  //     const { data } = await axios.get(link, config)

  //     console.log(data)
  //     console.log(link)

  //     dispatch({
  //       type: ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS,
  //       payload: data.result,
  //     })
  //   } catch (error) {
  //     dispatch({
  //       type: ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL,
  //       payload: error,
  //     })
  //   }
  // }
};

//add product to also like
export const addProductToAlsoLike = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ALSO_LIKE_PRODUCT_ADD_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/B_BrandAlsoLikePrd/assignToBrandAlsoLike`,
        info,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: ALSO_LIKE_PRODUCT_ADD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: ALSO_LIKE_PRODUCT_ADD_FAIL,
      });
    }
  };
};

//get best seller brand list
export const getBestSellerBrandList = info => {
  const url = `/Backend/B_BrandBestSellerPrd/list?page=${
    info.page
  }&page_size=10${info.brandId ? `&brandId=${info.brandId}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BEST_SELLER_BRAND_FAIL,
      method: 'get',
      requestConstant: BEST_SELLER_BRAND_REQUEST,
      successConstant: BEST_SELLER_BRAND_SUCCESS,
      url,
    });
  };

  // return async (dispatch, getState) => {
  //   try {
  //     dispatch({ type: BEST_SELLER_BRAND_REQUEST })

  //     const { token } = getState().userLogin.userInfo

  //     const config = {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + token,
  //       },
  //     }

  //     let link = `${
  //       baseURL + prefix_api_path
  //     }/Backend/B_BrandBestSellerPrd/list?page=${info.page}&page_size=10`

  //     if (info.brandId) {
  //       link = link.concat(`&brandId=${info.brandId}`)
  //     }

  //     const { data } = await axios.get(link, config)

  //     dispatch({
  //       type: BEST_SELLER_BRAND_SUCCESS,
  //       payload: data,
  //     })
  //   } catch (error) {
  //     dispatch({
  //       type: BEST_SELLER_BRAND_FAIL,
  //       payload: error && error.response ? error.response : error,
  //     })
  //   }
  // }
};

//get best seller brand product list
export const getBestSellerBrandProductList = ({ keyword, brandId, page }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BEST_SELLER_BRAND_PRODUCTS_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${
        baseURL + prefix_api_path
      }/Backend/B_BrandBestSellerPrd/listFromBrandBestSeller?brandId=${brandId}&page=${page}&page_size=10`;

      if (keyword) {
        link = link.concat(`&keyword=${keyword}`);
      }

      const { data } = await axios.get(link, config);

      console.log(data);

      dispatch({
        payload: data,
        type: BEST_SELLER_BRAND_PRODUCTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error && error.response ? error.response : error,
        type: BEST_SELLER_BRAND_PRODUCTS_FAIL,
      });
    }
  };
};

//get also like brand product
export const getBrandBestSellerProducts = ({
  keyword,
  model,
  brandCateId,
  brandId,
  page,
}) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${
        baseURL + prefix_api_path
      }/Backend/B_BrandBestSellerPrd/pickBrandPrdlist?brandId=${brandId}&page=${page}&page_size=10`;

      if (keyword) {
        link = link.concat(`&keyword=${keyword}`);
      }
      if (model) {
        link = link.concat(`&model=${model}`);
      }
      if (brandCateId) {
        link = link.concat(`&brandCateId=${brandCateId}`);
      }

      const { data } = await axios.get(link, config);

      dispatch({
        payload: data.result,
        type: BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL,
      });
    }
  };
};

//add product to best seller
export const addProductToBestSeller = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BEST_SELLER_PRODUCT_ADD_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/B_BrandBestSellerPrd/assignToBrandBestSeller`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: BEST_SELLER_PRODUCT_ADD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: BEST_SELLER_PRODUCT_ADD_FAIL,
      });
    }
  };
};

//delete best seller product
export const deleteBestSellerProduct = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BEST_SELLER_PRODUCT_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };
      console.log(object);

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/B_BrandBestSellerPrd/removeBrandBestSeller`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: BEST_SELLER_PRODUCT_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: BEST_SELLER_PRODUCT_DELETE_FAIL,
      });
    }
  };
};

//set top best seller product
export const setBestSellerProductTop = object => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BEST_SELLER_TOP_PRODUCTS_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };
      console.log(object);

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/B_BrandBestSellerPrd/setBestSellerTop`,
        object,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: BEST_SELLER_TOP_PRODUCTS_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: BEST_SELLER_TOP_PRODUCTS_FAIL,
      });
    }
  };
};

//get discount code List
export const getDiscountCodeList = ({ keyword, status, page }) => {
  const url = `/Backend/DiscountCode/list?page=${page}${
    keyword ? `&keyword=${keyword}` : ''
  }${status ? `&status=${status}` : ''}`;

  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DISCOUNT_CODE_LIST_FAIL,
      method: 'get',
      requestConstant: DISCOUNT_CODE_LIST_REQUEST,
      successConstant: DISCOUNT_CODE_LIST_SUCCESS,
      url,
    });
  };
};

//post discount code
export const postDiscountCode = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/DiscountCode/discountCode',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: DISCOUNT_CODE_POST_FAIL,
      method: 'post',
      requestConstant: DISCOUNT_CODE_POST_REQUEST,
      successConstant: DISCOUNT_CODE_POST_SUCCESS,
      url: '/Backend/DiscountCode/discountCode',
    });
  };
};

//delete discount code
export const deleteDiscountCode = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/DiscountCode/discountCode?discountCodeId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DISCOUNT_CODE_DELETE_FAIL,
      method: 'delete',
      requestConstant: DISCOUNT_CODE_DELETE_REQUEST,
      successConstant: DISCOUNT_CODE_DELETE_SUCCESS,
      url: `/Backend/DiscountCode/discountCode?discountCodeId=${id}`,
    });
  };
};

//get discount image List
export const getDiscountImageList = ({ brandId, page }) => {
  const url = `/Backend/DiscountBrandPic/list?page=${page}&page_size=10${
    brandId ? `&brandId=${brandId}` : ''
  }`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DISCOUNT_IMAGE_LIST_FAIL,
      method: 'get',
      requestConstant: DISCOUNT_IMAGE_LIST_REQUEST,
      successConstant: DISCOUNT_IMAGE_LIST_SUCCESS,
      url,
    });
  };
};

//post discount image
export const postDiscountImage = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DISCOUNT_IMAGE_POST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/DiscountBrandPic/discountBrandPic`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: DISCOUNT_IMAGE_POST_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error.response,
        type: DISCOUNT_IMAGE_POST_FAIL,
      });
    }
  };
};

//delete discount image
export const deleteDiscountImage = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: DISCOUNT_IMAGE_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.delete(
        `${
          baseURL + prefix_api_path
        }/Backend/DiscountBrandPic/discountBrandPic?discountBrandPicId=${id}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: DISCOUNT_IMAGE_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response,
        type: DISCOUNT_IMAGE_DELETE_FAIL,
      });
    }
  };
};
