import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import { getHideModelDetail } from '../../redux/actions/data/hideModelActions';
import style from '../../styles/layout.module.css';
import CommonUtil from '../../utils/CommonUtil';
import MessageUtil from '../../utils/MessageUtil';
import request from '../../utils/useAxios';
import { BlockSpinner } from '../Spinner';
import LabelC from '../groupModal/LabelC';

const AddEditSpecModal = ({ open, toggle, currentSpecId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: info.content || '',
      content_en: info.content_en || '',
      id: info.id || '',
      sort: 0,
      title: info.title || '',
      title_en: info.title_en || '',
    },
    async onSubmit(formModel, { resetForm }) {
      const params = {
        ...formModel,
        id: formModel.id || CommonUtil.getNewID(),
      };
      setLoading(true);
      const { code, message } = await request({
        data: params,
        method: 'post',
        url: `/Backend/HideModel/setSpec/${id}`,
      });
      setLoading(false);
      if (code !== 200) {
        MessageUtil.alertWanring('儲存失敗！', message);
        return;
      }
      MessageUtil.toastSuccess('儲存成功！');
      dispatch(getHideModelDetail(id));
      resetForm();
      toggle();
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().required('必填！'),
      title: Yup.string().required('必填！'),
    }),
  });

  const handleGetDetail = async () => {
    setLoading(true);
    const { code, result } = await request({
      method: 'get',
      url: `/Backend/HideModel/getSpec/${id}/${currentSpecId}`,
    });
    setLoading(false);
    if (code !== 200) return;
    setInfo(result);
  };
  useEffect(() => {
    open && currentSpecId && handleGetDetail();
  }, [open]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        商品規格
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <Form className="position-relative">
          {loading && <BlockSpinner />}
          <FormGroup row className="align-items-center">
            <LabelC text="標題" required />
            <Col>
              <Input
                name="title"
                onChange={handleChange}
                value={values.title}
              />
              {touched.title && (
                <FormText color="danger">{errors.title}</FormText>
              )}
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="標題(英)" required />
            <Col>
              <Input
                name="title_en"
                onChange={handleChange}
                value={values.title_en}
                disabled="disabled"
              />
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="內容" required />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="content"
                onChange={handleChange}
                value={values.content}
              />
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="內容(英)" required />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="content_en"
                onChange={handleChange}
                value={values.content_en}
                disabled="disabled"
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={loading}
        >
          {loading && (
            <span
              className="btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditSpecModal;
