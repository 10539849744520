import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import {
  getAdminDetails,
  updateAdmin,
} from '../redux/actions/data/adminActions';
import { ADMIN_UPDATE_RESET } from '../redux/constants/data/adminConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';

export default function PersonalData(props) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');

  const { userInfo } = useSelector(state => state.userLogin);
  const { adminDetailsInfo, error } = useSelector(state => state.adminDetails);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.adminUpdate);

  console.log(adminDetailsInfo);

  useEffect(() => {
    if (userInfo && userInfo.id) {
      dispatch(getAdminDetails(userInfo.id));
    }
  }, [userInfo?.id]);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: `/PersonalData` })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: `/PersonalData` })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (updateSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getAdminDetails(userInfo.id));

      // dispatch(userLogout())
    }

    if (updateError && updateError.code === 404) {
      MessageUtil.alertWanring('使用者不存在', '');
    }

    return () => {
      dispatch({ type: ADMIN_UPDATE_RESET });
    };
  }, [updateSuccess, updateError]);

  const handleSubmit = values => {
    console.log(values);

    const info = {
      account: values.email,
      id: adminDetailsInfo.id,
      isSuper: adminDetailsInfo.isSuper,
      name: values.name,
      permissionIds: adminDetailsInfo.permissionIds,
      pwd: values.password,
      status: adminDetailsInfo.status,
    };

    if (values.password === values.confirmPassword) {
      dispatch(updateAdmin(info));
    }
  };

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  console.log(value);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], '密碼不相符!')
        .when('password', {
          is: password => !!password,
          then: Yup.string().required('必填'),
        }),
      email: Yup.string(),
      name: Yup.string(),
      password: Yup.string(),
    });
    return schema;
  };
  return (
    <Layout
      pageTitle="修改個人資料"
      items={[{ isActive: false, page_name: '修改個人資料' }]}
    >
      <Card className={style.card}>
        <Formik
          enableReinitialize
          initialValues={{
            confirmPassword: '',
            email:
              adminDetailsInfo && adminDetailsInfo.account
                ? adminDetailsInfo.account
                : '',
            name:
              adminDetailsInfo && adminDetailsInfo.name
                ? adminDetailsInfo.name
                : '',
            password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <div className={style.formGroup}>
                <label className="w200">
                  E-mail{' '}
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field
                    name="email"
                    type="text"
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="w200">
                  姓名{' '}
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field name="name" type="text" className="form-control" />
                </div>
              </div>

              <div className={style.formGroup}>
                <label className="w200">
                  密碼{' '}
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="8碼以上英文大小、數字"
                  />
                  <small>若空白則不修改密碼</small>
                </div>
              </div>

              <div
                className={style.formGroup}

                // style={value ? {} : { display: 'none' }}
              >
                <label className="w200">
                  確認密碼{' '}
                  <ErrorMessage
                    name="confirmPassword"
                    component="span"
                    className="form-error"
                  />
                </label>
                <div className="form-item">
                  <Field
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                  />
                </div>
              </div>

              <hr />
              <div className="text-center">
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={updateLoading}
                >
                  {updateLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card>
    </Layout>
  );
}
