export const UUCAFE_NEWS_LIST_REQUEST = 'UUCAFE_NEWS_LIST_REQUEST';
export const UUCAFE_NEWS_LIST_SUCCESS = 'UUCAFE_NEWS_LIST_SUCCESS';
export const UUCAFE_NEWS_LIST_FAIL = 'UUCAFE_NEWS_LIST_FAIL';
export const UUCAFE_NEWS_DETAIL_REQUEST = 'UUCAFE_NEWS_DETAIL_REQUEST';
export const UUCAFE_NEWS_DETAIL_SUCCESS = 'UUCAFE_NEWS_DETAIL_SUCCESS';
export const UUCAFE_NEWS_DETAIL_FAIL = 'UUCAFE_NEWS_DETAIL_FAIL';
export const UUCAFE_NEWS_DETAIL_ADD_REQUEST = 'UUCAFE_NEWS_DETAIL_ADD_REQUEST';
export const UUCAFE_NEWS_DETAIL_ADD_SUCCESS = 'UUCAFE_NEWS_DETAIL_ADD_SUCCESS';
export const UUCAFE_NEWS_DETAIL_ADD_FAIL = 'UUCAFE_NEWS_DETAIL_ADD_FAIL';
export const UUCAFE_NEWS_DETAIL_UPDATE_REQUEST =
  'UUCAFE_NEWS_DETAIL_UPDATE_REQUEST';
export const UUCAFE_NEWS_DETAIL_UPDATE_SUCCESS =
  'UUCAFE_NEWS_DETAIL_UPDATE_SUCCESS';
export const UUCAFE_NEWS_DETAIL_UPDATE_FAIL = 'UUCAFE_NEWS_DETAIL_UPDATE_FAIL';
