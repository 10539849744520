import {
  FAQ_ADD_FAIL,
  FAQ_ADD_REQUEST,
  FAQ_ADD_RESET,
  FAQ_ADD_SUCCESS,
  FAQ_CATEGORY_ADD_FAIL,
  FAQ_CATEGORY_ADD_REQUEST,
  FAQ_CATEGORY_ADD_RESET,
  FAQ_CATEGORY_ADD_SUCCESS,
  FAQ_CATEGORY_DELETE_FAIL,
  FAQ_CATEGORY_DELETE_REQUEST,
  FAQ_CATEGORY_DELETE_RESET,
  FAQ_CATEGORY_DELETE_SUCCESS,
  FAQ_CATEGORY_DETAILS_FAIL,
  FAQ_CATEGORY_DETAILS_REQUEST,
  FAQ_CATEGORY_DETAILS_SUCCESS,
  FAQ_CATEGORY_FAIL,
  FAQ_CATEGORY_REQUEST,
  FAQ_CATEGORY_SUCCESS,
  FAQ_DELETE_FAIL,
  FAQ_DELETE_REQUEST,
  FAQ_DELETE_RESET,
  FAQ_DELETE_SUCCESS,
  FAQ_LIST_FAIL,
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_OPTIONS_FAIL,
  FAQ_OPTIONS_REQUEST,
  FAQ_OPTIONS_SUCCESS,
} from '../../constants/data/FAQConstants';

//get FAQ category
export const FAQCategoryReducer = (
  state = { FAQCategoryInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_CATEGORY_REQUEST:
      return { loading: true };
    case FAQ_CATEGORY_SUCCESS:
      return { FAQCategoryInfo: payload, loading: false };
    case FAQ_CATEGORY_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get FAQ category details
export const FAQCategoryDetailsReducer = (
  state = { FAQCategoryDetails: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_CATEGORY_DETAILS_REQUEST:
      return { loading: true };
    case FAQ_CATEGORY_DETAILS_SUCCESS:
      return { FAQCategoryDetails: payload, loading: false };
    case FAQ_CATEGORY_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add FAQ category
export const FAQCategoryAddReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_CATEGORY_ADD_REQUEST:
      return { loading: true };
    case FAQ_CATEGORY_ADD_SUCCESS:
      return { loading: false, success: true };
    case FAQ_CATEGORY_ADD_FAIL:
      return { error: payload, loading: false };
    case FAQ_CATEGORY_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete FAQ category
export const FAQCategoryDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_CATEGORY_DELETE_REQUEST:
      return { loading: true };
    case FAQ_CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FAQ_CATEGORY_DELETE_FAIL:
      return { error: payload, loading: false };
    case FAQ_CATEGORY_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get FAQ list
export const FAQListReducer = (
  state = { FAQListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_LIST_REQUEST:
      return { loading: true };
    case FAQ_LIST_SUCCESS:
      return { FAQListInfo: payload, loading: false };
    case FAQ_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get FAQ options
export const FAQOptionsReducer = (state = { FAQOptions: [] }, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_OPTIONS_REQUEST:
      return { loading: true };
    case FAQ_OPTIONS_SUCCESS:
      return { FAQOptions: payload, loading: false };
    case FAQ_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add FAQ
export const FAQAddReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_ADD_REQUEST:
      return { loading: true };
    case FAQ_ADD_SUCCESS:
      return { loading: false, success: true };
    case FAQ_ADD_FAIL:
      return { error: payload, loading: false };
    case FAQ_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete FAQ
export const FAQDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case FAQ_DELETE_REQUEST:
      return { loading: true };
    case FAQ_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FAQ_DELETE_FAIL:
      return { error: payload, loading: false };
    case FAQ_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
