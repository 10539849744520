import {
  GET_BANK_LIST_REQUEST,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_FALI,
} from 'redux/constants/data/backCodeConstant';

export const getBankListReducer = (
  state = { data: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BANK_LIST_REQUEST:
      return { data: [], error: null, loading: true, success: false };
    case GET_BANK_LIST_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case GET_BANK_LIST_FALI:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
