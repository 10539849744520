import { useFormik } from 'formik';
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  TabPane,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  deleteGroupModelSpec,
  getGroupModelDetail,
  setGroupModelSpec, setGroupModelSpecRequest,
} from '../../redux/actions/data/groupModelActions';
import {
  GROUP_MODEL_DELETE_RESET,
  GROUP_MODEL_SET_SPEC_RESET,
} from '../../redux/constants/data/groupModelConstants';
import style from '../../styles/layout.module.css';
import CommonUtil from '../../utils/CommonUtil';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import request from '../../utils/useAxios';
import { BlockSpinner } from '../Spinner';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

import LabelC from './LabelC';

const AddEditSpecModal = ({ open, toggle, currentSpecId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: info.content || '',
      content_en: info.content_en || '',
      id: info.id || '',
      sort: 0,
      title: info.title || '',
      title_en: info.title_en || '',
    },
    async onSubmit(formModel, { resetForm }) {
      await dispatch(
        setGroupModelSpec({
          data: { ...formModel, id: formModel.id || CommonUtil.getNewID() },
          mid: id || 0,
        }),
      );
      resetForm();
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      content: Yup.string().required('必填！'),
      content_en: Yup.string().required('必填！'),
      title: Yup.string().required('必填！'),
      title_en: Yup.string().required('必填！'),
    }),
  });

  const handleGetDetail = async () => {
    setLoading(true);
    const { code, result } = await request({
      method: 'get',
      url: `/Backend/GroupModel/getSpec/${id}/${currentSpecId}`,
    });
    setLoading(false);
    if (code !== 200) return;
    setInfo(result);
  };
  useEffect(() => {
    open && currentSpecId && handleGetDetail();
  }, [open]);

  const {
    loading: submitLoading,
    success,
    groupModelId,
    error,
  } = useSelector(state => state.setGroupModelSpec);
  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功', '');
    success && !id && history.push(`/GroupModelDetail/${groupModelId}`);
    success && id && dispatch(getGroupModelDetail(id));
    success && id && toggle();
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_SET_SPEC_RESET });
    };
  }, [success, error]);
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        商品規格
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <Form className="position-relative">
          {(loading || submitLoading) && <BlockSpinner />}
          <FormGroup row className="align-items-center">
            <LabelC text="標題" required />
            <Col>
              <Input
                name="title"
                onChange={handleChange}
                value={values.title}
              />
              {errors.title && <div className="form-error">{errors.title}</div>}
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="標題(英)" required />
            <Col>
              <Input
                name="title_en"
                onChange={handleChange}
                value={values.title_en}
              />
              {errors.title_en && (
                <div className="form-error">{errors.title_en}</div>
              )}
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="內容" required />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="content"
                onChange={handleChange}
                value={values.content}
              />
              {errors.content && (
                <div className="form-error">{errors.content}</div>
              )}
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="內容(英)" required />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="content_en"
                onChange={handleChange}
                value={values.content_en}
              />
              {errors.content_en && (
                <div className="form-error">{errors.content_en}</div>
              )}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={loading || submitLoading || Object.keys(errors).length > 0}
        >
          {(loading || submitLoading) && (
            <span
              className="btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const TabSpec = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, groupModelInfo } = useSelector(
    state => state.groupModelDetail,
  );
  const [addModalOpen, setAddModalOpen] = useState(false);
  const handleToggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const handleDelete = itemId => {
    MessageUtil.submitConfirm({
      doneFun() {
        dispatch(deleteGroupModelSpec({ id: itemId, mid: id }));
      },
      title: '確認刪除？',
    });
  };

  const updateSort = async (formModel) => {
    try{
      await  setGroupModelSpecRequest({
        data: { ...formModel, id: formModel.id || CommonUtil.getNewID() },
        mid: id || 0,
      })
    }catch (e){

    }
  };

  const {
    loading: delLoading,
    success,
    error,
  } = useSelector(state => state.deleteGroupModelSpec);

  useEffect(() => {
    success && MessageUtil.toastSuccess('刪除成功！');
    success && dispatch(getGroupModelDetail(id));
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_DELETE_RESET });
    };
  }, [success, error]);

  const [currentSpecId, setCurrentSpecId] = useState(null);
  const handleGetSpecDetail = itemId => {
    setCurrentSpecId(itemId);
    setAddModalOpen(true);
  };

  return (
    <TabPane tabId={3}>
      <Card className={style.card}>
        <Button
          className="btn btn-success w100"
          onClick={() => {
            setAddModalOpen(true);
            setCurrentSpecId(null);
          }}
        >
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {groupModelInfo?.spec?.list !== null
              ? groupModelInfo?.spec?.list.length
              : 0}
          </span>
          筆
        </div>
        {(loading || delLoading) && <BlockSpinner />}
        <div className="table-responsive">
          <Table hover bordered striped>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>標題</th>
                <th>說明</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {groupModelInfo?.spec?.list !== null &&
                groupModelInfo?.spec?.list?.length > 0 ? (
                  groupModelInfo?.spec?.list?.map((i, index) => (
                    <tr key={i.id}>
                      <td>
                        <input
                            type="number"
                            defaultValue={i.sort}
                            onChange={e => {
                              updateSort({
                                ...i,
                                sort: e.target.value,
                              });
                            }}
                            className="form-control"
                            style={{
                              maxWidth: '100px',
                            }}
                        />

                      </td>
                      <td>{i.title}</td>
                      <td>{i.content}</td>
                      <td>
                        <Button
                          className="m-2"
                          type="button"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleGetSpecDetail(i.id)}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-2"
                          type="button"
                          color="danger"
                          size="sm"
                          outline
                          onClick={() => handleDelete(i.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {addModalOpen && (
          <AddEditSpecModal
            open={addModalOpen}
            toggle={handleToggleAddModal}
            currentSpecId={currentSpecId}
          />
        )}
      </Card>
    </TabPane>
  );
};

export default memo(TabSpec);
