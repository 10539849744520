export const GET_CAPTCHA_CODE_REQUEST = 'GET_CAPTCHA_CODE_REQUEST';
export const GET_CAPTCHA_CODE_SUCCESS = 'GET_CAPTCHA_CODE_SUCCESS';
export const GET_CAPTCHA_CODE_FAIL = 'GET_CAPTCHA_CODE_FAIL';

export const UPDATE_CAPTCHA_CODE_IDLE = 'UPDATE_CAPTCHA_CODE_IDLE';
export const UPDATE_CAPTCHA_CODE_REQUEST = 'UPDATE_CAPTCHA_CODE_REQUEST';
export const UPDATE_CAPTCHA_CODE_SUCCESS = 'UPDATE_CAPTCHA_CODE_SUCCESS';
export const UPDATE_CAPTCHA_CODE_FAIL = 'UPDATE_CAPTCHA_CODE_FAIL';

export const GET_CONTACT_US_REQUEST = 'GET_CONTACT_US_REQUEST';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAIL = 'GET_CONTACT_US_FAIL';

export const UPDATE_CONTACT_US_IDLE = 'UPDATE_CONTACT_US_IDLE';
export const UPDATE_CONTACT_US_REQUEST = 'UPDATE_CONTACT_US_REQUEST';
export const UPDATE_CONTACT_US_SUCCESS = 'UPDATE_CONTACT_US_SUCCESS';
export const UPDATE_CONTACT_US_FAIL = 'UPDATE_CONTACT_US_FAIL';
