import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import * as Yup from 'yup';

import { forgotPassword } from '../redux/actions/data/userActions';
import { USER_SEND_FORGOT_RESET } from '../redux/constants/data/userConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';

export default function Forgetpwd(props) {
  const dispatch = useDispatch();

  const {
    loading: sendLoading,
    success: sendSuccess,
    error: sendError,
  } = useSelector(state => state.userForgotPassword);

  console.log(sendError);

  useEffect(() => {
    if (sendSuccess) {
      console.log('ggggggg');
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('已寄發驗證信至此信箱');
    }

    if (sendError && sendError.status === 404) {
      MessageUtil.alertWanring('使用者不存在', '');
    }

    if (sendError && sendError.status === 500) {
      console.log('bbbbbbbb');
      MessageUtil.alertWanring('寄件錯誤', '請告知管理員');
    }

    return () => {
      dispatch({ type: USER_SEND_FORGOT_RESET });
    };
  }, [sendSuccess, sendError]);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      email: Yup.string().email('請填入信箱').required('必填!'),
    });
    return schema;
  };

  const handleSubmit = values => {
    console.log(values);
    dispatch(forgotPassword(values.email));
  };

  return (
    <>
      <div className="panel">
        <div className="login">
          <img src="/images/logo.svg" className="w200 mb-3" />
          <Card className={style.card}>
            <div className="text-center">
              <h1 className={style.password_panel_title}>忘記密碼</h1>
            </div>

            <Formik
              enableReinitialize
              initialValues={{
                email: '',
              }}
              onSubmit={handleSubmit}
              validationSchema={getValidationSchema}
            >
              {props => (
                <Form className="form-list form-label-140">
                  <div className="form-group mb-3" style={{ display: 'block' }}>
                    <Field
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder="請輸入Email"
                    />
                    <ErrorMessage
                      name="email"
                      component="span"
                      className="form-error"
                    />
                  </div>
                  <div className="text-center py-4">
                    <Button
                      type="submit"
                      color="second"
                      className={style.password_panel_button}
                    >
                      <span className="btn-wrapper--label">寄發驗証信</span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </>
  );
}
