export const UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST =
  'UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST';
export const UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS =
  'UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS';
export const UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL =
  'UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL';

export const UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST =
  'UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST';
export const UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS =
  'UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS';
export const UUCAFE_MIDDLE_HOME_SETTINGS_FAIL =
  'UUCAFE_MIDDLE_HOME_SETTINGS_FAIL';

export const UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST =
  'UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST';
export const UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS =
  'UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS';
export const UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL =
  'UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL';
