export const UUCAFE_RECRUITMENT_LIST_REQUEST =
  'UUCAFE_RECRUITMENT_LIST_REQUEST';

export const UUCAFE_RECRUITMENT_LIST_SUCCESS =
  'UUCAFE_RECRUITMENT_LIST_SUCCESS';
export const UUCAFE_RECRUITMENT_LIST_FAIL = 'UUCAFE_RECRUITMENT_LIST_FAIL';
export const UUCAFE_RECRUITMENT_UPDATE_REQUEST =
  'UUCAFE_RECRUITMENT_UPDATE_REQUEST';
export const UUCAFE_RECRUITMENT_UPDATE_SUCCESS =
  'UUCAFE_RECRUITMENT_UPDATE_SUCCESS';
export const UUCAFE_RECRUITMENT_UPDATE_FAIL = 'UUCAFE_RECRUITMENT_UPDATE_FAIL';
export const UUCAFE_RECRUITMENT_SUCCESS = 'UUCAFE_RECRUITMENT_SUCCESS';
export const UUCAFE_RECRUITMENT_REQUEST = 'UUCAFE_RECRUITMENT_REQUEST';
export const UUCAFE_RECRUITMENT_FAIL = 'UUCAFE_RECRUITMENT_FAIL';
export const UUCAFE_RECRUITMENT_ADD_SUCCESS = 'UUCAFE_RECRUITMENT_ADD_SUCCESS';
export const UUCAFE_RECRUITMENT_ADD_REQUEST = 'UUCAFE_RECRUITMENT_ADD_REQUEST';
export const UUCAFE_RECRUITMENT_ADD_FAIL = 'UUCAFE_RECRUITMENT_ADD_FAIL';
