export const WARRANTY_CODE_LIST_REQUEST = 'WARRANTY_CODE_LIST_REQUEST';
export const WARRANTY_CODE_LIST_SUCCESS = 'WARRANTY_CODE_LIST_SUCCESS';
export const WARRANTY_CODE_LIST_FAIL = 'WARRANTY_CODE_LIST_FAIL';

export const WARRANTY_CODE_POST_REQUEST = 'WARRANTY_CODE_POST_REQUEST';
export const WARRANTY_CODE_POST_SUCCESS = 'WARRANTY_CODE_POST_SUCCESS';
export const WARRANTY_CODE_POST_FAIL = 'WARRANTY_CODE_POST_FAIL';
export const WARRANTY_CODE_POST_RESET = 'WARRANTY_CODE_POST_RESET';

export const BRAND_PREFIX_LIST_REQUEST = 'BRAND_PREFIX_LIST_REQUEST';
export const BRAND_PREFIX_LIST_SUCCESS = 'BRAND_PREFIX_LIST_SUCCESS';
export const BRAND_PREFIX_LIST_FAIL = 'BRAND_PREFIX_LIST_FAIL';

export const WARRANTY_NUMBER_LIST_REQUEST = 'WARRANTY_NUMBER_LIST_REQUEST';
export const WARRANTY_NUMBER_LIST_SUCCESS = 'WARRANTY_NUMBER_LIST_SUCCESS';
export const WARRANTY_NUMBER_LIST_FAIL = 'WARRANTY_NUMBER_LIST_FAIL';

export const WARRANTY_SERIAL_NUMBER_REQUEST = 'WARRANTY_SERIAL_NUMBER_REQUEST';
export const WARRANTY_SERIAL_NUMBER_SUCCESS = 'WARRANTY_SERIAL_NUMBER_SUCCESS';
export const WARRANTY_SERIAL_NUMBER_FAIL = 'WARRANTY_SERIAL_NUMBER_FAIL';
export const WARRANTY_SERIAL_NUMBER_CHECK = 'WARRANTY_SERIAL_NUMBER_CHECK';

export const WARRANTY_NUMBER_CREATE_REQUEST = 'WARRANTY_NUMBER_CREATE_REQUEST';
export const WARRANTY_NUMBER_CREATE_SUCCESS = 'WARRANTY_NUMBER_CREATE_SUCCESS';
export const WARRANTY_NUMBER_CREATE_FAIL = 'WARRANTY_NUMBER_CREATE_FAIL';
export const WARRANTY_NUMBER_CREATE_RESET = 'WARRANTY_NUMBER_CREATE_RESET';

export const WARRANTY_EXCEL_PRODUCE_REQUEST = 'WARRANTY_EXCEL_PRODUCE_REQUEST';
export const WARRANTY_EXCEL_PRODUCE_SUCCESS = 'WARRANTY_EXCEL_PRODUCE_SUCCESS';
export const WARRANTY_EXCEL_PRODUCE_FAIL = 'WARRANTY_EXCEL_PRODUCE_FAIL';
export const WARRANTY_EXCEL_PRODUCE_RESET = 'WARRANTY_EXCEL_PRODUCE_RESET';

export const PRODUCT_NUMBER_LIST_REQUEST = 'PRODUCT_NUMBER_LIST_REQUEST';
export const PRODUCT_NUMBER_LIST_SUCCESS = 'PRODUCT_NUMBER_LIST_SUCCESS';
export const PRODUCT_NUMBER_LIST_FAIL = 'PRODUCT_NUMBER_LIST_FAIL';

export const PRODUCT_SERIAL_NUMBER_REQUEST = 'PRODUCT_SERIAL_NUMBER_REQUEST';
export const PRODUCT_SERIAL_NUMBER_SUCCESS = 'PRODUCT_SERIAL_NUMBER_SUCCESS';
export const PRODUCT_SERIAL_NUMBER_FAIL = 'PRODUCT_SERIAL_NUMBER_FAIL';

export const PRODUCT_NUMBER_CREATE_REQUEST = 'PRODUCT_NUMBER_CREATE_REQUEST';
export const PRODUCT_NUMBER_CREATE_SUCCESS = 'PRODUCT_NUMBER_CREATE_SUCCESS';
export const PRODUCT_NUMBER_CREATE_FAIL = 'PRODUCT_NUMBER_CREATE_FAIL';
export const PRODUCT_NUMBER_CREATE_RESET = 'PRODUCT_NUMBER_CREATE_RESET';

export const PRODUCT_EXCEL_PRODUCE_REQUEST = 'PRODUCT_EXCEL_PRODUCE_REQUEST';
export const PRODUCT_EXCEL_PRODUCE_SUCCESS = 'PRODUCT_EXCEL_PRODUCE_SUCCESS';
export const PRODUCT_EXCEL_PRODUCE_FAIL = 'PRODUCT_EXCEL_PRODUCE_FAIL';
export const PRODUCT_EXCEL_PRODUCE_RESET = 'PRODUCT_EXCEL_PRODUCE_RESET';

export const SHIPPING_DEALER_LIST_REQUEST = 'SHIPPING_DEALER_LIST_REQUEST';
export const SHIPPING_DEALER_LIST_SUCCESS = 'SHIPPING_DEALER_LIST_SUCCESS';
export const SHIPPING_DEALER_LIST_FAIL = 'SHIPPING_DEALER_LIST_FAIL';

export const WARRANTY_PRODUCT_CHECK_REQUEST = 'WARRANTY_PRODUCT_CHECK_REQUEST';
export const WARRANTY_PRODUCT_CHECK_SUCCESS = 'WARRANTY_PRODUCT_CHECK_SUCCESS';
export const WARRANTY_PRODUCT_CHECK_FAIL = 'WARRANTY_PRODUCT_CHECK_FAIL';
export const WARRANTY_PRODUCT_CHECK_RESET = 'WARRANTY_PRODUCT_CHECK_RESET';
export const WARRANTY_MASTER_LIST_REQUEST = 'WARRANTY_MASTER_LIST_REQUEST';
export const WARRANTY_MASTER_LIST_SUCCESS = 'WARRANTY_MASTER_LIST_SUCCESS';
export const WARRANTY_MASTER_LIST_FAIL = 'WARRANTY_MASTER_LIST_FAIL';

export const WARRANTY_INFO_GET_REQUEST = 'WARRANTY_INFO_GET_REQUEST';
export const WARRANTY_INFO_GET_SUCCESS = 'WARRANTY_INFO_GET_SUCCESS';
export const WARRANTY_INFO_GET_FAIL = 'WARRANTY_INFO_GET_FAIL';

export const WARRANTY_INFO_UPDATE_REQUEST = 'WARRANTY_INFO_UPDATE_REQUEST';
export const WARRANTY_INFO_UPDATE_SUCCESS = 'WARRANTY_INFO_UPDATE_SUCCESS';
export const WARRANTY_INFO_UPDATE_FAIL = 'WARRANTY_INFO_UPDATE_FAIL';
export const WARRANTY_INFO_UPDATE_RESET = 'WARRANTY_INFO_UPDATE_RESET';

export const SHIPPING_EXCEL_PRODUCE_REQUEST = 'SHIPPING_EXCEL_PRODUCE_REQUEST';
export const SHIPPING_EXCEL_PRODUCE_SUCCESS = 'SHIPPING_EXCEL_PRODUCE_SUCCESS';
export const SHIPPING_EXCEL_PRODUCE_FAIL = 'SHIPPING_EXCEL_PRODUCE_FAIL';
export const SHIPPING_EXCEL_PRODUCE_RESET = 'SHIPPING_EXCEL_PRODUCE_RESET';

export const WARRANTY_BRAND_LIST_REQUEST = 'WARRANTY_BRAND_LIST_REQUEST';
export const WARRANTY_BRAND_LIST_SUCCESS = 'WARRANTY_BRAND_LIST_SUCCESS';
export const WARRANTY_BRAND_LIST_FAIL = 'WARRANTY_BRAND_LIST_FAIL';

export const WARRANTY_BRAND_EXPIRE_REQUEST = 'WARRANTY_BRAND_EXPIRE_REQUEST';
export const WARRANTY_BRAND_EXPIRE_SUCCESS = 'WARRANTY_BRAND_EXPIRE_SUCCESS';
export const WARRANTY_BRAND_EXPIRE_FAIL = 'WARRANTY_BRAND_EXPIRE_FAIL';

export const WARRANTY_BRAND_SET_REQUEST = 'WARRANTY_BRAND_SET_REQUEST';
export const WARRANTY_BRAND_SET_SUCCESS = 'WARRANTY_BRAND_SET_SUCCESS';
export const WARRANTY_BRAND_SET_FAIL = 'WARRANTY_BRAND_SET_FAIL';
export const WARRANTY_BRAND_SET_RESET = 'WARRANTY_BRAND_SET_RESET';

export const WARRANTY_REGISTER_LIST_REQUEST = 'WARRANTY_REGISTER_LIST_REQUEST';
export const WARRANTY_REGISTER_LIST_SUCCESS = 'WARRANTY_REGISTER_LIST_SUCCESS';
export const WARRANTY_REGISTER_LIST_FAIL = 'WARRANTY_REGISTER_LIST_FAIL';

export const WARRANTY_REGISTER_DETAILS_REQUEST =
  'WARRANTY_REGISTER_DETAILS_REQUEST';
export const WARRANTY_REGISTER_DETAILS_SUCCESS =
  'WARRANTY_REGISTER_DETAILS_SUCCESS';
export const WARRANTY_REGISTER_DETAILS_FAIL = 'WARRANTY_REGISTER_DETAILS_FAIL';
export const WARRANTY_REGISTER_DETAILS_RESET =
  'WARRANTY_REGISTER_DETAILS_RESET';

export const WARRANTY_REMARK_ADD_REQUEST = 'WARRANTY_REMARK_ADD_REQUEST';
export const WARRANTY_REMARK_ADD_SUCCESS = 'WARRANTY_REMARK_ADD_SUCCESS';
export const WARRANTY_REMARK_ADD_FAIL = 'WARRANTY_REMARK_ADD_FAIL';
export const WARRANTY_REMARK_ADD_RESET = 'WARRANTY_REMARK_ADD_RESET';

export const WARRANTY_REGISTER_REVIEW_REQUEST =
  'WARRANTY_REGISTER_REVIEW_REQUEST';
export const WARRANTY_REGISTER_REVIEW_SUCCESS =
  'WARRANTY_REGISTER_REVIEW_SUCCESS';
export const WARRANTY_REGISTER_REVIEW_FAIL = 'WARRANTY_REGISTER_REVIEW_FAIL';
export const WARRANTY_REGISTER_REVIEW_RESET = 'WARRANTY_REGISTER_REVIEW_RESET';

export const WARRANTY_REGISTER_NUMBER_REQUEST =
  'WARRANTY_REGISTER_NUMBER_REQUEST';
export const WARRANTY_REGISTER_NUMBER_SUCCESS =
  'WARRANTY_REGISTER_NUMBER_SUCCESS';
export const WARRANTY_REGISTER_NUMBER_FAIL = 'WARRANTY_REGISTER_NUMBER_FAIL';

export const WARRANTY_REGISTER_RECORD_REQUEST =
  'WARRANTY_REGISTER_RECORD_REQUEST';
export const WARRANTY_REGISTER_RECORD_SUCCESS =
  'WARRANTY_REGISTER_RECORD_SUCCESS';
export const WARRANTY_REGISTER_RECORD_FAIL = 'WARRANTY_REGISTER_RECORD_FAIL';
export const WARRANTY_REGISTER_RECORD_RESET = 'WARRANTY_REGISTER_RECORD_RESET';

export const WARRANTY_REGISTER_EXPORT_REQUEST =
  'WARRANTY_REGISTER_EXPORT_REQUEST';
export const WARRANTY_REGISTER_EXPORT_SUCCESS =
  'WARRANTY_REGISTER_EXPORT_SUCCESS';
export const WARRANTY_REGISTER_EXPORT_FAIL = 'WARRANTY_REGISTER_EXPORT_FAIL';
export const WARRANTY_REGISTER_EXPORT_RESET = 'WARRANTY_REGISTER_EXPORT_RESET';

export const GET_SIZE_OPTIONS_IDLE = 'GET_SIZE_OPTIONS_IDLE';
export const GET_SIZE_OPTIONS_REQUEST = 'GET_SIZE_OPTIONS_REQUEST';
export const GET_SIZE_OPTIONS_SUCCESS = 'GET_SIZE_OPTIONS_SUCCESS';
export const GET_SIZE_OPTIONS_FAIL = 'GET_SIZE_OPTIONS_FAIL';
export const GET_FORM_DESCRIPTION_FAIL = 'GET_FORM_DESCRIPTION_FAIL';
export const GET_FORM_DESCRIPTION_REQUEST = 'GET_FORM_DESCRIPTION_REQUEST';
export const GET_FORM_DESCRIPTION_SUCCESS = 'GET_FORM_DESCRIPTION_SUCCESS';
export const UPDATE_FORM_DESCRIPTION_FAIL = 'UPDATE_FORM_DESCRIPTION_FAIL';
export const UPDATE_FORM_DESCRIPTION_REQUEST = 'UPDATE_FORM_DESCRIPTION_REQUEST';
export const UPDATE_FORM_DESCRIPTION_SUCCESS = 'UPDATE_FORM_DESCRIPTION_SUCCESS';
export const GET_CAN_UNBIND_SHIPPER_LIST_FAIL = 'GET_CAN_UNBIND_SHIPPER_LIST_FAIL';
export const GET_CAN_UNBIND_SHIPPER_LIST_REQUEST = 'GET_CAN_UNBIND_SHIPPER_LIST_REQUEST';
export const GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS = 'GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS';
export const GET_WARRANTY_NOTIFY_LIST_FAIL = 'GET_WARRANTY_NOTIFY_LIST_FAIL';
export const GET_WARRANTY_NOTIFY_LIST_REQUEST = 'GET_WARRANTY_NOTIFY_LIST_REQUEST';
export const GET_WARRANTY_NOTIFY_LIST_SUCCESS = 'GET_WARRANTY_NOTIFY_LIST_SUCCESS';

export const UPDATE_WARRANTY_NOTIFY_LIST_FAIL = 'UPDATE_WARRANTY_NOTIFY_LIST_FAIL';
export const UPDATE_WARRANTY_NOTIFY_LIST_REQUEST = 'UPDATE_WARRANTY_NOTIFY_LIST_REQUEST';
export const UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS = 'UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS';
