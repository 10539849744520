import Layout from 'components/Layout';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Row,
} from 'reactstrap';
import { getGiftsList } from 'redux/actions/data/giftsActions';
import history from 'utils/createHistory';

import GiftListTable from '../components/giftManagement/GiftListTable';
import SearchGiftListForm from '../components/giftManagement/SearchGiftListForm';
import style from '../styles/layout.module.css';

const GiftManagement = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const { gifts } = useSelector(state => state.getGiftsList);

  const { values, setFieldValue, handleReset, handleSubmit, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        isSell: null,
        keyword: '',
        page: 1,
        page_size: 10,
        stock: 0,
        stockCompareType: 0,
      },
      onReset: () => {
        dispatch(
          getGiftsList({
            isSell: null,
            keyword: '',
            page: 1,
            page_size: 10,
            stock: 0,
            stockCompareType: 0,
          }),
        );
      },
      onSubmit: () => {
        const body = { ...values };
        const params = new URLSearchParams({
          ...body,
          page: ~~values?.page || 1,
          page_size: ~~values?.page_size || 10,
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      },
    });

  const searchHandler = useCallback(() => {
    setFieldValue('page', 1);
    handleSubmit();
  }, []);

  const pageChangeHandler = useCallback(page => {
    setFieldValue('page', page);
    handleSubmit();
  }, []);

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams);

      const body = {
        ...values,
        ...params,
        isSell: params.isSell ? JSON.parse(params.isSell) : null,
        keyword: params?.keyword || '',
        page: ~~params.page || 1,
        page_size: ~~params.page_size || 10,
        stock: ~~params.stock || 0,
        stockCompareType: ~~params.stockCompareType || 0,
      };
      setValues(body);
      dispatch(getGiftsList(body));
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle={'贈品管理'}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: false, page_name: '贈品管理' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <SearchGiftListForm values={values} setFieldValue={setFieldValue} />
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={handleReset}
          >
            取消
          </Button>
          <Button className={style.modal_button_submit} onClick={searchHandler}>
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <Row className="mb-2">
          <div className="col-md-6">
            <Button
              color="success"
              className="w100"
              onClick={() => {
                history.push('/GiftSettings?status=create');
              }}
            >
              新增
            </Button>
          </div>
          <div className="d-flex justify-content-end col-md-6 align-items-center">
            每頁
            <Input
              type="select"
              name="page_size"
              style={{ margin: '0.3rem', width: '80px' }}
              onChange={event => {
                const value = event.target.value;
                setFieldValue('page', 1);
                setFieldValue('page_size', value);
                handleSubmit();
              }}
              value={values.page_size}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </Input>
            {`筆，共${gifts.total_count || 0}筆`}
          </div>
        </Row>
        <React.Fragment>
          <GiftListTable />
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={gifts?.current_page || 1}
              pageSize={gifts?.page_size || 10}
              style={{ borderColor: '#446' }}
              total={gifts?.total_count || 0}
              onChange={pageChangeHandler}
            />
          </div>
        </React.Fragment>
      </Card>
    </Layout>
  );
};

export default GiftManagement;
