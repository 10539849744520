import { Spinner } from 'reactstrap';

export const BlockSpinner = () => {
  return (
    <div
      className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
      style={{
        backgroundColor: 'rgba(255,255,255,0.8)',
        left: 0,
        top: 0,
        zIndex: 1,
      }}
    >
      <Spinner color="primary" />
    </div>
  );
};
