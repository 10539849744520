import { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Layout from '../../components/Layout';
import PerformanceList from '../../components/report/PromoPerformanceList';
import SearchCondition from '../../components/report/PromoSearchCondition';
import { getPromoPerformanceReport } from '../../redux/actions/data/operatingReportActions';

const PromoPerformanceReport = () => {
  const dispatch = useDispatch();

    const { loading, list } = useSelector(
        state => state.getCouponPerformanceReportList,
    );
  const onSearch = useCallback(data => {
    dispatch(getPromoPerformanceReport(data));
  }, []);

  return (
    <Layout
      pageTitle="優惠碼-績效報表"
      items={[
        { isActive: false, page_name: '營運報表' },
        { isActive: true, page_name: '優惠碼-績效報表' },
      ]}
    >
      <SearchCondition onSearch={onSearch} />
      <PerformanceList list={list} loading={loading} onSearch={onSearch} />
    </Layout>
  );
};

export default PromoPerformanceReport;
