import TableHead from 'components/TableHead';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import {
  deleteVoucher,
  downloadCouponExportCode,
  exportCouponExport,
} from 'redux/actions/data/voucherActions';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';
import history from '../../utils/createHistory';
import Loader from '../Loader';

const VoucherTable = ({ loadVoucherList = () => {} }) => {
  const { loading, voucherList } = useSelector(state => state.getVoucherList);
  const { location } = history;

  const handleDeleteItems = useCallback(async data => {
    try {
      await deleteVoucher(data);
      setTimeout(() => {
        history.push(
          `${location.pathname}${
            (location.search && `${location.search}&`) || '?'
          }page=1&reload=${new Date().getTime()}`,
        );
      }, 500);
      MessageUtil.toastSuccess('刪除成功!');
    } catch (e) {
      MessageUtil.alertWanring('刪除失敗!', e);
    }
  }, []);

  const getWayEnum = [
    '',
    '直接領取',
    '輸入優惠券代碼',
    '紅利點數兌換',
    '系統發送',
  ];

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>
              <div>活動序號</div>
              <div>活動名稱</div>
              <div>活動代碼</div>
            </th>
            <th>
              <div>優惠券類型</div>
              <div>優惠內容</div>
            </th>
            <th>
              <div>使用門檻</div>
              <div>折抵上限</div>
            </th>
            <th>
              <div>領取方式</div>
              {/* <div>可領取站台</div> */}
              <div>使用通路</div>
            </th>
            <th>優惠券代碼</th>
            <th className={style['date-column']}>使用期限</th>
            <th>活動目的</th>
            <th>
              <div>適用商品</div>
              <div>數量</div>
            </th>
            <th>
              <div>排除商品</div>
              <div>數量</div>
            </th>
            <th>
              <div>總張數</div>
              <div>已發送張數</div>
            </th>
            <th className={style['date-column']}>更新日期</th>
            <th>活動狀態</th>
            <th>功能</th>
          </tr>
        </TableHead>
        {loading && (
          <tbody>
            <tr>
              <td colSpan="100%">
                <Loader />
              </td>
            </tr>
          </tbody>
        )}
        {!loading && (
          <tbody>
            {(voucherList?.datas?.length > 0
              ? voucherList.datas
              : ['此搜尋條件查無資料']
            ).map(data => {
              if (typeof data === 'string') {
                return (
                  <tr key={data} className={style.table_no_data}>
                    <td colSpan="100%">{data}</td>
                  </tr>
                );
              }
              return (
                <tr key={JSON.stringify(data)}>
                  <td className="text-start">
                    {/* 活動序號 */}
                    <div>{data.serialNumber || '--'}</div>
                    {/* 活動名稱 */}
                    <div>{data.name || '--'}</div>
                    {/* 活動代碼 */}
                    <div>{data.activityCode || '--'}</div>
                  </td>
                  <td className="text-start">
                    {/* 優惠券類型 */}
                    <div>{data.type === 1 ? '折價券' : '贈品券'}</div>
                    {/* 優惠內容 */}
                    <div>{data.desc}</div>
                  </td>
                  <td className="text-center">
                    {/* 折抵上限 */}
                    {data.type === 1 && (
                      <>
                        {data.discountType === 1 ? (
                          <div className="text-nowrap">
                            商品折抵 NT$
                            {(data.discountAmount || 0)?.toLocaleString()}
                          </div>
                        ) : (
                          <div className="text-nowrap">
                            商品打折 {data.discountAmount} 折
                          </div>
                        )}
                      </>
                    )}
                    {data.useMin === 2 && (
                      <div>
                        滿額門檻，金額需滿NT$
                        {(data.useMinAmount || 0)?.toLocaleString()}
                      </div>
                    )}
                    {data.discountType === 1 && (
                      <div>最高可折抵購買金額為 {data.discountMaxAmount} %</div>
                    )}
                    {data.discountType === 2 && data.discountMax === 2 && (
                      <div>
                        最高可折抵購買金額的NT${' '}
                        {data.discountMaxAmount?.toLocaleString()}
                      </div>
                    )}
                  </td>
                  <td>
                    {/* 領取方式 */}
                    <div className="text-nowrap">{getWayEnum[data.getWay]}</div>
                    <div className="text-nowrap">
                      {(data.availableWays || [])
                        .map(way => (way === 'web' ? '網頁版' : '門市'))
                        .join('/')}
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 優惠碼 */}
                    {data.getWay === 2 && data.setting == 2 && (
                      <Button
                        color="primary"
                        size="sm"
                        outline
                        onClick={() => {
                          downloadCouponExportCode(data).then(r => {
                            if (r.code === 200) {
                              window.open(r.result);
                            } else {
                              MessageUtil.toastWarning('下載失敗');
                            }
                          });
                        }}
                      >
                        下載優惠券代碼
                      </Button>
                    )}
                    {data.getWay === 2 &&
                      data.setting == 1 &&
                      (data.couponCode || '-')}
                  </td>
                  <td>
                    {/* 使用期限 */}
                    <div className="text-nowrap">
                      {data.redeemStartTime
                        ? moment(data.redeemStartTime).format(
                            'YYYY/MM/DD HH:mm:ss',
                          )
                        : '-'}
                      起
                    </div>
                    <div className="text-nowrap">
                      {data.redeemEndTime
                        ? moment(data.redeemEndTime).format(
                            'YYYY/MM/DD HH:mm:ss',
                          )
                        : '-'}
                      止
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 活動目的 */}
                    <div>{data.goal === 1 ? '行銷活動' : '客服補發'}</div>
                  </td>
                  <td>
                    {/* 適用商品 */}
                    <div>
                      {data.productRange === 'all' ? '全店商品' : '指定商品'}
                    </div>
                    {/* 數量 */}
                    <div>
                      {data.productRange === 'picked'
                        ? `${Number(data.productNum).toLocaleString()} 件`
                        : ''}
                    </div>
                  </td>
                  <td>
                    {/* 數量 */}
                    <div className="text-nowrap">
                      {data.productRange === 'all'
                        ? `${Number(
                            data.exclusiveProductNum,
                          ).toLocaleString()} 件`
                        : ''}
                    </div>
                  </td>
                  <td>
                    {/* 總張數 */}
                    <div className="text-nowrap">
                      {Number(data?.totalNum ?? 0).toLocaleString()} 張
                    </div>
                    {/* 已發送張數 */}
                    <div className="text-nowrap">
                      {Number(data.sentNum).toLocaleString()} 張
                    </div>
                  </td>
                  <td>
                    {/* 更新日期 */}
                    <div className="text-nowrap">
                      {data.updateDate} {data.updateTime}
                    </div>
                    <div className="text-nowrap">{data.updater}</div>
                  </td>
                  <td>
                    {/* 活動狀態 */}
                    {(data.statusNameList || []).map((state, i) => (
                      <div key={state}>{state}</div>
                    ))}
                  </td>
                  <td style={{ minWidth: '200px' }}>
                    {!(data?.statusList || []).includes(5) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="primary"
                        outline
                        onClick={() =>
                          history.push(
                            `/VoucherSettings?type=edit&id=${data.id}`,
                          )
                        }
                      >
                        編輯
                      </Button>
                    )}
                    {(data?.statusList || []).includes(5) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="primary"
                        outline
                        onClick={() =>
                          history.push(
                            `/VoucherSettings?type=view&id=${data.id}`,
                          )
                        }
                      >
                        查看
                      </Button>
                    )}
                    <Button
                      className="m-2"
                      size="sm"
                      color="primary"
                      outline
                      onClick={() =>
                        history.push(`/VoucherSettings?type=copy&id=${data.id}`)
                      }
                    >
                      複製
                    </Button>

                    {!(data?.statusList || []).includes(1) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="primary"
                        outline
                        onClick={() => {
                          exportCouponExport(data.id)
                            .then(res => {
                              if (res.result) {
                                window.open(res.result, '_blank');
                              } else {
                                MessageUtil.toastWarning('查無明細');
                              }
                            })
                            .catch(r => {
                              MessageUtil.toastWarning(r);
                            });
                        }}
                      >
                        匯出明細
                      </Button>
                    )}
                    {(data?.statusList || []).includes(1) && (
                      <Button
                        className="m-2"
                        size="sm"
                        color="danger"
                        outline
                        onClick={() => {
                          MessageUtil.submitConfirm({
                            doneFun: () => {
                              handleDeleteItems(data);
                            },
                            title: '確認是否刪除',
                          });
                        }}
                      >
                        刪除
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default VoucherTable;
