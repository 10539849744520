import axios from 'axios';

import CommonUtil from './CommonUtil';

class APIUtil {
  /**************** basic *****************/
  static mJsonInstance = null;
  static mFormDataInstance = null;

  // static baseURL = 'https://localhost:44356'
  static baseURL = 'https://pezcrown-api.chainyi.com';

  static prefix_api_path = '/api/v1';

  static getJsonInstance = () => {
    const token = CommonUtil.getAuthToken();

    // console.log('this.mJsonInstance => ' + this.mJsonInstance)
    if (this.mJsonInstance === null) {
      console.log('mJsonInstance is null');
      this.mJsonInstance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
        timeout: 30 * 1000,
      });
    }

    return this.mJsonInstance;
  };

  static getFormDataInstance = () => {
    const token = CommonUtil.getAuthToken();

    if (this.mFormDataInstance === null) {
      this.mFormDataInstance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'multipart/form-data',
        },
        timeout: 30 * 1000,
      });
    }

    return this.mFormDataInstance;
  };

  static getQueryString = object => {
    let serachParams = new URLSearchParams(object);

    var delParams = [];

    serachParams.forEach(function (val, key) {
      if (val === 'undefined' || val === 'null' || val.length === 0)
        delParams.push(key);
    });

    delParams.forEach(function (key) {
      serachParams.delete(key);
    });

    var queryStr = serachParams.toString();

    return queryStr;
  };

  /**************** response code *****************/

  static Code_OK = 200;
  static Code_Bad_Request = 400;
  static Code_Forbidden = 403;
  static Code_Not_Found = 404;
  static Code_Server_Error = 500;

  static Code_Account_Not_Valified = 1000; // 帳號未驗證
  static Code_Account_Invalid = 1001; // 帳號無效
  static Code_Brand_Not_Exists = 1100;
  static Code_Brand_Files = 1101;
  static Code_BrandCategory_Name_Duplicate = 1201;

  static GlobalCategory_Not_Exists = 1300;
  static GlobalCategory_Name_Duplicate = 1301;

  static Code_Product_Extensions_Not_Exists = 1400;

  /**************** method ***************/

  //-- 品牌
  static async callBrandList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Brand/list?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callBrandChangeStatus(param, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/changeStatus`;
      const response = await this.getJsonInstance().post(path, param);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callBrandOptions() {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/options`;
      const response = await this.getJsonInstance().get(path);

      // console.log(response)
      if (response.data.code === 200) {
        return response.data.result;
      }
    } catch (error) {
      console.log(error);
    }

    return [];
  }

  static async callBrandCateOptions() {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/category/options`;
      const response = await this.getJsonInstance().get(path);

      // console.log(response)
      if (response.data.code === 200) {
        return response.data.result;
      }
    } catch (error) {
      console.log(error);
    }

    return [];
  }

  //-- 品牌頁面設定
  static async callBrandPageSetting(brandId, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path  }/Backend/Brand/pageSetting?brandId=${  brandId}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callUpdBrandPageSetting(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/pageSetting`;
      const response = await this.getFormDataInstance().post(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //-- 品牌分類管理
  static async callBrandCateList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Brand/category/list?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callAddBrandCate(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/category`;
      const response = await this.getJsonInstance().post(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callUpdBrandCate(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Brand/category`;
      const response = await this.getJsonInstance().put(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callDelBrandCate(catId, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path  }/Backend/Brand/category?catId=${  catId}`;
      const response = await this.getJsonInstance().delete(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //-- 商品管理
  static async callProductOptions(col) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/options?col=${  col}`;
      const response = await this.getJsonInstance().get(path);
      let resData = await response.data;

      // console.log(resData)
      if (resData.code === 200) {
        return resData.result;
      }
    } catch (error) {
      console.log(error);
    }

    return [];
  }

  static async callProductList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Product/list?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callProductProfile(productId, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path  }/Backend/Product/profile?id=${  productId}`;
      const response = await this.getJsonInstance().get(path);

      // console.log(response)
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callUpdProductProfile(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/profile`;
      const response = await this.getFormDataInstance().post(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //-- 全域分類
  static async callGlobalList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Product/category/list?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callGlobalOptions() {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/category/options`;
      const response = await this.getJsonInstance().get(path);
      let resData = await response.data;

      // console.log(resData)
      if (resData.code === 200) {
        return resData.result;
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async callAddGlobal(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/category`;
      const response = await this.getJsonInstance().post(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callUpdGlobal(editInfo, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/category`;
      const response = await this.getJsonInstance().put(path, editInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callDelGlobal(id, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/category?id=${  id}`;
      const response = await this.getJsonInstance().delete(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //*****
  //-- 全域商品
  static async callGlobalCateProductList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Product/listFromGlobalCate?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //*****
  static async callAssignToGlobalCategory(
    assignInfo,
    handleSuccess,
    handleError,
  ) {
    try {
      let path =
        `${this.prefix_api_path  }/Backend/Product/assignToGlobalCategory`;
      const response = await this.getJsonInstance().post(path, assignInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callRemoveGlobalCategory(param, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/removeGlobalCategory`;
      const response = await this.getJsonInstance().post(path, param);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //-- 品牌商品
  static async callBrandCateProductList(param, handleSuccess, handleError) {
    try {
      let path =
        `${this.prefix_api_path 
        }/Backend/Product/listFromBrandCategory?${ 
        this.getQueryString(param)}`;
      const response = await this.getJsonInstance().get(path);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  //****
  static async callAssignToBrandCategory(
    assignInfo,
    handleSuccess,
    handleError,
  ) {
    try {
      let path =
        `${this.prefix_api_path  }/Backend/Product/assignToBrandCategory`;
      const response = await this.getJsonInstance().post(path, assignInfo);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }

  static async callRemoveBrandCategory(param, handleSuccess, handleError) {
    try {
      let path = `${this.prefix_api_path  }/Backend/Product/removeBrandCategory`;
      const response = await this.getJsonInstance().post(path, param);
      console.log(response);
      if (response.data.code === 200) {
        handleSuccess(response.data.result);
      } else {
        handleError(response.data.code, response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleError(error.response.data.code, error.response.data.message);
      } else {
        handleError(-999, error.message);
      }
    }
  }
}

export default APIUtil;
