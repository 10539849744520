import {
  GET_PROMO_DETAIL_FAIL,
  GET_PROMO_DETAIL_REQUEST,
  GET_PROMO_DETAIL_SUCCESS,
  GET_PROMO_LIST_FAIL,
  GET_PROMO_LIST_REQUEST,
  GET_PROMO_LIST_SUCCESS,
} from '../../constants/data/promoConstants';

export const promoListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 0,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROMO_LIST_REQUEST:
      return { error: null, list: {}, loading: true, success: false };
    case GET_PROMO_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case GET_PROMO_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const promoDetailReducer = (
  state = {
    detail: {
      availableWay: '["web"]',
      availableWays: ['web'],
      batchCodeList: '',
      batchGenerateSetting: 0,
      createTime: '',
      creator: '',
      discountAmount: 0,
      discountType: 0,
      id: 0,
      isBatchGenerate: true,
      isUsedwithCoupon: true,
      isUsedwithDiscount: true,
      isUsedwithYouLike: true,
      name: '',
      productRange: '',
      products: [],
      promoPrefix: '',
      redeemEndTime: '',
      redeemStartTime: '',
      status: 0,
      updateTime: '',
      updater: '',
      useMin: 0,
      useMinAmount: 0,
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROMO_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case GET_PROMO_DETAIL_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case GET_PROMO_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
