import RcPagination from 'rc-pagination';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import request from 'utils/useAxios';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  getAllProductListList,
  getProductLinkList,
} from '../redux/actions/data/productActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const ProductLinkControl = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const linkRef = useRef({ label: '請選擇通路', value: '' });
  const [link, setLink] = useState({ label: '請選擇通路', value: '' });
  const [current_page, setCurrent_page] = useState(1);
  const [linkOptions, setLinkOptions] = useState(null);
  const pageSize = 10;

  const { loading, linkListInfo, error } = useSelector(
      state => state.productLinkList,
  );

  useEffect(() => {
    getAllProductListList().then(res => {
      setLinkOptions(
          res?.result?.datas?.map(option => {
            return { label: option.name, value: option.id };
          }) || [],
      );
    });
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryLink = query.value.link;
      const queryPage = query.value.page;

      setLink({ ...link, value: queryLink });
      setCurrent_page(queryPage);
      dispatch(getProductLinkList(queryLink ?? '', queryPage, pageSize));
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrent_page(1);
      setLink({ label: '請選擇通路', value: '' });

      //初始搜尋
      dispatch(getProductLinkList('', 1, pageSize));
    }
  }, [location.search]);

  useEffect(() => {
    if (linkListInfo && linkListInfo.datas && linkListInfo.datas.length) {
      setEditSortList(linkListInfo?.datas);
    }
  }, [linkListInfo]);

  const handlePageChange = page => {
    setCurrent_page(page);
    dispatch(
        getProductLinkList(
            linkRef.current.label === '請選擇通路'
                ? ''
                : linkRef.current.label || '',
            page,
            pageSize,
        ),
    );

    if (link.label) {
      history.push(
          `${location.pathname}?link=${
              linkRef.current.label === '請選擇通路' ? '' : linkRef.current.label
          }&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    linkRef.current = link;
    dispatch(
        getProductLinkList(
            linkRef.current.label === '請選擇通路' ? '' : linkRef.current.label,
            1,
            pageSize,
        ),
    );
    history.push(
        `${location.pathname}?link=${
            linkRef.current.label === '請選擇通路' ? '' : linkRef.current.label
        }&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setLink({ label: '請選擇通路', value: '' });
    linkRef.current = { label: '請選擇通路', value: '' };
    setCurrent_page(1);
    dispatch(getProductLinkList('', 1, pageSize));
    history.push(`${location.pathname}`);
  };

  // 排序
  const [sortModal, setSortModal] = useState(false);
  const [editSortList, setEditSortList] = useState([]);
  const [sortLoading, setSortLoading] = useState(false);

  const toggleSortModal = () => {
    dispatch(getProductLinkList('', 1, pageSize));
    setSortModal(!sortModal);
  };
  const handleOnDragEnd = result => {
    const items = Array.from(editSortList);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setEditSortList(items);
  };
  const handleUpdateSort = async () => {
    const sort_objects = editSortList?.map((brand, index) => {
      return {
        id: brand.id,
        sort: index + 1,
      };
    });
    setSortLoading(true);
    const { code, message } = await request({
      data: sort_objects,
      method: 'post',
      url: '/Backend/ProductECLink/updateSort',
    });
    setSortLoading(false);
    if (code !== 200) {
      MessageUtil.alertWanring(message);
      return;
    }
    MessageUtil.toastSuccess('更新成功！');
    toggleSortModal();
  };
  return (
      <Layout
          pageTitle="導購連結設定"
          items={[
            { isActive: false, page_name: '商品管理' },
            { isActive: true, page_name: '導購連結設定' },
          ]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form className="form-inline" onSubmit={handleSearch}>
              <div className={style.formGroup}>
                <label
                    style={{ lineHeight: '48px', width: '70px' }}
                    htmlFor="qkeyword"
                >
                  通路名稱
                </label>
                <Select
                    isLoading={!linkOptions}
                    isDisabled={!linkOptions}
                    className={style['form-select']}
                    name="link"
                    options={
                        linkOptions && [
                          { label: '請選擇通路', value: '' },
                          ...linkOptions,
                        ]
                    }
                    onChange={setLink}
                    value={link}
                    style={{ margin: 0 }}
                />
              </div>
              <div className={style.button_position}>
                <button
                    type="button"
                    onClick={clearSearchForm}
                    className={style.modal_button_cancel}
                >
                  取消
                </button>
                <button type="submit" className={style.modal_button_submit}>
                  查詢
                </button>
              </div>
            </form>
          </div>
        </Card>

        <Card className={style.card}>
          <Button
              type="button"
              className={style.sort_button}
              onClick={() => {
                setSortModal(true);
                dispatch(getProductLinkList('', 1, 0));
              }}
          >
            排序
          </Button>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
            {!loading && linkListInfo && linkListInfo.total_count
                ? linkListInfo.total_count
                : '0'}
          </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">排序</th>
                <th scope="col" className="sortable">
                  通路名稱
                </th>
                <th scope="col">功能</th>
              </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                  <tbody>
                  {linkListInfo ? (
                      linkListInfo?.datas?.map((data, index) => (
                          <tr key={index}>
                            <td style={{ wordBreak: 'keep-all' }}>
                              {index +
                                  1 +
                                  (current_page - 1) * linkListInfo.page_size}
                            </td>
                            <td>{data.name}</td>
                            <td>
                              <Link
                                  to={`/ProductLinkDetail/${data.id}`}
                                  className="m-2 btn btn-outline-primary btn-sm"
                              >
                                導購連結設定
                              </Link>
                            </td>
                          </tr>
                      ))
                  ) : (
                      <TableHasNoData />
                  )}
                  </tbody>
              )}
            </Table>
          </div>

          {!loading && linkListInfo && linkListInfo.total_count > 0 && (
              <div className="d-flex align-items-center justify-content-center">
                <RcPagination
                    current={linkListInfo?.current_page || 1}
                    pageSize={pageSize}
                    total={linkListInfo?.total_count || 0}
                    onChange={handlePageChange}
                />
              </div>
          )}
        </Card>
        <Modal
            zIndex={2000}
            centered
            size="lg"
            isOpen={sortModal}
            toggle={toggleSortModal}
            backdrop="static"
        >
          <ModalHeader toggle={toggleSortModal} className={style.modal_head}>
            分類排序
          </ModalHeader>
          <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="brands">
                {provided => (
                    <ul
                        className="drag_list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                      {editSortList?.map(({ id, name, status }, index) => (
                          <Draggable key={id} draggableId={`${id}`} index={index}>
                            {provided => (
                                <li
                                    className="drag_data"
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                  <span className="title">{index + 1}</span>
                                  <span className="brand_name">
                            {name}
                                    {status === '下架' && (
                                        <span className="badge bg-danger ms-4">下架</span>
                                    )}
                          </span>
                                </li>
                            )}
                          </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <ModalFooter className={style.modal_footer}>
            <Button
                color="light"
                className={style.modal_button_cancel}
                onClick={toggleSortModal}
            >
              取消
            </Button>
            <Button
                color="primary"
                type="submit"
                className={style.modal_button_submit}
                onClick={handleUpdateSort}
                disabled={sortLoading}
            >
              {sortLoading && (
                  <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  ></span>
              )}
              儲存
            </Button>
          </ModalFooter>
        </Modal>
      </Layout>
  );
};

export default ProductLinkControl;
