import { useFormik } from 'formik';
import React, { memo, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Form, FormGroup } from 'reactstrap';

import { getHideModelDetail } from '../../redux/actions/data/hideModelActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import request from '../../utils/useAxios';
import { BlockSpinner } from '../Spinner';
import LabelC from '../groupModal/LabelC';

const HideModelOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, hideModelDetailInfo } = useSelector(
    state => state.hideModelDetail,
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const { values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      logisticsWays: (hideModelDetailInfo?.orderSetting?.logisticsWays || []).map(logisticsWays=>{
        if(logisticsWays.name.includes('宅配')){
          logisticsWays.isEabled = true;
          logisticsWays.disabled = true;
        }
        return logisticsWays
      }),
      payWays: (hideModelDetailInfo?.orderSetting?.payWays || []).map(payWay=>{
        if(payWay.name.includes('信用卡')){
          payWay.isEabled = true;
          payWay.disabled = true;
        }
        return payWay
      }),
    },
    async onSubmit(formModel) {
      setSubmitLoading(true);
      const { code, message } = await request({
        data: formModel,
        method: 'post',
        url: `/Backend/HideModel/setOrder/${id}`,
      });
      setSubmitLoading(false);
      if (code !== 200) {
        MessageUtil.alertWanring(message);
        return;
      }
      MessageUtil.toastSuccess('儲存成功', '');
      dispatch(getHideModelDetail(id));
    },
  });

  return (
    <Card className={style.card}>
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            {' '}
            付款方式、配送方式的設定在
            <Link to="/CheckoutPayment">【購物設定&gt;付款方式】</Link>
            <Link to="/CheckoutDelivery">【購物設定&gt;配送方式】</Link>
            ，只要未停用的項目，就會在這頁出現
          </span>
        </div>
        <div className="d-flex gap-1">
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            {' '}
            停用【付款/取配送】方式，將會連動相關功能：
            <br />
            例：停用【便利商店取貨付款】，配送方式的【7-11取貨、全家取貨】也會停用
          </span>
        </div>
      </Alert>
      <Form className="position-relative">
        {(loading || submitLoading) && <BlockSpinner />}
        <FormGroup row>
          <LabelC text="付款方式" />
          <Col>
            {values?.payWays?.map((i, index) => (
              <FormGroup key={index}>
                <label className="d-flex gap-2 mb-1">
                  <input
                    type="checkbox"
                    name={`payWays[${index}].isEabled`}
                    checked={values.payWays[index].isEabled}
                    onChange={handleChange}
                    disabled={values.payWays[index].disabled}
                    className={style.maintain_list_checkbox}
                  />
                  {i.name}
                </label>
              </FormGroup>
            ))}
          </Col>
        </FormGroup>
        <FormGroup row>
          <LabelC text="配送方式" />
          <Col>
            {values?.logisticsWays?.map((i, index) => (
              <FormGroup key={index}>
                <label className="d-flex gap-2 mb-1">
                  <input
                    type="checkbox"
                    name={`logisticsWays[${index}].isEabled`}
                    checked={values.logisticsWays[index].isEabled}
                    onChange={handleChange}
                    disabled={values.logisticsWays[index].disabled}
                    className={style.maintain_list_checkbox}
                  />
                  {i.name}
                </label>
              </FormGroup>
            ))}
          </Col>
        </FormGroup>
      </Form>
      <p className={`${style.fs_14} mb-0`}>
        異動時間：
        {hideModelDetailInfo.orderSetting?.updateDate}{' '}
        {hideModelDetailInfo.orderSetting?.updateTime}{' '}
        {hideModelDetailInfo.orderSetting?.updater}
      </p>
      <hr />
      <div className="text-center">
        <Button
          color="primary"
          className={style.modal_button_submit}
          disabled={loading || submitLoading}
          onClick={handleSubmit}
        >
          {(loading || submitLoading) && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          儲存
        </Button>
      </div>
    </Card>
  );
};

export default memo(HideModelOrder);
