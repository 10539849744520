import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { memo, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Table,
  TabPane,
} from 'reactstrap';
import * as Yup from 'yup';

import { getHideModelDetail } from '../../redux/actions/data/hideModelActions';
import style from '../../styles/layout.module.css';
import { hourArr, minArr } from '../../utils';
import MessageUtil from '../../utils/MessageUtil';
import request from '../../utils/useAxios';
import CustomColorPicker from '../CustomColorPicker';
import ProductImgPicker from '../ProductImgPicker';
import SearchSelect from '../SearchSelector';
import { BlockSpinner } from '../Spinner';
import TableHead from '../TableHead';
import UploadFiles from '../UploadFiles';
import LabelC from '../groupModal/LabelC';

import AddProductModal from './AddProductModal';

const HideModelSetting = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { loading, hideModelDetailInfo } = useSelector(
    state => state.hideModelDetail,
  );
  const checkCanDelete = useMemo(() => {
    return moment(hideModelDetailInfo.setting?.availableTimeStart).isAfter(
      moment(),
    );
  }, [hideModelDetailInfo.setting?.availableTimeStart]);

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableEndTimeType: 0,
      availableTimeEnd: '2099/12/12',
      availableTimeEndHour: 0,
      availableTimeEndMin: 0,
      availableTimeStart: '1000-01-01',
      availableTimeStartHour: 0,
      availableTimeStartMin: 0,
      features: { content: '' },
      images: [],
      modelImgFiles: [],
      modelName: '',
      modelName_en: '',
      modelimgs: [],
      productType: '',
      products: [],
      status: 1,
      updateDate: '',
      updateTime: '',
      updater: '',
    },
    async onSubmit(formModel) {
      const params = {
        ...formModel,
        availableTimeEnd:
          formModel.availableEndTimeType === 1
            ? (formModel.availableTimeEnd
                ? moment(formModel.availableTimeEnd)
                : moment()
              )
                .hour(+formModel.availableTimeEndHour)
                .minute(+formModel.availableTimeEndMin)
                .second(59)
                .format('YYYY-MM-DDTHH:mm:ss')
            : '2099-12-12T12:12:12',
        availableTimeStart: (formModel.availableTimeStart
          ? moment(formModel.availableTimeStart)
          : moment()
        )
          .hour(+formModel.availableTimeStartHour)
          .minute(+formModel.availableTimeStartMin)
          .second(0)
          .format('YYYY-MM-DDTHH:mm:ss'),
        features: JSON.stringify([formModel.features]),
        modelimgs: JSON.stringify(formModel.modelimgs),
        products: JSON.stringify(formModel.products),
      };
      const formData = new FormData();
      Object.keys(params).forEach(i => {
        formData.append(i, params[i]);
      });
      formModel.modelImgFiles.forEach(i => {
        formData.append('modelImgFiles', i);
      });
      setSubmitLoading(true);
      const { code, message } = await request({
        data: formData,
        method: 'post',
        url: `/Backend/HideModel/setSetting/${id}`,
      });
      setSubmitLoading(false);
      if (code !== 200) {
        MessageUtil.alertWanring(message);
        return;
      }
      MessageUtil.toastSuccess('儲存成功！');
      dispatch(getHideModelDetail(id));
    },
    validationSchema: Yup.object().shape({
      availableEndTimeType: Yup.number().positive('必填！'),
      features: Yup.object()
        .nullable()
        .shape({
          content: Yup.string().required('請輸入特色說明'),
        })
        .required('請輸入特色說明'),
      products: Yup.array().of(
        Yup.object().shape({
          colorName: Yup.string().nullable().required('必填'),
          colorName_en: Yup.string().nullable().required('必填'),
          lowestStock: Yup.number().min(1, '數值必須大於0！'),
          maxSellNum: Yup.number().min(1, '數值必須大於0！'),
          sellprice: Yup.number().min(1, '數值必須大於0！'),
          sellquantity: Yup.number().min(1, '數值必須大於0！'),
          stock: Yup.number().min(1, '數值必須大於0！'),
        }),
      ),
    }),
  });

  console.log('errors', errors);

  useEffect(() => {
    if (hideModelDetailInfo) {
      setValues({
        availableEndTimeType:
          hideModelDetailInfo.setting?.availableEndTimeType ?? 0,
        availableTimeEnd: hideModelDetailInfo.setting?.availableTimeEnd,
        availableTimeEndHour: hideModelDetailInfo.setting?.availableTimeEnd
          ? moment(hideModelDetailInfo.setting?.availableTimeEnd).hour()
          : 0,
        availableTimeEndMin: hideModelDetailInfo.setting?.availableTimeEnd
          ? moment(hideModelDetailInfo.setting?.availableTimeEnd).minute()
          : 0,
        availableTimeStart: hideModelDetailInfo.setting?.availableTimeStart,
        availableTimeStartHour: hideModelDetailInfo.setting?.availableTimeStart
          ? moment(hideModelDetailInfo.setting?.availableTimeStart).hour()
          : 0,
        availableTimeStartMin: hideModelDetailInfo.setting?.availableTimeStart
          ? moment(hideModelDetailInfo.setting?.availableTimeStart).minute()
          : 0,
        features:
          hideModelDetailInfo?.setting?.features?.length > 0
            ? hideModelDetailInfo?.setting?.features[0]
            : { content: '' },
        modelImgFiles: [],
        modelName: hideModelDetailInfo?.modelName,
        modelimgs: hideModelDetailInfo.setting?.images?.map(i => ({
          ...i,
          imgFormFileId: -1,
          isRemoveFile: false,
          name: i.image,
          url: i.imageUrl,
        })),
        products: (hideModelDetailInfo.setting?.products || []).map(p => {
          if (p.productType === 3) {
            return {
              ...p,
              lowestStock: (p.relProducts || [])?.reduce(
                (sum, rp) => sum + Number(rp.lowestStock),
                0,
              ),
            };
          }
          return p;
        }),
        remark: hideModelDetailInfo.setting?.remark || '',
        status: hideModelDetailInfo.status ?? 1,
      });
    }
  }, [hideModelDetailInfo]);
  const handleChangeImages = images => {
    const modelImgFiles = images.filter(i => Boolean(i.file)).map(i => i.file);
    const modelimgs = images.map((i, index) => ({
      id: i.id,
      image: i.name,
      imgFormFileId: modelImgFiles.indexOf(i.file),
      isRemoveFile: !i.file,
      sort: index + 1,
      ...i,
    }));
    setFieldValue('modelImgFiles', modelImgFiles);
    setFieldValue('modelimgs', modelimgs);
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null);

  const handleToggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const handleDeleteRelProduct = (iIndex, jIndex) => {
    MessageUtil.submitConfirm({
      doneFun() {
        setFieldValue(
          `products[${iIndex}].relProducts`,
          values.products[iIndex].relProducts.filter(
            (_, index) => jIndex !== index,
          ),
        );
      },
      title: '確認刪除？',
    });
  };

  const hidelModelProductType = useMemo(() => {
    if (
      hideModelDetailInfo.setting &&
      hideModelDetailInfo.setting.products &&
      hideModelDetailInfo.setting.products.length > 0
    ) {
      return hideModelDetailInfo.setting.products[0].productType || 1;
    } else {
      return 1;
    }
  }, [hideModelDetailInfo]);

  const handleAddModalSubmit = list => {
    if (hidelModelProductType === 1) {
      setFieldValue('products', list);
      handleToggleAddModal();
    } else {
      if (currentGroupIndex !== null && values.products.length > 0) {
        const params = list.map((i, index) => ({
          ...i,
          index:
            (values.products[currentGroupIndex]?.relProducts.length || 0) +
            index,
        }));
        setFieldValue(`products[${currentGroupIndex}].relProducts`, [
          ...params,
        ]);
        handleToggleAddModal();
        return;
      }
      const params = {
        colorCode: '',
        colorName: '',
        colorName_en: '',
        defaultImgId: '',
        groupModelProductId: 0,
        isSoldOut: false,
        outofStockWords: '已售完',
        productname: '',
        relProducts: list.map((i, index) => ({
          ...i,
          color: i.color,
          index,
          lowestStock: i.lowestStock ?? 0,
          productId: i.id,
          productcode: i.code,
          productname: i.name,
          sellprice: i.sellprice ?? 0,
          sellquantity: (i.h_sellQuantity || i.sellquantity) ?? 0,
          size: i.size,
          stock: i.stock ?? 0,
        })),
        showImg: '',
        size: '',
      };
      setFieldValue('products', [...values.products, params]);
      handleToggleAddModal();
    }
  };
  const handleDeleteProduct = i => {
    MessageUtil.submitConfirm({
      doneFun() {
        setFieldValue(
          'products',
          values.products.filter((_, index) => i !== index),
        );
      },
      title: '確認刪除？',
    });
  };

  useEffect(() => {
    if (hidelModelProductType === 3 && values.products.length > 0) {
      setFieldValue(
        'products',
        values.products.map(p => {
          return {
            ...p,
            lowestStock: (p?.relProducts || []).reduce(
              (sum, p) => sum + Number(p.lowestStock),
              0,
            ),
            sellprice: (p?.relProducts || []).reduce(
              (sum, p) => sum + Number(p.sellquantity) * Number(p.sellprice),
              0,
            ),
            stock: (p?.relProducts || []).reduce(
              (sum, p) => sum + Number(p.stock),
              0,
            ),
          };
        }),
      );
    }
  }, [hidelModelProductType, addModalOpen, loading]);

  return (
    <TabPane tabId={1}>
      <Card className={style.card} style={{ overflow: 'inherit' }}>
        <Form className="position-relative">
          {(loading || submitLoading) && <BlockSpinner />}
          <FormGroup row>
            <LabelC text="商品名稱" required />
            <Col>
              <Input
                name="modelName"
                onChange={handleChange}
                value={values.modelName}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <LabelC text="商品圖片" required />
            <Col className="mb-4">
              <UploadFiles
                thumbSize="100px"
                defaultFiles={values.modelimgs}
                onChange={handleChangeImages}
              />
            </Col>
          </FormGroup>
          <Button
            color="success"
            className="mb-3"
            title="可銷售區間開始後不能新增/刪除商品"
            disabled={!checkCanDelete}
            onClick={() => {
              if (hidelModelProductType === 3) {
                setCurrentGroupIndex(0);
              }
              setAddModalOpen(true);
            }}
          >
            新增商品
          </Button>
          <AddProductModal
            open={addModalOpen}
            toggle={handleToggleAddModal}
            onSuccess={handleAddModalSubmit}
            selectedItems={
              hidelModelProductType === 1
                ? values.products
                : values.products.reduce(
                    (arr, p) => [...arr, ...p.relProducts],
                    [],
                  )
            }
            productType={hidelModelProductType}
          />

          {!checkCanDelete && (
            <div className="form-error">銷售區間開始後不得新增刪除商品</div>
          )}

          <div className="table-responsive mb-5">
            {hidelModelProductType === 1 ? (
              <Table hover bordered>
                <TableHead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th>圖片</th>
                    <th>
                      <div>ProductCode</div>
                      <div>BarCode</div>
                    </th>
                    <th style={{ minWidth: 180 }}>*顏色</th>
                    <th style={{ minWidth: 180 }}>*顏色名稱</th>
                    <th style={{ minWidth: 100 }}>尺吋</th>
                    <th style={{ minWidth: 180 }}>
                      <div>*建議售價</div>
                      <div>*售價</div>
                    </th>
                    <th style={{ minWidth: 100 }}>售完</th>
                    <th style={{ minWidth: 100 }}>上下架</th>
                    <th style={{ minWidth: 150 }}>
                      <div>*庫存量</div>
                      <div>*安全庫存</div>
                    </th>
                    <th style={{ minWidth: 120 }}>*一次最高購買量</th>
                    <th>缺貨顯示</th>
                    <th>總銷售量</th>
                    <th>總銷售金額</th>
                    <th>功能</th>
                  </tr>
                </TableHead>
                <tbody>
                  {values.products.map((i, index) => (
                    <tr key={index}>
                      <td>
                        <ProductImgPicker
                          thumbnail={i.showImg}
                          imgs={values.modelimgs}
                          onSelect={imgItem => {
                            setFieldValue(
                              `products[${index}].defaultImgId`,
                              imgItem.id,
                            );
                            setFieldValue(
                              `products[${index}].showImg`,
                              imgItem.url,
                            );
                          }}
                        />
                      </td>
                      <td>
                        <div>{i.productcode}</div>
                        <div className="text-nowrap">{i.barcode}</div>
                      </td>
                      <td>
                        <div>{i.color || '--'}</div>
                        <CustomColorPicker
                          initColor={i.colorCode}
                          formFieldName={`products[${index}].colorCode`}
                          setFormFieldValue={setFieldValue}
                        />
                      </td>
                      <td>
                        {/* 顏色名稱*/}
                        <div className="d-flex align-items-center mb-1">
                          中：
                          <Input
                            type="text"
                            name={`products[${index}].colorName`}
                            onChange={handleChange}
                            value={values.products[index]?.colorName}
                          />
                        </div>
                        <div className="form-error">
                          {typeof errors?.products === 'object' &&
                            errors?.products[index] &&
                            errors?.products[index].colorName}
                        </div>
                        <div className="d-flex align-items-center">
                          英：
                          <Input
                            type="text"
                            name={`products[${index}].colorName_en`}
                            onChange={handleChange}
                            value={values.products[index]?.colorName_en}
                          />
                        </div>
                        <div className="form-error">
                          {typeof errors?.products === 'object' &&
                            errors?.products[index] &&
                            errors?.products[index].colorName_en}
                        </div>
                      </td>
                      <td align="center">{i.size || '--'}</td>
                      <td className="text-nowrap">
                        {/*建議售價 售價 */}
                        <div className="mb-2 d-flex">
                          NT$
                          <span className="mx-1">
                            {(i?.websiteprice ?? 0).toLocaleString()}
                          </span>
                        </div>
                        <div className="mb-2 d-flex align-items-center">
                          NT$
                          <Input
                            size="sm"
                            name={`products[${index}].sellprice`}
                            className="mx-1"
                            type="number"
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].sellprice`,
                                Number(e.target.value).toString(),
                              );
                            }}
                            onBlur={e => {
                              setFieldValue(
                                `products[${index}].sellprice`,
                                Number(e.target.value),
                              );
                            }}
                            value={values.products[index].sellprice}
                          />
                        </div>
                        <div className="form-error">
                          {typeof errors?.products === 'object' &&
                            errors?.products[index] &&
                            errors?.products[index].sellprice}
                        </div>
                      </td>
                      <td>
                        {/*售完*/}
                        <Label className="d-flex gap-1 align-items-center">
                          <input
                            className={style.maintain_list_checkbox}
                            name={`products[${index}].isSoldOut`}
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].isSoldOut`,
                                Boolean(+e.target.value),
                              );
                            }}
                            type="radio"
                            defaultChecked={values.products[index].isSoldOut}
                            value="1"
                          />
                          是
                        </Label>
                        <Label className="d-flex gap-1 align-items-center">
                          <input
                            className={style.maintain_list_checkbox}
                            name={`products[${index}].isSoldOut`}
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].isSoldOut`,
                                Boolean(+e.target.value),
                              );
                            }}
                            type="radio"
                            defaultChecked={!values.products[index].isSoldOut}
                            value="0"
                          />
                          否
                        </Label>
                      </td>
                      <td>
                        {/*上架*/}
                        <div className="d-flex align-items-center gap-2">
                          <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].isSell`}
                              checked={values.products[index]?.isSell}
                              onChange={() => {
                                setFieldValue(
                                    `products[${index}].isSell`,
                                    true,
                                );
                              }}
                          />
                          是
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].isSell`}
                              checked={!values.products[index]?.isSell}
                              onChange={() => {
                                setFieldValue(
                                    `products[${index}].isSell`,
                                    false,
                                );
                              }}
                          />
                          否
                        </div>
                      </td>
                      <td>
                        {/*庫存量*/}
                        <div>
                          <Input
                            name={`products[${index}].stock`}
                            type="number"
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].stock`,
                                Number(e.target.value).toString(),
                              );
                            }}
                            onBlur={e => {
                              setFieldValue(
                                `products[${index}].stock`,
                                Number(e.target.value),
                              );
                            }}
                            value={values.products[index].stock}
                          />
                          <div className="form-error">
                            {typeof errors?.products === 'object' &&
                              errors?.products[index] &&
                              errors?.products[index].stock}
                          </div>
                        </div>
                        {/*安全庫存量*/}
                        <div>
                          <Input
                            name={`products[${index}].lowestStock`}
                            type="number"
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].lowestStock`,
                                Number(e.target.value).toString(),
                              );
                            }}
                            onBlur={e => {
                              setFieldValue(
                                `products[${index}].lowestStock`,
                                Number(e.target.value),
                              );
                            }}
                            value={values.products[index].lowestStock}
                          />
                          <div className="form-error">
                            {typeof errors?.products === 'object' &&
                              errors?.products[index] &&
                              errors?.products[index].lowestStock}
                          </div>
                        </div>
                      </td>
                      <td>
                        {/* 一次最高購買量*/}
                        <Input
                          type="number"
                          name={`products[${index}].maxSellNum`}
                          onChange={e => {
                            setFieldValue(
                              `products[${index}].maxSellNum`,
                              Number(e.target.value).toString(),
                            );
                          }}
                          onBlur={e => {
                            setFieldValue(
                              `products[${index}].maxSellNum`,
                              Number(e.target.value),
                            );
                          }}
                          value={values.products[index]?.maxSellNum}
                        />
                        <div className="form-error">
                          {typeof errors?.products === 'object' &&
                            errors?.products[index] &&
                            errors?.products[index].maxSellNum}
                        </div>
                      </td>
                      <td className="text-nowrap">
                        {/*  缺貨顯示*/}
                        <Label className="d-flex gap-1 align-items-center">
                          <input
                            className={style.maintain_list_checkbox}
                            name={`products[${index}].outofStockWords`}
                            onChange={handleChange}
                            type="radio"
                            value="已售完"
                            checked={
                              values.products[index].outofStockWords ===
                              '已售完'
                            }
                          />
                          已售完
                        </Label>
                        <Label className="d-flex gap-1 align-items-center">
                          <input
                            className={style.maintain_list_checkbox}
                            name={`products[${index}].outofStockWords`}
                            onChange={handleChange}
                            type="radio"
                            value="售完不補貨"
                            checked={
                              values.products[index].outofStockWords ===
                              '售完不補貨'
                            }
                          />
                          售完不補貨
                        </Label>
                      </td>
                      <td align="center">{i.sellQuantity ?? 0}</td>
                      <td className="text-nowrap">
                        <span className="price text-dark fw-normal">
                          {i.sellAmount?.toLocaleString('en-US')}
                        </span>
                      </td>
                      <td>
                     {/*   <Button
                          color="danger"
                          outline
                          title="銷售區間開始後不得刪除商品"
                          disabled={!checkCanDelete}
                          onClick={() => handleDeleteProduct(index)}
                        >
                          刪除
                        </Button>*/}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              values.products?.map((i, index) => {
                return (
                  <Table hover bordered key={`${i.hProductId}`}>
                    <thead className={`thead-dark ${style.table_head}`}>
                      <tr>
                        <th>圖片</th>
                        <th style={{ minWidth: 250 }}>*productcode</th>
                        <th style={{ minWidth: 180 }}>*顏色</th>
                        <th style={{ minWidth: 180 }}>*顏色名稱</th>
                        <th style={{ minWidth: 180 }}>尺吋</th>
                        <th style={{ minWidth: 180 }}>建議售價</th>
                        <th style={{ minWidth: 150 }}>*售價</th>
                        <th style={{ minWidth: 100 }}>售完</th>
                        <th style={{ minWidth: 100 }}>上下架</th>
                        <th>缺貨顯示</th>
                        <th nowrap="nowrap">*一次最高購買量</th>
                        <th style={{ minWidth: 100 }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <ProductImgPicker
                            thumbnail={i.showImg}
                            imgs={values.modelimgs}
                            onSelect={imgItem => {
                              setFieldValue(
                                `products[${index}].defaultImgId`,
                                imgItem.id,
                              );
                              setFieldValue(
                                `products[${index}].showImg`,
                                imgItem.url,
                              );
                            }}
                          />
                        </td>
                        <td>
                          <td>
                            <div>{i.productcode}</div>
                            <div className="text-nowrap">{i.barcode}</div>
                          </td>
                        </td>
                        <td>
                          {/* 顏色 */}
                          <CustomColorPicker
                            initColor={i.colorCode}
                            formFieldName={`products[${index}].colorCode`}
                            setFormFieldValue={setFieldValue}
                          />
                          <FormText color="danger" className="d-block">
                            {errors.products?.[index]?.colorCode}
                          </FormText>
                        </td>
                        <td>
                          <div className="d-flex align-items-center mb-1 flex-column">
                            <div className="d-flex">
                              中：
                              <Input
                                type="text"
                                name={`products[${index}].colorName`}
                                onChange={handleChange}
                                value={values.products[index]?.colorName}
                              />
                            </div>
                            <FormText color="danger" className="d-block">
                              {errors.products?.[index]?.colorName}
                            </FormText>
                          </div>
                          <div className="d-flex align-items-center mb-1 flex-column">
                            <div className="d-flex">
                              英：
                              <Input
                                type="text"
                                name={`products[${index}].colorName_en`}
                                onChange={handleChange}
                                value={values.products[index]?.colorName_en}
                              />
                            </div>
                            <FormText color="danger" className="d-block">
                              {errors.products?.[index]?.colorName_en}
                            </FormText>
                          </div>
                        </td>
                        <td align="center">{i.size || '--'}</td>
                        <td className="text-nowrap">
                          <span className="price text-dark fw-normal">
                            {(values.products?.[index]?.relProducts?.length > 0
                              ? values.products?.[index]?.relProducts.reduce(
                                  (a, b) => a + b.websiteprice * b.sellquantity,
                                  0,
                                )
                              : i?.websiteprice
                            )?.toLocaleString('en-US')}
                          </span>
                        </td>
                        <td className="text-nowrap">
                          {values.products?.[index]?.relProducts?.length > 0 ? (
                            <span className="price text-dark fw-normal">
                              {values.products?.[index]?.relProducts
                                ?.reduce(
                                  (a, b) => a + b.sellprice * b.sellquantity,
                                  0,
                                )
                                ?.toLocaleString('en-US')}
                            </span>
                          ) : (
                            <>
                              NT$
                              <Input
                                size="sm"
                                name={`products[${index}].sellprice`}
                                className="mx-1"
                                type="number"
                                onChange={e => {
                                  setFieldValue(
                                    `products[${index}].sellprice`,
                                    Number(e.target.value).toString(),
                                  );
                                }}
                                onBlur={e => {
                                  setFieldValue(
                                    `products[${index}].sellprice`,
                                    Number(e.target.value),
                                  );
                                }}
                                value={values.products[index].sellprice}
                              />
                            </>
                          )}
                          <FormText color="danger" className="d-block">
                            {errors.products?.[index]?.sellprice}
                          </FormText>
                        </td>
                        <td>
                          <Label className="d-flex gap-1 align-items-center">
                            <input
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].isSoldOut`}
                              onChange={e => {
                                setFieldValue(
                                  `products[${index}].isSoldOut`,
                                  Boolean(+e.target.value),
                                );
                              }}
                              type="radio"
                              defaultChecked={values.products[index].isSoldOut}
                              value="1"
                            />
                            是
                          </Label>
                          <Label className="d-flex gap-1 align-items-center">
                            <input
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].isSoldOut`}
                              onChange={e => {
                                setFieldValue(
                                  `products[${index}].isSoldOut`,
                                  Boolean(+e.target.value),
                                );
                              }}
                              type="radio"
                              defaultChecked={!values.products[index].isSoldOut}
                              value="0"
                            />
                            否
                          </Label>
                        </td>
                        <td>
                          {/*上架*/}
                          <Label
                              className="d-flex gap-1 align-items-center"
                              htmlFor={`products[${index}].isSell.true`}
                          >
                            <input
                                id={`products[${index}].isSell.true`}
                                className={style.maintain_list_checkbox}
                                name={`products[${index}].isSell`}
                                onClick={e => {
                                  setFieldValue(
                                      `products[${index}].isSell`,
                                      true,
                                  );
                                }}
                                type="radio"
                                checked={values.products[index].isSell}
                                value={true}
                            />
                            是
                          </Label>
                          <Label
                              className="d-flex gap-1 align-items-center"
                              htmlFor={`products[${index}].isSell.false`}
                          >
                            <input
                                id={`products[${index}].isSell.false`}
                                className={style.maintain_list_checkbox}
                                onClick={e => {
                                  setFieldValue(
                                      `products[${index}].isSell`,
                                      false,
                                  );
                                }}
                                type="radio"
                                checked={!values.products[index].isSell}
                                value={false}
                            />
                            否
                          </Label>
                        </td>
                        <td className="text-nowrap">
                          <Label className="d-flex gap-1 align-items-center">
                            <input
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].outofStockWords`}
                              onChange={handleChange}
                              type="radio"
                              value="已售完"
                              checked={
                                values.products[index].outofStockWords ===
                                '已售完'
                              }
                            />
                            已售完
                          </Label>
                          <Label className="d-flex gap-1 align-items-center">
                            <input
                              className={style.maintain_list_checkbox}
                              name={`products[${index}].outofStockWords`}
                              onChange={handleChange}
                              type="radio"
                              value="售完不補貨"
                              checked={
                                values.products[index].outofStockWords ===
                                '售完不補貨'
                              }
                            />
                            售完不補貨
                          </Label>
                        </td>
                        <td>
                          {/* *一次最高購買量*/}
                          <Input
                            type="number"
                            name={`products[${index}].maxSellNum`}
                            onChange={e => {
                              setFieldValue(
                                `products[${index}].maxSellNum`,
                                Number(e.target.value).toString(),
                              );
                            }}
                            onBlur={e => {
                              setFieldValue(
                                `products[${index}].maxSellNum`,
                                Number(e.target.value),
                              );
                            }}
                            value={values.products[index]?.maxSellNum}
                          />
                          <FormText color="danger" className="d-block">
                            {errors.products?.[index]?.maxSellNum}
                          </FormText>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <Button
                              color="primary"
                              outline
                              disabled={!checkCanDelete}
                              onClick={() => {
                                setAddModalOpen(true);
                                setCurrentGroupIndex(index);
                              }}
                            >
                              新增商品
                            </Button>
                         {/*   <Button
                              color="danger"
                              outline
                              disabled={!checkCanDelete}
                              onClick={() => handleDeleteProduct(index)}
                            >
                              刪除
                            </Button>*/}
                          </div>
                        </td>
                      </tr>
                      <tr
                        className={`${style.bg_gray}`}
                        style={{ borderBottom: '2px solid #999' }}
                      >
                        <td colSpan="2"></td>
                        <td>顏色</td>
                        <td>尺寸</td>
                        <td>建議售價</td>
                        <td>*售價</td>
                        <td>*銷售數量</td>
                        <td>*庫存量</td>
                        <td>*安全庫存</td>
                        <td></td>
                      </tr>
                      {i.relProducts?.map((j, jIndex) => {
                        return (
                          <tr
                            className={style.bg_gray}
                            key={`${i.hProductId}_${j.id}_${index}`}
                          >
                            <td>{j.productcode}</td>
                            <td>{j.productname}</td>
                            <td>{j.color}</td>
                            <td>{j.size || '--'}</td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span style={{ wordBreak: 'keep-all' }}>
                                  NT$
                                </span>
                                {values.products[index]?.relProducts?.[
                                  jIndex
                                ]?.websiteprice?.toLocaleString() ?? '0'}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-1">
                                <span style={{ wordBreak: 'keep-all' }}>
                                  NT$
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  name={`products[${index}].relProducts[${jIndex}].sellprice`}
                                  onChange={e => {
                                    setFieldValue(
                                      `products[${index}].relProducts[${jIndex}].sellprice`,
                                      Number(e.target.value).toString(),
                                    );
                                  }}
                                  onBlur={e => {
                                    setFieldValue(
                                      `products[${index}].relProducts[${jIndex}].sellprice`,
                                      Number(e.target.value),
                                    );
                                    setFieldValue(
                                      `products[${index}].sellprice`,
                                      values.products[
                                        index
                                      ].relProducts?.reduce(
                                        (sum, p) =>
                                          sum +
                                          Number(p.sellquantity) *
                                            Number(p.sellprice),
                                        0,
                                      ),
                                    );
                                  }}
                                  value={
                                    values.products[index]?.relProducts?.[
                                      jIndex
                                    ]?.sellprice
                                  }
                                />
                              </div>
                              <FormText color="danger" className="d-block">
                                {
                                  errors.products?.[index]?.relProducts?.[
                                    jIndex
                                  ]?.sellprice
                                }
                              </FormText>
                            </td>
                            <td>
                              {/*銷售數量*/}
                              <Input
                                type="number"
                                name={`products[${index}].relProducts[${jIndex}].sellquantity`}
                                onChange={e => {
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].sellquantity`,
                                    Number(e.target.value).toString(),
                                  );
                                }}
                                onBlur={e => {
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].sellquantity`,
                                    Number(e.target.value),
                                  );
                                  setFieldValue(
                                    `products[${index}].sellprice`,
                                    values.products[index].relProducts?.reduce(
                                      (sum, p) =>
                                        sum +
                                        Number(p.sellquantity) *
                                          Number(p.sellprice),
                                      0,
                                    ),
                                  );
                                }}
                                value={
                                  values.products[index]?.relProducts?.[jIndex]
                                    ?.sellquantity
                                }
                              />
                              <FormText color="danger" className="d-block">
                                {
                                  errors.products?.[index]?.relProducts?.[
                                    jIndex
                                  ]?.sellquantity
                                }
                              </FormText>
                            </td>
                            <td>
                              <Input
                                type="number"
                                name={`products[${index}].relProducts[${jIndex}].stock`}
                                onChange={e => {
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].stock`,
                                    Number(e.target.value).toString(),
                                  );
                                }}
                                onBlur={e => {
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].stock`,
                                    Number(e.target.value),
                                  );
                                  setFieldValue(
                                    `products[${index}].stock`,
                                    values.products[index].relProducts?.reduce(
                                      (sum, p) => sum + Number(p.stock),
                                      0,
                                    ),
                                  );
                                }}
                                value={
                                  values.products[index]?.relProducts?.[jIndex]
                                    ?.stock
                                }
                              />
                              <FormText color="danger" className="d-block">
                                {
                                  errors.products?.[index]?.relProducts?.[
                                    jIndex
                                  ]?.stock
                                }
                              </FormText>
                            </td>
                            <td>
                              {/*安全庫存量*/}
                              <Input
                                type="number"
                                name={`products[${index}].relProducts[${jIndex}].lowestStock`}
                                onChange={e => {
                                  setFieldValue(
                                    `products[${index}].lowestStock`,
                                    values.products[index].relProducts?.reduce(
                                      (sum, p) => sum + Number(p.lowestStock),
                                      0,
                                    ),
                                  );
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].lowestStock`,
                                    Number(e.target.value).toString(),
                                  );
                                }}
                                onBlur={e => {
                                  setFieldValue(
                                    `products[${index}].relProducts[${jIndex}].lowestStock`,
                                    Number(e.target.value),
                                  );
                                }}
                                value={
                                  values.products[index]?.relProducts?.[jIndex]
                                    ?.lowestStock
                                }
                              />
                              <FormText color="danger" className="d-block">
                                {
                                  errors.products?.[index]?.relProducts?.[
                                    jIndex
                                  ]?.lowestStock
                                }
                              </FormText>
                            </td>
                            <td></td>
                            <td className="text-center">
                            {/*  <Button
                                color="danger"
                                outline
                                disabled={!checkCanDelete}
                                onClick={() =>
                                  handleDeleteRelProduct(index, jIndex)
                                }
                              >
                                刪除
                              </Button>*/}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                );
              })
            )}
          </div>
          <FormGroup row>
            <LabelC text="特色說明" required />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="features.content"
                onChange={e => {
                  setFieldValue('features', {
                    content: e.target.value,
                  });
                }}
                value={values.features?.content}
              />
              <div className="form-error">
                {typeof errors?.features === 'string' &&
                  (errors?.features || '')}
                {typeof errors?.features === 'object' &&
                  (errors?.features?.content || '')}
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <LabelC text="可銷售區間" required />
            <Col>
              <FormGroup>
                <Label>銷售開始時間</Label>
                <div className="form-item flex-wrap d-flex mb-3">
                  <div className="w200">
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd"
                      selected={moment(values?.availableTimeStart || new Date())
                        .startOf('day')
                        .toDate()}
                      name="availableTimeStart"
                      onChange={date => {
                        setFieldValue(
                          'availableTimeStart',
                          moment(date).startOf('day').toDate(),
                        );
                      }}
                      selectsStart
                    />
                  </div>
                  <Input
                    type="select"
                    className="w80 ms-1"
                    name="availableTimeStartHour"
                    onChange={handleChange}
                    value={values.availableTimeStartHour}
                  >
                    {(moment(values?.availableTimeStart || new Date()).isSame(
                      moment(),
                      'day',
                    )
                      ? Array.from(
                          { length: 23 - moment().hour() },
                          (_, i) => i + moment().hour(),
                        )
                      : Array.from({ length: 23 }, (_, i) => i)
                    ).map(v => (
                      <option key={v} value={v}>
                        {`0${v}`.slice(-2)}
                      </option>
                    ))}
                  </Input>
                  <span className="px-2 pt-2">:</span>
                  <Input
                    type="select"
                    className="w80"
                    name="availableTimeStartMin"
                    onChange={handleChange}
                    value={values.availableTimeStartMin}
                  >
                    {(moment(values?.availableTimeStart || new Date()).isSame(
                      moment(),
                      'day',
                    )
                      ? Array.from(
                          { length: 59 - moment().minute() },
                          (_, i) => i + moment().minute(),
                        )
                      : Array.from({ length: 59 }, (_, i) => i)
                    ).map(v => (
                      <option key={v} value={v}>
                        {`0${v}`.slice(-2)}
                      </option>
                    ))}
                  </Input>
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
                  <div>
                    <SearchSelect
                      options={[
                        { label: '自訂時間', value: 1 },
                        { label: '無期限', value: 2 },
                      ]}
                      emptyVal={0}
                      emptyLabel="請選擇"
                      name="availableEndTimeType"
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                    {touched.availableEndTimeType && (
                      <FormText color="danger" className="d-block">
                        {errors.availableEndTimeType}
                      </FormText>
                    )}
                  </div>
                  {values.availableEndTimeType === 2 && (
                    <span>結束日期預計為 2099/12/12 12:12:12</span>
                  )}
                </div>

                {values.availableEndTimeType === 1 && (
                  <div className="form-item flex-wrap d-flex mb-3">
                    <div className="w200">
                      <DatePicker
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        selected={moment(values.availableTimeEnd || new Date())
                          .startOf('day')
                          .toDate()}
                        name="availableTimeEnd"
                        onChange={date => {
                          setFieldValue(
                            'availableTimeEnd',
                            moment(date).startOf('day').toDate(),
                          );
                        }}
                        selectsEnd
                        minDate={moment(values.availableTimeStart || new Date())
                          .startOf('day')
                          .toDate()}
                      />
                    </div>
                    <Input
                      type="select"
                      className="w80 ms-1"
                      name="availableTimeEndHour"
                      onChange={handleChange}
                      value={values.availableTimeEndHour}
                    >
                      {hourArr().map(i => (
                        <option key={i.value} value={i.value}>
                          {i.label}
                        </option>
                      ))}
                    </Input>
                    <span className="px-2 pt-2">:</span>
                    <Input
                      type="select"
                      className="w80"
                      name="availableTimeEndMin"
                      onChange={handleChange}
                      value={values.availableTimeEndMin}
                    >
                      {minArr().map(i => (
                        <option key={i.value} value={i.value}>
                          {i.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                )}
              </FormGroup>
            </Col>
          </FormGroup>
          <FormGroup row>
            <LabelC text="隱賣狀態" required />
            <Col className="d-flex gap-2">
              <Label className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  className={style.maintain_list_checkbox}
                  name="啟用"
                  value={1}
                  onChange={() => {
                    setFieldValue('status', 1);
                  }}
                  checked={values.status === 1}
                />
                啟用
              </Label>
              <Label className="d-flex align-items-center gap-2">
                <input
                  type="radio"
                  className={style.maintain_list_checkbox}
                  name="失效"
                  value={2}
                  checked={values.status === 2}
                  onChange={() => {
                    setFieldValue('status', 2);
                  }}
                />
                失效
              </Label>
            </Col>
          </FormGroup>
          <FormGroup row>
            <LabelC text="備註" />
            <Col>
              <Input
                type="textarea"
                rows={5}
                name="remark"
                onChange={e => {
                  setFieldValue('remark', e.target.value);
                }}
                value={values.remark}
              />
            </Col>
          </FormGroup>
        </Form>

        <p className={style.fs_14}>
          異動時間：
          {hideModelDetailInfo.setting?.updateDate}
          {hideModelDetailInfo.setting?.updateTime}
          {hideModelDetailInfo.setting?.updater}
        </p>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            disabled={submitLoading || Object.keys(errors).length > 0}
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            {submitLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </TabPane>
  );
};

export default memo(HideModelSetting);
