
import {
  MODEL_LIST_REQUEST,
  MODEL_LIST_SUCCESS,
  MODEL_LIST_FAIL,
  MODEL_PROFILE_REQUEST,
  MODEL_PROFILE_SUCCESS,
  MODEL_PROFILE_FAIL,
  MODEL_EDIT_SETTING_REQUEST,
  MODEL_EDIT_SETTING_SUCCESS,
  MODEL_EDIT_SETTING_FAIL,
  MODEL_EDIT_DESC_REQUEST,
  MODEL_EDIT_DESC_SUCCESS,
  MODEL_EDIT_DESC_FAIL,
  MODEL_EDIT_SPEC_REQUEST,
  MODEL_EDIT_SPEC_SUCCESS,
  MODEL_EDIT_SPEC_FAIL,
  MODEL_DELETE_SPEC_REQUEST,
  MODEL_DELETE_SPEC_SUCCESS,
  MODEL_DELETE_SPEC_FAIL,
  MODEL_EDIT_ORDER_REQUEST,
  MODEL_EDIT_ORDER_SUCCESS,
  MODEL_EDIT_ORDER_FAIL,
  MODEL_GLOBAL_CAT_LIST_REQUEST,
  MODEL_GLOBAL_CAT_LIST_SUCCESS,
  MODEL_GLOBAL_CAT_LIST_FAIL,
  GLOBAL_CAT_MODEL_REMOVE_REQUEST,
  GLOBAL_CAT_MODEL_REMOVE_SUCCESS,
  GLOBAL_CAT_MODEL_REMOVE_FAIL,
  GLOBAL_CAT_MODEL_ASSIGN_REQUEST,
  GLOBAL_CAT_MODEL_ASSIGN_SUCCESS,
  GLOBAL_CAT_MODEL_ASSIGN_FAIL,
  MODEL_BRAND_OPTIONS_REQUEST,
  MODEL_BRAND_OPTIONS_SUCCESS,
  MODEL_BRAND_OPTIONS_FAIL,
  MODEL_PRODUCT_OPTIONS_REQUEST,
  MODEL_PRODUCT_OPTIONS_SUCCESS,
  MODEL_PRODUCT_OPTIONS_FAIL,
  MODEL_SELLING_STATE_UPDATE_REQUEST,
  MODEL_SELLING_STATE_UPDATE_SUCCESS,
  MODEL_SELLING_STATE_UPDATE_FAIL,
  ADD_NEW_MODEL_REQUEST,
  ADD_NEW_MODEL_SUCCESS,
  ADD_NEW_MODEL_FAIL,
  MODEL_EXPORT_REQUEST,
  MODEL_EXPORT_SUCCESS,
  MODEL_EXPORT_FAIL,
  MODEL_IMPORT_REQUEST,
  MODEL_IMPORT_SUCCESS,
  MODEL_IMPORT_FAIL,
  EDIT_MODEL_AVAILABLETIME_REQUEST,
  EDIT_MODEL_AVAILABLETIME_FAIL,
  EDIT_MODEL_AVAILABLETIME_SUCCESS,
  EDIT_MODEL_SHIPPING_DATE_REQUEST,
  EDIT_MODEL_SHIPPING_DATE_SUCCESS,
  EDIT_MODEL_SHIPPING_DATE_FAIL,
} from 'redux/constants/data/modelConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getModelList = params => {
  const url = '/Backend/Model/listNew';
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_LIST_FAIL,
      method: 'post',
      requestConstant: MODEL_LIST_REQUEST,
      successConstant: MODEL_LIST_SUCCESS,
      url,
    });
  };
};

export const getGroupModelList = params => {
  const url = '/Backend/GroupModel/list';
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_LIST_FAIL,
      method: 'post',
      requestConstant: MODEL_LIST_REQUEST,
      successConstant: MODEL_LIST_SUCCESS,
      url,
    });
  };
};


export const getModelProfile = id => {
  const url = `/Backend/Model/get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_PROFILE_FAIL,
      method: 'get',
      requestConstant: MODEL_PROFILE_REQUEST,
      successConstant: MODEL_PROFILE_SUCCESS,
      url,
    });
  };
};

export const editModelSetting = ({ id, params }) => {
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_EDIT_SETTING_FAIL,
      method: 'post',
      requestConstant: MODEL_EDIT_SETTING_REQUEST,
      successConstant: MODEL_EDIT_SETTING_SUCCESS,
      url: `/Backend/Model/setSetting/${id}`,
    });
  };
};

export const editModelDesc = ({ id, params }) => {
  CommonUtil.consoleLog({ anno: 'request body', data: params.get('intros') });
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_EDIT_DESC_FAIL,
      method: 'post',
      requestConstant: MODEL_EDIT_DESC_REQUEST,
      successConstant: MODEL_EDIT_DESC_SUCCESS,
      url: `/Backend/Model/setDesc_0201/${id}`,
    });
  };
};

export const editModelSpec = ({ id, params }) => {
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_EDIT_SPEC_FAIL,
      method: 'post',
      requestConstant: MODEL_EDIT_SPEC_REQUEST,
      successConstant: MODEL_EDIT_SPEC_SUCCESS,
      url: `/Backend/Model/setSpec/${id}`,
    });
  };
};

export const editModelSpecRequest = async ({ id, params }) => {

  const url = '/Backend/BrandWarrantyDateSetting/SaveModelWarrantyDateSetting';
  return await request({
    data: params,
    method: 'post',
    url: `/Backend/Model/setSpec/${id}`,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const deleteModelSpec = ({ mid, id }) => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_DELETE_SPEC_FAIL,
      method: 'get',
      requestConstant: MODEL_DELETE_SPEC_REQUEST,
      successConstant: MODEL_DELETE_SPEC_SUCCESS,
      url: `/Backend/Model/delSpec/${mid}/${id}`,
    });
  };
};
export const editModelOrder = ({ id, params }) => {
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: MODEL_EDIT_ORDER_FAIL,
      method: 'post',
      requestConstant: MODEL_EDIT_ORDER_REQUEST,
      successConstant: MODEL_EDIT_ORDER_SUCCESS,
      url: `/Backend/Model/setOrder/${id}`,
    });
  };
};
export const getModelGlobalCatList = (
  params = {
    brandId: '',
    globalCateId: 0,
    keyword: '',
    model: '',
    page: 1,
    page_size: '',
  },
) => {
  const url = `/Backend/GlobalCatModelRel/listFromGlobalCate?${new URLSearchParams(
    params,
  ).toString()}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_GLOBAL_CAT_LIST_FAIL,
      method: 'get',
      requestConstant: MODEL_GLOBAL_CAT_LIST_REQUEST,
      successConstant: MODEL_GLOBAL_CAT_LIST_SUCCESS,
      url,
    });
  };
};


export const getModelGlobalCatListV2 = (
    params = {
      brandId: '',
      globalCateId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: '',
    },
) => {
  const url = `/Backend/GlobalCatModelRel/listFromGlobalCateV2?${new URLSearchParams(
      params,
  ).toString()}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_GLOBAL_CAT_LIST_FAIL,
      method: 'get',
      requestConstant: MODEL_GLOBAL_CAT_LIST_REQUEST,
      successConstant: MODEL_GLOBAL_CAT_LIST_SUCCESS,
      url,
    });
  };
};

export const getGroupModelGlobalCatList = (
    params = {
      brandId: '',
      globalCateId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: '',
    },
) => {
  const url = `/Backend/GlobalCatGroupModelRel/listFromGlobalCate?${new URLSearchParams(
      params,
  ).toString()}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_GLOBAL_CAT_LIST_FAIL,
      method: 'get',
      requestConstant: MODEL_GLOBAL_CAT_LIST_REQUEST,
      successConstant: MODEL_GLOBAL_CAT_LIST_SUCCESS,
      url,
    });
  };
};


export const removeGlobalCatModel = params => {
  const url = '/Backend/GlobalCatModelRel/removeGlobalCategory';
  CommonUtil.consoleLog({ anno: 'request body', data: params });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: GLOBAL_CAT_MODEL_REMOVE_FAIL,
      method: 'post',
      requestConstant: GLOBAL_CAT_MODEL_REMOVE_REQUEST,
      successConstant: GLOBAL_CAT_MODEL_REMOVE_SUCCESS,
      url,
    });
  };
};

export const removeGlobalCatGroupModel = params => {
  const url = '/Backend/GlobalCatGroupModelRel/removeGlobalCategory';
  CommonUtil.consoleLog({ anno: 'request body', data: params });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: GLOBAL_CAT_MODEL_REMOVE_FAIL,
      method: 'post',
      requestConstant: GLOBAL_CAT_MODEL_REMOVE_REQUEST,
      successConstant: GLOBAL_CAT_MODEL_REMOVE_SUCCESS,
      url,
    });
  };
};



export const assignModelToGlobalCat = params => {
  const url = '/Backend/GlobalCatModelRel/assignToGlobalCategory';
  CommonUtil.consoleLog({ anno: 'request body', data: params });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: GLOBAL_CAT_MODEL_ASSIGN_FAIL,
      method: 'post',
      requestConstant: GLOBAL_CAT_MODEL_ASSIGN_REQUEST,
      successConstant: GLOBAL_CAT_MODEL_ASSIGN_SUCCESS,
      url,
    });
  };
};

export const assignGroupModelToGlobalCat = params => {
  const url = '/Backend/GlobalCatGroupModelRel/assignToGlobalCategory';
  CommonUtil.consoleLog({ anno: 'request body', data: params });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: GLOBAL_CAT_MODEL_ASSIGN_FAIL,
      method: 'post',
      requestConstant: GLOBAL_CAT_MODEL_ASSIGN_REQUEST,
      successConstant: GLOBAL_CAT_MODEL_ASSIGN_SUCCESS,
      url,
    });
  };
};

export const getModelBrandOptions = id => {
  const url = `/Backend/Model/brandOptions?id=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_BRAND_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MODEL_BRAND_OPTIONS_REQUEST,
      successConstant: MODEL_BRAND_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const getGroupModelBrandOptions = id => {
  const url = `/Backend/Brand/category/options?brandId=${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_BRAND_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MODEL_BRAND_OPTIONS_REQUEST,
      successConstant: MODEL_BRAND_OPTIONS_SUCCESS,
      url,
    });
  };
};
export const getModelProductOptions = () => {
  const url = '/Backend/Model/productOptions';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: MODEL_PRODUCT_OPTIONS_FAIL,
      method: 'get',
      requestConstant: MODEL_PRODUCT_OPTIONS_REQUEST,
      successConstant: MODEL_PRODUCT_OPTIONS_SUCCESS,
      url,
    });
  };
};

export const updateModelSellingState = ({ mId, data }) => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/Model/UpdateModelOption/${  mId}`,
  });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: MODEL_SELLING_STATE_UPDATE_FAIL,
      method: 'post',
      requestConstant: MODEL_SELLING_STATE_UPDATE_REQUEST,
      successConstant: MODEL_SELLING_STATE_UPDATE_SUCCESS,
      url: `/Backend/Model/UpdateModelOption/${  mId}`,
    });
  };
};

export const addNewModels = data => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Model/AddModels' });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ADD_NEW_MODEL_FAIL,
      method: 'post',
      requestConstant: ADD_NEW_MODEL_REQUEST,
      successConstant: ADD_NEW_MODEL_SUCCESS,
      url: '/Backend/Model/AddModels',
    });
  };
};

export const exportModel = ({ type, data }) => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: `/Backend/Model/Export/${type}` });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: MODEL_EXPORT_FAIL,
      method: 'post',
      requestConstant: MODEL_EXPORT_REQUEST,
      successConstant: MODEL_EXPORT_SUCCESS,
      url: `/Backend/Model/Export/${type}`,
    });
  };
};

export const importModel = data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Model/ImportModel' });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: MODEL_IMPORT_FAIL,
      method: 'post',
      requestConstant: MODEL_IMPORT_REQUEST,
      successConstant: MODEL_IMPORT_SUCCESS,
      url: '/Backend/Model/ImportModel',
    });
  };
};

export const editModelAvailableTime = (data, mId) => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/Model/ModelAvailableTimeSetting/${mId}`,
  });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: EDIT_MODEL_AVAILABLETIME_FAIL,
      method: 'post',
      requestConstant: EDIT_MODEL_AVAILABLETIME_REQUEST,
      successConstant: EDIT_MODEL_AVAILABLETIME_SUCCESS,
      url: `/Backend/Model/ModelAvailableTimeSetting/${mId}`,
    });
  };
};

export const editModelShippingDate = (data, pId) => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/Model/ProductDeliverySetting/${pId}`,
  });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: EDIT_MODEL_SHIPPING_DATE_FAIL,
      method: 'post',
      requestConstant: EDIT_MODEL_SHIPPING_DATE_REQUEST,
      successConstant: EDIT_MODEL_SHIPPING_DATE_SUCCESS,
      url: `/Backend/Model/ProductDeliverySetting/${pId}`,
    });
  };
};
