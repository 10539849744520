
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { getRewardsPointsSettings } from 'redux/actions/data/rewardsPointsActions';
import {
  UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE,
} from 'redux/constants/data/rewardsPointsConstants';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const ContentSettingsModal = ({ isOpen, toggle, data = {}, onCombineData }) => {
  const dispatch = useDispatch();
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateRewardsPointsEnableSettings);
  const { values, setFieldValue, handleChange, handleSubmit, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        content: data.desc || '',
        content_en: data.desc_en || '',
        index: data.index || 0,
        title: data.title || '',
        title_en: data.title_en || '',
      },
      onSubmit: () => {
        onCombineData(values);
      },
      validationSchema: Yup.object().shape({
        content: Yup.string().nullable().required('必填'),
        content_en: Yup.string().nullable().required('必填'),
        title: Yup.string().nullable().required('必填'),
        title_en: Yup.string().nullable().required('必填'),
      }),
    });

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE });
      dispatch(getRewardsPointsSettings());
      toggle();
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: UPDATE_REWARDS_POINTS_ENABLE_SETTINGS_IDLE });
    }
  }, [updateSuccess, updateError]);

  return (
    <Modal centered zIndex={2000} isOpen={isOpen} size="lg">
      <ModalHeader className={style.modal_head} toggle={toggle}>
        點數說明
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
          <label className="w100 required">標題</label>
          <Input
            id="title"
            onChange={handleChange}
            value={values.title}
            invalid={errors.title && touched.title}
          />
        </div>
        <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
          <label className="w100 required">標題(英)</label>
          <Input
            id="title_en"
            onChange={handleChange}
            value={values.title_en}
            invalid={errors.title_en && touched.title_en}
          />
        </div>
        <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
          <label className="w100 required">內容</label>
          <Input
            type="textarea"
            style={{ height: '130px' }}
            id="content"
            onChange={handleChange}
            value={values.content}
            invalid={errors.content && touched.content}
          />
        </div>
        <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
          <label className="w100 required">內容(英)</label>
          <Input
            type="textarea"
            style={{ height: '130px' }}
            id="content_en"
            onChange={handleChange}
            value={values.content_en}
            invalid={errors.content_en && touched.content_en}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={updateLoading}
        >
          {updateLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContentSettingsModal;
