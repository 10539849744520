import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment/moment';
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import style from '../../styles/layout.module.css';

const WarrantyDateSettingModal = ({
  toggleModal,
  handleSubmit,
  getValidationSchema,
  modal,
  info,
  otherReason,
  setOtherReason,
  addLoading,
  submitLoading,
}) => {
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={modal}
      toggle={toggleModal}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggleModal}>
        保固日期設定
      </ModalHeader>
      <Formik
        enableReinitialize
        initialValues={{
          purchaseDate:
            info && info.purchaseDate_admin
              ? new Date(info.purchaseDate_admin)
              : '',
          reason: '',
          repairRegisterNum:
            info && info.repairRegisterNum ? info.repairRegisterNum : '',
          shippingDate:
            info && info.productDate_admin
              ? new Date(info.productDate_admin)
              : '',
          startDateType_admin:
            info && info.startDateType_admin
              ? info.startDateType_admin.toString()
              : '',
          status: info && info.status ? info.status : '',
          warrantyEndDate:
            info && info.warrantyEndDate ? new Date(info.warrantyEndDate) : '',
        }}
        onSubmit={handleSubmit}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <ModalBody className={style.modal_body}>
              <div className={style.formGroup}>
                <label style={{ minWidth: '130px' }}>維修單號</label>
                <Input
                  name="repairRegisterNum"
                  onChange={event => {
                    props.setFieldValue(
                      'repairRegisterNum',
                      event.target.value,
                    );
                  }}
                  value={props.values.repairRegisterNum}
                />
              </div>

              {info?.warrantyDays ? (
                <div className={style.formGroup}>
                  <label style={{ width: '130px' }}>
                    保固起始日{' '}
                    <ErrorMessage
                      name="startDateType_admin"
                      component="span"
                      className="form-error"
                    />
                    <ErrorMessage
                      name="purchaseDate"
                      component="h6"
                      className="form-error"
                    />
                    <ErrorMessage
                      name="shippingDate"
                      component="h6"
                      className="form-error"
                    />
                  </label>
                  <div>
                    <div className="d-flex mb-3">
                      <Field
                        name="startDateType_admin"
                        type="radio"
                        id="purchase"
                        value="1"
                      >
                        {({ form, field }) => {
                          const { setFieldValue, values } = form;
                          const { value } = field;
                          return (
                            <input
                              className="me-2"
                              type="radio"
                              style={{ lineHeight: '38px' }}
                              checked={values.startDateType_admin == 1}
                              onChange={() => {
                                setFieldValue('startDateType_admin', value);
                                setFieldValue(
                                  'warrantyEndDate',
                                  values.purchaseDate
                                    ? moment(values.purchaseDate)
                                        .add(
                                          info?.isInExtendDays
                                            ? info?.warrantyDays +
                                                info?.extendDays
                                            : info?.warrantyDays,
                                          'day',
                                        )
                                        .toDate()
                                    : '',
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                      <label
                        className="w100"
                        htmlFor="purchase"
                        style={{ lineHeight: '38px' }}
                      >
                        購買日期
                      </label>
                      <Field name="purchaseDate">
                        {({ field, form }) => {
                          const { setFieldValue, values } = form;
                          const { value } = field;
                          return (
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              selected={value}
                              onChange={date => {
                                setFieldValue('purchaseDate', date);
                                if (Number(values.startDateType_admin) === 1) {
                                  setFieldValue(
                                    'warrantyEndDate',
                                    moment(date)
                                      .add(
                                        info?.isInExtendDays
                                          ? info?.warrantyDays +
                                              info?.extendDays
                                          : info?.warrantyDays,
                                        'day',
                                      )
                                      .toDate(),
                                  );
                                }
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <div className="d-flex mb-3">
                      <Field
                        name="startDateType_admin"
                        type="radio"
                        id="shipping"
                        value="2"
                      >
                        {({ form, field }) => {
                          const { setFieldValue, values } = form;
                          const { value } = field;
                          return (
                            <input
                              className="me-2"
                              type="radio"
                              style={{ lineHeight: '38px' }}
                              checked={values.startDateType_admin == 2}
                              onChange={() => {
                                setFieldValue('startDateType_admin', value);
                                setFieldValue(
                                  'warrantyEndDate',
                                  values.shippingDate
                                    ? moment(values.shippingDate)
                                        .add(
                                          info?.isInExtendDays
                                            ? info?.warrantyDays +
                                                info?.extendDays
                                            : info?.warrantyDays,
                                          'day',
                                        )
                                        .toDate()
                                    : '',
                                );
                              }}
                            />
                          );
                        }}
                      </Field>
                      <label
                        className="w100"
                        htmlFor="shipping"
                        style={{ lineHeight: '38px' }}
                      >
                        出貨日期
                      </label>
                      <Field name="shippingDate">
                        {({ field, form }) => {
                          const { setFieldValue, values } = form;
                          const { value } = field;
                          return (
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              selected={value}
                              onChange={date => {
                                setFieldValue('shippingDate', date);
                                if (Number(values.startDateType_admin) === 2) {
                                  setFieldValue(
                                    'warrantyEndDate',
                                    moment(date)
                                      .add(
                                        info?.isInExtendDays
                                          ? info?.warrantyDays +
                                              info?.extendDays
                                          : info?.warrantyDays,
                                        'day',
                                      )
                                      .toDate(),
                                  );
                                }
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              ) : null}
              {info?.warrantyDays ? (
                <div className={style.formGroup}>
                  <label style={{ width: '130px' }}>
                    保固結束日
                    <ErrorMessage
                      name="warrantyEndDate"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div>
                    <div className="d-flex mb-3">
                      <Field name="warrantyEndDate">
                        {({ field, form }) => {
                          const { setFieldValue, values } = form;
                          const { value } = field;
                          return (
                            <DatePicker
                              className="form-control"
                              dateFormat="yyyy/MM/dd"
                              selected={values.warrantyEndDate}
                              onChange={date => {
                                setFieldValue('warrantyEndDate', date);
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className={style.formGroup}>
                <label style={{ width: '130px' }}>
                  審核狀態
                  <ErrorMessage
                    name="status"
                    component="span"
                    className="form-error"
                  />
                  <ErrorMessage
                    name="reason"
                    component="h6"
                    className="form-error"
                  />
                </label>
                <div>
                  <div className="d-flex">
                    <div className="d-flex mb-3">
                      <Field
                        name="status"
                        type="radio"
                        style={{ lineHeight: '38px' }}
                        className="me-2"
                        id="notyet"
                        value={info?.status === '審核中' ? '審核中' : '未審核'}
                      />
                      <label
                        className="w100"
                        htmlFor="notyet"
                        style={{ lineHeight: '38px' }}
                      >
                        {info?.status === '審核中' ? '審核中' : '未審核'}
                      </label>
                    </div>

                    <div className="d-flex mb-3">
                      <Field
                        name="status"
                        type="radio"
                        style={{ lineHeight: '38px' }}
                        className="me-2"
                        id="fail"
                        value="未通過"
                      />
                      <label
                        className="w100"
                        htmlFor="fail"
                        style={{ lineHeight: '38px' }}
                      >
                        未通過
                      </label>
                    </div>

                    <div className="d-flex mb-3">
                      <Field
                        name="status"
                        type="radio"
                        style={{ lineHeight: '38px' }}
                        className="me-2"
                        id="success"
                        value="已通過"
                      />
                      <label
                        className="w100"
                        htmlFor="success"
                        style={{ lineHeight: '38px' }}
                      >
                        已通過
                      </label>
                    </div>
                    <div className="d-flex mb-3">
                      <Field
                        name="status"
                        type="radio"
                        style={{ lineHeight: '38px' }}
                        className="me-2"
                        id="cancel"
                        value="已作廢"
                      />
                      <label
                        className="w100"
                        htmlFor="cancel"
                        style={{ lineHeight: '38px' }}
                      >
                        已作廢
                      </label>
                    </div>
                  </div>
                  {(info &&
                    info.status == '未通過' &&
                    props.values.status == '未通過') ||
                  props.values.status == '未通過' ? (
                    <div className="mt-3">
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="image_problems"
                          value="發票與保卡未正確拍攝上傳"
                        />
                        <label
                          className="w-100"
                          htmlFor="image_problems"
                          style={{ lineHeight: '38px' }}
                        >
                          發票與保卡未正確拍攝上傳
                        </label>
                      </div>
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="info_not_complete"
                          value="發票與保卡之有效資訊不全"
                        />
                        <label
                          className="w-100"
                          htmlFor="info_not_complete"
                          style={{ lineHeight: '38px' }}
                        >
                          發票與保卡之有效資訊不全
                        </label>
                      </div>
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="product_number_error"
                          value="產品序號有誤"
                        />
                        <label
                          className="w-100"
                          htmlFor="product_number_error"
                          style={{ lineHeight: '38px' }}
                        >
                          產品序號有誤
                        </label>
                      </div>
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="provide_warranty_number"
                          value="請補上傳保卡正本背面(亂碼序號)，以利查證"
                        />
                        <label
                          className="w-100"
                          htmlFor="provide_warranty_number"
                          style={{ lineHeight: '38px' }}
                        >
                          請補上傳保卡正本背面(亂碼序號)，以利查證
                        </label>
                      </div>
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="provide_warranty_proof"
                          value="請補上傳保卡正本正面(日期、蓋章)或購買明細/發票，以利查證"
                        />
                        <label
                          className="w-100"
                          htmlFor="provide_warranty_proof"
                          style={{ lineHeight: '38px' }}
                        >
                          請補上傳保卡正本正面(日期、蓋章)或購買明細/發票，以利查證
                        </label>
                      </div>
                      <div className="d-flex mb-3">
                        <Field
                          name="reason"
                          type="radio"
                          style={{ lineHeight: '38px' }}
                          className="me-2"
                          id="success"
                          value={otherReason}
                        />
                        <textarea
                          placeholder="請輸入原因"
                          rows="5"
                          className="w-100 p-2"
                          value={otherReason}
                          onChange={e => setOtherReason(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className={`mt-3 ${style.formGroup}`}>
                <label style={{ width: '130px' }}>審核狀態</label>
                <div>{info && info.status}</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="link"
                className={style.modal_button_cancel}
                onClick={toggleModal}
              >
                取消
              </Button>
              <Button
                type="submit"
                color="primary"
                className={style.modal_button_submit}
                disabled={addLoading || submitLoading}
              >
                {addLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                {submitLoading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                儲存
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(WarrantyDateSettingModal);
