import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import React, { memo, useState } from 'react';
import { Card, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const statusMap = {
  1: '尚未開始',
  2: '領取中',
  3: '待使用',
  4: '使用中',
  5: '已結束',
};
const VoucherListRecords = ({ list = [], loading = false }) => {
  const [page, setPage] = useState(1);
  return (
    <Card className={style.card}>
      <div className="d-flex justify-content-end">
        <span className="mx-1">共{list?.length || '-'}筆</span>
      </div>
      <div className="table-responsive mb-5">
        <Table hover bordered striped>
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>
                <div>活動序號</div>
                <div>活動名稱</div>
              </th>
              <th>
                <div>票券</div>
                <div>優惠內容</div>
              </th>
              <th>使用狀態</th>
              <th>使用期限</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {list?.length > 0 ? (
                list.slice((page - 1) * 10, page * 10).map(l => (
                  <tr key={JSON.stringify(l)}>
                    <td>
                      <div>{l.code}</div>
                      <div>{l.name}</div>
                    </td>
                    <td>
                      <div>{l.type === 1 ? '折價券' : '贈品券'}</div>
                      <div>
                        <pre className="mb-0">{l.desc}</pre>
                      </div>
                    </td>
                    <td>
                      <div>{l.usedState ?? statusMap[l.usedState]}</div>
                    </td>
                    <td>
                      <div className="text-nowrap">
                        {l.redeemStartTime
                          ? moment(l.redeemStartTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )
                          : '--'}
                        起
                      </div>
                      <div className="text-nowrap">
                        {l.redeemEndTime
                          ? moment(l.redeemEndTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )
                          : '--'}
                        止
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={page}
          pageSize={10}
          total={list?.length}
          onChange={setPage}
        />
      </div>
    </Card>
  );
};

export default memo(VoucherListRecords);
