import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { memo, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import style from '../../styles/layout.module.css';
import history from '../../utils/createHistory';
import useQuery from '../../utils/useQuery';
import { useStore } from '../../utils/useStore';

const CouponTypeOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '折價券',
    value: 1,
  },
  {
    label: '贈品券',
    value: 2,
  },
];

const QueryDateTypeOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '領取期間',
    value: 'getTime',
  },
  {
    label: '使用時間',
    value: 'usedTime',
  },
];

const GetWayOptions = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '直接領取',
    value: 1,
  },
  {
    label: '輸入優惠券代碼',
    value: 2,
  },
  {
    label: '紅利點數兌換',
    value: 3,
  },
  {
    label: '系統發送',
    value: 4,
  },
];
const VoucherSearchCondition = ({ onSearch }) => {
  const location = useLocation();
  const query = useQuery();
  const { options: StoreOptions, loading: storeLoading } = useStore();

  const { values, setValues, setFieldValue, handleSubmit, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        couponType: '',
        endTime: '',
        getWay: '',
        keyword: '',
        order: 'asc',
        order_by: '',
        page: 1,
        page_size: 10,
        queryDateType: '',
        salesChannel: [{ label: 'online', value: 'online' }],
        startTime: '',
      },
      onReset: () => {
        const params = new URLSearchParams({
          couponType: '',
          endTime: '',
          getWay: '',
          keyword: '',
          order: 'asc',
          order_by: '',
          page: 1,
          page_size: 10,
          queryDateType: '',
          salesChannel: JSON.stringify([{ label: 'online', value: 'online' }]),
          startTime: '',
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
        onSearch({
          page: 1,
          page_size: 10,
        });
      },
      onSubmit: values => {
        const params = new URLSearchParams({
          ...values,
          endTime: values?.endTime || '',
          salesChannel: JSON.stringify(
            values?.salesChannel || [{ label: 'online', value: 'online' }],
          ),
          startTime: values?.startTime || '',
        });
        onSearch({
          couponType: values?.couponType || undefined,
          endTime: values?.endTime || undefined,
          getWay: values?.getWay || undefined,
          keywords: values?.keyword ?? undefined,
          order: values?.order || 'asc',
          order_by: values?.order_by || '',
          page: values?.page,
          page_size: 10,
          queryDateType: values?.queryDateType ?? null,
          salesChannel: values?.salesChannel.map(s => s.value).filter(s => s),
          startTime: values?.startTime || undefined,
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      },
    });

  const isInitial = useRef(true);

  useEffect(() => {
    if (location.search) {
      const body = {
        ...query.value,
        endTime: query?.value?.endTime
          ? moment(query?.value?.endTime).toDate()
          : '',
        page: Number(query?.value?.page || 1),
        page_size: 10,
        salesChannel: JSON.parse(
          query?.value?.salesChannel ||
            JSON.stringify([{ label: 'online', value: 'online' }]),
        ),
        startTime: query?.value?.startTime
          ? moment(query?.value?.startTime).toDate()
          : '',
      };
      setValues(body);
      if (isInitial.current) {
        handleSubmit();
        isInitial.current = false;
      }
    } else {
      handleReset();
    }
  }, [location.search]);

  return (
    <Card className={style.search_card}>
      <CardHeader className="bg-white">搜尋條件</CardHeader>
      <CardBody className={style.card_body}>
        <Row className="flex-wrap">
          <FormGroup
            className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
            style={{ minWidth: '250px' }}
          >
            <label style={{ minWidth: '80px' }}>關鍵字</label>
            <Input
              type="text"
              placeholder="活動名稱"
              value={values.keyword}
              onChange={e => setFieldValue('keyword', e.target.value)}
            />
          </FormGroup>
          <FormGroup
            className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
            style={{ minWidth: '350px' }}
          >
            <label style={{ minWidth: '80px' }}>門市</label>
            <Select
              isMulti
              className="w-100"
              options={StoreOptions}
              isLoading={storeLoading}
              value={values.salesChannel}
              onChange={e => {
                setFieldValue('salesChannel', e);
              }}
            />
          </FormGroup>
          <FormGroup
            className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
            style={{ minWidth: '250px' }}
          >
            <label style={{ minWidth: '80px' }}>券型</label>
            <Select
              className="w-100"
              value={
                CouponTypeOptions.filter(
                  c => String(c.value) === String(values.couponType || ''),
                )[0]
              }
              options={CouponTypeOptions}
              onChange={e => {
                setFieldValue('couponType', e.value);
              }}
            />
          </FormGroup>
          <FormGroup
            className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-8"
            style={{ minWidth: '250px' }}
          >
            <label style={{ minWidth: '80px' }}>日期區間</label>
            <div className={style['form-select']}>
              <Select
                className="w-100"
                value={
                  QueryDateTypeOptions.filter(
                    c => String(c.value) === String(values.queryDateType || ''),
                  )[0]
                }
                options={QueryDateTypeOptions}
                onChange={e => {
                  setFieldValue('queryDateType', e.value);
                }}
              />
            </div>
            <span className="mx-1" />
            <div className={style['form-select']}>
              <DatePicker
                className="form-control"
                dateFormat={'yyyy/MM/dd HH:mm'}
                showTimeSelect
                selected={
                  values.startTime ? moment(values.startTime).toDate() : ''
                }
                onChange={date => setFieldValue('startTime', date)}
              />
            </div>
            <span style={{ margin: '1rem' }}>~</span>
            <div className={style['form-select']}>
              <DatePicker
                className="form-control"
                dateFormat={'yyyy/MM/dd HH:mm'}
                showTimeSelect
                selected={values.endTime ? moment(values.endTime).toDate() : ''}
                onChange={date => setFieldValue('endTime', date)}
                minDate={
                  values.startTime ? moment(values.startTime).toDate() : ''
                }
              />
            </div>
          </FormGroup>
          <FormGroup
            className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
            style={{ minWidth: '250px' }}
          >
            <label style={{ minWidth: '80px' }}>活動類型</label>
            <Select
              className="w-100"
              value={
                GetWayOptions.filter(
                  c => String(c.value) === String(values.getWay ?? ''),
                )[0]
              }
              options={GetWayOptions}
              onChange={e => {
                setFieldValue('getWay', e.value);
              }}
            />
          </FormGroup>
        </Row>
      </CardBody>
      <CardFooter>
        <div className={style.button_position}>
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              handleReset();
            }}
          >
            取消
          </Button>
          <Button
            type="button"
            className={style.modal_button_submit}
            onClick={() => {
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

export default memo(VoucherSearchCondition);
