import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';

const TableList = ({ setCurrentId, setIsEditModalOpen, setIsEdit }) => {
  const { loading, blockList } = useSelector(state => state.getBlockList);
  const payWaysTable = useMemo(() => {
    return ['', '信用卡', 'ATM轉帳' , '超商取貨付款' , 'Apple Pay' , 'Line Pay'];
  }, []);
  return (
    <div className="table-responsive">
      <Table className="mb-5" bordered striped hover>
        <TableHead>
          <tr>
            <th>會員編號</th>
            <th>姓名</th>
            <th>電子郵件</th>
            <th>
              <div>黑名單原因</div>
              <div>可使用付款方式</div>
            </th>
            <th>黑名單備註</th>
            <th>
              <div>黑名單狀態</div>
              <div>狀態變更日期</div>
              <div>狀態變更人員</div>
            </th>
            <th>
              <div>建立日期</div>
              <div>建立人員</div>
            </th>
            <th>
              <div>更新日期</div>
              <div>更新人員</div>
              <div>更新次數</div>
            </th>
            <th>功能</th>
          </tr>
        </TableHead>
        <tbody>
          {blockList.datas?.length > 0 ? (
            blockList.datas.map((data, i) => (
              <tr key={i}>
                <td>{`0${data.phone}`}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>
                  <div>{data.reason}</div>
                  <div>
                    {data.payWays.map(way => (
                      <div>{payWaysTable[way]}</div>
                    ))}
                  </div>
                </td>
                <td>{data.remark}</td>
                <td>
                  <div>{data.status == 1 ? '已生效' : '已失效'}</div>
                  <div>{data.statusUpdateTime || '--'}</div>
                  <div>{data.statusUpdaterName || '--'}</div>
                </td>
                <td>
                  <div>{data.createTime}</div>
                  <div>{data.creatorName}</div>
                </td>
                <td>
                  <div>{data.updateTime || '--'}</div>
                  <div>{data.updaterName || '--'}</div>
                  <div>{data.updateCount}</div>
                </td>
                <td className="text-center">
                  <Button
                    color="primary"
                    outline
                    className="me-2 btn-sm"
                    onClick={() => {
                      setIsEdit(true);
                      setCurrentId(data);
                      setIsEditModalOpen();
                    }}
                  >
                    編輯
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <TableHasNoData colNumber={9} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableList;
