import {
  BRAND_PREFIX_LIST_FAIL,
  BRAND_PREFIX_LIST_REQUEST,
  BRAND_PREFIX_LIST_SUCCESS,
  PRODUCT_EXCEL_PRODUCE_FAIL,
  PRODUCT_EXCEL_PRODUCE_REQUEST,
  PRODUCT_EXCEL_PRODUCE_RESET,
  PRODUCT_EXCEL_PRODUCE_SUCCESS,
  PRODUCT_NUMBER_CREATE_FAIL,
  PRODUCT_NUMBER_CREATE_REQUEST,
  PRODUCT_NUMBER_CREATE_RESET,
  PRODUCT_NUMBER_CREATE_SUCCESS,
  PRODUCT_NUMBER_LIST_FAIL,
  PRODUCT_NUMBER_LIST_REQUEST,
  PRODUCT_NUMBER_LIST_SUCCESS,
  PRODUCT_SERIAL_NUMBER_FAIL,
  PRODUCT_SERIAL_NUMBER_REQUEST,
  PRODUCT_SERIAL_NUMBER_SUCCESS,
  SHIPPING_DEALER_LIST_FAIL,
  SHIPPING_DEALER_LIST_REQUEST,
  SHIPPING_DEALER_LIST_SUCCESS,
  SHIPPING_EXCEL_PRODUCE_FAIL,
  SHIPPING_EXCEL_PRODUCE_REQUEST,
  SHIPPING_EXCEL_PRODUCE_RESET,
  SHIPPING_EXCEL_PRODUCE_SUCCESS,
  WARRANTY_BRAND_EXPIRE_FAIL,
  WARRANTY_BRAND_EXPIRE_REQUEST,
  WARRANTY_BRAND_EXPIRE_SUCCESS,
  WARRANTY_BRAND_LIST_FAIL,
  WARRANTY_BRAND_LIST_REQUEST,
  WARRANTY_BRAND_LIST_SUCCESS,
  WARRANTY_BRAND_SET_FAIL,
  WARRANTY_BRAND_SET_REQUEST,
  WARRANTY_BRAND_SET_RESET,
  WARRANTY_BRAND_SET_SUCCESS,
  WARRANTY_CODE_LIST_FAIL,
  WARRANTY_CODE_LIST_REQUEST,
  WARRANTY_CODE_LIST_SUCCESS,
  WARRANTY_CODE_POST_FAIL,
  WARRANTY_CODE_POST_REQUEST,
  WARRANTY_CODE_POST_RESET,
  WARRANTY_CODE_POST_SUCCESS,
  WARRANTY_EXCEL_PRODUCE_FAIL,
  WARRANTY_EXCEL_PRODUCE_REQUEST,
  WARRANTY_EXCEL_PRODUCE_RESET,
  WARRANTY_EXCEL_PRODUCE_SUCCESS,
  WARRANTY_INFO_GET_FAIL,
  WARRANTY_INFO_GET_REQUEST,
  WARRANTY_INFO_GET_SUCCESS,
  WARRANTY_INFO_UPDATE_FAIL,
  WARRANTY_INFO_UPDATE_REQUEST,
  WARRANTY_INFO_UPDATE_RESET,
  WARRANTY_INFO_UPDATE_SUCCESS,
  WARRANTY_MASTER_LIST_FAIL,
  WARRANTY_MASTER_LIST_REQUEST,
  WARRANTY_MASTER_LIST_SUCCESS,
  WARRANTY_NUMBER_CREATE_FAIL,
  WARRANTY_NUMBER_CREATE_REQUEST,
  WARRANTY_NUMBER_CREATE_RESET,
  WARRANTY_NUMBER_CREATE_SUCCESS,
  WARRANTY_NUMBER_LIST_FAIL,
  WARRANTY_NUMBER_LIST_REQUEST,
  WARRANTY_NUMBER_LIST_SUCCESS,
  WARRANTY_PRODUCT_CHECK_FAIL,
  WARRANTY_PRODUCT_CHECK_REQUEST,
  WARRANTY_PRODUCT_CHECK_RESET,
  WARRANTY_PRODUCT_CHECK_SUCCESS,
  WARRANTY_REGISTER_DETAILS_FAIL,
  WARRANTY_REGISTER_DETAILS_REQUEST,
  WARRANTY_REGISTER_DETAILS_RESET,
  WARRANTY_REGISTER_DETAILS_SUCCESS,
  WARRANTY_REGISTER_EXPORT_FAIL,
  WARRANTY_REGISTER_EXPORT_REQUEST,
  WARRANTY_REGISTER_EXPORT_RESET,
  WARRANTY_REGISTER_EXPORT_SUCCESS,
  WARRANTY_REGISTER_LIST_FAIL,
  WARRANTY_REGISTER_LIST_REQUEST,
  WARRANTY_REGISTER_LIST_SUCCESS,
  WARRANTY_REGISTER_NUMBER_FAIL,
  WARRANTY_REGISTER_NUMBER_REQUEST,
  WARRANTY_REGISTER_NUMBER_SUCCESS,
  WARRANTY_REGISTER_RECORD_FAIL,
  WARRANTY_REGISTER_RECORD_REQUEST,
  WARRANTY_REGISTER_RECORD_SUCCESS,
  WARRANTY_REGISTER_RECORD_RESET,
  WARRANTY_REGISTER_REVIEW_FAIL,
  WARRANTY_REGISTER_REVIEW_REQUEST,
  WARRANTY_REGISTER_REVIEW_RESET,
  WARRANTY_REGISTER_REVIEW_SUCCESS,
  WARRANTY_REMARK_ADD_FAIL,
  WARRANTY_REMARK_ADD_REQUEST,
  WARRANTY_REMARK_ADD_RESET,
  WARRANTY_REMARK_ADD_SUCCESS,
  WARRANTY_SERIAL_NUMBER_FAIL,
  WARRANTY_SERIAL_NUMBER_REQUEST,
  WARRANTY_SERIAL_NUMBER_SUCCESS,
  GET_SIZE_OPTIONS_IDLE,
  GET_SIZE_OPTIONS_REQUEST,
  GET_SIZE_OPTIONS_SUCCESS,
  GET_SIZE_OPTIONS_FAIL,
  GET_FORM_DESCRIPTION_REQUEST,
  GET_FORM_DESCRIPTION_SUCCESS,
  GET_FORM_DESCRIPTION_FAIL,
  UPDATE_FORM_DESCRIPTION_REQUEST,
  UPDATE_FORM_DESCRIPTION_FAIL,
  UPDATE_FORM_DESCRIPTION_SUCCESS,
  GET_CAN_UNBIND_SHIPPER_LIST_FAIL,
  GET_CAN_UNBIND_SHIPPER_LIST_REQUEST,
  GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS,
  GET_WARRANTY_NOTIFY_LIST_REQUEST,
  GET_WARRANTY_NOTIFY_LIST_SUCCESS,
  GET_WARRANTY_NOTIFY_LIST_FAIL,
  UPDATE_WARRANTY_NOTIFY_LIST_REQUEST,
  UPDATE_WARRANTY_NOTIFY_LIST_FAIL,
  UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS,
} from '../../constants/data/warrantyConstants';

//get warranty code list
export const warrantyCodeListReducer = (
  state = { warrantyCodeListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_CODE_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_CODE_LIST_SUCCESS:
      return { loading: false, warrantyCodeListInfo: payload };
    case WARRANTY_CODE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post warranty code
export const warrantyCodePostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_CODE_POST_REQUEST:
      return { loading: true };
    case WARRANTY_CODE_POST_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_CODE_POST_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_CODE_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get warranty number list
export const warrantyNumberListReducer = (
  state = { numberListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_NUMBER_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_NUMBER_LIST_SUCCESS:
      return { loading: false, numberListInfo: payload };
    case WARRANTY_NUMBER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get warranty serial numbers
export const warrantySerialNumbersReducer = (
  state = { serialNumberListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_SERIAL_NUMBER_REQUEST:
      return { loading: true };
    case WARRANTY_SERIAL_NUMBER_SUCCESS:
      return { loading: false, serialNumberListInfo: payload };
    case WARRANTY_SERIAL_NUMBER_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get brand serial prefix
export const warrantyPrefixListReducer = (
  state = { brandPrefixListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_PREFIX_LIST_REQUEST:
      return { loading: true };
    case BRAND_PREFIX_LIST_SUCCESS:
      return { brandPrefixListInfo: payload, loading: false };
    case BRAND_PREFIX_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//create warranty serial number
export const warrantyNumberCreateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_NUMBER_CREATE_REQUEST:
      return { loading: true };
    case WARRANTY_NUMBER_CREATE_SUCCESS:
      return { loading: false, result: payload, success: true };
    case WARRANTY_NUMBER_CREATE_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_NUMBER_CREATE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//create warranty excel
export const warrantyExcelCreateReducer = (
  state = {
    result: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_EXCEL_PRODUCE_REQUEST:
      return { loading: true };
    case WARRANTY_EXCEL_PRODUCE_SUCCESS:
      return { loading: false, result: payload };
    case WARRANTY_EXCEL_PRODUCE_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_EXCEL_PRODUCE_RESET:
      return { error: null, loading: false, result: null };
    default:
      return state;
  }
};

//get product number list
export const productNumberListReducer = (
  state = { productSerialListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_NUMBER_LIST_REQUEST:
      return { loading: true };
    case PRODUCT_NUMBER_LIST_SUCCESS:
      return { loading: false, productSerialListInfo: payload };
    case PRODUCT_NUMBER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get product serial number
export const productSerialNumberReducer = (
  state = { productSerialNumberInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_SERIAL_NUMBER_REQUEST:
      return { loading: true };
    case PRODUCT_SERIAL_NUMBER_SUCCESS:
      return { loading: false, productSerialNumberInfo: payload };
    case PRODUCT_SERIAL_NUMBER_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//create product serial number
export const productNumberCreateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_NUMBER_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_NUMBER_CREATE_SUCCESS:
      return { loading: false, result: payload, success: true };
    case PRODUCT_NUMBER_CREATE_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_NUMBER_CREATE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//create warranty excel
export const productExcelCreateReducer = (
  state = {
    result: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_EXCEL_PRODUCE_REQUEST:
      return { loading: true };
    case PRODUCT_EXCEL_PRODUCE_SUCCESS:
      return { loading: false, result: payload };
    case PRODUCT_EXCEL_PRODUCE_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_EXCEL_PRODUCE_RESET:
      return { error: null, loading: false, result: null };
    default:
      return state;
  }
};

//get shipping dealer list
export const shippingDealerListReducer = (
  state = { shippingDealerList: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SHIPPING_DEALER_LIST_REQUEST:
      return { loading: true };
    case SHIPPING_DEALER_LIST_SUCCESS:
      return { loading: false, shippingDealerList: payload };
    case SHIPPING_DEALER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//shipping checck
export const warrantyProductCheckReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_PRODUCT_CHECK_REQUEST:
      return { loading: true };
    case WARRANTY_PRODUCT_CHECK_SUCCESS:
      return { loading: false, success: payload };
    case WARRANTY_PRODUCT_CHECK_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_PRODUCT_CHECK_RESET:
      return { error: null, loading: false, success: null };
    default:
      return state;
  }
};

//warranty master list
export const warrantyMasterListReducer = (
  state = { warrantyMasterListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_MASTER_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_MASTER_LIST_SUCCESS:
      return { loading: false, warrantyMasterListInfo: payload };
    case WARRANTY_MASTER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get warranty data
export const warrantyInfoReducer = (state = { warrantyInfo: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_INFO_GET_REQUEST:
      return { loading: true };
    case WARRANTY_INFO_GET_SUCCESS:
      return { loading: false, warrantyInfo: payload };
    case WARRANTY_INFO_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update warranty data
export const warrantyInfoUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_INFO_UPDATE_REQUEST:
      return { loading: true };
    case WARRANTY_INFO_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_INFO_UPDATE_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_INFO_UPDATE_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//create shipping excel
export const shippingExcelCreateReducer = (
  state = {
    result: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SHIPPING_EXCEL_PRODUCE_REQUEST:
      return { loading: true };
    case SHIPPING_EXCEL_PRODUCE_SUCCESS:
      return { loading: false, result: payload };
    case SHIPPING_EXCEL_PRODUCE_FAIL:
      return { error: payload, loading: false };
    case SHIPPING_EXCEL_PRODUCE_RESET:
      return { error: null, loading: false, result: null };
    default:
      return state;
  }
};

//warranty brand list
export const warrantyBrandListReducer = (
  state = {
    warrantyBrandListInfo: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_BRAND_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_BRAND_LIST_SUCCESS:
      return { loading: false, warrantyBrandListInfo: payload };
    case WARRANTY_BRAND_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//warranty brand expire date
export const warrantyBrandExpireDateReducer = (
  state = {
    warrantyBrandDateInfo: null,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_BRAND_EXPIRE_REQUEST:
      return { loading: true };
    case WARRANTY_BRAND_EXPIRE_SUCCESS:
      return { loading: false, warrantyBrandDateInfo: payload };
    case WARRANTY_BRAND_EXPIRE_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//warranty brand date setting
export const warrantyBrandSetReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_BRAND_SET_REQUEST:
      return { loading: true };
    case WARRANTY_BRAND_SET_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_BRAND_SET_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_BRAND_SET_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//warranty register list
export const warrantyRegisterListReducer = (
  state = { warrantyRegisterListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_LIST_SUCCESS:
      return { loading: false, warrantyRegisterListInfo: payload };
    case WARRANTY_REGISTER_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//warranty register details
export const warrantyRegisterDetailsReducer = (
  state = { warrantyRegisterDetailsInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_DETAILS_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_DETAILS_SUCCESS:
      return { loading: false, warrantyRegisterDetailsInfo: payload };
    case WARRANTY_REGISTER_DETAILS_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_REGISTER_DETAILS_RESET:
      return { error: null, loading: false };
    default:
      return state;
  }
};

//warranty register add remark
export const warrantyAddRemarkReducer = (
  state = { loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REMARK_ADD_REQUEST:
      return { loading: true };
    case WARRANTY_REMARK_ADD_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_REMARK_ADD_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_REMARK_ADD_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//warranty register review
export const warrantyRegisterReviewReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_REVIEW_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_REGISTER_REVIEW_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_REGISTER_REVIEW_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

//warranty number
export const warrantyNumberReducer = (
  state = { warrantyRegisterNumberInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_NUMBER_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_NUMBER_SUCCESS:
      return { loading: false, warrantyRegisterNumberInfo: payload };
    case WARRANTY_REGISTER_NUMBER_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//warranty record
export const warrantyRecordReducer = (
  state = { warrantyRecord: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_RECORD_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_RECORD_SUCCESS:
      return { loading: false, warrantyRecord: payload };
    case WARRANTY_REGISTER_RECORD_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_REGISTER_RECORD_RESET:
      return { loading: false, warrantyRecord: null };
    default:
      return state;
  }
};

//warranty register export
export const warrantyRegisterExportReducer = (
  state = { result: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_REGISTER_EXPORT_REQUEST:
      return { loading: true };
    case WARRANTY_REGISTER_EXPORT_SUCCESS:
      return { loading: false, result: payload };
    case WARRANTY_REGISTER_EXPORT_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_REGISTER_EXPORT_RESET:
      return { error: null, loading: false, success: false };
    default:
      return state;
  }
};

export const getSizeOptionsReducer = (
  state = { error: null, loading: false, sizeOptions: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SIZE_OPTIONS_IDLE:
      return { error: null, loading: false, sizeOptions: [], success: false };
    case GET_SIZE_OPTIONS_REQUEST:
      return { ...state, loading: true };
    case GET_SIZE_OPTIONS_SUCCESS:
      return { ...state, loading: false, sizeOptions: payload, success: true };
    case GET_SIZE_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getFormDescriptionReducer = (
  state = {
    detail: {
      new_Content: '',
      new_Content_en: '',
      old_Content: '',
      old_Content_en: '',
    },
    error: null,
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FORM_DESCRIPTION_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_FORM_DESCRIPTION_SUCCESS:
      return { detail: payload, error: null, loading: false };
    case GET_FORM_DESCRIPTION_FAIL:
      return { ...state, error: payload, loading: false };
    case UPDATE_FORM_DESCRIPTION_REQUEST:
      return { ...state, error: null, loading: true };
    case UPDATE_FORM_DESCRIPTION_SUCCESS:
      return { ...state, error: null, loading: false };
    case UPDATE_FORM_DESCRIPTION_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const canUnbindShipperDtListReducer = (
  state = {
    data: {},
    error: null,
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CAN_UNBIND_SHIPPER_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_CAN_UNBIND_SHIPPER_LIST_SUCCESS:
      return { data: payload, error: null, loading: false };
    case GET_CAN_UNBIND_SHIPPER_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};


export const warrantyRegisterNotifyListReducer = (
    state = {
      error: null,
      list: {},
      loading: false,
    },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_WARRANTY_NOTIFY_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_WARRANTY_NOTIFY_LIST_SUCCESS:
      return { error: null, list: payload, loading: false };
    case GET_WARRANTY_NOTIFY_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};


export const warrantyRegisterNotifyReducer = (
    state = {
      error: null,
      loading: false,
    },
    action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_WARRANTY_NOTIFY_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case UPDATE_WARRANTY_NOTIFY_LIST_SUCCESS:
      return { error: null, loading: false };
    case UPDATE_WARRANTY_NOTIFY_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
