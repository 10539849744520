import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import {
  deleteServiceType,
  getServiceTypeList,
  postServiceType,
} from '../redux/actions/data/storeActions';
import {
  SERVICE_TYPE_DELETE_RESET,
  SERVICE_TYPE_POST_RESET,
} from '../redux/constants/data/storeConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function StoreType(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [editData, setEditData] = useState({});
  const [serviceId, setServiceId] = useState(0);
  const [modal, setModal] = useState(false);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, serviceTypeListInfo, error } = useSelector(
    state => state.serviceTypeList,
  );
  const {
    loading: postLoading,
    success: postSuccess,
    error: postError,
  } = useSelector(state => state.serviceTypePost);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.serviceTypeDelete,
  );

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/StoreType' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/StoreType' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryPage = query.value.page;

      setKeyword(queryKeyword);
      setCurrentPage(queryPage);

      if (queryKeyword) {
        //儲存搜尋及頁數
        dispatch(
          getServiceTypeList({
            keyword: queryKeyword,
            page: queryPage,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getServiceTypeList({
            page: queryPage,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');

      //初始搜尋
      dispatch(
        getServiceTypeList({
          page: 1,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(
        getServiceTypeList({
          page: 1,
        }),
      );
      setModal(false);
    }

    if (postError) {
      MessageUtil.alertWanring(postError);
    }

    return () => {
      dispatch({ type: SERVICE_TYPE_POST_RESET });
    };
  }, [postSuccess, postError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (serviceTypeListInfo.datas.length !== 1) {
        dispatch(
          getServiceTypeList({
            keyword,
            page: current_page,
          }),
        );
      } else {
        setCurrentPage(current_page - 1);

        dispatch(
          getServiceTypeList({
            keyword,
            page: current_page - 1,
          }),
        );

        if (keyword) {
          history.push(
            `${location.pathname}?keyword=${keyword}&page=${current_page - 1}`,
          );
        } else {
          history.push(`${location.pathname}?page=${current_page - 1}`);
        }
      }
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: SERVICE_TYPE_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleOpenAddModal = () => {
    setModal(!modal);
    setServiceId(0);
    setEditData({});
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    dispatch(
      getServiceTypeList({
        page: 1,
      }),
    );
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getServiceTypeList({
        keyword,
        page: 1,
      }),
    );

    history.push(`${location.pathname}?keyword=${keyword}&page=${1}`);
  };

  const handleOpenEditModal = data => {
    setModal(!modal);
    setServiceId(data.id);

    setEditData(data);
  };

  const callDel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteServiceType(id));
    });
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getServiceTypeList({
        keyword,
        page,
      }),
    );
    if (keyword) {
      history.push(`${location.pathname}?keyword=${keyword}&page=${page}`);
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSubmit = values => {
    let formData = new FormData();
    formData.append('id', serviceId);
    formData.append('name', values.name);
    formData.append('name__en', values.name_en);
    formData.append('status', values.status);
    formData.append('contentImg', values.contentImg[0]);

    dispatch(postServiceType(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      current_contentImg: Yup.array().min(1, '需上傳圖片'),
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });
    return schema;
  };

  const getImageDataFromSetting = (pic, url) => {
    return [
      {
        id: CommonUtil.getNewID(),
        name: pic,
        url: url,
      },
    ];
  };

  useEffect(() => {
    if (serviceTypeListInfo) {
      if (JSON.stringify(serviceTypeListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: serviceTypeListInfo });
      }
    }
  }, [serviceTypeListInfo]);

  return (
    <Layout
      pageTitle="服務項目"
      items={[
        { isActive: false, page_name: '據點管理' },
        { isActive: true, page_name: '服務項目' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.formGroup}>
              <label className="col-form-label mr-2" htmlFor="keyword">
                服務項目
              </label>
              <input
                type="text"
                className={`form-control ${style['form-input']}`}
                id="keyword"
                placeholder="請輸入關鍵字"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
              />
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={handleOpenAddModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading &&
            serviceTypeListInfo &&
            serviceTypeListInfo.total_count > 0
              ? serviceTypeListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>服務項目</th>
                <th>圖標</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {serviceTypeListInfo ? (
                  serviceTypeListInfo.datas?.map((data, index) => (
                    <tr key={data.id}>
                      <td>
                        {index +
                          1 +
                          (current_page - 1) * serviceTypeListInfo.page_size}
                      </td>
                      <td>{data.name}</td>
                      <td>
                        <img
                          className="w150"
                          src={data.contentImgUrl}
                          alt={data.name}
                        />
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          serviceTypeListInfo &&
          serviceTypeListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={serviceTypeListInfo.current_page}
                defaultPageSize={10}
                total={serviceTypeListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={handleOpenAddModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={handleOpenAddModal}>
          服務項目編輯
        </ModalHeader>

        <Formik
          enableReinitialize
          initialValues={{
            contentImg: [],
            current_contentImg: [],
            init_contentImg:
              editData && editData.contentImg
                ? getImageDataFromSetting(
                    editData.contentImg,
                    editData.contentImgUrl,
                  )
                : [],

            name: editData && editData.name ? editData.name : '',
            name_en: editData && editData.name__en ? editData.name__en : '',
            status: editData && editData.status ? editData.status : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    服務項目
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="name" className="form-control" />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    服務項目(英)
                    <ErrorMessage
                      name="name_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="name_en" className="form-control" />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    內容
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="contentImg"
                      currentFileFieldName="current_contentImg"
                      uplodModel="svg"
                      // displayFileWidth="24"
                      // displayFileHeight="24"
                      displayFileSize="1MB"
                      initFiles={props.values.init_contentImg}
                      svgBackground="none"
                    />
                    <ErrorMessage
                      name="current_contentImg"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required w150">
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="d-flex form-item">
                    <Field
                      className="my-auto me-2"
                      id="up"
                      name="status"
                      type="radio"
                      value="上架"
                    />
                    <label htmlFor="up" className="me-5">
                      上架
                    </label>
                    <Field
                      className="my-auto me-2"
                      id="down"
                      name="status"
                      type="radio"
                      value="下架"
                    />
                    <label htmlFor="down">下架</label>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className={`me-3 ${style.modal_button_cancel}`}
                  onClick={handleOpenAddModal}
                >
                  取消
                </button>

                <Button
                  type="submit"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
