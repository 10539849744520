import { useFormik } from 'formik';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { editModelShippingDate } from 'redux/actions/data/modelActions';
import { EDIT_MODEL_SHIPPING_DATE_IDLE } from 'redux/constants/data/modelConstants';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const EditShippingDateModal = ({ open, toggle, info, onRefresh }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.editModelShippingDate);
  const { values, handleSubmit, setFieldValue, errors, touched, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        deliveryType: info.type,
        preorderDeliveryDate: info.date || '',
      },
      onSubmit: () => {
        const body = {
          deliveryType: Number(values.deliveryType),
          preorderDeliveryDate:
            Number(values.deliveryType) === 2
              ? values.preorderDeliveryDate
              : '',
        };
        dispatch(editModelShippingDate(body, info.id));
      },
      validationSchema: Yup.object().shape({
        deliveryType: Yup.string().nullable().required('必填'),
        preorderDeliveryDate: Yup.string()
          .nullable()
          .when('deliveryType', {
            is: val => Number(val) === 2,
            then: Yup.string().nullable().required('必填'),
          }),
      }),
    });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: EDIT_MODEL_SHIPPING_DATE_IDLE });
      onRefresh();
      toggle();
    }

    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
      dispatch({ type: EDIT_MODEL_SHIPPING_DATE_IDLE });
    }
  }, [success, error]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        交期修改
      </ModalHeader>
      <ModalBody style={{ height: '380px' }}>
        <div style={{ margin: '1rem' }}>
          <input
            type="radio"
            checked={Number(values.deliveryType) === 1}
            onChange={() => {
              setFieldValue('deliveryType', 1);
            }}
          />
          <span style={{ marginLeft: '1rem' }}>一般</span>
        </div>
        <div className={style.show_flex} style={{ margin: '1rem' }}>
          <input
            type="radio"
            checked={Number(values.deliveryType) === 2}
            onChange={() => {
              setFieldValue('deliveryType', 2);
            }}
          />
          <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>
            預購，預定出貨日
          </span>
          <div className="w200">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={
                values.preorderDeliveryDate === ''
                  ? ''
                  : new Date(values.preorderDeliveryDate)
              }
              onChange={date => {
                setFieldValue('preorderDeliveryDate', date);
              }}
              disabled={Number(values.deliveryType) === 1}
            />
          </div>
          {errors &&
            touched &&
            errors.preorderDeliveryDate &&
            touched.preorderDeliveryDate && (
              <span className="text-danger">{errors.preorderDeliveryDate}</span>
            )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className={style.modal_button_submit}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditShippingDateModal;
