import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import { getNewsDetails } from '../redux/actions/data/newsActions';
import history from '../utils/createHistory';

const NewsPreview = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loadingDetail, newsData } = useSelector(state => state.newsDetails);

  useEffect(() => {
    if (id > 0) {
      dispatch(getNewsDetails(id));
    }
  }, [id]);

  let info = newsData;

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <>
      {!loadingDetail && info && (
        <div className="preview">
          <Button
            className="preview_button_cancel btn btn-light"
            onClick={handleGoBack}
          >
            取消預覽
          </Button>
          <div className="hero">
            <img
              src={info?.bannerUrl}
              alt={info.title}
              className="mi-desktop"
            />
            <img
              src={info?.bannerUrl_mobile}
              alt={info?.title}
              className="mi-mobile"
            />
          </div>

          <main className="newsDt">
            <section>
              <div className="wrap text-center container overflow-hidden">
                <h1>{info?.title}</h1>
                <div className="sub-title" style={{ whiteSpace: 'pre-wrap' }}>
                  {info?.subTitle}
                </div>
                <div className="row gx-5">
                  <p
                    className="col-md-6 text-start"
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {info?.contentLeft}
                  </p>
                  <div className="col-md-6 text-start">
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                      {info?.contentRight}
                    </p>
                    <ul className="newDt-link">
                      {info?.contentLinks?.map(link => (
                        <li key={link.id}>
                          <Link href={link.linkUrl}>
                            <a target="_blank" className="fs-sm">
                              {link.name}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {info?.youtubeLink && (
                  <iframe
                    type="text/html"
                    width="100%"
                    height="385"
                    src={info?.youtubeLink}
                    frameBorder="0"
                  ></iframe>
                )}
                <img
                  className="w-100 mi-desktop"
                  src={info?.contentImg_desktop_url}
                  alt={info?.subTitle}
                />
                <img
                  className="w-100  mi-mobile"
                  src={info?.contentImg_mobile_url}
                  alt={info?.subTitle}
                />
                <div className="pagechange">
                  <a
                    href={`/news/${info?.backNewsId}`}
                    className={`pagechange-prev ${
                      info?.backNewsId === 0 && 'disabled'
                    }`}
                    style={{ pointerEvents: 'none' }}
                  >
                    <span className="arrow left"></span>上一則
                  </a>
                  <a
                    href={`/news/${info?.nextNewsId}`}
                    className={`pagechange-next ${
                      info?.nextNewsId === 0 && 'disabled'
                    }`}
                    style={{ pointerEvents: 'none' }}
                  >
                    下一則<span className="arrow right"></span>
                  </a>
                </div>
              </div>
            </section>
          </main>
        </div>
      )}
    </>
  );
};

export default NewsPreview;
