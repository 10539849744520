import React, { useEffect, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  setSidebarToggle,
} from '../redux/actions/style/sideBarActions';
import style from '../styles/sideBar.module.css';

const SideBarHeader = () => {
  const dispatch = useDispatch();
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { sidebarToggleMobile } = useSelector(state => state.sideBar);

  const toggleSidebar = () => {
    dispatch(setSidebarToggle(!sideBarOpen));
    setSideBarOpen(!sideBarOpen);
  };

  useEffect(() => {
    if (window.innerWidth <= 767) {
      dispatch(setSidebarToggle(true));
    }
  }, []);

  return (
    <>
      <div
        className={style.header}
        style={{
          transform: `${sidebarToggleMobile ? 'translateX(-320px)' : ''}`,
        }}
      >
        <div className={style.logo_wrapper}>
          <NavLink to="/" title="PEZCROWN">
            <div className={style.logo_icon}>
              <img alt="PEZCROWN" src="/images/ic_logo_white.svg" />
            </div>
            <div className={style.logo_text}>
              <b>百滋後台管理</b>
            </div>
          </NavLink>
        </div>
        <button onClick={toggleSidebar} className={style.btn}>
          <GiHamburgerMenu size={28} color="white" />
        </button>
      </div>
    </>
  );
};

export default SideBarHeader;
