import {
  UUMODELREL_LIST_REQUEST,
  UUMODELREL_LIST_SUCCESS,
  UUMODELREL_LIST_FAIL,
  UUMODELREL_SELECT_MODELS_REQUEST,
  UUMODELREL_SELECT_MODELS_SUCCESS,
  UUMODELREL_SELECT_MODELS_FAIL,
  UUMODELREL_EDIT_UUMODEL_REQUEST,
  UUMODELREL_EDIT_UUMODEL_SUCCESS,
  UUMODELREL_EDIT_UUMODEL_FAIL,
  UUMODELREL_EDIT_UUMODEL_RESET,
  UUMODELREL_SAVE_MODELS_REQUEST,
  UUMODELREL_SAVE_MODELS_SUCCESS,
  UUMODELREL_SAVE_MODELS_FAIL,
  UUMODELREL_SAVE_MODELS_RESET,
  UUMODELREL_DELETE_UUMODEL_REQUEST,
  UUMODELREL_DELETE_UUMODEL_SUCCESS,
  UUMODELREL_DELETE_UUMODEL_FAIL,
  UUMODELREL_DELETE_UUMODEL_RESET,
} from 'redux/constants/data/uuModelRelConstants';
export const uuModelRelListReducer = (
  state = { loading: false, uuModelRelListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUMODELREL_LIST_REQUEST:
      return { ...state, loading: true };
    case UUMODELREL_LIST_SUCCESS:
      return { loading: false, uuModelRelListInfo: payload || {} };
    case UUMODELREL_LIST_FAIL:
      return {
        error: payload,
        loading: false,
        uuModelRelListInfo: {},
      };
    default:
      return state;
  }
};
export const uuModelRelSelectModelsReducer = (
  state = { loading: false, uuModelRelSelectModelsInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUMODELREL_SELECT_MODELS_REQUEST:
      return { ...state, loading: true };
    case UUMODELREL_SELECT_MODELS_SUCCESS:
      return { loading: false, uuModelRelSelectModelsInfo: payload || {} };
    case UUMODELREL_SELECT_MODELS_FAIL:
      return {
        error: payload,
        loading: false,
        uuModelRelSelectModelsInfo: {},
      };
    default:
      return state;
  }
};

export const editUUModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UUMODELREL_EDIT_UUMODEL_REQUEST:
      return { loading: true };
    case UUMODELREL_EDIT_UUMODEL_SUCCESS:
      return { loading: false, success: true };
    case UUMODELREL_EDIT_UUMODEL_FAIL:
      return { error: payload, loading: false };
    case UUMODELREL_EDIT_UUMODEL_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const saveModelsReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UUMODELREL_SAVE_MODELS_REQUEST:
      return { loading: true };
    case UUMODELREL_SAVE_MODELS_SUCCESS:
      return { loading: false, success: true };
    case UUMODELREL_SAVE_MODELS_FAIL:
      return { error: payload, loading: false };
    case UUMODELREL_SAVE_MODELS_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
export const deleteUUModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UUMODELREL_DELETE_UUMODEL_REQUEST:
      return { loading: true };
    case UUMODELREL_DELETE_UUMODEL_SUCCESS:
      return { loading: false, success: true };
    case UUMODELREL_DELETE_UUMODEL_FAIL:
      return { error: payload, loading: false };
    case UUMODELREL_DELETE_UUMODEL_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
