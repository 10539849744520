import {
  SET_SIDEBAR_TOGGLE_MOBILE,
  SET_SIDEBAR_FIXED,
  SET_SIDEBAR_FOOTER,
  SET_SIDEBAR_SHADOW,
  SET_SIDEBAR_STYLE,
  SET_SIDEBAR_USERBOX,
  SET_SIDEBAR_TOGGLE,
} from '../../constants/style/sideBarConstants';

const initialState = {
  sidebarFixed: true,
  sidebarFooter: true,
  sidebarShadow: false,
  sidebarStyle: 'app-sidebar--dark',
  sidebarToggle: false,
  sidebarToggleMobile: false,
  sidebarUserbox: true,
};

export const sideBarReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SIDEBAR_SHADOW:
      return {
        ...state,
        sidebarShadow: payload,
      };
    case SET_SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: payload,
      };
    case SET_SIDEBAR_STYLE:
      return {
        ...state,
        sidebarStyle: payload,
      };
    case SET_SIDEBAR_FOOTER:
      return {
        ...state,
        sidebarFooter: payload,
      };
    case SET_SIDEBAR_TOGGLE_MOBILE: //*開合選單
      return {
        ...state,
        sidebarToggleMobile: payload,
      };
    case SET_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarToggle: payload,
      };
    case SET_SIDEBAR_USERBOX:
      return {
        ...state,
        sidebarUserbox: payload,
      };
    default:
      return state;
  }
};
