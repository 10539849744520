import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../../components/Layout';
import PerformanceList from '../../components/report/VoucherPerformanceList';
import SearchCondition from '../../components/report/VoucherSearchCondition';
import { getCouponPerformanceReportList } from '../../redux/actions/data/operatingReportActions';

const VoucherPerformanceReport = () => {
  const { loading, list } = useSelector(
    state => state.getCouponPerformanceReportList,
  );

  const dispatch = useDispatch();

  const onSearch = useCallback(data => {
    dispatch(getCouponPerformanceReportList(data));
  }, []);

  return (
    <Layout
      pageTitle="優惠券-績效報表"
      items={[
        { isActive: false, page_name: '營運報表' },
        { isActive: true, page_name: '優惠券-績效報表' },
      ]}
    >
      <SearchCondition onSearch={onSearch} />
      <PerformanceList list={list} loading={loading} onSearch={onSearch} />
    </Layout>
  );
};

export default VoucherPerformanceReport;
