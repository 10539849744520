import Layout from 'components/Layout';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


import CafeProducts from '../components/uuCafeMealIntroProduct/CafeProducts';

const CafeMealIntroCafeProduct = () => {
  const params = useParams();
  const { detail } = useSelector(state => state.cafeMealInfoMenu);
  return (
    <Layout
      pageTitle={`${detail.name}-商品設定`}
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: `${detail.name}-商品設定` },
      ]}
    >
      <CafeProducts menuId={params.id} />
    </Layout>
  );
};

export default CafeMealIntroCafeProduct;
