export const SERVICE_TYPE_LIST_REQUEST = 'SERVICE_TYPE_LIST_REQUEST';
export const SERVICE_TYPE_LIST_SUCCESS = 'SERVICE_TYPE_LIST_SUCCESS';
export const SERVICE_TYPE_LIST_FAIL = 'SERVICE_TYPE_LIST_FAIL';

export const SERVICE_TYPE_POST_REQUEST = 'SERVICE_TYPE_POST_REQUEST';
export const SERVICE_TYPE_POST_SUCCESS = 'SERVICE_TYPE_POST_SUCCESS';
export const SERVICE_TYPE_POST_FAIL = 'SERVICE_TYPE_POST_FAIL';
export const SERVICE_TYPE_POST_RESET = 'SERVICE_TYPE_POST_RESET';

export const SERVICE_TYPE_DELETE_REQUEST = 'SERVICE_TYPE_DELETE_REQUEST';
export const SERVICE_TYPE_DELETE_SUCCESS = 'SERVICE_TYPE_DELETE_SUCCESS';
export const SERVICE_TYPE_DELETE_FAIL = 'SERVICE_TYPE_DELETE_FAIL';
export const SERVICE_TYPE_DELETE_RESET = 'SERVICE_TYPE_DELETE_RESET';

export const SERVICE_STORE_LIST_REQUEST = 'SERVICE_STORE_LIST_REQUEST';
export const SERVICE_STORE_LIST_SUCCESS = 'SERVICE_STORE_LIST_SUCCESS';
export const SERVICE_STORE_LIST_FAIL = 'SERVICE_STORE_LIST_FAIL';

export const SERVICE_STORE_UPDATE_REQUEST = 'SERVICE_STORE_UPDATE_REQUEST';
export const SERVICE_STORE_UPDATE_SUCCESS = 'SERVICE_STORE_UPDATE_SUCCESS';
export const SERVICE_STORE_UPDATE_FAIL = 'SERVICE_STORE_UPDATE_FAIL';
export const SERVICE_STORE_UPDATE_RESET = 'SERVICE_STORE_UPDATE_RESET';

export const SERVICE_OPTION_REQUEST = 'SERVICE_OPTION_REQUEST';
export const SERVICE_OPTION_SUCCESS = 'SERVICE_OPTION_SUCCESS';
export const SERVICE_OPTION_FAIL = 'SERVICE_OPTION_FAIL';

export const SERVICE_DETAILS_REQUEST = 'SERVICE_DETAILS_REQUEST';
export const SERVICE_DETAILS_SUCCESS = 'SERVICE_DETAILS_SUCCESS';
export const SERVICE_DETAILS_FAIL = 'SERVICE_DETAILS_FAIL';
