import React, { memo } from 'react';
import { Label } from 'reactstrap';

const LabelC = ({ text, required, style, ...rest }) => (
  <Label style={{ width: 160, ...style }} {...rest}>
    {required && <span className="text-danger">*</span>}
    {text}
  </Label>
);

export default memo(LabelC);
