import {
  HOME_DELIVERY_SHIPPING_REQUEST,
  HOME_DELIVERY_SHIPPING_SUCCESS,
  HOME_DELIVERY_SHIPPING_FAIL,
  CHK_HOME_DELIVERY_REQUEST,
  CHK_HOME_DELIVERY_SUCCESS,
  CHK_HOME_DELIVERY_FAIL,
  CHK_STORE_REQUEST,
  CHK_STORE_SUCCESS,
  CHK_STORE_FAIL,
  STORE_SHIPPING_REQUEST,
  STORE_SHIPPING_SUCCESS,
  STORE_SHIPPING_FAIL,
  STORE_LOGISTIC_REQUEST,
  STORE_LOGISTIC_SUCCESS,
  STORE_LOGISTIC_FAIL,
  STORE_PICKED_UP_REQUEST,
  STORE_PICKED_UP_SUCCESS,
  STORE_PICKED_UP_FAIL,
  STORE_ARRIVED_REQUEST,
  STORE_ARRIVED_SUCCESS,
  STORE_ARRIVED_FAIL,
  STORE_CANCEL_REQUEST,
  STORE_CANCEL_SUCCESS,
  STORE_CANCEL_FAIL,
  UUOPTION_SUCCESS,
  SHIPPING_PRINT_INFO_REQUEST,
  SHIPPING_PRINT_INFO_SUCCESS,
  SHIPPING_PRINT_INFO_FAIL,
  HOME_DELIVERY_CANCEL_REQUEST,
  HOME_DELIVERY_CANCEL_SUCCESS,
  HOME_DELIVERY_CANCEL_FAIL,
} from 'redux/constants/data/orderShippingConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const homeDeliveryShipping = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: HOME_DELIVERY_SHIPPING_FAIL,
      method: 'post',
      requestConstant: HOME_DELIVERY_SHIPPING_REQUEST,
      successConstant: HOME_DELIVERY_SHIPPING_SUCCESS,
      url: '/Backend/OrderShipping/HomeDeliveryShipping',
    });
  };
};

export const chkHomeDelivery = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: CHK_HOME_DELIVERY_FAIL,
      method: 'post',
      requestConstant: CHK_HOME_DELIVERY_REQUEST,
      successConstant: CHK_HOME_DELIVERY_SUCCESS,
      url: '/Backend/OrderShipping/ChkHomeDelivery',
    });
  };
};
export const chkStore = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: CHK_STORE_FAIL,
      method: 'post',
      requestConstant: CHK_STORE_REQUEST,
      successConstant: CHK_STORE_SUCCESS,
      url: '/Backend/OrderShipping/ChkStore',
    });
  };
};
export const storeShipping = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: STORE_SHIPPING_FAIL,
      method: 'post',
      requestConstant: STORE_SHIPPING_REQUEST,
      successConstant: STORE_SHIPPING_SUCCESS,
      url: '/Backend/OrderShipping/StoreShipping',
    });
  };
};
export const storeLogistic = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: STORE_LOGISTIC_FAIL,
      method: 'post',
      requestConstant: STORE_LOGISTIC_REQUEST,
      successConstant: STORE_LOGISTIC_SUCCESS,
      url: '/Backend/OrderShipping/StoreLogistic',
    });
  };
};
export const storeArrived = data => {
  CommonUtil.consoleLog({ anno: 'request body', data: data });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/OrderShipping/StoreArrived',
  });
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: STORE_ARRIVED_FAIL,
      method: 'post',
      requestConstant: STORE_ARRIVED_REQUEST,
      successConstant: STORE_ARRIVED_SUCCESS,
      url: '/Backend/OrderShipping/StoreArrived',
    });
  };
};
export const storePickeUp = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: STORE_PICKED_UP_FAIL,
      method: 'post',
      requestConstant: STORE_PICKED_UP_REQUEST,
      successConstant: STORE_PICKED_UP_SUCCESS,
      url: '/Backend/OrderShipping/StorePickedUp',
    });
  };
};
export const storeCancel = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: STORE_CANCEL_FAIL,
      method: 'post',
      requestConstant: STORE_CANCEL_REQUEST,
      successConstant: STORE_CANCEL_SUCCESS,
      url: '/Backend/OrderShipping/StoreCancel',
    });
  };
};

export const getUUOptions = () => {
  return async (dispatch, getState) => {
    return await useRequest({
      dispatch,
      method: 'get',
      successConstant: UUOPTION_SUCCESS,
      url: '/Website/General/UUoptions',
    });
  };
};

export const getStoreOptions = () => {
  return request({
    method: 'get',
    url: '/Website/General/UUoptions',
  }).then(res => {
    if (!res.message) {
      return res;
    } else {
      throw res;
    }
  });
};

export const shippingPrintInfo = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: SHIPPING_PRINT_INFO_FAIL,
      method: 'post',
      requestConstant: SHIPPING_PRINT_INFO_REQUEST,
      successConstant: SHIPPING_PRINT_INFO_SUCCESS,
      url: '/Backend/OrderShipping/ShippingPrintInfo',
    });
  };
};

export const cancelHomeDeliveryOrder = ids => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/OrderShipping/HomeDeliveryCancel',
  });
  return async dispatch => {
    await useRequest({
      data: ids,
      dispatch,
      failConstant: HOME_DELIVERY_CANCEL_FAIL,
      method: 'post',
      requestConstant: HOME_DELIVERY_CANCEL_REQUEST,
      successConstant: HOME_DELIVERY_CANCEL_SUCCESS,
      url: '/Backend/OrderShipping/HomeDeliveryCancel',
    });
  };
};
