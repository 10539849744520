import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';

import {
  getDistributor,
  updateDistributor,
} from '../../redux/actions/data/distributorActions';
import { DISTRIBUTOR_GET_REQUEST } from '../../redux/constants/data/distributorConstants';
import { initValue } from '../../redux/reducers/data/distributorReducers';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import Loader from '../Loader';
import UploadFileBox from '../UploadFileBox';

const lableStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };

const ContentSettings = () => {
  const dispatch = useDispatch();
  const { detail, loading } = useSelector(state => state.distributor);
  const { values, errors, setFieldValue, setValues, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initValue,
      contentPcImgFiles: [],
      contentPhoneImgFiles: [],
      imageryPcImgFiles: [],
      imageryPhoneImgFiles: [],
    },
    onSubmit: async values => {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (typeof values[key] === 'object') {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          if (values[key] === null) {
            formData.append(key, 0);
          } else {
            formData.append(key, String(values[key]));
          }
        }
      });
      if (
        values?.contentPcImgFiles.length > 0 &&
        values?.contentPcImgFiles[0] instanceof File
      ) {
        formData.set('contentPcImg_file', values.contentPcImgFiles[0]);
      }
      if (
        values?.contentPhoneImgFiles.length > 0 &&
        values?.contentPhoneImgFiles[0] instanceof File
      ) {
        formData.set('contentPhoneImg_file', values.contentPhoneImgFiles[0]);
      }

      if (
        values?.imageryPcImgFiles.length > 0 &&
        values?.imageryPcImgFiles[0] instanceof File
      ) {
        formData.set('imageryPcImg_file', values.imageryPcImgFiles[0]);
      }
      if (
        values?.imageryPhoneImgFiles.length > 0 &&
        values?.imageryPhoneImgFiles[0] instanceof File
      ) {
        formData.set('imageryPhoneImg_file', values.imageryPhoneImgFiles[0]);
      }
      dispatch({
        type: DISTRIBUTOR_GET_REQUEST,
      });
      try {
        await updateDistributor(formData);
        MessageUtil.alertSuccess('更新成功');
        dispatch(getDistributor());
      } catch (e) {
        MessageUtil.alertWanring(e);
      }
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().required('請輸入內容'),
      contentPcImgFiles: Yup.array().min(1, '請上傳圖片'),
      contentPhoneImgFiles: Yup.array().min(1, '請上傳圖片'),
      content_en: Yup.string().required('請輸入內容(英文)'),
      imageryPcImgFiles: Yup.array().min(1, '請上傳圖片'),
      imageryPhoneImgFiles: Yup.array().min(1, '請上傳圖片'),
      title: Yup.string().required('請輸入標題'),
      title_en: Yup.string().required('請輸入標題(英文)'),
      url: Yup.string()
        .required('請輸入經銷商入口網連結')
        .test('is-invalid', '連結格式錯誤', value => {
          return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
        }),
    }),
  });

  useEffect(() => {
    dispatch(getDistributor());
  }, []);

  useEffect(() => {
    if (detail && !loading) {
      setValues({
        ...detail,
        contentPcImgFiles: detail.contentPcImgLink
          ? getImageDataFromSetting(
              detail.contentPcImg,
              detail.contentPcImgLink,
            )
          : [],
        contentPhoneImgFiles: detail.contentPhoneImgLink
          ? getImageDataFromSetting(
              detail.contentPhoneImg,
              detail.contentPhoneImgLink,
            )
          : [],
        imageryPcImgFiles: detail.imageryPcImgLink
          ? getImageDataFromSetting(
              detail.imageryPcImg,
              detail.imageryPcImgLink,
            )
          : [],
        imageryPhoneImgFiles: detail.imageryPhoneImgLink
          ? getImageDataFromSetting(
              detail.imageryPhoneImg,
              detail.imageryPhoneImgLink,
            )
          : [],
        initContentPcImgFiles: detail.contentPcImg
          ? getImageDataFromSetting(
              detail.contentPcImg,
              detail.contentPcImgLink,
            )
          : [],
        initContentPhoneImgFiles: detail.contentPhoneImgLink
          ? getImageDataFromSetting(
              detail.contentPhoneImg,
              detail.contentPhoneImgLink,
            )
          : [],
        initImageryPcImgFiles: detail.imageryPcImgLink
          ? getImageDataFromSetting(
              detail.imageryPcImg,
              detail.imageryPcImgLink,
            )
          : [],
        initImageryPhoneImgFiles: detail.imageryPhoneImgLink
          ? getImageDataFromSetting(
              detail.imageryPhoneImg,
              detail.imageryPhoneImgLink,
            )
          : [],
      });
    }
  }, [detail]);

  return (
    <Card className={style.card}>
      {loading && <Loader />}
      {!loading && (
        <div className="form-list form-label-140">
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              形象圖
              <br />
              電腦版圖片
            </label>
            <div className="form-item">
              <UploadFileBox
                uplodModel="images"
                formFieldName="imageryPcImgFiles"
                currentFileFieldName="initImageryPcImgFiles"
                setFormFieldValue={(field, files) => {
                  if (files)
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('imageryPcImgFiles', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('imageryPcImgLink', '');
                      setFieldValue('imageryPcImgFiles', []);
                    }
                }}
                displayFileWidth="1920"
                displayFileHeight="不限"
                displayFileSize="3MB"
                initFiles={values.initImageryPcImgFiles}
              />
              <div className="form-error px-2">
                {errors?.imageryPcImgFiles || ''}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              形象圖
              <br />
              手機版圖片
            </label>
            <div className="form-item">
              <UploadFileBox
                uplodModel="images"
                formFieldName="contentPhoneImgFiles"
                currentFileFieldName="initImageryPhoneImgFiles"
                setFormFieldValue={(field, files) => {
                  if (files)
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('imageryPhoneImgFiles', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('imageryPhoneImgLink', '');
                      setFieldValue('imageryPhoneImgFiles', []);
                    }
                }}
                displayFileWidth="800"
                displayFileHeight="不限"
                displayFileSize="3MB"
                initFiles={values.initImageryPhoneImgFiles}
              />
              <div className="form-error px-2">
                {errors?.contentPhoneImgFiles || ''}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              內容圖
              <br />
              電腦版圖片
            </label>
            <div className="form-item">
              <UploadFileBox
                uplodModel="images"
                formFieldName="contentPcImgFiles"
                currentFileFieldName="initContentPcImgFiles"
                setFormFieldValue={(field, files) => {
                  if (files)
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('contentPcImgFiles', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('contentPcImgLink', '');
                      setFieldValue('contentPcImgFiles', []);
                    }
                }}
                displayFileWidth="1920"
                displayFileHeight="不限"
                displayFileSize="3MB"
                initFiles={values.initContentPcImgFiles}
              />
              <div className="form-error px-2">
                {errors?.contentPcImgFiles || ''}
              </div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              內容圖
              <br />
              手機版圖片
            </label>
            <div className="form-item">
              <UploadFileBox
                uplodModel="images"
                formFieldName="contentPhoneImgFiles"
                currentFileFieldName="initContentPhoneImgFiles"
                setFormFieldValue={(field, files) => {
                  if (files)
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('contentPhoneImgFiles', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('contentPhoneImgLink', '');
                      setFieldValue('contentPhoneImgFiles', []);
                    }
                }}
                displayFileWidth="800"
                displayFileHeight="不限"
                displayFileSize="3MB"
                initFiles={values.initContentPhoneImgFiles}
              />
              <div className="form-error px-2">
                {errors?.contentPhoneImgFiles || ''}
              </div>
            </div>
          </FormGroup>

          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              介紹-標題(中)
            </label>
            <div className="form-item">
              <div className="d-flex">
                <Input
                  name="title"
                  className="mx-2"
                  value={values.title}
                  onChange={e => {
                    setFieldValue('title', e.target.value);
                  }}
                />
              </div>
              <div className="form-error px-2">{errors?.title || ''}</div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              介紹-標題(英)
            </label>
            <div className="form-item">
              <div className="d-flex">
                <Input
                  name="title_en"
                  className="mx-2"
                  value={values.title_en}
                  onChange={e => {
                    setFieldValue('title_en', e.target.value);
                  }}
                />
              </div>
              <div className="form-error px-2">{errors?.title_en || ''}</div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              介紹-內容(中)
            </label>
            <div className="form-item">
              <div className="d-flex">
                <Input
                  tag="textarea"
                  row="5"
                  name="content"
                  className="mx-2"
                  value={values.content}
                  onChange={e => {
                    setFieldValue('content', e.target.value);
                  }}
                />
              </div>
              <div className="form-error px-2">{errors?.content || ''}</div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              介紹-內容(英)
            </label>
            <div className="form-item">
              <div className="d-flex">
                <Input
                  tag="textarea"
                  row="5"
                  name="content_en"
                  className="mx-2"
                  value={values.content_en}
                  onChange={e => {
                    setFieldValue('content_en', e.target.value);
                  }}
                />
              </div>
              <div className="form-error px-2">{errors?.content_en || ''}</div>
            </div>
          </FormGroup>
          <FormGroup className="form-group mb-3 mb-3">
            <label className="required" style={lableStyle}>
              經銷商入口網連結
            </label>
            <div className="form-item">
              <div className="d-flex">
                <Input
                  name="url"
                  className="mx-2"
                  value={values.url}
                  onChange={e => {
                    setFieldValue('url', e.target.value);
                  }}
                />
              </div>
              <div className="form-error px-2">{errors?.url || ''}</div>
            </div>
          </FormGroup>
        </div>
      )}
      <hr />
      <div className="text-center">
        <Button
          type="button"
          className={style.modal_button_submit}
          disabled={Object.keys(errors).length || loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={{ marginRight: '1rem' }}
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </div>
    </Card>
  );
};

export default ContentSettings;
