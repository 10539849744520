import moment from 'moment';

export const emptyOption = (label, value = '') => ({ label, value });
export const formatImagesArr = arr => {
  if (!arr) return [];
  return arr.map(i => ({ id: i?.id, name: i?.image, url: i?.imageUrl }));
};
export const hourArr = () => {
  return Array.from({ length: 24 }, (_, index) => index).map(i => ({
    label: paddingZeroLeft({ num: 2, text: i }),
    value: i,
  }));
};
export const minArr = () => {
  return Array.from({ length: 6 }, (_, index) => index * 10).map(i => ({
    label: paddingZeroLeft({ num: 2, text: i }),
    value: i,
  }));
};
export const min2Arr = () => {
  return Array.from({ length: 60 }, (_, index) => index).map(i => ({
    label: paddingZeroLeft({ num: 2, text: i }),
    value: i,
  }));
};
export const paddingZeroLeft = ({ text, num }) => {
  let str = text.toString();
  return str.length < num ? paddingZeroLeft({ num, text: `0${  str}` }) : str;
};
export const handleCopyText = async (text, cb) => {
  await navigator.clipboard.writeText(text);
  cb && cb();
};

export const checkOverNow = d => moment(d).isBefore(moment());

export const createHiddenInput = (form, name, value) => {
  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = name;
  hiddenField.value = value;
  form.appendChild(hiddenField);
};
