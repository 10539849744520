import {
  UUCAFE_WORLD_DETAIL_ADD_FAIL,
  UUCAFE_WORLD_DETAIL_ADD_REQUEST,
  UUCAFE_WORLD_DETAIL_ADD_SUCCESS,
  UUCAFE_WORLD_DETAIL_FAIL,
  UUCAFE_WORLD_DETAIL_REQUEST,
  UUCAFE_WORLD_DETAIL_SUCCESS,
  UUCAFE_WORLD_DETAIL_UPDATE_FAIL,
  UUCAFE_WORLD_DETAIL_UPDATE_REQUEST,
  UUCAFE_WORLD_DETAIL_UPDATE_SUCCESS,
  UUCAFE_WORLD_LIST_FAIL,
  UUCAFE_WORLD_LIST_REQUEST,
  UUCAFE_WORLD_LIST_SUCCESS,
} from '../../constants/data/uuCafeWorldConstants';

export const cafeWorldListReducer = (
  state = {
    error: null,
    loading: false,
    success: false,
    worldList: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_WORLD_LIST_REQUEST:
      return {
        error: null,
        loading: true,
        success: false,
        worldList: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
      };
    case UUCAFE_WORLD_LIST_SUCCESS:
      return { ...state, loading: false, success: true, worldList: payload };
    case UUCAFE_WORLD_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeWorldDetailReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      endDateTime: '',
      imgFile: '',
      imgUrl: '',
      startDateTime: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_WORLD_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_WORLD_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_WORLD_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_WORLD_DETAIL_ADD_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_WORLD_DETAIL_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_WORLD_DETAIL_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_WORLD_DETAIL_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_WORLD_DETAIL_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_WORLD_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
