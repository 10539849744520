import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  addCafeProductDetail,
  getCafeProductDetail,
  updateCafeProductDetail,
} from '../../redux/actions/data/uuCafeProductAction';
import { UUCAFE_PRODUCT_SUCCESS } from '../../redux/constants/data/uuCafeProductConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import Loader from '../Loader';
import UploadFileBox from '../UploadFileBox';

const ProductModel = ({
  detail = {
    content: '',
    content_en: '',
    id: 0,
    imgFile: '',
    imgUrl: '',
    menuId: 0,
    name: '',
    name_en: '',
  },
  templateType = 1,
  menuId = 0,
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const {
    loading,
    success,
    error,
    detail: initData,
  } = useSelector(state => state.cafeMealProduct);
  const dispatch = useDispatch();
  const isSubmit = useRef(false);
  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const formData = new FormData();
      formData.append('name', props.values.name);
      formData.append('name_en', props.values.name_en);
      formData.append('content', props.values.content);
      formData.append('content_en', props.values.content_en);
      formData.append('menuId', props.values.menuId || menuId);
      formData.append('status', props.values.status);

      if (templateType === 2) {
        formData.append(
          'temperatures',
          JSON.stringify(initData.temperatures || props.values.temperatures),
        );
        formData.append(
          'prices',
          JSON.stringify(initData.prices || props.values.prices),
        );
      } else {
        formData.append('temperatures', '0');
        formData.append('prices', '0');
      }

      if (templateType !== 2) {
        if (!props.values.img_files && initData.imgFile && initData.imgUrl) {
          formData.append('img_files', []);
        } else {
          formData.append('img_files', props.values.img_files);
        }
      }

      if (detail.id) {
        formData.append('id', detail.id);
        dispatch(updateCafeProductDetail(formData));
      } else {
        formData.append('id', 0);
        dispatch(addCafeProductDetail(formData));
      }
    },
    [detail, menuId, templateType, initData],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    if (detail.id) {
      dispatch(getCafeProductDetail(detail));
    } else {
      dispatch({
        payload: detail,
        type: UUCAFE_PRODUCT_SUCCESS,
      });
    }
  }, [detail.id]);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      name: Yup.string().required('必填!'),
      name_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>商品編輯</ModalHeader>
        {!(!loading || isSubmit.current) && <Loader />}
        {(!loading || isSubmit.current) && (
          <Formik
            initialValues={{
              content: detail.content || initData.content,
              content_en: detail.content_en || initData.content_en,
              img_files: null,
              init_img_files: getImageDataFromSetting(
                detail.imgFile || initData.imgFile,
                detail.imgUrl || initData.imgUrl,
              ),
              menuId: detail.menuId || initData.menuId || 0,
              name: detail.name || initData.name,
              name_en: detail.name_en || initData.name_en,
              prices:
                templateType === 2
                  ? detail.prices ||
                    initData.prices || [{ code: '', price: '' }]
                  : '',
              status: detail.status || initData.status || 1,
              temperatures:
                templateType === 2
                  ? detail.temperatures || initData.temperatures || ['']
                  : '',
            }}
            validationSchema={getValidationSchema()}
          >
            {props => (
              <Form className="form-list form-label-140">
                <ModalBody className={style.modal_body}>
                  <div className="form-item">
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*商品名稱(中文)</label>
                      <div className="form-item">
                        <Field name="name" className="form-control" />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*商品名稱(英文)</label>
                      <div className="form-item">
                        <Field name="name_en" className="form-control" />
                        <ErrorMessage
                          name="name_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    {templateType === 2 && (
                      <FormGroup className="form-group mb-3 mb-3">
                        <label>*溫度</label>
                        <div className="form-item row flex-column">
                          {props.values.temperatures &&
                            props.values.temperatures instanceof Array &&
                            props.values.temperatures.length > 0 &&
                            props.values.temperatures.map((t, index, arr) => {
                              return (
                                <div
                                  className="d-inline-flex w-100 mb-2"
                                  key={`temp-${index}`}
                                >
                                  <input
                                    className="form-control"
                                    style={{ width: '150px' }}
                                    defaultValue={t}
                                    onBlur={e => {
                                      props.values.temperatures[index] =
                                        e.target.value;
                                      dispatch({
                                        payload: {
                                          ...initData,
                                          temperatures:
                                            props.values.temperatures,
                                        },
                                        type: UUCAFE_PRODUCT_SUCCESS,
                                      });
                                    }}
                                  />
                                  <Button
                                    color={
                                      index === arr.length - 1
                                        ? 'primary'
                                        : 'danger'
                                    }
                                    outline="outline"
                                    className="mx-2"
                                    onClick={() => {
                                      if (index === arr.length - 1) {
                                        if (props.values.temperatures[index]) {
                                          props.values.temperatures.push('');
                                        } else {
                                          MessageUtil.alertWanring(
                                            '請輸入溫度數值',
                                          );
                                        }
                                      } else {
                                        props.values.temperatures.splice(
                                          index,
                                          1,
                                        );
                                      }
                                      dispatch({
                                        payload: {
                                          ...initData,
                                          temperatures:
                                            props.values.temperatures,
                                        },
                                        type: UUCAFE_PRODUCT_SUCCESS,
                                      });
                                    }}
                                  >
                                    {index === arr.length - 1 ? '新增' : '刪除'}
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                      </FormGroup>
                    )}
                    {templateType === 2 && (
                      <FormGroup className="form-group mb-3 mb-3">
                        <label>*價格</label>
                        <div className="form-item row flex-column">
                          {props.values.prices &&
                            props.values.prices instanceof Array &&
                            props.values.prices.length > 0 &&
                            props.values.prices.map((p, index, arr) => {
                              return (
                                <div
                                  className="d-inline-flex w-100 mb-2 align-items-center"
                                  key={`price-${index}`}
                                >
                                  <span className="mx-2">代號</span>
                                  <input
                                    className="form-control"
                                    style={{ width: '100px' }}
                                    defaultValue={p.code}
                                    onBlur={e => {
                                      props.values.prices[index].code =
                                        e.target.value;
                                      dispatch({
                                        payload: {
                                          ...initData,
                                          prices: props.values.prices,
                                        },
                                        type: UUCAFE_PRODUCT_SUCCESS,
                                      });
                                    }}
                                  />
                                  <span className="mx-2">$</span>
                                  <input
                                    className="form-control"
                                    style={{ width: '100px' }}
                                    defaultValue={p.price}
                                    onBlur={e => {
                                      props.values.prices[index].price =
                                        e.target.value;
                                      dispatch({
                                        payload: {
                                          ...initData,
                                          prices: props.values.prices,
                                        },
                                        type: UUCAFE_PRODUCT_SUCCESS,
                                      });
                                    }}
                                  />
                                  <Button
                                    color={
                                      index === arr.length - 1
                                        ? 'primary'
                                        : 'danger'
                                    }
                                    outline="outline"
                                    className="mx-2"
                                    onClick={() => {
                                      if (index === arr.length - 1) {
                                        if (
                                          props.values.prices[index].code &&
                                          props.values.prices[index].price
                                        ) {
                                          props.values.prices.push({
                                            code: '',
                                            price: '',
                                          });
                                        } else {
                                          MessageUtil.alertWanring(
                                            '請輸入代碼與價格',
                                          );
                                        }
                                      } else {
                                        props.values.prices.splice(index, 1);
                                      }
                                      dispatch({
                                        payload: {
                                          ...initData,
                                          prices: props.values.prices,
                                        },
                                        type: UUCAFE_PRODUCT_SUCCESS,
                                      });
                                    }}
                                  >
                                    {index === arr.length - 1 ? '新增' : '刪除'}
                                  </Button>
                                </div>
                              );
                            })}
                        </div>
                      </FormGroup>
                    )}
                    <FormGroup className="form-group mb-3 mb-3">
                      {templateType === 1 && <label>內容(中文)</label>}
                      {templateType === 2 && <label>備註(中文)</label>}
                      <div className="form-item">
                        <Field
                          name="content"
                          className="form-control"
                          component="textarea"
                          row="4"
                        />
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      {templateType === 1 && <label>內容(英文)</label>}
                      {templateType === 2 && <label>備註(英文)</label>}
                      <div className="form-item">
                        <Field
                          name="content_en"
                          className="form-control"
                          component="textarea"
                          row="4"
                        />
                        <ErrorMessage
                          name="content_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    {templateType === 1 && (
                      <FormGroup className={style.formGroup}>
                        <label style={{ width: '120px' }}>圖片</label>
                        <div className="form-item">
                          <UploadFileBox
                            uplodModel="images"
                            formFieldName="img_files"
                            currentFileFieldName="img_files"
                            setFormFieldValue={(field, files) => {
                              if (files && files.length) {
                                if (files[0] instanceof File) {
                                  props.setFieldValue('img_files', files[0]);
                                }
                              }
                            }}
                            displayFileWidth="350"
                            displayFileHeight="200"
                            displayFileSize="1MB"
                            initFiles={props.values.init_img_files}
                          />
                        </div>
                      </FormGroup>
                    )}
                    <FormGroup className="mb-3 mb-3">
                      <label className="w-100">*狀態</label>
                      <div className="w-100 row">
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="status-1"
                        >
                          <Field
                            type="radio"
                            name="status"
                            value={1}
                            id="status-1"
                            onChange={() => {
                              props.setFieldValue('status', 1);
                            }}
                          />
                          上架
                        </label>
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="status-2"
                        >
                          <Field
                            type="radio"
                            name="status"
                            value={2}
                            id="status-2"
                            onChange={() => {
                              props.setFieldValue('status', 2);
                            }}
                          />
                          下架
                        </label>
                      </div>
                    </FormGroup>
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  <Button
                    color="light"
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={() => {
                      setOpenModel(false, false);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    disabled={loading}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span>儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default memo(ProductModel);
