import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderReturnDescModal from 'components/OrderReturnDescModal';
import { BlockSpinner } from 'components/Spinner';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const RejectModal = ({ open, toggle, ids, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit, handleReset, errors, touched } =
      useFormik({
        enableReinitialize: true,
        initialValues: {
          returnStatusReason: '',
        },
        async onSubmit(formModel) {
          setLoading(true);
          const { code, message } = await request({
            data: { ...formModel, orderItemIds: ids },
            method: 'post',
            url: '/Backend/OrderChange/LogisticsRejectFinish',
          });
          setLoading(false);
          if (code !== 200) {
            MessageUtil.alertWanring(message);
            return;
          }
          MessageUtil.toastSuccess('修改成功！');
          handleReset();
          toggle();
          onSubmit && onSubmit();
        },
        validationSchema: Yup.object().shape({
          returnStatusReason: Yup.string().required('必填'),
        }),
      });
  return (
      <div className={style.modal}>
        <Modal
            zIndex={2000}
            centered
            size="lg"
            isOpen={open}
            toggle={toggle}
            backdrop="static"
        >
          <ModalHeader toggle={toggle} className={style.modal_head}>
            不予退貨
          </ModalHeader>
          <ModalBody className={style.modal_body}>
            {loading && <BlockSpinner />}
            <Form>
              <FormGroup row>
                <Label style={{ width: 150 }}>
                  <span className="text-danger">* </span>
                  原因
                </Label>
                <Col>
                  <Input
                      type="textarea"
                      rows={5}
                      name="returnStatusReason"
                      onChange={handleChange}
                      value={values.returnStatusReason}
                  />
                  {touched.returnStatusReason && (
                      <FormText color="danger" className="d-block">
                        {errors.returnStatusReason}
                      </FormText>
                  )}
                </Col>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter className={style.modal_footer}>
            <Button
                color="light"
                className={style.modal_button_cancel}
                onClick={toggle}
                disabled={loading}
            >
              取消
            </Button>
            <Button
                color="primary"
                type="submit"
                className={style.modal_button_submit}
                disabled={loading}
                onClick={handleSubmit}
            >
              {loading && (
                  <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                  ></span>
              )}
              儲存
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  );
};
const defaultOrderItemModel = {
  homeDeliveryC: 0,
  homeDeliveryNumber: '',
  orderItemId: 0,
};
const TableList = ({
                     onAction,
                     onReject,
                     onChangePage,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );
  const flatList = orderListInfo?.datas?.map(i => i.orderItems).flat();

  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  const [allHomeDeliveryC, setAllHomeDeliveryC] = useState(0);
  const [allHomeDeliveryNumber, setAllHomeDeliveryNumber] = useState('');
  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
    async onSubmit(formModel, { resetForm }) {
      onAction && onAction(formModel, resetForm);
    },
  });

  const [modalOpen, setModalOpen] = useState(false);
  const handleToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};
  const [descOpen, setDescOpen] = useState(false);
  const [currentDescInfo, setCurrentDescInfo] = useState(null);
  const handleToggleDesc = () => {
    setDescOpen(!descOpen);
  };
  return (
      <Card className={style.card}>
        <div className="mb-3">
          <Button
              className="me-2"
              color="success"
              onClick={handleSubmit}
              disabled={values.list.length <= 0}
          >
            完成換貨
          </Button>
          <Button
              color="success"
              disabled={values.list.length <= 0}
              onClick={() => setModalOpen(true)}
          >
            不予換貨
          </Button>
        </div>
        <div
            className={`${style.table_head} p-3 d-flex align-items-center gap-4`}
        >
          <div className="d-flex align-items-center gap-1">
            <input
                className={style.maintain_list_checkbox}
                type="checkbox"
                checked={
                    values.list.length > 0 && values.list.length === flatList.length
                }
                onChange={e => {
                  const val = e.target.checked;
                  setAllHomeDeliveryC(0);
                  setAllHomeDeliveryNumber('');
                  setFieldValue(
                      'list',
                      val
                          ? flatList.map(i => ({
                            ...defaultOrderItemModel,
                            orderItemId: i.id,
                          }))
                          : [],
                  );
                }}
            />{' '}
            本頁全選
          </div>
          <div className="d-flex align-items-center gap-1">
            <label className="text-nowrap">配送商皆為</label>
            <Input
                className="w150 mb-1"
                type="select"
                value={allHomeDeliveryC}
                disabled={
                    values.list?.length <= 0 ||
                    values.list?.length !== flatList.length
                }
                onChange={e => {
                  const val = +e.target.value;
                  setAllHomeDeliveryC(val);
                  setFieldValue(
                      'list',
                      values.list.map(i => ({ ...i, homeDeliveryC: val })),
                  );
                }}
            >
              <option value={0} disabled>
                選擇配送商
              </option>
              {orderOptionsInfo?.homeDeliveryStore?.map(k => (
                  <option value={k.code} key={k.code}>
                    {k.name}
                  </option>
              ))}
            </Input>
          </div>
          <div className="d-flex align-items-center gap-1">
            <label className="text-nowrap">配送編號皆為</label>
            <input
                type="text"
                className="form-control"
                value={allHomeDeliveryNumber}
                disabled={
                    values.list?.length <= 0 ||
                    values.list?.length !== flatList.length
                }
                onChange={e => {
                  const val = e.target.value;
                  setAllHomeDeliveryNumber(val);
                  setFieldValue(
                      'list',
                      values.list.map(i => ({ ...i, homeDeliveryNumber: val })),
                  );
                }}
            />
          </div>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>選擇</th>
              <th>
                <div>訂單編號</div>
                <div>換貨單號</div>
              </th>
              <th>
                <div>取件配送商</div>
                <div>配送編號</div>
              </th>
              <th>
                <div>寄件配送商</div>
                <div>配送編號</div>
              </th>

              <th>
                <div>換貨單狀態</div>
                <div>換貨成立時間</div>
                <div>換貨狀態時間</div>
              </th>
              <th>
                <div>換貨原因</div>
                <div>換貨描述</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>總折扣金額</div>
                <div>總銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>換貨聯絡人</div>
                <div>換貨聯絡人電話</div>
              </th>
              <th>
                <div>換貨國家</div>
                <div>換貨郵遞區號</div>
                <div>換貨城市</div>
                <div>換貨鄉鎮市區</div>
                <div>換貨地址</div>
              </th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td>
                              <input
                                  className={style.maintain_list_checkbox}
                                  type="checkbox"
                                  onChange={e => {
                                    const val = e.target.checked;
                                    val &&
                                    setFieldValue('list', [
                                      ...values.list,
                                      ...i.orderItems.map(j => ({
                                        ...defaultOrderItemModel,
                                        orderItemId: j.id,
                                      })),
                                    ]);
                                    val ||
                                    setFieldValue(
                                        'list',
                                        values.list.filter(
                                            j =>
                                                i.orderItems
                                                    .map(k => k.id)
                                                    .indexOf(j.orderItemId) === -1,
                                        ),
                                    );
                                  }}
                                  checked={i.orderItems
                                      .map(j => j.id)
                                      .every(
                                          j =>
                                              values.list
                                                  .map(k => k.orderItemId)
                                                  .indexOf(j) !== -1,
                                      )}
                              />
                            </td>
                            <td colSpan="2">
                              總金額：
                              <span className="price text-black">
                          {(i.totalAmount || '0').toLocaleString('en-US')}
                        </span>
                            </td>
                            <td colSpan="2">主單編號：{i.orderNumber}</td>
                            <td colSpan="2">
                              運費：
                              <span className="price text-black">
                          {i.deliveryCharge?.toLocaleString('en-US')}
                        </span>
                              {i.deliveryChargeReturnNumber && (
                                  <span>
                            （退運費申請 -
                            <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </span>
                              )}
                            </td>
                            <td colSpan={4} className="text-end">
                              <FaChevronDown id={`orderId${i.id}`} />
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={jIndex}>
                                <td>
                                  <input
                                      className={style.maintain_list_checkbox}
                                      type="checkbox"
                                      checked={values.list.find(
                                          k => k.orderItemId === j.id,
                                      )}
                                      onChange={e => {
                                        const val = e.target.checked;
                                        setFieldValue(
                                            'list',
                                            val
                                                ? [
                                                  ...values.list,
                                                  {
                                                    ...defaultOrderItemModel,
                                                    orderItemId: j.id,
                                                  },
                                                ]
                                                : values.list.filter(
                                                    k => k.orderItemId !== j.id,
                                                ),
                                        );
                                      }}
                                  />
                                </td>
                                <td>
                                  <div
                                      className={`${style.text_dark_info} text-nowrap`}
                                  >
                                    {j.orderItemNumber}
                                  </div>
                                  <div>{j.changeNumber || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.changeHomeDeliveryCName || '--'}</div>
                                  <div>{j.changeHomeDeliveryNumber || '--'}</div>
                                </td>
                                <td>
                                  <Input
                                      className="w150 mb-1"
                                      type="select"
                                      value={
                                          values.list.find(k => k.orderItemId === j.id)
                                              ?.homeDeliveryC || 0
                                      }
                                      onChange={e => {
                                        const val = +e.target.value;
                                        const newArr = values.list.map(k => {
                                          let newObj = { ...k };
                                          if (k.orderItemId === j.id) {
                                            newObj.homeDeliveryC = val;
                                          }
                                          return newObj;
                                        });
                                        setFieldValue('list', newArr);
                                      }}
                                      disabled={
                                        !values.list.find(k => k.orderItemId === j.id)
                                      }
                                  >
                                    <option value={0} disabled>
                                      選擇配送商
                                    </option>
                                    {orderOptionsInfo?.homeDeliveryStore?.map(k => (
                                        <option value={k.code} key={k.code}>
                                          {k.name}
                                        </option>
                                    ))}
                                  </Input>
                                  <input
                                      type="text"
                                      className="form-control"
                                      value={
                                          values.list.find(k => k.orderItemId === j.id)
                                              ?.homeDeliveryNumber || ''
                                      }
                                      onChange={e => {
                                        const val = e.target.value;
                                        const newArr = values.list.map(k => {
                                          let newObj = { ...k };
                                          if (k.orderItemId === j.id) {
                                            newObj.homeDeliveryNumber = val;
                                          }
                                          return newObj;
                                        });
                                        setFieldValue('list', newArr);
                                      }}
                                  />
                                </td>
                                <td>
                                  <div>{j.changeStatusName}</div>
                                  <div>{j.changeCreateTime}</div>
                                  <div>{j.changeStatusTime}</div>
                                </td>
                                <td>
                                  <div>{j.changeReason || '--'}</div>
                                  <div className="text-clamp clamp3">
                                    {j.changeDesc || '--'}
                                  </div>
                                  {j.changeDesc && (
                                      <Button
                                          color="link"
                                          className="text-decoration-none"
                                          size="sm"
                                          onClick={() => {
                                            setCurrentDescInfo({
                                              returnDesc: j.changeDesc,
                                              returnReason: j.changeReason,
                                            });
                                            handleToggleDesc();
                                          }}
                                      >
                                        查看完整描述
                                      </Button>
                                  )}
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>{j.quantity}</div>
                                  <div className="price text-black fw-normal">
                                    {j.unitPrice.toLocaleString('en-US')}
                                  </div>
                                  {/* 折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end text-primary"
                                      role="button"
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    -
                                    <div className="price text-primary fw-normal discountAmount">
                                      {j?.discountAmount?.toLocaleString('en-US') ||
                                          0}
                                    </div>
                                  </div>
                                  <div className="price text-black fw-normal discountPrice">
                                    {j?.discountPrice?.toLocaleString('en-US') || 0}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 有無商品贈品 */}
                                  <div>--</div>
                                  {/* 有無活動贈品 */}
                                  <div>--</div>
                                  {/* 折扣活動總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountActivityModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.activityDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 折價券總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.couponDiscountAmountUnit || 0
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 贈品券活動序號 */}
                                  <div>--</div>
                                </td>
                                <td>
                                  <div className="text-nowrap">
                                    {j.memberPhone || '--'}
                                  </div>
                                  <div>{j.memberLevel || '--'}</div>
                                  <div>{j.changeName || '--'}</div>
                                  <div>{j.changePhone || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.changeCountryName || '--'}</div>
                                  <div>{j.changeZipcode || '--'}</div>
                                  <div>{j.changeCityName || '--'}</div>
                                  <div>{j.changeTownName || '--'}</div>
                                  <div>{j.changeAddress || '--'}</div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}

                {error && <TableHasNoData />}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
        <RejectModal
            open={modalOpen}
            toggle={handleToggleModal}
            ids={values.list.map(i => i.orderItemId)}
            onSubmit={onReject}
        />
        <OrderReturnDescModal
            open={descOpen}
            toggle={handleToggleDesc}
            info={currentDescInfo}
        />
      </Card>
  );
};
const OrderChangeStep3 = () => {
  const dispatch = useDispatch();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});
  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      changeLogisticStatus: 2,
      changeStatus: 2,
      endTime: null,
      isChangeOrder: true,
      keyword: '',
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      startTime: null,
      timeType: '換貨單成立日期',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        isChangeOrder: true,
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const handleAction = async (formModel, cb) => {
    MessageUtil.submitConfirm({
      doneFun: async () => {
        const { code, message } = await request({
          data: formModel.list,
          method: 'post',
          url: '/Backend/OrderChange/LogisticsChangeFinish',
        });
        if (code !== 200) {
          MessageUtil.alertWanring('換貨失敗', message);
          return;
        }
        MessageUtil.toastSuccess('換貨成功！');
        handleSubmit();
        cb();
      },
      title: '確認完成換貨？',
    });
  };

  return (
      <Layout
          pageTitle="換貨處理中-setp3"
          items={[
            { isActive: false, page_name: '換貨訂單管理' },
            { isActive: true, page_name: '換貨處理中-setp3' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            本頁僅出現物流狀態為【取件成功】的訂單
          </span>
          </div>
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            【完成換貨】：即是已把貨物寄出，換貨狀態改為【已換貨】，宅配物流狀態為【已出貨】
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="換貨單成立日期">換貨單成立日期</option>
                    <option value="換貨單狀態日期">換貨單狀態日期</option>
                    <option value="轉單時間">轉單時間</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onAction={handleAction}
            onReject={handleSubmit}
            onChangePage={handleChangePage}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderChangeStep3;
