import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';

import {
  deleteMilestoneById,
  getMilestoneList,
} from '../../redux/actions/data/distributorActions';
import { DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST } from '../../redux/constants/data/distributorConstants';
import {initMilestone} from '../../redux/reducers/data/distributorReducers';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import TableHasNoData, { TableLoading } from '../TableHasNoData';
import TableHead from '../TableHead';

import MilestoneEditModal from './MilestoneEditModal';

const labelStyle = {
  minWidth: '80px',
  paddingRight: '5px',
  whiteSpace: 'nowrap',
};
const MilestoneSettings = () => {
  const [keyword, setKeyword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const milestoneRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { location } = history;

  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    state => state.distributorMileStoneList,
  );

  const onSearch = useCallback((keyword = '', page = 1, page_size = 10) => {
    const params = new URLSearchParams({
      keyword,
      page,
      page_size,
    });
    history.push(
      `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
    );
  }, []);

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlParams);
      setKeyword(params?.keyword || '');
      setPage(params?.page || 1);
      setPageSize(params?.page_size || 10);
      dispatch(
        getMilestoneList({
          ...params,
          keyword: params?.keyword || '',
          page: params?.page || 1,
          page_size: params?.page_size || 10,
        }),
      );
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  return (
    <>
      <MilestoneEditModal
        isOpen={showModal}
        milestone={milestoneRef.current}
        onClose={isUpdate => {
          if (isUpdate) {
            onSearch();
          }
          setShowModal(false);
        }}
      />
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <Row className="flex-wrap">
            <FormGroup
              className="d-inline-flex align-items-center flex-nowrap col-sm-12 col-md-4"
              style={{ minWidth: '300px' }}
            >
              <label style={labelStyle}>關鍵字</label>
              <Input
                className={style['form-select-shop-banner']}
                placeholder="年份、經歷"
                onChange={e => {
                  setKeyword(e.target.value);
                }}
                value={keyword}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
            }}
          >
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            onClick={() => {
              setPage(1);
              onSearch(keyword, 1, pageSize);
            }}
          >
            搜尋
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <Row className="mb-2">
          <div className="col-md-6 d-flex justify-content-start  align-items-center">
            <Button
              color="success"
              onClick={() => {
                milestoneRef.current = initMilestone;
                setShowModal(true);
              }}
            >
              新增
            </Button>
          </div>
          <div className="d-flex justify-content-end  align-items-center col-md-6">
            每頁
            <Input
              type="select"
              name="page_size"
              style={{ margin: '0.3rem', width: '80px' }}
              onChange={event => {
                const value = event.target.value;
                setPage(1);
                setPageSize(value);
                onSearch(keyword, 1, value);
              }}
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </Input>
            {`筆，共${list?.total_count || 0}筆`}
          </div>
        </Row>
        <div className="table-responsive">
          <Table hover striped bordered className="mb-5">
            <TableHead>
              <tr>
                <th width="100">年份</th>
                <th>經歷</th>
                <th width="200">異動時間</th>
                <th width="200">功能</th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {list?.datas?.length ? (
                  list?.datas.map(d => {
                    return (
                      <tr key={JSON.stringify(d)}>
                        <td>{d.year ?? '-'}</td>
                        <td>{d.content ?? '-'}</td>
                        <td align="center">{d.updateTime ?? '-'}</td>
                        <td align="center" nowrap="nowrap">
                          <Button
                            className="m-2"
                            size="sm"
                            color="primary"
                            outline
                            onClick={() => {
                              milestoneRef.current = d;
                              setShowModal(true);
                            }}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-2"
                            size="sm"
                            color="danger"
                            outline
                            onClick={() => {
                              MessageUtil.delConfirm(() => {
                                deleteMilestoneById(d.id).then(() => {
                                  dispatch({
                                    type: DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST,
                                  });
                                  onSearch();
                                });
                              });
                            }}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={list?.current_page || page || 1}
            pageSize={list?.page_size || 50}
            style={{ borderColor: '#446' }}
            total={list?.total_count || 0}
            onChange={page => {
              setPage(page);
              onSearch(keyword, page, pageSize);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default MilestoneSettings;
