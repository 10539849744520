import Layout from 'components/Layout';
import React, { useState } from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';


import { TabItem } from '../components/tabItem';
import HomeSetting from '../components/uuCafeMealntrol/HomeSetting';
import SEOSetting from '../components/uuCafeMealntrol/SEOSetting';

const CafeMealIntroHome = () => {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <Layout
      pageTitle="餐點介紹-首頁設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '餐點介紹-首頁設定' },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="首頁設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="SEO設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <HomeSetting />
        </TabPane>
        <TabPane tabId={2}>
          <SEOSetting />
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default CafeMealIntroHome;
