import { motion } from 'framer-motion';
import React from 'react';
import { useSelector } from 'react-redux';

import style from '../styles/layout.module.css';

import Breadcrumbs from './Breadcrumbs';

const pageTransition = {
  duration: 0.6,
  ease: 'anticipate',
  type: 'tween',
};
const pageVariants = {
  in: {
    opacity: 1,
    pageTransition,
    scale: 1,
  },
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  out: {
    opacity: 0,
    pageTransition,
    scale: 1.01,
  },
};

const Layout = ({ pageTitle, items, children }) => {
  const { sidebarToggleMobile } = useSelector(state => state.sideBar);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.99 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1.01 }}
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className={style.header}
        style={sidebarToggleMobile ? { paddingLeft: '3rem' } : {}}
      >
        <b>{pageTitle}</b>
        <Breadcrumbs pageTitle={pageTitle} items={items} />
      </div>
      <div
        className={style.content}
        style={sidebarToggleMobile ? { paddingLeft: '3rem' } : {}}
      >
        {children}
      </div>
    </motion.div>
  );
};

Layout.defaultProps = {
  items: [],
  pageTitle: '首頁',
};

export default Layout;
