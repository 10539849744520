import AvailableBonusPoints from 'components/memberDetail/AvailableBonusPoints';
import BonusPointRecords from 'components/memberDetail/BonusPointRecords';
import PersonalCart from 'components/memberDetail/PersonalCart';
import ServiceRemarkModal from 'components/memberDetail/ServiceRemarkModal';
import ShoppingRecords from 'components/memberDetail/ShoppingRecords';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import {
  getAreaList,
  getCityList,
  getCountryList,
} from 'redux/actions/data/locationActions';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import MemberPresentRecords from '../components/memberDetail/MemberPresentRecords';
import RepairRegistersRecords from '../components/memberDetail/RepairRegistersRecords';
import VoucherListRecords from '../components/memberDetail/VoucherListRecords';
import WarrantyRegistersRecords from '../components/memberDetail/WarrantyRegistersRecords';
import {
  getMemberInfo,
  updateMemberInfo,
} from '../redux/actions/data/memberActions';
import { MEMBER_INFO_UPDATE_RESET } from '../redux/constants/data/memberConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import useQuery from '../utils/useQuery';
const EditMemberInfoModal = ({ isOpen, toggle, info, onRefresh }) => {
  const dispatch = useDispatch();
  const { countryList, loading: countryListLoading } = useSelector(
    state => state.getCountryList,
  );
  const { cityList, loading: cityListLoading } = useSelector(
    state => state.getCityList,
  );
  const { areaList, loading: areaListLoading } = useSelector(
    state => state.getAreaList,
  );

  const { loading, success, error } = useSelector(
    state => state.memberInfoUpdate,
  );

  const countryOptions = countryList?.map(country => {
    return { label: country.name, value: country.id };
  });
  const cityListOptions = cityList?.map(city => {
    return { label: city.name, value: city.id };
  });
  const areaListOptions = areaList?.map(area => {
    return { label: area.name, value: area.id };
  });

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: info?.address,
      birthday: info?.birthday,
      city: cityListOptions?.find(
        city => Number(city.value) === Number(info?.cityId),
      ) || { label: '請選擇', value: '' },
      country: countryOptions?.find(
        country => Number(country.value) === Number(info?.countryId),
      ) || { label: '請選擇', value: '' },
      email: info?.email || '',
      firstName: info?.firstName || '',
      gender: info?.gender,
      isSubscribed: info?.isSubscribed,
      lastName: info?.lastName || '',
      phone: info?.phone,
      town: areaListOptions?.find(
        area => Number(area.value) === Number(info?.townId),
      ) || { label: '請選擇', value: '' },
      zipcode: info?.zipCode,
    },
    onSubmit: () => {
      const body = {
        address: values.address,
        birthday: values.birthday
          ? moment(values.birthday).format('yyyy/MM/DD')
          : null,
        cityId: values.city.value || 0,
        countryId: values.country.value || 0,
        email: values.email,
        firstName: values.firstName,
        gender: values.gender ? Number(values.gender) : null,
        id: info.id,
        isSubscribed: values.isSubscribed,
        lastName: values.lastName,
        townId: values.town.value || 0,
        zipcode: values.zipcode,
      };
      dispatch(updateMemberInfo(body));
    },
  });

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  useEffect(() => {
    values.country.value && dispatch(getCityList(values.country.value));
  }, [values.country.value]);

  useEffect(() => {
    values.city.value && dispatch(getAreaList(values.city.value));
  }, [values.city.value]);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: MEMBER_INFO_UPDATE_RESET });
      toggle();
      onRefresh && onRefresh();
    }

    if (error) {
      MessageUtil.alertWanring(error);
      dispatch({ type: MEMBER_INFO_UPDATE_RESET });
    }
  }, [success, error]);

  return (
    <Modal
      zIndex={2000}
      size="xl"
      centered
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        編輯會員資料
      </ModalHeader>
      <ModalBody>
        <div style={{ padding: '1rem' }}>
          <div className={style.formGroup}>
            <label className={style['rwd-w100']}>姓名</label>
            <label>姓</label>
            <Input
              className={style['form-input']}
              value={values.lastName}
              onChange={event => {
                setFieldValue('lastName', event.target.value);
              }}
            />
            <div className="w80" />
            <label>名</label>
            <Input
              className={style['form-input']}
              value={values.firstName}
              onChange={event => {
                setFieldValue('firstName', event.target.value);
              }}
            />
          </div>
          <div className={style.formGroup}>
            <label className="w100">會員生日</label>
            <div>
              <DatePicker
                dateFormat={'yyyy/MM/dd'}
                className={`${style['form-select']} form-control`}
                selected={
                  values.birthday ? moment(values.birthday).toDate() : ''
                }
                onChange={date => setFieldValue('birthday', date)}
              />
            </div>
          </div>
          <div className={style.formGroup}>
            <label className="w100">會員性別</label>
            <Input
              type="select"
              className={style['form-select-short']}
              style={{ margin: 0 }}
              value={values.gender}
              onChange={event => {
                setFieldValue('gender', event.target.value);
              }}
            >
              <option value={''}>全部</option>
              <option value={1}>男</option>
              <option value={2}>女</option>
            </Input>
          </div>
          <div className={style.formGroup}>
            <label className="w100">電子郵件</label>
            <Input
              className={style['form-input-email']}
              value={values.email}
              onChange={event => {
                setFieldValue('email', event.target.value);
              }}
            />
          </div>
          <div className={style.formGroup}>
            <label className={style['rwd-w100']}>地址</label>
            <label>國家</label>
            <Select
              isLoading={countryListLoading}
              isDisabled={countryListLoading}
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={countryOptions}
              value={values.country}
              onChange={option => {
                setFieldValue('country', option);
                setFieldValue('city', { label: '請選擇', value: '' });
                setFieldValue('town', { label: '請選擇', value: '' });
              }}
            />
            <div style={{ width: '30px' }} />
            <label>城市</label>
            <Select
              isLoading={cityListLoading}
              isDisabled={cityListLoading}
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={cityListOptions}
              value={values.city}
              onChange={option => {
                setFieldValue('city', option);
                setFieldValue('town', { label: '請選擇', value: '' });
              }}
            />
            <div style={{ width: '30px' }} />
            <label>區域</label>
            <Select
              isLoading={areaListLoading}
              isDisabled={areaListLoading}
              className={style['form-select-short']}
              style={{ margin: 0 }}
              options={areaListOptions}
              value={values.town}
              onChange={option => {
                setFieldValue('town', option);
                setFieldValue(
                  'zipcode',
                  areaList.find(area => area.id === option.value)?.zipcode,
                );
              }}
            />
            <div style={{ width: '60px' }} />
            <label>郵遞區號</label>
            <label>{values.zipcode}</label>
          </div>
          <div className={style.formGroup}>
            <label className={style['rwd-w100']} />
            <label>詳細地址</label>
            <Input
              className={style['form-input-email']}
              value={values.address}
              onChange={event => {
                setFieldValue('address', event.target.value);
              }}
            />
          </div>
          <div className={style.formGroup}>
            <label className={style['rwd-w100']}>訂閱電子報</label>
            <input
              type="checkbox"
              style={{ cursor: 'pointer', height: '20px', width: '20px' }}
              checked={values.isSubscribed === true}
              onChange={event => {
                setFieldValue('isSubscribed', true);
              }}
            />
            <span className="ml-2">是</span>
            <div className="w50"></div>
            <input
              type="checkbox"
              style={{ cursor: 'pointer', height: '20px', width: '20px' }}
              checked={values.isSubscribed === false}
              onChange={event => {
                setFieldValue('isSubscribed', false);
              }}
            />
            <span className="ml-2">否</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        } ${tab === 4 ? style.tab_button_large : ''}`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

const MemberDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { loading, memberInfo } = useSelector(state => state.memberInfo);

  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      bonusRecordsType: 0,
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(getMemberInfo(id));
  }, []);

  const [currentTab, setCurrentTab] = useState(1);
  const [isEditMemberInfoModalOpen, setIsEditMemberInfoModal] = useState(false);
  const toggleEditMemberInfoModal = () => {
    setIsEditMemberInfoModal(!isEditMemberInfoModalOpen);
  };

  useEffect(() => {
    if (memberInfo) {
      if (JSON.stringify(memberInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: memberInfo });
      }
    }
  }, [memberInfo]);

  const [currentOrderPage, setCurrentOrderPage] = useState(1);

  const orderListChangePageHandler = page => {
    setCurrentOrderPage(page);
  };

  const [isEditRemarkModalOpen, setIsEditRemarkModalOpen] = useState(false);
  const toggleEditRemarkModal = () => {
    setIsEditRemarkModalOpen(!isEditRemarkModalOpen);
  };

  const [currentCartPage, setCurrentCartPage] = useState(1);
  const cartListChangePageHandler = page => {
    setCurrentCartPage(page);
  };

  const query = useQuery();

  const isClearInfo = useMemo(() => {
    return query.value.type === 'isClearInfo';
  }, [query]);

  return (
    <Layout
      pageTitle="會員詳細資料"
      items={[
        { isActive: false, page_name: '會員清單', to: '/MemberList' },
        { isActive: true, page_name: '會員資料' },
      ]}
    >
      {memberInfo && (
        <Row xs="1" sm="2">
          <Col>
            <Card className={style.search_card} style={{ margin: '1rem' }}>
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#406DB2' }}
              >
                基本資料
              </div>
              <div className={style.card_body}>
                <div
                  className="fw-bold"
                  style={{ fontSize: '28px', marginBottom: '1rem' }}
                >
                  {`${memberInfo.lastName || '--'}${
                    memberInfo.firstName || '--'
                  }`}
                </div>
                <div className={style.formGroup}>
                  <label className="w100">手機號碼</label>
                  <span>
                    {memberInfo.countryCode === '886'
                      ? `0${memberInfo.phone}`
                      : memberInfo.phone}
                  </span>
                </div>
                <div className={style.formGroup}>
                  <label className="w100">黑名單</label>
                  <span>否</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w100">紅利點數</label>
                  <span>{memberInfo.point?.toLocaleString('en-US')}</span>
                </div>
              </div>
            </Card>
            <Card className={style.search_card} style={{ margin: '1rem' }}>
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#406DB2' }}
              >
                客服備註
              </div>
              <div className={style.card_body}>
                {!isClearInfo && (
                  <div
                    style={{
                      color: '#0077ff',
                      cursor: 'pointer',
                      fontSize: '14px',
                      marginBottom: '1rem',
                    }}
                    onClick={toggleEditRemarkModal}
                  >
                    編輯內容
                  </div>
                )}
                <div>{memberInfo?.csRemark || '--'}</div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card
              className={style.search_card}
              style={{ height: '95%', margin: '1rem' }}
            >
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#406DB2' }}
              >
                消費資訊
              </div>
              <div className={style.card_body}>
                <div className={style.formGroup}>
                  <label className="w150">會員等級</label>
                  <span>{memberInfo.memberLevel}</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">會員等級起始日</label>
                  <span>{memberInfo.memberDateStart}</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">會員等級到期日</label>
                  <span>{memberInfo.memberDateEnd || '--'}</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">級別累積金額</label>
                  <span className="price fw-normal text-black">
                    {memberInfo.accumulateAmount.toLocaleString('en-US')}
                  </span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">歷史總消費金額</label>
                  <span className="price fw-normal text-black">{`${memberInfo.accumulateAmountTotal.toLocaleString(
                    'en-US',
                  )}`}</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">最後消費日期</label>
                  <span>{memberInfo.lastOrderDate || '--'}</span>
                </div>
                <div className={style.formGroup}>
                  <label className="w150">總消費次數</label>
                  <span>{`${memberInfo.orderTimes}次`}</span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      )}
      <div style={{ margin: '3rem' }} />
      <div style={{ padding: '1rem' }}>
        <Nav pills className="mb-2">
          <TabItem
            onClick={setCurrentTab}
            tab={1}
            text="會員資料"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={2}
            text="紅利點數紀錄"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={3}
            text="消費紀錄"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={4}
            text="購物車內商品(未結帳)"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={5}
            text="優惠卷紀錄"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={6}
            text="會員禮"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={7}
            text="保固紀錄"
            currentTab={currentTab}
          />
          <TabItem
            onClick={setCurrentTab}
            tab={8}
            text="維修紀錄"
            currentTab={currentTab}
          />
        </Nav>
        <TabContent activeTab={currentTab}>
          <TabPane tabId={1}>
            <Card className={style.card}>
              <div className={style.formGroup}>
                <label className="w200">姓名</label>
                <div>{`${memberInfo?.lastName || '--'}${
                  memberInfo?.firstName || '--'
                }`}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">會員生日</label>
                <div>{memberInfo?.birthday || '--'}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">會員性別</label>
                <div>
                  {Number(memberInfo?.gender) === 1
                    ? '男'
                    : Number(memberInfo?.gender) === 2
                    ? '女'
                    : '不透漏'}
                </div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">電子郵件</label>
                <div>{memberInfo?.email || '--'}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">地址</label>
                <div>{`${memberInfo?.countryName || '--'}${
                  memberInfo?.cityName || '--'
                }${memberInfo?.townName || '--'}${
                  memberInfo?.address || '--'
                }`}</div>
              </div>
              <hr />
              <div className={style.formGroup}>
                <label className="w200">註冊日期</label>
                <div>{memberInfo?.registerDate}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">Gmail連動</label>
                <div>{memberInfo?.gmailId || '-'}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">Fackbook連動</label>
                <div>{memberInfo?.facebookId || '-'}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">Line連動</label>
                <div>{memberInfo?.lineId || '-'}</div>
              </div>
              <div className={style.formGroup}>
                <label className="w200">最後填寫會員時間</label>
                <div>{memberInfo?.updateTime}</div>
              </div>
              <div style={{ margin: '2rem' }} />
              {!isClearInfo && (
                <Button
                  color="primary"
                  className={`${style.modal_button_submit} w150`}
                  onClick={toggleEditMemberInfoModal}
                >
                  編輯會員資料
                </Button>
              )}
            </Card>
          </TabPane>
          <TabPane tabId={2}>
            <Card className={style.card}>
              <div className={style.formGroup}>
                <label className={style['rwd-w100']}>請選擇內容</label>
                <div className={style.show_flex}>
                  <input
                    type="radio"
                    checked={values.bonusRecordsType === 0}
                    onChange={event => {
                      event.target.checked &&
                        setFieldValue('bonusRecordsType', 0);
                    }}
                  />
                  <span className="ml-2 w100">點數紀錄</span>
                </div>
                <div className={style.show_flex}>
                  <input
                    type="radio"
                    checked={values.bonusRecordsType === 1}
                    onChange={event => {
                      event.target.checked &&
                        setFieldValue('bonusRecordsType', 1);
                    }}
                  />
                  <span className="ml-2">可用點數</span>
                </div>
              </div>
              {values.bonusRecordsType === 0 ? (
                <BonusPointRecords datas={memberInfo?.pointRecordList} />
              ) : (
                <AvailableBonusPoints datas={memberInfo?.pointList} />
              )}
            </Card>
          </TabPane>
          <TabPane tabId={3}>
            <Card className={style.card}>
              <div>
                以下僅列出近90天的消費紀錄，欲查看完整消費紀錄，請前往{' '}
                <span
                  style={{
                    color: '#0077ff',
                    cursor: 'pointer',
                  }}
                >
                  訂單查詢
                </span>
              </div>
              <div className="d-flex justify-content-end">
                <span className="mx-1">{`共${memberInfo?.orderList?.length}筆`}</span>
              </div>
              <ShoppingRecords
                orderList={memberInfo?.orderList}
                currentPage={currentOrderPage}
              />
              {memberInfo?.orderList?.length > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <RcPagination
                    defaultCurrent={currentOrderPage}
                    defaultPageSize={10}
                    total={memberInfo?.orderList?.length}
                    onChange={orderListChangePageHandler}
                  />
                </div>
              )}
            </Card>
          </TabPane>
          <TabPane tabId={4}>
            <Card className={style.card}>
              <div className="d-flex justify-content-end">
                <span className="mx-1">{`共${memberInfo?.cartList?.length}筆`}</span>
              </div>
              <PersonalCart
                cartList={memberInfo?.cartList}
                currentPage={currentCartPage}
              />
              {memberInfo?.cartList?.length > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <RcPagination
                    defaultCurrent={currentCartPage}
                    defaultPageSize={10}
                    total={memberInfo?.cartList?.length}
                    onChange={cartListChangePageHandler}
                  />
                </div>
              )}
            </Card>
          </TabPane>
          <TabPane tabId={5}>
            <VoucherListRecords
              list={memberInfo?.couponList}
              loading={loading}
            />
          </TabPane>
          <TabPane tabId={6}>
            <MemberPresentRecords datas={memberInfo?.membershipGiftGetInfo} />
          </TabPane>
          <TabPane tabId={7}>
            <WarrantyRegistersRecords memberId={id} />
          </TabPane>
          <TabPane tabId={8}>
            <RepairRegistersRecords memberId={id} />
          </TabPane>
        </TabContent>
      </div>
      {isEditMemberInfoModalOpen && memberInfo && (
        <EditMemberInfoModal
          isOpen={isEditMemberInfoModalOpen}
          toggle={toggleEditMemberInfoModal}
          info={memberInfo}
          onRefresh={() => {
            dispatch(getMemberInfo(id));
          }}
        />
      )}
      {isEditRemarkModalOpen && memberInfo && (
        <ServiceRemarkModal
          isOpen={isEditRemarkModalOpen}
          toggle={toggleEditRemarkModal}
          info={{ id: memberInfo.id, remark: memberInfo.csRemark }}
          onRefresh={() => {
            dispatch(getMemberInfo(id));
          }}
        />
      )}
    </Layout>
  );
};

export default MemberDetails;
