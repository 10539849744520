import RcPagination from 'rc-pagination';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Card,
  Button,
  Table,
  CardHeader,
  CardFooter,
  CardBody,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Loader from '../../components/Loader';
import { getCafeMealIntroOther } from '../../redux/actions/data/uuCafeMealOtherAction';
import {
  deleteCafeOtherProductDetail,
  getCafeOtherProductList,
} from '../../redux/actions/data/uuCafeOtherProductAction';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';



import OtherProductModel from './OtherProductModel';
import SortOtherProductModel from './SortOtherProductModel';


const OtherProducts = ({ menuId }) => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(
    state => state.cafeMealOtherProductList,
  );

  const { loading: menuLoading, detail: menu } = useSelector(
    state => state.cafeMealInfoOther,
  );

  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = useCallback(
    (value, keyword, status) => {
      dispatch(
        getCafeOtherProductList({
          keyword,
          menuId,
          page: value,
          page_size: list.page_size || 10,
          status,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, list, menuId],
  );

  const handleDeleteShop = useCallback(
    async product => {
      try {
        await deleteCafeOtherProductDetail({
          id: product.id,
        });
        handlePageChange(1, keyword, status);
        MessageUtil.toastSuccess('刪除成功!');
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [status, keyword, handlePageChange],
  );

  useEffect(() => {
    dispatch(
      getCafeMealIntroOther({
        id: menuId,
      }),
    );
  }, [menuId]);

  useEffect(() => {
    handlePageChange(1, keyword, status);
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const [sortModalShow, setSortModalShow] = useState(false);

  const detail = useRef({
    content: '',
    content_en: '',
    id: 0,
    imgFile: '',
    imgUrl: '',
    menuId,
    name: '',
    name_en: '',
  });

  return (
    <>
      {modalShow && (
        <OtherProductModel
          menuId={menuId}
          detail={detail.current}
          openModal={modalShow}
          templateType={menu.templateType}
          setOpenModel={(show, isReload) => {
            if (isReload) {
              handlePageChange(1);
            }
            setModalShow(show);
          }}
        />
      )}
      {sortModalShow && (
        <SortOtherProductModel
          menuId={menuId}
          setOpenModel={show => {
            handlePageChange(1);
            setSortModalShow(show);
          }}
          openModal={sortModalShow}
        />
      )}
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">關鍵字:</label>
              <Input
                className="flex-1"
                placeholder="輸入關鍵字"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">狀態:</label>
              <Select
                className="flex-1"
                placeholder="請選擇狀態"
                options={[
                  {
                    label: '全部',
                    value: 0,
                  },
                  {
                    label: '上架',
                    value: 1,
                  },
                  {
                    label: '下架',
                    value: 2,
                  },
                ]}
                value={status}
                onChange={selectedOption => {
                  setStatus(selectedOption);
                }}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
              setStatus(0);
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword, status);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  detail.current = {
                    content: '',
                    content_en: '',
                    id: 0,
                    imgFile: '',
                    imgUrl: '',
                    menuId,
                    name: '',
                    name_en: '',
                  };
                  setModalShow(true);
                }}
              >
                新增
              </Button>
              <Button
                color="warning"
                onClick={() => {
                  setSortModalShow(true);
                }}
              >
                排序
              </Button>
            </div>
            <p className="small text-end mb-0">共 {list.total_count} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              {!menuLoading && menu && (
                <Table hover bordered striped>
                  <thead className={`thead-dark ${style.table_head}`}>
                    <tr>
                      <th nowrap="nowrap">商品名稱</th>
                      <th nowrap="nowrap">內容</th>
                      {(menu.templateType == 1 || menu.templateType == 2) && (
                        <th nowrap="nowrap">圖片</th>
                      )}
                      <th nowrap="nowrap">狀態</th>
                      <th style={{ minWidth: '120px' }}>異動日期</th>
                      <th>功能</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.datas.map((data, index) => (
                      <tr key={index}>
                        <td nowrap="nowrap">{data.name}</td>
                        <td>
                          <div
                            className="line-clamp"
                            style={{
                              height: '3rem',
                            }}
                          >
                            {data.content}
                          </div>
                        </td>

                        {(menu.templateType == 1 || menu.templateType == 2) && (
                          <td nowrap="nowrap">
                            <img
                              className={style.w200}
                              src={data.imgUrl}
                              alt={data.imgFile}
                            />
                          </td>
                        )}
                        <td nowrap="nowrap">
                          {data.status === 1 && '上架'}
                          {data.status === 2 && '下架'}
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {data.updateDateTime}
                          </div>
                          <div>{data.updater}</div>
                        </td>
                        <td nowrap="nowrap" className="text-center">
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => {
                              detail.current = data;
                              setModalShow(true);
                            }}
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-1"
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => {
                              MessageUtil.submitConfirm({
                                doneFun: () => {
                                  handleDeleteShop(data);
                                },
                                title: '確認是否刪除',
                              });
                            }}
                          >
                            刪除
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={list.page_size || 10}
                total={list.total_count || 1}
                onChange={value => {
                  handlePageChange(value, keyword, status);
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </>
  );
};

export default memo(OtherProducts);
