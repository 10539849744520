import moment from 'moment/moment';
import { memo, useMemo } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import history from '../../utils/createHistory';
import useQuery from '../../utils/useQuery';

const SortButton = ({ currentOrder, onSearch, children }) => {
  const query = useQuery();
  const location = useLocation();

  const { order, order_by } = useMemo(() => {
    return query?.value;
  }, [location?.search]);

  const orderChangeHandler = (order_by = '', order = 'asc') => {
    const values = {
      ...query.value,
      endTime: query?.value?.endTime
        ? moment(query?.value?.endTime).toDate()
        : '',
      order,
      order_by,
      salesChannel: JSON.parse(
        query?.value?.salesChannel ||
          JSON.stringify([{ label: 'online', value: '' }]),
      ),
      startTime: query?.value?.startTime
        ? moment(query?.value?.startTime).toDate()
        : '',
    };
    const params = new URLSearchParams({
      ...values,
      salesChannel: JSON.stringify(
        values?.salesChannel || [{ label: 'online', value: '' }],
      ),
    });
    history.push(`${location.pathname}?${params.toString()}`);

    onSearch({
      couponType: values?.couponType || undefined,
      endTime: values?.endTime || undefined,
      getWay: values?.getWay || undefined,
      keywords: values?.keyword ?? undefined,
      order: values?.order || 'asc',
      order_by: values?.order_by || '',
      page: values?.page,
      page_size: 10,
      queryDateType: values?.queryDateType ?? undefined,
      salesChannel:
        values?.salesChannel
          .map(s => s.value)
          .filter(s => s)
          .join(',') || '',
      startTime: values?.startTime || undefined,
    });
  };

  return (
    <div className="text-nowrap">
      {children}
      <FaArrowDown
        color={order_by === currentOrder && order === 'asc' ? '' : 'grey'}
        size="18"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          orderChangeHandler(currentOrder, 'asc');
        }}
      />
      <FaArrowUp
        color={order_by === currentOrder && order === 'desc' ? '' : 'grey'}
        size="18"
        style={{ cursor: 'pointer' }}
        onClick={() => {
          orderChangeHandler(currentOrder, 'desc');
        }}
      />
    </div>
  );
};

export default memo(SortButton);
