export const GET_POINT_EVENT_SETTINGS_REQUEST =
  'GET_POINT_EVENT_SETTINGS_REQUEST';
export const GET_POINT_EVENT_SETTINGS_SUCCESS =
  'GET_POINT_EVENT_SETTINGS_SUCCESS';
export const GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST =
  'GET_POINT_EVENT_SETTINGS_UPDATE_REQUEST';
export const GET_POINT_EVENT_SETTINGS_FAIL = 'GET_POINT_EVENT_SETTINGS_FAIL';

export const GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST';
export const GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS';
export const GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL';

export const GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST';
export const GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS';
export const GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL =
  'GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL';
