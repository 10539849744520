export const UUCAFE_PRODUCT_LIST_REQUEST = 'UUCAFE_PRODUCT_LIST_REQUEST';

export const UUCAFE_PRODUCT_LIST_SUCCESS = 'UUCAFE_PRODUCT_LIST_SUCCESS';
export const UUCAFE_PRODUCT_LIST_FAIL = 'UUCAFE_PRODUCT_LIST_FAIL';
export const UUCAFE_PRODUCT_UPDATE_REQUEST = 'UUCAFE_PRODUCT_UPDATE_REQUEST';
export const UUCAFE_PRODUCT_UPDATE_SUCCESS = 'UUCAFE_PRODUCT_UPDATE_SUCCESS';
export const UUCAFE_PRODUCT_UPDATE_FAIL = 'UUCAFE_PRODUCT_UPDATE_FAIL';
export const UUCAFE_PRODUCT_SUCCESS = 'UUCAFE_PRODUCT_SUCCESS';
export const UUCAFE_PRODUCT_REQUEST = 'UUCAFE_PRODUCT_REQUEST';
export const UUCAFE_PRODUCT_FAIL = 'UUCAFE_PRODUCT_FAIL';
export const UUCAFE_PRODUCT_ADD_SUCCESS = 'UUCAFE_PRODUCT_ADD_SUCCESS';

export const UUCAFE_PRODUCT_ADD_REQUEST = 'UUCAFE_PRODUCT_ADD_REQUEST';
export const UUCAFE_PRODUCT_ADD_FAIL = 'UUCAFE_PRODUCT_ADD_FAIL';
