import {
  UUCAFE_MEALINTRO_DETAIL_FAIL,
  UUCAFE_MEALINTRO_DETAIL_REQUEST,
  UUCAFE_MEALINTRO_DETAIL_SUCCESS,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_MENU_ADD_FAIL,
  UUCAFE_MEALINTRO_MENU_ADD_REQUEST,
  UUCAFE_MEALINTRO_MENU_ADD_SUCCESS,
  UUCAFE_MEALINTRO_MENU_FAIL,
  UUCAFE_MEALINTRO_MENU_LIST_FAIL,
  UUCAFE_MEALINTRO_MENU_LIST_REQUEST,
  UUCAFE_MEALINTRO_MENU_LIST_SUCCESS,
  UUCAFE_MEALINTRO_MENU_REQUEST,
  UUCAFE_MEALINTRO_MENU_SUCCESS,
  UUCAFE_MEALINTRO_MENU_UPDATE_FAIL,
  UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_SEO_FAIL,
  UUCAFE_MEALINTRO_SEO_REQUEST,
  UUCAFE_MEALINTRO_SEO_SUCCESS,
  UUCAFE_MEALINTRO_SEO_UPDATE_FAIL,
  UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_FAIL,
  UUCAFE_MEALINTRO_ZONE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SAVE_FAIL,
  UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SEO_FAIL,
  UUCAFE_MEALINTRO_ZONE_SEO_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST,
  UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS,
  UUCAFE_MEALINTRO_ZONE_SUCCESS,
} from '../../constants/data/uuCafeMealIntroConstants';

// ============ MEALINTRO ==================
export const cafeMealInfoReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_DETAIL_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          title: '',
          title_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_DETAIL_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_DETAIL_FAIL:
      return { ...state, error: payload || true, loading: false };

    case UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_SEO ==================

export const cafeMealInfoSEOReducer = (
  state = {
    detail: {
      desc: '',
      desc_en: '',
      keyword: '',
      keyword_en: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_SEO_REQUEST:
      return {
        detail: {
          desc: '',
          desc_en: '',
          keyword: '',
          keyword_en: '',
          title: '',
          title_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_SEO_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_SEO_FAIL:
      return { ...state, error: payload || true, loading: false };

    case UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_SEO_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_Menu ==================

export const cafeMealInfoMenuListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUCAFE_MEALINTRO_MENU_LIST_REQUEST:
      return {
        error: null,
        list: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_MENU_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_MENU_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeMealInfoMenuReducer = (
  state = {
    detail: {
      name: '',
      prdCount: 0,
      sort: 0,
      status: 0,
      templateType: 1,
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_MENU_REQUEST:
      return {
        detail: {
          name: '',
          prdCount: 0,
          sort: 0,
          status: 0,
          templateType: 1,
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_MENU_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_MENU_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MEALINTRO_MENU_ADD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_MENU_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_MENU_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_MENU_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_Cafe_Zone ==================

export const cafeMealInfoZoneReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      introImgBanner: '',
      introImgBannerUrl: '',
      introImgBannerUrl_mobile: '',
      introImgBanner_mobile: '',
      title: '',
      title_en: '',
      updateDateTime: '',
      updater: '',
      zoneImgBanner: '',
      zoneImgBannerUrl: '',
      zoneImgBannerUrl_mobile: '',
      zoneImgBanner_mobile: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_ZONE_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          introImgBanner: '',
          introImgBannerUrl: '',
          introImgBannerUrl_mobile: '',
          introImgBanner_mobile: '',
          title: '',
          title_en: '',
          updateDateTime: '',
          updater: '',
          zoneImgBanner: '',
          zoneImgBannerUrl: '',
          zoneImgBannerUrl_mobile: '',
          zoneImgBanner_mobile: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_ZONE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_ZONE_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_ZONE_SAVE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO_Cafe_Zone_SEO ==================

export const cafeMealInfoZoneSEOReducer = (
  state = {
    detail: {
      desc: '',
      desc_en: '',
      keyword: '',
      keyword_en: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_ZONE_SEO_REQUEST:
      return {
        detail: {
          desc: '',
          desc_en: '',
          keyword: '',
          keyword_en: '',
          title: '',
          title_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_ZONE_SEO_FAIL:
      return { ...state, error: payload || true, loading: false };

    case UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
