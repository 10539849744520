import {
  HIDE_MODEL_LIST_REQUEST,
  HIDE_MODEL_LIST_SUCCESS,
  HIDE_MODEL_LIST_FAIL,
  HIDE_MODEL_GET_REQUEST,
  HIDE_MODEL_GET_SUCCESS,
  HIDE_MODEL_GET_FAIL,
} from 'redux/constants/data/hideModelConstants';
import { useRequest } from 'utils/useFetch';

export const getHideModelList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: HIDE_MODEL_LIST_FAIL,
      method: 'post',
      requestConstant: HIDE_MODEL_LIST_REQUEST,
      successConstant: HIDE_MODEL_LIST_SUCCESS,
      url: '/Backend/HideModel/list',
    });
  };
};
export const getHideModelDetail = id => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: HIDE_MODEL_GET_FAIL,
      method: 'get',
      requestConstant: HIDE_MODEL_GET_REQUEST,
      successConstant: HIDE_MODEL_GET_SUCCESS,
      url: `/Backend/HideModel/Get/${  id}`,
    });
  };
};
