import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input } from 'reactstrap';
import {
  getRewardsPointsSettings,
  updateRewardsPointsRules,
} from 'redux/actions/data/rewardsPointsActions';
import { UPDATE_REWARDS_POINTS_RULES_IDLE } from 'redux/constants/data/rewardsPointsConstants';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';

const RulesSettings = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.getRewardsPointsSettings);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateRewardsPointsRules);
  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      expireMonth: settings?.giveSetting?.expireMonth || 1,
      expireYear: settings?.giveSetting?.expireYear || 0,
      memberLevelSettings: settings?.giveSetting?.memberLevelSettings || [],
    },
    onSubmit: () => {
      const body = {
        expireMonth: values.expireMonth,
        expireYear: values.expireYear,
        memberLevelSettings: values.memberLevelSettings.map(setting => ({
          isTherePointRewards: setting.isTherePointRewards,
          memberLevelSettingId: setting.memberLevelSettingId,
          pointRewardsPercentage: setting.pointRewardsPercentage,
        })),
      };
      dispatch(updateRewardsPointsRules(body));
    },
  });

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_REWARDS_POINTS_RULES_IDLE });
      dispatch(getRewardsPointsSettings());
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: UPDATE_REWARDS_POINTS_RULES_IDLE });
    }
  }, [updateSuccess, updateError]);

  return (
    <Card className={style.card}>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w100">給點設定</label>
        <div>
          {values.memberLevelSettings.map((option, index) => (
            <div
              key={option.memberLevelSettingId}
              className={`${style['radio-btn-layout']} mb-3`}
            >
              <input
                type="checkbox"
                className={style['radio-btn']}
                checked={option.isTherePointRewards === true}
                onChange={e => {
                  const checked = e.target.checked;
                  checked
                    ? setFieldValue(
                        `memberLevelSettings[${index}].isTherePointRewards`,
                        true,
                      )
                    : setFieldValue(
                        `memberLevelSettings[${index}].isTherePointRewards`,
                        false,
                      );
                }}
              />
              <label className="ml-2">{`${option.memberLevelSettingName}可享回饋`}</label>
              <Input
                type="number"
                className={style['form-input']}
                placeholder="請輸入數字"
                id="pointRewardsPercentage"
                value={option.pointRewardsPercentage}
                onChange={e => {
                  setFieldValue(
                    `memberLevelSettings[${index}].pointRewardsPercentage`,
                    e.target.value,
                  );
                }}
              />
              <span className="ml-2">%</span>
            </div>
          ))}
        </div>
      </div>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w100">給點效期</label>
        <div>
          <small>
            <li>
              點數到期日固定為月底的 23:59 到期，若選擇當年 12 月即 2021/12/31
              23:59 到期
            </li>
          </small>
          <small>
            <li>
              若點數生效月份大於給點日期，將自動延展至明年。例如：給點到期日設定為當年
              3 月，若消費者為 2021/6/6 獲得點數，則自動延展至 2022/3/31 23:59
              到期
            </li>
          </small>
          <div className={style.formGroup}>
            <label>點數到期日設定為</label>
            <Input
              type="select"
              className={style['form-select-short']}
              id="expireYear"
              onChange={handleChange}
              value={values.expireYear}
            >
              <option value="0">當年</option>
              <option value="1">明年</option>
              <option value="2">後年</option>
            </Input>
            <Input
              type="select"
              className={style['form-select-short']}
              id="expireMonth"
              onChange={handleChange}
              value={values.expireMonth}
            >
              <option value={'1'}>一月</option>
              <option value={'2'}>二月</option>
              <option value={'3'}>三月</option>
              <option value={'4'}>四月</option>
              <option value={'5'}>五月</option>
              <option value={'6'}>六月</option>
              <option value={'7'}>七月</option>
              <option value={'8'}>八月</option>
              <option value={'9'}>九月</option>
              <option value={'10'}>十月</option>
              <option value={'11'}>十一月</option>
              <option value={'12'}>十二月</option>
            </Input>
          </div>
        </div>
      </div>
      <div>
        {`異動時間：${settings?.giveSetting?.updateTime || '--'} ${
          settings?.giveSetting?.updater || '--'
        }`}
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            className={style.modal_button_submit}
            disabled={updateLoading}
            onClick={handleSubmit}
          >
            {updateLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default RulesSettings;
