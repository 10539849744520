import {
  CART_REQUEST,
  CART_SUCCESS,
  CART_UPDATE_SUCCESS,
  CART_FAIL,
  CART_RESET,
  PURCHASE_NOTE_REQUEST,
  PURCHASE_NOTE_SUCCESS,
  PURCHASE_NOTE_FAIL,
  PURCHASE_NOTE_UPDATE_REQUEST,
  PURCHASE_NOTE_UPDATE_SUCCESS,
  PURCHASE_NOTE_UPDATE_FAIL,
  PURCHASE_NOTE_UPDATE_RESET,
} from 'redux/constants/data/cartConstants';

export const cartReducer = (
  state = {
    cartInfo: [],
    error: null,
    loading: true,
    success: false,
    updateSuccess: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CART_REQUEST:
      return { ...state, loading: true };
    case CART_SUCCESS:
      return { cartInfo: payload, loading: false, success: true };
    case CART_UPDATE_SUCCESS:
      return { ...state, loading: false, updateSuccess: true };
    case CART_FAIL:
      return { ...state, error: payload, loading: false };
    case CART_RESET:
      return {
        ...state,
        error: null,
        success: false,
        updateSuccess: false,
      };
    default:
      return state;
  }
};

export const purchaseNoteReducer = (
  state = { loading: false, purchaseNote: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PURCHASE_NOTE_REQUEST:
      return { ...state, loading: true };
    case PURCHASE_NOTE_SUCCESS:
      return { loading: false, purchaseNote: payload || [] };
    case PURCHASE_NOTE_FAIL:
      return { error: payload, loading: false, purchaseNote: [] };
    default:
      return state;
  }
};

export const updatePurchaseNoteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PURCHASE_NOTE_UPDATE_REQUEST:
      return { loading: true };
    case PURCHASE_NOTE_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PURCHASE_NOTE_UPDATE_FAIL:
      return { error: payload, loading: false };
    case PURCHASE_NOTE_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
