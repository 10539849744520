import Layout from 'components/Layout';
import AddProductModal from 'components/discountCompaign/AddProductModal';
import BasicSettings from 'components/yourFavorite/BasicSettings';
import DiscountSettings from 'components/yourFavorite/DiscountSettings';
import OtherSettings from 'components/yourFavorite/OtherSettings';
import PercentOffSettings from 'components/yourFavorite/PercentOffSettings';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
  getSettings,
  setSettings,
} from 'redux/actions/data/yourFavoriteActions';
import { SET_SETTINGS_IDLE } from 'redux/constants/data/yourFavoriteConstant';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import { useToggle } from 'utils/customHooks';
import * as Yup from 'yup';

import style from '../styles/layout.module.css';

const YourFavorite = () => {
  const dispatch = useDispatch();
  const [isProductModalOpen, setIsProductModalOpen] = useToggle();
  const [isProductModalOpenDiscount, setIsProductModalOpenDiscount] =
    useToggle();
  const [discountType, setDiscountType] = useState('');
  const { settings } = useSelector(state => state.getYourFavoriteSettings);
  const {
    loading: setLoading,
    success: setSuccess,
    error: setError,
  } = useSelector(state => state.setYourFavoriteSettings);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      days: settings?.durationDays || 0,
      discountAmount:
        settings?.conditions?.find(c => c.discountType === '折價')
          ?.discountPrice || 0,
      discount_products: settings?.conditions
        ? settings.conditions[1].productRange === 'picked'
          ? [
              ...(settings.conditions[1]?.pickedModels?.map(m => ({
                ...m,
                modelType: '一般商品',
              })) || []),
              ...(settings.conditions[1]?.pickedGroupModels?.map(m => ({
                ...m,
                modelType: '組合商品',
              })) || []),
            ]
          : [
              ...(settings.conditions[1]?.excludeModels?.map(m => ({
                ...m,
                modelType: '一般商品',
              })) || []),
              ...(settings.conditions[1]?.excludeGroupModels?.map(m => ({
                ...m,
                modelType: '組合商品',
              })) || []),
            ]
        : [],
      firstCount: settings?.conditionalReq_Times_1st || 0,
      firstDays: settings?.conditionalReq_Day_1st || 0,
      hours: settings?.durationHours || 0,
      isCouponAvailable: settings?.isUseCoupon,
      minutes: settings?.durationMins || 0,
      name: settings?.name || '',
      name_en: settings?.name_en || '',
      percentOff:
        settings?.conditions?.find(c => c.discountType === '打折')
          ?.discountPercent || 0,
      percent_products: settings?.conditions
        ? settings.conditions[0].productRange === 'picked'
          ? [
              ...(settings.conditions[0]?.pickedModels?.map(m => ({
                ...m,
                modelType: '一般商品',
              })) || []),
              ...(settings.conditions[0]?.pickedGroupModels?.map(m => ({
                ...m,
                modelType: '組合商品',
              })) || []),
            ]
          : [
              ...(settings.conditions[0]?.excludeModels?.map(m => ({
                ...m,
                modelType: '一般商品',
              })) || []),
              ...(settings.conditions[0]?.excludeGroupModels?.map(m => ({
                ...m,
                modelType: '組合商品',
              })) || []),
            ]
        : [],
      productRange_discount: settings?.conditions
        ? settings.conditions.find(c => c.discountType === '折價')
            ?.productRange || ''
        : '',
      productRange_percent: settings?.conditions
        ? settings.conditions.find(c => c.discountType === '打折')
            ?.productRange || ''
        : '',
      resetDays: settings?.reNewCalDays || 0,
      secondCount: settings?.conditionalReq_Times_2nd || 0,
      secondDays: settings?.conditionalReq_Day_2nd || 0,
      withDiscountActivity: settings?.isUseDiscountActivity,
    },
    onReset: () => {
      // call get api
    },
    onSubmit: () => {
      if (
        values.productRange_percent === 'picked' &&
        values.productRange_discount === 'picked'
      ) {
        if (
          values.discount_products?.length > 0 &&
          values.percent_products?.length > 0
        ) {
          // 同為指定商品時, 兩邊的活動商品不能重複
          let breakPoint = false;
          values.discount_products.forEach(model => {
            model.products.forEach(prd => {
              if (
                values.percent_products?.some(m =>
                  m.products.some(p => p.productcode === prd.productcode),
                )
              ) {
                if (!breakPoint) {
                  breakPoint = true;
                }
              }
            });
          });

          if (breakPoint) {
            MessageUtil.alertWanring('活動商品重複');
            return;
          }
        }
      } else {
        // 商品只能從另一個活動的排除商品挑選
        if (
          values.productRange_percent === 'all' &&
          values.productRange_discount === 'picked' &&
          values.discount_products?.length === 0 &&
          values.percent_products?.length > 0
        ) {
          MessageUtil.alertWanring('商品只能從另一個活動的排除商品挑選');
          return;
        }
        if (
          values.productRange_percent === 'picked' &&
          values.productRange_discount === 'all' &&
          values.discount_products?.length > 0 &&
          values.percent_products?.length === 0
        ) {
          MessageUtil.alertWanring('商品只能從另一個活動的排除商品挑選');
          return;
        }
        if (
          values.productRange_percent !== values.productRange_discount
        ) {
          let breakPoint = false;
          values.discount_products.forEach(model => {
            model.products.forEach(prd => {
              if (
                !values.percent_products?.some(m =>
                  m.products.some(p => p.productcode === prd.productcode),
                )
              ) {
                if (!breakPoint) {
                  breakPoint = true;
                }
              }
            });
          });
          if (breakPoint) {
            MessageUtil.alertWanring('商品只能從另一個活動的排除商品挑選');
            return;
          }
        }
      }
      let checked = false;
      if (values.firstDays <= values.firstCount) {
        checked = true;
      }

      if (values.secondDays <= values.secondCount) {
        checked = true;
      }

      if (checked) {
        MessageUtil.alertWanring('瀏覽次數必須小於天數');
        return;
      }

      if (
        Number(values.days) + Number(values.hours) + Number(values.minutes) <
        1
      ) {
        MessageUtil.alertWanring('請輸入折扣持續時間');
      }

      const body = {
        conditionalReq_Day_1st: values.firstDays,
        conditionalReq_Day_2nd: values.secondDays,
        conditionalReq_Times_1st: values.firstCount,
        conditionalReq_Times_2nd: values.secondCount,
        conditions: [
          {
            discountPercent: values.percentOff,
            discountPrice: 0,
            discountType: '打折',
            excludeGroupPrds:
              values.productRange_percent === 'all'
                ? (values?.percent_products || [])
                    .filter(m => m.modelType === '組合商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            excludePrds:
              values.productRange_percent === 'all'
                ? (values?.percent_products || [])
                    .filter(m => m.modelType === '一般商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            pickedGroupPrds:
              values.productRange_percent === 'picked'
                ? (values?.percent_products || [])
                    .filter(m => m.modelType === '組合商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            pickedPrds:
              values.productRange_percent === 'picked'
                ? (values?.percent_products || [])
                    .filter(m => m.modelType === '一般商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            productRange: values.productRange_percent,
          },
          {
            discountPercent: 0,
            discountPrice: values.discountAmount,
            discountType: '折價',
            excludeGroupPrds:
              values.productRange_discount === 'all'
                ? (values?.discount_products || [])
                    .filter(m => m.modelType === '組合商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            excludePrds:
              values.productRange_discount === 'all'
                ? (values?.discount_products || [])
                    .filter(m => m.modelType === '一般商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            pickedGroupPrds:
              values.productRange_discount === 'picked'
                ? (values?.discount_products || [])
                    .filter(m => m.modelType === '組合商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            pickedPrds:
              values.productRange_discount === 'picked'
                ? (values?.discount_products || [])
                    .filter(m => m.modelType === '一般商品')
                    .reduce((arr, model) => {
                      return [...arr, ...model.products];
                    }, [])
                    .map(product => product.productid)
                : [],
            productRange: values.productRange_discount,
          },
        ],
        durationDays: values.days,
        durationHours: values.hours,
        durationMins: values.minutes,
        id: settings ? settings.id : 0,
        isUseCoupon: values.isCouponAvailable,
        isUseDiscountActivity: values.withDiscountActivity,
        name: values.name,
        name_en: values.name_en,
        reNewCalDays: values.resetDays,
      };

      dispatch(setSettings(body));
    },
    validationSchema: Yup.object().shape({
      firstCount: Yup.number().min(1, '必填'),
      firstDays: Yup.number().min(2, '必須大於2天'),
      isCouponAvailable: Yup.boolean().nullable().required('必填'),
      name: Yup.string().nullable().required('必填'),
      name_en: Yup.string().nullable().required('必填'),
      productRange_discount: Yup.string().required('必填'),
      productRange_percent: Yup.string().required('必填'),
      resetDays: Yup.number().min(1, '必填'),
      secondCount: Yup.number().min(1, '必填'),
      secondDays: Yup.number().min(2, '必須大於2天'),
      withDiscountActivity: Yup.boolean().nullable().required('必填'),
    }),
  });

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (settings) {
      if (JSON.stringify(settings !== '{}')) {
        CommonUtil.consoleLog({ anno: 'response', data: settings });
      }
    }
  }, [settings]);

  useEffect(() => {
    if (setSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch(getSettings());
      dispatch({ type: SET_SETTINGS_IDLE });
    }

    if (setError) {
      MessageUtil.alertWanring(setError);
      dispatch({ type: SET_SETTINGS_IDLE });
    }
  }, [setError, setSuccess]);

  const titleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
    fontWeight: 'normal',
    marginBottom: '2rem',
  };

  const combineModelsHandler = useCallback(
    temp => {
      setFieldValue('percent_products', temp);
    },
    [values.percent_products],
  );

  const combineModelsHandlerDiscount = useCallback(
    temp => {
      setFieldValue('discount_products', temp);
    },
    [values.discount_products],
  );

  return (
    <Layout
      pageTitle="商品瀏覽折扣設定"
      items={[
        { isActive: true, page_name: '銷售設定' },
        { isActive: false, page_name: '商品瀏覽折扣設定' },
      ]}
    >
      <Card className={style.card}>
        <h4 className="title" style={titleStyle}>
          活動基本設定
        </h4>
        <BasicSettings
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <h4 className="title" style={titleStyle}>
          折抵條件設定(打折)
        </h4>
        <PercentOffSettings
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setIsProductModalOpen={setIsProductModalOpen}
          setDiscountType={setDiscountType}
        />
        <h4 className="title" style={titleStyle}>
          折抵條件設定(折價)
        </h4>
        <DiscountSettings
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          setIsProductModalOpen={setIsProductModalOpenDiscount}
          setDiscountType={setDiscountType}
        />
        <h4 className="title" style={titleStyle}>
          其他行銷活動設定
        </h4>
        <OtherSettings
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
        <div className="mb-5" />
        <label>{`異動時間： ${settings?.updateTime || '--'} ${
          settings?.updater || '--'
        }`}</label>
        <hr />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={() => {
              handleReset();
            }}
          >
            取消
          </Button>
          <Button
            color="primary"
            className={style.modal_button_submit}
            onClick={() => {
              handleSubmit();
            }}
            disabled={setLoading}
          >
            {setLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '1rem' }}
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
      {
        // 打折
        isProductModalOpen && (
          <AddProductModal
            isOpen={isProductModalOpen}
            toggle={setIsProductModalOpen}
            type={
              values.productRange_percent === 'all' ? '排除商品' : '指定商品'
            }
            anotherType={
              values.productRange_discount === 'all' ? '排除商品' : '指定商品'
            }
            disableHideModel
            discountType={discountType}
            existList={values.percent_products}
            area={0}
            onSubmit={combineModelsHandler}
            limitList={values.discount_products}
          />
        )
      }
      {
        // 折價
        isProductModalOpenDiscount && (
          <AddProductModal
            isOpen={isProductModalOpenDiscount}
            toggle={setIsProductModalOpenDiscount}
            type={
              values.productRange_discount === 'all' ? '排除商品' : '指定商品'
            }
            anotherType={
              values.productRange_percent === 'all' ? '排除商品' : '指定商品'
            }
            discountType={discountType}
            existList={values.discount_products}
            disableHideModel
            area={0}
            onSubmit={combineModelsHandlerDiscount}
            limitList={values.percent_products}
          />
        )
      }
    </Layout>
  );
};

export default YourFavorite;
