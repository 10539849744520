export const UUCAFE_JOURNEY_LIST_REQUEST = 'UUCAFE_JOURNEY_LIST_REQUEST';
export const UUCAFE_JOURNEY_LIST_SUCCESS = 'UUCAFE_JOURNEY_LIST_SUCCESS';
export const UUCAFE_JOURNEY_LIST_FAIL = 'UUCAFE_JOURNEY_LIST_FAIL';
export const UUCAFE_JOURNEY_REQUEST = 'UUCAFE_JOURNEY_REQUEST';
export const UUCAFE_JOURNEY_SUCCESS = 'UUCAFE_JOURNEY_SUCCESS';
export const UUCAFE_JOURNEY_FAIL = 'UUCAFE_JOURNEY_FAIL';
export const UUCAFE_JOURNEY_ADD_REQUEST = 'UUCAFE_JOURNEY_ADD_REQUEST';
export const UUCAFE_JOURNEY_ADD_SUCCESS = 'UUCAFE_JOURNEY_ADD_SUCCESS';
export const UUCAFE_JOURNEY_ADD_FAIL = 'UUCAFE_JOURNEY_ADD_FAIL';
export const UUCAFE_JOURNEY_UPDATE_REQUEST = 'UUCAFE_JOURNEY_UPDATE_REQUEST';
export const UUCAFE_JOURNEY_UPDATE_SUCCESS = 'UUCAFE_JOURNEY_UPDATE_SUCCESS';
export const UUCAFE_JOURNEY_UPDATE_FAIL = 'UUCAFE_JOURNEY_UPDATE_FAIL';

export const UUCAFE_JOURNEY_PAGE_REQUEST = 'UUCAFE_JOURNEY_PAGE_REQUEST';
export const UUCAFE_JOURNEY_PAGE_SUCCESS = 'UUCAFE_JOURNEY_PAGE_SUCCESS';
export const UUCAFE_JOURNEY_PAGE_FAIL = 'UUCAFE_JOURNEY_PAGE_FAIL';

export const UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST =
  'UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST';
export const UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS =
  'UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS';
export const UUCAFE_JOURNEY_PAGE_UPDATE_FAIL =
  'UUCAFE_JOURNEY_PAGE_UPDATE_FAIL';
