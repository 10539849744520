import UploadFileBox from 'components/UploadFileBox';
import React from 'react';
import { Button, Input } from 'reactstrap';

import style from '../../styles/layout.module.css';

const Information = ({
  values,
  setFieldValue,
  errors,
  touched,
  onChange,
  onToggleModal,
  status,
}) => {
  return (
    <div className="mb-5">
      <div className={style.formGroup}>
        <label className="required w150">
          贈品名稱(中)
          {errors.name && touched.name && (
            <span className="ml-2 text-danger">{errors.name}</span>
          )}
        </label>
        <Input
          className={style['form-select-shop-banner']}
          id="name"
          value={values.name}
          onChange={onChange}
          invalid={errors.name && touched.name}
        />
      </div>
      <div className={style.formGroup}>
        <label className="required w150">
          贈品名稱(英)
          {errors.name_en && touched.name_en && (
            <span className="ml-2 text-danger">{errors.name_en}</span>
          )}
        </label>
        <Input
          className={style['form-select-shop-banner']}
          id="name_en"
          value={values.name_en}
          onChange={onChange}
          invalid={errors.name_en && touched.name_en}
        />
      </div>
      <div style={{ margin: '3rem' }} />
      <div className={style.formGroup}>
        <label className="required w150">
          贈品來源
          {errors.source && touched.source && (
            <span className="ml-2 text-danger">{errors.source}</span>
          )}
        </label>
        {/*        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            marginRight: '2rem',
          }}
        >
          <input
            type="radio"
            id="source"
            checked={values.source === 'ERP'}
            onChange={onChange}
            value="ERP"
            className={style['radio-btn']}
            disabled={status === 'edit'}
          />
          <span className="ml-2">ERP</span>
        </div>*/}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          <input
            type="radio"
            id="source"
            checked={values.source === '後台新增'}
            onChange={e => {
              setFieldValue('source', e.target.value);
              setFieldValue('productcode', '');
            }}
            value="後台新增"
            className={style['radio-btn']}
            disabled={status === 'edit'}
          />
          <span className="ml-2">後台新增</span>
        </div>
      </div>
      {values.source === 'ERP' && (
        <React.Fragment>
          <div className={style.formGroup}>
            <label className="w150" />
            <Button
              color="primary"
              outline
              className={style['rwd-w100']}
              onClick={onToggleModal}
              disabled={status === 'edit'}
            >
              選擇贈品
            </Button>
          </div>
          {
            <div className={style.formGroup}>
              <label className="w150" />
              {values?.productcode}
            </div>
          }
          {errors.productcode && touched.productcode && (
            <span className="text-danger ml-2">{errors.productcode}</span>
          )}
        </React.Fragment>
      )}
      <div style={{ margin: '3rem' }} />
      <div className={style.formGroup}>
        <label className="required w150">
          贈品圖片
          {errors.image && touched.image && (
            <span className="ml-2 text-danger">{errors.image}</span>
          )}
        </label>
        <div>
          <UploadFileBox
            setFormFieldValue={setFieldValue}
            formFieldName="image"
            currentFileFieldName="currentImage"
            uplodModel="images"
            displayFileWidth="不限"
            displayFileHeight="不限"
            displayFileSize="3MB"
            isMultipleUpload={true}
            initFiles={values.initImage || values.image}
          />
        </div>
      </div>
      <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
        <label className="required w150">特色說明</label>
        <div style={{ width: '100%' }}>
          <span>中</span>
          <Input
            type="textarea"
            id="desc"
            value={values.desc}
            onChange={onChange}
            style={{ minHeight: '140px' }}
            invalid={errors.desc && touched.desc}
          />
        </div>
      </div>
      <div className={style.formGroup}>
        <label className="w150" />
        <div style={{ width: '100%' }}>
          <span>英</span>
          <Input
            type="textarea"
            id="desc_en"
            value={values.desc_en}
            onChange={onChange}
            style={{ minHeight: '140px' }}
            invalid={errors.desc_en && touched.desc_en}
          />
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Information;
