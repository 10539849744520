import {
  GET_AREA_LIST_FAIL,
  GET_AREA_LIST_REQUEST,
  GET_AREA_LIST_SUCCESS,
  GET_CITY_LIST_FAIL,
  GET_CITY_LIST_REQUEST,
  GET_CITY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,
  GET_COUNTRY_LIST_REQUEST,
  GET_COUNYRT_LIST_SUCCESS,
} from 'redux/constants/data/locationContants';

export const getCountryListReducer = (
  state = { countryList: [], error: null, loading: false, success: true },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNTRY_LIST_REQUEST:
      return { countryList: [], error: null, loading: true, success: false };
    case GET_COUNYRT_LIST_SUCCESS:
      return { ...state, countryList: payload, loading: false, success: true };
    case GET_COUNTRY_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getCityListReducer = (
  state = { cityList: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CITY_LIST_REQUEST:
      return { cityList: [], error: null, loading: true, success: false };
    case GET_CITY_LIST_SUCCESS:
      return { ...state, cityList: payload, loading: false, success: true };
    case GET_CITY_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getAreaListReducer = (
  state = { areaList: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AREA_LIST_REQUEST:
      return { areaList: [], error: null, loading: true, success: false };
    case GET_AREA_LIST_SUCCESS:
      return { ...state, areaList: payload, loading: false, success: true };
    case GET_AREA_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
