import { useFormik } from 'formik';
import moment from 'moment';
import {useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';
import { addGifts } from 'redux/actions/data/productActions';
import useQuery from 'utils/useQuery';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const SelectedList = ({
                        // datas,
                        isAdded,
                      }) => {
  const query = useQuery();
  const productId = query.value.id;
  const mId = query.value.mid;
  const productType = useMemo(() => {
    return Number(query.value?.productType || 1);
  }, [query]);
  const dispatch = useDispatch();
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: [],
    },
    onSubmit: () => {
      const body = {
        freebieIds: values.selectedProducts,
        isforAdding: false,
        productType,
        mId,
        productId,
      };
      dispatch(addGifts(body));
      setFieldValue('selectedProducts', []);
    },
  });

  const { gifts, loading: getLoading } = useSelector(
    state => state.getBindingGiftsList,
  );

  useEffect(() => {
    setFieldValue('selectedProducts', []);
  }, [isAdded]);

  return (
    <Card className={style.card}>
      <Button
        color="success"
        className="w100 mb-3"
        onClick={handleSubmit}
        disabled={values.selectedProducts.length === 0}
      >
        移除贈品
      </Button>
      <div className="table-responsive">
        <Table hover striped bordered className="mb-5">
          <thead
            className={`${style.table_head} thead-dark`}
            style={{ marginBottom: 0 }}
          >
          <tr>
            <th colSpan={9}>
              <div className={style.formGroup}>
                <input
                  type="checkbox"
                  className={style['radio-btn']}
                  onChange={event => {
                    const checked = event.target.checked;
                    setFieldValue('selectedProducts', []);
                    checked &&
                    setFieldValue(
                      'selectedProducts',
                      gifts?.datas?.map(data => data.id),
                    );
                  }}
                  checked={
                    values.selectedProducts.length > 0 &&
                    values.selectedProducts.length === gifts?.datas?.length
                  }
                />
                <span className="ml-2">全選</span>
              </div>
            </th>
          </tr>
          <tr>
            <th>選擇</th>
            <th>贈品序號</th>
            <th>贈品主圖</th>
            <th>贈品名稱</th>
            <th>贈品料號</th>
            <th>
              <div>已發送數量</div>
              <div>贈品庫存</div>
            </th>
            <th>
              <div>加入此贈品日期</div>
              <div>加入人員</div>
            </th>
            <th>
              <div>贈品上架時間</div>
              <div>贈品下架時間</div>
            </th>
            <th>贈品狀態</th>
          </tr>
          </thead>
          {getLoading && <TableLoading />}
          {!getLoading && (
            <tbody>
            {gifts?.datas?.length > 0 ? (
              gifts.datas.map((data, index) => (
                <tr key={index}>
                  <td className="text-center">
                    {/* 選擇 */}
                    <input
                      type="checkbox"
                      className={style['radio-btn']}
                      checked={values.selectedProducts.find(
                        prd => prd === data.id,
                      )}
                      onChange={e => {
                        const checked = e.target.checked;
                        checked
                          ? setFieldValue('selectedProducts', [
                            ...values.selectedProducts,
                            data.id,
                          ])
                          : setFieldValue(
                            'selectedProducts',
                            values.selectedProducts.filter(
                              prd => prd !== data.id,
                            ),
                          );
                      }}
                    />
                  </td>
                  <td className="text-center">
                    {/* 贈品序號 */}
                    {data.freebieCode}
                  </td>
                  <td className="text-center">
                    {/* 贈品圖 */}
                    <img
                        className={style.w200}
                        src={data?.freebieImgInfo[0]?.imageUrl || ''}
                        alt={data?.freebieImgInfo[0]?.imageUrl || ''}/>
                  </td>
                  <td className="text-center">
                    {/* 贈品名稱 */}
                    {data.name}
                  </td>
                  <td className="text-center">
                    {/* 贈品料號 */}
                    {data.productcode}
                  </td>
                  <td className="text-center">
                    {/* 已發送數量/庫存 */}
                    <div>{data.soldNum}</div>
                    <div>{data.stock}</div>
                  </td>
                  <td className="text-center">
                    {/* 加入日期/加入人員 */}
                    <div>
                      {data.addTime
                        ? moment(data.addTime).format('YYYY/MM/DD HH:mm:ss')
                        : '--'}
                    </div>
                    <div>{data.addAdmin}</div>
                  </td>
                  <td className="text-center">
                    {/* 贈品上下架時間 */}
                    <div>
                      {data.startTime
                        ? moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')
                        : '--'}
                    </div>
                    <div>
                      {data.endTime
                        ? moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')
                        : '--'}
                    </div>
                  </td>
                  <td className="text-center">
                    {/* 贈品狀態 */}
                    {data.isSell ? '開啟' : '關閉'}
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
            </tbody>
          )}
        </Table>
      </div>
    </Card>
  );
};

export default SelectedList;
