import {
  INSTRUCTION_DELETE_FAIL,
  INSTRUCTION_DELETE_REQUEST,
  INSTRUCTION_DELETE_RESET,
  INSTRUCTION_DELETE_SUCCESS,
  INSTRUCTION_FAQ_DELETE_FAIL,
  INSTRUCTION_FAQ_DELETE_REQUEST,
  INSTRUCTION_FAQ_DELETE_RESET,
  INSTRUCTION_FAQ_DELETE_SUCCESS,
  INSTRUCTION_FAQ_LIST_FAIL,
  INSTRUCTION_FAQ_LIST_REQUEST,
  INSTRUCTION_FAQ_LIST_SUCCESS,
  INSTRUCTION_FAQ_POST_FAIL,
  INSTRUCTION_FAQ_POST_REQUEST,
  INSTRUCTION_FAQ_POST_RESET,
  INSTRUCTION_FAQ_POST_SUCCESS,
  INSTRUCTION_LIST_FAIL,
  INSTRUCTION_LIST_REQUEST,
  INSTRUCTION_LIST_SUCCESS,
  INSTRUCTION_POST_FAIL,
  INSTRUCTION_POST_REQUEST,
  INSTRUCTION_POST_RESET,
  INSTRUCTION_POST_SUCCESS,
  REPAIR_DELETE_FAIL,
  REPAIR_DELETE_REQUEST,
  REPAIR_DELETE_RESET,
  REPAIR_DELETE_SUCCESS,
  REPAIR_LIST_FAIL,
  REPAIR_LIST_REQUEST,
  REPAIR_LIST_SUCCESS,
  REPAIR_POST_FAIL,
  REPAIR_POST_REQUEST,
  REPAIR_POST_RESET,
  REPAIR_POST_SUCCESS,
  SERVICE_CONTENT_DELETE_FAIL,
  SERVICE_CONTENT_DELETE_REQUEST,
  SERVICE_CONTENT_DELETE_RESET,
  SERVICE_CONTENT_DELETE_SUCCESS,
  SERVICE_CONTENT_POST_FAIL,
  SERVICE_CONTENT_POST_REQUEST,
  SERVICE_CONTENT_POST_RESET,
  SERVICE_CONTENT_POST_SUCCESS,
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_TITLE_POST_FAIL,
  SERVICE_TITLE_POST_REQUEST,
  SERVICE_TITLE_POST_RESET,
  SERVICE_TITLE_POST_SUCCESS,
  UPDATE_SERVICE_REMARK_FAIL,
  UPDATE_SERVICE_REMARK_IDLE,
  UPDATE_SERVICE_REMARK_REQUEST,
  UPDATE_SERVICE_REMARK_SUCCESS,
  WARRANTY_DELETE_FAIL,
  WARRANTY_DELETE_REQUEST,
  WARRANTY_DELETE_RESET,
  WARRANTY_DELETE_SUCCESS,
  WARRANTY_LIST_FAIL,
  WARRANTY_LIST_REQUEST,
  WARRANTY_LIST_SUCCESS,
  WARRANTY_POST_FAIL,
  WARRANTY_POST_REQUEST,
  WARRANTY_POST_RESET,
  WARRANTY_POST_SUCCESS,
} from '../../constants/data/serviceConstants';

//get service list
export const serviceListReducer = (state = { serviceListInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_LIST_REQUEST:
      return { loading: true };
    case SERVICE_LIST_SUCCESS:
      return { loading: false, serviceListInfo: payload };
    case SERVICE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post service title
export const serviceTitlePostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_TITLE_POST_REQUEST:
      return { loading: true };
    case SERVICE_TITLE_POST_SUCCESS:
      return { loading: false, success: payload };
    case SERVICE_TITLE_POST_FAIL:
      return { error: payload, loading: false };
    case SERVICE_TITLE_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//post service content
export const serviceContentPostReducer = (state = { error: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_CONTENT_POST_REQUEST:
      return { error: null, loading: true, success: false };
    case SERVICE_CONTENT_POST_SUCCESS:
      return { loading: false, success: true };
    case SERVICE_CONTENT_POST_FAIL:
      return { error: payload, loading: false };
    case SERVICE_CONTENT_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete service content
export const serviceContentDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_CONTENT_DELETE_REQUEST:
      return { loading: true };
    case SERVICE_CONTENT_DELETE_SUCCESS:
      return { loading: false, success: payload };
    case SERVICE_CONTENT_DELETE_FAIL:
      return { error: payload, loading: false };
    case SERVICE_CONTENT_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get warranty list
export const warrantyListReducer = (
  state = { warrantyListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_LIST_REQUEST:
      return { loading: true };
    case WARRANTY_LIST_SUCCESS:
      return { loading: false, warrantyListInfo: payload };
    case WARRANTY_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post warranty
export const warrantyPostReducer = (state = { error: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_POST_REQUEST:
      return { ...state, loading: true };
    case WARRANTY_POST_SUCCESS:
      return { ...state, loading: false, success: true };
    case WARRANTY_POST_FAIL:
      return { ...state, error: payload, loading: false };
    case WARRANTY_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete warranty
export const warrantyDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case WARRANTY_DELETE_REQUEST:
      return { loading: true };
    case WARRANTY_DELETE_SUCCESS:
      return { loading: false, success: true };
    case WARRANTY_DELETE_FAIL:
      return { error: payload, loading: false };
    case WARRANTY_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get repair list
export const repairListReducer = (state = { repairListInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPAIR_LIST_REQUEST:
      return { loading: true };
    case REPAIR_LIST_SUCCESS:
      return { loading: false, repairListInfo: payload };
    case REPAIR_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post repair
export const repairPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPAIR_POST_REQUEST:
      return { loading: true };
    case REPAIR_POST_SUCCESS:
      return { loading: false, success: true };
    case REPAIR_POST_FAIL:
      return { error: payload, loading: false };
    case REPAIR_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete repair
export const repairDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPAIR_DELETE_REQUEST:
      return { loading: true };
    case REPAIR_DELETE_SUCCESS:
      return { loading: false, success: true };
    case REPAIR_DELETE_FAIL:
      return { error: payload, loading: false };
    case REPAIR_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get instruction list
export const instructionListReducer = (
  state = { instructionListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_LIST_REQUEST:
      return { loading: true };
    case INSTRUCTION_LIST_SUCCESS:
      return { instructionListInfo: payload, loading: false };
    case INSTRUCTION_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post instruction
export const instructionPostReducer = (state = { error: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_POST_REQUEST:
      return { error: null, loading: true };
    case INSTRUCTION_POST_SUCCESS:
      return { loading: false, success: payload };
    case INSTRUCTION_POST_FAIL:
      return { error: payload, loading: false };
    case INSTRUCTION_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete instruction
export const instructionDeleteReducer = (state = { error: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_DELETE_REQUEST:
      return { error: null, loading: true };
    case INSTRUCTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case INSTRUCTION_DELETE_FAIL:
      return { error: payload, loading: false };
    case INSTRUCTION_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get instruction FAQ list
export const instructionFAQListReducer = (
  state = { FAQListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_FAQ_LIST_REQUEST:
      return { loading: true };
    case INSTRUCTION_FAQ_LIST_SUCCESS:
      return { FAQListInfo: payload, loading: false };
    case INSTRUCTION_FAQ_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post instruction FAQ
export const instructionFAQPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_FAQ_POST_REQUEST:
      return { loading: true };
    case INSTRUCTION_FAQ_POST_SUCCESS:
      return { loading: false, success: payload };
    case INSTRUCTION_FAQ_POST_FAIL:
      return { error: payload, loading: false };
    case INSTRUCTION_FAQ_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete instruction
export const instructionFAQDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INSTRUCTION_FAQ_DELETE_REQUEST:
      return { loading: true, success: false };
    case INSTRUCTION_FAQ_DELETE_SUCCESS:
      return { loading: false, success: true };
    case INSTRUCTION_FAQ_DELETE_FAIL:
      return { error: payload, loading: false };
    case INSTRUCTION_FAQ_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const updateServiceRemarkReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SERVICE_REMARK_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_SERVICE_REMARK_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SERVICE_REMARK_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_SERVICE_REMARK_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
