import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_OPTIONS_REQUEST,
  ORDER_OPTIONS_SUCCESS,
  ORDER_OPTIONS_FAIL,
  ORDER_OPTIONS_BY_TYPE_REQUEST,
  ORDER_OPTIONS_BY_TYPE_SUCCESS,
  ORDER_OPTIONS_BY_TYPE_FAIL,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_LIST_EXPORT_ALL_IDLE,
  ORDER_LIST_EXPORT_ALL_REQUEST,
  ORDER_LIST_EXPORT_ALL_SUCCESS,
  ORDER_LIST_EXPORT_ALL_FAIL,
  ORDER_REMARK_EDIT_IDLE,
  ORDER_REMARK_EDIT_REQUEST,
  ORDER_REMARK_EDIT_SUCCESS,
  ORDER_REMARK_EDIT_FAIL,
  GET_ORDER_ACTIVITIES_REQUEST,
  GET_ORDER_ACTIVITIES_SUCCESS,
  GET_ORDER_ACTIVITIES_FAIL,
  GET_ORDER_FREEBIE_LIST_REQUEST,
  GET_ORDER_FREEBIE_LIST_SUCCESS,
  GET_ORDER_FREEBIE_LIST_FAIL,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS,
  GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL,
} from 'redux/constants/data/orderConstants';

export const orderListReducer = (
  state = { loading: false, orderListInfo: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_LIST_SUCCESS:
      return { loading: false, orderListInfo: payload || [] };
    case ORDER_LIST_FAIL:
      return { error: payload, loading: false, orderListInfo: [] };
    default:
      return state;
  }
};

export const orderOptionsReducer = (
  state = { loading: true, orderOptionsInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_OPTIONS_REQUEST:
      return { ...state, loading: true };
    case ORDER_OPTIONS_SUCCESS:
      if ('payWay' in payload) {
        payload.payWay = [
          ...payload.payWay,
        ];
      }
      return { loading: false, orderOptionsInfo: payload || {} };
    case ORDER_OPTIONS_FAIL:
      return { error: payload, loading: false, orderOptionsInfo: {} };
    default:
      return state;
  }
};
export const orderOptionReducer = (
  state = { loading: true, orderOption: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_OPTIONS_BY_TYPE_REQUEST:
      return { ...state, loading: true };
    case ORDER_OPTIONS_BY_TYPE_SUCCESS:
      return { loading: false, orderOption: payload || [] };
    case ORDER_OPTIONS_BY_TYPE_FAIL:
      return { error: payload, loading: false, orderOption: [] };
    default:
      return state;
  }
};

export const orderDetailReducer = (
  state = { loading: true, orderInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ORDER_DETAIL_SUCCESS:
      return { loading: false, orderInfo: payload || {} };
    case ORDER_DETAIL_FAIL:
      return { error: payload, loading: false, orderInfo: {} };
    default:
      return state;
  }
};
// 所有訂單
export const exportAllOrdersInfoReducer = (
  state = { error: null, info: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_LIST_EXPORT_ALL_IDLE:
      return { error: null, info: null, loading: false, success: false };
    case ORDER_LIST_EXPORT_ALL_REQUEST:
      return { ...state, loading: true };
    case ORDER_LIST_EXPORT_ALL_SUCCESS:
      return { ...state, info: payload, loading: false, success: true };
    case ORDER_LIST_EXPORT_ALL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const editOrderRemarkReducer = (
  state = { data: null, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_REMARK_EDIT_IDLE:
      return { data: null, error: null, loading: false, success: false };
    case ORDER_REMARK_EDIT_REQUEST:
      return { ...state, loading: true };
    case ORDER_REMARK_EDIT_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case ORDER_REMARK_EDIT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderActivitiesReducer = (
  state = { activities: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_ACTIVITIES_REQUEST:
      return { activities: [], error: null, loading: true, success: false };
    case GET_ORDER_ACTIVITIES_SUCCESS:
      return { ...state, activities: payload, loading: false, success: true };
    case GET_ORDER_ACTIVITIES_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderFreebieListReducer = (
  state = { error: null, freebieList: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_FREEBIE_LIST_REQUEST:
      return { error: null, freebieList: {}, loading: true, success: false };
    case GET_ORDER_FREEBIE_LIST_SUCCESS:
      return { ...state, freebieList: payload, loading: false, success: true };
    case GET_ORDER_FREEBIE_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderActivityFreebieListReducer = (
  state = { error: null, freebieList: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_ACTIVITY_FREEBIE_LIST_REQUEST:
      return { error: null, freebieList: {}, loading: true, success: false };
    case GET_ORDER_ACTIVITY_FREEBIE_LIST_SUCCESS:
      return { ...state, freebieList: payload, loading: false, success: true };
    case GET_ORDER_ACTIVITY_FREEBIE_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
