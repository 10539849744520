import Layout from 'components/Layout';
import React from 'react';
import { TabPane } from 'reactstrap';

import Tabs from '../components/Tabs';
import ContentSettings from '../components/distributionServicesSettings/ContentSettings';
import MilestoneSettings from '../components/distributionServicesSettings/MilestoneSettings';
const TABS = [
  {
    name: '內容設定',
    tab: 1,
  },
  {
    name: '里程碑',
    tab: 2,
  },
];
const DistributionServicesSettings = () => {
  return (
    <Layout
      pageTitle="經銷服務"
      items={[
        { isActive: false, page_name: '網頁設定' },
        { isActive: true, page_name: '經銷服務' },
      ]}
    >
      <Tabs tabs={TABS}>
        <TabPane tabId={1}>
          <ContentSettings />
        </TabPane>
        <TabPane tabId={2}>
          <MilestoneSettings />
        </TabPane>
      </Tabs>
    </Layout>
  );
};

export default DistributionServicesSettings;
