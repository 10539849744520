import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import {
  getCafeMealOtherProductPage,
  saveCafeMealOtherProductPage,
} from '../../redux/actions/data/uuCafeOtherProductAction';
import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import UploadFileBox from '../UploadFileBox';


const OtherProductPageEditor = ({ menuId }) => {
  const { loading, success, error, detail } = useSelector(
    state => state.cafeMealOtherProductPage,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);
  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const formData = new FormData();
      formData.append('title', props.values.title);
      formData.append('title_en', props.values.title_en);
      formData.append('content', props.values.content);
      formData.append('content_en', props.values.content_en);
      formData.append('menuId', menuId);

      if (props.values.introBanner_files) {
        formData.append('introBanner_files', props.values.introBanner_files);
      } else {
        if (detail.introImgBanner && detail.introImgBannerUrl) {
          formData.append('introBanner_files', []);
        }
      }

      if (props.values.introBanner_files_mobile) {
        formData.append(
          'introBanner_files_mobile',
          props.values.introBanner_files_mobile,
        );
      } else {
        if (detail.introImgBannerUrl_mobile && detail.introImgBanner_mobile) {
          formData.append('introBanner_files_mobile', []);
        }
      }

      if (props.values.zoneBanner_files) {
        formData.append('zoneBanner_files', props.values.zoneBanner_files);
      } else {
        if (detail.zoneImgBanner && detail.zoneImgBannerUrl) {
          formData.append('zoneBanner_files', []);
        }
      }

      if (props.values.zoneBanner_files_mobile) {
        formData.append(
          'zoneBanner_files_mobile',
          props.values.zoneBanner_files_mobile,
        );
      } else {
        if (detail.zoneImgBannerUrl_mobile && detail.zoneImgBanner_mobile) {
          formData.append('zoneBanner_files_mobile', []);
        }
      }

      formData.append('id', detail.id || 0);
      dispatch(saveCafeMealOtherProductPage(formData));
    },
    [detail],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        dispatch(getCafeMealOtherProductPage(menuId));
      }
      if (error) {
        toast.warning('修改失敗');
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    dispatch(getCafeMealOtherProductPage(menuId));
  }, [dispatch]);

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Formik
        enableReinitialize
        initialValues={{
          content: detail.content || '',
          content_en: detail.content_en || '',
          init_introBanner_files: getImageDataFromSetting(
            detail.introImgBanner,
            detail.introImgBannerUrl,
          ),
          init_introBanner_files_mobile: getImageDataFromSetting(
            detail.introImgBanner_mobile,
            detail.introImgBannerUrl_mobile,
          ),
          init_zoneBanner_files: getImageDataFromSetting(
            detail.zoneImgBanner,
            detail.zoneImgBannerUrl,
          ),
          init_zoneBanner_files_mobile: getImageDataFromSetting(
            detail.zoneImgBanner_mobile,
            detail.zoneImgBannerUrl_mobile,
          ),
          introBanner_files: null,
          introBanner_files_mobile: null,
          title: detail.title || '',
          title_en: detail.title_en || '',
          updateDateTime: detail.updateDateTime || '',
          updater: detail.updater || '',
          zoneBanner_files: null,
          zoneBanner_files_mobile: null,
        }}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <h4 className="title">餐點介紹-首頁圖片設定</h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*電腦版圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="introBanner_files"
                  currentFileFieldName="introBanner_files"
                  setFormFieldValue={(field, files) => {
                    if (files && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue('introBanner_files', files[0]);
                      }
                    }
                  }}
                  displayFileWidth="1320"
                  displayFileHeight="350"
                  displayFileSize="1MB"
                  initFiles={props.values.init_introBanner_files}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*手機版圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="introBanner_files_mobile"
                  currentFileFieldName="introBanner_files_mobile"
                  setFormFieldValue={(field, files) => {
                    if (files && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue(
                          'introBanner_files_mobile',
                          files[0],
                        );
                      }
                    }
                  }}
                  displayFileWidth="350"
                  displayFileHeight="250"
                  displayFileSize="1MB"
                  initFiles={props.values.init_introBanner_files_mobile}
                />
              </div>
            </FormGroup>
            <h4 className="title">餐點介紹-專區設定</h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*電腦版圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="zoneBanner_files"
                  currentFileFieldName="zoneBanner_files"
                  setFormFieldValue={(field, files) => {
                    if (files && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue('zoneBanner_files', files[0]);
                      }
                    }
                  }}
                  displayFileWidth="1320"
                  displayFileHeight="350"
                  displayFileSize="1MB"
                  initFiles={props.values.init_zoneBanner_files}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*手機版圖片</label>
              <div className="form-item">
                <UploadFileBox
                  uplodModel="images"
                  formFieldName="zoneBanner_files_mobile"
                  currentFileFieldName="zoneBanner_files_mobile"
                  setFormFieldValue={(field, files) => {
                    if (files && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue(
                          'zoneBanner_files_mobile',
                          files[0],
                        );
                      }
                    }
                  }}
                  displayFileWidth="350"
                  displayFileHeight="250"
                  displayFileSize="1MB"
                  initFiles={props.values.init_zoneBanner_files_mobile}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(中文)</label>
              <div className="form-item">
                <Field name="title" className="form-control" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(英文)</label>
              <div className="form-item">
                <Field name="title_en" className="form-control" />
                <ErrorMessage
                  name="title_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(中文)</label>
              <div className="form-item fix-z-index">
                <Field
                  name="content"
                  className="form-control"
                  component="textarea"
                />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(英文)</label>
              <div className="form-item fix-z-index">
                <Field
                  name="content_en"
                  className="form-control"
                  component="textarea"
                />
                <ErrorMessage
                  name="content_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <div style={{ minHeight: '150px' }}></div>
            <p className="fs-14">
              {props.values.updateDateTime} {props.values.updater}
            </p>
            <hr className="mt-5" />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!(props.isValid && props.dirty)}
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '1rem' }}
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(OtherProductPageEditor);
