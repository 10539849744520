export const GET_PRODUCT_SALES_DATA_RANKING_REQUEST =
    'GET_PRODUCT_SALES_DATA_RANKING_REQUEST';
export const GET_PRODUCT_SALES_DATA_RANKING_SUCCESS =
    'GET_PRODUCT_SALES_DATA_RANKING_SUCCESS';
export const GET_PRODUCT_SALES_DATA_RANKING_FAIL =
    'GET_PRODUCT_SALES_DATA_RANKING_FAIL';

export const GET_STORE_SALES_DATA_LIST_REQUEST =
    'GET_STORE_SALES_DATA_LIST_REQUEST';
export const GET_STORE_SALES_DATA_LIST_SUCCESS =
    'GET_STORE_SALES_DATA_LIST_SUCCESS';
export const GET_STORE_SALES_DATA_LIST_FAIL = 'GET_STORE_SALES_DATA_LIST_FAIL';

export const GET_ORDER_INSTANT_REPORT_REQUEST =
    'GET_ORDER_INSTANT_REPORT_REQUEST';
export const GET_ORDER_INSTANT_REPORT_SUCCESS =
    'GET_ORDER_INSTANT_REPORT_SUCCESS';
export const GET_ORDER_INSTANT_REPORT_FAIL = 'GET_ORDER_INSTANT_REPORT_FAIL';

export const GET_ORDER_CANCEL_REPORT_REQUEST =
    'GET_ORDER_CANCEL_REPORT_REQUEST';
export const GET_ORDER_CANCEL_REPORT_SUCCESS =
    'GET_ORDER_CANCEL_REPORT_SUCCESS';
export const GET_ORDER_CANCEL_REPORT_FAIL = 'GET_ORDER_CANCEL_REPORT_FAIL';

export const GET_ORDER_SOURCE_LIST_REQUEST = 'GET_ORDER_SOURCE_LIST_REQUEST';
export const GET_ORDER_SOURCE_LIST_SUCCESS = 'GET_ORDER_SOURCE_LIST_SUCCESS';
export const GET_ORDER_SOURCE_LIST_FAIL = 'GET_ORDER_SOURCE_LIST_FAIL';

export const GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST =
    'GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST';
export const GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS =
    'GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS';
export const GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL =
    'GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL';

export const GET_COUPON_REALTIME_REPORT_LIST_REQUEST =
    'GET_COUPON_REALTIME_REPORT_LIST_REQUEST';
export const GET_COUPON_REALTIME_REPORT_LIST_SUCCESS =
    'GET_COUPON_REALTIME_REPORT_LIST_SUCCESS';
export const GET_COUPON_REALTIME_REPORT_LIST_FAIL =
    'GET_COUPON_REALTIME_REPORT_LIST_FAIL';

export const GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST =
    'GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST';

export const GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS =
    'GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS';

export const GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL =
    'GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL';

export const GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL =
    'GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL';
export const GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST =
    'GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST';
export const GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS =
    'GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS';

export const GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL =
    'GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL';
export const GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST =
    'GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST';
export const GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS =
    'GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS';
