import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, FormGroup, Input } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../../components/Layout';
import UploadFileBox from '../../components/UploadFileBox';
import {
  getProductBrandBannerSetting,
  saveProductBrandBannerSetting,
} from '../../redux/actions/data/brandActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';

const lableStyle = { fontWeight: '400', minWidth: '200px', padding: '0px' };

function ProductBrandBannerSettings() {
  const { loading, detail } = useSelector(
    state => state.getProductBrandBannerSetting,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductBrandBannerSetting());
  }, []);

  const { values, setValues, errors, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      bannerColor: 'white',
      bannerUrl: '',
      bannerUrl_mobile: '',
      banner_files: [],
      banner_files_mobile: [],
      banner_init_files: [],
      banner_init_files_mobile: [],
      fbLink: '',
      igLink: '',
      introduction_en: '',
      introduction: '',
      lineLink: '',
      logoUrl: '',
      logo_files: [],
      logo_init_files: [],
      navColor: 'white',
      pinLink: '',
      status: '停用',
      twitterLink: '',
    },
    onSubmit: async values => {
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        if (!key.includes('files')) {
          if (typeof values[key] === 'object') {
            formData.append(key, JSON.stringify(values[key]));
          } else {
            if (values[key] === null) {
              formData.append(key, 0);
            } else {
              formData.append(key, String(values[key]));
            }
          }
        }
      });

      formData.set('pinLink', values?.pinLink ?? '');
      formData.set('fbLink', values?.fbLink ?? '');
      formData.set('igLink', values?.igLink ?? '');
      formData.set('lineLink', values?.lineLink ?? '');
      formData.set('twitterLink', values?.twitterLink ?? '');

      if (values.banner_files.length > 0) {
        formData.set('banner_files', values.banner_files[0]);
      } else {
        if (values.bannerImg && values.bannerUrl) {
          formData.set(
            'banner_files',
            getImageDataFromSetting(values.bannerImg, values.bannerUrl)[0],
          );
        }
      }

      if (values.banner_files_mobile.length > 0) {
        formData.set('banner_files_mobile', values.banner_files_mobile[0]);
      } else {
        if (values.bannerImg_mobile && values.bannerUrl_mobile) {
          formData.set(
            'banner_files_mobile',
            getImageDataFromSetting(
              values.bannerImg_mobile,
              values.bannerUrl_mobile,
            )[0],
          );
        }
      }

      if (values.logo_files.length > 0) {
        formData.set('logo_files', values.logo_files[0]);
      } else {
        if (values.logoImg && values.logoUrl) {
          formData.set(
            'logo_files',
            getImageDataFromSetting(values.logoImg, values.logoUrl)[0],
          );
        }
      }
      dispatch({
        type: 'PRODUCT_BRAND_BANNER_SETTING_REQUEST',
      });

      try {
        await saveProductBrandBannerSetting(formData);
        dispatch(getProductBrandBannerSetting());

        MessageUtil.alertSuccess('更新成功');
      } catch (e) {
        MessageUtil.alertWanring(e);
      }
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      bannerColor: Yup.string().required('請選擇顏色'),
      banner_files: Yup.array().min(1, '請上傳圖片'),
      banner_files_mobile: Yup.array().min(1, '請上傳圖片'),
      introduction_en: Yup.string().required('請輸入Banner文字(英文)'),
      introduction: Yup.string().required('請輸入Banner文字'),
      logo_files: Yup.array().min(1, '請上傳圖片'),
      navColor: Yup.string().required('請選擇顏色'),
    }),
  });

  useEffect(() => {
    if (!loading && detail !== null) {
      setValues({
        ...detail,
        bannerColor: detail.bannerColor || 'black',
        banner_files: getImageDataFromSetting(
          detail.bannerImg,
          detail.bannerUrl,
        ),
        banner_files_mobile: getImageDataFromSetting(
          detail.bannerImg_mobile,
          detail.bannerUrl_mobile,
        ),
        banner_init_files: getImageDataFromSetting(
          detail.bannerImg,
          detail.bannerUrl,
        ),
        banner_init_files_mobile: getImageDataFromSetting(
          detail.bannerImg_mobile,
          detail.bannerUrl_mobile,
        ),
        introduction_en: detail.introduction_en || '',
        introduction: detail.introduction || '',
        logo_files: getImageDataFromSetting(detail.logoImg, detail.logoUrl),
        logo_init_files: getImageDataFromSetting(
          detail.logoImg,
          detail.logoUrl,
        ),
        navColor: detail.navColor || 'black',
        status: detail.status,
      });
    }
  }, [loading, detail]);
  return (
    <Layout
      pageTitle="商城品牌形象圖設定"
      items={[
        { isActive: false, page_name: '網頁設定' },
        { isActive: true, page_name: '商城品牌形象圖設定' },
      ]}
    >
      <Card className={style.card}>
        <CardBody>
          <div className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                功能開關
              </label>
              <div className="form-item">
                <div className="d-flex">
                  {[
                    { label: '啟用', value: '啟用' },
                    {
                      label: '停用',
                      value: '停用',
                    },
                  ].map(option => {
                    return (
                      <div
                        className="me-3"
                        key={`status${JSON.stringify(option)}`}
                      >
                        <input
                          name={`status${JSON.stringify(option)}`}
                          type="radio"
                          value={option.value}
                          checked={values.status === option.value}
                          onChange={() => {
                            setFieldValue('status', option.value);
                          }}
                        />
                        {option.label}
                      </div>
                    );
                  })}
                  <div className="form-error">{errors.status}</div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                品牌形象圖(電腦版)
              </label>
              <div className="form-item">
                <UploadFileBox
                  formFieldName="banner_files"
                  currentFileFieldName="banner_init_files"
                  uplodModel="all"
                  displayFileWidth="1920"
                  displayFileHeight="不限"
                  displayFileSize="5MB"
                  initFiles={values.banner_init_files}
                  setFormFieldValue={(field, files) => {
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('banner_files', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('bannerUrl', '');
                      setFieldValue('banner_files', []);
                    }
                  }}
                />
                <div name="banner_files" className="form-error">
                  {errors.banner_files}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                品牌形象圖(手機板)
              </label>
              <div className="form-item">
                <UploadFileBox
                  formFieldName="banner_files_mobile"
                  currentFileFieldName="banner_init_files_mobile"
                  uplodModel="all"
                  displayFileWidth="800"
                  displayFileHeight="不限"
                  displayFileSize="5MB"
                  initFiles={values.banner_init_files_mobile}
                  setFormFieldValue={(field, files) => {
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('banner_files_mobile', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('bannerUrl_mobile', '');
                      setFieldValue('banner_files_mobile', []);
                    }
                  }}
                />
                <div name="banner_files" className="form-error">
                  {errors.banner_files_mobile}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                品牌logo
              </label>
              <div className="form-item brand-logo-color">
                <UploadFileBox
                  formFieldName="logo_files"
                  currentFileFieldName="logo_init_files"
                  uplodModel="svg"
                  displayFileWidth="不限"
                  displayFileHeight="不限"
                  displayFileSize="1MB"
                  initFiles={values.logo_init_files}
                  setFormFieldValue={(field, files) => {
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        setFieldValue('logo_files', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      setFieldValue('logoUrl', '');
                      setFieldValue('logo_files', []);
                    }
                  }}
                />
                <div className="form-error">{errors?.logo_files}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                主選單圖標顏色
              </label>
              <div className="form-item">
                <div className="d-flex">
                  {[
                    { label: '黑色', value: 'black' },
                    {
                      label: '白色',
                      value: 'white',
                    },
                  ].map(option => {
                    return (
                      <div
                        className="me-3"
                        key={`navColor${JSON.stringify(option)}`}
                      >
                        <input
                          name={`navColor${JSON.stringify(option)}`}
                          type="radio"
                          value={option.value}
                          checked={values.navColor === option.value}
                          onChange={() => {
                            setFieldValue('navColor', option.value);
                          }}
                        />
                        {option.label}
                      </div>
                    );
                  })}

                  <div className="form-error">{errors.navColor}</div>
                </div>
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                品牌形象圖logo/文字顏色
              </label>
              <div className="form-item">
                <div className="d-flex">
                  {[
                    { label: '黑色', value: 'black' },
                    {
                      label: '白色',
                      value: 'white',
                    },
                  ].map(option => {
                    return (
                      <div
                        className="me-3"
                        key={`bannerColor${JSON.stringify(option)}`}
                      >
                        <input
                          name={`bannerColor${JSON.stringify(option)}`}
                          type="radio"
                          value={option.value}
                          checked={values.bannerColor === option.value}
                          onChange={() => {
                            setFieldValue('bannerColor', option.value);
                          }}
                        />
                        {option.label}
                      </div>
                    );
                  })}

                  <div className="form-error">{errors.bannerColor}</div>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle} className="required">
                Banner文字
              </label>
              <div className="form-item">
                <Input
                  tag="textarea"
                  rows={4}
                  name="introduction"
                  className="form-control"
                  value={values.introduction}
                  onChange={e => {
                    setFieldValue('introduction', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.introduction}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle} className="required">
                Banner文字(英文)
              </label>
              <div className="form-item">
                <Input
                  tag="textarea"
                  rows={4}
                  name="banner"
                  className="form-control"
                  value={values.introduction_en}
                  onChange={e => {
                    setFieldValue('introduction_en', e.target.value);
                  }}
                />
                <div className="form-error">{errors?.introduction_en}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>FB連結</label>
              <div className="form-item">
                <Input
                  name="fbLink"
                  className="form-control"
                  value={values.fbLink}
                  onChange={e => {
                    setFieldValue('fbLink', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>IG連結</label>
              <div className="form-item">
                <Input
                  name="igLink"
                  className="form-control"
                  value={values.igLink}
                  onChange={e => {
                    setFieldValue('igLink', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>Line連結</label>
              <div className="form-item">
                <Input
                  name="lineLink"
                  className="form-control"
                  value={values.lineLink}
                  onChange={e => {
                    setFieldValue('lineLink', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>Twitter連結</label>
              <div className="form-item">
                <Input
                  name="twitterLink"
                  className="form-control"
                  value={values.twitterLink}
                  onChange={e => {
                    setFieldValue('twitterLink', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>Pin連結</label>
              <div className="form-item">
                <Input
                  name="pinLink"
                  className="form-control"
                  value={values.pinLink}
                  onChange={e => {
                    setFieldValue('pinLink', e.target.value);
                  }}
                />
              </div>
            </FormGroup>
          </div>
          <p className={`${style.fs_14} mb-0 mt-5`}>
            異動時間： {detail?.updateTime || '--'} {detail?.updater || '--'}
          </p>
          <hr />
          <div className="d-flex justify-content-center">
            <Button
              className={style.modal_button_submit}
              disabled={loading || Object.keys(errors).length > 0}
              onClick={() => {
                handleSubmit();
              }}
            >
              {loading && (
                <span
                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              <span className="btn-wrapper--label">儲存</span>
            </Button>
          </div>
        </CardBody>
      </Card>
    </Layout>
  );
}

export default ProductBrandBannerSettings;
