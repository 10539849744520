export const MEMBER_LIST_REQUEST = 'MEMBER_LIST_REQUEST';
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS';
export const MEMBER_LIST_FAIL = 'MEMBER_LIST_FAIL';

export const MEMBER_INFO_GET_REQUEST = 'MEMBER_INFO_GET_REQUEST';
export const MEMBER_INFO_GET_SUCCESS = 'MEMBER_INFO_GET_SUCCESS';
export const MEMBER_INFO_GET_FAIL = 'MEMBER_INFO_GET_FAIL';

export const MEMBER_INFO_UPDATE_REQUEST = 'MEMBER_INFO_UPDATE_REQUEST';
export const MEMBER_INFO_UPDATE_SUCCESS = 'MEMBER_INFO_UPDATE_SUCCESS';
export const MEMBER_INFO_UPDATE_FAIL = 'MEMBER_INFO_UPDATE_FAIL';
export const MEMBER_INFO_UPDATE_RESET = 'MEMBER_INFO_UPDATE_RESET';

export const GET_MEMBER_LEVEL_SETTINGS_REQUEST =
  'GET_MEMBER_LEVEL_SETTINGS_REQUEST';
export const GET_MEMBER_LEVEL_SETTINGS_SUCCESS =
  'GET_MEMBER_LEVEL_SETTINGS_SUCCESS';
export const GET_MEMBER_LEVEL_SETTINGS_FAIL = 'GET_MEMBER_LEVEL_SETTINGS_FAIL';

export const GET_MEMBER_SHIP_GIFT_REQUEST = 'GET_MEMBER_SHIP_GIFT_REQUEST';
export const GET_MEMBER_SHIP_GIFT_SUCCESS = 'GET_MEMBER_SHIP_GIFT_SUCCESS';
export const GET_MEMBER_SHIP_GIFT_FAIL = 'GET_MEMBER_SHIP_GIFT_FAIL';

export const ADD_MEMBER_LEVEL_SETTINGS_IDLE = 'ADD_MEMBER_LEVEL_SETTINGS_IDLE';
export const ADD_MEMBER_LEVEL_SETTINGS_REQUEST =
  'ADD_MEMBER_LEVEL_SETTINGS_REQUEST';
export const ADD_MEMBER_LEVEL_SETTINGS_SUCCESS =
  'ADD_MEMBER_LEVEL_SETTINGS_SUCCESS';
export const ADD_MEMBER_LEVEL_SETTINGS_FAIL = 'ADD_MEMBER_LEVEL_SETTINGS_FAIL';

export const UPDATE_MEMBER_LEVEL_SETTINGS_IDLE =
  'UPDATE_MEMBER_LEVEL_SETTINGS_IDLE';
export const UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST =
  'UPDATE_MEMBER_LEVEL_SETTINGS_REQUEST';
export const UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS =
  'UPDATE_MEMBER_LEVEL_SETTINGS_SUCCESS';
export const UPDATE_MEMBER_LEVEL_SETTINGS_FAIL =
  'UPDATE_MEMBER_LEVEL_SETTINGS_FAIL';

export const UPDATE_MEMBER_LEVEL_IDLE = 'UPDATE_MEMBER_LEVEL_IDLE';
export const UPDATE_MEMBER_LEVEL_REQUEST = 'UPDATE_MEMBER_LEVEL_REQUEST';
export const UPDATE_MEMBER_LEVEL_SUCCESS = 'UPDATE_MEMBER_LEVEL_SUCCESS';
export const UPDATE_MEMBER_LEVEL_FAIL = 'UPDATE_MEMBER_LEVEL_FAIL';

export const GET_MEMBER_LEVEL_OPTIONS_REQUEST =
  'GET_MEMBER_LEVEL_OPTIONS_REQUEST';
export const GET_MEMBER_LEVEL_OPTIONS_SUCCESS =
  'GET_MEMBER_LEVEL_OPTIONS_SUCCESS';
export const GET_MEMBER_LEVEL_OPTIONS_FAIL = 'GET_MEMBER_LEVEL_OPTIONS_FAIL';

export const EXPORT_MEMBER_INFO_IDLE = 'EXPORT_MEMBER_INFO_IDLE';
export const EXPORT_MEMBER_INFO_REQUEST = 'EXPORT_MEMBER_INFO_REQUEST';
export const EXPORT_MEMBER_INFO_SUCCESS = 'EXPORT_MEMBER_INFO_SUCCESS';
export const EXPORT_MEMBER_INFO_FAIL = 'EXPORT_MEMBER_INFO_FAIL';

export const IMPORT_MEMBER_INFO_IDLE = 'IMPORT_MEMBER_INFO_IDLE';
export const IMPORT_MEMBER_INFO_REQUEST = 'IMPORT_MEMBER_INFO_REQUEST';
export const IMPORT_MEMBER_INFO_SUCCESS = 'IMPORT_MEMBER_INFO_SUCCESS';
export const IMPORT_MEMBER_INFO_FAIL = 'IMPORT_MEMBER_INFO_FAIL';

export const COPY_MEMBER_LEVEL_LIST_IDLE = 'COPY_MEMBER_LEVEL_LIST_IDLE';
export const COPY_MEMBER_LEVEL_LIST_REQUEST = 'COPY_MEMBER_LEVEL_LIST_REQUEST';
export const COPY_MEMBER_LEVEL_LIST_SUCCESS = 'COPY_MEMBER_LEVEL_LIST_SUCCESS';
export const COPY_MEMBER_LEVEL_LIST_FAIL = 'COPY_MEMBER_LEVEL_LIST_FAIL';

export const DELETE_MEMBER_LEVEL_DRAFT_IDLE = 'DELETE_MEMBER_LEVEL_DRAFT_IDLE';
export const DELETE_MEMBER_LEVEL_DRAFT_REQUEST =
  'DELETE_MEMBER_LEVEL_DRAFT_REQUEST';
export const DELETE_MEMBER_LEVEL_DRAFT_SUCCESS =
  'DELETE_MEMBER_LEVEL_DRAFT_SUCCESS';
export const DELETE_MEMBER_LEVEL_DRAFT_FAIL = 'DELETE_MEMBER_LEVEL_DRAFT_FAIL';

export const UPDATE_MEMBER_LEVEL_DRAFT_IDLE = 'UPDATE_MEMBER_LEVEL_DRAFT_IDLE';
export const UPDATE_MEMBER_LEVEL_DRAFT_REQUEST =
  'UPDATE_MEMBER_LEVEL_DRAFT_REQUEST';
export const UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS =
  'UPDATE_MEMBER_LEVEL_DRAFT_SUCCESS';
export const UPDATE_MEMBER_LEVEL_DRAFT_FAIL = 'UPDATE_MEMBER_LEVEL_DRAFT_FAIL';

export const GET_PUBLISH_DATE_REQUEST = 'GET_PUBLISH_DATE_REQUEST';
export const GET_PUBLISH_DATE_SUCCESS = 'GET_PUBLISH_DATE_SUCCESS';
export const GET_PUBLISH_DATE_FAIL = 'GET_PUBLISH_DATE_FAIL';

export const UPDATE_PUBLISH_DATE_IDLE = 'UPDATE_PUBLISH_DATE_IDLE';
export const UPDATE_PUBLISH_DATE_REQUEST = 'UPDATE_PUBLISH_DATE_REQUEST';
export const UPDATE_PUBLISH_DATE_SUCCESS = 'UPDATE_PUBLISH_DATE_SUCCESS';
export const UPDATE_PUBLISH_DATE_FAIL = 'UPDATE_PUBLISH_DATE_FAIL';

export const DELETE_ONE_DRAFT_IDLE = 'DELETE_ONE_DRAFT_IDLE';
export const DELETE_ONE_DRAFT_REQUEST = 'DELETE_ONE_DRAFT_REQUEST';
export const DELETE_ONE_DRAFT_SUCCESS = 'DELETE_ONE_DRAFT_SUCCESS';
export const DELETE_ONE_DRAFT_FAIL = 'DELETE_ONE_DRAFT_FAIL';
