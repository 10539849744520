import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  addCafeJourneDetail,
  getCafeJourneDetail,
  updateCafeJourneDetail,
} from '../../redux/actions/data/uuCafeJourneAction';
import { UUCAFE_JOURNEY_SUCCESS } from '../../redux/constants/data/uuCafeJourneyConstants';
import style from '../../styles/layout.module.css';

const JourneyModel = ({
  detail = {
    content: '',
    content_en: '',
    yyyymm: '',
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const {
    loading,
    success,
    error,
    detail: initData,
  } = useSelector(state => state.cafeJourneyDetail);
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const param = {
        content: props.values.content,
        content_en: props.values.content_en,
        id: detail.id || 0,
        yyyymm: props.values.yyyymm,
      };

      if (detail.id) {
        dispatch(updateCafeJourneDetail(param));
      } else {
        dispatch(addCafeJourneDetail(param));
      }
    },
    [detail],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    if (detail.id) {
      dispatch(getCafeJourneDetail(detail));
    } else {
      dispatch({
        payload: detail,
        type: UUCAFE_JOURNEY_SUCCESS,
      });
    }
  }, [detail.id]);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      yyyymm: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>里程碑編輯</ModalHeader>

        {(!loading || isSubmit.current) && (
          <Formik
            initialValues={{
              content: initData.content,
              content_en: initData.content_en,
              yyyymm: initData.yyyymm,
            }}
            validationSchema={getValidationSchema()}
          >
            {props => (
              <Form className="form-list form-label-140">
                <ModalBody className={style.modal_body}>
                  <div className="form-item">
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*年/月</label>
                      <div className="form-item">
                        <DatePicker
                          className="form-control"
                          dateFormat="yyyy/MM"
                          showMonthYearPicker
                          selected={
                            new Date([props.values.yyyymm || new Date()])
                          }
                          onChange={date => {
                            props.values.yyyymm = `${date.getFullYear()}/${
                              date.getMonth() + 1
                            }`;
                            props.setFieldValue(
                              'yyyymm',
                              `${date.getFullYear()}/${date.getMonth() + 1}`,
                            );
                          }}
                        />
                        <ErrorMessage
                          name="yyyymm"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*內容(中文)</label>
                      <div className="form-item">
                        <Field
                          name="content"
                          className="form-control"
                          component="textarea"
                          row="5"
                        />
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*內容(英文)</label>
                      <div className="form-item">
                        <Field
                          name="content_en"
                          className="form-control"
                          component="textarea"
                          row="5"
                        />
                        <ErrorMessage
                          name="content_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                  </div>
                  <div style={{ height: '150px' }} />
                  <hr />
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  <Button
                    color="light"
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={() => {
                      setOpenModel(false, false);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    disabled={loading}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span>儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default memo(JourneyModel);
