export const SETTING_GET_REQUEST = 'SETTING_GET_REQUEST';
export const SETTING_GET_SUCCESS = 'SETTING_GET_SUCCESS';
export const SETTING_GET_FAIL = 'SETTING_GET_FAIL';

export const SETTING_EDIT_REQUEST = 'SETTING_EDIT_REQUEST';
export const SETTING_EDIT_SUCCESS = 'SETTING_EDIT_SUCCESS';
export const SETTING_EDIT_FAIL = 'SETTING_EDIT_FAIL';
export const SETTING_EDIT_RESET = 'SETTING_EDIT_RESET';

export const ABOUT_INFO_GET_REQUEST = 'ABOUT_INFO_GET_REQUEST';
export const ABOUT_INFO_GET_SUCCESS = 'ABOUT_INFO_GET_SUCCESS';
export const ABOUT_INFO_GET_FAIL = 'ABOUT_INFO_GET_FAIL';

export const ABOUT_INFO_EDIT_REQUEST = 'ABOUT_INFO_EDIT_REQUEST';
export const ABOUT_INFO_EDIT_SUCCESS = 'ABOUT_INFO_EDIT_SUCCESS';
export const ABOUT_INFO_EDIT_FAIL = 'ABOUT_INFO_EDIT_FAIL';
export const ABOUT_INFO_EDIT_RESET = 'ABOUT_INFO_EDIT_RESET';
