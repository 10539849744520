import {
  LOG_RECORD_REQUEST,
  LOG_RECORD_SUCCESS,
  LOG_RECORD_FAIL,
  EXPORT_LOG_RECORD_REQUEST,
  EXPORT_LOG_RECORD_SUCCESS,
  EXPORT_LOG_RECORD_FAIL,
} from 'redux/constants/data/logRecordConstant';
import { useRequest } from 'utils/useFetch';

export const getLogRecordList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: LOG_RECORD_FAIL,
      method: 'post',
      requestConstant: LOG_RECORD_REQUEST,
      successConstant: LOG_RECORD_SUCCESS,
      url: '/Backend/LogRecord/list',
    });
  };
};

export const exportLogRecordList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: EXPORT_LOG_RECORD_FAIL,
      method: 'post',
      requestConstant: EXPORT_LOG_RECORD_REQUEST,
      successConstant: EXPORT_LOG_RECORD_SUCCESS,
      url: '/Backend/LogRecord/Export',
    });
  };
};
