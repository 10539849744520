import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateProductOptionsState } from 'redux/actions/data/productActions';
import { PRODUCT_OPTIONS_STATE_UPDATE_IDLE } from 'redux/constants/data/productConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';

const EditStockNumberModal = ({ open, toggle, info, onUpdate }) => {
  const dispatch = useDispatch();
  const { error: updateProductOptionError, success } = useSelector(
    state => state.productOptionsUpdate,
  );
  const { values, handleSubmit, handleChange, isSubmitting } = useFormik({
    enableReinitialize: true,
    initialValues: {
      num: info.num,
      result: info.result,
      type: 'stock',
    },
    onSubmit: values => {
      const params = {
        ...values,
        brandId: Number(values.brandId),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: params });
      dispatch(updateProductOptionsState({ data: params, pId: info.id }));
    },
  });

  useEffect(() => {
    if (updateProductOptionError) {
      MessageUtil.alertWanring('儲存失敗', updateProductOptionError);
      return;
    }
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      toggle();
      dispatch({ type: PRODUCT_OPTIONS_STATE_UPDATE_IDLE });
      onUpdate();
    }
  }, [success, updateProductOptionError]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        商品庫存修改
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.show_flex}>
          <label className="required w80">數量</label>
          <Input
            type="number"
            id="num"
            name="num"
            className={style['form-input-short']}
            value={values.num}
            onChange={handleChange}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditStockNumberModal;
