export const ALSO_LIKE_BRAND_REQUEST = 'ALSO_LIKE_BRAND_REQUEST';
export const ALSO_LIKE_BRAND_SUCCESS = 'ALSO_LIKE_BRAND_SUCCESS';
export const ALSO_LIKE_BRAND_FAIL = 'ALSO_LIKE_BRAND_FAIL';

export const ALSO_LIKE_BRAND_PRODUCTS_REQUEST =
  'ALSO_LIKE_BRAND_PRODUCTS_REQUEST';
export const ALSO_LIKE_BRAND_PRODUCTS_SUCCESS =
  'ALSO_LIKE_BRAND_PRODUCTS_SUCCESS';
export const ALSO_LIKE_BRAND_PRODUCTS_FAIL = 'ALSO_LIKE_BRAND_PRODUCTS_FAIL';

export const ALSO_LIKE_PRODUCT_DELETE_REQUEST =
  'ALSO_LIKE_PRODUCT_DELETE_REQUEST';
export const ALSO_LIKE_PRODUCT_DELETE_SUCCESS =
  'ALSO_LIKE_PRODUCT_DELETE_SUCCESS';
export const ALSO_LIKE_PRODUCT_DELETE_FAIL = 'ALSO_LIKE_PRODUCT_DELETE_FAIL';
export const ALSO_LIKE_PRODUCT_DELETE_RESET = 'ALSO_LIKE_PRODUCT_DELETE_RESET';

export const ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST =
  'ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST';
export const ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS =
  'ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS';
export const ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL =
  'ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL';

export const ALSO_LIKE_PRODUCT_ADD_REQUEST = 'ALSO_LIKE_PRODUCT_ADD_REQUEST';
export const ALSO_LIKE_PRODUCT_ADD_SUCCESS = 'ALSO_LIKE_PRODUCT_ADD_SUCCESS';
export const ALSO_LIKE_PRODUCT_ADD_FAIL = 'ALSO_LIKE_PRODUCT_ADD_FAIL';
export const ALSO_LIKE_PRODUCT_ADD_RESET = 'ALSO_LIKE_PRODUCT_ADD_RESET';

export const ALSO_LIKE_TOP_PRODUCT_REQUEST = 'ALSO_LIKE_TOP_PRODUCT_REQUEST';
export const ALSO_LIKE_TOP_PRODUCT_SUCCESS = 'ALSO_LIKE_TOP_PRODUCT_SUCCESS';
export const ALSO_LIKE_TOP_PRODUCT_FAIL = 'ALSO_LIKE_TOP_PRODUCT_FAIL';

export const BEST_SELLER_BRAND_REQUEST = 'BEST_SELLER_BRAND_REQUEST';
export const BEST_SELLER_BRAND_SUCCESS = 'BEST_SELLER_BRAND_SUCCESS';
export const BEST_SELLER_BRAND_FAIL = 'BEST_SELLER_BRAND_FAIL';

export const BEST_SELLER_BRAND_PRODUCTS_REQUEST =
  'BEST_SELLER_BRAND_PRODUCTS_REQUEST';
export const BEST_SELLER_BRAND_PRODUCTS_SUCCESS =
  'BEST_SELLER_BRAND_PRODUCTS_SUCCESS';
export const BEST_SELLER_BRAND_PRODUCTS_FAIL =
  'BEST_SELLER_BRAND_PRODUCTS_FAIL';

export const BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST =
  'BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST';
export const BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS =
  'BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS';
export const BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL =
  'BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL';

export const BEST_SELLER_PRODUCT_ADD_REQUEST =
  'BEST_SELLER_PRODUCT_ADD_REQUEST';
export const BEST_SELLER_PRODUCT_ADD_SUCCESS =
  'BEST_SELLER_PRODUCT_ADD_SUCCESS';
export const BEST_SELLER_PRODUCT_ADD_FAIL = 'BEST_SELLER_PRODUCT_ADD_FAIL';
export const BEST_SELLER_PRODUCT_ADD_RESET = 'BEST_SELLER_PRODUCT_ADD_RESET';

export const BEST_SELLER_PRODUCT_DELETE_REQUEST =
  'BEST_SELLER_PRODUCT_DELETE_REQUEST';
export const BEST_SELLER_PRODUCT_DELETE_SUCCESS =
  'BEST_SELLER_PRODUCT_DELETE_SUCCESS';
export const BEST_SELLER_PRODUCT_DELETE_FAIL =
  'BEST_SELLER_PRODUCT_DELETE_FAIL';
export const BEST_SELLER_PRODUCT_DELETE_RESET =
  'BEST_SELLER_PRODUCT_DELETE_RESET';

export const BEST_SELLER_TOP_PRODUCTS_REQUEST =
  'BEST_SELLER_TOP_PRODUCTS_REQUEST';
export const BEST_SELLER_TOP_PRODUCTS_SUCCESS =
  'BEST_SELLER_TOP_PRODUCTS_SUCCESS';
export const BEST_SELLER_TOP_PRODUCTS_FAIL = 'BEST_SELLER_TOP_PRODUCTS_FAIL';

export const DISCOUNT_CODE_LIST_REQUEST = 'DISCOUNT_CODE_LIST_REQUEST';
export const DISCOUNT_CODE_LIST_SUCCESS = 'DISCOUNT_CODE_LIST_SUCCESS';
export const DISCOUNT_CODE_LIST_FAIL = 'DISCOUNT_CODE_LIST_FAIL';

export const DISCOUNT_CODE_POST_REQUEST = 'DISCOUNT_CODE_POST_REQUEST';
export const DISCOUNT_CODE_POST_SUCCESS = 'DISCOUNT_CODE_POST_SUCCESS';
export const DISCOUNT_CODE_POST_FAIL = 'DISCOUNT_CODE_POST_FAIL';
export const DISCOUNT_CODE_POST_RESET = 'DISCOUNT_CODE_POST_RESET';

export const DISCOUNT_CODE_DELETE_REQUEST = 'DISCOUNT_CODE_DELETE_REQUEST';
export const DISCOUNT_CODE_DELETE_SUCCESS = 'DISCOUNT_CODE_DELETE_SUCCESS';
export const DISCOUNT_CODE_DELETE_FAIL = 'DISCOUNT_CODE_DELETE_FAIL';
export const DISCOUNT_CODE_DELETE_RESET = 'DISCOUNT_CODE_DELETE_RESET';

export const DISCOUNT_IMAGE_LIST_REQUEST = 'DISCOUNT_IMAGE_LIST_REQUEST';
export const DISCOUNT_IMAGE_LIST_SUCCESS = 'DISCOUNT_IMAGE_LIST_SUCCESS';
export const DISCOUNT_IMAGE_LIST_FAIL = 'DISCOUNT_IMAGE_LIST_FAIL';

export const DISCOUNT_IMAGE_POST_REQUEST = 'DISCOUNT_IMAGE_POST_REQUEST';
export const DISCOUNT_IMAGE_POST_SUCCESS = 'DISCOUNT_IMAGE_POST_SUCCESS';
export const DISCOUNT_IMAGE_POST_FAIL = 'DISCOUNT_IMAGE_POST_FAIL';
export const DISCOUNT_IMAGE_POST_RESET = 'DISCOUNT_IMAGE_POST_RESET';

export const DISCOUNT_IMAGE_DELETE_REQUEST = 'DISCOUNT_IMAGE_DELETE_REQUEST';
export const DISCOUNT_IMAGE_DELETE_SUCCESS = 'DISCOUNT_IMAGE_DELETE_SUCCESS';
export const DISCOUNT_IMAGE_DELETE_FAIL = 'DISCOUNT_IMAGE_DELETE_FAIL';
export const DISCOUNT_IMAGE_DELETE_RESET = 'DISCOUNT_IMAGE_DELETE_RESET';
