import DiscountDetailModal from 'components/DiscountDetailModal';
import Layout from 'components/Layout';
import OrderRemarkModal from 'components/OrderRemarkModal';
import OrderReturnDescModal from 'components/OrderReturnDescModal';
import SearchSelect from 'components/OrderSearchSelector';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect } from 'react';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, Input, Table } from 'reactstrap';
import { getOrderList, getOrderOptions } from 'redux/actions/data/orderActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import { useToggle } from 'utils/customHooks';
import request from 'utils/useAxios';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const TableList = ({
                     onChangePage,
                     onRefresh,
                     onExportOrders,
                     setIsDiscountActivityModalOpen,
                     setIsDiscountModalOpen,
                     setCurrentDiscountInfo,
                   }) => {
  const { loading, orderListInfo, error } = useSelector(
      state => state.orderList,
  );

  const isSticky = orderListInfo?.datas?.length > 0 ? { height: '80vh' } : {};
  const [descOpen, setDescOpen] = useState(false);
  const [currentDescInfo, setCurrentDescInfo] = useState(null);
  const handleToggleDesc = () => {
    setDescOpen(!descOpen);
  };

  const [orderInfo, setOrderInfo] = useState(null);
  const [remarkOpen, setRemarkOpen] = useState(false);
  const toggleRemark = () => {
    setRemarkOpen(!remarkOpen);
  };

  return (
      <Card className={style.card}>
        <div className="mb-3">
          <Button
              className="me-2 w100"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                onExportOrders();
              }}
          >
            匯出訂單
          </Button>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
                className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
            <tr>
              <th>
                <div>訂單編號</div>
                <div>退貨單編號</div>
                <div>補收單號</div>
              </th>
              <th>
                <div>配送商</div>
                <div>配送編號</div>
              </th>
              <th>
                <div>退貨單狀態</div>
                <div>退貨成立時間</div>
                <div>退貨狀態時間</div>
              </th>
              <th>
                <div>退貨原因</div>
                <div>退貨描述</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>總折扣金額</div>
                <div>總銷售金額(折扣後)</div>
              </th>
              <th>
                <div>有無商品贈品</div>
                <div>有無活動贈品</div>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>贈品券活動序號</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>退貨聯絡人</div>
                <div>退貨聯絡人電話</div>
              </th>
              <th>
                <div>退貨國家</div>
                <div>退貨郵遞區號</div>
                <div>退貨城市</div>
                <div>退貨鄉鎮市區</div>
                <div>退貨地址</div>
              </th>
              <th>商店退貨備註</th>
            </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
                <tbody>
                {orderListInfo?.datas?.length > 0 ? (
                    orderListInfo?.datas?.map(i => (
                        <React.Fragment key={JSON.stringify(i)}>
                          <tr className="text-nowrap bg">
                            <td className="price text-black">
                              總金額：
                              {(i.totalAmount || '0')?.toLocaleString('en-US')}
                            </td>
                            <td colSpan="2">主單編號：{i.orderNumber}</td>
                            <td colSpan="2">
                              <div>
                                運費：
                                <span className="price text-black">
                            {i.deliveryCharge?.toLocaleString('en-US')}
                          </span>
                                {i.deliveryChargeReturnNumber && (
                                    <span>
                              （退運費申請 -
                              <span className={style.text_dark_info}>
                                {i.deliveryChargeReturnNumber}
                              </span>
                              )
                            </span>
                                )}
                              </div>
                            </td>
                            <td colSpan={5} className="text-end">
                              <FaChevronDown id={`orderId${i.id}`} />
                            </td>
                          </tr>
                          {i.orderItems.map((j, jIndex) => (
                              <tr key={JSON.stringify(j)}>
                                <td>
                                  <div className={style.text_dark_info}>
                                    {j.orderItemNumber || '--'}
                                  </div>
                                  <div>{j.returnNumber || '--'}</div>
                                  <div className={style.text_dark_info}>
                                    {j?.rechargeList.map(item => (
                                        <div key={JSON.stringify(item)}>
                                          {item.rechargeNumber}
                                        </div>
                                    )) || '--'}
                                  </div>
                                </td>
                                <td>
                                  <div>{j.returnHomeDeliveryCName || '--'}</div>
                                  <div>{j.returnHomeDeliveryNumber || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.returnStatusName || '--'}</div>
                                  <div>{j.returnCreateTime || '--'}</div>
                                  <div>{j.returnStatusTime || '--'}</div>
                                  {/* TODO 是否開立發票 */}
                                </td>
                                <td>
                                  <div>{j.returnReason || '--'}</div>
                                  <div className="text-clamp clamp3">
                                    {j.returnDesc || '--'}
                                  </div>
                                  {j.returnDesc && (
                                      <Button
                                          color="link"
                                          className="text-decoration-none"
                                          size="sm"
                                          onClick={() => {
                                            setCurrentDescInfo({
                                              returnDesc: j.returnDesc,
                                              returnReason: j.returnReason,
                                            });
                                            handleToggleDesc();
                                          }}
                                      >
                                        查看完整描述
                                      </Button>
                                  )}
                                </td>
                                <td>
                                  {/* 商品名稱 */}
                                  <div>{j.productname || '--'}</div>
                                  {/* 商品選項 */}
                                  <div>
                                    {j.color || '--'}/{j.size || '--'}
                                  </div>
                                  {/* 商品料號 */}
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                      }}
                                      onClick={() => {
                                        if (Number(j.productType) === 4) {
                                          window.open(
                                              `/#/GiftManagement?page=1&keyword=${
                                                  j.productname || j.freebieCode
                                              }`,
                                              '_blank',
                                          );
                                        } else if (Number(j.productType) === 1) {
                                          window.open(
                                              `/#/ProductControl?page=1&keyword=${
                                                  j.productname || j.productcode
                                              }`,
                                              '_blank',
                                          );
                                        }
                                      }}
                                  >
                                    {Number(j.productType) === 4
                                        ? j.freebieCode || '--'
                                        : j.productcode || '--'}
                                  </div>
                                  {/* 商品屬性 */}
                                  <div>
                                    {Number(j.productType) === 4
                                        ? j.freebieType || '--'
                                        : '商品'}
                                  </div>
                                </td>
                                <td className="text-end">
                                  <div>{j.quantity}</div>
                                  <div className="price text-black fw-normal">
                                    {j.unitPrice?.toLocaleString('en-US')}
                                  </div>
                                  {/* 折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end text-primary"
                                      role="button"
                                      onClick={() => {
                                        modal.showBillInfo({
                                          ...j,
                                          orderNumber: i.orderNumber,
                                        });
                                      }}
                                  >
                                    -
                                    <div className="price text-primary fw-normal discountAmount">
                                      {j?.discountAmount?.toLocaleString('en-US') ||
                                          0}
                                    </div>
                                  </div>
                                  <div className="price text-black fw-normal discountPrice">
                                    {j?.discountPrice?.toLocaleString('en-US') || 0}
                                  </div>
                                </td>
                                <td className="text-end">
                                  {/* 有無商品贈品 */}
                                  <div>--</div>
                                  {/* 有無活動贈品 */}
                                  <div>--</div>
                                  {/* 折扣活動總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountActivityModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.activityDiscountAmountUnit || '0'
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 折價券總折扣金額 */}
                                  <div
                                      className="d-flex justify-content-end"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setCurrentDiscountInfo(j.id);
                                        setIsDiscountModalOpen();
                                      }}
                                  >
                                    -
                                    <div
                                        className={`price fw-normal ${style.text_dark_info}`}
                                    >
                                      {(
                                          j.couponDiscountAmountUnit || 0
                                      )?.toLocaleString('en-US')}
                                    </div>
                                  </div>
                                  {/* 贈品券活動序號 */}
                                  <div>--</div>
                                </td>
                                <td>
                                  <div className="text-nowrap">
                                    {j.memberPhone || '--'}
                                  </div>
                                  <div>{j.memberLevel || '--'}</div>
                                  <div>{j.returnName || '--'}</div>
                                  <div>{j.returnPhone || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.returnCountryName || '--'}</div>
                                  <div>{j.returnZipcode || '--'}</div>
                                  <div>{j.returnCityName || '--'}</div>
                                  <div>{j.returnTownName || '--'}</div>
                                  <div>{j.returnAddress || '--'}</div>
                                </td>
                                <td>
                                  <div>{j.orderRemark || '--'}</div>
                                  <div
                                      style={{
                                        color: '#0077ff',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                      }}
                                      onClick={() => {
                                        setOrderInfo({
                                          requestBody: {
                                            orderItemId: j.id,
                                            orderRemark: j.orderRemark,
                                          },
                                          主單編號: i.orderNumber,
                                          商品名稱: j.productname,
                                          訂單編號: j.orderItemNumber,
                                        });
                                        toggleRemark();
                                      }}
                                  >
                                    編輯備註
                                  </div>
                                </td>
                              </tr>
                          ))}
                        </React.Fragment>
                    ))
                ) : (
                    <TableHasNoData />
                )}
                {error && <TableHasNoData />}
                </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
              current={orderListInfo?.current_page || 1}
              pageSize={orderListInfo?.page_size || 10}
              total={orderListInfo?.total_count || 0}
              onChange={onChangePage}
          />
        </div>
        {orderInfo && (
            <OrderRemarkModal
                isOpen={remarkOpen}
                toggle={toggleRemark}
                info={orderInfo}
                callBack={onRefresh}
            />
        )}
        <OrderReturnDescModal
            open={descOpen}
            toggle={handleToggleDesc}
            info={currentDescInfo}
        />
      </Card>
  );
};
const OrderReturnList = () => {
  const dispatch = useDispatch();
  const { orderOptionsInfo } = useSelector(state => state.orderOptions);

  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
      useToggle();
  const [currentDiscountInfo, setCurrentDiscountInfo] = useState(null);

  const query = useQuery();
  const { location } = history;

  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    dispatch(getOrderOptions());
  }, []);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      endTime: null,
      isReturnOrder: true,
      keyword: '',
      orderNumber: '',
      orderNumberType: '主單編號',
      page: 1,
      page_size: 10,
      startTime: null,
      timeType: '退貨單成立日期',
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit(formModel) {
      const body = {
        ...formModel,
        endTime: formModel.endTime
            ? moment(formModel.endTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        startTime: formModel.startTime
            ? moment(formModel.startTime).format('yyyy/MM/DD HH:mm:ss')
            : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
      };

      if (CommonUtil.isValueChanged(currentValues, body, setCurrentValues)) {
        const params = new URLSearchParams({
          ...body,
          endTime: JSON.stringify(body.endTime),
          startTime: JSON.stringify(body.startTime),
        });
        history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
      } else {
        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(getOrderList(body));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...values,
        ...query.value,
        endTime: query.value.endTime
            ? JSON.parse(query.value.endTime)
            : moment().format('yyyy/MM/DD HH:mm:ss'),
        isReturnOrder: true,
        keyword: `${query.value.keyword}`,
        orderNumber: `${query.value.orderNumber}`,
        startTime: query.value.startTime
            ? JSON.parse(query.value.startTime)
            : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
      };
      CommonUtil.consoleLog({ anno: 'request body', data: body });
      dispatch(getOrderList(body));
      setValues(body);
    } else {
      handleReset();
    }
  }, [location.search]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const exportOrdersInfoHandler = async () => {
    // 帶入當前頁面搜尋條件, 不包含頁碼
    const params = {
      ...values,
      endTime: values.endTime
          ? moment(values.endTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().format('yyyy/MM/DD HH:mm:ss'),
      startTime: values.startTime
          ? moment(values.startTime).format('yyyy/MM/DD HH:mm:ss')
          : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
    };
    isNaN(parseInt(values.logisticsStatus)) && delete params.logisticsStatus;
    isNaN(parseInt(values.shippingStatus)) && delete params.shippingStatus;

    delete params.page;

    CommonUtil.consoleLog({ anno: 'request body', data: params });
    const { code, message, result } = await request({
      data: params,
      method: 'post',
      url: '/Backend/Order/ExportReturn',
    });
    if (code !== 200) {
      MessageUtil.alertWanring('匯出失敗', message);
      return;
    }
    window.open(result);
    MessageUtil.alertSuccess('匯出成功!');
  };

  return (
      <Layout
          pageTitle="退貨訂單查詢"
          items={[
            { isActive: false, page_name: '退貨訂單管理' },
            { isActive: true, page_name: '退貨訂單查詢' },
          ]}
      >
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
            本頁面只出現主單狀態為【已取消】之訂單
          </span>
          </div>
        </Alert>
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form>
              <div className="d-flex flex-wrap w-100">
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label text-nowrap mr-2">
                    關鍵字
                  </label>
                  <input
                      type="text"
                      className={`form-control ${style['form-keyword']}`}
                      name="keyword"
                      placeholder="會員電話、收件人、收件人電話"
                      onChange={handleChange}
                      value={values.keyword}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2 text-nowrap">編號</label>
                  <Input
                      type="select"
                      className={style['form-select-medium']}
                      name="orderNumberType"
                      onChange={handleChange}
                      value={values.orderNumberType}
                      style={{ margin: 0 }}
                  >
                    <option value="主單編號">主單編號</option>
                    <option value="訂單編號">訂單編號</option>
                    <option value="退貨單編號">退貨單編號</option>
                  </Input>
                  <Input
                      type="text"
                      className={style['form-input-order-number']}
                      name="orderNumber"
                      onChange={handleChange}
                      value={values.orderNumber}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label mr-2">退貨單狀態</label>
                  <SearchSelect
                      className={style['form-select']}
                      options={orderOptionsInfo?.returnStatus}
                      emptyVal={''}
                      name="returnStatus"
                      setFieldValue={setFieldValue}
                      values={values}
                  />
                </div>
                <div
                    className={style.formGroup}
                    style={{ marginRight: '1.5rem' }}
                >
                  <label className="col-form-label me-2 text-nowrap">
                    日期區間
                  </label>
                  <Input
                      type="select"
                      className={style['form-select']}
                      name="timeType"
                      onChange={handleChange}
                      value={values.timeType}
                      style={{ margin: 0 }}
                  >
                    <option value="退貨單成立日期">退貨單成立日期</option>
                    <option value="退貨單狀態日期">退貨單狀態日期</option>
                  </Input>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.startTime
                            ? moment(values.startTime).toDate()
                            : moment().subtract(7, 'day').toDate()
                      }
                      name="startTime"
                      onChange={date => {
                        setFieldValue('startTime', date);
                      }}
                      selectsStart
                  />
                  <span style={{ margin: '1rem' }}>~</span>
                  <DatePicker
                      className="form-control"
                      popperClassName={style['form-control-date-picker']}
                      dateFormat="yyyy/MM/dd p"
                      showTimeSelect
                      selected={
                        values.endTime
                            ? moment(values.endTime).toDate()
                            : moment().toDate()
                      }
                      name="endTime"
                      onChange={date => {
                        setFieldValue('endTime', date);
                      }}
                      selectsEnd
                      minDate={values.startTime}
                  />
                </div>
              </div>
            </form>
            <div className={style.button_position}>
              <Button
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    handleReset();
                  }}
              >
                取消
              </Button>
              <Button
                  type="button"
                  className={style.modal_button_submit}
                  onClick={() => {
                    setFieldValue('page', 1);
                    handleSubmit();
                  }}
              >
                查詢
              </Button>
            </div>
          </div>
        </Card>
        <TableList
            onChangePage={handleChangePage}
            onRefresh={handleSubmit}
            onExportOrders={exportOrdersInfoHandler}
            setIsDiscountActivityModalOpen={setIsDiscountActivityModalOpen}
            setIsDiscountModalOpen={setIsDiscountModalOpen}
            setCurrentDiscountInfo={setCurrentDiscountInfo}
        />
        {isDiscountModalOpen && (
            <DiscountDetailModal
                isOpen={isDiscountModalOpen}
                toggle={setIsDiscountModalOpen}
                id={currentDiscountInfo}
            />
        )}
        {isDiscountActivityModalOpen && (
            <DiscountActivityDetailModal
                isOpen={isDiscountActivityModalOpen}
                toggle={setIsDiscountActivityModalOpen}
                id={currentDiscountInfo}
            />
        )}
      </Layout>
  );
};
export default OrderReturnList;
