export const UUCAFE_MEALINTRO_OTHER_LIST_REQUEST =
  'UUCAFE_MEALINTRO_OTHER_LIST_REQUEST';
export const UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS =
  'UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS';
export const UUCAFE_MEALINTRO_OTHER_LIST_FAIL =
  'UUCAFE_MEALINTRO_OTHER_LIST_FAIL';

export const UUCAFE_MEALINTRO_OTHER_REQUEST = 'UUCAFE_MEALINTRO_OTHER_REQUEST';
export const UUCAFE_MEALINTRO_OTHER_SUCCESS = 'UUCAFE_MEALINTRO_OTHER_SUCCESS';
export const UUCAFE_MEALINTRO_OTHER_FAIL = 'UUCAFE_MEALINTRO_OTHER_FAIL';

export const UUCAFE_MEALINTRO_OTHER_ADD_REQUEST =
  'UUCAFE_MEALINTRO_OTHER_ADD_REQUEST';
export const UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS =
  'UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS';
export const UUCAFE_MEALINTRO_OTHER_ADD_FAIL =
  'UUCAFE_MEALINTRO_OTHER_ADD_FAIL';

export const UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST =
  'UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST';
export const UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS =
  'UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS';
export const UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL =
  'UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL';

