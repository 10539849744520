import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { deleteMessageCategory } from 'redux/actions/data/messageActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

const CategoryTable = ({
  setIsEdit,
  setIsCategorySettingsModalOpen,
  setCurrentData,
}) => {
  const [flatList, setFlatList] = useState([]);
  const { list } = useSelector(state => state.getMessageCategoryList);
  const dispatch = useDispatch();

  useEffect(() => {
    let newDatas = [];
    if (list?.length > 0) {
      list?.forEach(data => {
        newDatas.push({
          ...data,
          isFlat: false,
          isShow: true,
        });
        data.cats.forEach(cate => {
          newDatas.push({
            ...cate,
            displaySort: `${data.sort}.${cate.sort}`,
            isShow: false,
          });
        });
      });
      CommonUtil.consoleLog({ anno: 'response', data: list });
    }
    setFlatList(newDatas);
  }, [list]);

  const toggleSubItems = (id, isFlat) => {
    const newList = flatList?.map((item, index) => {
      if (id === item.parentId && typeof item.cats === 'object') {
        return {
          ...item,
          isFlat: !isFlat,
        };
      }
      if (id === item.parentId) {
        return {
          ...item,
          isShow: !isFlat,
        };
      } else {
        return item;
      }
    });
    setFlatList(newList);
  };

  const deleteMessageCatHandler = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteMessageCategory(id));
    });
  };

  return (
    <div className="table-responsive">
      <Table hover bordered striped className="mb-3">
        <TableHead>
          <tr>
            <th>排序</th>
            <th>分類</th>
            <th>異動日期</th>
            <th>功能</th>
          </tr>
        </TableHead>
        <tbody>
          {flatList.length > 0 ? (
            flatList?.map(
              (data, i) =>
                data.isShow && (
                  <tr key={i}>
                    <td>
                      {data.cats?.length > 0 ? (
                        typeof data.cats === 'object' ? (
                          !data.isFlat ? (
                            <FaCaretDown
                              size="30"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                toggleSubItems(data.parentId, data.isFlat)
                              }
                            />
                          ) : (
                            <FaCaretUp
                              size="30"
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                toggleSubItems(data.parentId, data.isFlat)
                              }
                            />
                          )
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}{' '}
                      {data.displaySort || data.sort}
                    </td>
                    <td>{data.parent_name || data.name}</td>
                    <td>
                      <div>{data.updateTime}</div>
                      <div>{data.updater}</div>
                    </td>
                    <td>
                      <Button
                        color="primary"
                        outline
                        className="me-2 mb-2 btn-sm"
                        onClick={() => {
                          setIsEdit(true);
                          setIsCategorySettingsModalOpen();
                          setCurrentData(data);
                        }}
                      >
                        編輯
                      </Button>
                      {!data.usefor && (
                        <Button
                          color="danger"
                          outline
                          className="me-2 mb-2 btn-sm"
                          onClick={() => {
                            deleteMessageCatHandler(data.id || data.parentId);
                          }}
                        >
                          刪除
                        </Button>
                      )}
                    </td>
                  </tr>
                ),
            )
          ) : (
            <TableHasNoData colNumber={4} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CategoryTable;
