import Layout from 'components/Layout';
import DiscountTabPane from 'components/voucherSettings/discount/DiscountTabPane';
import GiftsTabPane from 'components/voucherSettings/gifts/GiftsTabPane';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Nav, TabContent, TabPane } from 'reactstrap';
import * as Yup from 'yup';

import { TabItem } from '../components/tabItem';
import { getMemberLevelOptions } from '../redux/actions/data/memberActions';
import {
  createVoucher,
  getVoucherDetail,
  updateVoucherDetail,
} from '../redux/actions/data/voucherActions';
import { GET_VOUCHER_DETAIL_SUCCESS } from '../redux/constants/data/voucherConstants';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import { getImageDataFromSetting } from '../utils/getImageDataFromSetting';

const VoucherSettings = () => {
  const initDate = moment().add(5, 'minute').toDate();
  const [currentTab, setCurrentTab] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const pageName = ['折價券', '贈品券'];
  const { detail } = useSelector(state => state.getVoucherDetail);
  const { memberLevelOptions } = useSelector(
    state => state.getMemberLevelOptions,
  );
  const type = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    switch (searchParams.get('type')) {
      case 'edit':
        return '編輯';
      case 'view':
        return '檢視';
      case 'copy':
        return '複製';
      default:
        return '新增';
    }
  }, [location?.search]);

  const isEditor = useMemo(() => {
    if (type === '編輯' && detail.id) {
      if (detail.getStartTime && moment(detail.getStartTime).isValid()) {
        return moment(detail.getStartTime).isBefore(moment());
      }
      return true;
    }
    return false;
  }, [type, detail]);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleSubmit = useCallback(
    async props => {
      if (type !== '檢視') {
        if (
          type === '新增' ||
          type === '複製' ||
          (type === '編輯' && !isEditor)
        ) {
          if (
            moment().isAfter(moment(props.values?.getStartTime || new Date()))
          ) {
            MessageUtil.alertWanring('領取或發送期限時間不可小於現在時間');
            return;
          }

          if (props.values.getWay === 4) {
            if (
              moment()
                .add(1, 'days')
                .startOf('day')
                .isAfter(moment(props.values?.getStartTime || new Date()))
            ) {
              MessageUtil.alertWanring('發送時間不可小於明天');
              return;
            }
          }
        }
        if (props.values.getWay !== 4) {
          if (
            moment(moment(props.values?.getStartTime || new Date())).isAfter(
              moment(props.values?.getEndTime || new Date()),
            )
          ) {
            MessageUtil.alertWanring('領取或發送期限結束時間不可小於開始時間');
            return;
          }

          if (
            moment().isAfter(moment(props.values?.getEndTime || new Date()))
          ) {
            MessageUtil.alertWanring('領取或發送期限結束時間不可小於現在時間');
            return;
          }
        }
        if (props.values.dueType === 1) {
          if (
            type === '新增' ||
            type === '複製' ||
            (type === '編輯' && !isEditor)
          ) {
            if (
              moment().isAfter(
                moment(props.values?.redeemStartTime || new Date()),
              )
            ) {
              MessageUtil.alertWanring('自訂日期區間開始時間不可小於現在時間');
              return;
            }

            if (
              moment(props.values?.getStartTime || new Date()).isAfter(
                moment(props.values?.redeemStartTime || new Date()),
              )
            ) {
              MessageUtil.alertWanring(
                '自訂日期區間開始時間不可小於領取或發送開始時間',
              );
              return;
            }
          }
          if (
            type === '新增' ||
            type === '複製' ||
            (type === '編輯' && !isEditor)
          ) {
            if (!props.values?.redeemEndTime) {
              props.setFieldValue(
                'redeemEndTime',
                moment(props.values?.redeemStartTime || new Date()).format(
                  'YYYY/MM/DD HH:mm:ss',
                ),
              );
              props.values.redeemEndTime = moment(
                props.values?.redeemStartTime || new Date(),
              ).format('YYYY/MM/DD HH:mm:ss');
            }

            if (
              moment(
                moment(props.values?.redeemStartTime || new Date()),
              ).isAfter(moment(props.values?.redeemEndTime || new Date()))
            ) {
              MessageUtil.alertWanring('自訂日期區間結束時間不可小於開始時間');
              return;
            }

            if (
              moment(moment(props.values?.getEndTime || new Date())).isAfter(
                moment(props.values?.redeemEndTime || new Date()),
              )
            ) {
              MessageUtil.alertWanring(
                '自訂日期區間結束時間不可小於領取或發送結束時間',
              );
              return;
            }
          }
        }

        const formData = new FormData();
        Object.keys(props.values).forEach(key => {
          if (typeof props.values[key] === 'object') {
            formData.append(key, JSON.stringify(props.values[key]));
          } else {
            if (props.values[key] === null) {
              formData.append(key, 0);
            } else {
              formData.append(key, String(props.values[key]));
            }
          }
        });

        if (props.values?.objectMLevelId?.length > 0) {
          formData.set(
            'objectMLevelId',
            JSON.stringify(
              props.values.objectMLevelId.map(m => {
                return Number(m);
              }),
            ),
          );
        } else {
          formData.delete('objectMLevelId');
        }
        if (props.values?.memberLevels?.length > 0) {
          formData.set(
            'memberLevels',
            JSON.stringify(
              props.values.memberLevels.map(m => {
                return {
                  ...m,
                  memberLevelSettingId: Number(m.memberLevelSettingId),
                };
              }),
            ),
          );
        } else {
          formData.delete('memberLevels');
        }

        formData.delete('quantity');
        formData.set('settingQuantity', props.values.quantity);

        if (type === '複製') {
          formData.delete('id');
        } else {
          formData.set('id', props.values.id || 0);
        }
        if (props.values.couponImgFiles?.length > 0) {
          if (props.values.couponImgFiles[0] instanceof File) {
            formData.set('couponImgFiles', props.values.couponImgFiles[0]);
            formData.set(
              'couponImg',
              JSON.stringify(props.values.couponImgFiles),
            );
          } else {
            formData.delete('couponImgFiles');
            if (type === '編輯') {
              formData.delete('couponImg');
            }
          }
        }

        if (props.values.importMemberFile) {
          formData.set('importMemberFile', props.values.importMemberFile);
        }

        formData.set('type', currentTab);
        formData.set('storeRedeemShowWay', 'qrcode');
        formData.set(
          'availableWays',
          JSON.stringify(props.values.availableWays),
        );
        formData.set(
          'availableWay',
          JSON.stringify(props.values.availableWays),
        );
        formData.set(
          'groupProducts',
          JSON.stringify(
            (props?.values?.models || [])
              .filter(m => m.modelType === '組合商品')
              .reduce((arr, model) => {
                return [
                  ...arr,
                  ...model.products.map(p => {
                    return {
                      ...p,
                      mid: model.productType === 2 ? model.id : model.mid,
                      productType: model.productType,
                    };
                  }),
                ];
              }, [])
              .map(product => ({
                ...product,
                activityProductId: product.activityProductId,
                area: product.area,
                discountPrice: Number(product.discountPrice || 0),
                mid: product.mid,
                productId:
                  product.productid || product.hProductId || product.productId,
                productType: product.productType,
                productid:
                  product.productid || product.hProductId || product.productId,
              })),
          ),
        );
        formData.set(
          'products',
          JSON.stringify(
            (props?.values?.models || [])
              .reduce((arr, model) => {
                return [
                  ...arr,
                  ...model.products.map(p => {
                    return {
                      ...p,
                      mid: model.productType === 2 ? model.id : model.mid,
                      productType: model.productType,
                    };
                  }),
                ];
              }, [])
              .map(product => ({
                ...product,
                activityProductId: product.activityProductId,
                area: product.area,
                discountPrice: Number(product.discountPrice || 0),
                mid: product.mid,
                productId:
                  product.productid || product.hProductId || product.productId,
                productType: product.productType,
                productid:
                  product.productid || product.hProductId || product.productId,
              })),
          ),
        );
        dispatch({
          payload: {},
          type: GET_VOUCHER_DETAIL_SUCCESS,
        });

        try {
          if (type === '編輯') {
            await updateVoucherDetail(formData);
            MessageUtil.toastSuccess('修改成功');
            const searchParams = new URLSearchParams(location.search);
            dispatch(getVoucherDetail(searchParams.get('id')));

          } else {
            await createVoucher(formData);
            MessageUtil.toastSuccess('新增成功');
            goBack();
          }
        } catch (e) {
          if (type === '編輯') {
            MessageUtil.toastWarning('修改失敗', e);
          } else {
            MessageUtil.toastWarning('新增失敗', e);
          }
          dispatch({
            payload: props.values,
            type: GET_VOUCHER_DETAIL_SUCCESS,
          });
        }
      }
    },
    [detail, currentTab, type],
  );
  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      ...(currentTab === 1
        ? {
            discountAmount: Yup.number()
              .required('數值必須大於0')
              .min(1, '數值必須大於0'),
            discountMax: Yup.number()
              .min(1, '數值必須大於0')
              .max(100, '數值必須小於等於100'),

            discountMaxAmount: Yup.number().when(
              ['discountType', 'discountMax'],
              {
                is: (discountType, discountMax) => {
                  return (
                    (discountType === 2 && discountMax === 2) ||
                    discountType === 1
                  );
                },
                then: Yup.number()
                  .required('數值必須大於0')
                  .min(1, '數值必須大於0'),
              },
            ),
            exchangePointAmount: Yup.number().when(['getWay', 'condition'], {
              is: (getWay, condition) => {
                return getWay === 3;
              },
              then: Yup.number()
                .required('數值必須大於0')
                .min(1, '數值必須大於0'),
            }),
            models: Yup.array().test(
              'invalid',
              '請選擇商品',
              (value, { parent }) => {
                return (
                  parent.productRange !== 'picked' ||
                  (parent.productRange === 'picked' && value?.length > 0)
                );
              },
            ),
            objectMLevelId: Yup.array().when(['getWay', '_object'], {
              is: (getWay, _object) => {
                return getWay === 4 && _object === 2;
              },
              then: Yup.array()
                .nullable()
                .min(1, '請選擇活動對象')
                .required('請選擇活動對象'),
            }),
          }
        : {
            exchangePointAmount: Yup.number().when(['getWay', 'condition'], {
              is: (getWay, condition) => {
                return getWay === 3 && condition === 1;
              },
              then: Yup.number()
                .required('數值必須大於0')
                .min(1, '數值必須大於0'),
            }),
            freebiecode: Yup.string().when(['availableWays'], {
              is: availableWays => {
                return availableWays.includes('web');
              },
              then: Yup.string().required('必填'),
            }),
            objectMLevelId: Yup.array().when(
              ['getWay', '_object', 'condition'],
              {
                is: (getWay, _object, condition) => {
                  return (
                    ((getWay === 4 || getWay === 1) && _object === 2) ||
                    (getWay === 3 && condition === 2)
                  );
                },
                then: Yup.array()
                  .nullable()
                  .min(1, '請選擇活動對象')
                  .required('請選擇活動對象'),
              },
            ),
            pointExchangeLv1: Yup.number().when(
              ['getWay', 'condition', 'objectMLevelId'],
              {
                is: (getWay, condition, objectMLevelId) => {
                  if (getWay === 3) {
                    const options = (memberLevelOptions || []).filter(
                      o => o.label === '好友卡',
                    )[0];
                    if (options) {
                      const ids = objectMLevelId || [];
                      return ids.includes(options.value);
                    }
                  }
                  return false;
                },
                then: Yup.number()
                  .min(1, '請輸入大於0的數字')
                  .required('請輸入大於0的數字'),
              },
            ),
            pointExchangeLv2: Yup.number().when(
              ['getWay', 'condition', 'objectMLevelId'],
              {
                is: (getWay, condition, objectMLevelId) => {
                  if (getWay === 3) {
                    const options = (memberLevelOptions || []).filter(
                      o => o.label === '銀卡會員',
                    )[0];
                    if (options) {
                      const ids = objectMLevelId || [];
                      return ids.includes(options.value);
                    }
                  }
                  return false;
                },
                then: Yup.number()
                  .min(1, '請輸入大於0的數字')
                  .required('請輸入大於0的數字'),
              },
            ),
            pointExchangeLv3: Yup.number().when(
              ['getWay', 'condition', 'objectMLevelId'],
              {
                is: (getWay, condition, objectMLevelId) => {
                  if (getWay === 3) {
                    const options = (memberLevelOptions || []).filter(
                      o => o.label === '金卡會員',
                    )[0];
                    if (options) {
                      const ids = objectMLevelId || [];
                      return ids.includes(options.value);
                    }
                  }
                  return false;
                },
                then: Yup.number()
                  .min(1, '請輸入大於0的數字')
                  .required('請輸入大於0的數字'),
              },
            ),
          }),
      availableWays: Yup.array().min(1, '請勾選可使用通路'),
      couponWording: Yup.string().max(20, '超過字數').required('必填'),
      couponWording_en: Yup.string().max(80, '超過字數').required('必填'),
      desc: Yup.string().max(800, '超過字數').required('必填'),
      desc_en: Yup.string().max(800, '超過字數').required('必填'),
      goal: Yup.number().required('請選擇活動目的'),
      name: Yup.string().max(80, '超過字數').required('必填'),
      name_en: Yup.string().max(80, '超過字數').required('必填'),
      quantity: Yup.number().when(['getWay'], {
        is: getWay => {
          return getWay !== 4;
        },
        then: Yup.number()
          .required('數值必須大於0')
          .min(
            detail.id ? detail.quantity : 1,
            `數值必須大於${detail.id ? detail.quantity : 0}`,
          ),
      }),
      remiderNum: Yup.number().min(1, '數值必須大於0'),
      usableDays: Yup.number().when(['dueType'], {
        is: dueType => {
          return dueType === 2;
        },
        then: Yup.number().required('數值必須大於0').min(1, '數值必須大於0'),
      }),
      usableNdaysLater: Yup.number().when(['usableType'], {
        is: usableType => {
          return usableType === 2;
        },
        then: Yup.number().required('數值必須大於0').min(1, '數值必須大於0'),
      }),
      useMinAmount: Yup.number().when(['useMin'], {
        is: useMin => {
          return useMin === 2;
        },
        then: Yup.number().required('數值必須大於0').min(1, '數值必須大於0'),
      }),
    });
    return schema;
  };

  useEffect(() => {
    dispatch({
      payload: {},
      type: GET_VOUCHER_DETAIL_SUCCESS,
    });
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get('type') === 'edit') {
      dispatch(getVoucherDetail(searchParams.get('id')));
    } else if (searchParams.get('type') === 'copy') {
      dispatch(getVoucherDetail(searchParams.get('id')));
    } else if (searchParams.get('type') === 'view') {
      dispatch(getVoucherDetail(searchParams.get('id')));
    } else {
      dispatch({
        payload: {
          _object: 1,
          availableWays: [],
          budget: 0,
          condition: 1,
          couponCode:'',
          couponImgLink: '',
          couponWording: '',
          couponWording_en: '',
          createTime: '',
          creator: '',
          customedActivityCode: '',
          customedCode: '',
          desc: '',
          desc_en: '',
          discountAmount: 0,
          discountMax: 1,
          discountMaxAmount: 100,
          discountType: 1,
          dueType: 2,
          freebiecode: '',
          getEndTime: moment().add(30, 'minute').format('YYYY/MM/DD HH:mm'),
          getStartTime: moment(initDate).format('YYYY/MM/DD HH:mm'),
          getWay: 1,
          goal: 1,
          groupProducts: [],
          importMemberFile: null,
          isCustomedCode: false,
          isGetTime: false,
          isUsedwithDiscount: true,
          memberLevels: [],
          memberListFileLink: null,
          name: '',
          name_en: '',
          objectMLevelId: '[]',
          pointExchange: 1,
          pointExchangeLimit: 0,
          pointExchangeLv1: 0,
          pointExchangeLv2: 0,
          pointExchangeLv3: 0,
          productRange: 'all',
          products: [],
          quantity: 0,
          redeemEndTime: moment().add(30, 'minute').format('YYYY/MM/DD HH:mm'),
          redeemStartTime: moment(initDate).format('YYYY/MM/DD HH:mm'),
          remiderNum: 5,
          setting: 1,
          statusList: [],
          statusNameList: [],
          storeRedeemShowWay: 'qrcode',
          type: 1,
          updateTime: '',
          updater: '',
          usableDays: 30,
          usableNdaysLater: 0,
          usableType: 1,
          useMin: 1,
          useMinAmount: 0,
        },
        type: GET_VOUCHER_DETAIL_SUCCESS,
      });
    }
  }, [location]);

  useEffect(() => {
    setCurrentTab(~~detail.type);
  }, [detail]);

  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  return (
    <Layout
      pageTitle={`${type}優惠券`}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: false, page_name: '優惠券', to: '/VoucherList?page=1' },
        { isActive: true, page_name: pageName[currentTab - 1] },
      ]}
    >
      <Nav pills className="mb-2">
        {(!detail.id || (detail.id && detail.type === 1)) && (
          <TabItem
            onClick={setCurrentTab}
            tab={1}
            text="折價券"
            currentTab={currentTab}
          />
        )}
        {(!detail.id || (detail.id && detail.type === 2)) && (
          <TabItem
            onClick={setCurrentTab}
            tab={2}
            text="贈品券"
            currentTab={currentTab}
          />
        )}
      </Nav>
      <TabContent activeTab={currentTab}>
        {(!detail.id || (detail.id && detail.type === 1)) && (
          <TabPane tabId={1}>
            {currentTab === 1 && (
              <DiscountTabPane
                goBack={goBack}
                getValidationSchema={getValidationSchema}
                handleSubmit={handleSubmit}
                isEditor={isEditor}
                isView={type === '檢視'}
                initialValues={{
                  ...detail,
                  availableWays: detail.availableWays || ['web'],
                  condition: detail.pointExchangeType || 1,
                  couponCode:  detail.couponCode || '',
                  couponImgFiles:
                    type === '編輯'
                      ? getImageDataFromSetting(
                          detail.couponImgLink,
                          detail.couponImgLink,
                        )
                      : [],
                  customedActivityCode:
                    type === '複製' ? '' : detail.customedActivityCode ?? '',
                  customedCode: detail.customedCode || '',
                  exchangePointAmount: detail.exchangePointAmount || 0,
                  freebiecode: detail.freebiecode ?? '',
                  getEndTime:
                    detail.getEndTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  getStartTime:
                    detail.getStartTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  groupProducts: (detail?.groupProducts || []).map(p => {
                    return {
                      ...p,
                      modelType:
                        p.productType === 1
                          ? '一般商品'
                          : p.productType === 2
                          ? '隱形賣場'
                          : '組合商品',
                    };
                  }),
                  id: type === '複製' ? 0 : detail.id || 0,
                  init_img_files:
                    type === '編輯'
                      ? getImageDataFromSetting(
                          detail.couponImgLink,
                          detail.couponImgLink,
                        )
                      : [],
                  isCustomedCode:  detail.isCustomedCode ?? false,
                  memberLevels: detail.memberLevels || [],
                  models: [
                    ...(detail?.products || []).map(p => {
                      return {
                        ...p,
                        modelType:
                          p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                            ? '隱形賣場'
                            : '組合商品',
                      };
                    }),
                    ...(detail?.groupProducts || []).map(p => {
                      return {
                        ...p,
                        modelType:
                          p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                            ? '隱形賣場'
                            : '組合商品',
                      };
                    }),
                  ],
                  objectMLevelId:  typeof  detail.objectMLevelId  === 'string' ? JSON.parse(detail.objectMLevelId || '[]') : detail.objectMLevelId,
                  pointExchange: detail.pointExchange || 1,
                  pointExchangeLv1: detail.pointExchangeLv1 || 0,
                  pointExchangeLv2: detail.pointExchangeLv2 || 0,
                  pointExchangeLv3: detail.pointExchangeLv3 || 0,
                  pointExchangeType: detail.pointExchangeType || 1,
                  products: (detail?.products || []).map(p => {
                    return {
                      ...p,
                      modelType:
                        p.productType === 1
                          ? '一般商品'
                          : p.productType === 2
                          ? '隱形賣場'
                          : '組合商品',
                    };
                  }),
                  quantity: detail.settingQuantity || 0,
                  redeemEndTime:
                    detail.redeemEndTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  redeemStartTime:
                    detail.redeemStartTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  storeRedeemShowWay: 'qrcode',
                }}
              />
            )}
          </TabPane>
        )}
        {(!detail.id || (detail.id && detail.type === 2)) && (
          <TabPane tabId={2}>
            {currentTab === 2 && (
              <GiftsTabPane
                goBack={goBack}
                getValidationSchema={getValidationSchema}
                handleSubmit={handleSubmit}
                isEditor={isEditor}
                isView={type === '檢視'}
                initialValues={{
                  ...detail,
                  availableWays: detail.availableWays || ['web'],
                  condition: detail.pointExchangeType || 1,
                  couponCode:  detail.couponCode || '',
                  couponImgFiles:
                    type === '編輯'
                      ? getImageDataFromSetting(
                          detail.couponImgLink,
                          detail.couponImgLink,
                        )
                      : [],

                  customedActivityCode:
                    type === '複製' ? '' : detail.customedActivityCode ?? '',
                  customedCode: detail.customedCode || '',
                  exchangePointAmount: detail.exchangePointAmount || 0,
                  freebiecode: detail.freebiecode ?? '',
                  getEndTime:
                    detail.getEndTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  getStartTime:
                    detail.getStartTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  groupProducts: (detail?.groupProducts || []).map(p => {
                    return {
                      ...p,
                      modelType:
                        p.productType === 1
                          ? '一般商品'
                          : p.productType === 2
                          ? '隱形賣場'
                          : '組合商品',
                    };
                  }),
                  id: type === '複製' ? 0 : detail.id,
                  init_img_files:
                    type === '編輯'
                      ? getImageDataFromSetting(
                          detail.couponImgLink,
                          detail.couponImgLink,
                        )
                      : [],
                  isCustomedCode:  detail.isCustomedCode ?? false,
                  memberLevels: detail.memberLevels || [],

                  models: [
                    ...(detail?.products || []).map(p => {
                      return {
                        ...p,
                        modelType:
                          p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                            ? '隱形賣場'
                            : '組合商品',
                      };
                    }),
                    ...(detail?.groupProducts || []).map(p => {
                      return {
                        ...p,
                        modelType:
                          p.productType === 1
                            ? '一般商品'
                            : p.productType === 2
                            ? '隱形賣場'
                            : '組合商品',
                      };
                    }),
                  ],
                  objectMLevelId: JSON.parse(detail.objectMLevelId || '[]'),
                  pointExchange: detail.pointExchange || 1,
                  pointExchangeLv1: detail.pointExchangeLv1 || 0,
                  pointExchangeLv2: detail.pointExchangeLv2 || 0,
                  pointExchangeLv3: detail.pointExchangeLv3 || 0,

                  pointExchangeType: detail.pointExchangeType || 1,
                  products: (detail?.products || []).map(p => {
                    return {
                      ...p,
                      modelType:
                        p.productType === 1
                          ? '一般商品'
                          : p.productType === 2
                          ? '隱形賣場'
                          : '組合商品',
                    };
                  }),
                  quantity: detail.settingQuantity || 0,
                  redeemEndTime:
                    detail.redeemEndTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  redeemStartTime:
                    detail.redeemStartTime ||
                    moment(initDate).format('YYYY/MM/DD HH:mm:ss'),
                  storeRedeemShowWay: 'qrcode',
                }}
              />
            )}
          </TabPane>
        )}
      </TabContent>
    </Layout>
  );
};

export default VoucherSettings;
