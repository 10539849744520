import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  BRAND_CATEGORY_FAIL,
  BRAND_CATEGORY_PRODUCT_FAIL,
  BRAND_CATEGORY_PRODUCT_REQUEST,
  BRAND_CATEGORY_PRODUCT_SUCCESS,
  BRAND_CATEGORY_PRODUCT_REMOVE_FAIL,
  BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST,
  BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS,
  BRAND_CATEGORY_REQUEST,
  BRAND_CATEGORY_SUCCESS,
  BRAND_DETAILS_FAIL,
  BRAND_DETAILS_REQUEST,
  BRAND_DETAILS_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE_FAIL,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
  BRAND_CATEGORY_REMOVE_REQUEST,
  BRAND_CATEGORY_REMOVE_SUCCESS,
  BRAND_CATEGORY_REMOVE_FAIL,
  BRAND_CATEGORY_ADD_FAIL,
  BRAND_CATEGORY_ADD_SUCCESS,
  BRAND_CATEGORY_UPDATE_REQUEST,
  BRAND_CATEGORY_UPDATE_SUCCESS,
  BRAND_CATEGORY_UPDATE_FAIL,
  BRAND_OPTIONS_REQUEST,
  BRAND_OPTIONS_SUCCESS,
  BRAND_OPTIONS_FAIL,
  BRAND_CATEGORY_OPTIONS_REQUEST,
  BRAND_CATEGORY_OPTIONS_SUCCESS,
  BRAND_CATEGORY_OPTIONS_FAIL,
  BRAND_CATEGORY_ADD_REQUEST,
  BRAND_CATEGORY_PRODUCT_LIST_REQUEST,
  BRAND_CATEGORY_PRODUCT_LIST_SUCCESS,
  BRAND_CATEGORY_PRODUCT_LIST_FAIL,
  PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST,
  PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS,
  PRODUCT_ADDTO_BRAND_CATEGORY_FAIL,
  BRAND_UPDATESORT_REQUEST,
  BRAND_UPDATESORT_SUCCESS,
  BRAND_UPDATESORT_FAIL,
  BRAND_CATEGORY_SORT_REQUEST,
  BRAND_CATEGORY_SORT_SUCCESS,
  BRAND_CATEGORY_SORT_FAIL,
  BRAND_CATEGORY_MODEL_LIST_REQUEST,
  BRAND_CATEGORY_MODEL_LIST_SUCCESS,
  BRAND_CATEGORY_MODEL_LIST_FAIL,
  BRAND_CATEGORY_MODEL_REMOVE_REQUEST,
  BRAND_CATEGORY_MODEL_REMOVE_SUCCESS,
  BRAND_CATEGORY_MODEL_REMOVE_FAIL,
  BRAND_CATEGORY_MODEL_ASSIGN_REQUEST,
  BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS,
  BRAND_CATEGORY_MODEL_ASSIGN_FAIL,
  PRODUCT_BRAND_BANNER_SETTING_FAIL,
  PRODUCT_BRAND_BANNER_SETTING_REQUEST,
  PRODUCT_BRAND_BANNER_SETTING_SUCCESS,
} from '../../constants/data/brandConstants';

import { baseURL, prefix_api_path } from './api_config';

//get brand list
export const getBrandList = (keyword, status, pageNumber) => {
  const url = `/Backend/Brand/list?${keyword ? `name=${keyword}` : ''}&${
    status ? `status=${status}` : ''
  }&page=${pageNumber}&order=asc&order_by=sort`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_LIST_FAIL,
      method: 'get',
      requestConstant: BRAND_LIST_REQUEST,
      successConstant: BRAND_LIST_SUCCESS,
      url,
    });
  };
};

//update brand sort
export const updateBrandSort = array => {
  const url = '/Backend/Brand/updateSort';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: array,
      dispatch,
      failConstant: BRAND_UPDATESORT_FAIL,
      method: 'post',
      requestConstant: BRAND_UPDATESORT_REQUEST,
      successConstant: BRAND_UPDATESORT_SUCCESS,
      url,
    });
  };
};

//get brand options
export const getBrandOptions = () => {
  const url = '/Backend/Brand/options';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_OPTIONS_FAIL,
      method: 'get',
      requestConstant: BRAND_OPTIONS_REQUEST,
      successConstant: BRAND_OPTIONS_SUCCESS,
      url,
    });
  };
};

//get brand details
export const getBrandDetails = brandId => {
  const url = `/Backend/Brand/pageSetting?brandId=${brandId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_DETAILS_FAIL,
      method: 'get',
      requestConstant: BRAND_DETAILS_REQUEST,
      successConstant: BRAND_DETAILS_SUCCESS,
      url,
    });
  };
};

//update brand details
export const updateBrandDetails = (data = new FormData()) => {
  const url = '/Backend/Brand/pageSetting';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: BRAND_UPDATE_FAIL,
      method: 'post',
      requestConstant: BRAND_UPDATE_REQUEST,
      successConstant: BRAND_UPDATE_SUCCESS,
      url,
    });
  };
};

//get brand category
export const getBrandCategory = (pageNumber, brandId, name, status) => {
  const url = '/Backend/Brand/category/list';
  CommonUtil.consoleLog({ anno: 'API', data: url });

  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_FAIL,
      method: 'get',
      params: {
        brandId,
        name,
        page: pageNumber || 1,
        status,
      },
      requestConstant: BRAND_CATEGORY_REQUEST,
      successConstant: BRAND_CATEGORY_SUCCESS,
      url,
    });
  };
};

//get brand category options
export const getBrandCategoryOptions = () => {
  const url = '/Backend/Brand/category/options';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_OPTIONS_FAIL,
      method: 'get',
      requestConstant: BRAND_CATEGORY_OPTIONS_REQUEST,
      successConstant: BRAND_CATEGORY_OPTIONS_SUCCESS,
      url,
    });
  };
};

//add brand category
export const addBrandCategory = editInfo => {
  const url = '/Backend/Brand/category';
  CommonUtil.consoleLog({ anno: 'request body', data: editInfo });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: editInfo,
      dispatch,
      failConstant: BRAND_CATEGORY_ADD_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_ADD_REQUEST,
      successConstant: BRAND_CATEGORY_ADD_SUCCESS,
      url,
    });
  };
};

//update brand category
export const updateBrandCategory = editInfo => {
  const url = '/Backend/Brand/category';
  CommonUtil.consoleLog({ anno: 'request body', data: editInfo });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: editInfo,
      dispatch,
      failConstant: BRAND_CATEGORY_UPDATE_FAIL,
      method: 'put',
      requestConstant: BRAND_CATEGORY_UPDATE_REQUEST,
      successConstant: BRAND_CATEGORY_UPDATE_SUCCESS,
      url,
    });
  };
};

//remove brand category
export const removeBrandCategory = cateId => {
  const url = `/Backend/Brand/category?catId=${cateId}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_REMOVE_FAIL,
      method: 'delete',
      requestConstant: BRAND_CATEGORY_REMOVE_REQUEST,
      successConstant: BRAND_CATEGORY_REMOVE_SUCCESS,
      url,
    });
  };
};

//update brand category sort
export const updateBrandCategorySort = array => {
  const url = '/Backend/Brand/category/updateSort';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data: array,
      dispatch,
      failConstant: BRAND_CATEGORY_SORT_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_SORT_REQUEST,
      successConstant: BRAND_CATEGORY_SORT_SUCCESS,
      url,
    });
  };
};

//get brand category product
export const getBrandCategoryProducts = (
  pageNumber,
  brandId,
  brandCateId,
  keyword,
  model,
) => {
  const url = `/Backend/Product/listFromBrandCategory?page=${pageNumber}&${
    brandId ? `brandId=${brandId}` : ''
  }&${brandCateId ? `brandCateId=${brandCateId}` : ''}&${
    keyword ? `keyword=${keyword}` : ''
  }&${model ? `model=${model}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_PRODUCT_FAIL,
      method: 'get',
      requestConstant: BRAND_CATEGORY_PRODUCT_REQUEST,
      successConstant: BRAND_CATEGORY_PRODUCT_SUCCESS,
      url,
    });
  };
};

//remove brand category product
export const removeBrandCategoryProduct = productId => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Product/removeBrandCategory`,
        productId,
        config,
      );

      dispatch({
        payload: data,
        type: BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error,
        type: BRAND_CATEGORY_PRODUCT_REMOVE_FAIL,
      });
    }
  };
};

//get brand category product
export const getBrandCategoryProductsList = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BRAND_CATEGORY_PRODUCT_LIST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${baseURL + prefix_api_path}/Backend/Product/list?page=${
        info.page
      }`;

      if (info.brandId) {
        link = link.concat(`&brandId=${info.brandId}`);
      }
      if (info.keyword) {
        link = link.concat(`&keyword=${info.keyword}`);
      }
      if (info.model) {
        link = link.concat(`&model=${info.model}`);
      }
      if (info.color) {
        link = link.concat(`&color=${info.color}`);
      }
      if (info.page_size) {
        link = link.concat(`&page_size=${info.page_size}`);
      }

      const { data } = await axios.get(link, config);

      dispatch({
        payload: data.result,
        type: BRAND_CATEGORY_PRODUCT_LIST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error && error.response ? error.response : error,
        type: BRAND_CATEGORY_PRODUCT_LIST_FAIL,
      });
    }
  };
};

//add product to brand category
export const addProductToBrandCategory = info => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/Product/assignToBrandCategory`,
        info,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error.response.data,
        type: PRODUCT_ADDTO_BRAND_CATEGORY_FAIL,
      });
    }
  };
};

export const getBrandCategoryModelListForGroupProduct = (
  params = {
    brandCateId: 0,
    brandId: 0,
    keyword: '',
    model: '',
    page: 1,
  },
) => {
  const url =
    `/Backend/BrandCategoryModelRel/listFromBrandCategoryForGroupProduct?
  ${new URLSearchParams(params).toString()}`.trim();
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_LIST_FAIL,
      method: 'get',
      params,
      requestConstant: BRAND_CATEGORY_MODEL_LIST_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_LIST_SUCCESS,
      url,
    });
  };
};

export const getBrandCategoryModelList = (
  params = {
    brandCateId: 0,
    brandId: 0,
    keyword: '',
    model: '',
    page: 1,
  },
) => {
  const url = `/Backend/BrandCategoryModelRel/listFromBrandCategory?
  ${new URLSearchParams(params).toString()}`.trim();
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_LIST_FAIL,
      method: 'get',
      params,
      requestConstant: BRAND_CATEGORY_MODEL_LIST_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_LIST_SUCCESS,
      url,
    });
  };
};

export const removeBrandCategoryForGroupProduct = modelId => {
  const url =
    '/Backend/BrandCategoryModelRel/removeBrandCategoryForGroupProduct';
  return async dispatch => {
    await useRequest({
      data: {
        modelId,
      },
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_REMOVE_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_MODEL_REMOVE_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_REMOVE_SUCCESS,
      url,
    });
  };
};

export const removeBrandCategoryModel = modelId => {
  const url = '/Backend/BrandCategoryModelRel/removeBrandCategory';
  return async dispatch => {
    await useRequest({
      data: {
        modelId,
      },
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_REMOVE_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_MODEL_REMOVE_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_REMOVE_SUCCESS,
      url,
    });
  };
};

export const assignModelForGroupProductToBrandCategory = params => {
  const url = '/Backend/BrandCategoryModelRel/assignToBrandForGroupProduct';
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_ASSIGN_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_MODEL_ASSIGN_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS,
      url,
    });
  };
};

export const assignModelToBrandCategory = params => {
  const url = '/Backend/BrandCategoryModelRel/assignToBrandCategory';
  return async dispatch => {
    await useRequest({
      data: params,
      dispatch,
      failConstant: BRAND_CATEGORY_MODEL_ASSIGN_FAIL,
      method: 'post',
      requestConstant: BRAND_CATEGORY_MODEL_ASSIGN_REQUEST,
      successConstant: BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS,
      url,
    });
  };
};

export const getProductBrandBannerSetting = () => {
  const url = '/Backend/ProductBrandBannerSetting/setting';
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: PRODUCT_BRAND_BANNER_SETTING_FAIL,
      method: 'get',
      requestConstant: PRODUCT_BRAND_BANNER_SETTING_REQUEST,
      successConstant: PRODUCT_BRAND_BANNER_SETTING_SUCCESS,
      url,
    });
  };
};

export const saveProductBrandBannerSetting = async (data = new FormData()) => {
  const url = '/Backend/ProductBrandBannerSetting/save';
  return await request({
    data,
    method: 'post',
    url,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};
