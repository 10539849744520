import Layout from 'components/Layout';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';


import Loader from '../components/Loader';
import UploadFileBox from '../components/UploadFileBox';
import {
  getCafeBottomHomeSetting,
  updateCafeBottomHome,
} from '../redux/actions/data/uuCafeBottomHomeSettingsAction';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

const UUCafeBottomHomeSettings = () => {
  const dispatch = useDispatch();
  const { loading, success, error, detail } = useSelector(
    state => state.cafeBottomHomeSetting,
  );

  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        const formData = new FormData();
        formData.append('linkUrl', props.values.linkUrl);
        formData.append('title', props.values.title);
        formData.append('title_en', props.values.title_en);

        if (props.values.banner_files) {
          formData.append('banner_files', props.values.banner_files);
        } else {
          if (detail.bannerUrl && detail.bannerImg) {
            formData.append('banner_files', []);
          }
        }

        if (props.values.banner_mobile_files) {
          formData.append(
            'banner_files_mobile',
            props.values.banner_mobile_files,
          );
        } else {
          if (detail.bannerUrl_mobile && detail.bannerImg_mobile) {
            formData.append('banner_files_mobile', []);
          }
        }
        if (detail.id) {
          formData.append('id', detail.id);
          dispatch(updateCafeBottomHome(formData));
        }
      }
    },
    [detail, dispatch],
  );

  const getImageDataFromSetting = (pic, url) => {
    if (pic && url) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: pic,
          url: url,
        },
      ];
    } else {
      return [];
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      linkUrl: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    dispatch(getCafeBottomHomeSetting());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        MessageUtil.toastSuccess('修改成功');
        dispatch(getCafeBottomHomeSetting());
      }
      if (error) {
        MessageUtil.alertWanring('修改失敗', error);
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  return (
    <Layout
      pageTitle="頁尾圖片設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '頁尾圖片設定' },
      ]}
    >
      <Card className={style.card}>
        {!loading && detail && detail.id && (
          <Formik
            validationSchema={getValidationSchema}
            initialValues={{
              banner_files: null,
              banner_mobile_files: null,
              init_bannerFile: getImageDataFromSetting(
                detail.bannerImg,
                detail.bannerUrl,
              ),
              init_bannerUrl_mobile: getImageDataFromSetting(
                detail.bannerImg_mobile,
                detail.bannerUrl_mobile,
              ),
              linkUrl: detail.linkUrl,
              updateDateTime: detail.updateDateTime,
              updater: detail.updater,
            }}
          >
            {props => (
              <Form className="form-list form-label-140">
                <FormGroup className={style.formGroup}>
                  <label style={{ width: '120px' }}>*電腦版圖片</label>
                  <div className="form-item">
                    <UploadFileBox
                      uplodModel="images"
                      formFieldName="banner_files"
                      currentFileFieldName="banner_files"
                      setFormFieldValue={(field, files) => {
                        if (files && files.length) {
                          if (files[0] instanceof File) {
                            props.setFieldValue('banner_files', files[0]);
                          }
                        }
                      }}
                      displayFileWidth="1920"
                      displayFileHeight="580"
                      displayFileSize="5MB"
                      initFiles={props.values.init_bannerFile}
                    />
                  </div>
                  <ErrorMessage
                    name="bannerUrl"
                    component="div"
                    className="form-error"
                  />
                </FormGroup>
                <FormGroup className={style.formGroup}>
                  <label style={{ width: '120px' }}>*手機版圖片</label>
                  <div className="form-item">
                    <UploadFileBox
                      uplodModel="images"
                      formFieldName="banner_mobile_files"
                      currentFileFieldName="banner_mobile_files"
                      setFormFieldValue={(field, files) => {
                        if (files && files.length) {
                          if (files[0] instanceof File) {
                            props.setFieldValue(
                              'banner_mobile_files',
                              files[0],
                            );
                          }
                        }
                      }}
                      displayFileWidth="390"
                      displayFileHeight="390"
                      displayFileSize="3MB"
                      initFiles={props.values.init_bannerUrl_mobile}
                    />
                    <ErrorMessage
                      name="bannerUrl_mobile"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label>*連結</label>
                  <div className="form-item">
                    <Field name="linkUrl" className="form-control" />
                    <ErrorMessage
                      name="linkUrl"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </FormGroup>
                <div style={{ minHeight: '150px' }}></div>
                <p className="fs-14">
                  {props.values.updateDateTime} {props.values.updater}
                </p>
                <hr />
                <div className="text-center">
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    disabled={loading || !(props.isValid && props.dirty)}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span>儲存</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
        {loading && !detail.id && <Loader />}
      </Card>
    </Layout>
  );
};

export default UUCafeBottomHomeSettings;
