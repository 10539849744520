import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';

import { getProductListV2 } from '../../redux/actions/data/productActions';
import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';
import TableHead from '../TableHead';

const AddProductModal = ({
  open,
  toggle,
  selectedItems,
  onSuccess,
  productType,
}) => {
  const dispatch = useDispatch();
  const { hideModelDetailInfo } = useSelector(state => state.hideModelDetail);
  const { loading, productListInfo } = useSelector(state => state.productList);
  const handleGetProductsList = page => {
    dispatch(
      getProductListV2({
        mid: hideModelDetailInfo.mid,
        page,
        page_size: 10,
        productType,
      }),
    );
  };
  useEffect(() => {
    open && handleGetProductsList(1);
  }, [open]);

  const paresParams = i => {
    return {
      ...i,
      colorName: i.colorName || i.color || '',
      colorName_en: i.colorName_en || i.color || '',
      lowestStock: i.lowestStock ?? 0,
      maxSellNum: i.maxSellNum ?? 0,
      outofStockWords: i.isSoldOut ? '已售完' : '售完不補貨',
      sellAmount: i.h_sellAmount || i.sellAmount || 0,
      sellQuantity: i.h_sellQuantity || i.sellQuantity || 0,
      stock: i.stock ?? 0,
    };
  };

  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: selectedItems,
    },
    onSubmit(formModel, { resetForm }) {
      onSuccess && onSuccess(formModel.list);
      resetForm();
    },
  });

  const products = useMemo(() => {
    if (productType === 1) {
      return (productListInfo?.datas || []).reduce(
        (arr, model) => [
          ...arr,
          ...model.products.map(p => {
            const productId = p.productId || p.productid;
            const productid = p.productid || p.productId;

            return {
              ...p,
              productId,
              productid,
            };
          }),
        ],
        [],
      );
    } else {
      return (productListInfo?.datas || []).reduce(
        (arr, model) => [
          ...arr,
          ...(model.products || []).reduce((arr, p) => {
            return [...arr, ...p.rels];
          }, []),
        ],
        [],
      );
    }
  }, [productListInfo, productType]);

  console.log(products, values.list);

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        選擇商品
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        <div className="table-responsive mb-5">
          <Table hover bordered striped>
            <TableHead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th style={{ width: 90 }}>
                  <input
                    className={style.maintain_list_checkbox}
                    type="checkbox"
                    name="all"
                    onChange={e => {
                      const val = e.target.checked;
                      setFieldValue(
                        'list',
                        val
                          ? [
                              ...values.list,
                              ...products
                                .filter(i =>
                                  values.list.every(
                                    selected =>
                                      selected.productId !== i.productId,
                                  ),
                                )
                                .map(i => paresParams(i)),
                            ]
                          : [],
                      );
                    }}
                    checked={
                      values.list?.length > 0 &&
                      values.list.every(l =>
                        products.some(p => p.productId === l.productId),
                      )
                    }
                  />
                  全選
                </th>
                <th>商品名稱</th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {products.length > 0 ? (
                  products.map(i => (
                    <tr className="text-nowrap" key={JSON.stringify(i)}>
                      <td>
                        <input
                          className={style.maintain_list_checkbox}
                          type="checkbox"
                          onChange={e => {
                            const val = e.target.checked;
                            setFieldValue(
                              'list',
                              val
                                ? [...values.list, paresParams(i)]
                                : values.list.filter(
                                    j => j.productId !== i.productId,
                                  ),
                            );
                          }}
                          checked={values.list.some(
                            j => j.productId === i.productId,
                          )}
                        />
                      </td>
                      <td>{i.productname}</td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {productListInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={productListInfo?.current_page}
              defaultPageSize={productListInfo?.page_size}
              total={productListInfo?.total_count}
              onChange={handleGetProductsList}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
        >
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(AddProductModal);
