import React, { memo } from 'react';
import Select from 'react-select';
import { FormGroup, Input, Row } from 'reactstrap';

import style from '../../styles/layout.module.css';

const stockCompareTypeOptions = [
  { label: '大於', value: 0 },
  { label: '小於', value: 1 },
];

const isSellOptions = [
  { label: '全部', value: null },
  { label: '開啟', value: true },
  { label: '關閉', value: false },
];

const SearchGiftListForm = ({ values, setFieldValue }) => {
  return (
    <Row className="flex-wrap">
      <FormGroup
        className="d-inline-flex align-items-center col-sm-12 col-md-6 col-lg-4"
        style={{ minWidth: '300px' }}
      >
        <label className="mx-3 text-nowrap">關鍵字</label>
        <Input
          id="keyword"
          className={style['form-select-shop-banner']}
          value={values.keyword}
          onChange={e => {
            setFieldValue('keyword', e.target.value);
          }}
          placeholder="贈品編號、贈品料號、贈品名稱"
        />
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center col-sm-12 col-md-6 col-lg-4"
        style={{ minWidth: '250px' }}
      >
        <label className="mx-3 text-nowrap">可售數量</label>
        <Select
          className={style['form-input-short']}
          value={
            stockCompareTypeOptions.filter(
              o => String(o.value) === String(values.stockCompareType),
            )[0] || null
          }
          options={stockCompareTypeOptions}
          onChange={op => {
            setFieldValue('stockCompareType', op.value);
          }}
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              marginRight: '5px',
              minWidth: '100px',
            }),
          }}
        ></Select>
        <Input
          id="stock"
          type="number"
          className="flex-grow-1 ml-2"
          style={{
            minWidth: '80px',
          }}
          value={values.stock}
          onChange={e => {
            setFieldValue('stock', e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup
        className="d-inline-flex align-items-center col-sm-12 col-md-6 col-lg-4"
        style={{ minWidth: '200px' }}
      >
        <label className="mx-3 text-nowrap">贈品狀態</label>
        <Select
          className="flex-grow-1"
          value={
            isSellOptions.filter(
              o => String(o.value) === String(values.isSell),
            )[0] || null
          }
          styles={{
            control: baseStyles => ({
              ...baseStyles,
              minWidth: '100px',
              width: '100%',
            }),
          }}
          options={isSellOptions}
          onChange={op => {
            setFieldValue('isSell', op.value);
          }}
        ></Select>
      </FormGroup>
    </Row>
  );
};

export default memo(SearchGiftListForm);
