import {
  GET_VOUCHER_DETAIL_FAIL,
  GET_VOUCHER_DETAIL_REQUEST,
  GET_VOUCHER_DETAIL_SUCCESS,
  GET_VOUCHER_LIST_FAIL,
  GET_VOUCHER_LIST_REQUEST,
  GET_VOUCHER_LIST_SUCCESS,
} from 'redux/constants/data/voucherConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getVoucherList = data => {
  const url = '/Backend/CouponSetting/List';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_VOUCHER_LIST_FAIL,
      method: 'post',
      requestConstant: GET_VOUCHER_LIST_REQUEST,
      successConstant: GET_VOUCHER_LIST_SUCCESS,
      url,
    });
  };
};

export const getVoucherDetail = id => {
  const url = `/Backend/CouponSetting/Get/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_VOUCHER_DETAIL_FAIL,
      method: 'get',
      requestConstant: GET_VOUCHER_DETAIL_REQUEST,
      successConstant: GET_VOUCHER_DETAIL_SUCCESS,
      url,
    });
  };
};

export const createVoucher = async data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url: '/Backend/CouponSetting/Create',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const updateVoucherDetail = async data => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url: '/Backend/CouponSetting/Update',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const downloadCouponExportCode = async (params = { id: 0 }) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/CouponSetting/ExportCode',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const exportSingleMembershipGift = async (id = 0) => {
  return await request({
    data: {
      id,
    },
    method: 'get',
    params: {
      id,
    },
    url: `/Backend/MembershipGift/ExportSingleMembershipGift/${id}`,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const exportCouponExport = async (id = 0) => {
  return await request({
    data: {
      id,
    },
    params: {
      id,
    },
    method: 'post',
    url: '/Backend/CouponSetting/ExportSingleCouppon',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const deleteVoucher = async (params = { id: 0 }) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/CouponSetting/Delete',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};
