export const FAQ_LIST_REQUEST = 'FAQ_LIST_REQUEST';
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS';
export const FAQ_LIST_FAIL = 'FAQ_LIST_FAIL';

export const FAQ_OPTIONS_REQUEST = 'FAQ_OPTIONS_REQUEST';
export const FAQ_OPTIONS_SUCCESS = 'FAQ_OPTIONS_SUCCESS';
export const FAQ_OPTIONS_FAIL = 'FAQ_OPTIONS_FAIL';

export const FAQ_ADD_REQUEST = 'FAQ_ADD_REQUEST';
export const FAQ_ADD_SUCCESS = 'FAQ_ADD_SUCCESS';
export const FAQ_ADD_FAIL = 'FAQ_ADD_FAIL';
export const FAQ_ADD_RESET = 'FAQ_ADD_RESET';

export const FAQ_DELETE_REQUEST = 'FAQ_DELETE_REQUEST';
export const FAQ_DELETE_SUCCESS = 'FAQ_DELETE_SUCCESS';
export const FAQ_DELETE_FAIL = 'FAQ_DELETE_FAIL';
export const FAQ_DELETE_RESET = 'FAQ_DELETE_RESET';

export const FAQ_CATEGORY_REQUEST = 'FAQ_CATEGORY_REQUEST';
export const FAQ_CATEGORY_SUCCESS = 'FAQ_CATEGORY_SUCCESS';
export const FAQ_CATEGORY_FAIL = 'FAQ_CATEGORY_FAIL';

export const FAQ_CATEGORY_DETAILS_REQUEST = 'FAQ_CATEGORY_DETAILS_REQUEST';
export const FAQ_CATEGORY_DETAILS_SUCCESS = 'FAQ_CATEGORY_DETAILS_SUCCESS';
export const FAQ_CATEGORY_DETAILS_FAIL = 'FAQ_CATEGORY_DETAILS_FAIL';

export const FAQ_CATEGORY_ADD_REQUEST = 'FAQ_CATEGORY_ADD_REQUEST';
export const FAQ_CATEGORY_ADD_SUCCESS = 'FAQ_CATEGORY_ADD_SUCCESS';
export const FAQ_CATEGORY_ADD_FAIL = 'FAQ_CATEGORY_ADD_FAIL';
export const FAQ_CATEGORY_ADD_RESET = 'FAQ_CATEGORY_ADD_RESET';

export const FAQ_CATEGORY_DELETE_REQUEST = 'FAQ_CATEGORY_DELETE_REQUEST';
export const FAQ_CATEGORY_DELETE_SUCCESS = 'FAQ_CATEGORY_DELETE_SUCCESS';
export const FAQ_CATEGORY_DELETE_FAIL = 'FAQ_CATEGORY_DELETE_FAIL';
export const FAQ_CATEGORY_DELETE_RESET = 'FAQ_CATEGORY_DELETE_RESET';
