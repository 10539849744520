import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import React from 'react';

export const ProductGifts = ({ list }) => {
  return (
    <React.Fragment>
      <TableHead>
        <tr>
          <th>訂單編號</th>
          <th>商品/贈品名稱</th>
          <th>商品選項</th>
          <th>商品屬性</th>
        </tr>
      </TableHead>
      {!list && <TableLoading />}
      {list && (
        <tbody>
          {list.length > 0 ? (
            list.map(data => (
              <tr key={JSON.stringify(data)}>
                <td>{data.orderItemNumber || '--'}</td>
                <td>{data.productName || data.freebieName || '--'}</td>
                <td>{`${data.color || '--'} / ${data.size || '--'}`}</td>
                <td>{data.type}</td>
              </tr>
            ))
          ) : (
            <TableHasNoData />
          )}
        </tbody>
      )}
    </React.Fragment>
  );
};

export const ActivityGifts = ({ list }) => {
  return (
    <React.Fragment>
      <TableHead>
        <tr>
          <th>活動序號</th>
          <th>活動名稱</th>
          <th>活動時間</th>
        </tr>
      </TableHead>
      {!list && <TableLoading />}
      {list && (
        <tbody>
          {list.length > 0 ? (
            list.map(data => (
              <tr key={JSON.stringify(data)}>
                <td>{data.activityCode}</td>
                <td>{data.activityName}</td>
                <td>
                  <div>{`${data.startTime} 起`}</div>
                  <div>{`${data.endTime} 止`}</div>
                </td>
              </tr>
            ))
          ) : (
            <TableHasNoData />
          )}
        </tbody>
      )}
    </React.Fragment>
  );
};

export const ActivityFreebies = ({ list }) => {
  return (
    <React.Fragment>
      <TableHead>
        <tr>
          <th>活動序號</th>
          <th>優惠券代碼</th>
          <th>活動名稱</th>
          <th>活動時間</th>
        </tr>
      </TableHead>
      {!list && <TableLoading />}
      {list && (
        <tbody>
          {list.length > 0 ? (
            list.map(data => (
              <tr key={JSON.stringify(data)}>
                <td>{data.couponCode}</td>
                <td>{data.code}</td>
                <td>{data.couponName}</td>
                <td>
                  <div>{`${data.redeemStartTime} 起`}</div>
                  <div>{`${data.redeemEndTime} 止`}</div>
                </td>
              </tr>
            ))
          ) : (
            <TableHasNoData />
          )}
        </tbody>
      )}
    </React.Fragment>
  );
};
