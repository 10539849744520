import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import {
  DEALER_CHANNEL_DETAILS_FAIL,
  DEALER_CHANNEL_DETAILS_REQUEST,
  DEALER_CHANNEL_DETAILS_SUCCESS,
  DEALER_CHANNEL_LIST_FAIL,
  DEALER_CHANNEL_LIST_REQUEST,
  DEALER_CHANNEL_LIST_SUCCESS,
  DEALER_CHANNEL_POST_FAIL,
  DEALER_CHANNEL_POST_REQUEST,
  DEALER_CHANNEL_POST_SUCCESS,
  DEALER_DEFAULT_SET_FAIL,
  DEALER_DEFAULT_SET_REQUEST,
  DEALER_DEFAULT_SET_SUCCESS,
} from '../../constants/data/channelConstants';

//get channel list
export const getChannelList = ({ page, keyword, status }) => {
  CommonUtil.consoleLog({ anno: 'params', data: { keyword, page, status } });
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/SupplierChannel/list?page=${page}&page_size=10&keyword=${
      typeof keyword === 'undefined' ? '' : keyword
    }&status=${typeof status === 'undefined' ? '' : status}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DEALER_CHANNEL_LIST_FAIL,
      method: 'post',
      requestConstant: DEALER_CHANNEL_LIST_REQUEST,
      successConstant: DEALER_CHANNEL_LIST_SUCCESS,
      url: `/Backend/SupplierChannel/list?page=${page}&page_size=10&keyword=${
        typeof keyword === 'undefined' ? '' : keyword
      }&status=${typeof status === 'undefined' ? '' : status}`,
    });
  };
};

//get channel details
export const getChannelDetails = id => {
  const url = `/Backend/SupplierChannel/Detail/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DEALER_CHANNEL_DETAILS_FAIL,
      method: 'get',
      requestConstant: DEALER_CHANNEL_DETAILS_REQUEST,
      successConstant: DEALER_CHANNEL_DETAILS_SUCCESS,
      url,
    });
  };
};

//add channel
export const addChannel = object => {
  CommonUtil.consoleLog({ anno: 'request body', data: object });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/SupplierChannel/Save' });
  return async dispatch => {
    await useRequest({
      data: object,
      dispatch,
      failConstant: DEALER_CHANNEL_POST_FAIL,
      method: 'post',
      requestConstant: DEALER_CHANNEL_POST_REQUEST,
      successConstant: DEALER_CHANNEL_POST_SUCCESS,
      url: '/Backend/SupplierChannel/Save',
    });
  };
};

//set channel default
export const setChannel = id => {
  const url = `/Backend/SupplierChannel/SetDefault/${id}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: DEALER_DEFAULT_SET_FAIL,
      method: 'get',
      requestConstant: DEALER_DEFAULT_SET_REQUEST,
      successConstant: DEALER_DEFAULT_SET_SUCCESS,
      url,
    });
  };
};
