import {
  UUCAFE_SHOP_DETAIL_ADD_FAIL,
  UUCAFE_SHOP_DETAIL_ADD_REQUEST,
  UUCAFE_SHOP_DETAIL_ADD_SUCCESS,
  UUCAFE_SHOP_DETAIL_FAIL,
  UUCAFE_SHOP_DETAIL_REQUEST,
  UUCAFE_SHOP_DETAIL_SUCCESS,
  UUCAFE_SHOP_DETAIL_UPDATE_FAIL,
  UUCAFE_SHOP_DETAIL_UPDATE_REQUEST,
  UUCAFE_SHOP_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SHOP_LIST_FAIL,
  UUCAFE_SHOP_LIST_REQUEST,
  UUCAFE_SHOP_LIST_SUCCESS,
} from '../../constants/data/uuCafeShopConstants';

export const cafeShopListReducer = (
  state = {
    error: null,
    loading: false,
    shopList: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SHOP_LIST_REQUEST:
      return {
        error: null,
        loading: true,
        shopList: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        success: false,
      };
    case UUCAFE_SHOP_LIST_SUCCESS:
      return { ...state, loading: false, shopList: payload, success: true };
    case UUCAFE_SHOP_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeShopDetailReducer = (
  state = {
    detail: {
      address: '',
      address_en: '',
      businessHours: '',
      businessHours_en: '',
      cityId: '',
      countryId: '',
      imgFile: '',
      imgUrl: '',
      latitude: '',
      longitude: '',
      name: '',
      name_en: '',
      otherDesc: '',
      otherDesc_en: '',
      phone: '',
      services: [],
      townId: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SHOP_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_SHOP_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_SHOP_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_SHOP_DETAIL_ADD_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_SHOP_DETAIL_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_SHOP_DETAIL_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_SHOP_DETAIL_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_SHOP_DETAIL_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_SHOP_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
