
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import request from 'utils/useAxios';

import { baseURL, prefix_api_path } from '../../redux/actions/data/api_config';
import style from '../../styles/layout.module.css';

const ModelSelection = ({ values, setFieldValue, index }) => {
  const dispatch = useDispatch();
  const [modelOptions, setModelOptions] = useState([]);
  const { brandOptions } = useSelector(state => state.brandOptions);

  const res = async () => {
    const { result } = await request({
      method: 'get',
      url: `${baseURL}${prefix_api_path}/Backend/Product/modelOptions?brandName=${values.conditions[index].brand.label}`,
    });

    setModelOptions(result);
    setFieldValue(`conditions[${index}].models`, result);
  };

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  useEffect(() => {
    res();
  }, [values.conditions[index].brand.label]);

  return (
    <div className={style.show_flex} style={{ marginBottom: '2rem' }}>
      <Select
        className={style['form-select']}
        value={values.conditions[index].brand}
        style={{ margin: 0 }}
        options={brandOptions}
        onChange={option => {
          setFieldValue(`conditions[${index}].brand`, option);
          setFieldValue(`conditions[${index}].model`, {
            label: '全部型號',
            value: '',
          });
        }}
      />
      <Select
        className={style['form-select']}
        value={values.conditions[index].model}
        style={{ margin: 0 }}
        options={modelOptions}
        onChange={option => {
          setFieldValue(`conditions[${index}].model`, option);
        }}
      />
    </div>
  );
};

const ChooseModal = ({
  isOpen,
  toggle,
  datas = [
    {
      brand: { label: '品牌', value: 0 },
      model: { label: '全部型號', value: '' },
      models: [],
    },
  ],
  onSubmit,
}) => {
  const { values, setFieldValue } = useFormik({
    initialValues: {
      conditions: datas,
    },
  });
  return (
    <Modal isOpen={isOpen} size="lg" zIndex={2000} centered>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        選擇品牌/型號
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '400px' }}>
        <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
          <label className="required w100">請選擇</label>
          <div>
            {values.conditions.map((condition, i) => (
              <ModelSelection
                values={values}
                setFieldValue={setFieldValue}
                index={i}
              />
            ))}
            <Button
              outline
              color="primary"
              onClick={() => {
                setFieldValue('conditions', [
                  ...values.conditions,
                  {
                    brand: { label: '品牌', value: 0 },
                    model: { label: '全部型號', value: '' },
                  },
                ]);
              }}
            >
              新增品牌型號
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={style.button_position}>
          <Button
            className={`${style.modal_button_submit}`}
            onClick={() => {
              onSubmit(values.conditions.filter(c => c.brand.value != 0));
              toggle();
            }}
          >
            儲存
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ChooseModal;
