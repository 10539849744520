import Layout from 'components/Layout';
import SearchCard from 'components/orderStatisticalTable/SearchCard';
import TableList from 'components/orderStatisticalTable/TableList';
import ChooseModal from 'components/productSalesDataRanking/ChooseModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import { getOrderInstantReport } from 'redux/actions/data/operatingReportActions';
import { useToggle } from 'utils/customHooks';

import style from '../styles/layout.module.css';

const OrderStatisticalTable = () => {
  const [isChooseModalOpen, setIsChooseModalOpen] = useToggle();
  const disptach = useDispatch();
  const { reportList } = useSelector(state => state.getOrderInstantReport);
  const { values, setFieldValue, handleSubmit, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeId: 0,
      dtEnd: '',
      dtStart: '',
      keyword: '',
      options: [],
      order: 'desc',
      order_by: 'storeNumber',
      page: 1,
      page_size: 10,
      rankingNumber: 0,
      salesChannel: [{ label: 'online', value: 'online' }],
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      let modelIds = [];

      values.options.forEach(o => {
        if (o.model.value === '') {
          o.models.forEach(m => modelIds.push(m.value));
        } else {
          modelIds.push(o.model.value);
        }
      });
      const body = {
        brandIds: values.options.map(option => option.brand.value) || [],
        dtEnd: values.dtEnd
          ? moment(values.dtEnd).format('yyyy/MM/DD HH:mm:ss')
          : '',
        dtStart: values.dtStart
          ? moment(values.dtStart).format('yyyy/MM/DD HH:mm:ss')
          : '',
        keyword: values.keyword,
        models: modelIds || [],
        order: values.order,
        order_by: values.order_by,
        page: values.page,
        page_size: values.page_size,
        rankingNumber: values.rankingNumber,
      };
      disptach(getOrderInstantReport(body));
    },
  });

  const handlePaginationChange = page => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    handleSubmit();
  }, [values.activeId]);

  return (
    <Layout
      pageTitle={'即時訂單統計表'}
      items={[
        { isActive: true, page_name: '營運報表' },
        { isActive: false, page_name: '訂單-即時訂單統計表' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <SearchCard
            values={values}
            setFieldValue={setFieldValue}
            setIsChooseModalOpen={setIsChooseModalOpen}
          />
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                setFieldValue('page', 1);
                handleSubmit();
              }}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <TableList
          setFieldValue={setFieldValue}
          values={values}
          className="mb-5"
        />
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={reportList.current_page || 1}
            defaultPageSize={reportList.page_size || 10}
            total={reportList.total_count}
            onChange={handlePaginationChange}
          />
        </div>
      </Card>
      {isChooseModalOpen && (
        <ChooseModal
          isOpen={isChooseModalOpen}
          toggle={setIsChooseModalOpen}
          datas={values.options}
          onSubmit={datas => {
            setFieldValue('options', datas);
          }}
        />
      )}
    </Layout>
  );
};

export default OrderStatisticalTable;
