import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Alert, Button, Card, FormGroup } from 'reactstrap';
import * as Yup from 'yup';

import {
  getCafeMealIntroDetail,
  updateCafeMealIntroDetail,
} from '../../redux/actions/data/uuCafeMealIntroAction';
import style from '../../styles/layout.module.css';
import Loader from '../Loader';



const HomeSetting = () => {
  const { detail, loading, success, error } = useSelector(
    state => state.cafeMealInfo,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        const params = {
          content: props.values.content,
          content_en: props.values.content_en,
          id: detail.id || 0,
          title: props.values.title,
          title_en: props.values.title_en,
        };
        dispatch(updateCafeMealIntroDetail(params));
      }
    },
    [dispatch, detail],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        dispatch(getCafeMealIntroDetail());
      }
      if (error) {
        toast.warning('修改失敗');
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    dispatch(getCafeMealIntroDetail());
  }, []);

  if (!detail) {
    return <Loader />;
  }

  return (
    <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />{' '}
          <span className={style.fs_14}>
            餐點介紹首頁選單圖片，在餐點介紹-各專區設定
          </span>
        </div>
      </Alert>
      <Formik
        enableReinitialize
        initialValues={{
          content: detail.content || '',
          content_en: detail.content_en || '',
          title: detail.title || '',
          title_en: detail.title_en || '',
          updateDateTime: detail.updateDateTime || '',
          updater: detail.updater || '',
        }}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(中文)</label>
              <div className="form-item">
                <Field name="title" className="form-control" />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*標題(英文)</label>
              <div className="form-item">
                <Field name="title_en" className="form-control" />
                <ErrorMessage
                  name="title_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(中文)</label>
              <div className="form-item fix-z-index">
                <Field name="content" className="form-control" as="textarea" />
                <ErrorMessage
                  name="content"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>

            <FormGroup className="form-group mb-3 mb-3">
              <label>*內容(英文)</label>
              <div className="form-item fix-z-index">
                <Field
                  name="content_en"
                  className="form-control"
                  as="textarea"
                />

                <ErrorMessage
                  name="content_en"
                  component="div"
                  className="form-error"
                />
              </div>
            </FormGroup>
            <div style={{ minHeight: '150px' }}></div>
            <p className="fs-14">
              {props.values.updateDateTime} {props.values.updater}
            </p>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_submit}
                disabled={!(props.isValid && props.dirty)}
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                {loading && (
                  <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: '1rem' }}
                  ></span>
                )}
                <span className="btn-wrapper--label">儲存</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(HomeSetting);
