import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Table } from 'reactstrap';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  getModelOptions,
  getProductLinkDetails,
  postProductLink,
  setProductStockStatus,
} from '../redux/actions/data/productActions';
import {
  PRODUCT_LINK_POST_RESET,
  SET_PRODUCT_STOCK_STATUS_IDLE,
} from '../redux/constants/data/productConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function ProductLinkDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { location } = history;
  const query = useQuery();

  const [current_page, setCurrent_page] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [model, setModel] = useState({ label: '請選擇型號', value: '' });
  const [brand, setBrand] = useState({ label: '請選擇品牌', value: 0 });

  const [editData, setEditData] = useState({ id: '', link: '' });
  const pageSize = 10;

  const { loading, linkDetailInfo, error } = useSelector(
      state => state.productLinkDetail,
  );
  const { success: stockSuccess, error: stockError } = useSelector(
      state => state.setProductStockStatus,
  );

  // const { productOptions } = useSelector((state) => state.productOptions)
  const { modelOptions: productOptions, loading: modelOptionsLoading } =
      useSelector(state => state.productmodelOptions);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
      state => state.brandOptions,
  );
  const {
    loading: isSumbitLoading,
    success,
    error: submitError,
  } = useSelector(state => state.productLinkPost);
  const { userInfo } = useSelector(state => state.userLogin);

  //***modal***
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);

  useEffect(() => {
    if (stockSuccess) {
      dispatch(
          getProductLinkDetails(
              id,
              keyword,
              brand.value,
              model.value,
              current_page,
              pageSize,
          ),
      );
      dispatch({ type: SET_PRODUCT_STOCK_STATUS_IDLE });
    }

    if (stockError) {
      MessageUtil.alertWanring('更新錯誤', stockError);
      dispatch({ type: SET_PRODUCT_STOCK_STATUS_IDLE });
    }
  }, [stockSuccess, stockError]);

  useEffect(() => {
    dispatch(getBrandOptions());

    // dispatch(getProductOptions('model'))
  }, []);

  useEffect(() => {
    if (brand.label === '請選擇品牌') {
      dispatch(getModelOptions(''));
    } else {
      dispatch(getModelOptions(brand.label));
    }
  }, [brand]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryBrand = query.value.brand;
      const queryModel = query.value.model;
      const queryPage = query.value.page;
      setCurrent_page(queryPage);

      if (queryKeyword || queryBrand || queryModel) {
        setKeyword(queryKeyword);
        setBrand(
            brandOptions?.find(option => option.value == queryBrand) ?? brand,
        );
        setModel(
            productOptions?.find(option => option.value == queryModel) ?? model,
        );

        //儲存搜尋及頁數
        dispatch(
            getProductLinkDetails(
                id,
                queryKeyword,
                queryBrand,
                queryModel,
                queryPage,
                pageSize,
            ),
        );
      } else {
        //單純記錄頁數
        dispatch(getProductLinkDetails(id, '', '', '', queryPage, pageSize));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setKeyword('');
      setBrand({ label: '請選擇品牌', value: 0 });
      setModel({ label: '請選擇型號', value: '' });

      //初始搜尋
      dispatch(getProductLinkDetails(id, '', '', '', 1, pageSize));
    }
  }, [location.search, current_page]);

  useEffect(() => {
    if (success) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(
          getProductLinkDetails(
              id,
              keyword,
              brand.value,
              model.value,
              current_page,
              pageSize,
          ),
      );
      toggle3();
    }

    return () => {
      dispatch({ type: PRODUCT_LINK_POST_RESET });
    };
  }, [success, submitError]);

  const handleSubmitLink = e => {
    e.preventDefault();
    console.log(editData);
    const linkInfo = {
      ecId: id,
      productId: editData.id,
      productLink: editData.link,
    };
    dispatch(postProductLink(linkInfo));
  };

  const handlePageChange = page => {
    setCurrent_page(page);
    console.log(pageSize);
    dispatch(
        getProductLinkDetails(
            id,
            keyword,
            brand.value,
            model.value,
            page,
            pageSize,
        ),
    );

    if (keyword || brand.value || model.value) {
      history.push(
          `${location.pathname}?keyword=${keyword}&brand=${brand.value}&model=${model.value}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrent_page(1);
    dispatch(
        getProductLinkDetails(id, keyword, brand.value, model.value, 1, pageSize),
    );
    history.push(
        `${location.pathname}?keyword=${keyword}&brand=${brand.value}&model=${
            model.value
        }&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setKeyword('');
    setBrand({ label: '請選擇品牌', value: 0 });
    setModel({ label: '請選擇型號', value: '' });
    setCurrent_page(1);
    dispatch(getProductLinkDetails(id, '', '', '', 1, pageSize));
    history.push(`${location.pathname}`);
  };

  console.log(linkDetailInfo);

  return (
      <Layout
          pageTitle="導購連結編輯"
          items={[
            { isActive: false, page_name: '商品管理' },
            {
              isActive: false,
              page_name: '導購連結設定',
              to: '/ProductLinkControl',
            },
            { isActive: true, page_name: '導購連結編輯' },
          ]}
      >
        <Card className={style.search_card}>
          <div className={style.card_header}>搜尋條件</div>
          <div className={style.card_body}>
            <form onSubmit={handleSearch}>
              <div className={style.show_flex}>
                <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                  <label
                      style={{ lineHeight: '48px', width: '100px' }}
                      htmlFor="keyword"
                  >
                    關鍵字
                  </label>
                  <input
                      id="keyword"
                      type="text"
                      value={keyword}
                      onChange={e => setKeyword(e.target.value)}
                      className="form-control"
                      placeholder="品項編碼、商品名稱"
                  />
                </div>
                <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                  <label
                      style={{ lineHeight: '48px', width: '50px' }}
                      htmlFor="brand"
                  >
                    品牌
                  </label>
                  <Select
                      isLoading={brandOptionsLoading}
                      isDisabled={brandOptionsLoading}
                      className="w200"
                      options={
                          brandOptions && [
                            { label: '請選擇品牌', value: '0' },
                            ...brandOptions,
                          ]
                      }
                      value={brand}
                      onChange={option => setBrand(option)}
                  />
                </div>
                <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                  <label
                      style={{ lineHeight: '48px', width: '50px' }}
                      htmlFor="model"
                  >
                    型號
                  </label>
                  <Select
                      isLoading={modelOptionsLoading}
                      isDisabled={modelOptionsLoading}
                      className="w200"
                      value={model}
                      options={
                          productOptions && [
                            { label: '請選擇型號', value: '' },
                            ...productOptions,
                          ]
                      }
                      onChange={option => setModel(option)}
                  />
                </div>
              </div>
              <div className={style.button_position}>
                <Button
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={clearSearchForm}
                >
                  取消
                </Button>
                <Button type="submit" className={style.modal_button_submit}>
                  查詢
                </Button>
              </div>
            </form>
          </div>
        </Card>

        <Card className={style.card}>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
            {!loading && linkDetailInfo && linkDetailInfo.total_count
                ? linkDetailInfo.total_count
                : '0'}
          </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">品項編碼</th>
                <th scope="col" style={{ minWidth: '120px' }}>
                  商品
                </th>
                <th scope="col" style={{ minWidth: '120px' }}>
                  品牌
                </th>
                <th scope="col" style={{ minWidth: '120px' }}>
                  型號
                </th>
                <th>庫存</th>
                <th scope="col">連結網址</th>
                <th scope="col">功能</th>
              </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                  <tbody>
                  {linkDetailInfo ? (
                      linkDetailInfo.datas.map((data, index) => (
                          <tr key={index}>
                            <td style={{ wordBreak: 'keep-all' }}>
                              {index +
                                  1 +
                                  (current_page - 1) * linkDetailInfo.page_size}
                            </td>
                            <td style={{ wordBreak: 'keep-all' }}>{data.code}</td>
                            <td style={{ wordBreak: 'keep-all' }}>
                              {data.name}/{data.color}
                            </td>
                            <td>{data.brandName}</td>
                            <td style={{ wordBreak: 'keep-all' }}>{data.model}</td>
                            <td>
                              {data.isCrawler === false && (
                                  <>
                                    <div className={style['radio-btn-layout']}>
                                      <input
                                          className={style['radio-btn']}
                                          type="radio"
                                          checked={data.stockStatus === '有貨'}
                                          onClick={() => {
                                            dispatch(
                                                setProductStockStatus({
                                                  ecId: id,
                                                  productId: data.id,
                                                  stockStatus: '有貨',
                                                }),
                                            );
                                          }}
                                      />
                                      <span className="ml-2">有庫存</span>
                                    </div>
                                    <div className={style['radio-btn-layout']}>
                                      <input
                                          className={style['radio-btn']}
                                          type="radio"
                                          checked={data.stockStatus === '沒貨'}
                                          onClick={() => {
                                            dispatch(
                                                setProductStockStatus({
                                                  ecId: id,
                                                  productId: data.id,
                                                  stockStatus: '沒貨',
                                                }),
                                            );
                                          }}
                                      />
                                      <span className="ml-2">無庫存</span>
                                    </div>
                                  </>
                              )}
                            </td>
                            <td>
                              {data.productLink && (
                                  <a
                                      href={data.productLink}
                                      className="m-2 btn btn-outline-success btn-sm"
                                      target="_blank"
                                      rel="noreferrer"
                                  >
                                    連結
                                  </a>
                              )}
                            </td>
                            <td>
                              {' '}
                              <Button
                                  color="primary"
                                  outline
                                  size="sm"
                                  onClick={() => {
                                    setEditData({
                                      id: data.id,
                                      link: data.productLink,
                                    });
                                    toggle3();
                                  }}
                                  className="me-2"
                              >
                                編輯網址
                              </Button>
                            </td>
                          </tr>
                      ))
                  ) : (
                      <TableHasNoData />
                  )}
                  </tbody>
              )}
            </Table>
          </div>

          {!loading && linkDetailInfo && linkDetailInfo.total_count > 0 && (
              <div className="d-flex align-items-center justify-content-center">
                <RcPagination
                    defaultCurrent={linkDetailInfo.current_page}
                    defaultPageSize={linkDetailInfo.page_size}
                    total={linkDetailInfo.total_count}
                    onChange={handlePageChange}
                />
              </div>
          )}
        </Card>

        <Modal
            zIndex={2000}
            centered
            size="lg"
            isOpen={modal3}
            toggle={toggle3}
            backdrop="static"
        >
          <ModalHeader className={style.modal_head} toggle={toggle3}>
            編輯網址
          </ModalHeader>
          <form className="form-list form-label-140">
            <ModalBody className={style.modal_body}>
              <div className={style.formGroup}>
                <label className="required" style={{ width: '80px' }}>
                  網址
                </label>
                <div className="form-item">
                <textarea
                    className="form-control"
                    rows="3"
                    onChange={e =>
                        setEditData({ ...editData, link: e.target.value })
                    }
                    value={editData.link}
                ></textarea>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className={style.modal_footer}>
              <Button
                  color="link"
                  className={`${style.modal_button_cancel} ms-auto`}
                  onClick={toggle3}
              >
                取消
              </Button>{' '}
              <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={isSumbitLoading}
              >
                {isSumbitLoading && (
                    <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                <span className="btn-wrapper--label" onClick={handleSubmitLink}>
                儲存
              </span>
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </Layout>
  );
}
