import React, { useMemo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Alert } from 'reactstrap';

import style from '../styles/layout.module.css';

const BillInfo = ({ isOpen, toggle, info }) => {
    const data = useMemo(() => {
        if ('productInfo' in info) {
            return info;
        }
        return {
            amount: info.finalPriceUnit,
            price: {
                商品單價: (
                    <div className="price text-black fw-normal">
                        {info?.unitPrice?.toLocaleString('en-US') || 0}
                    </div>
                ),
                折扣金額:
                    info?.discountAmountList?.length > 0 ? (
                        info.discountAmountList.map(d => {
                            return (
                                <div className="text-nowrap">
                                    -
                                    <span className="price text-black fw-normal">
                    {d?.discountAmount?.toLocaleString('en-US')}
                  </span>
                                    ({d.discountName})
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-nowrap">
                            -
                            <span className="price text-black fw-normal">
                {info.discountAmountUnit?.toLocaleString('en-US')}
              </span>
                        </div>
                    ),
            },
            productInfo: {
                主單編號: info.orderNumber,
                商品名稱: info.productname,
                數量: info.quantity,
                訂單編號: info.orderItemNumber,
            },
        };
    }, [info]);

    return (
        <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className={style.modal_head}>
                銷售金額明細
            </ModalHeader>
            <ModalBody>
                <div style={{ marginLeft: '1rem' }}>
                    {Object.keys(data.productInfo).map((key, index) => {
                        return (
                            <div key={index} className={`fs-14 mb-2 ${style.show_flex}`}>
                                <div className="w100">{key}</div>
                                <div>{data.productInfo[key]}</div>
                            </div>
                        );
                    })}
                </div>
                <Alert color="secondary">
                    {Object.keys(data.price).map((key, index) => {
                        return (
                            <div key={key} className={`fs-14 mb-2 ${style.show_flex}`}>
                                <div className="w100">{key}</div>
                                <div className="text-black">{data.price[key]}</div>
                            </div>
                        );
                    })}
                </Alert>
                <div
                    className={`fs-14 mb-2 ${style.show_flex}`}
                    style={{ marginLeft: '1rem' }}
                >
                    <div className="w100">實際銷售金額</div>
                    <div className="price text-black fw-normal">
                        {data.amount.toLocaleString('en-US')}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default BillInfo;
