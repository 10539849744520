import {
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_SAVE_REQUEST,
  PAYMENT_LIST_SAVE_SUCCESS,
  PAYMENT_LIST_SAVE_FAIL,
  PAYMENT_LIST_SAVE_RESET,
} from 'redux/constants/data/paymentConstants';

export const paymentListReducer = (
  state = { loading: true, paymentList: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case PAYMENT_LIST_REQUEST:
      return { ...state, loading: true };
    case PAYMENT_LIST_SUCCESS:
      return { loading: false, paymentList: payload || [] };
    case PAYMENT_LIST_FAIL:
      return { error: payload, loading: false, paymentList: [] };
    default:
      return state;
  }
};

export const paymentListSaveReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PAYMENT_LIST_SAVE_REQUEST:
      return { loading: true };
    case PAYMENT_LIST_SAVE_SUCCESS:
      return { loading: false, success: true };
    case PAYMENT_LIST_SAVE_FAIL:
      return { error: payload, loading: false };
    case PAYMENT_LIST_SAVE_RESET:
      return { error: null, success: false };
    default:
      return state;
  }
};
