import { useFormik } from 'formik';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { editModelAvailableTime } from 'redux/actions/data/modelActions';
import { EDIT_MODEL_AVAILABLETIME_IDLE } from 'redux/constants/data/modelConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const EditAvailableTimeModal = ({ open, toggle, info, onRefresh }) => {
  const dispatch = useDispatch();
  const { success, error } = useSelector(state => state.editModelAvailableTime);
  const { values, handleSubmit, setFieldValue, errors, touched, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        availableEndTimeType: info.type,
        availableTimeEnd: info.endTime,
        availableTimeStart: info.startTime,
      },
      onSubmit: values => {
        const body = {
          ...values,
          availableTimeEnd: new Date(values.availableTimeEnd).toLocaleString(
            'zh-TW',
            {
              dateStyle: 'medium',
              hourCycle: 'h23',
              timeStyle: 'medium',
            },
          ),
          availableTimeStart: new Date(
            values.availableTimeStart,
          ).toLocaleString('zh-TW', {
            dateStyle: 'medium',
            hourCycle: 'h23',
            timeStyle: 'medium',
          }),
        };

        if (Number(body.availableEndTimeType) === 2) {
          delete body.availableTimeEnd;
        }

        CommonUtil.consoleLog({ anno: 'request body', data: body });
        dispatch(editModelAvailableTime(body, info.id));
      },
      validationSchema: Yup.object().shape({
        availableEndTimeType: Yup.string().required('必填'),
        availableTimeEnd: Yup.string().nullable().required('必填'),
        availableTimeStart: Yup.string().nullable().required('必填'),
      }),
    });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('儲存成功!');
      toggle();
      onRefresh();
      dispatch({ type: EDIT_MODEL_AVAILABLETIME_IDLE });
    }

    if (error) {
      MessageUtil.alertWanring('儲存失敗', error);
      dispatch({ type: EDIT_MODEL_AVAILABLETIME_IDLE });
    }
  }, [success, error]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        銷售時間修改
      </ModalHeader>
      <ModalBody style={{ height: '450px' }}>
        <div style={{ display: 'flex', marginLeft: '1rem' }}>
          <div>
            <Label>銷售開始時間</Label>
            <div className="form-item flex-wrap d-flex mb-3">
              <div className={style['form-select-long']}>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd HH:mm:ss"
                  timeFormat="HH:mm:ss"
                  timeIntervals={1}
                  showTimeSelect
                  selected={
                    values?.availableTimeStart
                      ? new Date(values?.availableTimeStart)
                      : ''
                  }
                  name="availableTimeStart"
                  onChange={date => {
                    setFieldValue('availableTimeStart', new Date(date));
                  }}
                  selectsStart
                />
              </div>
              {errors &&
                touched &&
                errors.availableTimeStart &&
                touched.availableTimeStart && (
                  <span className="text-danger">
                    {errors.availableTimeStart}
                  </span>
                )}
            </div>
            <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
              <Input
                type="select"
                className="w200"
                name="availableEndTimeType"
                onChange={event => {
                  setFieldValue('availableEndTimeType', event.target.value);
                }}
                value={values.availableEndTimeType}
              >
                <option value="1">自訂時間</option>
                <option value="2">無限期</option>
              </Input>
              {errors &&
                touched &&
                errors.availableEndTimeType &&
                touched.availableEndTimeType && (
                  <span className="text-danger">
                    {errors.availableEndTimeType}
                  </span>
                )}
              {Number(values.availableEndTimeType) === 2 && (
                <span>結束日期預計為 2099/12/12 12:12:12</span>
              )}
            </div>
            {Number(values.availableEndTimeType) === 1 && (
              <div className="form-item flex-wrap d-flex mb-3">
                <div className={style['form-select-long']}>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd HH:mm:ss"
                    timeFormat="HH:mm:ss"
                    timeIntervals={1}
                    showTimeSelect
                    selected={
                      values.availableTimeEnd
                        ? new Date(values.availableTimeEnd)
                        : ''
                    }
                    name="availableTimeEnd"
                    onChange={date => {
                      setFieldValue('availableTimeEnd', new Date(date));
                    }}
                    selectsEnd
                    minDate={values.availableTimeStart}
                  />
                </div>
                {errors &&
                  touched &&
                  errors.availableTimeEnd &&
                  touched.availableTimeEnd && (
                    <span className="text-danger">
                      {errors.availableTimeEnd}
                    </span>
                  )}
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditAvailableTimeModal;
