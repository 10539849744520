import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Table } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  addAdmin,
  deleteAdmin,
  getAdminDetails,
  getAdminList,
  getPermissionOptions,
  sendVerifyLetter,
  updateAdmin,
} from '../redux/actions/data/adminActions';
import {
  ADMIN_ADD_RESET,
  ADMIN_DELETE_RESET,
  ADMIN_SEND_VERIFY_RESET,
  ADMIN_UPDATE_RESET,
} from '../redux/constants/data/adminConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

// import prompt from 'password-prompt'

export default function Admin(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [modal, setModal] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [adminId, setAdminId] = useState(0);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [permissionIds, setPermissionIds] = useState([]);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, adminListInfo, error } = useSelector(
    state => state.adminList,
  );
  const { adminDetailsInfo } = useSelector(state => state.adminDetails);
  const { options } = useSelector(state => state.permissionOptions);

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.adminAdd);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.adminUpdate);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = useSelector(state => state.adminDelete);
  const {
    loading: sendLoading,
    success: sendSuccess,
    error: sendError,
  } = useSelector(state => state.adminSendVerify);

  useEffect(() => {
    if (adminDetailsInfo && adminDetailsInfo.name) {
      setEditData(adminDetailsInfo);
      setIsSuperAdmin(adminDetailsInfo.isSuper);

      if (adminDetailsInfo.permissionIds) {
        setPermissionIds(adminDetailsInfo.permissionIds);
      } else {
        setPermissionIds([]);
      }
    }
  }, [adminDetailsInfo]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        setKeyword(queryKeyword);
        setStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(
          getAdminList({
            keyword: queryKeyword,
            page: queryPage,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getAdminList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      dispatch(getAdminList({ page: 1 }));

      // dispatch(getPermissionOptions())
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getPermissionOptions());
  }, []);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      setModal(false);
      dispatch(getAdminList({ page: 1 }));
    }

    if (addError) {
      MessageUtil.alertWanring(addError, '');
    }

    return () => {
      dispatch({ type: ADMIN_ADD_RESET });
    };
  }, [addSuccess, addError]);

  useEffect(() => {
    if (updateSuccess || updateError === undefined) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      setModal(false);
      dispatch(getAdminList({ page: 1 }));
      dispatch({ type: ADMIN_UPDATE_RESET });
    }

    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: ADMIN_UPDATE_RESET });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (sendSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('驗證信已寄出');
      dispatch({ type: ADMIN_SEND_VERIFY_RESET });
    }

    if (sendError) {
      MessageUtil.alertWanring(sendError, '');
      dispatch({ type: ADMIN_SEND_VERIFY_RESET });
    }
  }, [sendSuccess, sendError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (adminListInfo.datas.length !== 1) {
        dispatch(
          getAdminList({
            page: current_page,
          }),
        );
      } else {
        setCurrentPage(current_page - 1);

        dispatch(
          getAdminList({
            page: current_page - 1,
          }),
        );

        if (keyword || status) {
          history.push(
            `${location.pathname}?keyword=${keyword}&status=${status}&page=${
              current_page - 1
            }`,
          );
        } else {
          history.push(`${location.pathname}?page=${current_page - 1}`);
        }
      }
    }

    return () => {
      dispatch({ type: ADMIN_DELETE_RESET });
    };
  }, [deleteSuccess]);

  const toggleModal = () => {
    setModal(!modal);
    setAdminId(0);
    setIsSuperAdmin(false);
    setPermissionIds([]);
    setEditData(null);
    setIsReadOnly(false);
  };

  const clearSearchForm = () => {
    setKeyword('');
    setStatus('');
    dispatch(getAdminList({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getAdminList({
        keyword,
        page: 1,
        status,
      }),
    );
    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getAdminList({
        keyword,
        page,
        status,
      }),
    );
    if (keyword || status) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const toggleEditContent = id => {
    setModal(!modal);
    setAdminId(id);
    setIsReadOnly(true);

    dispatch(getAdminDetails(id));
  };

  const handleDelete = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteAdmin(id));
    });
  };

  const handleSubmit = values => {
    const info = {
      account: values.account,
      id: adminId,
      isSuper: isSuperAdmin,

      // pwd: confirm,
      name: values.name,
      permissionIds: isSuperAdmin ? [] : permissionIds,
      status: values.status,
    };

    let serviceMenagement = false;

    permissionIds.forEach(id => {
      if (id == 30 || id == 31 || id == 32 || id == 33) {
        serviceMenagement = true;
      }
    });

    if (serviceMenagement && !permissionIds.includes(35)) {
      info.permissionIds.push(35);
    }

    if (permissionIds.includes(35) && !serviceMenagement) {
      let newArr = permissionIds.filter(id => id !== 35);
      info.permissionIds = newArr;
    }

    if (adminId > 0) {
      dispatch(updateAdmin(info));
    } else {
      dispatch(addAdmin(info));
    }
  };

  const handleCheck = (id, checked) => {
    if (checked) {
      setPermissionIds([...permissionIds, id]);
    } else {
      setPermissionIds(permissionIds.filter(targetId => targetId != id));
    }
  };

  const handleSend = id => {
    dispatch(sendVerifyLetter(id));
    setAdminId(id);
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      account: Yup.string().required('必填!'),
      name: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <Layout
      pageTitle="權限管理"
      items={[{ isActive: false, page_name: '權限管理' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '2rem' }}>
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  id="keyword"
                  type="text"
                  className={`form-control ${style['form-input-order-number']}`}
                  placeholder="E-mail、姓名"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>
              <div className={style.formGroup}>
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  value={status}
                  id="status"
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="正常">正常</option>
                  <option value="未驗證">未驗證</option>
                  <option value="停用">停用</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && adminListInfo && adminListInfo.total_count
              ? adminListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>帳號(E-mail)</th>
                <th>姓名</th>
                <th>狀態</th>
                <th>權限</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {adminListInfo ? (
                  adminListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index +
                          1 +
                          (current_page - 1) * adminListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.account}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.name}</td>
                      <td>{data.status}</td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {!data.isSuper &&
                          data.permissions.map(item => item).join('、')}
                        {data.isSuper && '超級管理員'}
                      </td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <>
                          {data.status === '未驗證' && (
                            <Button
                              color="warning"
                              outline
                              size="sm"
                              onClick={() => handleSend(data.id)}
                              className="m-2"
                              disabled={sendLoading}
                            >
                              {adminId == data.id && sendLoading && (
                                <span
                                  className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              重發驗證mail
                            </Button>
                          )}
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => toggleEditContent(data.id)}
                            className="m-2"
                          >
                            編輯
                          </Button>
                          <Button
                            className="m-2"
                            color="danger"
                            outline
                            size="sm"
                            onClick={() => handleDelete(data.id)}
                          >
                            刪除
                          </Button>
                        </>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {!loading && adminListInfo && adminListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={adminListInfo.current_page}
                defaultPageSize={adminListInfo.page_size}
                total={adminListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          權限編輯
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            account: editData && editData.account ? editData.account : '',
            name: editData && editData.name ? editData.name : '',
            status: editData && editData.status ? editData.status : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required w150" htmlFor="account">
                    帳號(E-mail){' '}
                    <ErrorMessage
                      name="account"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <Field
                    id="account"
                    name="account"
                    type="text"
                    className="form-control flex-1"
                    readOnly={isReadOnly}
                  />
                </div>

                <div className={style.formGroup}>
                  <label className="required w150">
                    姓名{' '}
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <Field
                    id="name"
                    name="name"
                    type="textarea"
                    className="form-control flex-1"
                  />
                </div>

                <div className={style.formGroup}>
                  <label className="required w150" htmlFor="name">
                    權限
                  </label>

                  <div className="form-item">
                    {/* <div className="text-danger">
                      給開發：設定超級管理員後，下面的權限都藏，超級管理員沒勾選，下面的權限才會出現
                    </div> */}

                    <div className="d-flex">
                      超級管理員：
                      <FormGroup>
                        <input
                          className="me-2"
                          type="checkbox"
                          id="isSuper"
                          checked={isSuperAdmin}
                          onChange={e => setIsSuperAdmin(e.target.checked)}
                        />
                        <Label check for="isSuper">
                          是
                        </Label>
                      </FormGroup>
                    </div>
                    <FormGroup
                      style={
                        isSuperAdmin
                          ? { display: 'none' }
                          : { display: 'block' }
                      }
                    >
                      {options?.map((option, index) => (
                        <div
                          key={index}
                          style={{
                            display:
                              option.label === '客服管理' ? 'none' : 'block',
                          }}
                        >
                          <input
                            className="me-2"
                            type="checkbox"
                            id={option.value}
                            value={option.value}
                            checked={
                              !!permissionIds?.find(id => id == option.value)
                            }
                            onChange={e =>
                              handleCheck(option.value, e.target.checked)
                            }
                          />
                          <Label check for={option.value}>
                            {option.label}
                          </Label>
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required w150" style={{ width: '120px' }}>
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="d-flex form-item">
                    <Field
                      className="my-auto me-2"
                      id="normal"
                      name="status"
                      type="radio"
                      value="正常"
                      disabled={editData?.status === '未驗證'}
                    />
                    <label htmlFor="normal" className="me-5">
                      正常
                    </label>
                    {/* <Field
                      className="my-auto me-2"
                      id="notyet"
                      name="status"
                      type="radio"
                      value="未驗證"
                    />
                    <label htmlFor="notyet" className="me-5">
                      未驗證
                    </label> */}
                    <Field
                      className="my-auto me-2"
                      id="stop"
                      name="status"
                      type="radio"
                      value="停用"
                      disabled={editData?.status === '未驗證'}
                    />
                    <label htmlFor="stop" className="me-5">
                      停用
                    </label>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading || updateLoading}
                >
                  {addLoading ||
                    (updateLoading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ))}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
