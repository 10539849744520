import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { getCouponDetails } from 'redux/actions/data/orderActions';

import style from '../styles/layout.module.css';

import TableHasNoData, { TableLoading } from './TableHasNoData';

const DiscountDetailModal = ({ isOpen, toggle, id }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCouponDetails(id)
      .then(res => {
        setActivities(res || []);
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Modal
      isOpen={isOpen}
      zIndex={2000}
      centered
      toggle={toggle}
      size="xl"
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        折價券明細
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>活動序號</th>
                <th>活動名稱</th>
                <th>折扣金額</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {activities.length > 0 ? (
                  activities.map((activity, index) => (
                    <tr key={index}>
                      <td>
                        {/* 活動序號 */}
                        {activity.activityCode}
                      </td>
                      <td>
                        {/* 活動名稱 */}
                        {activity.name}
                      </td>
                      <td className="price fw-normal text-black">
                        {/* 折扣金額 */}
                        {activity.discountAmount.toLocaleString('en-US')}
                      </td>
                      <td className="text-center">
                        {/* 功能 */}
                        <Button
                          color="link"
                          className="text-decoration-none"
                          size="sm"
                          onClick={() => {
                            window.open(
                              `/#/VoucherList?page=1&keyword=${activity.name}&activityCode=${activity.activityCode}`,
                              '_blank',
                            );
                          }}
                        >
                          查看明細
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DiscountDetailModal;
