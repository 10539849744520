import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  createMilestone,
  updateMilestone,
} from '../../redux/actions/data/distributorActions';
import { initMilestone } from '../../redux/reducers/data/distributorReducers';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
const lableStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };

const MilestoneEditModal = ({
  isOpen = false,
  onClose,
  milestone = initMilestone,
}) => {
  const [loading, setLoading] = useState(false);
  const { values, setValues, setFieldValue, errors, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: milestone,
    onSubmit: async values => {
      setLoading(true);
      try {
        if (milestone.id) {
          await updateMilestone(values);
          MessageUtil.alertSuccess('更新成功');
        } else {
          await createMilestone(values);
          MessageUtil.alertSuccess('新增成功');
        }
      } catch (e) {
        if (milestone.id) {
          MessageUtil.alertWanring('更新失敗', e);
        } else {
          MessageUtil.alertWanring('新增失敗', e);
        }
      }
      setLoading(false);
      onClose(true);
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().required('請輸入經歷'),
      content_en: Yup.string().required('請輸入經歷(英)'),
      year: Yup.string()
        .required('請輸入年份')
        .test('is-invalid', '年份格式錯誤', val => {
          return /\d{4}/.test(val);
        }),
    }),
  });

  useEffect(() => {
    if (isOpen) {
      setValues(milestone);
    } else {
      setValues(null);
    }
  }, [isOpen, milestone]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        onClose();
      }}
      backdrop="static"
    >
      <ModalHeader
        className={style.modal_head}
        toggle={() => {
          onClose();
        }}
      >
        新增選單
      </ModalHeader>
      <ModalBody
        className={style.modal_body}
        style={{ height: '730px', margin: '1rem' }}
      >
        {values && (
          <div className="form-list form-label-140">
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                年份
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Input
                    name="year"
                    className="mx-2"
                    maxLength="4"
                    value={values?.year || ''}
                    onChange={e => {
                      setFieldValue('year', e.target.value);
                    }}
                  />
                </div>
                <div className="form-error px-2">{errors?.year || ''}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                經歷(中)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Input
                    name="content"
                    className="mx-2"
                    value={values?.content}
                    onChange={e => {
                      setFieldValue('content', e.target.value);
                    }}
                  />
                </div>
                <div className="form-error px-2">{errors?.content || ''}</div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                經歷(英)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Input
                    name="content_en"
                    className="mx-2"
                    value={values?.content_en}
                    onChange={e => {
                      setFieldValue('content_en', e.target.value);
                    }}
                  />
                </div>
                <div className="form-error px-2">
                  {errors?.content_en || ''}
                </div>
              </div>
            </FormGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          className={`me-3 ${style.modal_button_cancel}`}
          onClick={() => {
            onClose();
          }}
        >
          取消
        </Button>
        <Button
          className={style.modal_button_submit}
          onClick={() => {
            handleSubmit();
          }}
          disabled={loading || Object.keys(errors).length > 0}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MilestoneEditModal;
