import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  GET_PROMO_DETAIL_FAIL,
  GET_PROMO_DETAIL_REQUEST,
  GET_PROMO_DETAIL_SUCCESS,
  GET_PROMO_LIST_FAIL,
  GET_PROMO_LIST_REQUEST,
  GET_PROMO_LIST_SUCCESS,
} from '../../constants/data/promoConstants';

export const getPromoList = (
  data = {
    availableWay: [],
    endTime: '',
    keyword: '',
    order: '',
    order_by: '',
    page: 1,
    page_size: 50,
    productRange: '',
    startTime: '',
    timeType: '',
    type: 0,
    useMin: 0,
  },
) => {
  const url = '/Backend/PromoSetting/List';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_PROMO_LIST_FAIL,
      method: 'post',
      requestConstant: GET_PROMO_LIST_REQUEST,
      successConstant: GET_PROMO_LIST_SUCCESS,
      url,
    });
  };
};

export const getPromoDetail = (
  params = {
    id: 0,
  },
) => {
  const url = '/Backend/PromoSetting/Get';
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_PROMO_DETAIL_FAIL,
      method: 'get',
      params,
      requestConstant: GET_PROMO_DETAIL_REQUEST,
      successConstant: GET_PROMO_DETAIL_SUCCESS,
      url: `${url}/${params.id}`,
    });
  };
};

export const createPromo = async (data = new FormData()) => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url: '/Backend/PromoSetting/Create',
  }).then(res => {
    if (res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

/** @Params
 * id : integer($int32)
 * name : string
 * redeemStartTime : string
 * redeemEndTime : string
 * availableWay : string
 * useMin : integer($int32)
 * useMinAmount : integer($int32)
 * productRange : string
 * discountType : integer($int32)
 * discountAmount : number($double)
 * isUsedwithDiscount : boolean
 * isUsedwithCoupon : boolean
 * isUsedwithYouLike : boolean
 * isBatchGenerate : boolean
 * batchGenerateSetting : integer($int32)
 * batchCodeList : string
 * promoPrefix : string
 * products : string
 * */
export const updatePromo = async (data = new FormData()) => {
  CommonUtil.consoleLog({ anno: 'request body', data });
  return await request({
    data,
    method: 'post',
    url: '/Backend/PromoSetting/Update',
  }).then(res => {
    if (res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};

export const deletePromo = async (params = { id: 0 }) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/PromoSetting/Delete',
  }).then(res => {
    if (res.code === 200) {
      return res;
    } else {
      throw res.title;
    }
  });
};

export const exportCodePromoExportCode = async (params = { id: 0 }) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/PromoSetting/ExportCode',
  }).then(res => {
    if (res.code === 200) {
      return res;
    } else {
      throw res.message;
    }
  });
};
