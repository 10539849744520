import { useEffect, useState } from 'react';

import { getStoreOptions } from '../redux/actions/data/orderShippingActions';

export const useStore = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([
    { label: 'online', value: 'online' },
  ]);

  useEffect(() => {
    setLoading(true);
    getStoreOptions().then(res => {
      setLoading(false);
      setOptions([
        ...(res?.result || []).map(r => {
          return {
            label: r.companyname || '--',
            value: r.suppliercode || '--',
          };
        }),
        { label: 'online', value: 'online' },
      ]);
    });
  }, []);

  return { loading, options };
};
