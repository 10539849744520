import moment from 'moment/moment';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const GiftDetailModal = ({ open, toggle }) => {
  const { gifts, loading } = useSelector(state => state.getBindingGiftsList);
  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        贈品
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        <div className="table-responsive mb-5">
          <Table bordered striped hover>
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>贈品序號</th>
                <th style={{ width: '250px' }}>贈品主圖</th>
                <th>贈品名稱</th>
                <th>贈品料號</th>
                <th>
                  <div>已發送數量</div>
                  <div>贈品庫存</div>
                </th>
                <th>
                  <div>加入此贈品日期</div>
                  <div>加入人員</div>
                </th>
                <th>
                  <div>贈品上架時間</div>
                  <div>贈品下架時間</div>
                </th>
                <th>贈品狀態</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {gifts?.datas?.length > 0 ? (
                  gifts.datas.map(data => (
                    <tr key={JSON.stringify(data)}>
                      <td className="text-center text-nowrap">
                        {/* 贈品序號 */}
                        {data.freebieCode}
                      </td>
                      <td className="text-center">
                        {/* 贈品主圖 */}
                        <img
                          className={style.w200}
                          src={data?.freebieImgInfo[0]?.imageUrl || ''}
                          alt={data?.freebieImgInfo[0]?.imageUrl || ''}
                        />
                      </td>
                      <td className="text-center">
                        {/* 贈品名稱 */}
                        {data.name || '--'}
                      </td>
                      <td className="text-center text-nowrap">
                        {data.productcode || '--'}
                      </td>
                      <td className="text-center">
                        {/* 已發送數量/贈品庫存 */}
                        <div>{data.soldNum ?? '--'}</div>
                        <div>{data.stock ?? '--'}</div>
                      </td>
                      <td className="text-left">
                        {/* 加入贈品日期 */}
                        <div className="text-nowrap">
                          {data.addTime
                            ? moment(data.addTime).format('YYYY/MM/DD HH:mm:ss')
                            : '--'}
                        </div>
                        {/* 加入人員 */}
                        <div className="text-nowrap">
                          {data.addAdmin || '--'}
                        </div>
                      </td>
                      <td className="text-left">
                        {/* 贈品上架時間/贈品下架時間 */}
                        <div className="text-nowrap">
                          {moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                        <div className="text-nowrap">
                          {moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                      </td>

                      <td className="text-center">
                        {/* 贈品狀態 */}
                        {data.isSell ? '開啟' : '關閉'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(GiftDetailModal);
