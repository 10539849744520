import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  getAreaList,
  getCityList,
  getCountryList,
} from 'redux/actions/data/locationActions';
import {
  getColorModelOption,
  getMemberInfoViaPhoneNumber,
  getProductInfoViaWarrantySerialNumber,
  getRepairBrandOption,
  getRepairModelOption,
  updateRepairRegister,
} from 'redux/actions/data/repairActions';
import { getSizeOptions } from 'redux/actions/data/warrantyActions';
import {
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE,
  UPDATE_REPAIR_REGISTER_IDLE,
} from 'redux/constants/data/repairConstants';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../styles/layout.module.css';

const ExchangeWarrantyProductModal = ({
  isOpen,
  toggle,
  onRefresh,
  info,
  type,
}) => {

  const dispatch = useDispatch();
  const { repairBrandOptions } = useSelector(state => state.repairBrandOptions);
  const { repairModelOptions } = useSelector(state => state.repairModelOptions);
  const { repairColorOptions } = useSelector(state => state.repairColorOptions);
  const { sizeOptions } = useSelector(state => state.getSizeOptions);

  const { loading, success, error } = useSelector(
    state => state.updateRepiarRegister,
  );

  const {
    success: getInfoSuccess,
    error: getInfoError,
    info: memberInfo,
  } = useSelector(state => state.getMemberInfoViaPhoneNumber);
  const {
    success: getSuccess,
    error: getError,
    info: productInfo,
  } = useSelector(state => state.getProductInfoViaWarrantySerialNumber);

  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: '',
      brand: { label: '請選擇', value: '' },
      city: { label: '請選擇', value: '' },
      color: { label: '請選擇', value: '' },
      colorOptions: [],
      country: { label: '請選擇', value: '' },
      email: info?.email ?? '',
      firstName: '',
      isExchangedNew: true,
      isNew: true,
      lastName: '',
      memberId: info?.id || '',
      model: { label: '請選擇', value: '' },
      // id: info.id,
      phone: info?.phone ?? '',
      productSerialNum: '',
      // supplier: { label: '請選擇', value: '' },
      purchaseDate: '',
      repairRegisterNum: info?.repairRegisterNum || '',
      size: { label: '請選擇', value: '' },
      sizeOptions: [],
      town: { label: '請選擇', value: '' },
      warrantyEndDate: '',
      warrantySerialNum: '',
    },
    onSubmit: values => {
      const body = {
        ...values,
        address: values.address,
        brandId: values?.brand?.value || 0,
        cityId: values?.city?.value || 0,
        color: values?.color?.value || '',
        countryId: values?.country?.value || 0,
        email: values.email,
        id: 0,
        isExchangedNew: values.isExchangedNew,
        isNew: values.isNew,
        model: values?.model?.label,
        modelId: values?.model?.value,
        productSerialNum: values.productSerialNum,
        purchaseDate: moment(values.purchaseDate).format('YYYY/MM/DD'),
        size: values?.size?.value || '',
        townId: values?.town?.value || 0,
        warrantyEndDate: moment(values.warrantyEndDate).format('YYYY/MM/DD'),
        warrantySerialNum: values.warrantySerialNum,
      };

      if (body.countryId === 0) {
        delete body.countryId;
      }
      if (body.cityId === 0) {
        delete body.cityId;
      }
      if (body.townId === 0) {
        delete body.townId;
      }
      if (body.address === '' || body.address === null) {
        delete body.address;
      }
      if (values.memberId) {
        dispatch(updateRepairRegister(body, values.memberId));
      } else {
        MessageUtil.alertWanring('無會員資料');
      }
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.string().nullable().required('必填'),
      }),
      brand: Yup.object().shape({
        label: Yup.string()
          .test('label', '必填', value => value !== '請選擇')
          .required('必填'),
      }),
      city: Yup.object().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.object().shape({
          label: Yup.string()
            .test('val', '必填', val => val !== '請選擇')
            .required('必填'),
        }),
      }),
      color: Yup.object()
        .nullable()
        .when('colorOptions', {
          is: val => val.length > 0,
          then: Yup.object().nullable().required('請選擇顏色'),
        }),
      country: Yup.object().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.object().shape({
          label: Yup.string()
            .test('val', '必填', val => val !== '請選擇')
            .required('必填'),
        }),
      }),
      email: Yup.string().nullable().required('必填'),
      firstName: Yup.string().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.string().nullable().required('必填'),
      }),
      lastName: Yup.string().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.string().nullable().required('必填'),
      }),
      model: Yup.object().shape({
        label: Yup.string()
          .test('label', '必填', value => value !== '請選擇')
          .required('必填'),
      }),
      phone: Yup.string().nullable().required('必填'),
      productSerialNum: Yup.string().required('必填'),
      purchaseDate: Yup.date().required('必填'),
      size: Yup.object()
        .nullable()
        .when('sizeOptions', {
          is: val => val.length > 0,
          then: Yup.object().nullable().required('請選擇尺寸'),
        }),
      town: Yup.object().when('brand', {
        is: val => val.label === 'Airinum',
        then: Yup.object().shape({
          label: Yup.string()
            .test('val', '必填', val => val !== '請選擇')
            .required('必填'),
        }),
      }),
      warrantyEndDate: Yup.date().required('必填'),
      warrantySerialNum: Yup.string().required('必填'),
    }),
  });

  const { countryList } = useSelector(state => state.getCountryList);
  const { cityList } = useSelector(state => state.getCityList);
  const { areaList } = useSelector(state => state.getAreaList);

  const countryOptions = countryList.map(country => {
    return { label: country.name, value: country.id };
  });
  const cityListOptions = cityList.map(city => {
    return { label: city.name, value: city.id };
  });
  const areaListOptions = areaList.map(area => {
    return { label: area.name, value: area.id };
  });

  const getProductInfo = (value, type) => {
    value && dispatch(getProductInfoViaWarrantySerialNumber(value, type));
  };

  const getMemberInfo = () => {
    values.phone && dispatch(getMemberInfoViaPhoneNumber('886', values.phone));
  };

  useEffect(() => {
    dispatch(getRepairBrandOption(0, 0));
    dispatch(getCountryList());
    dispatch(getCityList());
    dispatch(getAreaList());
  }, []);

  useEffect(() => {
    dispatch(getRepairModelOption(0, 0, values?.brand?.value || ''));
  }, [values.brand]);

  useEffect(() => {
    dispatch(getColorModelOption(0, 0, values?.model?.value || ''));
  }, [values?.model]);

  useEffect(() => {
    dispatch(
      getSizeOptions({
        color: values?.color?.value || '',
        isRepair: type !== '保固' ? 1 : 0,
        isWarranty: type === '保固' ? 1 : 0,
        modelId: values?.model?.value || '',
      }),
    );
  }, [values?.model?.value, values?.color?.value]);

  useEffect(() => {
    typeof repairColorOptions === 'object' &&
      setFieldValue('colorOptions', repairColorOptions);
  }, [repairColorOptions]);

  useEffect(() => {
    typeof sizeOptions === 'object' &&
      setFieldValue('sizeOptions', sizeOptions);
  }, [sizeOptions]);

  useEffect(() => {
    dispatch(getCityList(values?.country?.value));
  }, [values?.country?.value]);

  useEffect(() => {
    dispatch(getAreaList(values?.city?.value));
  }, [values?.city?.value]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      toggle();
      onRefresh && onRefresh();
      dispatch({ type: UPDATE_REPAIR_REGISTER_IDLE });
    }

    if (error) {
      setFieldValue('productSerialNum', '');
      setFieldValue('warrantySerialNum', '');

      MessageUtil.alertWanring('更新失敗', error);
      dispatch({ type: UPDATE_REPAIR_REGISTER_IDLE });
    }
  }, [success, error]);

  useEffect(() => {
    if (getInfoSuccess && memberInfo) {
      setFieldValue('email', memberInfo.email);
      setFieldValue('memberId', memberInfo.id);
      setFieldValue('lastName', memberInfo.lastName);
      setFieldValue('firstName', memberInfo.firstName);
      setFieldValue('country', {
        label: memberInfo.countryName,
        value: memberInfo.countryId,
      });
      setFieldValue('city', {
        label: memberInfo.cityName,
        value: memberInfo.cityId,
      });

      setFieldValue('town', {
        label: memberInfo.townName,
        value: memberInfo.townId,
      });
      setFieldValue('address', memberInfo.address);

      dispatch({ type: GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE });
    }
    if (getInfoSuccess && !memberInfo) {
      MessageUtil.alertWanring('無會員資料');
      setFieldValue('memberId', '');
      setFieldValue('email', '');
      dispatch({ type: GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE });
    }
  }, [getInfoSuccess, getInfoError]);

  useEffect(() => {
    if (getSuccess && productInfo) {
      setFieldValue(
        'brand',
        repairBrandOptions?.find(option => option.value == productInfo.brandId),
      );
      setFieldValue('model', {
        label: productInfo.model,
        value: productInfo.modelId,
      });
      setFieldValue('color', {
        label: productInfo.color,
        value: productInfo.color,
      });
      setFieldValue('size', {
        label: productInfo.size,
        value: productInfo.size,
      });
      setFieldValue('productSerialNum', productInfo.productSerialNum);

      setFieldValue('warrantySerialNum', productInfo.warrantySerialNum);

      // setFieldValue('supplier', info?.supplier?.find(option => option.value == productInfo.supplierChannelId));
      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    }

    if (getError) {
      MessageUtil.alertWanring(getError);
      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    }
  }, [getError, getSuccess]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggle}>
        保固註冊-折價換新
      </ModalHeader>
      <ModalBody
        className={style.modal_body}
        style={{ height: '730px', margin: '1rem' }}
      >
        {type === '保固' && (
          <div className={`mb-3 ${style.show_flex}`}>
            <input
              type="checkbox"
              style={{ height: '20px', width: '20px' }}
              onChange={event => {
                const checked = event.target.checked;
                setFieldValue('isExchangedNew', checked);
              }}
              checked={values.isExchangedNew}
            />
            <span style={{ marginLeft: '1rem' }}>折價換新</span>
          </div>
        )}
        {type === '保固' ? (
          <div className={`${style.show_flex} mb-3`}>
            <span className="required w100">手機號碼：</span>
            <Input
              className={style['repair-modal-input']}
              value={values.phone}
              onChange={event => {
                setFieldValue('phone', event.target.value);
              }}
              onBlur={getMemberInfo}
            />
            {errors?.phone && touched?.phone && (
              <span className="text-danger ml-2">{errors.phone}</span>
            )}
          </div>
        ) : (
          <div className="mb-3">手機號碼：+{values.phone}</div>
        )}

        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">Email</span>
          <Input
            className={style['repair-modal-input']}
            value={values.email}
            onChange={event => {
              setFieldValue('email', event.target.value);
            }}
          />
          {errors.email && touched.email && (
            <span className="text-danger ml-2">{errors.email}</span>
          )}
        </div>
        {values.brand.label === 'Airinum' && (
          <React.Fragment>
            <div className={style.formGroup}>
              <label className="required">姓名</label>
              <div className={style['rwd-w55']} />
              <label className="required">姓</label>
              <Input
                className={style['form-input']}
                value={values.lastName}
                onChange={event =>
                  setFieldValue('lastName', event.target.value)
                }
              />
              {errors.lastName && touched.lastName && (
                <span className="text-danger ml-2">{errors.lastName}</span>
              )}
              <div className="w80" />
              <label className="required">名</label>
              <Input
                className={style['form-input']}
                value={values.firstName}
                onChange={event =>
                  setFieldValue('firstName', event.target.value)
                }
              />
              {errors.firstName && touched.firstName && (
                <span className="text-danger ml-2">{errors.firstName}</span>
              )}
            </div>
            <div className={style.formGroup}>
              <label className="required">地址</label>
              <div className={style['rwd-w55']} />
              <label className="required">國家</label>
              <Select
                className={style['form-select-short']}
                options={countryOptions}
                onChange={option => {
                  setFieldValue('country', option);
                  setFieldValue('city', { label: '請選擇', value: '' });
                  setFieldValue('town', { label: '請選擇', value: '' });
                }}
                value={values.country}
              />
              {errors.country?.label && touched.country?.label && (
                <span className="text-danger ml-2">{errors.country.label}</span>
              )}
              <div style={{ width: '30px' }} />
              <label className="required">城市</label>
              <Select
                className={style['form-select-short']}
                options={cityListOptions}
                onChange={option => {
                  setFieldValue('city', option);
                  setFieldValue('town', { label: '請選擇', value: '' });
                }}
                value={values.city}
              />
              {errors.city?.label && touched.city?.label && (
                <span className="text-danger ml-2">{errors.city.label}</span>
              )}
              <div style={{ width: '30px' }} />
              <label className="required">區域</label>
              <Select
                className={style['form-select-short']}
                options={areaListOptions}
                onChange={option => setFieldValue('town', option)}
                value={values.town}
              />
              {errors.town?.label && touched.town?.label && (
                <span className="text-danger ml-2">{errors.town.label}</span>
              )}
            </div>
            <div className={style.formGroup}>
              <div className={style['rwd-w100']} />
              <label className="required">詳細地址</label>
              <Input
                className={style['repair-modal-input']}
                value={values.address}
                onChange={event => setFieldValue('address', event.target.value)}
              />
              {errors.address && touched.address && (
                <span className="text-danger ml-2">{errors.address}</span>
              )}
            </div>
          </React.Fragment>
        )}
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">商品品牌</span>
          <Select
            className={style['form-select']}
            options={repairBrandOptions || []}
            value={values.brand}
            onChange={option => {
              setFieldValue('brand', option);
              setFieldValue('model', null);
              setFieldValue('color', null);
              setFieldValue('size', null);
              setFieldValue('colorOptions', []);
              setFieldValue('sizeOptions', []);
            }}
          />
          {errors.brand && touched.brand && (
            <span className="text-danger ml-2">{errors.brand.label}</span>
          )}
        </div>
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">商品型號</span>
          <Select
            className={style['form-select']}
            options={repairModelOptions || []}
            value={values?.model || { label: '請選擇', value: '' }}
            onChange={option => {
              setFieldValue('model', option);
              setFieldValue('color', null);
              setFieldValue('size', null);
              setFieldValue('colorOptions', []);
              setFieldValue('sizeOptions', []);
            }}
          />
          {errors.model && touched.model && (
            <span className="text-danger ml-2">{errors?.model?.label}</span>
          )}
        </div>
        {values?.colorOptions?.length > 0 && (
          <div className={`${style.show_flex} mb-3`}>
            <span className="required w100">商品顏色</span>
            <Select
              className={style['form-select']}
              options={values?.colorOptions || []}
              value={values.color || { lable: '請選擇', value: '' }}
              onChange={option => {
                setFieldValue('color', option);
              }}
            />
            {errors?.color && (
              <span className="text-danger ml-2">{errors?.color}</span>
            )}
          </div>
        )}
        {values?.sizeOptions?.length > 0 && (
          <div className={`${style.show_flex} mb-3`}>
            <span className="required w100">商品尺寸</span>
            <Select
              className={style['form-select']}
              options={values?.sizeOptions || []}
              value={values.size || { lable: '請選擇', value: '' }}
              onChange={option => {
                setFieldValue('size', option);
              }}
            />
            {errors.size && (
              <span className="text-danger ml-2">{errors?.size}</span>
            )}
          </div>
        )}
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">商品序號</span>
          <Input
            className={style['repair-modal-input']}
            value={values.productSerialNum}
            onChange={event => {
              setFieldValue('productSerialNum', event.target.value);
            }}
            onBlur={e => {
              getProductInfo(e.target.value, 'productSerial');
            }}
          />
          {errors.productSerialNum && touched.productSerialNum && (
            <span className="text-danger ml-2">{errors.productSerialNum}</span>
          )}
        </div>
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">保固序號</span>
          <Input
            className={style['repair-modal-input']}
            value={values.warrantySerialNum}
            onChange={event => {
              setFieldValue('warrantySerialNum', event.target.value);
            }}
            onBlur={e => {
              getProductInfo(e.target.value, 'warrantSerial');
            }}
          />
          {errors.warrantySerialNum && touched.warrantySerialNum && (
            <span className="text-danger ml-2">{errors.warrantySerialNum}</span>
          )}
        </div>
        {type === '保固' && (
          <div className={`${style.show_flex} mb-3`}>
            <span className="w100">維修單號：</span>
            <Input
              className={style['repair-modal-input']}
              value={values.repairRegisterNum}
              onChange={event => {
                setFieldValue('repairRegisterNum', event.target.value);
              }}
            />
            {errors?.repairRegisterNum && touched?.repairRegisterNum && (
              <span className="text-danger ml-2">
                {errors.repairRegisterNum}
              </span>
            )}
          </div>
        )}

        {/* <div className={`${style.show_flex} mb-3`}>
          <span className='required w100'>購買通路</span>
          <Select
            className={style["form-select-long"]}
            value={values.supplier}
            options={info.supplier}
            onChange={option => {
              setFieldValue('supplier', option);
            }}
          />
          {
            errors.supplier &&
            touched.supplier &&
            <span className="text-danger ml-2">{errors.supplier.label}</span>
          }
        </div> */}
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">購買日期</span>
          <div className={style['form-select']}>
            <DatePicker
              className="form-control"
              dateFormat={'yyyy/MM/dd'}
              selected={values.purchaseDate}
              onChange={date => {
                setFieldValue('purchaseDate', date);
              }}
            />
          </div>
          {errors.purchaseDate && touched.purchaseDate && (
            <span className="text-danger ml-2">{errors.purchaseDate}</span>
          )}
        </div>
        <div className={`${style.show_flex} mb-3`}>
          <span className="required w100">保固結束日</span>
          <div className={style['form-select']}>
            <DatePicker
              className="form-control"
              dateFormat={'yyyy/MM/dd'}
              selected={values.warrantyEndDate}
              onChange={date => {
                setFieldValue('warrantyEndDate', date);
              }}
            />
          </div>
          {errors.warrantyEndDate && touched.warrantyEndDate && (
            <span className="text-danger ml-2">{errors.warrantyEndDate}</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading || Object.keys(errors).length > 0}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExchangeWarrantyProductModal;
