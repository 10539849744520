import Layout from 'components/Layout';
import { useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card } from 'reactstrap';
import { getOneActivity } from 'redux/actions/data/discountCompaignActions';
import useQuery from 'utils/useQuery';


import style from '../styles/layout.module.css';

const SortingActivityProducts = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const id = query.value.id;

  const { data: activityData } = useSelector(state => state.getOneActivity);

  useEffect(() => {
    dispatch(getOneActivity(id));
  }, [id]);

  return (
    <Layout
      pageTitle={'商品排序'}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: true, page_name: '折扣活動' },
        { isActive: false, page_name: '商品排序' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle /> 請按照下方步驟完成上傳
        </div>
        <div>
          <AiOutlineInfoCircle /> 完成設定後，即刻生效
        </div>
      </Alert>
      <Card className={`${style.card} mb-4`}>
        <div className="mb-2">{`活動類型：${activityData.catName}`}</div>
        <div className="mb-2">{`活動名稱：${activityData.name}`}</div>
        <div className="mb-2">{`活動範圍：${
          activityData.productRange === 'all' ? '全店活動' : '指定商品頁'
        }`}</div>
      </Card>
      <Card className={style.search_card}>
        <div
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1B99A2', padding: '1.5rem' }}
        >
          1、請先匯出活動清單
        </div>
        <div className={style.card_body}>
          <Button
            color="primary"
            outline
            style={{ fontSize: '20px', padding: '1rem' }}
            className={`${style['rwd-w250']} mb-4`}
          >
            匯出活動商品清單
          </Button>
          <li>請先點選「匯出活動商品清單」</li>
          <li>
            活動範圍若為「全店活動」，檔案不包含您已排除的商品；若為「指定商品頁」，檔案為您指定的商品。
          </li>
        </div>
      </Card>
      <Card className={style.search_card}>
        <div
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1B99A2', padding: '1.5rem' }}
        >
          2、上傳自訂商品排序檔案
        </div>
        <div className={style.card_body}>
          <Button
            color="primary"
            outline
            style={{ fontSize: '20px', padding: '1rem' }}
            className={`${style['rwd-w250']} mb-4`}
          >
            匯入活動商品清單
          </Button>
          <li>上傳自訂商品排序後，如欲修改排序，請直接重新上傳檔案即可</li>
        </div>
      </Card>
      <Button
        color="primary"
        className={`${style.modal_button_submit} ${style['rwd-w180']}`}
      >
        確認上傳
      </Button>
    </Layout>
  );
};

export default SortingActivityProducts;
