import { Modal } from 'reactstrap';

import style from '../styles/layout.module.css';

const ShowOriginImageModal = ({ isOpen, toggle, source }) => {
  return (
    <Modal
      modalClassName="border-0"
      contentClassName="bg-transparent border-0"
      className={style['warranty-full-screen-img']}
      zIndex={2000}
      centered
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
    >
      <img
        src={source}
        onClick={toggle}
        alt={'popupImg'}
        style={{
          height: '90vh',
          minHeight: '300px',
          objectFit: 'contain',
        }}
      />
    </Modal>
  );
};

export default ShowOriginImageModal;
