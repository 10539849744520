import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Input } from 'reactstrap';

import { getAdminListOptions } from '../../redux/actions/data/adminActions';
import style from '../../styles/layout.module.css';

const SearchCard = ({ values, setFieldValue, handleChange, permissions }) => {
  const { options: catOptions } = useSelector(
    state => state.getMessageCategoryOptions,
  );
  const { options: subCatOptions } = useSelector(
    state => state.getMessageSubCategoryOptions,
  );

  const [adminOptions, setAdminOptions] = useState([]);

  useEffect(() => {
    getAdminListOptions().then(res => {
      setAdminOptions(res);
    });
  }, []);

  return (
    <React.Fragment>
      <div className={style.show_flex}>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>關鍵字</label>
          <Input
            className={style['form-select-shop-banner']}
            placeholder="會員電話、提問內容、備註"
            id="keyword"
            onChange={handleChange}
            value={values.keyword}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>問題分類</label>
          <Select
            className={style['form-select']}
            defaultInputValue={''}
            options={[{ label: '全部', value: 0 }, ...catOptions]}
            style={{ margin: 0 }}
            onChange={option => {
              setFieldValue('cat1', option);
              setFieldValue('cat2', { label: '全部', value: 0 });
            }}
            value={values.cat1}
          />
          <Select
            className={style['form-select']}
            defaultInputValue={''}
            options={[{ label: '全部', value: 0 }, ...subCatOptions]}
            style={{ margin: 0 }}
            onChange={option => {
              setFieldValue('cat2', option);
            }}
            value={values.cat2}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>編號</label>
          <Input
            type="select"
            style={{ margin: 0 }}
            className={style['form-select']}
            id="serialNumType"
            onChange={handleChange}
            value={values.serialNumType}
          >
            <option value={''}>全部</option>
            <option value="message">留言編號</option>
            <option value="order">訂單主單編號</option>
            <option value="repair">維修單號</option>
          </Input>
          <Input
            className={style['form-select-input']}
            id="serialNum"
            onChange={handleChange}
            value={values.serialNum}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>日期區間</label>
          <div className={style['form-select']}>
            <DatePicker
              popperProps={{ strategy: 'fixed' }}
              className="form-control"
              dateFormat={'yyyy/MM/dd HH:mm'}
              showTimeSelect
              selected={values.dtStart ? moment(values.dtStart).toDate() : ''}
              onChange={date => {
                setFieldValue('dtStart', date);
              }}
            />
          </div>
          <span style={{ margin: '1rem' }}>~</span>
          <div className={style['form-select']}>
            <DatePicker
              popperProps={{ strategy: 'fixed' }}
              className="form-control"
              dateFormat={'yyyy/MM/dd HH:mm'}
              showTimeSelect
              selected={values.dtEnd ? moment(values.dtEnd).toDate() : ''}
              onChange={date => setFieldValue('dtEnd', date)}
              minDate={values.dtStart ? moment(values.dtStart).toDate() : ''}
            />
          </div>
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>經手人員</label>
          <Select
            className={style['form-select']}
            options={[{ label: '全部', value: 0 } , ...adminOptions]}
            style={{ margin: 0 }}
            onChange={option => {
              setFieldValue('adminId', String(option.value));
            }}
            value={adminOptions.find(a=>a.value === String(values.adminId)) || { label: '全部', value: 0 }}
          />
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>處理人員</label>
          <Input
            type="select"
            style={{ margin: 0 }}
            className={style['form-select-short']}
            id="catType"
            onChange={handleChange}
            value={values.catType}
          >
            <option value={''}>全部</option>
            {permissions.find(permission => permission.id === 39) && (
              <option value={'客服'}>客服</option>
            )}
            {permissions.find(permission => permission.id === 40) && (
              <option value={'業務'}>業務</option>
            )}
          </Input>
        </div>
        <div className={`${style.formGroup} ${style['mr-1p5']}`}>
          <label>問題狀態</label>
          <div className={style['radio-btn-layout']}>
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.messageStatus.includes('待處理')}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('messageStatus', [
                      ...values.messageStatus,
                      '待處理',
                    ])
                  : setFieldValue(
                      'messageStatus',
                      values.messageStatus.filter(
                        status => status !== '待處理',
                      ),
                    );
              }}
            />
            <span>待處理</span>
          </div>
          <div style={{ margin: '0.8rem' }} />
          <div className={style['radio-btn-layout']}>
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.messageStatus.includes('處理中')}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('messageStatus', [
                      ...values.messageStatus,
                      '處理中',
                    ])
                  : setFieldValue(
                      'messageStatus',
                      values.messageStatus.filter(
                        status => status !== '處理中',
                      ),
                    );
              }}
            />
            <span>處理中</span>
          </div>
          <div style={{ margin: '0.8rem' }} />
          <div className={style['radio-btn-layout']}>
            <input
              type="checkbox"
              className={style['radio-btn']}
              checked={values.messageStatus.includes('已結案')}
              onChange={e => {
                const checked = e.target.checked;
                checked
                  ? setFieldValue('messageStatus', [
                      ...values.messageStatus,
                      '已結案',
                    ])
                  : setFieldValue(
                      'messageStatus',
                      values.messageStatus.filter(
                        status => status !== '已結案',
                      ),
                    );
              }}
            />
            <span>已結案</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchCard;
