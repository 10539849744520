import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import {
  addCafeHomeBanner,
  updateCafeHomeBanner,
} from '../../redux/actions/data/uuCafeBannerSettingsAction';
import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import UploadFileBox from '../UploadFileBox';

const BannerModel = ({
  banner = {
    bannerImg: '',
    bannerImg_mobile: '',
    bannerUrl: '',
    bannerUrl_mobile: '',
    linkUrl: '',
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const { loading, success, error } = useSelector(
    state => state.cafeBannerSetting,
  );
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const formData = new FormData();
      formData.append('linkUrl', props.values.linkUrl);
      if (props.values.banner_files) {
        formData.append('banner_files', props.values.banner_files);
      } else {
        if (banner.bannerUrl && banner.bannerImg) {
          formData.append('banner_files', []);
        }
      }

      if (props.values.banner_mobile_files) {
        formData.append(
          'banner_files_mobile',
          props.values.banner_mobile_files,
        );
      } else {
        if (banner.bannerUrl_mobile && banner.bannerImg_mobile) {
          formData.append('banner_files_mobile', []);
        }
      }

      if (banner.id) {
        formData.append('id', banner.id);
        dispatch(updateCafeHomeBanner(formData));
      } else {
        dispatch(addCafeHomeBanner(formData));
      }
    },
    [banner],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (banner.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (banner.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, banner]);

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>編輯圖片</ModalHeader>
        <Formik
          initialValues={{
            banner_files: null,
            banner_mobile_files: null,
            init_bannerFile: getImageDataFromSetting(
              banner.bannerImg,
              banner.bannerUrl,
            ),
            init_bannerUrl_mobile: getImageDataFromSetting(
              banner.bannerImg_mobile,
              banner.bannerUrl_mobile,
            ),
            linkUrl: banner.linkUrl,
          }}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className="form-item">
                  <FormGroup className={style.formGroup}>
                    <label style={{ width: '120px' }}>*電腦版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        uplodModel="images"
                        formFieldName="banner_files"
                        currentFileFieldName="banner_files"
                        setFormFieldValue={(field, files) => {
                          if (files && files.length) {
                            if (files[0] instanceof File) {
                              props.setFieldValue('banner_files', files[0]);
                            }
                          }
                        }}
                        displayFileWidth="1920"
                        displayFileHeight="800"
                        displayFileSize="10MB"
                        initFiles={props.values.init_bannerFile}
                      />
                    </div>
                    <ErrorMessage
                      name="bannerUrl"
                      component="div"
                      className="form-error"
                    />
                  </FormGroup>

                  <FormGroup className={style.formGroup}>
                    <label style={{ width: '120px' }}>*手機版圖片</label>
                    <div className="form-item">
                      <UploadFileBox
                        uplodModel="images"
                        formFieldName="banner_mobile_files"
                        currentFileFieldName="banner_mobile_files"
                        setFormFieldValue={(field, files) => {
                          if (files && files.length) {
                            if (files[0] instanceof File) {
                              props.setFieldValue(
                                'banner_mobile_files',
                                files[0],
                              );
                            }
                          }
                        }}
                        displayFileWidth="800"
                        displayFileHeight="500"
                        displayFileSize="5MB"
                        initFiles={props.values.init_bannerUrl_mobile}
                      />
                      <ErrorMessage
                        name="bannerUrl_mobile"
                        component="div"
                        className="form-error"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group mb-3 mb-3">
                    <label>連結</label>
                    <div className="form-item">
                      <Field name="linkUrl" className="form-control" />
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center">
                <Button
                  color="light"
                  type="button"
                  className={style.modal_button_cancel}
                  onClick={() => {
                    setOpenModel(false, false);
                  }}
                >
                  取消
                </Button>
                <Button
                  color="primary"
                  type="button"
                  className={style.modal_button_submit}
                  disabled={loading}
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span>儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default memo(BannerModel);
