import qs from 'qs';

import history from '../utils/createHistory';

const tryParseInt = value => {
  const result = parseInt(value);
  return isNaN(result) ? value : result;
};

const parseObjectValues = (obj = {}) => {
  Object.keys(obj).forEach(k => {
    if (k === 'keyword') {
      obj[k] = String(obj[k] || '');
    } else if (k === 'payWay' && obj[k] === 'null') {
      obj[k] = null;
    } else {
      obj[k] = tryParseInt(obj[k]);
    }
  });
  return obj;
};

const useQuery = () => {
  const { location } = history;

  const value = parseObjectValues(
    qs.parse(location.search, { ignoreQueryPrefix: true }) || {},
  );

  return {
    set: params =>
      history.push({
        pathname: location.pathname,
        search: qs.stringify({ ...value, ...parseObjectValues(params) }),
      }),
    value,
  };
};

export default useQuery;
