import Layout from 'components/Layout';
import Configurations from 'components/giftSettings/Configurations';
import Information from 'components/giftSettings/Information';
import SelectGiftsModal from 'components/giftSettings/SelectGiftsModal';
import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
  createGift,
  getGift,
  updateGift,
} from 'redux/actions/data/giftsActions';
import {
  CREATE_GIFT_IDLE,
  UPDATE_GIFT_IDLE,
} from 'redux/constants/data/GiftsConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const GiftSettings = () => {
  const query = useQuery();
  const id = useMemo(() => {
    return query.value.id || 0;
  }, []);
  const status = query.value.status;

  // const { list } = useSelector(state => state.getTempGiftsList);
  const dispatch = useDispatch();
  const { gift } = useSelector(state => state.getGift);

  const {
    loading: createLoading,
    success: createSuccess,
    error: createError,
  } = useSelector(state => state.createGift);

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.updateGift);
  const { values, setFieldValue, handleSubmit, handleChange, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        currentImage: status === 'create' ? [] : [], // from API
        desc: status === 'create' ? '' : gift?.desc, // from API
        desc_en: status === 'create' ? '' : gift?.desc_en, // from API
        endTime: status === 'create' ? null : gift?.endTime, // from API
        id: status === 'create' ? 0 : gift?.id,
        image: status === 'create' ? [] : [], // from API
        initImage:
          status === 'create'
            ? []
            : (gift?.freebieImgInfo || []).map(img => {
                return {
                  ...img,
                  name: img.image,
                  url: img.imageUrl,
                };
              }),
        isSell: status === 'create' ? null : gift?.isSell, // from API
        lowestStock: status === 'create' ? 0 : gift?.lowestStock, // from API
        name: status === 'create' ? '' : gift?.name, // from API
        name_en: status === 'create' ? '' : gift?.name_en, // from API
        productcode: status === 'create' ? '' : gift?.productcode,
        source: status === 'create' ? '後台新增' : gift?.source, // from API
        startTime: status === 'create' ? null : gift?.startTime, // from API
        stock: status === 'create' ? 0 : gift?.stock, // from API
      },
      onSubmit: () => {
        const body = {
          ...values,
          endTime: moment(values.endTime).format('yyyy/MM/DD HH:mm'),
          startTime: moment(values.startTime).format('yyyy/MM/DD HH:mm'),
        };

        const formData = new FormData();

        Object.keys(body)
          .filter(
            i => i !== 'currentImage' && i !== 'image' && i !== 'initImage',
          )
          .forEach(i => {
            if (i) {
              if (body[i] !== null) {
                formData.append(i, body[i]);
              }
            }
          });

        if (values.currentImage.length > 0) {
          const imageJson = JSON.stringify(
            values.currentImage.map(img => ({
              ...img,
              image: img.url,
              imgFormFileId: body.image
                ? body.image.indexOf(body.image.find(j => j.id === img.id))
                : -1,
              isRemoveFile:
                values.currentImage.length > 0
                  ? values.initImage.find(
                      i => i.id === values.currentImage[0].id,
                    )
                    ? false
                    : true
                  : false,
              sort: 0,
            })),
          );
          formData.append('freebieImg', imageJson);
        }

        if (values.image.length > 0) {
          values.image.forEach(img => {
            formData.append('freebieImgFiles', img);
          });
        }

        if (status === 'create') {
          dispatch(createGift(formData));
        } else {
          dispatch(updateGift(formData));
        }
      },
      validationSchema: Yup.object().shape({
        currentImage: Yup.array(),
        desc: Yup.string().nullable().required('必填'),
        desc_en: Yup.string().nullable().required('必填'),
        endTime: Yup.date().nullable().required('必填'),
        image: Yup.array()
          .nullable()
          .when(['initImage', 'currentImage'], {
            is: (initImage, currentImage) =>
              initImage.length === 0 || currentImage.length === 0,
            then: Yup.array().nullable().min(1, '必填').required('必填'),
          }),
        initImage: Yup.array().nullable(),
        isSell: Yup.boolean().nullable().required('必填'),
        lowestStock: Yup.number().typeError('必填').required('必填'),
        name: Yup.string().nullable().required('必填'),
        name_en: Yup.string().nullable().required('必填'),
        productcode: Yup.string()
          .nullable()
          .when('source', {
            is: val => val === 'ERP',
            then: Yup.string().required('必填'),
          }),
        source: Yup.string().nullable().required('必填'),
        startTime: Yup.date().nullable().required('必填'),
        stock: Yup.number().typeError('必填').required('必填'),
      }),
    });

  const [isSelectGiftsModalOpen, setIsSelectGiftsModalOpen] = useState(false);
  const toggleSelectGiftsModal = () => {
    setIsSelectGiftsModalOpen(!isSelectGiftsModalOpen);
  };

  const titleStyle = {
    borderColor: '#3c44b1',
    color: '#3c44b1',
    fontWeight: 'normal',
    marginBottom: '2rem',
  };

  useEffect(() => {
    if (id != 0) {
      dispatch(getGift(id));
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(gift) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: gift });
    }
  }, [gift]);

  useEffect(() => {
    if (createSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: CREATE_GIFT_IDLE });
      history.goBack();
    }

    if (createError) {
      MessageUtil.alertWanring('儲存失敗', createError);
      dispatch({ type: CREATE_GIFT_IDLE });
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_GIFT_IDLE });
      dispatch(getGift(id));
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: UPDATE_GIFT_IDLE });
    }
  }, [updateSuccess, updateError]);

  return (
    <Layout
      pageTitle={`${status === 'create' ? '新增' : '編輯'}贈品`}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: true, page_name: '贈品管理' },
        {
          isActive: false,
          page_name: `${status === 'create' ? '新增' : '編輯'}贈品`,
        },
      ]}
    >
      <Card className={style.card}>
        <h4 className="title" style={titleStyle}>
          贈品資訊
        </h4>
        <Information
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onChange={handleChange}
          onToggleModal={toggleSelectGiftsModal}
          status={status}
        />
        <h4 className="title" style={titleStyle}>
          贈品選項
        </h4>
        <Configurations
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onChange={handleChange}
        />
        <div className="mb-3">
          {`異動時間：${gift.updateTime || '--'} ${gift.updater || '--'}`}
        </div>
        <hr />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className={style.modal_button_submit}
            onClick={handleSubmit}
            disabled={createLoading || updateLoading}
          >
            {createLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {updateLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
      {isSelectGiftsModalOpen && (
        <SelectGiftsModal
          isOpen={isSelectGiftsModalOpen}
          toggle={toggleSelectGiftsModal}
          productcode={values.productcode}
          onSubmit={data => {
            setFieldValue('productcode', data);
          }}
        />
      )}
    </Layout>
  );
};

export default GiftSettings;
