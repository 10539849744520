import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';

import { getMemberLevelOptions } from '../../redux/actions/data/memberActions';
import style from '../../styles/layout.module.css';
import TableHasNoData from '../TableHasNoData';

import {
  ConditionFixedTPrice,
  ConditionsAreaName,
  ConditionsAreaNameEN,
  ConditionsAreaValue1,
  ConditionsAreaValue2,
  ConditionsValue1,
  ConditionsValue2,
} from './FormInput';

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

export const DiscountConditionSettings = ({
  activityType,
  values,
  setFieldValue,
  errors,
  touched,
  onToggleModal,
  onSetProductType,
  limit,
}) => {
  const dispatch = useDispatch();
  const { memberLevelOptions } = useSelector(
    state => state.getMemberLevelOptions,
  );
  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    setFieldValue('currentTab', currentTab);
  }, [currentTab]);

  const { reaptedProducts } = useSelector(
    state => state.getActivityReaptedProducts,
  );

  return (
    <div>
      <div className={style.formGroup}>
        <label className="w150 required">
          活動對象
          {errors.target && touched.target && (
            <span className="ml-2 text-danger">{errors.target}</span>
          )}
        </label>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            marginRight: '3rem',
          }}
        >
          <input
            type="radio"
            checked={values.target === 'all'}
            style={{ height: '20px', width: '20px' }}
            onChange={() => {
              setFieldValue('target', 'all');
              setFieldValue('objectType', '');
              setFieldValue('memberLevel', 0);
            }}
            disabled={values.status === 1}
          />
          <span className="ml-2">全體會員</span>
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
          }}
        >
          <input
            type="radio"
            checked={values.target === 'member'}
            style={{ height: '20px', width: '20px' }}
            onChange={() => {
              setFieldValue('target', 'member');
            }}
            disabled={values.status === 1}
          />
          <span className="ml-2">會員等級</span>
        </div>
      </div>
      {values.target === 'member' && (
        <div className={style.formGroup}>
          <label className="w150" />
          {memberLevelOptions?.map((option, index) => (
            <div
              key={index}
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
                marginRight: '1.5rem',
              }}
            >
              <input
                type="radio"
                checked={option.value == values?.memberLevel}
                style={{ height: '20px', width: '20px' }}
                onChange={event => {
                  setFieldValue('memberLevel', option.value);
                }}
                disabled={values.status === 1}
              />
              <span className="ml-2">{option.label}</span>
            </div>
          ))}
          {errors.memberLevel && (
            <div className="form-error">{errors.memberLevel}</div>
          )}
        </div>
      )}
      {values.target === 'member' &&
        values.catCode != 1 &&
        values.catCode != 7 &&
        values.catCode != 6 &&
        values.catCode != 8 &&
        values.catCode != 9 &&
        values.catCode != 10 && (
          <div className={style.formGroup}>
            <label className="w150" />
            <div>
              <div className="ml-2">請選擇此活動類型</div>
              <div className={style.show_flex}>
                <div className={style.show_flex}>
                  <input
                    type="radio"
                    style={{ height: '20px', width: '20px' }}
                    checked={values.objectType === 'special'}
                    onChange={() => {
                      setFieldValue('objectType', 'special');
                    }}
                    disabled={values.status === 1}
                  />
                  <label className="ml-2">短期活動</label>
                </div>
                <div className={style.show_flex}>
                  <input
                    type="radio"
                    style={{ height: '20px', width: '20px' }}
                    checked={values.objectType === 'regular'}
                    onChange={() => {
                      setFieldValue('objectType', 'regular');
                    }}
                    disabled={values.status === 1}
                  />
                  <label className="ml-2">常態活動</label>
                </div>
                {errors.objectType && (
                  <div className="form-error">{errors.objectType}</div>
                )}
              </div>
            </div>
          </div>
        )}
      <div className={style.formGroup}>
        <label className="w150 required">
          適用通路
          {errors.gateway && touched.gateway && (
            <span className="ml-2 text-danger">{errors.gateway}</span>
          )}
        </label>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            marginRight: '4rem',
          }}
        >
          <input
            type="checkbox"
            style={{ height: '20px', width: '20px' }}
            checked={(values?.gateway || []).includes('web')}
            onChange={event => {
              const checked = event.target.checked;
              checked
                ? setFieldValue('gateway', [...(values.gateway || []), 'web'])
                : setFieldValue(
                    'gateway',
                    (values.gateway || []).filter(i => i !== 'web'),
                  );
            }}
          />
          <span className="ml-2">網頁版</span>
        </div>
      </div>
      {activityType !== '限時折扣' &&
        activityType !== '紅配綠' &&
        activityType !== '任選優惠價' && (
          <div className={style.formGroup}>
            <label className="w150 required">活動範圍</label>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
                marginRight: '3rem',
              }}
            >
              <input
                type="radio"
                style={{ height: '20px', width: '20px' }}
                checked={values.range === 'all'}
                onChange={() => {
                  setFieldValue('range', 'all');
                }}
                disabled={values.status === 1}
              />
              <span className="ml-2">全店商品</span>
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'left',
              }}
            >
              <input
                type="radio"
                style={{ height: '20px', width: '20px' }}
                checked={values.range === 'picked'}
                onChange={() => {
                  setFieldValue('range', 'picked');
                }}
                disabled={values.status === 1}
              />
              <span className="ml-2">指定商品</span>
            </div>
          </div>
        )}
      {values.range !== 'set' && values.range && (
        <React.Fragment>
          <div className={style.formGroup}>
            <label className="w150 required">
              活動商品
              {errors.models &&
                touched.models &&
                typeof errors.models === 'string' && (
                  <span className="ml-2 text-danger">{errors.models}</span>
                )}
            </label>
            <div>
              <small>
                <li>商品清單更新後，系統將於30分鐘後生效</li>
              </small>
            </div>
          </div>
          <div className={style.formGroup}>
            <label className="w150" />
            <Button
              color="primary"
              outline
              onClick={() => {
                onToggleModal();
                onSetProductType(values.range === 'picked' ? '指定' : '排除');
              }}
            >
              {`新增${values.range === 'picked' ? '指定' : '排除'}商品`}
            </Button>
          </div>
          <div className={style.formGroup}>
            <label className="w150" />
            <div>
              <h5>{`${values.range === 'picked' ? '指定' : '排除'}商品`} </h5>
              <div className="table-responsive">
                <Table bordered striped hover>
                  <thead className={`thead-dark ${style.table_head}`}>
                    <tr>
                      <th style={{ minWidth: '150px' }}>商品名稱</th>
                      <th style={{ minWidth: '150px' }}>商品類型</th>
                      <th style={{ minWidth: '150px' }}>商品料號</th>
                      <th>規格</th>
                      <th>商品是否上架</th>
                      {values.catCode == 1 && (
                        <th style={{ minWidth: '150px' }}>售價</th>
                      )}
                      {values.catCode == 1 && <th>限時折扣價</th>}
                      <th>刪除</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values?.models?.length > 0 ? (
                      values.models.map((temp, index) =>
                        temp.products?.map((product, pIndex) => (
                          <tr key={index + pIndex}>
                            {pIndex === 0 && (
                              <td rowSpan={temp.products.length}>
                                {temp.modelName}
                              </td>
                            )}
                            {pIndex === 0 && (
                              <td rowSpan={temp?.products?.length}>
                                <div>
                                  {temp.productType === 1 ? '一般商品' : ''}
                                  {temp.productType === 2 ? '隱形賣場' : ''}
                                  {temp.productType === 3 ? '組合商品' : ''}
                                </div>
                                {temp.productType === 2 && (
                                  <div>({temp.modelNumber || '-'})</div>
                                )}
                              </td>
                            )}
                            <td className="text-center">
                              <div className={style.show_flex}>
                                <label>{product.productcode}</label>
                                {reaptedProducts?.includes(product.productid) &&
                                  values.range !== 'all' &&
                                  values.catCode == 1 && (
                                    <Button color="danger" className="btn-sm">
                                      區間重複
                                    </Button>
                                  )}
                              </div>
                            </td>
                            <td nowrap="nowrap">
                              {product.color || '--'} / {product.size || '--'}
                            </td>
                            <td>{product.isSell ? '是' : '否'}</td>
                            {values.catCode == 1 && (
                              <td className="price fw-normal text-black text-center">
                                {product.sellprice.toLocaleString('en-US')}
                              </td>
                            )}
                            {values.catCode == 1 && (
                              <td>
                                <Input
                                  className={style['form-input-short']}
                                  type="number"
                                  onChange={event => {
                                    setFieldValue(
                                      `models[${index}].products[${pIndex}].discountPrice`,
                                      event.target.value,
                                    );
                                  }}
                                  value={
                                    values?.models[index]?.products?.length > 0
                                      ? values?.models[index]?.products[pIndex]
                                          ?.discountPrice
                                      : 0
                                  }
                                />
                                {errors.models &&
                                  errors.models[index] &&
                                  errors.models[index].products &&
                                  errors.models[index].products[pIndex] &&
                                  errors.models[index].products[pIndex]
                                    .discountPrice && (
                                    <div className="form-error">
                                      {errors.models[index].products[pIndex] &&
                                        errors.models[index].products[pIndex]
                                          .discountPrice}
                                    </div>
                                  )}
                              </td>
                            )}
                            <td>
                              <Button
                                className="btn-sm"
                                color="danger"
                                outline
                                onClick={() => {
                                  const newArr = values.models[
                                    index
                                  ].products.filter(
                                    p =>
                                      p.productcode !== product.productcode ||
                                      p.area !== product.area,
                                  );
                                  if (newArr.length > 0) {
                                    let newTemp = values.models;
                                    newTemp[index].products = newArr;
                                    setFieldValue('models', newTemp);
                                  } else {
                                    // 沒有商品, 刪除 model
                                    setFieldValue(
                                      'models',
                                      values.models.filter(
                                        t =>
                                          t?.mid !== temp?.mid ||
                                          t?.area !== temp?.area,
                                      ),
                                    );
                                  }
                                }}
                              >
                                刪除
                              </Button>
                            </td>
                          </tr>
                        )),
                      )
                    ) : (
                      <TableHasNoData />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {
        // 紅配綠
        values.catCode == 7 && (
          <div className={style.formGroup}>
            <label className="w150 required">
              活動範圍
              {errors.models && touched.models && (
                <span className="ml-2 text-danger">{errors.models}</span>
              )}
            </label>
            <div>
              <li>
                <small>
                  A區商品、B區商品或C區商品不能重複選擇，範例：被選取為A區商品時，就不可再選入B區或C區。
                </small>
              </li>
              <li>
                <small>商品清單更新後，系統將於30分鐘後生效。</small>
              </li>
              <li>
                <small>
                  分區活動商品，商品金額需大於或等於活動價格，才享有活動優惠。
                </small>
              </li>
            </div>
          </div>
        )
      }
      {values.catCode == 7 && (
        // ABC區商品
        <div className={style.formGroup}>
          <label className="w150" />
          <div>
            <Nav pills className="mb-2">
              <TabItem
                onClick={setCurrentTab}
                tab={1}
                text="A區"
                currentTab={currentTab}
              />
              <TabItem
                onClick={setCurrentTab}
                tab={2}
                text="B區"
                currentTab={currentTab}
              />
              <TabItem
                onClick={setCurrentTab}
                tab={3}
                text="C區"
                currentTab={currentTab}
              />
            </Nav>
            <TabContent activeTab={currentTab}>
              <TabPane tabId={1}>
                <Card className={style.card}>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱</label>
                    <ConditionsAreaName
                      placeholder="請輸入名稱"
                      values={values}
                      index={0}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱(英)</label>
                    <ConditionsAreaNameEN
                      placeholder="請輸入名稱"
                      values={values}
                      index={0}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <Button
                    color="primary w150 mb-2"
                    outline
                    onClick={() => {
                      onToggleModal();
                      onSetProductType('指定');
                    }}
                  >
                    新增指定商品
                  </Button>
                  <div className="table-responsive">
                    <h5>指定商品</h5>
                    <div className="table-responsive">
                      <Table bordered striped hover>
                        <thead className={`thead-dark ${style.table_head}`}>
                          <tr>
                            <th style={{ minWidth: '150px' }}>商品名稱</th>
                            <th style={{ minWidth: '150px' }}>商品類型</th>
                            <th style={{ minWidth: '150px' }}>商品料號</th>
                            <th>規格</th>
                            <th>商品是否上架</th>
                            {values.catCode == 1 && (
                              <th style={{ minWidth: '150px' }}>售價</th>
                            )}
                            {values.catCode == 1 && <th>限時折扣價</th>}
                            <th>刪除</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.models?.length > 0 &&
                          values.models.find(temp =>
                            temp?.products.find(p => p.area === 1),
                          ) ? (
                            values.models.map(
                              (temp, index) =>
                                temp.area === 1 &&
                                temp?.products?.map(
                                  (product, pIndex) =>
                                    product.area === 1 && (
                                      <tr key={index + pIndex}>
                                        {pIndex === 0 && (
                                          <td rowSpan={temp.products.length}>
                                            {temp.modelName}
                                          </td>
                                        )}
                                        {pIndex === 0 && (
                                          <td rowSpan={temp?.products?.length}>
                                            <div>
                                              {temp.productType === 1
                                                ? '一般商品'
                                                : ''}
                                              {temp.productType === 2
                                                ? '隱形賣場'
                                                : ''}
                                              {temp.productType === 3
                                                ? '組合商品'
                                                : ''}
                                            </div>
                                            {temp.productType === 2 && (
                                              <div>
                                                ({temp.modelNumber || '-'})
                                              </div>
                                            )}
                                          </td>
                                        )}

                                        <td className="text-center">
                                          <div className={style.show_flex}>
                                            <label>{product.productcode}</label>
                                            {reaptedProducts?.includes(
                                              product.productid,
                                            ) &&
                                              values.range !== 'all' &&
                                              values.catCode == 1 && (
                                                <Button
                                                  color="danger"
                                                  className="btn-sm"
                                                >
                                                  區間重複
                                                </Button>
                                              )}
                                          </div>
                                        </td>

                                        <td nowrap="nowrap">
                                          {product.color || '--'} /{' '}
                                          {product.size || '--'}
                                        </td>

                                        <td>{product.isSell ? '是' : '否'}</td>
                                        {values.catCode == 1 && (
                                          <td className="price fw-normal text-black text-center">
                                            {product.sellprice.toLocaleString(
                                              'en-US',
                                            )}
                                          </td>
                                        )}
                                        {values.catCode == 1 && (
                                          <td>
                                            <Input
                                              className={
                                                style['form-input-short']
                                              }
                                              type="number"
                                              onChange={event => {
                                                setFieldValue(
                                                  `models[${index}].products[${pIndex}].discountPrice`,
                                                  event.target.value,
                                                );
                                              }}
                                              value={
                                                values?.models[index]?.products
                                                  ?.length > 0
                                                  ? values?.models[index]
                                                      ?.products[pIndex]
                                                      ?.discountPrice
                                                  : 0
                                              }
                                            />
                                            {errors.models &&
                                              errors.models[index] &&
                                              errors.models[index].products &&
                                              errors.models[index].products[
                                                pIndex
                                              ] &&
                                              errors.models[index].products[
                                                pIndex
                                              ].discountPrice && (
                                                <div className="form-error">
                                                  {errors.models[index]
                                                    .products[pIndex] &&
                                                    errors.models[index]
                                                      .products[pIndex]
                                                      .discountPrice}
                                                </div>
                                              )}
                                          </td>
                                        )}
                                        <td>
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            outline
                                            onClick={() => {
                                              const newArr = values.models[
                                                index
                                              ].products.filter(
                                                p =>
                                                  p.productcode !==
                                                    product.productcode ||
                                                  p.area !== product.area,
                                              );
                                              if (newArr.length > 0) {
                                                let newTemp = values.models;
                                                newTemp[index].products =
                                                  newArr;
                                                setFieldValue(
                                                  'models',
                                                  newTemp,
                                                );
                                              } else {
                                                // 沒有商品, 刪除 model
                                                setFieldValue(
                                                  'models',
                                                  values.models.filter(
                                                    t =>
                                                      t?.mid !== temp?.mid ||
                                                      t?.area !== temp?.area,
                                                  ),
                                                );
                                              }
                                            }}
                                          >
                                            刪除
                                          </Button>
                                        </td>
                                      </tr>
                                    ),
                                ),
                            )
                          ) : (
                            <TableHasNoData />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card>
              </TabPane>
              <TabPane tabId={2}>
                <Card className={style.card}>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱</label>
                    <ConditionsAreaName
                      placeholder="請輸入名稱"
                      values={values}
                      index={1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱(英)</label>
                    <ConditionsAreaNameEN
                      placeholder="請輸入名稱"
                      values={values}
                      index={1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <Button
                    color="primary w150 mb-2"
                    outline
                    onClick={() => {
                      onToggleModal();
                      onSetProductType('指定');
                    }}
                  >
                    新增指定商品
                  </Button>
                  <div className="table-responsive">
                    <h5>指定商品</h5>
                    <div className="table-responsive">
                      <Table bordered striped hover>
                        <thead className={`thead-dark ${style.table_head}`}>
                          <tr>
                            <th style={{ minWidth: '150px' }}>商品名稱</th>
                            <th style={{ minWidth: '150px' }}>商品類型</th>
                            <th style={{ minWidth: '150px' }}>商品料號</th>
                            <th>規格</th>
                            <th>商品是否上架</th>
                            {values.catCode == 1 && (
                              <th style={{ minWidth: '150px' }}>售價</th>
                            )}
                            {values.catCode == 1 && <th>限時折扣價</th>}
                            <th>刪除</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.models?.length > 0 &&
                          values.models.find(temp =>
                            temp?.products.find(p => p.area === 2),
                          ) ? (
                            values.models.map(
                              (temp, index) =>
                                temp.area === 2 &&
                                temp?.products?.map(
                                  (product, pIndex) =>
                                    product.area === 2 && (
                                      <tr key={index + pIndex}>
                                        {pIndex === 0 && (
                                          <td rowSpan={temp.products.length}>
                                            {temp.modelName}
                                          </td>
                                        )}
                                        {pIndex === 0 && (
                                          <td rowSpan={temp?.products?.length}>
                                            <div>
                                              {temp.productType === 1
                                                ? '一般商品'
                                                : ''}
                                              {temp.productType === 2
                                                ? '隱形賣場'
                                                : ''}
                                              {temp.productType === 3
                                                ? '組合商品'
                                                : ''}
                                            </div>
                                            {temp.productType === 2 && (
                                              <div>
                                                ({temp.modelNumber || '-'})
                                              </div>
                                            )}
                                          </td>
                                        )}
                                        <td className="text-center">
                                          <div className={style.show_flex}>
                                            <label>{product.productcode}</label>
                                            {reaptedProducts?.includes(
                                              product.productid,
                                            ) &&
                                              values.range !== 'all' &&
                                              values.catCode == 1 && (
                                                <Button
                                                  color="danger"
                                                  className="btn-sm"
                                                >
                                                  區間重複
                                                </Button>
                                              )}
                                          </div>
                                        </td>
                                        <td nowrap="nowrap">
                                          {product.color || '--'} /{' '}
                                          {product.size || '--'}
                                        </td>
                                        <td>{product.isSell ? '是' : '否'}</td>
                                        {values.catCode == 1 && (
                                          <td className="price fw-normal text-black text-center">
                                            {product.sellprice.toLocaleString(
                                              'en-US',
                                            )}
                                          </td>
                                        )}
                                        {values.catCode == 1 && (
                                          <td>
                                            <Input
                                              className={
                                                style['form-input-short']
                                              }
                                              type="number"
                                              onChange={event => {
                                                setFieldValue(
                                                  `models[${index}].products[${pIndex}].discountPrice`,
                                                  event.target.value,
                                                );
                                              }}
                                              value={
                                                values?.models[index]?.products
                                                  ?.length > 0
                                                  ? values?.models[index]
                                                      ?.products[pIndex]
                                                      ?.discountPrice
                                                  : 0
                                              }
                                            />
                                            {errors.models &&
                                              errors.models[index] &&
                                              errors.models[index].products &&
                                              errors.models[index].products[
                                                pIndex
                                              ] &&
                                              errors.models[index].products[
                                                pIndex
                                              ].discountPrice && (
                                                <div className="form-error">
                                                  {errors.models[index]
                                                    .products[pIndex] &&
                                                    errors.models[index]
                                                      .products[pIndex]
                                                      .discountPrice}
                                                </div>
                                              )}
                                          </td>
                                        )}
                                        <td>
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            outline
                                            onClick={() => {
                                              const newArr = values.models[
                                                index
                                              ].products.filter(
                                                p =>
                                                  p.productcode !==
                                                    product.productcode ||
                                                  p.area !== product.area,
                                              );
                                              if (newArr.length > 0) {
                                                let newTemp = values.models;
                                                newTemp[index].products =
                                                  newArr;
                                                setFieldValue(
                                                  'models',
                                                  newTemp,
                                                );
                                              } else {
                                                // 沒有商品, 刪除 model
                                                setFieldValue(
                                                  'models',
                                                  values.models.filter(
                                                    t =>
                                                      t?.mid !== temp?.mid ||
                                                      t?.area !== temp?.area,
                                                  ),
                                                );
                                              }
                                            }}
                                          >
                                            刪除
                                          </Button>
                                        </td>
                                      </tr>
                                    ),
                                ),
                            )
                          ) : (
                            <TableHasNoData />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card>
              </TabPane>
              <TabPane tabId={3}>
                <Card className={style.card}>
                  <div className={style.formGroup}>
                    <label className="required w150">是否新增C區</label>
                    <div>
                      <div className="d-flex">
                        <input
                          type="radio"
                          checked={values.isExtraProductRange}
                          onChange={() => {
                            setFieldValue('isExtraProductRange', true);
                          }}
                          style={{ height: '20px', width: '20px' }}
                          disabled={
                            values.status === 1 || !values.isExtraProductRange
                          }
                        />
                        <span className="mx-2">是</span>
                      </div>
                      <div className="d-flex">
                        <input
                          type="radio"
                          checked={!values.isExtraProductRange}
                          onChange={() => {
                            setFieldValue('isExtraProductRange', false);
                          }}
                          style={{ height: '20px', width: '20px' }}
                          disabled={
                            values.status === 1 || !values.isExtraProductRange
                          }
                        />
                        <span className="mx-2">否</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱</label>
                    <Input
                      className={style['form-input']}
                      value={
                        values.area?.length > 0 && values.area[2]?.areaName
                      }
                      disabled={!values.isExtraProductRange}
                      onChange={event => {
                        setFieldValue('area[2].areaName', event.target.value);
                      }}
                    />
                  </div>
                  <div className={style.formGroup}>
                    <label className="w150 required">自訂名稱(英)</label>
                    <Input
                      className={style['form-input']}
                      value={values.area?.length && values.area[2]?.areaName_en}
                      disabled={!values.isExtraProductRange}
                      onChange={event => {
                        setFieldValue(
                          'area[2].areaName_en',
                          event.target.value,
                        );
                      }}
                    />
                  </div>
                  <Button
                    color="primary w150 mb-2"
                    outline
                    disabled={!values.isExtraProductRange}
                    onClick={() => {
                      onToggleModal();
                      onSetProductType('指定');
                    }}
                  >
                    新增指定商品
                  </Button>
                  <div className="table-responsive">
                    <h5>指定商品</h5>
                    <div className="table-responsive">
                      <Table bordered striped hover>
                        <thead className={`thead-dark ${style.table_head}`}>
                          <tr>
                            <th style={{ minWidth: '150px' }}>商品名稱</th>
                            <th style={{ minWidth: '150px' }}>商品類型</th>
                            <th style={{ minWidth: '150px' }}>商品料號</th>
                            <th>規格</th>
                            <th>商品是否上架</th>
                            {values.catCode == 1 && (
                              <th style={{ minWidth: '150px' }}>售價</th>
                            )}
                            {values.catCode == 1 && <th>限時折扣價</th>}
                            <th>刪除</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.models?.length > 0 &&
                          values.models.find(temp =>
                            temp?.products.find(p => p.area === 3),
                          ) ? (
                            values.models.map(
                              (temp, index) =>
                                temp.area === 3 &&
                                temp?.products?.map(
                                  (product, pIndex) =>
                                    product.area === 3 && (
                                      <tr key={index + pIndex}>
                                        {pIndex === 0 && (
                                          <td rowSpan={temp.products.length}>
                                            {temp.modelName}
                                          </td>
                                        )}
                                        {pIndex === 0 && (
                                          <td rowSpan={temp?.products?.length}>
                                            <div>
                                              {temp.productType === 1
                                                ? '一般商品'
                                                : ''}
                                              {temp.productType === 2
                                                ? '隱形賣場'
                                                : ''}
                                              {temp.productType === 3
                                                ? '組合商品'
                                                : ''}
                                            </div>
                                            {temp.productType === 2 && (
                                              <div>
                                                ({temp.modelNumber || '-'})
                                              </div>
                                            )}
                                          </td>
                                        )}
                                        <td className="text-center">
                                          <div className={style.show_flex}>
                                            <label>{product.productcode}</label>
                                            {reaptedProducts?.includes(
                                              product.productid,
                                            ) &&
                                              values.range !== 'all' &&
                                              values.catCode == 1 && (
                                                <Button
                                                  color="danger"
                                                  className="btn-sm"
                                                >
                                                  區間重複
                                                </Button>
                                              )}
                                          </div>
                                        </td>
                                        <td nowrap="nowrap">
                                          {product.color || '--'} /{' '}
                                          {product.size || '--'}
                                        </td>
                                        <td>{product.isSell ? '是' : '否'}</td>
                                        {values.catCode == 1 && (
                                          <td className="price fw-normal text-black text-center">
                                            {product.sellprice.toLocaleString(
                                              'en-US',
                                            )}
                                          </td>
                                        )}
                                        {values.catCode == 1 && (
                                          <td>
                                            <Input
                                              className={
                                                style['form-input-short']
                                              }
                                              type="number"
                                              onChange={event => {
                                                setFieldValue(
                                                  `models[${index}].products[${pIndex}].discountPrice`,
                                                  event.target.value,
                                                );
                                              }}
                                              value={
                                                values?.models[index]?.products
                                                  ?.length > 0
                                                  ? values?.models[index]
                                                      ?.products[pIndex]
                                                      ?.discountPrice
                                                  : 0
                                              }
                                            />
                                          </td>
                                        )}
                                        <td>
                                          <Button
                                            className="btn-sm"
                                            color="danger"
                                            outline
                                            onClick={() => {
                                              const newArr = values.models[
                                                index
                                              ].products.filter(
                                                p =>
                                                  p.productcode !==
                                                    product.productcode ||
                                                  p.area !== product.area,
                                              );
                                              if (newArr.length > 0) {
                                                let newTemp = values.models;
                                                newTemp[index].products =
                                                  newArr;
                                                setFieldValue(
                                                  'models',
                                                  newTemp,
                                                );
                                              } else {
                                                // 沒有商品, 刪除 model
                                                setFieldValue(
                                                  'models',
                                                  values.models.filter(
                                                    t =>
                                                      t?.mid !== temp?.mid ||
                                                      t?.area !== temp?.area,
                                                  ),
                                                );
                                              }
                                            }}
                                          >
                                            刪除
                                          </Button>
                                        </td>
                                      </tr>
                                    ),
                                ),
                            )
                          ) : (
                            <TableHasNoData />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card>
              </TabPane>
            </TabContent>
          </div>
        </div>
      )}
      {values.catCode != 1 && (
        <div className={style.formGroup}>
          <label className="w150 required">折抵條件</label>
          <div>
            {values.catCode != 1 &&
              values.catCode != 3 &&
              values.catCode != 5 &&
              values.catCode != 7 &&
              values.catCode != 6 &&
              values.catCode != 8 &&
              values.catCode != 9 &&
              values.catCode != 10 && (
                <div style={{ marginBottom: '1rem' }}>
                  <li>
                    <small>折扣請輸入1.0-9.9，若打85折，請輸入[8.5]折</small>
                  </li>
                </div>
              )}
            {
              // 滿件打折
              values.catCode == 2 &&
                values.conditions.map((condition, i) => (
                  <React.Fragment>
                    <div className={style.formGroup} key={i}>
                      <label>滿</label>
                      <ConditionsValue1
                        placeholder="請輸入數字"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <label className="ml-2">件，打</label>
                      <ConditionsValue2
                        placeholder="請輸入1.0-9.9折"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <span className="ml-2">折</span>
                      {i !== 0 && (
                        <Button
                          color="danger"
                          outline
                          onClick={() => {
                            setFieldValue(
                              'conditions',
                              values.conditions.filter(
                                condition => condition !== values.conditions[i],
                              ),
                            );
                          }}
                          className="ml-2"
                          disabled={values.status === 1}
                        >
                          刪除
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                ))
            }
            {
              // 滿件折現
              values.catCode == 3 &&
                values.conditions.map((condition, i) => (
                  <React.Fragment>
                    <div className={style.formGroup} key={i}>
                      <label>滿</label>
                      <ConditionsValue1
                        placeholder="請輸入數字"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <label className="ml-2">件，現折NT$</label>
                      <ConditionsValue2
                        placeholder="請輸入金額"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      {i !== 0 && (
                        <Button
                          color="danger"
                          outline
                          onClick={() => {
                            setFieldValue(
                              'conditions',
                              values.conditions.filter(
                                condition => condition !== values.conditions[i],
                              ),
                            );
                          }}
                          className="ml-2"
                          disabled={values.status === 1}
                        >
                          刪除
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                ))
            }
            {
              // 滿額打折
              values.catCode == 4 &&
                values.conditions.map((condition, i) => (
                  <React.Fragment>
                    <div className={style.formGroup} key={i}>
                      <label>滿NT$</label>
                      <ConditionsValue1
                        placeholder="請輸入金額"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <label className="ml-2">，打</label>
                      <ConditionsValue2
                        placeholder="請輸入1.0-9.9折"
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <span className="ml-2">折</span>
                      {i !== 0 && (
                        <Button
                          color="danger"
                          outline
                          onClick={() => {
                            setFieldValue(
                              'conditions',
                              values.conditions.filter(
                                condition => condition !== values.conditions[i],
                              ),
                            );
                          }}
                          className="ml-2"
                          disabled={values.status === 1}
                        >
                          刪除
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                ))
            }
            {
              // 滿額折現
              values.catCode == 5 &&
                values.conditions.map((condition, i) => (
                  <React.Fragment>
                    <div className={style.formGroup} key={i}>
                      <label>滿NT$</label>
                      <ConditionsValue1
                        values={values}
                        index={i}
                        placeholder="請輸入金額"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <label className="ml-2">，現折NT$</label>
                      <ConditionsValue2
                        values={values}
                        index={i}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                        placeholder="請輸入金額"
                      />
                      <span className="ml-2"></span>
                      {i !== 0 && (
                        <Button
                          color="danger"
                          outline
                          onClick={() => {
                            setFieldValue(
                              'conditions',
                              values.conditions.filter(
                                condition => condition !== values.conditions[i],
                              ),
                            );
                          }}
                          className="ml-2"
                          disabled={values.status === 1}
                        >
                          刪除
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                ))
            }
            {
              // 任選優惠價
              values.catCode == 6 && (
                <React.Fragment>
                  <div className="mb-4">
                    <li>
                      <small>此折抵條件將以最低價的商品做為此活動之折扣</small>
                    </li>
                  </div>
                  {values.conditions?.length > 0 &&
                    values.conditions.map((condition, i) => (
                      <div className={style.formGroup} key={i}>
                        <label>任選</label>
                        <ConditionsValue1
                          values={values}
                          index={i}
                          placeholder="請輸入數字"
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status === 1}
                        />
                        <label className="ml-2">件，NT$</label>
                        <ConditionsValue2
                          placeholder="請輸入金額"
                          values={values}
                          index={i}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status === 1}
                        />
                        {i !== 0 && (
                          <Button
                            color="danger"
                            outline
                            onClick={() => {
                              setFieldValue(
                                'conditions',
                                values.conditions.filter(
                                  condition =>
                                    condition !== values.conditions[i],
                                ),
                              );
                            }}
                            className="ml-2"
                            disabled={values.status === 1}
                          >
                            刪除
                          </Button>
                        )}
                      </div>
                    ))}
                </React.Fragment>
              )
            }
            {
              // 第N件固定價
              values.catCode == 8 && (
                <React.Fragment>
                  <div className="mb-4">
                    <li>
                      <small>此折抵條件將以最低價的商品做為此活動之折扣</small>
                    </li>
                    <li>
                      <small>
                        最常見的「加1元多1件」活動，可設定為「第2件，固定價NT$1」
                      </small>
                    </li>
                  </div>
                  <div className={style.formGroup}>
                    <label>第</label>
                    <ConditionsValue1
                      values={values}
                      index={0}
                      placeholder="請輸入數字"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                    <label className="ml-2">件，固定價NT$</label>
                    <ConditionsValue2
                      placeholder="請輸入金額"
                      values={values}
                      index={0}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                  </div>
                </React.Fragment>
              )
            }
            {
              // 第N件打折
              values.catCode == 9 && (
                <React.Fragment>
                  <div className="mb-5">
                    <li>
                      <small>
                        折扣請輸入1.0~9.9，若打85折，請輸入「8.5」折
                      </small>
                    </li>
                    <li>
                      <small>此折抵條件將以最低價的商品做為此活動之折扣</small>
                    </li>
                  </div>
                  {values.conditions?.length > 0 &&
                    values.conditions.map((condition, i) => (
                      <div className={style.formGroup} key={i}>
                        <label>第</label>
                        <ConditionsValue1
                          values={values}
                          index={i}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status === 1}
                        />
                        <label className="ml-2">件，打</label>
                        <ConditionsValue2
                          placeholder="請輸入1.0-9.9折"
                          values={values}
                          index={i}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status === 1}
                        />
                        <span className="ml-2">折</span>
                        {i !== 0 && (
                          <Button
                            color="danger"
                            outline
                            disabled={values.status === 1}
                            onClick={() => {
                              setFieldValue(
                                'conditions',
                                values.conditions.filter(
                                  condition =>
                                    condition !== values.conditions[i],
                                ),
                              );
                            }}
                            className="ml-2"
                          >
                            刪除
                          </Button>
                        )}
                      </div>
                    ))}
                </React.Fragment>
              )
            }
            {
              // 第N件折現
              values.catCode == 10 && (
                <React.Fragment>
                  <div className="mb-4">
                    <li>
                      <small>此折抵條件將以最低價的商品做為此活動之折扣</small>
                    </li>
                  </div>
                  {values.conditions?.length > 0 &&
                    values.conditions.map((condition, i) => (
                      <div className={style.formGroup} key={i}>
                        <label>第</label>
                        <ConditionsValue1
                          values={values}
                          index={i}
                          placeholder="請輸入數字"
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status !== 0 || values.status === 1}
                        />
                        <label className="ml-2">件，現折NT$</label>
                        <ConditionsValue2
                          placeholder="請輸入金額"
                          values={values}
                          index={i}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          disabled={values.status !== 0 || values.status === 1}
                        />
                        {i !== 0 && (
                          <Button
                            color={values.status !== 0 ? 'secondary' : 'danger'}
                            disabled={
                              values.status !== 0 || values.status === 1
                            }
                            outline
                            onClick={() => {
                              setFieldValue(
                                'conditions',
                                values.conditions.filter(
                                  condition =>
                                    condition !== values.conditions[i],
                                ),
                              );
                            }}
                            className="ml-2"
                          >
                            刪除
                          </Button>
                        )}
                      </div>
                    ))}
                </React.Fragment>
              )
            }
            {values.catCode != 7 && values.catCode != 8 && (
              <div className={style.formGroup}>
                <Button
                  className="w100 btn-sm"
                  outline
                  color={values?.status !== 0 ? 'secondary' : 'primary'}
                  disabled={values?.status !== 0 || values.status === 1}
                  onClick={() => {
                    if (values?.conditions?.length < limit) {
                      setFieldValue('conditions', [
                        ...values.conditions,
                        { value1: '', value2: '' },
                      ]);
                    }
                  }}
                >
                  新增條件
                </Button>
                <span className="ml-2">{`(條件門檻請由低到高設定，最高限制為${limit}階)`}</span>
              </div>
            )}
            {
              // 紅配綠
              values.catCode == 7 && (
                <React.Fragment>
                  <label className="mb-4">區域條件</label>
                  <Alert
                    color="secondary"
                    onClick={() => {
                      setFieldValue('conditionsAreaA.value2', null);
                      setFieldValue('conditionsAreaB.value2', null);
                      setFieldValue('conditionsAreaC.value2', null);
                    }}
                  >
                    <div className={style.formGroup}>
                      <input
                        type="radio"
                        style={{ height: '20px', width: '20px' }}
                        checked={values.condition === 'all'}
                        onChange={() => {
                          setFieldValue('condition', 'all');
                        }}
                        disabled={values.status === 1}
                      />
                      <span className="ml-2">全區</span>
                    </div>
                    <div className={style.formGroup}>
                      <div style={{ width: '30px' }} />
                      <div>A區+B區+C區，一起設定折扣。</div>
                    </div>
                  </Alert>
                  <Alert
                    color="secondary"
                    onClick={() => {
                      setFieldValue(
                        'conditionsArea[0].value1',
                        values?.conditionsArea[0]?.value1 || 0,
                      );
                      setFieldValue(
                        'conditionsArea[1].value1',
                        values?.conditionsArea[1]?.value1 || 0,
                      );
                      setFieldValue(
                        'conditionsArea[2].value1',
                        values?.conditionsArea[2]?.value1 || 0,
                      );
                      setFieldValue(
                        'conditionsArea[0].value2',
                        values?.conditionsArea[0]?.value2 || 0,
                      );
                      setFieldValue(
                        'conditionsArea[1].value2',
                        values?.conditionsArea[1]?.value2 || 0,
                      );
                      setFieldValue(
                        'conditionsArea[2].value2',
                        values?.conditionsArea[2]?.value2 || 0,
                      );
                    }}
                  >
                    <div className={style.formGroup}>
                      <input
                        type="radio"
                        style={{ height: '20px', width: '20px' }}
                        checked={values.condition === 'separate'}
                        onChange={() => {
                          setFieldValue('condition', 'separate');
                        }}
                        disabled={values.status === 1}
                      />
                      <span className="ml-2">分區</span>
                    </div>
                    <div className={style.formGroup}>
                      <div style={{ width: '30px' }} />
                      <div>A區、B區、C區，各別設定折扣。</div>
                    </div>
                  </Alert>
                </React.Fragment>
              )
            }
            {values.catCode == 7 && values.condition === 'all' && (
              <React.Fragment>
                <div className="d-flex mb-2 align-items-center">
                  <label>A區商品任選</label>
                  <ConditionsAreaValue1
                    placeholder="請輸入數字"
                    values={values}
                    index={0}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={values.status === 1}
                  />
                  <span className="ml-2">件</span>
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <label>B區商品任選</label>
                  <ConditionsAreaValue1
                    placeholder="請輸入數字"
                    values={values}
                    index={1}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={values.status === 1}
                  />
                  <span className="ml-2">件</span>
                </div>
                <div className={style.formGroup}>
                  <label>C區商品任選</label>
                  <ConditionsAreaValue1
                    placeholder="請輸入數字"
                    values={values}
                    index={2}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={
                      values.status === 1 || !values.isExtraProductRange
                    }
                  />
                  <span className="ml-2">件</span>
                </div>
                <div className={style.formGroup}>
                  <label>總計固定價NT$</label>
                  <ConditionFixedTPrice
                    placeholder="請輸入金額"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={values.status === 1}
                  />
                </div>
              </React.Fragment>
            )}
            {values.catCode == 7 && values.condition === 'separate' && (
              <React.Fragment>
                <div style={{ marginBottom: '2rem' }}>
                  <div className="d-flex mb-2 align-items-center">
                    <label>A區商品任選</label>
                    <ConditionsAreaValue1
                      placeholder="請輸入數字"
                      values={values}
                      index={0}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                    <span className="ml-2">件</span>
                  </div>
                  <div className="d-flex mb-2 align-items-center">
                    <div style={{ height: '20px', width: '20px' }}>
                      <input
                        type="radio"
                        checked={!values.originPriceA}
                        onChange={() => {
                          setFieldValue('originPriceA', false);
                          setFieldValue('conditionsArea[0].value2', 0);
                        }}
                        disabled={values.status === 1}
                      />
                    </div>
                    <label className="ml-2">每件固定價NT$</label>
                    <ConditionsAreaValue2
                      className={style['form-input']}
                      placeholder="請輸入金額"
                      values={values}
                      index={0}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                    <div className="w50" />
                    <div style={{ height: '20px', width: '20px' }}>
                      <input
                        type="radio"
                        checked={values.originPriceA}
                        onChange={() => {
                          setFieldValue('originPriceA', true);
                          setFieldValue('conditionsArea[0].value2', null);
                          setTimeout(() => {
                            setFieldValue('originPriceA', true);
                            setFieldValue('conditionsArea[0].value2', null);
                          }, 100);
                        }}
                        disabled={values.status === 1}
                      />
                    </div>
                    <span className="ml-2">有A區商品維持原售價</span>
                  </div>
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  <div className="d-flex mb-2 align-items-center">
                    <label>B區商品任選</label>
                    <ConditionsAreaValue1
                      placeholder="請輸入數字"
                      values={values}
                      index={1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                    <span className="ml-2">件</span>
                  </div>
                  <div className={style.formGroup}>
                    <div style={{ height: '20px', width: '20px' }}>
                      <input
                        type="radio"
                        checked={!values.originPriceB}
                        onChange={() => {
                          setFieldValue('originPriceB', false);
                          setFieldValue('conditionsArea[1].value2', Number(0));
                        }}
                        disabled={values.status === 1}
                      />
                    </div>
                    <label className="ml-2">每件固定價NT$</label>
                    <ConditionsAreaValue2
                      className={style['form-input']}
                      placeholder="請輸入金額"
                      values={values}
                      index={1}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      disabled={values.status === 1}
                    />
                    <div className="w50" />
                    <div style={{ height: '20px', width: '20px' }}>
                      <input
                        type="radio"
                        checked={values.originPriceB}
                        onChange={() => {
                          setFieldValue('originPriceB', true);
                          setFieldValue('conditionsArea[1].value2', null);
                        }}
                        disabled={values.status === 1}
                      />
                    </div>
                    <span className="ml-2">有B區商品維持原售價</span>
                  </div>
                </div>
                {values.isExtraProductRange && (
                  <div style={{ marginBottom: '2rem' }}>
                    <div className={`${style.show_flex} mb-2`}>
                      <label>C區商品任選</label>
                      <ConditionsAreaValue1
                        placeholder="請輸入數字"
                        values={values}
                        index={2}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={
                          values.status === 1 || !values.isExtraProductRange
                        }
                      />
                      <span className="ml-2">件</span>
                    </div>
                    <div className={style.formGroup}>
                      <div style={{ height: '20px', width: '20px' }}>
                        <input
                          type="radio"
                          checked={!values.originPriceC}
                          onChange={() => {
                            setFieldValue('originPriceC', false);
                            setFieldValue('conditionsArea[2].value2', 0);
                          }}
                          disabled={values.status === 1}
                        />
                      </div>
                      <label className="ml-2">每件固定價NT$</label>
                      <ConditionsAreaValue2
                        className={style['form-input']}
                        placeholder="請輸入金額"
                        values={values}
                        index={2}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        disabled={values.status === 1}
                      />
                      <div className="w50" />
                      <div style={{ height: '20px', width: '20px' }}>
                        <input
                          type="radio"
                          checked={values.originPriceC}
                          onChange={() => {
                            setFieldValue('originPriceC', true);
                            setFieldValue('conditionsArea[2].value2', null);
                          }}
                          disabled={values.status === 1}
                        />
                      </div>
                      <span className="ml-2">有C區商品維持原售價</span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(DiscountConditionSettings);
