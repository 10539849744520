import Layout from 'components/Layout';
import React, { useState } from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';


import { TabItem } from '../components/tabItem';
import RecruitmentPage from '../components/uuCafeRecruitment/RecruitmentPage';
import RecruitmentSetting from '../components/uuCafeRecruitment/RecruitmentSetting';

const UUCafeRecruitment = () => {
  const [currentTab, setCurrentTab] = useState(1);
  return (
    <Layout
      pageTitle="人才招募"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '人才招募' },
      ]}
    >
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="職缺管理"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="頁面文字"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <RecruitmentSetting />
        </TabPane>
        <TabPane tabId={2}>
          <RecruitmentPage />
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default UUCafeRecruitment;
