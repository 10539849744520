import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useDispatch } from 'react-redux';

import { getProductBg } from '../redux/actions/data/productActions';

const CustomColorPicker = props => {
  const { initColor } = props;
  const { setFormFieldValue, formFieldName } = props;
  const [isShowPicker, setShowPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState('');
  const [pickerColor, setPickerColor] = useState('');

  const dispatch = useDispatch();

  const toggleColorPicker = () => {
    if (isShowPicker) {
      setPickerColor(currentColor);
    }
    setShowPicker(preShow => !preShow);
  };

  const handlePickComplete = (color, event) => {
    let colorCode = color.hex.replace('#', '');
    setColor(colorCode);
  };

  const handleInpuChange = event => {
    let colorCode = event.target.value.replace('#', '');
    setColor(colorCode);
  };

  const setColor = colorCode => {
    let code = colorCode.toUpperCase();
    setCurrentColor(code);
    setPickerColor(code);

    // console.log(code)
    dispatch(getProductBg(code));

    if (setFormFieldValue) setFormFieldValue(formFieldName, `#${  code}`);
  };

  useEffect(() => {
    let colorCode = '964C4C';

    if (initColor) {
      colorCode = initColor.replace('#', '');
    }

    // console.log('colorpicker init =>' + colorCode)

    setColor(colorCode);
  }, [initColor]);

  return (
    <>
      <div className="d-flex align-items-center gap-1">
        <button
          type="button"
          className="btn btn-link color-square mr-2"
          onClick={toggleColorPicker}
          style={{ backgroundColor: `#${  currentColor}` }}
        ></button>
        #
        <input
          className="d-inline-block form-control px-1"
          placeholder="色碼"
          value={currentColor}
          onChange={handleInpuChange}
          name={props.name}
        />
      </div>

      {isShowPicker && (
        <div style={{ position: 'absolute', zIndex: '2' }}>
          <div
            style={{
              bottom: '0px',
              left: '0px',
              position: 'fixed',
              right: '0px',
              top: '0px',
            }}
            onClick={toggleColorPicker}
          />
          <ChromePicker
            color={`#${  pickerColor}`}
            onChangeComplete={handlePickComplete}
          />
        </div>
      )}
    </>
  );
};

CustomColorPicker.defaultProps = {
  formFieldName: 'colorPicker',
  initColor: '#964C4C',
  setFormFieldValue: null, // function for fromilk，讓formilk sumbit時可接到colorpicker的內容，預設是抓不到
};

export default CustomColorPicker;
