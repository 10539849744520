import SortingTableHead from 'components/SortingTableHead';
import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

const TableList = ({ setFieldValue, values }) => {
  const { loading, salesRankingList } = useSelector(
    state => state.getStoreSalesDataList,
  );

  useEffect(() => {
    if (JSON.stringify(salesRankingList) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: salesRankingList });
    }
  }, [salesRankingList]);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>
              <SortingTableHead
                activeId={{
                  down: 0,
                  item: 'orderItemDate',
                  up: 1,
                }}
                title="日期"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 2,
                  item: 'masterCount',
                  up: 3,
                }}
                title="主單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 4,
                  item: 'itemCount',
                  up: 5,
                }}
                title="訂單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 6,
                  item: 'quantity_sum',
                  up: 7,
                }}
                title="商品數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 8,
                  item: 'finalPrice_sell_sum',
                  up: 9,
                }}
                title="商品金額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 10,
                  item: 'discountPrice_sum',
                  up: 11,
                }}
                title="折扣金額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 12,
                  item: 'finalPrice_sum',
                  up: 13,
                }}
                title={['訂單金額', '(折扣後)']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 14,
                  item: 'cancelCount',
                  up: 15,
                }}
                title="取消單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 16,
                  item: 'cancel_quantity_sum',
                  up: 17,
                }}
                title="取消商品數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 18,
                  item: 'cancel_finalPrice_sum',
                  up: 19,
                }}
                title="取消單金額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 20,
                  item: 'returnCount',
                  up: 21,
                }}
                title={['退貨結案', '退貨單筆數']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 22,
                  item: 'return_quantity_sum',
                  up: 23,
                }}
                title={['退貨結案', '商品數量']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 24,
                  item: 'return_totalAmount_sum',
                  up: 25,
                }}
                title={['退貨結案', '退貨金額']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 26,
                  item: 'netSalesAmount',
                  up: 27,
                }}
                title="淨銷售額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 28,
                  item: 'costPrice_sum',
                  up: 29,
                }}
                title="成本"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 30,
                  item: 'grossMargin',
                  up: 31,
                }}
                title="毛利率"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
          </tr>
        </TableHead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {salesRankingList.datas?.length > 0 ? (
              salesRankingList.datas.map((data, i) => (
                <tr key={i}>
                  <td>{data.orderItemDate}</td>
                  <td>{data.masterCount}</td>
                  <td>{data.itemCount}</td>
                  <td>{data.quantity_sum}</td>
                  <td className="price text-black fw-normal">
                    {data.finalPrice_sell_sum.toLocaleString('en-US')}
                  </td>
                  <td className="price text-black fw-normal">
                    {data.discountPrice_sum.toLocaleString('en-US')}
                  </td>
                  <td className="price text-black fw-normal">
                    {data.finalPrice_sum.toLocaleString('en-US')}
                  </td>
                  <td>{data.cancelCount}</td>
                  <td>{data.cancel_quantity_sum}</td>
                  <td className="price text-black fw-normal">
                    {data.cancel_finalPrice_sum.toLocaleString('en-US')}
                  </td>
                  <td>{data.returnCount}</td>
                  <td>{data.return_quantity_sum}</td>
                  <td className="price text-black fw-normal">
                    {data.return_totalAmount_sum.toLocaleString('en-US')}
                  </td>
                  <td className="price text-black fw-normal">
                    {data.netSalesAmount.toLocaleString('en-US')}
                  </td>
                  <td className="price text-black fw-normal">
                    {data.costPrice_sum.toLocaleString('en-US')}
                  </td>
                  <td>{data.grossMargin}</td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default TableList;
