import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_OTHER_PRODUCT_ADD_FAIL,
  UUCAFE_OTHER_PRODUCT_ADD_REQUEST,
  UUCAFE_OTHER_PRODUCT_ADD_SUCCESS,
  UUCAFE_OTHER_PRODUCT_FAIL,
  UUCAFE_OTHER_PRODUCT_REQUEST,
  UUCAFE_OTHER_PRODUCT_SUCCESS,
  UUCAFE_OTHER_PRODUCT_UPDATE_FAIL,
  UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST,
  UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_LIST_FAIL,
  UUCAFE_OTHER_PRODUCT_LIST_REQUEST,
  UUCAFE_OTHER_PRODUCT_LIST_SUCCESS,
  UUCAFE_OTHER_PRODUCT_SEO_REQUEST,
  UUCAFE_OTHER_PRODUCT_SEO_SUCCESS,
  UUCAFE_OTHER_PRODUCT_SEO_FAIL,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL,
  UUCAFE_OTHER_PRODUCT_PAGE_FAIL,
  UUCAFE_OTHER_PRODUCT_PAGE_REQUEST,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST,
  UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS,
  UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS,
} from '../../constants/data/uuCafeOtherProductConstants';

/*
* @Params
* {
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string",
  "keyword": "string",
  "menuId": 0,
  "status": 0
  }
*
*/
export const getCafeOtherProductList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_OTHER_PRODUCT_LIST_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_LIST_SUCCESS,
      url: '/Backend/Cafe_MealOthPrd/list',
    });
  };
};

export const getCafeOtherProductDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_FAIL,
      method: 'get',
      requestConstant: UUCAFE_OTHER_PRODUCT_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_SUCCESS,
      url: `/Backend/Cafe_MealOthPrd/prd/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
menuId integer($int32)
name string
name_en string
content string
content_en string
status integer($int32)
img_files array
temperatures string
prices string
*/
export const addCafeOtherProductDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_OTHER_PRODUCT_ADD_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_ADD_SUCCESS,
      url: '/Backend/Cafe_MealOthPrd/prd/add',
    });
  };
};

/*
* @Params
id integer($int32)
menuId integer($int32)
name string
name_en string
content string
content_en string
status integer($int32)
img_files array
temperatures string
prices string
*/

export const updateCafeOtherProductDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealOthPrd/prd/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeOtherProductDetail = async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_MealOthPrd/prd/delete',
  });
};

// ============ OTHER_PRODUCT_SEO ==================

export const getCafeMealOtherProductSeo = menuId => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_SEO_FAIL,
      method: 'get',
      requestConstant: UUCAFE_OTHER_PRODUCT_SEO_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_SEO_SUCCESS,
      url: `Backend/Cafe_MealOthMenu/menu/seo/${menuId}`,
    });
  };
};

/*
@Params : data : {
{
  "title": "string",
  "title_en": "string",
  "keyword": "string",
  "keyword_en": "string",
  "desc": "string",
  "desc_en": "string"
}
}
*/
export const updateCafeMealOtherProductSeo = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS,
      url: '/Backend/Cafe_MealOthMenu/menu/seo/save',
    });
  };
};

//PAGE
export const getCafeMealOtherProductPage = menuId => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_PAGE_FAIL,
      method: 'get',
      requestConstant: UUCAFE_OTHER_PRODUCT_PAGE_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS,
      url: `/Backend/Cafe_MealOthMenu/menu/page/${menuId}`,
    });
  };
};

/**
 * title  string
 * title_en  string
 * content  string
 * content_en string
 * introBanner_files  array
 * introBanner_files_mobile  array
 * zoneBanner_files  array
 * zoneBanner_files_mobile array
 */

export const saveCafeMealOtherProductPage = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST,
      successConstant: UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS,
      url: '/Backend/Cafe_MealOthMenu/menu/page/save',
    });
  };
};
