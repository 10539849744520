import {
  ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST,
  ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS,
  ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL,
  ORDER_RETURN_RECHARGE_LIST_REQUEST,
  ORDER_RETURN_RECHARGE_LIST_SUCCESS,
  ORDER_RETURN_RECHARGE_LIST_FAIL,
  ORDER_RETURN_EDIT_ATMACC_REQUEST,
  ORDER_RETURN_EDIT_ATMACC_SUCCESS,
  ORDER_RETURN_EDIT_ATMACC_FAIL,
  ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL,
  ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST,
  ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS,
  GET_ORDERITEMS_RETURN_INFO_REQUEST,
  GET_ORDERITEMS_RETURN_INFO_SUCCESS,
  GET_ORDERITEMS_RETURN_INFO_FAIL,
  ORDER_RETURN_FINISHED_REQUEST,
  ORDER_RETURN_FINISHED_SUCCESS,
  ORDER_RETURN_FINISHED_FAIL,
} from 'redux/constants/data/orderReturnConstants';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';

export const getDeliveryRefoundList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST,
      successConstant: ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS,
      url: '/Backend/OrderReturn/DeliveryRefundList',
    });
  };
};
export const getRechargeList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_RECHARGE_LIST_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_RECHARGE_LIST_REQUEST,
      successConstant: ORDER_RETURN_RECHARGE_LIST_SUCCESS,
      url: '/Backend/OrderReturn/RechargeList',
    });
  };
};

export const editATMAccount = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_EDIT_ATMACC_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_EDIT_ATMACC_REQUEST,
      successConstant: ORDER_RETURN_EDIT_ATMACC_SUCCESS,
      url: '/Backend/Refund/SaveRefundAccountInfo',
    });
  };
};

export const editReturnFailATMAccount = data => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST,
      successConstant: ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS,
      url: '/Backend/Refund/SaveCancelAccountInfo',
    });
  };
};

export const getOrderitemReturnInfo = data => {
  const url = '/Website/OrderReturn/OrderReturnEdit_v2';
  CommonUtil.consoleLog({ anno: 'request', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_ORDERITEMS_RETURN_INFO_FAIL,
      method: 'post',
      requestConstant: GET_ORDERITEMS_RETURN_INFO_REQUEST,
      successConstant: GET_ORDERITEMS_RETURN_INFO_SUCCESS,
      url,
    });
  };
};

export const orderReturnFinished = data => {
  const url = '/Backend/OrderReturn/LogisticsReturnFinish_v3';
  CommonUtil.consoleLog({ anno: 'request', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: ORDER_RETURN_FINISHED_FAIL,
      method: 'post',
      requestConstant: ORDER_RETURN_FINISHED_REQUEST,
      successConstant: ORDER_RETURN_FINISHED_SUCCESS,
      url,
    });
  };
};

export const addReturnOrder = (data = {}) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/OrderReturn/AddReturnOrder_v3',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};

export const checkReturnOrderItemPair = (
  data = {
    items: [],
    orderMasterId: 0,
    orderNumber: '',
  },
) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/OrderReturn/CheckReturnOrderItemPair',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res.message.split('，')[0];
    }
  });
};
