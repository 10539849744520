import RcPagination from 'rc-pagination';
import React, { memo } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import { modal } from '../../utils/modal';
import { TableHasNoData, TableLoading } from '../TableHasNoData';

const OrderListTable = ({
  exportOrdersInfoHandler,
  setOrderInfo,
  handleChangePage,
  setIsOrderRemarkModalOpen,
  setIsRedeemRewardPointsDetailModalOpen,
  setRedeemRewardsPointsInfo,
  setIsDiscountModalOpen,
  setIsDiscountActivityModalOpen,
  handleToDetail,
  setCurrentDiscountInfo,
}) => {
  const { loading, orderListInfo, error } = useSelector(
    state => state.orderList,
  );

  return (
    <Card className={style.card}>
      {/* <div className="mb-3" style={{ margin: '0.5rem' }}>
          <Button className="btn-gray">列印出貨明細單</Button>
        </div> */}
      <Button className="me-2 mb-2 w100" onClick={exportOrdersInfoHandler}>
        匯出訂單
      </Button>
      <div className="table-responsive mb-5" style={{ minHeight: '80vh' }}>
        <Table hover bordered striped>
          <thead
            className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
          >
            <tr>
              <th>訂單編號</th>
              <th>
                <div>配送方式</div>
                <div>配送商</div>
                <div>配送編號</div>
              </th>
              <th>
                <div>交期</div>
                <div>預計出貨日</div>
                <div>出貨單狀態</div>
                <div>出貨單狀態日期</div>
                <div>驗退原因說明</div>
              </th>
              <th>
                <div>轉單時間</div>
                <div>訂單狀態</div>
                <div>訂單狀態日</div>
                <div>付款狀態</div>
                <div>取消原因</div>
              </th>
              <th>
                <div>商品名稱</div>
                <div>商品選項</div>
                <div>商品料號</div>
                <div>商品屬性</div>
              </th>
              <th>
                <div>數量</div>
                <div>商品單價</div>
                <div>總折扣金額</div>
                <div>總銷售金額(折扣後)</div>
              </th>
              <th>
                <div>折扣活動總折扣金額</div>
                <div>折價券總折扣金額</div>
                <div>點數總折扣金額</div>
                <div>贈品券活動序號</div>
                <div className="d-none">門市券活動序號</div>
              </th>
              <th>
                <div>實際出貨數量</div>
                <div>實際出貨金額</div>
              </th>
              <th>
                <div>會員編號</div>
                <div>會員等級</div>
                <div>購買人</div>
                <div>收件人</div>
                <div>收件人電話</div>
              </th>
              <th>商店訂單備註</th>
              <th>消費者訂單備註</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {orderListInfo?.datas?.length > 0 && !error ? (
                orderListInfo?.datas?.map(i => (
                  <React.Fragment key={JSON.stringify(i)}>
                    <tr className="text-nowrap bg">
                      <td colSpan="2">
                        <div className="price text-black">
                          總金額：
                          {(i.totalAmount || '0').toLocaleString('en-US')}
                        </div>
                        {/* <div>訂購時間：{i.orderTime}</div> */}
                      </td>
                      <td>
                        <div>{`使用點數：${i.redeemPoint.toLocaleString(
                          'en-US',
                        )}點`}</div>
                      </td>
                      <td>
                        <div>主單編號：{i.orderNumber}</div>
                        {/* <div>主單狀態：{i.orderStatusName}</div> */}
                      </td>
                      <td colSpan="2">
                        <div>實付運費：NT$ {i.deliveryCharge}</div>
                        {i.deliveryChargeReturnNumber && (
                          <div>
                            （退運費申請 -
                            <span className={style.text_dark_info}>
                              {i.deliveryChargeReturnNumber}
                            </span>
                            )
                          </div>
                        )}
                      </td>
                      <td colSpan={5} className="text-end">
                        <FaChevronDown id={`orderId${i.id}`} />
                      </td>
                    </tr>
                    {i.orderItems.map((j, jIndex) => (
                      <tr key={jIndex}>
                        <td>
                          {/* 訂單編號 */}
                          <div>{j.orderItemNumber}</div>
                          <Button
                            color="info"
                            outline
                            onClick={() => handleToDetail(j.id)}
                          >
                            詳細資料
                          </Button>
                        </td>
                        <td>
                          {/* 配送方式 */}
                          <div>{j.logisticsWayName || '--'}</div>
                          {/* 配送商 */}
                          <div>
                            {j.logisticsWay === 3
                              ? j.homeDeliveryCName || '--'
                              : '--'}
                          </div>
                          {/* 配送編號 */}
                          <div>
                            {j.logisticsWay === 3
                              ? j.homeDeliveryNumber || '--'
                              : j.cvsPaymentNo || '--'}
                          </div>
                        </td>
                        <td>
                          {/* 交期 */}
                          <div>{j.deliveryType === 1 ? '一般' : '預購'}</div>
                          {/* 預計出貨日 */}
                          <div>{j.expectedDeliveryDate || '--'}</div>
                          {/* 出貨單狀態 */}
                          <div>{j.shippingStatusName || '--'}</div>
                          {/* 出貨單狀態日期 */}
                          <div>{j.shippingStatusDate || '--'}</div>
                          {/* 驗退原因說明 */}
                          <div>{j.cReturnReason || '--'}</div>
                        </td>
                        <td>
                          {/* 轉單時間 */}
                          <div>{i.orderTime || '--'}</div>
                          {/* 訂單狀態 */}
                          <div>{i.orderStatusName || '--'}</div>
                          {/* 訂單狀態日 */}
                          <div>{i.orderStatusTime || '--'}</div>
                          {/* 付款狀態 */}
                          <div>{i.payStatusName || i.payStatus || '--'}</div>
                          {/* 取消原因 */}
                          <div>{j.cancelRemark || '--'}</div>
                        </td>
                        <td>
                          {/* 商品名稱 */}
                          <div className="text-nowrap">
                            {j.productname || '--'}
                          </div>
                          {/* 商品選項 */}
                          <div>
                            {j.color || '--'}/{j.size || '--'}
                          </div>
                          {/* 商品料號 */}
                          <div
                            role={
                              Number(j.productType) === 4 ||
                              Number(j.productType) === 1
                                ? 'button'
                                : ''
                            }
                            className={
                              Number(j.productType) === 4 ||
                              Number(j.productType) === 1
                                ? 'text-primary'
                                : ''
                            }
                            onClick={() => {
                              if (Number(j.productType) === 4) {
                                window.open(
                                  `/#/GiftManagement?page=1&keyword=${
                                    j.productname || j.freebieCode
                                  }`,
                                  '_blank',
                                );
                              } else if (Number(j.productType) === 1) {
                                window.open(
                                  `/#/ProductControl?page=1&keyword=${
                                    j.productname || j.productcode
                                  }`,
                                  '_blank',
                                );
                              }
                            }}
                          >
                            {Number(j.productType) === 4
                              ? j.freebieCode || '--'
                              : j.productcode || '--'}
                          </div>
                          {/* 商品屬性 */}
                          <div
                            className={
                              j.productType == 4 &&
                              (j.isThereFreebie_A ||
                                j.isThereFreebie_P ||
                                j.isThereFreebie_C)
                                ? 'text-primary'
                                : ''
                            }
                            role={
                              j.productType == 4 &&
                              (j.isThereFreebie_A ||
                                j.isThereFreebie_P ||
                                j.isThereFreebie_C)
                                ? 'button'
                                : ''
                            }
                            onClick={() => {
                              if (j.productType == 4) {
                                // 贈品資訊
                                if (j.isThereFreebie_A) {
                                  modal.showGiftInfo('活動贈品', j.id);
                                }
                                if (j.isThereFreebie_P) {
                                  modal.showGiftInfo('商品贈品', j.id);
                                }

                                if (j.isThereFreebie_C) {
                                  modal.showGiftInfo('贈品券', j.id);
                                }
                              }
                            }}
                          >
                            {Number(j.productType) === 4
                              ? j.freebieType || '--'
                              : '商品'}
                          </div>
                        </td>
                        <td className="text-end">
                          {/* 數量 */}
                          <div>{j.quantity}</div>
                          {/* 商品單價 */}
                          <div className="price text-black fw-normal">
                            {j.unitPrice.toLocaleString('en-US')}
                          </div>
                          {/* 折扣金額 */}
                          <div
                            className="d-flex justify-content-end text-primary"
                            role="button"
                            onClick={() => {
                              modal.showBillInfo({
                                ...j,
                                orderNumber: i.orderNumber,
                              });
                            }}
                          >
                            -
                            <div className="price text-primary fw-normal discountAmount">
                              {j?.discountAmount?.toLocaleString('en-US') ??
                                0}
                            </div>
                          </div>
                          {/* 銷售金額(折扣後) */}
                          <div className="price text-black fw-normal discountPrice">
                            {(j.discountPrice || 0).toLocaleString('en-US')}
                          </div>
                        </td>
                        <td className="text-end">
                          <div
                            className="text-primary"
                            role="button"
                            onClick={() => {
                              setCurrentDiscountInfo(j.id);
                            }}
                          >
                            {/* 折扣活動總折扣金額 */}
                            <div
                              className="d-flex justify-content-end"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCurrentDiscountInfo(j.id);
                                setIsDiscountActivityModalOpen();
                              }}
                            >
                              -
                              <div
                                className={`price fw-normal ${style.text_dark_info}`}
                              >
                                {(
                                  j.activityDiscountAmountUnit || '0'
                                )?.toLocaleString('en-US')}
                              </div>
                            </div>
                            {/* 折價券總折扣金額 */}
                            <div
                              className="d-flex justify-content-end"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setCurrentDiscountInfo(j.id);
                                setIsDiscountModalOpen();
                              }}
                            >
                              -
                              <div
                                className={`price fw-normal ${style.text_dark_info}`}
                              >
                                {(
                                  j.couponDiscountAmountUnit || '0'
                                )?.toLocaleString('en-US')}
                              </div>
                            </div>
                            {/* 點數總折扣金額 */}
                            <div
                              className="d-flex justify-content-end"
                              role="button"
                              onClick={() => {
                                setRedeemRewardsPointsInfo({
                                  amount: j.redeemAmount,
                                  points: j.redeemPoint,
                                });
                                setIsRedeemRewardPointsDetailModalOpen();
                              }}
                            >
                              -
                              <div className="price fw-normal text-primary">
                                {j.redeemAmount?.toLocaleString('en-US') || 0}
                              </div>
                            </div>
                          </div>
                          {/* 贈品券活動序號 */}
                          <div>--</div>
                          {/* 門市券活動序號 */}
                          <div className="d-none">--</div>
                        </td>
                        <td>
                          {/* 實際出貨數量 */}
                          <div>
                            {j.orderItemStatus === 4 || j.orderItemStatus === 7
                              ? j.quantity
                              : '--'}
                          </div>
                          {/* 實際出貨金額 */}
                          <div>
                            {j.orderItemStatus === 4 || j.orderItemStatus === 7
                              ? `NT$ ${j.finalPrice.toLocaleString('en-US')}`
                              : '--'}
                          </div>
                        </td>
                        <td>
                          {/* 會員編號 */}
                          <div>{`+${j.memberPhone}` || '--'}</div>
                          {/* 會員等級 */}
                          <div>{j.memberLevel}</div>
                          {/* 購買人 */}
                          <div>{j.memberName || '--'}</div>
                          {/* 收件人 */}
                          <div>{j.name || '--'}</div>
                          {/* 收件人電話 */}
                          <div>{j.phone || '--'}</div>
                        </td>
                        <td className="text-center">
                          {/* 商店訂單備註 */}
                          <div>{j.orderRemark || '--'}</div>
                          <div
                            className={style.text_dark_info}
                            style={{ cursor: 'pointer', fontSize: '14px' }}
                            onClick={() => {
                              setOrderInfo({
                                requestBody: {
                                  orderItemId: j.id,
                                  orderRemark: j.orderRemark,
                                },
                                主單編號: i.orderNumber,
                                商品名稱: j.productname,
                                訂單編號: j.orderItemNumber,
                              });
                              setIsOrderRemarkModalOpen();
                            }}
                          >
                            編輯備註
                          </div>
                        </td>
                        <td className="text-center">
                          {/* 消費者訂單備註 */}
                          <div>{j.remark || '--'}</div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={orderListInfo?.current_page || 1}
          pageSize={orderListInfo?.page_size || 10}
          total={orderListInfo?.total_count || 0}
          onChange={handleChangePage}
        />
      </div>
    </Card>
  );
};

export default memo(OrderListTable);
