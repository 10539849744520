import { useState } from 'react';

export const usePointOptions = () => {
  const [loading, setLoading] = useState(false);
  const options = [
    {
      label: '全部',
      value: 0,
    },
    {
      label: '比例給點',
      value: 1,
    },
    {
      label: '固定給點',
      value: 2,
    },
  ];
  return { loading, options };
};
