import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  FormGroup,
  InputGroup,
  Label,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import { getSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';
import * as Yup from 'yup';
import SEOSettingsConfig from 'components/SEOSettingsConfig';

import Layout from '../components/Layout';
import NewsLink from '../components/NewsLink';
import UploadFileBox from '../components/UploadFileBox';
import { TabItem } from '../components/tabItem';
import { getNewsDetails, postNews } from '../redux/actions/data/newsActions';
import { NEWS_DETAILS_SUCCESS } from '../redux/constants/data/newsConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

import moment from 'moment';

export default function NewsDetail() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loadingDetail, newsData } = useSelector(state => state.newsDetails);
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id > 0) {
      dispatch(getNewsDetails(id));
    } else {
      dispatch({
        payload: {
          contentLeft: '',
          contentLeft_en: '',
          contentLinks: '',
          contentRight: '',
          contentRight_en: '',
          endTime: '',
          id: 0,
          introduction_en: '',
          introdution: '',
          startTime: '',
          subTitle: '',
          subTitle_en: '',
          title: '',
          title_en: '',
          youtubeLink: '',
        },
        type: NEWS_DETAILS_SUCCESS,
      });
    }
  }, [id]);

  useEffect(() => {
    dispatch(getSeoSettingsDetail({ sourceId: id, type: 16 }));
  }, []);

  const handleSubmit = useCallback(
    async values => {
      let formData = new FormData();
      formData.set('id', id || 0);
      formData.set('title', values.title);
      formData.set('title_en', values.title_en);
      formData.set('subTitle', values.subTitle);
      formData.set('subTitle_en', values.subTitle_en);
      formData.set('introdution', values.introdution || '');
      formData.set('introduction_en', values.introduction_en || '');
      formData.append('contentLeft', values.contentLeft);
      formData.append('contentLeft_en', values.contentLeft_en);
      formData.append('contentRight', values.contentRight);
      formData.append('contentRight_en', values.contentRight_en);
      formData.append('startTime', values.startTime);
      formData.set('youtubeLink', values.youtubeLink);

      if (values.endTime !== null) {
        formData.append('endTime', values.endTime);
      }

      if (values.newsLink !== null && values.newsLink.length > 0) {
        formData.append('contentLinks', JSON.stringify(values.newsLink));
      } else {
        formData.append('contentLinks', '[]');
      }

      formData.append('navColor', values.navColor);

      values.banner_files.forEach(file => {
        formData.append('banner_files', file);
      });

      values.banner_files_mobile.forEach(file => {
        formData.append('banner_files_mobile', file);
      });

      values.content_desktop_files.forEach(file => {
        formData.append('content_desktop_files', file);
      });

      values.content_mobile_files.forEach(file => {
        formData.append('content_mobile_files', file);
      });
      formData.append('status', values.status);
      setLoading(true);
      try {
        await postNews(formData);
        if (id) {
          MessageUtil.toastSuccess('修改成功', '');
        } else {
          MessageUtil.toastSuccess('新增成功', '');
        }
        setLoading(false);
        setTimeout(() => history.push('/NewsList'), 1000);
      } catch (e) {
        MessageUtil.alertWanring(e);
        setLoading(false);
      }
    },
    [id],
  );

  const getImageDataFromSetting = (pic, url) => {
    if (pic && url) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: pic,
          url: url,
        },
      ];
    } else {
      return [];
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      contentLeft: Yup.string().required('必填!'),
      contentLeft_en: Yup.string().required('必填!'),
      contentRight: Yup.string().required('必填!'),
      contentRight_en: Yup.string().required('必填!'),
      current_banner_files: Yup.array().min(1, '需上傳圖片'),
      current_banner_files_mobile: Yup.array().min(1, '需上傳圖片'),
      current_content_desktop_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_files: Yup.array().min(1, '需上傳圖片'),
      navColor: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
      subTitle: Yup.string().required('必填!'),
      subTitle_en: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });

    return schema;
  };

  const handlePreview = id => {
    history.push(`/NewsPreview/${id}`);
  };

  return (
    <Layout
      pageTitle="新聞編輯"
      items={[
        { isActive: false, page_name: '新聞管理', to: '/NewsList' },
        { isActive: false, page_name: '新聞編輯' },
      ]}
    >
      {id > 0 && (
        <div className="text-end">
          <Button
            className="m-2 btn btn-sm"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
            onClick={() => handlePreview(id)}
          >
            <FaEye style={{ color: '#f4772e' }} />
            <span className="ms-2" style={{ color: '#f4772e' }}>
              預覽
            </span>
          </Button>
        </div>
      )}
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="內容設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="SEO設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            {!loadingDetail ? (
              <Formik
                enableReinitialize
                initialValues={{
                  banner_files: [],
                  banner_files_mobile: [],
                  contentLeft: newsData.contentLeft || '',
                  contentLeft_en: newsData.contentLeft_en || '',
                  contentRight: newsData.contentRight || '',
                  contentRight_en: newsData.contentRight_en || '',
                  content_desktop_files: [],
                  content_mobile_files: [],
                  current_banner_files: [],
                  current_banner_files_mobile: [],
                  current_content_desktop_files: [],
                  current_content_mobile_files: [],
                  endTime:
                    newsData.endTime ||
                    moment(newsData.startTime || new Date())
                      .year(9999)
                      .format('YYYY/MM/DD HH:mm'),
                  init_banner_files: getImageDataFromSetting(
                    newsData.bannerImg || '',
                    newsData.bannerUrl || '',
                  ),
                  init_banner_files_mobile: getImageDataFromSetting(
                    newsData.bannerImg_mobile || '',
                    newsData.bannerUrl_mobile || '',
                  ),
                  init_content_desktop_files: getImageDataFromSetting(
                    newsData.contentImg_desktop || '',
                    newsData.contentImg_desktop_url || '',
                  ),
                  init_content_mobile_files: getImageDataFromSetting(
                    newsData.contentImg_mobile || '',
                    newsData.contentImg_mobile_url || '',
                  ),

                  introduction_en: newsData.introduction_en || '',
                  introdution: newsData.introdution || '',
                  navColor: newsData.navColor,
                  newsLink: newsData.contentLinks || [],
                  startTime: moment(newsData.startTime || new Date()).format(
                    'YYYY/MM/DD HH:mm',
                  ),
                  status: newsData.status,
                  subTitle: newsData.subTitle || '',
                  subTitle_en: newsData.subTitle_en || '',
                  title: newsData.title || '',
                  title_en: newsData?.title_en || '',
                  youtubeLink: newsData?.youtubeLink || '',
                }}
                onSubmit={handleSubmit}
                validationSchema={getValidationSchema}
              >
                {props => (
                  <Form className="form-list form-label-140">
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        新聞標題
                        <ErrorMessage
                          name="title"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field name="title" className="form-control" />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        新聞標題(英)
                        <ErrorMessage
                          name="title_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field name="title_en" className="form-control" />
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        副標
                        <ErrorMessage
                          name="subTitle"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="subTitle"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>網站位置：左上和右下有大逗號包著的文字</small>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        副標(英)
                        <ErrorMessage
                          name="subTitle_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="subTitle_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>網站位置：左上和右下有大逗號包著的文字</small>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="" style={{ width: '180px' }}>
                        內容摘要
                      </label>
                      <div className="form-item">
                        <Field
                          name="introdution"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>
                          新聞列表的文字說明，若沒填寫，網頁會顯示【內文－左】的文字
                        </small>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="" style={{ width: '180px' }}>
                        內容摘要(英)
                      </label>
                      <div className="form-item">
                        <Field
                          name="introduction_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>
                          新聞列表的文字說明，若沒填寫，網頁會顯示【內文－左】的文字
                        </small>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－左
                        <ErrorMessage
                          name="contentLeft"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentLeft"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－左(英)
                        <ErrorMessage
                          name="contentLeft_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentLeft_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－右
                        <ErrorMessage
                          name="contentRight"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentRight"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－右(英)
                        <ErrorMessage
                          name="contentRight_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentRight_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label style={{ width: '180px' }}>上架日期區間 </label>
                      <div className="form-item">
                        <FormGroup>
                          <Label className="required">
                            上架時間
                            <ErrorMessage
                              name="startTime"
                              component="span"
                              className="form-error"
                            />
                          </Label>
                          <InputGroup>
                            <DatePicker
                              className="form-control"
                              name="startTime"
                              selected={moment(props.values.startTime).toDate()}
                              onChange={date =>
                                props.setFieldValue(
                                  'startTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                )
                              }
                              selectsStart
                              dateFormat="yyyy/MM/dd"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <Label>下架時間</Label>
                          <InputGroup>
                            <DatePicker
                              className="form-control"
                              name="endTime"
                              selected={moment(props.values.endTime).toDate()}
                              onChange={date =>
                                props.setFieldValue(
                                  'endTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                )
                              }
                              dateFormat="yyyy/MM/dd"
                              selectsEnd
                              minDate={new Date(newsData.startTime || null)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label style={{ width: '180px' }}>連結</label>
                      <div className="form-item">
                        <NewsLink
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="newsLink"
                          initContents={props.values.newsLink}
                        />
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        主選單圖標顏色
                        <ErrorMessage
                          name="navColor"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Field
                            className="my-auto me-2"
                            id="white"
                            name="navColor"
                            type="radio"
                            value="white"
                          />
                          <label htmlFor="white" className="me-5">
                            白色
                          </label>
                          <Field
                            className="my-auto me-2"
                            id="black"
                            name="navColor"
                            type="radio"
                            value="black"
                          />
                          <label htmlFor="black" className="me-5">
                            黑色
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="required" style={{ width: '180px' }}>
                        形象圖
                      </label>

                      <div>
                        <div className={style.formGroup}>
                          <label
                            className="required"
                            style={{ width: '180px' }}
                          >
                            電腦版圖片
                          </label>
                          <div className="form-item">
                            <UploadFileBox
                              setFormFieldValue={props.setFieldValue}
                              formFieldName="banner_files"
                              currentFileFieldName="current_banner_files"
                              uplodModel="images"
                              displayFileWidth="1920"
                              displayFileHeight="1080"
                              displayFileSize="3MB"
                              initFiles={props.values.init_banner_files}
                            />
                            <ErrorMessage
                              name="current_banner_files"
                              component="span"
                              className="form-error"
                            />
                          </div>
                        </div>

                        <div className={style.formGroup}>
                          <label
                            className="required"
                            style={{ width: '180px' }}
                          >
                            手機版圖片
                          </label>
                          <div className="form-item">
                            <UploadFileBox
                              setFormFieldValue={props.setFieldValue}
                              formFieldName="banner_files_mobile"
                              currentFileFieldName="current_banner_files_mobile"
                              uplodModel="images"
                              displayFileWidth="800"
                              displayFileHeight="1200"
                              displayFileSize="3MB"
                              initFiles={props.values.init_banner_files_mobile}
                            />
                            <ErrorMessage
                              name="current_banner_files_mobile"
                              component="span"
                              className="form-error"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={style.formGroup}
                      style={{ alignItems: 'baseline' }}
                    >
                      <label style={{ width: '180px' }}>影片嵌入碼</label>
                      <div className="form-item">
                        <Field name="youtubeLink" className="form-control" />
                        <ul
                          className="mt-2 px-2"
                          style={{
                            listStyle: 'decimal',
                          }}
                        >
                          <li className="text-secondary">
                            <small className="text-secondary">
                              【Youtube】
                            </small>
                          </li>
                          <li className="text-secondary">
                            <small className="text-secondary">
                              點擊【分享】按鈕，出現Popup視窗
                            </small>
                          </li>
                          <li className="text-secondary">
                            <small className="text-secondary">
                              點擊【嵌入】按鈕，出現iframe的code
                            </small>
                          </li>
                          <li className="text-secondary">
                            <small className="text-secondary">
                              點擊【src】裡面的網址貼到上面的框即可
                            </small>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文圖片－電腦版
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="content_desktop_files"
                          currentFileFieldName="current_content_desktop_files"
                          uplodModel="images"
                          displayFileWidth="1200"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          initFiles={props.values.init_content_desktop_files}
                        />
                        <ErrorMessage
                          name="current_content_desktop_files"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文圖片－手機版
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="content_mobile_files"
                          currentFileFieldName="current_content_mobile_files"
                          uplodModel="images"
                          displayFileWidth="800"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          initFiles={props.values.init_content_mobile_files}
                        />
                        <ErrorMessage
                          name="current_content_mobile_files"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        狀態
                        <ErrorMessage
                          name="status"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="d-flex form-item">
                        <Field
                          className="my-auto me-2"
                          id="up"
                          name="status"
                          type="radio"
                          value="上架"
                        />
                        <label htmlFor="up" className="me-5">
                          上架
                        </label>
                        <Field
                          className="my-auto me-2"
                          id="down"
                          name="status"
                          type="radio"
                          value="下架"
                        />
                        <label htmlFor="down">下架</label>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className={`me-3 ${style.modal_button_cancel}`}
                        onClick={() => history.goBack()}
                      >
                        取消
                      </button>

                      <Button
                        type="submit"
                        className={style.modal_button_submit}
                        disabled={loading}
                      >
                        {loading && (
                          <span
                            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        <span className="btn-wrapper--label">儲存</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{
                  banner_files: [],
                  banner_files_mobile: [],
                  contentLeft: '',
                  contentLeft_en: '',
                  contentRight: '',
                  contentRight_en: '',
                  content_desktop_files: [],
                  content_mobile_files: [],
                  current_banner_files: [],
                  current_banner_files_mobile: [],
                  current_content_desktop_files: [],
                  current_content_mobile_files: [],
                  endTime: moment(new Date())
                    .add(7, 'days')
                    .format('YYYY/MM/DD HH:mm'),
                  init_banner_files: [],
                  init_banner_files_mobile: [],
                  init_content_desktop_files: [],
                  init_content_mobile_files: [],
                  introduction_en: '',
                  introdution: '',
                  navColor: '',
                  newsLink: [],
                  startTime: moment(new Date()).format('YYYY/MM/DD HH:mm'),
                  status: '',
                  subTitle: '',
                  subTitle_en: '',
                  title: '',
                  title_en: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={getValidationSchema}
              >
                {props => (
                  <Form className="form-list form-label-140">
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        新聞標題
                        <ErrorMessage
                          name="title"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field name="title" className="form-control" />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        新聞標題(英)
                        <ErrorMessage
                          name="title_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field name="title_en" className="form-control" />
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        副標
                        <ErrorMessage
                          name="subTitle"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="subTitle"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>網站位置：左上和右下有大逗號包著的文字</small>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        副標(英)
                        <ErrorMessage
                          name="subTitle_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="subTitle_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                        <small>網站位置：左上和右下有大逗號包著的文字</small>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內容摘要
                      </label>
                      <div className="form-item">
                        <Field
                          name="introdution"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>
                          新聞列表的文字說明，若沒填寫，網頁會顯示【內文－左】的文字
                        </small>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內容摘要(英)
                      </label>
                      <div className="form-item">
                        <Field
                          name="introduction_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />

                        <small>
                          新聞列表的文字說明，若沒填寫，網頁會顯示【內文－左】的文字
                        </small>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－左
                        <ErrorMessage
                          name="contentLeft"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentLeft"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－左(英)
                        <ErrorMessage
                          name="contentLeft_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentLeft_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－右
                        <ErrorMessage
                          name="contentRight"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentRight"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文－右(英)
                        <ErrorMessage
                          name="contentRight_en"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <Field
                          name="contentRight_en"
                          as="textarea"
                          rows="15"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label style={{ width: '180px' }}>上架日期區間 </label>
                      <div className="form-item">
                        <FormGroup>
                          <Label className="required">
                            上架時間
                            <ErrorMessage
                              name="startTime"
                              component="span"
                              className="form-error"
                            />
                          </Label>
                          <InputGroup>
                            <DatePicker
                              className="form-control"
                              name="startTime"
                              selected={
                                new Date(props.values.startTime || null)
                              }
                              onChange={date =>
                                props.setFieldValue(
                                  'startTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                )
                              }
                              selectsStart
                              dateFormat="yyyy/MM/dd"
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <Label>下架時間</Label>
                          <InputGroup>
                            <DatePicker
                              className="form-control"
                              name="endTime"
                              selected={new Date(props.values.endTime || null)}
                              onChange={date =>
                                props.setFieldValue(
                                  'endTime',
                                  moment(date).format('YYYY/MM/DD HH:mm'),
                                )
                              }
                              dateFormat="yyyy/MM/dd"
                              selectsEnd
                              minDate={new Date(props.values.startTime || null)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </div>
                    </div>

                    <div className={style.formGroup}>
                      <label style={{ width: '180px' }}>連結</label>
                      <div className="form-item">
                        <NewsLink
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="newsLink"
                          initContents={props.values.newsLink}
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        主選單圖標顏色
                        <ErrorMessage
                          name="navColor"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Field
                            className="my-auto me-2"
                            id="white"
                            name="navColor"
                            type="radio"
                            value="white"
                          />
                          <label htmlFor="white" className="me-5">
                            白色
                          </label>
                          <Field
                            className="my-auto me-2"
                            id="black"
                            name="navColor"
                            type="radio"
                            value="black"
                          />
                          <label htmlFor="black" className="me-5">
                            黑色
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="required" style={{ width: '180px' }}>
                        形象圖
                      </label>

                      <div>
                        <div className={style.formGroup}>
                          <label style={{ width: '180px' }}>電腦版圖片</label>
                          <div className="form-item">
                            <UploadFileBox
                              setFormFieldValue={props.setFieldValue}
                              formFieldName="banner_files"
                              currentFileFieldName="current_banner_files"
                              uplodModel="images"
                              displayFileWidth="1920"
                              displayFileHeight="1080"
                              displayFileSize="3MB"
                              initFiles={props.values.init_banner_files}
                            />
                            <ErrorMessage
                              name="current_banner_files"
                              component="span"
                              className="form-error"
                            />
                          </div>
                        </div>

                        <div className={style.formGroup}>
                          <label style={{ width: '180px' }}>手機版圖片</label>
                          <div className="form-item">
                            <UploadFileBox
                              setFormFieldValue={props.setFieldValue}
                              formFieldName="banner_files_mobile"
                              currentFileFieldName="current_banner_files_mobile"
                              uplodModel="images"
                              displayFileWidth="800"
                              displayFileHeight="1200"
                              displayFileSize="3MB"
                              initFiles={props.values.init_banner_files_mobile}
                            />
                            <ErrorMessage
                              name="current_banner_files_mobile"
                              component="span"
                              className="form-error"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文圖片－電腦版
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="content_desktop_files"
                          currentFileFieldName="current_content_desktop_files"
                          uplodModel="images"
                          displayFileWidth="1200"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          initFiles={props.values.init_content_desktop_files}
                        />
                        <ErrorMessage
                          name="current_content_desktop_files"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        內文圖片－手機版
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={props.setFieldValue}
                          formFieldName="content_mobile_files"
                          currentFileFieldName="current_content_mobile_files"
                          uplodModel="images"
                          displayFileWidth="800"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          initFiles={props.values.init_content_mobile_files}
                        />
                        <ErrorMessage
                          name="current_content_mobile_files"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </div>
                    <div className={style.formGroup}>
                      <label className="required" style={{ width: '180px' }}>
                        狀態
                        <ErrorMessage
                          name="status"
                          component="span"
                          className="form-error"
                        />
                      </label>
                      <div className="d-flex form-item">
                        <Field
                          className="my-auto me-2"
                          id="up"
                          name="status"
                          type="radio"
                          value="上架"
                        />
                        <label htmlFor="up" className="me-5">
                          上架
                        </label>
                        <Field
                          className="my-auto me-2"
                          id="down"
                          name="status"
                          type="radio"
                          value="下架"
                        />
                        <label htmlFor="down">下架</label>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className={`me-3 ${style.modal_button_cancel}`}
                        onClick={() => history.goBack()}
                      >
                        取消
                      </button>
                      <Button
                        type="submit"
                        className={style.modal_button_submit}
                        disabled={loading}
                      >
                        {loading && (
                          <span
                            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        <span className="btn-wrapper--label">儲存</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <SEOSettingsConfig
            info={{ sourceId: Number(id), type: 16, typeName: '新聞編輯頁' }}
          />
        </TabPane>
      </TabContent>
    </Layout>
  );
}
