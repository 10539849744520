import Layout from 'components/Layout';
import RcPagination from 'rc-pagination';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Table,
} from 'reactstrap';

import Loader from '../components/Loader';
import AddProductModal from '../components/discountCompaign/AddProductModal';
import {
  addCouponExcludeProduct,
  deleteCouponExcludeProduct,
  getCouponExcludeProductList,
} from '../redux/actions/data/discountCompaignActions';
import {
  getRepairBrandOption,
  getRepairModelOption,
} from '../redux/actions/data/repairActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';

const ProductNoneApplySettings = () => {
  const dispatch = useDispatch();
  const { repairBrandOptions, loading: repairBrandOptionsLoading } =
    useSelector(state => state.repairBrandOptions);
  const { repairModelOptions, loading: repairModelOptionsLoading } =
    useSelector(state => state.repairModelOptions);
  const [keyword, setKeyword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [brand, setBrand] = useState({ label: '選擇品牌', value: 0 });
  const [model, setModel] = useState({ label: '選擇型號', value: '' });
  const [page, setPage] = useState(1);
  const [productList, setProductList] = useState({
    current_page: 1,
    datas: [],
    page_size: 10,
    total_count: 1,
    total_page: 1,
  });
  const [selectProductList, setSelectProductList] = useState([]);

  const [loading, setLoading] = useState(false);

  const loadProductList = useCallback(
    async page => {
      setLoading(true);
      try {
        const productList = await getCouponExcludeProductList({
          brand: brand?.value || null,
          keyword,
          model: model?.value || null,
          page,
          page_size: 10,
        });
        setProductList(productList);
      } catch (e) {}
      setLoading(false);
    },
    [keyword, brand, model],
  );

  const deleteProductList = useCallback(
    async selectProductList => {
      setLoading(true);
      try {
        await deleteCouponExcludeProduct(
          selectProductList
            .map(p => p.products.filter(p => p).map(p => p.id))
            .reduce((s, o) => [...s, ...o], []),
        );
        MessageUtil.toastSuccess('移除成功');
        if (page === 1) {
          loadProductList(1);
        } else {
          setPage(1);
        }
      } catch (e) {
        MessageUtil.toastSuccess('移除失敗', e);
      }
      setLoading(false);
    },
    [page, loadProductList],
  );

  const addProductList = useCallback(
    async selectProductList => {
      setLoading(true);
      try {
        await addCouponExcludeProduct(selectProductList);
        MessageUtil.toastSuccess('新增成功');
        if (page === 1) {
          loadProductList(1);
        } else {
          setPage(1);
        }
      } catch (e) {
        MessageUtil.toastSuccess('新增失敗', e);
      }
      setLoading(false);
    },
    [page, loadProductList],
  );
  useEffect(() => {
    dispatch(getRepairBrandOption(0, 0));
  }, []);

  useEffect(() => {
    dispatch(getRepairModelOption(0, 0, brand?.value));
  }, [brand]);

  useEffect(() => {
    loadProductList(page);
  }, [page]);

  return (
    <>
      <AddProductModal
        isOpen={showModal}
        toggle={() => {
          setShowModal(false);
        }}
        type="新增排除商品 or 新增指定商品"
        onSubmit={data => {
          addProductList(data);
        }}
        existList={productList.datas}
        area={0}
      />
      <Layout
        pageTitle="設定不適用任何折價券商品"
        items={[
          { isActive: true, page_name: '商品管理' },
          { isActive: false, page_name: '設定不適用任何折價券商品' },
        ]}
      >
        <Card className={style.search_card}>
          <CardHeader
            className={style.card_header}
            style={{ background: 'white' }}
          >
            搜尋條件
          </CardHeader>
          <CardBody className={style.card_body}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  name="keyword"
                  placeholder="商品料號、商品名稱"
                  onChange={e => {
                    setKeyword(e.target.value);
                  }}
                  value={keyword}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">品牌</label>
                <Select
                  isLoading={repairBrandOptionsLoading}
                  isDisabled={repairBrandOptionsLoading}
                  className={`ml-2 ${style['form-select']}`}
                  options={
                    repairBrandOptions
                      ? [...repairBrandOptions]
                      : [{ label: '選擇品牌', value: 0 }]
                  }
                  value={brand}
                  onChange={option => {
                    setBrand(option);
                    setModel({ label: '選擇型號', value: '' });
                  }}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  className="col-form-label mr-2"
                  style={{ marginRight: '1rem' }}
                >
                  型號
                </label>
                <Select
                  isLoading={repairModelOptionsLoading}
                  isDisabled={repairModelOptionsLoading}
                  className={`ml-2 ${style['form-select']}`}
                  options={
                    repairModelOptions
                      ? [...repairModelOptions]
                      : [{ label: '選擇型號', value: '' }]
                  }
                  value={model}
                  onChange={option => {
                    setModel(option);
                  }}
                />
              </div>
            </div>
          </CardBody>
          <CardFooter className="text-end border-0">
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                setBrand({ label: '選擇品牌', value: '' });
                setModel({ label: '選擇型號', value: '' });
                setKeyword('');
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => {
                if (page === 1) {
                  loadProductList(1);
                } else {
                  setPage(1);
                }
              }}
            >
              查詢
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardBody>
            <div className="mb-2">
              <Button
                color="danger"
                className="mx-1"
                onClick={() => {
                  MessageUtil.submitConfirm({
                    doneFun: () => {
                      deleteProductList(selectProductList);
                    },
                    title: '確認是否刪除',
                  });
                }}
              >
                移除不適用任何折價券商品
              </Button>
              <Button
                color="success"
                className="mx-1"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                新增不適用任何折價券商品
              </Button>
            </div>
            <div className="table-responsive mb-5">
              {loading && <Loader />}
              {!loading && (
                <Table hover bordered striped>
                  <thead className={`thead-dark ${style.table_head}`}>
                    <tr>
                      <th colSpan="100%" className="text-start">
                        <label
                          className="d-inline-flex align-items-center"
                          htmlFor="selectAll"
                        >
                          <input
                            id="selectAll"
                            type="checkbox"
                            className={style['radio-btn']}
                            checked={
                              JSON.stringify(selectProductList.sort()) ===
                              JSON.stringify(productList.datas.sort())
                            }
                            onChange={e => {
                              if (
                                JSON.stringify(selectProductList.sort()) ===
                                JSON.stringify(productList.datas.sort())
                              ) {
                                setSelectProductList([]);
                              } else {
                                setSelectProductList(productList.datas);
                              }
                            }}
                          />
                          <span>全選</span>
                        </label>
                      </th>
                    </tr>
                    <tr>
                      <th className="text-nowrap">選擇</th>
                      <th className="text-nowrap">商品名稱</th>
                      <th className="text-nowrap">選擇料號</th>
                      <th className="text-nowrap">商品料號</th>
                      <th className="text-nowrap">規格</th>
                      <th className="text-nowrap">售價</th>
                      <th className="text-nowrap">商品交期</th>
                      <th className="text-nowrap">商品是否要上架</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList.datas.map(product => {
                      return product.products.map((p, index) => {
                        return (
                          <tr
                            key={
                              JSON.stringify(product) +
                              JSON.stringify(p) +
                              index
                            }
                          >
                            {index === 0 && (
                              <td rowSpan={product?.products?.length}>
                                <label
                                  className="d-inline-flex align-items-center"
                                  htmlFor={JSON.stringify(product)}
                                >
                                  <input
                                    id={JSON.stringify(product)}
                                    type="checkbox"
                                    className={style['radio-btn']}
                                    checked={
                                      JSON.stringify(
                                        selectProductList.filter(
                                          p => p.mid === product.mid,
                                        )[0],
                                      ) === JSON.stringify(product)
                                    }
                                    onChange={e => {
                                      if (
                                        JSON.stringify(
                                          selectProductList.filter(
                                            p => p.mid === product.mid,
                                          )[0],
                                        ) === JSON.stringify(product)
                                      ) {
                                        setSelectProductList(
                                          selectProductList.filter(
                                            p => p.mid !== product.mid,
                                          ),
                                        );
                                      } else {
                                        selectProductList.push({ ...product });
                                        setSelectProductList([
                                          ...selectProductList,
                                        ]);
                                      }
                                    }}
                                  />
                                </label>
                              </td>
                            )}
                            {index === 0 && (
                              <td
                                rowSpan={product?.products?.length}
                                className="text-nowrap"
                              >
                                {product.modelName}
                              </td>
                            )}
                            <td className="text-center">
                              <label
                                className="d-inline-flex align-items-center"
                                htmlFor={
                                  JSON.stringify(product) +
                                  JSON.stringify(p) +
                                  index
                                }
                              >
                                <input
                                  id={
                                    JSON.stringify(product) +
                                    JSON.stringify(p) +
                                    index
                                  }
                                  type="checkbox"
                                  className={style['radio-btn']}
                                  checked={
                                    JSON.stringify(
                                      selectProductList.filter(
                                        p => p.mid === product.mid,
                                      )[0]?.products[index],
                                    ) === JSON.stringify(p)
                                  }
                                  onChange={e => {
                                    if (
                                      JSON.stringify(
                                        selectProductList.filter(
                                          p => p.mid === product.mid,
                                        )[0]?.products[index],
                                      ) === JSON.stringify(p)
                                    ) {
                                      selectProductList.filter(
                                        p => p.mid === product.mid,
                                      )[0].products[index] = {};
                                      setSelectProductList([
                                        ...selectProductList,
                                      ]);
                                    } else {
                                      if (
                                        selectProductList.filter(
                                          p => p.mid === product.mid,
                                        )[0]
                                      ) {
                                        selectProductList.filter(
                                          p => p.mid === product.mid,
                                        )[0].products[index] = { ...p };
                                        setSelectProductList([
                                          ...selectProductList,
                                        ]);
                                      } else {
                                        const temp = { ...product };
                                        temp.products = Array.apply(
                                          null,
                                          Array(product.products.length),
                                        );
                                        temp.products[index] = { ...p };
                                        selectProductList.push(temp);
                                        setSelectProductList([
                                          ...selectProductList,
                                        ]);
                                      }
                                      selectProductList.filter(
                                        p => p.mid === product.mid,
                                      )[0].products[index] = { ...p };
                                      setSelectProductList([
                                        ...selectProductList,
                                      ]);
                                    }
                                  }}
                                />
                              </label>
                            </td>
                            <td className="text-center text-nowrap">
                              {p.productcode || '--'}
                            </td>
                            <td className="text-center text-nowrap">
                              {p.color || '--'} / {p.size || '--'}
                            </td>
                            <td className="text-center text-nowrap">
                              ${Number(p.sellprice).toLocaleString()}
                            </td>
                            <td className="text-center text-nowrap">
                              {p.productcode || '--'}
                            </td>
                            <td className="text-center text-nowrap">
                              {p.isSell ? '是' : '否'}
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </Table>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={page}
                defaultPageSize={productList?.page_size}
                total={productList?.total_count}
                onChange={setPage}
              />
            </div>
          </CardBody>
        </Card>
      </Layout>
    </>
  );
};

export default ProductNoneApplySettings;
