import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  createRepairRegisterNotify, deleteRepairRegisterNotify,
  getRepairRegisterNotifyList,
  updateRepairRegisterNotify
} from '../redux/actions/data/repairActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';

const MaintenanceNotifyModal = ({ open, toggle, onConfirm, data }) => {
  const { loading } = useSelector(state => state.warrantyRegisterNotify);
  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: '',
      content_en: '',
      createTime: '',
      creator: 1,
      endTime: '',
      id: null,
      startTime: '',
      state: '停用',
      status: 1,
      title: '',
      title_en: '',
      updateTime: '',
      updater: 1,
    },
    async onSubmit(formModel) {
      onConfirm(formModel);
    },
    validateOnChange:true,
    validateOnMount:true,
    validationSchema: Yup.object().shape({
      content: Yup.string().required('請輸入內容(中文)'),
      content_en: Yup.string().required('請輸入內容(英文)'),
      endTime: Yup.string().required('請選擇結束時間'),
      startTime: Yup.date().required('請選擇開始時間'),
      state: Yup.string()
        .oneOf(['啟用', '停用'], '請選擇啟用 or 停用')
        .required('請選擇啟用 or 停用'),
      title: Yup.string().required('請輸入標題'),
      title_en: Yup.string().required('請輸入標題(英文)'),
    }),
  });

  useEffect(() => {
    if (data?.id) {
      setValues(data);
    } else {
      handleReset();
    }
  }, [data]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        新增分類商品
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        <FormGroup className="d-flex mb-3 align-items-center">
          <Label for="title" className="required" style={{ width: '150px' }}>
            標題
          </Label>
          <div className="form-item w-100">
            <Input
              id="title"
              name="title"
              type="text"
              value={values.title}
              onChange={handleChange}
              invalid={!!errors.title}
            />
            {errors.title && <div className="form-error">{errors.title}</div>}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label for="title_en" className="required" style={{ width: '150px' }}>
            標題(英文)
          </Label>{' '}
          <div className="form-item w-100">
            <Input
              id="title_en"
              name="title_en"
              type="text"
              value={values.title_en}
              onChange={handleChange}
              invalid={!!errors.title_en}
            />
            {errors.title_en && (
              <div className="form-error">{errors.title_en}</div>
            )}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label for="content" className="required" style={{ width: '150px' }}>
            內容(中文)
          </Label>
          <div className="form-item w-100">
            <Input
              id="content"
              name="content"
              type="textarea"
              value={values.content}
              onChange={handleChange}
              invalid={!!errors.content}
            />
            {errors.content && (
              <div className="form-error">{errors.content}</div>
            )}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label
            for="content_en"
            className="required"
            style={{ width: '150px' }}
          >
            內容(英文)
          </Label>
          <div className="form-item w-100">
            <Input
              id="content_en"
              name="content_en"
              type="textarea"
              value={values.content_en}
              onChange={handleChange}
              invalid={!!errors.content_en}
            />
            {errors.content_en && (
              <div className="form-error">{errors.content_en}</div>
            )}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label
            for="startTime"
            className="required"
            style={{ width: '150px' }}
          >
            開始時間
          </Label>
          <div className="form-item w-100">
            <DatePicker
              wrapperClassName="w-100"
              id="startTime"
              name="startTime"
              selected={values.startTime ? new Date(values.startTime) : null}
              onChange={date =>
                setFieldValue(
                  'startTime',
                  moment(date).format('YYYY/MM/DD HH:mm:ss'),
                )
              }
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="時間"
              className={`form-control ${errors.startTime ? 'is-invalid' : ''}`}
            />
            {errors.startTime && (
              <div className="form-error">{errors.startTime}</div>
            )}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label for="endTime" className="required" style={{ width: '150px' }}>
            結束時間
          </Label>
          <div className="form-item w-100">
            <DatePicker
              wrapperClassName="w-100"
              id="endTime"
              name="endTime"
              selected={values.endTime ? new Date(values.endTime) : null}
              onChange={date =>
                setFieldValue(
                  'endTime',
                  moment(date).format('YYYY/MM/DD HH:mm:ss'),
                )
              }
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="時間"
              className={`form-control ${errors.endTime ? 'is-invalid' : ''}`}
            />
            {errors.endTime && (
              <div className="form-error">{errors.endTime}</div>
            )}
          </div>
        </FormGroup>

        <FormGroup className="d-flex mb-3 align-items-center">
          <Label for="state" className="required" style={{ width: '150px' }}>
            狀態
          </Label>
          <div className="form-item w-100">
            <Input
              id="state"
              name="state"
              type="select"
              value={values.state}
              onChange={e => {
                setFieldValue('state', e.target.value);
                setFieldValue('status', e.target.value === '啟用' ? 1 : 0);
              }}
              invalid={!!errors.state}
            >
              <option value="啟用">啟用</option>
              <option value="停用">停用</option>
            </Input>
            {errors.state && <div className="form-error">{errors.state}</div>}
          </div>
        </FormGroup>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          disabled={Object.keys(errors).length > 0 || loading}
          className={style.modal_button_submit}
        >
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const MaintenanceAnnouncementNotice = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(
    state => state.warrantyRegisterNotifyList,
  );
  const { loading: updateLoading, error } = useSelector(
    state => state.warrantyRegisterNotify,
  );
  const isDelete = useRef(false);
  const isUpdate = useRef(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleDelete = data => {
    MessageUtil.delConfirm(() => {
      isDelete.current = true;
      dispatch(deleteRepairRegisterNotify(data));
    });
  };
  const handleUpdate = data => {
    isUpdate.current = true;
    if(data?.id){
      dispatch(updateRepairRegisterNotify(data));
    }else{
      dispatch(createRepairRegisterNotify(data));
    }
  };

  useEffect(() => {
    dispatch(getRepairRegisterNotifyList());
  }, []);

  useEffect(() => {
    if (isDelete.current) {
      if (!updateLoading) {
        if (error) {
          MessageUtil.alertWanring('刪除失敗' ,error);
        } else {
          MessageUtil.alertSuccess('刪除成功');
        }
        isDelete.current = false;
      }
    }
    if( isUpdate.current ){
      if (!updateLoading) {
        if (error) {
          MessageUtil.alertWanring(currentItem?.id ? '更新失敗' : '新增失敗' , error);
        } else {
          MessageUtil.alertSuccess(currentItem?.id ? '更新成功' : '新增成功');
          setCurrentItem(null);
        }
        isUpdate.current= false
      }
    }
    dispatch(getRepairRegisterNotifyList());
  }, [updateLoading , error]);

  return (
    <Layout
      pageTitle="公告通知"
      items={[
        {
          isActive: false,
          page_name: '產品保固註冊',
          to: '/WarrantyAnnouncementNotice',
        },
        { isActive: true, page_name: '公告通知' },
      ]}
    >
      <MaintenanceNotifyModal
        data={currentItem}
        toggle={() => {
          setCurrentItem(null);
        }}
        open={currentItem !== null}
        onConfirm={handleUpdate}
      />
      <Card className={style.card}>
        <div className="d-flex justify-content-between mb-3">
          <Button
            color="success"
            onClick={() => {
              setCurrentItem({});
            }}
          >
            新增公告
          </Button>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
              {!loading && list?.length ? list.length : '-'}
            </span>
            筆
          </div>
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">標題</th>
                <th scope="col">內容</th>
                <th scope="col">狀態</th>
                <th scope="col">開始時間/結束時間</th>
                <th scope="col">功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {list?.length > 0 ? (
                  list?.map(data => (
                    <tr key={JSON.stringify(data)}>
                      <td>{data.title}</td>
                      <td>{data.content}</td>
                      <td align="center">{data.state}</td>
                      <td>
                        <div className="text-center">
                          {moment(data.startTime).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                        <div className="text-center">
                          {moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')}
                        </div>
                      </td>
                      <td className="text-center">
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => {
                            setCurrentItem(data);
                          }}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleDelete(data)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </Card>
    </Layout>
  );
};
export default MaintenanceAnnouncementNotice;
