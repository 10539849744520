import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import request from 'utils/useAxios';

import { baseURL, prefix_api_path } from '../../redux/actions/data/api_config';
import style from '../../styles/layout.module.css';

const ProductCategorySelection = ({ values, setFieldValue, errors, index }) => {
  const dispatch = useDispatch();
  const { options: cat1Options } = useSelector(
    state => state.getProductCategoryOptions,
  );
  const [cat2Options, setCat2Options] = useState([]);

  useEffect(() => {
    const res = async () => {
      const { result } = await request({
        method: 'get',
        url: `${baseURL}${prefix_api_path}/Backend/Product/category/cat2s?parentId=${values.categories[index].cat1.value}`,
      });
      setCat2Options(result);
    };
    res();
  }, [values.categories[index].cat1.value]);

  useEffect(() => {
    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (cat2Options.length === 0) {
      setFieldValue(`categories[${index}].cat1`, {
        ...values.categories[index].cat1,
        hasCat2: false,
      });
    } else {
      setFieldValue(`categories[${index}].cat1`, {
        ...values.categories[index].cat1,
        hasCat2: true,
      });
    }
  }, [cat2Options]);

  return (
    <>
      <div className="d-flex flex-wrap" key={`${index}-categories`}>
        <div className="p-1 flex-grow-1" style={{ minWidth: '120px' }}>
          <Select
            className={style['form-select']}
            value={values.categories[index].cat1}
            options={cat1Options}
            onChange={option => {
              setFieldValue(`categories[${index}].cat1`, option);
              setFieldValue(`categories[${index}].cat2`, {
                label: '請選擇',
                value: -1,
              });
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                minWidth: '120px',
              }),
            }}
            classNames={{
              control: state => 'w-100 ',
            }}
          />
          {errors.categories &&
            typeof errors.categories === 'object' &&
            errors.categories[index] &&
            errors.categories[index].cat1 && (
              <div className="form-error">
                {errors.categories[index].cat1.value}
              </div>
            )}
        </div>
        {cat2Options.length > 0 && (
          <div className="p-1 flex-grow-1" style={{ minWidth: '100px' }}>
            <Select
              className={style['form-select']}
              value={values.categories[index].cat2}
              options={cat2Options}
              onChange={option => {
                setFieldValue(`categories[${index}].cat2`, option);
              }}
              classNames={{
                control: state => 'w-100',
              }}
            />
            {errors.categories &&
              typeof errors.categories === 'object' &&
              errors.categories[index] &&
              errors.categories[index].cat2 && (
                <div className="form-error">
                  {errors.categories[index].cat2.value}
                </div>
              )}
          </div>
        )}
        <div className="flex-grow-0 p-2" style={{ width: '90px' }}>
          {index !== 0 && (
            <Button
              color="danger"
              outline
              size="sm"
              className="flex-grow-0"
              style={{ width: '90px' }}
              onClick={() => {
                setFieldValue(
                  'categories',
                  values.categories.filter(
                    cate => cate?.cat1?.value !== values.categories[index]?.cat1?.value,
                  ),
                );
              }}
            >
              刪除
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductCategorySelection;
