import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { Alert, Badge, Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from '../../styles/layout.module.css';
import request from '../../utils/useAxios';


const SortRecruitmentModel = ({
  openModal = false,
  setOpenModel = () => {},
}) => {
  const [recruitments, setRecruitments] = useState([]);
  const sortRecruitments = useRef([]);

  const getAllRecruitmentList = useCallback(async () => {
    const res = await request({
      data: {
        order: '',
        order_by: '',
        page: 0,
        page_size: 0,
        type: 0,
      },
      method: 'post',
      url: '/Backend/Cafe_Recruitment/list',
    });
    if (res.code === 200) {
      return (res.result.datas || []).map((data, index) => {
        return {
          index: index + 1,
          ...data,
        };
      });
    } else {
      return [];
    }
  }, []);

  const sortRecruitmentList = useCallback(async recruitments => {
    const res = await request({
      data: recruitments.map((detail, index) => {
        return {
          id: detail.id,
          sort: index,
        };
      }),
      method: 'post',
      url: '/Backend/Cafe_Recruitment/sort',
    });
    if (res.code === 200) {
      toast.success('修改完成');
      return res;
    } else {
      toast.success('修改失敗');
      throw res;
    }
  }, []);

  const handleOnDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const [remove] = sortRecruitments.current.splice(source.index, 1);
    sortRecruitments.current.splice(destination.index, 0, remove);
    setRecruitments(sortRecruitments.current);
  };

  useEffect(() => {
    if (openModal) {
      getAllRecruitmentList().then(recruitments => {
        setRecruitments(recruitments);
        sortRecruitments.current = [...recruitments];
      });
    }
  }, [openModal, getAllRecruitmentList, setRecruitments]);

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          sortRecruitmentList(recruitments).then(() => {
            setOpenModel(false);
          });
        }}
        backdrop="static"
      >
        <ModalHeader
          className={style.modal_head}
          toggle={() => {
            sortRecruitmentList(recruitments).then(() => {
              setOpenModel(false);
            });
          }}
        >
          商品選單排序
        </ModalHeader>
        <ModalBody>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {recruitments.map((detail, index) => (
                    <Draggable
                      key={detail.id}
                      draggableId={`${detail.id}`}
                      index={index}
                    >
                      {provided => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Alert color="warning" className="d-flex p-0 w-100">
                            <Badge className="d-flex align-items-center">
                              {detail.index}
                            </Badge>
                            <div className="p-3">{detail.name}</div>
                          </Alert>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default memo(SortRecruitmentModel);
