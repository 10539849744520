import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_SERVICES_DETAIL_ADD_FAIL,
  UUCAFE_SERVICES_DETAIL_ADD_REQUEST,
  UUCAFE_SERVICES_DETAIL_ADD_SUCCESS,
  UUCAFE_SERVICES_DETAIL_FAIL,
  UUCAFE_SERVICES_DETAIL_REQUEST,
  UUCAFE_SERVICES_DETAIL_SUCCESS,
  UUCAFE_SERVICES_DETAIL_UPDATE_FAIL,
  UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST,
  UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SERVICES_LIST_FAIL,
  UUCAFE_SERVICES_LIST_REQUEST,
  UUCAFE_SERVICES_LIST_SUCCESS,
} from '../../constants/data/uuCafeServicesConstants';

/*
* @Params
* {
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string",
  "keyword": "string"
  }
*
*/
export const getCafeServiceList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SERVICES_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SERVICES_LIST_REQUEST,
      successConstant: UUCAFE_SERVICES_LIST_SUCCESS,
      url: '/Backend/Cafe_Service/list',
    });
  };
};

export const getCafeServiceDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_SERVICES_DETAIL_FAIL,
      method: 'get',
      requestConstant: UUCAFE_SERVICES_DETAIL_REQUEST,
      successConstant: UUCAFE_SERVICES_DETAIL_SUCCESS,
      url: `/Backend/Cafe_Service/service/${params.id}`,
    });
  };
};

/*
* @Params
id integer($int32)
name string
name_en string
*/
export const addCafeServiceDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SERVICES_DETAIL_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SERVICES_DETAIL_ADD_REQUEST,
      successConstant: UUCAFE_SERVICES_DETAIL_ADD_SUCCESS,
      url: '/Backend/Cafe_Service/service/add',
    });
  };
};

export const updateCafeServiceDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SERVICES_DETAIL_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST,
      successConstant: UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS,
      url: '/Backend/Cafe_Service/service/update',
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
*/
export const deleteCafeServiceDetail = async params => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_Service/service/delete',
  });
};
