import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Layout from '../components/Layout';
import history from '../utils/createHistory';

const Home = () => {
  const { userInfo } = useSelector(state => state.userLogin);
  useEffect(() => {
    if (!userInfo) {
      history.push('/Login');
    }
  }, [userInfo]);
  return <Layout title="Home"></Layout>;
};

export default Home;
