import { useFormik } from 'formik';
import React, { useEffect, memo } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Form, FormGroup, TabPane } from 'reactstrap';

import {
  getGroupModelDetail,
  setGroupModelOrder,
} from '../../redux/actions/data/groupModelActions';
import { GROUP_MODEL_SET_ORDER_RESET } from '../../redux/constants/data/groupModelConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import { BlockSpinner } from '../Spinner';

import LabelC from './LabelC';

const TabOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, groupModelInfo } = useSelector(
    state => state.groupModelDetail,
  );
  const { values, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      logisticsWays: (groupModelInfo?.orderSetting?.logisticsWays || []).map(logisticsWays=>{
        if(logisticsWays.name.includes('宅配')){
          logisticsWays.isEabled = true;
          logisticsWays.disabled = true;
        }
        return logisticsWays
      }),
      payWays: (groupModelInfo?.orderSetting?.payWays || []).map(payWay=>{
        if(payWay.name.includes('信用卡')){
          payWay.isEabled = true;
          payWay.disabled = true;
        }
        return payWay
      }),    },
    onSubmit(formModel) {
      dispatch(setGroupModelOrder({ data: formModel, mid: id || 0 }));
    },
  });

  const {
    loading: submitLoading,
    groupModelId,
    success,
    error,
  } = useSelector(state => state.setGroupModelOrder);
  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功', '');
    success && !id && history.push(`/GroupModelDetail/${groupModelId}`);
    success && id && dispatch(getGroupModelDetail(id));
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_SET_ORDER_RESET });
    };
  }, [success, error]);
  return (
    <TabPane tabId={4}>
      <Card className={style.card}>
        <Alert color="secondary">
          <div>
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
              {' '}
              付款方式、配送方式的設定在
              <Link to="/CheckoutPayment">【購物設定&gt;付款方式】</Link>
              <Link to="/CheckoutDelivery">【購物設定&gt;配送方式】</Link>
              ，只要未停用的項目，就會在這頁出現
            </span>
          </div>
          <div className="d-flex gap-1">
            <AiOutlineInfoCircle />{' '}
            <span className={style.fs_14}>
              {' '}
              停用【付款/取配送】方式，將會連動相關功能：
              <br />
              例：停用【便利商店取貨付款】，配送方式的【7-11取貨、全家取貨】也會停用
            </span>
          </div>
        </Alert>
        <Form className="position-relative">
          {(loading || submitLoading) && <BlockSpinner />}
          <FormGroup row>
            <LabelC text="付款方式" />
            <Col>
              {values?.payWays?.map((i, index) => (
                <FormGroup key={index}>
                  <label className="d-flex gap-2 mb-1">
                    <input
                      type="checkbox"
                      name={`payWays[${index}].isEabled`}
                      checked={values.payWays[index].isEabled}
                      disabled={values.payWays[index].disabled}
                      onChange={handleChange}
                      className={style.maintain_list_checkbox}
                    />
                    {i.name}
                  </label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <LabelC text="配送方式" />
            <Col>
              {values?.logisticsWays?.map((i, index) => (
                <FormGroup key={index}>
                  <label className="d-flex gap-2 mb-1">
                    <input
                      type="checkbox"
                      name={`logisticsWays[${index}].isEabled`}
                      checked={values.logisticsWays[index].isEabled}
                      onChange={handleChange}
                      disabled={values.logisticsWays[index].disabled}
                      className={style.maintain_list_checkbox}
                    />
                    {i.name}
                  </label>
                </FormGroup>
              ))}
            </Col>
          </FormGroup>
        </Form>
        <p className={`${style.fs_14} mb-0`}>
          異動時間：
          {groupModelInfo.orderSetting?.updateDate}{' '}
          {groupModelInfo.orderSetting?.updateTime}{' '}
          {groupModelInfo.orderSetting?.updater}
        </p>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            className={style.modal_button_submit}
            disabled={loading || submitLoading}
            onClick={handleSubmit}
          >
            {(loading || submitLoading) && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </TabPane>
  );
};

export default memo(TabOrder);
