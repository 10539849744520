import RcPagination from 'rc-pagination';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  Card,
  Button,
  Table,
  CardHeader,
  CardFooter,
  CardBody,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Loader from '../../components/Loader';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import request from '../../utils/useAxios';

import ProductModel from './SubjectModel';

const SubjectManage = () => {
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [list, setList] = useState(null);
  const [totalCount, setTotalCount] = useState(1);

  const handlePageChange = useCallback(
    async (value, keyword) => {
      setPage(value);
      setList(null);
      const res = await request({
        data: {
          keyword,
          order: '',
          order_by: '',
          page: value || 1,
          page_size: 10,
          type: 0,
        },
        method: 'post',
        url: '/Backend/Cafe_ContactUsSubjectCat/list',
      });
      if (res.code === 200) {
        setTotalCount(res.result.total_count);
        setList(res.result.datas);
        return res.result || {};
      } else {
        setList([]);
        return {};
      }
    },
    [setPage, setTotalCount],
  );

  const handleDeleteDetail = useCallback(
    async detail => {
      setList(null);
      const res = await request({
        method: 'get',
        params: {
          id: detail.id,
        },
        url: '/Backend/Cafe_ContactUsSubjectCat/subjectCat/delete',
      });
      if (!res.code) {
        MessageUtil.toastSuccess('刪除成功!');
      } else {
        MessageUtil.alertWanring('刪除失敗!', res);
      }
      handlePageChange(1, keyword);
    },
    [handlePageChange, setList],
  );

  useEffect(() => {
    handlePageChange(1, keyword);
  }, []);

  const [modalShow, setModalShow] = useState(false);

  const detail = useRef({
    content: '',
    content_en: '',
    id: 0,
  });

  return (
    <>
      <ProductModel
        detail={detail.current}
        openModal={modalShow}
        setOpenModel={(show, isReload) => {
          if (isReload) {
            handlePageChange(1, keyword);
          }
          setModalShow(show);
        }}
      />
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">關鍵字:</label>
              <Input
                className="flex-1"
                placeholder="輸入關鍵字"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <Button
            color="success"
            onClick={() => {
              detail.current = {
                content: '',
                content_en: '',
                id: 0,
              };
              setModalShow(true);
            }}
          >
            新增
          </Button>
        </div>
        <p className="small text-end mb-0">共 {totalCount} 筆</p>
        <div className={style.table_overflow} style={{ minHeight: '200px' }}>
          {list && (
            <Table hover bordered striped>
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th nowrap="nowrap">主旨名稱</th>
                  <th nowrap="nowrap">主旨名稱(英文)</th>
                  <th style={{ minWidth: '120px' }}>異動日期</th>
                  <th>功能</th>
                </tr>
              </thead>
              <tbody>
                {list.map((data, index) => (
                  <tr key={index}>
                    <td nowrap="nowrap">{data.content}</td>
                    <td nowrap="nowrap">{data.content_en}</td>
                    <td>
                      <div className="text-nowrap">{data.updateDateTime}</div>
                      <div>{data.updater}</div>
                    </td>
                    <td nowrap="nowrap" className="text-center">
                      <Button
                        className="m-1"
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => {
                          detail.current = data;
                          setModalShow(true);
                        }}
                      >
                        編輯
                      </Button>
                      <Button
                        className="m-1"
                        size="sm"
                        outline
                        color="danger"
                        onClick={() => {
                          MessageUtil.submitConfirm({
                            doneFun: () => {
                              handleDeleteDetail(data);
                            },
                            title: '確認是否刪除',
                          });
                        }}
                      >
                        刪除
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {!list && <Loader />}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={page}
            pageSize={10}
            total={totalCount || 1}
            onChange={value => {
              handlePageChange(value, keyword);
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default memo(SubjectManage);
