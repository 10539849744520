export const UUCAFE_MENU_SETTINGS_REQUEST = 'UUCAFE_MENU_SETTINGS_REQUEST';
export const UUCAFE_MENU_SETTINGS_SUCCESS = 'UUCAFE_MENU_SETTINGS_SUCCESS';
export const UUCAFE_MENU_SETTINGS_FAIL = 'UUCAFE_MENU_SETTINGS_FAIL';

export const UUCAFE_MENU_SETTINGS_UPDATE_REQUEST =
  'UUCAFE_MENU_SETTINGS_UPDATE_REQUEST';
export const UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS =
  'UUCAFE_MENU_SETTINGS_UPDATE_SUCCESS';
export const UUCAFE_MENU_SETTINGS_UPDATE_FAIL =
  'UUCAFE_MENU_SETTINGS_UPDATE_FAIL';
