import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import request from 'utils/useAxios';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  getModelOptions,
  getProductBrandCategory,
} from '../redux/actions/data/productActions';
import {
  addProductToAlsoLike,
  deleteAlsoLikeProduct,
  getAlsoLikeBrandProductList,
  getBrandAlsoLikeProducts,
  setAlsoLikeProductTop,
} from '../redux/actions/data/salesActions';
import {
  ALSO_LIKE_PRODUCT_ADD_RESET,
  ALSO_LIKE_PRODUCT_DELETE_RESET,
} from '../redux/constants/data/salesConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function YouMayAlsoLikeSetting(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const { id } = useParams();
  const query = useQuery();

  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');

  const { loading, productListInfo, error } = useSelector(
    state => state.alsoLikeBrandProductList,
  );
  const { alsoLikeProductListInfo } = useSelector(
    state => state.alsoLikeProductList,
  );
  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const {
    brandCategoryInfo: brandCategoryOptions,
    loading: brandCategoryOptionsLoading,
  } = useSelector(state => state.productBrandCategory);
  const {
    loading: deleteLoading,
    success: deleteSuccess,
    error: deleteError,
  } = useSelector(state => state.alsoLikeProductDelete);
  const { loading: setLoading, success: setSuccess } = useSelector(
    state => state.alsoLikeSetTop,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  const [sortModal, setSortModal] = useState(false);

  const [sortProductList, setSortProductList] = useState([]);

  const getTopProductListParams = {
    brandId: id,
    isTop: true,
    page_size: -1,
  };

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({
  //       pathname: '/Login',
  //       state: `/YouMayAlsoLikeSetting/${id}`,
  //     });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({
  //         pathname: '/Login',
  //         state: `/YouMayAlsoLikeSetting/${id}`,
  //       });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  useEffect(() => {
    dispatch(getProductBrandCategory(id));
    dispatch(getBrandAlsoLikeProducts({ brandId: id, page: current_page }));
  }, []);

  useEffect(() => {
    if (productListInfo) {
      dispatch(getModelOptions(productListInfo?.brandName));
      if (JSON.stringify(productListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: productListInfo });
      }
    }
  }, [productListInfo]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword) {
        setKeyword(queryKeyword);

        //儲存搜尋及頁數
        dispatch(
          getAlsoLikeBrandProductList({
            brandId: id,
            keyword: queryKeyword,
            page: queryPage,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getAlsoLikeBrandProductList({
            brandId: id,
            page: queryPage,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setKeyword('');
      setCurrentPage(1);

      //初始搜尋
      dispatch(getAlsoLikeBrandProductList({ brandId: id, page: 1 }));
    }
  }, [location.search]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');

      if (productListInfo.datas.length !== 1) {
        dispatch(
          getAlsoLikeBrandProductList({ brandId: id, page: current_page }),
        );
      } else {
        dispatch(
          getAlsoLikeBrandProductList({ brandId: id, page: current_page - 1 }),
        );
        history.push(
          `${location.pathname}?keyword=${keyword}&page=${current_page - 1}`,
        );
        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError) {
      MessageUtil.alertWanring('資料錯誤', '');
    }

    return () => {
      dispatch({ type: ALSO_LIKE_PRODUCT_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (sortModal) {
      getAlsoLikeTopProductList(getTopProductListParams);
    }
  }, [sortModal]);

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getAlsoLikeBrandProductList({
        brandId: id,
        keyword: keyword,
        page: 1,
      }),
    );
    history.push(`${location.pathname}?keyword=${keyword}&page=${1}`);
  };
  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    dispatch(getAlsoLikeBrandProductList({ brandId: id, page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getAlsoLikeBrandProductList({
        brandId: id,
        keyword: keyword,
        page,
      }),
    );
    if (keyword) {
      history.push(`${location.pathname}?keyword=${keyword}&page=${page}`);
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };
  const callDel = productId => {
    MessageUtil.delConfirm(() => {
      dispatch(
        deleteAlsoLikeProduct({
          brandId: id,
          productIds: [productId],
        }),
      );
    });
  };

  //**********modal part**********//
  const [modal, setModal] = useState(false);
  const [modal_current_page, setModalCurrentPage] = useState(1);
  const [modal_keyword, setModalKeyword] = useState('');
  const [modal_model, setModalModel] = useState({
    label: '請選擇型號',
    value: '',
  });
  const [brandCateId, setBrandCateId] = useState({
    label: '請選擇品牌類別',
    value: '0',
  });
  const [existProductIds, setExistProductIds] = useState([]);

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.alsoLikeAddProduct);

  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      MessageUtil.toastSuccess('新增成功!!');
      dispatch(
        getAlsoLikeBrandProductList({ brandId: id, page: current_page }),
      );
    }

    if (addError) {
      if (addError.code === APIUtil.Code_Product_Extensions_Not_Exists) {
        MessageUtil.alertWanring('商品不存在', '');
      }
    }

    return () => {
      dispatch({ type: ALSO_LIKE_PRODUCT_ADD_RESET });
    };
  }, [addSuccess, addError]);

  const toggleModal = () => {
    setModal(!modal);
    clearModalSearchForm();
    dispatch(
      getBrandAlsoLikeProducts({
        brandId: id,
        page: modal_current_page,
      }),
    );

    const existProductIds = productListInfo.datas.map(item => item.productId);
    setExistProductIds(existProductIds);
  };

  const handleModalSearch = e => {
    e.preventDefault();
    setModalCurrentPage(1);
    dispatch(
      getBrandAlsoLikeProducts({
        brandCateId: brandCateId.value,
        brandId: id,
        keyword: modal_keyword,
        model: modal_model.value,
        page: modal_current_page,
      }),
    );
  };

  const clearModalSearchForm = () => {
    setModalKeyword('');
    setModalModel({ label: '請選擇型號', value: '' });
    setBrandCateId({ label: '請選擇品牌類別', value: '0' });
  };

  const [selProductIds, setSelProductIds] = useState([]);

  const handleCheck = (id, checked) => {
    if (checked) {
      setSelProductIds([...selProductIds, id]);
    } else {
      setSelProductIds(selProductIds.filter(productId => productId !== id));
    }
  };

  const assignToBrandAlsoLike = () => {
    dispatch(
      addProductToAlsoLike({
        brandId: id,
        productIds: selProductIds,
      }),
    );
  };

  const handleModalPageChange = page => {
    setModalCurrentPage(page);
    dispatch(
      getBrandAlsoLikeProducts({
        brandCateId: brandCateId.value,
        brandId: id,
        keyword: modal_keyword,
        model: modal_model.value,
        page,
      }),
    );
  };

  const handleSwitch = (isShow, productId) => {
    dispatch(
      setAlsoLikeProductTop({
        brandId: id,
        isShow: !isShow,
        productId: productId,
      }),
    );
  };

  useEffect(() => {
    if (setSuccess) {
      dispatch(
        getAlsoLikeBrandProductList({
          brandId: id,
          keyword: keyword,
          page: current_page,
        }),
      );
    }
  }, [setSuccess]);

  const toggleSortModal = () => {
    setSortModal(!sortModal);
  };

  // get also like top products
  const getAlsoLikeTopProductList = async params => {
    const { code, result, message } = await request({
      method: 'get',
      url: `/Backend/B_BrandAlsoLikePrd/listFromBrandAlsoLike?brandId=${params.brandId}&isTop=${params.isTop}&page_size=${params.page_size}`,
    });

    if (code !== 200) {
      MessageUtil.alertWanring(message);
      toggleSortModal();
      return;
    }
    setSortProductList(result.datas);
  };

  const handleOnDragEnd = result => {
    const items = Array.from(sortProductList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortProductList(items);
  };

  const handleUpdateSort = async () => {
    const sort_objects = sortProductList.map((product, index) => {
      return {
        id: product.id,
        sort: index + 1,
      };
    });
    const { code, message } = await request({
      data: sort_objects,
      method: 'post',
      url: '/Backend/B_BrandAlsoLikePrd/updateSort',
    });
    if (code !== 200) {
      MessageUtil.alertWanring(message);
      return;
    }
    toggleSortModal();
    dispatch(getAlsoLikeBrandProductList({ brandId: id, page: current_page }));
    MessageUtil.toastSuccess('更新成功！');
  };

  return (
    <Layout
      pageTitle={`${productListInfo && productListInfo.brandName} 商品設定`}
      items={[
        { isActive: false, page_name: 'YouMayAlsoLike', to: '/YouMayAlsoLike' },
        { isActive: true, page_name: '編輯' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className="d-flex">
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  style={{ lineHeight: '48px', width: '70px' }}
                  htmlFor="name"
                >
                  關鍵字
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="品項編碼、商品名稱"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                color="light"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
                style={{ marginRight: '1rem' }}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="alert alert-warning mb-2 p-2">
          <ol className="m-0">
            <li>前台網頁最多顯示4個商品</li>
            <li>不足4個商品，會隨機挑設定的商品補足4個</li>
          </ol>
        </div>

        <button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </button>
        <Button
          type="button"
          className={style.sort_button}
          onClick={() => {
            setSortModal(true);
            getAlsoLikeTopProductList(getTopProductListParams);
          }}
        >
          排序
        </Button>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && productListInfo && productListInfo.total_count > 0
              ? productListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th scope="col">#</th>
                <th scope="col">品項編碼</th>
                <th scope="col" style={{ minWidth: '120px' }}>
                  商品
                </th>
                <th>TOP</th>
                <th scope="col">官網商品</th>
                <th scope="col">功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {productListInfo && productListInfo.datas.length > 0 ? (
                  productListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td>
                        {index +
                          1 +
                          (current_page - 1) * productListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.code}</td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.model}/{data.name}/{data.color}/{data.size}
                      </td>
                      <td>
                        <label
                          style={{ wordBreak: 'keep-all' }}
                          id={data.productId}
                          className={`bss-top ${data.isShow && 'active'}`}
                          onClick={() =>
                            handleSwitch(data.isShow, data.productId)
                          }
                        >
                          TOP
                        </label>
                      </td>
                      <td>{data.isSoldOut ? '下架' : '上架'}</td>
                      <td>
                        <Button
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(data.productId)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading && productListInfo && productListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={productListInfo.current_page}
              defaultPageSize={productListInfo.page_size}
              total={productListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>新增類別商品</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-wrap">
            <form className="d-flex flex-wrap" onSubmit={handleModalSearch}>
              <div className="d-flex flex-wrap">
                <div className="form-group me-2 mb-2">
                  <input
                    className="form-control w200"
                    placeholder="品項編碼、關鍵字"
                    value={modal_keyword}
                    onChange={e => setModalKeyword(e.target.value)}
                  />
                </div>

                <div className="form-group me-2 mb-2">
                  <Select
                    isLoading={modelOptionsLoading}
                    isDisabled={modelOptionsLoading}
                    className="w200"
                    options={
                      modelOptions && [
                        { label: '請選擇型號', value: '' },
                        ...modelOptions,
                      ]
                    }
                    value={modal_model}
                    onChange={option => setModalModel(option)}
                  />
                </div>
                <div className="form-group me-2 mb-2">
                  <Select
                    isLoading={brandCategoryOptionsLoading}
                    isDisabled={brandCategoryOptionsLoading}
                    className="w200"
                    options={
                      brandCategoryOptions && [
                        { label: '請選擇品牌類別', value: '0' },
                        ...brandCategoryOptions,
                      ]
                    }
                    value={brandCateId}
                    onChange={option => setBrandCateId(option)}
                  />
                </div>
              </div>
              <div className="text-end">
                <Button type="submit" className={style.modal_button_submit}>
                  搜尋
                </Button>
              </div>
            </form>
          </div>
          <div className="d-flex mt-3">
            <div>
              共
              <span className="mx-1">
                {alsoLikeProductListInfo &&
                alsoLikeProductListInfo.total_count > 0
                  ? alsoLikeProductListInfo.total_count
                  : '0'}
              </span>
              筆
            </div>
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">品項編碼</th>
                  <th scope="col">商品</th>
                  <th scope="col">型號</th>
                  <th scope="col">品牌類別</th>
                  <th scope="col">狀態</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {alsoLikeProductListInfo &&
                  alsoLikeProductListInfo?.datas?.length > 0 ? (
                    alsoLikeProductListInfo.datas.map((data, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={existProductIds.find(id => id === data.id)}
                            onChange={e =>
                              handleCheck(data.id, e.target.checked)
                            }
                          />
                        </td>
                        <td>{data.code}</td>
                        <td style={{ wordBreak: 'keep-all' }}>
                          {data.name}/{data.color}/{data.size}
                        </td>
                        <td style={{ wordBreak: 'keep-all' }}>{data.model}</td>
                        <td>{data.brandCateName}</td>
                        <td>{data.isSoldOut ? '下架' : '上架'}</td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-3">
            {alsoLikeProductListInfo &&
              alsoLikeProductListInfo.total_count > 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <RcPagination
                    defaultCurrent={alsoLikeProductListInfo.current_page}
                    defaultPageSize={alsoLikeProductListInfo.page_size}
                    total={alsoLikeProductListInfo.total_count}
                    onChange={handleModalPageChange}
                  />
                </div>
              )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleModal}
          >
            取消
          </Button>{' '}
          <Button
            type="button"
            color="primary"
            className={style.modal_button_submit}
            onClick={assignToBrandAlsoLike}
            disabled={addLoading}
          >
            {addLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="btn-wrapper--label">儲存</span>
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={sortModal}
        toggle={toggleSortModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleSortModal} className={style.modal_head}>
          TOP商品排序
        </ModalHeader>
        <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="brands">
              {provided => (
                <ul
                  className="drag_list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sortProductList?.map(({ id, name }, index) => (
                    <Draggable key={id} draggableId={`${id}`} index={index}>
                      {provided => (
                        <li
                          className="drag_data"
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <span className="title">{index + 1}</span>
                          <span className="brand_name">{name}</span>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
        <ModalFooter className={style.modal_footer}>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleSortModal}
          >
            取消
          </Button>
          <Button
            color="primary"
            type="submit"
            className={style.modal_button_submit}
            onClick={handleUpdateSort}

            // disabled={sortLoading}
          >
            {/* {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
            儲存
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
}
