import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { memo, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import {
  getAreaList,
  getCityList,
  getCountryList,
} from '../../redux/actions/data/locationActions';
import {
  addRepairRequest,
  getColorModelOption,
  getMaintainDetails,
  getProductInfoViaWarrantySerialNumber,
  getRepairModelOption,
  updateMaintainDetails,
  updateRepairDealing,
} from '../../redux/actions/data/repairActions';
import { getSizeOptions } from '../../redux/actions/data/warrantyActions';
import {
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE,
  MAINTAIN_ADD_RESET,
  MAINTAIN_DETAILS_UPDATE_RESET,
} from '../../redux/constants/data/repairConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';

import PersonalInfo from './PersonalInfo';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
};

const labelStyle = { width: '125px' };

const EditMaintainanceDetailModal = ({
                                       isOpen,
                                       toggle,
                                       isCopy,
                                       setIsCopy,
                                       dealingData,
                                     }) => {
  const dispatch = useDispatch();
  const { maintainOptions } = useSelector(state => state.maintainOptions);
  const { maintainDetailsInfo } = useSelector(state => state.maintainDetails);
  const { repairBrandOptions } = useSelector(state => state.repairBrandOptions);
  const { repairModelOptions } = useSelector(state => state.repairModelOptions);
  const { repairColorOptions } = useSelector(state => state.repairColorOptions);
  const { sizeOptions } = useSelector(state => state.getSizeOptions);

  const {
    success: getSuccess,
    error: getError,
    info: productInfo,
  } = useSelector(state => state.getProductInfoViaWarrantySerialNumber);

  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.maintainDetailUpdate);

  const {
    success: addSuccess,
    error: addError,
    id: responseId,
  } = useSelector(state => state.maintainAdd);


  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...maintainDetailsInfo,
      brand: {
        label: maintainDetailsInfo.brandName || '請選擇',
        value: maintainDetailsInfo.brandId || 0,
      },
      channel: {
        label: maintainDetailsInfo.supplierChannel,
        value: maintainDetailsInfo.supplierChannelId,
      },
      color: {
        label: maintainDetailsInfo.color || '請選擇',
        value: maintainDetailsInfo.color || '',
      },
      colorOptions: [],
      model: {
        label: maintainDetailsInfo.model || '請選擇',
        value: maintainDetailsInfo.modelId || '',
      },
      otherRepiarItemInputValue: maintainDetailsInfo.otherRepiarItem,
      receiver: {
        ...maintainDetailsInfo.receiver,
        city: {
          label: maintainDetailsInfo.receiver.city_receiver || '請選擇',
          value: maintainDetailsInfo.receiver.cityId_receiver,
        },
        country: {
          label: maintainDetailsInfo.receiver.country_receiver || '請選擇',
          value: maintainDetailsInfo.receiver.countryId_receiver,
        },
        town: {
          label: maintainDetailsInfo.receiver.town_receiver || '請選擇',
          value: maintainDetailsInfo.receiver.townId_receiver,
        },
      },
      sender: {
        ...maintainDetailsInfo.sender,
        city: {
          label: maintainDetailsInfo.sender.city_sender || '請選擇',
          value: maintainDetailsInfo.sender.cityId_sender,
        },
        country: {
          label: maintainDetailsInfo.sender.country_sender || '請選擇',
          value: maintainDetailsInfo.sender.countryId_sender,
        },
        town: {
          label: maintainDetailsInfo.sender.town_sender || '請選擇',
          value: maintainDetailsInfo.sender.townId_sender,
        },
      },
      size: {
        label: maintainDetailsInfo.size || '請選擇',
        value: maintainDetailsInfo.size || '',
      },
      sizeOptions: [],
      store: {
        stType: maintainDetailsInfo.store.stType,
        store_receiver: maintainDetailsInfo.store.store_receiver
            ? {
              label: maintainDetailsInfo.store.storeName_receiver,
              value: maintainDetailsInfo.store.store_receiver,
            }
            : { label: '請選擇', value: '' },
        store_receiverType: maintainDetailsInfo.store.store_receiverType,
        store_sender: maintainDetailsInfo.store.store_sender
            ? {
              label: maintainDetailsInfo.store.storeName_sender,
              value: maintainDetailsInfo.store.store_sender,
            }
            : { label: '請選擇', value: '' },
      },
      supplier: {
        ...maintainDetailsInfo.supplier,
        csType: maintainDetailsInfo.supplier.csType,
        supplierReceiver: {
          label: maintainDetailsInfo.supplier.supplierName_receiver,
          value: maintainDetailsInfo.supplier.supplier_receiver,
        },
        supplierSender: {
          label: maintainDetailsInfo.supplier.supplierName_sender,
          value: maintainDetailsInfo.supplier.supplier_sender,
        },
      },
    },
    onSubmit: (values) => {
      const checkRepairItems = values.repairItems.filter(item =>
          maintainOptions.repairItems.find(option => option.value === item),
      );
      const body = {
        brandId: values.brand?.value,
        color: values.color?.value,
        id: values.id,
        isParallelGoods: values.isParallelGoods,
        model: values.model.label,
        modelId: values.model?.value,
        otherRepiarItem: values.otherRepiarItem,
        productProblem: values.productProblem,
        productSerailNum: values.productSerailNum,
        purchaseDate: values.purchaseDate
            ? moment(values.purchaseDate).format('yyyy-MM-DD')
            : null,
        receiver: {
          address_receiver: values.receiver.address_receiver,
          cityId_receiver: values.receiver.city?.value,
          countryCode_receiver: '886',
          countryId_receiver: values.receiver.country?.value,
          email_receiver: values.receiver.email_receiver,
          firstName_receiver: values.receiver.firstName_receiver,
          lastName_receiver: values.receiver.lastName_receiver,
          phone_receiver: values.receiver.phone_receiver,
          townId_receiver: values.receiver.town?.value,
        },
        repairItems: checkRepairItems,
        repairRegisterNum:values.repairRegisterNum,
        sender: {
          address_sender: values.sender.address_sender,
          cityId_sender: values.sender.city?.value,
          countryCode_sender: '886',
          countryId_sender: values.sender.country?.value,
          email_sender: values.sender.email_sender,
          firstName_sender: values.sender.firstName_sender,
          lastName_sender: values.sender.lastName_sender,
          phone_sender: values.sender.phone_sender,
          townId_sender: values.sender.town?.value,
        },
        size: values.size?.value,
        store: {
          stType: values.store.stType,
          store_receiver: values.store.store_receiver?.value,
          store_sender: values.store.store_sender?.value,
        },
        supplier: {
          csType: values.supplier.csType,
          supplierRepairNum: values.supplier.supplierRepairNum,
          supplier_receiver: values.supplier.supplierReceiver?.value || '',
          supplier_receiverType: values.supplier.supplier_receiverType,
          supplier_sender: values.supplier.supplierSender?.value || '',
        },
        supplierChannel: values.channel.label,
        supplierChannelId: values.channel?.value,
        warrantySerailNum: values.warrantySerailNum,
      };

      if (
          values.supplier.csType === '庫存報廢' ||
          values.supplier.supplier_receiverType === '經銷商'
      ) {
        delete body.receiver;
        delete body.sender;
      }

      if (values.supplier.supplier_receiverType === '客戶') {
        delete body.sender;
      }

      if (values.supplier.csType !== '非會員') {
        delete body.isParallelGoods;
      }

      if (values.source === 4) {
        body.supplier.csType = '經銷商';
      }

      if (!isCopy) {
        dispatch(updateMaintainDetails(body));
      } else {
        delete body.id;
        dispatch(addRepairRequest(values.source, body));
      }
    },
    validationSchema: Yup.object().shape({
      brand: Yup.object().shape({
        label: Yup.string()
            .test('label', '必填', val => val !== '請選擇')
            .required('必填'),
      }),
      channel: Yup.object().when('isParallelGoods', {
        is: value => !value,
        then: Yup.object().shape({
          value: Yup.string().min(0).required('購買通路必填'),
        }),
      }),
      color: Yup.object().when('colorOptions', {
        is: val => val.length > 0,
        then: Yup.object().shape({
          value: Yup.string().required('商品顏色必填'),
        }),
      }),
      colorOptions: Yup.array(),
      model: Yup.object().shape({
        label: Yup.string()
            .test('label', '必填', val => val !== '請選擇')
            .required('必填'),
      }),
      productProblem: Yup.string().min(1, '必填').required('必填'),
      purchaseDate: Yup.date().typeError('必填').required('必填'),
      receiver: Yup.object().when(['supplier', 'store', 'source'], {
        is: (supplier, store, source) => {
          return (
              supplier.csType !== '非會員' &&
              store.store_receiverType !== '門市' &&
              supplier.csType !== '經銷商' &&
              supplier.csType !== '庫存報廢' &&
              source !== 4 &&
              store.stType !== '內部庫存報廢' &&
              store.stType !== '一般'
          );
        },
        then: Yup.object().shape({
          address_receiver: Yup.string().min(1, '必填').required('必填'),
          city: Yup.object().shape({
            label: Yup.string()
                .test('label', '必填', val => val !== '請選擇')
                .required('必填'),
          }),
          country: Yup.object().shape({
            label: Yup.string()
                .test('label', '必填', val => val !== '請選擇')
                .required('必填'),
          }),
          email_receiver: Yup.string()
              .matches(
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+.)+[a-zA-Z]{2,}))$/,
                  'Email格式錯誤',
              )
              .required('Email必填'),
          firstName_receiver: Yup.string().min(1, '必填').required('必填'),
          lastName_receiver: Yup.string().min(1, '必填').required('必填'),
          phone_receiver: Yup.string().min(1, '必填').required('必填'),
          town: Yup.object().shape({
            label: Yup.string()
                .test('label', '必填', val => val !== '請選擇')
                .required('必填'),
          }),
        }),
      }),
      repairItems: Yup.array().min(1, '必填').required('必填'),
      sender: Yup.object().when(['supplier', 'source', 'store'], {
        is: (supplier, source, store) => {
          return (
              supplier.csType === '非會員' &&
              source !== 4 &&
              store.stType !== '內部庫存報廢'
          );
        },
        otherwise: Yup.object().when(['supplier', 'source', 'store'], {
          is: (supplier, source, store) => {
            return (
                supplier.csType !== '經銷商' &&
                supplier.csType !== '庫存報廢' &&
                source !== 4 &&
                store.stType !== '內部庫存報廢'
            );
          },
          then: Yup.object().shape({
            address_sender: Yup.string().min(1, '必填').required('必填'),
            city: Yup.object().shape({
              label: Yup.string()
                  .test('label', '必填', val => val !== '請選擇')
                  .required('必填'),
            }),
            country: Yup.object().shape({
              label: Yup.string()
                  .test('label', '必填', val => val !== '請選擇')
                  .required('必填'),
            }),
            email_sender: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Email格式錯誤',
                )
                .required('Email必填'),
            firstName_sender: Yup.string().min(1, '必填').required('必填'),
            lastName_sender: Yup.string().min(1, '必填').required('必填'),
            phone_sender: Yup.string().min(1, '必填').required('必填'),
            town: Yup.object().shape({
              label: Yup.string()
                  .test('label', '必填', val => val !== '請選擇')
                  .required('必填'),
            }),
          }),
        }),
        then: Yup.object().shape({
          firstName_sender: Yup.string().min(1, '必填').required('必填'),
          lastName_sender: Yup.string().min(1, '必填').required('必填'),
        }),
      }),
      size: Yup.object().when('sizeOptions', {
        is: val => val.length > 0,
        then: Yup.object().shape({
          value: Yup.string().required('請選擇尺寸'),
        }),
      }),
      sizeOptions: Yup.array(),
      source: Yup.number(),
      store: Yup.object().shape({
        stType: Yup.string().nullable(),
        store_receiverType: Yup.string().nullable(),
      }),
      supplier: Yup.object().shape({
        csType: Yup.string().nullable(),
      }),
    }),
  });

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getCityList());
    dispatch(getAreaList());
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch(getMaintainDetails(values.id));
      dispatch({ type: MAINTAIN_DETAILS_UPDATE_RESET });
      toggle();
    }

    if (updateError) {
      MessageUtil.alertWanring('更新失敗', updateError);
      dispatch({ type: MAINTAIN_DETAILS_UPDATE_RESET });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    if (addSuccess && responseId) {
      MessageUtil.toastSuccess('新增成功!');
      dispatch({ type: MAINTAIN_ADD_RESET });
      history.push(`/MaintainanceContent/${responseId}`);
      localStorage.removeItem('isCopy');
      setIsCopy(false);
      dispatch(
          updateRepairDealing({
            ...dealingData,
            id: responseId,
            invoiceDate:
                dealingData.invoiceDate === '' ? null : dealingData.invoiceDate,
            repairDate:
                dealingData.repairDate === '' ? null : dealingData.repairDate,
            repairFinishedDate:
                dealingData.repairFinishedDate === ''
                    ? null
                    : dealingData.repairFinishedDate,
            sendBackDate:
                dealingData.sendBackDate === '' ? null : dealingData.sendBackDate,
            status: dealingData.status.label,
            statusCode: dealingData.status.value,
          }),
      );
      toggle();
    }
    if (addError) {
      MessageUtil.toastSuccess('新增失敗!');
      dispatch({ type: MAINTAIN_ADD_RESET });
    }
  }, [addSuccess, addError, responseId]);

  useEffect(() => {
    dispatch(getRepairModelOption(0, 0, values.brand.value));
  }, [values.brand.value]);

  useEffect(() => {
    dispatch(getColorModelOption(0, 0, values.model.value));
  }, [values.model]);

  useEffect(() => {
    if (getSuccess && productInfo) {
      setFieldValue(
          'brand',
          repairBrandOptions?.find(option => option.value == productInfo.brandId),
      );
      setFieldValue('model', {
        label: productInfo.model,
        value: productInfo.modelId,
      });
      setFieldValue('color', {
        label: productInfo.color,
        value: productInfo.color,
      });
      setFieldValue('size', {
        label: productInfo.size,
        value: productInfo.size,
      });
      setFieldValue('productSerailNum', productInfo.productSerialNum);

      setFieldValue('warrantySerailNum', productInfo.warrantySerialNum);

      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    }

    if (getError) {
      dispatch({ type: GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE });
    }
  }, [getError, getSuccess]);

  useEffect(() => {
    dispatch(
        getSizeOptions({
          color: values.color.value,
          isRepair: 1,
          isWarranty: 0,
          modelId: values.model.value,
        }),
    );
  }, [values.model.value, values.color.value]);

  useEffect(() => {
    typeof repairColorOptions === 'object' &&
    setFieldValue('colorOptions', repairColorOptions);
  }, [repairColorOptions]);

  useEffect(() => {
    typeof sizeOptions === 'object' &&
    setFieldValue('sizeOptions', sizeOptions);
  }, [sizeOptions]);

  const syncSenderInfo = () => {
    setFieldValue('store.store_receiver', values.store.store_sender);
    setFieldValue('receiver.lastName_receiver', values.sender.lastName_sender);
    setFieldValue(
        'receiver.firstName_receiver',
        values.sender.firstName_sender,
    );
    setFieldValue(
        'receiver.countryCode_receiver',
        values.sender.countryCode_sender,
    );
    setFieldValue('receiver.phone_receiver', values.sender.phone_sender);
    setFieldValue('receiver.email_receiver', values.sender.email_sender);
    setFieldValue('receiver.country', values.sender.country);
    setFieldValue('receiver.city', values.sender.city);
    setFieldValue('receiver.town', values.sender.town);
    setFieldValue('receiver.address_receiver', values.sender.address_sender);
  };

  const getProductInfo = (value , type) => {
    value && dispatch(getProductInfoViaWarrantySerialNumber(value , type));
  };

  return (
      <Modal
          zIndex={2000}
          centered
          size="xl"
          isOpen={isOpen}
          toggle={toggle}
          backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggle}>
          修改維修單資訊
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          {values?.supplier?.supplierRepairNum && (
              <div className={style.show_flex} style={{ marginBottom: '0.5rem' }}>
                <label style={labelStyle}>經銷商維修單號</label>
                <Input
                    name="supplierRepairNum"
                    onChange={event => {
                      setFieldValue('supplier.supplierRepairNum', event.target.value);
                    }}
                    value={values.supplier.supplierRepairNum ?? ''}
                />
              </div>
          )}
          <h4 className="title mt-5" style={titleStyle}>
            寄件人資訊
          </h4>
          {Number(values.source) === 4 ||
              (values.supplier.csType === '經銷商' && (
                  <div className={style.show_flex} style={{ marginBottom: '0.5rem' }}>
                    <label style={labelStyle} className="required">
                      請選擇經銷商
                    </label>
                    <Select
                        name="sender_suppliers"
                        className={style['form-select-long']}
                        options={maintainOptions && maintainOptions.suppliers}
                        onChange={option => {
                          setFieldValue('supplier.supplierSender', option);
                        }}
                        value={values.supplier.supplierSender}
                    />
                    {errors.supplier && touched.supplier && (
                        <span className="text-danger ml-2">
                  {errors.supplier.label}
                </span>
                    )}
                  </div>
              ))}
          {values.store.stType === '一般' && (
              <div className={style.show_flex} style={{ marginBottom: '1rem' }}>
                <label style={labelStyle}>請選擇門市</label>
                <Select
                    name="sender_suppliers"
                    className={style['form-select-long']}
                    options={maintainOptions && maintainOptions.supplierUUs}
                    onChange={option => {
                      setFieldValue('store.store_sender', option);
                    }}
                    value={values.store.store_sender}
                />
              </div>
          )}
          {values.store.stType === '內部庫存報廢' &&
              maintainDetailsInfo.store.storeName_sender && (
                  <div style={{ marginBottom: '2rem' }}>
                    {maintainDetailsInfo.store.storeName_sender}
                  </div>
              )}
          {values.supplier?.csType !== '經銷商' &&
              values.supplier?.csType !== '庫存報廢' &&
              Number(values.source) !== 4 &&
              values.store.stType !== '內部庫存報廢' && (
                  <PersonalInfo
                      setFieldValue={setFieldValue}
                      type="sender"
                      values={values.sender}
                      errors={errors?.sender}
                      touched={touched?.sender}
                      isNonmember={values.supplier?.csType === '非會員'}
                  />
              )}
          {values.supplier?.csType === '庫存報廢' && (
              <div>百滋國際股份有限公司</div>
          )}
          <h4 className="title mt-5" style={titleStyle}>
            收件人資訊
          </h4>
          {values.store.stType === '內部庫存報廢' && (
              <div>百滋國際股份有限公司</div>
          )}
          {values.supplier?.csType === '庫存報廢' && (
              <div>百滋國際股份有限公司</div>
          )}
          {values.supplier?.supplier_receiverType === '經銷商' && (
              <div className={style.show_flex} style={{ marginBottom: '0.5rem' }}>
                <label style={labelStyle} className="required">
                  請選擇經銷商
                </label>
                <Select
                    name="receiver_suppliers"
                    className={style['form-select-long']}
                    options={maintainOptions && maintainOptions.suppliers}
                    onChange={option => {
                      setFieldValue('supplier.supplierReceiver', option);
                    }}
                    value={values.supplier.supplierReceiver}
                />
              </div>
          )}
          {values.store.stType === '一般' && (
              <React.Fragment>
                <div className={style.show_flex}>
                  <input
                      type="checkbox"
                      style={{ height: '20px', width: '20px' }}
                      onChange={event => {
                        const checked = event.target.checked;
                        checked && syncSenderInfo();
                      }}
                  />
                  <span style={{ marginLeft: '1rem' }}>同寄件人</span>
                </div>
                <div className={style.show_flex} style={{ marginBottom: '1rem' }}>
                  <label style={labelStyle}>請選擇門市</label>
                  <Select
                      name="sender_suppliers"
                      className={style['form-select-long']}
                      options={maintainOptions && maintainOptions.supplierUUs}
                      onChange={option => {
                        setFieldValue('store.store_receiver', option);
                      }}
                      value={values.store.store_receiver}
                  />
                </div>
              </React.Fragment>
          )}
          {values.supplier?.csType !== '庫存報廢' &&
              values.supplier?.supplier_receiverType !== '經銷商' &&
              values.store.stType !== '內部庫存報廢' && (
                  <PersonalInfo
                      setFieldValue={setFieldValue}
                      type="receiver"
                      values={values.receiver}
                      errors={errors?.receiver}
                      touched={touched?.receiver}
                      isNonmember={values.supplier?.csType === '非會員'}
                      isStore={values.store.stType === '一般'}
                  />
              )}
          <h4 className="title mt-5" style={titleStyle}>
            維修品資訊
          </h4>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80 required">商品品牌</label>
            {repairBrandOptions && (
                <Select
                    className={style['form-select']}
                    options={[{ label: '請選擇', value: '' }, ...repairBrandOptions]}
                    value={values.brand}
                    onChange={({ label, value }) => {
                      setFieldValue('brand', { label, value });
                      setFieldValue('model', { label: '請選擇', value: '' });
                      setFieldValue('color', { label: '請選擇', value: '' });
                      setFieldValue('size', { label: '請選擇', value: '' });
                    }}
                />
            )}
            {errors.brand && touched.brand && (
                <span className="text-danger ml-2">{errors.brand.label}</span>
            )}
          </div>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80 required">商品型號</label>
            <Select
                className={style['form-select']}
                options={
                  repairModelOptions
                      ? [{ label: '請選擇', value: '' }, ...repairModelOptions]
                      : []
                }
                value={values.model}
                onChange={({ label, value }) => {
                  setFieldValue('model', { label, value });
                  setFieldValue('color', { label: '請選擇', value: '' });
                  setFieldValue('size', { label: '請選擇', value: '' });
                }}
            />
            {errors.model && touched.model && (
                <span className="text-danger ml-2">{errors.model.label}</span>
            )}
          </div>
          {repairColorOptions?.length > 0 && (
              <div className={`mb-3 ${style.show_flex}`}>
                <label className="w80 required">商品顏色</label>
                <Select
                    className={style['form-select']}
                    options={
                      repairColorOptions
                          ? [{ label: '請選擇', value: '' }, ...repairColorOptions]
                          : []
                    }
                    value={values.color}
                    onChange={({ label, value }) => {
                      setFieldValue('color', { label, value });
                      setFieldValue('size', { label: '請選擇', value: '' });
                    }}
                />
                {errors?.color && errors?.color?.value && (
                    <span className="text-danger ml-2">{errors.color.value}</span>
                )}
              </div>
          )}
          {sizeOptions?.length > 0 && (
              <div className={`${style.show_flex} mb-3`}>
                <label className="required w80">商品尺寸</label>
                <Select
                    className={style['form-select']}
                    options={sizeOptions || []}
                    value={values.size}
                    onChange={option => {
                      setFieldValue('size', option);
                    }}
                />
                {errors?.size && errors?.size?.value && (
                    <span className="text-danger ml-2">{errors.size.value}</span>
                )}
              </div>
          )}
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80">商品序號</label>
            <Input
                className={style['serial-number-input']}
                value={values.productSerailNum ?? ''}
                onChange={event => {
                  setFieldValue('productSerailNum', event.target.value);
                }}
                onBlur={e => {
                  getProductInfo(e.target.value , 'productSerial');
                }}
            />
          </div>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80">保固序號</label>
            <Input
                className={style['serial-number-input']}
                value={values.warrantySerailNum ?? ''}
                onChange={event => {
                  setFieldValue('warrantySerailNum', event.target.value);
                }}
                onBlur={e => {
                  getProductInfo(e.target.value , 'warrantSerial');
                }}
            />
          </div>
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80 required">購買日期</label>
            <div className={style['form-select']}>
              <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.purchaseDate ? new Date(values.purchaseDate) : ''
                  }
                  onChange={date => {
                    setFieldValue('purchaseDate', date);
                  }}
              />
            </div>
            {errors.purchaseDate && touched.purchaseDate && (
                <span className="text-danger ml-2">{errors.purchaseDate}</span>
            )}
          </div>
          {values.supplier?.csType === '非會員' && (
              <div className={`mb-3 ${style.show_flex}`}>
                <label className="w80 required">水貨</label>
                <div style={{ marginRight: '1.5rem' }}>
                  <input
                      type="radio"
                      checked={values.isParallelGoods === true}
                      onChange={() => {
                        setFieldValue('isParallelGoods', true);
                      }}
                  />
                  <span style={{ marginLeft: '0.5rem' }}>是</span>
                </div>
                <div style={{ marginRight: '1.5rem' }}>
                  <input
                      type="radio"
                      checked={values.isParallelGoods === false}
                      onChange={() => {
                        setFieldValue('isParallelGoods', false);
                      }}
                  />
                  <span style={{ marginLeft: '0.5rem' }}>否</span>
                </div>
              </div>
          )}
          <div className={`mb-3 ${style.show_flex}`}>
            <label className="w80 required">購買通路</label>
            {maintainOptions && (
                <Select
                    className={style['form-select-long']}
                    isDisabled={
                        values.isParallelGoods && values.supplier?.csType === '非會員'
                    }
                    options={[
                      { label: '請選擇', value: '' },
                      ...maintainOptions.allChannels,
                    ]}
                    value={values.channel}
                    onChange={({ label, value }) => {
                      setFieldValue('channel', { label, value });
                    }}
                />
            )}
            {errors?.channel && errors?.channel?.value && (
                <span className="text-danger ml-2">{errors.channel.value}</span>
            )}
          </div>
          <h4 className="title mt-5 required" style={titleStyle}>
            檢附項目
          </h4>
          {errors.repairItems && touched.repairItems && (
              <span className="text-danger ml-2">{errors.repairItems}</span>
          )}
          <div className={style.show_flex}>
            {maintainOptions &&
                maintainOptions.repairItems.map((item, index) => (
                    <div
                        key={index}
                        style={{
                          alignContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                    >
                      <input
                          type="checkbox"
                          style={{ height: '20px', width: '20px' }}
                          checked={values.repairItems.includes(item.value)}
                          onChange={event => {
                            const checked = event.target.checked;
                            checked
                                ? !values.repairItems.includes(item.value) &&
                                setFieldValue('repairItems', [
                                  ...values.repairItems,
                                  item.value,
                                ])
                                : setFieldValue(
                                    'repairItems',
                                    values.repairItems.filter(i => i !== item.value),
                                );
                          }}
                      />
                      <span style={{ marginLeft: '0.5rem', marginRight: '1rem' }}>
                  {item.value}
                </span>
                    </div>
                ))}
            <div
                style={{
                  alignContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
            >
              <input
                  type="checkbox"
                  style={{ height: '20px', width: '20px' }}
                  checked={
                      values.otherRepiarItem?.trim() !== '' &&
                      values.otherRepiarItem !== null
                  }
                  onChange={event => {
                    const checked = event.target.checked;
                    checked
                        ? setFieldValue(
                            'otherRepiarItem',
                            values.otherRepiarItemInputValue,
                        )
                        : setFieldValue('otherRepiarItem', '');
                  }}
              />
              <span
                  style={{
                    marginLeft: '0.5rem',
                    marginRight: '1rem',
                    whiteSpace: 'nowrap',
                  }}
              >
              其他
            </span>
              <Input
                  className={style['form-input']}
                  onChange={event => {
                    setFieldValue('otherRepiarItemInputValue', event.target.value);
                  }}
                  value={values.otherRepiarItemInputValue ?? ''}
              />
            </div>
          </div>
          <h4 className="title mt-5 required" style={titleStyle}>
            商品問題
          </h4>
          {errors.productProblem && touched.productProblem && (
              <span className="text-danger">{errors.productProblem}</span>
          )}
          <Input
              type="textarea"
              style={{ height: '180px' }}
              value={values.productProblem ?? ''}
              onChange={event => {
                const htmlTag = new RegExp('&lt;br /&gt', 'g');
                const value = event.target.value.replace(htmlTag, '\r\n');
                setFieldValue('productProblem', value);
              }}
              invalid={errors?.productProblem && touched?.productProblem}
          />
        </ModalBody>
        <ModalFooter>
          <Button
              className={`me-3 ${style.modal_button_cancel}`}
              onClick={toggle}
          >
            取消
          </Button>

          <Button
              type="submit"
              className={style.modal_button_submit}
              onClick={handleSubmit}
              disabled={updateLoading || Object.keys(errors).length > 0}
          >
            {updateLoading && (
                <span
                    className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
            <span className="btn-wrapper--label">儲存</span>
          </Button>
        </ModalFooter>
      </Modal>
  );
};

export default memo(EditMaintainanceDetailModal);
