import React, { memo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from '../../styles/layout.module.css';

const ModificationHandleModal = ({
  allShow = false,
  toggleAllShow = () => {},
  info = {
    notHandlerEdits: [],
  },
}) => {
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={allShow}
      toggle={toggleAllShow}
      backdrop="static"
    >
      <ModalHeader toggle={toggleAllShow} className={style.modal_head}>
        非經手人修改
      </ModalHeader>
      <ModalBody
        className={`form-list form-label-140 mb-5 ${style.modal_body}`}
      >
        {info?.notHandlerEdits.map((item, index) => (
          <div key={index} className="mb-3">
            <span style={{ color: '#3c44b1' }}>
              {item.date} {item.admin}
            </span>
            {item.repairChanges.map((change, index) => (
              <div className="mb-2" key={index + 1}>
                <h6 className="m-0">{change.title}</h6>
                <span>{change.content}</span>
              </div>
            ))}
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default memo(ModificationHandleModal);
