import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getSingleMessage } from 'redux/actions/data/messageActions';
import CommonUtil from 'utils/CommonUtil';

import style from '../../styles/layout.module.css';

import Message from './Message';

const RecordsModal = ({
  isOpen,
  toggle,
  id,
  setCurrentImgSrc,
  setIsOriginImageModalOpen,
}) => {
  const dispatch = useDispatch();
  const { datas } = useSelector(state => state.getSingleMessage);
  const [content, setContent] = useState([]);

  useEffect(() => {
    dispatch(getSingleMessage(id));
  }, [id]);

  useEffect(() => {
    let replies = [];
    if (JSON.stringify(datas) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: datas });
      replies = datas.replies?.map(reply => ({
        content: reply.context,
        messageImages: reply.messageImgs,
        name: reply.adminName,
        time: reply.createTime,
        type: reply.adminName ? 'staff' : 'customer',
      }));

      if (replies?.length > 0) {
        // setContent([{
        //   // 第一筆資料固定是消費者提問
        //   type: 'customer',
        //   content: datas.context,
        //   time: datas.createTime,
        //   messageImages: datas.messageImgs,
        // }, ...replies]);
        setContent(replies);
      }
    }
  }, [datas]);

  // const content = [{
  //   type: 'customer',
  //   content: '請問送修產品什麼時候寄回?',
  //   time: '2023/03/01 13:26:09',
  // }, {
  //   type: 'customer',
  //   content: '哈囉請問有人在嘛?',
  //   time: '2023/03/02 09:10:18',
  // }, {
  //   type: 'staff',
  //   name: 'admin',
  //   content: '您好, 不好意思請問您送修的商品品牌及型號是?',
  //   time: '2023/03/02 10:02:49',
  // }, {
  //   type: 'customer',
  //   content: 'XX牌 XXX XXXXXXXX',
  //   time: '2023/03/02 14:06:26',
  // }, {
  //   type: 'staff',
  //   name: 'admin',
  //   content: '好的, 稍後會再幫您確認目前的維修進度, 請內心等候',
  //   time: '2023/03/02 14:08:33',
  // }, {
  //   type: 'staff',
  //   name: 'admin',
  //   content: 'http://tqjimg.tianqistatic.com/toutiao/jianbihuadaquan/jiaoyu/images/201908/05/175abbd461994197dd7ebb8ad82a0e9d.jpg',
  //   time: '2023/03/02 15:18:02',
  //   isImage: true,
  // }];
  return (
    <Modal zIndex={2000} size="xl" centered isOpen={isOpen}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        歷程記錄
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        {content.length > 0 &&
          content.map((data, index) => (
            <Message
              key={index}
              type={data.type}
              name={data.name}
              time={data.time}
              content={data.content}
              messageImages={data.messageImages}
              setCurrentImgSrc={setCurrentImgSrc}
              setIsOriginImageModalOpen={setIsOriginImageModalOpen}
            />
          ))}
      </ModalBody>
    </Modal>
  );
};

export default RecordsModal;
