import Layout from 'components/Layout';
import SearchSelect from 'components/SearchSelector';
import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import { getModelOptions } from 'redux/actions/data/productActions';
import {
  deleteUUModel,
  editUUModel,
  getUUModelRelList,
  getUUModelRelSelectModels,
  saveModels,
} from 'redux/actions/data/uuModelRelActions';
import {
  UUMODELREL_DELETE_UUMODEL_RESET,
  UUMODELREL_EDIT_UUMODEL_RESET,
  UUMODELREL_SAVE_MODELS_RESET,
} from 'redux/constants/data/uuModelRelConstants';
import MessageUtil from 'utils/MessageUtil';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const AddModalTableList = ({ isShowTable, onChange }) => {
  const { loading, uuModelRelSelectModelsInfo } = useSelector(
    state => state.uuModelRelSelectModels,
  );

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: [],
    },
  });
  useEffect(() => {
    onChange && onChange(values.list);
  }, [values]);
  return (
    <div className="table-responsive">
      {loading && <BlockSpinner />}
      {isShowTable && (
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th style={{ width: 90 }}>
                <input
                  className={style.maintain_list_checkbox}
                  type="checkbox"
                  name="all"
                  onChange={e => {
                    const val = e.target.checked;
                    setFieldValue(
                      'list',
                      val
                        ? uuModelRelSelectModelsInfo?.datas?.map(i => i.id)
                        : [],
                    );
                  }}
                  checked={
                    values.list.length > 0 &&
                    values.list.length ===
                      uuModelRelSelectModelsInfo?.datas?.length
                  }
                />{' '}
                全選
              </th>
              <th>商品名稱</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {uuModelRelSelectModelsInfo?.datas?.length > 0 ? (
                uuModelRelSelectModelsInfo?.datas?.map((i, index) => (
                  <tr className="text-nowrap" key={i.id}>
                    <td>
                      <input
                        className={style.maintain_list_checkbox}
                        type="checkbox"
                        onChange={e => {
                          const val = e.target.checked;
                          setFieldValue(
                            'list',
                            val
                              ? [...values.list, i.id]
                              : values.list.filter(j => j !== i.id),
                          );
                        }}
                        checked={values.list.indexOf(i.id) > -1}
                      />
                    </td>
                    <td>{i.name}</td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      )}
    </div>
  );
};

const AddModal = ({ open, toggle, onSuccess }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { uuModelRelSelectModelsInfo } = useSelector(
    state => state.uuModelRelSelectModels,
  );
  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  const [isShowTable, setIsShowTable] = useState(false);
  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: 10,
      uubrandCatId: query.value.uubrandCatId,
    },
    async onSubmit(formModel) {
      setIsShowTable(true);
      dispatch(
        getUUModelRelSelectModels({
          ...formModel,
          brandId: +formModel.brandId,
        }),
      );
    },
  });

  useEffect(() => {
    setFieldValue('model', '');
    dispatch(
      getModelOptions(
        brandOptions?.find(i => i.value === values.brandId)?.label || '',
      ),
    );
  }, [values.brandId]);
  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const [list, setList] = useState([]);
  const handleListChange = formModel => {
    setList(formModel);
  };
  const { loading, success, error } = useSelector(state => state.saveModels);
  const handleSaveModels = () => {
    dispatch(saveModels(query.value.uubrandCatId, list));
  };
  useEffect(() => {
    success && MessageUtil.toastSuccess('新增成功！');
    success && toggle();
    success && handleSubmit();
    success && onSuccess && onSuccess();
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: UUMODELREL_SAVE_MODELS_RESET });
    };
  }, [success, error]);
  return (
    <Modal
      zIndex={2000}
      centered
      size="xl"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        新增分類商品
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: 370 }}>
        {loading && <BlockSpinner />}
        <form className="form-inline">
          <div className="d-flex flex-column flex-sm-row flex-wrap w-100">
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2 text-nowrap">關鍵字</label>
              <input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="商品名稱"
                onChange={handleChange}
                value={values.keyword}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label me-2">品牌</label>
              <SearchSelect
                options={brandOptions}
                emptyVal={0}
                name="brandId"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label me-2">型號</label>
              <SearchSelect
                options={modelOptions}
                emptyVal={''}
                name="model"
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className={style.formGroup}>
              <Button
                type="button"
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                查詢
              </Button>
            </div>
          </div>
        </form>
        <AddModalTableList
          isShowTable={isShowTable}
          onChange={handleListChange}
        />
        {uuModelRelSelectModelsInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={uuModelRelSelectModelsInfo?.current_page}
              defaultPageSize={uuModelRelSelectModelsInfo?.page_size}
              total={uuModelRelSelectModelsInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={loading}
        >
          {loading && (
            <span
              className="btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSaveModels}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
const IsShowBrandForm = ({ modelId, defaultValue, onSuccess }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { loading, success, error } = useSelector(state => state.editUUModel);
  const handleChange = e => {
    MessageUtil.submitConfirm({
      doneFun: () => {
        dispatch(
          editUUModel({
            isShowonTheBrand: e.target.value,
            modelId,
            uubrandCatId: query.value.uubrandCatId,
          }),
        );
      },
      title: '確認修改顯示在商品？',
    });
  };

  useEffect(() => {
    success && MessageUtil.toastSuccess('修改成功！');
    success && onSuccess && onSuccess();
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: UUMODELREL_EDIT_UUMODEL_RESET });
    };
  }, [success, error]);

  return (
    <div className="position-relative">
      {loading && <BlockSpinner />}
      <Label className="me-3">
        <input
          name={`isShowonTheBrand${modelId}`}
          value={1}
          type="radio"
          onChange={handleChange}
          checked={defaultValue}
        />{' '}
        是
      </Label>
      <Label>
        <input
          name={`isShowonTheBrand${modelId}`}
          value={0}
          type="radio"
          onChange={handleChange}
          checked={!defaultValue}
        />{' '}
        否
      </Label>
    </div>
  );
};

const TableList = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { loading, uuModelRelListInfo } = useSelector(
    state => state.uuModelRelList,
  );
  const handleDelete = modelId => {
    MessageUtil.submitConfirm({
      doneFun() {
        dispatch(deleteUUModel(modelId, query.value.uubrandCatId));
      },
      title: '確認刪除商品？',
    });
  };
  const {
    loading: deleteLoading,
    success,
    error,
  } = useSelector(state => state.deleteUUModel);
  useEffect(() => {
    success && MessageUtil.toastSuccess('刪除成功！');
    success && onSuccess && onSuccess();
    error && MessageUtil.alertWanring('刪除失敗！', error);
    return () => {
      dispatch({ type: UUMODELREL_DELETE_UUMODEL_RESET });
    };
  }, [success, error]);
  return (
    <div className="table-responsive">
      <Table hover bordered striped className="mb-5">
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>品牌</th>
            <th>型號</th>
            <th>商品名稱</th>
            <th>品牌分類</th>
            <th>商品分類</th>
            <th>顯示官網</th>
            <th>出現在此品牌商品裡</th>
            <th>功能</th>
          </tr>
        </thead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {uuModelRelListInfo?.datas?.length > 0 ? (
              uuModelRelListInfo?.datas?.map((i, index) => (
                <tr className="text-nowrap" key={i.id}>
                  <td>{i.brandName}</td>
                  <td>{i.model}</td>
                  <td>{i.name}</td>
                  <td>{i.brandCatName}</td>
                  <td>{/* TODO 商品分類 */}</td>
                  <td className="text-center">{i.isSell ? '是' : '否'}</td>
                  <td>
                    <IsShowBrandForm
                      modelId={i.modelId}
                      defaultValue={i.isShowonTheBrand}
                      onSuccess={onSuccess}
                    />
                  </td>
                  <td>
                    <Button
                      color="danger"
                      onClick={() => handleDelete(i.modelId)}
                      disabled={deleteLoading}
                    >
                      {deleteLoading && (
                        <span
                          className="btn-wrapper--icon spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      刪除
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

const BrandCategoryUUPrds = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { uuModelRelListInfo } = useSelector(state => state.uuModelRelList);
  useEffect(() => {
    dispatch(getBrandOptions());
    dispatch(
      getUUModelRelList({
        page: 1,
        page_size: 10,
        uubrandCatId: query.value.uubrandCatId,
      }),
    );
  }, []);
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        brandId: 0,
        isSell: null,
        isShowonTheBrand: null,
        keyword: '',
        model: '',
        page: 1,
        page_size: 10,
        uubrandCatId: query.value.uubrandCatId,
      },
      async onSubmit(formModel) {
        dispatch(
          getUUModelRelList({ ...formModel, brandId: +formModel.brandId }),
        );
      },
    });
  useEffect(() => {
    dispatch(
      getModelOptions(
        brandOptions?.find(i => i.value === values.brandId)?.label || '',
      ),
    );
  }, [values.brandId]);

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <Layout
      pageTitle="類別設定"
      items={[
        { isActive: false, page_name: '品牌管理', to: '/BrandControl' },
        { isActive: true, page_name: '類別設定' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2 text-nowrap">
                  關鍵字
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="keyword"
                  placeholder="商品名稱"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">品牌</label>
                <SearchSelect
                  options={brandOptions}
                  emptyVal={0}
                  name="brandId"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">型號</label>
                <SearchSelect
                  options={modelOptions}
                  emptyVal={0}
                  name="model"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              {/* <div className={style.formGroup}>
                <label className="col-form-label me-2">顯示官網</label>
                <SearchSelect
                  options={[
                    { label: '是', value: true },
                    { label: '否', value: false },
                  ]}
                  emptyVal={''}
                  name="isSell"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div> */}
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="mb-3">
          <Button
            color="success"
            className="me-2"
            onClick={() => setOpen(true)}
          >
            新增分類商品
          </Button>
        </div>
        <TableList onSuccess={handleSubmit} />
        {uuModelRelListInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={uuModelRelListInfo?.current_page}
              defaultPageSize={uuModelRelListInfo?.page_size}
              total={uuModelRelListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
        <AddModal open={open} toggle={handleToggle} onSuccess={handleSubmit} />
      </Card>
    </Layout>
  );
};
export default BrandCategoryUUPrds;
