import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

const SortingTableHead = ({
  title,
  activeId,
  setFieldValue,
  currentActiveId,
  handleSearch,
}) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div>
        {typeof title === 'object'
          ? title.map((text, index) => <div key={index}>{text}</div>)
          : title}
      </div>
      <div style={{ marginLeft: '0.7rem' }}>
        <FaArrowDown
          color={`${currentActiveId}` === `${activeId.down}` ? '' : 'grey'}
          size="18"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setFieldValue('activeId', activeId.down);
            setFieldValue('order_by', activeId.item);
            setFieldValue('order', 'desc');

            // handleSearch();
          }}
        />{' '}
        <FaArrowUp
          color={`${currentActiveId}` === `${activeId.up}` ? '' : 'grey'}
          size="18"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setFieldValue('activeId', activeId.up);
            setFieldValue('order_by', activeId.item);
            setFieldValue('order', 'asc');

            // handleSearch();
          }}
        />
      </div>
    </div>
  );
};

export default SortingTableHead;
