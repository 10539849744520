import moment from 'moment';
import { Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const AvailableBonusPoints = ({ datas }) => {
  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>到期日</th>
            <th>可用點數</th>
          </tr>
        </thead>
        {!datas && <TableLoading />}

        {datas && (
          <tbody>
            {datas?.length > 0 ? (
              datas.map((data, i) => (
                <tr key={i}>
                  <td>
                    {moment(data.expireTime).format('yyyy/MM/DD HH:mm:ss')}
                  </td>
                  <td>{data.point}</td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default AvailableBonusPoints;
