import Layout from 'components/Layout';
import React from 'react';


import OtherSettings from '../components/uuCafeMealOther/OtherSettings';

const CafeMealIntroOther = () => {
  return (
    <Layout
      pageTitle="餐點介紹-其它專區"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '餐點介紹-其它專區' },
      ]}
    >
      <OtherSettings />
    </Layout>
  );
};

export default CafeMealIntroOther;
