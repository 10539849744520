import {
  BRAND_CATEGORY_FAIL,
  BRAND_CATEGORY_PRODUCT_FAIL,
  BRAND_CATEGORY_PRODUCT_REQUEST,
  BRAND_CATEGORY_PRODUCT_SUCCESS,
  BRAND_CATEGORY_PRODUCT_REMOVE_FAIL,
  BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST,
  BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS,
  BRAND_CATEGORY_REQUEST,
  BRAND_CATEGORY_SUCCESS,
  BRAND_DETAILS_FAIL,
  BRAND_DETAILS_REQUEST,
  BRAND_DETAILS_SUCCESS,
  BRAND_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_UPDATE_FAIL,
  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_RESET,
  BRAND_UPDATE_SUCCESS,
  BRAND_CATEGORY_REMOVE_REQUEST,
  BRAND_CATEGORY_REMOVE_SUCCESS,
  BRAND_CATEGORY_REMOVE_FAIL,
  BRAND_CATEGORY_ADD_REQUEST,
  BRAND_CATEGORY_ADD_SUCCESS,
  BRAND_CATEGORY_ADD_FAIL,
  BRAND_CATEGORY_UPDATE_REQUEST,
  BRAND_CATEGORY_UPDATE_SUCCESS,
  BRAND_CATEGORY_UPDATE_FAIL,
  BRAND_CATEGORY_ADD_RESET,
  BRAND_CATEGORY_UPDATE_RESET,
  BRAND_CATEGORY_REMOVE_RESET,
  BRAND_OPTIONS_REQUEST,
  BRAND_OPTIONS_SUCCESS,
  BRAND_OPTIONS_FAIL,
  BRAND_CATEGORY_OPTIONS_REQUEST,
  BRAND_CATEGORY_OPTIONS_SUCCESS,
  BRAND_CATEGORY_OPTIONS_FAIL,
  BRAND_CATEGORY_PRODUCT_LIST_REQUEST,
  BRAND_CATEGORY_PRODUCT_LIST_SUCCESS,
  BRAND_CATEGORY_PRODUCT_LIST_FAIL,
  BRAND_CATEGORY_PRODUCT_REMOVE_RESET,
  PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST,
  PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS,
  PRODUCT_ADDTO_BRAND_CATEGORY_FAIL,
  PRODUCT_ADDTO_BRAND_CATEGORY_RESET,
  BRAND_UPDATESORT_REQUEST,
  BRAND_UPDATESORT_SUCCESS,
  BRAND_UPDATESORT_FAIL,
  BRAND_UPDATESORT_RESET,
  BRAND_CATEGORY_SORT_REQUEST,
  BRAND_CATEGORY_SORT_SUCCESS,
  BRAND_CATEGORY_SORT_FAIL,
  BRAND_CATEGORY_SORT_RESET,
  BRAND_CATEGORY_MODEL_LIST_REQUEST,
  BRAND_CATEGORY_MODEL_LIST_SUCCESS,
  BRAND_CATEGORY_MODEL_LIST_FAIL,
  BRAND_CATEGORY_MODEL_REMOVE_REQUEST,
  BRAND_CATEGORY_MODEL_REMOVE_SUCCESS,
  BRAND_CATEGORY_MODEL_REMOVE_FAIL,
  BRAND_CATEGORY_MODEL_ASSIGN_REQUEST,
  BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS,
  BRAND_CATEGORY_MODEL_ASSIGN_FAIL,
  BRAND_CATEGORY_MODEL_ASSIGN_RESET,
  PRODUCT_BRAND_BANNER_SETTING_SUCCESS,
  PRODUCT_BRAND_BANNER_SETTING_FAIL,
} from '../../constants/data/brandConstants';

//get brand list
export const brandListReducer = (
  state = { brandList: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_LIST_REQUEST:
      return { loading: true };
    case BRAND_LIST_SUCCESS:
      return { brandList: payload, loading: false };
    case BRAND_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get brand list
export const brandOptionsReducer = (
  state = { brandOptions: [], loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_OPTIONS_REQUEST:
      return { loading: true };
    case BRAND_OPTIONS_SUCCESS:
      return { brandOptions: payload, loading: false };
    case BRAND_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update brand sort
export const brandSortReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_UPDATESORT_REQUEST:
      return { loading: true };
    case BRAND_UPDATESORT_SUCCESS:
      return { loading: false, success: true };
    case BRAND_UPDATESORT_FAIL:
      return { error: payload, loading: false };
    case BRAND_UPDATESORT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get brand details
export const brandDetailsReducer = (
  state = { loading: true, settingData: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_DETAILS_REQUEST:
      return { loading: true };
    case BRAND_DETAILS_SUCCESS:
      return { loading: false, settingData: payload };
    case BRAND_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update brand details
export const brandUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_UPDATE_REQUEST:
      return { loading: true };
    case BRAND_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_UPDATE_FAIL:
      return { error: payload, loading: false };
    case BRAND_UPDATE_RESET:
      return { ...state, success: false };
    default:
      return state;
  }
};

//get brand category
export const brandCategoryReducer = (
  state = { categoryListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_SUCCESS:
      return { categoryListInfo: payload, loading: false };
    case BRAND_CATEGORY_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get brand category options
export const brandCategoryOptionsReducer = (
  state = { brandCategoryOptions: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_OPTIONS_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_OPTIONS_SUCCESS:
      return { brandCategoryOptions: payload, loading: false };
    case BRAND_CATEGORY_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get brand category product
export const brandCategoryProductReducer = (
  state = { categoryProductListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_PRODUCT_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_PRODUCT_SUCCESS:
      return { categoryProductListInfo: payload, loading: false };
    case BRAND_CATEGORY_PRODUCT_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add brand category
export const brandCategoryAddReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_ADD_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_ADD_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_ADD_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//update brand category
export const brandCategoryUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_UPDATE_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_UPDATE_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//remove brand category
export const brandCategoryRemoveReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_REMOVE_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_REMOVE_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_REMOVE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//update brand sort
export const brandCategorySortReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_SORT_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_SORT_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_SORT_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_SORT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//remove brand category product
export const brandCategoryProductRemoveReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_PRODUCT_REMOVE_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_PRODUCT_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_PRODUCT_REMOVE_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_PRODUCT_REMOVE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get brand category product list
export const brandCategoryProductListReducer = (
  state = { brandCategoryProductList: [], loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_PRODUCT_LIST_SUCCESS:
      return { brandCategoryProductList: payload, loading: false };
    case BRAND_CATEGORY_PRODUCT_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add product to brand category
export const brandCategoryAddProductReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case PRODUCT_ADDTO_BRAND_CATEGORY_REQUEST:
      return { loading: true };
    case PRODUCT_ADDTO_BRAND_CATEGORY_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_ADDTO_BRAND_CATEGORY_FAIL:
      return { error: payload, loading: false };
    case PRODUCT_ADDTO_BRAND_CATEGORY_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const brandCategoryModelListReducer = (
  state = {
    brandCategoryModelList: {},
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_MODEL_LIST_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_MODEL_LIST_SUCCESS:
      return { brandCategoryModelList: payload, loading: false };
    case BRAND_CATEGORY_MODEL_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const brandCategoryRemoveModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_MODEL_REMOVE_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_MODEL_REMOVE_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_MODEL_REMOVE_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};
export const brandCategoryAssignModelReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_MODEL_ASSIGN_REQUEST:
      return { loading: true };
    case BRAND_CATEGORY_MODEL_ASSIGN_SUCCESS:
      return { loading: false, success: true };
    case BRAND_CATEGORY_MODEL_ASSIGN_FAIL:
      return { error: payload, loading: false };
    case BRAND_CATEGORY_MODEL_ASSIGN_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const getProductBrandBannerSettingReducer = (
  state = { detail: {}, errors: null, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_CATEGORY_MODEL_ASSIGN_REQUEST:
      return { ...state, error: null, loading: true };
    case PRODUCT_BRAND_BANNER_SETTING_SUCCESS:
      return { detail: payload, error: null, loading: false };
    case PRODUCT_BRAND_BANNER_SETTING_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
