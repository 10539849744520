import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';

import Layout from '../components/Layout';
import Loader from '../components/Loader';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  ClearMemberInfo,
  getMemberLevelOptions,
  getMemberList,
} from '../redux/actions/data/memberActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const DeleteMemberModal = ({ isOpen, toggle, onReload }) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');

  const handleSubmit = useCallback(async (phone='') => {
    setLoading(true);
    try{
      await ClearMemberInfo(Number(phone).toFixed(0))
      MessageUtil.alertSuccess('儲存成功')
      onReload()
    }catch (e){
      MessageUtil.alertWanring('儲存失敗',e)
    }
    setLoading(false);
  }, []);

  return (
    <Modal isOpen={isOpen} zIndex={2000}  centered>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        清除個資
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '100px' }}>
        <div className={style.formGroup}>
          <label className="w150 required">電話號碼</label>
          <Input
            id="memberNumber"
            type="tel"
            value={phone}
            onChange={e => {
              setPhone(e.target.value);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className={`me-3 ${style.modal_button_cancel}`}
          onClick={toggle}
        >
          取消
        </Button>

        <Button
          type="submit"
          className={style.modal_button_submit}
          onClick={()=>{
            handleSubmit(phone)
          }}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ClearMemberList = () => {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();
  const page_size = 10;
  const [current_page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [rangeType, setRangeType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [memberLevelSettingId, setMemberLevelSettingId] = useState({
    label: '全部',
    value: 0,
  });

  const { loading, memberListInfo } = useSelector(state => state.memberList);
  const { memberLevelOptions, loading: memberLevelOptionsLoading } =
    useSelector(state => state.getMemberLevelOptions);

  useEffect(() => {
    dispatch(getMemberLevelOptions());
  }, []);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query?.value?.keyword;
      const queryRangeType = query?.value?.rangeType;
      const queryStartDate = query?.value?.startDate;
      const queryEndDate = query?.value?.endDate;
      const queryPage = query?.value?.page;
      const queryMemberLevel = query?.value?.memberLevelSettingId || 0;
      if (queryPage) setCurrentPage(queryPage);

      if (queryKeyword) setKeyword(decodeURI(queryKeyword));
      else setKeyword('');

      if (queryRangeType) setRangeType(decodeURI(queryRangeType));
      else setRangeType('');

      if (queryStartDate) setStartDate(new Date(JSON.parse(queryStartDate)));
      else setStartDate('');

      if (queryEndDate) setEndDate(new Date(JSON.parse(queryEndDate)));
      else setEndDate('');

      setMemberLevelSettingId(
        memberLevelOptions.find(
          option => Number(option.value) === Number(queryMemberLevel),
        ) || { label: '全部', value: 0 },
      );
      loadMemberList(queryPage);
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setRangeType('');
      setStartDate(null);
      setEndDate(null);
      setMemberLevelSettingId({ label: '全部', value: 0 });
      loadMemberList(1);
    }
  }, [location?.search]);

  const loadMemberList = (page = 1) => {
    let params = {
      page,
      page_size,
    };

    if (keyword) {
      params['keyword'] = keyword;
    }
    if (rangeType) {
      params['type'] = rangeType;
    }
    if (startDate) {
      params['startTime'] = startDate;
    }
    if (endDate) {
      params['endTime'] = endDate;
    }
    if (memberLevelSettingId) {
      params['memberLevelSettingId'] = memberLevelSettingId.value;
    }
    dispatch(getMemberList({ ...params, isClearInfo: true }));
  };

  const handleSearch = useCallback(
    (page = 1) => {
      let url = `${location.pathname}?page=${page}&v=${new Date().getTime()}`;
      if (keyword) {
        url += `&keyword=${encodeURI(keyword)}`;
      }
      if (rangeType) {
        url += `&rangeType=${encodeURI(rangeType)}`;
      }
      if (startDate) {
        url += `&startDate=${moment(startDate).add(8, 'hours').toISOString()}`;
      }
      if (endDate) {
        url += `&endDate=${moment(endDate).add(8, 'hours').toISOString()}`;
      }
      if (memberLevelSettingId) {
        url += `&memberLevelSettingId=${memberLevelSettingId?.value || 0}`;
      }
      history.push(url);
    },
    [endDate, startDate, keyword, rangeType, memberLevelSettingId, history],
  );

  const clearSearchForm = useCallback(() => {
    setKeyword('');
    setRangeType('請選擇區間');
    setStartDate(null);
    setEndDate(null);
    setMemberLevelSettingId({ label: '全部', value: 0 });
    handleSearch(1);
  }, [handleSearch]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <Layout
      pageTitle="會員清單-清除個資"
      items={[{ isActive: true, page_name: '會員清單' }]}
    >
      <DeleteMemberModal
        isOpen={showDeleteModal}
        toggle={() => {
          setShowDeleteModal(false);
        }}
        onReload={() => {
          handleSearch(1);
        }}
      />
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <div className={style.show_flex}>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="keyword">
                關鍵字
              </label>
              <input
                type="text"
                className={`form-control ${style['form-input']}`}
                id="keyword"
                placeholder="電話、姓名、Email"
                value={keyword}
                onChange={e => setKeyword(e.target.value)}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="col-form-label mr-2" htmlFor="rangeType">
                區間查詢
              </label>
              <Input
                type="select"
                className={style['form-select']}
                value={rangeType}
                id="rangeType"
                onChange={e => setRangeType(e.target.value)}
                style={{ margin: 0 }}
              >
                <option value="">全部</option>
                <option value="註冊區間">註冊日期</option>
                <option value="消費區間">消費日期</option>
              </Input>
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="mr-2">日期區間</label>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={date => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
              />
              <span style={{ margin: '1rem' }}>~</span>
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={date => setEndDate(date)}
                minDate={startDate}
                dateFormat="yyyy/MM/dd"
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="mr-2">會員等級</label>
              <Select
                isLoading={memberLevelOptionsLoading}
                isDisabled={memberLevelOptionsLoading}
                className={style['form-select']}
                options={
                  memberLevelOptions && [
                    { label: '全部', value: 0 },
                    ...memberLevelOptions,
                  ]
                }
                value={memberLevelSettingId}
                onChange={option => setMemberLevelSettingId(option)}
              />
            </div>
            <div className={style.formGroup} style={{ marginRight: '1.5rem' }}>
              <label className="mr-2">會員來源</label>
              <Input
                type="select"
                className={style['form-select-short']}
                style={{ margin: 0 }}
              >
                <option value="">全部</option>
                <option value="線上">線上</option>
                <option value="線下">線下</option>
                <option value="匯入">匯入</option>
              </Input>
              {}
              <Input
                type="select"
                className={style['form-select']}
                style={{ margin: 0 }}
              >
                <option value="">全部</option>
                <option value="線下的UU門市">線下的UU門市</option>
              </Input>
            </div>
          </div>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={clearSearchForm}
          >
            取消
          </Button>
          <Button
            type="button"
            className={style.modal_button_submit}
            onClick={() => {
              handleSearch();
            }}
          >
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <div className={style.show_flex}>
          <Button
            color="success"
            className="me-3 mb-1"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            新增清除會員
          </Button>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && memberListInfo && memberListInfo.total_count > 0
              ? memberListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th scope="col">電話</th>
                  <th scope="col">
                    <div>姓名</div>
                  </th>
                  <th>生日</th>
                  <th scope="col">註冊日期</th>
                  <th scope="col">
                    <div>點數紅利</div>
                    <div>即將到期點數</div>
                  </th>
                  <th scope="col">歷史總消費金額</th>
                  <th scope="col">會員等級</th>
                  <th scope="col" style={{ minWidth: '168px' }}>
                    <div>會員等級起始日</div>
                    <div>會員等級到期日</div>
                    <div>級別累積金額</div>
                  </th>
                  <th>功能</th>
                </tr>
              </thead>
              {!loading && (
                <tbody>
                  {memberListInfo?.datas?.length > 0 ? (
                    memberListInfo.datas?.map(data => (
                      <tr key={JSON.stringify(data)}>
                        <td nowrap>
                          <span className="text-nowrap">
                            {data.countryCode === '886'
                              ? `0${data.phone}`
                              : data.phone}
                          </span>
                        </td>
                        <td>
                          <div>{`${data.lastName || '--'} ${
                            data.firstName || '--'
                          }`}</div>
                        </td>
                        <td>
                          <div>{data.birthday || '--'}</div>
                        </td>
                        <td nowrap="nowrap">{data.registerDate || '--'}</td>
                        <td className="text-center">
                          {/* 紅利點數/即將到期點數 */}
                          <div>{data.point.toLocaleString('en-US')}</div>
                          <div>
                            {data.expiringPoint.toLocaleString('en-US')}
                          </div>
                        </td>
                        <td className="text-center price fw-normal text-black">
                          {/* 總消費金額 */}
                          {`${data.accumulateAmountTotal.toLocaleString(
                            'en-US',
                          )}`}
                        </td>
                        <td className="text-center">
                          {/* 會員等級 */}
                          {data?.memberLevelList?.map((info, index) => (
                            <div style={{ marginBottom: '4rem' }} key={index}>
                              {info.memberLevelName}
                            </div>
                          ))}
                        </td>
                        <td>
                          {/* 會員等級起始日/會員等級到期日/級別累積金額 */}
                          {data?.memberLevelList?.map((info, index) => (
                            <div className="mb-3 text-center" key={index}>
                              <div>
                                {info.memberDateStart
                                  ? moment(info.memberDateStart).format(
                                      'YYYY/MM/DD HH:mm:ss',
                                    )
                                  : '--'}
                              </div>
                              <div>
                                {info.memberDateEnd
                                  ? moment(info.memberDateEnd).format(
                                      'YYYY/MM/DD HH:mm:ss',
                                    )
                                  : '--'}
                              </div>
                              <div className="price fw-normal text-black">
                                {info.accumulateAmount?.toLocaleString('en-US')}
                              </div>
                            </div>
                          ))}
                        </td>
                        <td>
                          <Link
                              className="m-2 btn btn-outline-primary btn-sm"
                              to={`/MemberDetails/${data.id}?type=isClearInfo`}
                          >
                            會員資料
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
              {loading && <TableLoading />}
            </Table>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={memberListInfo?.current_page || 1}
            defaultPageSize={10}
            total={memberListInfo?.total_count || 0}
            onChange={handleSearch}
          />
        </div>
      </Card>
    </Layout>
  );
};

export default ClearMemberList;
