const chineseNumberMap = {
  0: '零',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
};

const chineseUnitMap = {
  1: '',
  10: '十',
  100: '百',
  1000: '千',
};

function numberToChinese(number) {
  if (number === 0) {
    return chineseNumberMap[number];
  }

  const digits = Array.from(String(number), Number);
  const digitsLength = digits.length;
  let chineseRepresentation = '';

  for (let i = 0; i < digitsLength; i++) {
    const digit = digits[i];
    const position = digitsLength - i - 1;

    if (digit !== 0) {
      chineseRepresentation +=
        chineseNumberMap[digit] + chineseUnitMap[10 ** position];
    } else {
      if (i === digitsLength - 1 || digits[i + 1] !== 0) {
        chineseRepresentation += chineseNumberMap[digit];
      }
    }
  }

  return chineseRepresentation;
}

export default numberToChinese;
