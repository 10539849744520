import {
  ADD_ACTIVITY_FAIL,
  ADD_ACTIVITY_IDLE,
  ADD_ACTIVITY_REQUEST,
  ADD_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  DELETE_ACTIVITY_IDLE,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  GET_ACTIVITY_CATE_OPTIONS_FAIL,
  GET_ACTIVITY_CATE_OPTIONS_REQUEST,
  GET_ACTIVITY_CATE_OPTIONS_SUCCESS,
  GET_ACTIVITY_LIST_FAIL,
  GET_ACTIVITY_LIST_REQUEST,
  GET_ACTIVITY_LIST_SUCCESS,
  GET_ACTIVITY_PRODUCTLIST_FAIL,
  GET_ACTIVITY_PRODUCTLIST_REQUEST,
  GET_ACTIVITY_PRODUCTLIST_SUCCESS,
  GET_ACTIVITY_REAPTED_PRODUCTS_FAIL,
  GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST,
  GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS,
  GET_ONE_ACTIVITY_FAIL,
  GET_ONE_ACTIVITY_REQUEST,
  GET_ONE_ACTIVITY_SUCCESS,
  SORT_ACTIVITY_FAIL,
  SORT_ACTIVITY_IDLE,
  SORT_ACTIVITY_REQUEST,
  SORT_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  UPDATE_ACTIVITY_IDLE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
} from 'redux/constants/data/discountCompaignContants';

export const getActivityCateOptionsReducer = (
  state = { cateOptions: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIVITY_CATE_OPTIONS_REQUEST:
      return { cateOptions: [], error: null, loading: true, success: false };
    case GET_ACTIVITY_CATE_OPTIONS_SUCCESS:
      return { ...state, cateOptions: payload, loading: false, success: true };
    case GET_ACTIVITY_CATE_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getActivityListReducer = (
  state = { activityList: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIVITY_LIST_REQUEST:
      return { activityList: [], error: null, loading: true, success: false };
    case GET_ACTIVITY_LIST_SUCCESS:
      return { ...state, activityList: payload, loading: false, success: true };
    case GET_ACTIVITY_LIST_FAIL:
      return { ...state, activityList: [], error: payload, loading: false };
    default:
      return state;
  }
};

export const getActivityProductListReducer = (
  state = { error: null, loading: false, productList: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIVITY_PRODUCTLIST_REQUEST:
      return { error: null, loading: true, productList: [], success: false };
    case GET_ACTIVITY_PRODUCTLIST_SUCCESS:
      return { ...state, loading: false, productList: payload, success: true };
    case GET_ACTIVITY_PRODUCTLIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const addActivityReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_ACTIVITY_IDLE:
      return { error: null, loading: false, success: false };
    case ADD_ACTIVITY_REQUEST:
      return { ...state, loading: true };
    case ADD_ACTIVITY_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateActivityReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ACTIVITY_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_ACTIVITY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_ACTIVITY_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOneActivityReducer = (
  state = { data: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_ACTIVITY_REQUEST:
      return { data: {}, error: null, loading: true, success: false };
    case GET_ONE_ACTIVITY_SUCCESS:
      return { ...state, data: payload, loading: false, success: true };
    case GET_ONE_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const deleteActivityReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_ACTIVITY_IDLE:
      return { error: null, loading: false, success: false };
    case DELETE_ACTIVITY_REQUEST:
      return { ...state, loading: true };
    case DELETE_ACTIVITY_SUCCESS:
      return { ...state, loading: false, success: true };
    case DELETE_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getActivityReaptedProductReducer = (
  state = { error: null, loading: false, reaptedProducts: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST:
      return {
        error: null,
        loading: true,
        reaptedProducts: [],
        success: false,
      };
    case GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS:
      return { ...state, reaptedProducts: payload, success: true };
    case GET_ACTIVITY_REAPTED_PRODUCTS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const sortActivityReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SORT_ACTIVITY_IDLE:
      return { error: null, loading: false, success: false };
    case SORT_ACTIVITY_REQUEST:
      return { ...state, loading: true };
    case SORT_ACTIVITY_SUCCESS:
      return { ...state, loading: false, success: true };
    case SORT_ACTIVITY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
