import moment from 'moment/moment';
import React, { memo, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Alert, Button, Input, Table } from 'reactstrap';

import { baseURL, prefix_api_path } from '../../redux/actions/data/api_config';
import style from '../../styles/layout.module.css';
import request from '../../utils/useAxios';
import TableHasNoData from '../TableHasNoData';
import UploadFileBox from '../UploadFileBox';

import { DisplaySettings } from './DisplaySettings';
import { ConditionsValue1 } from './FormInput';

export const BasicSettings = ({
  activityType,
  values,
  setFieldValue,
  errors,
  touched,
  onToggleModal,
}) => {
  const initDate = moment().add(5, 'minute').toDate();
  const { data: activityData } = useSelector(state => state.getOneActivity);
  const { options, loading: optionsLoading } = useSelector(
    state => state.getProductCategoryOptions,
  );

  const [cat2Options, setCat2Options] = useState([]);

  const res = useCallback(async () => {
    const { result } = await request({
      method: 'get',
      url: `${baseURL}${prefix_api_path}/Backend/Product/category/cat2s?parentId=${values.category1.value}`,
    });
    setCat2Options(result);
  }, [setCat2Options, values?.category1?.value]);

  useEffect(() => {
    res();
  }, [res]);

  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterPassedTime30Min = time => {
    const currentDate = values.startDate
      ? moment(values.startDate).add(29, 'minutes').toDate()
      : moment().add(29, 'minutes').toDate();
    const selectedDate = moment(time).toDate();

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div>
      {values.catCode == 11 && (
        <div className={style.formGroup}>
          <label className="w150">活動對象</label>
          <span>全體會員</span>
        </div>
      )}
      <div className={style.formGroup}>
        <label className="w150">活動類型</label>
        <div>{activityType}</div>
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">活動名稱</label>
        <Input
          className={style['form-keyword']}
          value={values.name}
          onChange={event => {
            setFieldValue('name', event.target.value);
          }}
          maxLength={60}
        />
        <small className="ml-2 text-nowrap">({values.name.length}/60)</small>
        {errors.name && touched.name && (
          <span className="ml-2 text-danger">{errors.name}</span>
        )}
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">活動名稱(英)</label>
        <Input
          className={style['form-keyword']}
          value={values.name_en}
          onChange={event => {
            setFieldValue('name_en', event.target.value);
          }}
          maxLength={60}
        />
        <small className="ml-2 text-nowrap">({values.name_en.length}/60)</small>
        {errors.name_en && touched.name_en && (
          <span className="ml-2 text-danger">{errors.name_en}</span>
        )}
      </div>
      <div className={style.formGroup}>
        <label className="w150 required">活動時間</label>
        <div className={style['form-select']}>
          <DatePicker
            className="form-control"
            dateFormat={'yyyy/MM/dd HH:mm'}
            showTimeSelect
            selected={values.startDate ? moment(values.startDate).toDate() : ''}
            onChange={date => {
              if (moment(date).toDate() < initDate) {
                setFieldValue('startDate', initDate);
              } else {
                setFieldValue('startDate', date);
              }
            }}
            timeIntervals={15}
            filterTime={filterPassedTime}
            minDate={initDate}
            disabled={values.status === 1}
          />
          {errors.startDate && touched.startDate && (
            <span className="ml-2 text-danger">{errors.startDate}</span>
          )}
        </div>
        <span style={{ margin: '1rem' }}>~</span>
        <div className={style['form-select']}>
          <DatePicker
            className="form-control"
            dateFormat={'yyyy/MM/dd HH:mm'}
            showTimeSelect
            selected={values.endDate ? moment(values.endDate).toDate() : ''}
            onChange={date => {
              if (moment(date).isBefore(moment())) {
                setFieldValue('endDate', moment().add(1, 'minute').toDate());
              } else {
                setFieldValue('endDate', date);
              }
            }}
            timeIntervals={15}
            filterTime={filterPassedTime30Min}
            minTime={
              moment(values?.endDate || initDate).format('YYYY-MM-DD') ===
              moment(initDate).format('YYYY-MM-DD')
                ? initDate
                : moment().year(9999).hour(0).minute(0).second(0).toDate()
            }
            maxTime={moment()
              .year(9999)
              .hour(23)
              .minute(59)
              .second(59)
              .toDate()}
            minDate={
              moment(values.startDate || initDate).isAfter(initDate)
                ? moment(values.startDate).toDate()
                : initDate
            }
          />
          {errors.endDate && touched.endDate && (
            <span className="ml-2 text-danger">{errors.endDate}</span>
          )}
        </div>
      </div>
      {values.catCode != 1 &&
        values.objectType !== 'regular' &&
        values.catCode != 11 && (
          <DisplaySettings values={values} setFieldValue={setFieldValue} />
        )}
      {activityType !== '限時折扣' && values.catCode !== 11 && (
        <div className={style.formGroup}>
          <label className={`w150${values.isShow ? ' required' : ''}`}>
            商城活動圖
            {errors.currentStorePic && touched.currentStorePic && (
              <span className="ml-2 text-danger">{errors.currentStorePic}</span>
            )}
          </label>
          <div>
            <UploadFileBox
              setFormFieldValue={setFieldValue}
              formFieldName="storePic"
              currentFileFieldName="currentStorePic"
              // isDelete="isDelete"
              // isUpload="isUpload"
              // isDragNDrop="isDragNDrop"
              uplodModel="images"
              displayFileWidth="450"
              displayFileHeight="450"
              displayFileSize="250KB"
              initFiles={values.initStorePic}
            />
          </div>
        </div>
      )}
      {activityType !== '限時折扣' && values.catCode !== 11 && (
        <div className={style.formGroup}>
          <label className={`w150${values.isShow ? ' required' : ''}`}>
            活動頁主圖(桌機)
            {errors.initActivityPic && touched.initActivityPic && (
              <span className="ml-2 text-danger">{errors.initActivityPic}</span>
            )}
          </label>
          <div>
            <UploadFileBox
              setFormFieldValue={setFieldValue}
              formFieldName="activityPic"
              currentFileFieldName="currentActivityPic"
              uplodModel="images"
              displayFileWidth="1920"
              displayFileHeight="不限"
              displayFileSize="3M"
              initFiles={values.initActivityPic}
            />
          </div>
        </div>
      )}
      {activityType !== '限時折扣' && values.catCode !== 11 && (
        <div className={style.formGroup}>
          <label className={`w150${values.isShow ? ' required' : ''}`}>
            活動頁主圖(手機)
            {errors.currentActivityPicMoblie &&
              touched.currentActivityPicMoblie && (
                <span className="ml-2 text-danger">
                  {errors.currentActivityPicMoblie}
                </span>
              )}
          </label>
          <div>
            <UploadFileBox
              setFormFieldValue={setFieldValue}
              formFieldName="activityPicMoblie"
              currentFileFieldName="currentActivityPicMoblie"
              // activityImg="activityPicMoblie"
              // isDelete="isDelete"
              // isUpload="isUpload"
              // isDragNDrop="isDragNDrop"
              uplodModel="images"
              displayFileWidth="800"
              displayFileHeight="不限"
              displayFileSize="3M"
              initFiles={values.initActivityPicMoblie}
            />
          </div>
        </div>
      )}
      {activityType !== '限時折扣' && values.catCode !== 11 && (
        <div className={style.formGroup}>
          <label className={`w150${values.isShow ? ' required' : ''}`}>
            <div>活動頁</div>
            <div>主選單圖標顏色</div>
          </label>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
              marginRight: '3rem',
            }}
          >
            <input
              type="radio"
              checked={values.menuColor === 'white'}
              style={{ height: '20px', width: '20px' }}
              onChange={() => {
                setFieldValue('menuColor', 'white');
              }}
            />
            <span className="ml-2">白色</span>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'left',
            }}
          >
            <input
              type="radio"
              checked={values.menuColor === 'black'}
              style={{ height: '20px', width: '20px' }}
              onChange={() => {
                setFieldValue('menuColor', 'black');
              }}
            />
            <span className="ml-2">黑色</span>
          </div>
        </div>
      )}
      {activityType !== '限時折扣' && (
        <React.Fragment>
          <div className={style.formGroup} />
          <div className={style.formGroup}>
            <label className="w150 required">
              活動說明
              {errors.desc && touched.desc && (
                <span className="ml-2 text-danger">{errors.desc}</span>
              )}
            </label>
            <Input
              type="textarea"
              value={values.desc}
              onChange={event => {
                setFieldValue('desc', event.target.value);
              }}
              style={{ height: '120px' }}
              maxLength={400}
            />
            <small className="ml-2" style={{ whiteSpace: 'nowrap' }}>
              ({values?.desc?.length}/400)
            </small>
          </div>
          <div className={style.formGroup}>
            <label className="w150 required">
              活動說明(英)
              {errors.desc_en && touched.desc_en && (
                <span className="ml-2 text-danger">{errors.desc_en}</span>
              )}
            </label>
            <Input
              type="textarea"
              value={values.desc_en}
              onChange={event => {
                setFieldValue('desc_en', event.target.value);
              }}
              style={{ height: '120px' }}
              maxLength={400}
            />
            <small
              className="ml-2"
              style={{ whiteSpace: 'nowrap' }}
            >{`(${values?.desc_en?.length}/400)`}</small>
          </div>
        </React.Fragment>
      )}
      {values.catCode == 11 && (
        <React.Fragment>
          <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
            <label className="w150 required">滿額門檻</label>
            <div>
              {values.conditions.map((i, index) => (
                <div className={style.formGroup} key={index}>
                  <label>滿NT$</label>
                  <ConditionsValue1
                    placeholder="請輸入金額"
                    values={values}
                    index={index}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    disabled={values.status === 1}
                  />
                  <label className="ml-2">贈送，</label>
                  <Input
                    className={style['form-input']}
                    value={values.conditions[index].freebieCode}
                    onChange={event => {
                      setFieldValue(
                        `conditions[${index}].freebieCode`,
                        event.target.value,
                      );
                    }}
                    placeholder="請輸入贈品序號"
                    disabled={values.status === 1}
                    invalid={
                      errors?.conditions?.length > 0 &&
                      errors.conditions[index]?.freebieCode &&
                      touched.conditions
                    }
                  />
                  {index === 0 && (
                    <Button
                      color="primary"
                      outline
                      className="ml-2 btn-sm"
                      onClick={() => {
                        window.open('/#/GiftManagement', '_blank');
                      }}
                    >
                      查詢贈品
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button
                      color="danger"
                      outline
                      className="ml-2 btn-sm"
                      disabled={values.status === 1}
                      onClick={() => {
                        setFieldValue(
                          'conditions',
                          values.conditions.filter((c, i) => i !== index),
                        );
                      }}
                    >
                      刪除
                    </Button>
                  )}
                </div>
              ))}
              <Button
                color="primary"
                outline
                className="btn-sm"
                onClick={() => {
                  if (values.conditions.length < 3) {
                    setFieldValue('conditions', [
                      ...values.conditions,
                      { freebieCode: '', value1: '' },
                    ]);
                  }
                }}
                disabled={values.status === 1}
              >
                新增條件
              </Button>
              <span className="ml-2">(門檻請由低到高設定，最高限制為三階)</span>
            </div>
          </div>
        </React.Fragment>
      )}
      {values.catCode == 11 && (
        <React.Fragment>
          <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
            <label className="w150 required">
              活動範圍
              {errors.globalCat && touched.globalCat && (
                <span className="text-danger ml-2">{errors.globalCat}</span>
              )}
            </label>
            <div>
              <div className={style.show_flex}>
                <div className={style['radio-btn-layout']}>
                  <input
                    className={style['radio-btn']}
                    type="radio"
                    checked={values.range === 'all'}
                    onChange={() => {
                      setFieldValue('range', 'all');
                    }}
                    disabled={values.status === 1}
                  />
                  <span>全店商品</span>
                </div>
                <div className={style['rwd-w100']} />
                <div className={style['radio-btn-layout']}>
                  <input
                    className={style['radio-btn']}
                    type="radio"
                    checked={values.range === 'pickedbyCat'}
                    onChange={() => {
                      setFieldValue('range', 'pickedbyCat');
                    }}
                    disabled={values.status === 1}
                  />
                  <span>指定商品分類</span>
                  {errors.globalCat && (
                    <div className="form-error">{errors.globalCat}</div>
                  )}
                </div>
              </div>
              <small>
                <li>
                  指定商品分類：活動開始前類別可增加或減少，活動開始後無法增減類別
                </li>
              </small>
              {values.range === 'pickedbyCat' && (
                <React.Fragment>
                  <div className={style.formGroup}>
                    <div>
                      <div className={style.formGroup}>
                        <Select
                          isLoading={optionsLoading}
                          isDisabled={optionsLoading}
                          className={style['form-select']}
                          style={{ margin: 0 }}
                          options={options}
                          onChange={option => {
                            setFieldValue('category1', option);
                            setFieldValue('category2', {
                              label: '全部',
                              value: 0,
                            });
                          }}
                          value={values.category1}
                        />
                        <Select
                          className={style['form-select']}
                          style={{ margin: 0 }}
                          options={cat2Options}
                          onChange={option => {
                            setFieldValue('category2', option);
                          }}
                          value={values.category2}
                        />
                        <Button
                          className="btn-sm ml-2"
                          color="primary"
                          outline
                          onClick={() => {
                            setFieldValue('globalCat', [
                              ...values.globalCat,
                              {
                                cat1: values.category1,
                                cat2: values.category2,
                              },
                            ]);
                            setFieldValue('category1', {
                              label: '請選擇',
                              value: 0,
                            });
                            setFieldValue('category2', {
                              label: '全部',
                              value: 0,
                            });
                          }}
                          disabled={
                            values.status === 1 || values.category1.value == 0
                          }
                        >
                          新增
                        </Button>
                      </div>
                      <Alert color="secondary">
                        <div className={style.show_flex}>
                          {values.globalCat.map((item, i) => (
                            <div
                              key={i}
                              style={{
                                alignItems: 'center',
                                backgroundColor: '#8155BA',
                                borderRadius: '8px',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                margin: '0.5rem',
                                minWidth: '150px',
                                padding: '0.5rem',
                              }}
                            >
                              <div
                                style={{ marginRight: '1rem' }}
                              >{`${item.cat1.label}/${item.cat2.label}`}</div>
                              {values.status !== 1 && (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setFieldValue(
                                      'globalCat',
                                      values.globalCat.filter(
                                        cat => cat !== item,
                                      ),
                                    );
                                  }}
                                >
                                  X
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Alert>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      {values.catCode == 11 && (
        <React.Fragment>
          <div className={style.formGroup} style={{ alignItems: 'flex-start' }}>
            <label className="required w150">
              設定必購商品
              {errors.models && touched.models && (
                <span className="text-danger ml-2">{errors.models}</span>
              )}
            </label>
            <div>
              <div className={style.formGroup}>
                <div className={style['radio-btn-layout']}>
                  <input
                    type="radio"
                    className={style['radio-btn']}
                    checked={values.hasFreebieMustBuy}
                    onChange={() => {
                      setFieldValue('hasFreebieMustBuy', true);
                    }}
                    disabled={values.status === 1}
                  />
                  <span className="ml-2">是</span>
                </div>
                <div className={style['rwd-w100']} />
                <div className={style['radio-btn-layout']}>
                  <input
                    type="radio"
                    className={style['radio-btn']}
                    checked={values.hasFreebieMustBuy === false}
                    onChange={() => {
                      setFieldValue('hasFreebieMustBuy', false);
                    }}
                    disabled={values.status === 1}
                  />
                  <span className="ml-2">否</span>
                </div>
              </div>
              {values.hasFreebieMustBuy === true && (
                <React.Fragment>
                  <Button
                    className="btn-sm mb-3"
                    color="primary"
                    outline
                    onClick={onToggleModal}
                  >
                    新增必購商品
                  </Button>
                  <div>
                    <h5>必購商品</h5>
                    <div className="table-responsive">
                      <Table bordered striped hover>
                        <thead className={`thead-dark ${style.table_head}`}>
                          <tr>
                            <th style={{ minWidth: '150px' }}>商品名稱</th>
                            <th style={{ minWidth: '150px' }}>商品料號</th>
                            <th>規格</th>
                            <th>商品是否上架</th>
                            <th>刪除</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values?.models?.length > 0 ? (
                            values.models.map((temp, index) =>
                              temp?.products?.map((product, pIndex) => (
                                <tr key={index + pIndex}>
                                  {pIndex === 0 && (
                                    <td rowSpan={temp.products.length}>
                                      {temp.modelName}
                                    </td>
                                  )}
                                  <td className="text-center">
                                    <div className={style.show_flex}>
                                      <label>{product.productcode}</label>
                                    </div>
                                  </td>
                                  <td>{`${product.color || '--'}/${
                                    product.size || '--'
                                  }`}</td>
                                  <td>{product.isSell ? '是' : '否'}</td>
                                  {values.catCode == 1 && (
                                    <td className="price fw-normal text-black text-center">
                                      {product.sellprice.toLocaleString(
                                        'en-US',
                                      )}
                                    </td>
                                  )}
                                  {values.catCode == 1 && (
                                    <td>
                                      <Input
                                        type="number"
                                        className={style['form-input-short']}
                                        onChange={event => {
                                          setFieldValue(
                                            `models[${index}].products[${pIndex}].discountPrice`,
                                            event.target.value,
                                          );
                                        }}
                                        value={
                                          values?.models[index]?.products
                                            ?.length > 0
                                            ? values?.models[index]?.products[
                                                pIndex
                                              ]?.discountPrice
                                            : 0
                                        }
                                      />
                                    </td>
                                  )}
                                  <td>
                                    {values.latestList?.find(model =>
                                      model.products?.find(
                                        prd =>
                                          prd.productcode ===
                                          product.productcode,
                                      ),
                                    ) && (
                                      <Button
                                        className="btn-sm"
                                        color="danger"
                                        outline
                                        onClick={() => {
                                          const newArr = values.models[
                                            index
                                          ].products.filter(
                                            p =>
                                              p.productcode !==
                                                product.productcode ||
                                              p.area !== product.area,
                                          );
                                          if (newArr.length > 0) {
                                            let newTemp = values.models;
                                            newTemp[index].products = newArr;
                                            setFieldValue('models', newTemp);
                                          } else {
                                            // 沒有商品, 刪除 model
                                            setFieldValue(
                                              'models',
                                              values.models.filter(
                                                t =>
                                                  t?.mid !== temp?.mid ||
                                                  t?.area !== temp?.area,
                                              ),
                                            );
                                          }
                                        }}
                                      >
                                        刪除
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                              )),
                            )
                          ) : (
                            <TableHasNoData />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default memo(BasicSettings);
