import { useFormik } from 'formik';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
  getActivityReaptedProduct,
  getOneActivity,
} from 'redux/actions/data/discountCompaignActions';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import { UPDATE_ACTIVITY_IDLE } from 'redux/constants/data/discountCompaignContants';
import { formatImagesArr } from 'utils';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import useQuery from 'utils/useQuery';

import Layout from '../components/Layout';
import BasicSettings from '../components/compaignSettingPage/ViewBasicSettings';
import DiscountConditionSettings from '../components/compaignSettingPage/ViewDiscountConditionSettings';
import OthersSettings from '../components/compaignSettingPage/ViewOthersSettings';
import style from '../styles/layout.module.css';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
};

const ViewCompaignSettingPage = () => {
  const initData = useMemo(() => {
    const initDate = moment().add(5, 'minute').toDate();
    return {
      activityPic: [],
      activityPicMoblie: [],
      area: [
        {
          area: 1,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 2,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 3,
          areaName: '',
          areaName_en: '',
        },
      ],
      catCode: 0,
      category1: { label: '請選擇', value: 0 },
      category2: { label: '全部', value: 0 },
      condition: null,
      condition_fixedTPrice: 0,
      conditions: [{ freebieCode: '', value1: '', value2: '' }],
      conditionsArea: [
        {
          area: 1,
          value1: '',
          value2: '',
        },
        {
          area: 2,
          value1: '',
          value2: '',
        },
        {
          area: 3,
          value1: '',
          value2: '',
        },
      ],
      couponEnabled: true,
      currentTab: 1,
      desc: '',
      desc_en: '',
      endDate: moment(initDate).add(30, 'minute').toDate(),
      gateway: ['web'],
      globalCat: [],
      hasFreebieMustBuy: false,
      isExtraProductRange: false,
      isShow: true,
      memberLevel: 0,
      menuColor: 'white',
      modelType: '一般商品',
      models: [],
      name: '',
      name_en: '',
      objectType: '',
      originPriceA: false,
      originPriceB: false,
      originPriceC: false,
      range: 'all',
      startDate: initDate,
      storePic: [],
      target: 'all',
      type: '',
    };
  }, []);

  const query = useQuery();
  const id = query.value.id;

  // const [activityName, setActivityName] = useState('');
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productType, setProductType] = useState(null);
  const toggleProductModal = () => {
    setIsProductModalOpen(!isProductModalOpen);
  };

  const { data: activityData } = useSelector(state => state.getOneActivity);
  const dispatch = useDispatch();
  const catCode = query.value.cateCode || query.value.catCode;

  const { values, setValues, touched, errors, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: initData,
      onSubmit: () => {},
    });

  const { success, loading, error } = useSelector(
    state => state.updateActivity,
  );

  useEffect(() => {
    dispatch(getOneActivity(id));
    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_ACTIVITY_IDLE });
      dispatch(getOneActivity(id));
    }
    if (error) {
      MessageUtil.alertWanring('更新失敗', error);
      dispatch({ type: UPDATE_ACTIVITY_IDLE });
    }
  }, [success, error]);

  useEffect(() => {
    if (activityData) {
      if (JSON.stringify(activityData) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: activityData });
      }
    }
  }, [activityData]);

  useEffect(() => {
    dispatch(
      getActivityReaptedProduct({
        _object: values.target,
        catCode: values.catCode,
        endTime: values.endDate
          ? moment(values.endDate).format('YYYY/MM/DD HH:mm')
          : null,
        id: id,
        objectMLevelId: values.memberLevel,
        startTime: values.startDate
          ? moment(values.startDate).format('YYYY/MM/DD HH:mm')
          : null,
      }),
    );
  }, [
    values.target,
    values.memberLevel,
    values.startDate,
    values.endDate,
    catCode,
  ]);

  useEffect(() => {
    setValues({
      ...activityData,
      activityPic: [],
      activityPicMoblie: [],
      area: activityData?.areas || [],
      catCode: activityData.catCode,
      category1: { label: '請選擇', value: 0 },
      category2: { label: '全部', value: 0 },
      condition: activityData.condition,
      condition_fixedTPrice: activityData.condition_fixedTPrice,
      conditions: activityData.conditions,
      conditionsArea: activityData.conditions,
      couponEnabled: activityData.isDiscountAvailable,
      currentActivityPic: [],
      currentActivityPicMoblie: [],
      currentStorePic: [],
      desc: activityData.desc || '',
      desc_en: activityData.desc_en || '',
      endDate: activityData.endTime,
      gateway: activityData.availableWays,
      globalCat:
        activityData?.globalCategoies?.map(data => ({
          cat1: {
            label: data.name,
            value: data.id,
          },
          cat2: {
            label: data.cat2Name || '全部',
            value: data.cat2Id,
          },
        })) || [],
      globleCatIds: activityData.globleCatIds || [],
      hasFreebieMustBuy: activityData.hasFreebieMustBuy,
      initActivityPic: activityData?.pageImg_dInfo
        ? formatImagesArr([activityData.pageImg_dInfo])
        : [],
      initActivityPicMoblie: activityData.pageImg_mInfo
        ? formatImagesArr([activityData.pageImg_mInfo])
        : [],
      initStorePic: activityData?.coverImgInfo
        ? formatImagesArr([activityData.coverImgInfo])
        : [],
      isExtraProductRange: activityData.isExtraProductRange,
      isShow: activityData.isShow,
      markColor: activityData.markColor ?? 'white',
      memberLevel: activityData.objectMLevelId,
      menuColor: values.menuColor ?? 'white',
      modelType:
        activityData.groupProducts?.length > 0 ? '一般商品' : '組合商品',
      models: [
        ...(activityData?.products || []).map(m => {
          return {
            ...m,
            modelType: '一般商品',
          };
        }),
        ...(activityData?.groupProducts || []).map(m => {
          return {
            ...m,
            modelType: '組合商品',
          };
        }),
      ],
      name: activityData.name || '',
      name_en: activityData.name_en || '',
      objectType: activityData.objectType,
      originPriceA:
        activityData?.conditions?.length > 0
          ? activityData.conditions[0]?.value2 === null &&
            activityData.condition === 'separate'
            ? true
            : false
          : false,
      originPriceB:
        activityData?.conditions?.length > 0
          ? activityData.conditions[1]?.value2 === null &&
            activityData.condition === 'separate'
            ? true
            : false
          : false,
      originPriceC:
        activityData?.conditions?.length > 0
          ? activityData.conditions[2]?.value2 === null &&
            activityData.condition === 'separate'
            ? true
            : false
          : false,
      range: activityData.productRange,
      startDate: activityData.startTime,
      status: values.status,
      storePic: [],
      target: activityData._object,
      type: activityData.catName,
    });
  }, [activityData]);

  const limit = Number(catCode) >= 5 ? 5 : 10;

  return (
    <Layout
      pageTitle={`編輯折扣活動-${values.type}`}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: true, page_name: '折扣活動' },
        { isActive: false, page_name: `編輯折扣活動-${values.type}` },
      ]}
    >
      <Card className={style.card}>
        <h4 className="title" style={titleStyle}>
          活動基本設定
        </h4>
        <BasicSettings
          activityType={values.type}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onToggleModal={toggleProductModal}
        />
        {values.catCode != 11 && (
          <React.Fragment>
            <h4 className="title" style={titleStyle}>
              折抵條件設定
            </h4>
            <DiscountConditionSettings
              activityType={values.type}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              onToggleModal={toggleProductModal}
              onSetProductType={setProductType}
              limit={limit}

              // tempList={tempProductList}
            />
          </React.Fragment>
        )}
        {values.catCode != 11 && (
          <React.Fragment>
            <h4 className="title" style={titleStyle}>
              其他行銷活動設定
            </h4>
            <OthersSettings
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </React.Fragment>
        )}
        <div
          className={style.formGroup}
          style={{ marginBottom: 0, marginTop: '1rem' }}
        >
          <div style={{ marginRight: '3rem' }}>
            {`新增時間：${activityData.createTime || '--'} ${
              activityData.creator || '--'
            }`}
          </div>
          <div>
            {`異動時間：${activityData.updateTime || '--'} ${
              activityData.updater || '--'
            }`}
          </div>
        </div>
        <hr />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={() => {
              history.goBack();
            }}
          >
            返回
          </Button>
        </div>
      </Card>
    </Layout>
  );
};

export default ViewCompaignSettingPage;
