import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

import CommonUtil from '../utils/CommonUtil';

const NewsLink = ({ initContents, setFormFieldValue, formFieldName }) => {
  const [currentContents, setCurrentContents] = useState(initContents);
  const removeLink = (index) => {
    if(currentContents.length){
      currentContents.splice(index, 1);
    }
    setCurrentContents([...currentContents]);
  };

  const addLink = () => {
    setCurrentContents([
      ...currentContents,
      {
        id: CommonUtil.getNewID(),
        linkUrl: '',
        name: '',
        name_en: '',
      },
    ]);
  };

  const handleChange = e => {
    let dataId = e.target.id;
    let dataType = e.target.dataset.type;
    let dataValue = e.target.value;
    setCurrentContents(
      currentContents.map(data => {
        if (data.id === dataId) {
          if (dataType === 'name') {
            data.name = dataValue;
          }
          if (dataType === 'name_en') {
            data.name_en = dataValue;
          }
          if (dataType === 'linkUrl') {
            data.linkUrl = dataValue;
          }
        }
        return data;
      }),
    );
  };

  useEffect(() => {
    setFormFieldValue(formFieldName, currentContents);
  }, [currentContents]);

  useEffect(() => {
    if (initContents && initContents.length > 0) {
      setCurrentContents(initContents);
    }
  }, [initContents]);

  return (
    <div>
      {currentContents?.map((item, index) => (
        <div key={`${JSON.stringify(item)}_${  index}`}>
          <div className="d-flex">
            <div className="flex-1">
              <div className="d-flex align-items-center mb-2">
                <label className="me-2">中文</label>
                <input
                  id={item.id}
                  data-type="name"
                  defaultValue={item.name}
                  onChange={(e)=>{
                    item.name = e.target.value;
                  }}
                  onBlur={handleChange}
                  type="text"
                  className="form-control flex-1"
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="me-2">英文</label>
                <input
                  id={item.id}
                  data-type="name_en"
                  defaultValue={item.name_en}
                  onChange={(e)=>{
                    item.name_en = e.target.value;
                  }}
                  onBlur={handleChange}
                  type="text"
                  className="form-control flex-1"
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <label className="me-2">網址</label>
                <input
                  id={item.id}
                  data-type="linkUrl"
                  defaultValue={item.linkUrl}
                  onChange={(e)=>{
                    item.linkUrl = e.target.value;
                  }}
                  onBlur={handleChange}
                  type="text"
                  className="form-control flex-1"
                />
              </div>
              <hr />
            </div>
            <button
              type="button"
              onClick={() => removeLink(item.id)}
              className="btn btn-danger align-self-start btn-sm"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      ))}
      <button
        onClick={() => addLink()}
        type="button"
        className="btn btn-success"
      >
        新增連結
      </button>
    </div>
  );
};

export default NewsLink;
