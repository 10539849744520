export const GET_ACTIVITY_CATE_OPTIONS_REQUEST =
  'GET_ACTIVITY_CATE_OPTIONS_REQUEST';
export const GET_ACTIVITY_CATE_OPTIONS_SUCCESS =
  'GET_ACTIVITY_CATE_OPTIONS_SUCCESS';
export const GET_ACTIVITY_CATE_OPTIONS_FAIL = 'GET_ACTIVITY_CATE_OPTIONS_FAIL';

export const GET_ACTIVITY_LIST_REQUEST = 'GET_ACTIVITY_LIST_REQUEST';
export const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS';
export const GET_ACTIVITY_LIST_FAIL = 'GET_ACTIVITY_LIST_FAIL';

export const CREATE_ACTIVITY_IDLE = 'CREATE_ACTIVITY_IDLE';
export const CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAIL = 'CREATE_ACTIVITY_FAIL';

export const UPDATE_ACTIVITY_IDLE = 'UPDATE_ACTIVITY_IDLE';
export const UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAIL = 'UPDATE_ACTIVITY_FAIL';

export const DELETE_ACTIVITY_IDLE = 'DELETE_ACTIVITY_IDLE';
export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAIL = 'DELETE_ACTIVITY_FAIL';

export const GET_ACTIVITY_PRODUCTLIST_REQUEST =
  'GET_ACTIVITY_PRODUCTLIST_REQUEST';
export const GET_ACTIVITY_PRODUCTLIST_SUCCESS =
  'GET_ACTIVITY_PRODUCTLIST_SUCCESS';
export const GET_ACTIVITY_PRODUCTLIST_FAIL = 'GET_ACTIVITY_PRODUCTLIST_FAIL';

export const ADD_ACTIVITY_IDLE = 'ADD_ACTIVITY_IDLE';
export const ADD_ACTIVITY_REQUEST = 'ADD_ACTIVITY_REQUEST';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_FAIL = 'ADD_ACTIVITY_FAIL';

export const GET_ONE_ACTIVITY_REQUEST = 'GET_ONE_ACTIVITY_REQUEST';
export const GET_ONE_ACTIVITY_SUCCESS = 'GET_ONE_ACTIVITY_SUCCESS';
export const GET_ONE_ACTIVITY_FAIL = 'GET_ONE_ACTIVITY_FAIL';

export const GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST =
  'GET_ACTIVITY_REAPTED_PRODUCTS_REQUEST';
export const GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS =
  'GET_ACTIVITY_REAPTED_PRODUCTS_SUCCESS';
export const GET_ACTIVITY_REAPTED_PRODUCTS_FAIL =
  'GET_ACTIVITY_REAPTED_PRODUCTS_FAIL';

export const SORT_ACTIVITY_IDLE = 'SORT_ACTIVITY_IDLE';
export const SORT_ACTIVITY_REQUEST = 'SORT_ACTIVITY_REQUEST';
export const SORT_ACTIVITY_SUCCESS = 'SORT_ACTIVITY_SUCCESS';
export const SORT_ACTIVITY_FAIL = 'SORT_ACTIVITY_FAIL';
