import {
  JOB_DELETE_FAIL,
  JOB_DELETE_REQUEST,
  JOB_DELETE_RESET,
  JOB_DELETE_SUCCESS,
  JOB_DETAILS_FAIL,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_LIST_FAIL,
  JOB_LIST_REQUEST,
  JOB_LIST_SUCCESS,
  JOB_POST_FAIL,
  JOB_POST_REQUEST,
  JOB_POST_RESET,
  JOB_POST_SUCCESS,
  UPDATE_DATA_FAIL,
  UPDATE_DATA_REQUEST,
  UPDATE_DATA_SUCCESS,
  UPDATE_TIME_FAIL,
  UPDATE_TIME_REQUEST,
  UPDATE_TIME_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_RESET,
  USER_RESET_PASSWORD_SUCCESS,
  USER_SEND_FORGOT_FAIL,
  USER_SEND_FORGOT_REQUEST,
  USER_SEND_FORGOT_RESET,
  USER_SEND_FORGOT_SUCCESS,
  USER_VERIFY_FAIL,
  USER_VERIFY_REQUEST,
  USER_VERIFY_RESET,
  USER_VERIFY_SUCCESS,
} from '../../constants/data/userConstants';

export const userLoginReducer = (
  state = { loading: true, userInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: payload };
    case USER_LOGIN_FAIL:
      return { error: payload, loading: false };
    case USER_LOGIN_RESET:
      return { ...state, error: null };
    case USER_LOGOUT:
      return { userInfo: null };
    default:
      return state;
  }
};

export const updateTimeReducer = (state = { timeInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TIME_REQUEST:
      return { loading: true };
    case UPDATE_TIME_SUCCESS:
      return { loading: false, timeInfo: payload };
    case UPDATE_TIME_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const updateDataReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_DATA_REQUEST:
      return { loading: true };
    case UPDATE_DATA_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_DATA_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const jobListReducer = (state = { jobListInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_LIST_REQUEST:
      return { jobListInfo: {}, loading: true };
    case JOB_LIST_SUCCESS:
      return { jobListInfo: payload, loading: false };
    case JOB_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const jobDetailsReducer = (state = { jobDetailsInfo: {} }, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_DETAILS_REQUEST:
      return { loading: true };
    case JOB_DETAILS_SUCCESS:
      return { jobDetailsInfo: payload, loading: false };
    case JOB_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const jobPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_POST_REQUEST:
      return { loading: true };
    case JOB_POST_SUCCESS:
      return { loading: false, success: true };
    case JOB_POST_FAIL:
      return { error: payload, loading: false };
    case JOB_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const jobDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_DELETE_REQUEST:
      return { loading: true };
    case JOB_DELETE_SUCCESS:
      return { loading: false, success: true };
    case JOB_DELETE_FAIL:
      return { error: payload, loading: false };
    case JOB_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const userVerifyReducer = (state = { userData: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_VERIFY_REQUEST:
      return { loading: true };
    case USER_VERIFY_SUCCESS:
      return { loading: false, userData: payload };
    case USER_VERIFY_FAIL:
      return { error: payload, loading: false };
    case USER_VERIFY_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const userResetPasswordReducer = (
  state = { success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { loading: true };
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, success: true };
    case USER_RESET_PASSWORD_FAIL:
      return { error: payload, loading: false };
    case USER_RESET_PASSWORD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

export const userForgotPasswordReducer = (
  state = { success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case USER_SEND_FORGOT_REQUEST:
      return { loading: true };
    case USER_SEND_FORGOT_SUCCESS:
      return { loading: false, success: true };
    case USER_SEND_FORGOT_FAIL:
      return { error: payload, loading: false };
    case USER_SEND_FORGOT_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
