import Layout from 'components/Layout';
import RcPagination from 'rc-pagination';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';


import Loader from '../components/Loader';
import {
  deleteCafeShopDetail,
  getCafeShopList,
} from '../redux/actions/data/uuCafeShopAction';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import request from '../utils/useAxios';

const CafeShop = () => {
  const dispatch = useDispatch();
  const { loading, shopList } = useSelector(state => state.cafeShopList);
  const [keyword, setKeyword] = useState('');
  const [service, setService] = useState(null);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [page, setPage] = useState(1);

  const loadServiceList = useCallback(async () => {
    const res = await request({
      method: 'get',
      url: '/Backend/Cafe_Shop/services',
    });
    setServiceOptions(res || []);
    return res || [];
  }, [setServiceOptions]);

  const handlePageChange = useCallback(
    (value, keyword, service) => {
      dispatch(
        getCafeShopList({
          keyword,
          page: value,
          page_size: shopList.page_size || 10,
          serviceId: (service && service.value) || 0,
          type: 0,
        }),
      );
      setPage(value);
    },
    [dispatch, setPage, shopList],
  );

  const handleDeleteShop = useCallback(
    async shop => {
      try {
        await deleteCafeShopDetail({
          id: shop.id,
        });
        handlePageChange(1, keyword, service);
        MessageUtil.toastSuccess('刪除成功!');
      } catch (e) {
        MessageUtil.alertWanring('刪除失敗!', e);
      }
    },
    [keyword, handlePageChange, service],
  );

  useEffect(() => {
    handlePageChange(1, keyword, service);
    loadServiceList();
  }, []);

  return (
    <Layout
      pageTitle="門市查詢-門市設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '門市查詢-門市設定' },
      ]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">關鍵字:</label>
              <Input
                className="flex-1"
                placeholder="輸入關鍵字"
                value={keyword}
                onChange={e => {
                  setKeyword(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup className="d-inline-flex align-items-center col-sm-12 col-md-6">
              <label className="mx-3">服務項目:</label>
              <Select
                className="flex-1"
                placeholder="輸入關鍵字"
                options={serviceOptions}
                value={service}
                onChange={selectedOption => {
                  setService(selectedOption);
                }}
              />
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              setKeyword('');
              setService(null);
            }}
          >
            <span className="btn-wrapper--label">取消</span>
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handlePageChange(1, keyword, service);
            }}
          >
            <span className="btn-wrapper--label">搜尋</span>
          </Button>
        </CardFooter>
      </Card>
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {!loading ? (
          <>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <Button
                color="success"
                onClick={() => {
                  history.push('/UUCafe/CafeShop/add');
                }}
              >
                新增
              </Button>
            </div>
            <p className="small text-end mb-0">共 {shopList.total_count} 筆</p>
            <div
              className={style.table_overflow}
              style={{ minHeight: '200px' }}
            >
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th nowrap="nowrap">門市查詢-門市設定</th>
                    <th nowrap="nowrap">電話</th>
                    <th nowrap="nowrap">地址</th>
                    <th nowrap="nowrap">營業時間</th>
                    <th nowrap="nowrap">其他說明</th>
                    <th nowrap="nowrap">服務項目</th>
                    <th style={{ minWidth: '120px' }}>異動日期</th>
                    <th>功能</th>
                  </tr>
                </thead>
                <tbody>
                  {shopList.datas.map((data, index) => (
                    <tr key={index}>
                      <td nowrap="nowrap">{data.name}</td>
                      <td nowrap="nowrap">{data.phone}</td>
                      <td nowrap="nowrap">{data.address}</td>
                      <td nowrap="nowrap">
                        <pre>{data.businessHours}</pre>
                      </td>
                      <td nowrap="nowrap">{data.otherDesc}</td>
                      <td nowrap="nowrap">
                        {(data.serviceItems || []).map(s => {
                          return <div key={s.serviceId}> {s.name} </div>;
                        })}
                      </td>
                      <td>
                        <div className="text-nowrap">{data.updateDateTime}</div>
                        <div>{data.updater}</div>
                      </td>
                      <td nowrap="nowrap" className="text-center">
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="primary"
                          onClick={() => {
                            history.push(`/UUCafe/CafeShop/${data.id}`);
                          }}
                        >
                          編輯
                        </Button>
                        <Button
                          className="m-1"
                          size="sm"
                          outline
                          color="danger"
                          onClick={() => {
                            MessageUtil.submitConfirm({
                              doneFun: () => {
                                handleDeleteShop(data);
                              },
                              title: '確認是否刪除',
                            });
                          }}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                current={page}
                pageSize={shopList.page_size || 10}
                total={shopList.total_count || 1}
                onChange={value => {
                  handlePageChange(value, keyword, service);
                }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Card>
    </Layout>
  );
};

export default CafeShop;
