import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  getWarrantyCodeList,
  postWarrantyCode,
} from '../redux/actions/data/warrantyActions';
import { WARRANTY_CODE_POST_RESET } from '../redux/constants/data/warrantyConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function WarrantyNum(props) {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [keyword, setKeyword] = useState('');
  const [status, setStatus] = useState('全部');
  const [current_page, setCurrentPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const [itemId, setItemId] = useState(0);
  const [brand, setBrand] = useState({ label: '請選擇品牌', value: 0 });

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const handleOpenAddModal = () => {
    setModal(!modal);
    setEditData(null);
    setItemId(0);
    setBrand({ label: '請選擇品牌', value: 0 });
  };

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, warrantyCodeListInfo, error } = useSelector(
    state => state.warrantyCodeList,
  );
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.warrantyCodePost);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ');
  //     dispatch(userLogout());
  //     history.push({ pathname: '/Login', state: '/WarrantyBrand' });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL');
  //       dispatch(userLogout());
  //       history.push({ pathname: '/Login', state: '/WarrantyBrand' });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;

      setKeyword(queryKeyword);
      setStatus(queryStatus);
      setCurrentPage(queryPage);

      if (queryKeyword || queryStatus) {
        //儲存搜尋及頁數
        dispatch(
          getWarrantyCodeList({
            keyword: queryKeyword,
            page: queryPage,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(
          getWarrantyCodeList({
            page: queryPage,
          }),
        );
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setKeyword('');
      setStatus('');

      //初始搜尋
      dispatch(getBrandOptions());
      dispatch(
        getWarrantyCodeList({
          page: 1,
        }),
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      MessageUtil.toastSuccess('新增成功!!');
      dispatch(
        getWarrantyCodeList({
          page: current_page,
        }),
      );
    }

    //代碼重複錯誤
    if (addError) {
      MessageUtil.alertWanring(addError);
    }

    return () => {
      dispatch({ type: WARRANTY_CODE_POST_RESET });
    };
  }, [addSuccess, addError]);

  const handleOpenEditModal = data => {
    setModal(!modal);
    setEditData(data);
    setItemId(data.id);
    setBrand({ label: data.name, value: data.brandId });
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getWarrantyCodeList({
        keyword,
        page: 1,
        status,
      }),
    );

    history.push(
      `${location.pathname}?keyword=${keyword}&status=${status}&page=${1}`,
    );
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setKeyword('');
    setStatus('');
    dispatch(
      getWarrantyCodeList({
        page: 1,
      }),
    );
    history.push(`${location.pathname}`);
  };

  const handleSubmit = values => {
    let formData = new FormData();
    formData.append('id', itemId);
    formData.append('brandId', brand.value);
    formData.append('serialNumPrefix', values.brandCode);
    formData.append('status', values.status);

    dispatch(postWarrantyCode(formData));
  };

  const handlePageChange = page => {
    setCurrentPage(page);

    dispatch(
      getWarrantyCodeList({
        keyword,
        page,
        status,
      }),
    );
    if (keyword) {
      history.push(
        `${location.pathname}?keyword=${keyword}&status=${status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      // brandName: Yup.string().required('必填!'),
      brandCode: Yup.string().required('必填!').length(2, '請輸兩位字'),
      status: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    if (warrantyCodeListInfo) {
      if (JSON.stringify(warrantyCodeListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: warrantyCodeListInfo });
      }
    }
  }, [warrantyCodeListInfo]);

  return (
    <Layout
      pageTitle="保固序號編碼設定"
      items={[
        { isActive: false, page_name: '序號綁定及出貨' },
        { isActive: true, page_name: '保固序號編碼設定' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  id="keyword"
                  placeholder="請輸入關鍵字"
                  value={keyword}
                  onChange={e => setKeyword(e.target.value)}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2" htmlFor="status">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select-short']}
                  value={status}
                  id="status"
                  onChange={e => setStatus(e.target.value)}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="正常">正常</option>
                  <option value="停用">停用</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={handleOpenAddModal}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading &&
            warrantyCodeListInfo &&
            warrantyCodeListInfo.total_count > 0
              ? warrantyCodeListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>品牌名稱</th>
                <th>品牌代碼</th>
                <th>狀態</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {warrantyCodeListInfo &&
                warrantyCodeListInfo.datas?.length > 0 ? (
                  warrantyCodeListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td>{data.name}</td>
                      <td>{data.serialNumPrefix}</td>
                      <td>{data.status}</td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          編輯
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {!loading &&
          warrantyCodeListInfo &&
          warrantyCodeListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={warrantyCodeListInfo.current_page}
                defaultPageSize={10}
                total={warrantyCodeListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleModal}>
          品牌保固序號編碼設定
        </ModalHeader>

        <Formik
          enableReinitialize
          initialValues={{
            // brandName: editData && editData.name ? editData.name : '',
            brandCode:
              editData && editData.serialNumPrefix
                ? editData.serialNumPrefix
                : '',
            status: editData && editData.status ? editData.status : '',
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    品牌名稱{' '}
                    <ErrorMessage
                      name="brandName"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Select
                      isLoading={brandOptionsLoading}
                      isDisabled={brandOptionsLoading}
                      name="brandName"
                      options={brandOptions}
                      onChange={setBrand}
                      defaultValue={brand}
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    品牌代碼{' '}
                    <ErrorMessage
                      name="brandCode"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field name="brandCode" className="form-control" />
                  </div>
                </div>
                <div
                  className={style.formGroup}
                  style={{ marginBottom: '20vh' }}
                >
                  <label className="required" style={{ width: '150px' }}>
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Field
                        className="my-auto me-2"
                        id="normal"
                        name="status"
                        type="radio"
                        value="正常"
                      />
                      <label htmlFor="normal" className="me-5">
                        正常
                      </label>
                      <Field
                        className="my-auto me-2"
                        id="stop"
                        name="status"
                        type="radio"
                        value="停用"
                      />
                      <label htmlFor="stop" className="me-5">
                        停用
                      </label>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={toggleModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading}
                >
                  {addLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
