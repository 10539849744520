import { createElement } from 'react';
import ReactDom from 'react-dom';

import BillInfo from '../components/BillInfo';
import SMSNotificationModal from '../components/maintainanceContent/SMSNotificationModal';
import DetailModal from '../components/orderDetail/DetailModal';
import UpdateMessageNoteModal from '../components/staffReply/UpdateMessageNoteModal';
import FullScreenImageModal from '../components/warrantyRegisterDetails/FullScreenImageModal';

export const modal = {
  close: () => {
    if (typeof document !== 'undefined') {
      if (!document.querySelector('#Modal')) {
        document.body.append(
          '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
        );
      }
      const container = document.querySelector('#Modal');
      if (container) {
        container.innerHTML = '';
      }
    }
  },
  showBillInfo: info => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        if (!document.querySelector('#Modal')) {
          document.body.append(
            '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
          );
        }
        const div = document.createElement('div');
        const container = document.querySelector('#Modal');
        if (container) {
          container.appendChild(div);
          ReactDom.render(
            createElement(BillInfo, {
              info,
              isOpen: true,
              toggle: () => {
                resolve('close');
                ReactDom.render(
                  createElement(
                    BillInfo,
                    {
                      info,
                      isOpen: false,
                      toggle: () => {},
                    },
                    '',
                  ),
                  div,
                );
                container.innerHTML = '';
              },
            }),
            div,
          );
        }
      } else {
        reject('error');
      }
    });
  },
  showFillImage: source => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        if (!document.querySelector('#Modal')) {
          document.body.append(
            '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
          );
        }
        const div = document.createElement('div');
        const container = document.querySelector('#Modal');
        if (container) {
          container.appendChild(div);
          ReactDom.render(
            createElement(FullScreenImageModal, {
              isOpen: true,
              source,
              toggle: () => {
                resolve('close');
                ReactDom.render(
                  createElement(
                    FullScreenImageModal,
                    {
                      isOpen: false,
                      source,
                      toggle: () => {},
                    },
                    '',
                  ),
                  div,
                );
                container.innerHTML = '';
              },
            }),
            div,
          );
        }
      } else {
        reject('error');
      }
    });
  },
  showGiftInfo: (type, id) => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        if (!document.querySelector('#Modal')) {
          document.body.append(
            '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
          );
        }
        const div = document.createElement('div');
        const container = document.querySelector('#Modal');
        if (container) {
          container.appendChild(div);
          ReactDom.render(
            createElement(DetailModal, {
              id,
              isOpen: true,
              toggle: () => {
                resolve('close');
                ReactDom.render(
                  createElement(
                    DetailModal,
                    {
                      id,
                      isOpen: false,
                      toggle: () => {},
                      type,
                    },
                    '',
                  ),
                  div,
                );
                container.innerHTML = '';
              },
              type,
            }),
            div,
          );
        }
      } else {
        reject('error');
      }
    });
  },
  showSMSNotification: maintaince => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        if (!document.querySelector('#Modal')) {
          document.body.append(
            '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
          );
        }
        const div = document.createElement('div');
        const container = document.querySelector('#Modal');
        if (container) {
          container.appendChild(div);
          ReactDom.render(
            createElement(SMSNotificationModal, {
              isOpen: true,
              maintaince,
              toggle: () => {
                resolve('close');
                ReactDom.render(
                  createElement(
                    SMSNotificationModal,
                    {
                      isOpen: false,
                      maintaince,
                      toggle: () => {},
                    },
                    '',
                  ),
                  div,
                );
                container.innerHTML = '';
              },
            }),
            div,
          );
        }
      } else {
        reject('error');
      }
    });
  },
  showUpdateMessageNoteModal: datas => {
    return new Promise((resolve, reject) => {
      if (typeof document !== 'undefined') {
        if (!document.querySelector('#Modal')) {
          document.body.append(
            '<div id="Modal" style="position: fixed; top: 0px; left: 0px; z-index: 1000"></div>',
          );
        }
        const div = document.createElement('div');
        const container = document.querySelector('#Modal');
        if (container) {
          container.appendChild(div);
          ReactDom.render(
            createElement(UpdateMessageNoteModal, {
              datas,
              isOpen: true,
              toggle: () => {
                resolve('close');
                ReactDom.render(
                  createElement(
                    UpdateMessageNoteModal,
                    {
                      datas,
                      isOpen: false,
                      toggle: () => {},
                    },
                    '',
                  ),
                  div,
                );
                container.innerHTML = '';
              },
            }),
            div,
          );
        }
      } else {
        reject('error');
      }
    });
  },
};
