import AddProductModal from 'components/discountCompaign/AddProductModal';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import {
  addActivity,
  getActivityReaptedProduct,
  getOneActivity,
} from 'redux/actions/data/discountCompaignActions';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import { ADD_ACTIVITY_IDLE } from 'redux/constants/data/discountCompaignContants';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import useQuery from 'utils/useQuery';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import DiscountConditionSettings from '../components/compaignSettingPage/DiscountConditionSettings';
import BasicSettings from '../components/compaignSettingPage/EditBasicSettings';
import OthersSettings from '../components/compaignSettingPage/EditOthersSettings';
import style from '../styles/layout.module.css';

import { asyncValidation } from './CompaignSettingPage';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
};
const CopyCompaignSettingPage = () => {
  const initData = useMemo(() => {
    const initDate = moment().add(5, 'minute').toDate();
    return {
      activityPic: [],
      activityPicMoblie: [],
      area: [
        {
          area: 1,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 2,
          areaName: '',
          areaName_en: '',
        },
        {
          area: 3,
          areaName: '',
          areaName_en: '',
        },
      ],
      catCode: 0,
      category1: { label: '請選擇', value: 0 },
      category2: { label: '全部', value: 0 },
      condition: null,
      condition_fixedTPrice: 0,
      conditions: [{ freebieCode: '', value1: '', value2: '' }],
      conditionsArea: [
        {
          area: 1,
          value1: '',
          value2: '',
        },
        {
          area: 2,
          value1: '',
          value2: '',
        },
        {
          area: 3,
          value1: '',
          value2: '',
        },
      ],
      couponEnabled: true,
      currentTab: 1,
      desc: '',
      desc_en: '',
      endDate: moment(initDate).add(30, 'minute').toDate(),
      gateway: ['web'],
      globalCat: [],
      hasFreebieMustBuy: false,
      isExtraProductRange: false,
      isShow: true,
      memberLevel: 0,
      menuColor: 'white',
      modelType: '一般商品',
      models: [],
      name: '',
      name_en: '',
      objectType: '',
      originPriceA: false,
      originPriceB: false,
      originPriceC: false,
      range: 'all',
      startDate: initDate,
      storePic: [],
      target: 'all',
      type: '',
    };
  }, []);
  const query = useQuery();
  const id = query.value.id;

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [productType, setProductType] = useState(null);
  const toggleProductModal = () => {
    setIsProductModalOpen(!isProductModalOpen);
  };
  const { data: activityData } = useSelector(state => state.getOneActivity);
  const dispatch = useDispatch();
  const catCode = query.value.cateCode || query.value.catCode;
  const {
    values,
    setFieldTouched,
    setValues,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initData,
    onSubmit: () => {
      const body = {
        ...values,
        _object: values.catCode == 11 ? 'all' : values.target,
        availableWay: values.gateway,
        catCode: values.catCode,
        condition_fixedTPrice: values.condition_fixedTPrice,
        conditions:
          values.condition === null ? values.conditions : values.conditionsArea,
        coverImg: values.storePic,
        desc: values.desc,
        desc_en: values.desc_en,
        endTime: moment(values.endDate).format('YYYY/MM/DD HH:mm:ss'),
        globleCatIds: values.globalCat.map(cat => {
          if (cat.cat2.value != 0) {
            return cat.cat2.value;
          } else {
            return cat.cat1.value;
          }
        }),
        groupProducts: values.models
          .filter(m => m.modelType === '組合商品')
          .reduce((arr, model) => {
            return [...arr, ...model.products];
          }, [])
          .map(product => ({
            activityProductId: product.activityProductId,
            area: product.area,
            discountPrice: Number(product.discountPrice || 0),
            mid: product.mid,
            productid: product.productid,
          })),
        id: id,
        isDiscountAvailable: values.catCode == 11 ? true : values.couponEnabled,
        isShow: values.catCode == 11 ? false : values.isShow,
        menuColor: values.menuColor ?? 'white',
        objectMemberLevelId: values.memberLevel,
        pageImg_d: values.activityPic,
        pageImg_m: values.activityPicMoblie,
        productRange: values.range,
        products: values.models
          .filter(m => m.modelType === '一般商品')
          .reduce((arr, model) => {
            return [...arr, ...model.products];
          }, [])
          .map(product => ({
            activityProductId: product.activityProductId,
            area: product.area,
            discountPrice: Number(product.discountPrice || 0),
            mid: product.mid,
            productid: product.productid,
          })),
        startTime: moment(values.startDate).format('YYYY/MM/DD HH:mm:ss'),
      };

      if (body.hasFreebieMustBuy === false && body.catCode == 11) {
        body.products = [];
      }

      if (body.catCode == 11) {
        delete body.menuColor;
      }

      let breakPoint = false;
      if (body.products.find(product => product.discountPrice == 0)) {
        breakPoint = true;
      }
      if (body.products.find(product => product.discountPrice == '')) {
        breakPoint = true;
      }
      if (breakPoint && body.catCode == 1) {
        MessageUtil.alertWanring('商品價格不能為0');
        return;
      }
      if (
        values.isShow &&
        body.coverImg.length === 0 &&
        values.currentStorePic.length === 0 &&
        body.catCode != 1 &&
        body.catCode != 11
      ) {
        MessageUtil.alertWanring('商城活動圖必填');
        return;
      }
      if (
        values.isShow &&
        body.pageImg_d.length === 0 &&
        values.currentActivityPic.length === 0 &&
        body.catCode != 1 &&
        body.catCode != 11
      ) {
        MessageUtil.alertWanring('活動頁主圖(桌機)必填');
        return;
      }
      if (
        values.isShow &&
        body.pageImg_m.length === 0 &&
        values.currentActivityPicMoblie.length === 0 &&
        body.catCode != 1 &&
        body.catCode != 11
      ) {
        MessageUtil.alertWanring('活動頁主圖(手機)必填');
        return;
      }

      const catIds = JSON.stringify(
        body.productRange === 'pickedbyCat' ? body.globleCatIds : [],
      );
      let formData = new FormData();
      formData.set('id', 0);
      formData.set('catCode', body.catCode);
      formData.set('name', body.name);
      formData.set('name_en', body.name_en);
      formData.set('startTime', body.startTime);
      formData.set('endTime', body.endTime);
      formData.set('_object', body._object);
      if (body.objectMemberLevelId) {
        formData.set('objectMLevelId', body.objectMemberLevelId);
      }
      formData.set('objectType', body.objectType);
      formData.set('availableWay', JSON.stringify(body.availableWay));
      formData.set('productRange', body.productRange);
      formData.set('isDiscountAvailable', body.isDiscountAvailable);
      formData.set('products', JSON.stringify(body.products));
      formData.set('groupProducts', JSON.stringify(body.groupProducts));

      formData.set('globleCatIds', catIds);
      formData.set('hasFreebieMustBuy', values.hasFreebieMustBuy);

      if (body.coverImg?.length > 0) {
        const coverImgJsonString = JSON.stringify(
          body.coverImg.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.coverImg
              ? body.coverImg.indexOf(body.coverImg.find(j => j.id === img.id))
              : -1,
            isRemoveFile: !!body.coverImg?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('coverImg', coverImgJsonString);
        formData.append('coverImgFiles', body.coverImg[0]);
      }

      if (body.pageImg_d?.length > 0) {
        const pageImg_dJsonString = JSON.stringify(
          body.pageImg_d.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.pageImg_d
              ? body.pageImg_d.indexOf(
                  body.pageImg_d.find(j => j.id === img.id),
                )
              : -1,
            isRemoveFile: !!body.pageImg_d?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('pageImg_d', pageImg_dJsonString);
        formData.append('pageImg_dFiles', body.pageImg_d[0]);
      }
      if (body.pageImg_m?.length > 0) {
        const pageImg_mJsonString = JSON.stringify(
          body.pageImg_m.map((img, index) => ({
            ...img,
            image: img.url,
            imgFormFileId: body.pageImg_m
              ? body.pageImg_m.indexOf(
                  body.pageImg_m.find(j => j.id === img.id),
                )
              : -1,
            isRemoveFile: !!body.pageImg_m?.some(j => j.id !== img.id),
            sort: img.sort || index + 1,
          })),
        );
        formData.append('pageImg_m', pageImg_mJsonString);
        formData.append('pageImg_mFiles', body.pageImg_m[0]);
      }
      body.desc && formData.append('desc', body.desc);
      body.desc_en && formData.append('desc_en', body.desc_en);
      body.menuColor && formData.append('menuColor', body.menuColor);
      body.isShow && formData.append('isShow', body.isShow);

      // 紅配綠的新增項目
      if (body.catCode === 7) {
        if (!body.isExtraProductRange) {
          body.conditions = body.conditions?.slice(0, 2);
          body.areas = body.areas?.slice(0, 2);
        }

        if (values?.originPriceA) {
          body.conditions[0].value2 = null;
        }
        if (values?.originPriceB) {
          body.conditions[1].value2 = null;
        }
        if (values?.originPriceC && body.isExtraProductRange) {
          body.conditions[2].value2 = null;
        }

        formData.append(
          'conditions',
          JSON.stringify(
            body.conditions.map((c, index) => ({
              ...c,
              area: index + 1,
            })),
          ),
        );
        formData.append('areas', JSON.stringify(body?.area || body?.areas));
        formData.append('condition', body.condition);
        formData.append('isExtraProductRange', body.isExtraProductRange);

        if (body.condition === 'all') {
          formData.append('condition_fixedTPrice', body.condition_fixedTPrice);
        }
      } else {
        body.catCode !== 1 &&
          formData.append('conditions', JSON.stringify(body.conditions));
      }

      if (
        values.catCode === 7 &&
        (values?.conditionsArea
          ?.slice(0, values?.isExtraProductRange ? 3 : 2)
          .some(a => {
            return Number(a?.value1) === 0;
          }) ||
          values?.conditionsArea?.length === 0)
      ) {
        return MessageUtil.alertWanring('商品任選件數不可為0');
      }

      if (
        values.catCode === 7 &&
        values.condition === 'separate' &&
        (!values.originPriceA ||
          !values.originPriceB ||
          !values.originPriceC) &&
        values?.conditionsArea?.some(a => {
          return Number(a?.value1) > 0 && Number(a?.value2) === 0;
        })
      ) {
        let msg = '';
        if (
          values?.conditionsArea[0] &&
          !values.originPriceA &&
          Number(values?.conditionsArea[0].value2) === 0
        ) {
          msg = 'A區每件固定價為NT$0確定儲存?';
        }
        if (
          values?.conditionsArea[1] &&
          !values.originPriceB &&
          Number(values?.conditionsArea[1].value2) === 0
        ) {
          msg = 'B區每件固定價為NT$0確定儲存?';
        }
        if (
          values.isExtraProductRange &&
          values?.conditionsArea[2] &&
          !values.originPriceC &&
          Number(values?.conditionsArea[2].value2) === 0
        ) {
          msg = 'C區每件固定價為NT$0確定儲存?';
        }
        if (msg) {
          MessageUtil.confirm(msg, '', () => {
            dispatch(addActivity(formData));
          });
        } else {
          dispatch(addActivity(formData));
        }
      } else {
        dispatch(addActivity(formData));
      }
    },
    validationSchema: Yup.object().shape({
      area: Yup.array().when('catCode', {
        is: val => val === 7,
        then: Yup.array().of(
          Yup.object().shape({
            area: Yup.number(),
            areaName: Yup.string()
              .nullable()
              .when('area', {
                is: val => val !== 3,
                then: Yup.string().nullable().required('必填'),
              }),
            areaName_en: Yup.string()
              .nullable()
              .when('area', {
                is: val => val !== 3,
                then: Yup.string().nullable().required('必填'),
              }),
          }),
        ),
      }),
      condition_fixedTPrice: Yup.number().when('condition', {
        is: val => val === 'all',
        then: Yup.number().min(1, '必填').nullable().required('必填'),
      }),
      conditions: Yup.array().when('catCode', {
        is: val => val !== 1 && val !== 7,
        then: Yup.array().of(
          Yup.object().shape({
            freebieCode: Yup.string()
              .nullable()
              .when('catCode', {
                is: () => values.catCode == 11,
                then: Yup.string().nullable().required('必填'),
              }),
            value1: Yup.number().nullable().required('必填'),
            value2: Yup.string()
              .nullable()
              .when('catCode', {
                is: () => values.catCode != 11,
                then: Yup.string().nullable().required('必填'),
              }),
          }),
        ),
      }),
      conditionsArea: Yup.array().when(['catCode'], {
        is: catCode => catCode === 7,
        then: Yup.array().test('invalid', '必填', async function (val) {
          await asyncValidation(10);

          if (val?.length > 0) {
            if (Number(val[0]?.value1) <= 0) {
              return this.createError({
                message: 'A區商品任選至少大於一件',
                path: `${this.path}[0].value1`, // 设置错误路径
              });
            }
            if (Number(val[1]?.value1) <= 0) {
              return this.createError({
                message: 'B區商品任選至少大於一件',
                path: `${this.path}[1].value1`, // 设置错误路径
              });
            }
            if (Number(val[2]?.value1) <= 0 && values.isExtraProductRange) {
              return this.createError({
                message: 'C區商品任選至少大於一件',
                path: `${this.path}[2].value1`, // 设置错误路径
              });
            }

            if (values.condition === 'separate') {
              if (
                Number(val[0]?.value1) > 0 &&
                Number(val[0]?.value2) <= 0 &&
                !values.originPriceA
              ) {
                return this.createError({
                  message: '金額至少大於0',
                  path: `${this.path}[0].value2`, // 设置错误路径
                });
              }
            }
            return true;
          } else {
            return this.createError({
              message: '請設定折抵條件',
            });
          }
        }),
      }),

      desc: Yup.string()
        .nullable()
        .when('catCode', {
          is: val => val !== 1,
          then: Yup.string().nullable().required('必填'),
        }),
      desc_en: Yup.string()
        .nullable()
        .when('catCode', {
          is: val => val !== 1,
          then: Yup.string().nullable().required('必填'),
        }),
      endDate: Yup.date().typeError('必填').required('必填'),

      gateway: Yup.array().when('catCode', {
        is: val => val != 11,
        then: Yup.array().min(1, '必填').required('必填'),
      }),
      globalCat: Yup.array().when('range', {
        is: val => val === 'pickedbyCat',
        then: Yup.array().min(1, '必填'),
      }),
      isExtraProductRange: Yup.boolean(),
      models: Yup.array().when(['range', 'hasFreebieMustBuy'], {
        is: (range, hasFreebieMustBuy) =>
          range === 'picked' || hasFreebieMustBuy === true,
        then: Yup.array().min(1, '必填').required('必填'),
      }),
      name: Yup.string().nullable().required('必填'),
      name_en: Yup.string().nullable().required('必填'),
      startDate: Yup.date().typeError('必填').required('必填'),
      target: Yup.string().nullable().required('必填'),
    }),
  });

  const { success, loading, error } = useSelector(state => state.addActivity);

  useEffect(() => {
    dispatch(getOneActivity(id));
    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('新增成功!');
      dispatch({ type: ADD_ACTIVITY_IDLE });
      history.goBack();
    }
    if (error) {
      MessageUtil.alertWanring('新增失敗', error);
      dispatch({ type: ADD_ACTIVITY_IDLE });
    }
  }, [success, error]);

  useEffect(() => {
    if (values.startDate && values.endDate) {
      dispatch(
        getActivityReaptedProduct({
          _object: values.target,
          catCode: values.catCode,
          endTime: values.endDate
            ? moment(values.endDate).format('YYYY/MM/DD HH:mm')
            : null,
          id: 0,
          objectMLevelId: values.memberLevel,
          startTime: values.startDate
            ? moment(values.startDate).format('YYYY/MM/DD HH:mm')
            : null,
        }),
      );
    }
  }, [
    values.target,
    values.memberLevel,
    values.startDate,
    values.endDate,
    catCode,
  ]);

  useEffect(() => {
    if (activityData) {
      setValues({
        ...activityData,
        activityPic: activityData?.activityPic || [],
        activityPicMoblie: activityData?.activityPicMoblie || [],
        area: activityData?.areas || [],
        category1: { label: '請選擇', value: 0 },
        category2: { label: '全部', value: 0 },
        condition_fixedTPrice: activityData.condition_fixedTPrice,
        conditionsArea: activityData.conditions,
        couponEnabled: activityData.isDiscountAvailable,
        currentActivityPic: activityData?.currentActivityPicMoblie || [],
        currentActivityPicMoblie: activityData?.currentActivityPicMoblie || [],
        currentStorePic: [],
        desc: activityData.desc || '',
        desc_en: activityData.desc_en || '',
        endDate: activityData.endTime,
        gateway: activityData.availableWays,
        globalCat:
          activityData?.globalCategoies?.map(data => ({
            cat1: {
              label: data.name,
              value: data.id,
            },
            cat2: {
              label: data.cat2Name || '全部',
              value: data.cat2Id,
            },
          })) || [],
        globleCatIds: activityData.globleCatIds || [],
        hasFreebieMustBuy: activityData.hasFreebieMustBuy,
        initActivityPic: [],
        initActivityPicMoblie: [],
        initStorePic: [],
        isExtraProductRange: activityData.isExtraProductRange,
        isShow: activityData.isShow,
        memberLevel: Number(activityData.objectMLevelId),
        menuColor: activityData.menuColor ?? 'white',
        modelType:
          activityData.groupProducts?.length > 0 ? '一般商品' : '組合商品',
        models: [
          ...(activityData?.products || []).map(m => {
            return {
              ...m,
              modelType: '一般商品',
            };
          }),
          ...(activityData?.groupProducts || []).map(m => {
            return {
              ...m,
              modelType: '組合商品',
            };
          }),
        ],
        name: activityData.name || '',
        name_en: activityData.name_en || '',
        objectType: String(activityData.objectType),
        originPriceA:
          activityData?.conditions?.length > 0
            ? activityData.conditions[0]?.value2 === null &&
              activityData.condition === 'separate'
              ? true
              : false
            : false,
        originPriceB:
          activityData?.conditions?.length > 0
            ? activityData.conditions[1]?.value2 === null &&
              activityData.condition === 'separate'
              ? true
              : false
            : false,
        originPriceC:
          activityData?.conditions?.length > 0
            ? activityData.conditions[2]?.value2 === null &&
              activityData.condition === 'separate'
              ? true
              : false
            : false,
        range: activityData.productRange,
        startDate: activityData.startTime,
        status: 0,
        storePic: [],
        target: activityData._object,
        type: activityData.catName,
      });
    }
  }, [activityData]);

  const limit = Number(catCode) >= 5 ? 5 : 10;
  const valueRef = useRef();
  const isCompare = useRef(false);

  useEffect(() => {
    if (!isCompare.current) {
      if (!valueRef.current) {
        valueRef.current = { ...values };
      } else {
        try {
          Object.keys(valueRef.current).forEach(key => {
            if (
              JSON.stringify(valueRef.current[key]) !==
              JSON.stringify(values[key])
            ) {
              setFieldTouched(key, true);
              isCompare.current = true;
            }
          });
        } catch (e) {}
      }
    }
  }, [values]);

  return (
    <Layout
      pageTitle={`編輯折扣活動-${values.type}`}
      items={[
        { isActive: true, page_name: '行銷活動' },
        { isActive: true, page_name: '折扣活動' },
        { isActive: false, page_name: `編輯折扣活動-${values.type}` },
      ]}
    >
      <Card className={style.card}>
        <h4 className="title" style={titleStyle}>
          活動基本設定
        </h4>
        <BasicSettings
          activityType={values.type}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          onToggleModal={toggleProductModal}
        />
        {values.catCode != 11 && (
          <React.Fragment>
            <h4 className="title" style={titleStyle}>
              折抵條件設定
            </h4>
            <DiscountConditionSettings
              activityType={values.type}
              values={{
                ...values,
                status: 0,
              }}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              onToggleModal={toggleProductModal}
              onSetProductType={setProductType}
              limit={limit}
            />
          </React.Fragment>
        )}

        {values.catCode != 11 && (
          <React.Fragment>
            <h4 className="title" style={titleStyle}>
              其他行銷活動設定
            </h4>
            <OthersSettings
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            />
          </React.Fragment>
        )}
        <div
          className={style.formGroup}
          style={{ marginBottom: 0, marginTop: '1rem' }}
        >
          <div style={{ marginRight: '3rem' }}>
            {`新增時間：${'--'} ${'--'}`}
          </div>
          <div>{`異動時間：${'--'} ${'--'}`}</div>
        </div>
        <hr />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={() => {
              history.goBack();
            }}
          >
            取消
          </Button>
          <Button
            color="primary"
            className={style.modal_button_submit}
            disabled={loading || Object.keys(errors).length > 0}
            onClick={() => {
              handleSubmit();
            }}
          >
            {loading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={{ marginRight: '1rem' }}
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
      <AddProductModal
        disableHideModel
        isOpen={isProductModalOpen}
        toggle={toggleProductModal}
        type={productType}
        existList={
          values.catCode == 7
            ? values.models.filter(
                m => String(m.area) === String(values.currentTab),
              )
            : values.models
        }
        disableList={
          values.catCode == 7
            ? values.models.filter(
                m => String(m.area) !== String(values.currentTab),
              )
            : []
        }
        area={values.catCode == 7 ? values.currentTab || 1 : 0}
        onSubmit={(models, type) => {
          setFieldValue('modelType', type);
          if (values.catCode == 7) {
            setFieldValue('models', [
              ...values.models.filter(
                m => String(m.area) !== String(values.currentTab),
              ),
              ...models,
            ]);
          } else {
            setFieldValue('models', [...models]);
          }
        }}
      />
    </Layout>
  );
};

export default CopyCompaignSettingPage;
