import { SET_SIDEBAR_TOGGLE_MOBILE } from '../../constants/style/sideBarConstants';

export const setSidebarToggleMobile = sidebarToggleMobile => ({
  payload: sidebarToggleMobile,
  type: SET_SIDEBAR_TOGGLE_MOBILE,
});

//開合選單
export const setSidebarToggle = sideBarOpen => ({
  payload: sideBarOpen,
  type: SET_SIDEBAR_TOGGLE_MOBILE,
});
