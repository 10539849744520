import {
  GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL,
  GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST,
  GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS,
  GET_COUPON_REALTIME_REPORT_LIST_FAIL,
  GET_COUPON_REALTIME_REPORT_LIST_REQUEST,
  GET_COUPON_REALTIME_REPORT_LIST_SUCCESS,
  GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL,
  GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST,
  GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST,
  GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS,
  GET_ORDER_CANCEL_REPORT_FAIL,
  GET_ORDER_CANCEL_REPORT_REQUEST,
  GET_ORDER_CANCEL_REPORT_SUCCESS,
  GET_ORDER_INSTANT_REPORT_FAIL,
  GET_ORDER_INSTANT_REPORT_REQUEST,
  GET_ORDER_INSTANT_REPORT_SUCCESS,
  GET_ORDER_SOURCE_LIST_FAIL,
  GET_ORDER_SOURCE_LIST_REQUEST,
  GET_ORDER_SOURCE_LIST_SUCCESS,
  GET_PRODUCT_SALES_DATA_RANKING_FAIL,
  GET_PRODUCT_SALES_DATA_RANKING_REQUEST,
  GET_PRODUCT_SALES_DATA_RANKING_SUCCESS,
  GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL,
  GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST,
  GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS,
  GET_STORE_SALES_DATA_LIST_FAIL,
  GET_STORE_SALES_DATA_LIST_REQUEST,
  GET_STORE_SALES_DATA_LIST_SUCCESS,
} from 'redux/constants/data/operatingReportContants';

export const getProductSalesDataRankingReducer = (
  state = { error: null, loading: false, rankingList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCT_SALES_DATA_RANKING_REQUEST:
      return { error: null, loading: true, rankingList: {}, success: false };
    case GET_PRODUCT_SALES_DATA_RANKING_SUCCESS:
      return { ...state, loading: false, rankingList: payload };
    case GET_PRODUCT_SALES_DATA_RANKING_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getStoreSalesDataListReducer = (
  state = { error: null, loading: false, salesRankingList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_STORE_SALES_DATA_LIST_REQUEST:
      return {
        error: null,
        loading: true,
        salesRankingList: {},
        success: false,
      };
    case GET_STORE_SALES_DATA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        salesRankingList: payload,
        success: true,
      };
    case GET_STORE_SALES_DATA_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderInstantReportReducer = (
  state = { error: null, loading: false, reportList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_INSTANT_REPORT_REQUEST:
      return { error: null, loading: true, reportList: {}, success: false };
    case GET_ORDER_INSTANT_REPORT_SUCCESS:
      return { ...state, loading: false, reportList: payload, success: true };
    case GET_ORDER_INSTANT_REPORT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderCancelReportReducer = (
  state = { error: null, loading: false, reportList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_CANCEL_REPORT_REQUEST:
      return { error: null, loading: true, reportList: {}, success: false };
    case GET_ORDER_CANCEL_REPORT_SUCCESS:
      return { ...state, loading: false, reportList: payload, success: true };
    case GET_ORDER_CANCEL_REPORT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOrderSourceListReducer = (
  state = { error: null, loading: false, reportList: {}, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_SOURCE_LIST_REQUEST:
      return { error: null, loading: true, reportList: {}, success: false };
    case GET_ORDER_SOURCE_LIST_SUCCESS:
      return { ...state, loading: false, reportList: payload, success: true };
    case GET_ORDER_SOURCE_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getRewardsPointsStatisticalListReducer = (
  state = { error: null, list: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REWARDS_POINTS_STATISTICAL_LIST_REQUEST:
      return { error: null, list: {}, loading: true, success: false };
    case GET_REWARDS_POINTS_STATISTICAL_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case GET_REWARDS_POINTS_STATISTICAL_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getCouponPerformanceReportListReducer = (
  state = { error: null, list: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUPON_PERFORMANCE_REPORT_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_COUPON_PERFORMANCE_REPORT_LIST_SUCCESS:
      return { ...state, error: null, list: payload, loading: false };
    case GET_COUPON_PERFORMANCE_REPORT_LIST_FAIL:
      return { error: payload, list: {}, loading: false };
    default:
      return state;
  }
};

export const getCouponRealtimeReportListReducer = (
  state = { error: null, list: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUPON_REALTIME_REPORT_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_COUPON_REALTIME_REPORT_LIST_SUCCESS:
      return { ...state, error: null, list: payload, loading: false };
    case GET_COUPON_REALTIME_REPORT_LIST_FAIL:
      return { error: payload, list: {}, loading: false };
    default:
      return state;
  }
};

export const getFinanceReportDashboardListReducer = (
  state = { error: null, list: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FINANCE_REPORT_DASHBOARD_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_FINANCE_REPORT_DASHBOARD_LIST_SUCCESS:
      return { ...state, error: null, list: payload, loading: false };
    case GET_FINANCE_REPORT_DASHBOARD_LIST_FAIL:
      return { error: payload, list: {}, loading: false };
    default:
      return state;
  }
};

export const getFinanceReportReconciliationListReducer = (
  state = { error: null, list: {}, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FINANCE_REPORT_RECONCILIATION_LIST_REQUEST:
      return { ...state, error: null, loading: true };
    case GET_FINANCE_REPORT_RECONCILIATION_LIST_SUCCESS:
      return { ...state, error: null, list: payload, loading: false };
    case GET_FINANCE_REPORT_RECONCILIATION_LIST_FAIL:
      return { error: payload, list: {}, loading: false };
    default:
      return state;
  }
};
