import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';

import Layout from '../components/Layout';
import {
  ExportGroupProductCat1Sort,
  ExportProductCat1SortV2,
  ImportGroupProductCat1Sort,
  ImportProductCat1SortV2,
} from '../redux/actions/data/categoryActions';
import {
  getGroupModelGlobalCatList,
  getModelGlobalCatList,
} from '../redux/actions/data/modelActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import useQuery from '../utils/useQuery';

const GroupCategoryPrdsExport = () => {
  const { loading, modelGlobalCatListInfo } = useSelector(
    state => state.modelGlobalCatList,
  );
  const query = useQuery();
  const dispatch = useDispatch();
  const [fileLoading, setFileLoading] = useState(false);

  useEffect(() => {
    const params = {
      globalCateId: +query.value.cateId,
      isSell: null,
      keyword: '',
      page: 1,
    };
    query.value.type === 'group'
      ? dispatch(getGroupModelGlobalCatList(params))
      : dispatch(getModelGlobalCatList(params));
  }, []);

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const ExportSort = useCallback(async () => {
    setFileLoading(true);
    try {
      let fileUrl = '';
      if (query.value.type === 'group') {
        const res = await ExportGroupProductCat1Sort(query.value.cateId);
        fileUrl = res.result;
      } else {
        const res = await ExportProductCat1SortV2(query.value.cateId);
        fileUrl = res.result;
      }
      if (fileUrl) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        MessageUtil.alertSuccess('匯出成功');
      } else {
        MessageUtil.alertWanring('匯出失敗', '無檔案匯出');
      }
    } catch (e) {
      MessageUtil.alertWanring('匯出失敗', e);
    }

    setFileLoading(false);
  }, []);

  const ImportList = useCallback(async ele => {
    setFileLoading(true);
    if (fileInputRef.current.files.length > 0) {
      try {
        const formData = new FormData();
        formData.set('formFile', fileInputRef.current.files[0]);
        if (query.value.type === 'group') {
          const res = await ImportGroupProductCat1Sort(formData);
        } else {
          const res = await ImportProductCat1SortV2(formData);
        }
        MessageUtil.alertSuccess('匯入成功');
      } catch (e) {
        MessageUtil.alertWanringWithHTML('匯入失敗', e);
      }
    }
    setFileLoading(false);
  }, []);

  return (
    <Layout
      pageTitle={
        !loading && `${modelGlobalCatListInfo?.globalCateName} 商品排序`
      }
      items={[
        { isActive: false, page_name: '商品管理', to: '' },
        {
          isActive: false,
          page_name: '商品分類管理',
          to: '/CategoryControl',
        },
        { isActive: true, page_name: '商品類別管理' },
      ]}
    >
      <div className="container">
        <Row className="mb-3">
          <Col md="12">
            <Alert color="secondary">
              <div>
                <AiOutlineInfoCircle />
                <span className={style.fs_14}>請按照下方步驟完成上傳</span>
              </div>
              <div className="d-flex gap-1">
                <AiOutlineInfoCircle />
                <span className={style.fs_14}>完成設定後，即刻生效</span>
              </div>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className={style.search_card}>
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#1DB4B9' }}
              >
                1. 請先匯出活動清單
              </div>
              <CardBody>
                <Button
                  color="primary"
                  className="mb-2"
                  outline
                  onClick={ExportSort}
                  disabled={fileLoading}
                >
                  匯出商品清單
                </Button>
                <p>請先點選「匯出商品清單」</p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card className={style.search_card}>
              <div
                className={`${style.card_header} ${style.card_header_dark}`}
                style={{ backgroundColor: '#1DB4B9' }}
              >
                2. 上傳自訂商品排序檔案
              </div>
              <CardBody>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }} // Hide the file input
                  onChange={ImportList}
                />

                <Button
                  color="primary"
                  className="mb-2"
                  outline
                  onClick={handleFileUploadClick}
                >
                  匯入商品清單
                </Button>
                <p>上傳自訂商品排序後，如欲修改排序，請直接重新上傳檔案即可</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};
export default GroupCategoryPrdsExport;
