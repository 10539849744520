import moment from 'moment';
import React, { memo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData from '../TableHasNoData';

const WarrantyHistoryRecordModal = ({
  toggleModal,
  modal = false,
  reviewRecords = [],
}) => {
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={modal && reviewRecords}
      toggle={toggleModal}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggleModal}>
        保固註冊完整歷程紀錄
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <ul>
          <li className="form-error">
            <small className="form-error">
              不含手動輸入/建立的[審核歷程記錄]
            </small>
          </li>
          <li className="form-error">
            <small className="form-error">
              只有將狀態 [未審核 or 審核中] 的保固單變更為 [未通過 or 已通過]
              時才會發送通知信
            </small>
          </li>
        </ul>
        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table mx-sm-0">
            <thead>
              <tr>
                <th width="180px">
                  <div>異動時間</div>
                  <div>變更後的狀態</div>
                </th>
                <th>
                  <div>異動者</div>
                  <div>訊息</div>
                </th>
              </tr>
            </thead>
            <tbody width="180px">
              {(reviewRecords || []).length > 0 ? (
                reviewRecords
                  .sort((a, b) => {
                    if (a.creatTime == b.creatTime) {
                      return 0;
                    }
                    return a.creatTime > b.creatTime ? -1 : 1;
                  })
                  .map(record => {
                    return (
                      <tr
                        key={JSON.stringify(record)}
                      >
                        <td>
                          <div className="text-secondary text-nowrap">
                            {moment(record.creatTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )}
                          </div>
                          <div className="text-black fw-bold">
                            {record.status || '--'}
                          </div>
                        </td>
                        <td>
                          <div className="text-black fw-bold">
                            {record.name || '--'}
                          </div>
                          <div className="text-black fw-bold">
                            {record.reason || '--'}
                          </div>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(WarrantyHistoryRecordModal);
