import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  createMessageCategory,
  updateMessageCategory,
} from 'redux/actions/data/messageActions';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';


const CategorySettingsModal = ({
  isOpen,
  toggle,
  isEdit,
  options,
  data,
  type,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.createMessageCategory);
  const { loading: updateLoading } = useSelector(
    state => state.updateMessageCategory,
  );
  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        catType: type,
        cate: { label: '請選擇', value: -1 },
        name: isEdit ? data.name || data.parent_name : '',
        name_en: isEdit ? data.name_en || data.parent_name_en : '',
        parentId: isEdit ? data.parentId : 0,
        parent_name: isEdit ? '' : '',
        parent_name_en: isEdit ? '' : '',
        type: '',
      },
      onSubmit: () => {
        if (!isEdit) {
          const body = {
            catType: values.catType,
            name: values.name,
            name_en: values.name_en,
            parentId: values.cate.value === -1 ? 0 : values.cate.value,
            parent_name: values.parent_name,
            parent_name_en: values.parent_name_en,
          };
          CommonUtil.consoleLog({ anno: 'request body', data: body });
          dispatch(createMessageCategory(body));
        } else {
          const body = {
            id: data.id || data.parentId,
            name: values.name,
            name_en: values.name_en,
          };
          CommonUtil.consoleLog({ anno: 'request body', data: body });
          dispatch(updateMessageCategory(body));
        }
      },
      validationSchema: Yup.object().shape({
        cate: Yup.object().when('type', {
          is: val => val === 'select',
          then: Yup.object().shape({
            value: Yup.number().test('test', '必填', val => val !== -1),
          }),
        }),
        isEdit: Yup.boolean(),
        name: Yup.string().required('必填'),
        name_en: Yup.string().required('必填'),
        parent_name: Yup.string().when('type', {
          is: val => val === 'create',
          then: Yup.string().required('必填'),
        }),
        parent_name_en: Yup.string().when('type', {
          is: val => val === 'create',
          then: Yup.string().required('必填'),
        }),
        type: Yup.string().when('isEdit', {
          is: val => val === false,
          then: Yup.string().required('必填'),
        }),
      }),
    });

  return (
    <Modal centered size="lg" zIndex={2000} isOpen={isOpen}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        {`${isEdit ? '編輯' : '新增'}選單`}
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        {!isEdit && (
          <React.Fragment>
            <div className={style.formGroup}>
              <label className="w150 required">
                第一層選單
                {errors.type && touched.type && (
                  <span className="ml-2 text-danger">{errors.type}</span>
                )}
              </label>
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="type"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                    setFieldValue('parent_name', '');
                    setFieldValue('parent_name_en', '');
                  }}
                  value="select"
                  checked={values.type === 'select'}
                />
                <span className="ml-2">選擇分類</span>
              </div>
              <div className="w100" />
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="type"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                    setFieldValue('cate', { label: '請選擇', value: -1 });
                  }}
                  value="create"
                  checked={values.type === 'create'}
                />
                <span className="ml-2">新增</span>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="w150" />
              {values.type === 'select' && (
                <React.Fragment>
                  <Select
                    className={style['form-select-shop-banner']}
                    options={options}
                    value={values.cate}
                    onChange={option => {
                      setFieldValue('cate', option);
                    }}
                  />
                  {errors.cate?.value && touched.cate?.value && (
                    <span className="ml-2 text-danger">
                      {errors.cate.value}
                    </span>
                  )}
                </React.Fragment>
              )}
              {values.type === 'create' && (
                <div>
                  <div className={style.formGroup}>
                    <label>中文</label>
                    <Input
                      className={style['form-select-shop-banner']}
                      id="parent_name"
                      onChange={e => {
                        setFieldValue('parent_name', e.target.value);

                        // setFieldValue('cate', { label: '請選擇', value: -1 });
                      }}
                      value={values.parent_name}
                      invalid={errors.parent_name && touched.parent_name}
                    />
                  </div>
                  <div className={style.formGroup}>
                    <label>英文</label>
                    <Input
                      className={style['form-select-shop-banner']}
                      id="parent_name_en"
                      onChange={e => {
                        setFieldValue('parent_name_en', e.target.value);

                        // setFieldValue('cate', { label: '請選擇', value: -1 });
                      }}
                      value={values.parent_name_en}
                      invalid={errors.parent_name_en && touched.parent_name_en}
                    />
                  </div>
                </div>
              )}
            </div>
            <div style={{ margin: '3rem' }} />
            <div className={style.formGroup}>
              <label className="required w150">第二層選單(中文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name"
                onChange={handleChange}
                value={values.name}
                invalid={errors.name && touched.name}
              />
            </div>
            <div className={style.formGroup}>
              <label className="required w150">第二層選單(英文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name_en"
                onChange={handleChange}
                value={values.name_en}
                invalid={errors.name_en && touched.name_en}
              />
            </div>
          </React.Fragment>
        )}
        {isEdit && (
          <React.Fragment>
            <div className={style.formGroup}>
              <label className="required w150">選單名稱(中文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name"
                onChange={handleChange}
                value={values.name}
                invalid={errors.name && touched.name}
              />
            </div>
            <div className={style.formGroup}>
              <label className="required w150">選單名稱(英文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name_en"
                onChange={handleChange}
                value={values.name_en}
                invalid={errors.name_en && touched.name_en}
              />
            </div>
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading || updateLoading}
        >
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategorySettingsModal;
