import Layout from 'components/Layout';
import { BlockSpinner } from 'components/Spinner';
import DiscountActivityDetailModal from 'components/orderList/DiscountActivityDetailModal';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { getOrderDetail } from 'redux/actions/data/orderActions';
import { useToggle } from 'utils/customHooks';

import DiscountDetailModal from '../components/DiscountDetailModal';
import RedeemRewardPointsDetailModal from '../components/orderList/RedeemRewardPointsDetailModal';
import style from '../styles/layout.module.css';
import { modal } from '../utils/modal';

const OrderDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleInit = () => {
    dispatch(getOrderDetail(id));
  };

  useEffect(() => {
    handleInit();
  }, []);

  const { loading, orderInfo } = useSelector(state => state.orderDetail);
  const [isDiscountActivityModalOpen, setIsDiscountActivityModalOpen] =
    useToggle();
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useToggle();
  const [
    isRedeemRewardPointsDetailModalOpen,
    setIsRedeemRewardPointsDetailModalOpen,
  ] = useToggle();

  return (
    <Layout
      pageTitle={`${orderInfo.orderItemNumber} 訂單明細`}
      items={[{ isActive: true, page_name: '訂單明細' }]}
    >
      <Card className={style.card}>
        {loading && <BlockSpinner />}
        <div className="mb-5">
          <p className={`${style.text_purple} mb-0`}>訂購人資訊</p>
          <hr style={{ backgroundColor: '#3c44b1' }} />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex">
              <div className="w150">會員電話</div>
              <div>{orderInfo.memberPhone}</div>
            </div>
            <div className="d-flex">
              <div className="w150">購買人</div>
              <div>{orderInfo.memberName}</div>
            </div>
            <div className="d-flex">
              <div className="w150">主單編號</div>
              <div>{orderInfo.orderNumber}</div>
            </div>
            <div className="d-flex">
              <div className="w150">訂單編號</div>
              <div>{orderInfo.orderItemNumber}</div>
            </div>
            <div className="d-flex">
              <div className="w150">主單狀態</div>
              <div>{orderInfo.orderStatusName}</div>
            </div>
            <div className="d-flex">
              <div className="w150">主單狀態日期</div>
              <div>{orderInfo.orderStatusTime || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">付款方式</div>
              <div>{orderInfo.payWayName || '--'}</div>
            </div>
            {orderInfo.payWay === 5 && (
              <div className="d-flex">
                <div className="w150">Line Pay付款狀態</div>
                <div>{orderInfo.payStatusName || '--'}</div>
              </div>
            )}
            {orderInfo.payWay === 5 && (
              <div className="d-flex">
                <div className="w150">LINE Pay 交易 ID</div>
                <div>{orderInfo.linePayTransactionId || '--'}</div>
              </div>
            )}
            {orderInfo.payWay !== 5 && (
              <div className="d-flex">
                <div className="w150">付款狀態</div>
                <div>{orderInfo.payStatusName || '--'}</div>
              </div>
            )}
            <div className="d-flex">
              <div className="w150">訂購時間</div>
              <div>{orderInfo.orderTime}</div>
            </div>
            <div className="d-flex">
              <div className="w150">預計出貨日</div>
              <div>{orderInfo.expectedDeliveryDate || '--'}</div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <p className={`${style.text_purple} mb-0`}>收件人資訊</p>
          <hr style={{ backgroundColor: '#3c44b1' }} />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex">
              <div className="w150">收件人</div>
              <div>{orderInfo.name}</div>
            </div>
            <div className="d-flex">
              <div className="w150">收件手機</div>
              <div>{orderInfo.phone}</div>
            </div>
            {orderInfo.logisticsWay === 3 && (
              <div className="d-flex">
                <div className="w150">收件地址</div>
                <div>{`${
                  orderInfo.countryName +
                  orderInfo.cityName +
                  orderInfo.townName +
                  orderInfo.address
                }`}</div>
              </div>
            )}
            {orderInfo.logisticsWay === 3 && (
              <div className="d-flex">
                <div className="w150">備註</div>
                <div>{orderInfo.remark || '--'}</div>
              </div>
            )}
            {orderInfo.logisticsWay !== 3 && (
              <div className="d-flex">
                <div className="w150">門市店號</div>
                <div>
                  {orderInfo.logisticsWay === 4
                    ? orderInfo.store
                    : orderInfo.cvsStoreID}
                </div>
              </div>
            )}
            {orderInfo.logisticsWay !== 3 && (
              <div className="d-flex">
                <div className="w150">門市名稱</div>
                <div>
                  {orderInfo.logisticsWay === 4
                    ? orderInfo.storeName
                    : orderInfo.cvsStoreName}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mb-5">
          <p className={`${style.text_purple} mb-0`}>配送與物流資訊</p>
          <hr style={{ backgroundColor: '#3c44b1' }} />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex">
              <div className="w150">出貨單號</div>
              <div>{orderInfo.shippingNumber || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">配送方式</div>
              <div>{orderInfo.logisticsWayName}</div>
            </div>
            <div className="d-flex">
              <div className="w150">配送編號</div>
              <div>
                {orderInfo.logisticsWay === 3
                  ? orderInfo.homeDeliveryNumber || '--'
                  : orderInfo.logisticsWay === 4
                  ? '--'
                  : orderInfo.cvsPaymentNo || '--'}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">配送商</div>
              <div>{orderInfo.homeDeliveryCName || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">主單運費</div>
              <div className="price text-black fw-normal">
                {(orderInfo.deliveryCharge ?? 0).toLocaleString('en-US')}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">出貨狀態</div>
              <div>
                {orderInfo.shippingStatusName}(
                {orderInfo.shippingStatusDate || '--'})
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">驗退貨原因說明</div>
              <div>{orderInfo.cancelRemark || '--'}</div>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <p className={`${style.text_purple} mb-0`}>商品資訊</p>
          <hr style={{ backgroundColor: '#3c44b1' }} />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex">
              <div className="w150">商品類型</div>
              <div>
                {orderInfo.productType === 1 ? '一般商品' : ''}
                {orderInfo.productType === 2
                  ? `隱形賣場(${orderInfo.modelNumber})`
                  : ''}
                {orderInfo.productType === 3 ? '組合商品' : ''}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">商品交期</div>
              <div>{orderInfo.deliveryType === 1 ? '一般' : '預購'}</div>
            </div>
            {/* <div className="d-flex">
              <div className="w150">預計出貨日</div>
              <div>{orderInfo.expectedDeliveryDate || '--'}</div>
            </div> */}
            <div className="d-flex">
              <div className="w150">預購出貨日</div>
              <div>{orderInfo.preorderDeliveryDate || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">品項編碼</div>
              <div>{orderInfo.productcode}</div>
            </div>
            <div className="d-flex">
              <div className="w150">商品名稱</div>
              <div>{orderInfo.productname}</div>
            </div>
            <div className="d-flex">
              <div className="w150">顏色</div>
              <div>{orderInfo.color || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">尺寸</div>
              <div>{orderInfo.size || '--'}</div>
            </div>
            <div className="d-flex">
              <div className="w150">數量</div>
              <div>{orderInfo.quantity}</div>
            </div>
            <div className="d-flex">
              <div className="w150">商品單價</div>
              <div className="price text-black fw-normal">
                {orderInfo.unitPrice?.toLocaleString('en-US')}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">折扣金額</div>-
              <span className="price text-black fw-normal discountAmount">
                {(orderInfo.discountAmount || '0')?.toLocaleString('en-US')}
              </span>
            </div>
            <div className="d-flex">
              <div className="w150">銷售金額(折扣後)</div>-
              <span className="price text-black fw-normal discountPrice">
                {(orderInfo?.discountPrice || '0')?.toLocaleString('en-US') ||
                  0}
              </span>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <p className={`${style.text_purple} mb-0`}>折扣 / 贈品資訊</p>
          <hr style={{ backgroundColor: '#3c44b1' }} />
          <div className="d-flex flex-column gap-3">
            <div className="d-flex">
              <div className="w150">折扣活動總折扣金額</div>
              <div
                style={{
                  color: '#0077ff',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsDiscountActivityModalOpen();
                }}
              >
                -NT$
                {(orderInfo.activityDiscountAmountUnit || '0')?.toLocaleString(
                  'en-US',
                )}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">折價券總折扣金額</div>
              <div
                style={{
                  color: '#0077ff',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsDiscountModalOpen();
                }}
              >
                -NT$
                {(orderInfo.couponDiscountAmountUnit || 0)?.toLocaleString(
                  'en-US',
                )}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">點數總折扣金額</div>
              <div
                style={{
                  color: '#0077ff',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsRedeemRewardPointsDetailModalOpen();
                }}
              >
                -NT${orderInfo?.redeemAmount?.toLocaleString('en-US') || 0}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">有無商品贈品</div>
              <div
                className={orderInfo.isThereFreebie_P ? 'text-primary' : ''}
                role={orderInfo.isThereFreebie_P ? 'button' : ''}
                onClick={() => {
                  if (orderInfo.isThereFreebie_P) {
                    modal.showGiftInfo('商品贈品', orderInfo.id);
                  }
                }}
              >
                {orderInfo.isThereFreebie_P ? '有' : '無'}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">有無活動贈品</div>
              <div
                className={orderInfo.isThereFreebie_A ? 'text-primary' : ''}
                role={orderInfo.isThereFreebie_A ? 'button' : ''}
                onClick={() => {
                  if (orderInfo.isThereFreebie_A) {
                    modal.showGiftInfo('活動贈品', orderInfo.id);
                  }
                }}
              >
                {orderInfo.isThereFreebie_A ? '有' : '無'}
              </div>
            </div>
            <div className="d-flex">
              <div className="w150">贈品券活動序號</div>
              <div
                className={orderInfo.isThereFreebie_C ? 'text-primary' : ''}
                role={orderInfo.isThereFreebie_C ? 'button' : ''}
                onClick={() => {
                  if (orderInfo.isThereFreebie_C) {
                    modal.showGiftInfo('贈品券', orderInfo.id);
                  }
                }}
              >
                {orderInfo.isThereFreebie_C ? '有' : '無'}
              </div>
            </div>
          </div>
        </div>
      </Card>
      {isDiscountActivityModalOpen && (
        <DiscountActivityDetailModal
          isOpen={isDiscountActivityModalOpen}
          toggle={setIsDiscountActivityModalOpen}
          id={orderInfo.id}
        />
      )}
      {isDiscountModalOpen && (
        <DiscountDetailModal
          isOpen={isDiscountModalOpen}
          toggle={setIsDiscountModalOpen}
          id={orderInfo.id}
        />
      )}
      {isRedeemRewardPointsDetailModalOpen && (
        <RedeemRewardPointsDetailModal
          isOpen={isRedeemRewardPointsDetailModalOpen}
          toggle={setIsRedeemRewardPointsDetailModalOpen}
          id={orderInfo.id}
          data={{
            amount: orderInfo.redeemAmount,
            points: orderInfo.redeemPoint,
          }}
        />
      )}
    </Layout>
  );
};
export default OrderDetail;
