import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import {
  deleteRepair,
  getRepairList,
  postRepair,
} from '../redux/actions/data/serviceActions';
import {
  REPAIR_DELETE_RESET,
  REPAIR_POST_RESET,
} from '../redux/constants/data/serviceConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

const Maintenance = () => {
  const dispatch = useDispatch();
  const [contentId, setContentId] = useState(0);
  const [editData, setEditData] = useState({});
  const [modal, setModal] = useState(false);
  const handleOpenEditModal = () => setModal(!modal);

  const { loading, repairListInfo, error } = useSelector(
    state => state.repairList,
  );
  const {
    loading: postLoading,
    success: postSuccess,
    error: postError,
  } = useSelector(state => state.repairPost);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.repairDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/Maintenance' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/Maintenance' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getRepairList());
  }, []);

  useEffect(() => {
    if (postSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getRepairList());
      setModal(false);
    }

    if (postError) {
      MessageUtil.alertWanring(postError);
    }

    return () => {
      dispatch({ type: REPAIR_POST_RESET });
    };
  }, [postSuccess, postError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getRepairList());
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: REPAIR_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const toggleAdd = () => {
    setModal(!modal);
    setContentId(0);
    setEditData({});
  };

  const toggleEditModal = data => {
    setModal(!modal);
    setContentId(data.id);
    setEditData(data);
  };

  const callDel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteRepair(id));
    });
  };

  const handleSubmit = values => {
    let formData = new FormData();

    formData.append('id', contentId);
    formData.append('status', '上架');
    formData.append('img_desktop_files', values.content_desktop_files[0]);
    formData.append('img_mobile_files', values.content_mobile_files[0]);
    formData.append('img_desktop_en_files', values.content_desktop_files_en[0]);
    formData.append('img_mobile_en_files', values.content_mobile_files_en[0]);

    dispatch(postRepair(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      current_content_desktop_files: Yup.array().min(1, '需上傳圖片'),
      current_content_mobile_files: Yup.array().min(1, '需上傳圖片'),
    });
    return schema;
  };

  const getImageDataFromSetting = (pic, url) => {
    return [
      {
        id: CommonUtil.getNewID(),
        name: pic,
        url: url,
      },
    ];
  };

  useEffect(() => {
    if (repairListInfo) {
      if (JSON.stringify(repairListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: repairListInfo });
      }
    }
  }, [repairListInfo]);

  return (
    <Layout
      pageTitle="維修服務"
      items={[
        { isActive: false, page_name: '客服中心' },
        { isActive: true, page_name: '維修服務' },
      ]}
    >
      <Card className={style.card}>
        <Button className="btn btn-success w100" onClick={toggleAdd}>
          新增
        </Button>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {repairListInfo ? repairListInfo.length : 0}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th style={{ minWidth: '150px' }}>電腦版圖片</th>
                <th style={{ minWidth: '150px' }}>手機版圖片</th>
                <th style={{ minWidth: '150px' }}>電腦版圖片(英)</th>
                <th style={{ minWidth: '150px' }}>手機版圖片(英)</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {repairListInfo && repairListInfo.length > 0 ? (
                  repairListInfo.map((item, index) => (
                    <tr key={item.id}>
                      <td style={{ wordBreak: 'keep-all' }}>{index + 1}</td>
                      <td>
                        <img
                          className="w150"
                          src={item.img_desktopUrl}
                          alt={item.content}
                        />
                      </td>
                      <td>
                        <img
                          className="w150"
                          src={item.img_mobileUrl}
                          alt={item.content}
                        />
                      </td>
                      <td>
                        <img
                          className="w150"
                          src={item.img_desktopUrl_en}
                          alt={item.content_en}
                        />
                      </td>
                      <td>
                        <img
                          className="w150"
                          src={item.img_mobileUrl_en}
                          alt={item.content_en}
                        />
                      </td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {item.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {item.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {item.creator}
                          </span>
                        </div>
                        {item.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {item.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {item.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => toggleEditModal(item)}
                        >
                          編輯分類
                        </Button>
                        <Button
                          className="m-2"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(item.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={modal}
        toggle={handleOpenEditModal}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={handleOpenEditModal}>
          品牌保固升級
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            content_desktop_files: [],
            content_desktop_files_en: [],
            content_mobile_files: [],

            content_mobile_files_en: [],
            current_content_desktop_files: [],
            current_content_desktop_files_en: [],

            current_content_mobile_files: [],
            current_content_mobile_files_en: [],
            init_content_desktop_files:
              editData && editData.img_desktop
                ? getImageDataFromSetting(
                    editData.img_desktop,
                    editData.img_desktopUrl,
                  )
                : [],

            init_content_desktop_files_en:
              editData && editData.img_desktop_en
                ? getImageDataFromSetting(
                    editData.img_desktop_en,
                    editData.img_desktopUrl_en,
                  )
                : [],
            init_content_mobile_files:
              editData && editData.img_mobile
                ? getImageDataFromSetting(
                    editData.img_mobile,
                    editData.img_mobileUrl,
                  )
                : [],
            init_content_mobile_files_en:
              editData && editData.img_mobile_en
                ? getImageDataFromSetting(
                    editData.img_mobile_en,
                    editData.img_mobileUrl_en,
                  )
                : [],
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版圖片
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_files"
                      currentFileFieldName="current_content_desktop_files"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_files}
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版圖片
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_files"
                      currentFileFieldName="current_content_mobile_files"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_files}
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    電腦版圖片(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_desktop_files_en"
                      currentFileFieldName="current_content_desktop_files_en"
                      uplodModel="images"
                      displayFileWidth="1040"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_desktop_files_en}
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '120px' }}>
                    手機版圖片(英)
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="content_mobile_files_en"
                      currentFileFieldName="current_content_mobile_files_en"
                      uplodModel="images"
                      displayFileWidth="800"
                      displayFileHeight="不限"
                      displayFileSize="1MB"
                      initFiles={props.values.init_content_mobile_files_en}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  className={style.modal_button_cancel}
                  onClick={handleOpenEditModal}
                  disabled={postLoading}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  儲存
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
};

export default Maintenance;
