import Layout from 'components/Layout';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Table,
} from 'reactstrap';
import {
  getOrderInvoiceDetail,
  getOrderInvoiceList,
} from 'redux/actions/data/orderInvoiceActions';
import CommonUtil from 'utils/CommonUtil';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const DetailModal = ({ isOpen, toggle }) => {
  const { loading, orderInvoiceDetailInfo, success } = useSelector(
    state => state.orderInvoiceDetail,
  );

  let totalAmount = 0;

  return (
    <Modal isOpen={isOpen} zIndex={2000} centered toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className={style.modal_head}>
        發票明細(含稅)
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th style={{ textAlign: 'left' }}>項目</th>
                <th>數量</th>
                <th>金額</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {success && orderInvoiceDetailInfo.length > 0 ? (
                  orderInvoiceDetailInfo.map(info => {
                    totalAmount += info.itemAmount;
                    return (
                      <tr key={info.id}>
                        <td>{info.itemName || '--'}</td>
                        <td style={{ textAlign: 'center' }}>
                          {info.itemCount || '--'}
                        </td>
                        <td
                          style={{ textAlign: 'right' }}
                        >{`NT$ ${info.itemAmount.toLocaleString('en-US')}`}</td>
                      </tr>
                    );
                  })
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <b>{`總額 NT$ ${totalAmount.toLocaleString('en-US')}`}</b>
      </ModalFooter>
    </Modal>
  );
};

const TableList = ({ onChangePage }) => {
  const dispatch = useDispatch();
  const { loading, orderInvoiceListInfo } = useSelector(
    state => state.orderInvoiceList,
  );

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const toggleDetailModalHandler = () => {
    setIsModalOpened(!isModalOpened);
  };

  useEffect(() => {
    currentId && dispatch(getOrderInvoiceDetail(currentId));
  }, [currentId]);

  return (
    <Card className={style.card}>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>主單編號</th>
              <th>發票號碼</th>
              <th>開立時間</th>
              <th>開立金額</th>
              <th>作廢時間</th>
              <th>統一編號</th>
              <th>載具類別</th>
              <th>載具編號</th>
              <th>愛心碼</th>
              <th>發票異常</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {orderInvoiceListInfo?.datas?.length > 0 ? (
                orderInvoiceListInfo?.datas?.map((i, index) => (
                  <tr className="text-nowrap" key={i.id}>
                    <td>{i.orderNumber}</td>
                    <td>{i.invoiceNo || '--'}</td>
                    <td>{i.issueTime || '--'}</td>
                    <td className="text-center">
                      <div>{`NT$ ${i.salesAmount.toLocaleString(
                        'en-US',
                      )}`}</div>
                      <div
                        className={style.text_dark_info}
                        style={{
                          cursor: 'pointer',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                        onClick={() => {
                          setCurrentId(i.id);
                          toggleDetailModalHandler();
                        }}
                      >
                        明細
                      </div>
                    </td>
                    <td>{i.invalidTime || '--'}</td>
                    <td>{i.customerIdentifier || '--'}</td>
                    <td>{i.carrierType || '--'}</td>
                    <td>{i.carrierNum || '--'}</td>
                    <td>{i.loveCode || '--'}</td>
                    <td>{i.errMsg || '--'}</td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
        {loading && (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        )}
      </div>
      {orderInvoiceListInfo?.total_count > 0 && (
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            defaultCurrent={orderInvoiceListInfo?.current_page}
            defaultPageSize={orderInvoiceListInfo?.page_size}
            total={orderInvoiceListInfo?.total_count}
            onChange={onChangePage}
          />
        </div>
      )}
      <DetailModal isOpen={isModalOpened} toggle={toggleDetailModalHandler} />
    </Card>
  );
};
const OrderInvoiceList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    handleSearch({
      endTime: moment().toDate(),
      page: 1,
      page_size: 10,
      startTime: moment().subtract(7, 'day').toDate(),
      timeType: '異常時間',
    });
  }, []);
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endTime: null,
        keyword: '',
        page: 1,
        page_size: 10,
        startTime: null,
        timeType: '異常時間',
      },
      async onSubmit(formModel) {
        const params = {
          ...formModel,
          endTime: formModel.endTime ?? moment().toDate(),
          startTime:
            formModel.startTime ?? moment().subtract(7, 'day').toDate(),
        };
        CommonUtil.consoleLog({ anno: 'request body', data: params });
        await dispatch(getOrderInvoiceList(params));
      },
    });

  const handleSearch = params => {
    handleSubmit(params);
  };

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  return (
    <Layout
      pageTitle="電子發票開立記錄"
      items={[{ isActive: true, page_name: '電子發票開立記錄' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className="d-flex flex-wrap w-100">
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem', width: '100%' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-keyword']}`}
                  name="keyword"
                  placeholder="主單編號、發票號碼"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label text-nowrap mr-2">
                  開立時間
                </label>
                <Input
                  type="select"
                  className={style['form-select-medium']}
                  style={{ margin: 0 }}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('timeType', value);
                  }}
                >
                  <option value="異常時間">異常發票</option>
                  <option value="">正常發票</option>
                </Input>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    values.startTime ?? moment().subtract(7, 'day').toDate()
                  }
                  name="startTime"
                  onChange={date => {
                    setFieldValue('startTime', date);
                  }}
                  selectsStart
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={values.endTime ?? new Date()}
                  name="endTime"
                  onChange={date => {
                    setFieldValue('endTime', date);
                  }}
                  selectsEnd
                  minDate={values.startTime}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={handleSubmit}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <TableList onChangePage={handleChangePage} />
    </Layout>
  );
};
export default OrderInvoiceList;
