// ============ MEALINTRO ==================

export const UUCAFE_MEALINTRO_DETAIL_REQUEST =
  'UUCAFE_MEALINTRO_DETAIL_REQUEST';
export const UUCAFE_MEALINTRO_DETAIL_SUCCESS =
  'UUCAFE_MEALINTRO_DETAIL_SUCCESS';
export const UUCAFE_MEALINTRO_DETAIL_FAIL = 'UUCAFE_MEALINTRO_DETAIL_FAIL';
export const UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST =
  'UUCAFE_MEALINTRO_DETAIL_UPDATE_REQUEST';
export const UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS =
  'UUCAFE_MEALINTRO_DETAIL_UPDATE_SUCCESS';
export const UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL =
  'UUCAFE_MEALINTRO_DETAIL_UPDATE_FAIL';

// ============ MEALINTRO_SEO ==================

export const UUCAFE_MEALINTRO_SEO_REQUEST = 'UUCAFE_MEALINTRO_SEO_REQUEST';
export const UUCAFE_MEALINTRO_SEO_SUCCESS = 'UUCAFE_MEALINTRO_SEO_SUCCESS';
export const UUCAFE_MEALINTRO_SEO_FAIL = 'UUCAFE_MEALINTRO_SEO_FAIL';
export const UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST =
  'UUCAFE_MEALINTRO_SEO_UPDATE_REQUEST';
export const UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS =
  'UUCAFE_MEALINTRO_SEO_UPDATE_SUCCESS';
export const UUCAFE_MEALINTRO_SEO_UPDATE_FAIL =
  'UUCAFE_MEALINTRO_SEO_UPDATE_FAIL';

// ============ MEALINTRO_Menu ==================

export const UUCAFE_MEALINTRO_MENU_LIST_REQUEST =
  'UUCAFE_MEALINTRO_MENU_LIST_REQUEST';
export const UUCAFE_MEALINTRO_MENU_LIST_SUCCESS =
  'UUCAFE_MEALINTRO_MENU_LIST_SUCCESS';
export const UUCAFE_MEALINTRO_MENU_LIST_FAIL =
  'UUCAFE_MEALINTRO_MENU_LIST_FAIL';

export const UUCAFE_MEALINTRO_MENU_REQUEST = 'UUCAFE_MEALINTRO_MENU_REQUEST';
export const UUCAFE_MEALINTRO_MENU_SUCCESS = 'UUCAFE_MEALINTRO_MENU_SUCCESS';
export const UUCAFE_MEALINTRO_MENU_FAIL = 'UUCAFE_MEALINTRO_MENU_FAIL';

export const UUCAFE_MEALINTRO_MENU_ADD_REQUEST =
  'UUCAFE_MEALINTRO_MENU_ADD_REQUEST';
export const UUCAFE_MEALINTRO_MENU_ADD_SUCCESS =
  'UUCAFE_MEALINTRO_MENU_ADD_SUCCESS';
export const UUCAFE_MEALINTRO_MENU_ADD_FAIL = 'UUCAFE_MEALINTRO_MENU_ADD_FAIL';

export const UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST =
  'UUCAFE_MEALINTRO_MENU_UPDATE_REQUEST';
export const UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS =
  'UUCAFE_MEALINTRO_MENU_UPDATE_SUCCESS';
export const UUCAFE_MEALINTRO_MENU_UPDATE_FAIL =
  'UUCAFE_MEALINTRO_MENU_UPDATE_FAIL';

// ============ MEALINTRO_Cafe_Zone ==================
export const UUCAFE_MEALINTRO_ZONE_REQUEST = 'UUCAFE_MEALINTRO_ZONE_REQUEST';
export const UUCAFE_MEALINTRO_ZONE_SUCCESS = 'UUCAFE_MEALINTRO_ZONE_SUCCESS';
export const UUCAFE_MEALINTRO_ZONE_FAIL = 'UUCAFE_MEALINTRO_ZONE_FAIL';

export const UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST =
  'UUCAFE_MEALINTRO_ZONE_SAVE_REQUEST';
export const UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS =
  'UUCAFE_MEALINTRO_ZONE_SAVE_SUCCESS';
export const UUCAFE_MEALINTRO_ZONE_SAVE_FAIL =
  'UUCAFE_MEALINTRO_ZONE_SAVE_FAIL';

export const UUCAFE_MEALINTRO_ZONE_SEO_REQUEST =
  'UUCAFE_MEALINTRO_ZONE_SEO_REQUEST';
export const UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS =
  'UUCAFE_MEALINTRO_ZONE_SEO_SUCCESS';
export const UUCAFE_MEALINTRO_ZONE_SEO_FAIL = 'UUCAFE_MEALINTRO_ZONE_SEO_FAIL';

export const UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST =
  'UUCAFE_MEALINTRO_ZONE_SEO_SAVE_REQUEST';
export const UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS =
  'UUCAFE_MEALINTRO_ZONE_SEO_SAVE_SUCCESS';
export const UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL =
  'UUCAFE_MEALINTRO_ZONE_SEO_SAVE_FAIL';
