import axios from 'axios';
import CommonUtil from 'utils/CommonUtil';
import { useRequest } from 'utils/useFetch';

import {
  INSTRUCTION_DELETE_FAIL,
  INSTRUCTION_DELETE_REQUEST,
  INSTRUCTION_DELETE_SUCCESS,
  INSTRUCTION_FAQ_DELETE_FAIL,
  INSTRUCTION_FAQ_DELETE_REQUEST,
  INSTRUCTION_FAQ_DELETE_SUCCESS,
  INSTRUCTION_FAQ_LIST_FAIL,
  INSTRUCTION_FAQ_LIST_REQUEST,
  INSTRUCTION_FAQ_LIST_SUCCESS,
  INSTRUCTION_FAQ_POST_FAIL,
  INSTRUCTION_FAQ_POST_REQUEST,
  INSTRUCTION_FAQ_POST_SUCCESS,
  INSTRUCTION_LIST_FAIL,
  INSTRUCTION_LIST_REQUEST,
  INSTRUCTION_LIST_SUCCESS,
  INSTRUCTION_POST_FAIL,
  INSTRUCTION_POST_REQUEST,
  INSTRUCTION_POST_SUCCESS,
  REPAIR_DELETE_FAIL,
  REPAIR_DELETE_REQUEST,
  REPAIR_DELETE_SUCCESS,
  REPAIR_LIST_FAIL,
  REPAIR_LIST_REQUEST,
  REPAIR_LIST_SUCCESS,
  REPAIR_POST_FAIL,
  REPAIR_POST_REQUEST,
  REPAIR_POST_SUCCESS,
  SERVICE_CONTENT_DELETE_FAIL,
  SERVICE_CONTENT_DELETE_REQUEST,
  SERVICE_CONTENT_DELETE_SUCCESS,
  SERVICE_CONTENT_POST_FAIL,
  SERVICE_CONTENT_POST_REQUEST,
  SERVICE_CONTENT_POST_SUCCESS,
  SERVICE_LIST_FAIL,
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_TITLE_POST_FAIL,
  SERVICE_TITLE_POST_REQUEST,
  SERVICE_TITLE_POST_SUCCESS,
  UPDATE_SERVICE_REMARK_FAIL,
  UPDATE_SERVICE_REMARK_REQUEST,
  UPDATE_SERVICE_REMARK_SUCCESS,
  WARRANTY_DELETE_FAIL,
  WARRANTY_DELETE_REQUEST,
  WARRANTY_DELETE_SUCCESS,
  WARRANTY_LIST_FAIL,
  WARRANTY_LIST_REQUEST,
  WARRANTY_LIST_SUCCESS,
  WARRANTY_POST_FAIL,
  WARRANTY_POST_REQUEST,
  WARRANTY_POST_SUCCESS,
} from '../../constants/data/serviceConstants';

import { baseURL, prefix_api_path } from './api_config';

//get service list
export const getServiceList = () => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/MemBenefitBlock/list' });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: SERVICE_LIST_FAIL,
      method: 'get',
      requestConstant: SERVICE_LIST_REQUEST,
      successConstant: SERVICE_LIST_SUCCESS,
      url: '/Backend/MemBenefitBlock/list',
    });
  };
};

//post service title
export const postServiceTitle = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_TITLE_POST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${baseURL + prefix_api_path}/Backend/MemBenefitBlock/block`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: SERVICE_TITLE_POST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SERVICE_TITLE_POST_FAIL,
      });
    }
  };
};

//post service content
export const postServiceContent = formData => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/MemBenefitBlockContent/blockContent',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: SERVICE_CONTENT_POST_FAIL,
      method: 'post',
      requestConstant: SERVICE_CONTENT_POST_REQUEST,
      successConstant: SERVICE_CONTENT_POST_SUCCESS,
      url: '/Backend/MemBenefitBlockContent/blockContent',
    });
  };
};

//delete service content
export const deleteServiceContent = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_CONTENT_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.delete(
        `${
          baseURL + prefix_api_path
        }/Backend/MemBenefitBlockContent/blockContent?contentId=${id}`,

        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: SERVICE_CONTENT_DELETE_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: SERVICE_CONTENT_DELETE_FAIL,
      });
    }
  };
};

//get warranty list
export const getWarrantyList = () => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Warranty/list' });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: WARRANTY_LIST_FAIL,
      method: 'get',
      requestConstant: WARRANTY_LIST_REQUEST,
      successConstant: WARRANTY_LIST_SUCCESS,
      url: '/Backend/Warranty/list',
    });
  };
};

//post warranty
export const postWarranty = formData => {
  CommonUtil.consoleLog({ anno: 'request body', formData });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Warranty/warranty' });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: WARRANTY_POST_FAIL,
      method: 'post',
      requestConstant: WARRANTY_POST_REQUEST,
      successConstant: WARRANTY_POST_SUCCESS,
      url: '/Backend/Warranty/warranty',
    });
  };
};

//delete warranty
export const deleteWarranty = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/Warranty/warranty?warrantyId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: WARRANTY_DELETE_FAIL,
      method: 'delete',
      requestConstant: WARRANTY_DELETE_REQUEST,
      successConstant: WARRANTY_DELETE_SUCCESS,
      url: `/Backend/Warranty/warranty?warrantyId=${id}`,
    });
  };
};

//get repair list
export const getRepairList = () => {
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Repair/list' });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: REPAIR_LIST_FAIL,
      method: 'get',
      requestConstant: REPAIR_LIST_REQUEST,
      successConstant: REPAIR_LIST_SUCCESS,
      url: '/Backend/Repair/list',
    });
  };
};

//post repair
export const postRepair = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({ anno: 'API', data: '/Backend/Repair/repair' });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: REPAIR_POST_FAIL,
      method: 'post',
      requestConstant: REPAIR_POST_REQUEST,
      successConstant: REPAIR_POST_SUCCESS,
      url: '/Backend/Repair/repair',
    });
  };
};

//delete repair
export const deleteRepair = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/Repair/repair?repairId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: REPAIR_DELETE_FAIL,
      method: 'delete',
      requestConstant: REPAIR_DELETE_REQUEST,
      successConstant: REPAIR_DELETE_SUCCESS,
      url: `/Backend/Repair/repair?repairId=${id}`,
    });
  };
};

//get instruction list
export const getInstructionList = ({ page, brandId, model }) => {
  const url = `/Backend/BrandModelInstruction/list?page=${page}&page_size=10${
    brandId ? `&brandId=${brandId}` : ''
  }${model ? `&model=${model}` : ''}`;
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: INSTRUCTION_LIST_FAIL,
      method: 'get',
      requestConstant: INSTRUCTION_LIST_REQUEST,
      successConstant: INSTRUCTION_LIST_SUCCESS,
      url,
    });
  };
};

//post instruction
export const postInstruction = formData => {
  CommonUtil.consoleLog({ anno: 'request body', data: formData });
  CommonUtil.consoleLog({
    anno: 'API',
    data: '/Backend/BrandModelInstruction/instruction',
  });
  return async dispatch => {
    await useRequest({
      data: formData,
      dispatch,
      failConstant: INSTRUCTION_POST_FAIL,
      method: 'post',
      requestConstant: INSTRUCTION_POST_REQUEST,
      successConstant: INSTRUCTION_POST_SUCCESS,
      url: '/Backend/BrandModelInstruction/instruction',
    });
  };
};

//delete instruction
export const deleteInstruction = id => {
  CommonUtil.consoleLog({
    anno: 'API',
    data: `/Backend/BrandModelInstruction/instruction?instructionId=${id}`,
  });
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: INSTRUCTION_DELETE_FAIL,
      method: 'delete',
      requestConstant: INSTRUCTION_DELETE_REQUEST,
      successConstant: INSTRUCTION_DELETE_SUCCESS,
      url: `/Backend/BrandModelInstruction/instruction?instructionId=${id}`,
    });
  };
};

//get instruction FAQ list
export const getFAQList = ({ page, brandId, model, keyword, status }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSTRUCTION_FAQ_LIST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      let link = `${
        baseURL + prefix_api_path
      }/Backend/BrandModelInstructionFAQ/list?brandId=${brandId}&model=${model}&page=${page}&page_size=10`;

      if (keyword) {
        link = link.concat(`&keyword=${keyword}`);
      }
      if (status) {
        link = link.concat(`&status=${status}`);
      }

      const { data } = await axios.get(link, config);

      console.log(data);

      dispatch({
        payload: data,
        type: INSTRUCTION_FAQ_LIST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        payload: error && error.response ? error.response : error,
        type: INSTRUCTION_FAQ_LIST_FAIL,
      });
    }
  };
};

//post instruction FAQ
export const postFAQ = formData => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSTRUCTION_FAQ_POST_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.post(
        `${
          baseURL + prefix_api_path
        }/Backend/BrandModelInstructionFAQ/instructionFaq`,
        formData,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: INSTRUCTION_FAQ_POST_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: INSTRUCTION_FAQ_POST_FAIL,
      });
    }
  };
};

//delete instruction FAQ
export const deleteFAQ = id => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INSTRUCTION_FAQ_DELETE_REQUEST });

      const { token } = getState().userLogin.userInfo;

      const config = {
        headers: {
          Authorization: `Bearer ${  token}`,
          'Content-Type': 'application/json',
        },
      };

      const { data } = await axios.delete(
        `${
          baseURL + prefix_api_path
        }/Backend/BrandModelInstructionFAQ/instructionFaq?faqId=${id}`,
        config,
      );

      console.log(data);

      dispatch({
        payload: data,
        type: INSTRUCTION_FAQ_DELETE_SUCCESS,
      });
    } catch (error) {
      console.log(error.response);
      dispatch({
        payload: error && error.response ? error.response : error,
        type: INSTRUCTION_FAQ_DELETE_FAIL,
      });
    }
  };
};

export const updateServiceRemark = data => {
  const url = '/Backend/Member/UpdateCsRemark';
  CommonUtil.consoleLog({ anno: 'request body', data });
  CommonUtil.consoleLog({ anno: 'API', data: url });
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: UPDATE_SERVICE_REMARK_FAIL,
      method: 'post',
      requestConstant: UPDATE_SERVICE_REMARK_REQUEST,
      successConstant: UPDATE_SERVICE_REMARK_SUCCESS,
      url,
    });
  };
};
