import {
  UUCAFE_MIDDLE_HOME_SETTINGS_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS,
  UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST,
  UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeMiddleHomeSettingsConstants';

export const cafeMiddleHomeSettingsListReducer = (
  state = {
    error: null,
    list: [],
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MIDDLE_HOME_SETTINGS_LIST_REQUEST:
      return {
        error: null,
        list: [],
        loading: true,
        success: false,
      };
    case UUCAFE_MIDDLE_HOME_SETTINGS_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_MIDDLE_HOME_SETTINGS_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeMiddleHomeSettingReducer = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MIDDLE_HOME_SETTINGS_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_MIDDLE_HOME_SETTINGS_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_MIDDLE_HOME_SETTINGS_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MIDDLE_HOME_SETTINGS_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
