import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Table } from 'reactstrap';
import style from 'styles/layout.module.css';

import { getHideModelDetail } from '../../redux/actions/data/hideModelActions';
import MessageUtil from '../../utils/MessageUtil';
import request from '../../utils/useAxios';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

import AddEditSpecModal from './AddEditSpecModal';

const HideModelSpec = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, hideModelDetailInfo } = useSelector(
    state => state.hideModelDetail,
  );
  const [addModalOpen, setAddModalOpen] = useState(false);
  const handleToggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleDelete = itemId => {
    MessageUtil.submitConfirm({
      async doneFun() {
        setSubmitLoading(true);
        const { code, message } = await request({
          url: `/Backend/HideModel/delSpec/${id}/${itemId}`,
        });
        setSubmitLoading(false);
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('刪除成功！');
        dispatch(getHideModelDetail(id));
      },
      title: '確認刪除？',
    });
  };

  const [currentSpecId, setCurrentSpecId] = useState(null);
  const handleGetSpecDetail = itemId => {
    setCurrentSpecId(itemId);
    setAddModalOpen(true);
  };

  return (
    <Card className={style.card}>
      <Button
        className="btn btn-success w100"
        onClick={() => {
          setAddModalOpen(true);
          setCurrentSpecId(null);
        }}
      >
        新增
      </Button>
      <div className="d-flex justify-content-end">
        共
        <span className="mx-1">
          {hideModelDetailInfo?.spec?.list !== null
            ? hideModelDetailInfo?.spec?.list.length
            : 0}
        </span>
        筆
      </div>
      <div className="table-responsive">
        <Table hover bordered striped>
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>#</th>
              <th>標題</th>
              <th>說明</th>
              <th>功能</th>
            </tr>
          </thead>
          {(loading || submitLoading) && <TableLoading />}
          {!(loading || submitLoading) && (
            <tbody>
              {hideModelDetailInfo?.spec?.list !== null &&
              hideModelDetailInfo?.spec?.list?.length > 0 ? (
                hideModelDetailInfo?.spec?.list?.map((i, index) => (
                  <tr key={i.id}>
                    <td>{index + 1}</td>
                    <td>{i.title}</td>
                    <td>{i.content}</td>
                    <td>
                      <Button
                        className="m-2"
                        type="button"
                        color="primary"
                        outline
                        size="sm"
                        onClick={() => handleGetSpecDetail(i.id)}
                      >
                        編輯
                      </Button>
                      <Button
                        className="m-2"
                        type="button"
                        color="danger"
                        size="sm"
                        outline
                        onClick={() => handleDelete(i.id)}
                      >
                        刪除
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      {addModalOpen && (
        <AddEditSpecModal
          open={addModalOpen}
          toggle={handleToggleAddModal}
          currentSpecId={currentSpecId}
        />
      )}
    </Card>
  );
};

export default memo(HideModelSpec);
