import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import { emptyOption } from 'utils';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  assignModelToBrandCategory,
  getBrandCategoryModelList,
  removeBrandCategoryModel,
} from '../redux/actions/data/brandActions';
import { getModelList } from '../redux/actions/data/modelActions';
import { getModelOptions } from '../redux/actions/data/productActions';
import {
  BRAND_CATEGORY_MODEL_ASSIGN_RESET,
  BRAND_CATEGORY_MODEL_REMOVE_REQUEST,
} from '../redux/constants/data/brandConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil.js';
import MessageUtil from '../utils/MessageUtil';

export default function BrandCategoryPrds(props) {
  const dispatch = useDispatch();
  const { brandId, cateId } = useParams();

  const { loading, brandCategoryModelList, error } = useSelector(
    state => state.brandCategoryModelList,
  );
  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      initialValues: {
        keyword: '',
        model: emptyOption('請選擇型號', ''),
      },
      onSubmit: values => {
        setCurrentPage(1);
        handleSearch({
          ...values,
          brandCateId: cateId,
          brandId,
          model: values.model.value,
        });
      },
    });
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({
  //       pathname: '/Login',
  //       state: `/BrandCategoryPrds/${brandId}/${cateId}`,
  //     });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({
  //         pathname: '/Login',
  //         state: `/BrandCategoryPrds/${brandId}/${cateId}`,
  //       });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  const handleSearch = params => {
    dispatch(getBrandCategoryModelList(params));
  };
  useEffect(() => {
    if (brandCategoryModelList) {
      dispatch(getModelOptions(brandCategoryModelList?.brandName));
    }
  }, [brandCategoryModelList]);

  const [current_page, setCurrentPage] = useState(1);
  useEffect(() => {
    handleSearch({
      ...values,
      brandCateId: cateId,
      brandId,
      model: values.model.value,
    });
  }, [current_page]);

  // DELETE
  const handleRemoveBrandCategoryModel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(removeBrandCategoryModel(id));
    });
  };
  const { success: deleteSuccess, error: deleteError } = useSelector(state => {
    return state.brandCategoryModelRemove;
  });
  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(
        getBrandCategoryModelList({
          brandCateId: cateId,
          brandId,
          page: current_page,
        }),
      );

      if (brandCategoryModelList?.datas.length !== 1) {
        dispatch(
          getBrandCategoryModelList({
            brandCateId: cateId,
            brandId,
            page: current_page,
          }),
        );
      } else {
        dispatch(
          getBrandCategoryModelList({
            brandCateId: cateId,
            brandId,
            page: current_page - 1,
          }),
        );
        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError) {
      MessageUtil.alertWanring('資料錯誤', '');
    }

    return () => {
      dispatch({ type: BRAND_CATEGORY_MODEL_REMOVE_REQUEST });
    };
  }, [deleteSuccess, deleteError]);

  /**** modal  ****/
  const [isOpenModal, setOpenModal] = useState(false);
  const [modal_current_page, setModalCurrentPage] = useState(1);
  const [modal_keyword, setModalKeyword] = useState('');
  const [modal_model, setModalModel] = useState(emptyOption('請選擇型號', ''));
  const [existProductIds, setExistProductIds] = useState([]);
  const { modelListInfo } = useSelector(state => state.modelList);
  const toggleModal = () => {
    setOpenModal(prev => !prev);
  };

  useEffect(() => {
    if (brandCategoryModelList && brandCategoryModelList?.datas?.length > 0) {
      const existProductIds = brandCategoryModelList?.datas.map(
        item => item.id,
      );
      setExistProductIds(existProductIds);
    }
  }, [brandCategoryModelList]);
  useEffect(() => {
    isOpenModal && handleModalSearch();
    isOpenModal || clearModalSearchForm();
  }, [isOpenModal]);
  const handleModalSearch = () => {
    dispatch(
      getModelList({
        brandId: brandId,
        keyword: modal_keyword,
        model: modal_model.value,
        page: modal_current_page,
        page_size: 10,
      }),
    );
  };
  const clearModalSearchForm = () => {
    setModalCurrentPage(1);
    setModalKeyword('');
    setModalModel(emptyOption('請選擇型號', ''));
  };

  const [selProductIds, setSelProductIds] = useState([]);
  const handleCheck = (id, checked) => {
    if (checked) {
      setSelProductIds([...selProductIds, id]);
    } else {
      setSelProductIds(selProductIds.filter(productId => productId !== id));
    }
  };
  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.brandCategoryAssignModel);
  useEffect(() => {
    handleModalSearch();
  }, [modal_current_page]);

  const callAssignToBrandCategory = () => {
    if (selProductIds.length === 0) {
      MessageUtil.alertWanring('請新增商品', '');
      return;
    }
    dispatch(
      assignModelToBrandCategory({
        brandCateId: cateId,
        modelIds: selProductIds,
      }),
    );
  };
  useEffect(() => {
    if (addSuccess) {
      toggleModal();
      MessageUtil.toastSuccess('新增成功!!');
      handleSearch({ brandCateId: cateId, brandId, page: 1 });
    }

    if (addError) {
      if (addError.code === APIUtil.Code_Product_Extensions_Not_Exists) {
        MessageUtil.alertWanring('商品不存在', '');
      }
    }
    return () => {
      dispatch({ type: BRAND_CATEGORY_MODEL_ASSIGN_RESET });
    };
  }, [addSuccess, addError]);

  return (
    <Layout
      pageTitle={
        brandCategoryModelList &&
        `${brandCategoryModelList.brandName} - ${brandCategoryModelList.brandCateName} 分類設定`
      }
      items={[
        { isActive: false, page_name: '品牌管理', to: '/BrandControl' },
        {
          isActive: false,
          page_name: '類別管理',
          to: `/BrandCategory/${brandId}`,
        },
        { isActive: true, page_name: '商品設定管理' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div className={`me-3 ${style.formGroup}`}>
                <label className="w80">關鍵字</label>
                <input
                  type="text"
                  className="form-control"
                  value={values.keyword}
                  onChange={handleChange}
                  placeholder="商品名稱"
                  name="keyword"
                />
              </div>
              <div className={style.formGroup}>
                <label className="me-3">型號</label>
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  className="w200"
                  options={
                    modelOptions && [
                      { label: '請選擇型號', value: '' },
                      ...modelOptions,
                    ]
                  }
                  value={values.model}
                  onChange={val => {
                    setFieldValue('model', val);
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <button
                type="button"
                className={style.modal_button_cancel}
                onClick={handleReset}
              >
                取消
              </button>
              <button
                type="button"
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                查詢
              </button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <button className="btn btn-success w100" onClick={toggleModal}>
          新增
        </button>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading &&
              brandCategoryModelList &&
              brandCategoryModelList.total_count}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>型號</th>
                <th style={{ minWidth: '120px' }}>商品</th>
                <th>上架</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {brandCategoryModelList &&
                brandCategoryModelList?.datas?.length > 0 ? (
                  brandCategoryModelList?.datas?.map((data, index) => (
                    <tr key={index}>
                      <td>{data.model}</td>
                      <td>{data.modelName}</td>
                      <td>
                        <p>{data.isSell ? '官網商品' : ''}</p>
                        <p>{data.isInstruction ? '商品操作說明' : ''}</p>
                        <p>{data.isWarranty ? '商品保固' : ''}</p>
                        <p>{data.isRepair ? '商品維修' : ''}</p>
                      </td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span
                            className="me-2"
                            style={{ wordBreak: 'break-word' }}
                          >
                            {data.createDate}
                          </span>
                          <span
                            className="me-2"
                            style={{ wordBreak: 'break-word' }}
                          >
                            {data.createTime}
                          </span>
                          <span className="d-inline-block">{data.creator}</span>
                        </div>
                        {data.updateDate && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="me-2">{data.updateTime}</span>
                            <span className="d-inline-block">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() =>
                            handleRemoveBrandCategoryModel(data.id)
                          }
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {brandCategoryModelList && brandCategoryModelList.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={brandCategoryModelList.current_page}
              defaultPageSize={brandCategoryModelList.page_size}
              total={brandCategoryModelList.total_count}
              onChange={setCurrentPage}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={1000}
        centered
        size="xl"
        isOpen={isOpenModal}
        toggle={toggleModal}
        backdrop="static"
      >
        <ModalHeader toggle={toggleModal}>新增類別商品</ModalHeader>
        <ModalBody>
          <div className="form-inline">
            <form className="d-flex flex-wrap">
              <div className="form-group me-2 mb-2">
                <input
                  className="form-control w200"
                  placeholder="關鍵字"
                  value={modal_keyword}
                  onChange={e => setModalKeyword(e.target.value)}
                />
              </div>

              <div className="form-group me-2 mb-2">
                <Select
                  isDisabled={modelOptionsLoading}
                  isLoading={modelOptionsLoading}
                  className="w200"
                  options={
                    modelOptions && [
                      { label: '請選擇型號', value: '' },
                      ...modelOptions,
                    ]
                  }
                  value={modal_model}
                  onChange={option => setModalModel(option)}
                />
              </div>
              <div className="d-flex ml-auto">
                <button
                  tyep="submit"
                  className={style.modal_button_submit}
                  onClick={handleModalSearch}
                >
                  搜尋
                </button>
              </div>
            </form>
          </div>

          <div className="text-danger mr-auto">
            ※同商品編碼只能指定一個品牌類別
          </div>
          <div className="d-flex justify-content-end">
            共
            <span className="mx-1">
              {modelListInfo?.total_count > 0
                ? modelListInfo?.total_count
                : '0'}
            </span>
            筆
          </div>
          <div className="table-responsive">
            <Table hover bordered striped className="mb-5">
              <thead className={`thead-dark ${style.table_head}`}>
                <tr>
                  <th>#</th>
                  <th>型號</th>
                  <th>商品</th>
                  <th>品牌類別</th>
                  <th>品牌上架</th>
                  <th>官網上架</th>
                </tr>
              </thead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {modelListInfo?.datas?.length > 0 ? (
                    modelListInfo?.datas.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={existProductIds.find(id => id === item.id)}
                            onChange={e =>
                              handleCheck(item.id, e.target.checked)
                            }
                          />
                        </td>
                        <td style={{ wordBreak: 'keep-all' }}>{item.model}</td>
                        <td style={{ wordBreak: 'keep-all' }}>{item.name}</td>
                        <td>{item.brandCateName}</td>
                        <td>{item.brandStatus}</td>
                        <td>{item.isSell ? '是' : '否'}</td>
                      </tr>
                    ))
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          {modelListInfo?.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={modelListInfo?.current_page}
                defaultPageSize={modelListInfo?.page_size}
                total={modelListInfo?.total_count}
                onChange={setModalCurrentPage}
              />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className={style.modal_button_cancel}
            onClick={toggleModal}
          >
            取消
          </Button>{' '}
          <Button
            type="button"
            color="primary"
            className={style.modal_button_submit}
            onClick={callAssignToBrandCategory}
            disabled={addLoading}
          >
            {addLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span className="btn-wrapper--label">儲存</span>
          </Button>
        </ModalFooter>
      </Modal>
    </Layout>
  );
}
