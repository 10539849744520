export const GET_GIFTS_LIST_REQUEST = 'GET_GIFTS_LIST_REQUEST';
export const GET_GIFTS_LIST_SUCCESS = 'GET_GIFTS_LIST_SUCCESS';
export const GET_GIFTS_LIST_FAIL = 'GET_GIFTS_LIST_FAIL';

export const GET_GIFT_REQUEST = 'GET_GIFT_REQUEST';
export const GET_GIFT_SUCCESS = 'GET_GIFT_SUCCESS';
export const GET_GIFT_FAIL = 'GET_GIFT_FAIL';

export const CREATE_GIFT_IDLE = 'CREATE_GIFT_IDLE';
export const CREATE_GIFT_REQUEST = 'CREATE_GIFT_REQUEST';
export const CREATE_GIFT_SUCCESS = 'CREATE_GIFT_SUCCESS';
export const CREATE_GIFT_FAIL = 'CREATE_GIFT_FAIL';

export const UPDATE_GIFT_IDLE = 'UPDATE_GIFT_IDLE';
export const UPDATE_GIFT_REQUEST = 'UPDATE_GIFT_REQUEST';
export const UPDATE_GIFT_SUCCESS = 'UPDATE_GIFT_SUCCESS';
export const UPDATE_GIFT_FAIL = 'UPDATE_GIFT_FAIL';

export const GET_BINDING_PRODUCT_LIST_REQUEST =
  'GET_BINDING_PRODUCT_LIST_REQUEST';
export const GET_BINDING_PRODUCT_LIST_SUCCESS =
  'GET_BINDING_PRODUCT_LIST_SUCCESS';
export const GET_BINDING_PRODUCT_LIST_FAIL = 'GET_BINDING_PRODUCT_LIST_FAIL';

export const ADD_BINDING_PRODUCT_IDLE = 'ADD_BINDING_PRODUCT_IDLE';
export const ADD_BINDING_PRODUCT_REQUEST = 'ADD_BINDING_PRODUCT_REQUEST';
export const ADD_BINDING_PRODUCT_SUCCESS = 'ADD_BINDING_PRODUCT_SUCCESS';
export const ADD_BINDING_PRODUCT_FAIL = 'ADD_BINDING_PRODUCT_FAIL';

// for testing
export const ADD_NEW_GIFT = 'ADD_NEW_GIFT';
export const CREATE_NEW_GIFT = 'CREATE_NEW_GIFT';
export const CREATE_NEW_GIFT_IDLE = 'CREATE_NEW_GIFT_IDLE';
