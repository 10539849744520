import Layout from 'components/Layout';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Table,
} from 'reactstrap';
import { getBrandOptions } from 'redux/actions/data/brandActions';
import { getHideModelList } from 'redux/actions/data/hideModelActions';
import {
  getBindingGiftsList,
  getModelOptions,
} from 'redux/actions/data/productActions';
import { checkOverNow, handleCopyText } from 'utils';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import request from 'utils/useAxios';

import CreateHideModelModal from '../components/HideModel/CreateHideModelModal';
import GiftDetailModal from '../components/HideModel/GiftDetailModal';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';
import useQuery from '../utils/useQuery';

const HideModel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { brandOptions } = useSelector(state => state.brandOptions);
  const { modelOptions } = useSelector(state => state.productmodelOptions);
  const { loading, hideModelListInfo } = useSelector(
    state => state.hideModelList,
  );

  const {
    values,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleReset,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableTimeEnd: '',
      availableTimeEndHour: 0,
      availableTimeEndMin: 0,
      availableTimeStart: '',
      availableTimeStartHour: 0,
      availableTimeStartMin: 0,
      brandId: 0,
      keyword: '',
      model: '',
      page: 1,
      page_size: 10,
      status: 0,
    },
    onReset() {
      const params = new URLSearchParams({
        availableTimeEnd: '',
        availableTimeEndHour: 0,
        availableTimeEndMin: 0,
        availableTimeStart: '',
        availableTimeStartHour: 0,
        availableTimeStartMin: 0,
        brandId: 0,
        keyword: '',
        model: '',
        page: 1,
        page_size: 10,
        status: 0,
      });
      history.push(
        `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
      );

      dispatch(
        getHideModelList({
          page: 1,
          page_size: 10,
        }),
      );
    },
    onSubmit(values) {
      const params = new URLSearchParams({
        ...values,
        availableTimeEnd: values.availableTimeEnd || '',
        availableTimeStart: values.availableTimeStart || '',
        model: JSON.stringify(values.model),
      });
      history.push(
        `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
      );
      dispatch(
        getHideModelList({
          ...values,
          availableTimeEnd: values.availableTimeEnd || undefined,
          availableTimeStart: values.availableTimeStart || undefined,
          brandId: Number(values.brandId) || null,
          model: String(values.model),
        }),
      );
    },
  });

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  const handleDelete = id => {
    MessageUtil.submitConfirm({
      async doneFun() {
        const { code, message } = await request({
          method: 'get',
          url: `/Backend/HideModel/Delete/${id}`,
        });
        if (code !== 200) {
          MessageUtil.alertWanring('刪除失敗！', message);
          return;
        }
        MessageUtil.toastSuccess('刪除成功！');
        handleChangePage(1);
      },
      title: '刪除商品？',
    });
  };
  const handleChangeStatus = async (id, status) => {
    const { code, message } = await request({
      method: 'get',
      url: `/Backend/HideModel/UpdateStatus/${id}/${status}`,
    });
    if (code !== 200) {
      MessageUtil.alertWanring('更新失敗！', message);
      return;
    }
    MessageUtil.toastSuccess('更新成功！');
    handleSubmit();
  };

  const [addModalOpen, setAddModalOpen] = useState(false);
  const handleToggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const [isShowGiftModal, setIsShowGiftModal] = useState(false);

  const handleProductDetail = product => {
    setIsShowGiftModal(true);
    dispatch(
      getBindingGiftsList({
        freebieCodes: [''],
        isAdded: true,
        keyword: '',
        page: 1,
        page_size: product.freebieNum,
        productId: product.productId,
        searchType: '',
      }),
    );
  };

  const isSticky =
    hideModelListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  const isInitial = useRef(true);
  const query = useQuery();

  useEffect(() => {
    const body = {
      ...query.value,

      availableTimeEnd: query?.value?.availableTimeEnd
        ? moment(query?.value?.availableTimeEnd).toDate()
        : '',
      availableTimeStart: query?.value?.availableTimeStart
        ? moment(query?.value?.availableTimeStart).toDate()
        : '',
      model: query?.value?.model ? JSON.parse(query?.value?.model) : '',
    };
    setValues(body);

    if (isInitial.current) {
      handleSubmit();
      isInitial.current = false;
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle="隱形賣場"
      items={[
        { isActive: false, page_name: '商品管理' },
        { isActive: true, page_name: '隱形賣場' },
      ]}
    >
      <CreateHideModelModal open={addModalOpen} toggle={handleToggleAddModal} />
      <GiftDetailModal
        open={isShowGiftModal}
        toggle={() => {
          setIsShowGiftModal(false);
        }}
      />
      <Card className={style.search_card}>
        <CardHeader className="bg-white">搜尋條件</CardHeader>
        <CardBody className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2 text-nowrap">
                  關鍵字
                </label>
                <Input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  name="keyword"
                  placeholder="商品名稱、備註"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">品牌</label>
                <Select
                  className="w200"
                  value={
                    brandOptions?.filter(
                      c => String(c.value) === String(values.brandId ?? ''),
                    )[0] || { label: '全部', value: null }
                  }
                  options={[
                    { label: '全部', value: null },
                    ...(brandOptions || []),
                  ]}
                  onChange={e => {
                    setFieldValue('brandId', e.value);
                    setFieldValue('model', '');
                    dispatch(getModelOptions(e.label));
                  }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">型號</label>
                <Select
                  className="w200"
                  value={
                    modelOptions?.filter(
                      c => String(c.value) === String(values.model),
                    )[0] || { label: '全部', value: '' }
                  }
                  options={[
                    { label: '全部', value: '' },
                    ...(modelOptions || []),
                  ]}
                  onChange={option => {
                    setFieldValue('model', option.value);
                  }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2">狀態</label>
                <Select
                  className="w200"
                  value={
                    [
                      { label: '啟用', value: 1 },
                      { label: '失效', value: 2 },
                    ].filter(
                      c => String(c.value) === String(values.status ?? ''),
                    )[0] || { label: '全部', value: '' }
                  }
                  options={[
                    { label: '啟用', value: 1 },
                    { label: '失效', value: 2 },
                  ]}
                  onChange={e => {
                    setFieldValue('status', e.value);
                  }}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label me-2 text-nowrap">
                  可銷售區間
                </label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  showTimeSelect
                  popperClassName={style['form-control-date-picker']}
                  selected={values.availableTimeStart}
                  name="availableTimeStart"
                  onChange={date => {
                    setFieldValue('availableTimeStart', date);
                  }}
                  selectsStart
                  disabled={values.availableEndTimeType}
                />

                <span className="mx-1">~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  popperClassName={style['form-control-date-picker']}
                  showTimeSelect
                  selected={values.availableTimeEnd}
                  name="availableTimeEnd"
                  onChange={date => {
                    setFieldValue('availableTimeEnd', date);
                  }}
                  selectsEnd
                  minDate={values.availableTimeStart}
                  disabled={values.availableEndTimeType}
                />
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => handleChangePage(1)}
            >
              查詢
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card className={style.card} style={{ zIndex: 0 }}>
        <div className="mb-3">
          <Button color="success" onClick={() => setAddModalOpen(true)}>
            新增隱形賣場
          </Button>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
              className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
              <tr>
                <th className="text-center">功能</th>
                <th style={{ minWidth: 150 }}>隱賣編號</th>
                <th className="text-center">隱賣網址</th>
                <th style={{ minWidth: 200 }}>商品名稱</th>
                <th>可銷售區間</th>
                <th>狀態</th>
                <th className="text-start">
                  <div>商品料號</div>
                  <div>顏色,尺寸</div>
                  <div>贈品</div>
                </th>
                <th className="text-center">
                  <div>建議售價</div>
                  <div>售價</div>
                </th>
                <th>
                  <div>已售數量</div>
                  <div>可售數量</div>
                </th>
                <th>商品缺貨顯示</th>
                <th>銷售量</th>
                <th>銷售總數量</th>
                <th>銷售總額</th>
                <th>備註</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {hideModelListInfo?.datas?.length > 0 ? (
                  hideModelListInfo?.datas?.map(i =>
                    i.products?.map((j, jIndex) => (
                      <tr key={JSON.stringify(j)}>
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length}>
                            <div className="d-flex gap-2 justify-content-center">
                              <Button
                                className="m-2 btn btn-outline-primary btn-sm text-primary"
                                style={{
                                  backgroundColor: 'transparent',
                                }}
                                onClick={() =>
                                  history.push(`/HideModelDetail/${i.id}`)
                                }
                              >
                                設定
                              </Button>
                              <Button
                                className="m-2 btn btn-sm"
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: '#f4772e',
                                }}
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_WEBSITE_URL}/hideModel?preview=1&productType=2&previewToken=${hideModelListInfo.previewToken}`,
                                  );
                                }}
                              >
                                <span style={{ color: '#f4772e' }}>預覽</span>
                              </Button>
                              {!checkOverNow(i.availableTimeStart) && (
                                <Button
                                  className="m-2 btn btn-sm text-danger"
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: 'red',
                                  }}
                                  outline
                                  onClick={() => handleDelete(i.id)}
                                >
                                  刪除
                                </Button>
                              )}
                            </div>
                          </td>
                        )}
                        {/* 隱賣編號 */}
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length}>{i.modelNumber}</td>
                        )}
                        {/* 隱賣網址 */}
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length}>
                            <Button
                              color="link"
                              onClick={() =>
                                handleCopyText(i.webUrl, () => {
                                  MessageUtil.toastSuccess('連結已複製');
                                })
                              }
                            >
                              複製連結
                            </Button>
                          </td>
                        )}
                        {/* 商品名稱 */}
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length}>{i.modelName}</td>
                        )}
                        {jIndex === 0 && (
                          <td
                            className="text-nowrap"
                            rowSpan={i.products.length}
                          >
                            <div>
                              起：
                              {i.availableTimeStart
                                ? moment(i.availableTimeStart).format(
                                    'YYYY/MM/DD HH:mm',
                                  )
                                : '--'}
                            </div>
                            {i.availableEndTimeType === 1 ? (
                              <div>
                                迄：
                                {i.availableTimeEnd
                                  ? moment(i.availableTimeEnd).format(
                                      'YYYY/MM/DD HH:mm',
                                    )
                                  : '--'}
                              </div>
                            ) : (
                              <div> 2099/12/12 12:12:12 </div>
                            )}
                          </td>
                        )}
                        {jIndex === 0 && (
                          <td
                            className="text-nowrap"
                            rowSpan={i.products.length}
                          >
                            <Label className="d-flex gap-1 align-items-center">
                              <input
                                className={style.maintain_list_checkbox}
                                name={`status${i.id}`}
                                type="radio"
                                value="1"
                                checked={i.status === 1}
                                onChange={e => {
                                  handleChangeStatus(i.id, +e.target.value);
                                }}
                              />
                              啟用
                            </Label>
                            <Label className="d-flex gap-1 align-items-center">
                              <input
                                className={style.maintain_list_checkbox}
                                name={`status${i.id}`}
                                type="radio"
                                value="2"
                                checked={i.status === 2}
                                onChange={e => {
                                  handleChangeStatus(i.id, +e.target.value);
                                }}
                              />
                              失效
                            </Label>
                          </td>
                        )}
                        <td>
                          <div className="text-nowrap">{j.productcode}</div>
                          <div>
                            {j.colorName || '--'},{j.size || '--'}
                          </div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              history.push(
                                `/GiftsBinding?id=${j.hProductId}&mid=${i.mid}&productcode=${j.productcode}&productType=2`,
                              );
                            }}
                          >
                            加入贈品
                          </div>
                          <button
                            type="button"
                            className="text-primary btn btn-link text-start px-0"
                            onClick={() => {
                              handleProductDetail(j);
                            }}
                          >
                            贈品{j.freebieNum || '0'}個
                          </button>
                        </td>
                        <td>
                          {/* 建議售價*/}
                          <div className="price text-black fw-normal ">
                            {(j.websiteprice || '0').toLocaleString('en-US')}
                          </div>
                          {/*售價*/}
                          <div className="price text-black fw-normal">
                            {(j?.sellPrice || '0').toLocaleString('en-US')}
                          </div>
                        </td>
                        <td align="center">
                          <div>
                            {(j.sellQuantity || '0').toLocaleString('en-US')}
                          </div>
                          <div>{(j.stock || '0').toLocaleString('en-US')}</div>
                        </td>
                        <td align="center">{j.outofStockWords || '--'}</td>
                        <td align="center">
                          {(j.sellQuantity || '0').toLocaleString('en-US')}
                        </td>
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length} align="center">
                            {(i.sellTotalQuatity || '0').toLocaleString(
                              'en-US',
                            )}
                          </td>
                        )}
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length} align="center">
                            <div className="price fw-normal text-black text-center">
                              {(i.sellTotalAmount || '0').toLocaleString(
                                'en-US',
                              )}
                            </div>
                          </td>
                        )}
                        {jIndex === 0 && (
                          <td rowSpan={i.products.length}>
                            {i.remark || '--' + ''}
                          </td>
                        )}
                      </tr>
                    )),
                  )
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {hideModelListInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={hideModelListInfo?.current_page}
              defaultPageSize={hideModelListInfo?.page_size}
              total={hideModelListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
      </Card>
    </Layout>
  );
};
export default HideModel;
