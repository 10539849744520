import React, { memo, useCallback, useState } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import { UpdateMessageNote } from '../../redux/actions/data/messageActions';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';

const UpdateMessageNoteModal = ({ isOpen, toggle, datas }) => {
  const [note, setNote] = useState(datas.note || '');
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await UpdateMessageNote(datas.messageId, note);
      MessageUtil.alertSuccess('更新成功');
      toggle();
    } catch (e) {
      MessageUtil.alertWanring('更新失敗', e);
    }
    setLoading(false);
  }, [datas.messageId, note]);

  return (
    <Modal centered size="md" zIndex={2000} isOpen={isOpen}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        更新備註
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '150px' }}>
        <Input
          tag="textarea"
          rows={5}
          value={note}
          onChange={event => {
            setNote(event.target.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className={style.modal_button_submit}
          disabled={loading}
          onClick={onSubmit}
        >
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(UpdateMessageNoteModal);
