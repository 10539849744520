import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';
import request from '../../utils/useAxios';
import Loader from '../Loader';

const SubjectModel = ({
  detail = {
    content: '',
    content_en: '',
    id: 0,
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const [subject, setSubject] = useState(detail);
  useEffect(() => {
    setSubject(detail);
  }, [detail]);
  const handleSubmit = useCallback(
    async props => {
      setSubject(null);
      const data = {
        content: props.values.content,
        content_en: props.values.content_en,
        id: detail.id || 0,
      };
      const res = await request({
        data,
        method: 'post',
        url: detail.id
          ? '/Backend/Cafe_ContactUsSubjectCat/subjectCat/update'
          : '/Backend/Cafe_ContactUsSubjectCat/subjectCat/add',
      });
      if (res.code === 200) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      } else {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      setSubject(data);
    },
    [detail, setSubject],
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>商品編輯</ModalHeader>
        {!subject && <Loader />}
        {subject && (
          <Formik
            initialValues={{
              content: subject.content,
              content_en: subject.content_en,
            }}
            validationSchema={getValidationSchema()}
          >
            {props => (
              <Form className="form-list form-label-140">
                <ModalBody className={style.modal_body}>
                  <div className="form-item">
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*主旨名稱(中文)</label>
                      <div className="form-item">
                        <Field name="content" className="form-control" />
                        <ErrorMessage
                          name="content"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*主旨名稱(英文)</label>
                      <div className="form-item">
                        <Field name="content_en" className="form-control" />
                        <ErrorMessage
                          name="content_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  <Button
                    color="light"
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={() => {
                      setOpenModel(false, false);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    <span>儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default memo(SubjectModel);
