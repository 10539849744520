import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  UUCAFE_BANNER_SETTINGS_ADD_FAIL,
  UUCAFE_BANNER_SETTINGS_ADD_REQUEST,
  UUCAFE_BANNER_SETTINGS_ADD_SUCCESS,
  UUCAFE_BANNER_SETTINGS_FAIL,
  UUCAFE_BANNER_SETTINGS_LIST_FAIL,
  UUCAFE_BANNER_SETTINGS_LIST_REQUEST,
  UUCAFE_BANNER_SETTINGS_LIST_SUCCESS,
  UUCAFE_BANNER_SETTINGS_REQUEST,
  UUCAFE_BANNER_SETTINGS_SUCCESS,
  UUCAFE_BANNER_SETTINGS_UPDATE_FAIL,
  UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST,
  UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeBannerSettingsConstants';

/*
* @param
* data:{
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string"
  }
* @Response
 {
  "total_page": 0,
  "total_count": 0,
  "current_page": 0,
  "page_size": 0,
  "order": "string",
  "order_by": "string",
  "updateTime": "string",
  "previewToken": "string",
  "datas": [
    {
      "id": 0,
      "bannerImg": "string",
      "bannerUrl": "string",
      "bannerImg_mobile": "string",
      "bannerUrl_mobile": "string",
      "linkUrl": "string",
      "linkTarget": "string",
      "updater": "string",
      "updateDateTime": "string"
    }
  ]
}
*/
export const getCafeHomeBannerList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_BANNER_SETTINGS_LIST_FAIL,
      method: 'post',
      requestConstant: UUCAFE_BANNER_SETTINGS_LIST_REQUEST,
      successConstant: UUCAFE_BANNER_SETTINGS_LIST_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/topHome/list',
    });
  };
};

/*
* @param
* data:{
  "page": 0,
  "page_size": 0,
  "type": 0,
  "order": "string",
  "order_by": "string"
  }
*/
export const getCafeHomeBanner = data => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_BANNER_SETTINGS_FAIL,
      method: 'get',
      requestConstant: UUCAFE_BANNER_SETTINGS_REQUEST,
      successConstant: UUCAFE_BANNER_SETTINGS_SUCCESS,
      url: `/Backend/Cafe_HomeBanner/topHome/${data.id}`,
    });
  };
};

/*
  @params
  data:
  id: integer($int32)
  linkUrl: string
  banner_files: array
  banner_files_mobile: array
*/
export const addCafeHomeBanner = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_BANNER_SETTINGS_ADD_FAIL,
      method: 'post',
      requestConstant: UUCAFE_BANNER_SETTINGS_ADD_REQUEST,
      successConstant: UUCAFE_BANNER_SETTINGS_ADD_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/topHome/add',
    });
  };
};

export const updateCafeHomeBanner = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_BANNER_SETTINGS_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_BANNER_SETTINGS_UPDATE_REQUEST,
      successConstant: UUCAFE_BANNER_SETTINGS_UPDATE_SUCCESS,
      url: '/Backend/Cafe_HomeBanner/topHome/update',
    });
  };
};

export const deleteCafeHomeBanner = async (params) => {
  return await request({
    method: 'get',
    params,
    url: '/Backend/Cafe_HomeBanner/topHome/delete',
  });
};
