import { useRequest } from 'utils/useFetch';

import request from '../../../utils/useAxios';
import {
  GET_POINT_EVENT_SETTINGS_FAIL,
  GET_POINT_EVENT_SETTINGS_REQUEST,
  GET_POINT_EVENT_SETTINGS_SUCCESS,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS,
  GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL,
  GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST,
  GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS,
} from '../../constants/data/pointSettingConstants';

export const getPointEventSetting = (type = '') => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_POINT_EVENT_SETTINGS_FAIL,
      method: 'get',
      requestConstant: GET_POINT_EVENT_SETTINGS_REQUEST,
      successConstant: GET_POINT_EVENT_SETTINGS_SUCCESS,
      url: `/Backend/PointSetting/GetPointEventSetting/${type}`,
    });
  };
};

export const updatePointEventSetting = (
  data = {
    content: '',
    content_en: '',
    month: 0,
    quantity: 0,
    status: '',
    type: '',
    year: 0,
  },
) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/PointSetting/UpdatePointEventSetting',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res;
    }
  });
};

export const getSpecialPointEventSettings = (
  data = {
    endTime: '',
    keyword: '',
    page: 1,
    page_size: 10,
    startTime: '',
    statusList: [],
    type: 0,
  },
) => {
  return async dispatch => {
    await useRequest({
      data,
      dispatch,
      failConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_FAIL,
      method: 'post',
      requestConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_REQUEST,
      successConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_SUCCESS,
      url: '/Backend/PointSetting/GetSpecialPointEventQuery',
    });
  };
};

export const getSpecialPointEventSettingsDetail = (id = 0) => {
  return async dispatch => {
    await useRequest({
      dispatch,
      failConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_FAIL,
      method: 'get',
      params: { id },
      requestConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_REQUEST,
      successConstant: GET_SPECIAL_POINT_EVENT_SETTINGS_DETAIL_SUCCESS,
      url: `/Backend/PointSetting/GetSpecialPointEvent/${id}`,
    });
  };
};

export const createSpecialPointEventSetting = (
  data = {
    availableWay: '',
    description: '',
    endTime: '',
    eventObject: '',
    name: '',
    orderAmount: 0,
    quantity: 0,
    startTime: '',
    type: 0,
  },
) => {
  if ('id' in data) {
    delete data.id;
  }

  return request({
    data,
    method: 'post',
    url: '/Backend/PointSetting/CreateSpecialPointEvent',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res;
    }
  });
};

export const updateSpecialPointEventSetting = (
  data = {
    availableWay: '',
    description: '',
    endTime: '',
    eventObject: '',
    name: '',
    orderAmount: 0,
    quantity: 0,
    startTime: '',
    type: 0,
  },
) => {
  return request({
    data,
    method: 'post',
    url: '/Backend/PointSetting/UpdateSpecialPointEvent',
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res;
    }
  });
};

export const deleteSpecialPointEventSetting = (
  id = {
    availableWay: '',
    description: '',
    endTime: '',
    eventObject: '',
    name: '',
    orderAmount: 0,
    quantity: 0,
    startTime: '',
    type: 0,
  },
) => {
  return request({
    method: 'get',
    params: { id },
    url: `/Backend/PointSetting/DeleteSpecialPointEvent/${id}`,
  }).then(res => {
    if (~~res.code === 200) {
      return res;
    } else {
      throw res;
    }
  });
};
