import {
  UUCAFE_SERVICES_DETAIL_ADD_FAIL,
  UUCAFE_SERVICES_DETAIL_ADD_REQUEST,
  UUCAFE_SERVICES_DETAIL_ADD_SUCCESS,
  UUCAFE_SERVICES_DETAIL_FAIL,
  UUCAFE_SERVICES_DETAIL_REQUEST,
  UUCAFE_SERVICES_DETAIL_SUCCESS,
  UUCAFE_SERVICES_DETAIL_UPDATE_FAIL,
  UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST,
  UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SERVICES_LIST_FAIL,
  UUCAFE_SERVICES_LIST_REQUEST,
  UUCAFE_SERVICES_LIST_SUCCESS,
} from '../../constants/data/uuCafeServicesConstants';

export const cafeServiceListReducer = (
  state = {
    error: null,
    loading: false,
    serviceList: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SERVICES_LIST_REQUEST:
      return {
        error: null,
        loading: true,
        serviceList: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        success: false,
      };
    case UUCAFE_SERVICES_LIST_SUCCESS:
      return { ...state, loading: false, serviceList: payload, success: true };
    case UUCAFE_SERVICES_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeServiceDetailReducer = (
  state = {
    detail: {
      name: '',
      name_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SERVICES_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_SERVICES_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_SERVICES_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_SERVICES_DETAIL_ADD_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_SERVICES_DETAIL_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_SERVICES_DETAIL_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_SERVICES_DETAIL_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_SERVICES_DETAIL_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_SERVICES_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
