import {
  ALSO_LIKE_BRAND_FAIL,
  ALSO_LIKE_BRAND_PRODUCTS_FAIL,
  ALSO_LIKE_BRAND_PRODUCTS_REQUEST,
  ALSO_LIKE_BRAND_PRODUCTS_SUCCESS,
  ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL,
  ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST,
  ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS,
  ALSO_LIKE_BRAND_REQUEST,
  ALSO_LIKE_BRAND_SUCCESS,
  ALSO_LIKE_PRODUCT_ADD_FAIL,
  ALSO_LIKE_PRODUCT_ADD_REQUEST,
  ALSO_LIKE_PRODUCT_ADD_RESET,
  ALSO_LIKE_PRODUCT_ADD_SUCCESS,
  ALSO_LIKE_PRODUCT_DELETE_FAIL,
  ALSO_LIKE_PRODUCT_DELETE_REQUEST,
  ALSO_LIKE_PRODUCT_DELETE_RESET,
  ALSO_LIKE_PRODUCT_DELETE_SUCCESS,
  ALSO_LIKE_TOP_PRODUCT_REQUEST,
  ALSO_LIKE_TOP_PRODUCT_SUCCESS,
  ALSO_LIKE_TOP_PRODUCT_FAIL,
  BEST_SELLER_BRAND_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL,
  BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST,
  BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS,
  BEST_SELLER_BRAND_PRODUCTS_REQUEST,
  BEST_SELLER_BRAND_PRODUCTS_SUCCESS,
  BEST_SELLER_BRAND_REQUEST,
  BEST_SELLER_BRAND_SUCCESS,
  BEST_SELLER_PRODUCT_ADD_FAIL,
  BEST_SELLER_PRODUCT_ADD_REQUEST,
  BEST_SELLER_PRODUCT_ADD_RESET,
  BEST_SELLER_PRODUCT_ADD_SUCCESS,
  BEST_SELLER_PRODUCT_DELETE_FAIL,
  BEST_SELLER_PRODUCT_DELETE_REQUEST,
  BEST_SELLER_PRODUCT_DELETE_RESET,
  BEST_SELLER_PRODUCT_DELETE_SUCCESS,
  BEST_SELLER_TOP_PRODUCTS_FAIL,
  BEST_SELLER_TOP_PRODUCTS_REQUEST,
  BEST_SELLER_TOP_PRODUCTS_SUCCESS,
  DISCOUNT_CODE_DELETE_FAIL,
  DISCOUNT_CODE_DELETE_REQUEST,
  DISCOUNT_CODE_DELETE_RESET,
  DISCOUNT_CODE_DELETE_SUCCESS,
  DISCOUNT_CODE_LIST_FAIL,
  DISCOUNT_CODE_LIST_REQUEST,
  DISCOUNT_CODE_LIST_SUCCESS,
  DISCOUNT_CODE_POST_FAIL,
  DISCOUNT_CODE_POST_REQUEST,
  DISCOUNT_CODE_POST_RESET,
  DISCOUNT_CODE_POST_SUCCESS,
  DISCOUNT_IMAGE_DELETE_FAIL,
  DISCOUNT_IMAGE_DELETE_REQUEST,
  DISCOUNT_IMAGE_DELETE_RESET,
  DISCOUNT_IMAGE_DELETE_SUCCESS,
  DISCOUNT_IMAGE_LIST_FAIL,
  DISCOUNT_IMAGE_LIST_REQUEST,
  DISCOUNT_IMAGE_LIST_SUCCESS,
  DISCOUNT_IMAGE_POST_FAIL,
  DISCOUNT_IMAGE_POST_REQUEST,
  DISCOUNT_IMAGE_POST_RESET,
  DISCOUNT_IMAGE_POST_SUCCESS,
} from '../../constants/data/salesConstants';

//get also like brand list
export const alsoLikeBrandListReducer = (
  state = { brandListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_BRAND_REQUEST:
      return { loading: true };
    case ALSO_LIKE_BRAND_SUCCESS:
      return { brandListInfo: payload, loading: false };
    case ALSO_LIKE_BRAND_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get also like brand product list
export const alsoLikeBrandProductListReducer = (
  state = { loading: true, productListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_BRAND_PRODUCTS_REQUEST:
      return { loading: true };
    case ALSO_LIKE_BRAND_PRODUCTS_SUCCESS:
      return { loading: false, productListInfo: payload };
    case ALSO_LIKE_BRAND_PRODUCTS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//delete also like brand product
export const alsoLikeProductDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case ALSO_LIKE_PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ALSO_LIKE_PRODUCT_DELETE_FAIL:
      return { error: payload, loading: false };
    case ALSO_LIKE_PRODUCT_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get also like brand product
export const alsoLikeProductListReducer = (
  state = { alsoLikeProductListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_BRAND_PRODUCT_LIST_REQUEST:
      return { loading: true };
    case ALSO_LIKE_BRAND_PRODUCT_LIST_SUCCESS:
      return { alsoLikeProductListInfo: payload, loading: false };
    case ALSO_LIKE_BRAND_PRODUCT_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add product to also like
export const alsoLikeAddProductReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_PRODUCT_ADD_REQUEST:
      return { loading: true };
    case ALSO_LIKE_PRODUCT_ADD_SUCCESS:
      return { loading: false, success: true };
    case ALSO_LIKE_PRODUCT_ADD_FAIL:
      return { error: payload, loading: false };
    case ALSO_LIKE_PRODUCT_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//set also like product top
export const alsoLikeSetTopReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case ALSO_LIKE_TOP_PRODUCT_REQUEST:
      return { loading: true };
    case ALSO_LIKE_TOP_PRODUCT_SUCCESS:
      return { loading: false, success: true };
    case ALSO_LIKE_TOP_PRODUCT_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get best seller brand list
export const bestSellerBrandListReducer = (
  state = { brandListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_BRAND_REQUEST:
      return { loading: true };
    case BEST_SELLER_BRAND_SUCCESS:
      return { brandListInfo: payload, loading: false };
    case BEST_SELLER_BRAND_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get best seller brand product list
export const bestSellerBrandProductListReducer = (
  state = { loading: true, productListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_BRAND_PRODUCTS_REQUEST:
      return { loading: true };
    case BEST_SELLER_BRAND_PRODUCTS_SUCCESS:
      return { loading: false, productListInfo: payload };
    case BEST_SELLER_BRAND_PRODUCTS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get best seller product list
export const bestSellerProductListReducer = (
  state = { bestSellerProductListInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_BRAND_PRODUCTS_LIST_REQUEST:
      return { loading: true };
    case BEST_SELLER_BRAND_PRODUCTS_LIST_SUCCESS:
      return { bestSellerProductListInfo: payload, loading: false };
    case BEST_SELLER_BRAND_PRODUCTS_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//add product to best seller
export const bestSellerAddProductReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_PRODUCT_ADD_REQUEST:
      return { loading: true };
    case BEST_SELLER_PRODUCT_ADD_SUCCESS:
      return { loading: false, success: true };
    case BEST_SELLER_PRODUCT_ADD_FAIL:
      return { error: payload, loading: false };
    case BEST_SELLER_PRODUCT_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete best seller product
export const bestSellerProductDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case BEST_SELLER_PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BEST_SELLER_PRODUCT_DELETE_FAIL:
      return { error: payload, loading: false };
    case BEST_SELLER_PRODUCT_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//set top product
export const bestSellerSetTopReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case BEST_SELLER_TOP_PRODUCTS_REQUEST:
      return { loading: true };
    case BEST_SELLER_TOP_PRODUCTS_SUCCESS:
      return { loading: false, success: true };
    case BEST_SELLER_TOP_PRODUCTS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get discount list
export const discountCodeListReducer = (
  state = { codeInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_CODE_LIST_REQUEST:
      return { loading: true };
    case DISCOUNT_CODE_LIST_SUCCESS:
      return { codeInfo: payload, loading: false };
    case DISCOUNT_CODE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post discount code
export const discountCodePostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_CODE_POST_REQUEST:
      return { loading: true };
    case DISCOUNT_CODE_POST_SUCCESS:
      return { loading: false, success: true };
    case DISCOUNT_CODE_POST_FAIL:
      return { error: payload, loading: false };
    case DISCOUNT_CODE_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete discount code
export const discountCodeDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_CODE_DELETE_REQUEST:
      return { loading: true };
    case DISCOUNT_CODE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DISCOUNT_CODE_DELETE_FAIL:
      return { error: payload, loading: false };
    case DISCOUNT_CODE_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get discount image list
export const discountImageListReducer = (
  state = { imageInfo: {}, loading: true },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_IMAGE_LIST_REQUEST:
      return { loading: true };
    case DISCOUNT_IMAGE_LIST_SUCCESS:
      return { imageInfo: payload, loading: false };
    case DISCOUNT_IMAGE_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post discount image
export const discountImagePostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_IMAGE_POST_REQUEST:
      return { loading: true };
    case DISCOUNT_IMAGE_POST_SUCCESS:
      return { loading: false, success: true };
    case DISCOUNT_IMAGE_POST_FAIL:
      return { error: payload, loading: false };
    case DISCOUNT_IMAGE_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//delete discount image
export const discountImageDeleteReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DISCOUNT_IMAGE_DELETE_REQUEST:
      return { loading: true };
    case DISCOUNT_IMAGE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DISCOUNT_IMAGE_DELETE_FAIL:
      return { error: payload, loading: false };
    case DISCOUNT_IMAGE_DELETE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};
