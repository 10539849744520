import {
  UUMODELREL_LIST_REQUEST,
  UUMODELREL_LIST_SUCCESS,
  UUMODELREL_LIST_FAIL,
  UUMODELREL_SELECT_MODELS_REQUEST,
  UUMODELREL_SELECT_MODELS_SUCCESS,
  UUMODELREL_SELECT_MODELS_FAIL,
  UUMODELREL_EDIT_UUMODEL_REQUEST,
  UUMODELREL_EDIT_UUMODEL_SUCCESS,
  UUMODELREL_EDIT_UUMODEL_FAIL,
  UUMODELREL_SAVE_MODELS_REQUEST,
  UUMODELREL_SAVE_MODELS_SUCCESS,
  UUMODELREL_SAVE_MODELS_FAIL,
  UUMODELREL_DELETE_UUMODEL_REQUEST,
  UUMODELREL_DELETE_UUMODEL_SUCCESS,
  UUMODELREL_DELETE_UUMODEL_FAIL,
} from 'redux/constants/data/uuModelRelConstants';
import { useRequest } from 'utils/useFetch';
export const getUUModelRelList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUMODELREL_LIST_FAIL,
      method: 'post',
      requestConstant: UUMODELREL_LIST_REQUEST,
      successConstant: UUMODELREL_LIST_SUCCESS,
      url: '/Backend/UUModelRel/List',
    });
  };
};
export const getUUModelRelSelectModels = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUMODELREL_SELECT_MODELS_FAIL,
      method: 'post',
      requestConstant: UUMODELREL_SELECT_MODELS_REQUEST,
      successConstant: UUMODELREL_SELECT_MODELS_SUCCESS,
      url: '/Backend/UUModelRel/SelectModels',
    });
  };
};
export const editUUModel = ({ modelId, uubrandCatId, isShowonTheBrand }) => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUMODELREL_EDIT_UUMODEL_FAIL,
      method: 'get',
      requestConstant: UUMODELREL_EDIT_UUMODEL_REQUEST,
      successConstant: UUMODELREL_EDIT_UUMODEL_SUCCESS,
      url: `/Backend/UUModelRel/EditUUModel/${modelId}/${uubrandCatId}/${isShowonTheBrand}`,
    });
  };
};

export const saveModels = (uubrandCatId, data) => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUMODELREL_SAVE_MODELS_FAIL,
      method: 'post',
      requestConstant: UUMODELREL_SAVE_MODELS_REQUEST,
      successConstant: UUMODELREL_SAVE_MODELS_SUCCESS,
      url: `/Backend/UUModelRel/SaveModels/${uubrandCatId}`,
    });
  };
};
export const deleteUUModel = (modelId, uubrandCatId) => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUMODELREL_DELETE_UUMODEL_FAIL,
      method: 'get',
      requestConstant: UUMODELREL_DELETE_UUMODEL_REQUEST,
      successConstant: UUMODELREL_DELETE_UUMODEL_SUCCESS,
      url: `/Backend/UUModelRel/DeleteUUModel/${modelId}/${uubrandCatId}`,
    });
  };
};
