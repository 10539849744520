import { useFormik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  createProductCategory,
  updateCategory,
} from 'redux/actions/data/categoryActions';
import { getProductCategoryOptions } from 'redux/actions/data/productActions';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import * as Yup from 'yup';

import style from '../../styles/layout.module.css';

const CategorySettingsModal = ({ isOpen, toggle, isEdit, data }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.createProductCategory);
  const { loading: updateLoading } = useSelector(state => state.categoryUpdate);
  const { options } = useSelector(state => state.getProductCategoryOptions);

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        cat2Name: '',
        cat2Name_en: '',
        cate: { label: '請選擇', value: -1 },
        isEdit: isEdit,
        menuType: '',
        name: isEdit ? data.name : '',
        name_en: isEdit ? data.name_en : '',
        parentInt: isEdit ? data.id : 0,
        status: isEdit ? data.status : '',
        type: '',
      },
      onSubmit: () => {
        if (!isEdit) {
          const body = {
            cat1Name: values.name,
            cat1Name_en: values.name_en,
            cat2Name: values.cat2Name,
            cat2Name_en: values.cat2Name_en,
            menuType: values.menuType,
            parentInt: isEdit
              ? values.parentInt
              : values.cate.value === -1
              ? 0
              : values.cate.value,
            status: values.status,
          };
          CommonUtil.consoleLog({ anno: 'request body', data: body });
          dispatch(createProductCategory(body));
        } else {
          const body = {
            cateId: data.id,
            name: values.name,
            name_en: values.name_en,
            status: values.status,
          };
          CommonUtil.consoleLog({ anno: 'request body', data: body });
          dispatch(updateCategory(body));
        }
      },
      validationSchema: Yup.object().shape({
        cat2Name: Yup.string().when('menuType', {
          is: val => val === 'Cat1AndCat2',
          then: Yup.string().required('必填'),
        }),
        cat2Name_en: Yup.string().when('menuType', {
          is: val => val === 'Cat1AndCat2',
          then: Yup.string().required('必填'),
        }),
        cate: Yup.object().when('type', {
          is: val => val === 'select',
          then: Yup.object().shape({
            value: Yup.number().test('test', '必填', val => val !== -1),
          }),
        }),
        isEdit: Yup.boolean(),
        menuType: Yup.string().when('isEdit', {
          is: val => !val,
          then: Yup.string().required('必填'),
        }),
        name: Yup.string().when(['type', 'isEdit'], {
          is: (type, isEdit) => type === 'create' || isEdit,
          then: Yup.string().required('必填'),
        }),
        name_en: Yup.string().when(['type', 'isEdit'], {
          is: (type, isEdit) => type === 'create' || isEdit,
          then: Yup.string().required('必填'),
        }),
        status: Yup.string().required('必填'),
        type: Yup.string().when('isEdit', {
          is: val => val === false,
          then: Yup.string().required('必填'),
        }),
      }),
    });

  useEffect(() => {
    dispatch(getProductCategoryOptions());
  }, []);

  useEffect(() => {
    if (values.cate?.hasPrd) {
      MessageUtil.alertWanring('請將第一層產品刪除後，才可以新增第二層分類');
    }
  }, [values.cate]);

  return (
    <Modal centered size="lg" zIndex={2000} isOpen={isOpen}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        {`${isEdit ? '編輯' : '新增'}選單`}
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ minHeight: '460px' }}>
        {!isEdit && (
          <React.Fragment>
            <div className={style.formGroup}>
              <label className="w150 required">
                選單類型
                {errors.menuType && touched.menuType && (
                  <span className="ml-2 text-danger">{errors.menuType}</span>
                )}
              </label>
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="menuType"
                  onChange={e => {
                    setFieldValue('menuType', e.target.value);
                    setFieldValue('cat2Name', '');
                    setFieldValue('cat2Name_en', '');
                    setFieldValue('type', 'create');
                    setFieldValue('cate', { label: '請選擇', value: -1 });
                  }}
                  value="OnlyCat1"
                  checked={values.menuType === 'OnlyCat1'}
                />
                <span className="ml-2">一層選單</span>
              </div>
              <div className={style['rwd-w100']} />
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="menuType"
                  onChange={e => {
                    setFieldValue('menuType', e.target.value);
                  }}
                  value="Cat1AndCat2"
                  checked={values.menuType === 'Cat1AndCat2'}
                />
                <span className="ml-2">兩層選單</span>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="w150 required">
                第一層選單
                {errors.type && touched.type && (
                  <span className="ml-2 text-danger">{errors.type}</span>
                )}
              </label>
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="type"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                    setFieldValue('parent_name', '');
                    setFieldValue('parent_name_en', '');
                  }}
                  value="select"
                  checked={values.type === 'select'}
                  disabled={values.menuType === 'OnlyCat1'}
                />
                <span className="ml-2">選擇分類</span>
              </div>
              <div className="w100" />
              <div className={style['radio-btn-layout']}>
                <input
                  type="radio"
                  className={style['radio-btn']}
                  id="type"
                  onChange={e => {
                    setFieldValue('type', e.target.value);
                    setFieldValue('cate', { label: '請選擇', value: -1 });
                  }}
                  value="create"
                  checked={values.type === 'create'}
                />
                <span className="ml-2">新增</span>
              </div>
            </div>
            <div className={style.formGroup}>
              <label className="w150" />
              {values.type === 'select' && (
                <React.Fragment>
                  <Select
                    className={style['form-select-shop-banner']}
                    options={options}
                    value={values.cate}
                    onChange={option => {
                      setFieldValue('cate', option);
                    }}
                  />
                  {errors.cate?.value && touched.cate?.value && (
                    <span className="ml-2 text-danger">
                      {errors.cate.value}
                    </span>
                  )}
                </React.Fragment>
              )}
              {values.type === 'create' && (
                <div>
                  <div className={style.formGroup}>
                    <label>中文</label>
                    <Input
                      className={style['form-select-shop-banner']}
                      id="name"
                      onChange={e => {
                        setFieldValue('name', e.target.value);

                        // setFieldValue('cate', { label: '請選擇', value: -1 });
                      }}
                      value={values.name}
                      invalid={errors.name && touched.name}
                    />
                  </div>
                  <div className={style.formGroup}>
                    <label>英文</label>
                    <Input
                      className={style['form-select-shop-banner']}
                      id="name_en"
                      onChange={e => {
                        setFieldValue('name_en', e.target.value);

                        // setFieldValue('cate', { label: '請選擇', value: -1 });
                      }}
                      value={values.name_en}
                      invalid={errors.name_en && touched.name_en}
                    />
                  </div>
                </div>
              )}
            </div>
            <div style={{ margin: '3rem' }} />
            {values.menuType === 'Cat1AndCat2' && (
              <React.Fragment>
                <div className={style.formGroup}>
                  <label className="required w150">第二層選單(中文)</label>
                  <Input
                    className={style['form-select-shop-banner']}
                    id="cat2Name"
                    onChange={handleChange}
                    value={values.cat2Name}
                    invalid={errors.cat2Name && touched.cat2Name}
                  />
                </div>
                <div className={style.formGroup}>
                  <label className="required w150">第二層選單(英文)</label>
                  <Input
                    className={style['form-select-shop-banner']}
                    id="cat2Name_en"
                    onChange={handleChange}
                    value={values.cat2Name_en}
                    invalid={errors.cat2Name_en && touched.cat2Name_en}
                  />
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {isEdit && (
          <React.Fragment>
            <div className={style.formGroup}>
              <label className="required w150">選單名稱(中文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name"
                onChange={handleChange}
                value={values.name}
                invalid={errors.name && touched.name}
              />
            </div>
            <div className={style.formGroup}>
              <label className="required w150">選單名稱(英文)</label>
              <Input
                className={style['form-select-shop-banner']}
                id="name_en"
                onChange={handleChange}
                value={values.name_en}
                invalid={errors.name_en && touched.name_en}
              />
            </div>
          </React.Fragment>
        )}
        {
          <div className={style.formGroup}>
            <label className="required w150">
              狀態
              {errors.status && touched.status && (
                <span className="ml-2 text-danger">{errors.status}</span>
              )}
            </label>
            <div className={style['radio-btn-layout']}>
              <input
                type="radio"
                className={style['radio-btn']}
                id="status"
                onChange={handleChange}
                value="上架"
                checked={values.status === '上架'}
              />
              <span className="ml-2">上架</span>
            </div>
            <div className={style['rwd-w100']} />
            <div className={style['radio-btn-layout']}>
              <input
                type="radio"
                className={style['radio-btn']}
                id="status"
                onChange={handleChange}
                value="下架"
                checked={values.status === '下架'}
              />
              <span className="ml-2">下架</span>
            </div>
          </div>
        }
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading ? true : updateLoading ? true : values.cate.hasPrd}
        >
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CategorySettingsModal;
