export const UUCAFE_OTHER_PRODUCT_LIST_REQUEST =
  'UUCAFE_OTHER_PRODUCT_LIST_REQUEST';

export const UUCAFE_OTHER_PRODUCT_LIST_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_LIST_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_LIST_FAIL = 'UUCAFE_OTHER_PRODUCT_LIST_FAIL';
export const UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST =
  'UUCAFE_OTHER_PRODUCT_UPDATE_REQUEST';
export const UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_UPDATE_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_UPDATE_FAIL =
  'UUCAFE_OTHER_PRODUCT_UPDATE_FAIL';
export const UUCAFE_OTHER_PRODUCT_SUCCESS = 'UUCAFE_OTHER_PRODUCT_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_REQUEST = 'UUCAFE_OTHER_PRODUCT_REQUEST';
export const UUCAFE_OTHER_PRODUCT_FAIL = 'UUCAFE_OTHER_PRODUCT_FAIL';
export const UUCAFE_OTHER_PRODUCT_ADD_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_ADD_SUCCESS';

export const UUCAFE_OTHER_PRODUCT_ADD_REQUEST =
  'UUCAFE_OTHER_PRODUCT_ADD_REQUEST';
export const UUCAFE_OTHER_PRODUCT_ADD_FAIL = 'UUCAFE_OTHER_PRODUCT_ADD_FAIL';

//===== SEO

export const UUCAFE_OTHER_PRODUCT_SEO_REQUEST =
  'UUCAFE_OTHER_PRODUCT_SEO_REQUEST';
export const UUCAFE_OTHER_PRODUCT_SEO_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_SEO_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_SEO_FAIL = 'UUCAFE_OTHER_PRODUCT_SEO_FAIL';

export const UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST =
  'UUCAFE_OTHER_PRODUCT_SEO_UPDATE_REQUEST';
export const UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_SEO_UPDATE_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL =
  'UUCAFE_OTHER_PRODUCT_SEO_UPDATE_FAIL';

//===== PAGE
export const UUCAFE_OTHER_PRODUCT_PAGE_REQUEST =
  'UUCAFE_OTHER_PRODUCT_PAGE_REQUEST';
export const UUCAFE_OTHER_PRODUCT_PAGE_FAIL = 'UUCAFE_OTHER_PRODUCT_PAGE_FAIL';
export const UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL =
  'UUCAFE_OTHER_PRODUCT_PAGE_SAVE_FAIL';

export const UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST =
  'UUCAFE_OTHER_PRODUCT_PAGE_SAVE_REQUEST';
export const UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_PAGE_SAVE_SUCCESS';
export const UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS =
  'UUCAFE_OTHER_PRODUCT_PAGE_SUCCESS';
