import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import {
  addCafeMealIntroMenu,
  getCafeMealIntroMenu,
  updateCafeMealIntroMenu,
} from '../../redux/actions/data/uuCafeMealIntroAction';
import { UUCAFE_MEALINTRO_MENU_SUCCESS } from '../../redux/constants/data/uuCafeMealIntroConstants';
import style from '../../styles/layout.module.css';
import { getImageDataFromSetting } from '../../utils/getImageDataFromSetting';
import UploadFileBox from '../UploadFileBox';

const MenuModel = ({
  detail = {
    imgFile: '',
    imgUrl: '',
    name: '',
    prdCount: 0,
    sort: 0,
    status: 0,
    templateType: 1,
  },
  openModal = false,
  setOpenModel = (show, isReload) => {},
}) => {
  const {
    loading,
    success,
    error,
    detail: initData,
  } = useSelector(state => state.cafeMealInfoMenu);
  const dispatch = useDispatch();
  const isSubmit = useRef(false);

  const handleSubmit = useCallback(
    props => {
      isSubmit.current = true;
      const formData = new FormData();
      formData.append('name', props.values.name);
      formData.append('name_en', props.values.name_en);
      formData.append('templateType', props.values.templateType);
      formData.append('status', 1);

      if (!props.values.img_files && initData.imgFile && initData.imgUrl) {
        formData.append('img_files', []);
      } else {
        if (props.values.templateType === 2) {
          formData.append('img_files', props.values.img_files);
        }
      }

      if (detail.id) {
        formData.append('id', detail.id);
        dispatch(updateCafeMealIntroMenu(formData));
      } else {
        formData.append('id', 0);
        dispatch(addCafeMealIntroMenu(formData));
      }
    },
    [detail],
  );

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        setOpenModel(false, true);
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail]);

  useEffect(() => {
    if (detail.id) {
      dispatch(getCafeMealIntroMenu(detail));
    } else {
      dispatch({
        payload: detail,
        type: UUCAFE_MEALINTRO_MENU_SUCCESS,
      });
    }
  }, [detail.id]);
  return (
    <div className={style.modal}>
      <Modal
        centered
        size="lg"
        isOpen={openModal}
        toggle={() => {
          setOpenModel(false, false);
        }}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head}>編輯咖啡專區選單</ModalHeader>

        {(!loading || isSubmit.current) && (
          <Formik
            initialValues={{
              img_files: null,
              init_img_files: getImageDataFromSetting(
                initData.imgFile,
                initData.imgUrl,
              ),
              name: initData.name,
              name_en: initData.name_en,
              status: initData.status,
              templateType: initData.templateType,
            }}
          >
            {props => (
              <Form className="form-list form-label-140">
                <ModalBody className={style.modal_body}>
                  <div className="form-item">
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*選單名稱(中文)</label>
                      <div className="form-item">
                        <Field name="name" className="form-control" />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label>*選單名稱(英文)</label>
                      <div className="form-item">
                        <Field name="name_en" className="form-control" />
                        <ErrorMessage
                          name="name_en"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-3 mb-3">
                      <label className="w-100">*選擇樣板</label>
                      <div className="w-100 row">
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="radio-temp1"
                        >
                          <img
                            src="/images/temp1.png"
                            width="236"
                            height="159"
                            className="border-1 mx-auto mb-1"
                          />
                          <span className="d-inline-flex w-100 justify-content-center">
                            <Field
                              type="radio"
                              name="templateType"
                              value={1}
                              id="radio-temp1"
                              onChange={() => {
                                props.setFieldValue('templateType', 1);
                              }}
                            />
                            版型1
                          </span>
                        </label>
                        <label
                          className="col-sm-12 col-md-6 text-center"
                          htmlFor="radio-temp2"
                        >
                          <img
                            src="/images/temp2.png"
                            width="236"
                            height="159"
                            className="border-1 mx-auto mb-1"
                          />
                          <span className="d-inline-flex w-100 justify-content-center">
                            <Field
                              type="radio"
                              name="templateType"
                              value={2}
                              id="radio-temp2"
                              onChange={() => {
                                props.setFieldValue('templateType', 2);
                              }}
                            />
                            版型2
                          </span>
                        </label>
                      </div>
                    </FormGroup>
                    {props.values.templateType === 2 && (
                      <FormGroup className={style.formGroup}>
                        <label style={{ width: '120px' }}>圖片</label>
                        <div className="form-item">
                          <UploadFileBox
                            uplodModel="images"
                            formFieldName="img_files"
                            currentFileFieldName="img_files"
                            setFormFieldValue={(field, files) => {
                              if (files && files.length) {
                                if (files[0] instanceof File) {
                                  props.setFieldValue('img_files', files[0]);
                                }
                              }
                            }}
                            displayFileWidth="350"
                            displayFileHeight="350"
                            displayFileSize="1MB"
                            initFiles={props.values.init_img_files}
                          />
                        </div>
                      </FormGroup>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                  <Button
                    color="light"
                    type="button"
                    className={style.modal_button_cancel}
                    onClick={() => {
                      setOpenModel(false, false);
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    className={style.modal_button_submit}
                    disabled={loading}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span>儲存</span>
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </div>
  );
};

export default memo(MenuModel);
