import {
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_SAVE_REQUEST,
  PAYMENT_LIST_SAVE_SUCCESS,
  PAYMENT_LIST_SAVE_FAIL,
} from 'redux/constants/data/paymentConstants';
import { useRequest } from 'utils/useFetch';

export const getPaymentList = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: PAYMENT_LIST_FAIL,
      method: 'get',
      requestConstant: PAYMENT_LIST_REQUEST,
      successConstant: PAYMENT_LIST_SUCCESS,
      url: '/Backend/PaymentSetting/GetPaymentSettingList',
    });
  };
};

export const savePaymentList = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: PAYMENT_LIST_SAVE_FAIL,
      method: 'post',
      requestConstant: PAYMENT_LIST_SAVE_REQUEST,
      successConstant: PAYMENT_LIST_SAVE_SUCCESS,
      url: '/Backend/PaymentSetting/SavePaymentSettingList',
    });
  };
};
