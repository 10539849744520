import EditAvailableTimeModal from 'components/productDetail/EditAvailableTimeModal';
import EditShippingDateModal from 'components/productDetail/EditShippingDateModal';
import EditStockNumberModal from 'components/productDetail/EditStockNumberModal';
import LaunchTimeModal from 'components/productDetail/LaunchTimeModal';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Input,
  Table,
} from 'reactstrap';
import {
  exportModel,
  getModelList,
  importModel,
  updateModelSellingState,
} from 'redux/actions/data/modelActions';
import {
  MODEL_EXPORT_IDLE,
  MODEL_IMPORT_IDLE,
  MODEL_SELLING_STATE_UPDATE_IDLE,
} from 'redux/constants/data/modelConstants';
import {
  IMPORT_PRODUCT_STOCK_FILE_IDLE,
  PRODUCT_OPTIONS_STATE_UPDATE_IDLE,
} from 'redux/constants/data/productConstants';
import { emptyOption } from 'utils';
import CommonUtil from 'utils/CommonUtil';
import { useToggle } from 'utils/customHooks';
import useQuery from 'utils/useQuery';

import Layout from '../components/Layout';
import { TableHasNoData, TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import {
  getModelOptions,
  getProductBrandCategory,
  importProductStockFile,
  updateProductOptionsState,
} from '../redux/actions/data/productActions';
import { getUpdateTime } from '../redux/actions/data/userActions';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const ProductControl = () => {
  const importStockFileRef = useRef(null);
  const importModelsFileRef = useRef(null);
  const dispatch = useDispatch();
  const { location } = history;
  const { loading: submitLoading, success } = useSelector(
    state => state.modelEditSetting,
  );
  const [currentValues, setCurrentValues] = useState({});

  // 紀錄商品內容更新時, 主視窗同步更新前的 page
  const query = useQuery();

  const isValueChanged = body => {
    let result = false;
    if (currentValues === null) {
      return true;
    }
    Object.entries(body).forEach(([key, value]) => {
      if (
        typeof currentValues[key] !== 'object' &&
        value !== currentValues[key]
      ) {
        result = true;
      } else if (typeof currentValues[key] === 'object') {
        if (JSON.stringify(currentValues[key]) !== JSON.stringify(value)) {
          result = true;
        }
      }
    });
    return result;
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setValues,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      brandId: 0,
      deliveryType: 0,
      isInstruction: null,
      isRepair: null,
      isSell: null,
      isWarranty: null,
      keyword: '',
      logisticsWay: 0,
      model: '',
      page_size: 50,
      stock: 0,
      stockType: 1,
    },
    onReset: () => {
      handleSubmit();
    },
    onSubmit: () => {
      if (isValueChanged(values)) {
        const params = new URLSearchParams({
          ...values,
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
        setCurrentValues(values);
      } else {
        dispatch(getModelList(values));
      }
    },
  });

  useEffect(() => {
    if (location.search) {
      const body = {
        ...query.value,
        isInstruction: query.value.isInstruction
          ? JSON.parse(query.value.isInstruction)
          : null,
        isRepair: query.value.isRepair
          ? JSON.parse(query.value.isRepair)
          : null,
        isSell: query.value.isSell ? JSON.parse(query.value.isSell) : null,
        isWarranty: query.value.isWarranty
          ? JSON.parse(query.value.isWarranty)
          : null,
      };

      dispatch(getModelList(body));
      setValues({
        ...values,
        ...body,
      });
    } else {
      resetForm();
    }
  }, [location.search]);

  // for updating the data on the list
  const [temp, setTemp] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [modelsSellingSettingsDisabled, setModelsSellingSettingsDisabled] =
    useState(null);
  const { userInfo } = useSelector(state => state.userLogin);
  const {
    loading: modelListLoading,
    modelListInfo,
    error,
  } = useSelector(state => state.modelList);
  const { modelOptions: productOptions, loading: productOptionssLoading } =
    useSelector(state => state.productmodelOptions);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { brandCategoryInfo: brandCategoryOptions } = useSelector(
    state => state.productBrandCategory,
  );

  const { error: updateError, success: updateSuccess } = useSelector(
    state => state.modelSellingState,
  );
  const {
    error: updateProductOptionError,
    success: updateProductOptionSuccess,
  } = useSelector(state => state.productOptionsUpdate);

  const {
    success: exportSuccess,
    error: exportError,
    url: fileUrl,
  } = useSelector(state => state.modelExport);

  const {
    success: stockImportSuccess,
    error: stockImportError,
    warning: stockImportWarning,
    loading: stockImportLoading,
  } = useSelector(state => state.productStockFileImport);
  const {
    success: modelImportSuccess,
    error: modelImportError,
    warning: modelImportWarning,
    loading: modelImportLoading,
  } = useSelector(state => state.modelImport);

  const [isSellForModel, setIsSellForModel] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('updateTime')) {
      dispatch(getUpdateTime());
    }

    dispatch(getBrandOptions());
    dispatch(getModelOptions(''));
    if (!location.search) {
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    brandOptions &&
      dispatch(
        getModelOptions(
          values.brandId
            ? brandOptions.find(i => Number(i.value) === Number(values.brandId))
                ?.label
            : '',
        ),
      );
    dispatch(getProductBrandCategory(values.brandId ?? 0));
  }, [values.brandId, brandOptions]);

  useEffect(() => {
    setTemp(modelListInfo);
    setModelsSellingSettingsDisabled(
      modelListInfo?.datas?.map(data => {
        let init = true;
        data.products.forEach(product => {
          if (product.isSell) {
            init = false;
          }
        });
        return init;
      }),
    );
  }, [modelListInfo]);

  const handlePageChange = page => {
    setFieldValue('page', page);
    handleSubmit();
    setTemp({ ...temp, current_page: page });
  };

  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [currentProd, setCurrentProd] = useState(null);
  const handleToggleLaunchModal = () => {
    setLaunchModalOpen(!launchModalOpen);
  };

  const isSticky = modelListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  const exportModelHandler = type => {
    dispatch(exportModel({ data: values, type }));
  };

  useEffect(() => {
    if (updateError) {
      MessageUtil.alertWanring(updateError);
      dispatch({ type: MODEL_SELLING_STATE_UPDATE_IDLE });
      return;
    }
    if (updateSuccess) {
      dispatch({ type: MODEL_SELLING_STATE_UPDATE_IDLE });
    }
  }, [updateError, updateSuccess]);
  useEffect(() => {
    if (updateProductOptionError) {
      MessageUtil.alertWanring(updateProductOptionError);
      dispatch({ type: PRODUCT_OPTIONS_STATE_UPDATE_IDLE });
      return;
    }
    if (updateProductOptionSuccess) {
      dispatch({ type: PRODUCT_OPTIONS_STATE_UPDATE_IDLE });
    }
  }, [updateProductOptionError, updateProductOptionSuccess]);

  useEffect(() => {
    if (exportSuccess && fileUrl) {
      window.open(fileUrl);
      CommonUtil.consoleLog({ anno: 'response', data: fileUrl });
      MessageUtil.toastSuccess('匯出成功!');

      dispatch({ type: MODEL_EXPORT_IDLE });
    }

    if (exportError) {
      MessageUtil.alertWanring('匯出失敗', exportError);
      dispatch({ type: MODEL_EXPORT_IDLE });
    }
  }, [exportError, exportSuccess]);

  useEffect(() => {
    if (stockImportSuccess) {
      const replaceWord = new RegExp('<br>', 'g');
      MessageUtil.alertWanringWithHTML(
        '匯入成功!',
        stockImportWarning?.replace(replaceWord, '<br />'),
      );
      importStockFileRef.current.value = '';
      handleSubmit();
      dispatch({ type: IMPORT_PRODUCT_STOCK_FILE_IDLE });
    }

    if (stockImportError) {
      MessageUtil.alertWanring('匯入失敗', stockImportError);
      importStockFileRef.current.value = '';
      dispatch({ type: IMPORT_PRODUCT_STOCK_FILE_IDLE });
    }
  }, [stockImportSuccess, stockImportError]);

  useEffect(() => {
    if (modelImportSuccess) {
      const replaceWord = new RegExp('<br>', 'g');
      MessageUtil.alertWanringWithHTML(
        '匯入成功!',
        modelImportWarning?.replace(replaceWord, '<br />'),
      );
      importModelsFileRef.current.value = '';
      handleSubmit();
      dispatch({ type: MODEL_IMPORT_IDLE });
    }

    if (modelImportError) {
      MessageUtil.alertWanring('匯入失敗', modelImportError);
      importModelsFileRef.current.value = '';
      dispatch({ type: MODEL_IMPORT_IDLE });
    }
  }, [modelImportError, modelImportSuccess]);

  useEffect(() => {
    currentId && checkAllProductsOfModelSellingStatus();
  }, [currentId, temp]);

  const updateModelSellingStateHandler = (id, value) => {
    const newTemp = temp.datas.map(i => {
      if (i.id === id) {
        return { ...i, isSell: value };
      } else {
        return i;
      }
    });
    setTemp({ ...temp, datas: newTemp });
  };

  const updateProductSellingStateHandler = (id, value) => {
    const newTemp = temp.datas.map(i => ({
      ...i,
      products: i.products.map(j => {
        if (j.productId === id) {
          return { ...j, isSell: value };
        } else {
          return j;
        }
      }),
    }));
    setTemp({ ...temp, datas: newTemp });
  };

  const [isEditStockNumModalOpen, setIsEditStockNumModalOpen] = useToggle();

  const [currentProductInfo, setCurrentProductInfo] = useState(null);

  const uploadFileHandler = (type, fileProps) => {
    const formData = new FormData();
    formData.append('formFile', fileProps);
    switch (type) {
      case 'stock':
        dispatch(importProductStockFile(formData));
        break;
      case 'model':
        dispatch(importModel(formData));
        break;
      default:
        return;
    }
  };

  const checkAllProductsOfModelSellingStatus = () => {
    const target = temp?.datas?.find(i => i.id === currentId.id);
    let check = [];
    target?.products?.forEach(element => {
      check.push(element.isSell);
    });
    if (!check.includes(true)) {
      updateModelSellingStateHandler(currentId.id, false);
      setIsSellForModel({
        data: { result: false, type: 'isSell' },
        mId: currentId.id,
      });
      let newState = [...modelsSellingSettingsDisabled];
      newState[currentId.index] = true;
      setModelsSellingSettingsDisabled(newState);
    } else {
      let newState = [...modelsSellingSettingsDisabled];
      newState[currentId.index] = false;
      let newTemp = { ...temp };
      newTemp.datas[currentId.index].isSell = true;
      setTemp(newTemp);
      setModelsSellingSettingsDisabled(newState);
    }

    setCurrentId(null);
  };

  const pullAllProductsFromShelves = index => {
    let products = temp?.datas[index].products?.map(product => {
      return { ...product, isSell: false };
    });
    let newTemp = { ...temp };
    newTemp.datas[index].products = products;

    setTemp(newTemp);
  };

  const [isEditAvailableTimeModalOpen, setIsEditAvailableTimeModalOpen] =
    useToggle();
  const [availableTimeInfo, setAvailableTimeInfo] = useState(null);

  const [isEditShippingDateModalOpen, setIsEditShippingDateModalOpen] =
    useToggle();
  const [shippingDateInfo, setShippingDateInfo] = useState(null);

  return (
    <Layout
      pageTitle="商品管理"
      items={[{ isActive: false, page_name: '商品管理' }]}
    >
      <Card className={style.search_card}>
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">關鍵字</label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="商品料號、商品名稱"
                  id="keyword"
                  name="keyword"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">品牌</label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className={style['form-select']}
                  options={[
                    emptyOption('請選擇品牌', 0),
                    ...(brandOptions ? [...brandOptions] : []),
                  ]}
                  id="brandId"
                  name="brandId"
                  defaultValue={emptyOption('請選擇品牌', 0)}
                  onChange={({ value }) =>
                    setFieldValue('brandId', Number(value))
                  }
                  value={
                    brandOptions?.find(
                      i => Number(i.value) === Number(values.brandId),
                    ) || emptyOption('請選擇品牌', 0)
                  }
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  className="col-form-label mr-2"
                  style={{ marginRight: '1rem' }}
                >
                  型號
                </label>
                <Select
                  isLoading={productOptionssLoading}
                  isDisabled={productOptionssLoading}
                  className={style['form-select']}
                  options={
                    productOptions && [
                      emptyOption('請選擇型號'),
                      ...productOptions,
                    ]
                  }
                  id="model"
                  name="model"
                  defaultValue={emptyOption('請選擇型號')}
                  onChange={({ value }) => setFieldValue('model', value)}
                  value={
                    productOptions?.find(i => i.value === values.model) ||
                    emptyOption('請選擇型號')
                  }
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">交期</label>
                <Input
                  type="select"
                  name="deliveryType"
                  className={style['form-select-shorts']}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('deliveryType', Number(value));
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="0">全部</option>
                  <option value="2">預購</option>
                  <option value="1">一般</option>
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">配送方式</label>
                <Input
                  type="select"
                  name="logisticsWay"
                  className={style['form-select-shorts']}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('logisticsWay', Number(value));
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="0">全部</option>
                  <option value="1">7-11</option>
                  <option value="2">全家</option>
                  <option value="3">宅配</option>
                  <option value="4">門市</option>
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">上/下架官網商品</label>
                <Input
                  type="select"
                  name="isSell"
                  className={style['form-select-shorts']}
                  onChange={e => {
                    const value = e.target.value;
                    const val =
                      value === '1' ? true : value === '2' ? false : null;
                    setFieldValue('isSell', val);
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="1">上架</option>
                  <option value="2">下架</option>
                </Input>
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">可銷售數量</label>
                <Input
                  type="select"
                  name="stockType"
                  className={style['form-select-short']}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('stockType', Number(value));
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="1">大於</option>
                  <option value="2">小於</option>
                </Input>
                <Input
                  type="number"
                  name="stock"
                  className={`${style['form-input-short']} ${style['text-center']}`}
                  defaultValue={0}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('stock', Number(value));
                  }}
                ></Input>
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter className="text-end">
          <Button
            type="button"
            className={style.modal_button_cancel}
            onClick={() => {
              resetForm();
            }}
          >
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            onClick={() => {
              setFieldValue('page', 1);
              handleSubmit();
            }}
          >
            查詢
          </Button>
        </CardFooter>
      </Card>
      <Card className={style.card}>
        <div className="d-flex flex-wrap justify-content-between">
          <div>
            <Button
              color="success"
              className="me-2 w130"
              onClick={() => {
                history.push('/AddNewProduct');
              }}
              style={{ margin: '0.2rem' }}
            >
              新增商品
            </Button>
            <Button
              className="me-2 w130"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                exportModelHandler('model');
              }}
            >
              商品匯出資料
            </Button>
            <Button
              className="me-2 w130"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                importModelsFileRef.current.click();
              }}
            >
              <input
                type="file"
                style={{ display: 'none' }}
                ref={importModelsFileRef}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={e => {
                  const fileProps = e.target.files[0];
                  fileProps && uploadFileHandler('model', fileProps);
                }}
                disabled={modelImportLoading}
              />
              商品匯入資料
            </Button>
            <Button
              className="me-2 w130"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                exportModelHandler('product');
              }}
            >
              商品庫存匯出
            </Button>
            <Button
              className="me-2 w130"
              style={{ margin: '0.2rem' }}
              onClick={() => {
                importStockFileRef.current.click();
              }}
            >
              <input
                type="file"
                style={{ display: 'none' }}
                ref={importStockFileRef}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={e => {
                  const fileProps = e.target.files[0];
                  fileProps && uploadFileHandler('stock', fileProps);
                }}
                disabled={stockImportLoading}
              />
              商品庫存匯入
            </Button>
          </div>
          <div className={style['rwd-width-100']} style={{ width: '150px' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              每頁
              <Input
                type="select"
                name="page_size"
                style={{ margin: '0.3rem', width: '80px' }}
                onChange={event => {
                  setFieldValue('page_size', event.target.value);
                  handleSubmit();
                }}
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </Input>
              筆
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!modelListLoading && temp?.total_count ? temp?.total_count : '-'}
          </span>
          筆
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
              className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
              <tr>
                <th>功能</th>
                <th>商品名稱</th>
                <th>銷售時間</th>
                <th>官網商品</th>
                <th>
                  <div>商品料號</div>
                  <div>顏色,尺寸</div>
                  <div>贈品</div>
                </th>
                <th>
                  <div>建議售價</div>
                  <div>售價</div>
                </th>
                <th>
                  <div>已售數量</div>
                  <div>可售數量</div>
                  <div>貨到通知數</div>
                </th>
                <th>上/下架</th>
                <th>商品缺貨顯示</th>
                <th style={{ minWidth: '120px' }}>新增時間</th>
                <th style={{ minWidth: '120px' }}>交期</th>
                <th>配送方式</th>
                <th>上市日期</th>
              </tr>
            </thead>
            {(modelListLoading || stockImportLoading || modelImportLoading) && (
              <TableLoading />
            )}
            {!(
              modelListLoading ||
              stockImportLoading ||
              modelImportLoading
            ) && (
              <tbody>
                {temp?.datas ? (
                  temp?.datas?.map((data, index) =>
                    data.products.map((product, pIndex) => (
                      <tr key={pIndex}>
                        {pIndex === 0 && (
                          <>
                            <td rowSpan={data.products.length}>
                              <>
                                <Button
                                  className="m-2 btn btn-outline-primary btn-sm text-primary"
                                  style={{
                                    backgroundColor: 'transparent',
                                  }}
                                  onClick={() => {
                                    history.push(`/ProductDetail/${data.id}`);
                                  }}
                                >
                                  編輯
                                </Button>
                                <Button
                                  className="m-2 btn btn-sm"
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: '#f4772e',
                                  }}
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_WEBSITE_URL}/products/${data.id}?productType=1&preview=1&previewToken=${temp.previewToken}`,
                                    );
                                  }}
                                >
                                  <span style={{ color: '#f4772e' }}>預覽</span>
                                </Button>
                              </>
                            </td>
                            <td
                              style={{ wordBreak: 'keep-all' }}
                              rowSpan={data.products.length}
                            >
                              {data.name}
                            </td>
                            <td
                              style={{
                                color: '#0077ff',
                                cursor: 'pointer',
                                minWidth: '140px',
                                wordBreak: 'keep-all',
                              }}
                              rowSpan={data.products.length}
                              onClick={() => {
                                setIsEditAvailableTimeModalOpen();
                                setAvailableTimeInfo({
                                  endTime: data.availableTimeEnd,
                                  id: data.id,
                                  startTime: data.availableTimeStart,
                                  type: data.availableEndTimeType,
                                });
                              }}
                            >
                              <div>起：{data.availableTimeStart}</div>
                              <div>迄：{data.availableTimeEnd}</div>
                            </td>
                            <td
                              style={{ wordBreak: 'keep-all' }}
                              rowSpan={data.products.length}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={data.isSell}
                                  onChange={() => {
                                    setIsSellForModel({
                                      data: { result: true, type: 'isSell' },
                                      mId: data.id,
                                    });
                                    updateModelSellingStateHandler(
                                      data.id,
                                      true,
                                    );
                                    pullAllProductsFromShelves(index);
                                    dispatch(
                                      updateModelSellingState({
                                        data: {
                                          result: true,
                                          type: 'isSell',
                                        },
                                        mId: data.id,
                                      }),
                                    );
                                  }}
                                  disabled={
                                    !userInfo?.permissions.find(
                                      permission => permission.id === 30,
                                    ) || modelsSellingSettingsDisabled[index]
                                  }
                                />
                                上架
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={!data.isSell}
                                  onChange={() => {
                                    setIsSellForModel({
                                      data: { result: false, type: 'isSell' },
                                      mId: data.id,
                                    });
                                    updateModelSellingStateHandler(
                                      data.id,
                                      false,
                                    );
                                    setCurrentId({ id: data.id, index });
                                    pullAllProductsFromShelves(index);
                                    dispatch(
                                      updateModelSellingState({
                                        data: {
                                          result: false,
                                          type: 'isSell',
                                        },
                                        mId: data.id,
                                      }),
                                    );
                                  }}
                                  disabled={
                                    !userInfo?.permissions.find(
                                      permission => permission.id === 30,
                                    ) || modelsSellingSettingsDisabled[index]
                                  }
                                />
                                下架
                              </div>
                            </td>
                          </>
                        )}
                        {/* 商品料號 顏色 贈品 */}
                        <td style={{ wordBreak: 'keep-all' }}>
                          <div>{product.productcode || '-'}</div>
                          <div>
                            {product.color || '--'}/{product.size || '--'}
                          </div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                            onClick={() => {
                              history.push(
                                `/GiftsBinding?id=${product.productId}&mid=${data.id}&productcode=${product.productcode}&productType=1`,
                              );
                            }}
                          >
                            加入贈品
                          </div>
                          <div>{`贈品${product?.freebieNum ?? '--'}個`}</div>
                        </td>
                        {/* 建議售價 售價 */}
                        <td>
                          <div className="price text-black fw-normal">
                            {(product?.websiteprice || '--')?.toLocaleString(
                              'en-US',
                            )}
                          </div>
                          <div className="price text-black fw-normal">
                            {(product?.sellprice || '--')?.toLocaleString(
                              'en-US',
                            )}
                          </div>
                        </td>
                        {/* 已售數量 可售數量 貨到通知數 */}
                        <td className="text-center">
                          <div>{product.soldNum || 0}</div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                            onClick={() => {
                              setIsEditStockNumModalOpen();
                              setCurrentProductInfo({
                                id: product.productId,
                                num: product.stock,
                                result: product.isSell,
                              });
                            }}
                          >
                            {product.stock || 0}
                          </div>
                          <div>{product.notifyNum || 0}</div>
                        </td>
                        {/* 上下架 */}
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              checked={product.isSell}
                              onChange={() => {
                                dispatch(
                                  updateProductOptionsState({
                                    data: { result: true, type: 'isSell' },
                                    pId: product.productId,
                                  }),
                                );
                                updateProductSellingStateHandler(
                                  product.productId,
                                  true,
                                );
                                setCurrentId({ id: data.id, index });
                                // checkAllProductsOfModelSellingStatus(data.id);
                              }}
                            />
                            上架
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              checked={!product.isSell}
                              onChange={() => {
                                dispatch(
                                  updateProductOptionsState({
                                    data: { result: false, type: 'isSell' },
                                    pId: product.productId,
                                  }),
                                );
                                updateProductSellingStateHandler(
                                  product.productId,
                                  false,
                                );
                                setCurrentId({ id: data.id, index });
                                // checkAllProductsOfModelSellingStatus(data.id);
                              }}
                            />
                            下架
                          </div>
                        </td>
                        {/* 商品缺貨顯示 */}
                        <td>{product.outofStockWords || '--'}</td>
                        {/* 新增時間 */}
                        <td nowrap="nowrap">{product?.createTime ?? '--'}</td>
                        {/* 交期 */}
                        <td
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            wordBreak: 'keep-all',
                          }}
                          onClick={() => {
                            setIsEditShippingDateModalOpen();
                            setShippingDateInfo({
                              date: product.preorderDeliveryDate,
                              id: product.productId,
                              type: product.deliveryType,
                            });
                          }}
                        >
                          <div>
                            {Number(product.deliveryType) === 1
                              ? '一般'
                              : '預購'}
                          </div>
                          {Number(product.deliveryType) === 2 && (
                            <div>
                              {product.preorderDeliveryDate
                                ? product.preorderDeliveryDate.split('T')[0]
                                : '--'}
                            </div>
                          )}
                        </td>
                        {pIndex === 0 && (
                          <>
                            <td rowSpan={data.products.length}>
                              {data.isLogistic1 && <div>7-11取貨</div>}
                              {data.isLogistic2 && <div>全家取貨</div>}
                              {data.isLogistic3 && <div>宅配</div>}
                              {data.isLogistic4 && <div>門市取貨</div>}
                            </td>
                            <td rowSpan={data.products.length}>
                              <Button
                                color="link"
                                className="text-decoration-none d-flex align-items-center gap-1"
                                onClick={() => {
                                  setLaunchModalOpen(true);
                                  setCurrentProd(data);
                                }}
                              >
                                {data?.launchedTime
                                  ? moment(data?.launchedTime).format(
                                      'YYYY/MM/DD HH:mm:ss',
                                    )
                                  : '--'}
                                <FiEdit />
                              </Button>
                            </td>
                          </>
                        )}
                      </tr>
                    )),
                  )
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={temp?.current_page || 1}
            pageSize={temp?.page_size || 10}
            total={temp?.total_count || 0}
            onChange={handlePageChange}
          />
        </div>
        <LaunchTimeModal
          open={launchModalOpen}
          toggle={handleToggleLaunchModal}
          item={currentProd}
          onSuccess={handleSubmit}
        />
        {isEditStockNumModalOpen && (
          <EditStockNumberModal
            open={isEditStockNumModalOpen}
            toggle={setIsEditStockNumModalOpen}
            info={currentProductInfo}
            onUpdate={handleSubmit}
          />
        )}
        {isEditAvailableTimeModalOpen && availableTimeInfo && (
          <EditAvailableTimeModal
            open={isEditAvailableTimeModalOpen}
            toggle={setIsEditAvailableTimeModalOpen}
            info={availableTimeInfo}
            onRefresh={handleSubmit}
          />
        )}
        {isEditShippingDateModalOpen && shippingDateInfo && (
          <EditShippingDateModal
            open={isEditShippingDateModalOpen}
            toggle={setIsEditShippingDateModalOpen}
            info={shippingDateInfo}
            onRefresh={handleSubmit}
          />
        )}
      </Card>
    </Layout>
  );
};

export default ProductControl;
