import Layout from 'components/Layout';
import ChooseModal from 'components/productSalesDataRanking/ChooseModal';
import SearchCard from 'components/productSalesDataRanking/SearchCard';
import { useState } from 'react';
import { useToggle } from 'utils/customHooks';
const ProductSalesDataRanking = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const [isChooseModalOpen, setIsChooseModalOpen] = useToggle();

  const [brandModelOptions, setBrandModelOptions] = useState([]);

  return (
    <Layout
      pageTitle={'商品銷售排行榜'}
      items={[
        { isActive: true, page_name: '營運報表' },
        { isActive: false, page_name: '商品銷售排行榜' },
      ]}
    >
      <SearchCard
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        isChooseModalOpen={isChooseModalOpen}
        setIsChooseModalOpen={setIsChooseModalOpen}
        brandModelOptions={brandModelOptions}
        setBrandModelOptions={setBrandModelOptions}
      />
      {isChooseModalOpen && (
        <ChooseModal
          isOpen={isChooseModalOpen}
          toggle={setIsChooseModalOpen}
          onSubmit={setBrandModelOptions}
          datas={brandModelOptions}
        />
      )}
    </Layout>
  );
};

export default ProductSalesDataRanking;
