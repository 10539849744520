import {
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import style from 'styles/layout.module.css';

const OrderReturnDescModal = ({ open, toggle, info }) => {
  return (
    <div className={style.modal}>
      <Modal
        zIndex={2000}
        centered
        size="lg"
        isOpen={open}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className={style.modal_head}>
          退貨原因
        </ModalHeader>
        <ModalBody className={style.modal_body}>
          <Form>
            <FormGroup row>
              <Label style={{ width: 150 }}>退貨原因</Label>
              <Col>{info?.returnReason}</Col>
            </FormGroup>
            <FormGroup row>
              <Label style={{ width: 150 }}>退貨描述</Label>
              <Col>{info?.returnDesc}</Col>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OrderReturnDescModal;
