import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import Loader from '../components/Loader';
import UploadFileBox from '../components/UploadFileBox';
import { editSetting, getSetting } from '../redux/actions/data/settingAction';
import { SETTING_EDIT_RESET } from '../redux/constants/data/settingConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';

const BannerSetting = () => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [pageHead, setPageHead] = useState('');
  const [modalData, setModalData] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  const { userInfo } = useSelector(state => state.userLogin);
  const { loading, settingInfo, error } = useSelector(state => state.setting);
  const {
    loading: editLoading,
    success,
    error: editError,
  } = useSelector(state => state.settingEdit);

  const swal = withReactContent(Swal);

  // useEffect(() => {
  //   if (!settingInfo || settingInfo.length === 0) {
  //     dispatch(getSetting())
  //   }
  // }, [settingInfo])

  useEffect(() => {
    dispatch(getSetting());
  }, []);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     console.log('JJJJJJJJJJJJJJJJJ')
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/BannerSetting' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       console.log('LLLLLLLLLLLLLLLLLLLL')
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/BannerSetting' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    if (success) {
      dispatch(getSetting());
      toggleModal();
      MessageUtil.toastSuccess('新增成功!!');
    }
    if (editError) {
      if (editError.response && editError.response.data) {
        swal.fire({
          icon: 'warning',
          text: editError.response.data.message,
          title: `儲存失敗(${editError.response.data.code})`,
        });
      } else {
        swal.fire({
          icon: 'warning',
          text: editError,
          title: '儲存失敗',
        });
      }
    }

    return () => {
      dispatch({ type: SETTING_EDIT_RESET });
    };
  }, [success, editError]);

  const toggleModal = pageName => {
    setOpenModal(!openModal);
    setPageHead(pageName);

    let setting_data = settingInfo.result.find(data => {
      return data.pageName === pageName;
    });

    if (setting_data) {
      setModalData(setting_data);
    }
  };

  const getImageDataFromSetting = (pic, url) => {
    if (pic && url) {
      return [
        {
          id: CommonUtil.getNewID(),
          name: pic,
          url: url,
        },
      ];
    } else {
      return [];
    }
  };

  const handleSubmit = values => {
    setSubmitLoading(true);
    let formData = new FormData();

    formData.append('type', values.fileType);
    formData.append('pageName', modalData.pageName);
    formData.append('block', 'banner');
    formData.append('navColor', values.navColor);

    //確認是否刪除原有的檔案
    let isRemoveOrginalFile = false;
    if (modalData.fileName && modalData.fileName.length > 0) {
      if (
        values.current_banner_files &&
        values.current_banner_files.length > 0
      ) {
        isRemoveOrginalFile =
          values.current_banner_files.filter(function (item) {
            return item.name === modalData.fileName;
          }).length == 0;
      } else {
        isRemoveOrginalFile = true;
      }
    }

    formData.append('isRemoveOrginalFile', isRemoveOrginalFile);

    if (values.banner_files?.length > 0) {
      formData.append('files', values.banner_files[0]);
    }

    // 確認是否刪除原有的檔案
    let isRemoveOrginalFile_mobile = false;
    if (modalData.fileName_mobile && modalData.fileName_mobile.length > 0) {
      if (
        values.current_banner_files_mobile &&
        values.current_banner_files_mobile.length > 0
      ) {
        isRemoveOrginalFile_mobile =
          values.current_banner_files_mobile.filter(function (item) {
            return item.name === modalData.fileName_mobile;
          }).length == 0;
      } else {
        isRemoveOrginalFile_mobile = true;
      }
    }

    formData.append('isRemoveOrginalFile_mobile', isRemoveOrginalFile_mobile);

    if (values.banner_files_mobile?.length > 0) {
      formData.append('files_mobile', values.banner_files_mobile[0]);
    }

    dispatch(editSetting(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      navColor: Yup.string().required('必填!'),
    });

    return schema;
  };

  useEffect(() => {
    if (settingInfo) {
      if (JSON.stringify(settingInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: settingInfo.result });
      }
    }
  }, [settingInfo]);

  return (
    <Layout
      pageTitle="網頁形象圖片"
      items={[{ isActive: true, page_name: '網頁形象圖片' }]}
    >
      <div className={style.card}>
        {!loading ? (
          <>
            <p className={style.text}>共 5 筆</p>
            <div className={style.table_overflow}>
              <Table hover bordered striped>
                <thead className={`thead-dark ${style.table_head}`}>
                  <tr>
                    <th style={{ minWidth: '50px' }}>網頁</th>
                    <th style={{ minWidth: '90px' }}>主選單圖標顏色</th>
                    <th>電腦版圖片</th>
                    <th>手機版圖片</th>
                    <th style={{ minWidth: '120px' }}>異動日期</th>
                    <th>功能</th>
                  </tr>
                </thead>
                <tbody>
                  {settingInfo &&
                    settingInfo.result.map((data, index) =>
                      data.pageName === 'brand' ||
                      data.pageName === 'product' ||
                      data.pageName === 'news' ? null : (
                        <tr
                          key={index}
                          style={
                            data.pageName === 'about' ? { display: 'none' } : {}
                          }
                        >
                          <td>
                            {data.pageName === 'index'
                              ? '首頁'
                              : data.pageName === 'product'
                              ? '商品類別'
                              : data.pageName === 'services'
                              ? '客服中心'
                              : data.pageName === 'joinus'
                              ? '加入我們'
                              : ''}
                          </td>
                          <td>{data.navColor === 'black' ? '黑色' : '白色'}</td>
                          <td>
                            {data.type === 'video' ? (
                              <video
                                className={style.w200}
                                autoPlay
                                muted
                                loop
                                playsInline
                              >
                                <source src={data.fileUrl} type="video/mp4" />
                              </video>
                            ) : (
                              <img
                                className={style.w200}
                                src={data.fileUrl}
                                alt={data.fileUrl && data.pageName}
                              />
                            )}
                          </td>
                          <td>
                            {data.type === 'video' ? (
                              <video
                                className={style.w200}
                                autoPlay
                                muted
                                loop
                                playsInline
                              >
                                <source
                                  src={data.fileUrl_mobile}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <img
                                className={style.w200}
                                src={data.fileUrl_mobile}
                                alt={data.fileUrl_mobile && data.pageName}
                              />
                            )}
                          </td>
                          <td>
                            <div>
                              <span className="d-inline-block me-2">
                                {data.editDate}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.editTime}
                              </span>
                              <span className="d-inline-block me-2">
                                {data.editorName}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              outline
                              color="primary"
                              onClick={() => toggleModal(data.pageName)}
                            >
                              編輯
                            </Button>
                          </td>
                        </tr>
                      ),
                    )}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <Loader />
        )}
        <div className={style.modal}>
          <Modal
            centered
            size="lg"
            isOpen={openModal}
            toggle={toggleModal}
            backdrop="static"
          >
            <ModalHeader toggle={toggleModal} className={style.modal_head}>
              {pageHead === 'index'
                ? '首頁形象圖'
                : pageHead === 'brand'
                ? '品牌總頁形象圖'
                : pageHead === 'news'
                ? '最新消息形象圖'
                : pageHead === 'product'
                ? '商品類別形象圖'
                : pageHead === 'services'
                ? '客服中心形象圖'
                : pageHead === 'joinus'
                ? '加入我們形象圖'
                : ''}
            </ModalHeader>
            <Formik
              initialValues={{
                // banner_files: getExistFileData(),
                banner_files: [],
                banner_files_mobile: [],

                current_banner_files: [],
                current_banner_files_mobile: [],
                fileType: modalData ? modalData.type : '',

                init_banner_files: modalData
                  ? getImageDataFromSetting(
                      modalData.fileName,
                      modalData.fileUrl,
                    )
                  : [],
                init_banner_files_mobile: modalData
                  ? getImageDataFromSetting(
                      modalData.fileName_mobile,
                      modalData.fileUrl_mobile,
                    )
                  : [],
                navColor: modalData ? modalData.navColor : '',
              }}
              onSubmit={handleSubmit}
              validationSchema={getValidationSchema}
            >
              {props => (
                <Form className="form-list form-label-140">
                  <ModalBody className={style.modal_body}>
                    <div className={style.formGroup}>
                      <Label
                        className="required"
                        style={{ marginRight: '1rem' }}
                      >
                        主選單圖標顏色
                      </Label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Label
                            className="mr-3"
                            style={{ marginRight: '1rem' }}
                          >
                            <Field name="navColor" type="radio" value="white" />
                            白色
                          </Label>

                          <Label
                            className="mr-3"
                            style={{ marginRight: '1rem' }}
                          >
                            <Field name="navColor" type="radio" value="black" />
                            黑色
                          </Label>
                          <ErrorMessage
                            name="navColor"
                            component="span"
                            className="form-error"
                          />
                        </div>
                      </div>
                    </div>

                    {modalData.pageName === 'index' && (
                      <div className="d-flex">
                        <Label
                          style={{
                            marginRight: '2rem',
                            verticalAlign: 'center',
                          }}
                        >
                          上傳檔案類型
                        </Label>
                        <Label className="me-3">
                          <Field name="fileType" type="radio" value="images" />
                          圖片
                        </Label>
                        <Label className="me-3">
                          <Field name="fileType" type="radio" value="video" />
                          影片
                        </Label>
                      </div>
                    )}
                    <FormGroup className={style.formGroup}>
                      <div className="form-item">
                        {props.values.fileType === 'images' && (
                          <>
                            <div className={style.formGroup}>
                              <label style={{ width: '120px' }}>
                                電腦版圖片
                              </label>
                              <div className="form-item">
                                <UploadFileBox
                                  uplodModel={props.values.fileType}
                                  formFieldName="banner_files"
                                  currentFileFieldName="current_banner_files"
                                  setFormFieldValue={props.setFieldValue}
                                  displayFileWidth="1920"
                                  displayFileHeight="1080"
                                  displayFileSize="3MB"
                                  initFiles={props.values.init_banner_files}
                                />
                              </div>
                            </div>

                            <div className={style.formGroup}>
                              <label style={{ width: '120px' }}>
                                手機版圖片
                              </label>
                              <div className="form-item">
                                <UploadFileBox
                                  uplodModel={props.values.fileType}
                                  formFieldName="banner_files_mobile"
                                  currentFileFieldName="current_banner_files_mobile"
                                  setFormFieldValue={props.setFieldValue}
                                  displayFileWidth="800"
                                  displayFileHeight="1200"
                                  displayFileSize="3MB"
                                  initFiles={
                                    props.values.init_banner_files_mobile
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {props.values.fileType === 'video' && (
                          <>
                            <div className={style.formGroup}>
                              <label style={{ width: '120px' }}>
                                電腦版影片
                              </label>
                              <div className="form-item">
                                <UploadFileBox
                                  uplodModel={props.values.fileType}
                                  setFormFieldValue={props.setFieldValue}
                                  initFiles={props.values.init_banner_files}
                                  displayFileWidth="1920"
                                  displayFileHeight="1080"
                                  displayFileSize="5MB"
                                  formFieldName="banner_files"
                                  currentFileFieldName="current_banner_files"
                                />
                              </div>
                            </div>
                            <div className={style.formGroup}>
                              <label style={{ width: '120px' }}>
                                手機版影片
                              </label>
                              <div className="form-item">
                                <UploadFileBox
                                  uplodModel={props.values.fileType}
                                  setFormFieldValue={props.setFieldValue}
                                  displayFileWidth="800"
                                  displayFileHeight="1200"
                                  displayFileSize="5MB"
                                  initFiles={
                                    props.values.init_banner_files_mobile
                                  }
                                  formFieldName="banner_files_mobile"
                                  currentFileFieldName="current_banner_files_mobile"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      color="light"
                      type="button"
                      className={style.modal_button_cancel}
                      onClick={toggleModal}
                    >
                      取消
                    </Button>
                    <Button
                      color="primary"
                      type="submit"
                      className={style.modal_button_submit}
                    >
                      {editLoading && (
                        <span
                          className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          style={{ marginRight: '1rem' }}
                        ></span>
                      )}
                      <span>儲存</span>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default BannerSetting;
