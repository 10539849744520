import Layout from 'components/Layout';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import { getActivityList } from 'redux/actions/data/discountCompaignActions';
import {
  getGift,
  getCouponSettingList,
  BuyAndGetFreeList,
} from 'redux/actions/data/giftsActions';
import useQuery from 'utils/useQuery';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const ThirdTable = () => {
  const { gift } = useSelector(state => state.getGift);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const onPageChange = useCallback(
    async page => {
      setLoading(true);
      try {
        const res = await getCouponSettingList(gift?.freebieCode, page);
        setList(res.datas);
        setPage(res.current_page);
        setTotal(res.total_count);
      } catch (e) {
        setList([]);
        setPage(1);
        setTotal(1);
      }
      setLoading(false);
    },
    [gift?.freebieCode],
  );

  useEffect(() => {
    if (gift?.freebieCode) {
      onPageChange(1);
    }
  }, [gift]);

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>活動序號</th>
              <th>活動名稱</th>
              <th>領取期間</th>
              <th>使用期限</th>
              <th>活動狀態</th>
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {list.length > 0 ? (
                list.map(l => {
                  return (
                    <tr key={JSON.stringify(l)}>
                      <td>{l.activityCode}</td>
                      <td>{l.name}</td>
                      <td>
                        <div className="text-nowrap">
                          {(l.getStartTime &&
                            moment(l.getStartTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )) ||
                            '--'}
                        </div>
                        <div className="text-nowrap">
                          {(l.getEndTime &&
                            moment(l.getEndTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )) ||
                            '--'}
                        </div>
                      </td>
                      <td>
                        <div className="text-nowrap">
                          {(l.redeemStartTime &&
                            moment(l.redeemStartTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )) ||
                            '--'}
                        </div>
                        <div className="text-nowrap">
                          {(l.redeemEndTime &&
                            moment(l.redeemEndTime).format(
                              'YYYY/MM/DD HH:mm:ss',
                            )) ||
                            '--'}
                        </div>
                      </td>
                      <td>
                        {l.statusNameList.map(s => {
                          return <div key={s}>{s}</div>;
                        })}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
          {loading && <TableLoading />}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={page}
          pageSize={10}
          total={total}
          onChange={onPageChange}
        />
      </div>
    </React.Fragment>
  );
};

const SecondTable = () => {
  const dispatch = useDispatch();
  const { gift } = useSelector(state => state.getGift);
  const { activityList, loading } = useSelector(state => state.getActivityList);

  useEffect(() => {
    if (gift?.freebieCode) {
      pageChangeHandler(1);
    }
  }, [gift]);

  const pageChangeHandler = useCallback(
    page => {
      dispatch(
        getActivityList({
          freebieCode: gift.freebieCode,
          page,
          page_size: 10,
        }),
      );
    },
    [gift],
  );

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>活動序號</th>
              <th>活動名稱</th>
              <th>活動時間</th>
              <th>活動狀態</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {activityList?.datas?.length > 0 ? (
                activityList.datas.map(data => (
                  <tr key={data.id}>
                    <td className="text-center">{data.activityCode}</td>
                    <td className="text-center">{data.name}</td>
                    <td className="text-center">
                      <div className="text-nowrap">
                        {(data.startTime &&
                          moment(data.startTime).format(
                            'YYYY/MM/DD HH:mm:ss',
                          )) ||
                          '--'}
                      </div>
                      <div className="text-nowrap">
                        {(data.endTime &&
                          moment(data.endTime).format('YYYY/MM/DD HH:mm:ss')) ||
                          '--'}
                      </div>
                    </td>
                    <td className="text-center">
                      {data.status == 0
                        ? '尚未開始'
                        : data.status == 1
                        ? '進行中'
                        : '已結束'}
                    </td>
                  </tr>
                ))
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={activityList?.current_page || 1}
          pageSize={activityList?.page_size || 10}
          total={activityList?.total_count || 0}
          onChange={pageChangeHandler}
        />
      </div>
    </React.Fragment>
  );
};
const FirstTable = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { productList, loading } = useSelector(
    state => state.getBindingProductList,
  );

  useEffect(() => {
    if (query?.value?.id) {
      pageChangeHandler(1);
    }
  }, [query?.value?.id]);

  const pageChangeHandler = useCallback(
    page => {
      dispatch(
        BuyAndGetFreeList({
          freebieId: query.value.id,
          globalCatId: 0,
          isAdded: true,
          keyword: '',
          page: page,
          page_size: 10,
          searchType: '',
        }),
      );
    },
    [query?.value?.id],
  );

  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table hover bordered striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>商品類型</th>
              <th>商品名稱</th>
              <th>商品料號</th>
              <th>顏色</th>
              <th>尺寸</th>
              <th>
                <div>加入此贈品日期</div>
                <div>加入人員</div>
              </th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {productList?.datas?.length > 0 ? (
                productList.datas.map((data, index) =>
                  data.products.map((product, pIndex) => (
                    <tr key={index + pIndex}>
                      {
                        // 商品名稱
                        pIndex === 0 && (
                          <td
                            className="text-center"
                            rowSpan={data.products.length}
                          >
                            <div>
                              {data.productType === 1 ? '一般商品' : ''}
                              {data.productType === 2 ? '隱形賣場' : ''}
                              {data.productType === 3 ? '組合商品' : ''}
                            </div>
                            <div>
                              {data.productType === 2 && data.modelNumber}
                            </div>
                          </td>
                        )
                      }
                      {
                        // 商品名稱
                        pIndex === 0 && (
                          <td
                            className="text-center"
                            rowSpan={data.products.length}
                          >
                            {data.modelName}
                          </td>
                        )
                      }
                      {/* 商品料號 */}
                      <td className="text-center text-nowrap">
                        {product.productcode}
                      </td>
                      {/* 商品顏色 */}
                      <td className="text-center text-nowrap">
                        {product.color || '--'}
                      </td>
                      {/* 商品尺寸 */}
                      <td className="text-center text-nowrap">
                        {product.size || '--'}
                      </td>
                      {/* 加入日期/人員 */}
                      <td className="text-center">
                        <div className="text-nowrap">
                          {product?.addTime
                            ? moment(product.addTime).format(
                                'YYYY/MM/DD HH:mm:ss',
                              )
                            : ''}
                        </div>
                        <div>{product.addAdmin || ''}</div>
                      </td>
                    </tr>
                  )),
                )
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <RcPagination
          current={productList?.current_page || 1}
          pageSize={productList?.page_size || 10}
          total={productList?.total_count || 0}
          onChange={pageChangeHandler}
        />
      </div>
    </React.Fragment>
  );
};

const Profile = () => {
  const { gift } = useSelector(state => state.getGift);
  return (
    <div className={style.formGroup}>
      <img
        src={gift?.freebieImgInfo ? gift.freebieImgInfo[0]?.imageUrl : ''}
        className={style.w200}
        alt="贈品圖片"
        style={{ marginBottom: '3rem', marginRight: '2rem' }}
      />
      <div style={{ fontSize: '18px', lineHeight: '30px' }}>
        <div>{`贈品序號： ${gift?.freebieCode || '-'}`}</div>
        <div>{`贈品名稱： ${gift?.name || '-'}`}</div>
        <div>{`贈品狀態： ${gift?.isSell ? '開啟' : '關閉'}`}</div>
        <div>{`銷售時間： ${
          (gift?.startTime &&
            moment(gift.startTime).format('yyyy/MM/DD HH:mm:ss')) ||
          '-'
        } - ${
          (gift?.endTime &&
            moment(gift.endTime).format('yyyy/MM/DD HH:mm:ss')) ||
          '-'
        }`}</div>
      </div>
    </div>
  );
};

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        }`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

const GiftDetail = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(1);
  const query = useQuery();
  const id = useMemo(() => {
    return query.value.id;
  }, [query?.value?.id]);

  useEffect(() => {
    dispatch(getGift(id));
  }, []);

  return (
    <Layout
      pageTitle={'檢視活動'}
      items={[
        { isActive: true, page_name: '商品管理' },
        { isActive: false, page_name: '贈品管理', to: '/GiftManagement' },
        { isActive: false, page_name: '檢視活動' },
      ]}
    >
      <Nav pills className="">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="買就送"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="滿額贈"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={3}
          text="贈品券"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            <Profile />
            <FirstTable />
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <Card className={style.card}>
            <Profile />
            <SecondTable />
          </Card>
        </TabPane>
        <TabPane tabId={3}>
          <Card className={style.card}>
            <Profile />
            <ThirdTable />
          </Card>
        </TabPane>
      </TabContent>
    </Layout>
  );
};

export default GiftDetail;
