import { ErrorMessage, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import UploadFileBox from '../components/UploadFileBox';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import { getModelOptions } from '../redux/actions/data/productActions';
import {
  deleteInstruction,
  getInstructionList,
  postInstruction,
} from '../redux/actions/data/serviceActions';
import {
  INSTRUCTION_DELETE_RESET,
  INSTRUCTION_POST_RESET,
} from '../redux/constants/data/serviceConstants';
import style from '../styles/layout.module.css';
import CommonUtil from '../utils/CommonUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

export default function PrdInstructions(props) {
  var FileSaver = require('file-saver');
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [current_page, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [brandId, setBrandId] = useState({ label: '請選擇品牌', value: 0 });
  const [model, setModel] = useState({ label: '請選擇型號', value: '' });
  const [editData, setEditData] = useState({});

  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { modelOptions, loading: modelOptionsLoading } = useSelector(
    state => state.productmodelOptions,
  );
  const { loading, instructionListInfo, error } = useSelector(
    state => state.instructionList,
  );
  const {
    loading: postLoading,
    success: postSuccess,
    error: postError,
  } = useSelector(state => state.instructionPost);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.instructionDelete,
  );
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout())
  //     history.push({ pathname: '/Login', state: '/ProductInstructions' })
  //     MessageUtil.alertWanring('請重新登入取得授權')
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout())
  //       history.push({ pathname: '/Login', state: '/ProductInstructions' })
  //       MessageUtil.alertWanring('請重新登入取得授權')
  //     }
  //   }
  // }, [userInfo, error])

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  useEffect(() => {
    if (brandId.label === '請選擇品牌') {
      dispatch(getModelOptions(''));
    } else {
      dispatch(getModelOptions(brandId.label));
    }
  }, [brandId]);

  useEffect(() => {
    if (postSuccess || typeof postError === 'undefined') {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('儲存成功!');
      dispatch(getInstructionList({ page: 1 }));
      setOpenModal(false);
    }

    if (postError) {
      MessageUtil.alertWanring(postError);
    }

    return () => {
      dispatch({ type: INSTRUCTION_POST_RESET });
    };
  }, [postSuccess, postError]);

  useEffect(() => {
    if (deleteSuccess || typeof deleteError === 'undefined') {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getInstructionList({ page: 1 }));
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: INSTRUCTION_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (location.search) {
      const queryBrand = query.value.brandId;
      const queryModel = query.value.model;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryBrand || queryModel) {
        setBrandId({ ...brandId, value: queryBrand });
        setModel({ ...model, value: queryModel });

        //儲存搜尋及頁數
        dispatch(
          getInstructionList({
            brandId: queryBrand,
            model: queryModel,
            page: queryPage,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getInstructionList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setBrandId({ label: '請選擇品牌', value: 0 });

      //初始搜尋
      dispatch(getInstructionList({ page: 1 }));
    }
  }, [location.search]);

  const toggleUpload = data => {
    setOpenModal(!openModal);
    setEditData(data);
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setModel({ label: '請選擇型號', value: '' });
    setBrandId({ label: '請選擇品牌', value: 0 });
    dispatch(
      getInstructionList({
        brandId: 0,
        model: '',
        page: 1,
      }),
    );
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(
      getInstructionList({
        brandId: brandId.value,
        model: model.value,
        page: 1,
      }),
    );
    history.push(
      `${location.pathname}?brandId=${brandId.value}&model=${
        model.value
      }&page=${1}`,
    );
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getInstructionList({ page }));
    if (brandId.value || model.value) {
      history.push(
        `${location.pathname}?brandId=${brandId.value}&model=${model.value}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleSubmit = values => {
    let formData = new FormData();

    formData.append('brandId', editData.brandId);
    formData.append('model', editData.model);
    formData.append('manual_files', values.PDF_files[0]);

    dispatch(postInstruction(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      current_PDF_files: Yup.array().min(1, '需上傳檔案'),
    });
    return schema;
  };

  const downloadHandler = data => {
    fetch(data.fileUrl)
      .then(res => res.blob())
      .then(blob => {
        FileSaver.saveAs(
          blob,
          `${data.brandName}-${data.model}-Instruction.pdf`,
        );
      });
  };

  const getDataFromSetting = data => {
    return [
      {
        id: CommonUtil.getNewID(),
        name: `${data.brandName}-${data.model}-Instruction.pdf`,
        url: data.fileUrl,
      },
    ];
  };

  const deleteHandler = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteInstruction(id));
    });
  };

  useEffect(() => {
    if (instructionListInfo) {
      if (JSON.stringify(instructionListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: instructionListInfo });
      }
    }
  }, [instructionListInfo]);

  return (
    <Layout
      pageTitle="產品使用手冊"
      items={[
        { isActive: false, page_name: '客服中心' },
        { isActive: true, page_name: '產品使用手冊' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">品牌</label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className={style['form-select']}
                  options={
                    brandOptions && [
                      { label: '請選擇品牌', value: 0 },
                      ...brandOptions,
                    ]
                  }
                  value={brandId}
                  onChange={option => setBrandId(option)}
                  style={{ margin: 0 }}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">型號</label>
                <Select
                  isLoading={modelOptionsLoading}
                  isDisabled={modelOptionsLoading}
                  className={style['form-select']}
                  options={
                    modelOptions && [
                      { label: '請選擇型號', value: '' },
                      ...modelOptions,
                    ]
                  }
                  value={model}
                  onChange={option => setModel(option)}
                  style={{ margin: 0 }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {' '}
            {!loading && instructionListInfo && instructionListInfo.total_count
              ? instructionListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>品牌</th>
                <th>型號</th>
                <th>說明書</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {instructionListInfo &&
                instructionListInfo.datas?.length > 0 ? (
                  instructionListInfo.datas.map((data, index) => (
                    <tr key={index}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index +
                          1 +
                          (current_page - 1) * instructionListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.brandName}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.model}</td>
                      <td>
                        {/*點擊icon，可以下載說明書，若沒上傳，不出現icon*/}
                        {data.fileUrl && (
                          <div className="d-flex">
                            <button
                              className="btn btn-link fs-24"
                              onClick={() => downloadHandler(data)}
                            >
                              <AiOutlineFileText color="black" size={25} />
                            </button>
                            <button
                              className="btn btn-link fs-24"
                              onClick={() => deleteHandler(data.instructionId)}
                            >
                              <MdDelete color="red" size={25} />
                            </button>
                          </div>
                        )}
                      </td>
                      <td>
                        <div>
                          <span className="d-inline-block me-2">
                            {data.updateTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.updater}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => toggleUpload(data)}
                        >
                          說明書上傳
                        </Button>
                        <Link
                          to={`/PrdInstructionsFAQ/${data.brandId}/${data.model}`}
                          className="m-2 btn btn-outline-success btn-sm"
                        >
                          常見問題
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>

        {!loading &&
          instructionListInfo &&
          instructionListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={instructionListInfo.current_page}
                defaultPageSize={instructionListInfo.page_size}
                total={instructionListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={openModal}
        toggle={toggleUpload}
        backdrop="static"
      >
        <ModalHeader className={style.modal_head} toggle={toggleUpload}>
          {`${editData.brandName} - ${editData.model}`} 使用手冊
        </ModalHeader>
        <Formik
          enableReinitialize
          initialValues={{
            PDF_files: [],
            current_PDF_files: [],
            init_PDF_files:
              editData && editData.fileUrl ? getDataFromSetting(editData) : [],
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required" style={{ width: '150px' }}>
                    上傳使用手冊
                  </label>
                  <div className="form-item">
                    <UploadFileBox
                      setFormFieldValue={props.setFieldValue}
                      formFieldName="PDF_files"
                      currentFileFieldName="current_PDF_files"
                      uplodModel="pdf"
                      initFiles={props.values.init_PDF_files}
                    />
                    <ErrorMessage
                      name="current_PDF_files"
                      component="span"
                      className="form-error"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className={style.modal_footer}>
                <Button
                  color="link"
                  className={`${style.modal_button_cancel} ms-auto`}
                  onClick={toggleUpload}
                >
                  取消
                </Button>{' '}
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={postLoading}
                >
                  {postLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
