import Layout from 'components/Layout';
import { ErrorMessage, Field, Formik } from 'formik';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Card, Form } from 'reactstrap';
import style from 'styles/layout.module.css';
import * as Yup from 'yup';


import {
  getCafeMenuSettings,
  updateCafeMenuSettings,
} from '../redux/actions/data/uuCafeMenuSettingsAction';

const UUCafeMenuSettings = () => {
  const dispatch = useDispatch();
  const isSubmit = useRef(false);
  const { loading, success, error, detail } = useSelector(
    state => state.cafeMenuSettings,
  );

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      bookingUrl: Yup.string().required('必填!'),
      membercentreUrl: Yup.string().required('必填!'),
      shopUrl: Yup.string().required('必填!'),
      shoppingcartUrl: Yup.string().required('必填!'),
    });
    return schema;
  };

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        dispatch(updateCafeMenuSettings(props.values));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getCafeMenuSettings());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        toast.success('修改成功');
        dispatch(getCafeMenuSettings());
      }
      if (error) {
        toast.warn('修改失敗');
      }
      isSubmit.current = false;
    }
  }, [loading, success, error]);

  return (
    <Layout
      pageTitle="選單連結設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: '選單連結' },
      ]}
    >
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        {detail && (
          <Formik
            enableReinitialize
            initialValues={{
              bookingUrl: detail.bookingUrl || '',
              membercentreUrl: detail.membercentreUrl || '',
              shopUrl: detail.shopUrl || '',
              shoppingcartUrl: detail.shoppingcartUrl || '',
            }}
            validationSchema={getValidationSchema}
          >
            {props => (
              <Form className="form-list form-label-140">
                <div className="form-group mb-3 mb-3">
                  <label>*線上商城連結</label>
                  <div className="form-item">
                    <Field name="shopUrl" className="form-control" />
                    <ErrorMessage
                      name="shopUrl"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className="form-group mb-3 mb-3">
                  <label>*訂位服務連結</label>
                  <div className="form-item">
                    <Field name="bookingUrl" className="form-control" />
                    <ErrorMessage
                      name="bookingUrl"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className="form-group mb-3 mb-3">
                  <label>*購物車連結</label>
                  <div className="form-item">
                    <Field name="shoppingcartUrl" className="form-control" />
                    <ErrorMessage
                      name="shoppingcartUrl"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div className="form-group mb-3 mb-3">
                  <label>*會員中心連結</label>
                  <div className="form-item">
                    <Field name="membercentreUrl" className="form-control" />
                    <ErrorMessage
                      name="membercentreUrl"
                      component="div"
                      className="form-error"
                    />
                  </div>
                </div>
                <div style={{ minHeight: '200px' }}></div>
                <p>
                  {detail.updateDateTime} {detail.updater}
                </p>
                <hr />
                <div className="text-center">
                  <Button
                    type="button"
                    className={style.modal_button_submit}
                    disabled={!(props.isValid && props.dirty)}
                    onClick={() => {
                      handleSubmit(props);
                    }}
                  >
                    {loading && (
                      <span
                        className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: '1rem' }}
                      ></span>
                    )}
                    <span className="btn-wrapper--label">儲存</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Card>
    </Layout>
  );
};

export default UUCafeMenuSettings;
