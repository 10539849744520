import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateServiceRemark } from 'redux/actions/data/serviceActions';
import { UPDATE_SERVICE_REMARK_IDLE } from 'redux/constants/data/serviceConstants';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';

const ServiceRemarkModal = ({ isOpen, toggle, info, onRefresh }) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    state => state.updateServiceRemark,
  );
  const { values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      csRemark: info.remark,
      id: info.id,
    },
    onSubmit: () => {
      dispatch(updateServiceRemark(values));
    },
  });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功');
      onRefresh && onRefresh();
      toggle();
      dispatch({ type: UPDATE_SERVICE_REMARK_IDLE });
    }
    if (error) {
      MessageUtil.alertWanring(error);
      dispatch({ type: UPDATE_SERVICE_REMARK_IDLE });
    }
  }, [success, error]);

  return (
    <Modal zIndex={2000} size="xl" centered isOpen={isOpen}>
      <ModalHeader toggle={toggle} className={style.modal_head}>
        客服備註
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ height: '300px' }}>
        <Input
          type="textarea"
          style={{ height: '250px' }}
          value={values.csRemark || ''}
          onChange={event => {
            setFieldValue('csRemark', event.target.value);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceRemarkModal;
