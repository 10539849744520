import { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';
import { UploadCloud } from 'react-feather';
import { FaTimes } from 'react-icons/fa';
import { Button } from 'reactstrap';
import style from 'styles/layout.module.css';

import CommonUtil from '../utils/CommonUtil';

const UploadFiles = ({
  displayAcceptType = 'image/jpeg,image/png,image/gif',
  displayFileWidth = 800,
  displayFileHeight = 1024,
  displayFileSize = '1MB',
  defaultFiles = [],
  onChange,
}) => {
  const [error, setError] = useState('');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: displayAcceptType,
    multiple: true,
    onDrop: useCallback(
      acceptedFiles => {
        const fileByte = Number(displayFileSize.split('M')[0]) * 1024 * 1024;
        if (acceptedFiles.some(i => i.size > fileByte)) {
          setError('檔案大小不符限制');
          return;
        } else if (
          acceptedFiles.some(
            i => i.width > displayFileWidth || i.height > displayFileHeight,
          )
        ) {
          setError('檔案尺寸不符限制');
          return;
        } else {
          setError('');
        }

        const newCurrentFiles = acceptedFiles.map(file => {
          file.id = CommonUtil.getNewID();
          return {
            file,
            id: file.id,
            name: file.name,
            url: URL.createObjectURL(file),
          };
        });

        onChange && onChange([...defaultFiles, ...newCurrentFiles]);
      },
      [defaultFiles],
    ),
  });

  const handleDrag = result => {
    const { source, destination } = result;
    if (!destination) return;
    const arr = [...defaultFiles];
    const [remove] = arr.splice(source.index, 1);
    arr.splice(destination.index, 0, remove);
    onChange && onChange(arr);
  };

  return (
    <div>
      <div className="font-size-xs">
        檔案類型：{displayAcceptType}
        <br />
        {displayFileWidth && displayFileHeight && (
          <span>
            檔案尺寸：(寬){displayFileWidth}
            {displayFileWidth === '不限' ? '' : 'px'} x (高){displayFileHeight}
            {displayFileHeight === '不限' ? '' : 'px'}
          </span>
        )}
        <br />
        {displayFileSize && <span>檔案大小：{displayFileSize}</span>}
      </div>
      <div className="dropzone-box">
        <div className="dropzone w200">
          <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
            <input {...getInputProps()} />
            <div className="dropzone-inner-wrapper">
              <div className={isDragActive ? 'drag_active' : ''}>
                <div className="dropzone-icon">
                  <UploadCloud size={50} />
                </div>
                <div className="font-size-sm">拖拉檔案到這邊 </div>
              </div>
              <small className="py-2 text-black-50">or</small>
              <div>
                <Button
                  type="button"
                  color="primary"
                  className={style.upload_button}
                >
                  <span className="px-2">選擇檔案</span>
                </Button>
              </div>
            </div>
          </div>
          <span style={{ color: 'red' }}>{error && error}</span>
        </div>
        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="id">
            {p => (
              <div
                className="d-flex flex-wrap gap-4"
                ref={p.innerRef}
                {...p.droppableProps}
              >
                {defaultFiles.map((i, index) => (
                  <Draggable key={i.id} draggableId={i.id} index={index} direction="horizontal">
                    {(provided , snapshot) => (
                      <div
                        className={
                          snapshot.isDragging
                              ? 'dragging position-relative p-2 thumbs-img'
                              : 'position-relative p-2 thumbs-img'
                        }
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          maxWidth: '200px',
                          ...provided.draggableProps.style,
                        }}
                      >
                        <img
                          className="img-fluid img-fit-container rounded-sm"
                          src={i.url}
                          alt={i.name}
                        />
                        <button
                          type="button"
                          className="ic-img-delete"
                          onClick={() => {
                            onChange &&
                              onChange(defaultFiles.filter(j => j.id !== i.id));
                          }}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {p.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default UploadFiles;
