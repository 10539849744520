import React, { memo } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import style from '../../styles/layout.module.css';

const MaintenanceProcessModal = ({
  historyModal = false,
  toggleHistoryModal = () => {},
  info = {
    repairProgress: [],
  },
}) => {
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={historyModal}
      toggle={toggleHistoryModal}
      backdrop="static"
    >
      <ModalHeader toggle={toggleHistoryModal} className={style.modal_head}>
        維修單歷程
      </ModalHeader>
      <ModalBody
        className={`form-list form-label-140 mb-5 ${style.modal_body}`}
      >
        {info?.repairProgress.map((data, index) => (
          <div className="mb-3" key={index}>
            <small>{data.date}</small>
            <h6>{data.status}</h6>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default memo(MaintenanceProcessModal);
