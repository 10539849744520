import Layout from 'components/Layout';
import { useFormik } from 'formik';
import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Input,
    Row,
} from 'reactstrap';

import PromoListTable from '../components/promoList/PromoListTable';
import SearchPromoListForm from '../components/promoList/SearchPromoListForm';
import { getPromoList } from '../redux/actions/data/promoActions';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const PromoList = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { list } = useSelector(state => state.promoList);

    const { values, handleReset, setFieldValue, handleSubmit, setValues } =
        useFormik({
            enableReinitialize: true,
            initialValues: {
                endTime: '',
                keyword: '',
                page: 1,
                page_size: 50,
                productRange: '', // 適用商品
                startTime: '',
                status: [], // 活動狀態
                timeType: '全部',
            },
            onReset: () => {
                dispatch(
                    getPromoList({
                        endTime: '',
                        keyword: '',
                        page: 1,
                        page_size: 50,
                        productRange: '', // 適用商品
                        startTime: '',
                        status: [], // 活動狀態
                        timeType: '全部',
                    }),
                );
            },
            onSubmit: () => {
                const body = {
                    ...values,
                    endTime: values.endTime
                        ? moment(values.endTime).format('YYYY/MM/DD')
                        : moment().format('yyyy/MM/DD HH:mm:ss'),
                    startTime: values.startTime
                        ? moment(values.startTime).format('YYYY/MM/DD')
                        : moment().subtract(7, 'day').format('yyyy/MM/DD HH:mm:ss'),
                };

                const params = new URLSearchParams({
                    ...body,
                    endTime: JSON.stringify(body.endTime),
                    startTime: JSON.stringify(body.startTime),
                    status: JSON.stringify(body.status),
                });
                history.push(`${location.pathname}?${params.toString()}&v=${new Date().getTime()}`);
            },
        });

    useEffect(() => {
        if (location.search) {
            const urlParams = new URLSearchParams(location.search);
            const params = Object.fromEntries(urlParams);
            const body = {
                ...params,
                endTime: params.endTime
                    ? JSON.parse(params.endTime)
                    : moment().format('yyyy/MM/DD HH:mm:ss'),
                keyword: params?.keyword || '',
                page: ~~params?.page || 1,
                page_size: ~~params?.page_size || 50,
                productRange: params?.productRange || '',
                startTime: params.startTime
                    ? JSON.parse(params.startTime)
                    : moment().subtract(7, 'days').format('yyyy/MM/DD HH:mm:ss'),
                status: params?.status ? JSON.parse(params.status) : [],
                timeType: params?.timeType || '全部',
            };
            dispatch(getPromoList(body));
            setValues(body);
        } else {
            history.push(`${location.pathname}?page=1`);
        }
    }, [location.search]);

    const searchHandler = useCallback(() => {
        setFieldValue('page', 1);
        handleSubmit();
    }, []);

    const pageChangeHandler = useCallback(page => {
        setFieldValue('page', page);
        handleSubmit();
    }, []);

    return (
        <Layout
            pageTitle="優惠碼"
            items={[
                { isActive: true, page_name: '行銷活動' },
                { isActive: false, page_name: '優惠碼' },
            ]}
        >
            <Card className={style.search_card}>
                <CardHeader
                    className={style.card_header}
                    style={{ background: 'white' }}
                >
                    搜尋條件
                </CardHeader>
                <CardBody className={style.card_body}>
                    <SearchPromoListForm values={values} setFieldValue={setFieldValue} />
                </CardBody>
                <CardFooter className="text-end border-0">
                    <Button
                        className={style.modal_button_cancel}
                        onClick={() => {
                            handleReset();
                        }}
                    >
                        取消
                    </Button>
                    <Button className={style.modal_button_submit} onClick={searchHandler}>
                        搜尋
                    </Button>
                </CardFooter>
            </Card>
            <Card className={style.card}>
                <Row className="mb-2">
                    <div className="col-md-6">
                        <Button
                            color="success"
                            onClick={() => {
                                history.push('/PromoSetting/add');
                            }}
                        >
                            新增優惠碼
                        </Button>
                    </div>
                    <div className="d-flex justify-content-end col-md-6 align-items-center">
                        每頁
                        <Input
                            type="select"
                            className="mx-2"
                            style={{ width: '80px' }}
                            onChange={event => {
                                setFieldValue('page', 1);
                                setFieldValue('page_size', ~~event.target.value);
                                handleSubmit();
                            }}
                            value={values.page_size}
                        >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                        </Input>
                        {`筆，共${list.total_count || 0}筆`}
                    </div>
                </Row>
                <PromoListTable />
                <div className="d-flex align-items-center justify-content-center">
                    <RcPagination
                        current={list?.current_page || 1}
                        pageSize={list?.page_size || 10}
                        total={list?.total_count || 0}
                        onChange={pageChangeHandler}
                    />
                </div>
            </Card>
        </Layout>
    );
};

export default PromoList;
