import Layout from 'components/Layout';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table } from 'reactstrap';


import Loader from '../components/Loader';
import { getCafeSeoSettingsList } from '../redux/actions/data/uuCafeSEOSettingsAction';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';

const SEOSettings = () => {
  const dispatch = useDispatch();

  const { loading, success, list } = useSelector(
    state => state.cafeSEOSettingsList,
  );

  useEffect(() => {
    dispatch(getCafeSeoSettingsList());
  }, []);

  return (
    <Layout
      pageTitle="SEO設定"
      items={[
        { isActive: true, page_name: 'UUCafe' },
        { isActive: true, page_name: 'SEO設定' },
      ]}
    >
      <Card className={`${style.search_card} ${style['seo-card-layout']}`}>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>頁面</th>
                <th>商品標題(Title)</th>
                <th>商品關鍵字(Keywords)</th>
                <th>商品描述(Description)</th>
                <th>異動時間</th>
                <th>功能</th>
              </tr>
            </thead>
            <tbody className={style['padding']}>
              {!loading &&
                success &&
                list.map((page, index) => (
                  <tr key={index}>
                    <td>{page.typeName}</td>
                    <td>{page.title || ''}</td>
                    <td>{page.keyword || ''}</td>
                    <td>{page.desc || ''}</td>
                    <td>
                      <div className="text-nowrap">
                        {page.updateDateTime || ''}
                      </div>
                      <div>{page.updater || ''}</div>
                    </td>
                    <td>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          key={index}
                          className="m-2 btn btn-outline-primary btn-sm text-primary"
                          style={{
                            backgroundColor: 'transparent',
                            maxWidth: '80px',
                            width: '90%',
                          }}
                          onClick={() => {
                            history.push(`/UUCafe/SEOSettings/${page.id}`);
                          }}
                        >
                          編輯
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {loading && <Loader />}
        </div>
      </Card>
    </Layout>
  );
};

export default SEOSettings;
