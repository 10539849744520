import {
  DISTRIBUTOR_GET_FAIL,
  DISTRIBUTOR_GET_REQUEST,
  DISTRIBUTOR_GET_SUCCESS,
  DISTRIBUTOR_MILESTONE_LIST_GET_FAIL,
  DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST,
  DISTRIBUTOR_MILESTONE_LIST_GET_SUCCESS,
} from '../../constants/data/distributorConstants';

export const initValue = {
  content: '',
  contentPcImg: '',
  contentPcImgLink: '',
  contentPhoneImg: '',
  contentPhoneImgLink: '',
  content_en: '',
  createTime: null,
  creator: null,
  imageryPcImg: '',
  imageryPcImgLink: '',
  imageryPhoneImg: '',
  imageryPhoneImgLink: '',
  initContentPcImgFiles: [],
  initContentPhoneImgFiles: [],
  initImageryPcImgFiles: [],
  initImageryPhoneImgFiles: [],
  title: '',
  title_en: '',
  updateTime: null,
  updater: null,
  url: '',
};

export const initMilestone = {
  content: '',
  content_en: '',
  id: 0,
  year: '',
};
export const distributorReducer = (
  state = { detail: initValue, error: null, loading: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DISTRIBUTOR_GET_REQUEST:
      return { detail: null, error: null, loading: true };
    case DISTRIBUTOR_GET_SUCCESS:
      return { detail: payload, error: null, loading: false };
    case DISTRIBUTOR_GET_FAIL:
      return { detail: initValue, error: payload, loading: false };
    default:
      return state;
  }
};

export const distributorMileStoneListReducer = (
  state = {
    error: null,
    list: {
      current_page: 1,
      datas: [],
      page_size: 10,
      total_count: 6,
      total_page: 1,
    },
    loading: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DISTRIBUTOR_MILESTONE_LIST_GET_REQUEST:
      return { error: null, list: {}, loading: true };
    case DISTRIBUTOR_MILESTONE_LIST_GET_SUCCESS:
      return { error: null, list: payload, loading: false };
    case DISTRIBUTOR_MILESTONE_LIST_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};
