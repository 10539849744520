import {
  ORDER_INVOICE_LIST_REQUEST,
  ORDER_INVOICE_LIST_SUCCESS,
  ORDER_INVOICE_LIST_FAIL,
  ORDER_INVOICE_DETAIL_IDLE,
  ORDER_INVOICE_DETAIL_REQUEST,
  ORDER_INVOICE_DETAIL_SUCCESS,
  ORDER_INVOICE_DETAIL_FAIL,
} from 'redux/constants/data/orderInvoiceConstants';

export const orderInvoiceListReducer = (
  state = { loading: false, orderInvoiceListInfo: {} },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_INVOICE_LIST_REQUEST:
      return { ...state, loading: true };
    case ORDER_INVOICE_LIST_SUCCESS:
      return { loading: false, orderInvoiceListInfo: payload || {} };
    case ORDER_INVOICE_LIST_FAIL:
      return { error: payload, loading: false, orderInvoiceListInfo: {} };
    default:
      return state;
  }
};

export const orderInvoiceDetailReducer = (
  state = {
    error: null,
    loading: false,
    orderInvoiceDetailInfo: {},
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_INVOICE_DETAIL_IDLE:
      return {
        error: null,
        loading: false,
        orderInvoiceDetailInfo: {},
        success: false,
      };
    case ORDER_INVOICE_DETAIL_REQUEST:
      return { ...state, loading: true };
    case ORDER_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        orderInvoiceDetailInfo: payload,
        success: true,
      };
    case ORDER_INVOICE_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
