import { useFormik } from 'formik';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormGroup,
  Row,
  Table,
} from 'reactstrap';

import Layout from '../../components/Layout';
import { TableHasNoData, TableLoading } from '../../components/TableHasNoData';
import TableHead from '../../components/TableHead';
import { getFinanceReportDashboard } from '../../redux/actions/data/operatingReportActions';
import style from '../../styles/layout.module.css';
import history from '../../utils/createHistory';
const labelStyle = {
  minWidth: '80px',
  paddingRight: '5px',
  whiteSpace: 'nowrap',
};
const ReconciliationReport = () => {
  const location = useLocation();
  const { loading, list } = useSelector(
    state => state.getFinanceReportDashboardList,
  );
  const dispatch = useDispatch();

  const { values, setFieldValue, handleSubmit, handleReset, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        endDate: moment().startOf('month').format('YYYY/MM/DD'),
        page: 1,
        startDate: moment().format('YYYY/MM/DD'),
      },
      onReset: () => {},
      onSubmit: values => {
        const body = {
          ...values,
          endDate: values.endDate
            ? moment(values.endDate).format('YYYY/MM/DD')
            : '',
          startDate: values.startDate
            ? moment(values.startDate).format('YYYY/MM/DD')
            : '',
        };
        const params = new URLSearchParams({
          ...body,
          endDate: JSON.stringify(body.endDate),
          startDate: JSON.stringify(body.startDate),
        });
        history.push(
          `${location.pathname}?${params.toString()}&v=${new Date().getTime()}`,
        );
      },
    });

  const handlePageChange = (page = 1) => {
    setFieldValue('page', page);
    handleSubmit();
  };

  useEffect(() => {
    if (location.search) {
      const urlParams = new URLSearchParams(location.search);

      const body = {
        endDate: urlParams.get('endDate')
          ? JSON.parse(urlParams.get('endDate'))
          : moment().format('YYYY/MM/DD'),
        page: urlParams.get('page'),
        startDate: urlParams.get('startDate')
          ? JSON.parse(urlParams.get('startDate'))
          : moment().startOf('month').format('YYYY/MM/DD'),
      };
      setValues(body);
      dispatch(getFinanceReportDashboard(body));
    } else {
      history.push(`${location.pathname}?page=1`);
    }
  }, [location.search]);

  return (
    <Layout
      pageTitle="對帳-當期帳款查詢"
      items={[
        { isActive: false, page_name: '營運報表' },
        { isActive: true, page_name: '當期帳款查詢' },
      ]}
    >
      <Card className="mb-3">
        <CardHeader
          className={style.card_header}
          style={{ background: 'white' }}
        >
          搜尋條件
        </CardHeader>
        <CardBody className={style.card_body}>
          <Row className="flex-wrap">
            <FormGroup className="col-sm-12 d-inline-flex align-items-center flex-nowrap">
              <label style={labelStyle}>日期區間</label>
              <div className="flex-wrap  d-inline-flex align-items-center flex-grow-1 gap-1">
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  selected={
                    values.startDate ? moment(values.startDate).toDate() : ''
                  }
                  onChange={date => setFieldValue('startDate', date)}
                />
                <span className="mx-3">~</span>
                <DatePicker
                  className="form-control"
                  dateFormat={'yyyy/MM/dd'}
                  style={{ minWidth: '100px' }}
                  selected={
                    values.endDate ? moment(values.endDate).toDate() : ''
                  }
                  onChange={date => setFieldValue('endDate', date)}
                  minDate={
                    values.startDate ? moment(values.startDate).toDate() : ''
                  }
                />
              </div>
            </FormGroup>
          </Row>
        </CardBody>
        <CardFooter className="text-end border-0">
          <Button className={style.modal_button_cancel} onClick={handleReset}>
            取消
          </Button>
          <Button
            className={style.modal_button_submit}
            onClick={() => {
              handlePageChange(1);
            }}
          >
            搜尋
          </Button>
        </CardFooter>
      </Card>
      <div className="pt-4 pb-2 d-flex justify-content-end">
        <div className="text-end">
          <h5>本日期區間帳款金額</h5>
          <ul
            className="text-secondary"
            style={{ marginLeft: 'auto', width: 'auto' }}
          >
            <li className="text-secondary">
              <small className="text-secondary">不含退貨、取消的訂單金額</small>
            </li>
          </ul>
        </div>
        <div className="px-3 text-danger">
          NT$ {Number(list?.actualAmount || 0).toLocaleString()}
        </div>
      </div>
      <Card>
        <CardBody className={style.card_body}>
          <div className="table-responsive">
            <Table hover striped bordered>
              <TableHead>
                <tr>
                  <th>
                    <div className="text-nowrap">類別</div>
                  </th>
                  <th>
                    <div className="text-nowrap">統計項目</div>
                  </th>
                  <th>
                    <div className="text-nowrap">主單筆數</div>
                  </th>
                  <th>
                    <div className="text-nowrap">主單金額</div>
                  </th>
                </tr>
              </TableHead>
              {loading && <TableLoading />}
              {!loading && (
                <tbody>
                  {list?.list?.length > 0 ? (
                    list?.list?.map(data => {
                      return (
                        <tr key={JSON.stringify(data)}>
                          <td className="text-start" align="center">
                            {/* 類別 */}
                            <div className="text-center">
                              {data?.type || '--'}
                            </div>
                          </td>
                          <td className="text-start">
                            {/* 統計項目 */}
                            <div>{data.title || '--'}</div>
                          </td>
                          <td className="text-center">
                            {/* 主單筆數 */}
                            <div className="text-nowrap">
                              {data?.pieces || '0'}
                            </div>
                          </td>
                          <td className="text-center">
                            {/* 主單金額 */}
                            <div className="text-nowrap">
                              NT$ {Number(data?.amount || '0').toLocaleString()}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <TableHasNoData />
                  )}
                </tbody>
              )}
            </Table>
          </div>
          <p className="text-end fs-14">
            金額總計: NT$ {Number(list?.actualAmount || 0).toLocaleString()}
          </p>
        </CardBody>
      </Card>
    </Layout>
  );
};

export default ReconciliationReport;
