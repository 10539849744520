import {
  CART_REQUEST,
  CART_SUCCESS,
  CART_FAIL,
  CART_UPDATE_SUCCESS,
  PURCHASE_NOTE_REQUEST,
  PURCHASE_NOTE_SUCCESS,
  PURCHASE_NOTE_FAIL,
  PURCHASE_NOTE_UPDATE_REQUEST,
  PURCHASE_NOTE_UPDATE_SUCCESS,
  PURCHASE_NOTE_UPDATE_FAIL,
} from 'redux/constants/data/cartConstants';
import { useRequest } from 'utils/useFetch';

export const getCart = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: CART_FAIL,
      method: 'get',
      requestConstant: CART_REQUEST,
      successConstant: CART_SUCCESS,
      url: '/Backend/CartSetting/getCartSettingInfo',
    });
  };
};
export const updateCart = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: CART_FAIL,
      method: 'post',
      requestConstant: CART_REQUEST,
      successConstant: CART_UPDATE_SUCCESS,
      url: '/Backend/CartSetting/updateCartSettingInfo',
    });
  };
};

export const getPurchaseNote = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: PURCHASE_NOTE_FAIL,
      method: 'get',
      requestConstant: PURCHASE_NOTE_REQUEST,
      successConstant: PURCHASE_NOTE_SUCCESS,
      url: '/Backend/CartSetting/getPurchaseNote',
    });
  };
};
export const updatePurchaseNote = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: PURCHASE_NOTE_UPDATE_FAIL,
      method: 'post',
      requestConstant: PURCHASE_NOTE_UPDATE_REQUEST,
      successConstant: PURCHASE_NOTE_UPDATE_SUCCESS,
      url: '/Backend/CartSetting/updatePurchaseNote',
    });
  };
};
