import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { updateMemberLevel } from 'redux/actions/data/memberActions';
import { UPDATE_MEMBER_LEVEL_IDLE } from 'redux/constants/data/memberConstants';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';

const UpdateRewardsPointsModal = ({
  isOpen,
  toggle,
  info,
  lastName,
  firstName,
  id,
  phone,
}) => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector(
    state => state.updateMemberLevel,
  );
  const { values, handleSubmit, setFieldValue, handleChange, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        accumulateAmount: info.accumulateAmount,
        accumulateDateEnd: info.accumulateDateEnd,
        accumulateDateStart: info.accumulateDateStart,
        action: '新增',
        firstName: firstName,
        lastName: lastName,
        memberDateEnd: info.memberDateEnd,
        memberDateStart: info.memberDateStart,
        memberId: id,
        memberLevelSettingId: info.memberLevelSettingId,
        memberPhone: phone,
        points: 0,
        remark: '',
      },
      onSubmit: () => {
        const body = {
          accumulateAmount: values.accumulateAmount,
          accumulateDateEnd: values.accumulateDateEnd,
          accumulateDateStart: values.accumulateDateStart,
          firstName: values.firstName,
          lastName: values.lastName,
          memberDateEnd: values.memberDateEnd,
          memberDateStart: values.memberDateStart,
          memberId: values.memberId,
          memberLevelSettingId: values.memberLevelSettingId,
          memberPhone: values.memberPhone,
          pointChangeInfo: {
            point: values.points,
            remark: values.remark,
          },
        };

        if (values.action === '減少') {
          body.pointChangeInfo.point = ~body.pointChangeInfo.point + 1;
        }

        dispatch(updateMemberLevel(body));
      },
    });

  useEffect(() => {
    if (success) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_MEMBER_LEVEL_IDLE });
      toggle();
    }

    if (error) {
      MessageUtil.alertWanring(error);
      dispatch({ type: UPDATE_MEMBER_LEVEL_IDLE });
    }
  }, [success, error]);

  return (
    <Modal isOpen={isOpen} centered size="xl" zIndex={2000}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        點數異動
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={style.formGroup}>
          <label className="required" style={{ width: '120px' }}>
            點數異動
          </label>
          <Input
            type="select"
            className={style['form-select-short']}
            style={{ margin: 0 }}
            value={values.action}
            id="action"
            onChange={e => {
              setFieldValue('action', e.target.value);
            }}
          >
            <option value="新增">新增</option>
            <option value="減少">減少</option>
          </Input>
          <Input
            className={style['form-input']}
            value={values.points}
            onChange={event => {
              setFieldValue('points', event.target.value);
            }}
          />
          <span className="ml-2">點</span>
        </div>
        <div className={style.formGroup}>
          <label style={{ width: '120px' }}>備註</label>
          <Input
            type="textarea"
            id="remark"
            onChange={handleChange}
            value={values.remark}
            style={{ height: '200px', maxWidth: '600px' }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateRewardsPointsModal;
