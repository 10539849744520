import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { AiFillDashboard, AiFillFile, AiOutlineShopping } from 'react-icons/ai';
import {
  FaGlobe,
  FaHeadset,
  FaQuestion,
  FaRegNewspaper,
  FaSignOutAlt,
  FaStore,
  FaTasks,
  FaTicketAlt,
  FaTools,
  FaTruck,
  FaUserAlt,
  FaUserEdit,
  FaUserFriends,
  FaUserPlus,
  FaUserShield,
} from 'react-icons/fa';
import { FiBriefcase, FiChevronRight, FiHeart } from 'react-icons/fi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { getUnreadMessageCount } from '../redux/actions/data/messageActions';
import { userLogout } from '../redux/actions/data/userActions';
import { getWarrantyRegisterNumber } from '../redux/actions/data/warrantyActions';
import style from '../styles/sideBar.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

const MenuItem = ({ isOpen, children, name, icon, toggleOpen, to = '/' }) => {
  const isDropdown = to === '/';
  const location = useLocation();
  const [highLight, setHighLight] = useState(false);

  useEffect(() => {
    setHighLight(!isDropdown && location.pathname === to);
  }, [location.pathname]);

  const handleClick = e => {
    if (!isDropdown) return;
    e.preventDefault();
    toggleOpen();
  };

  return (
    <li
      onClick={isDropdown ? () => null : toggleOpen}
      style={{
        backgroundColor: `${isOpen || highLight ? 'rgba(0, 0, 0, 0.1)' : ''}`,
      }}
    >
      <Link
        to={to}
        onClick={handleClick}
        className={`${clsx({ active: isOpen })} ${style.navigation_item}`}
        style={{
          color: `${
            isOpen || highLight ? 'white' : 'rgba(255, 255, 255, 0.45)'
          }`,
        }}
      >
        <span className={style.navigationTitle}>
          {icon}
          <span className={style.navText}>{name}</span>
        </span>
        {isDropdown && (
          <span
            className={style.arrow}
            style={{
              transform: `${isOpen ? 'rotate(-90deg)' : 'rotate(0deg)'}`,
            }}
          >
            <FiChevronRight />
          </span>
        )}
      </Link>
      {children}
    </li>
  );
};
const SubMenu = ({ menus, isOpen }) => {
  return (
    <div className={style.collapse}>
      <Collapse isOpen={isOpen}>
        <ul className={style.collapse}>
          {menus
            .filter(i => {
              return (
                !i.hidden &&
                ((process.env.REACT_APP_ENV !== 'development' && !i.develop) ||
                  process.env.REACT_APP_ENV === 'development')
              );
            })
            .map(i => (
              <li key={i.name}>
                <NavLink
                  to={i.to}
                  style={{
                    color: `${isOpen ? 'white' : 'rgba(255, 255, 255, 0.45)'}`,
                  }}
                  className={style.navigation_item}
                >
                  {i.name}
                  {i.badge && (
                    <span className="badge rounded-pill bg-danger">
                      {i.badge}
                    </span>
                  )}
                </NavLink>
              </li>
            ))}
        </ul>
      </Collapse>
    </div>
  );
};

const SideBarMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sidebarToggleMobile } = useSelector(state => state.sideBar);
  const { warrantyRegisterNumberInfo } = useSelector(
    state => state.warrantyNumber,
  );

  const { unReadCount } = useSelector(state => state.unReadMessageCount);

  useEffect(() => {
    dispatch(getUnreadMessageCount());
  }, [location.pathname]);

  const MENUES = [
    {
      icon: <AiFillDashboard />,
      id: 'overall',
      name: '總覽',
      to: '/Overall',
      unique: 28,
    },
    {
      children: [
        {
          name: '形象圖片/選單顏色設定',
          to: '/BannerSetting',
        },
        {
          name: '商城品牌形象圖設定',
          to: '/ProductBrandBannerSettings',
        },
        {
          name: '商城形象圖管理',
          to: '/ShopBannerSetting',
        },
        {
          name: '關於我們',
          to: '/About',
        },
        {
          name: '經銷服務',
          to: '/DistributionServicesSettings',
        },
        {
          name: 'SEO設定',
          to: '/SEOSettings',
        },
      ],
      icon: <FaGlobe />,
      id: 'officital',
      name: '網頁設定',
    },
    {
      icon: <FaUserAlt />,
      id: 'websiteSettings',
      name: '網站設定',
      to: '/WebsiteSettings',
      unique: 1,
    },
    {
      icon: <FiBriefcase />,
      id: 'brandControl',
      name: '品牌管理',
      to: '/BrandControl',
      unique: 2,
    },
    {
      children: [
        { name: '類別管理', to: '/CategoryControl' },
        { name: '商品管理', to: '/ProductControl', unique: 3 },
        { name: '導購連結設定', to: '/ProductLinkControl' },
        { name: '安全庫存量通知', to: '/LowestNotify' },
        { name: '隱形賣場', to: '/HideModel' },
        { name: '商品組合', to: '/GroupModel' },
        { name: '商品上架設定', to: '/ProductSellingSettings' },
        { name: '贈品管理', to: '/GiftManagement' },
      ],
      icon: <FaGlobe />,
      id: 'product',
      name: '商品管理',
    },
    {
      children: [
        { name: '你可能會喜歡', to: '/YouMayAlsoLike' },
        { name: '暢銷商品', to: '/BestSeller' },
        { name: '商品瀏覽折扣設定', to: '/YourFavorite' },
      ],
      icon: <FiHeart />,
      id: 'saleSetting',
      name: '銷售設定',
      unique: 4,
    },
    {
      children: [
        { name: '折扣活動', to: '/DiscountCompaign' },
        { name: '優惠券', to: '/VoucherList' },
        { name: '優惠碼', to: '/PromoList' },
      ],
      icon: <FaUserAlt />,
      id: 'marketingCompaign',
      name: '行銷活動',
      unique: 38,
    },
    {
      children: [
        { name: '紅利點數設定', to: '/RewardsPointsSettings' },
        {
          hidden: true,
          name: '指定活動給點設定-個人資料填寫',
          to: '/PersonalInformation',
        },
        {
          hidden: true,
          name: '指定活動給點設定-虛擬試穿戴',
          to: '/VirtualTryOn',
        },
        {
          name: '指定活動給點設定',
          to: '/SpecifyPointSettings',
        },
        {
          name: '特殊活動給點設定',
          to: '/SpecialEventPointSystem',
        },
        {
          hidden: true,
          name: '特殊活動給點設定新增',
          to: '/SpecialEventPointSystemAdd',
        },
        {
          hidden: true,
          name: '特殊活動給點設定詳細修改',
          to: '/SpecialEventPointSystemDetail',
        },
        {
          hidden: true,
          name: '指定活動給點設定-個人資料填寫',
          to: '/PersonalInformation',
        },
        {
          hidden: true,
          name: '指定活動給點設定-虛擬試穿戴',
          to: '/VirtualTryOn',
        },
      ],
      icon: <FaUserAlt />,
      id: 'rewardsPoints',
      name: '紅利點數',
      unique: 42,
    },
    {
      children: [
        { name: '折扣碼設定', to: '/CouponList' },
        { name: '品牌圖片設定', to: '/CouponBackground' },
      ],
      hidden: true,
      icon: <FaTicketAlt />,
      id: 'coupon',
      name: '折扣碼管理',
      unique: 5,
    },
    {
      children: [
        { name: '商品銷售排行榜', to: '/ProductSalesDataRanking' },
        { name: '商店銷售統計表', to: '/StoreSalesDataList' },
        { name: '訂單-即時訂單統計表', to: '/OrderStatisticalTable' },
        { name: '訂單-訂單來源', to: '/OrderSourceData' },
        { name: '訂單-訂單取消', to: '/OrderCancelStatisticalTable' },
        { name: '紅利點數統計表', to: '/RewardsPointsStatisticalTable' },
        { name: '優惠券-即時報表', to: '/VoucherRealTimeReport' },
        { name: '優惠券-績效報表', to: '/VoucherPerformanceReport' },
        { name: '優惠碼-即時報表', to: '/PromoRealTimeReport' },
        {
          name: '優惠碼-績效報表',
          to: '/PromoPerformanceReport',
        },
      ],
      icon: <FaUserAlt />,
      id: 'operating',
      name: '營運報表',
      unique: 43,
    },
    {
      children: [
        { name: '當期帳款查詢', to: '/ReconciliationReport' },
        { name: '帳務核對', to: '/ReconciliationCheck' },
      ],
      icon: <FaUserAlt />,
      id: 'reconciliation',
      name: '對帳報表',
      unique: 45,
    },

    {
      icon: <FaRegNewspaper />,
      id: 'newsControl',
      name: '新聞管理',
      to: '/NewsList',
      unique: 6,
    },
    {
      children: [
        { name: '會員權益', to: '/MembersInterests' },
        { name: '產品使用手冊', to: '/ProductInstructions' },
        { name: '保固註冊', to: '/Warranty' },
        { name: '維修服務', to: '/Maintenance' },
      ],
      icon: <FaHeadset />,
      id: 'service',
      name: '客服中心',
      unique: 7,
    },
    {
      children: [
        { name: '類別管理', to: '/FAQCategory' },
        { name: '問題管理', to: '/FAQList' },
      ],
      icon: <FaQuestion />,
      id: 'faq',
      name: '客服中心-常見問題',
      unique: 8,
    },
    {
      children: [
        { name: '服務項目', to: '/StoreType' },
        { name: '據點管理', to: '/StoreList' },
      ],
      icon: <FaStore />,
      id: 'store',
      name: '據點管理',
      unique: 9,
    },
    {
      children: [
        { name: '品牌保固序號編碼設定', to: '/WarrantyBrand' },
        { name: '產出保固序號', to: '/WarrantyNum' },
        { name: '產出產品序號', to: '/ProductNum' },
        { name: '出貨', to: '/Ship' },
        { name: '出貨New', to: '/Ship_New' },
        { name: '序號/出貨查詢', to: '/WarrantyNumSearch' },
        { hidden: true, name: '批次解除綁定', to: '/BatchUnbind' },
      ],
      icon: <FaTruck />,
      id: 'shipping',
      name: '序號綁定及出貨',
      unique: 13,
    },
    {
      children: [
        { name: '公告通知', to: '/WarrantyAnnouncementNotice' },
        { name: '表單說明文字', to: '/FormDescriptionText' },
        { name: '品牌保固期限設定', to: '/WarrantyExpiration' },
        { name: '保固註冊清單', to: '/WarrantyRegister' },
        {
          badge: warrantyRegisterNumberInfo?.notReviewedNum || undefined,
          name: '未審核',
          to: '/WarrantyRegisterUnverified',
        },
        {
          badge: warrantyRegisterNumberInfo?.reviewingNum || undefined,
          name: '審核中',
          to: '/WarrantyRegisterVerifying',
        },
      ],
      icon: <FaTasks />,
      id: 'warranty',
      name: '產品保固註冊',
      unique: 14,
    },
    {
      children: [
        { name: '公告通知', to: '/MaintenanceAnnouncementNotice' },

        { name: '維修表單設定', to: '/MaintainanceListSetting' },
        { name: '維修清單', to: '/MaintainanceList' },
      ],
      icon: <FaTools />,
      id: 'productMaintain',
      name: '產品維修服務',
      unique: 17,
    },
    {
      icon: <FaTools />,
      id: 'channelControl',
      name: '經銷通路管理',
      to: '/ChannelControl',
      unique: 16,
    },
    {
      children: [
        {
          name: '會員清單',
          to: '/MemberList',
        },
        {
          name: '會員清單-清除個資',
          to: '/ClearMemberList',
        },
        {
          name: '會員等級設定',
          to: '/MemberLevelSettings',
        },

        {
          name: '會員禮設定',
          to: '/MemberPresentSettings',
        },
      ],
      icon: <FaUserFriends />,
      id: 'memberList',
      name: '會員管理',
      unique: 15,
    },
    {
      icon: <FaUserPlus />,
      id: 'joinUs',
      name: '加入我們',
      to: '/JoinUs',
      unique: 10,
    },
    {
      children: [
        { name: '付款設定', to: '/CheckoutPayment' },
        { name: '配送設定', to: '/CheckoutDelivery' },
        { name: '便利商店退貨聯絡人設定', to: '/CheckoutReturnContact' },
        { name: '購物需知', to: '/PurchaseNote' },
        { name: '退/換貨原因設定', to: '/OrderReturnReason' },
      ],
      icon: <AiOutlineShopping />,
      id: 'checkout',
      name: '購物相關設定',
      unique: 18,
    },
    {
      children: [
        { name: '訂單確認', to: '/HomeDeliveryOrderConfirmList' },
        { name: '訂單出貨', to: '/HomeDeliveryOrderShippingList' },
        { name: '宅配連繫不到/無人簽收', to: '/HomeDeliveryOrderFailedList' },
      ],
      icon: <AiFillFile />,
      id: 'homeDeliveryOrders',
      name: '宅配訂單管理',
      unique: 20,
    },
    {
      children: [
        { name: '全家店到店出貨 - 1. 訂單配號', to: '/CreateLogistics' },
        {
          name: '全家店到店出貨 - 2. 標籤列印/出貨確認',
          to: '/CreateLogisticsShipping',
        },
        {
          name: '全家店到店出貨 - 取消配號',
          to: '/CreateLogisticsCancelLogistic',
        },
        {
          name: '全家店到店出貨 - 驗退處理',
          to: '/CreateLogisticsCancelOrder',
        },
        {
          name: '全家店到店出貨 - 出貨失敗 - 待取貨',
          to: '/CreateLogisticsFail',
        },
        { name: '7-11店到店出貨 - 1. 訂單配號', to: '/CreateLogisticsSeven' },
        {
          name: '7-11店到店出貨 - 2. 標籤列印/出貨確認',
          to: '/CreateLogisticsShippingSeven',
        },
        {
          name: '7-11店到店出貨 - 取消配號',
          to: '/CreateLogisticsCancelLogisticSeven',
        },
        {
          name: '7-11店到店出貨 - 驗退處理',
          to: '/CreateLogisticsCancelOrderSeven',
        },
        {
          name: '7-11店到店出貨 - 出貨失敗 - 待取貨',
          to: '/CreateLogisticsFailSeven',
        },
      ],
      icon: <AiFillFile />,
      id: 'logisticsOrders',
      name: '超商店到店訂單管理',
      unique: 21,
    },
    {
      children: [
        {
          name: '門市自取 - 1. 訂單確認',
          to: '/StoreOrders',
        },
        {
          name: '門市自取 - 2. 訂單出貨',
          to: '/StoreOrdersShipping',
        },
        {
          name: '門市自取 - 3. 貨到門市確認',
          to: '/StoreOrdersArrived',
        },
        {
          name: '門市自取 - 4. 消費者取貨確認',
          to: '/StoreOrdersPickedUp',
        },
        {
          name: '門市自取. 消費者未取貨確認',
          to: '/StoreOrdersCancel',
        },
      ],
      icon: <AiFillFile />,
      id: 'storeOrders',
      name: '門市自取訂單管理',
      unique: 22,
    },
    {
      icon: <AiFillFile />,
      id: 'orders',
      name: '所有訂單查詢',
      to: '/OrderList',
      unique: 19,
    },
    {
      children: [
        {
          name: '新增取消單',
          to: '/CancelOrderCreate',
        },
        {
          name: '取消訂單查詢',
          to: '/CancelOrderList',
        },
      ],
      icon: <AiFillFile />,
      id: 'cancelOrders',
      name: '取消訂單管理',
      unique: 23,
    },
    {
      children: [
        {
          name: '退貨待確認-setp1',
          to: '/OrderReturnStep1',
        },
        {
          name: '退貨取件中-setp2',
          to: '/OrderReturnStep2',
        },
        {
          name: '退貨處理中-setp3',
          to: '/OrderReturnStep3',
        },
        {
          name: '退貨訂單查詢',
          to: '/OrderReturnList',
        },
        {
          name: '補收單查詢',
          to: '/OrderReturnRepayList',
        },
        {
          name: '運費退款單查詢',
          to: '/OrderReturnDeliveryList',
        },
        {
          name: '新增退貨單',
          to: '/OrderReturnCreate',
        },
      ],
      icon: <AiFillFile />,
      id: 'orderReturn',
      name: '退貨訂單管理',
      unique: 24,
    },
    {
      children: [
        {
          name: '換貨待確認-setp1',
          to: '/OrderChangeStep1',
        },
        {
          name: '換貨取件中-setp2',
          to: '/OrderChangeStep2',
        },
        {
          name: '換貨處理中-setp3',
          to: '/OrderChangeStep3',
        },
        {
          name: '換貨訂單查詢',
          to: '/OrderChangeList',
        },
        {
          name: '新增換貨單',
          to: '/OrderChangeCreate',
        },
      ],
      icon: <AiFillFile />,
      id: 'orderChange',
      name: '換貨訂單管理',
      unique: 25,
    },
    {
      children: [
        { name: 'ATM轉帳-未退款訂單', to: '/OrderReturnPayATMYet' },
        { name: '退款訂單查詢', to: '/OrderReturnPayList' },
        { name: '退款失敗', to: '/OrderReturnPayFail' },
      ],
      icon: <AiFillFile />,
      id: 'orderReturnPay',
      name: '退款訂單管理',
      unique: 26,
    },
    {
      icon: <AiFillFile />,
      id: 'orderInvoice',
      name: '電子發票開立紀錄',
      to: '/OrderInvoiceList',
      unique: 27,
    },
    {
      children: [
        { name: '商品頁瀏覽', to: '/LogRecord' },
        { name: '商品頁導購外連', to: '/LogRecordGuideLink' },
        { name: '試穿戴商品', to: '/LogRecordWearableProduct' },
        { name: '試穿戴結果下載', to: '/LogRecordWearableProductDownload' },
        { name: '試穿戴結果分享', to: '/LogRecordWearableProductShare' },
        {
          name: '試穿戴結果現場體驗',
          to: '/LogRecordWearableProductExperience',
        },
      ],
      icon: <FaUserAlt />,
      id: 'logRecord',
      name: 'log記錄',
      unique: 29,
    },
    {
      develop: true,
      icon: <FaUserAlt />,
      id: 'mailRecord',
      name: '信件寄送記錄',
      to: '/MailRecords',
      unique: 37,
    },
    {
      children: [{ name: '商品上架設定', to: '/ProductSellingSettings' }],
      icon: <FaUserAlt />,
      id: 'serviceManagement',
      name: '客服管理',
      unique: 35,
    },
    {
      children: [
        {
          badge: unReadCount || undefined,
          name: '訊息紀錄',
          to: '/MessageList',
        },
        { name: '自動回覆訊息', to: '/AutoReply' },
      ],
      icon: <FaUserAlt />,
      id: 'messageManagment',
      name: '訊息管理',
    },
    {
      icon: <FaUserAlt />,
      id: 'blockList',
      name: '交易黑名單管理',
      to: 'BlockList',
      unique: 44,
    },
    {
      children: [
        { name: 'SEO設定', to: '/UUCafe/SEOSettings', unique: 45 },
        { name: '選單連結設定', to: '/UUCafe/MenuSettings', unique: 46 },
        { name: '首頁-形象圖片設定', to: '/UUCafe/BannerSettings', unique: 47 },
        { name: '首頁-最新動態', to: '/UUCafe/MiddleHomeSettings', unique: 48 },
        {
          name: '首頁-頁尾圖圖片設定',
          to: '/UUCafe/BottomHomeSettings',
          unique: 49,
        },
        { name: '最新消息', to: '/UUCafe/News', unique: 50 },
        { name: '咖啡世界', to: '/UUCafe/CafeWorld', unique: 51 },
        {
          name: '門市查詢-服務項目設定',
          to: '/UUCafe/CafeService',
          unique: 52,
        },
        { name: '門市查詢-門市設定', to: '/UUCafe/CafeShop', unique: 53 },
        { name: '餐點介紹-首頁設定', to: '/UUCafe/MealIntroHome', unique: 54 },
        { name: '餐點介紹-咖啡專區', to: '/UUCafe/MealIntroMenu', unique: 55 },
        { name: '餐點介紹-其他專區', to: '/UUCafe/MealIntroOther', unique: 56 },
        { name: '關於我們', to: '/UUCafe/Journey', unique: 57 },
        { name: '人才招募', to: '/UUCafe/Recruitment', unique: 58 },
        { name: '聯絡我們', to: '/UUCafe/ContactUs', unique: 59 },
      ],
      icon: <FaUserAlt />,
      id: 'uuCafeManagement',
      name: 'UUCafe',
    },
    {
      icon: <FaUserShield />,
      id: 'adminControl',
      name: '權限管理',
      to: '/AdminControl',
      unique: 11,
    },
    {
      icon: <FaUserEdit />,
      id: 'personalData',
      name: '修改個人資料',
      to: '/PersonalData',
      unique: 12,
    },
  ];

  const [active, setActive] = useState('');
  const handleToggleSubmenu = activeName => {
    setActive(active === activeName ? '' : activeName);
  };
  const { userInfo } = useSelector(state => state.userLogin);

  // useEffect(() => {
  //   dispatch(getWarrantyRegisterNumber());
  // }, []);
  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/Login') return;
    let activeId = MENUES.find(i => {
      return i.children
        ? i.children.some(j => j.to === location.pathname)
        : i.to === location.pathname;
    })?.id;

    // 解決頁面跳轉 sub 選單更新問題, 直接抓取前一個 id
    if (activeId === undefined) {
      activeId = active;
    }

    setActive(activeId);

    if (userInfo?.permissions?.find(item => item.name === '產品保固註冊')) {
      dispatch(getWarrantyRegisterNumber());
    }
  }, [location]);

  const logoutHandler = () => {
    MessageUtil.logOutConfirm(() => {
      history.push('/Login'); //*防止history記憶state

      dispatch(userLogout());
    });
  };

  return (
    <>
      <PerfectScrollbar>
        <div>
          <ul
            className={style.navigation}
            style={{
              transform: `${sidebarToggleMobile ? 'translateX(-320px)' : ''}`,
            }}
          >
            {MENUES.filter(i => {
              return (
                !i.hidden &&
                ((process.env.REACT_APP_ENV !== 'development' && !i.develop) ||
                  process.env.REACT_APP_ENV === 'development')
              );
            })
              .filter(m => {
                if (
                  m.unique &&
                  userInfo?.permissions.some(
                    p => String(p.id) === String(m.unique),
                  )
                ) {
                  return m;
                }

                if (
                  !m.unique &&
                  userInfo?.permissions.some(p => p.name === m.name)
                ) {
                  return m;
                }

                if (
                  m.name === '訊息管理' &&
                  userInfo?.permissions.some(
                    p => p.id === 39 || p.id === 40 || p.id === 41,
                  )
                ) {
                  return m;
                }
                if (
                  m.name === 'UUCafe' &&
                  userInfo?.permissions.some(p => p.id >= 45 && p.id <= 59)
                ) {
                  return m;
                }
                return false;
              })
              .map(i => {
                let children = undefined;
                if (i.children) {
                  if (i.name === 'UUCafe') {
                    children = i.children.filter(c => {
                      if (userInfo?.permissions.some(p => p.id === c.unique)) {
                        return true;
                      }
                    });
                  } else {
                    children = i.children;
                  }
                }

                return (
                  <MenuItem
                    key={i.id}
                    isOpen={active === i.id}
                    name={i.name}
                    icon={i.icon}
                    toggleOpen={() => handleToggleSubmenu(i.id)}
                    to={i.to}
                  >
                    {children && (
                      <SubMenu menus={children} isOpen={active === i.id} />
                    )}
                  </MenuItem>
                );
              })}
            <button
              className={style.navigation_item}
              onClick={logoutHandler}
              style={{ border: 0, cursor: 'pointer' }}
            >
              <span className={style.navigationTitle}>
                <FaSignOutAlt size={24} />
                <span className={style.navText}>登出</span>
              </span>
            </button>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default SideBarMenu;
