import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { getOrderActivities } from 'redux/actions/data/orderActions';

import style from '../../styles/layout.module.css';

const DiscountActivityDetailModal = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch();
  const { loading, activities } = useSelector(
    state => state.getOrderActivities,
  );
  useEffect(() => {
    dispatch(getOrderActivities(id));
  }, [id]);

  return (
    <Modal zIndex={2000} size="xl" centered toggle={toggle} isOpen={isOpen}>
      <ModalHeader className={style.modal_head} toggle={toggle}>
        折扣活動明細
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className="table-responsive">
          <Table hover striped bordered>
            <TableHead>
              <tr>
                <th>活動對象</th>
                <th>會員等級</th>
                <th>常態/短期活動</th>
                <th>活動總類</th>
                <th>活動代碼</th>
                <th>活動名稱</th>
                <th>功能</th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {activities.length > 0 ? (
                  activities.map((activity, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        {activity._object === 'all' ? '全體會員' : '指定會員'}
                      </td>
                      <td className="text-center">
                        {activity?.objectMLevelName || '--'}
                      </td>
                      <td className="text-center">
                        {activity.objectType
                          ? activity.objectType === 'regular'
                            ? '常態活動'
                            : activity.objectType === 'special'
                            ? '短期活動'
                            : '--'
                          : '--'}
                      </td>
                      <td className="text-center">{activity.discountType}</td>
                      <td className="text-center">{activity.activityCode}</td>
                      <td className="text-center">{activity.name}</td>
                      <td className="text-center">
                        <Button
                          color="link"
                          className="text-decoration-none"
                          size="sm"
                          onClick={() => {
                            if (activity.discountType === '你真的喜歡') {
                              window.open('/#/YourFavorite', '_blank');
                            } else {
                              window.open(
                                `/#/DiscountCompaign?page=1&keyword=${activity.name}&activityCode=${activity.activityCode}`,
                                '_blank',
                              );
                            }
                          }}
                        >
                          查看明細
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DiscountActivityDetailModal;
