import React, { memo } from 'react';
import { FormGroup } from 'reactstrap';

import style from '../../styles/layout.module.css';
const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
};

const OthersSettings = ({ values, setFieldValue, errors, touched }) => {
  return (
    <>
      <h4 className="title" style={titleStyle}>
        其他行銷活動設定
      </h4>
      <FormGroup className={style.formGroup}>
        <label className="w150 required">是否可使用折價券</label>
        <div>
          <ul>
            <li>
              <small>
                若活動商品已設定為「不適用任何折價券商品」則此商品亦無法使用折價券
              </small>
            </li>
          </ul>
          <div className="d-flex align-items-center mb-2">
            <input
              type="radio"
              checked={values.couponEnabled}
              onChange={() => {
                setFieldValue('couponEnabled', true);
              }}
            />
            <span className="ml-2">是(此檔活動結帳可使用折價券)</span>
          </div>
          <div className="d-flex align-items-center mb-2">
            <input
              type="radio"
              checked={!values.couponEnabled}
              onChange={() => {
                setFieldValue('couponEnabled', false);
              }}
            />
            <span className="ml-2">否(不可使用折價券)</span>
          </div>
        </div>
      </FormGroup>
    </>
  );
};

export default memo(OthersSettings);
