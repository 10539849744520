import {
  BRAND_OPTIONS_FAIL,
  BRAND_OPTIONS_REQUEST,
  BRAND_OPTIONS_SUCCESS,
  COLOR_OPTIONS_FAIL,
  COLOR_OPTIONS_REQUEST,
  COLOR_OPTIONS_SUCCESS,
  EXPORT_MAINTAINLIST_WORD_FAIL,
  EXPORT_MAINTAINLIST_WORD_IDLE,
  EXPORT_MAINTAINLIST_WORD_REQUEST,
  EXPORT_MAINTAINLIST_WORD_SUCCESS,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST,
  GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST,
  GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS,
  GET_RELATED_REGISTER_LIST_FAIL,
  GET_RELATED_REGISTER_LIST_REQUEST,
  GET_RELATED_REGISTER_LIST_SUCCESS,
  MAINTAIN_ADD_FAIL,
  MAINTAIN_ADD_REQUEST,
  MAINTAIN_ADD_RESET,
  MAINTAIN_ADD_SUCCESS,
  MAINTAIN_BATCH_UPDATE_FAIL,
  MAINTAIN_BATCH_UPDATE_REQUEST,
  MAINTAIN_BATCH_UPDATE_RESET,
  MAINTAIN_BATCH_UPDATE_SUCCESS,
  MAINTAIN_DEALING_UPDATE_FAIL,
  MAINTAIN_DEALING_UPDATE_REQUEST,
  MAINTAIN_DEALING_UPDATE_RESET,
  MAINTAIN_DEALING_UPDATE_SUCCESS,
  MAINTAIN_DETAILS_FAIL,
  MAINTAIN_DETAILS_REQUEST,
  MAINTAIN_DETAILS_SUCCESS,
  MAINTAIN_DETAILS_UPDATE_FAIL,
  MAINTAIN_DETAILS_UPDATE_REQUEST,
  MAINTAIN_DETAILS_UPDATE_RESET,
  MAINTAIN_DETAILS_UPDATE_SUCCESS,
  MAINTAIN_EXPORT_FAIL,
  MAINTAIN_EXPORT_REQUEST,
  MAINTAIN_EXPORT_RESET,
  MAINTAIN_EXPORT_SUCCESS,
  MAINTAIN_GET_INFO_FAIL,
  MAINTAIN_GET_INFO_REQUEST,
  MAINTAIN_GET_INFO_RESET,
  MAINTAIN_GET_INFO_SUCCESS,
  MAINTAIN_HANDLERS_FAIL,
  MAINTAIN_HANDLERS_REQUEST,
  MAINTAIN_HANDLERS_SUCCESS,
  MAINTAIN_IMPORT_FAIL,
  MAINTAIN_IMPORT_REQUEST,
  MAINTAIN_IMPORT_RESET,
  MAINTAIN_IMPORT_SUCCESS,
  MAINTAIN_LIST_FAIL,
  MAINTAIN_LIST_REQUEST,
  MAINTAIN_LIST_SUCCESS,
  MAINTAIN_OPTIONS_FAIL,
  MAINTAIN_OPTIONS_REQUEST,
  MAINTAIN_OPTIONS_SUCCESS,
  MAINTAIN_STATUS_OPTIONS_FAIL,
  MAINTAIN_STATUS_OPTIONS_REQUEST,
  MAINTAIN_STATUS_OPTIONS_SUCCESS,
  MODEL_OPTIONS_FAIL,
  MODEL_OPTIONS_REQUEST,
  MODEL_OPTIONS_SUCCESS,
  REPAIR_SETTING_GET_FAIL,
  REPAIR_SETTING_GET_REQUEST,
  REPAIR_SETTING_GET_SUCCESS,
  REPAIR_SETTING_POST_FAIL,
  REPAIR_SETTING_POST_REQUEST,
  REPAIR_SETTING_POST_RESET,
  REPAIR_SETTING_POST_SUCCESS,
  UPDATE_CREDITS_FAIL,
  UPDATE_CREDITS_IDLE,
  UPDATE_CREDITS_REQUEST,
  UPDATE_CREDITS_SUCCESS,
  UPDATE_EMPLOYEE_ID_FAIL,
  UPDATE_EMPLOYEE_ID_IDLE,
  UPDATE_EMPLOYEE_ID_REQUEST,
  UPDATE_EMPLOYEE_ID_SUCCESS,
  UPDATE_REPAIR_REGISTER_FAIL,
  UPDATE_REPAIR_REGISTER_IDLE,
  UPDATE_REPAIR_REGISTER_REQUEST,
  UPDATE_REPAIR_REGISTER_SUCCESS,
  UPDATE_SEND_BACK_DATE_FAIL,
  UPDATE_SEND_BACK_DATE_IDLE,
  UPDATE_SEND_BACK_DATE_SUCCESS,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST,
  UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS,
  UPDUATE_SEND_BACK_DATE_REQUEST,
} from '../../constants/data/repairConstants';

//get repair setting
export const repairSettingInfoReducer = (
  state = { repairSettingInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case REPAIR_SETTING_GET_REQUEST:
      return { loading: true };
    case REPAIR_SETTING_GET_SUCCESS:
      return { loading: false, repairSettingInfo: payload };
    case REPAIR_SETTING_GET_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post repair setting
export const repairSettingPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case REPAIR_SETTING_POST_REQUEST:
      return { loading: true };
    case REPAIR_SETTING_POST_SUCCESS:
      return { loading: false, success: true };
    case REPAIR_SETTING_POST_FAIL:
      return { error: payload, loading: false };
    case REPAIR_SETTING_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get repair list
export const maintainListReducer = (
  state = { maintainListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_LIST_REQUEST:
      return { loading: true };
    case MAINTAIN_LIST_SUCCESS:
      return { loading: false, maintainListInfo: payload };
    case MAINTAIN_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get repair status options
export const maintainStatusOptionsReducer = (
  state = { maintainStatusOptions: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_STATUS_OPTIONS_REQUEST:
      return { loading: true };
    case MAINTAIN_STATUS_OPTIONS_SUCCESS:
      return { loading: false, maintainStatusOptions: payload };
    case MAINTAIN_STATUS_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get repair details
export const maintainDetailsReducer = (
  state = { maintainDetailsInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_DETAILS_REQUEST:
      return { error: null, loading: true, maintainDetailsInfo: null };
    case MAINTAIN_DETAILS_SUCCESS:
      return { loading: false, maintainDetailsInfo: payload };
    case MAINTAIN_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//get repair options
export const maintainOptionsReducer = (
  state = { maintainOptions: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_OPTIONS_REQUEST:
      return { loading: true };
    case MAINTAIN_OPTIONS_SUCCESS:
      return { loading: false, maintainOptions: payload };
    case MAINTAIN_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update repair details
export const maintainDetailUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_DETAILS_UPDATE_REQUEST:
      return { loading: true };
    case MAINTAIN_DETAILS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case MAINTAIN_DETAILS_UPDATE_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_DETAILS_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get repair handler
export const maintainHandlerOptionsReducer = (
  state = { maintainHandlerOptions: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_HANDLERS_REQUEST:
      return { loading: true };
    case MAINTAIN_HANDLERS_SUCCESS:
      return { loading: false, maintainHandlerOptions: payload };
    case MAINTAIN_HANDLERS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//update repair dealing
export const maintainDealingUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_DEALING_UPDATE_REQUEST:
      return { loading: true };
    case MAINTAIN_DEALING_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case MAINTAIN_DEALING_UPDATE_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_DEALING_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//add repair
export const maintainAddReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_ADD_REQUEST:
      return { loading: true };
    case MAINTAIN_ADD_SUCCESS:
      return { id: payload, loading: false, success: true };
    case MAINTAIN_ADD_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_ADD_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//batch update repair
export const maintainBatchUpdateReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_BATCH_UPDATE_REQUEST:
      return { loading: true };
    case MAINTAIN_BATCH_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case MAINTAIN_BATCH_UPDATE_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_BATCH_UPDATE_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//repair get info by product number
export const maintainGetInfoReducer = (
  state = { error: null , loading: false, maintainInfo: null,  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_GET_INFO_REQUEST:
      return { loading: true };
    case MAINTAIN_GET_INFO_SUCCESS:
      return { loading: false, maintainInfo: payload };
    case MAINTAIN_GET_INFO_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_GET_INFO_RESET:
      return { error: null, loading: false, maintainInfo: null };
    default:
      return state;
  }
};

//repair export
export const maintainExportReducer = (state = { result: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_EXPORT_REQUEST:
      return { loading: true };
    case MAINTAIN_EXPORT_SUCCESS:
      return { loading: false, result: payload };
    case MAINTAIN_EXPORT_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_EXPORT_RESET:
      return { error: null, loading: false, result: null };
    default:
      return state;
  }
};

//repair import
export const maintainImporteReducer = (state = { result: null }, action) => {
  const { type, payload } = action;
  switch (type) {
    case MAINTAIN_IMPORT_REQUEST:
      return { loading: true };
    case MAINTAIN_IMPORT_SUCCESS:
      return { loading: false, result: payload };
    case MAINTAIN_IMPORT_FAIL:
      return { error: payload, loading: false };
    case MAINTAIN_IMPORT_RESET:
      return { error: null, loading: false, result: null };
    default:
      return state;
  }
};

// repair brand options
export const repairBrandOptionsReducer = (
  state = { repairBrandOptions: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case BRAND_OPTIONS_REQUEST:
      return { loading: true };
    case BRAND_OPTIONS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let repairBrandOptions = [];
      if (payload instanceof Array && payload.length > 0) {
        repairBrandOptions = payload.sort((a, b) => {
          return (a.label || '').localeCompare(b.label || '');
        });
      }
      return { loading: false, repairBrandOptions: repairBrandOptions };
    case BRAND_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

// repair model options
export const repairModelOptionsReducer = (
  state = { repairModelOptions: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case MODEL_OPTIONS_REQUEST:
      return { loading: true };
    case MODEL_OPTIONS_SUCCESS:
      return { loading: false, repairModelOptions: payload };
    case MODEL_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

// repair color options
export const repairColorOptionsReducer = (
  state = { repairColorOptions: [] },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case COLOR_OPTIONS_REQUEST:
      return { loading: true };
    case COLOR_OPTIONS_SUCCESS:
      return { loading: false, repairColorOptions: payload };
    case COLOR_OPTIONS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export const updateCreditsReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CREDITS_IDLE:
      return { error: false, loading: false, success: false };
    case UPDATE_CREDITS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CREDITS_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_CREDITS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateSendBackDateReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_SEND_BACK_DATE_IDLE:
      return { error: null, loading: false, success: false };
    case UPDUATE_SEND_BACK_DATE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SEND_BACK_DATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_SEND_BACK_DATE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getRelatedRegisterListReducer = (
  state = { error: null, loading: false, relatedList: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RELATED_REGISTER_LIST_REQUEST:
      return { error: null, loading: true, relatedList: [], success: false };
    case GET_RELATED_REGISTER_LIST_SUCCESS:
      return { ...state, loading: false, relatedList: payload, success: true };
    case GET_RELATED_REGISTER_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateWarrantyProductSerialNumberReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_WARRANTY_PRODUCT_SERIALNUMBER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateRepairRegisterReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_REPAIR_REGISTER_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_REPAIR_REGISTER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_REPAIR_REGISTER_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_REPAIR_REGISTER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getProductInfoViaWarrantySerialNumberReducer = (
  state = { error: null, info: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_IDLE:
      return { error: null, info: null, loading: false, success: false };
    case GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_REQUEST:
      return { ...state, loading: true };
    case GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_SUCCESS:
      return { ...state, info: payload, loading: false, success: true };
    case GET_PRODUCT_INFO_VIA_WARRANTY_SERIALNUMBER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMemberInfoViaPhoneNumberReducer = (
  state = { error: null, info: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MEMBER_INFO_VIA_PHONE_NUMBER_IDLE:
      return { error: null, info: null, loading: false, success: false };
    case GET_MEMBER_INFO_VIA_PHONE_NUMBER_REQUEST:
      return { ...state, loading: true };
    case GET_MEMBER_INFO_VIA_PHONE_NUMBER_SUCCESS:
      return { ...state, info: payload, loading: false, success: true };
    case GET_MEMBER_INFO_VIA_PHONE_NUMBER_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const exportMaintainListWordReducer = (
  state = { error: null, loading: false, result: null, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case EXPORT_MAINTAINLIST_WORD_IDLE:
      return { error: null, loading: false, result: null, success: false };
    case EXPORT_MAINTAINLIST_WORD_REQUEST:
      return { ...state, loading: false };
    case EXPORT_MAINTAINLIST_WORD_SUCCESS:
      return { ...state, loading: false, result: payload, success: true };
    case EXPORT_MAINTAINLIST_WORD_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateEmployeeIdReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_EMPLOYEE_ID_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_EMPLOYEE_ID_REQUEST:
      return { ...state, loading: true };
    case UPDATE_EMPLOYEE_ID_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_EMPLOYEE_ID_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
