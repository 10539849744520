export const ADMIN_LIST_REQUEST = 'ADMIN_LIST_REQUEST';
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';
export const ADMIN_LIST_FAIL = 'ADMIN_LIST_FAIL';

export const ADMIN_PERMISSIONS_REQUEST = 'ADMIN_PERMISSIONS_REQUEST';
export const ADMIN_PERMISSIONS_SUCCESS = 'ADMIN_PERMISSIONS_SUCCESS';
export const ADMIN_PERMISSIONS_FAIL = 'ADMIN_PERMISSIONS_FAIL';

export const ADMIN_ADD_REQUEST = 'ADMIN_ADD_REQUEST';
export const ADMIN_ADD_SUCCESS = 'ADMIN_ADD_SUCCESS';
export const ADMIN_ADD_FAIL = 'ADMIN_ADD_FAIL';
export const ADMIN_ADD_RESET = 'ADMIN_ADD_RESET';

export const ADMIN_DETAILS_REQUEST = 'ADMIN_DETAILS_REQUEST';
export const ADMIN_DETAILS_SUCCESS = 'ADMIN_DETAILS_SUCCESS';
export const ADMIN_DETAILS_FAIL = 'ADMIN_DETAILS_FAIL';

export const ADMIN_UPDATE_REQUEST = 'ADMIN_UPDATE_REQUEST';
export const ADMIN_UPDATE_SUCCESS = 'ADMIN_UPDATE_SUCCESS';
export const ADMIN_UPDATE_FAIL = 'ADMIN_UPDATE_FAIL';
export const ADMIN_UPDATE_RESET = 'ADMIN_UPDATE_RESET';

export const ADMIN_DELETE_REQUEST = 'ADMIN_DELETE_REQUEST';
export const ADMIN_DELETE_SUCCESS = 'ADMIN_DELETE_SUCCESS';
export const ADMIN_DELETE_FAIL = 'ADMIN_DELETE_FAIL';
export const ADMIN_DELETE_RESET = 'ADMIN_DELETE_RESET';

export const ADMIN_SEND_VERIFY_REQUEST = 'ADMIN_SEND_VERIFY_REQUEST';
export const ADMIN_SEND_VERIFY_SUCCESS = 'ADMIN_SEND_VERIFY_SUCCESS';
export const ADMIN_SEND_VERIFY_FAIL = 'ADMIN_SEND_VERIFY_FAIL';
export const ADMIN_SEND_VERIFY_RESET = 'ADMIN_SEND_VERIFY_RESET';
