import {
  DEALER_CHANNEL_DETAILS_FAIL,
  DEALER_CHANNEL_DETAILS_IDLE,
  DEALER_CHANNEL_DETAILS_REQUEST,
  DEALER_CHANNEL_DETAILS_SUCCESS,
  DEALER_CHANNEL_LIST_FAIL,
  DEALER_CHANNEL_LIST_REQUEST,
  DEALER_CHANNEL_LIST_SUCCESS,
  DEALER_CHANNEL_POST_FAIL,
  DEALER_CHANNEL_POST_REQUEST,
  DEALER_CHANNEL_POST_RESET,
  DEALER_CHANNEL_POST_SUCCESS,
  DEALER_DEFAULT_SET_FAIL,
  DEALER_DEFAULT_SET_REQUEST,
  DEALER_DEFAULT_SET_RESET,
  DEALER_DEFAULT_SET_SUCCESS,
} from '../../constants/data/channelConstants';

//get channel list
export const channelListReducer = (
  state = { channelListInfo: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DEALER_CHANNEL_LIST_REQUEST:
      return { loading: true };
    case DEALER_CHANNEL_LIST_SUCCESS:
      return { channelListInfo: payload, loading: false };
    case DEALER_CHANNEL_LIST_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

//post channel
export const channelPostReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DEALER_CHANNEL_POST_REQUEST:
      return { loading: true };
    case DEALER_CHANNEL_POST_SUCCESS:
      return { loading: false, success: true };
    case DEALER_CHANNEL_POST_FAIL:
      return { error: payload, loading: false };
    case DEALER_CHANNEL_POST_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//set channel default status
export const channelSetReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case DEALER_DEFAULT_SET_REQUEST:
      return { loading: true };
    case DEALER_DEFAULT_SET_SUCCESS:
      return { loading: false, success: true };
    case DEALER_DEFAULT_SET_FAIL:
      return { error: payload, loading: false };
    case DEALER_DEFAULT_SET_RESET:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
};

//get channel details
export const channelDetailsReducer = (
  state = { channelDetails: null },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DEALER_CHANNEL_DETAILS_IDLE:
      return (state = { channelDetails: null, error: null });
    case DEALER_CHANNEL_DETAILS_REQUEST:
      return { loading: true };
    case DEALER_CHANNEL_DETAILS_SUCCESS:
      return { channelDetails: payload, loading: false };
    case DEALER_CHANNEL_DETAILS_FAIL:
      return { error: payload, loading: false };
    default:
      return state;
  }
};
