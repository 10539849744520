import { useRequest } from 'utils/useFetch';

import {
  UUCAFE_SEO_SETTINGS_DETAIL_FAIL,
  UUCAFE_SEO_SETTINGS_DETAIL_REQUEST,
  UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SEO_SETTINGS_LIST_FAIL,
  UUCAFE_SEO_SETTINGS_LIST_REQUEST,
  UUCAFE_SEO_SETTINGS_LIST_SUCCESS,
} from '../../constants/data/uuCafeSEOSettingsConstants';

export const getCafeSeoSettingsList = () => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_SEO_SETTINGS_LIST_FAIL,
      method: 'get',
      requestConstant: UUCAFE_SEO_SETTINGS_LIST_REQUEST,
      successConstant: UUCAFE_SEO_SETTINGS_LIST_SUCCESS,
      url: '/Backend/Cafe_SeoSetting/list',
    });
  };
};

export const getCafeSeoSettingsDetail = params => {
  return async (dispatch, getState) => {
    await useRequest({
      dispatch,
      failConstant: UUCAFE_SEO_SETTINGS_DETAIL_FAIL,
      method: 'get',
      requestConstant: UUCAFE_SEO_SETTINGS_DETAIL_REQUEST,
      successConstant: UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS,
      url: `/Backend/Cafe_SeoSetting/seo/${params.id}`,
    });
  };
};

export const updateSeoSettingsDetail = data => {
  return async (dispatch, getState) => {
    await useRequest({
      data,
      dispatch,
      failConstant: UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL,
      method: 'post',
      requestConstant: UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST,
      successConstant: UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS,
      url: '/Backend/Cafe_SeoSetting/update',
    });
  };
};
