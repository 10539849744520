import moment from 'moment/moment';
import RcPagination from 'rc-pagination';
import { memo } from 'react';
import { Card, CardBody, Table } from 'reactstrap';

import history from '../../utils/createHistory';
import useQuery from '../../utils/useQuery';
import { TableHasNoData, TableLoading } from '../TableHasNoData';
import TableHead from '../TableHead';

import SortButton from './SortButtin';

const VoucherPerformanceList = ({ list, loading, onSearch }) => {
  const query = useQuery();
  const { location } = history;
  const pageChangeHandler = page => {
    const values = {
      ...query.value,
      endTime: query?.value?.endTime
        ? moment(query?.value?.endTime).toDate()
        : '',
      page,
      page_size: 10,
      salesChannel: JSON.parse(
        query?.value?.salesChannel ||
          JSON.stringify([{ label: 'online', value: '' }]),
      ),
      startTime: query?.value?.startTime
        ? moment(query?.value?.startTime).toDate()
        : '',
    };
    const params = new URLSearchParams({
      ...values,
      salesChannel: JSON.stringify(
        values?.salesChannel || [{ label: 'online', value: '' }],
      ),
    });

    onSearch({
      couponType: values?.couponType || undefined,
      endTime: values?.endTime || undefined,
      getWay: values?.getWay || undefined,
      keywords: values?.keyword ?? undefined,
      order: values?.order || 'asc',
      order_by: values?.order_by || '',
      page: values?.page,
      page_size: 10,
      queryDateType: values?.queryDateType ?? undefined,
      salesChannel: values?.salesChannel.map(s => s.value).filter(s => s),
      startTime: values?.startTime || undefined,
    });
    history.push(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Card>
      <CardBody>
        <div className="table-responsive mb-3">
          <Table hover striped bordered className="mb-5">
            <TableHead>
              <tr>
                <th>
                  <SortButton currentOrder="couponType" onSearch={onSearch}>
                    券型
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="couponCode" onSearch={onSearch}>
                    活動序號
                  </SortButton>

                  <SortButton currentOrder="couponName" onSearch={onSearch}>
                    活動名稱
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="getStartTime" onSearch={onSearch}>
                    領取期間
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="redeemStartTime"
                    onSearch={onSearch}
                  >
                    使用期間
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="getWay" onSearch={onSearch}>
                    活動類型
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="goal" onSearch={onSearch}>
                    目的
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="discountType" onSearch={onSearch}>
                    折抵類型
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="discountAmount" onSearch={onSearch}>
                    面額
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="settingQuantity"
                    onSearch={onSearch}
                  >
                    設定張數
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="discountMaxAmount"
                    onSearch={onSearch}
                  >
                    折抵上限
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="totalSendQty" onSearch={onSearch}>
                    總領取張數
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="totalRedeemedAmount"
                    onSearch={onSearch}
                  >
                    已領取面額
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="totalUsedOnlineQty"
                    onSearch={onSearch}
                  >
                    使用張數
                  </SortButton>
                  <div>(官網)</div>
                </th>
                <th>
                  <SortButton
                    currentOrder="totalUsedOfflineQty"
                    onSearch={onSearch}
                  >
                    使用張數
                  </SortButton>
                  <div>(門市)</div>
                </th>
                <th>
                  <SortButton currentOrder="totalUsedQty" onSearch={onSearch}>
                    總使用張數
                  </SortButton>
                </th>
                <th>
                  <SortButton currentOrder="totalUsedRate" onSearch={onSearch}>
                    總使用率
                  </SortButton>
                </th>
                <th>
                  <SortButton
                    currentOrder="totalDiscountAmount"
                    onSearch={onSearch}
                  >
                    折價券折抵總金額
                  </SortButton>
                  <div>(官網)</div>
                </th>
                <th>
                  <SortButton
                    currentOrder="totalOrderAmount"
                    onSearch={onSearch}
                  >
                    訂單實付總金額
                  </SortButton>
                  <div>(官網)</div>
                </th>
              </tr>
            </TableHead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {list.datas?.length ? (
                  list.datas.map(d => {
                    return (
                      <tr key={JSON.stringify(d)}>
                        <td nowrap="nowrap">{d.couponTypeLabel || '--'}</td>

                        <td>
                          <div>{d.couponCode || '--'}</div>
                          <div>{d.couponName || '--'}</div>
                          {/*TODO 活動名稱待補*/}
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {d.getStartTime
                              ? moment(d.getStartTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                            迄
                          </div>
                          <div className="text-nowrap">
                            {d.getEndTime
                              ? moment(d.getEndTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                            起
                          </div>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            {d.redeemStartTime
                              ? moment(d.redeemStartTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                            起
                          </div>
                          <div className="text-nowrap">
                            {d.redeemEndTime
                              ? moment(d.redeemEndTime).format(
                                  'YYYY/MM/DD HH:mm:ss',
                                )
                              : '--'}
                            迄
                          </div>
                        </td>
                        <td>
                          <div>{d.getWayLabel || '--'}</div>
                        </td>
                        <td>
                          <div>{d.goalLabel || '--'}</div>
                        </td>
                        <td>
                          <div className="text-center">
                            {d.discountTypeLabel || '--'}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {d.discountType === 1 && 'NT$'}{' '}
                            {(d.discountAmount ?? '0').toLocaleString()}
                            {d.discountType !== 1 && '折'}{' '}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {(d.settingQuantity ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center" style={{
                            width: '250px'
                          }}>
                            {d.discountType === 1 && (
                                <div>最高可折抵購買金額為 {d.discountMaxAmount} %</div>
                            )}
                            {d.discountType === 2 && d.discountMax === 2 && (
                                <div>
                                  最高可折抵購買金額的NT${' '}
                                  {d.discountMaxAmount?.toLocaleString()}
                                </div>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {(d.totalSendQty ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          {d.discountType === 1 && (
                            <div className="text-center">
                              NT${' '}
                              {(d.totalRedeemedAmount ?? '0').toLocaleString()}
                            </div>
                          )}
                        </td>
                        <td>
                          <div className="text-center">
                            {(d.totalUsedOnlineQty ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {(d.totalUsedOfflineQty ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {(d.totalUsedQty ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {d.totalUsedRate ?? '0'}%
                          </div>
                        </td>
                        <td>
                          <div className="text-center text-nowrap">
                            - NT${' '}
                            {(d.totalDiscountAmount ?? '0').toLocaleString()}
                          </div>
                        </td>
                        <td>
                          <div className="text-center text-nowrap">
                            NT$ {(d.totalOrderAmount ?? '0').toLocaleString()}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={list?.current_page || 1}
            pageSize={list?.page_size || 50}
            style={{ borderColor: '#446' }}
            total={list?.total_count || 0}
            onChange={pageChangeHandler}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default memo(VoucherPerformanceList);
