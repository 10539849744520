import {
  UUCAFE_JOURNEY_ADD_FAIL,
  UUCAFE_JOURNEY_ADD_REQUEST,
  UUCAFE_JOURNEY_ADD_SUCCESS,
  UUCAFE_JOURNEY_FAIL,
  UUCAFE_JOURNEY_REQUEST,
  UUCAFE_JOURNEY_SUCCESS,
  UUCAFE_JOURNEY_UPDATE_FAIL,
  UUCAFE_JOURNEY_UPDATE_REQUEST,
  UUCAFE_JOURNEY_UPDATE_SUCCESS,
  UUCAFE_JOURNEY_LIST_FAIL,
  UUCAFE_JOURNEY_LIST_REQUEST,
  UUCAFE_JOURNEY_LIST_SUCCESS,
  UUCAFE_JOURNEY_PAGE_SUCCESS,
  UUCAFE_JOURNEY_PAGE_FAIL,
  UUCAFE_JOURNEY_PAGE_REQUEST,
  UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS,
  UUCAFE_JOURNEY_PAGE_UPDATE_FAIL,
  UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST,
} from '../../constants/data/uuCafeJourneyConstants';

export const cafeJourneyListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_JOURNEY_LIST_REQUEST:
      return {
        error: null,
        list: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        loading: true,
        success: false,
      };
    case UUCAFE_JOURNEY_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_JOURNEY_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeJourneyDetailReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      yyyymm: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_JOURNEY_REQUEST:
      return {
        detail: { content: '', content_en: '', yyyymm: '' },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_JOURNEY_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_JOURNEY_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_JOURNEY_ADD_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_JOURNEY_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_JOURNEY_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_JOURNEY_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_JOURNEY_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_JOURNEY_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

// ============ MEALINTRO ==================
export const cafeJourneyPageReducer = (
  state = {
    detail: {
      content: '',
      content_en: '',
      title: '',
      title_en: '',
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_JOURNEY_PAGE_REQUEST:
      return {
        detail: {
          content: '',
          content_en: '',
          title: '',
          title_en: '',
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_JOURNEY_PAGE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_JOURNEY_PAGE_FAIL:
      return { ...state, error: payload || true, loading: false };

    case UUCAFE_JOURNEY_PAGE_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_JOURNEY_PAGE_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_JOURNEY_PAGE_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
