import {
  CREATE_MESSAGE_CATEGORY_FAIL,
  CREATE_MESSAGE_CATEGORY_IDLE,
  CREATE_MESSAGE_CATEGORY_REQUEST,
  CREATE_MESSAGE_CATEGORY_SUCCESS,
  CREATE_MESSAGE_REPLY_FAIL,
  CREATE_MESSAGE_REPLY_IDLE,
  CREATE_MESSAGE_REPLY_REQUEST,
  CREATE_MESSAGE_REPLY_SUCCESS,
  DELETE_MESSAGE_CATEGORY_FAIL,
  DELETE_MESSAGE_CATEGORY_IDLE,
  DELETE_MESSAGE_CATEGORY_REQUEST,
  DELETE_MESSAGE_CATEGORY_SUCCESS,
  GET_AUTO_REPLY_MESSAGE_FAIL,
  GET_AUTO_REPLY_MESSAGE_REQUEST,
  GET_AUTO_REPLY_MESSAGE_SUCCESS,
  GET_MESSAGE_CATEGORY_LIST_FAIL,
  GET_MESSAGE_CATEGORY_LIST_REQUEST,
  GET_MESSAGE_CATEGORY_LIST_SUCCESS,
  GET_MESSAGE_CATEGORY_OPTIONS_FAIL,
  GET_MESSAGE_CATEGORY_OPTIONS_REQUEST,
  GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS,
  GET_MESSAGE_LIST_FAIL,
  GET_MESSAGE_LIST_SUCCESS,
  GET_MESSAGE_LSIT_REQUEST,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST,
  GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS,
  GET_SINGLE_MESSAGE_FAIL,
  GET_SINGLE_MESSAGE_REQUEST,
  GET_SINGLE_MESSAGE_SUCCESS,
  SET_AUTO_REPLY_MESSAGE_FAIL,
  SET_AUTO_REPLY_MESSAGE_IDLE,
  SET_AUTO_REPLY_MESSAGE_REQUEST,
  SET_AUTO_REPLY_MESSAGE_SUCCESS,
  SET_MESSAGE_CLOSED_FAIL,
  SET_MESSAGE_CLOSED_IDLE,
  SET_MESSAGE_CLOSED_REQUEST,
  SET_MESSAGE_CLOSED_SUCCESS,
  UNREAD_MESSAGE_COUNT_FAIL,
  UNREAD_MESSAGE_COUNT_REQUEST,
  UNREAD_MESSAGE_COUNT_SUCCESS,
  UPDATE_MESSAGE_CATEGORY_FAIL,
  UPDATE_MESSAGE_CATEGORY_IDLE,
  UPDATE_MESSAGE_CATEGORY_REQUEST,
  UPDATE_MESSAGE_CATEGORY_SORT_FAIL,
  UPDATE_MESSAGE_CATEGORY_SORT_IDLE,
  UPDATE_MESSAGE_CATEGORY_SORT_REQUEST,
  UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS,
  UPDATE_MESSAGE_CATEGORY_SUCCESS,
} from 'redux/constants/data/MessageConstant';
import {
  GET_OVERALL_MESSAGE_FAIL,
  GET_OVERALL_MESSAGE_REQUEST,
  GET_OVERALL_MESSAGE_SUCCESS,
} from 'redux/constants/data/overallConstants';

export const getMessageCategoryListReducer = (
  state = { error: null, list: [], loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGE_CATEGORY_LIST_REQUEST:
      return { error: null, list: [], loading: true, success: false };
    case GET_MESSAGE_CATEGORY_LIST_SUCCESS:
      return { ...state, list: payload, lodaing: false, success: true };
    case GET_MESSAGE_CATEGORY_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const createMessageCategoryReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_MESSAGE_CATEGORY_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_MESSAGE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case CREATE_MESSAGE_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_MESSAGE_CATEGORY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const unReadMessageCountReducer = (
  state = { loading: false, unReadCount: 0 },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UNREAD_MESSAGE_COUNT_REQUEST:
      return { loading: true, unReadCount: 0 };
    case UNREAD_MESSAGE_COUNT_SUCCESS:
      return { loading: false, unReadCount: payload };
    case UNREAD_MESSAGE_COUNT_FAIL:
      return { loading: true, unReadCount: 0 };
    default:
      return state;
  }
};

export const updateMessageCategoryReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MESSAGE_CATEGORY_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_MESSAGE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MESSAGE_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_MESSAGE_CATEGORY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const deleteMessageCategoryReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_MESSAGE_CATEGORY_IDLE:
      return { error: null, loading: false, success: false };
    case DELETE_MESSAGE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case DELETE_MESSAGE_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: true };
    case DELETE_MESSAGE_CATEGORY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const updateMessageCategorySortReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_MESSAGE_CATEGORY_SORT_IDLE:
      return { error: null, loading: false, success: false };
    case UPDATE_MESSAGE_CATEGORY_SORT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MESSAGE_CATEGORY_SORT_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_MESSAGE_CATEGORY_SORT_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getAutoReplyMessageReducer = (
  state = { error: null, loading: false, message: null, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AUTO_REPLY_MESSAGE_REQUEST:
      return { error: null, loading: true, message: null, success: false };
    case GET_AUTO_REPLY_MESSAGE_SUCCESS:
      return { ...state, loading: false, message: payload, success: true };
    case GET_AUTO_REPLY_MESSAGE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const setAutoReplyMessageReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTO_REPLY_MESSAGE_IDLE:
      return { error: null, loading: false, success: false };
    case SET_AUTO_REPLY_MESSAGE_REQUEST:
      return { ...state, loading: true };
    case SET_AUTO_REPLY_MESSAGE_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_AUTO_REPLY_MESSAGE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMessageListReducer = (
  state = { error: null, list: {}, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGE_LSIT_REQUEST:
      return { error: null, list: {}, loading: true, success: false };
    case GET_MESSAGE_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case GET_MESSAGE_LIST_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMessageCategoryOptionsReducer = (
  state = { error: null, loading: false, options: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGE_CATEGORY_OPTIONS_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case GET_MESSAGE_CATEGORY_OPTIONS_SUCCESS:
      return { ...state, lodaing: false, options: payload, success: true };
    case GET_MESSAGE_CATEGORY_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getMessageSubCategoryOptionsReducer = (
  state = { error: null, loading: false, options: [], success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGE_SUB_CATEGORY_OPTIONS_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case GET_MESSAGE_SUB_CATEGORY_OPTIONS_SUCCESS:
      return { ...state, lodaing: false, options: payload, success: true };
    case GET_MESSAGE_SUB_CATEGORY_OPTIONS_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getOverallMessageReducer = (
  state = { data: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_OVERALL_MESSAGE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case GET_OVERALL_MESSAGE_SUCCESS:
      return { ...state, data: payload, lodaing: false, success: true };
    case GET_OVERALL_MESSAGE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const getSingleMessageReducer = (
  state = { datas: [], error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SINGLE_MESSAGE_REQUEST:
      return { datas: [], error: null, loading: true, success: false };
    case GET_SINGLE_MESSAGE_SUCCESS:
      return { ...state, datas: payload, loading: false, success: true };
    case GET_SINGLE_MESSAGE_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const createMessageReplyReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_MESSAGE_REPLY_IDLE:
      return { error: null, loading: false, success: false };
    case CREATE_MESSAGE_REPLY_REQUEST:
      return { ...state, loading: true };
    case CREATE_MESSAGE_REPLY_SUCCESS:
      return { ...state, loading: false, success: true };
    case CREATE_MESSAGE_REPLY_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};

export const setMessageClosedReducer = (
  state = { error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGE_CLOSED_IDLE:
      return { error: null, loading: false, success: false };
    case SET_MESSAGE_CLOSED_REQUEST:
      return { ...state, loading: true };
    case SET_MESSAGE_CLOSED_SUCCESS:
      return { ...state, loading: false, success: true };
    case SET_MESSAGE_CLOSED_FAIL:
      return { ...state, error: payload, loading: false };
    default:
      return state;
  }
};
