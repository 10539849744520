import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';

const isSellOptions = [
  {
    label: '全選',
    value: null,
  },
  {
    label: '上架',
    value: true,
  },
  {
    label: '下架',
    value: false,
  },
];

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  assignGroupModelToGlobalCat,
  getGroupModelGlobalCatList,
  getGroupModelList,
  removeGlobalCatGroupModel,
} from '../redux/actions/data/modelActions';
import {
  GLOBAL_CAT_MODEL_ASSIGN_RESET,
  GLOBAL_CAT_MODEL_REMOVE_RESET,
} from '../redux/constants/data/modelConstants';
import style from '../styles/layout.module.css';
import APIUtil from '../utils/APIUtil';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';

function AddGroupModal({
  isOpenModal = false,
  toggleModal = () => {},
  existProductIds = [],
}) {
  const dispatch = useDispatch();

  const { modelListInfo, loading } = useSelector(state => state.modelList);
  const { cateId } = useParams();

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.globalCatAssignModel);

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      isSell: null,
      keyword: '',
      page: 1,
    },
    onSubmit: values => {
      dispatch(
        getGroupModelList({
          ...values,
          page_size: 10,
        }),
      );
    },
  });

  useEffect(() => {
    if (addSuccess) {
      toggleModal(true);
      MessageUtil.toastSuccess('新增成功!!');
    }

    if (addError) {
      if (addError.code === APIUtil.Code_Product_Extensions_Not_Exists) {
        MessageUtil.alertWanring('商品不存在', '');
      }
    }

    return () => {
      dispatch({ type: GLOBAL_CAT_MODEL_ASSIGN_RESET });
    };
  }, [addSuccess, addError]);

  const [selProductIds, setSelProductIds] = useState([]);
  const handleCheck = (id, checked) => {
    if (checked) {
      setSelProductIds([...selProductIds, id]);
    } else {
      setSelProductIds(selProductIds.filter(productId => productId !== id));
    }
  };

  const callAssignToGlobalCategory = () => {
    if (selProductIds.length === 0) {
      MessageUtil.alertWanring('請新增商品', '');
    } else {
      dispatch(
        assignGroupModelToGlobalCat({
          globalCateId: +cateId,
          modelIds: selProductIds,
        }),
      );
    }
  };

  useEffect(() => {
    console.log(existProductIds);
    setSelProductIds([...existProductIds]);
  }, [isOpenModal, existProductIds]);

  useEffect(() => {
    handleSubmit();
  }, [values.page]);

  return (
    <Modal
      zIndex={1000}
      centered
      size="xl"
      isOpen={isOpenModal}
      toggle={toggleModal}
      backdrop="static"
    >
      <ModalHeader className={style.modal_head} toggle={toggleModal}>
        新增類別商品
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className="form-inline">
          <form className="d-flex flex-wrap">
            <div className="form-group me-2 mb-2">
              <input
                className="form-control w200"
                placeholder="關鍵字"
                value={values.keyword}
                onChange={e => setFieldValue('keyword', e.target.value)}
              />
            </div>
            <div className="form-group me-2 mb-2">
              <Select
                className={`ml-2 ${style['form-select']}`}
                placeholder="上架狀態"
                value={isSellOptions.filter(o => o.value === values.isSell)[0]}
                options={isSellOptions}
                onChange={option => {
                  setFieldValue('isSell', option.value);
                }}
              />
            </div>
            <div>
              <Button
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                搜尋
              </Button>
            </div>
          </form>
        </div>

        <div className="text-danger mr-auto">
          ※同商品編碼可以指定多個商品類別
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {modelListInfo?.total_count > 0 ? modelListInfo?.total_count : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>型號</th>
                <th>商品</th>
                <th>品牌</th>
                <th>官網上架</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {modelListInfo?.datas ? (
                  modelListInfo?.datas?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selProductIds.find(id => id === item.id)}
                          onChange={e => handleCheck(item.id, e.target.checked)}
                        />
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{item.model}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{item.name}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{item.brand}</td>
                      <td>{item.isSell ? '是' : '否'}</td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={modelListInfo?.current_page || 1}
            pageSize={modelListInfo?.page_size || 0}
            total={modelListInfo?.total_count || 0}
            onChange={page => {
              setFieldValue('page', page);
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="light"
          className={style.modal_button_cancel}
          onClick={toggleModal}
        >
          取消
        </Button>{' '}
        <Button
          type="button"
          color="primary"
          className={style.modal_button_submit}
          onClick={callAssignToGlobalCategory}
          disabled={addLoading}
        >
          {addLoading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function GroupPrdCategoryPrds() {
  const dispatch = useDispatch();
  const { cateId } = useParams();
  const { values, handleSubmit, setFieldValue, handleReset } = useFormik({
    initialValues: {
      isSell: null,
      keyword: '',
      page: 1,
    },
    onReset: () => {
      const params = {
        globalCateId: +cateId,
        isSell: null,
        keyword: '',
        page: 1,
      };
      dispatch(getGroupModelGlobalCatList(params));
    },
    onSubmit: values => {
      const params = {
        ...values,
        globalCateId: +cateId,
      };
      dispatch(getGroupModelGlobalCatList(params));
    },
  });

  const { loading, modelGlobalCatListInfo } = useSelector(
    state => state.modelGlobalCatList,
  );

  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.globalCatRemoveModel,
  );

  const [modal, setModal] = useState(false);

  useEffect(() => {
    handleSubmit();
  }, [values.page]);

  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      setFieldValue('page', 1);
      handleSubmit();
    }
    if (deleteError) {
      MessageUtil.alertWanring('資料錯誤', '');
    }
    return () => {
      dispatch({ type: GLOBAL_CAT_MODEL_REMOVE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleRemoveGlobalCatModel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(
        removeGlobalCatGroupModel({
          globalCateId: +cateId,
          modelIds: [id],
        }),
      );
    });
  };

  const goToExportPage = useCallback(() => {
    history.push(`/ProductCategoryPrdsExport?type=group&cateId=${cateId}`);
  }, []);

  return (
    <Layout
      pageTitle={!loading && `${modelGlobalCatListInfo?.globalCateName} 設定`}
      items={[
        { isActive: false, page_name: '商品管理', to: '' },
        {
          isActive: false,
          page_name: '商品分類管理',
          to: '/CategoryControl',
        },
        { isActive: true, page_name: '組合商品類別管理' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="keyword"
                  style={{ lineHeight: '48px', width: '60px' }}
                >
                  關鍵字
                </label>
                <input
                  type="text"
                  className="form-control w200"
                  id="keyword"
                  value={values.keyword}
                  onChange={e => {
                    setFieldValue('keyword', e.target.value);
                  }}
                  placeholder="商品名稱"
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  htmlFor="number"
                  style={{ lineHeight: '48px', width: '45px' }}
                >
                  上架狀態
                </label>
                <Select
                  className={`ml-2 ${style['form-select']}`}
                  placeholder="上架狀態"
                  value={
                    isSellOptions.filter(o => o.value === values.isSell)[0]
                  }
                  options={isSellOptions}
                  onChange={option => {
                    setFieldValue('isSell', option.value);
                  }}
                />
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                color="light"
                className={style.modal_button_cancel}
                onClick={handleReset}
                style={{ marginRight: '1rem' }}
              >
                取消
              </Button>
              <Button
                type="submit"
                className={style.modal_button_submit}
                onClick={handleSubmit}
              >
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="d-flex gap-2">
          <button
            className="btn btn-success w100"
            onClick={() => {
              setModal(true);
            }}
          >
            新增
          </button>
          <button className="btn btn-success w100" onClick={goToExportPage}>
            匯出/匯入
          </button>
        </div>

        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && modelGlobalCatListInfo?.total_count > 0
              ? modelGlobalCatListInfo?.total_count
              : '0'}
          </span>
          筆
        </div>

        <div className={style.table_overflow}>
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>排序</th>
                <th>商品</th>
                <th>官網上架</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {modelGlobalCatListInfo?.datas?.length > 0 ? (
                  modelGlobalCatListInfo?.datas?.map((data, index) => (
                    <tr key={index}>
                      <td nowrap="nowrap" align="center">{data.sort ?? '--'}</td>

                      <td  nowrap="nowrap">
                        {data.groupModelName}
                      </td>
                      <td>{data.isSell ? '是' : '否'}</td>
                      <td>
                        <Button
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => handleRemoveGlobalCatModel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <RcPagination
            current={modelGlobalCatListInfo?.current_page || 1}
            pageSize={modelGlobalCatListInfo?.page_size || 10}
            total={modelGlobalCatListInfo?.total_count || 0}
            onChange={page => {
              setFieldValue('page', page);
            }}
          />
        </div>
      </Card>
      <AddGroupModal
        existProductIds={modelGlobalCatListInfo?.datas?.map(d => d.id) || []}
        isOpenModal={modal}
        toggleModal={isReload => {
          if (isReload) {
            handleSubmit();
          }
          setModal(false);
        }}
      />
    </Layout>
  );
}
export default GroupPrdCategoryPrds;
