import RcPagination from 'rc-pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import { getBestSellerBrandList } from '../redux/actions/data/salesActions';
import style from '../styles/layout.module.css';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const BestSeller = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { location } = history;

  const [current_page, setCurrentPage] = useState(1);
  const [brandId, setBrandId] = useState({ label: '請選擇品牌', value: 0 });

  const { userInfo } = useSelector(state => state.userLogin);
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { loading, brandListInfo, error } = useSelector(
    state => state.bestSellerBrandList,
  );

  // console.log(brandListInfo)

  useEffect(() => {
    dispatch(getBrandOptions());
  }, []);

  // useEffect(() => {
  //   if (error && error.status === 401) {
  //     dispatch(userLogout());
  //     history.push({ pathname: '/Login', state: '/BestSeller' });
  //     MessageUtil.alertWanring('請重新登入取得授權');
  //   } else if (userInfo) {
  //     //比對token時間
  //     if (moment(Date.now()).isAfter(new Date(userInfo.authValidTime))) {
  //       dispatch(userLogout());
  //       history.push({ pathname: '/Login', state: '/BestSeller' });
  //       MessageUtil.alertWanring('請重新登入取得授權');
  //     }
  //   }
  // }, [userInfo, error]);

  useEffect(() => {
    if (location.search) {
      const queryBrandId = query.value.brandId;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryBrandId) {
        setBrandId({ ...brandId, value: queryBrandId });

        //儲存搜尋及頁數
        dispatch(
          getBestSellerBrandList({
            brandId: queryBrandId,
            page: queryPage,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getBestSellerBrandList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setBrandId({ label: '請選擇品牌', value: 0 });

      //初始搜尋
      dispatch(getBestSellerBrandList({ page: 1 }));
    }
  }, [location.search]);

  const handleSearch = e => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getBestSellerBrandList({ brandId: brandId.value, page: 1 }));

    history.push(`${location.pathname}?brandId=${brandId.value}&page=${1}`);
  };

  const clearSearchForm = () => {
    setCurrentPage(1);
    setBrandId({ label: '請選擇品牌', value: 0 });
    dispatch(getBestSellerBrandList({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(getBestSellerBrandList({ page }));
    if (brandId.value > 0) {
      history.push(
        `${location.pathname}?brandId=${brandId.value}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  useEffect(() => {
    if (brandListInfo) {
      if (JSON.stringify(brandListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: brandListInfo });
      }
    }
  }, [brandListInfo]);

  return (
    <Layout
      pageTitle="Best Seller"
      items={[{ isActive: true, page_name: 'BestSeller' }]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className={style.formGroup} style={{ marginRight: '1rem' }}>
              <label className="col-form-label mr-2">品牌</label>
              <Select
                isDisabled={brandOptionsLoading}
                isLoading={brandOptionsLoading}
                className={style['form-select']}
                options={
                  brandOptions && [
                    { label: '請選擇品牌', value: '0' },
                    ...brandOptions,
                  ]
                }
                value={brandId}
                onChange={option => setBrandId(option)}
              />
            </div>
            {/* <FormGroup className="align-items-center mb-0">
              <input type="checkbox" id="top" className="me-2" />
              <label htmlFor="top">TOP商品</label>
            </FormGroup> */}
            <div className="float-end">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && brandListInfo && brandListInfo.total_count > 0
              ? brandListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>品牌</th>
                <th>商品</th>
                <th>TOP商品</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {brandListInfo ? (
                  brandListInfo.datas.map((data, index) => (
                    <tr key={data.brandId}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {data.brandName}
                      </td>
                      <td>{data.prdCount}項</td>
                      <td>{data.showCount}項</td>
                      <td>
                        <Link
                          className="m-2 btn btn-outline-primary btn-sm"
                          to={`/BestSellerSetting/${data.brandId}`}
                        >
                          設定商品
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {!loading && brandListInfo && brandListInfo.total_count > 0 && (
            <div className="d-flex align-items-center justify-content-center">
              <RcPagination
                defaultCurrent={brandListInfo.current_page}
                defaultPageSize={brandListInfo.page_size}
                total={brandListInfo.total_count}
                onChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default BestSeller;
