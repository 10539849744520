import Layout from 'components/Layout';
import EditScheduleSettingModal from 'components/shopBannerSetting/EditScheduleSettingModal';
import EditSettingModal from 'components/shopBannerSetting/EditSettingModal';
import { useFormik } from 'formik';
import RcPagination from 'rc-pagination';
import { useEffect } from 'react';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  createNewShopBanner,
  deleteShopBanner,
  getOneShopBanner,
  getShopBannerColor,
  getShopBannerList,
  sortingShopBanner,
  updateShopBannerColor,
} from 'redux/actions/data/shopBannerActions';
import {
  CREATE_NEW_SHOP_BANNER_IDLE,
  CREATE_SHOP_BANNER_SCHEDULE_IDLE,
  DELETE_SHOP_BANNER_IDLE,
  SORTING_SHOP_BANNER_IDLE,
  UPDATE_SHOP_BANNER_COLOR_IDLE,
  UPDATE_SHOP_BANNER_IDLE,
} from 'redux/constants/data/shopBannerConstants';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import style from '../styles/layout.module.css';

const SortingModal = ({ isOpen, toggle, onCancel }) => {
  const [sortList, setSortList] = useState([]);
  const dispatch = useDispatch();

  const { list: bannerList } = useSelector(state => state.getShopBannerList);

  useEffect(() => {
    dispatch(
      getShopBannerList({
        page: 0,
        page_size: 0,
      }),
    );
  }, []);

  useEffect(() => {
    setSortList(bannerList.datas);
  }, [bannerList]);

  const handleOnDragEnd = result => {
    const items = Array.from(sortList);
    const [newOrderItem] = items.splice(result?.source?.index, 1);
    items.splice(result?.destination?.index, 0, newOrderItem);
    setSortList(items);
  };

  const handleUpdateSort = () => {
    const body = sortList.map((item, index) => ({
      id: item.id,
      sort: index + 1,
    }));
    dispatch(sortingShopBanner(body));
    toggle();
  };

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader
        toggle={() => {
          toggle();
          onCancel && onCancel();
        }}
        className={style.modal_head}
      >
        商城折扣活動排序
      </ModalHeader>
      <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="brands">
            {provided => (
              <ul
                className="drag_list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {sortList?.map(({ id, fileUrl, fileUrl_mobile }, index) => (
                  <Draggable key={id} draggableId={`${id}`} index={index}>
                    {provided => (
                      <li
                        className="drag_data"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <div className={style.show_flex}>
                          <span className="title" style={{ height: '100%' }}>
                            {index + 1}
                          </span>
                          <img className={`${style.w100} ml-2`} src={fileUrl} />
                          <img
                            className={`${style.w100} ml-2`}
                            src={fileUrl_mobile}
                          />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="light"
          className={style.modal_button_cancel}
          onClick={() => {
            toggle();
            onCancel && onCancel();
          }}
        >
          取消
        </Button>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={handleUpdateSort}

          // disabled={sortLoading}
        >
          {/* {sortLoading && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )} */}
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const EditMenuColorModal = ({ isOpen, toggle }) => {
  const dispatch = useDispatch();
  const { data: color } = useSelector(state => state.getShopBannerColor);
  const { values, setFieldValue, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: color?.content,
    },
    onSubmit: () => {
      dispatch(updateShopBannerColor(values.color));
    },
  });

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered zIndex={2000} size="lg">
      <ModalHeader className={style.modal_head} toggle={toggle}>
        商城形象圖
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <div className={`${style.formGroup} ml-2`}>
          <label className="required w150">主選單圖標顏色</label>
          <div className={style.show_flex} style={{ marginRight: '1.5rem' }}>
            <input
              type="radio"
              style={{ height: '20px', width: '20px' }}
              checked={values.color === 'white'}
              onChange={() => {
                setFieldValue('color', 'white');
              }}
            />
            <span className="ml-2">白色</span>
          </div>
          <div className={style.show_flex}>
            <input
              type="radio"
              style={{ height: '20px', width: '20px' }}
              checked={values.color === 'black'}
              onChange={() => {
                setFieldValue('color', 'black');
              }}
            />
            <span className="ml-2">黑色</span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className={style.modal_button_submit}
          onClick={handleSubmit}
        >
          {/* {addLoading && (
          <span
            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        )} */}
          <span className="btn-wrapper--label">儲存</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const TableList = ({
  onToggle,
  onToggleScheduleModal,
  onSetType,
  onDelete,
  onEditSchedule,
  onGetData,
}) => {
  const { list: bannerList, loading } = useSelector(
    state => state.getShopBannerList,
  );

  useEffect(() => {
    if (
      JSON.stringify(bannerList) !== '{}' &&
      JSON.stringify(bannerList) !== '[]'
    ) {
      CommonUtil.consoleLog({ anno: 'response', data: bannerList });
    }
  }, [bannerList]);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>電腦版圖片</th>
            <th>手機圖片</th>
            <th>進行中、未到期排程</th>
            <th>功能</th>
          </tr>
        </thead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {bannerList.datas?.length > 0 ? (
              bannerList.datas.map((data, index) => (
                <tr key={index}>
                  <td>
                    {/* 電腦版圖片 */}
                    <img
                      className={style.w200}
                      src={data.fileUrl}

                      // height="50%"
                    />
                  </td>
                  <td>
                    {/* 手機版圖片 */}
                    <img
                      className={style.w200}
                      src={data.fileUrl_mobile}

                      // height="50%"
                    />
                  </td>
                  <td>
                    {/* 排程 */}
                    {data.schedules.map((p, i) =>
                      p.status !== 2 ? (
                        <div
                          className="mb-3 ml-2"
                          key={i}
                          style={{ minWidth: '210px' }}
                        >
                          <div
                            className={style.show_flex}
                            style={{ maxWidth: '50%' }}
                          >
                            <div
                              className={style.activityStatus}
                              style={{
                                backgroundColor:
                                  p.status === 0
                                    ? '#0077FF'
                                    : p.status === 1
                                    ? '#1BC943'
                                    : 'transparent',
                              }}
                            >
                              {p.status === 0 ? '未開始' : '進行中'}
                            </div>
                            <div style={{ margin: 'auto' }} />
                            <FaEdit
                              style={{
                                color: '#0077ff',
                                cursor: 'pointer',
                                marginRight: '0.3rem',
                              }}
                              size={25}
                              onClick={() => {
                                onSetType('排程');
                                onGetData(p.id);
                                onEditSchedule(true);
                                onToggleScheduleModal();
                              }}
                            />
                            <MdDelete
                              color="red"
                              size={30}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onDelete(p.id);
                              }}
                            />
                          </div>
                          <div>{`起：${p.startTime}`}</div>
                          <div>{`訖：${p.endTime}`}</div>
                        </div>
                      ) : (
                        <></>
                      ),
                    )}
                  </td>
                  <td>
                    <div className={style.formGroup}>
                      <Button
                        className="me-3"
                        color="primary"
                        outline
                        onClick={() => {
                          onSetType('基本');
                          onToggle();
                          onEditSchedule(false);
                          onGetData(data.id);
                        }}
                      >
                        常態設定
                      </Button>
                      <Button
                        className="me-3"
                        color="primary"
                        outline
                        onClick={() => {
                          onSetType('排程');
                          onGetData(data.id);
                          onEditSchedule(false);
                          onToggleScheduleModal();
                        }}
                      >
                        新增排程
                      </Button>
                      <Button
                        className="me-3"
                        color="danger"
                        outline
                        onClick={() => {
                          onDelete(data.id);
                        }}
                      >
                        刪除
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

const ShopBannerSetting = () => {
  const dispatch = useDispatch();
  const [isEditMenuColorModalOpen, setIsEditMenuColorModalOpen] =
    useState(false);
  const toggleEditMenuColorModalOpen = () => {
    setIsEditMenuColorModalOpen(!isEditMenuColorModalOpen);
  };

  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const toggleSettingModal = () => {
    setIsSettingModalOpen(!isSettingModalOpen);
  };

  const [isScheduleSettingModalOpen, setIsScheduleSettingModalOpen] =
    useState(false);
  const toggleScheduleSettingModal = () => {
    setIsScheduleSettingModalOpen(!isScheduleSettingModalOpen);
  };

  const [isSortingModalOpen, setIsSortingModalOpen] = useState(false);
  const toggleSortingModal = () => {
    setIsSortingModalOpen(!isSortingModalOpen);
  };

  const [editingType, setEditingType] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [isEditSchedule, setIsEditSchedule] = useState(false);

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.createNewShopBanner);
  const { list: bannerList } = useSelector(state => state.getShopBannerList);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.deleteShopBanner,
  );
  const { success: updateSuccess, error: updateError } = useSelector(
    state => state.updateShopBanner,
  );
  const { success: createSuccess, error: createError } = useSelector(
    state => state.createShopBannerSchedule,
  );
  const { data: color } = useSelector(state => state.getShopBannerColor);
  const { success: updateColorSuccess, error: updateColorError } = useSelector(
    state => state.updateShopBannerColor,
  );
  const { success: sortingSuccess, error: sortingError } = useSelector(
    state => state.sortingShopBanner,
  );

  const refreshPage = () => {
    dispatch(
      getShopBannerList({
        page: currentPage,
        page_size: 10,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      getShopBannerList({
        page: 1,
        page_size: 10,
      }),
    );
    dispatch(getShopBannerColor());
  }, []);

  useEffect(() => {
    // 常態設定
    if (addSuccess) {
      dispatch({ type: CREATE_NEW_SHOP_BANNER_IDLE });
      refreshPage();
    }
    if (addError) {
      MessageUtil.alertWanring('新增失敗', addError);
      dispatch({ type: CREATE_NEW_SHOP_BANNER_IDLE });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (deleteSuccess) {
      MessageUtil.toastSuccess('刪除成功!');
      dispatch({ type: DELETE_SHOP_BANNER_IDLE });
      refreshPage();
    }

    if (deleteError) {
      MessageUtil.alertWanring('刪除失敗', deleteError);
      dispatch({ type: DELETE_SHOP_BANNER_IDLE });
    }
  }, [deleteSuccess, deleteError]);

  useEffect(() => {
    if (updateSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: UPDATE_SHOP_BANNER_IDLE });
      refreshPage();
      if (isEditSchedule) {
        toggleScheduleSettingModal();
      } else {
        toggleSettingModal();
      }
    }

    if (updateError) {
      MessageUtil.alertWanring('儲存失敗', updateError);
      dispatch({ type: UPDATE_SHOP_BANNER_IDLE });
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    // 排程設定
    if (createSuccess) {
      MessageUtil.toastSuccess('儲存成功!');
      dispatch({ type: CREATE_SHOP_BANNER_SCHEDULE_IDLE });
      refreshPage();
      toggleScheduleSettingModal();
    }
    if (createError) {
      MessageUtil.alertWanring('新增失敗', createError);
      dispatch({ type: CREATE_SHOP_BANNER_SCHEDULE_IDLE });
    }
  }, [createSuccess, createError]);

  useEffect(() => {
    if (updateColorSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: UPDATE_SHOP_BANNER_COLOR_IDLE });

      // refreshPage();
      dispatch(getShopBannerColor());
      toggleEditMenuColorModalOpen();
    }
    if (updateColorError) {
      MessageUtil.alertWanring('更新失敗', updateColorError);
      dispatch({ type: UPDATE_SHOP_BANNER_COLOR_IDLE });
    }
  }, [updateColorSuccess, updateColorError]);

  useEffect(() => {
    if (sortingSuccess) {
      MessageUtil.toastSuccess('更新成功!');
      dispatch({ type: SORTING_SHOP_BANNER_IDLE });
      refreshPage();

      // toggleSortingModal();
    }
    if (sortingError) {
      MessageUtil.alertWanring('更新失敗', updateColorError);
      dispatch({ type: SORTING_SHOP_BANNER_IDLE });
    }
  }, [sortingSuccess, sortingError]);

  const changePageHandler = page => {
    setCurrentPage(page);
    dispatch(
      getShopBannerList({
        page: page,
        page_size: 10,
      }),
    );
  };

  const deleteItemHandler = id => {
    dispatch(deleteShopBanner(id));
  };

  const getOneDataHandler = id => {
    dispatch(getOneShopBanner(id));
  };

  return (
    <Layout
      pageTitle="商城形象圖設定"
      items={[
        { isActive: true, page_name: '網頁設定' },
        { isActive: false, page_name: '商城形象圖設定' },
      ]}
    >
      <Card className={`${style.card} mb-5`}>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>{`主選單圖標顏色：${
            color?.content === 'black'
              ? '黑色'
              : color?.content === 'white'
              ? '白色'
              : ''
          }`}</div>
          <Button
            color="primary"
            outline
            className="btn-sm"
            onClick={toggleEditMenuColorModalOpen}
          >
            編輯
          </Button>
        </div>
      </Card>
      <Card className={style.card}>
        <div
          className="mb-4"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            color="success"
            onClick={() => {
              dispatch(createNewShopBanner());
            }}
            disabled={addLoading}
          >
            新增 Banner
          </Button>
          <Button
            color="warning"
            style={{ color: 'white' }}
            onClick={toggleSortingModal}
          >
            排序
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {`共${bannerList?.total_count || 0}筆`}
        </div>
        <TableList
          onToggle={toggleSettingModal}
          onToggleScheduleModal={toggleScheduleSettingModal}
          onSetType={setEditingType}
          onDelete={deleteItemHandler}
          onEditSchedule={setIsEditSchedule}
          onGetData={getOneDataHandler}
        />
        {bannerList?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={bannerList?.current_page}
              defaultPageSize={bannerList?.page_size}
              total={bannerList?.total_count}
              onChange={changePageHandler}
            />
          </div>
        )}
        {isEditMenuColorModalOpen && (
          <EditMenuColorModal
            isOpen={isEditMenuColorModalOpen}
            toggle={toggleEditMenuColorModalOpen}
          />
        )}
        {isSettingModalOpen && (
          <EditSettingModal
            isOpen={isSettingModalOpen}
            toggle={toggleSettingModal}
            type={editingType}
            isEditSchedule={isEditSchedule}
          />
        )}
        {isScheduleSettingModalOpen && (
          <EditScheduleSettingModal
            isOpen={isScheduleSettingModalOpen}
            toggle={toggleScheduleSettingModal}
            type={editingType}
            isEdit={isEditSchedule}
          />
        )}
        {isSortingModalOpen && (
          <SortingModal
            isOpen={isSortingModalOpen}
            toggle={toggleSortingModal}
            onCancel={refreshPage}
          />
        )}
      </Card>
    </Layout>
  );
};

export default ShopBannerSetting;
