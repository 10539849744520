import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Alert, Button, Card, CardBody, CardHeader } from 'reactstrap';
import history from 'utils/createHistory';

import Layout from '../../components/Layout';
import style from '../../styles/layout.module.css';

const SpecifyPointSettings = () => {
  return (
    <Layout
      pageTitle={'指定活動給點設定'}
      items={[
        { isActive: true, page_name: '紅利點數' },
        { isActive: false, page_name: '指定活動給點設定' },
      ]}
    >
      <Alert color="secondary">
        <div>
          <AiOutlineInfoCircle />
          <span>當會員完成指定功能後，即時贈與相對應的點數</span>
        </div>
        <div>
          <AiOutlineInfoCircle />
          <span>完成相同的動作僅即時贈點一次(相同動作點數不會重複贈送)</span>
        </div>
      </Alert>

      <span className="ml-2">請選擇指定活動</span>
      <div style={{ height: '17px' }} />
      <Card className={style.search_card}>
        <CardHeader
          className={`${style.card_header} ${style.card_header_dark}`}
          style={{ backgroundColor: '#1DB4B9' }}
        >
          指定活動
        </CardHeader>

        <CardBody
          className={style.card_body}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'left',
            minHeight: '120px',
          }}
        >
          {[
            { name: '個人資料填寫', url: '/PersonalInformation' },
            { name: '虛擬試穿戴', url: '/VirtualTryOn' },
          ].map((cate, index) => {
            return (
              <Button
                key={JSON.stringify(cate)}
                outline
                className={style['discount-btn']}
                onClick={() => {
                  history.push(`${cate.url}?type=${cate.name}`);
                }}
              >
                {cate.name}
              </Button>
            );
          })}
        </CardBody>
      </Card>
    </Layout>
  );
};

export default SpecifyPointSettings;
