import SEOSettingsConfig from 'components/SEOSettingsConfig';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { getSeoSettingsDetail } from 'redux/actions/data/seoSettingsAction';
import * as Yup from 'yup';

import CustomColorPicker from '../components/CustomColorPicker';
import Layout from '../components/Layout.js';
import UploadFileBox from '../components/UploadFileBox';
import {
  getBrandDetails,
  updateBrandDetails,
} from '../redux/actions/data/brandActions';
import { BRAND_UPDATE_RESET } from '../redux/constants/data/brandConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import { getImageDataFromSetting } from '../utils/getImageDataFromSetting';

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '150px',
};
const lableStyle = { fontWeight: '400', minWidth: '200px', padding: '0px' };

const TabItem = ({ currentTab, tab, onClick, text }) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => onClick(tab)}
        active={currentTab === tab}
        className={`${
          currentTab === tab
            ? style.modal_button_submit
            : style.modal_button_cancel
        } ${tab === 4 ? style.modal_button_submit_extend : ''}`}
      >
        <span style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{text}</span>
      </NavLink>
    </NavItem>
  );
};

export default function BrandSetting(props) {
  const dispatch = useDispatch();
  const { brandId } = useParams();

  const { loading, settingData } = useSelector(state => state.brandDetails);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector(state => state.brandUpdate);

  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    if (updateSuccess) {
      window.scrollTo(0, 0);
      history.goBack();
      MessageUtil.toastSuccess('新增成功', '');
      dispatch({ type: BRAND_UPDATE_RESET });
    }

    if (updateError) {
      MessageUtil.toastWarning(updateError.message);
    }
  }, [updateSuccess, updateError]);

  useEffect(() => {
    dispatch(getBrandDetails(brandId));
    dispatch(getSeoSettingsDetail({ sourceId: brandId, type: 15 }));
  }, []);

  /************  form *************/
  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      background_files: Yup.array().min(1, '請上傳圖片'),
      banner_bottom_files: Yup.array().min(1, '請上傳圖片'),
      banner_bottom_mobile_files: Yup.array().min(1, '請上傳圖片'),
      banner_files: Yup.array().min(1, '請上傳圖片'),
      banner_files_mobile: Yup.array().min(1, '請上傳圖片'),
      bgColor_top: Yup.string().required('必填!'),
      introBlock_files: Yup.array().min(1, '請上傳圖片'),
      introBlock_mobile_files: Yup.array().min(1, '請上傳圖片'),
      introColor: Yup.string().required('請選擇顏色'),
      introTitle: Yup.string().required('請輸入品牌介面標題'),
      introTitle_en: Yup.string().required('請輸入品牌介面標題(英)'),
      introduction_en: Yup.string().required('請輸入品牌說明(英)'),
      introdution: Yup.string().required('請輸入品牌說明'),
      listTitle: Yup.string().required('請輸入商品列表標題'),
      listTitle_en: Yup.string().required('請輸入商品列表標題(英)'),
      logoColor: Yup.string().required('請選擇顏色'),
      logo_files: Yup.array().min(1, '請上傳圖片'),
      navColor: Yup.string().required('請選擇顏色'),
      priceColor: Yup.string().required('請選擇顏色'),
      warranty: Yup.string().required('請輸入商品保固說明'),
      warranty_en: Yup.string().required('請輸入商品保固說明(英)'),
    });
    return schema;
  };

  const handleSubmit = values => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (!key.includes('files')) {
        if (typeof values[key] === 'object') {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          if (values[key] === null) {
            formData.append(key, 0);
          } else {
            formData.append(key, String(values[key]));
          }
        }
      }
    });

    if (values.background_files.length > 0) {
      formData.set('background_files', values.background_files[0]);
    } else {
      if (values.backgroundUrl && values.backgroundImg) {
        formData.set(
          'background_files',
          getImageDataFromSetting(
            values.backgroundImg,
            values.backgroundUrl,
          )[0],
        );
      }
    }

    if (values.banner_bottom_files.length > 0) {
      formData.set('banner_bottom_files', values.banner_bottom_files[0]);
    } else {
      if (values.bannerImgUrl_bottom && values.bannerImg_bottom) {
        formData.set(
          'banner_bottom_files',
          getImageDataFromSetting(
            values.bannerImg_bottom,
            values.bannerImgUrl_bottom,
          )[0],
        );
      }
    }

    if (values.banner_bottom_mobile_files.length > 0) {
      formData.set(
        'banner_bottom_mobile_files',
        values.banner_bottom_mobile_files[0],
      );
    } else {
      if (values.bannerImg_bottom_mobile && values.bannerImgUrl_bottom_mobile) {
        formData.set(
          'banner_bottom_mobile_files',
          getImageDataFromSetting(
            values.bannerImg_bottom_mobile,
            values.bannerImgUrl_bottom_mobile,
          )[0],
        );
      }
    }

    if (values.banner_files.length > 0) {
      formData.set('banner_files', values.banner_files[0]);
    } else {
      if (values.bannerImg && values.bannerUrl) {
        formData.set(
          'banner_files',
          getImageDataFromSetting(values.bannerImg, values.bannerUrl)[0],
        );
      }
    }

    if (values.banner_files_mobile.length > 0) {
      formData.set('banner_files_mobile', values.banner_files_mobile[0]);
    } else {
      if (values.bannerImg_mobile && values.bannerUrl_mobile) {
        formData.set(
          'banner_files_mobile',
          getImageDataFromSetting(
            values.bannerImg_mobile,
            values.bannerUrl_mobile,
          )[0],
        );
      }
    }

    if (values.introBlock_files.length > 0) {
      formData.set('introBlock_files', values.introBlock_files[0]);
    } else {
      if (values.introBlockImg && values.introBlockImgUrl) {
        formData.set(
          'introBlock_files',
          getImageDataFromSetting(
            values.introBlockImg,
            values.introBlockImgUrl,
          ),
        )[0];
      }
    }

    if (values.introBlock_mobile_files.length > 0) {
      formData.set(
        'introBlock_mobile_files',
        values.introBlock_mobile_files[0],
      );
    } else {
      if (values.introBlockImg_mobile && values.introBlockImgUrl_mobile) {
        formData.set(
          'introBlock_mobile_files',
          getImageDataFromSetting(
            values.introBlockImg_mobile,
            values.introBlockImgUrl_mobile,
          )[0],
        );
      }
    }

    if (values.logo_files.length > 0) {
      formData.set('logo_files', values.logo_files[0]);
    } else {
      if (values.logoImg && values.logoUrl) {
        formData.set(
          'logo_files',
          getImageDataFromSetting(values.logoImg, values.logoUrl)[0],
        );
      }
    }

    dispatch(updateBrandDetails(formData));
  };

  const handlePreview = () => {
    history.push(`/BrandPreview/${brandId}`);
  };

  return (
    <Layout
      pageTitle={!loading && `${settingData.name}品牌設定`}
      items={[
        { isActive: false, page_name: '品牌管理', to: '/BrandControl' },
        { isActive: true, page_name: '品牌設定' },
      ]}
    >
      <div className="text-end">
        <Button
          className="m-2 btn btn-sm"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          }}
          onClick={handlePreview}
        >
          <FaEye style={{ color: '#f4772e' }} />
          <span className="ms-2" style={{ color: '#f4772e' }}>
            預覽
          </span>
        </Button>
      </div>
      <Nav pills className="mb-2">
        <TabItem
          onClick={setCurrentTab}
          tab={1}
          text="品牌設定"
          currentTab={currentTab}
        />
        <TabItem
          onClick={setCurrentTab}
          tab={2}
          text="SEO設定"
          currentTab={currentTab}
        />
      </Nav>
      <TabContent activeTab={currentTab}>
        <TabPane tabId={1}>
          <Card className={style.card}>
            {!loading && settingData && (
              <Formik
                enableReinitialize
                initialValues={{
                  ...settingData,
                  background_files: getImageDataFromSetting(
                    settingData.backgroundImg,
                    settingData.backgroundUrl,
                  ),
                  background_init_files: getImageDataFromSetting(
                    settingData.backgroundImg,
                    settingData.backgroundUrl,
                  ),
                  bannerColor: settingData?.bannerColor ?? '',
                  banner_bottom_files: getImageDataFromSetting(
                    settingData.bannerImg_bottom,
                    settingData.bannerImgUrl_bottom,
                  ),
                  banner_bottom_init_files: getImageDataFromSetting(
                    settingData.bannerImg_bottom,
                    settingData.bannerImgUrl_bottom,
                  ),
                  banner_bottom_mobile_files: getImageDataFromSetting(
                    settingData.bannerImg_bottom_mobile,
                    settingData.bannerImgUrl_bottom_mobile,
                  ),
                  banner_bottom_mobile_init_files: getImageDataFromSetting(
                    settingData.bannerImg_bottom_mobile,
                    settingData.bannerImgUrl_bottom_mobile,
                  ),
                  banner_files: getImageDataFromSetting(
                    settingData.bannerImg,
                    settingData.bannerUrl,
                  ),
                  banner_files_mobile: getImageDataFromSetting(
                    settingData.bannerImg_mobile,
                    settingData.bannerUrl_mobile,
                  ),
                  banner_init_files: getImageDataFromSetting(
                    settingData.bannerImg,
                    settingData.bannerUrl,
                  ),
                  banner_init_files_mobile: getImageDataFromSetting(
                    settingData.bannerImg_mobile,
                    settingData.bannerUrl_mobile,
                  ),
                  bgColor_bottom: settingData.bgColor_bottom ?? '',
                  bgColor_top: settingData.bgColor_top ?? '',
                  brandId: brandId,
                  fbLink: settingData?.fbLink ?? '',
                  igLink: settingData?.igLink ?? '',
                  introBlock_files: getImageDataFromSetting(
                    settingData.introBlockImg,
                    settingData.introBlockImgUrl,
                  ),
                  introBlock_init_files: getImageDataFromSetting(
                    settingData.introBlockImg,
                    settingData.introBlockImgUrl,
                  ),
                  introBlock_mobile_files: getImageDataFromSetting(
                    settingData.introBlockImg_mobile,
                    settingData.introBlockImgUrl_mobile,
                  ),
                  introBlock_mobile_init_files: getImageDataFromSetting(
                    settingData.introBlockImg_mobile,
                    settingData.introBlockImgUrl_mobile,
                  ),
                  introColor: settingData.introColor ?? 'black',
                  introTitle: settingData?.introTitle ?? '',
                  introTitle_en: settingData.introTitle_en ?? '',
                  introduction_en: settingData.introduction_en ?? '',
                  introdution: settingData.introdution ?? '',
                  lineLink: settingData?.lineLink ?? '',
                  listTitle: settingData.listTitle ?? '',
                  listTitle_en: settingData.listTitle_en ?? '',
                  logoColor: settingData.logoColor ?? 'black',
                  logo_files: getImageDataFromSetting(
                    settingData.logoImg,
                    settingData.logoUrl,
                  ),
                  logo_init_files: getImageDataFromSetting(
                    settingData.logoImg,
                    settingData.logoUrl,
                  ),
                  navColor: settingData.navColor ?? 'black',
                  pinLink: settingData?.pinLink ?? '',
                  prd_left_files: getImageDataFromSetting(
                    settingData.prdImg_left,
                    settingData.prdImg_left_url,
                  ),

                  prd_left_init_files: getImageDataFromSetting(
                    settingData.prdImg_left,
                    settingData.prdImg_left_url,
                  ),
                  prd_right_files: getImageDataFromSetting(
                    settingData.prdImg_right,
                    settingData.prdImg_right_url,
                  ),
                  prd_right_init_files: getImageDataFromSetting(
                    settingData.prdImg_right,
                    settingData.prdImg_right_url,
                  ),
                  priceColor: settingData.priceColor ?? '',
                  status: settingData.status ?? '上架',
                  twitterLink: settingData?.twitterLink ?? '',
                  warranty: settingData.warranty ?? '',
                  warranty_en: settingData.warranty_en ?? '',
                }}
                validationSchema={getValidationSchema}
              >
                {props => (
                  <Form className="form-list form-label-140">
                    <h4 className="title" style={titleStyle}>
                      品牌總頁
                    </h4>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌logo
                      </label>
                      <div className="form-item brand-logo-color">
                        <UploadFileBox
                          formFieldName="logo_files"
                          currentFileFieldName="logo_init_files"
                          uplodModel="svg"
                          displayFileWidth="不限"
                          displayFileHeight="不限"
                          displayFileSize="1MB"
                          initFiles={props.values.logo_init_files}
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue('logo_files', files);
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('logoUrl', '');
                              props.setFieldValue('logo_files', []);
                            }
                          }}
                        />
                        <div className="form-error">
                          {props.errors?.logo_files}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌logo背景圖
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          formFieldName="background_files"
                          currentFileFieldName="background_init_files"
                          uplodModel="images"
                          displayFileWidth="405"
                          displayFileHeight="300"
                          displayFileSize="1MB"
                          initFiles={props.values.background_init_files}
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue('background_files', files);
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('backgroundUrl', '');
                              props.setFieldValue('background_files', []);
                            }
                          }}
                        />
                        <div
                          name="background_current_files"
                          className="form-error"
                        >
                          {props.errors?.background_files}
                        </div>
                      </div>
                    </FormGroup>
                    <h4 className="title" style={titleStyle}>
                      品牌頁
                    </h4>
                    <p className="bg-light p-1 mb-5">
                      <strong>【品牌頁相關設定】</strong>
                    </p>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        主選單圖標顏色
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Label className="me-3">
                            <Field name="navColor" type="radio" value="black" />
                            黑色
                          </Label>
                          <Label className="me-3">
                            <Field name="navColor" type="radio" value="white" />
                            白色
                          </Label>
                          <div name="logoColor" className="form-error">
                            {props.errors?.navColor}
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌介紹背景顏色
                      </label>
                      <div className="form-item">
                        <CustomColorPicker
                          initColor={props.values.bgColor_top}
                          formFieldName="bgColor_top"
                          setFormFieldValue={props.setFieldValue}
                        />
                        <ErrorMessage
                          name="bgColor_top"
                          component="span"
                          className="form-error"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌介紹文字顏色：
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Label className="me-3">
                            <Field
                              name="introColor"
                              type="radio"
                              value="black"
                            />
                            黑色
                          </Label>
                          <Label className="me-3">
                            <Field
                              name="introColor"
                              type="radio"
                              value="white"
                            />
                            白色
                          </Label>
                        </div>
                        <div name="introColor" className="form-error">
                          {props.errors.introColor}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        商品售價顏色
                      </label>
                      <div className="form-item">
                        <CustomColorPicker
                          initColor={props.values.priceColor}
                          formFieldName="priceColor"
                          setFormFieldValue={props.setFieldValue}
                        />
                        <div name="priceColor" className="form-error">
                          {props.errors.priceColor}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        商品保固說明
                      </label>
                      <div className="form-item">
                        <Field
                          name="warranty"
                          rows="3"
                          as="textarea"
                          className="form-control"
                        />
                        <div
                          name="warranty"
                          component="span"
                          className="form-error"
                        >
                          {props.errors.warranty}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        商品保固說明(英)
                      </label>
                      <div className="form-item">
                        <Field
                          name="warranty_en"
                          rows="3"
                          as="textarea"
                          className="form-control"
                        />
                        <div
                          name="warranty_en"
                          component="span"
                          className="form-error"
                        >
                          {props.errors.warranty_en}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        商品列表標題
                      </label>
                      <div className="form-item">
                        <Field name="listTitle" className="form-control" />
                        <div
                          name="listTitle"
                          component="span"
                          className="form-error"
                        >
                          {props.errors.listTitle}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        商品列表標題(英)
                      </label>
                      <div className="form-item">
                        <Field name="listTitle_en" className="form-control" />
                        <div
                          name="listTitle_en"
                          component="span"
                          className="form-error"
                        >
                          {props.errors.listTitle_en}
                        </div>
                      </div>
                    </FormGroup>
                    {/* ==========================================================================  */}
                    <p className="bg-light p-1 mb-5">
                      <strong>【品牌形象圖】</strong>
                    </p>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌形象圖(電腦版)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          formFieldName="banner_files"
                          currentFileFieldName="banner_init_files"
                          uplodModel="all"
                          displayFileWidth="1920"
                          displayFileHeight="不限"
                          displayFileSize="5MB"
                          initFiles={props.values.banner_init_files}
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue('banner_files', files);
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('bannerUrl', '');
                              props.setFieldValue('banner_files', []);
                            }
                          }}
                        />
                        <div name="banner_files" className="form-error">
                          {props.errors.banner_files}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌形象圖(手機板)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          formFieldName="banner_files_mobile"
                          currentFileFieldName="banner_init_files_mobile"
                          uplodModel="all"
                          displayFileWidth="800"
                          displayFileHeight="不限"
                          displayFileSize="5MB"
                          initFiles={props.values.banner_init_files_mobile}
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue(
                                  'banner_files_mobile',
                                  files,
                                );
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('bannerUrl_mobile', '');
                              props.setFieldValue('banner_files_mobile', []);
                            }
                          }}
                        />
                        <div name="banner_files" className="form-error">
                          {props.errors.banner_files_mobile}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌形象圖logo顏色
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Label className="me-3">
                            <Field
                              name="logoColor"
                              type="radio"
                              value="white"
                            />
                            白色
                          </Label>
                          <Label className="me-3">
                            <Field
                              name="logoColor"
                              type="radio"
                              value="black"
                            />
                            黑色
                          </Label>
                          <div name="banner_files" className="form-error">
                            {props.errors.logoColor}
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    {/* ==========================================================================  */}
                    <p className="bg-light p-1 mb-5">
                      <strong>【品牌介紹區塊】</strong>
                    </p>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        圖片(電腦版)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue('introBlock_files', files);
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('introBlockImgUrl', '');
                              props.setFieldValue('introBlock_files', []);
                            }
                          }}
                          formFieldName="introBlock_files"
                          currentFileFieldName="introBlock_init_files"
                          uplodModel="all"
                          displayFileWidth="800"
                          displayFileHeight="500"
                          displayFileSize="5MB"
                          initFiles={props.values.introBlock_init_files}
                        />
                        <div name="introBlock_files" className="form-error">
                          {props.errors.introBlock_files}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        圖片(手機版)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue(
                                  'introBlock_mobile_files',
                                  files,
                                );
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue(
                                'introBlockImgUrl_mobile',
                                '',
                              );
                              props.setFieldValue(
                                'introBlock_mobile_files',
                                [],
                              );
                            }
                          }}
                          formFieldName="introBlock_mobile_files"
                          currentFileFieldName="introBlock_mobile_init_files"
                          uplodModel="all"
                          displayFileWidth="800"
                          displayFileHeight="500"
                          displayFileSize="5MB"
                          initFiles={props.values.introBlock_mobile_init_files}
                        />

                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.introBlock_mobile_files}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌介面標題
                      </label>
                      <div className="form-item">
                        <Field
                          name="introTitle"
                          type="text"
                          className="form-control"
                        />
                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.introTitle}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌介面標題(英)
                      </label>
                      <div className="form-item">
                        <Field
                          name="introTitle_en"
                          type="text"
                          className="form-control"
                        />
                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.introTitle_en}
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌說明
                      </label>
                      <div className="form-item">
                        <Field
                          name="introdution"
                          rows="4"
                          as="textarea"
                          className="form-control"
                        />
                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.introdution}
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌說明(英)
                      </label>
                      <div className="form-item">
                        <Field
                          name="introduction_en"
                          rows="4"
                          as="textarea"
                          className="form-control"
                        />
                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.introduction_en}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label style={lableStyle}>FB連結</label>
                      <div className="form-item">
                        <Field name="fbLink" className="form-control" />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label style={lableStyle}>IG連結</label>
                      <div className="form-item">
                        <Field name="igLink" className="form-control" />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label style={lableStyle}>Line連結</label>
                      <div className="form-item">
                        <Field name="lineLink" className="form-control" />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label style={lableStyle}>Twitter連結</label>
                      <div className="form-item">
                        <Field name="twitterLink" className="form-control" />
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label style={lableStyle}>Pin連結</label>
                      <div className="form-item">
                        <Field name="pinLink" className="form-control" />
                      </div>
                    </FormGroup>
                    {/*===============================================================================*/}
                    <p className="p-1 bg-light mb-2">底圖區塊</p>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌形象圖(電腦版)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue(
                                  'banner_bottom_files',
                                  files,
                                );
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue('bannerImgUrl_bottom', '');
                              props.setFieldValue('banner_bottom_files', []);
                            }
                          }}
                          formFieldName="banner_bottom_files"
                          currentFileFieldName="banner_bottom_init_files"
                          uplodModel="all"
                          displayFileWidth="1920"
                          displayFileHeight="不限"
                          displayFileSize="5MB"
                          initFiles={props.values.banner_bottom_init_files}
                        />
                        <div name="introBlock_files" className="form-error">
                          {props.errors.banner_bottom_files}
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        品牌形象圖(手機版)
                      </label>
                      <div className="form-item">
                        <UploadFileBox
                          setFormFieldValue={(field, files) => {
                            if (
                              files &&
                              files instanceof Array &&
                              files.length
                            ) {
                              if (files[0] instanceof File) {
                                props.setFieldValue(
                                  'banner_bottom_mobile_files',
                                  files,
                                );
                              }
                            } else if (
                              typeof files === 'string' &&
                              files === 'isDelete'
                            ) {
                              props.setFieldValue(
                                'bannerImgUrl_bottom_mobile',
                                '',
                              );
                              props.setFieldValue(
                                'banner_bottom_mobile_files',
                                [],
                              );
                            }
                          }}
                          formFieldName="banner_bottom_mobile_files"
                          currentFileFieldName="banner_bottom_mobile_init_files"
                          uplodModel="all"
                          displayFileWidth="800"
                          displayFileHeight="不限"
                          displayFileSize="5MB"
                          initFiles={
                            props.values.banner_bottom_mobile_init_files
                          }
                        />

                        <div
                          name="introBlock_mobile_files"
                          className="form-error"
                        >
                          {props.errors.banner_bottom_mobile_files}
                        </div>
                      </div>
                    </FormGroup>
                    <p className="p-1 bg-light mb-2">品牌狀態</p>
                    <FormGroup className="form-group mb-3 mb-3">
                      <label className="required" style={lableStyle}>
                        狀態
                      </label>
                      <div className="form-item">
                        <div className="d-flex">
                          <Label
                            className="mr-3"
                            style={{ marginRight: '1rem' }}
                          >
                            <Field name="status" type="radio" value="上架" />
                            上架
                          </Label>
                          <Label
                            className="mr-3"
                            style={{ marginRight: '1rem' }}
                          >
                            <Field name="status" type="radio" value="下架" />
                            下架
                          </Label>
                          <div name="status" className="form-error">
                            {props.errors.status}
                          </div>
                        </div>
                      </div>
                    </FormGroup>

                    <hr />
                    <div className="d-flex justify-content-center">
                      <Button
                        color="light"
                        type="button"
                        className={style.modal_button_cancel}
                        onClick={() => history.goBack()}
                      >
                        取消
                      </Button>
                      <Button
                        className={style.modal_button_submit}
                        disabled={
                          updateLoading || Object.keys(props.errors).length > 0
                        }
                        onClick={() => {
                          handleSubmit(props.values);
                        }}
                      >
                        {updateLoading && (
                          <span
                            className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        <span className="btn-wrapper--label">儲存</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Card>
        </TabPane>
        <TabPane tabId={2}>
          <SEOSettingsConfig
            info={{
              sourceId: Number(brandId),
              type: 15,
              typeName: '品牌編輯頁',
            }}
          />
        </TabPane>
      </TabContent>
    </Layout>
  );
}
