import { ErrorMessage, Field, Form, Formik } from 'formik';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';
import * as Yup from 'yup';

import Layout from '../components/Layout';
import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import {
  addFAQ,
  deleteFAQ,
  getAllFAQList,
  getFAQList,
  getFAQOptions,
  updateFAQListSort,
} from '../redux/actions/data/FAQActions';
import {
  FAQ_ADD_RESET,
  FAQ_DELETE_RESET,
} from '../redux/constants/data/FAQConstants';
import style from '../styles/layout.module.css';
import MessageUtil from '../utils/MessageUtil';
import history from '../utils/createHistory';
import useQuery from '../utils/useQuery';

const FAQCategoryListSortModal = ({ isOpen = false, toggle }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateSort = useCallback(async (list = []) => {
    setLoading(true);
    try {
      await updateFAQListSort(
        list.map((l, index) => {
          return {
            ...l,
            sort: index,
          };
        }),
      );
      dispatch(getFAQList({ page: 1 }));
      toggle()
      MessageUtil.alertSuccess('更新成功');
    } catch (e) {
      MessageUtil.alertWanring('更新失敗', e);
    }
    setLoading(false);
  }, []);

  const handleOnDragEnd = result => {
    const items = Array.from(list);
    const [newOrderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, newOrderItem);
    setList(items);
  };

  useEffect(() => {
    if (isOpen) {
      getAllFAQList().then(res => {
        setList(res?.result?.datas || []);
      });
    }
  }, [isOpen]);

  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        問題管理排序
      </ModalHeader>
      <ModalBody className={`form-list form-label-140 ${style.modal_body}`}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="brands">
            {provided => (
              <ul
                className="drag_list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {list.map((l, index) => (
                  <Draggable key={l.id} draggableId={`${l.id}`} index={index}>
                    {provided => (
                      <li
                        className="drag_data"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <span className="title">{index + 1}</span>
                        <span className="brand_name">
                          {l.title}-{l.catName}
                        </span>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="light"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          color="primary"
          type="submit"
          className={style.modal_button_submit}
          onClick={() => {
            updateSort(list);
          }}
          disabled={loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          儲存
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default function FAQCategoryList() {
  const dispatch = useDispatch();
  const { location } = history;
  const query = useQuery();

  const [current_page, setCurrentPage] = useState(1);
  const [search_keyword, setSearchKeyword] = useState('');
  const [search_status, setSearchStatus] = useState('');
  const [search_category, setSearchCategory] = useState({
    label: '請選擇分類',
    value: 0,
  });

  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState({ label: '請選擇分類', value: 0 });
  const [title, setTitle] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [content, setContent] = useState('');
  const [content_en, setContentEn] = useState('');
  const [status, setStatus] = useState('');
  const [FAQId, setFAQId] = useState(0);

  const { loading, FAQListInfo, error } = useSelector(state => state.FAQList);
  const { FAQOptions, loading: FAQOptionsLoading } = useSelector(
    state => state.FAQOptions,
  );

  const {
    loading: addLoading,
    success: addSuccess,
    error: addError,
  } = useSelector(state => state.FAQAdd);
  const { success: deleteSuccess, error: deleteError } = useSelector(
    state => state.FAQDelete,
  );

  useEffect(() => {
    if (location.search) {
      const queryKeyword = query.value.keyword;
      const queryCategory = query.value.cate;
      const queryStatus = query.value.status;
      const queryPage = query.value.page;
      setCurrentPage(queryPage);

      if (queryKeyword || queryCategory || queryStatus) {
        setSearchKeyword(queryKeyword);
        setSearchCategory({ ...search_category, value: queryCategory });
        setSearchStatus(queryStatus);

        //儲存搜尋及頁數
        dispatch(
          getFAQList({
            catId: queryCategory,
            keyword: queryKeyword,
            page: queryPage,
            status: queryStatus,
          }),
        );
      } else {
        //單純記錄頁數
        dispatch(getFAQList({ page: queryPage }));
      }
    } else {
      //初始化狀態、避免下次跳轉遺留
      setCurrentPage(1);
      setSearchKeyword('');
      setSearchStatus('');
      setSearchCategory({ label: '請選擇分類', value: 0 });

      //初始搜尋
      dispatch(getFAQList({ page: 1 }));
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getFAQOptions());
  }, []);

  useEffect(() => {
    if (addSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('新增成功!');
      dispatch(getFAQList({ page: current_page }));
      handleOpenAddModal();
      dispatch({ type: FAQ_ADD_RESET });
    }

    if (addError) {
      MessageUtil.alertWanring(addError);
      dispatch({ type: FAQ_ADD_RESET });
    }
  }, [addSuccess, addError]);

  useEffect(() => {
    if (deleteSuccess) {
      window.scrollTo(0, 0);
      MessageUtil.toastSuccess('刪除成功!');
      dispatch(getFAQList({ page: current_page }));

      if (FAQListInfo?.datas?.length !== 1) {
        dispatch(getFAQList({ page: current_page }));
      } else {
        dispatch(getFAQList({ page: current_page - 1 }));

        history.push(
          `${location.pathname}?keyword=${search_keyword}&cate=${
            search_category.value
          }&status=${search_status}&page=${current_page - 1}`,
        );

        setCurrentPage(current_page - 1);
      }
    }

    if (deleteError) {
      MessageUtil.alertWanring(deleteError);
    }

    return () => {
      dispatch({ type: FAQ_DELETE_RESET });
    };
  }, [deleteSuccess, deleteError]);

  const handleOpenAddModal = () => {
    setModal(!modal);
    setCategory({ label: '請選擇分類', value: 0 });
    setTitle('');
    setTitleEn('');
    setContent('');
    setContentEn('');
    setStatus('');
    setFAQId(0);
  };

  const clearSearchForm = () => {
    setSearchKeyword('');
    setSearchCategory({
      label: '請選擇分類',
      value: 0,
    });
    setSearchStatus('');
    dispatch(getFAQList({ page: 1 }));
    history.push(`${location.pathname}`);
  };

  const handleSearch = e => {
    e.preventDefault();
    dispatch(
      getFAQList({
        catId: search_category.value,
        keyword: search_keyword,
        page: current_page,
        status: search_status,
      }),
    );
    history.push(
      `${location.pathname}?keyword=${search_keyword}&cate=${
        search_category.value
      }&status=${search_status}&page=${1}`,
    );
  };

  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(
      getFAQList({
        page,
      }),
    );

    if (search_keyword) {
      history.push(
        `${location.pathname}?keyword=${search_keyword}&cate=${search_category.value}&status=${search_status}&page=${page}`,
      );
    } else {
      history.push(`${location.pathname}?page=${page}`);
    }
  };

  const handleOpenEditModal = data => {
    setModal(!modal);

    const targetOption = FAQOptions.find(
      option => option.value === data.catId.toString(),
    );

    setCategory(targetOption);
    setTitle(data.title);
    setTitleEn(data.title_en);
    setContent(data.content);
    setContentEn(data.content_en);
    setStatus(data.status);
    setFAQId(data.id);
  };

  const callDel = id => {
    MessageUtil.delConfirm(() => {
      dispatch(deleteFAQ(id));
    });
  };

  const handleSubmit = values => {
    let formData = new FormData();

    formData.append('id', FAQId);
    formData.append('catId', category.value);
    formData.append('title', values.title);
    formData.append('title_en', values.title_en);
    formData.append('content', values.content);
    formData.append('content_en', values.content_en);
    formData.append('status', values.status);

    dispatch(addFAQ(formData));
  };

  const getValidationSchema = () => {
    const schema = Yup.object().shape({
      content: Yup.string().required('必填!'),
      content_en: Yup.string().required('必填!'),
      status: Yup.string().required('必填!'),
      title: Yup.string().required('必填!'),
      title_en: Yup.string().required('必填!'),
    });
    return schema;
  };

  useEffect(() => {
    if (FAQListInfo) {
      if (JSON.stringify(FAQListInfo) !== '{}') {
        CommonUtil.consoleLog({ anno: 'response', data: FAQListInfo });
      }
    }
  }, [FAQListInfo]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Layout
      pageTitle="問題管理"
      items={[
        { isActive: false, page_name: '常見問題' },
        { isActive: true, page_name: '問題管理' },
      ]}
    >
      <FAQCategoryListSortModal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
      />

      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form onSubmit={handleSearch}>
            <div className="d-flex flex-wrap">
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2" htmlFor="keyword">
                  關鍵字
                </label>
                <input
                  id="keyword"
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  placeholder="請輸入關鍵字"
                  value={search_keyword}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">分類</label>
                <Select
                  className={style['form-select']}
                  options={
                    FAQOptions && [
                      { label: '請選擇分類', value: 0 },
                      ...FAQOptions,
                    ]
                  }
                  isLoading={FAQOptionsLoading}
                  isDisabled={FAQOptionsLoading}
                  value={search_category}
                  onChange={option => setSearchCategory(option)}
                  style={{ margin: 0 }}
                />
              </div>

              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label htmlFor="search_status" className="col-form-label mr-2">
                  狀態
                </label>
                <Input
                  type="select"
                  className={style['form-select']}
                  onChange={e => setSearchStatus(e.target.value)}
                  id="search_status"
                  value={search_status}
                  style={{ margin: 0 }}
                >
                  <option value="">全部</option>
                  <option value="上架">上架</option>
                  <option value="下架">下架</option>
                </Input>
              </div>
            </div>
            <div className={style.button_position}>
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={clearSearchForm}
              >
                取消
              </Button>
              <Button type="submit" className={style.modal_button_submit}>
                查詢
              </Button>
            </div>
          </form>
        </div>
      </Card>

      <Card className={style.card}>
        <div className="d-flex justify-content-between">
          <div>
            <button
              type="button"
              className="btn btn-success w100 mb-3"
              onClick={handleOpenAddModal}
            >
              新增
            </button>
          </div>
          <span className="flex-grow-1" />
          <div>
            <Button
              type="button"
              className={style.sort_button}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              排序
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          共
          <span className="mx-1">
            {!loading && FAQListInfo && FAQListInfo.total_count > 0
              ? FAQListInfo.total_count
              : '0'}
          </span>
          筆
        </div>
        <div className="table-responsive">
          <Table hover bordered striped className="mb-5">
            <thead className={`thead-dark ${style.table_head}`}>
              <tr>
                <th>#</th>
                <th>分類</th>
                <th style={{ minWidth: '100px' }}>標題</th>
                <th style={{ minWidth: '100px' }}>內容</th>
                <th>狀態</th>
                <th style={{ minWidth: '120px' }}>異動日期</th>
                <th>功能</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {FAQListInfo ? (
                  FAQListInfo.datas.map((data, index) => (
                    <tr key={data.id}>
                      <td style={{ wordBreak: 'keep-all' }}>
                        {index + 1 + (current_page - 1) * FAQListInfo.page_size}
                      </td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.catName}</td>
                      <td style={{ wordBreak: 'keep-all' }}>{data.title}</td>
                      <td>
                        <div
                          style={{
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 5,
                            display: '-webkit-box',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {data.content}
                        </div>
                      </td>
                      <td>{data.status}</td>
                      <td>
                        <div>
                          <span>新增：</span>
                          <span className="d-inline-block me-2">
                            {data.createDate}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.createTime}
                          </span>
                          <span className="d-inline-block me-2">
                            {data.creator}
                          </span>
                        </div>
                        {data.updateDate.length > 0 && (
                          <div>
                            <span>修改：</span>
                            <span className="d-inline-block me-2">
                              {data.updateDate}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updateTime}
                            </span>
                            <span className="d-inline-block me-2">
                              {data.updater}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Button
                          className="m-2"
                          color="primary"
                          outline
                          size="sm"
                          onClick={() => handleOpenEditModal(data)}
                        >
                          編輯分類
                        </Button>
                        <Button
                          className="m-2"
                          type="button"
                          color="danger"
                          outline
                          size="sm"
                          onClick={() => callDel(data.id)}
                        >
                          刪除
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {FAQListInfo && FAQListInfo.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              defaultCurrent={FAQListInfo.current_page}
              defaultPageSize={FAQListInfo.page_size}
              total={FAQListInfo.total_count}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Card>

      <Modal
        zIndex={2000}
        centered
        size="xl"
        isOpen={modal}
        toggle={handleOpenAddModal}
        backdrop="static"
      >
        <ModalHeader toggle={handleOpenAddModal} className={style.modal_head}>
          編輯問題
        </ModalHeader>
        <Formik
          initialValues={{
            category: category,
            content: content,
            content_en: content_en,
            status: status,
            title: title,
            title_en: title_en,
          }}
          onSubmit={handleSubmit}
          validationSchema={getValidationSchema}
        >
          {props => (
            <Form className="form-list form-label-140">
              <ModalBody className={style.modal_body}>
                <div className={style.formGroup}>
                  <label className="required w100">
                    分類{' '}
                    <ErrorMessage
                      name="category"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Select
                      isLoading={FAQOptionsLoading}
                      isDisabled={FAQOptionsLoading}
                      name="category"
                      className="w200"
                      options={
                        FAQOptions && [
                          { label: '請選擇分類', value: 0 },
                          ...FAQOptions,
                        ]
                      }
                      value={category}
                      onChange={option => setCategory(option)}
                    />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required w100">
                    標題{' '}
                    <ErrorMessage
                      name="title"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="title"
                      as="input"
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">
                    標題(英){' '}
                    <ErrorMessage
                      name="title_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="title_en"
                      as="input"
                      className="form-control w-100"
                    />
                  </div>
                </div>

                <div className={style.formGroup}>
                  <label className="required w100">
                    內容{' '}
                    <ErrorMessage
                      name="content"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="content"
                      as="textarea"
                      rows="10"
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">
                    內容(英){' '}
                    <ErrorMessage
                      name="content_en"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <Field
                      name="content_en"
                      as="textarea"
                      rows="10"
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className={style.formGroup}>
                  <label className="required w100">
                    狀態{' '}
                    <ErrorMessage
                      name="status"
                      component="span"
                      className="form-error"
                    />
                  </label>
                  <div className="form-item">
                    <div className="d-flex">
                      <Field
                        className="my-auto me-2"
                        id="up"
                        name="status"
                        type="radio"
                        value="上架"
                      />
                      <label htmlFor="up" className="me-5">
                        上架
                      </label>
                      <Field
                        className="my-auto me-2"
                        id="down"
                        name="status"
                        type="radio"
                        value="下架"
                      />
                      <label htmlFor="down">下架</label>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="light"
                  className={style.modal_button_cancel}
                  onClick={handleOpenAddModal}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={style.modal_button_submit}
                  disabled={addLoading}
                >
                  {addLoading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </Layout>
  );
}
