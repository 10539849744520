import { useFormik } from 'formik';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const AddModalTableList = ({
  isShowTable,
  onChange,
  productType,
  selecteds = [],
}) => {
  const { loading, productListInfo } = useSelector(state => state.productList);
  const { values, setFieldValue, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedProducts: selecteds,
    },
  });

  useEffect(() => {
    if (onChange) {
      onChange(values.selectedProducts);
    }
  }, [values]);

  useEffect(() => {
    handleReset();
  }, [productType]);

  return (
    <div className="table-responsive mb-5">
      {isShowTable && (
        <Table hover bordered striped className="">
          <thead className={`thead-dark ${style.table_head}`}>
            <tr>
              <th>商品名稱</th>
              <th>選擇料號</th>
              <th>商品料號</th>
              <th>規格</th>
              <th style={{ maxWidth: '60px' }}>商品是否上架</th>
            </tr>
          </thead>
          {loading && <TableLoading />}
          {!loading && (
            <tbody>
              {productListInfo?.datas?.length > 0 ? (
                (productListInfo?.datas || []).map(data =>
                  (data?.products || []).map((product, pIndex) => (
                    <tr key={JSON.stringify(product) + JSON.stringify(data)}>
                      {pIndex === 0 && (
                        <td rowSpan={data.products.length}>{data.modelName}</td>
                      )}
                      <td className="text-center">
                        <input
                          type="checkbox"
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                          checked={values.selectedProducts.some(
                            p =>
                              p.productcode === product.productcode &&
                              p.mid === product.mid,
                          )}
                          onChange={event => {
                            const checked = event.target.checked;

                            if (checked) {
                              setFieldValue('selectedProducts', [
                                ...values.selectedProducts.filter(
                                  p =>
                                    p.productcode !== product.productcode &&
                                    p.mid === product.mid,
                                ),
                                {
                                  ...product,
                                },
                              ]);
                            } else {
                              setFieldValue(
                                'selectedProducts',
                                values.selectedProducts.filter(
                                  p =>
                                    p.productcode !== product.productcode &&
                                    p.mid === product.mid,
                                ),
                              );
                            }
                          }}
                        />
                      </td>
                      <td nowrap="nowrap">
                        {/*商品料號*/}
                        {product.productcode}
                      </td>
                      <td nowrap="nowrap">
                        {product.color || '--'}/{product.size || '--'}
                      </td>
                      <td>{product.isSell ? '是' : '否'}</td>
                    </tr>
                  )),
                )
              ) : (
                <TableHasNoData />
              )}
            </tbody>
          )}
        </Table>
      )}
    </div>
  );
};

export default memo(AddModalTableList);
