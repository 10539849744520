import { memo, useCallback, useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { reissueMemberCoupon } from 'redux/actions/data/memberActions';
import MessageUtil from 'utils/MessageUtil';

import style from '../../styles/layout.module.css';

const ReissueMemberCouponModal = ({
  show = false,
  onClose = () => {},
  member = {
    id: 0,
    phone: '',
  },
}) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (id, code) => {
      try {
        setLoading(true);
        await reissueMemberCoupon({
          code,
          id,
        });
        setLoading(false);
        setCode('');
        onClose();
        MessageUtil.alertSuccess('發送成功');
      } catch (e) {
        setLoading(false);
        MessageUtil.alertWanring('補發失敗', e.toLocaleString());
      }
    },
    [onClose, setLoading],
  );

  return (
    <Modal isOpen={show} centered size="xl" zIndex={2000}>
      <ModalHeader className={style.modal_head} toggle={onClose}>
        補發優惠券
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        <FormGroup className={style.formGroup}>
          <label style={{ width: '80px' }}>手機號碼</label>
          <div className="my-2">{member?.phone}</div>
        </FormGroup>
        <FormGroup className={style.formGroup}>
          <label className="required" style={{ width: '200px' }}>
            輸入優惠券活動序號或活動代碼
          </label>
          <Input
            type="text"
            onChange={e => {
              setCode(e.target.value);
            }}
            value={code}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color="secondary"
          type="button"
          className={style.modal_button_cancel}
          onClick={onClose}
        >
          取消
        </Button>
        <Button
          color="primary"
          type="button"
          className={style.modal_button_submit}
          onClick={() => {
            onSubmit(member.id, code);
          }}
          disabled={!code || loading}
        >
          {loading && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          確認補發優惠券
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ReissueMemberCouponModal);
