import { useFormik } from 'formik';
import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, TabPane } from 'reactstrap';
import * as Yup from 'yup';

import {
  getGroupModelDetail,
  setGroupModelDesc,
} from '../../redux/actions/data/groupModelActions';
import { GROUP_MODEL_SET_DESC_RESET } from '../../redux/constants/data/groupModelConstants';
import style from '../../styles/layout.module.css';
import MessageUtil from '../../utils/MessageUtil';
import history from '../../utils/createHistory';
import CustomColorPicker from '../CustomColorPicker';
import ProductIntro from '../ProductIntro';
import { BlockSpinner } from '../Spinner';

import LabelC from './LabelC';

const TabDesc = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, groupModelInfo } = useSelector(
    state => state.groupModelDetail,
  );
  const {
    loading: submitLoading,
    success,
    groupModelId,
    error,
  } = useSelector(state => state.setGroupModelDesc);
  useEffect(() => {
    success && MessageUtil.toastSuccess('儲存成功', '');
    success && !id && history.push(`/GroupModelDetail/${groupModelId}`);
    success && id && dispatch(getGroupModelDetail(id));
    error && MessageUtil.alertWanring(error);
    return () => {
      dispatch({ type: GROUP_MODEL_SET_DESC_RESET });
    };
  }, [success, error]);

  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        bgColor: groupModelInfo?.desc?.bgColor || '#964C4C',
        fontColor: groupModelInfo?.desc?.fontColor,
        introImgFiles: [],
        intros: groupModelInfo?.desc?.introdutions,
      },
      onSubmit: values => {
        const params = {
          ...values,
          intros: JSON.stringify(
            values.intros
              .map((i, index) => {
                const obj = {
                  content: i.get('content'),
                  content_en: i.get('content_en'),
                  height: i.get('height') || 0,
                  id: i.get('id'),
                  image: i.get('imageUrl'),
                  isChangeFile: i.get('isUpload'),
                  isRemoveFile: false,
                  isRemoveFile_en: false,
                  sort: i.get('sort') ? i.get('sort') : index + 1, //沒有拖拉排序就要有此默認值
                  title: i.get('title'),
                  title_en: i.get('title_en'),
                  type: i.get('type'),
                  youtubeLink: i.get('youtubeLink'),
                };
                obj.imgFormFileId = values.introImgFiles
                  ? values.introImgFiles.indexOf(
                      values.introImgFiles.find(i => i.id === obj.id),
                    )
                  : -1;

                obj.imgFormFileId_en = values.introImgFiles_en
                    ? values.introImgFiles_en.indexOf(
                        values.introImgFiles_en.find(i => i.id === obj.id),
                    )
                    : -1;
                return obj;
              })
              .sort((a, b) => {
                return a.sort - b.sort;
              }),
          ),
        };
        const formData = new FormData();
        Object.keys(params).forEach(i => {
          i !== 'introImgFiles' &&
          i !== 'introImgFiles_en' &&
          formData.append(i, params[i]);
        });
        values.introImgFiles?.length > 0 &&
        values.introImgFiles.forEach(i => {
          formData.append('introImgFiles', i);
        });
        values.introImgFiles_en?.length > 0 &&
        values.introImgFiles_en.forEach(i => {
          formData.append('introImgFiles_en', i);
        });
        dispatch(setGroupModelDesc({ data: formData, id: id || 0 }));
      },
      validationSchema: Yup.object().shape({
        bgColor: Yup.string().required('必填'),
        fontColor: Yup.string().required('必填'),
      }),
    });
  return (
    <TabPane tabId={2}>
      <Card className={style.card}>
        <Form className="position-relative">
          {(loading || submitLoading) && <BlockSpinner />}
          <FormGroup row className="align-items-center">
            <LabelC text="商品介紹背景顏色" required className="mb-0" />
            <Col>
              <div className="w200">
                <CustomColorPicker
                  initColor={groupModelInfo?.desc?.bgColor}
                  formFieldName="bgColor"
                  setFormFieldValue={setFieldValue}
                />
              </div>
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC
              text=" 商品介紹 / 銷售標題文字顏色"
              required
              className="mb-0"
            />
            <Col className="d-flex gap-4">
              <Label className="d-flex gap-1 align-items-center">
                <input
                  className={style.maintain_list_checkbox}
                  name="fontColor"
                  value="white"
                  type="radio"
                  onChange={handleChange}
                  checked={values.fontColor === 'white'}
                />{' '}
                白色
              </Label>
              <Label className="d-flex gap-1 align-items-center">
                <input
                  className={style.maintain_list_checkbox}
                  name="fontColor"
                  value="black"
                  type="radio"
                  onChange={handleChange}
                  checked={values.fontColor === 'black'}
                />{' '}
                黑色
              </Label>
              {errors.fontColor && touched.fontColor && (
                <span className="text-danger"> 必填！</span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row className="align-items-center">
            <LabelC text="商品介紹" required />
            <Col>
              <ProductIntro
                setFormFieldValue={setFieldValue}
                initContents={groupModelInfo?.desc?.introdutions}
                onChange={value => setFieldValue('intros', value)}
                name="intros"
                formFieldName="introImgFiles"
              />
            </Col>
          </FormGroup>
        </Form>
        <hr />
        <div className="text-center">
          <Button
            color="primary"
            disabled={loading || submitLoading}
            className={style.modal_button_submit}
            onClick={handleSubmit}
          >
            {(loading || submitLoading) && (
              <span
                className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            儲存
          </Button>
        </div>
      </Card>
    </TabPane>
  );
};

export default memo(TabDesc);
