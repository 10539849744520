export const CHK_HOME_DELIVERY_REQUEST = 'CHK_HOME_DELIVERY_REQUEST';
export const CHK_HOME_DELIVERY_SUCCESS = 'CHK_HOME_DELIVERY_SUCCESS';
export const CHK_HOME_DELIVERY_FAIL = 'CHK_HOME_DELIVERY_FAIL';
export const CHK_HOME_DELIVERY_RESET = 'CHK_HOME_DELIVERY_RESET';

export const HOME_DELIVERY_SHIPPING_REQUEST = 'HOME_DELIVERY_SHIPPING_REQUEST';
export const HOME_DELIVERY_SHIPPING_SUCCESS = 'HOME_DELIVERY_SHIPPING_SUCCESS';
export const HOME_DELIVERY_SHIPPING_FAIL = 'HOME_DELIVERY_SHIPPING_FAIL';
export const HOME_DELIVERY_SHIPPING_RESET = 'HOME_DELIVERY_SHIPPING_RESET';

export const CHK_STORE_REQUEST = 'CHK_STORE_REQUEST';
export const CHK_STORE_SUCCESS = 'CHK_STORE_SUCCESS';
export const CHK_STORE_FAIL = 'CHK_STORE_FAIL';
export const CHK_STORE_RESET = 'CHK_STORE_RESET';

export const STORE_SHIPPING_REQUEST = 'STORE_SHIPPING_REQUEST';
export const STORE_SHIPPING_SUCCESS = 'STORE_SHIPPING_SUCCESS';
export const STORE_SHIPPING_FAIL = 'STORE_SHIPPING_FAIL';
export const STORE_SHIPPING_RESET = 'STORE_SHIPPING_RESET';

export const STORE_LOGISTIC_REQUEST = 'STORE_LOGISTIC_REQUEST';
export const STORE_LOGISTIC_SUCCESS = 'STORE_LOGISTIC_SUCCESS';
export const STORE_LOGISTIC_FAIL = 'STORE_LOGISTIC_FAIL';
export const STORE_LOGISTIC_RESET = 'STORE_LOGISTIC_RESET';

export const STORE_ARRIVED_REQUEST = 'STORE_ARRIVED_REQUEST';
export const STORE_ARRIVED_SUCCESS = 'STORE_ARRIVED_SUCCESS';
export const STORE_ARRIVED_FAIL = 'STORE_ARRIVED_FAIL';
export const STORE_ARRIVED_RESET = 'STORE_ARRIVED_RESET';

export const STORE_PICKED_UP_REQUEST = 'STORE_PICKED_UP_REQUEST';
export const STORE_PICKED_UP_SUCCESS = 'STORE_PICKED_UP_SUCCESS';
export const STORE_PICKED_UP_FAIL = 'STORE_PICKED_UP_FAIL';
export const STORE_PICKED_UP_RESET = 'STORE_PICKED_UP_RESET';

export const STORE_CANCEL_REQUEST = 'STORE_CANCEL_REQUEST';
export const STORE_CANCEL_SUCCESS = 'STORE_CANCEL_SUCCESS';
export const STORE_CANCEL_FAIL = 'STORE_CANCEL_FAIL';
export const STORE_CANCEL_RESET = 'STORE_CANCEL_RESET';

export const UUOPTION_SUCCESS = 'UUOPTION_SUCCESS';

export const SHIPPING_PRINT_INFO_REQUEST = 'SHIPPING_PRINT_INFO_REQUEST';
export const SHIPPING_PRINT_INFO_SUCCESS = 'SHIPPING_PRINT_INFO_SUCCESS';
export const SHIPPING_PRINT_INFO_FAIL = 'SHIPPING_PRINT_INFO_FAIL';
export const SHIPPING_PRINT_INFO_RESET = 'SHIPPING_PRINT_INFO_RESET';

export const HOME_DELIVERY_CANCEL_IDLE = 'HOME_DELIVERY_CANCEL_IDLE';
export const HOME_DELIVERY_CANCEL_REQUEST = 'HOME_DELIVERY_CANCEL_REQUEST';
export const HOME_DELIVERY_CANCEL_SUCCESS = 'HOME_DELIVERY_CANCEL_SUCCESS';
export const HOME_DELIVERY_CANCEL_FAIL = 'HOME_DELIVERY_CANCEL_FAIL';
