import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { Button, Card, FormGroup, Input } from 'reactstrap';

import { getMemberListCount } from '../../../redux/actions/data/memberActions';
import style from '../../../styles/layout.module.css';
import { ExcelToJSON } from '../../../utils/ExcelToJSON';
import MessageUtil from '../../../utils/MessageUtil';
import Loader from '../../Loader';
import UploadFileBox from '../../UploadFileBox';

import ProductRange from './ProductRange';
const initDate = moment().add(5, 'minute').toDate();

const lableStyle = { fontWeight: '400', minWidth: '100px', padding: '0px' };

const titleStyle = {
  borderColor: '#3c44b1',
  color: '#3c44b1',
  fontWeight: 'normal',
  marginBottom: '2rem',
  minWidth: '150px',
};
const DiscountTabPane = ({
  isEditor = false,
  isView = false,
  goBack = () => {},
  getValidationSchema = () => {},
  handleSubmit = () => {},
  initialValues = {},
}) => {
  const { detail, loading } = useSelector(state => state.getVoucherDetail);
  const { memberLevelOptions } = useSelector(
    state => state.getMemberLevelOptions,
  );

  if (loading || !Object.keys(detail)?.length) {
    return <Loader />;
  }

  return (
    <Card className={style.card}>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={initialValues}
        validationSchema={getValidationSchema}
      >
        {props => (
          <Form className="form-list form-label-140">
            <h4 className="title" style={titleStyle}>
              基本設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動名稱(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="name"
                    className="form-control mx-2"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.name?.length > 80 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.name?.length || 0}/80)
                  </span>
                </div>
                {props?.errors?.name && (
                  <div className="form-error px-2">{props.errors.name}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動名稱(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="name_en"
                    className="form-control mx-2"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.name_en?.length > 80 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.name_en?.length || 0}/80)
                  </span>
                </div>
                {props?.errors?.name_en && (
                  <div className="form-error px-2">{props.errors.name_en}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動說明(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="desc"
                    className="form-control mx-2"
                    as="textarea"
                    row="5"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.desc?.length > 800 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.desc?.length || 0}/800)
                  </span>
                </div>
                {props?.errors?.desc && (
                  <div className="form-error px-2">{props.errors.desc}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動說明(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="desc_en"
                    className="form-control mx-2"
                    as="textarea"
                    row="5"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.desc_en?.length > 800 ? 'text-danger' : ''
                    }`}
                  >
                    ({props.values?.desc_en?.length || 0}/800)
                  </span>
                </div>
                {props?.errors?.desc_en && (
                  <div className="form-error px-2">{props.errors.desc_en}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>
                券面圖片
              </label>
              <div className="form-item">
                <UploadFileBox
                  disabled={isView}
                  uplodModel="images"
                  formFieldName="couponImgFiles"
                  currentFileFieldName="couponImgFiles"
                  setFormFieldValue={(field, files) => {
                    if (files && files instanceof Array && files.length) {
                      if (files[0] instanceof File) {
                        props.setFieldValue('couponImgFiles', files);
                      }
                    } else if (
                      typeof files === 'string' &&
                      files === 'isDelete'
                    ) {
                      props.setFieldValue('couponImgFiles', []);
                      props.setFieldValue('couponImgLink', '');
                    }
                  }}
                  displayFileWidth="450"
                  displayFileHeight="450"
                  displayFileSize="3MB"
                  initFiles={props.values.init_img_files}
                />
                <div className="form-error px-2">
                  {props.errors?.couponImgFiles}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                券面顯示文字(中文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="couponWording"
                    className="form-control mx-2"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.couponWording?.length > 20
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    ({props.values?.couponWording?.length || 0}/20)
                  </span>
                </div>

                {props?.errors?.couponWording && (
                  <div className="form-error px-2">
                    {props.errors.couponWording}
                  </div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                券面顯示文字(英文)
              </label>
              <div className="form-item">
                <div className="d-flex">
                  <Field
                    name="couponWording_en"
                    className="form-control mx-2"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.couponWording_en?.length > 80
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    ({props.values?.couponWording_en?.length || 0}/80)
                  </span>
                </div>
                {props?.errors?.couponWording_en && (
                  <div className="form-error px-2">
                    {props.errors.couponWording_en}
                  </div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                活動目的
              </label>
              <div className="form-item">
                <label className="d-inline-block m-2" htmlFor="radio-temp1">
                  <Field
                    type="radio"
                    name="goal"
                    disabled={isView || isEditor}
                    value={1}
                    id="radio-temp1"
                    onChange={() => {
                      props.setFieldValue('goal', 1);
                      props.setFieldValue('getWay', 1);
                    }}
                  />
                  <span className="fw-medium px-1">行銷活動</span>
                </label>
                <label className="d-inline-block m-2" htmlFor="radio-temp2">
                  <Field
                    type="radio"
                    name="goal"
                    disabled={isView || isEditor}
                    value={2}
                    id="radio-temp2"
                    onChange={() => {
                      props.setFieldValue('goal', 2);
                      props.setFieldValue('getWay', 2);
                    }}
                  />
                  <span className="fw-medium px-1"> 客服補發</span>
                </label>
                {props.errors?.goal && (
                  <div className="form-error px-2">{props.errors?.goal}</div>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                領取方式
              </label>
              <div className="form-item">
                {props.values.goal === 1 && (
                  <label
                    className="d-inline-block m-2"
                    htmlFor="radio-getWay-1"
                  >
                    <Field
                      disabled={isView || isEditor}
                      type="radio"
                      name="getWay"
                      value={1}
                      id="radio-getWay-1"
                      onChange={() => {
                        props.setFieldValue('getWay', 1);
                      }}
                    />
                    <span className="fw-medium px-1">直接領取</span>
                  </label>
                )}
                <label className="d-inline-block m-2" htmlFor="radio-getWay-2">
                  <Field
                    type="radio"
                    name="getWay"
                    disabled={isView || isEditor}
                    value={2}
                    id="radio-getWay-2"
                    onChange={() => {
                      props.setFieldValue('getWay', 2);
                      props.setFieldValue('setting', 1);
                    }}
                  />
                  <span className="fw-medium px-1">輸入優惠券代碼</span>
                </label>
                {props.values.goal === 1 && (
                  <label
                    className="d-inline-block m-2"
                    htmlFor="radio-getWay-3"
                  >
                    <Field
                      type="radio"
                      disabled={isView || isEditor}
                      name="getWay"
                      value={3}
                      id="radio-getWay-3"
                      onChange={() => {
                        props.setFieldValue('getWay', 3);
                      }}
                    />
                    <span className="fw-medium px-1">紅利點數兌換</span>
                  </label>
                )}
                <label className="d-inline-block m-2" htmlFor="radio-getWay-4">
                  <Field
                    type="radio"
                    name="getWay"
                    disabled={isView || isEditor}
                    value={4}
                    id="radio-getWay-4"
                    onChange={async () => {
                      props.setFieldValue('getWay', 4);
                      props.setFieldValue('_object', 1);
                      props.setFieldValue('object', 1);
                      props.setFieldValue('isGetTime', false);
                      props.setFieldValue('quantity', 0);
                      const res = await getMemberListCount(0);
                      props.setFieldValue('quantity', res.total_count);

                      if (
                        moment()
                          .add(1, 'days')
                          .startOf('day')
                          .isAfter(
                            moment(props.values?.getStartTime || initDate),
                          )
                      ) {
                        props.setFieldValue(
                          'getStartTime',
                          moment().add(1, 'days').format('YYYY/MM/DD HH:mm:ss'),
                        );
                        props.setFieldValue(
                          'getEndTime',
                          moment().add(1, 'days').format('YYYY/MM/DD HH:mm:ss'),
                        );
                      }
                    }}
                  />
                  <span className="fw-medium px-1">系統發送</span>
                </label>
              </div>
            </FormGroup>
            {props.values.getWay === 3 && (
              <>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    兌換上限
                  </label>
                  <div className="form-item">
                    <label
                      className="d-inline-block m-2"
                      htmlFor="radio-useMin-1"
                    >
                      <Field
                        type="radio"
                        disabled={isView || isEditor}
                        name="pointExchange"
                        value={1}
                        id="radio-useMin-1"
                        onChange={() => {
                          props.setFieldValue('pointExchange', 1);
                        }}
                      />
                      <span className="fw-medium px-1"> 每人不限兌換次數</span>
                    </label>
                    <label
                      className="d-inline-block m-2"
                      htmlFor="radio-useMin-2"
                    >
                      <Field
                        type="radio"
                        name="pointExchange"
                        disabled={isView || isEditor}
                        value={2}
                        id="radio-useMin-2"
                        onChange={() => {
                          props.setFieldValue('pointExchange', 2);
                        }}
                      />
                      <span className="fw-medium px-1">每人限兌換1次</span>
                    </label>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    兌換條件
                  </label>
                  <div className="form-item">
                    <ul>
                      <li className="text-secondary">
                        <small className="text-secondary">
                          設定消費者可使用多少點兌換一張優惠券
                        </small>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center">
                      <Field
                        disabled={isView || isEditor}
                        type="number"
                        placeholder="輸入數字"
                        min="0"
                        value={props.values?.exchangePointAmount || 0}
                        onChange={e => {
                          props.setFieldValue(
                            'exchangePointAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          props.setFieldValue(
                            'exchangePointAmount',
                            Number(e.target.value),
                          );
                        }}
                        name="exchangePointAmount"
                        className="form-control mx-2"
                        style={{ maxWidth: '150px' }}
                      />
                      點可兌換一張
                    </div>
                    {props.errors?.exchangePointAmount && (
                      <div className="px-2 form-error">
                        {props.errors?.exchangePointAmount}
                      </div>
                    )}
                  </div>
                </FormGroup>
              </>
            )}
            {props.values.getWay === 2 && (
              <>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    優惠券代碼設定
                  </label>
                  <div className="form-item">
                    <label
                      className="d-inline-block m-2"
                      htmlFor="radio-setting-1"
                    >
                      <Field
                        type="radio"
                        disabled={isView || isEditor}
                        name="setting"
                        value={1}
                        id="radio-setting-1"
                        onChange={() => {
                          props.setFieldValue('setting', 1);
                        }}
                      />
                      <span className="fw-medium px-1">
                        {' '}
                        每張優惠券代碼相同
                      </span>
                    </label>
                    <label
                      className="d-inline-block m-2"
                      htmlFor="radio-setting-2"
                    >
                      <Field
                        type="radio"
                        name="setting"
                        disabled={isView || isEditor}
                        value={2}
                        id="radio-setting-2"
                        onChange={() => {
                          props.setFieldValue('setting', 2);
                        }}
                      />
                      <span className="fw-medium px-1">每張優惠券代碼不同</span>
                    </label>
                  </div>
                </FormGroup>
                {props.values.setting === 1 && (
                  <FormGroup className="form-group mb-3 mb-3">
                    <label />
                    <div className="form-item">
                      <div className="d-flex align-items-center">
                        <label
                          className="d-inline-block m-2"
                          htmlFor="isCustomedCode-checkbox"
                        >
                          <Field
                            type="checkbox"
                            name="isCustomedCode"
                            disabled={isView || isEditor}
                            value="isCustomedCode"
                            checked={props.values.isCustomedCode}
                            id="isCustomedCode-checkbox"
                            onChange={() => {
                              props.setFieldValue(
                                'isCustomedCode',
                                !props.values.isCustomedCode,
                              );
                            }}
                          />
                          <span className="d-inline-block px-1 fw-medium">
                            自訂輸入優惠券代碼
                          </span>
                        </label>
                        <Input
                          className="form-control mx-2"
                          placeholder="限輸入英數字"
                          disabled={
                            !props.values.isCustomedCode || isView || isEditor
                          }
                          style={{ maxWidth: '150px' }}
                          name="couponCode"
                          value={String(props.values.couponCode)}
                          onChange={e => {
                            props.setFieldValue('couponCode', e.target.value);
                          }}
                        />
                        <span
                          style={{ minWidth: '80px' }}
                          className={`text-nowrap ${
                            props.values?.couponCode?.length > 12
                              ? 'text-danger'
                              : ''
                          }`}
                        >
                          ({props.values?.couponCode?.length || 0}/12)
                        </span>

                        {props?.errors?.couponCode && (
                          <div className="form-error px-2">
                            {props.errors.couponCode}
                          </div>
                        )}
                      </div>
                      <ul>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            優惠券代碼之格式限英數字，不可使用空格或標點符號，例如FREE202200205
                          </small>
                        </li>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            若不勾選擇由系統產生一組隨機英數字提供會員輸入{' '}
                          </small>
                        </li>
                        <li className="text-secondary">
                          <small className="text-secondary">
                            已使用過的優惠券代碼，其他活動就不可使用{' '}
                          </small>
                        </li>
                      </ul>
                    </div>
                  </FormGroup>
                )}
              </>
            )}
            {props.values.getWay === 4 && (
              <>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    發送時間
                  </label>
                  <div className="form-item">
                    <DatePicker
                      className="form-control mx-2"
                      dateFormat="yyyy/MM/dd"
                      selected={moment(
                        props.values.getStartTime || initDate,
                      ).toDate()}
                      name="getStartTime"
                      onChange={date => {
                        props.setFieldValue(
                          'getStartTime',
                          moment(date || initDate)
                            .startOf('days')
                            .format('yyyy/MM/DD 00:00:00'),
                        );
                        props.setFieldValue(
                          'getEndTime',
                          moment(date || initDate).format(
                            'yyyy/MM/DD 00:00:00',
                          ),
                        );
                      }}
                      minDate={moment().add(1, 'day').toDate()}
                    />
                    <ul>
                      <li className="text-secondary">
                        <small className="text-secondary">凌晨0:00發送</small>
                      </li>
                    </ul>
                  </div>
                </FormGroup>
                <FormGroup className="form-group mb-3 mb-3">
                  <label className="required" style={lableStyle}>
                    活動對象
                  </label>
                  <div className="form-item">
                    <div>
                      <label
                        className="d-inline-block m-2"
                        htmlFor="radio-object-0"
                      >
                        <Field
                          type="radio"
                          disabled={isView || isEditor}
                          checked={props.values._object !== 3}
                          id="radio-object-0"
                          onChange={async () => {
                            props.setFieldValue('quantity', 0);
                            props.setFieldValue('_object', 1);
                            props.setFieldValue('object', 1);
                            const res = await getMemberListCount(0);
                            props.setFieldValue('quantity', res.total_count);
                          }}
                        />
                        <span className="fw-medium px-1">會員</span>
                      </label>
                      <label
                        className="d-inline-block m-2"
                        htmlFor="radio-object-3"
                      >
                        <Field
                          type="radio"
                          name="_object"
                          value={3}
                          id="radio-object-3"
                          disabled={isView || isEditor}
                          onChange={() => {
                            props.setFieldValue('quantity', 0);
                            props.setFieldValue('_object', 3);
                            props.setFieldValue('object', 3);
                          }}
                        />
                        <span className="fw-medium px-1"> 匯入會員名單</span>
                      </label>
                    </div>
                    {props.values._object !== 3 && (
                      <div>
                        <label
                          className="d-inline-block m-2"
                          htmlFor="radio-object-1"
                        >
                          <Field
                            type="radio"
                            name="_object"
                            value={1}
                            id="radio-object-1"
                            disabled={isView || isEditor}
                            onChange={async () => {
                              props.setFieldValue('_object', 1);
                              props.setFieldValue('object', 1);
                            }}
                            onClick={async () => {
                              props.setFieldValue('quantity', 0);
                              const res = await getMemberListCount(0);
                              props.setFieldValue('quantity', res.total_count);
                            }}
                          />
                          <span className="fw-medium px-1">全體會員</span>
                        </label>
                        <label
                          className="d-inline-block m-2"
                          htmlFor="radio-object-2"
                        >
                          <Field
                            type="radio"
                            name="_object"
                            value={2}
                            id="radio-object-2"
                            disabled={isView || isEditor}
                            onClick={() => {
                              props.setFieldValue('quantity', 0);
                            }}
                            onChange={() => {
                              props.setFieldValue('_object', 2);
                              props.setFieldValue('object', 2);
                              props.setFieldValue('objectMLevelId', []);
                            }}
                          />
                          <span className="fw-medium px-1"> 會員等級</span>
                        </label>
                      </div>
                    )}
                  </div>
                </FormGroup>
                {props.values._object === 2 && (
                  <FormGroup className="form-group mb-3 mb-3">
                    <label />
                    <div className="form-item">
                      {props.errors.objectMLevelId && (
                        <div className="form-error">
                          {props.errors.objectMLevelId}
                        </div>
                      )}
                      {(memberLevelOptions || []).map(m => (
                        <label
                          key={`objectMLevelId - ${m.value}`}
                          className="d-inline-block m-2"
                          htmlFor={`objectMLevelId - ${m.value}`}
                        >
                          <Field
                            type="checkbox"
                            name="objectMLevelId"
                            value={m.value}
                            disabled={isView || isEditor}
                            checked={(props.values.objectMLevelId || []).some(
                              o => String(o) === String(m.value),
                            )}
                            id={`objectMLevelId - ${m.value}`}
                            onChange={async () => {
                              const res = await getMemberListCount(
                                Number(m.value),
                              );

                              let ids = props.values.objectMLevelId || [];
                              if (
                                props.values.objectMLevelId?.includes(m.value)
                              ) {
                                ids = ids.filter(d => d !== m.value);
                                props.setFieldValue(
                                  'quantity',
                                  Number(props.values.quantity || 0) -
                                    Number(res.total_count || 0),
                                );
                              } else {
                                ids.push(m.value);
                                props.setFieldValue(
                                  'quantity',
                                  Number(props.values.quantity || 0) +
                                    Number(res.total_count || 0),
                                );
                              }
                              props.setFieldValue('objectMLevelId', [...ids]);
                            }}
                          />
                          <span className="d-inline-block px-1 fw-medium">
                            {m.label}
                          </span>
                        </label>
                      ))}
                    </div>
                  </FormGroup>
                )}
                {props.values._object === 3 && (
                  <FormGroup className="form-group mb-3 mb-3">
                    <label />
                    <div className="form-item">
                      <div>
                        <label
                          className="btn btn-outline-primary d-inline-block"
                          htmlFor="importMemberFile"
                          onClick={() => {
                            document.querySelector('#importMemberFile').value =
                              null;
                          }}
                        >
                          匯入發送會員名單
                          <input
                            id="importMemberFile"
                            type="file"
                            className="d-none"
                            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={e => {
                              const fileProps = e.target.files[0];
                              if (fileProps) {
                                const xl2json = new ExcelToJSON();
                                xl2json.parseExcel(fileProps).then(data => {
                                  if (
                                    data?.length > 0 &&
                                    data.every(d => {
                                      return Object.keys(d).every(key => {
                                        return (
                                          d[key].startsWith('9') ||
                                          d[key].startsWith('09') ||
                                          d[key].startsWith('8869')
                                        );
                                      });
                                    })
                                  ) {
                                    props.setFieldValue(
                                      'importMemberFile',
                                      fileProps,
                                    );

                                    props.setFieldValue(
                                      'quantity',
                                      data.length,
                                    );
                                  } else {
                                    MessageUtil.alertWanring(
                                      '檔案格式錯誤',
                                      '請依範例檔案格式填寫',
                                    );
                                  }
                                });
                              }
                            }}
                          />
                        </label>
                        <span
                          className="text-info btn btn-link"
                          onClick={() => {
                            window.open('/example/優惠券_匯入會員範本.xlsx');
                          }}
                        >
                          下載範本
                        </span>
                      </div>
                      {props.values.importMemberFile && (
                        <div className="d-flex align-items-center my-2">
                          <span
                            className="btn btn-link text-danger text-decoration-none fw-bolder"
                            disabled={isView || isEditor}
                            onClick={() => {
                              document.querySelector(
                                '#importMemberFile',
                              ).value = null;
                              props.setFieldValue('importMemberFile', null);
                            }}
                          >
                            X
                          </span>
                          <span
                            onClick={() => {
                              const url = URL.createObjectURL(
                                props.values.importMemberFile,
                              );
                              window.open(url);
                            }}
                          >
                            {props.values.importMemberFile?.name}
                          </span>
                        </div>
                      )}
                    </div>
                  </FormGroup>
                )}
              </>
            )}
            {props.values.getWay !== 4 && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  領取或發送期限
                </label>
                <div className="form-item">
                  <div className="d-flex align-items-center">
                    <div style={{ maxWidth: '250px' }}>
                      <DatePicker
                        className="form-control mx-2"
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        disabled={isView || isEditor}
                        selected={moment(
                          props.values.getStartTime || initDate,
                        ).toDate()}
                        name="getStartTime"
                        onChange={date => {
                          if (!date) {
                            date = initDate;
                          }
                          props.setFieldValue(
                            'getStartTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );

                          if (props.values.isGetTime) {
                            props.setFieldValue(
                              'redeemStartTime',
                              moment(date).format('yyyy/MM/DD HH:mm'),
                            );
                          }

                          if (
                            !props.values.getEndTime ||
                            (props.values.getEndTime &&
                              moment(date).isAfter(
                                moment(props.values.getEndTime),
                              ))
                          ) {
                            props.setFieldValue(
                              'getEndTime',
                              moment(date)
                                .add(30, 'minute')
                                .format('yyyy/MM/DD HH:mm'),
                            );
                          }
                        }}
                        minTime={
                          moment().format('YYYY/MM/DD') ===
                          moment(props.values?.getStartTime || initDate).format(
                            'YYYY/MM/DD',
                          )
                            ? moment().add(5, 'minute').toDate()
                            : moment().startOf('day').toDate()
                        }
                        maxTime={moment().hour(23).minute(59).toDate()}
                        minDate={moment().add(30, 'minute').toDate()}
                      />
                    </div>
                    <span className="px-2">~</span>
                    <div style={{ maxWidth: '250px' }}>
                      <DatePicker
                        className="form-control mx-2"
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        selected={moment(
                          props.values.getEndTime || initDate,
                        ).toDate()}
                        name="getEndTime"
                        disabled={isView}
                        onChange={date => {
                          if (!date) {
                            date = initDate;
                          }
                          if (initialValues.id) {
                            if (
                              moment(date).isBefore(moment().add(1, 'minute'))
                            ) {
                              date = moment().add(1, 'minute').toDate();
                            }
                          }
                          props.setFieldValue(
                            'getEndTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );
                          if (props.values.isGetTime) {
                            props.setFieldValue(
                              'redeemEndTime',
                              moment(date).format('yyyy/MM/DD HH:mm'),
                            );
                          }
                        }}
                        timeIntervals={1}
                        minTime={
                          initialValues.id
                            ? moment().add(1, 'minute').toDate()
                            : moment(
                                props.values?.getEndTime || initDate,
                              ).format('YYYY/MM/DD') ===
                              moment(
                                props.values?.getStartTime || initDate,
                              ).format('YYYY/MM/DD')
                            ? moment(props.values?.getStartTime || initDate)
                                .add(30, 'minute')
                                .toDate()
                            : moment(props.values?.getStartTime || initDate)
                                .hour(0)
                                .minute(0)
                                .toDate()
                        }
                        minDate={
                          initialValues.id
                            ? initDate
                            : moment(
                                props.values?.getEndTime || initDate,
                              ).format('YYYY/MM/DD') ===
                              moment(
                                props.values?.getStartTime || initDate,
                              ).format('YYYY/MM/DD')
                            ? moment(props.values?.getStartTime || initDate)
                                .add(30, 'minute')
                                .toDate()
                            : moment(props.values?.getStartTime || initDate)
                                .hour(0)
                                .minute(0)
                                .toDate()
                        }
                        maxTime={moment().hour(23).minute(59).toDate()}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                使用期限
              </label>
              <div className="form-item">
                <label className="d-inline-block m-2" htmlFor="radio-dueType-1">
                  <Field
                    type="radio"
                    name="dueType"
                    disabled={isView || isEditor}
                    value={1}
                    id="radio-dueType-1"
                    onChange={() => {
                      props.setFieldValue('dueType', 1);
                      props.setFieldValue('isGetTime', false);
                    }}
                  />
                  <span className="fw-medium px-1"> 自訂日期區間</span>
                </label>
                <label className="d-inline-block m-2" htmlFor="radio-dueType-2">
                  <Field
                    type="radio"
                    name="dueType"
                    disabled={isView || isEditor}
                    value={2}
                    id="radio-dueType-2"
                    onChange={() => {
                      props.setFieldValue('dueType', 2);
                      props.setFieldValue('isGetTime', false);
                    }}
                  />
                  <span className="fw-medium px-1"> 依日期推算期限</span>
                </label>
              </div>
            </FormGroup>
            {props.values.dueType === 1 && (
              <FormGroup className="form-group mb-3 mb-3">
                <label />
                <div className="form-item">
                  {props.values.getWay !== 4 && (
                    <label className="d-block m-2" htmlFor="radio-isGetTime">
                      <Field
                        type="checkbox"
                        name="isGetTime"
                        value="isGetTime"
                        disabled={isView || isEditor}
                        checked={props.values.isGetTime}
                        id="radio-isGetTime"
                        onChange={() => {
                          if (!props.values.isGetTime) {
                            props.setFieldValue(
                              'redeemStartTime',
                              props.values.getStartTime,
                            );
                            props.setFieldValue(
                              'redeemEndTime',
                              props.values.getEndTime,
                            );
                          }
                          props.setFieldValue(
                            'isGetTime',
                            !props.values.isGetTime,
                          );
                        }}
                      />
                      <span className="d-inline-block px-1 fw-medium">
                        {' '}
                        與領取或發送期間相同
                      </span>
                    </label>
                  )}
                  {props.values.isGetTime && (
                    <p className="fs-14">
                      {moment(props.values.getStartTime || initDate).format(
                        'yyyy/MM/DD HH:mm',
                      )}
                      ~
                      {moment(props.values.getEndTime || initDate).format(
                        'yyyy/MM/DD HH:mm',
                      )}
                    </p>
                  )}
                  {!props.values.isGetTime && (
                    <div className="d-flex">
                      <DatePicker
                        dateFormat="yyyy/MM/dd HH:mm"
                        className="form-control mx-2"
                        showTimeSelect
                        disabled={isView || isEditor}
                        selected={moment(
                          props.values.redeemStartTime || initDate,
                        ).toDate()}
                        name="redeemStartTime"
                        onChange={date => {
                          props.setFieldValue(
                            'redeemStartTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );
                        }}
                        maxTime={moment().year(9999).endOf('days').toDate()}
                        minTime={
                          moment(props.values.redeemStartTime).format(
                            'YYYY/MM/DD',
                          ) !==
                          moment(props.values.getStartTime || initDate).format(
                            'YYYY/MM/DD',
                          )
                            ? moment(props.values.getStartTime || initDate)
                                .startOf('days')
                                .toDate()
                            : moment(props.values.getStartTime || initDate)
                                .startOf('days')
                                .toDate()
                        }
                        minDate={moment(
                          props.values.getStartTime || initDate,
                        ).toDate()}
                      />
                      <span className="mx-2">~</span>
                      <DatePicker
                        className="form-control mx-2"
                        disabled={isView || isEditor}
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        selected={moment(
                          props.values.redeemEndTime || initDate,
                        ).toDate()}
                        name="redeemEndTime"
                        onChange={date => {
                          props.setFieldValue(
                            'redeemEndTime',
                            moment(date).format('yyyy/MM/DD HH:mm'),
                          );
                        }}
                        minDate={
                          moment(
                            props.values.redeemStartTime || initDate,
                          ).isAfter(moment(props.values.getEndTime || initDate))
                            ? moment(
                                props.values.redeemStartTime || initDate,
                              ).toDate()
                            : moment(
                                props.values.getEndTime || initDate,
                              ).toDate()
                        }
                        minTime={
                          moment(props.values.redeemEndTime || initDate).format(
                            'YYYY/MM/DD',
                          ) !==
                          moment(
                            moment(
                              props.values.redeemStartTime || initDate,
                            ).isAfter(
                              moment(props.values.getEndTime || initDate),
                            )
                              ? moment(
                                  props.values.redeemStartTime || initDate,
                                ).toDate()
                              : moment(
                                  props.values.getEndTime || initDate,
                                ).toDate(),
                          ).format('YYYY/MM/DD')
                            ? moment(
                                moment(
                                  props.values.redeemStartTime || initDate,
                                ).isAfter(
                                  moment(props.values.getEndTime || initDate),
                                )
                                  ? moment(
                                      props.values.redeemStartTime || initDate,
                                    ).toDate()
                                  : moment(
                                      props.values.getEndTime || initDate,
                                    ).toDate(),
                              )
                                .startOf('day')
                                .toDate()
                            : moment(
                                moment(
                                  props.values.redeemStartTime || initDate,
                                ).isAfter(
                                  moment(props.values.getEndTime || initDate),
                                )
                                  ? moment(
                                      props.values.redeemStartTime || initDate,
                                    ).toDate()
                                  : moment(
                                      props.values.getEndTime || initDate,
                                    ).toDate(),
                              ).toDate()
                        }
                        maxDate={moment().year(9999).toDate()}
                        maxTime={moment().year(9999).endOf('days').toDate()}
                      />
                    </div>
                  )}
                </div>
              </FormGroup>
            )}
            {props.values.dueType === 2 && (
              <FormGroup className="form-group mb-3 mb-3">
                <label />
                <div className="form-item">
                  <div className="mb-2">何時可開始使用：</div>
                  <div>
                    <label
                      className="d-inline-flex w-100 my-2"
                      htmlFor="radio-usableType-1"
                    >
                      <Field
                        type="radio"
                        name="usableType"
                        value={1}
                        disabled={isView || isEditor}
                        id="radio-usableType-1"
                        onChange={() => {
                          props.setFieldValue('usableType', 1);
                        }}
                      />
                      <span className="fw-medium px-1"> 領取後即可使用</span>
                    </label>
                    <label
                      className="d-inline-flex w-100 my-2 align-items-center"
                      htmlFor="radio-usableType-2"
                    >
                      <Field
                        type="radio"
                        name="usableType"
                        value={2}
                        disabled={isView || isEditor}
                        id="radio-usableType-2"
                        onChange={() => {
                          props.setFieldValue('usableType', 2);
                        }}
                      />
                      <span className="fw-medium px-1">
                        {' '}
                        領取後第"N"日可使用:
                      </span>
                      <Field
                        name="usableNdaysLater"
                        disabled={
                          props.values.usableType !== 2 || isView || isEditor
                        }
                        className="form-control mx-2"
                        style={{ maxWidth: '80px' }}
                        type="number"
                        value={props.values?.usableNdaysLater || 0}
                        onChange={e => {
                          props.setFieldValue(
                            'usableNdaysLater',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          props.setFieldValue(
                            'usableNdaysLater',
                            Number(e.target.value),
                          );
                        }}
                        placeholder="輸入數字"
                        min="0"
                      />
                      {props.errors?.usableNdaysLater && (
                        <div className="form-error px-2 fw-light">
                          {props.errors?.usableNdaysLater}
                        </div>
                      )}
                    </label>
                  </div>
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        舉例：若為領取優惠券後第2日可使用，將於收到隔日的 00:00
                        可開始使用
                      </small>
                    </li>
                  </ul>
                  <div className="mt-3 mb-1">可使用天數：</div>
                  <div className="d-flex w-100 align-items-center mb-1">
                    <Field
                      type="number"
                      placeholder="輸入數字"
                      min="0"
                      value={props.values?.usableDays || 0}
                      onChange={e => {
                        props.setFieldValue(
                          'usableDays',
                          Number(e.target.value).toString(),
                        );
                      }}
                      onBlur={e => {
                        props.setFieldValue(
                          'usableDays',
                          Number(e.target.value),
                        );
                      }}
                      disabled={isView || isEditor}
                      style={{ maxWidth: '100px' }}
                      name="usableDays"
                      className="form-control mx-2"
                    />
                    <span className="text-nowrap">
                      日內有效（含開始使用當日）
                    </span>
                  </div>
                  {props.errors?.usableDays && (
                    <div className="form-error px-2">
                      {props.errors?.usableDays}
                    </div>
                  )}
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        若設定1日內有效，將於開始使用當日的 23:59 到期
                      </small>
                    </li>
                  </ul>
                </div>
              </FormGroup>
            )}
            <div className="my-5" />
            <h4 className="title" style={titleStyle}>
              條件設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                折扣類型
              </label>
              <div className="form-item">
                <ul>
                  <li className=" text-secondary">
                    <small className=" text-secondary">
                      打折：請輸入1.0 ~
                      9.9之間的數字，例：8.5代表85折；7.0代表7折。
                    </small>
                  </li>
                </ul>

                <div className="d-flex flex-wrap align-items-center">
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-discountType-1"
                  >
                    <div
                      style={{
                        width: '25px',
                      }}
                    >
                      <Field
                        type="radio"
                        name="discountType"
                        value={1}
                        disabled={isView || isEditor}
                        id="radio-discountType-1"
                        onChange={() => {
                          props.setFieldValue('discountType', 1);
                          props.setFieldValue('discountAmount', 0);
                          props.setFieldValue('discountMaxAmount', 100);
                          props.setFieldValue('budget', 0);
                          props.setFieldValue('discountMax', 1);
                        }}
                      />
                    </div>
                    <span className="fw-medium px-1"> 商品折抵 NT$</span>
                    {props.values.discountType === 1 ? (
                      <Field
                        type="number"
                        placeholder="輸入數字"
                        value={props.values?.discountAmount || 0}
                        onChange={e => {
                          props.setFieldValue(
                            'discountAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          props.setFieldValue(
                            'discountAmount',
                            Number(e.target.value),
                          );
                        }}
                        min="0"
                        name="discountAmount"
                        className={'form-control mx-2'}
                        disabled={isView || isEditor}
                        style={{ maxWidth: '80px' }}
                      />
                    ) : (
                      <div
                        className="form-control mx-2 disabled"
                        style={{ maxWidth: '80px' }}
                      >
                        -
                      </div>
                    )}
                  </label>
                  {props.values.discountType === 1 &&
                    props.errors?.discountAmount && (
                      <div className="form-error">
                        {props.errors.discountAmount}
                      </div>
                    )}
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-discountType-2"
                  >
                    <div
                      style={{
                        width: '25px',
                      }}
                    >
                      <Field
                        type="radio"
                        name="discountType"
                        value={2}
                        disabled={isView || isEditor}
                        id="radio-discountType-2"
                        onChange={() => {
                          props.setFieldValue('discountType', 2);
                          props.setFieldValue('discountAmount', 0);
                          props.setFieldValue('discountMaxAmount', 0);
                          props.setFieldValue('discountMax', 1);
                          props.setFieldValue('budget', 0);
                        }}
                      />
                    </div>
                    <span className="fw-medium px-1"> 商品打折</span>
                    {props.values.discountType === 2 ? (
                      <Field
                        type="number"
                        value={props.values?.discountAmount || 0}
                        onChange={e => {
                          props.setFieldValue(
                            'discountAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          props.setFieldValue(
                            'discountAmount',
                            Number(e.target.value),
                          );
                        }}
                        placeholder="輸入數字"
                        min="0"
                        name="discountAmount"
                        disabled={isView || isEditor}
                        className={'form-control mx-2'}
                        style={{ maxWidth: '80px' }}
                      />
                    ) : (
                      <div
                        className="form-control mx-2 disabled"
                        style={{ maxWidth: '80px' }}
                      >
                        -
                      </div>
                    )}
                  </label>

                  {props.values.discountType === 2 &&
                    props.errors?.discountAmount && (
                      <div className="form-error">
                        {props.errors.discountAmount}
                      </div>
                    )}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                提供張數
              </label>
              <div className="form-item">
                {props.values.getWay === 4 && (
                  <div className="my-2">系統自動計算張數</div>
                )}
                {props.values.getWay !== 4 && (
                  <div className="d-flex align-items-center">
                    自訂張數
                    <Field
                      name="quantity"
                      className="form-control mx-2 w-auto"
                      type="number"
                      value={props.values?.quantity || 0}
                      onBlur={e => {
                        props.setFieldValue('quantity', Number(e.target.value));
                      }}
                      onChange={e => {
                        props.setFieldValue(
                          'quantity',
                          Number(e.target.value).toString(),
                        );
                      }}
                      placeholder="輸入數字"
                      min="0"
                      disabled={isView}
                    />
                    {props.errors?.quantity && (
                      <span className="form-error px-2">
                        {props.errors?.quantity}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </FormGroup>
            {props.values.discountType === 1 && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  優惠券預算
                </label>
                <div className="form-item">
                  <div className="d-flex align-items-center">
                    總計 NT$
                    <div
                      className={`form-control mx-2 text-nowrap w-auto ${
                        !props.values.budget && 'is-invalid'
                      }`}
                      style={{ minWidth: '80px' }}
                    >
                      {Number(props.values.budget).toLocaleString()}
                    </div>
                    <Button
                      color="primary"
                      outline
                      disabled={isView}
                      onClick={async () => {
                        props.setFieldValue(
                          'budget',
                          props.values.quantity * props.values.discountAmount,
                        );
                      }}
                    >
                      計算優惠券預算
                    </Button>
                  </div>
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        請點擊計算優惠券預算
                      </small>
                    </li>
                  </ul>
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                使用門檻
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      此門檻計算是根據消費者購買的「適用商品」，符合門檻則可使用消費券
                    </small>
                  </li>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      消費者結帳時，若商品不可使用此折價券，則商品不會被列入購物車金額的門檻計算
                    </small>
                  </li>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      若使用通路包含實體門市，需透過門市人員確認結帳金額是否滿足使用門檻
                    </small>
                  </li>
                </ul>

                <div className="d-flex align-items-center">
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-useMinAmount-1"
                  >
                    <Field
                      type="radio"
                      name="useMin"
                      value={1}
                      disabled={isView || isEditor}
                      id="radio-useMinAmount-1"
                      onChange={() => {
                        props.setFieldValue('useMin', 1);
                        props.setFieldValue('useMinAmount', 0);
                      }}
                    />
                    <span className="fw-medium px-1"> 無門檻</span>
                  </label>
                  <label
                    className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                    htmlFor="radio-useMinAmount-2"
                  >
                    <Field
                      type="radio"
                      name="useMin"
                      disabled={isView || isEditor}
                      value={2}
                      id="radio-useMinAmount-2"
                      onChange={() => {
                        props.setFieldValue('useMin', 2);
                        props.setFieldValue('useMinAmount', 0);
                        props.setFieldValue('discountMaxAmount', 100);
                      }}
                    />
                    <span className="fw-medium px-1">
                      滿額門檻，金額需滿NT$
                    </span>
                    <Field
                      type="number"
                      placeholder="輸入數字"
                      min="0"
                      name="useMinAmount"
                      value={props.values?.useMinAmount || 0}
                      onChange={e => {
                        props.setFieldValue(
                          'useMinAmount',
                          Number(e.target.value).toString(),
                        );
                      }}
                      onBlur={e => {
                        props.setFieldValue(
                          'useMinAmount',
                          Number(e.target.value),
                        );
                      }}
                      disabled={props.values.useMin !== 2 || isView || isEditor}
                      className="form-control mx-2"
                      style={{ maxWidth: '80px' }}
                    />
                  </label>
                  {props?.errors.useMinAmount && (
                    <div className="form-error">
                      {props.errors.useMinAmount}
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                折抵上限
              </label>
              {props.values.discountType === 1 && (
                <div className="form-item">
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        當有設定滿額門檻時，折抵上限設定為100%
                      </small>
                    </li>
                  </ul>
                  <div className="d-flex align-items-center">
                    最高可折抵購買金額為
                    <input
                      type="number"
                      placeholder="輸入數字"
                      min="0"
                      value={props.values?.discountMaxAmount || 0}
                      className="form-control mx-2"
                      disabled={props.values.useMin === 2 || isView || isEditor}
                      style={{ maxWidth: '80px' }}
                      onChange={e => {
                        if (Number(e.target.value).toString() > 100) {
                          props.setFieldValue('discountMaxAmount', 100);
                        } else {
                          props.setFieldValue(
                            'discountMaxAmount',
                            Number(e.target.value).toString(),
                          );
                        }
                      }}
                      onBlur={e => {
                        props.setFieldValue(
                          'discountMaxAmount',
                          Number(e.target.value),
                        );
                      }}
                    />
                    %
                  </div>
                  {props.errors.discountMaxAmount && (
                    <div className="form-error">
                      {props.errors.discountMaxAmount}
                    </div>
                  )}
                </div>
              )}
              {props.values.discountType === 2 && (
                <div className="form-item">
                  <div className="d-flex align-items-center">
                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-discountMax-1"
                    >
                      <Field
                        type="radio"
                        disabled={isView || isEditor}
                        name="discountMax"
                        value={1}
                        id="radio-discountMax-1"
                        onChange={() => {
                          props.setFieldValue('discountMax', 1);
                          props.setFieldValue('discountMaxAmount', 100);
                        }}
                      />
                      <span className="fw-medium px-1"> 不限定</span>
                    </label>

                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-discountMax-2"
                    >
                      <Field
                        type="radio"
                        name="discountMax"
                        value={2}
                        disabled={isView || isEditor}
                        id="radio-discountMax-2"
                        onChange={() => {
                          props.setFieldValue('discountMax', 2);
                          props.setFieldValue('discountMaxAmount', 100);
                        }}
                      />
                      <span className="fw-medium px-1">
                        {' '}
                        最高可折抵購買金額的NT$
                      </span>
                      <Field
                        type="number"
                        placeholder="輸入數字"
                        min="0"
                        name="discountMaxAmount"
                        disabled={
                          props.values.discountMax !== 2 || isView || isEditor
                        }
                        value={props.values?.discountMaxAmount || 0}
                        onChange={e => {
                          props.setFieldValue(
                            'discountMaxAmount',
                            Number(e.target.value).toString(),
                          );
                        }}
                        onBlur={e => {
                          props.setFieldValue(
                            'discountMaxAmount',
                            Number(e.target.value),
                          );
                        }}
                        className="form-control mx-2"
                        style={{ maxWidth: '80px' }}
                      />
                    </label>
                    {props.errors.discountMaxAmount && (
                      <div className="form-error">
                        {props.errors.discountMaxAmount}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                可使用通路
              </label>
              <div className="form-item">
                <div className="d-flex align-items-center">
                  {['web', 'store'].map(op => {
                    return (
                      <label
                        key={`radio-availableWays-${op}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-availableWays-${op}`}
                      >
                        <Field
                          type="checkbox"
                          name="availableWays"
                          value={op}
                          disabled={isView || isEditor}
                          checked={props.values?.availableWays.includes(op)}
                          id={`radio-availableWays-${op}`}
                          onChange={() => {
                            const availableWays =
                              props.values?.availableWays || [];
                            const index = availableWays.indexOf(op);
                            if (index > -1) {
                              availableWays.splice(index, 1);
                            } else {
                              availableWays.push(op);
                            }
                            props.setFieldValue('availableWays', availableWays);
                          }}
                        />
                        <span className="d-inline-block px-1 fw-medium">
                          {' '}
                          {op === 'web' && '網頁版'}
                          {op === 'store' && '門市'}
                        </span>
                      </label>
                    );
                  })}
                </div>
                {props.errors?.availableWays && (
                  <div className="form-error">
                    {props.errors?.availableWays}
                  </div>
                )}
              </div>
            </FormGroup>
            {props.values.availableWays.includes('web') && (
              <FormGroup className="form-group mb-3 mb-3">
                <label className="required" style={lableStyle}>
                  是否與折扣活動
                  <br />
                  合併使用
                </label>
                <div className="form-item">
                  <ul>
                    <li className="text-secondary">
                      <small className="text-secondary">
                        <span className="d-none">
                          設定為「否」，再購買流程不會自動預選此優惠券。
                        </span>
                        消費者可自行選擇使用折扣活動或是使用折價券的優惠
                      </small>
                    </li>
                  </ul>

                  <div className="d-flex align-items-center">
                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-isUsedwithDiscount-1"
                    >
                      <Field
                        type="radio"
                        name="isUsedwithDiscount"
                        value={true}
                        disabled={isView || isEditor}
                        id="radio-isUsedwithDiscount-1"
                        onChange={() => {
                          props.setFieldValue('isUsedwithDiscount', true);
                        }}
                      />
                      <span className="fw-medium px-1"> 是</span>
                    </label>
                    <label
                      className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                      htmlFor="radio-isUsedwithDiscount-2"
                    >
                      <Field
                        type="radio"
                        name="isUsedwithDiscount"
                        value={false}
                        disabled={isView || isEditor}
                        id="radio-isUsedwithDiscount-2"
                        onChange={() => {
                          props.setFieldValue('isUsedwithDiscount', false);
                        }}
                      />
                      <span className="fw-medium px-1"> 否</span>
                    </label>
                  </div>
                </div>
              </FormGroup>
            )}
            <FormGroup className="form-group mb-3 mb-3">
              <label className="required" style={lableStyle}>
                適用商品
              </label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      此折價券指定商品中若有「不適用任何折價券商品」，該商品亦無法使用本折價券折抵
                    </small>
                  </li>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      商品清單更新後，系統將於30分鐘後生效
                    </small>
                  </li>
                </ul>

                <div className="d-flex align-items-center">
                  {[
                    { name: '全店商品', value: 'all' },
                    { name: '指定商品', value: 'picked' },
                  ].map((op, index) => {
                    return (
                      <label
                        key={`radio-range-${op.value}`}
                        className="d-inline-flex m-2 align-items-center flex-grow-0 text-nowrap"
                        htmlFor={`radio-range-${op.value}`}
                      >
                        <Field
                          type="radio"
                          name="productRange"
                          disabled={isView}
                          value={op.value}
                          id={`radio-range-${op.value}`}
                          onChange={() => {
                            props.setFieldValue('productRange', op.value);
                            props.setFieldTouched('products', true);
                          }}
                        />
                        <span className="fw-medium px-1"> {op.name}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </FormGroup>
            <ProductRange
              errors={props.errors}
              touched={props.touched}
              disabled={isView}
              type={props.values.productRange}
              models={props.values.models}
              onChange={(products, type) => {
                props.setFieldTouched('models', true);
                props.setFieldValue('models', products);
              }}
            />
            <div className="my-5" />
            <h4 className="title" style={titleStyle}>
              其他設定
            </h4>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>剩餘張數提醒</label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      剩餘券數低於設定值時，系統將發信至主要窗口及業務窗口、帳務窗口
                    </small>
                  </li>
                </ul>
                <div className="d-flex align-items-center">
                  當優惠券發送剩餘「x」張時，系統發信提醒：
                  <Field
                    type="number"
                    min="0"
                    placeholder="輸入數字"
                    disabled={isView}
                    className="form-control mx-2"
                    style={{ maxWidth: '150px', minWidth: '100px' }}
                    name="remiderNum"
                    value={props.values?.remiderNum || 0}
                    onChange={e => {
                      props.setFieldValue(
                        'remiderNum',
                        Number(e.target.value).toString(),
                      );
                    }}
                    onBlur={e => {
                      props.setFieldValue('remiderNum', Number(e.target.value));
                    }}
                  />
                  {props.errors.remiderNum && (
                    <div className="form-error">{props.errors.remiderNum}</div>
                  )}
                </div>
              </div>
            </FormGroup>
            <FormGroup className="form-group mb-3 mb-3">
              <label style={lableStyle}>自訂活動代碼</label>
              <div className="form-item">
                <ul>
                  <li className="text-secondary">
                    <small className="text-secondary">
                      輸入自訂的活動代碼或pos產出的活動代碼，以辨識該活動的訂單，限20字元以內英文字母、數字、半形底線「_」，或半形連字號「-」
                    </small>
                  </li>
                </ul>
                <div className="d-flex">
                  <Field
                    style={{ maxWidth: '200px' }}
                    name="customedActivityCode"
                    placeholder="輸入自訂活動代碼"
                    className="form-control mx-2"
                    disabled={isView || isEditor}
                  />
                  <span
                    style={{ minWidth: '80px' }}
                    className={`text-nowrap ${
                      props.values?.customedActivityCode?.length > 20
                        ? 'text-danger'
                        : ''
                    }`}
                  >
                    ({props.values?.customedActivityCode?.length || 0}/20)
                  </span>
                </div>
              </div>
            </FormGroup>
            <div className="mt-4 text-nowrap row flex-wrap">
              <div
                className="d-inline-block col-4"
                style={{ minWidth: '300px' }}
              >
                新增時間: {props.values.createTime || '--'}{' '}
                {props.values.creator || '--'}
              </div>
              {!!detail.id && (
                <div
                  className="d-inline-block col-4"
                  style={{ minWidth: '300px' }}
                >
                  異動時間: {props.values.updateTime || '--'}{' '}
                  {props.values.updater || '--'}
                </div>
              )}
            </div>
            <hr />
            <div className="text-center">
              <Button
                type="button"
                className={style.modal_button_cancel}
                onClick={goBack}
              >
                <span className="btn-wrapper--label">取消</span>
              </Button>
              {!isView && (
                <Button
                  type="button"
                  className={style.modal_button_submit}
                  disabled={
                    loading || Object.keys(props.errors).length || !props.dirty
                  }
                  onClick={() => {
                    handleSubmit(props);
                  }}
                >
                  {loading && (
                    <span
                      className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      style={{ marginRight: '1rem' }}
                    ></span>
                  )}
                  <span className="btn-wrapper--label">儲存</span>
                </Button>
              )}
            </div>
            <pre className="d-none">{JSON.stringify(props.errors)}</pre>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default memo(DiscountTabPane);
