import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { hourArr, min2Arr } from 'utils';
import CommonUtil from 'utils/CommonUtil';
import MessageUtil from 'utils/MessageUtil';
import request from 'utils/useAxios';

import style from '../../styles/layout.module.css';

const LaunchTimeModal = ({ open, toggle, item, onSuccess }) => {
  const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        launchedTime: item?.launchedTime,
        launchedTimeHour: item?.launchedTime
          ? moment(item?.launchedTime).hour()
          : 0,
        launchedTimeMin: item?.launchedTime
          ? moment(item?.launchedTime).minute()
          : 0,
      },
      async onSubmit(formModel) {
        const params = {
          launchedTime: (formModel.launchedTime
            ? moment(formModel.launchedTime)
            : moment()
          )
            .hour(+formModel.launchedTimeHour)
            .minute(+formModel.launchedTimeMin)
            .second(0)
            .format(),
          modelId: item.id,
        };
        CommonUtil.consoleLog({ anno: 'request body', data: params });
        const { code, message } = await request({
          data: params,
          method: 'post',
          url: '/Backend/Model/SaveLaunchedTime',
        });
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('修改成功！');
        toggle();
        onSuccess && onSuccess();
      },
    });
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        上架日期修改
      </ModalHeader>
      <ModalBody className={style.modal_body} style={{ height: '320px' }}>
        {isSubmitting && <BlockSpinner />}
        <FormGroup row>
          <Label style={{ width: 150 }}>
            <span className="text-danger">* </span>
            上架日期
          </Label>
          <Col>
            <div className="form-item d-flex flex-wrap">
              <div className="w200">
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={moment(values?.launchedTime || new Date())
                    .startOf('day')
                      .toDate()}
                  name="launchedTime"
                  onChange={date => {
                    setFieldValue(
                      'launchedTime',
                      moment(date).startOf('day').toDate(),
                    );
                  }}
                  selectsStart
                />
              </div>
              <Input
                type="select"
                className="w80 ms-1"
                name="launchedTimeHour"
                onChange={handleChange}
                value={values.launchedTimeHour}
              >
                {hourArr().map(i => (
                  <option key={i.value} value={i.value}>
                    {i.label}
                  </option>
                ))}
              </Input>
              <span className="px-2 pt-2">:</span>
              <Input
                type="select"
                className="w80"
                name="launchedTimeMin"
                onChange={handleChange}
                value={values.launchedTimeMin}
              >
                {min2Arr().map(i => (
                  <option key={i.value} value={i.value}>
                    {i.label}
                  </option>
                ))}
              </Input>
            </div>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LaunchTimeModal;
