import Layout from 'components/Layout';
import SearchSelect from 'components/SearchSelector';
import { BlockSpinner } from 'components/Spinner';
import { useFormik } from 'formik';
import moment from 'moment';
import RcPagination from 'rc-pagination';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from 'reactstrap';
import {
  deleteGroupModel,
  getGroupModelList,
  UpdateModelOption,
  UpdateProductOption,
} from 'redux/actions/data/groupModelActions';
import {
  GROUP_MODEL_DELETE_RESET,
  GROUP_MODEL_LIST_SUCCESS,
} from 'redux/constants/data/groupModelConstants';
import { checkOverNow, emptyOption, hourArr, min2Arr } from 'utils';
import MessageUtil from 'utils/MessageUtil';
import history from 'utils/createHistory';
import request from 'utils/useAxios';

import TableHasNoData, { TableLoading } from '../components/TableHasNoData';
import { getBrandOptions } from '../redux/actions/data/brandActions';
import { getModelOptions } from '../redux/actions/data/productActions';
import style from '../styles/layout.module.css';

const LaunchTimeModal = ({ open, toggle, item, onSuccess }) => {
  const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        launchedTime: item?.launchedTime,
        launchedTimeHour: item?.launchedTime
          ? moment(item?.launchedTime).hour()
          : 0,
        launchedTimeMin: item?.launchedTime
          ? moment(item?.launchedTime).minute()
          : 0,
      },
      async onSubmit(formModel) {
        const params = {
          launchedTime: (formModel.launchedTime
            ? moment(formModel.launchedTime)
            : moment()
          )
            .hour(+formModel.launchedTimeHour)
            .minute(+formModel.launchedTimeMin)
            .second(0)
            .format(),
          modelId: item.id,
        };
        const { code, message } = await request({
          data: params,
          method: 'post',
          url: '/Backend/GroupModel/SaveLaunchedTime',
        });
        if (code !== 200) {
          MessageUtil.alertWanring(message);
          return;
        }
        MessageUtil.toastSuccess('修改成功！');
        toggle();
        onSuccess && onSuccess();
      },
    });
  return (
    <Modal
      zIndex={2000}
      centered
      size="lg"
      isOpen={open}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle} className={style.modal_head}>
        上架日期修改
      </ModalHeader>
      <ModalBody className={style.modal_body}>
        {isSubmitting && <BlockSpinner />}
        <FormGroup row>
          <Label style={{ width: 150 }}>
            <span className="text-danger">* </span>
            上架日期
          </Label>
          <Col>
            <div className="form-item d-flex flex-wrap">
              <div className="w200">
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={
                    moment(values?.launchedTime || undefined).toDate() ??
                    moment().toDate()
                  }
                  name="launchedTime"
                  onChange={date => {
                    setFieldValue('launchedTime', date?.toISOString());
                  }}
                  selectsStart
                />
              </div>
              <Input
                type="select"
                className="w80 ms-1"
                name="launchedTimeHour"
                onChange={handleChange}
                value={values.launchedTimeHour}
              >
                {hourArr().map(i => (
                  <option key={i.value} value={i.value}>
                    {i.label}
                  </option>
                ))}
              </Input>
              <span className="px-2 pt-2">:</span>
              <Input
                type="select"
                className="w80"
                name="launchedTimeMin"
                onChange={handleChange}
                value={values.launchedTimeMin}
              >
                {min2Arr().map(i => (
                  <option key={i.value} value={i.value}>
                    {i.label}
                  </option>
                ))}
              </Input>
            </div>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className={style.modal_footer}>
        <Button
          color="link"
          className={style.modal_button_cancel}
          onClick={toggle}
        >
          取消
        </Button>{' '}
        <Button
          type="submit"
          color="primary"
          className={style.modal_button_submit}
          disabled={isSubmitting}
        >
          {isSubmitting && (
            <span
              className="me-2 btn-wrapper--icon spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          )}
          <span className="btn-wrapper--label" onClick={handleSubmit}>
            儲存
          </span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const GroupModel = () => {
  const dispatch = useDispatch();
  const { loading, groupModelListInfo } = useSelector(
    state => state.groupModelList,
  );
  const { brandOptions, loading: brandOptionsLoading } = useSelector(
    state => state.brandOptions,
  );
  const { modelOptions: productOptions, loading: productOptionssLoading } =
    useSelector(state => state.productmodelOptions);

  const { values, handleChange, handleSubmit, setFieldValue, handleReset } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        availableEndTimeType: true,
        availableTimeEnd: null,
        availableTimeEndHour: 0,
        availableTimeEndMin: 0,
        availableTimeStart: null,
        availableTimeStartHour: 0,
        availableTimeStartMin: 0,
        brandId: 0,
        deliveryType: '0',
        isSell: null,
        keyword: '',
        logisticsWay: '0',
        model: '',
        page: 1,
        page_size: 10,
        stock: 0,
        stockType: '1',
      },
      async onSubmit(formModel) {
        const params = {
          brandId: formModel.brandId,
          deliveryType: Number(formModel.deliveryType),
          isSell: formModel.isSell ?? null,
          keyword: formModel.keyword,
          logisticsWay: Number(formModel.logisticsWay),
          model: formModel.model,
          stock: Number(formModel.stock),
          stockType: Number(formModel.stockType),
          ...(!formModel.availableEndTimeType && { availableEndTimeType: 1 }),
          ...(!formModel.availableEndTimeType && {
            availableTimeEnd: formModel.availableTimeEnd
              ? moment(formModel.availableTimeEnd)
              : moment(),
            availableTimeStart: formModel.availableTimeStart
              ? moment(formModel.availableTimeStart)
              : moment().subtract(7, 'day'),
          }),
          page: formModel.page,
          page_size: 10,
        };
        dispatch(getGroupModelList(params));
      },
    });

  const handleChangePage = n => {
    setFieldValue('page', n);
    handleSubmit();
  };

  useEffect(() => {
    dispatch(
      getGroupModelList({
        page: 1,
        page_size: 10,
      }),
    );
    dispatch(getBrandOptions());
    dispatch(getModelOptions(''));
  }, []);

  useEffect(() => {
    brandOptions &&
      dispatch(
        getModelOptions(
          values.brandId
            ? brandOptions.find(i => Number(i.value) === Number(values.brandId))
                ?.label
            : '',
        ),
      );
  }, [values.brandId, brandOptions]);

  const { success, error } = useSelector(state => state.deleteGroupModel);

  const handleDelete = id => {
    MessageUtil.submitConfirm({
      doneFun() {
        dispatch(deleteGroupModel(id));
      },
      title: '刪除商品？',
    });
  };
  useEffect(() => {
    success && MessageUtil.toastSuccess('刪除成功！');
    success && handleSubmit();
    error && MessageUtil.alertWanring('刪除失敗！', error);
    return () => {
      dispatch({ type: GROUP_MODEL_DELETE_RESET });
    };
  }, [success, error]);

  const [launchModalOpen, setLaunchModalOpen] = useState(false);
  const [currentProd, setCurrentProd] = useState(null);
  const handleToggleLaunchModal = () => {
    setLaunchModalOpen(!launchModalOpen);
  };

  const isSticky =
    groupModelListInfo?.datas?.length > 0 ? { height: '80vh' } : {};

  const updateModelOption = useCallback(async (mid, isSell = true) => {
    try {
      await UpdateModelOption(mid, {
        result: isSell,
        type: 'isSell',
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  const updateProductOption = useCallback(async (pid, isSell = true) => {
    try {
      await UpdateProductOption(pid, {
        result: isSell,
        type: 'isSell',
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <Layout
      pageTitle="商品組合"
      items={[
        { isActive: false, page_name: '商品管理' },
        { isActive: true, page_name: '商品組合' },
      ]}
    >
      <Card className={style.search_card}>
        <div className={style.card_header}>搜尋條件</div>
        <div className={style.card_body}>
          <form>
            <div className={style.show_flex}>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  關鍵字
                </label>
                <input
                  type="text"
                  className={`form-control ${style['form-input']}`}
                  name="keyword"
                  placeholder="商品名稱"
                  onChange={handleChange}
                  value={values.keyword}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">品牌</label>
                <Select
                  isLoading={brandOptionsLoading}
                  isDisabled={brandOptionsLoading}
                  className={style['form-select']}
                  options={[
                    emptyOption('請選擇品牌', 0),
                    ...(brandOptions ? [...brandOptions] : []),
                  ]}
                  id="brandId"
                  name="brandId"
                  defaultValue={emptyOption('請選擇品牌', 0)}
                  onChange={({ value }) =>
                    setFieldValue('brandId', Number(value))
                  }
                  value={
                    brandOptions?.find(
                      i => Number(i.value) === Number(values.brandId),
                    ) || emptyOption('請選擇品牌', 0)
                  }
                />
              </div>

              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label
                  className="col-form-label mr-2"
                  style={{ marginRight: '1rem' }}
                >
                  型號
                </label>
                <Select
                  isLoading={productOptionssLoading}
                  isDisabled={productOptionssLoading}
                  className={style['form-select']}
                  options={
                    productOptions && [
                      emptyOption('請選擇型號'),
                      ...productOptions,
                    ]
                  }
                  id="model"
                  name="model"
                  defaultValue={emptyOption('請選擇型號')}
                  onChange={({ value }) => setFieldValue('model', value)}
                  value={
                    productOptions?.find(i => i.value === values.model) ||
                    emptyOption('請選擇型號')
                  }
                />
              </div>

              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">交期</label>
                <SearchSelect
                  className={style['form-select']}
                  options={[
                    { label: '一般', value: 1 },
                    { label: '預購', value: 2 },
                  ]}
                  emptyVal={0}
                  name="deliveryType"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">配送方式</label>
                <SearchSelect
                  className={style['form-select']}
                  options={[
                    { label: '7-11', value: 1 },
                    { label: '全家', value: 2 },
                    { label: '宅配', value: 3 },
                    { label: '門市', value: 4 },
                  ]}
                  emptyVal={0}
                  name="logisticsWay"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2">上/下架官網商品</label>
                <SearchSelect
                  className={style['form-select']}
                  options={[
                    { label: '是', value: true },
                    { label: '否', value: false },
                  ]}
                  emptyVal={null}
                  name="isSell"
                  setFieldValue={setFieldValue}
                  values={values}
                />
              </div>

              <div className={style.formGroup} style={{ marginRight: '1rem' }}>
                <label className="col-form-label mr-2">可銷售數量</label>
                <Input
                  type="select"
                  name="stockType"
                  className={style['form-select-short']}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('stockType', Number(value));
                  }}
                  style={{ margin: 0 }}
                >
                  <option value="1">大於</option>
                  <option value="2">小於</option>
                </Input>
                <Input
                  type="number"
                  name="stock"
                  className={`${style['form-input-short']} ${style['text-center']}`}
                  defaultValue={0}
                  onChange={e => {
                    const value = e.target.value;
                    setFieldValue('stock', Number(value));
                  }}
                ></Input>
              </div>

              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <label className="col-form-label mr-2 text-nowrap">
                  可銷售區間
                </label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  popperClassName={style['form-control-date-picker']}
                  showTimeSelect
                  selected={
                    values.availableTimeStart ??
                    moment().subtract(7, 'day').toDate()
                  }
                  name="availableTimeStart"
                  onChange={date => {
                    setFieldValue('availableTimeStart', date);
                  }}
                  selectsStart
                  disabled={values.availableEndTimeType}
                />
                <span style={{ margin: '1rem' }}>~</span>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd p"
                  popperClassName={style['form-control-date-picker']}
                  showTimeSelect
                  selected={values.availableTimeEnd ?? new Date()}
                  name="availableTimeEnd"
                  onChange={date => {
                    setFieldValue('availableTimeEnd', date);
                  }}
                  selectsEnd
                  minDate={values.availableTimeStart}
                  disabled={values.availableEndTimeType}
                />
              </div>
              <div
                className={style.formGroup}
                style={{ marginRight: '1.5rem' }}
              >
                <Label className="d-flex align-items-center gap-2">
                  <input
                    type="checkbox"
                    className={style.maintain_list_checkbox}
                    name="availableEndTimeType"
                    onChange={handleChange}
                    checked={values.availableEndTimeType}
                  />
                  不限時間
                </Label>
              </div>
            </div>
          </form>
          <div className={style.button_position}>
            <Button
              type="button"
              className={style.modal_button_cancel}
              onClick={() => {
                handleReset();
                handleSubmit(values);
              }}
            >
              取消
            </Button>
            <Button
              type="button"
              className={style.modal_button_submit}
              onClick={() => handleChangePage(1)}
            >
              查詢
            </Button>
          </div>
        </div>
      </Card>
      <Card className={style.card}>
        <div className="mb-3">
          <Link to="/GroupModelAdd">
            <Button color="success">新增商品組合</Button>
          </Link>
        </div>
        <div className="table-responsive mb-5" style={isSticky}>
          <Table hover bordered striped>
            <thead
              className={`thead-dark ${style.table_head} ${style['form-table-header-sticky']}`}
            >
              <tr>
                <th>功能</th>
                <th>商品名稱</th>
                <th>銷售時間</th>
                <th>官網商品</th>
                <th>
                  <div>商品料號</div>
                  <div>顏色,尺寸</div>
                  <div>贈品</div>
                </th>
                <th>
                  <div>建議售價</div>
                  <div>售價</div>
                </th>
                <th>
                  <div>已售數量</div>
                  <div>可售數量</div>
                  <div>貨到通知數</div>
                </th>
                <th>上/下架</th>
                <th>商品缺貨顯示</th>
                <th style={{ minWidth: '120px' }}>新增時間</th>
                <th style={{ minWidth: '120px' }}>交期</th>
                <th>配送方式</th>
                <th>上市日期</th>
              </tr>
            </thead>
            {loading && <TableLoading />}
            {!loading && (
              <tbody>
                {groupModelListInfo?.datas?.length > 0 ? (
                  groupModelListInfo?.datas?.map((data, index) =>
                    data?.products.map((product, pIndex) => (
                      <tr key={`${data.id}_${product.productId}`}>
                        {pIndex === 0 && (
                          <>
                            <td rowSpan={data.products?.length || 1}>
                              <div className="d-flex gap-2">
                                <Button
                                  className="m-2 btn btn-outline-primary btn-sm text-primary"
                                  style={{
                                    backgroundColor: 'transparent',
                                  }}
                                  onClick={() =>
                                    history.push(`/GroupModelDetail/${data.id}`)
                                  }
                                >
                                  設定
                                </Button>
                                <Button
                                  className="m-2 btn btn-sm"
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderColor: '#f4772e',
                                  }}
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_WEBSITE_URL}/products/${data.id}?productType=3&preview=1&previewToken=${groupModelListInfo.previewToken}`,
                                    );
                                  }}
                                >
                                  <span style={{ color: '#f4772e' }}>預覽</span>
                                </Button>
                                {data.availableEndTimeType !== 2 &&
                                  !checkOverNow(data.availableTimeStart) && (
                                    <Button
                                      className="m-2 btn btn-outline-primary btn-sm text-primary"
                                      color="danger"
                                      outline
                                      onClick={() => handleDelete(data.id)}
                                    >
                                      刪除
                                    </Button>
                                  )}
                              </div>
                            </td>
                            <td rowSpan={data.products?.length || 1}>
                              {data.name || '--'}
                            </td>
                            <td rowSpan={data.products?.length || 1}>
                              <div className="text-nowrap">
                                起：
                                {data.availableTimeStart
                                  ? moment(data.availableTimeStart).format(
                                      'YYYY/MM/DD HH:mm:ss',
                                    )
                                  : '--'}
                              </div>
                              <div className="text-nowrap">
                                訖：
                                {data.availableTimeEnd
                                  ? moment(data.availableTimeEnd).format(
                                      'YYYY/MM/DD HH:mm:ss',
                                    )
                                  : '--'}
                              </div>
                            </td>
                            <td rowSpan={data.products.length}>
                              <div className="d-inline-flex w-100 align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={data.isSell}
                                  onChange={() => {
                                    updateModelOption(data.id, true).then(
                                      () => {
                                        groupModelListInfo.datas[
                                          index
                                        ].isSell = true;

                                        groupModelListInfo.datas[
                                          index
                                        ].products = (
                                          groupModelListInfo.datas[index]
                                            ?.products || []
                                        ).map(p => ({
                                          ...p,
                                          isSell: true,
                                        }));

                                        groupModelListInfo.datas[
                                          index
                                        ].products.forEach(p => {
                                          updateProductOption(
                                            p.productId,
                                            true,
                                          );
                                        });

                                        dispatch({
                                          payload: groupModelListInfo,
                                          type: GROUP_MODEL_LIST_SUCCESS,
                                        });
                                      },
                                    );
                                  }}
                                />
                                上架
                              </div>
                              <div className="d-inline-flex w-100 align-items-center gap-2">
                                <input
                                  type="radio"
                                  className={style.maintain_list_checkbox}
                                  checked={!data.isSell}
                                  onChange={() => {
                                    updateModelOption(data.id, false).then(
                                      () => {
                                        groupModelListInfo.datas[
                                          index
                                        ].isSell = false;

                                        groupModelListInfo.datas[
                                          index
                                        ].products = (
                                          groupModelListInfo.datas[index]
                                            ?.products || []
                                        ).map(p => ({
                                          ...p,
                                          isSell: false,
                                        }));

                                        groupModelListInfo.datas[
                                          index
                                        ].products.forEach(p => {
                                          updateProductOption(
                                            p.productId,
                                            false,
                                          );
                                        });

                                        dispatch({
                                          payload: groupModelListInfo,
                                          type: GROUP_MODEL_LIST_SUCCESS,
                                        });
                                      },
                                    );
                                  }}
                                />
                                下架
                              </div>
                            </td>
                          </>
                        )}
                        {/* 商品料號 顏色 贈品 */}
                        <td>
                          <div className="text-nowrap">
                            {product.productcode || '-'}
                          </div>
                          <div className="text-nowrap">
                            {product.color || '--'}/{product.size || '--'}
                          </div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                              fontSize: '14px',
                            }}
                            onClick={() => {
                              history.push(
                                `/GiftsBinding?id=${product.productId}&mid=${data.id}&productcode=${product.productcode}&productType=3`,
                              );
                            }}
                          >
                            加入贈品
                          </div>
                          <div>{`贈品${product?.freebieNum ?? '--'}個`}</div>
                        </td>
                        {/* 建議售價 售價 */}
                        <td>
                          <div className="price text-black fw-normal">
                            {(product?.websiteprice || '--')?.toLocaleString(
                              'en-US',
                            )}
                          </div>
                          <div className="price text-black fw-normal">
                            {(product?.sellprice || '--')?.toLocaleString(
                              'en-US',
                            )}
                          </div>
                        </td>
                        {/* 已售數量 可售數量 貨到通知數 */}
                        <td className="text-center">
                          <div>{product.soldNum || 0}</div>
                          <div
                            style={{
                              color: '#0077ff',
                              cursor: 'pointer',
                            }}
                            onClick={() => {}}
                          >
                            {product.stock || 0}
                          </div>
                          <div>{product.notifyNum || 0}</div>
                        </td>
                        {/* 上下架 */}
                        <td style={{ minWidth: '150px' }}>
                          <div className="d-inline-flex w-100 align-items-center gap-2 flex-nowrap">
                            <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              checked={product.isSell}
                              onChange={() => {
                                updateProductOption(
                                  product.productId,
                                  true,
                                ).then(() => {
                                  groupModelListInfo.datas[index].products[
                                    pIndex
                                  ].isSell = true;

                                  dispatch({
                                    payload: groupModelListInfo,
                                    type: GROUP_MODEL_LIST_SUCCESS,
                                  });
                                });
                              }}
                            />
                            上架
                          </div>
                          <div className="d-inline-flex w-100 align-items-center gap-2 flex-nowrap">
                            <input
                              type="radio"
                              className={style.maintain_list_checkbox}
                              checked={!product.isSell}
                              onChange={() => {
                                updateProductOption(
                                  product.productId,
                                  false,
                                ).then(() => {
                                  groupModelListInfo.datas[index].products[
                                    pIndex
                                  ].isSell = false;

                                  dispatch({
                                    payload: groupModelListInfo,
                                    type: GROUP_MODEL_LIST_SUCCESS,
                                  });
                                });
                              }}
                            />
                            下架
                          </div>
                        </td>
                        {/* 商品缺貨顯示 */}
                        <td>{product.outofStockWords || '--'}</td>
                        {/* 新增時間 */}
                        <td nowrap="nowrap">{product?.createTime ?? '--'}</td>
                        {/* 交期 */}
                        <td
                          style={{
                            color: '#0077ff',
                            cursor: 'pointer',
                            wordBreak: 'keep-all',
                          }}
                          onClick={() => {}}
                        >
                          <div>
                            {Number(product.deliveryType) === 1
                              ? '一般'
                              : '預購'}
                          </div>
                          {Number(product.deliveryType) === 2 && (
                            <div>
                              {product.preorderDeliveryDate
                                ? product.preorderDeliveryDate.split('T')[0]
                                : '--'}
                            </div>
                          )}
                        </td>
                        {pIndex === 0 && (
                          <td rowSpan={data.products.length}>
                            {data.isLogistic1 && <div>7-11取貨</div>}
                            {data.isLogistic2 && <div>全家取貨</div>}
                            {data.isLogistic3 && <div>宅配</div>}
                            {data.isLogistic4 && <div>門市取貨</div>}
                          </td>
                        )}
                        {pIndex === 0 && (
                          <td rowSpan={data.products.length}>
                            <Button
                              color="link"
                              className="text-decoration-none d-flex align-items-center gap-1"
                              onClick={() => {
                                setLaunchModalOpen(true);
                                setCurrentProd(data);
                              }}
                            >
                              {data?.launchedTime
                                ? moment(data?.launchedTime).format(
                                    'YYYY/MM/DD HH:mm:ss',
                                  )
                                : '--'}
                              <FiEdit />
                            </Button>
                          </td>
                        )}
                      </tr>
                    )),
                  )
                ) : (
                  <TableHasNoData />
                )}
              </tbody>
            )}
          </Table>
        </div>
        {groupModelListInfo?.total_count > 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <RcPagination
              current={groupModelListInfo?.current_page}
              defaultPageSize={groupModelListInfo?.page_size}
              total={groupModelListInfo?.total_count}
              onChange={handleChangePage}
            />
          </div>
        )}
        <LaunchTimeModal
          open={launchModalOpen}
          toggle={handleToggleLaunchModal}
          item={currentProd}
          onSuccess={handleSubmit}
        />
      </Card>
    </Layout>
  );
};
export default GroupModel;
