import {
  UUCAFE_SEO_SETTINGS_DETAIL_FAIL,
  UUCAFE_SEO_SETTINGS_DETAIL_REQUEST,
  UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST,
  UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS,
  UUCAFE_SEO_SETTINGS_LIST_FAIL,
  UUCAFE_SEO_SETTINGS_LIST_REQUEST,
  UUCAFE_SEO_SETTINGS_LIST_SUCCESS,
} from '../../constants/data/uuCafeSEOSettingsConstants';

export const cafeSEOSettingsListReducer = (
  state = { error: null, list: [], loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SEO_SETTINGS_LIST_REQUEST:
      return { error: null, list: [], loading: true, success: false };
    case UUCAFE_SEO_SETTINGS_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_SEO_SETTINGS_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeSEOSettingDetailReducder = (
  state = { detail: {}, error: null, loading: false, success: false },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_SEO_SETTINGS_DETAIL_REQUEST:
      return { detail: {}, error: null, loading: true, success: false };
    case UUCAFE_SEO_SETTINGS_DETAIL_SUCCESS:
      return {
        ...state,
        detail: payload,
        loading: false,
        success: true,
      };
    case UUCAFE_SEO_SETTINGS_DETAIL_FAIL:
      return { ...state, error: payload, loading: false };
    case UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_SEO_SETTINGS_DETAIL_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
