import SortingTableHead from 'components/SortingTableHead';
import TableHasNoData, { TableLoading } from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

const TableList = ({ setFieldValue, values }) => {
  const { loading, reportList } = useSelector(
    state => state.getOrderInstantReport,
  );

  useEffect(() => {
    if (JSON.stringify(reportList) !== '{}') {
      CommonUtil.consoleLog({ anno: 'reponse', data: reportList });
    }
  }, [reportList]);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>
              <SortingTableHead
                activeId={{
                  down: 0,
                  item: 'storeNumber',
                  up: 1,
                }}
                title="門市編號"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 2,
                  item: 'storeName',
                  up: 3,
                }}
                title="門市名稱"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 4,
                  item: 'orderSource',
                  up: 5,
                }}
                title="訂單來源"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 6,
                  item: 'cartCount',
                  up: 7,
                }}
                title="購物車數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 8,
                  item: 'masterCount',
                  up: 9,
                }}
                title="主單數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 10,
                  item: 'itemCount',
                  up: 11,
                }}
                title="訂單數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 12,
                  item: 'quantity_sum',
                  up: 13,
                }}
                title="總商品數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 14,
                  item: 'finalPrice_sum',
                  up: 15,
                }}
                title="訂單金額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
          </tr>
        </TableHead>
        {loading && <TableLoading />}
        {!loading && (
          <tbody>
            {reportList.datas?.length > 0 ? (
              reportList.datas.map((data, i) => (
                <tr key={i}>
                  <td>{data.storeNumber ?? '--'}</td>
                  <td>{data.storeName ?? '--'}</td>
                  <td>{data.orderSource ?? '--'}</td>
                  <td>{data.cartCount ?? '--'}</td>
                  <td>{data.masterCount ?? '--'}</td>
                  <td>{data.itemCount ?? '--'}</td>
                  <td>{data.quantity_sum ?? '--'}</td>
                  <td className="price text-black fw-normal">
                    {(data.finalPrice_sum || 0).toLocaleString('en-US')}
                  </td>
                </tr>
              ))
            ) : (
              <TableHasNoData colNumber={8} />
            )}
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default TableList;
