import {
  UUCAFE_MEALINTRO_OTHER_ADD_FAIL,
  UUCAFE_MEALINTRO_OTHER_ADD_REQUEST,
  UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_FAIL,
  UUCAFE_MEALINTRO_OTHER_LIST_FAIL,
  UUCAFE_MEALINTRO_OTHER_LIST_REQUEST,
  UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_REQUEST,
  UUCAFE_MEALINTRO_OTHER_SUCCESS,
  UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL,
  UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST,
  UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS,
} from '../../constants/data/uuCafeMealOtherConstants';

// ============ MEALINTRO_Other ==================

export const cafeMealInfoOtherListReducer = (
  state = {
    error: null,
    list: {
      current_page: 0,
      datas: [],
      order: '',
      order_by: '',
      page_size: 10,
      previewToken: '',
      total_count: 0,
      total_page: 0,
      updateTime: '',
    },
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case UUCAFE_MEALINTRO_OTHER_LIST_REQUEST:
      return {
        error: null,
        list: {
          current_page: 0,
          datas: [],
          order: '',
          order_by: '',
          page_size: 10,
          previewToken: '',
          total_count: 0,
          total_page: 0,
          updateTime: '',
        },
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_OTHER_LIST_SUCCESS:
      return { ...state, list: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_OTHER_LIST_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};

export const cafeMealInfoOtherReducer = (
  state = {
    detail: {
      name: '',
      prdCount: 0,
      sort: 0,
      status: 0,
      templateType: 1,
    },
    error: null,
    loading: false,
    success: false,
  },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case UUCAFE_MEALINTRO_OTHER_REQUEST:
      return {
        detail: {
          name: '',
          prdCount: 0,
          sort: 0,
          status: 0,
          templateType: 1,
        },
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_OTHER_SUCCESS:
      return { ...state, detail: payload, loading: false, success: true };
    case UUCAFE_MEALINTRO_OTHER_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MEALINTRO_OTHER_ADD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_OTHER_ADD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_OTHER_ADD_FAIL:
      return { ...state, error: payload || true, loading: false };
    case UUCAFE_MEALINTRO_OTHER_UPDATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      };
    case UUCAFE_MEALINTRO_OTHER_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true };
    case UUCAFE_MEALINTRO_OTHER_UPDATE_FAIL:
      return { ...state, error: payload || true, loading: false };
    default:
      return state;
  }
};
