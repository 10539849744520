import { useState } from 'react';

export const useStatus = () => {
  const [loading, setLoading] = useState(false);
  const options = [
    {
      label: '尚未開始',
      value: 0,
    },
    {
      label: '進行中',
      value: 1,
    },
    {
      label: '已結束',
      value: 2,
    },
  ];
  return { loading, options };
};
