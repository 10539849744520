import Layout from 'components/Layout';
import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


import DetailEditor from '../components/uuCafeCommon/DetailEditor';
import {
  addCafeWorldDetail,
  getCafeWorldDetail,
  updateCafeWorldDetail,
} from '../redux/actions/data/uuCafeWorldAction';
import { UUCAFE_WORLD_DETAIL_SUCCESS } from '../redux/constants/data/uuCafeWorldConstants';
import history from '../utils/createHistory';

const CafeCafeWorldDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const isSubmit = useRef(false);
  const { error, loading, success, detail } = useSelector(
    state => state.cafeWorldDetail,
  );

  const handleSubmit = useCallback(
    props => {
      if (props.isValid && props.dirty) {
        isSubmit.current = true;
        const formData = new FormData();
        formData.append('title', props.values.title);
        formData.append('title_en', props.values.title_en);
        formData.append('content', props.values.content);
        formData.append('content_en', props.values.content_en);
        formData.append('startTime', props.values.startDateTime);
        formData.append('endTime', props.values.endDateTime);
        if (props.values.img_files) {
          formData.append('img_files', props.values.img_files);
        } else {
          if (detail.imgFile && detail.imgUrl) {
            formData.append('img_files', []);
          }
        }
        if (detail.id) {
          formData.append('id', detail.id);
          dispatch(updateCafeWorldDetail(formData));
        } else {
          formData.append('id', 0);
          dispatch(addCafeWorldDetail(formData));
        }
      }
    },
    [dispatch, detail],
  );

  const goBack = useCallback(() => {
    history.push('/UUCafe/CafeWorld');
  }, []);

  useEffect(() => {
    if (params) {
      if (params.id === 'add') {
        dispatch({
          payload: {
            content: '',
            content_en: '',
            endDateTime: '',
            imgFile: '',
            imgUrl: '',
            startDateTime: '',
            title: '',
            title_en: '',
          },
          type: UUCAFE_WORLD_DETAIL_SUCCESS,
        });
      } else {
        dispatch(getCafeWorldDetail(params));
      }
    }
  }, [params, dispatch]);

  useEffect(() => {
    if (isSubmit.current && !loading) {
      if (success) {
        if (detail.id) {
          toast.success('修改成功');
        } else {
          toast.success('新增成功');
        }
        goBack();
      }
      if (error) {
        if (detail.id) {
          toast.warning('修改失敗');
        } else {
          toast.warning('新增失敗');
        }
      }
      isSubmit.current = false;
    }
  }, [loading, success, error, detail, goBack]);

  return (
    <Layout
      pageTitle={`咖啡世界-${detail.id ? '編輯' : '新增'}`}
      items={[
        { isActive: true, page_name: 'UUCafe' },
        {
          isActive: false,
          page_name: `咖啡世界-${detail.id ? '編輯' : '新增'}`,
          to: '/UUCafe/CafeWorld',
        },
      ]}
    >
      <DetailEditor
        detail={detail}
        loading={loading}
        handleSubmit={handleSubmit}
        goBack={goBack}
      />
    </Layout>
  );
};
export default CafeCafeWorldDetail;
