import moment from 'moment';
import { Table } from 'reactstrap';

import style from '../../styles/layout.module.css';
import TableHasNoData, { TableLoading } from '../TableHasNoData';

const BonusPointRecords = ({ datas }) => {
  return (
      <div className="table-responsive">
        <Table bordered hover striped className="mb-5">
          <thead className={`thead-dark ${style.table_head}`}>
          <tr>
            <th>日期</th>
            <th>類型</th>
            <th>訂單編號</th>
            <th>效期</th>
            <th>紅利點數</th>
            <th>已用點數/剩餘點數</th>
            <th>備註</th>
          </tr>
          </thead>
          {!datas && <TableLoading />}
          {datas && (
              <tbody>
              {datas?.length > 0 ? (
                  datas.map((data, i) => (
                      <tr key={i}>
                        <td>
                          {moment(data.updateTime).format('yyyy/MM/DD HH:mm:ss')}
                        </td>
                        <td>{data.reason}</td>
                        <td>{data.orderItemNumber}</td>
                        <td>
                          {data.expireTime
                              ? moment(data.expireTime).format('yyyy/MM/DD HH:mm:ss')
                              : '--'}
                        </td>
                        <td>{data.point.toLocaleString('en-US')}</td>
                        <td>{`${data.usedpoint.toLocaleString(
                            'en-US',
                        )} / ${data.remainedpoint.toLocaleString('en-US')}`}</td>
                        <td>{data.remark}</td>
                      </tr>
                  ))
              ) : (
                  <TableHasNoData />
              )}
              </tbody>
          )}
        </Table>
      </div>
  );
};

export default BonusPointRecords;
