import SortingTableHead from 'components/SortingTableHead';
import TableHasNoData from 'components/TableHasNoData';
import TableHead from 'components/TableHead';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import CommonUtil from 'utils/CommonUtil';

const TableList = ({ setFieldValue, values }) => {
  const { reportList } = useSelector(state => state.getOrderSourceList);

  useEffect(() => {
    if (JSON.stringify(reportList) !== '{}') {
      CommonUtil.consoleLog({ anno: 'response', data: reportList });
    }
  }, [reportList]);

  return (
    <div className="table-responsive">
      <Table hover striped bordered className="mb-5">
        <TableHead>
          <tr>
            <th>
              <SortingTableHead
                activeId={{
                  down: 0,
                  item: 'storeNumber',
                  up: 1,
                }}
                title="門市編號"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 2,
                  item: 'storeName',
                  up: 3,
                }}
                title="門市名稱"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 4,
                  item: 'orderSource',
                  up: 5,
                }}
                title="訂單來源"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 6,
                  item: 'cartCount',
                  up: 7,
                }}
                title="購物車筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 8,
                  item: 'masterCount',
                  up: 9,
                }}
                title="主單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 10,
                  item: 'itemCount',
                  up: 11,
                }}
                title="訂單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 12,
                  item: 'quantity_sum',
                  up: 13,
                }}
                title={'商品數'}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 14,
                  item: 'finalPrice_sum',
                  up: 15,
                }}
                title={['訂單金額', '折扣後']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 16,
                  item: 'cancelCount',
                  up: 17,
                }}
                title="取消單筆數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 18,
                  item: 'cancel_quantity_sum',
                  up: 19,
                }}
                title="取消商品數"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 20,
                  item: 'cancel_finalPrice_sum',
                  up: 21,
                }}
                title={'取消單金額'}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 22,
                  item: 'returnCount',
                  up: 23,
                }}
                title={['退貨結案', '退貨單筆數']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 24,
                  item: 'return_quantity_sum',
                  up: 25,
                }}
                title={['退貨結案', '商品數量']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 26,
                  item: 'return_totalAmount_sum',
                  up: 27,
                }}
                title={['退貨結案', '退貨金額']}
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
            <th>
              <SortingTableHead
                activeId={{
                  down: 28,
                  item: 'netSalesAmount',
                  up: 29,
                }}
                title="淨銷售額"
                setFieldValue={setFieldValue}
                currentActiveId={values.activeId}
              />
            </th>
          </tr>
        </TableHead>
        <tbody>
          {reportList.datas?.length > 0 ? (
            reportList.datas.map((data, i) => (
              <tr key={i}>
                <td>{data.storeNumber}</td>
                <td>{data.storeName}</td>
                <td>{data.orderSource}</td>
                <td>{data.cartCount}</td>
                <td>{data.masterCount}</td>
                <td>{data.itemCount}</td>
                <td>{data.quantity_sum}</td>
                <td className="price text-black fw-normal">
                  {data.finalPrice_sum.toLocaleString('en-US')}
                </td>
                <td>{data.cancelCount}</td>
                <td>{data.cancel_quantity_sum}</td>
                <td className="price text-black fw-normal">
                  {data.cancel_finalPrice_sum.toLocaleString('en-US')}
                </td>
                <td>{data.returnCount}</td>
                <td>{data.return_quantity_sum}</td>
                <td className="price text-black fw-normal">
                  {data.return_totalAmount_sum.toLocaleString('en-US')}
                </td>
                <td className="price text-black fw-normal">
                  {data.netSalesAmount.toLocaleString('en-US')}
                </td>
              </tr>
            ))
          ) : (
            <TableHasNoData colNumber={15} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableList;
