export const MODEL_LIST_REQUEST = 'MODEL_LIST_REQUEST';
export const MODEL_LIST_SUCCESS = 'MODEL_LIST_SUCCESS';
export const MODEL_LIST_FAIL = 'MODEL_LIST_FAIL';

export const MODEL_PROFILE_REQUEST = 'MODEL_PROFILE_REQUEST';
export const MODEL_PROFILE_SUCCESS = 'MODEL_PROFILE_SUCCESS';
export const MODEL_PROFILE_FAIL = 'MODEL_PROFILE_FAIL';

export const MODEL_EDIT_SETTING_REQUEST = 'MODEL_EDIT_SETTING_REQUEST';
export const MODEL_EDIT_SETTING_SUCCESS = 'MODEL_EDIT_SETTING_SUCCESS';
export const MODEL_EDIT_SETTING_FAIL = 'MODEL_EDIT_SETTING_FAIL';
export const MODEL_EDIT_SETTING_RESET = 'MODEL_EDIT_SETTING_RESET';

export const MODEL_EDIT_DESC_REQUEST = 'MODEL_EDIT_DESC_REQUEST';
export const MODEL_EDIT_DESC_SUCCESS = 'MODEL_EDIT_DESC_SUCCESS';
export const MODEL_EDIT_DESC_FAIL = 'MODEL_EDIT_DESC_FAIL';
export const MODEL_EDIT_DESC_RESET = 'MODEL_EDIT_DESC_RESET';

export const MODEL_EDIT_SPEC_REQUEST = 'MODEL_EDIT_SPEC_REQUEST';
export const MODEL_EDIT_SPEC_SUCCESS = 'MODEL_EDIT_SPEC_SUCCESS';
export const MODEL_EDIT_SPEC_FAIL = 'MODEL_EDIT_SPEC_FAIL';
export const MODEL_EDIT_SPEC_RESET = 'MODEL_EDIT_SPEC_RESET';

export const MODEL_DELETE_SPEC_REQUEST = 'MODEL_DELETE_SPEC_REQUEST';
export const MODEL_DELETE_SPEC_SUCCESS = 'MODEL_DELETE_SPEC_SUCCESS';
export const MODEL_DELETE_SPEC_FAIL = 'MODEL_DELETE_SPEC_FAIL';
export const MODEL_DELETE_SPEC_RESET = 'MODEL_DELETE_SPEC_RESET';

export const MODEL_EDIT_ORDER_REQUEST = 'MODEL_EDIT_ORDER_REQUEST';
export const MODEL_EDIT_ORDER_SUCCESS = 'MODEL_EDIT_ORDER_SUCCESS';
export const MODEL_EDIT_ORDER_FAIL = 'MODEL_EDIT_ORDER_FAIL';
export const MODEL_EDIT_ORDER_RESET = 'MODEL_EDIT_ORDER_RESET';

export const MODEL_GLOBAL_CAT_LIST_REQUEST = 'MODEL_GLOBAL_CAT_LIST_REQUEST';
export const MODEL_GLOBAL_CAT_LIST_SUCCESS = 'MODEL_GLOBAL_CAT_LIST_SUCCESS';
export const MODEL_GLOBAL_CAT_LIST_FAIL = 'MODEL_GLOBAL_CAT_LIST_FAIL';

export const GLOBAL_CAT_MODEL_REMOVE_REQUEST =
  'GLOBAL_CAT_MODEL_REMOVE_REQUEST';
export const GLOBAL_CAT_MODEL_REMOVE_SUCCESS =
  'GLOBAL_CAT_MODEL_REMOVE_SUCCESS';
export const GLOBAL_CAT_MODEL_REMOVE_FAIL = 'BRAND_CATEGORY_MODEL_REMOVE_FAIL';
export const GLOBAL_CAT_MODEL_REMOVE_RESET =
  'BRAND_CATEGORY_MODEL_REMOVE_RESET';

export const GLOBAL_CAT_MODEL_ASSIGN_REQUEST =
  'GLOBAL_CAT_MODEL_ASSIGN_REQUEST';
export const GLOBAL_CAT_MODEL_ASSIGN_SUCCESS =
  'GLOBAL_CAT_MODEL_ASSIGN_SUCCESS';
export const GLOBAL_CAT_MODEL_ASSIGN_FAIL = 'GLOBAL_CAT_MODEL_ASSIGN_FAIL';
export const GLOBAL_CAT_MODEL_ASSIGN_RESET = 'GLOBAL_CAT_MODEL_ASSIGN_RESET';

export const MODEL_BRAND_OPTIONS_REQUEST = 'MODEL_BRAND_OPTIONS_REQUEST';
export const MODEL_BRAND_OPTIONS_SUCCESS = 'MODEL_BRAND_OPTIONS_SUCCESS';
export const MODEL_BRAND_OPTIONS_FAIL = 'MODEL_BRAND_OPTIONS_FAIL';

export const MODEL_PRODUCT_OPTIONS_REQUEST = 'MODEL_PRODUCT_OPTIONS_REQUEST';
export const MODEL_PRODUCT_OPTIONS_SUCCESS = 'MODEL_PRODUCT_OPTIONS_SUCCESS';
export const MODEL_PRODUCT_OPTIONS_FAIL = 'MODEL_PRODUCT_OPTIONS_FAIL';

export const MODEL_SELLING_STATE_UPDATE_REQUEST =
  'MODEL_SELLING_STATE_UPDATE_REQUEST';
export const MODEL_SELLING_STATE_UPDATE_SUCCESS =
  'MODEL_SELLING_STATE_UPDATE_SUCCESS';
export const MODEL_SELLING_STATE_UPDATE_FAIL =
  'MODEL_SELLING_STATE_UPDATE_FAIL';
export const MODEL_SELLING_STATE_UPDATE_IDLE =
  'MODEL_SELLING_STATE_UPDATE_IDLE';

export const ADD_NEW_MODELS_IDLE = 'ADD_NEW_MODELS_IDLE';
export const ADD_NEW_MODEL_REQUEST = 'ADD_NEW_MODEL_REQUEST';
export const ADD_NEW_MODEL_SUCCESS = 'ADD_NEW_MODEL_SUCCESS';
export const ADD_NEW_MODEL_FAIL = 'ADD_NEW_MODEL_FAIL';

export const MODEL_EXPORT_IDLE = 'MODEL_EXPORT_IDLE';
export const MODEL_EXPORT_REQUEST = 'MODEL_EXPORT_REQUEST';
export const MODEL_EXPORT_SUCCESS = 'MODEL_EXPORT_SUCCESS';
export const MODEL_EXPORT_FAIL = 'MODEL_EXPORT_FAIL';

export const MODEL_IMPORT_IDLE = 'MODEL_IMPORT_IDLE';
export const MODEL_IMPORT_REQUEST = 'MODEL_IMPORT_REQUEST';
export const MODEL_IMPORT_SUCCESS = 'MODEL_IMPORT_SUCCESS';
export const MODEL_IMPORT_FAIL = 'MODEL_IMPORT_FAIL';

export const EDIT_MODEL_AVAILABLETIME_IDLE = 'EDIT_MODEL_AVAILABLETIME_IDLE';
export const EDIT_MODEL_AVAILABLETIME_REQUEST =
  'EDIT_MODEL_AVAILABLETIME_REQUEST';
export const EDIT_MODEL_AVAILABLETIME_SUCCESS =
  'EDIT_MODEL_AVAILABLETIME_SUCCESS';
export const EDIT_MODEL_AVAILABLETIME_FAIL = 'EDIT_MODEL_AVAILABLETIME_FAIL';

export const EDIT_MODEL_SHIPPING_DATE_IDLE = 'EDIT_MODEL_SHIPPING_DATE_IDLE';
export const EDIT_MODEL_SHIPPING_DATE_REQUEST =
  'EDIT_MODEL_SHIPPING_DATE_REQUEST';
export const EDIT_MODEL_SHIPPING_DATE_SUCCESS =
  'EDIT_MODEL_SHIPPING_DATE_SUCCESS';
export const EDIT_MODEL_SHIPPING_DATE_FAIL = 'EDIT_MODEL_SHIPPING_DATE_FAIL';
