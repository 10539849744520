export const ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST =
  'ORDER_RETURN_DELIVERY_REFUND_LIST_REQUEST';
export const ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS =
  'ORDER_RETURN_DELIVERY_REFUND_LIST_SUCCESS';
export const ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL =
  'ORDER_RETURN_DELIVERY_REFUND_LIST_FAIL';

export const ORDER_RETURN_RECHARGE_LIST_REQUEST =
  'ORDER_RETURN_RECHARGE_LIST_REQUEST';
export const ORDER_RETURN_RECHARGE_LIST_SUCCESS =
  'ORDER_RETURN_RECHARGE_LIST_SUCCESS';
export const ORDER_RETURN_RECHARGE_LIST_FAIL =
  'ORDER_RETURN_RECHARGE_LIST_FAIL';

export const ORDER_RETURN_EDIT_ATMACC_IDLE = 'ORDER_RETURN_EDIT_ATMACC_IDLE';
export const ORDER_RETURN_EDIT_ATMACC_REQUEST =
  'ORDER_RETURN_EDIT_ATMACC_REQUEST';
export const ORDER_RETURN_EDIT_ATMACC_SUCCESS =
  'ORDER_RETURN_EDIT_ATMACC_SUCCESS';
export const ORDER_RETURN_EDIT_ATMACC_FAIL = 'ORDER_RETURN_EDIT_ATMACC_FAIL';

export const ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE =
  'ORDER_RETURN_FAIL_EDIT_ATMACC_IDLE';
export const ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST =
  'ORDER_RETURN_FAIL_EDIT_ATMACC_REQUEST';
export const ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS =
  'ORDER_RETURN_FAIL_EDIT_ATMACC_SUCCESS';
export const ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL =
  'ORDER_RETURN_FAIL_EDIT_ATMACC_FAIL';

export const GET_ORDERITEMS_RETURN_INFO_REQUEST =
  'GET_ORDERITEMS_RETURN_INFO_REQUEST';
export const GET_ORDERITEMS_RETURN_INFO_SUCCESS =
  'GET_ORDERITEMS_RETURN_INFO_SUCCESS';
export const GET_ORDERITEMS_RETURN_INFO_FAIL =
  'GET_ORDERITEMS_RETURN_INFO_FAIL';

export const ORDER_RETURN_FINISHED_IDLE = 'ORDER_RETURN_FINISHED_IDLE';
export const ORDER_RETURN_FINISHED_REQUEST = 'ORDER_RETURN_FINISHED_REQUEST';
export const ORDER_RETURN_FINISHED_SUCCESS = 'ORDER_RETURN_FINISHED_SUCCESS';
export const ORDER_RETURN_FINISHED_FAIL = 'ORDER_RETURN_FINISHED_FAIL';
