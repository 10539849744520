import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { setSidebarToggleMobile } from '../redux/actions/style/sideBarActions';
import style from '../styles/header.module.css';

const Header = () => {
  const { userInfo } = useSelector(state => state.userLogin);

  const dispatch = useDispatch();

  const { sidebarToggleMobile } = useSelector(state => state.sideBar);

  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };

  return (
    <div className={style.header}>
      <div>
        <button onClick={toggleSidebarMobile} className={style.btn}>
          <GiHamburgerMenu size={28} color="#2c3063" />
        </button>
      </div>
      <div className={style.panel}>
        <FaUserCircle size={24} />
        {userInfo && userInfo.name && <span>{userInfo.name}</span>}
      </div>
    </div>
  );
};

export default Header;
