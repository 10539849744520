import Cookies from 'universal-cookie';

class CommonUtil {
  static getNewID = () => {
    let d = Date.now();
    if (
      typeof performance !== 'undefined' &&
      typeof performance.now === 'function'
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  };

  //******  Auth Token  *******//
  static auth_cookie_name = 'backend_auth';

  static setAuthToken = token => {
    new Cookies().set(this.auth_cookie_name, token, { path: '/' });
  };

  static getAuthToken = () => {
    return new Cookies().get(this.auth_cookie_name);
  };

  static clearAuthToken = () => {
    new Cookies().remove(this.auth_cookie_name);
  };

  //******  Auth Info  *******//
  static auth_localstorage_name = 'backend_auth';

  static setAuthInfo = data => {
    console.log('setAuthInfo');
    localStorage.setItem(this.auth_localstorage_name, JSON.stringify(data));
  };

  static getAuthInfo = () => {
    let authJson = localStorage.getItem(this.auth_localstorage_name);

    if (authJson && authJson.length > 0) return JSON.parse(authJson);

    return null;
  };

  static clearAuthInfo = () => {
    localStorage.removeItem(this.auth_localstorage_name);
  };

  //******  Auth Info  *******//
  static getQueryStrFromObj = obj => {
    let serachParams = new URLSearchParams(obj);

    let emptyParams = [];

    serachParams.forEach(function (val, key) {
      if (val === 'null' || val.length === 0) emptyParams.push(key);
    });

    emptyParams.forEach(function (key) {
      serachParams.delete(key);
    });

    var queryStr = serachParams.toString();

    return queryStr;
  };

  /**
   * dev environment only
   * @param info : { anno: string, data: any }
   */
  static consoleLog = info => {
    if (process.env.REACT_APP_API_URL !== 'https://api.pezcrown.com') {
      console.log(`${info.anno}\n`, info.data);
    }
  };

  /**
   * 拆解 nested object 的方法 (object 只拆 value 出來)
   * @param {*} obj
   * @returns 回傳包含所有 element 的單維陣列
   */
  static _deepFlatten = obj => {
    let result = [];
    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        // is array
        obj.forEach(i => {
          const data = this._deepFlatten(i);
          result.push(...data);
        });
      } else {
        // is object
        const temp = Object.values(obj);
        const data = this._deepFlatten(temp);
        result.push(...data);
      }
    } else {
      // is element
      result.push(obj);
    }
    return result;
  };

  /**
   * 表單驗證錯誤訊息的處理, 搭配 MessageUtil 帶 HTML 結尾的 func 使用
   * @param errors Formix errors 物件
   * @returns 以 HTML tag "br" 分斷的文字
   */
  static getFormVaildationErrorMessages = errors => {
    let message = '';
    this._deepFlatten(errors).forEach(error => (message += `${error}<br />`));
    return message;
  };

  /**
   * 合併商品使用
   * @param originalData 原始的商品資料
   * @param target 要被合併的商品資料
   */
  static combineModels = (originData, target) => {
    let combine = originData;
    if (combine.length === 0) {
      return target;
    } else {
      combine.forEach(t => {
        if (target.find(m => m?.mid === t?.mid && m?.area == t?.area)) {
          target
            .find(m => m?.mid === t?.mid && m?.area == t?.area)
            ?.products?.forEach(p => {
              t?.products.push(p);
            });
          target.shift();
        } else {
          combine.push(target[0]);
          target.shift();
        }
      });
      return combine;
    }
  };

  /**
   * data compare
   * @param prevData
   * @param currentData
   * @param callBack
   */
  static isValueChanged = (prevData, currentData, callBack) => {
    let result = false;
    if (prevData === null) {
      callBack(currentData);
      return true;
    }
    Object.entries(currentData).forEach(([key, value]) => {
      if (typeof prevData[key] !== 'object' && value !== prevData[key]) {
        result = true;
        callBack(currentData);
      } else if (typeof prevData[key] === 'object') {
        if (JSON.stringify(prevData[key]) !== JSON.stringify(value)) {
          result = true;
          callBack(currentData);
        }
      }
    });
    return result;
  };
}

export default CommonUtil;
