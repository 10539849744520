export const EnableOptions = [
  {
    label: '啟用',
    value: '啟用',
  },
  {
    label: '停用',
    value: '停用',
  },
];
